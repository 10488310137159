import React, { Component } from 'react'
import { SimpleForm, ReferenceInput, SelectInput } from 'react-admin'
import {
  FuzzySelectInput,
  defaultFuzzySearch,
} from '../common/react-fuzzy-picker'
import { Provider } from '../provider'
import { reset } from 'redux-form'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import CustomToolbar from '../common/CustomToolbarForm'


class CompanyAgencyForm extends Component {

  state = {
    record: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record } = nextProps
    let { record:  currentRecord } = prevState
    if (record !== currentRecord) {
      currentRecord = {
        ...currentRecord,
        id: record.id,
        companyId: record.companyId,
        type: record.type,
        status: record.status,
        agencyId: record.agencyId,
      }
    }
    return {
      record: currentRecord || {}
    }
  }

  onSave = record => {
    let { action } = this.props
    if (action === 'edit') {
      this.edit(record)
    } else if(action === 'create') {
      this.create(record)
    }
  }

  create = record => {
    let { onDone, reset, onError, form } = this.props
    Provider.dataProvider('CREATE', 'companyagencies', {
      data: record
    }).then( success => {
      reset(form)
      onDone && onDone()
    }).catch( error => {
      reset(form)
      onError && onError()
    })
  }

  edit = record => {
    let { onDone, reset, onError, form } = this.props
    Provider.dataProvider('UPDATE', 'companyagencies', {
      id: record.id,
      data: record
    }).then( success => {
      reset(form)
      onDone && onDone()
    }).catch( error => {
      reset(form)
      onError && onError()
    })
  }


  render() {
    let { form } = this.props
    let { record } = this.state
    return <SimpleForm
      record={record}
      form={form}
      basePath="/companyagencies"
      resource="companyagencies"
      save={this.onSave}
      toolbar={<CustomToolbar />}
    >
      <ReferenceInput
        reference="companies"
        source="companyId"
        fullWidth
        perPage={1000}
        filter={{ '../fields': ['id', 'name'] }}
      >
        <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
      </ReferenceInput>
      <ReferenceInput
        source="type"
        reference="agencytypes"
        fullWidth
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="status"
        reference="statuses"
        fullWidth
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  }
}

const enhance = compose(connect(null, { reset }))

export default enhance(CompanyAgencyForm)
