import React, { Component, createRef } from 'react'
import {
  List,
  Create,
  Edit,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  CardActions as RACardActions,
  ReferenceManyField,
  MenuItemLink,
  Filter,
  ReferenceInput,
  SelectInput,
  DateField,
} from 'react-admin'
import FormSave from './FormSave'
import { CardActions, withStyles, Button } from '@material-ui/core'
import AssignButton from './AssignButton'
import UnAssignButton from './UnAssignButton'
import ObjectLicence, { ExpiredText } from './ObjectLicence'
import UpsertServiceTaskButton from './UpsertServiceTaskButton'
import DropDownMenu from '../common/DropDownMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { EditTitle } from '../common/Title'
import RenewalButton from './RenewalButton'
import { Provider } from '../provider'
import CustomToolbar from '../common/CustomToolbarForm'
import LinkField from '../common/LinkField'
import EditIcon from '@material-ui/icons/Edit'
import { isCompanyManager } from '../utils/permission'
import _ from 'lodash'

const styleListAction = theme => ({
  rigthLabel: {
    paddingLeft: '0.5em'
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
})

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}

const ListMenu = ({ data, ids }) => {
  return ids ? ids.map((id, idx) => (
    <MenuItemLink
      key={idx}
      to={`/licences/create?type=${data[id].id}`}
      primaryText={data[id].name}
    />
  )) : null
}

const dropDownMenuItems = (translate, record, classes) => (
  <DropDownMenu
    icon={<FontAwesomeIcon className={classes.leftIcon} icon={faPlus} />}
    label={translate('button.create')}
    iconButton={false}
  >
    <ReferenceManyField
      basePath="/licencetypes"
      resource="licencetypes"
      reference="licencetypes"
      record={{}}
      target=""
    >
      <ListMenu />
    </ReferenceManyField>
  </DropDownMenu>
)

const ActionList = withStyles(styleListAction)(({
  resource,
  filters,
  showFilter,
  displayedFilters,
  filterValues,
  translate,
  history,
  classes,
  companyManager,
}) => {
  return (
    <RACardActions>
      {companyManager && <>
        {filters && React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      {dropDownMenuItems(translate, {}, classes)}
      <Button
        color="primary"
        onClick={() => {
          history.push(`/${resource}/import`)
        }}
      >
        <FontAwesomeIcon icon={faFileUpload} />
        <span className={classes.rigthLabel}>
          {translate('button.import')}
        </span>
      </Button>
      </>}
    </RACardActions>
  )
})

const FilterList = props => (
  <Filter {...props}>
    <ReferenceInput
      source="type"
      reference="licencetypes"
      alwaysOn
      fullWidth
    >
      <SelectInput optionText="name" translateChoice={false} />
    </ReferenceInput>
  </Filter>
)

const filterDefaultValues = {
  '../include': {
    relation: 'licenceValidity',
    scope: {
      where: { status: { neq: '40ARC' } },
      fields: ['id', 'licenceNumber', 'validFrom', 'validTo', 'renewalNo', ]
    },
  },
  '../fields': ['id', 'status', 'licenceValidity', 'type']
}

class _LicenceList extends Component {

  render() {
    let { classes, history, permissions, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <List
        history={history}
        {...props}
        actions={<ActionList history={history} companyManager={companyManager} />}
        filters={<FilterList  {...props} />}
        bulkActionButtons={false}
        filterDefaultValues={filterDefaultValues}
      >
        <Datagrid>
          <TextField
            label="resources.licences.fields.licenceNumber"
            source="licenceValidity[0].licenceNumber"
          />
          <DateField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.licences.fields.validFrom"
            source="licenceValidity[0].validFrom"
            locales="vi-VN"
          />
          <DateField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.licences.fields.validTo"
            source="licenceValidity[0].validTo"
            locales="vi-VN"
          />
          <ReferenceField
            source="type"
            reference="licencetypes"
            allowEmpty
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            linkType={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.licences.fields.numberOfRenewal"
            source="licenceValidity[0].renewalNo"
          />
          <FunctionField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.licences.fields.applyFor"
            source="object"
            render={
              record => record.status !== '30UNA' && (
                <ObjectLicence record={record} showWithChip={true} companyManager={companyManager} />
              )}
          />
          <FunctionField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.licences.fields.note"
            render={record => {
              let validTo = _.get(record, 'licenceValidity[0].validTo')
              return <ExpiredText validTo={validTo} />
            }}
          />
          {companyManager && <FunctionField
            render={record => {
              return <LinkField path={`/licences/${record.id}`} >
                <EditIcon fontSize="small" />
              </LinkField>
            }}
          />}
        </Datagrid>
      </List>
    )
  }
}

export const LicenceList = withStyles(styles)(_LicenceList)

const Title = ({ resource, record = {} }) => (
  <EditTitle
    record={record}
    resource={resource}
    render={async record => {
      let { id } = record
      let result
      if (id) {
        let response = await Provider.dataProvider('GET_ONE', 'licences', {
          id,
          filter: {
            fields: ['id', 'licenceValidity' ],
            include: {
              relation: 'licenceValidity',
              scope: {
                where: { status: { neq: '40ARC' } },
                fields: ['id', 'licenceNumber'],
              }
            }
          },
        })
        if (response && response.data) {
          result = _.get(response.data, 'licenceValidity[0].licenceNumber', '')
        }
      }
      return result
    }}
  />
)

export const LicenceCreate = props => {
  return (
    <Create
      {...props}
      title={<Title {...props} />}
      redirect="list"
    >
      <FormSave />
    </Create>
  )
}

const styleEditAction = () => ({
  root: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

const LicenceEditActions = withStyles(styleEditAction)(({
  data = {},
  getRecord,
  classes,
  handleDone,
  companyManager,
}) => (
  <CardActions className={classes.root}>
    {companyManager && <>
      {data.status === '30UNA'
        ? <AssignButton handleDone={handleDone} record={data} />
        : <UnAssignButton handleDone={handleDone} record={data} />
      }
    <UpsertServiceTaskButton
      isCreate={true}
      licenceRecord={data}
      isIconButton={false}
    />
    <RenewalButton getRecord={getRecord} handleDone={handleDone} record={data} />
    </>}
  </CardActions>
))

export class LicenceEdit extends Component {
  constructor(props) {
    super(props)
    this.formsave = createRef()
  }

  handleChangeDone = () => {
    this.formsave.current.handleChangeDone()
  }

  getRecord = () => {
    return this.formsave.current && this.formsave.current.getRecord()
  }

  render() {
    let { record, id, save, permissions, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <Edit
        actions={<LicenceEditActions
          getRecord={this.getRecord}
          handleDone={this.handleChangeDone}
          companyManager={companyManager}
        />}
        title={<Title record={record} {...props} />}
        undoable={false}
        id={id}
        {...props}
      >
        <FormSave
          toolbar={<CustomToolbar />}
          forwardRef={this.formsave}
          companyManager={companyManager}
          save={save}
          id={id}
          isEdit={true}
          redirect="list"
        />
      </Edit>
    )
  }
}
