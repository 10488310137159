import React from 'react'
import { Typography, CardContent } from '@material-ui/core'
import { Pagination } from 'react-admin'

const PaginationWrapper = ({ noResultTitle, noResultText, ...rest}) => {
  if (!rest.isLoading && rest.total === 0) {
    if (typeof noResultText === 'string') {
      noResultText = (
        <CardContent>
          <Typography component="h2" variant="h5">
            {noResultTitle}
          </Typography>
          <p></p>
          <Typography component="subtitle" variant="subtitle1">
            {noResultText}
          </Typography>
        </CardContent>
      )
    }
    return noResultText
  }
  return <Pagination {...rest} />
}

export default PaginationWrapper
