import React, { Component } from 'react'
import {
  TextInput,
  SelectInput,
  ReferenceInput,
  ReferenceArrayInput,
  translate,
  required,
} from 'react-admin'
import CheckboxGroupInput from '../common/CheckboxGroupInput'
import { DatePickerInput } from '../common/DatePicker'
import { withStyles, Grid } from '@material-ui/core'
import compose from 'recompose/compose'
import { MIN, MAX, validateName } from '../common/validateName'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    stopList: {
      padding: 12
    },
    card: {
      padding: 12
    },
    checkbox: {
      display: 'flex'
    }
  })
}

class FormSave extends Component {
  render() {
    const { classes, resource } = this.props
    const extra = { resource, fullWidth: true }
    return (
      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <TextInput
            source="name"
            validate={validateName(MIN, MAX)}
            {...extra}
          />
          <ReferenceArrayInput
            source="days"
            reference="servicedays"
            validate={required()}
            sort={{id: 'asc'}}
            {...extra}
          >
            <CheckboxGroupInput
              className={classes.checkbox}
              direction="row"
            />
          </ReferenceArrayInput>
          <DatePickerInput
            source="startDate"
            dateFormat="DD/MM/YYYY"
            showLunarDate={true}
            keyboard={true}
            {...extra}
          />
          <DatePickerInput
            source="endDate"
            dateFormat="DD/MM/YYYY"
            showLunarDate={true}
            keyboard={true}
            {...extra}
          />
          <TextInput source="desc" {...extra} />
          <ReferenceInput
            reference="statuses"
            source="status"
            defaultValue="10ACT"
            {...extra}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(FormSave)
