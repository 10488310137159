import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListSubheader,
  Collapse,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import _ from 'lodash'
import { Provider } from '../provider'
import {
  translate,
  showNotification,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import CancelTimeInput from './CancelTimeInput'

export const ON_PARENT = 'ON_PARENT'
export const OFF_PARENT = 'OFF_PARENT'

const styles = theme => ({
  root: {
    padding: 0,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 8,
  },
  listItemSecondaryAction : {
    width: 100,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  }
})

class MenuSetting extends Component {
  state = {
    setting: {
      client: {
        pos: {
          menu: {
            agency: {
              hide: {}
            },
            driver: {
              hide: {}
            },
          },
          actionSetting: {
            agency: {
              autoPrint: false,
            },
            driver: {
              autoPrint: false,
              usePOSSession: false,
              cancelTime: 30,
            },
          },
        },
        webapp: {
          menu: {
            hide: {}
          },
        },
        manager: {
          menu: {
            hide: {}
          }
        },
      }
    },
    menus: {},
    ids: [],
    childIds: {},
    cancelTime: 30,
  }
  
  getHideMenu = (hideMenu, setting) => {
    let hides
    let isPos = hideMenu.split('.').length === 2 
    if (isPos) {
      let menuPos = hideMenu.split('.')
      hides = _.get(setting, `client.${menuPos[0]}.menu.${menuPos[1]}.hide`, {})
    }
    else {
      hides = _.get(setting, `client.${hideMenu}.menu.hide`, {})
    }
    return hides
  }

  getHideAction = (hideMenu, setting) => {
    let hides
    let menuPos = hideMenu.split('.')
    hides = _.get(setting, `client.${menuPos[0]}.actionSetting.${menuPos[1]}`, {})
    return hides
  }

  updateData = (options) => {
    let { menus, setting, cancelTime } = this.state
    let { items, hideMenu, handleKey, actionSetting } = this.props
    let ids = []
    let childIds = {}
    for (let itemIdx = 0; itemIdx < items.length; itemIdx++) {
      const item = items[itemIdx]
      let key = !handleKey ? `${item.name}` : handleKey(item.name)
      if (!ids.includes(key)) {
        ids.push(key)
      }
      menus[key] = {
        ...menus[key],
        name: item.name,
        hide: actionSetting ? true : false,
        icon: item.icon,
        keyName: key
      }
      if (item.childrens) {
        childIds[key] = []
        let openCollapse = menus[key].openCollapse
        menus[key].openCollapse = !(!openCollapse)
        for (let childrenIdx = 0; childrenIdx < item.childrens.length; childrenIdx++) {
          let childItem = item.childrens[childrenIdx]
          let childId = childItem.name ? key + `/${childItem.name}` : childItem.id
          childIds[key].push(childId)
          menus[childId] = {
            name: childItem.name,
            text: childItem.text,
            hide: false,
            icon: childItem.icon,
            keyName: childId
          }
        }
      } else {
        delete childIds[key]
      }
    }

    let hides = actionSetting ? this.getHideAction(hideMenu, setting) : this.getHideMenu(hideMenu, setting)
    Object.keys(hides).forEach(key => {
      if (actionSetting) {
        menus[key].hide = !hides[key]
      } else {
        if (menus[key])
          menus[key].hide = true
      }
    })
    if (options) {
      let { action, key } = options
      switch (action) {
        case OFF_PARENT:
          menus[key].openCollapse = false
          break
        case ON_PARENT:
          menus[key].openCollapse = true
          break
        default:
      }
    }

    if (setting.client.pos.actionSetting) {
      cancelTime = setting.client.pos.actionSetting.driver.cancelTime
    }

    this.setState({
      ids,
      menus,
      childIds,
      cancelTime,
    })
  }
  
  loadData = async () => {
    let { record, resources } = this.props
    let res
    let { id } = record
    let whereId = resources === 'agencysettings' ? { agencyId: id } : { companyId: id }

    res = await Provider.dataProvider(
      'GET_LIST',
      resources, {
        filter: {
          where: whereId
        },
        sort: {},
        pagination: {}
      })

    if (!_.isEmpty(res) && res.data.length > 0) {
      let { setting, cancelTime } = this.state
      let settingData = { ...res.data[0].setting }
      setting.client = { ...settingData.client }

      if (setting.client.pos.actionSetting) {
        cancelTime = setting.client.pos.actionSetting.driver.cancelTime
      }

      this.setState({ setting, cancelTime })
    }

    this.updateData()
  }

  componentDidMount() {
    this.loadData()
  }

  handleChange = (key) => () => {
    const { record, showNotification, hideMenu, handleChild, resources, actionSetting } = this.props
    let { menus, childIds, setting } = this.state
    let hide = actionSetting ? this.getHideAction(hideMenu, setting) : this.getHideMenu(hideMenu, setting)
    if (actionSetting) {
      hide[key] = !hide[key]
    } else {
      if (hide[key]) {
        delete hide[key]
      } else {
        hide[key] = 1
      }
    }
    switch (hideMenu) {
      case 'webapp' : {
        setting.client.webapp.menu.hide = hide
        break
      }
      case 'pos.driver': {
        if (actionSetting) {
          setting.client.pos.actionSetting.driver = hide
        } else {
          setting.client.pos.menu.driver.hide = hide
        }
        break
      }
      case 'pos.agency': {
        if (actionSetting) {
          setting.client.pos.actionSetting.agency = hide
        } else {
          setting.client.pos.menu.agency.hide = hide
        }
        break
      }
      case 'manager': {
        setting.client.manager.menu.hide = hide
        break
      }
      default:
    }
    let data = resources === 'agencysettings' ? {
      agencyId: record.id,
      setting: setting,
    } : {
      companyId: record.id,
      setting: setting,
    }

    Provider.dataProvider('REMOTE', resources, {
      method: 'changeMenuSetting',
      requestMethod: 'POST',
      data: data,
    }).then(
      () => {
        handleChild ? this.updateData(handleChild(menus, childIds, key)) : this.updateData()
      }
    ).catch(
      () => {
        showNotification('error.change_failed', 'warning')
      }
    )
  }

  handleExpand = parentKey => {
    let { menus } = this.state
    menus[parentKey].openCollapse = !menus[parentKey].openCollapse
    this.setState({ menus })
  }

  renderItem = (from, to) => {
    let { menus, ids, childIds } = this.state
    let { translate, classes, } = this.props
    let result = []
    for (let idx = from; idx <= to; idx++) {
      let parentKey = ids[idx]
      result.push(
        <Fragment key={idx}>
          <ListItem
            button
            onClick={() => {
              if (!childIds[parentKey]) return
              this.handleExpand(parentKey)
            }}
            disabled={menus[parentKey].hide}
          >
            {menus[parentKey].icon &&
              <ListItemIcon>
                {menus[parentKey].icon}
              </ListItemIcon>}
            <ListItemText
              inset
              primary={translate(
                `resources.${menus[parentKey].name}.name`,
                {
                  _: menus[parentKey].name,
                  smart_count: 1,
                }
              )}
            />
            <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
              <Switch
                onChange={this.handleChange(parentKey)}
                checked={!menus[parentKey].hide}
              />
              {childIds[parentKey]
                && (menus[parentKey].openCollapse ?
                  <IconButton onClick={() => this.handleExpand(parentKey)} >
                    <ExpandLess />
                  </IconButton> :
                  <IconButton
                    onClick={() => this.handleExpand(parentKey)}
                    disabled={menus[parentKey].hide}
                  >
                    <ExpandMore />
                  </IconButton>)}
            </ListItemSecondaryAction>
          </ListItem>
          {childIds[parentKey] && <Collapse
            in={menus[parentKey].openCollapse}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {childIds[parentKey].map((childId, idx) => {
                let childItem = menus[childId]
                console.log('itt', childItem.text)
                return <ListItem
                  key={idx}
                  disabled={childItem.hide}
                  className={classes.nested}
                >
                  {childItem.icon &&
                    <ListItemIcon>{childItem.icon}</ListItemIcon>
                  }
                  <ListItemText
                    inset
                    primary={childItem.name ? translate(
                      `resources.${childItem.name}.name`,
                      {
                        _: childItem.name,
                        smart_count: 1,
                      }) : translate(childItem.text)}
                  />
                  <ListItemSecondaryAction style={{ width: 100 }}>
                    <Switch
                      onChange={this.handleChange(childId)}
                      checked={!childItem.hide}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              })}
            </List>
          </Collapse>}
        </Fragment>
      )
    }
    return result
  }

  handleCancelTimeChange = cancelTime => {
    let { resources, record } = this.props
    let { setting } = this.state
    setting.client.pos.actionSetting.driver.cancelTime = cancelTime
    let data = resources === 'agencysettings' ? {
      agencyId: record.id,
      setting: setting,
    } : {
      companyId: record.id,
      setting: setting,
    }

    Provider.dataProvider('REMOTE', resources, {
      method: 'changeMenuSetting',
      requestMethod: 'POST',
      data: data,
    }).then(
      () => {
        this.updateData()
      }
    ).catch(
      () => {
        showNotification('error.change_failed', 'warning')
      }
    )
  }

  render() {
    let { translate, actionDriver } = this.props
    let { ids=[], cancelTime } = this.state
    let midIndex = Math.floor(ids.length / 2)
    return ids.length > 0 ?
      (
        <List component='nav'
          subheader={
            <ListSubheader
              component="div"
            >
              {translate('resources.companies.fields.showOrHideMenu')}
            </ListSubheader>}
        >
          <Grid container>
            {actionDriver ? <Grid item xs={12} md={6}>
              {this.renderItem(0, ids.length - 2)}
              <div>
                <CancelTimeInput
                  label="resources.cancelTime.name"
                  defaultValue={cancelTime}
                  extraChange={this.handleCancelTimeChange}
                  fullWidth
                />
              </div>
            </Grid> : <Grid container>
              <Grid item xs={12} md={6} style={{ borderRight: '1px solid #e8e8e8' }}>
                {this.renderItem(0, midIndex)}
              </Grid>
              <Grid item xs={12} md={6}>
                {this.renderItem(midIndex + 1, ids.length - 1)}
              </Grid>
            </Grid>}
          </Grid>
        </List>
      ) : <div></div>
  }
}

export default compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification }),
)(MenuSetting)
