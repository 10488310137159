import React, { Component } from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import ValueInput from './ValueInput'

const style = () => ({
  root: {
    width: '50%',
  }
})

class FormSave extends Component {

  render() {
    let { classes } = this.props
    let extras = { resource: 'configurations', fullWidth: true }
    return <div className={classes.root}>
      <TextInput 
        source="code"
        {...extras} 
        validate={[required(), minLength(6), maxLength(255)]}
      />
      <ReferenceInput
        source="group"
        reference="configurationgroups"
        validate={required()}
        {...extras}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="dataType"
        reference="configurationdatatypes"
        validate={required()}
        {...extras}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="desc" {...extras} />
      <FormDataConsumer>
        {(form, props) => {
          let { formData = {} } = form
          let { dataType: type }  = formData
          let name = 'value'
          if (type === 'IMAGE') {
            name='bValue'
          }
          return <ValueInput source={name} type={type} {...extras} />
        }}
      </FormDataConsumer>
    </div>
  }
}

export default withStyles(style)(FormSave)
