import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  IconButton,
  Button,
} from '@material-ui/core'
import compose from 'recompose/compose'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { upsertServiceTask as upsertServiceTaskAction } from './action'
import { connect } from 'react-redux'
import { translate } from 'ra-core'
import CustomToolbar from '../common/CustomToolbarForm'

const EditIcon = () => (
  <FontAwesomeIcon icon={faPen} />
)

class ServiceTaskFormSave extends Component {

  render() {
    let {
      open,
      handleClose,
      handleSave,
      licenceRecord = {},
      isCreate,
      record,
      translate
    } = this.props
    let recordForm = {
      name: '',
      desc: '',
      principalType: 'Licence',
      principalId: licenceRecord.id,
    }
    if (!isCreate) {
      recordForm = record
    }
    const extra = { resource: 'servicetasks', fullWidth: true }
    return (
      <Dialog
        open={open}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{translate('button.create_service_task')}</DialogTitle>
        <div>
          <SimpleForm
            record={{...recordForm }}
            form={isCreate ? 'serviceTaskFormCreate' : 'serviceTaskFormEdit'}
            resource="servicetasks"
            save={(record) => handleSave({ ...record, id: recordForm.id })}
            toolbar={<CustomToolbar />}
          >
            <TextInput source="name" {...extra} />
            <TextInput source="desc" {...extra} />
            <ReferenceInput
              source="serviceGroupId"
              basePath="/servicegroups"
              reference="servicegroups"
              defaultValue=""
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="status"
              basePath="/statuses"
              reference="statuses"
              defaultValue="00IAT"
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleForm>
        </div>
      </Dialog>
    )
  }
}

ServiceTaskFormSave.defaultProps = {
  elements: [],
}

class UpsertServiceTaskButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
  }
  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  handleSave = record => {
    let { upsertServiceTaskAction } = this.props
    let done = () => {
      this.setState({
        open: false
      })
    }
    let error = () => {
      this.setState({
        open: false
      })
    }
    upsertServiceTaskAction(record, done, error)
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    let { licenceRecord, record, isIconButton, isCreate,translate } = this.props
    let { open } = this.state
    return (
      <>
        {isIconButton ? 
          <IconButton onClick={this.openDialog}>
            <EditIcon />
          </IconButton> :
          <Button
            color="primary"
            onClick={this.openDialog}
          >
            {translate('button.create_service_task')}
          </Button>
        }
        {open && <ServiceTaskFormSave
          translate={translate}
          open={open}
          handleClose={this.handleClose}
          record={record}
          isCreate={isCreate}
          licenceRecord={licenceRecord}
          handleSave={this.handleSave}
        />}
      </>
    )
  }
}

const enhance = compose(
  translate,
  connect(
    null,
    {
      upsertServiceTaskAction
    }
  )
)

export default enhance(UpsertServiceTaskButton)
