import React, { Component, Fragment } from 'react'
import {
  IconButton,
  withStyles,
} from '@material-ui/core'
import {
  addField,
} from 'react-admin'
import _ from 'lodash'
import ReactDatagrid from 'react-data-grid'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons'
import { formatCurrency } from '../utils/formatUtil'
import PropTypes from 'prop-types'
import ColorButton from './ColorButton'

const styles = {
  icon: {
    width: 32,
    height: 32,
    padding: 0,
    marginTop: -8,
    float: 'right',
  },
  header: {
    display: 'flex'
  },
  title: {
    flex: 1,
    alignSelf: 'center'
  },
  colorBtn: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end'
  }
}

const cellStyles = {
  normal: {
  },
  label: {
    display: 'flex',
  },
  basePrice: {
    display: 'flex',
  },
  value: {
    flex: 1,
    alignSelf: 'center'
  },
  colorBtn: {
    display: 'flex',
    flex: 1,
    justifyContent: 'end'
  },
  fomular: {
    fontSize: 8,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}

const _LabelCellFormatter = ({
  classes,
  value,
  row,
  column,
  setColor,
}) => {
  const { idx: columnIdx, key } = column
  const { color, originId } = row
  return <div className={classes.label}>
    <span>{value}</span>
    <div className={classes.colorBtn}>
      <ColorButton
        color={color}
        setColor={(newColor) => {
          setColor(newColor, originId)
        }}
      />
    </div>
  </div>
}
const LabelCellFormatter = withStyles(cellStyles)(_LabelCellFormatter)

class _BaseCellFormatter extends Component {
  state = {}

  componentDidMount () {
    const { value } = this.props
    let isLock = _.get(value, 'isLock', true)
    this.setState({ isLock })
  }

  handleSwitchLock = (rowIdx, columnIdx) => {
    const { onClick } = this.props
    this.setState({ isLock: !this.state.isLock })
    onClick(rowIdx, columnIdx)
  }

  render () {
    const { classes, value, row, onClick, rowIdx, column, } = this.props
    const { idx: columnIdx, key } = column
    const { color } = row
    const { isLock } = this.state
    if (typeof value === 'object') {
      const { value: val, color: backgroundColor } = value
      return <div className={classes.basePrice} style={{ backgroundColor }}>
        <span className={classes.value}>{formatCurrency(val, 'vi', '')}</span>
        <IconButton
          color="primary"
          className={classes.icon}
          onClick={() => this.handleSwitchLock(rowIdx, columnIdx)}
        >
          <FontAwesomeIcon
            color="error"
            icon={isLock ? faLock : faLockOpen}
            size="xs"
          />
        </IconButton>
      </div>
    }
    return <span>{value}</span>
  }
}
const BaseCellFormatter = withStyles(cellStyles)(_BaseCellFormatter)

const HeaderRenderer = withStyles(styles)(({ classes, column, color, setColor }) => {
  return <div className={classes.header}>
    <span className={classes.value}>
      {column.name}
    </span>
    <div className={classes.colorBtn}>
      <ColorButton
        color={color}
        setColor={(color) => {
          setColor(color, column.key)
        }}
      />
    </div>
  </div>
})


class CellEditor extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    column: PropTypes.object,
    options: PropTypes.array
  }

  getValue() {
    let updated = {}
    let { column, rowData, options = [] } = this.props
    let value = Number(this.getInputNode().value)
    let old = rowData[column.key]
    const option = options.find(item => item.fareIndexId === value)
    updated[column.key] = { ...old, value: option.value, fareIndexId: value }
    return updated
  }

  getInputNode() {
    return this.input
  }

  render() {
    let { value, options = [] } = this.props
    return (
      <select
        ref={ref => this.input = ref}
        defaultValue={value.fareIndexId}
      >
        {options.map(item => {
          return <option key={item.fareIndexId} value={item.fareIndexId}>{item.value}</option>
        })}
      </select>
    )
  }
}

//<input
//ref={ref => this.input = ref}
//defaultValue={isObject ? props.value.value : props.value}
///>

class _FareMatrix extends Component {

  static propTypes = {
    columns: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    input: PropTypes.object.isRequired,
    setRows: PropTypes.func.isRequired
  }

  state = {}

  componentDidMount() {
    const { input, rows } = this.props
    if (rows) {
      input.onChange(rows)
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { rows } = this.props
    let { rows: prevRows } = prevProps
    const updated = !(_.isEqual(prevRows, rows))
    return { updated }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      const { input, rows } = this.props
      if (rows) {
        input.onChange(rows)
      }
    }
  }

  onGridRowsUpdated = ({ toRow, updated, cellKey }) => {
    const { input, setRows } = this.props
    const rows = _.cloneDeep(input.value)
    let row = rows[toRow]
    let { view, data } = row
    const dataIndex = _.findIndex(data, item => item.destinationId === Number(cellKey))
    view = { ...view, ...updated }
    let val = data[dataIndex]
    val = { ...val, ...updated[cellKey] }
    data[dataIndex] = val

    row = { ...row, view, data }
    rows[toRow] = row
    setRows(rows)
    //input.onChange(rows)
  }

  handleRowClick = (rowIdx, row, column) => {
    let { setFareCell } = this.props
    let { idx: columnIdx } = column
    if (!columnIdx) return
    if (!columnIdx && !rowIdx) return
    setFareCell(rowIdx, columnIdx)
  }

  render() {
    let { input, columns } = this.props
    let rows = input.value
    if (!rows) return null
    return (
      <ReactDatagrid
        columns={columns}
        rowsCount={rows.length}
        rowGetter={i => {
          if (rows[i]) {
            return rows[i].view
          }
        }}
        enableCellSelect
        onGridRowsUpdated={this.onGridRowsUpdated}
        onRowClick={this.handleRowClick}
      />
    )
  }
}

const FareMatrix = addField(_FareMatrix)

export {
  FareMatrix,
  BaseCellFormatter,
  HeaderRenderer,
  CellEditor,
  LabelCellFormatter,
}

