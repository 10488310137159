export const changeLocation = (form, field, value) => {
  return ({
    type: '@@redux-form/CHANGE',
    meta: {
      form: form,
      field: field,
      touch: false,
      persistentSubmitErrors: false
    },
    payload: value
  })
}