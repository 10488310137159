import React, { Component } from 'react'
import { 
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  IconButton,
  withStyles,
  Chip,
  Collapse,
  Avatar,
  Tooltip,
} from '@material-ui/core'
import clsx from 'clsx'
import { grey, green, yellow } from '@material-ui/core/colors'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RouteGroupDetail from './RouteGroupDetail'
import ArchiveButton from './ArchiveButton'
import _ from 'lodash'
import { PriceIcon } from '../icons'
import LinkField from '../common/LinkField'
import { limitTextLength } from '../utils/formatUtil'
import EditIcon from '@material-ui/icons/Edit'

const cardStyle = theme => ({
  card: {
    marginBottom: 8
  },
  cardActions: {
    padding: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  unAssigned: {
    backgroundColor: grey[500],
    color: 'white'
  },
  avatarUnassigned: {
    backgroundColor: grey[600],
    color: 'white'
  },
  assignedAll: {
    backgroundColor: green[500],
    color: 'white'
  },
  avatarAssignedAll: {
    backgroundColor: green[600],
    color: 'white'
  },
  assignedAPart: {
    backgroundColor: yellow[500]
  },
  avatarAssignedAPart: {
    backgroundColor: yellow[600]
  },
  textContainer: {
    fontSize: 13,
    marginBottom: 4
  },
  label: {
    fontWeight: 'bold'
  }
})

class _RouteGroupCard extends Component {

  state = {
    expanded: false
  }

  handleExpand = () => {
    let { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    let { record, translate, isCompanyManager, classes, history } = this.props
    let { expanded } = this.state
    let { countRoute, countSchedule, countTimetable, routeTrips, routeFares, id } = record
    let routeFareChip = ''
    if (routeFares) {
      let unAssigned = _.filter(routeFares, routeFare => !routeFare)
      let total = Object.values(routeFares).length
      let unAssignedLength = unAssigned.length
      if (unAssignedLength === 0) {
        routeFareChip = <Chip
          avatar={<Avatar className={classes.avatarAssignedAll}>
            <PriceIcon className={classes.icon} fontSize="small"/>
          </Avatar>}
          className={classes.assignedAll}
          label={translate('resources.routes.fare_assigned_all')}
        />
      } else {
        if (total === unAssignedLength) {
          routeFareChip = <Chip
            avatar={<Avatar className={classes.avatarUnassigned}>
              <PriceIcon className={classes.icon} fontSize="small"/>
            </Avatar>}
            className={classes.unAssigned}
            label={translate('resources.routes.fare_unassigned')}
          />
        } else {
          routeFareChip = <Chip
            avatar={<Avatar className={classes.avatarAssignedAPart}>
              <PriceIcon className={classes.icon} fontSize="small"/>
            </Avatar>}
            className={classes.assignedAPart}
            label={translate('resources.routes.fare_assigned_a_part', { number: total - unAssignedLength })}
          />
        }
      }
    }
    let countTrip = 0
    if (routeTrips) {
      countTrip = _.reduce(Object.values(routeTrips), (total, ele) => {
        total += ele
        return total
      }, 0)
    }
    return <div className={classes.card}>
      <Card>
        <CardHeader title={limitTextLength(record.name, 50)} />
        <Divider />
        <CardContent>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.routegroups.fields.countRoute')}:</span>&nbsp;<span>{countRoute}</span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.routegroups.fields.countTimetable')}:</span>&nbsp;<span>{countTimetable}</span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.routegroups.fields.countSchedule')}:</span>&nbsp;<span>{countSchedule}</span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.routegroups.fields.countTrip')}:</span>&nbsp;<span>{countTrip}</span>
          </div>
          <div>
            {routeFareChip}
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <LinkField
            className={classes.iconButton}
            path={`/routegroups/${id}`}
            icon
          >
            <Tooltip title={translate('button.edit')} enterDelay={100} >
              <EditIcon fontSize="small" />
            </Tooltip>
          </LinkField>
          {isCompanyManager && <ArchiveButton id={id} />}
          <IconButton
            className={clsx(classes.expand, { [classes.expandOpen]: expanded })}
            aria-label={translate('button.edit')}
            onClick={this.handleExpand}
            aria-expanded={expanded}
          >
            <ExpandMoreIcon /> 
          </IconButton>
        </CardActions>
      </Card>
      <Collapse in={expanded}>
        <RouteGroupDetail record={record} history={history} isCompanyManager={isCompanyManager} />
      </Collapse>
    </div>
  }
}

const enhance = compose(translate, withStyles(cardStyle))
const RouteGroupCard = enhance(_RouteGroupCard)

const style = {
  root: {
    padding: 8
  }
}

class MobileGrid extends Component {

  render() {
    let { ids, data, isCompanyManager, classes, history } = this.props    
    return <div className={classes.root}>
      {ids.map(id => {
        return <RouteGroupCard 
          isCompanyManager={isCompanyManager}
          key={id}
          record={data[id]}
          history={history}
        />
      })}
    </div>
  }
}

export default withStyles(style)(MobileGrid)
