import React, { Component } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sanitize } from '../utils/commonUtil'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  },
}

class EditReservationButton extends Component {

  onClick = (e) => {
    let { onEditSeat, seat, charge } = this.props
    e.preventDefault()
    e.stopPropagation()
    onEditSeat({ seat, charge })
    return true
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      ...props
    } = this.props
    let rest = sanitize(props, ['onEditSeat', 'reservationId'])
    return button ? <Button
      color={color}
      onClick={this.open}
      {...rest}
    >
      <div className={classes.container}>
        <FontAwesomeIcon icon={faEdit} className={classes.icon} />
        <span>{translate('button.edit')}</span>
      </div>
    </Button> : <Tooltip
      title={translate('button.edit')}
      enterDelay={100}
      {...rest}
    >
      <LinkField
        className={classes.iconButton}
        icon
        onClick={this.onClick}
      >
        <FontAwesomeIcon icon={faEdit} size="xs" />
      </LinkField>
    </Tooltip>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(EditReservationButton)
