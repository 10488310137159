import React, { Component } from 'react'
import {
  List,
  ListItem,
  Avatar,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
  withStyles,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import {
  DropTarget,
  DragSource,
  DragDropContext,
} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

const styles = {
  root: {
    flex: 1,
  },
  stopList: {
    maxHeight: 'calc(100% - 50px)',
    overflow: 'auto',
  },
  dialog: {
    minWidth: 600,
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 600,
    paddingTop: 8
  },
  menuButton: {
    display: 'grid',
    justifyItems: 'center'
  },
  buttonContainer: {
    padding: 8,
  }
}

const ItemTypes = {
  ITEM: 'itemwithdragdrop',
}

let anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
let transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const cardTarget = {
  drop() {
    //
  },
}

const itemSource = {
  beginDrag(props) {
    let { id, index } = props
    return {
      id: id,
      originalIndex: index,
    }
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id
  },
  async endDrag(props, monitor) {
    const { id, originalIndex } = monitor.getItem()
    const didDrop = monitor.didDrop()
    if (!didDrop) {
      props.onMove(id, originalIndex)
    } else {
      await props.endDrop()
    }
  }
}

const itemTarget = {
  canDrop() {
    return false
  },

  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem()
    const { id: overId, index: overIndex } = props
    if (!monitor.isOver({ shallow: true })) {
      if (draggedId !== overId) {
        props.onMove(draggedId, overIndex)
      }
    }
  },
}

class _ItemWithDragDrop extends Component {
  render() {
    const {
      index,
      stop,
      panMapTo,
      showStopMenu,
      connectDragSource,
      connectDropTarget,
      isDragging,
      companyManager,
    } = this.props
    let opacity = isDragging ? 0 : 1
    return connectDragSource(
      connectDropTarget(
        <div style={{ ...{}, opacity }} >
          <Item
            panMapTo={panMapTo}
            showStopMenu={showStopMenu}
            key={index}
            stop={stop}
            companyManager={companyManager}
            index={index}
          />
        </div>
      )
    )
  }
}

class Item extends Component {
  render() {
    const {
      index,
      stop,
      panMapTo,
      showStopMenu,
      companyManager,
    } = this.props
    return <ListItem
      key={stop.id}
      button
      onClick={() => panMapTo(stop)}
    >
      <Avatar
        aria-label={stop.name}
        title={index}
      >
        {index + 1}
      </Avatar>
      <ListItemText
        primary={stop.name}
        secondary={`${stop.distance ? stop.distance.toFixed(1) : 0}km`}
      />
      {companyManager && <ListItemSecondaryAction>
        <IconButton
          onClick={(evt) => showStopMenu(evt.target, stop, false, anchorOrigin, transformOrigin)}
          aria-label="Ref"
        >
          <FontAwesomeIcon icon={faEllipsisV} size="xs" />
        </IconButton>
      </ListItemSecondaryAction>}
    </ListItem>
  }
}

export const ItemWithDragDrop = DropTarget(
  ItemTypes.ITEM,
  itemTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(DragSource(
  ItemTypes.ITEM,
  itemSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(_ItemWithDragDrop))

class ListStopLeftMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      routeStops: []
    }
  }

  static getDerivedStateFromProps(nexProps, prev) {
    let { routeStops } = nexProps
    let currentRouteStops = prev.routeStops
    if (currentRouteStops !== routeStops) {
      currentRouteStops = routeStops
    }
    return {
      routeStops: currentRouteStops || []
    }
  }

  render() {
    const {
      classes,
      panMapTo,
      showStopMenu,
      connectDropTarget,
      findItem,
      onMove,
      endDrop,
      companyManager,
    } = this.props
    let { routeStops } = this.state
    return connectDropTarget(
      <div className={classes.root}>
        <List component="nav" dense className={classes.stopList}>
          {routeStops.map((stop, index) => (
            stop ? (
              stop.type === 'stop' ?
                <ItemWithDragDrop
                  id={stop.id}
                  panMapTo={panMapTo}
                  showStopMenu={showStopMenu}
                  key={index}
                  stop={stop}
                  index={index}
                  onMove={onMove}
                  findItem={findItem}
                  endDrop={endDrop}
                  companyManager={companyManager}
                /> : <Item
                  panMapTo={panMapTo}
                  showStopMenu={showStopMenu}
                  key={index}
                  stop={stop}
                  index={index}
                  companyManager={companyManager}
                />
            ) : null
          ))}
        </List>
      </div>
    )
  }
}

const DragDropListStop = DragDropContext(HTML5Backend)(
  DropTarget(ItemTypes.ITEM, cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }))(ListStopLeftMenu)) 


export default withStyles(styles)(DragDropListStop)
