import React from 'react'

import SvgIcon from '@material-ui/core/SvgIcon'

const AssistantDriverIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" id="svg_1" d="m17.73813,6.6325l0,-1.855a1.92894,1.1225 0 0 0 -1.66259,-1.1075l0,2.8725l-8.14108,0l0,-2.8725a1.92894,1.1225 0 0 0 -1.66259,1.1075l0,1.855a2.66787,1.5525 0 0 0 -2.55188,1.5475l0,12.27a2.67217,1.555 0 0 0 2.66357,1.55l11.24286,0a2.67217,1.555 0 0 0 2.66357,-1.55l0,-12.2725a2.66787,1.5525 0 0 0 -2.55188,-1.545zm-8.5578,13.345l-2.87408,0l0,-1.675l2.87408,0l0,1.6725l0,0.0025zm0,-2.23l-2.87408,0l0,-1.6725l2.87408,0l0,1.675l0,-0.0025zm0,-2.2275l-2.87408,0l0,-1.6725l2.87408,0l0,1.6725zm4.25742,4.4575l-2.87408,0l0,-1.675l2.87408,0l0,1.6725l0,0.0025zm0,-2.2275l-2.87408,0l0,-1.675l2.87408,0l0,1.675zm0,-2.2275l-2.87408,0l0,-1.675l2.87408,0l0,1.6725l0,0.0025zm4.25742,4.4575l-2.86979,0l0,-1.6775l2.87408,0l0,1.6725l-0.0043,0.005zm0,-2.2275l-2.86979,0l0,-1.6775l2.87408,0l0,1.675l-0.0043,0.0025zm0,-2.2275l-2.86979,0l0,-1.6775l2.87408,0l0,1.6725l-0.0043,0.005zm0,-3l-11.38893,0l0,-3.8875l11.39322,0l0,3.89l-0.0043,-0.0025z" className="cls-1"/>
    <path stroke="null" id="svg_2" d="m15.49987,2l-6.99403,0l0,4.1975l6.99403,0l0,-4.1975zm-6.13481,1.39l2.42729,0l0,0.28l-2.42729,0l0,-0.28zm0,1.4l3.06741,0l0,0.28l-3.06741,0l0,-0.28zm3.99106,0.98l-3.99106,0l0,-0.27l3.99106,0l0,0.28l0,-0.01zm0,-1.4l-3.99106,0l0,-0.28l3.99106,0l0,0.28zm0,-1.4l-3.99106,0l0,-0.28l3.99106,0l0,0.28zm1.28883,2.8025l-0.49405,0l0,-0.2725l0.48116,0l0,0.28l0.01289,-0.0075zm0,-0.7l-0.49405,0l0,-0.2825l0.48116,0l0,0.28l0.01289,0.0025zm0,-0.7l-0.49405,0l0,-0.2825l0.48116,0l0,0.28l0.01289,0.0025zm0,-0.7l-0.49405,0l0,-0.2825l0.48116,0l0,0.28l0.01289,0.0025zm0,-0.7l-0.49405,0l0,-0.2825l0.48116,0l0,0.28l0.01289,0.0025z" className="cls-1"/>
  </SvgIcon>
)

export default AssistantDriverIcon
