import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  CardActions,
  CreateButton,
  FunctionField,
  MenuItemLink,
  ReferenceField,
  translate,
} from 'react-admin'
import FormSave from './FormSave'
import { Button, withStyles } from '@material-ui/core'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import DropDownMenu from '../common/DropDownMenu'
import compose from 'recompose/compose'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import { isCompanyManager } from '../utils/permission'

const styleListAction = () => ({
  rigthLabel: {
    paddingLeft: '0.5em'
  }
})

const enhance = compose(
  translate
)

const ActionList = withStyles(styleListAction)(({ basePath, classes }) => {
  return (
    <CardActions>
      <CreateButton basePath={basePath} />
      <Button color="primary" component={Link} to="/viewblocks">
        <FontAwesomeIcon icon={faCalendarAlt} />
        <span className={classes.rigthLabel}>TIME SCHEDULER</span>
      </Button>
    </CardActions>
  )
})

const dropDownMenuItems = (translate, record) => (
  <DropDownMenu>
    <MenuItemLink
      to={`/routes/${record.id}`}
      primaryText={translate('resources.common.edit')}
    />
    <MenuItemLink
      to={`/timetablegroups?routeId=${record.id}`}
      primaryText={translate('resources.routes.timetable')}
    />
    <MenuItemLink
      to={`/timetablegroups/create?routeId=${record.id}`}
      primaryText={translate('resources.routes.create_timetable')}
    />
    <MenuItemLink
      to={`/routepatterns?filter=${encodeURI(JSON.stringify({routeId: record.id}))}`}
      primaryText={translate('resources.routes.patterns')}
    />
    <MenuItemLink
      to={`/routepatterns/create?routeId=${record.id}`}
      primaryText={translate('resources.routes.create_pattern')}
    />
  </DropDownMenu>
)

////////////////////////////////////////////////////////////////////////////////
// List
export const _RouteList = props => (
  <List
    {...props}
    actions={<ActionList />}
  >
    <Datagrid>
      <TextField source="number" label="resources.routes.number" />
      <TextField source="name" label="resources.common.name" />
      <TextField source="desc" label="resources.common.desc" />
      <FunctionField label="resources.common.type" render={(record) => {
        if (record && record.type) {
          return (
            <ReferenceField
              record={record}
              basePath="/routetypes"
              source="type"
              reference="routetypes"
              linkType={false}
            >
              <TextField source="name" />
            </ReferenceField>
          )
        }
        return ''
      }} />
      <FunctionField
        render={record => dropDownMenuItems(props.translate, record)}
      />
    </Datagrid>
  </List>
)

export const RouteList = translate(_RouteList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const RouteCreate = enhance(props => (
  <Create
    {...props}
    title={
      <EditTitle
        resource={props.resource}
        base={{ resource: 'routegroups', target: 'routeGroupId' }}
      />
    }
  >
    <FormSave isCreate={ true }/>
  </Create>
))

////////////////////////////////////////////////////////////////////////////////
// Edit

export const RouteEdit = enhance(({ permissions, ...props}) => {
  let companyManager = isCompanyManager(permissions)
  return (
    <Edit
      undoable="false"
      {...props}
      title={
        <EditTitle
          resource={props.resource}
          base={{ resource: 'routegroups', target: 'routeGroupId' }}
        />
      }
    >
      <FormSave
        action='update'
        toolbar={companyManager ? <CustomToolbar /> : null}
      />
    </Edit>
  )
})
