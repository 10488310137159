import React, { Component } from 'react'

class ImageBase64 extends Component {
  render() {
    const { value = {}, alt, width, height } = this.props
    let { src } = value
    if (!src) {
      try {
        // For input is buffer
        src = value && 'data:image/png;base64,' + new Buffer(value).toString('base64')
      } catch (e) {
        let { rawFile } = value
        // For input is blod
        if (rawFile) {
          src = value.rawFile.preview
        }
      }
    }
    return (
      <div>
        {src && <img src={src} style={{ width: width, height: height }} alt={alt} ></img>}
      </div>
    )
  }
}

export default ImageBase64
