import React, { Component } from 'react'
import { translate, addField } from 'react-admin'
import compose from 'recompose/compose'
import {
  Input,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  withStyles,
} from '@material-ui/core'

const styles = theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: '16px 0 8px 0',
    minWidth: 120,
  },
})

class SelectCustom extends Component {

  state = {}

  componentDidMount() {
    let { input, defaultValue } = this.props
    input.onChange(defaultValue)
  }

  static getDerivedStateFromProps(nextProps, prevState){
    let { input } = nextProps
    let { value } = input
    let { value: currentValue } = prevState
    if (value !== currentValue){
      return { ...prevState, value }
    }

    return { ...prevState }
  }

  onChange = (evt) => {
    let { input } = this.props
    let { value } = evt.target
    input.onChange(value)
    this.setState({ value })
  }

  render() {
    const {
      classes,
      choices,
      label,
      allowEmpty,
      optionValue,
      optionText,
      translate,
      isTranslateText,
      fullWidth,
      meta,
    } = this.props
    let { value } = this.state
    let { error } = meta
    return (
      <FormControl 
        error={Boolean(error)}
        fullWidth={fullWidth}
        className={classes.formControl}
      >
        <InputLabel htmlFor="select-custom">{label}</InputLabel>
        <Select
          value={value}
          onChange={this.onChange}
          input={<Input />}
          fullWidth
        >
          {allowEmpty && (
            <MenuItem value="">
              <em></em>
            </MenuItem>
          )}
          {choices.map((choice, key) => (
            <MenuItem key={key} value={choice[optionValue]}>
              {isTranslateText ? translate(choice[optionText]) : choice[optionText]}
            </MenuItem>
          ))}
        </Select>
        {error && <span>{error}</span>}
      </FormControl>
    )
  }
}

SelectCustom.defaultProps = {
  optionValue: 'id',
  choices: [],
  isTranslateText: true
}

const enhance = compose(
  addField,
  translate,
  withStyles(styles),
)

export default enhance(SelectCustom)
