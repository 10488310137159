import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import {
  SimpleForm,
  TextInput,
  showNotification,
  required,
  translate,
} from 'react-admin'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { validate } from './validate'
import ResetPasswordIcon from '../icons/reset-password'
import { reset } from 'redux-form'

const style = () => ({
  label: {
    paddingLeft: '0.5em'
  }
})

class ResetPasswordButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  openDialog = () => {
    this.setState({
      open: true
    })
  }

  closeDialog = () => {
    let { reset } = this.props
    reset('reset-password')
    this.setState({
      open: false
    })
  }

  save = async record => {
    let { id } = this.props
    let { showNotification, reset } = this.props
    await Provider.dataProvider('REMOTE', 'users', {
      method: 'resetPassword',
      requestMethod: 'PUT',
      data: {...record, id }
    }).then(
      res => {
        showNotification('notification.reset_password_success')
      }
    ).catch(
      error => {
        showNotification('notification.reset_password_failure', 'warning')
      }
    )
    reset('reset-password')
    this.setState({
      open: false
    })
  }

  render() {
    let { open } = this.state
    const { translate, classes } = this.props
    let extra = { fullWidth: true, resource: 'users' }
    return <>
      <Button color="primary" onClick={this.openDialog}>
        <ResetPasswordIcon />
        <span className={classes.label}>{translate('button.reset_password')}</span>
      </Button>
      <Dialog
        open={open}
        onClose={this.closeDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{translate('resources.users.page.reset_password')}</DialogTitle>
        <DialogContent>
          <SimpleForm validate={validate} resource="users" form="reset-password" save={this.save}>
            <TextInput source="newPassword" type="password" validate={required()} {...extra} />
            <TextInput source="confirmPassword" type="password" validate={required()} {...extra} />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  }
}

const enhance = compose(
  translate,
  connect(null, { showNotification, reset }),
  withStyles(style)
)

export default enhance(ResetPasswordButton)
