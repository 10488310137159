import Numeral from 'numeral'
import moment from 'moment'

export const minToHour = (min) => {
  return `${Numeral(Math.floor(min / 60)).format('00')}:${Numeral(min % 60, '00').format('00')}`
}

export const hourToMin = (hour) => {
  if (!hour) return null
  // removes whitespaces
  hour = hour.replace(/\s/g, '')
  // matches 05:50, 5:5
  let hourPattern = /(\d\d?):(\d\d?)/
  let match = hour.match(hourPattern)

  if (!match) return null
  let nHour = parseInt(match[1])
  // nHour = Math.min(23, Math.max(0, nHour))
  let nMin = parseInt(match[2])
  // nMin = Math.min(59, Math.max(0, nMin))
  return nHour * 60 + nMin
}

export const getMinFromDate = (date) => {
  date = moment(date)
  let beginOfDay = date.clone().startOf('day')
  return date.diff(beginOfDay, 'minute')
}

export const getDays = (fromDate, toDate) => {
  fromDate = fromDate && moment(fromDate)
  toDate = toDate && moment(toDate)
  if (!fromDate || !toDate) return -1
  return toDate.diff(fromDate, 'days')
}

export const textToDate = (text) => {
  if (typeof text !== 'string') return null
  if (text === 'now') return moment()
  if (text === 'today') return moment().startOf('day')
  let match = text.match(/((begin|end)\s+(.*)\s+of\s+)?([+-]\d+)\s+(minute|hour|day|month|year)/)
  if (!match) return null 
  let time = moment()
  time = time.add(Number(match[4]), match[5])
  if (match[1]) {
    if (match[2] === 'begin') {
      time = time.startOf(match[3])
    }
    if (match[2] === 'end') {
      time = time.endOf(match[3])
    }
  }

  return time
}
