import React, { Fragment, Component } from 'react'
import {
  Card,
  CardHeader,
  Button,
  MenuItem,
  ListItemText,
  Popover,
  Dialog,
  DialogTitle,
  CardActions,
} from '@material-ui/core'
import {
  ReferenceField,
  ReferenceManyField,
  SimpleForm,
  translate,
  ReferenceInput,
  refreshView,
  showNotification,
} from 'react-admin'
import Snackbar from '../common/Snackbar'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { Provider } from '../provider'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'

export const typeMapping = {
  COM: { label: 'resources.companies.name', resource: 'companies', source: 'companyId' },
  AGE: { label: 'resources.agencies.name', resource: 'agencies', source: 'agencyId' },
  SUP: { label: 'resources.suppliers.name', resource: 'agencies', source: 'supplierId', filter: { type: '30SUP' } },
}

export function getRecord(data, ids) {
  if (ids.length < 2) {
    let id = ids[0]
    return data[id]
  }
  for (let i = 0; i < ids.length; i++) {
    let { type } = data[ids[i]]
    if (type === 'COM') {
      return data[ids[i]]
    }
  }
}

const CardComponent = translate(({ record = {}, principal, userId, translate, unMapping }) => {
  record = record !== null ? record : {}
  return <Card style={{ marginBottom: '1em' }}>
    <CardHeader
      title={record.name}
      subheader={translate(principal, { smart_count: 1 })}
    />
    <CardActions>
      <Button color="primary" variant="contained" onClick={() => unMapping(userId)}>
        {translate('button.unassign')}
      </Button>
    </CardActions>
  </Card>
})

const action = (onClick, labelButtonAction) => {
  return [
    <Button
      key={0}
      onClick={(event) => onClick(event)}
      style={{ color: '#ffff' }}
    >
      {labelButtonAction}
    </Button>
  ]
}

const Actions = ({ data = {}, ids = [], handleLink }) => {
  return ids.map((id, idx) => (
    <MenuItem
      key={idx}
      onClick={() => handleLink(id)}
    >
      <ListItemText>{data[id].name}</ListItemText>
    </MenuItem>
  ))
}

const ActionMenu = ({ open, onClose, anchorEl, handleLink }) => {
  return <Popover
    anchorEl={anchorEl}
    open={open}
    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    onClose={onClose}
  >
    <ReferenceManyField
      basePath="/accounttypes"
      resource="accounttypes"
      reference="accounttypes"
      record={{}}
      target=""
      filter={{ id: { neq: 'PLA' } }}
    >
      <Actions handleLink={handleLink} />
    </ReferenceManyField>
  </Popover>
}

class RefForm extends Component {

  render() {
    let { type, mapping } = this.props
    if (!type) return null
    let { resource, label, source, filter = {} } = typeMapping[type]
    //let agencyFilter = resource === 'agencies' ? { '../ignore': { agencyIsCompany: true }} : {}
    return resource && <SimpleForm
      resource="users"
      form="add-ref-form"
      save={record => mapping(record)}
    >
      <ReferenceInput
        label={label}
        resource="users"
        basePath={`/${resource}`}
        reference={resource}
        record={{}}
        source={source}
        filter={filter}
        perPage={1000}
        fullWidth
      >
        <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
      </ReferenceInput>
    </SimpleForm>
  }
}

const RefFormDialog = translate(({ accountId, type, open, translate, onClose, mapping }) => {
  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle>{translate('button.add_reference')}</DialogTitle>
    <RefForm type={type} accountId={accountId} mapping={mapping} />
  </Dialog>
})

class RefCard extends Component {

  state = {
    actionMenu: {
      open: false,
      anchorEl: null,
    },
    dialog: {
      open: false,
      type: '',
    }
  }

  openMenu = (event) => {
    this.setState({
      actionMenu: {
        open: true,
        anchorEl: event.target,
      }
    })
  }

  handleCloseMenu = () => {
    this.setState({
      actionMenu: {
        open: false,
        anchorEl: null,
      }
    })
  }

  openDialog = (type) => {
    this.setState({
      dialog: {
        open: true,
        type,
      }
    })
    this.handleCloseMenu()
  }

  handleCloseDialog = () => {
    this.setState({
      dialog: {
        open: false,
        type: '',
      }
    })
  }

  unMapping = (userId, data = { companyId: null, agencyId: null }) => {
    let { refreshView, showNotification } = this.props
    Provider.dataProvider('REMOTE', 'Users', {
      method: userId,
      requestMethod: 'PATCH',
      data,
    }).then(() => {
      refreshView()
      showNotification('notification.remove_principal_success_user')
    }).catch((e) => {
      refreshView()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  mapping = (record) => {
    let { reset, refreshView, showNotification, record: user } = this.props
    let { id: userId } = user
    let { dialog } = this.state
    let { type } = dialog
    Provider.dataProvider('REMOTE', 'users', {
      method: userId,
      requestMethod: 'PATCH',
      data: { ...record, type },
    }).then(() => {
      reset('add-ref-form')
      this.handleCloseDialog()
      refreshView()
      showNotification('notification.add_principal_success')
    }).catch((e) => {
      reset('add-ref-form')
      this.handleCloseDialog()
      refreshView()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  render() {
    let { translate, record = {} } = this.props
    let { actionMenu, dialog } = this.state
    let { companyId, agencyId, id: userId } = record
    let resource, source, principal
    let filter = {}
    if (companyId) {
      resource = 'companies'
      source = 'companyId'
      principal = 'resources.companies.name' 
    } else if (agencyId) {
      resource = 'agencies'
      source= 'agencyId'
      principal = 'resources.agencies.name'
    }
    return (
      <Fragment>
        {resource ? <ReferenceField
          basePath={`/${resource}`}
          resource={resource}
          reference={resource}
          record={record}
          source={source}
          perPage={1000}
          linkType={false}
          filter={filter}
          allowEmpty
        >
          <CardComponent principal={principal} userId={userId} unMapping={this.unMapping} />
        </ReferenceField> : <Fragment>
          <Snackbar
            variant="warning"
            message={translate('notification.no_reference_user')}
            onClick={this.openMenu}
            labelButtonAction={translate('button.add_reference')}
            action={action}
          />
          <ActionMenu
            open={actionMenu.open}
            onClose={this.handleCloseMenu}
            anchorEl={actionMenu.anchorEl}
            handleLink={this.openDialog}
          />
        </Fragment>}
        {companyId && record && record.companyId && record.supplierId && <ReferenceField
          basePath="/"
          resource="agencies"
          reference="agencies"
          record={record}
          source="supplierId"
          linkType={false}
          allowEmpty
        >
          <CardComponent
            principal={'resources.suppliers.name'}
            userId={userId}
            unMapping={(userId) => this.unMapping(userId, { supplierId: null })}
          />
        </ReferenceField>}
        {companyId && record && record.companyId && !record.supplierId && <Fragment>
          <Snackbar
            variant="info"
            message={translate('notification.add_supplier')}
            onClick={() => this.openDialog('SUP')}
            labelButtonAction={translate('button.add_supplier')}
            fullWidth
            action={action}
          />
        </Fragment>}
        <RefFormDialog
          open={dialog.open}
          type={dialog.type}
          onClose={this.handleCloseDialog}
          mapping={this.mapping}
        />
      </Fragment>
    )
  }
}

const enhance = compose(translate, connect(null, { reset, refreshView, showNotification }))

export default enhance(RefCard)
