import React,  { Component } from 'react'
import {
  List,
  TextField,
  ReferenceField,
  Datagrid,
  translate,
  Filter,
  ReferenceInput, 
  FunctionField
} from 'react-admin'
import {
  IconButton,
  withStyles,
  Dialog,
  DialogTitle
} from '@material-ui/core' 
import compose from 'recompose/compose'
import moment from 'moment'
import { Provider } from '../provider'
import MaintainCheckList from './MaintainCheckList'
import { faEye, faCalendar, faBus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ServiceReminderFormSave from '../calendar/ServiceReminderFormSave'
import {
  FuzzySelectInput,
  defaultFuzzySearch,
} from '../common/react-fuzzy-picker'
// List
const MaintainListFilter = ({ translate, ...props }) => (
  <Filter {...props}>
    <ReferenceInput
      basePath="/vehicles"
      reference="vehicles"
      source="vehicleId"
      resource="vehicles"
      filter={{ '../fields': ['id', 'plate'] }}
      fullWidth
      perPage={1000}
      alwaysOn
    >
      <FuzzySelectInput renderItem={item => item.plate} {...defaultFuzzySearch({ name: 'plate' })} />
    </ReferenceInput>
  </Filter>
)

export const DialogNewServiceReminder = ({
  open,
  handleClose,
  record,
  isCreate,
  principalType,
  defaultDueAt,
  done,
  error,
  title,
}) => {
  const tt = title || 'Tạo lời nhắc'
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
    >
      <DialogTitle>{tt}</DialogTitle>
      <ServiceReminderFormSave
        record={record}
        isCreate={isCreate}
        principalType={principalType}
        defaultDueAt={defaultDueAt}
        done={done}
        error={error}
      />
    </Dialog>
  )
}
class _MaintainList extends Component {
  state = {
    nextEvent: {},
    version: 0,
    selectedVehicle: null,
    isViewChecklist: false,
    openDialogNewServiceReminder: false,
    defaultDueAt: new Date()
  }
  getNextEvent = async (vehicleId, recordId) => {
    let nextTask = await Provider.dataProvider('REMOTE', 'servicereminders', {
      method: 'vehicleNextMaintain',
      requestMethod: 'GET',
      data: {vehicleId}
    })
    if (nextTask.data.data.data.length > 0) {
      const data = nextTask.data.data.data      
      const listItem = data.map((item, idx) => {
        return <li key= {idx}>
          <span style={{color: 'red'}}>Ngày {`${moment(item.dueAt).format('DD/MM/YYYY')}`}</span>
          <span> - </span>
          <span style={{color:'green'}}>{`${item.name}`}</span>
        </li>
      })

      let { nextEvent } = this.state
      nextEvent = { ...nextEvent, [recordId]: listItem }
      let { version } = this.state
      version++
      this.setState({ nextEvent, version })
    }
  }

  renderNextEvent = record => {
    let txt = this.state.nextEvent[record.id] || ''
    if (!txt) {
      this.getNextEvent(record.vehicleId, record.id)
    }
    return <ul>{txt}</ul>
  }
  handleClose = () => {
    this.setState({isViewChecklist: false})
  }
  handleCloseDialogNewServiceReminder = () => {
    this.setState({
      openDialogNewServiceReminder: false,
    })
  }
  doneForCreate = () => {
    // this.loadData()
    this.setState({
      openMenuNewServiceReminder: false,
      openDialogNewServiceReminder: false,
      currentAnchor: null,
    })
  }
  handleOpenForm = () => {    
    this.setState({
      openDialogNewServiceReminder: true,
      principalType: {
        principalResource: 'maintaingroups',
        fieldShow: 'name',
        principal: 'MaintainGroup',
        label: 'Biển số xe',
        hasExtras: false,
        icon: <FontAwesomeIcon icon={faBus} />
      },
      defaultDueAt: new Date()
    })
  }
  errorForCreate = () => {
    this.handleCloseDialogNewServiceReminder()
  }
  render() {
    const {classes, translate, ...props} = this.props
    let { version, selectedVehicle, openDialogNewServiceReminder, defaultDueAt, principalType } = this.state    
    return(
      <>
      <List 
        {...props}
        filters={<MaintainListFilter />}
        bulkActionButtons={false}
      >
        <Datagrid >
          <ReferenceField
            source="vehicleId"
            reference="vehicles"
          >
            <TextField source="plate" />
          </ReferenceField>
          
          <FunctionField label="Lịch chi tiết" key={version} render={record => {
            return (<IconButton
              className={classes.deleteButton}
              variant='contained'
              size="small"
              onClick={(e) => { 
                this.setState({selectedVehicle: record.vehicleId, isViewChecklist: true})
              }}
            >
              <FontAwesomeIcon icon={faEye} size="xs" />
            </IconButton>)
          }} />
          {/* <FunctionField label="Hạng mục" key={version} render={this.renderNextEvent} /> */}
          <FunctionField label="Đặt lịch hẹn bảo dưỡng" key={version} render={record => {
            return (<IconButton
              className={classes.deleteButton}
              variant='contained'
              size="small"
              onClick={(e) => { 
                this.handleOpenForm()
              }}
            >
              <FontAwesomeIcon icon={faCalendar} size="xs" />
            </IconButton>)
          }} />
        </Datagrid>
        
      </List>
      <MaintainCheckList vehicleId={selectedVehicle} handleClose={this.handleClose} open={this.state.isViewChecklist}/>
      <DialogNewServiceReminder
        open={openDialogNewServiceReminder}
        handleClose={this.handleCloseDialogNewServiceReminder}
        isCreate={true}
        principalType={principalType}
        defaultDueAt={defaultDueAt}
        done={this.doneForCreate}
        error={this.errorForCreate}
        title="Đặt lịch nhắc bảo dưỡng"
      />
      </>)
  }
}
const styles = {

}
const enhance = compose( withStyles(styles), translate)
export default enhance(_MaintainList)
