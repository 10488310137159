import React, { Component } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import ImageBase64 from '../configuration/ImageBase64'
import EditSettingButton from './EditSettingButton'
import { withStyles } from '@material-ui/core'

let Datatype = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  JSON: 'JSON',
}

const styles = {
  root: {
    padding: 0,

  }
}

const SettingListFilter = props => {
  return <Filter {...props}>
    <TextInput source="code" alwaysOn />
    <ReferenceInput
      source="group"
      reference="settinggroups"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
}


class Setting extends Component {

  render() {
    let { match, classes, location, record, ...props } = this.props
    return <List
      match={match}
      location={location}
      basePath="/settings"
      resource="settings"
      bulkActionButtons={false}
      className={classes.root}
      filters={<SettingListFilter />}
      {...props}
    >
      <Datagrid>
        <TextField source="code" />
        <TextField source="name" />
        <ReferenceField
          reference="settinggroups"
          source="group"
          linkType={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="desc" />
        <FunctionField
          source="value"
          render={record => {
            let { dataType, value: component, bValue, code } = record
            if (dataType === Datatype.IMAGE) {
              let size = { width: 100, height: 100 }
              let value = (bValue && bValue !== null) ? bValue : {}
              if (dataType === Datatype.IMAGE) {
                component = <ImageBase64
                  value={value}
                  alt={code}
                  label="menu.payment.fields.image"
                  {...size}
                />
              }
            } else {
              component = record.value && record.value.length > 50 ? `${record.value.substring(0, 50)}...${record.value[record.value.length - 1]}` : record.value
            }
            return component
          }}
        />
        <FunctionField render={record => <EditSettingButton record={record} />} />
      </Datagrid>
    </List>
  }
}

export default withStyles(styles)(Setting)
