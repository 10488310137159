import isEmpty from 'lodash/isEmpty'
import { Provider } from '../provider'

export function validate(values, props) {
  let errors = {}
  let { translate } = props
  let name = (values && values.name) && values.name.trim()
  if (!name || name.length === 0) {
    errors.name = translate('error_messages.required.stop.name')
  }
  // let code = (values && values.code) && values.code.trim()
  // if (!code || code.length == 0) {
  //   errors.code = translate('error_messages.required.stop.code')
  // }
  return errors
}

export async function asyncValidate(values, dispatcher, { translate }) {
  let errors = {}
  let { code } = values
  let data = {
    filter: {
      where: { code }
    }
  }
  try {
    let res = await Provider.dataProvider('REMOTE', 'stops', {
      method: 'findOne',
      requestMethod: 'GET',
      data
    })
    let stop = res.data
    if (stop) {
      errors.code = translate('error_messages.required.stop.code_unique', { code })
    }
  } catch (e) {
    console.error( e)
  }
  if (!isEmpty(errors)) {
    return Promise.reject(errors)
  }
  return Promise.resolve()
}
