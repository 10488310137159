import React, { Component } from 'react'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import { translate } from 'react-admin'

import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { openContextMenu, networkUpdate } from './actions'
import { withStyles } from '@material-ui/core/styles'
import Delete from '@material-ui/icons/Delete'
import Edit from '@material-ui/icons/Edit'
import Add from '@material-ui/icons/Add'
import Check from '@material-ui/icons/Check'
import Close from '@material-ui/icons/Close'
import Link from '@material-ui/icons/Link'
import LinkOff from '@material-ui/icons/LinkOff'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import clipboard from '../utils/clipboard'

import { resourceRequest, invokeAction } from '../action'

const styles = theme => ({
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
  },
  primary: {},
  icon: {},
})

class LocationMenuItem extends Component {

  onCopy(v) {
    let { data } = this.props
    let location = (data && data.location) ? `${data.location.lat}, ${data.location.lon}` : ''
    clipboard(location)
  }

  render() {
    let { data, classes, translate } = this.props
    let location = (data && data.location) ? `${data.location.lat}, ${data.location.lon}` : ''
    return (
      <MenuItem>
        <ListItemIcon>
          {translate('resources.common.location')}
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.primary }} inset primary={ location } />
        <div className={'clipboard'} onClick={ v => this.onCopy(v) }>
          <FileCopyOutlined />
        </div>
      </MenuItem>
    )
  }
}

class NetworkContextMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {anchorEl: null, value: ''}
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    let { openContextMenu } = this.props
    openContextMenu({ anchorEl: null, anchorPosition: null, data: null, type: null })
  }

  openStopEdit() {
    const { anchorEl, anchorPosition, anchorReference, data } = this.props
    let { openContextMenu } = this.props
    openContextMenu({ anchorEl, anchorPosition, anchorReference, data, type: 'stop-edit' })
  }

  openStopNew() {
    const { anchorEl, anchorPosition, anchorReference, data } = this.props
    let { openContextMenu } = this.props
    data.stop = { name: '' }
    openContextMenu({ anchorEl, anchorPosition, anchorReference, data, type: 'stop-new' })
  }

  onTextFieldChanged(evt) {
    this.setState({[evt.target.name]: evt.target.value})
  }

  onKeyUp(evt) {
    const keyCode = evt.keyCode
    const key = evt.key
    if (keyCode === 13) {
      this.onStopSubmit(evt)
    }
    if (key === '-' || key === '=' || key === '+') {
      evt.preventDefault()
    }
  }

  async onStopSubmit(evt) {
    const { data } = this.props
    let { route, stop } = data
    stop = stop || {}
    stop.code = this.state.code || stop.code
    stop.name = this.state.name || stop.name
    stop.lat = data.location.lat - 0
    stop.lon = data.location.lon - 0

    if (stop.id) {
      //update existing stop
      await this.updateExstingStop(stop)
    } else {
      //create new stop
      await this.createNewStop(route.id, stop)
    }
    this.handleClose()
    evt.preventDefault()

    this.dispatchUpdate()
  }

  async updateExstingStop(stop) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'stops',
      fetch: 'UPDATE',
      payload: {
        id: stop.id, 
        data: stop
      }
    }
    await invokeAction(resourceRequest, params)
  }

  async createNewStop(routeId, stop) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'stops',
      fetch: 'CREATE',
      payload: {
        data: stop
      }
    }
    let resp = await invokeAction(resourceRequest, params)
    let stopId = resp.id
    await this.attachToRoute(routeId, stopId)
  }

  async attachToRoute(id, stopId) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'routes',
      payload: {
        method: `${id}/stop/${stopId}`,
        requestMethod: 'POST'
      }
    }
    await invokeAction(resourceRequest, params)
  }

  async onStopDelete() {
    let { data } = this.props
    let { route, stop } = data
    let id = route.id
    let stopId = stop.id
    await this.detachFromRoute(id, stopId)
    await this.removeStop(stopId)
    this.handleClose()

    this.dispatchUpdate()
  }

  async detachFromRoute(id, stopId) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'routes',
      payload: {
        id, stopId,
        method: `${id}/stop/${stopId}`,
        requestMethod: 'DELETE'
      }
    }
    await invokeAction(resourceRequest, params)
  }

  async removeStop(stopId) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'stops',
      fetch: 'DELETE',
      payload: {
        id: stopId
      }
    }
    await invokeAction(resourceRequest, params)
  }

  async onAttachStop() {
    let { data } = this.props
    let { planningRoute, stop } = data
    let id = planningRoute.id
    let stopId = stop.id
    await this.attachToRoute(id, stopId)
    this.handleClose()

    this.dispatchUpdate()
  }

  async onDetachStop() {
    let { data } = this.props
    let { route, stop } = data
    let id = route.id
    let stopId = stop.id
    await this.detachFromRoute(id, stopId)
    this.handleClose()

    this.dispatchUpdate()
  }

  dispatchUpdate() {
    let { networkUpdate } = this.props
    let version = {
      ts: new Date().getTime()
    }
    networkUpdate(version)
  }

  renderLocation() {
    const { classes, translate, anchorEl, anchorPosition, anchorReference, data } = this.props
    let route = (data && data.route) ? data.route : {}
    const open = Boolean(anchorEl) || Boolean(anchorPosition)
    return (
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        open={open}
        onClose={this.handleClose}
      >
        <LocationMenuItem {...this.props} />
        <MenuItem>
          <ListItemIcon>
            {translate('resources.routes.name', { smartcont: 2 })}
          </ListItemIcon>
          <Avatar aria-label={ route.number } title={ route.number } style={{ background: route.color, color: '#fff'}}>
            { route.number }
          </Avatar>
        </MenuItem>
        <Divider /> 
        <MenuItem>
          <ListItemIcon>
            {translate('resources.stops.create_new_stop')}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={''} />
          <IconButton color="secondary" onClick={(evt)=> this.openStopNew(evt)}>
            <Add />
          </IconButton>
        </MenuItem>
      </Menu>
    )
  }

  renderStopAssigned() {
    const { classes, translate, anchorEl, anchorPosition, anchorReference, data } = this.props
    let route = (data && data.route) ? data.route : {}
    let stop = (data && data.stop) ? data.stop : {}
    const open = Boolean(anchorEl) || Boolean(anchorPosition)
    return (
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        open={open}
        onClose={this.handleClose}
      >
        <LocationMenuItem {...this.props} />
        <MenuItem>
          <ListItemIcon>
            {translate('resources.stops.name')}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.name } />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {translate('resources.stops.code')}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.code } />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {translate('resources.routes.name')}
          </ListItemIcon>
          <Avatar aria-label={ route.number } title={ route.number } style={{ background: route.color, color: '#fff'}}>
            { route.number }
          </Avatar>
        </MenuItem>
        <Divider /> 
        <MenuItem>
          <ListItemIcon>
            {translate('resources.common.id')}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.id } />
          <ListItemIcon>
            <IconButton color="secondary" onClick={(evt)=> this.startStop(evt)} >
              <LinkOff />
            </IconButton>
            <IconButton color="secondary" onClick={(evt)=> this.endStop(evt)} >
              <LinkOff />
            </IconButton>
            <IconButton color="secondary" onClick={(evt)=> this.onDetachStop(evt)} >
              <LinkOff />
            </IconButton>
            <IconButton color="secondary" onClick={(evt)=> this.openStopEdit(evt)} >
              <Edit />
            </IconButton>
            <IconButton onClick={(evt)=> this.onStopDelete(evt)}>
              <Delete />
            </IconButton>
          </ListItemIcon>
        </MenuItem>
      </Menu>
    )
  }

  renderStop() {
    const { classes, translate, anchorEl, anchorPosition, anchorReference, data } = this.props
    let route = (data && data.route) ? data.route : {}
    let stop = (data && data.stop) ? data.stop : {}
    const open = Boolean(anchorEl) || Boolean(anchorPosition)
    return (
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        anchorPosition={anchorPosition}
        anchorReference={anchorReference}
        open={open}
        onClose={this.handleClose}
      >
        <LocationMenuItem {...this.props} />
        <MenuItem>
          <ListItemIcon>
            {translate('resources.stops.name', { smartcont: 2 })}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.name } />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {translate('resources.stops.code')}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.code } />
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            {translate('resources.routes.name', { smartcont: 2 })}
          </ListItemIcon>
          <Avatar aria-label={ route.number } title={ route.number } style={{ background: route.color, color: '#fff'}}>
            { route.number }
          </Avatar>
        </MenuItem>
        <Divider /> 
        <MenuItem>
          <ListItemIcon>
            {translate('resources.common.id')}
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.id } />
          <IconButton color="secondary" onClick={(evt)=> this.onAttachStop(evt)}>
            <Link />
          </IconButton>
          <ListItemIcon>
            <IconButton color="secondary" onClick={(evt)=> this.openStopEdit(evt)} >
              <Edit />
            </IconButton>
            <IconButton onClick={(evt)=> this.onStopDelete(evt)}>
              <Delete />
            </IconButton>
          </ListItemIcon>
        </MenuItem>
      </Menu>
    )
  }

  renderStopEdit() {
    const { classes, translate, anchorEl, anchorPosition, anchorReference, data } = this.props
    let stop = (data && data.stop) ? data.stop : {}
    const open = Boolean(anchorEl) || Boolean(anchorPosition)
    return (
      <form onSubmit={this.onSubmit}>
        <Menu
          id="network-context-menu"
          anchorEl={anchorEl}
          anchorPosition={anchorPosition}
          anchorReference={anchorReference}
          open={open}
          onClose={this.handleClose}
        >
          <LocationMenuItem {...this.props} />
          <MenuItem>
            <ListItemIcon>
              {translate('resources.stops.name')}
            </ListItemIcon>
            <TextField fullWidth name={'name'} defaultValue={stop.name} 
              onChange={(evt)=> this.onTextFieldChanged(evt)} 
              onKeyUp={(evt)=> this.onKeyUp(evt)}/>
          </MenuItem>
          <MenuItem>
            <ListItemIcon>
              {translate('resources.stops.code')}
            </ListItemIcon>
            <TextField fullWidth name={'code'} defaultValue={stop.code} 
              onChange={(evt)=> this.onTextFieldChanged(evt)}
              onKeyUp={(evt)=> this.onKeyUp(evt)}/>
          </MenuItem>
          <Divider /> 
          <MenuItem>
            <ListItemIcon>
              {translate('resources.common.id')}
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.primary }} inset primary={ stop.id } />
            <ListItemIcon>
              <IconButton color="primary" onClick={this.handleClose}>
                <Close />
              </IconButton>
              <IconButton color="secondary" onClick={(evt)=> this.onStopSubmit(evt)}>
                <Check />
              </IconButton>
            </ListItemIcon>
          </MenuItem>
        </Menu>
      </form>
    )
  }

  render() {
    const { type } = this.props
    if ('stop-assigned' === type) {
      return this.renderStopAssigned()
    } else if ('stop' === type) {
      return this.renderStop()
    } else if ('stop-edit' === type || 'stop-new' === type) {
      return this.renderStopEdit()
    }
    return this.renderLocation()
  }
}

const enhance = compose(
  connect((state) => {
    let { anchorEl, anchorPosition, anchorReference, data, type } = state.network
    return { anchorEl, anchorPosition, anchorReference, data, type }
  }, { openContextMenu, resourceRequest, networkUpdate }),
  withStyles(styles), translate,
)

export default enhance(NetworkContextMenu)
