import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'
import { Provider } from '../provider'
import CancelIcon from '@material-ui/icons/Cancel'
import { translate, refreshView, showNotification } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { connect } from 'react-redux'
import ButtonWithLoading from '../common/ButtonWithLoading'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
    color: '',
  }
}

const cancelConfirmDialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  titleText: {
    color: 'white',
  },
}

const _CancelConfirmDialog = ({
  open,
  onClose,
  onDone,
  translate,
  chargeId,
  classes,
  isLoading,
}) => {
  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle className={classes.title}>
      <span className={classes.titleText}>{translate('resources.common.confirm_cancel_charge_title', { chargeId })}</span>
    </DialogTitle>
    <DialogContent>
      <span>{translate('resources.common.confirm_cancel_charge_content')}</span>
    </DialogContent>
    <DialogActions>
      <ButtonWithLoading
        isLoading={isLoading}
        onClick={onDone}
        variant="contained"
        color="primary"
      >
        {translate('button.continue')}
      </ButtonWithLoading>
      <Button onClick={onClose}>
        {translate('button.close')}
      </Button>
    </DialogActions>
  </Dialog>
}

const enhanceCancelConfirmDialog = compose(translate, withStyles(cancelConfirmDialogStyle))
const CancelConfirmDialog = enhanceCancelConfirmDialog(_CancelConfirmDialog)

class CancelChargeButton extends Component {

  state = {
    open: false,
    isLoading: false,
  }

  startLoading = () => {
    this.setState({ isLoading: true })
  }

  endLoading = () => {
    this.setState({ isLoading: false })
  }

  cancel = () => {
    let { id, closeMenu, onDone, data, showNotification, refreshView } = this.props
    this.startLoading()

    Provider.dataProvider('REMOTE', 'charges', {
      method: `${id}/cancel`,
      requestMethod: 'POST',
    }).then(
      (res) => {
        if (res && res.data) {
          this.closeConfirm()
          closeMenu && closeMenu()
          const { result } = res.data
          if (result) {
            refreshView()
            onDone({ ...data, status: '20CANCEL' })
          } else {
            showNotification('notification.cancel_charge_fail', 'warning')
          }
        }

      }
    ).catch(e => {
      console.log('Error', e.message)
    }).finally(
      () => {
        this.endLoading()
      }
    )
  }

  openConfirm = () => {
    this.setState({
      open: true,
    })
  }

  closeConfirm = () => {
    this.setState({
      open: false,
    })
  }

  renderComponent = () => {
    let { classes, translate, type, disabled, button, color, ...props } = this.props
    let style = { color: !disabled && '#fb404b', border: '1px solid #fb404b' }
    switch (type) {
      case 'button':
        return <Button
          style={{ backgroundColor: !disabled && '#fb404b', color:  !disabled && '#fff' }}
          variant="contained"
          onClick={this.openConfirm}
          className={classes.button}
          disabled={disabled}
          {...props}
        >
          <span>
            <span>{translate('button.cancel')}</span>
          </span>
        </Button>
      case 'menuItem':
        return <MenuItem
          style={style}
          onClick={this.openConfirm}
          disabled={disabled}
        >
          <ListItemIcon style={style}>
            <CancelIcon fontSize="small" />
          </ListItemIcon>
          <span>{translate('button.cancel')}</span>
        </MenuItem>
      default:
        return <Tooltip
          title={translate('button.cancel')}
          enterDelay={100}
        >
          <LinkField
            style={style}
            className={classes.iconButton}
            icon
            color={color}
            disabled={disabled}
            onClick={this.openConfirm}
            {...props}
          >
            <CancelIcon fontSize="small" />
          </LinkField>
        </Tooltip>
    }
  }
  render() {
    let { id } = this.props
    let { open, isLoading } = this.state
    return <Fragment>
      {this.renderComponent()}
      {open && <CancelConfirmDialog
        chargeId={id}
        open={open}
        isLoading={isLoading}
        onClose={this.closeConfirm}
        onDone={this.cancel}
      />}
    </Fragment>
  }
}

const enhance = compose(withStyles(styles), translate, connect(null, { refreshView, showNotification }))
export default enhance(CancelChargeButton)
