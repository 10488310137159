import _i18n from './i18n'
import _data from './data'
import _auth from './auth'
import uploadFile from './upload'

const types = ['REMOTE', 'CREATE', 'UPDATE']
const resources = {
  drivers: {
    fields: [
      {
        name: 'avatar',
        isMulti: false
      }
    ]
  },
  vehicles: {
    fields: [
      {
        name: 'images',
        isMulti: true
      }
    ]
  },
  licences: {
    fields: [
      {
        name: 'images',
        isMulti: true
      }
    ]
  },
  configurations: {
    fields: [
      {
        name: 'bValue',
        isMulti: false
      }
    ]
  },
  settings: {
    fields: [
      {
        name: 'bValue',
        isMulti: false
      }
    ]
  },
  products: {
    fields: [
      {
        name: 'images',
        isMulti: true
      }
    ]
  },
  ticketlayouts: {
    fields: [
      {
        name: 'background',
        isMulti: false 
      }
    ]
  },
}

const dataProvider =  uploadFile({ types, resources })(_data)

export const Provider = {
  i18nProvider: _i18n,
  dataProvider: dataProvider,
  authProvider: _auth
}
