import React, { Fragment, Component } from 'react'
import compose from 'recompose/compose'
import {
  withStyles,
  Grid,
  ClickAwayListener,
  SwipeableDrawer,
  Chip,
} from '@material-ui/core'
import {
  translate,
  ReferenceArrayField,
  Datagrid,
  FunctionField,
  TextField,
  ReferenceManyField,
} from 'react-admin'
import PeriodSummary from './PeriodSummary'
import { getColor } from '../utils/color'
import {
  faBus,
  faShuttleVan,
  faMoneyBillWave,
  faClock,
  faUser,
  faUserCircle,
} from '@fortawesome/free-solid-svg-icons'
import LinkField from './../common/LinkField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import _ from 'lodash'
import { formatCurrency } from '../utils/formatUtil'

const styles = (theme) => {
  let { unit } = theme.spacing
  return {
    swipeContainer: {
      width: 400,
    },
    gridRoot: {
      marginLeft: -2 * unit,
      marginRight: -2 * unit,
    },
  }
}

const LicenceClass = ({ data, ids }) => {
  return !_.isEmpty(ids) && <Chip label={data[ids[0]].licenceClass} />
}

const DriverGrid = translate(({ classes, ids }) => {
  return (
    <ReferenceArrayField
      record={{ ids }}
      basePath="/drivers"
      resource="drivers"
      reference="drivers"
      source="ids">
      <Datagrid>
        <FunctionField
          source="driverId"
          render={record => {
            return <Fragment>
              <LinkField
                classes={classes}
                path={`/drivers/${record.id}`}>
                <FontAwesomeIcon
                  style={{ verticalAlign: 'middle' }}
                  icon={faUserCircle}
                  size="2x"
                  color={getColor(record.id)}
                />
                <span style={{ verticalAlign: 'middle' }}>
                  &nbsp;
                  {record.fullName}
                </span>
              </LinkField>
            </Fragment>
          }}
        />

        <TextField source="phone" />
        <ReferenceManyField
          basePath="/licencedrivers"
          resource="licencedrivers"
          reference="licencedrivers"
          target="driverId"
          sort={{ field: 'createdAt', order: 'DESC' }}
          label={translate('resources.drivers.fields.licenceClass')}
        >
          <LicenceClass />
        </ReferenceManyField>
      </Datagrid>
    </ReferenceArrayField>
  )
})

const VehicleGrid = ({ classes, ids }) => {
  return <ReferenceArrayField
    record={{ ids }}
    basePath="/vehicles"
    resource="vehicles"
    reference="vehicles"
    source="ids">
    <Datagrid>
      <FunctionField
        source="vehicleId"
        render={record => {
          return <Fragment>
            <LinkField
              classes={classes}
              path={`/vehicles/${record.id}`}>
              <FontAwesomeIcon
                style={{ verticalAlign: 'middle' }}
                icon={faBus}
                size="2x"
                color={getColor(record.id)}
              />
              <span style={{ verticalAlign: 'middle' }}>
                &nbsp;
                {record.plate}
              </span>
            </LinkField>
          </Fragment>
        }}
      />
      <TextField source="name" />
    </Datagrid>
  </ReferenceArrayField>
}

class GeneralReport extends Component {

  constructor(props) {
    super(props)
    this.state = {
      swipeState: false,
      drawerView: 'driver'
    }
  }

  toggleDrawer = (open) => {
    this.setState({ swipeState: open })
  }

  render() {
    let { classes, report, translate } = this.props
    let {
      totalRevenue = 0,
      totalTrip = 0,
      driver = {},
      vehicle = {},
      currentRunningTrip = 0,
    } = report
    let { drawerView } = this.state

    return (
      <div className={classes.gridRoot}>
        <Grid container spacing={0}>
          <Grid item xs>
            <PeriodSummary
              title={translate('resources.reports.revenue')}
              bgColor={getColor(1)}
              icon={faMoneyBillWave}
              size="3x"
              value={(totalRevenue && totalRevenue !== null) ? formatCurrency(totalRevenue) : '0đ'}
            />
          </Grid>

          <Grid item xs>
            <PeriodSummary
              title={translate('resources.reports.totalTrip')}
              bgColor={getColor(2)}
              icon={faShuttleVan}
              size="3x"
              value={totalTrip}
            />
          </Grid>

          <Grid item xs>
            <PeriodSummary
              title={translate('resources.reports.totalVehicle')}
              bgColor={getColor(3)}
              icon={faBus}
              size="3x"
              onClick={() => {
                this.setState({ drawerView: 'vehicle' })
                this.toggleDrawer(true)
              }}
              value={vehicle && vehicle.total ? vehicle.total : 0}
            />
          </Grid>

          <Grid item xs>
            <PeriodSummary
              title={translate('resources.reports.totalDriver')}
              bgColor={getColor(4)}
              icon={faUser}
              size="3x"
              onClick={() => {
                this.setState({ drawerView: 'driver' })
                this.toggleDrawer(true)
              }}
              value={driver && driver.total ? driver.total : 0}
            />
          </Grid>

          <Grid item xs>
            <PeriodSummary
              title={translate('resources.reports.runningTrip')}
              bgColor={getColor(5)}
              icon={faClock}
              size="3x"
              value={currentRunningTrip}
            />
          </Grid>
        </Grid>
        <SwipeableDrawer
          anchor="right"
          open={this.state.swipeState}
          onOpen={() => {
            setImmediate(this.toggleDrawer(true))
          }}
          onClose={() =>
            setImmediate(this.toggleDrawer(false))
          }
        >
          <ClickAwayListener onClickAway={() => this.toggleDrawer(false)}>
            <div className={classes.swipeContainer}>
              {drawerView === 'driver' &&
                <DriverGrid classes={classes} ids={driver.ids} />}
              {drawerView === 'vehicle' &&
                <VehicleGrid classes={classes} ids={vehicle.ids} />}
            </div>
          </ClickAwayListener>
        </SwipeableDrawer>
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(GeneralReport)
