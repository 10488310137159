import React, { Component } from 'react'
import { translate } from 'react-admin'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

const OK_ACTION = 'OK'
const CANCEL_ACTION = 'CANCEL'
const CLOSE_ACTION = 'CLOSE'

const DialogConfirm = ({
  open,
  message,
  numberOfChange,
  onClose,
  action,
  changePrevent,
  checked,
  translate
}) => (
  <Dialog
    open={open}
    onClose={() => onClose()}
  >
    <DialogTitle>Thông báo</DialogTitle>
    <DialogContent>
      <DialogContentText>{message}</DialogContentText>
      {numberOfChange > 1 && <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(event, isChecked) => changePrevent(event, isChecked)}
          />
        }
        label={translate('notification.prevent_notification')}
      />}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => action(OK_ACTION)} color="primary">
        {translate('button.save')}
      </Button>
      <Button onClick={() => action(CANCEL_ACTION)}>
        {translate('button.cancel')}
      </Button>
      <Button onClick={() => action(CLOSE_ACTION)}>
        {translate('button.close')}
      </Button>
    </DialogActions>
  </Dialog>
)

class NavigationBlocker extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      message: '',
      navigateLocation: {},
      isChanged: false,
      numberOfChange: 0,
      isPrevented: false,
      preventValue: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isChanged } = nextProps
    let currentIsChanged = prevState.isChanged
    if (isChanged !== currentIsChanged) {
      currentIsChanged = isChanged
    }
    return {
      isChanged: currentIsChanged
    }
  }

  componentDidMount() {
    let { history, translate } = this.props
    if (history) {
      history.block((location, action) => {
        const { isChanged, numberOfChange, isPrevented } = this.state
        if (isPrevented) {
          return true
        }
        if (isChanged) {
          this.setState({
            open: true,
            message: translate('notification.navigate_when_not_save_route'),
            navigateLocation: location,
            numberOfChange: numberOfChange + 1
          })
          return false
        }
        return true
      })
    }
    window.addEventListener('beforeunload', this.handleBeforeunload)
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleBeforeunload)
  }

  handleCloseConfirm = () => {
    this.setState({
      open: false,
    })
  }

  handleBeforeunload = event => {
    const { translate } = this.props
    const { isChanged } = this.state
    let returnValue
    if (isChanged) {
      returnValue = translate('notification.navigate_when_not_save_route')
    }
    if (typeof returnValue === 'string') {
      event.returnValue = returnValue
      return returnValue
    }
  }

  handlePreventNotify = (event, isChecked) => {
    let { isPrevented } = this.state
    if (isChecked) {
      isPrevented = isChecked
    }
    this.setState({ isPrevented, preventValue: isChecked })
  }

  actionConfirm = (actionType) => {
    let { history, doneAction, cancelAction } = this.props
    let { navigateLocation } = this.state
    switch (actionType) {
      case OK_ACTION:
        doneAction && doneAction()
        this.setState({
          open: false,
          isChanged: false
        }, () => history.push(navigateLocation))
        break
      case CANCEL_ACTION:
        cancelAction && cancelAction()
        this.setState({
          open: false,
          isChanged: false
        }, () => history.push(navigateLocation))
        break
      case CLOSE_ACTION:
        this.handleCloseConfirm()
        break
      default:
        return
    }
  }

  render() {
    let { open, message, numberOfChange, preventValue } = this.state
    let { translate } = this.props
    return <div>
      <DialogConfirm
        message={message}
        open={open}
        onClose={this.handleCloseConfirm}
        action={this.actionConfirm}
        numberOfChange={numberOfChange}
        changePrevent={this.handlePreventNotify}
        checked={preventValue}
        translate={translate}
      />
    </div>
  }
}

const mapStateToProps = (state) => {
  let isChanged = state['network'].isChanged
  return {
    isChanged
  }
}

const enhance = compose(
  translate,
  connect(mapStateToProps, {})
)

export default enhance(NavigationBlocker)