import React, { Component } from 'react'
import { Tooltip, withStyles } from '@material-ui/core'
import { Provider } from '../provider'
import FileSaver from 'file-saver'
import moment from 'moment'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from 'react-admin'
import slugify from 'voca/slugify'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
}

class ExportButton extends Component {

  export = () => {
    let { scheduleId, name } = this.props
    const slug = slugify(name)

    Provider.dataProvider('REMOTE', 'schedules', {
      method: 'export',
      requestMethod: 'POST',
      data: { scheduleId }
    }).then(
      res => {
        let data = res.data
        data = JSON.stringify(data)
        let fileName = `${slug}-${moment(new Date()).format('YYYMMDDhhmmss')}.txt`
        FileSaver.saveAs(new Blob([data], { type: 'application/octet-stream' }), fileName)
      }
    )
  }

  render() {
    let { translate, classes, color } = this.props
    return <LinkField
      className={classes.iconButton}
      icon
      color={color}
      onClick={this.export}
    >
      <Tooltip title={translate('button.download_file')} enterDelay={100} >
        <FontAwesomeIcon icon={faFileDownload} size="xs" />
      </Tooltip>
    </LinkField>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(ExportButton)
