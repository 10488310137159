import MQTT from '../mqtt/mqtt'
import { MqttActionName } from '../common/constants'

const publish = async (topic, message) => {
  const mqtt = await MQTT.getInstance()
  mqtt.publish(topic, JSON.stringify(message))
}

export const pickUp = async ({ userId, companyId, callLogId }) => {
  let name = MqttActionName.PICK_UP
  let type = 'phonecall'
  let message = { name, type, payload: { userId, companyId, callLogId } }
  let topic = `user/${userId}`
  publish(topic, message)
}

export const rejectCall = ({ userId, companyId, callLogId }) => {
  let name = MqttActionName.REJECT_CALL
  let type = 'phonecall'
  let message = { name, type, payload: { userId, companyId, callLogId } }
  let topic = `user/${userId}`
  publish(topic, message)
}

export const makeCall = async ({ phoneNumber, companyId, userId }) => {
  let name = MqttActionName.MAKE_CALL
  let type = 'phonecall'
  let message = { name, type, payload: { phoneNumber, userId, companyId } }
  let topic = `user/${userId}`
  publish(topic, message)
}

export const sendSms = async ({ to, body, companyId, userId }) => {
  let name = MqttActionName.SEND_SMS
  let type = 'sms'
  let message = { name, type, payload: { to, body, companyId, userId } }
  let topic = `user/${userId}`
  publish(topic, message)
}

export const remotePrint = (deviceSerial, pairedKey, reservationCode) => {
  let topic = `device/${deviceSerial}`
  let message = {
    type: 'notification',
    name: MqttActionName.DEVICE_ACTION_REQUEST,
    payload: {
      actionType: MqttActionName.PRINT_TICKET,
      pairedKey,
      payload: {
        reservationCode,
      }
    }
  }
  publish(topic, message)
}

export const pingToDevice = async (deviceSerial, pairedKey) => {
  let topic = `device/${deviceSerial}`
  let message = {
    type: 'notification',
    name: MqttActionName.DEVICE_ACTION_REQUEST,
    payload: {
      pairedKey,
      actionType: 'PING',
    }
  }
  publish(topic, message)
}