import React, { Component } from 'react'
import { DragSource, DropTarget, } from 'react-dnd'
import {
  TableCell,
  withStyles,
  Button,
  Checkbox,
} from '@material-ui/core'
import { DragHandle as DragHandleIcon, ArrowUpward as ArrowUpwardIcon } from '@material-ui/icons'
import { compose } from 'recompose'
import { formatCurrency } from '../utils/formatUtil'

const ItemTypes = {
  ITEM: 'PRODUCT_DND_ITEM'
}

let styles = {
  bodyCell: {
    fontSize: '0.8125rem',
  },
  rowBody: {
    height: 48,
  }
}

class TableRowItem extends Component {

  state = {
    isChecked: false,
  }

  handleReorder = index => {
    let { reorder, reorderGroup,  routeId, productSelected = [] } = this.props
    if (productSelected.length > 0) {
      reorderGroup(productSelected, routeId)
    } else {
      while (index) {
        reorder(index, index-1, routeId, routeId)
        index--
      }
    }
  }

  render() {
    let {
      record,
      routeId,
      index,
      connectDragSource,
      connectDropTarget,
      isHover,
      classes,
      canDrop,
      handleGetProduct,
      rowChecked,
      productSelected=[]
    } = this.props
    let opacity = (isHover && canDrop) ? 0 : 1
    return connectDropTarget(connectDragSource(
      <tr className={classes.rowBody} style={{ opacity, cursor: 'pointer' }}>
        <TableCell className={classes.bodyCell} component="th" scope="row">
          <Checkbox
            checked={rowChecked}
            onClick={() => {
              handleGetProduct(index, routeId)}
            }
          />
        </TableCell>
        <TableCell className={classes.bodyCell}>{record.productName}</TableCell>
        <TableCell className={classes.bodyCell}>{record.name}</TableCell>
        <TableCell className={classes.bodyCell}>{record.amount ? formatCurrency(record.amount) : '0đ'}</TableCell>
        <TableCell className={classes.bodyCell}>
          <Button
            disabled={productSelected.length > 0 ? !rowChecked : false}
            color="primary"
            onClick={() => this.handleReorder(index)}
          >
            <ArrowUpwardIcon />
          </Button>
        </TableCell>
        <TableCell>
          <DragHandleIcon />
        </TableCell>
      </tr>
    ))
  }
}

const dragResource = {
  beginDrag: (props) => ({
    id: props.record.id,
    index: props.index,
    routeId: props.routeId
  }),
}

export default compose(
  DragSource(ItemTypes.ITEM, dragResource, (connect) => ({
    connectDragSource: connect.dragSource(),
  })),
  DropTarget(ItemTypes.ITEM, {
    hover: (props, monitor, component) => {
      if (!component) return null
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.index
      if (dragIndex === hoverIndex) {
        return
      }
      props.reorder(dragIndex, hoverIndex, props.routeId, monitor.getItem().routeId)
      monitor.getItem().index = hoverIndex
    },
    drop: (props) => {
      let { index, record = {}, routeId } = props
      const overIdx = index
      let dragId = record.id
      props.onDrop(overIdx, dragId, routeId)
    },
    canDrop: (props, monitor) => {
      return props.routeId === monitor.getItem().routeId
    }
  }, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isHover: monitor.isOver(),
    canDrop: monitor.canDrop()
  })),
  withStyles(styles)
)(TableRowItem)
