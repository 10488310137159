import React, { Component, Fragment } from 'react'
import {
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import LinkField from './../common/LinkField'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  translate,
  showNotification,
  refreshView,
} from 'react-admin'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'
import moment from 'moment'
import { reset, change } from 'redux-form'
import { FuzzyAsyncPickerInput } from '../common/react-fuzzy-picker'
import { MIN, MAX } from '../common/validateName'

const validateForm = translate => formValue => {
  const errors = {}
  let { routeId, departureTime, arrivalTime, name } = formValue
  if (!routeId) {
    errors.routeId = translate('resources.trips.validate.routeError')
  }

  if (moment(departureTime).isAfter(moment(arrivalTime))) {
    errors.arrivalTime = translate('resources.trips.validate.arrivedTimeError')
  }
  if (!name || (name && !name.trim())) {
    errors.name = translate('error_messages.required.name')
  } else {
    if (name.length < MIN) {
      errors.name = translate('error_messages.required.viewtrip.min_length_name', { min: MIN })
    } else if (name.length > MAX) {
      errors.name = translate('error_messages.required.viewtrip.max_length_name', { max: MAX })
    }
  }

  return errors
}

class QuickCreateTripButton extends Component {
  state = {
    openForm: false,
    saveLoading: false,
    formValue: {
      routeId: '',
      name: '',
      departureTime: moment().format(),
      arrivalTime: moment().add(1, 'minute').format(),
      vehicleId: '',
      driverId: '',
      driver2Id: '',
      assistantDriverId: '',
      type: '00TST',
      capacity: 0,
    },
    routeId: ''
  }

  onCloseForm = () => {
    this.setState({ openForm: false })
    this.props.reset('quick-create-trip')
  }

  onSave = (formData) => {
    let { showNotification, refreshView } = this.props
    this.setState({ saveLoading: true })
    Provider.dataProvider('CREATE', 'trips', {
      data: formData
    })
      .then(() => {
        showNotification('resources.trips.messages.saveSuccess')
        this.onCloseForm()
        refreshView()
      })
      .catch(() => {
        showNotification('resources.trips.messages.saveFailed', 'warning')
      })
      .finally(() => {
        this.setState({ saveLoading: false })
      })
  }

  render() {
    let {
      classes,
      routeResource,
      translate,
    } = this.props
    let {
      openForm,
      saveLoading,
      formValue,
      routeId,
    } = this.state
    return (<Fragment>
      <LinkField
        classes={classes}
        onClick={() => this.setState({ openForm: true })}
        style={{ fontSize: '0.6964285714285714rem' }}
      >
        <Icon style={{ marginRight: 8 }}>add</Icon>
        {translate('resources.trips.buttons.quickCreateTrip')}
      </LinkField>

      <Dialog
        open={openForm}
        onClose={this.onCloseForm}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle style={{
          backgroundColor: '#3f51b5',
        }}>
          <span style={{
            color: 'white',
            fontSize: 16,
            fontWeight: 'bold'
          }}>
            {translate('resources.trips.buttons.quickCreateTrip')}
          </span>
        </DialogTitle>
        <DialogContent>
          <SimpleForm
            record={formValue}
            form="quick-create-trip"
            saving={saveLoading}
            save={this.onSave}
            validate={validateForm(translate)}
            resource="trips"
            submitOnEnter={false}
          >
            <ReferenceInput
              label="resources.trips.fields.routeId"
              source="routeId"
              reference="routes"
              filter={{
                status: {
                  neq: '20ARCHIVED'
                }
              }}
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <FormDataConsumer>
              {({ formData, dispatch }) => {
                if (formData.routeId && formData.routeId !== routeId) {
                  this.setState({ routeId: formData.routeId })
                  let name = routeResource[formData.routeId].name
                  dispatch(change('quick-create-trip', 'name', name))
                }
                return (
                  <TextInput
                    label="resources.trips.fields.name"
                    source="name"
                    fullWidth
                    resettable
                  />
                )
              }
              }
            </FormDataConsumer>
            <DateTimePickerInput
              label={translate('resources.trips.fields.departureTime')}
              fullWidth
              source="departureTime"
              keyboard
            />
            <DateTimePickerInput
              label={translate('resources.trips.fields.arrivalTime')}
              fullWidth
              source="arrivalTime"
              keyboard
            />
            <FuzzyAsyncPickerInput
              label={translate('resources.trips.fields.vehicleId')}
              source="vehicleId"
              resources="vehicles"
              optionText="plate"
              optionValue="id"
              fullWidth
            />
            <FuzzyAsyncPickerInput
              label={translate('resources.trips.fields.driverId')}
              source="driverId"
              resources="drivers"
              optionText="fullName"
              optionValue="id"
              fullWidth
            />
            <FuzzyAsyncPickerInput
              label={translate('resources.trips.fields.driver2Id')}
              source="driver2Id"
              resources="drivers"
              optionText="fullName"
              optionValue="id"
              fullWidth
            />
            <FuzzyAsyncPickerInput
              label={translate('resources.trips.fields.assistantDriverId')}
              source="assistantDriverId"
              resources="assistantdrivers"
              optionText="fullName"
              optionValue="id"
              fullWidth
            />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </Fragment>)
  }
}

export default compose(
  connect(state => ({
    routeResource: _.get(state, 'admin.resources.routes.data', {}),
  }), { showNotification, refreshView, reset }),
  translate,
)(QuickCreateTripButton)
