import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import { Provider } from '../provider'
import { reset } from 'redux-form'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import CustomToolbar from '../common/CustomToolbarForm'
import ValueInput from '../configuration/ValueInput'


class SettingForm extends Component {

  state = {
    record: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record } = nextProps
    let { record: currentRecord } = prevState
    if (record !== currentRecord) {
      currentRecord = record
    }
    return {
      record: currentRecord || {}
    }
  }

  onSave = record => {
    let { action } = this.props
    if (action === 'edit') {
      this.edit(record)
    } else if (action === 'create') {
      this.create(record)
    }
  }

  create = record => {
    let { onDone, reset, onError, form } = this.props
    Provider.dataProvider('CREATE', 'settings', {
      data: record
    }).then(success => {
      reset(form)
      onDone && onDone()
    }).catch(error => {
      reset(form)
      onError && onError()
    })
  }

  edit = record => {
    let { onDone, reset, onError, form } = this.props
    Provider.dataProvider('UPDATE', 'settings', {
      id: record.id, data: record
    }).then(success => {
      reset(form)
      onDone && onDone()
    }).catch(error => {
      reset(form)
      onError && onError()
    })
  }


  render() {
    let { form } = this.props
    let { record } = this.state
    let extras = {
      resource: 'settings',
      fullWidth: true,
    }
    return <SimpleForm
      record={record}
      form={form}
      basePath="/settings"
      resource="settings"
      save={this.onSave}
      toolbar={<CustomToolbar />}
    >
      <TextInput source="code" {...extras} />
      <TextInput source="name" {...extras} />
      <ReferenceInput
        source="group"
        reference="settinggroups"
        {...extras}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        source="dataType"
        reference="configurationdatatypes"
        {...extras}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="desc" {...extras} />
      <FormDataConsumer>
        {(form, props) => {
          let { formData = {} } = form
          let { dataType: type } = formData
          let name = 'value'
          if (type === 'IMAGE') {
            name = 'bValue'
          }
          return <ValueInput source={name} type={type} {...extras} />
        }}
      </FormDataConsumer>
    </SimpleForm>
  }
}

const enhance = compose(connect(null, { reset }))

export default enhance(SettingForm)