import L from 'leaflet'
import {
  point,
  featureCollection,
  center,
  nearestPointOnLine,
} from '@turf/turf'
import _ from 'lodash'
import moment from 'moment'

const MAX_REQUEST_TIME = 1000

let lastTime = 0

export function coordinatesToLatLngs(coordinates) {
  let latlngs = coordinates.map(v => {
    return L.latLng(v[1], v[0])
  })
  return latlngs
}

export function contains(map, routeId) {
  let retval = null
  map.eachLayer(v => {
    if (v.routeId && v.routeId === routeId) {
      retval = v
    }
  })
  return retval
}

export function getBounds(lbounds, rbounds) {
  if (!lbounds || !lbounds.isValid()) {
    return rbounds
  }
  if (!rbounds || !rbounds.isValid()) {
    return lbounds
  }

  const lne = lbounds.getNorthEast()
  const rne = rbounds.getNorthEast()
  const lsw = lbounds.getSouthWest()
  const rsw = rbounds.getSouthWest()
  const maxLon = Math.max(lne.lng, rne.lng)
  const maxLat = Math.max(lne.lat, rne.lat)

  const minLon = Math.min(lsw.lng, rsw.lng)
  const minLat = Math.min(lsw.lat, rsw.lat)

  const ne = { lng: maxLon, lat: maxLat }
  const sw = { lng: minLon, lat: minLat }
  return L.latLngBounds(L.latLng(sw), L.latLng(ne))
}

export function hasCoordinates(route) {
  return route.path && route.path.coordinates
}

const baseURL = process.env.REACT_APP_GH_URL
const routeURL = `${baseURL}route?points_encoded=false&vehicle=car&`
//accept-language
const nominatim = process.env.REACT_APP_NOMINATIM_URL
const reverseURL = `${nominatim}/reverse`
const detailURL = `${nominatim}/details`

export async function createListOfStopDetails(stops) {
  let len = stops.length
  let array = []
  for (let idx = 0; idx < len; idx++) {
    let stop = stops[idx]
    let details = await createStopDetails(stop.lat, stop.lon)
    stop = {...stop, placeId: details.placeId, osmId: details.osmId }
    array.push(stop)
  }

  return array
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function createStopDetails(lat, lon, locale = 'vn') {
  let stop = {
    lat: lat.toFixed(6),
    lon: lon.toFixed(6),
  }
  let place = await reverse(stop.lat, stop.lon, locale, 18)
  let placeDetail = await addressdetail(place.place_id, locale)

  for (let address of placeDetail.address) {
    if (Number(address.admin_level) === 6) {
      stop = {
        ...stop,
        name: place ? parseAddress(place.address, false) : 'New stop',
        code: place ? place.osm_id : '',
        address: place ? parseAddress(place.address) : '',
        placeId: address.place_id,
        osmId: address.osm_id,
      }
      return stop
    }
  }
}

/*
export async function createStopDetails(lat, lon, locale = 'vn') {
  let stop = {
    lat: lat.toFixed(6),
    lon: lon.toFixed(6),
  }
  let place = await reverse(stop.lat, stop.lon, locale)
  let county = await reverse(stop.lat, stop.lon, locale, 8)

  let countyAddressTypes = new Set(['county', 'city_district', 'borough'])

  if (county.osm_type !== 'relation' || !countyAddressTypes.has(county.addresstype) ) {
    county = await reverse(stop.lat, stop.lon, locale, 12)
    if (!countyAddressTypes.has(county.addresstype)) {
      let placeDetail = await detail(county.place_id, 0, locale)
      let parentDetail = await detail(placeDetail.parent_place_id, 1, locale)
      if (parentDetail) {
        let normCounty = _.find(parentDetail.hierarchy, {
          localname: placeDetail.localname,
          osm_type: 'R',
        })
        if (normCounty) county = normCounty
      }
    }
    //let placeDetail = await detail(county.place_id, 0, locale)
    //if (placeDetail && placeDetail.parent_place_id) {
    //let parentDetail = await detail(placeDetail.parent_place_id, 1, locale)
    //if (parentDetail) {
    //let normCounty = _.find(parentDetail.hierarchy, {
    //localname: placeDetail.localname,
    //osm_type: 'R',
    //})
    //if (normCounty) county = normCounty
    //}
    //}
  }
  stop = {
    ...stop,
    name: place ? parseAddress(place.address, false) : 'New stop',
    code: place ? place.osm_id : '',
    address: place ? parseAddress(place.address) : '',
    placeId: county ? county.place_id : '',
    osmId: county ? county.osm_id : '',
  }
  return stop
}
*/

// parse address from nominatim reverse address
function parseAddress(address, country = true) {
  let ignores = new Set(['postcode', 'country_code', 'town'])
  if (!country) ignores.add('country')
  return Object.keys(address)
    .filter(item => !ignores.has(item))
    .map(item => address[item])
    .join(', ')
}

const waitFromLastRequest = async () => {
  let delta = MAX_REQUEST_TIME + lastTime - moment.now()
  if (delta > 0) await sleep(delta).then(() => console.log('Nominatim has an absolute maximum of 1 request per second requirement. So the next request must wait %i(ms)', delta))
  lastTime = moment.now()
}

//get geo data for a [lat, lon] point. Expected to have city name and province name
export async function reverse(lat, lon, locale = 'vn', zoom = 18) {
  await waitFromLastRequest()
  let url = `${reverseURL}?format=jsonv2&addressdetails=1&namedetails=1&zoom=${zoom}&lat=${lat}&lon=${lon}&accept-language=${locale}`
  let resp = await fetch(url)
  const data = await resp.json()
  return data
}

export async function detail(placeId, hierarchy = 1, locale = 'vn') {
  await waitFromLastRequest()
  let url = `${detailURL}?place_id=${placeId}&format=json&hierarchy=${hierarchy}&accept-language=${locale}`
  let resp = await fetch(url)
  const data = await resp.json()
  return data
}

export async function addressdetail(placeId, locale = 'vn') {
  await waitFromLastRequest()
  let url = `${detailURL}?place_id=${placeId}&format=json&addressdetails=1&accept-language=${locale}`
  let resp = await fetch(url)
  const data = await resp.json()
  return data
}

export function getURL({ from, to, via }) {
  let points = [encodeURI(`point=${from.join(',')}`)]
  if (via) {
    points.push(encodeURI(`point=${via.join(',')}`))
  }
  points.push(encodeURI(`point=${to.join(',')}`))
  let url = `${routeURL}${points.join('&')}`
  return url
}

export function getMouseEvtLatLng(map, evt, offset = [0, 0]) {
  let xy = map.mouseEventToLayerPoint(evt.originalEvent)
  xy.x += offset[0]
  xy.y += offset[1]
  let latlng = map.layerPointToLatLng(xy)
  return latlng
}

export async function getRouting({ from, to, via, convertToLatLng = true }) {
  let url = getURL({ from, to, via })
  let resp = await fetch(url)
  const data = await resp.json()
  if (data && data.paths && data.paths.length > 0) {
    const path = data.paths[0]
    if (path.points && path.points.coordinates) {
      if (convertToLatLng)
        return coordinatesToLatLngs(path.points.coordinates)
      else
        return path.points.coordinates
    }
  }
  return []
}

export function gotoKm(linestring, km) {
  let latlngs = coordinatesToLatLngs(linestring.coordinates)
  let len = latlngs.length
  if (len === 0) {
    return null
  }
  let start = latlngs[0]
  let curr = start
  let idx = 1
  while (idx < len) {
    curr = latlngs[idx]
    let d = curr.distanceTo(start)
    if (d >= km) {
      break
    }
    idx++
  }
  return curr
}

//length in meter
export function length(latlngs) {
  let len = latlngs.length
  let d = 0
  for (let idx = 1; idx < len; idx++) {
    d += latlngs[idx - 1].distanceTo(latlngs[idx])
  }
  return d
}

export function latlngsToLineString(latlngs) {
  let linestring = {
    type: 'LineString',
    coordinates: []
  }
  let len = latlngs.length
  for (let idx = 0; idx < len; idx++) {
    let latlng = latlngs[idx]
    linestring.coordinates.push([latlng.lng, latlng.lat])
  }
  return linestring
}

export function calcDistance(model) {
  let d = 0
  model.distances.iterate(v => {
    d += v.value
  })
  return d
}

export function getLatLngs(model) {
  let latlngs = []
  model.lines.iterate(v => {
    let len = v.value.length
    for (let idx = 0; idx < len; idx++) {
      latlngs.push(v.value[idx])
    }
  })
  return latlngs
}

export class LinkedList {
  constructor() {
    this.head = null
    this.tail = null
  }

  append(value) {
    if (!this.head) {
      this.head = value
      this.tail = value
      return this
    }

    this.tail.next = value
    this.tail = value
    return this
  }

  removeTail() {
    const tail = this.tail
    if (this.head === this.tail) {
      this.head = null
      this.tail = null
      return tail
    }
    let curr = this.head
    while (curr.next) {
      if (!curr.next.next) {
        curr.next = null
      } else {
        curr = curr.next
      }
    }
    this.tail = curr
    return tail
  }

  iterate(fnc) {
    let curr = this.head
    while (curr) {
      if (fnc) {
        fnc(curr)
      }
      curr = curr.next
    }
  }
}

export function isValidPoint(point) {
  const lat = point.lat - 0
  const lon = point.lon - 0
  if (!lat || lat > 90 || lat < -90) {
    return false
  }
  if (!lon || lon > 180 || lon < -180) {
    return false
  }
  return true
}

// take list stop to find center point

export function findCenter(stops) {
  let feCol = _.reduce(stops, (fet = [], stop) => {
    let latlon = [parseFloat(stop.lat), parseFloat(stop.lon)]
    fet.push(point(latlon))
    return fet
  }, [])
  let pointCenter = center(featureCollection(feCol))
  if (!pointCenter) {
    return []
  }
  let centLatlon = pointCenter.geometry.coordinates
  return centLatlon
}

export function getLocationPoint(lnString, stop) {
  if (isNaN(stop.lat) || isNaN(stop.lon)) { return 0 }
  let pnt = point([parseFloat(stop.lon), parseFloat(stop.lat)])
  let nearest = nearestPointOnLine(lnString, pnt, { units: 'kilometers' })
  if (nearest && nearest.properties) {
    let { location } = nearest.properties
    return location
  }
  return 0
}

export function markerPopup(lat, lng, address) {
  var street = address.road || address.pedestrian || ''
  var house = address.house_number || ''
  var postcode = address.postcode || ''
  var city = address.city || address.town || ''
  var state = address.state || ''
  var country = address.country
  return `<div class="card" style="width: 18rem;">
    <div class="card-body">
      <h3 class="card-title">${street} ${house}</h3>
      <h4 class="card-subtitle mb-2 text-muted">${postcode} ${city}</h4>
      <p class="card-text">${state} ${country}</p>
      <p class="card-text text-muted">
        ${lat}, ${lng}
      </p>
    </div>
    </div>`
}
