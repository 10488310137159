import React, { Component, Fragment } from 'react'
import { Button, withStyles } from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import { AssignDialog } from './RegisterRouteButton'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { reset } from 'redux-form'
import AddIcon from '@material-ui/icons/Add'
import { UPDATE } from './constant'

const PRODUCT_ROUTE_EDIT_FORM = 'product-route-edit-form'

const style = {
  button: {
    marginRight: 8,
  },
  icon: {
    marginRight: '0.5em',
  },
}

class EditProductRouteButton extends Component {

  state = {
    open: false,
    oldFares: [],
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    let { reset } = this.props
    reset(PRODUCT_ROUTE_EDIT_FORM)
    this.setState({ open: false })
  }

  onSaveDone = (data) => {
    let { showNotification, updateProductRouteMapping, updateChildRouteProductsAfterAdd } = this.props
    this.closeDialog()
    updateProductRouteMapping(data.id, UPDATE, data)
    updateChildRouteProductsAfterAdd(data)
    showNotification('notification.register_company_success')
  }

  onSaveError = () => {
    let { showNotification } = this.props
    this.closeDialog()
    showNotification('notification.register_company_failure', 'warning')
  }

  render() {
    let { translate, size, classes, record, productId } = this.props
    let { open } = this.state
    return <Fragment>
      <Button
        onClick={this.openDialog}
        size={size}
        color='primary'
        variant="outlined"
        className={classes.button}
      >
        <AddIcon fontSize="small" className={classes.icon} />
        {translate('button.add_new_fare')}
      </Button>
      {open && <AssignDialog
        open={open}
        onClose={this.closeDialog}
        translate={translate}
        onDone={this.onSaveDone}
        onError={this.onSaveError}
        record={record}
        productId={productId}
        action="edit"
        form={PRODUCT_ROUTE_EDIT_FORM}
      />}
    </Fragment>
  }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(null, { showNotification, reset, refreshView })
)
export default enhance(EditProductRouteButton)
