import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  Filter,
  TextInput,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'

const AppConfigListFilter = props => {
  return <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
}


////////////////////////////////////////////////////////////////////////////////
// AppConfigList
export const AppConfigList = props => (
  <List
    bulkActionButtons={false}
    filters={<AppConfigListFilter />}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="value" />
    </Datagrid>
  </List>
)

////////////////////////////////////////////////////////////////////////////////
// AppConfigCreate
export const AppConfigCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// AppConfigEdit
export const AppConfigEdit = props => (
  <Edit
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Edit>
)
