import React, {Component} from 'react'
import Konva from 'konva'
import {
  translate,
  showNotification,
} from 'react-admin'
import _ from 'lodash'
import { 
  withStyles,
  IconButton,
  Button,
  Tooltip,
  TextField
} from '@material-ui/core'
import {CardCustom} from './components'
import {contents, genId, isJsonString} from './utils/ticketEditor'
import TextEditor from './TextEditor'
import StageEditor from './StageEditor'
import { Provider } from '../provider'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import CodeIcon from '@material-ui/icons/Code'
import SaveIcon from '@material-ui/icons/Save'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'

const MIN_SIZE = 20
const DEFAULT_WIDTH = 461
const DEFAULT_HEIGHT = 844
const DEFAULT_ACTUAL_WIDTH = 80
const MAX_WIDTH = 1000
const GUIDELINE_OFFSET = 5

const styles = () => ({
  container: {
    width: '100%',
    display: 'flex',
    padding: 50,
    boxSizing: 'border-box',
    flexWrap: 'wrap',
    gap: '20px',
  },
  left: {
    flex: 2
  },
  konva: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%'
  },
  konvaContainer: {
    background: '#e5e5e5',
  },
  right: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& > div': {
      marginBottom: 20
    }
  },
  textConfig: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  configBtns: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: '10px',
  },
  inputUpload: {
    display: 'none'
  },
  group: {
    display: 'flex',
    marginBottom: 10,
    flexWrap: 'wrap',
    gap: '10px',
    width: '100%',
  },
})

class TicketEditor extends Component {
  constructor(props) {
    super(props)
    this.state = {
      configs: undefined,
      stage: undefined,
      layer: undefined,
      selectedItem: undefined,
      selectedId: undefined,
      transformer: undefined,
      background: undefined,
      actualWidth: DEFAULT_ACTUAL_WIDTH,
      loading: true,
      preview: false,
      record: undefined
    }
  }

  createSharp = (ele) => {
    let item = null
    if (ele.type === 'textarea') {
      item = new Konva.Text(ele)
      //const sharp = {
      //...ele,
      //x: mmToPx(ele.left, density),
      //y: height - mmToPx(ele.bottom, density),
      //fontSize: ptsToPx(ele.fontsize, density),
      //fontFamily: ele.fontfamily,
      //width: mmToPx(ele.width, density),
      //id: genId(8),
      //fill: ele.color,
      //draggable: true,
      //name: 'item'
      //}
      //const textKonva = new Konva.Text({...sharp})
    } else {
      item = new Konva.Rect(ele)
      //const sharp =  {
      //...ele,
      //type: 'qr',
      //fill: '#2e2e2e',
      //x: mmToPx(ele.left, density),
      //y: height - mmToPx(ele.bottom, density) - mmToPx(ele.size, density),
      //width: mmToPx(ele.size, density),
      //height: mmToPx(ele.size, density),
      //id: genId(8),
      //name: 'item',
      //draggable: true
      //}
      //const rectKonva = new Konva.Rect({...sharp})
    }
    return item
  }

  initKonva = (layout, background, loadedImage) => {
    const {width = DEFAULT_WIDTH, height = DEFAULT_HEIGHT} = background || {}

    let scale = 1
    if (width > MAX_WIDTH) {
      const ratio = MAX_WIDTH / width
      scale = ratio.toFixed(2)
    }

    var stage = new Konva.Stage({
      container: 'konva_container',
      width: Number(width) * scale,
      height: Number(height) * scale,
      scale: { x: scale, y: scale },
      draggable: true,
    })

    var bgLayer = new Konva.Layer({id: 'background'})
    stage.add(bgLayer)

    var layer = new Konva.Layer({id: 'content'})
    stage.add(layer)

    var layerTr = new Konva.Layer({id: 'transformer'})
    stage.add(layerTr)

    if(loadedImage) {

      var yoda = new Konva.Image({
        image: loadedImage,
        width: Number(width),
        height: Number(height),
        //scale: { x: scale, y: scale },
        name: 'imageId',
        id: 'imageId'
      })

      // add the shape to the layer
      bgLayer.add(yoda)
    } else {
      var rect1 = new Konva.Rect({
        x: 0,
        y: 0,
        width: stage.width(),
        height: stage.height(),
        fill: '#fff',
        stroke: 'black',
        strokeWidth: 1,
      })
      bgLayer.add(rect1)
    }

    var tr = new Konva.Transformer({
      shouldOverdrawWholeArea: true,
      // enable only side anchors
      //enabledAnchors: ['middle-left', 'middle-right'],
      // limit transformer size
      boundBoxFunc: (oldBox, newBox) => {
        if (newBox.width < MIN_SIZE) {
          return oldBox
        }
        return newBox
      },
    })
    layerTr.add(tr)

    const configs = layout ? JSON.parse(layout) : []
    for (let i = 0; i < configs.length; i++) {
      const ele = configs[i]
      if (ele.type === 'group') {
        if (ele.name === 'header') {
          const line = new Konva.Line({
            name: 'line',
            type: 'line',
            points: [0, ele.height, Number(background.width), ele.height],
            stroke: 'green',
            strokeWidth: 2,
            content: ele.content || '',
            lineJoin: 'round',
            draggable: true,
            dash: [10, 5],
            id: 'header_line',
            dragBoundFunc: function (pos) {
              return {
                x: this.absolutePosition().x,
                y: pos.y,
              }
            },
          })
          layer.add(line)
        }
        if (ele.name === 'footer') {
          const line = new Konva.Line({
            type: 'line',
            name: 'line',
            points: [0, Number(background.height) - ele.height, Number(background.width), Number(background.height) - ele.height],
            stroke: 'red',
            draggable: true,
            content: ele.content || '',
            strokeWidth: 2,
            lineJoin: 'round',
            dash: [10, 5],
            id: 'footer_line',
            dragBoundFunc: function (pos) {
              return {
                x: this.absolutePosition().x,
                y: pos.y,
              }
            },
          })
          layer.add(line)
        }
        const arr = ele.children || []
        for (let j = 0; j < arr.length; j++) {
          const child = arr[j]
          if(child.type === 'image') {
            const newImage = new Image()
            newImage.onload = function () {
              const image = new Konva.Image({
                ...child,
                image: newImage,
              })
              layer.add(image)
            }
            newImage.src = child.text 
          } else {
            const item = this.createSharp(child)
            layer.add(item)
          }
        }
      } else {
        if(ele.type === 'image') {
          const newImage = new Image()
          newImage.onload = function () {
            const image = new Konva.Image({
              ...ele,
              image: newImage,
            })
            layer.add(image)
          }
          newImage.src = ele.text 
        } else {
          const sharp = this.createSharp(ele)
          layer.add(sharp)
        }
      }
    }

    // by default select all shapes
    tr.nodes([])

    // add a new feature, lets add ability to draw selection rectangle
    // clicks should select/deselect shapes

    stage.on('wheel', (e) => {
      const scaleBy = 1.01
      const stage = e.currentTarget
      let oldScale = stage.scaleX()
      let pointer = stage.getPointerPosition()
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale,
      }
      // how to scale? Zoom in? Or zoom out?
      let direction = e.evt.deltaY > 0 ? 1 : -1

      // when we zoom on trackpad, e.evt.ctrlKey is true
      // in that case lets revert direction
      if (e.evt.ctrlKey) {
        direction = -direction
      }

      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy

      stage.scale({ x: newScale, y: newScale })

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale,
      }
      stage.position(newPos)
    })

    //  ===============Start Snap draggable shapes======================
    function getLineGuideStops(skipShape) {
      // we can snap to stage borders and the center of the stage
      var vertical = [0, stage.width() / 2, stage.width()]
      var horizontal = [0, stage.height() / 2, stage.height()]

      // and we snap over edges and center of each object on the canvas
      stage.find('.item').forEach((guideItem) => {
        if (guideItem === skipShape) {
          return
        }
        var box = guideItem.getClientRect()
        // and we can snap to all edges of shapes
        vertical.push([box.x, box.x + box.width, box.x + box.width / 2])
        horizontal.push([box.y, box.y + box.height, box.y + box.height / 2])
      })
      return {
        vertical: vertical.flat(),
        horizontal: horizontal.flat(),
      }
    }

    function getObjectSnappingEdges(node) {
      var box = node.getClientRect()
      var absPos = node.absolutePosition()

      return {
        vertical: [
          {
            guide: Math.round(box.x),
            offset: Math.round(absPos.x - box.x),
            snap: 'start',
          },
          {
            guide: Math.round(box.x + box.width / 2),
            offset: Math.round(absPos.x - box.x - box.width / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.x + box.width),
            offset: Math.round(absPos.x - box.x - box.width),
            snap: 'end',
          },
        ],
        horizontal: [
          {
            guide: Math.round(box.y),
            offset: Math.round(absPos.y - box.y),
            snap: 'start',
          },
          {
            guide: Math.round(box.y + box.height / 2),
            offset: Math.round(absPos.y - box.y - box.height / 2),
            snap: 'center',
          },
          {
            guide: Math.round(box.y + box.height),
            offset: Math.round(absPos.y - box.y - box.height),
            snap: 'end',
          },
        ],
      }
    }

    // find all snapping possibilities
    function getGuides(lineGuideStops, itemBounds) {
      var resultV = []
      var resultH = []

      lineGuideStops.vertical.forEach((lineGuide) => {
        itemBounds.vertical.forEach((itemBound) => {
          var diff = Math.abs(lineGuide - itemBound.guide)
          // if the distance between guild line and object snap point is close we can consider this for snapping
          if (diff < GUIDELINE_OFFSET) {
            resultV.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            })
          }
        })
      })

      lineGuideStops.horizontal.forEach((lineGuide) => {
        itemBounds.horizontal.forEach((itemBound) => {
          var diff = Math.abs(lineGuide - itemBound.guide)
          if (diff < GUIDELINE_OFFSET) {
            resultH.push({
              lineGuide: lineGuide,
              diff: diff,
              snap: itemBound.snap,
              offset: itemBound.offset,
            })
          }
        })
      })

      var guides = []

      // find closest snap
      var minV = resultV.sort((a, b) => a.diff - b.diff)[0]
      var minH = resultH.sort((a, b) => a.diff - b.diff)[0]
      if (minV) {
        guides.push({
          lineGuide: minV.lineGuide,
          offset: minV.offset,
          orientation: 'V',
          snap: minV.snap,
        })
      }
      if (minH) {
        guides.push({
          lineGuide: minH.lineGuide,
          offset: minH.offset,
          orientation: 'H',
          snap: minH.snap,
        })
      }
      return guides
    }

    function drawGuides(guides) {
      guides.forEach((lg) => {
        if (lg.orientation === 'H') {
          var line = new Konva.Line({
            points: [-6000, 0, 6000, 0],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          })
          layer.add(line)
          line.absolutePosition({
            x: 0,
            y: lg.lineGuide,
          })
        } else if (lg.orientation === 'V') {
          var line = new Konva.Line({
            points: [0, -6000, 0, 6000],
            stroke: 'rgb(0, 161, 255)',
            strokeWidth: 1,
            name: 'guid-line',
            dash: [4, 6],
          })
          layer.add(line)
          line.absolutePosition({
            x: lg.lineGuide,
            y: 0,
          })
        }
      })
    }

    layer.on('dragmove', function (e) {
      // clear all previous lines on the screen
      layer.find('.guid-line').forEach((l) => l.destroy())

      // find possible snapping lines
      var lineGuideStops = getLineGuideStops(e.target)
      // find snapping points of current object
      var itemBounds = getObjectSnappingEdges(e.target)

      // now find where can we snap current object
      var guides = getGuides(lineGuideStops, itemBounds)

      // do nothing of no snapping
      if (!guides.length) {
        return
      }

      drawGuides(guides)

      var absPos = e.target.absolutePosition()
      // now force object position
      guides.forEach((lg) => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
          case 'center': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset
                break
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset
                break
              }
            }
            break
          }
        }
      })
      e.target.absolutePosition(absPos)
    })

    layer.on('dragend', function (e) {
      // clear all previous lines on the screen
      layer.find('.guid-line').forEach((l) => l.destroy())
    })
    //  ===============END Snap draggable shapes======================
    
    layer.on('mouseover', function () {
      document.body.style.cursor = 'pointer'
    })
    layer.on('mouseout', function () {
      document.body.style.cursor = 'default'
    })

    stage.on('click tap', (e) =>  {
    // if we are selecting with rect, do nothing
    //if (selectionRectangle.visible()) {
    //return
    //}
      // if click on empty area || clicked not our item - remove all selections
      if (e.target === stage || !(e.target.hasName('item') || e.target.hasName('line'))) {
        tr.nodes([])
        this.setState({ selectedItem: null, selectedId: null })
        return
      }

      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
      const isSelected = tr.nodes().indexOf(e.target) >= 0

      if (!metaPressed && !isSelected) {
        // if no key pressed and the node is not selected
        // select just one
        //tr.nodes([e.target])

        const id = e.target.getAttr('id')
        const attrs = e.target.getAttrs() || {}
        const type = attrs.type
        if (type == 'textarea') {
          tr.rotateEnabled(true)
          tr.enabledAnchors(['middle-left', 'middle-right'])
        } else if(type === 'qr'){
          tr.rotateEnabled(true)
          tr.enabledAnchors(['top-left', 'bottom-left', 'top-right', 'bottom-right'])
        }else if (type === 'line') {
          tr.rotateEnabled(false)
          tr.enabledAnchors(['top-left', 'bottom-left', 'top-right', 'bottom-right'])
        }else {
          tr.rotateEnabled(true)
          tr.enabledAnchors(['top-left', 'top-center', 'top-right', 'middle-right', 'middle-left', 'bottom-left', 'bottom-center', 'bottom-right'])
        }
        tr.nodes([e.target])
        this.setState({ selectedItem: e.target, selectedId: id })
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = tr.nodes().slice() // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target), 1)
        tr.nodes(nodes)
        this.setState({ selectedId: null, selectedItem: null })
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = tr.nodes().concat([e.target])
        tr.nodes(nodes)
        this.setState({ selectedId: null, selectedItem: null })
      }
    })
    this.setState({stage, layer, transformer: tr, configs: layout, background, loading: false })

  }

  renderKonva = (layout, background = {}) => {
    if (!_.isEmpty(background) && background.src) {
      var imageObj = new Image()
      imageObj.src = background.src
      imageObj.onload = () => {
        background.width = imageObj.width
        background.height = imageObj.height
        this.initKonva(layout, background, imageObj)
      }
    } else {
      let newBackground = background ? background : {width: DEFAULT_WIDTH, height: DEFAULT_HEIGHT}
      this.initKonva(layout, newBackground)
    }
  }

  fetchTicketLayout = async () => {
    let { match = {} } = this.props
    let { params = {} } = match
    const res = await Provider.dataProvider('REMOTE', 'ticketlayouts', {
      method: `${params.id}`,
      requestMethod: 'GET',
    })
    return res.data
  }

  async componentDidMount() {
    try {
      const data = await this.fetchTicketLayout()
      const {layout = [], background = {}, layoutType} = data
      this.renderKonva(layout, background)
      const actualWidth = _.get(background, 'actualWidth', DEFAULT_ACTUAL_WIDTH)
      this.setState({record: data, actualWidth, layoutType})
    } catch(e) {
      this.setState({loading: false})
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {background, stage, layoutType} = this.state
    if (background && prevState.background && background !== prevState.background) {
      let result = this.getConfigsDynamic()
      //if (layoutType === 'dynamic') {
      //result = 
      //} else {
      //var contentLayer = stage.findOne('#content')
      //const {children = []} = contentLayer
      //result = this.convertConfigs(children)
      //}
      this.renderKonva(JSON.stringify(result), background)
    }
  }

  onDelete = () => {
    this.state.selectedItem.destroy()
    this.state.transformer.nodes([])
    this.setState({selectedId: undefined, selectedItem: null})
  }

  changeSize = (e) => {
    const {stage} = this.state
    stage.width(Number(e.target.value))
    this.setState({[e.target.name]: Number(e.target.value)})
  }

  convertConfigs = (children = []) => {
    const {layoutType} = this.state
    let configs = []
    if (layoutType === 'dynamic') {
      configs = children.map(item => {
        const {children = [], ...props} = item
        const attrsChildren = children.map(e => e.attrs)
        const newAttrs = {...props, children: attrsChildren}
        return newAttrs
      })
    } else {
      configs = children.map(item => {
        const type = _.get(item, 'attrs.type', '')
        if (type === 'group') {
          const {attrs, children} = item
          const attrsChildren = children.map(e => e.attrs)
          const newAttrs = {...attrs, children: attrsChildren}
          return newAttrs
        }
        return item.attrs
      })
    }
    return configs
  }

  onSave = async () => {
    try {
      let { showNotification, match = {}, push } = this.props
      let { params = {} } = match
      const {stage, background, record, actualWidth} = this.state
      const configs = this.getConfigsDynamic()
      ////const configs = this.convertConfigs(result)
      //var contentLayer = stage.findOne('#content')
      //const {children = []} = contentLayer
      //const configs = this.convertConfigs(children)
      background.actualWidth = actualWidth
      const data = {
        ...record,
        layout: JSON.stringify(configs),
        background
      }
      const res = await Provider.dataProvider('UPDATE','ticketlayouts', {
        id: params.id,
        data,
      })
      if (res && res.data) {
        showNotification('notification.updated')
        push('/ticketlayouts')
      }
    } catch(e) {
      showNotification(e.message, 'warning')
    }
  }

  getConfigsDynamic = () => {
    const {stage, layer, background, layoutType} = this.state
    let configs
    if (layoutType !== 'dynamic') {
      var contentLayer = stage.findOne('#content')
      const {children = []} = contentLayer
      configs = this.convertConfigs(children)
    } else {
      const result = []
      const shapes = stage.find('.item')
      const contentSection = {type: 'group',name: 'body', x: 0, y: 0, width: background.width, height: background.height}
      const lineHeader = layer.findOne('#header_line')
      if (lineHeader) {
        const lineBox = lineHeader.getPoints()
        const attrs = lineHeader.getAttrs()
        lineBox[1] = lineBox[1] + (attrs.y || lineBox[0])
        const section = {type: 'group', name: 'header', x: 0, y: 0, width: background.width, height: lineBox[1]}
        const selected = shapes.filter((shape) => this.haveIntersection(section, shape.getAttrs()))
        section.children = selected
        result.push(section)
        contentSection.y = lineBox[1]
        contentSection.height = background.height - lineBox[1]
      }
      const lineFooter = layer.findOne('#footer_line')
      if (lineFooter) {
        const lineBox = lineFooter.getPoints()
        const attrs = lineFooter.getAttrs()
        lineBox[1] = lineBox[1] + (attrs.y || lineBox[0])
        const section = {type: 'group', name: 'footer', x: 0, y: lineBox[1], width: background.width, height: background.height - lineBox[1]}
        const selected = shapes.filter((shape) => this.haveIntersection(section, shape.getAttrs()))
        section.children = selected
        result.push(section)
        contentSection.height = contentSection.height - (section.height)
      }
      const selected = shapes.filter((shape) => this.haveIntersection(contentSection, shape.getAttrs()))
      contentSection.children = selected
      result.push(contentSection)
      configs = this.convertConfigs(result)
    }
    return configs
  }

  onPreview = () => {
    const {preview} = this.state
    if (preview) {
      this.setState({preview: !this.state.preview})
    } else {
      const configs = this.getConfigsDynamic()
      //const configs = this.convertConfigs(result)

      this.setState({preview: !this.state.preview, configs: JSON.stringify(configs)})
    }
  }
  haveIntersection = (box, shape) => {
    const pos1 = this.isInside(box, {x: shape.x, y: shape.y})
    const pos2 = this.isInside(box, {x: shape.x + shape.width, y: shape.y})
    return  pos1 || pos2
  }

  isInside = (box, pos) => {
    const {x: p_x, y: p_y} = pos
    const {x, y, width, height} = box
    const min_x = x
    const max_x = min_x + width
    const min_y = y 
    const max_y = min_y + height 
    return ((min_x <= p_x) && (p_x <= max_x)) && ((min_y <= p_y) && (p_y <= max_y))
  }

  onAddLine = async (type) => {
    const {layer, background} = this.state
    const id = `#${type}_line`
    const findLine = layer.findOne(id)
    if (findLine) return
    switch(type) {
      case 'header' : {
        const line = new Konva.Line({
          name: 'line',
          type: 'line',
          points: [0, 100, Number(background.width), 100],
          stroke: 'green',
          strokeWidth: 2,
          lineJoin: 'round',
          draggable: true,
          dash: [10, 5],
          id: 'header_line',
          dragBoundFunc: function (pos) {
            return {
              x: this.absolutePosition().x,
              y: pos.y,
            }
          },
        })
        layer.add(line)
        break
      }
      case 'footer' : {
        const line = new Konva.Line({
          type: 'line',
          name: 'line',
          points: [0, Number(background.height) - 100, Number(background.width), Number(background.height) - 100],
          stroke: 'red',
          draggable: true,
          strokeWidth: 2,
          lineJoin: 'round',
          dash: [10, 5],
          id: 'footer_line',
          dragBoundFunc: function (pos) {
            return {
              x: this.absolutePosition().x,
              y: pos.y,
            }
          },
        })
        layer.add(line)
        break
      }
      default: return null
    }
  }

  onCloneObj = async () => {
    const {selectedItem, layer} = this.state
    const attrs = selectedItem.getAttrs()
    const type = _.get(attrs, 'type', '')
    const height = attrs.height || attrs.fontSize || attrs.size
    const clone = {...attrs, id: genId(8), y: Number(attrs.y) + height}
    switch (type) {
      case 'textarea' : {
        const item = new Konva.Text(clone)
        layer.add(item)
        break
      }
      case 'qr' : {
        const item = new Konva.Rect(clone)
        layer.add(item)
        break
      }
      case 'image' : {
        const item = new Konva.Image(clone)
        layer.add(item)
        break
      }
      default: return ''
    }
  }

  onCreateObj = async (type, e) => {
    const {layer, stage, background} = this.state
    let scale = stage.scaleX()
    switch(type) {
      case 'textarea' : {
        const newText = {
          type: 'textarea',
          text: 'Reservation name',
          content: 'reservation_name',
          x: 10,
          y: 10,
          opacity:1,
          width: 200 / scale,
          fontSize:20 / scale,
          fontFamily: 'Open Sans',
          align: 'left',
          //shadowColor: '#333',
          //shadowOffset: { x: 1, y: 1 },
          draggable: true,
          name: 'item',
          id: genId(8),
        }
        var text = new Konva.Text(newText)
        layer.add(text)
        break
      }
      case 'qr' : {
        const newRect = {
          type: 'qr',
          x: 10,
          y: 10,
          width: 100 / scale,
          height: 100 / scale,
          fill: '#2e2e2e',
          name: 'item',
          draggable: true,
          id: genId(8),
        }
        var rect = new Konva.Rect(newRect)
        layer.add(rect)
        break
      }
      case 'image' : {
        const file = e.target.files[0]
        const base64 = await this.convertBase64(file)
        var newImage = new Image()
        newImage.onload = function () {
          var image = new Konva.Image({
            type: 'image',
            x: 10,
            y: 10,
            text: base64,
            image: newImage,
            width: 100,
            height: 100,
            name: 'item',
            draggable: true,
            id: genId(8),
          })
          layer.add(image)
        }
        newImage.src = base64 
        break
      }
      default: 
        return null
    }
  }

  addRect = () => {
    const {layer} = this.state
    var text = new Konva.Rect({
      x: 10,
      y: 15,
      text: 'Text test',
      fontSize: 30,
      fontFamily: 'Calibri',
      fill: 'green',
      draggable: true,
    })
    layer.add(text)

  }

  addText = () => {
    const {layer} = this.state
    var text = new Konva.Text({
      x: 10,
      y: 15,
      text: 'Text test',
      fontSize: 30,
      fontFamily: 'Calibri',
      fill: 'green',
      draggable: true,
    })
    layer.add(text)
  }

  onStylesText = (e, id) => {
    const {stage} = this.state
    const findText = stage.findOne(`#${id}`)
    const name = e.target.name
    const value = e.target.value
    if (name === 'content') {
      const mappingText = contents[value]
      findText.attrs[name] = value
      findText.attrs['text'] = mappingText
    } else {
      findText.attrs[name] = value
    }
    //this.state.layer.batchDraw()
    this.setState({selectedItem: findText})
  }

  onHandleStyleText = (name, value) => {
    const {stage, selectedId: id} = this.state
    const findText = stage.findOne(`#${id}`)
    const attrs = findText.getAttrs() || {}
    switch(name) {
      case 'content':{
        if (attrs.type === 'textarea') {
          const mappingText = value !== 'other' ? contents[value] : ''
          findText.text(mappingText)
        } 
        findText.attrs[name] = value
        break
      }
      case 'text':{
        if(attrs.type === 'textarea') {
          findText.text(value)
        } else {
          findText.attrs[name] = value
        }
        break
      }
      case 'formatType':{
        findText.attrs[name] = value
        break
      }
      case 'color':{
        findText.fill(value)
        break
      }
      case 'size':{
        findText.width(value)
        findText.height(value)
        break
      }
      default: findText[name](value)
    }
    this.state.layer.batchDraw()
    this.setState({selectedItem: findText})
  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  onChangeBackground = async (e, isBlank, options = {}) => {
    if (isBlank) {
      this.setState({background: {...options}})
    } else {
      const file = e.target.files[0]
      const type = file.type
      const base64 = await this.convertBase64(file)
      this.setState({background: {src: base64, type, ...options}})
      //const res = await Provider.dataProvider('REMOTE', 'ticketlayouts', {
      //method: 'getMetadata',
      //requestMethod: 'POST',
      //data: { image: base64}
      //})
      //this.setState({background: {...res.data, ...options}})
    }
  }

  onChangeConfigs = (e) => {
    const {configs} = this.state
    this.setState({configs: e.target.value})
  }

  handleJsonConfigs = () => {
    const {configs, background} = this.state
    const isJson = isJsonString(configs)
    if (isJson) {
      this.renderKonva(configs, background)
      this.setState({preview: false})
    }
  }

  render() {
    const {
      selectedItem = {},
      configs,
      selectedId,
      preview,
      background,
      loading,
      actualWidth,
      layoutType,
    } = this.state
    const {classes, translate} = this.props

    return (
      <div className={classes.container}>
        <div className={classes.left}>
          <CardCustom
            title={translate('resources.editor.name')}
            action={
              <>
                <Tooltip
                  title={translate('resources.editor.button.preview')}
                  enterDelay={100}
                >
                  <IconButton size="small" onClick={this.onPreview} >
                    <CodeIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={translate('resources.editor.button.save')}
                  enterDelay={100}
                >
                  <IconButton size="small" onClick={this.onSave} >
                    <SaveIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
          >
            <div className={classes.konva} >
              <div
                id="konva_container"
                className={classes.konvaContainer}
                style={preview ? {display: 'none'} : {}}
              ></div>
            </div>
            {(preview && configs) && <div className={classes.textConfig}>
              <TextField multiline value={configs} onChange={this.onChangeConfigs} style={{width: '100%'}} rowsMax="20" variant="outlined" />
              <div className={classes.configBtns}>
                <Button 
                  variant="outlined"
                  onClick={() => this.setState({preview: false})}
                >{translate('resources.editor.button.cancel')}</Button>
                <Button 
                  variant="outlined"
                  onClick={this.handleJsonConfigs}
                >{translate('resources.editor.button.apply')}</Button>
              </div>
            </div>}
          </CardCustom>
        </div>
        <div className={classes.right}>
          <CardCustom
            title={translate('resources.editor.common.editObj')}
            action={
              selectedId && 
                <Tooltip
                  title={translate('resources.editor.button.delete')}
                  enterDelay={100}
                >
                  <IconButton size="small" onClick={this.onDelete} >
                    <DeleteOutlineIcon color="error" />
                  </IconButton>
                </Tooltip>
            }
          >
            {selectedId ? 
              <TextEditor 
                selectedId={selectedId}
                selectedSharp={selectedItem}
                onChange={this.onStylesText}
                onHandleStyleText={this.onHandleStyleText}
              /> :
              <StageEditor 
                width={_.get(background, 'width', DEFAULT_WIDTH)}
                height={_.get(background, 'height', DEFAULT_HEIGHT)}
                actualWidth={actualWidth || DEFAULT_ACTUAL_WIDTH}
                handleActualWidth={(e) => {this.setState({actualWidth: Number(e.target.value)})}}
                onChangeBackground={this.onChangeBackground}
              />
            }
          </CardCustom>
          <CardCustom
            title={translate('resources.editor.common.createObj')}
          >
            <div className={classes.group}>
              <Button 
                variant="outlined"
                onClick={()=>this.onCreateObj('textarea')}
                style={{flex: 1}}
              >Text</Button>
              <Button 
                variant="outlined"
                onClick={()=>this.onCreateObj('qr')}
                style={{flex: 1}}
              >QR code</Button>
            </div>
            <div className={classes.group}>
              <div style={{flex: 1}}>
                <input
                  accept="image/*"
                  style={{ display: 'none' }}
                  id="raised-button-file"
                  type="file"
                  onChange={(e)=>this.onCreateObj('image', e)}
                />
                <label htmlFor="raised-button-file" style={{flex: 1}}>
                  <Button 
                    fullWidth
                    variant="outlined"
                    component="span"
                  >Image</Button>
                </label>
              </div>
              {selectedId ? <div style={{flex: 1}}> <Button 
                variant="outlined"
                onClick={this.onCloneObj}
                style={{flex: 1, width: '100%'}}
              >Clone</Button></div> : <div style={{flex: 1}}></div>}
            </div>
            {
              layoutType === 'dynamic' && 
              <div className={classes.group}>
                <Button 
                  variant="outlined"
                  onClick={()=>this.onAddLine('header')}
                  style={{flex: 1}}
                >Header</Button>
                <Button 
                  variant="outlined"
                  onClick={()=>this.onAddLine('footer')}
                  style={{flex: 1}}
                >Footer</Button>
              </div>
            }
          </CardCustom>
        </div>
      </div>
    )
  }
}

export default compose(withStyles(styles), translate, connect(null, { showNotification, push }))(TicketEditor)
