import React from 'react'
import { blue, green, grey, red, yellow, orange } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core'
import { ChipField } from 'react-admin'
import get from 'lodash/get'

const typeStyles = {
  '00TST': { color: 'purple', backgroundColor: yellow[500] },
  '10TBL': { color: 'white', backgroundColor: orange[500] },
  '20BES': { color: 'white', backgroundColor: blue[500] },
  '21BES': { color: 'white', backgroundColor: blue[500] },
  '30ONS': { color: 'white', backgroundColor: green[500] },
  '31ONP': { color: 'white', backgroundColor: green[500] },
  '50AFS': { color: 'white', backgroundColor: grey[500] },
  '51AFS': { color: 'white', backgroundColor: grey[500] },
  '90CAN': { color: 'white', backgroundColor: red[500] },
}

export const TypeField = withStyles(typeStyles)(({ classes, ...props }) => {
  let type = get(props.record, 'id')
  let className = get(classes, type)
  return (
    <ChipField className={className} {...props} />
  )
})