import React, { Component } from 'react'
import MenuSetting, { ON_PARENT, OFF_PARENT } from './MenuSetting'

class SettingMenu extends Component {

  handleChild = (menus, childIds, key) => {
    let options
    let apiMap = []
    const actionMap = {
      parent: {
        off: () => {
          if (childIds[key]) {
            apiMap = childIds[key]
              .filter(childId => !menus[childId].hide)
            apiMap.push(key)
            options = {
              action: OFF_PARENT,
              key
            }
          }
        },
        on: () => {
          if (childIds[key]) {
            options = {
              action: ON_PARENT,
              key
            }
            apiMap = childIds[key]
              .filter(childId => menus[childId].hide)
            apiMap.push(key)
          }
        }
      },
      child: {
        off: () => {
          let isOff = !menus[key].hide
          if (!isOff) return
          let parentKey = key.slice(0, key.indexOf('/', 1))
          let isAllOff = childIds[parentKey].every(child => {
            let childItem = menus[child]
            if (childItem.keyName === key) return true
            return childItem.hide
          })
          if (isAllOff) {
            apiMap = [parentKey, key]
            options = {
              action: OFF_PARENT,
              key: parentKey
            }
          }
        }
      }
    }
    let isParent = key.split('/').length === 2
    if (isParent) {
      let parentItem = menus[key]
      let isOnParent = parentItem.hide
      actionMap.parent[isOnParent ? 'on' : 'off']()
    } else {
      actionMap.child.off()
    }
    return options
  }

  handleKey = name => {
    return `/${name}`
  }
  render() {
    let { record, items, resources } = this.props
    return <MenuSetting 
      items={items}
      record={record}
      hideMenu="webapp"
      handleChild={this.handleChild}
      handleKey={this.handleKey}
      resources={resources}
    />
  }
}

export default SettingMenu
