import React, { Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  CardActions,
  ReferenceField,
  Filter,
  ReferenceInput,
  SelectInput,
  FunctionField,
  translate,
  Responsive,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import RegisterRouteButton from './RegisterRouteButton'
import RegisterAgencyButton from './RegisterAgencyButton'
import * as permission from '../utils/permission'
import { withStyles, Tooltip } from '@material-ui/core'
import SearchInput from '../common/SearchInput'
import moment from 'moment'
import compose from 'recompose/compose'
import { Provider } from '../provider'
import ProductActions from './ProductAction'
import CardAvatar from '../common/CardAvatar'
import HashTags from '../common/HashTags'
import ProductsMobileGrid from './ProductsMobileGrid'
import SaveDescriptionButton from './SaveDescriptionButton'
import LinkField from './../common/LinkField'
import { Edit as EditIcon } from '@material-ui/icons'
import _ from 'lodash'

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
  }
})

const ProductListFilter = withStyles(styleListFilter)(({ classes, ...props }) => {
  return <Filter {...props}>
    <SearchInput 
      helperText="resources.products.searchHelperText"
      source="q"
      alwaysOn
    />
    <ReferenceInput
      reference="productstatuses"
      source="status"
      alwaysOn
      className={classes.otherSearchInput}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
    <ReferenceInput
      reference="producttypes"
      source="type"
      alwaysOn
      className={classes.otherSearchInput}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
})

////////////////////////////////////////////////////////////////////////////////
// Product
const styleList = () => ({
  textCenter: {
    textAlign: 'center',
  },
  avatarCol: {
    display: 'flex'
  },
  name: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
})


export class _ProductList extends Component {

  state = {}

  async componentDidMount() {
    let listTag = await this.getTags()
    this.setState({ listTag })
  }

  getTags = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'tags', {
      filter: {
        '../fields': ['id', 'name', 'color']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  render() {
    let { history, permissions, translate, classes, ...props} = this.props
    let { listTag } = this.state
    let companyManager = permission.isCompanyManager(permissions)
    return <List
      filters={<ProductListFilter />}
      bulkActionButtons={false}
      filter={{
        '../withOtherAgencyCount': true,
        '../withRouteCount': true,
        '../withTags': true,
        parentProductId: null,
      }}
      actions={<ProductActions />}
      {...props}
    >
      <Responsive
        xsmall={<ProductsMobileGrid companyManager={companyManager} history={history} />}
        medium={
          <Datagrid>
            <FunctionField
              source="name"
              render={record => <div className={classes.avatarCol}>
                <CardAvatar record={record} source="images" size='small' />
                <span className={classes.name}>{record.name}</span>
              </div>}
            />
            <ReferenceField
              reference="producttypes"
              source="type"
              allowEmpty
              linkType={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
            >
              <TextField source="name" />
            </ReferenceField>
            <FunctionField 
              source="validDate"
              sortable={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              render={({ validFrom, validTo }) => `${moment(validFrom).format('HH:mm DD/MM/YYYY')} - ${moment(validTo).format('HH:mm DD/MM/YYYY')}`}
            />
            <FunctionField 
              source="quantity"
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              render={({ quantity }) => quantity < 0 ? translate('resources.products.no_limit') : (quantity ? quantity.toLocaleString() : 0)}
            />
            <FunctionField
              sortable={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="agencyCount"
              render={({ agencyCount = 0 }) => agencyCount ? agencyCount : 0}
            />
            <TextField
              sortable={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="routeCount"
            />
            <ReferenceField
              reference="productstatuses"
              source="status"
              allowEmpty
              linkType={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
            >
              <TextField source="name" />
            </ReferenceField>
            {listTag && <FunctionField
              sortable={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="tags"
              render={({ id, tags }) => <HashTags
                listTag={listTag}
                tags={tags}
                principal="Product"
                principalId={id}
              />}
            />}
            <FunctionField
              headerClassName={classes.textEnd}
              cellClassName={classes.textEnd}
              render={({ id }) => {
                return companyManager && <Tooltip
                  title={translate('button.edit')}
                  enterDelay={100}
                >
                  <LinkField
                    className={classes.iconButton}
                    path={`/products/${id}`}
                    icon
                  >
                    <EditIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
              }}
            />
          </Datagrid>}
      />
    </List>
  }
} 

const enhanceList = compose(withStyles(styleList), translate)
export const ProductList = enhanceList(_ProductList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const ProductCreate = props => (
  <Create
    undoable="false"
    title={<EditTitle resource={props.resource} />}
    {...props}
  >
    <FormSave />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit

const EditAction = ({ 
  id,
  permissions,
  commissionTemplates,
  setNewRoute,
  setNewAgency,
}) => {
  let isCompanyManager = permission.isCompanyManager(permissions)
  return <CardActions>
    {isCompanyManager && <SaveDescriptionButton
      productId={id}
    />}
    {isCompanyManager && <RegisterRouteButton
      productId={id}
      setNewRoute={setNewRoute}
    />}
    {isCompanyManager && <RegisterAgencyButton
      commissionTemplates={commissionTemplates}
      productId={id}
      setNewAgency={setNewAgency}
    />}
  </CardActions>
}

export class ProductEdit extends Component {

  state = {}

  async componentDidMount() {
    let commissionTemplates = await this.getCommissionTemplates()
    this.setState({ commissionTemplates })
  }

  getCommissionTemplates = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'commissiontemplates', {
      filter: {
        '../fields': ['id', 'name', 'parameters']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  setNewRoute = (newRouteProduct) => {
    this.setState({ newRouteProduct })
  }

  setNewAgency = (newAgency) => {
    this.setState({ newAgency })
  }

  render() {
    let { id, permissions, resource, ...props } = this.props
    id = _.toNumber(id)
    let { commissionTemplates, newRouteProduct, newAgency }= this.state
    return <Edit
      id={id}
      undoable={false}
      resource={resource}
      title={<EditTitle resource={resource} />}
      actions={<EditAction
        id={id}
        permissions={permissions}
        commissionTemplates={commissionTemplates}
        setNewRoute={this.setNewRoute}
        setNewAgency={this.setNewAgency}
      />}
      {...props}
    >
      <FormSave
        id={id}
        permissions={permissions}
        commissionTemplates={commissionTemplates}
        newRouteProduct={newRouteProduct}
        newAgency={newAgency}
      />
    </Edit>
  }
}
