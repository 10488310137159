import React, { Component} from 'react'
import { withStyles } from '@material-ui/core'
import TabPos from './TabPos'
import compose from 'recompose/compose'
import AgencyPosSetting from './AgencyPosSetting'
import DriverPosSetting from './DriverPosSetting'

const styles = theme => ({
  tabContainer: {
    padding: theme.spacing.unit * 3,
    width: '100%',
  }
})

class SettingPos extends Component {
  render() {
    let {
      record,
      isAdmin,
      classes,
      match,
      location,
      resources,
      tabPos,
      tabIndexPos,
      onChangeTabPos,
      onChangeTabIndexPos,
    } = this.props
    return <div className={classes.tabContainer}>
      <TabPos
        tabPos={tabPos}
        onChangeTabPos={(v) => {
          onChangeTabPos(v)
        }}
        isAdmin={isAdmin}
        action='update'
      />
      {isAdmin && tabPos === 0 && <div className={classes.tabContainer}>
        <AgencyPosSetting
          match={match}
          record={record}
          location={location}
          tabIndexPos={tabIndexPos}
          resources={resources}
          onChangeTabIndexPos={onChangeTabIndexPos}
          isAdmin={isAdmin}
        />
      </div>}
      {isAdmin && tabPos === 1 && <div className={classes.tabContainer}>
        <DriverPosSetting
          match={match}
          record={record}
          location={location}
          tabIndexPos={tabIndexPos}
          resources={resources}
          onChangeTabIndexPos={onChangeTabIndexPos}
          isAdmin={isAdmin}
        />
      </div>}
    </div>
  }
}

export default compose(
  withStyles(styles)
)(SettingPos)
