import {
  withStyles,
  Grid,
  Dialog,
  DialogTitle,
} from '@material-ui/core'
import {
  translate,
  TextInput,
  SimpleForm,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import numeral from 'numeral'
import { currencyFormat } from '../common/format'
import { reset } from 'redux-form'
import FormBody from '../common/FormBody'
import FareTable from '../common/FareTable'

const styles = (theme) => ({
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  chip: {
    margin: 8,
    width: 50
  },
  textField: {
    marginTop: 16,
    marginBottom: 8
  }
})

class SelectSeatModal extends Component {

  state = {
    productId: 0,
    fareId: 0,
    open: false,
    seat: '',
  }

  componentDidMount() {
    let { reset } = this.props
    reset('select-seat-form')
    this.searchFare()
  }

  static getDerivedStateFromProps(nextProps, state) {
    let { roundType, inProgressBooking, productId } = nextProps
    let seat = nextProps.seat

    let result = {
      productId,
      open: nextProps.open,
      seat,
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      address: null
    }

    if (inProgressBooking && inProgressBooking[roundType]) {
      let { seats } = inProgressBooking[roundType]
      let progressingSeat = seats && seats[seat]
      if (progressingSeat) {
        let { firstName, lastName, phone, email, address } = progressingSeat

        result.firstName = firstName
        result.lastName = lastName
        result.phone = phone
        result.email = email
        result.address = address
      }
    }

    return result
  }

  searchFare = async () => {
    let { roundType, trips } = this.props
    let trip = trips[roundType]
    let fareTable = await FareTable.createFromTrip(trip)
    this.setState({ fareTable })
    /*
    let [fareTable, fareConditions] = await Promise.all([getFareTable(trip), getFareConditions()])
    fareTable.prices.forEach((price, index) => {
      price.index = index
    })
    fareConditions = _.mapKeys(fareConditions, item => item.id)
    this.setState({ fareTable, fareConditions })
    */
  }

  searchPassenger = async (search) => {
    let response = await Provider.dataProvider('GET_LIST', 'passengers', {
      filter: { where: search },
      sort: {},
      pagination: { page: 1, perPage: 10 },
    })

    if (response) {
      let { data } = response
      if (data && data.length > 0) {
        let res = data[0]
        let { firstName, lastName, phone, email, address } = res
        this.setState({
          firstName,
          lastName,
          phone,
          email,
          address,
        })
      }
    }
  }

  onClose = () => this.setState({ open: false })

  onSave = (record) => {

    let { seat, fareTable } = this.state
    let { onSave } = this.props
    let { fareType: index, fareCode: code } = record

    let fareCharge = fareTable.getChargeFare({ index, code })
    if (fareCharge) {
      onSave({ ...record, ...fareCharge, seat })
    }
  }

  render() {
    let {
      roundType, inProgressBooking, translate
    } = this.props

    let { open, seat, fareTable } = this.state
    let record = {
      fareType: 0,
      fareCode: 'adult',
    }

    if (inProgressBooking && inProgressBooking[roundType]) {
      let { seats } = inProgressBooking[roundType]
      let progressingSeat = seats && seats[seat]
      if (progressingSeat) {
        let { firstName, lastName, phone, email, address } = progressingSeat
        record = { firstName, lastName, phone, email, address }
      }
    }

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        autoFocus={false}
        maxWidth="lg"
      >
        <DialogTitle>
          {`Thông tin hành khách đặt chỗ ${seat}`}
        </DialogTitle>
        <SimpleForm
          form="select-seat-form"
          record={record}
          save={this.onSave}
        >
          <FormBody>
            <Grid container spacing={8}>
              <Grid item xs={4}>
                <TextInput
                  label={translate('resources.reservations.passenger.phone')}
                  source="phone"
                  onBlur={(e, val) => this.searchPassenger({ phone: val })}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  label={translate('resources.reservations.passenger.firstName')}
                  source="firstName"
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  label={translate('resources.reservations.passenger.lastName')}
                  source="lastName"
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                {fareTable && <SelectInput
                  source="fareType"
                  choices={fareTable.getBasePriceTable()}
                  optionText={price => numeral(price.basePrice).format(currencyFormat)}
                  optionValue="index"
                  fullWidth
                />}
                <FormDataConsumer>
                  {({ formData }) => {
                    if (!fareTable) return null
                    let { fareType = 0 } = formData
                    fareTable.setActive(fareType)
                    let choices = fareTable.getPriceTable()
                    return (<SelectInput
                      source="fareCode"
                      choices={choices}
                      optionText={r => fareTable.getPriceLabel(r)}
                      optionValue="code"
                      fullWidth
                    />)
                  }}
                </FormDataConsumer>
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  label={translate('resources.reservations.passenger.email')}
                  source="email"
                  type="email"
                  fullWidth
                />
              </Grid>

              <Grid item xs={4}>
                <TextInput
                  label={translate('resources.reservations.passenger.address')}
                  source="address"
                  fullWidth
                />
              </Grid>
            </Grid>
          </FormBody>
        </SimpleForm>
      </Dialog>
    )
  }
}

export default compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { reservation } = state
      let { trips, inProgressBooking } = reservation
      return { trips, inProgressBooking }
    },
    { reset }
  )
)(SelectSeatModal)
