import React, { Component, createRef, Fragment } from 'react'
import L from 'leaflet'
import {
  getRouting,
  coordinatesToLatLngs,
  getMouseEvtLatLng,
  createStopDetails,
  getLocationPoint,
} from './utils'
import { Provider } from '../provider'
import Map from './map2'
import _ from 'lodash'
import { lineString, length } from '@turf/turf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import 'leaflet.smooth_marker_bouncing'
import {
  faSignOutAlt,
  faSignInAlt,
  faLink,
  faUnlink,
  faEdit,
  faExchangeAlt,
  faMapMarker,
} from '@fortawesome/free-solid-svg-icons'
import {
  MenuItem,
  ListItem,
  ListItemText,
  Button,
  Divider,
  withStyles,
  Avatar,
  Dialog,
  DialogTitle,
  Popover,
  DialogActions,
  DialogContent,
  DialogContentText,
  withWidth,
} from '@material-ui/core'
import {
  SimpleForm,
  TextInput,
  DisabledInput,
  translate,
  Title,
  WithPermissions,
  required,
} from 'react-admin'
import { validate } from './validate'
import { SubmissionError } from 'redux-form'
import 'leaflet-geosearch/assets/css/leaflet.css'
import { showNotification } from 'ra-core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import NavigationBlocker from '../common/NavigationBlocker'
import { changeRoute as changeRouteAction } from './actions'
import { changeBreadcrumb } from '../breadcrumb/action'
import { push } from 'react-router-redux'
import CustomToolbar from '../common/CustomToolbarForm'
import DialogLoading from '../common/DialogLoading'
import { isCompanyManager } from '../utils/permission'
import SetupPassengerCollectionDialog from './SetupPassengerCollectionDialog'

require('leaflet.markercluster/dist/MarkerCluster.css')
require('leaflet.markercluster/dist/MarkerCluster.Default.css')

const styles = {
  stopList: {
    maxHeight: 500,
    overflow: 'auto',
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 600,
    paddingTop: 8
  },
  menuButton: {
    display: 'grid',
    justifyItems: 'center'
  },
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

const iconImages = {
  stop: '/images/markers/marker-icon-2x.png',
  pickup: '/images/markers/marker-pickup-icon-2x.png',
  dropoff: '/images/markers/marker-dropoff-icon-2x.png',
  both: '/images/markers/marker-both-icon-2x.png',
  start: '/images/markers/start-2x.png',
  end: '/images/markers/end-2x.png',
}

let anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
let transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

let passengerCollectionTypeMapping = {
  '00PICKUP' : 'pickup',
  '10DROPOFF' : 'dropoff',
  '20BOTH' : 'both',
}

const DialogConfirm = ({ confirmDialog = {}, translate }) => {
  let { onClose, open, onSave, title = '', content } = confirmDialog
  return (
    <Dialog
      open={open || false}
      onClose={() => onClose()}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onSave()}
          color="primary"
          autoFocus
          variant="contained"
        >
          {translate('button.ok')}
        </Button>
        <Button onClick={() => onClose()} color="primary">
          {translate('button.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const StopEditDialog = withStyles(styles)(({
  classes,
  translate,
  editStopDialog: {
    stop,
    onSave,
    onClose,
  },
}) => {
  const open = Boolean(stop)
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>
          {stop && stop.id ? translate('resources.common.edit') : translate('resources.common.create')}
        </span>
      </DialogTitle>
      <SimpleForm
        toolbar={<CustomToolbar />}
        record={{ ...stop }}
        save={record => onSave(record)}
        form={stop && stop.id ? 'edit-stop-form' : 'create-stop-form'}
        validate={validate}
      >
        <TextInput
          label={translate('resources.stops.name_stop')}
          fullWidth
          source="name"
          validate={required()}
        />
        <TextInput
          label={translate('resources.stops.code')}
          fullWidth
          source="code"
          validate={required()}
        />
        <TextInput
          label={translate('resources.stops.address')}
          fullWidth
          source="address"
        />
        <DisabledInput label={translate('resources.stops.lat')} fullWidth source="lat" />
        <DisabledInput label={translate('resources.stops.lon')} fullWidth source="lon" />
      </SimpleForm>
    </Dialog>
  )
})

const StopContextMenu = withStyles(styles)(({
  anchor: { anchorEl, stop, open, nearestStop, doSwap, doAttach },
  onClose,
  onSetAsStart,
  onSetAsEnd,
  onAttach,
  onDetach,
  onEditStop,
  clickFromMarker,
  anchorOrigin,
  transformOrigin,
  translate,
  swapStop,
  detachPointFromRoute,
  onSetupPassengerCollection,
}) => <Popover
  anchorEl={anchorEl}
  open={open}
  onClose={onClose}
  anchorOrigin={anchorOrigin}
  transformOrigin={transformOrigin}
>
  {clickFromMarker && <>
    <ListItem>
      <Avatar aria-label={stop.name} title={stop.name}>
        {stop.name && stop.name[0]}
      </Avatar>
      <ListItemText primary={`(${_.round(stop.lat, 5)},${_.round(stop.lon, 5)})`} secondary={stop.name} />
    </ListItem>
    <Divider />
  </>}
  {!stop.type && <MenuItem onClick={() => onSetAsStart(stop) && onClose()}>
    <FontAwesomeIcon icon={faSignOutAlt} />
    <ListItemText>
      {translate('button.set_as_start')}
    </ListItemText>
  </MenuItem>}
  {!stop.type && <MenuItem onClick={() => onSetAsEnd(stop) && onClose()}>
    <FontAwesomeIcon icon={faSignInAlt} />
    <ListItemText>
      {translate('button.set_as_end')}
    </ListItemText>
  </MenuItem>}
  <Divider />
  {!stop.type && <MenuItem disabled={!doAttach} onClick={() => onAttach(stop) && onClose()}>
    <FontAwesomeIcon icon={faLink} />
    <ListItemText>
      {translate('button.attach')}
    </ListItemText>
  </MenuItem>}
  {stop.type && <MenuItem onClick={() => {
    switch (stop.type) {
      case 'stop':
        onDetach(stop)
        break
      case 'start':
      case 'end':
        detachPointFromRoute(stop, nearestStop)
        break
      default:
    }
    onClose()
  }}>
    <FontAwesomeIcon icon={faUnlink} />
    <ListItemText>
      {translate('button.detach_stop')}
    </ListItemText>
  </MenuItem>}
  <MenuItem onClick={() => onEditStop(stop) && onClose()}>
    <FontAwesomeIcon icon={faEdit} />
    <ListItemText>
      {translate('button.edit_stop')}
    </ListItemText>
  </MenuItem>
  {(!stop.type || stop.type === 'stop') && <MenuItem onClick={() => { 
    onSetupPassengerCollection(stop)
    onClose()
  }}>
    <FontAwesomeIcon icon={faMapMarker} />
    <ListItemText>
      {translate('button.setup_passenger_collection')}
    </ListItemText>
  </MenuItem>}
  {/*!stop.type && <MenuItem onClick={() => onRemoveStop(stop) && onClose()}>
    <FontAwesomeIcon icon={faTrash} />
    <ListItemText>
      {translate('button.remove_stop')}
    </ListItemText>
  </MenuItem>*/}
  {(stop.type === 'start' || stop.type === 'end') && <MenuItem
    disabled={!doSwap}
    onClick={() => swapStop(stop) && onClose()}
  >
    <FontAwesomeIcon icon={faExchangeAlt} />
    <ListItemText>
      {translate('button.swap_stop')}
    </ListItemText>
  </MenuItem>}
</Popover>
)

class NetworkPlanningMap extends Component {

  constructor(props) {
    super(props)
    this.map = createRef()
    this.state = {
      loading: false,
    }
  }

  createTempMarker = (latlng) => {
    let map = this.map.current.getMap()
    this.newMarker = L.marker(latlng, {
      opacity: 0.5
    })
    map.addLayer(this.newMarker)
  }

  removeTempMarker = () => {
    if (!this.newMarker) return
    let map = this.map.current.getMap()
    map.removeLayer(this.newMarker)
  }

  onMapClick = async () => {
    let { locale } = this.props
    if (this.newStopMarker) {
      this.setState({ loading: true })
      let latlng = this.newStopMarker.getLatLng()
      this.createTempMarker(latlng)
      this.hideNewStopMarker()
      
      // enriches stop with address and place id
      let stop = await createStopDetails(latlng.lat, latlng.lng, locale)
      if (!stop) {
        alert('Không tìm thấy thông tin địa chỉ của điểm dừng')
        return
      }
      this.setState({ loading: false })
      await this.createStop(stop)
    }
  }

  onMouseMove = (evt) => {
    if (!this.newStopMarker) return
    const map = this.map.current.getMap()
    let latlng = getMouseEvtLatLng(map, evt, [0, 35])
    this.newStopMarker.setLatLng(latlng)
  }

  createStop = async (stop) => {
    this.showEditStopDialog({
      stop,
      onSave: async (record) => {
        return this.saveStopToServer(record)
      },
      onClose: () => {
        this.hideEditStopDialog()
        this.removeTempMarker()
      },
    })
  }

  saveStopToServer = (record) => {
    const { showNotification } = this.props
    return Provider.dataProvider('CREATE', 'stops', {
      data: record 
    }).then(
      response => {
        let { totalStop, stops } = this.state
        if (response && response.data) {
          let stop = response.data
          this.stopsMap[stop.id] = stop
          this.addStopToGroupLayer(this.stopLayerGroup, stop)
          this.setState({ totalStop: totalStop + 1, stops: [...stops, stop] })
          this.removeTempMarker()
          this.hideEditStopDialog()
          showNotification('notification.create_stop_success')
        }
      },
    ).catch(
      e => {
        showNotification('notification.create_stop_fail', 'warning')
      }
    )
  }

  updateStopData = ({ id, name, lat, lon, code, identifier, companyId }) => {
    let { routeStops, updatedStop = {} } = this.state
    if (routeStops && routeStops.length > 0) {
      for (let i = 0; i < routeStops.length; i++) {
        let routeStop = routeStops[i] || {}
        if (routeStop.id === id) {
          routeStop = { ...routeStop, id, name, lat, lon, code, identifier, companyId }
          routeStops[i] = routeStop
        }
      }
    }
    this.setState({
      updatedStop: { ...updatedStop, id, name, lat, lon, code, identifier, companyId },
      routeStops: routeStops,
    })

  }

  editStop = async (stop) => {
    const { showNotification } = this.props
    this.showEditStopDialog({
      stop,
      onSave: async (record) => {
        return Provider.dataProvider('UPDATE', 'stops', {
          id: stop.id,
          data: record
        }).then(
          response => {
            if (response && response.data) {
              let updatedStop = response.data
              this.updateStopData(updatedStop)
              this.hideEditStopDialog()
              showNotification('notification.update_stop_success')
            }
          }
        ).catch(
          err => {
            let error = err.body.error
            let fieldErro = error.field
            throw new SubmissionError({
              [fieldErro]: error.message,
            })
          })
      },
      onClose: () => {
        this.hideEditStopDialog()
      },
    })
  }

  openRemoveStopDialog = (stop) => {
    const { translate } = this.props
    this.setState({
      confirmDialog: {
        open: true,
        onClose: () => this.hideConfirmDialog(),
        onSave: () => this.removeStop(stop),
        title: translate('notification.remove_stop')
      }
    })
    this.hideStopMenu()
  }

  removeStop = async (stop) => {
    const { showNotification } = this.props
    const { id, passengerCollectionType } = stop
    return Provider.dataProvider('DELETE', 'stops', {
      id: stop.id,
    })
      .then(
        response => {
          if (response && response.data) {
            let { totalStop } = this.state
            this.hideConfirmDialog()
            this.setState({ totalStop: totalStop - 1 })
            if (passengerCollectionType) {
              this.removePolyline(id)
            }
            this.removeStopFromGroupLayer(this.stopLayerGroup, stop)
            showNotification('notification.remove_stop_success')
          }
        }
      )
      .catch(
        () => {
          this.hideConfirmDialog()
          showNotification('notification.remove_stop_failure', 'warning')
        })
  }

  hideNewStopMarker = () => {
    if (this.newStopMarker) {
      this.routeLayer.removeLayer(this.newStopMarker)
      this.newStopMarker = null
    }
  }

  createNewStopMarker = () => {
    if (this.newStopMarker) return
    this.newStopMarker = L.marker([0, 0], {
      zIndexOffset: 20000,
    })
    this.routeLayer.addLayer(this.newStopMarker)
  }

  async componentDidMount() {
    const { changeBreadcrumb, translate, match } = this.props
    let paths = []
    // changeBreadcrumb({ paths })

    const map = this.map.current.getMap()
    // if (!map) return
    // FIXME markerClusterGroup is not a constructor error, workaround
    require('leaflet.markercluster')

    this.stopLayerGroup = new L.markerClusterGroup({ disableClusteringAtZoom: 16 })
    this.routeLayer = new L.layerGroup()
    this.polylineLayer = new L.layerGroup().addTo(map)
    map.addLayer(this.routeLayer)
    map.addLayer(this.stopLayerGroup)
    let { id } = match.params
    if (!id) return
    let route = await this.fetchRoute(id)

    let stopsInfo = await this.fetchStopsInfo(id)
    paths = [
      { label: translate('resources.routegroups.name', { smart_count: 2 }), to: '/routegroups' },
      { label: route.routeGroup.name, to: `/routegroups/${route.routeGroupId}` },
      { label: translate('resources.routes.network_plan'), to: '' },
    ]
    changeBreadcrumb({ paths })

    map.on('editable:editing', function (e) {
      e.layer.setStyle({color: 'DarkRed'})
    })

    if (route.path) {
      let color = route.color ? route.color : 'red'
      let coordinates = route.path.coordinates
      if (coordinates && coordinates.length > 0) {
        this.poly = L.polyline(coordinatesToLatLngs(coordinates), { weight: 7, opacity: 0.6, color: color })
        this.poly.addTo(this.routeLayer)
        let polyBound = this.poly && this.poly.getBounds()
        if (polyBound && polyBound.isValid()) {
          map.fitBounds(this.poly.getBounds())
        }
      }
    }
    this.route = route
    let stops = await this.fetchStops() || []
    await this.normalizeRouteAndStop(route, stops, stopsInfo)
    let canSmartSave = await this.canApplySmartSave()
    this.setState( { canSmartSave, stops: stops, routeStopsInfo: stopsInfo })
  }

  normalizeRouteAndStop = async (route, stops, stopsInfo) => {
    let routeStopIds = route.stops || [] 
    let startId = _.first(routeStopIds)
    let stopId = _.last(routeStopIds)
    let passengerCollections = await this.fetchPassengerCollection(routeStopIds)
    let stopsMap = (stops || []).reduce(function (prev, stop) {
      let id = stop.id
      let passCollection = _.find(passengerCollections, (passengerCollection) => passengerCollection.collectionStopId === id)
      if (passCollection && (id !== startId && id !== stopId)) {
        let { type, stopId } = passCollection
        if (routeStopIds.indexOf(stopId) >= 0) {
          let passengerCollectionType = passengerCollectionTypeMapping[type]
          stop.passengerCollectionType = passengerCollectionType
        }
      }
      prev[id] = stop
      return prev
    }, {})

    routeStopIds.forEach(id => { 
      if (stopsMap[id]) {
        stopsMap[id].type = 'stop'
      }
      let routeStopInfo = stopsInfo[id] 
      let { passengerCollections } = routeStopInfo
      if (passengerCollections && passengerCollections.length) {
        for (let i = 0; i < passengerCollections.length; i++) {
          let collectionStop = passengerCollections[i]
          if (collectionStop && routeStopInfo) {
            this.drawPolyline(routeStopInfo, collectionStop)
          }
        }
      }
    })

    this.stopsMap = stopsMap
    if (startId && stopId) {
      this.startPoint = stopsMap[startId] || {}
      this.endPoint = stopsMap[stopId] || {}
      this.startPoint.type = 'start'
      if (stopId !== startId) {
        this.endPoint.type = 'end'
      } else {
        this.endPoint = null
      }
    }
    stops.forEach((stop) => {
      if (!stop.type) {
        try {
          this.stopLayerGroup.addLayer(this.createStopMarker(stop))
        } catch(e) {
          console.log('Error', e)
        }
      }
    })

    let routeStops = route.stops.reduce((accum, id, index) => {
      let stop = stopsMap[id]
      if (stop) {
        this.routeLayer.addLayer(this.createStopMarker(stop, index + 1))
        accum.push(stop)
      }
      return accum
    }, [])

    let routeLoaders = routeStops.slice(0, -1).map(async (stop, index) => {
      let stop2 = routeStops[index + 1]
      if (stop && stop2) {
        let coordinates = await getRouting({
          from: [stop.lat, stop.lon],
          to: [stop2.lat, stop2.lon],
          convertToLatLng: false,
        })
        return coordinates
      }
    })
    this.setState({ passengerCollections })
    let routes = await Promise.all(routeLoaders)
    let tripLength = 0
    let coordinatesOfRoute = route.path && route.path.coordinates
    if (coordinatesOfRoute && coordinatesOfRoute.length > 0) {
      let lnString = lineString(coordinatesOfRoute)
      // set distance of stops
      routeStops = this.getStopsWithDistance(lnString, routeStops)
      // Calculate distance of route
      tripLength = this.calculateDistanceRoute(routeStops)
    }
    this.setState({ routeStops, tripLength, totalStop: stops.length, stops })

    this.subLineStrings = routes.map(coordinates => {
      if (coordinates) {
        return lineString(coordinates)
      }
      return null
    })
  }
  
  drawPolyline(stop1, stop2) {
    let { polylines = {}  } = this.state
    let polyline =  L.polyline([[stop1.lat, stop1.lon], [stop2.lat , stop2.lon ]], {
      color: '#333',
      weight: 1,
      opacity: 0.5,
      dashArray: 5,
    }).addTo(this.polylineLayer)
    let polylineId = L.stamp(polyline)
    let key = stop2.id
    this.setState({
      polylines: { ...polylines, [key]: polylineId } 
    })
  }
  removePolyline(idPolyline) {
    let { polylines = {} } = this.state
    let polyline = polylines[idPolyline]
    if (polyline) {
      this.polylineLayer.removeLayer(polyline)
      delete polylines[idPolyline]
    }
    this.setState({
      polylines: polylines
    })
  }

  createStopMarker(stop, index) {
    let stopMarker
    let { permissions } = this.props
    let companyManager = isCompanyManager(permissions)
    if (stop.type || stop.passengerCollectionType) {
      let type = stop.passengerCollectionType ? stop.passengerCollectionType : stop.type
      stopMarker = L.marker([stop.lat, stop.lon], {
        icon: L.divIcon({
          html: ['stop', 'pickup', 'dropoff', 'both'].indexOf(type) < 0 ?
            `<img src=${iconImages[type]} class="depot" /> <span class="depot">${index && index > -1 ? index : ''}</span>` :
            `<img src=${iconImages[type]} /> <img src="/images/markers/marker-shadow.png" class="shadow" /> <span>${index && index > -1 ? index : ''}<span>`,
          bgPos: [stop.lat, stop.lon],
          className: 'myIcon'
        }),
        zIndexOffset: 10000,
        opacity: 0.8,
        stopId: stop.id,
      })
    } else {
      stopMarker = L.marker([stop.lat, stop.lon], {
        zIndexOffset: 4999,
        opacity: 0.8,
        stopId: stop.id,
      })
    }
    if (companyManager) {
      stopMarker.on('click', evt => {
        let clickFromMarker = true
        let anchorEl = evt.originalEvent.target
        this.showStopMenu(anchorEl, stop, clickFromMarker, anchorOrigin, transformOrigin)
      })
    }
    return stopMarker
  }
  async fetchStopsInfo(id) {
    let res = await Provider.dataProvider('REMOTE', 'routes', {
      data: { routeId: parseInt(id) },
      method: 'getCollectionStops',
      requestMethod: 'GET'
    })
    if (res) {
      return res.data
    }
  }
  async fetchRoute(routeId) {
    let response = await Provider.dataProvider('GET_ONE', 'routes', {
      id: parseInt(routeId),
      filter: {
        include: ['routeGroup']
      }
    })
    if (response) {
      return response.data
    }
  }

  async fetchStops() {
    let response = await Provider.dataProvider('GET_LIST', 'stops', {
      filter: {
        where: {},
        fields: [
          'id',
          'name',
          'lat',
          'lon',
          'code',
          'identifier',
          'address',
          'companyId',
          'passengerCollections',
          'zoneId'
        ],
      },
      sort: {},
      pagination: { page: 1, perPage: 2000, },
    })
    if (response) {
      return response.data
    }
  }

  async fetchRouteWithStops(routeId) {
    let response = await Provider.dataProvider('GET_LIST', 'routes/getRouteWithStop', {
      filter: {
        where: { id: routeId }
      },
      sort: {},
      pagination: { page: 1, perPage: 2000, },
    })
    if (response) {
      return response.data
    }
  }

  async fetchPassengerCollection() {
    let response = await Provider.dataProvider('GET_LIST', 'passengercollections', {
      filter: { where: { status: { neq: '00IAT' }}},
      sort: {},
      pagination: {}
    })
    if (response) {
      return response.data
    }
  }

  getStopsWithDistance = (lineString, routeStops) => {
    let last = null
    routeStops.map((item, index) => {
      if (item) {
        let distance = 0
        if (index > 0) {
          distance = ((getLocationPoint(lineString, item) - getLocationPoint(lineString, last)))
        }
        item.distance = distance
        last = item
      }
      return item
    })
    return routeStops
  }

  calculateDistanceRoute = (routeStops) => {
    let lengthTrip = routeStops.reduce(function (length, routeStop) {
      if (routeStop) {
        length += routeStop.distance
      }
      return length
    }, 0)
    return lengthTrip || 0
  }

  hideStopMenu = () => {
    this.setState({ stopMenu: null })
  }

  showStopMenu = (anchorEl, stop, clickFromMarker, anchorOrigin, transformOrigin) => {
    let { updatedStop, routeStops = [] } = this.state
    if (updatedStop) {
      let { id, name, lat, lon, code, identifier, companyId } = updatedStop
      if (id === stop.id) {
        stop = { ...stop, name, lat, lon, code, identifier, companyId }
      }
    }
    let { type } = stop
    let nearestStop
    if (type === 'start') {
      nearestStop = routeStops[1]
    }
    if (type === 'end') {
      nearestStop = routeStops[routeStops.length - 2]
    }
    let { endPoint, startPoint } = this
    let doSwap = false
    let doAttach = false
    if (endPoint && startPoint) {
      doSwap = true
      doAttach = true
    }
    this.setState({
      stopMenu: {
        anchorEl: anchorEl,
        stop,
        open: true,
        nearestStop,
        doSwap,
        doAttach
      },
      clickFromMarker,
      anchorOrigin,
      transformOrigin,
    })
  }

  swapStop = async (stop) => {
    const { showNotification, changeRouteAction } = this.props
    let { routeStops } = this.state
    try {
      let { type } = stop
      if (type === 'start') {
        let endPos = routeStops.length
        let currentEndStop = routeStops[endPos - 1]
        stop.type = 'end'
        currentEndStop.type = 'start'
        if (this.endPoint) {
          this.removeStopFromGroupLayer(this.routeLayer, this.endPoint)
          this.endPoint = stop
        }
        if (this.startPoint) {
          this.removeStopFromGroupLayer(this.routeLayer, this.startPoint)
          this.startPoint = currentEndStop
        }
        this.addStopToGroupLayer(this.routeLayer, stop, endPos)
        this.addStopToGroupLayer(this.routeLayer, currentEndStop, 1)
      } else if (type === 'end') {
        let startPos = 0
        let routeStopsLen = routeStops.length
        let currentStartStop = routeStops[startPos]
        stop.type = 'start'
        currentStartStop.type = 'end'
        if (this.startPoint) {
          this.removeStopFromGroupLayer(this.routeLayer, this.startPoint)
          this.startPoint = stop
        }
        if (this.endPoint) {
          this.removeStopFromGroupLayer(this.routeLayer, this.endPoint)
          this.endPoint = currentStartStop
        }
        this.addStopToGroupLayer(this.routeLayer, stop, 1)
        this.addStopToGroupLayer(this.routeLayer, currentStartStop, routeStopsLen)
      }
      this.clearRouteStops(true)
      changeRouteAction(true)
      showNotification('notification.swap_dimension_route_success')
    } catch (e) {
      showNotification('notification.swap_dimension_route_failure', 'warning')
    }
  }

  openSelectStartPointDialog = (stop) => {
    const { translate } = this.props
    this.setState({
      confirmDialog: {
        open: true,
        onClose: () => this.hideConfirmDialog(),
        onSave: () => this.selectStartPoint(stop),
        title: translate('notification.set_start_point'),
        content: translate('notification.delete_all_stops')
      }
    })
    this.hideStopMenu()
  }

  openSelectEndPointDialog = (stop) => {
    const { translate } = this.props
    this.setState({
      confirmDialog: {
        open: true,
        onClose: () => this.hideConfirmDialog(),
        onSave: () => this.selectEndPoint(stop),
        title: translate('notification.set_end_point'),
        content: translate('notification.delete_all_stops')
      }
    })
    this.hideStopMenu()
  }

  isValidStop = async (stop) => {
    let { routeStops } = this.state
    if (_.isEmpty(routeStops)) {
      return true
    }
    let existsStop = routeStops[0] || {}
    let latlngs = await getRouting({
      from: [existsStop.lat, existsStop.lon],
      to: [stop.lat, stop.lon],
      convertToLatLng: false,
    })
    if (_.isEmpty(latlngs)) {
      return false
    }
    return true
  }

  selectStartPoint = async (stop) => {
    const { showNotification, changeRouteAction } = this.props
    let isValid = await this.isValidStop(stop)
    if (isValid) {
      if (this.startPoint) {
        this.startPoint.type = null
      }
      stop.type = 'start'
      stop.passengerCollectionType = null
      this.startPoint = stop
      this.removeStopFromGroupLayer(this.stopLayerGroup, stop)
      this.addStopToGroupLayer(this.routeLayer, stop, 1)
      this.clearRouteStops(true)
      changeRouteAction(true)
      showNotification('notification.set_start_point_success')
    } else {
      this.hideConfirmDialog()
      showNotification('notification.no_route', 'warning')
    }
    return true
  }

  detachPointFromRoute = (stop, nearestStop) => {
    if(!nearestStop) {
      this.detachStopFromRoute(stop)
    } else {
      let { type: nearestStopType } = nearestStop
      let { type } = stop
      if (nearestStopType === 'end' || nearestStopType === 'start') {
        this.detachStopFromRoute(stop)
        if (type === 'end') {
          this.endPoint = null
        } else if (type === 'start') {
          this.startPoint = null
        }
      } else {
        this.detachStopFromRoute(nearestStop)
        if (type === 'end') {
          this.selectEndPoint(nearestStop)
        } else if (type === 'start') {
          this.selectStartPoint(nearestStop)
        }
      }
    }
  }

  selectEndPoint = async (stop) => {
    const { showNotification, changeRouteAction } = this.props
    let { routeStops = [] } = this.state
    let insertPos = routeStops.indexOf(stop)
    if (insertPos <= 0) {
      insertPos = routeStops && routeStops.length + 1
    }
    let isValid = await this.isValidStop(stop)
    if (isValid) {
      if (this.endPoint) {
        this.endPoint.type = null
      }
      stop.type = 'end'
      stop.passengerCollectionType = null
      this.endPoint = stop
      this.removeStopFromGroupLayer(this.stopLayerGroup, stop)
      this.addStopToGroupLayer(this.routeLayer, stop, insertPos)
      this.clearRouteStops(true)
      showNotification('notification.set_end_point_success')
      changeRouteAction(true)
    } else {
      this.hideConfirmDialog()
      showNotification('notification.no_route', 'warning')
    }

    return true
  }

  async findRoute(routeStops) {
    let { startPoint, endPoint } = this
    if (startPoint && endPoint) {
      let start = [startPoint.lat, startPoint.lon]
      let end = [endPoint.lat, endPoint.lon]
      let coordinates = await getRouting({ from: start, to: end, convertToLatLng: false })
      if (coordinates && coordinates.length > 2) {
        let line = lineString(coordinates)
        this.subLineStrings = [line]
      }
      this.reRouteWithStops(routeStops)
    }
  }

  removeStopFromGroupLayer(groupLayer, stop) {
    let layers = groupLayer.getLayers()
    let layer = _.find(layers, ['options.stopId', stop.id])
    if (layer) {
      groupLayer.removeLayer(layer)
    }
  }

  addStopToGroupLayer(groupLayer, stop, insertPos) {
    let marker = this.createStopMarker(stop, insertPos)
    groupLayer.addLayer(marker)
  }

  removeStopFromRoute = async (stop, routeStops) => {
    if (!routeStops) {
      routeStops = this.state.routeStops
    }
    let index = _.findIndex(routeStops, rStop => rStop.id === stop.id)
    let from = routeStops[index - 1]
    let to = routeStops[index + 1]
    if (from && to) {
      let latlngs = await getRouting({
        from: [from.lat, from.lon],
        to: [to.lat, to.lon],
        convertToLatLng: false,
      })
      this.subLineStrings = [
        ...this.subLineStrings.slice(0, index - 1),
        lineString(latlngs),
        ...this.subLineStrings.slice(index + 1)
      ]
    } else {
      this.subLineStrings = []
    }
    routeStops = [...routeStops.slice(0, index), ...routeStops.slice(index + 1)]
    return routeStops
  }

  detachStopFromRoute = async (stop) => {
    let { changeRouteAction } = this.props
    let { routeStopsInfo } = this.state
    let routeStop = routeStopsInfo[stop.id]
    if (routeStop) {
      let { passengerCollections } = routeStop 
      if (passengerCollections && passengerCollections.length > 0) {
        for(let i = 0; i < passengerCollections.length; i++) {
          let collectionStop = passengerCollections[i]
          if (collectionStop) {
            this.removePolyline(collectionStop.id)
          }
        }
      }
    }
    this.removeStopFromGroupLayer(this.routeLayer, stop)
    stop.type = null
    this.addStopToGroupLayer(this.stopLayerGroup, stop)
    let routeStops = await this.removeStopFromRoute(stop)
    this.reRouteWithStops(routeStops)
    changeRouteAction(true)
    return true
  }

  reRouteWithStops(routeStops) {
    if (routeStops && routeStops.length >= 2) {
      let coordinates = this.subLineStrings.reduce(function (prev, ls) {
        return prev.concat(ls.geometry.coordinates)
      }, [])
      let route = { ...this.route }
      let color = route && route.color ? route.color : 'red'
      let line = lineString(coordinates)
      if (this.poly) {
        this.poly.setLatLngs(coordinatesToLatLngs(coordinates))
      } else {
        this.poly = L.polyline(coordinatesToLatLngs(coordinates), { weight: 7, opacity: 0.6, color: color })
        this.poly.addTo(this.routeLayer)
      }
      this.updateIndexStopInRoute(routeStops)
      routeStops = this.getStopsWithDistance(line, routeStops)
      let tripLength = this.calculateDistanceRoute(routeStops)
      this.setState({ routeStops, tripLength })
    } else {
      if (this.poly) {
        this.routeLayer.removeLayer(this.poly)
        this.poly=null
      }
      this.setState({ routeStops, tripLength: 0 })
    }
  }

  updateIndexStopInRoute = (routeStops) => {
    routeStops.forEach((routeStop, index) => {
      let type = routeStop.passengerCollectionType ? routeStop.passengerCollectionType :routeStop.type
      let marker = this.findMarker(routeStop)
      if (marker)
        marker.setIcon(L.divIcon({
          html: ['stop', 'pickup', 'dropoff', 'both'].indexOf(type) < 0 ?
            `<img src=${iconImages[type]} class="depot" /> <span class="depot">${index + 1}</span>` :
            `<img src=${iconImages[type]} /><img src="/images/markers/marker-shadow.png" class="shadow" /> <span>${index + 1}<span>`,
          bgPos: [routeStop.lat, routeStop.lon],
          className: 'myIcon'
        }))
    })
  }

  addStopToRoute = async (stop, insertPos, routeStops) => {
    if (!routeStops) {
      routeStops = this.state.routeStops
    }
    if (!insertPos) {
      insertPos = -1
      let tripLength = 0
      let len = routeStops.length
      let start = routeStops[0]
      let startToStops = await getRouting({
        from: [start.lat, start.lon],
        to: [stop.lat, stop.lon],
        convertToLatLng: false,
      })
      if (_.isEmpty(startToStops)) {
        return []
      }
      let toStopLength = length(lineString(startToStops), { units: 'metres' })
      this.subLineStrings.forEach((lineString, index) => {
        if (insertPos < 0) {
          tripLength += length(lineString, { units: 'metres' })
          if (tripLength > toStopLength) {
            insertPos = index + 1
          }
        }
      })
      if (insertPos <= 0) insertPos = len - 1
    }
    let fromStop = routeStops[insertPos - 1] || {}
    let toStop = routeStops[insertPos] || {}
    let latlng1 = await getRouting({
      from: [fromStop.lat, fromStop.lon],
      to: [stop.lat, stop.lon],
      convertToLatLng: false,
    })
    let latlng2 = await getRouting({
      from: [stop.lat, stop.lon],
      to: [toStop.lat, toStop.lon],
      convertToLatLng: false,
    })
    this.subLineStrings = [...this.subLineStrings.slice(0, insertPos - 1), lineString(latlng1), lineString(latlng2), ...this.subLineStrings.slice(insertPos)]
    routeStops = [...routeStops.slice(0, insertPos), stop, ...routeStops.slice(insertPos)]
    return routeStops
  }

  attachStopToRoute = async (stop) => {
    if (stop.type) return
    let { showNotification, changeRouteAction } = this.props
    let { startPoint, endPoint } = this
    if (startPoint && endPoint) {
      let routeStops = await this.addStopToRoute(stop)
      if (!_.isEmpty(routeStops)) {
        this.removeStopFromGroupLayer(this.stopLayerGroup, stop)
        stop.type = 'stop'
        this.addStopToGroupLayer(this.routeLayer, stop)
        this.reRouteWithStops(routeStops)
        changeRouteAction(true)
        this.setState({
          routeStopsInfo: {...this.state.routeStopsInfo, [stop.id]: stop}
        })
      } else {
        showNotification('notification.no_route', 'warning')
      }
    } else {
      showNotification('notification.no_path', 'warning')
    }
    return true
  }

  moveStopInRoute = async () => {
    let { routeStops } = this.state
    if (!routeStops || _.isEmpty(routeStops)) return
    let routeLoaders = routeStops.slice(0, -1).map(async (stop, index) => {
      let stop2 = routeStops[index + 1]
      let coordinates = await getRouting({
        from: [stop.lat, stop.lon],
        to: [stop2.lat, stop2.lon],
        convertToLatLng: false,
      })
      return coordinates
    })
    let routes = await Promise.all(routeLoaders)
    this.subLineStrings = routes.map(coordinates => lineString(coordinates))
  }

  openDialogClearStop = () => {
    const { translate } = this.props
    this.setState({
      confirmDialog: {
        open: true,
        onClose: () => this.hideConfirmDialog(),
        onSave: () => this.clearRouteStops(),
        title: translate('notification.clear_all_stop')
      }
    })
  }

  hideConfirmDialog = () => {
    this.setState({ confirmDialog: {} })
  }

  handleCancelAction = () => {
    let { changeRouteAction } = this.props
    changeRouteAction(false)
  }

  clearRouteStops = async (callFromAnotherFunc) => {
    const { showNotification } = this.props
    let { routeStopsInfo = {}, polylines = [] } = this.state
    let layers = this.routeLayer.getLayers()
    layers.forEach(layer => {
      let { stopId } = layer.options
      if (stopId) {
        let stop = this.stopsMap[stopId]
        if (stop === this.startPoint || stop === this.endPoint) return

        this.removeStopFromGroupLayer(this.routeLayer, stop)
        stop.type = null
        this.addStopToGroupLayer(this.stopLayerGroup, stop)
      }
    })
    let routeStops = []
    if (this.startPoint) routeStops.push(this.startPoint)
    if (this.endPoint) routeStops.push(this.endPoint)
    
    // clear all polylines
    for (let i = 0; i < routeStops.length; i++) {
      let routeStop = routeStops[i] || {}
      let routeStopId = routeStop.id
      if (!(routeStopsInfo[routeStopId])){
        routeStopsInfo = {...routeStopsInfo, [routeStopId]: routeStop }
      }
    }
    let routeStopIds = routeStops.map(v => v.id)
    let stopsInfo = _.omitBy(routeStopsInfo, (value, key) => routeStopIds.indexOf(parseInt(key)) === -1)
    let polylineIds = Object.keys(polylines)
    for (let i = 0; i < polylineIds.length; i++) {
      this.removePolyline(polylineIds[i])
    }
    for (let k in stopsInfo) {
      let stopInfo = stopsInfo[k]
      let { passengerCollections } = stopInfo
      if (passengerCollections && passengerCollections.length > 0) {
        for (let i = 0; i < passengerCollections.length; i++){
          let collectionStop = passengerCollections[i]
          if (stopInfo && collectionStop) {
            this.drawPolyline(stopInfo, collectionStop)
          }
        }
      }
    }
    this.setState({ routeStops, routeStopsInfo: stopsInfo })
    this.findRoute(routeStops)
    if (!callFromAnotherFunc) showNotification('notification.clear_all_stop_success')
    this.hideConfirmDialog()
  }

  findMarker(stop) {
    let layers = this.routeLayer._layers
    if (!layers) return
    for (let prop in layers) {
      let marker = layers[prop]
      let options = layers[prop].options
      if (options) {
        if (options['stopId'] === stop.id) {
          return marker
        }
      }
    }
    return null
  }

  panMapTo = (stop) => {
    const map = this.map.current.getMap()
    let latLng = L.latLng(stop.lat, stop.lon)
    map.panTo(latLng, {
      animate: true
    })
    let marker = this.findMarker(stop)
    marker.bounce(2)
  }

  openSaveRouteDialog = () => {
    const { translate } = this.props
    this.setState({
      confirmDialog: {
        open: true,
        onClose: () => this.hideConfirmDialog(),
        onSave: () => this.saveRouteToServer(),
        title: translate('notification.save_route')
      }
    })
  }

  openSmartSaveRouteDialog = () => {
    const { translate } = this.props
    this.setState({
      confirmDialog: {
        open: true,
        onClose: () => this.hideConfirmDialog(),
        onSave: () => this.smartSaveRouteToServer(),
        title: translate('notification.smart_save_route')
      }
    })
  }

  saveRouteToServer = async () => {
    let route = { ...this.route }
    const { showNotification, changeRouteAction, push } = this.props
    let { tripLength, routeStops } = this.state

    route.path = route.path || { type: 'LineString', coordinates: [] }
    /*
    route.path.coordinates = this.subLineStrings.reduce(function (prev, ls) {
      return prev.concat(ls.geometry.coordinates)
    }, [])
    */

    const json = this.poly.toGeoJSON()
    route.path.coordinates = json.geometry.coordinates
    route.stops = routeStops ? routeStops.map(stop => stop.id) : []
    route.originId = (routeStops && routeStops.length > 0) && routeStops[0].id
    route.destinationId = (routeStops && routeStops.length > 0) && routeStops[routeStops.length - 1].id
    route.distance = tripLength && Math.floor(parseFloat(tripLength) * 1000)
    let response = await Provider.dataProvider('UPDATE', 'routes', {
      id: route.id,
      data: route,
    })

    if (response) {
      this.hideConfirmDialog()
      changeRouteAction(false)
      push(`/routegroups/${route.routeGroupId}`)
      showNotification('notification.save_route_success')
      return response.data
    }
  }

  // if stops empty, the opposite direction already designed
  canApplySmartSave = async () => {
    let route = { ...this.route }
    if (route && route.stops && route.stops.length > 0) {
      return false
    }

    // query the opposite direction
    // if route stops empty
    let response = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: { where: { routeGroupId: route.routeGroupId, id: { neq: route.id } } },
      sort: {},
      pagination: { page: 1, perPage: 1 },
    })

    let opposite = (response && response.data && response.data.length > 0) ? response.data[0] : null
    if (opposite && opposite.stops && opposite.stops.length > 1) {
      return true
    }
    return false
  }

  smartSaveRouteToServer = async () => {
    const { showNotification, changeRouteAction, push } = this.props
    let { stops } = this.state

    let route = { ...this.route }
    let opposite
    // if route stops empty
    let response = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: { where: { routeGroupId: route.routeGroupId, id: { neq: route.id } } },
      sort: {},
      pagination: { page: 1, perPage: 1 },
    })
    opposite = (response && response.data && response.data.length > 0) ? response.data[0] : null

    // if the other direction was designed
    if (opposite && opposite.stops && opposite.stops.length > 1) {
      let routeStopIds = _.reverse(opposite.stops) || []
      let routeStops = _.reduce(stops, (result, stop) => {
        let { id } = stop
        if (routeStopIds.indexOf(id) >= 0) {
          result.push(stop)
        }
        return result
      }, [])
      let path = opposite.path
      let coordinatesOfRoute = _.reverse(path.coordinates) 
      let lnString = lineString(coordinatesOfRoute)
      routeStops = this.getStopsWithDistance(lnString, routeStops)
      let tripLength = this.calculateDistanceRoute(routeStops)
      route.stops = routeStopIds || []
      route.originId = (routeStopIds && routeStopIds.length > 0) && routeStopIds[0]
      route.destinationId = (routeStopIds && routeStopIds.length > 0) && routeStopIds[routeStopIds.length - 1]
      route.distance = tripLength && Math.floor(Math.abs(parseFloat(tripLength)) * 1000)
      path.coordinates = coordinatesOfRoute 
      route.path = path
    }

    response = await Provider.dataProvider('UPDATE', 'routes', {
      id: route.id,
      data: route,
    })
    
    if (response) {
      this.hideConfirmDialog()
      changeRouteAction(false)
      push(`/routegroups/${route.routeGroupId}`)
      showNotification('notification.save_route_success')
      return response.data
    }
  }

  findItem = (id) => {
    const { routeStops } = this.state
    const stop = routeStops.filter(s => s.id === id)[0]
    return {
      stop: stop || {},
      index: routeStops.indexOf(stop),
    }
  }

  endDrop = async () => {
    let { changeRouteAction } = this.props
    let routeStops = this.state.routeStops
    await this.moveStopInRoute()
    this.reRouteWithStops(routeStops)
    changeRouteAction(true)
  }

  onMove = async (draggedId, atIndex) => {
    let { stop, index } = this.findItem(draggedId)
    let routeStops = this.state.routeStops
    routeStops.splice(index, 1)
    routeStops.splice(atIndex, 0, stop)
    this.setState({
      routeStops: routeStops
    })
  }

  showEditStopDialog = ({ stop, onSave, onClose }) => {
    this.setState({
      editStopDialog: {
        stop,
        onSave,
        onClose,
      }
    })
  }

  hideEditStopDialog = () => {
    this.setState({ editStopDialog: undefined })
  }

  handleEscapeKey = () => {
    this.hideNewStopMarker()
  }

  onKeyUp = (evt) => {
    if (evt.defaultPrevented) {
      return
    }
    const key = evt.key
    const ctrl = evt.ctrlKey
    switch (key) {
      case 'Escape':
        this.handleEscapeKey()
        break
      case 'e':
        if (ctrl) {
          this.poly.toggleEdit()
        }
        break;
      default:
        break
    }
  }

  onSetupPassengerCollection = (stop) => {
    let { passengerCollections, routeStops } = this.state
    let rawRecord = _.find(passengerCollections, (passengerCollection) => passengerCollection.collectionStopId === stop.id)
    let record = {}
    if (!rawRecord) {
      let routeStop = _.find(routeStops, (routeStop) => routeStop.id === stop.id) || {}
      record = { collectionStopId: stop.id, stopId: routeStop.id }
    } else {
      let { collectionStopId, stopId, type, status, id, companyId } = rawRecord
      record = {
        collectionStopId,
        stopId,
        type,
        status,
        id,
        companyId
      } 
    }
    let disableRelationStop = ((stop && stop.id) && _.findIndex(routeStops, ['id', stop.id]) !== -1) ? true : false
    this.setState({
      setupPassengerCollectionDialog: {
        open: true,
        record,
        stop,
        disableRelationStop
      }
    })
  }

  closeSetupPassengerCollectionDialog = () => {
    this.setState({
      setupPassengerCollectionDialog: {
        open: false,
        record: {},
        stop: {},
      }
    })
  }
  
  setupPassengerCollectionDone = ( record ) => {
    let { passengerCollections, routeStops, stops, routeStopsInfo } = this.state
    let oldRouteStop = _.find(passengerCollections, stop => stop.collectionStopId === record.collectionStopId)
    let colStop = _.find(stops, stop => stop.id === record.collectionStopId) 
    let newRouteStop = routeStopsInfo[record.stopId] 
    let recordIdx = _.findIndex(passengerCollections, (passengerCollection) => passengerCollection.collectionStopId === record.collectionStopId)
    if (recordIdx >= 0) {
      passengerCollections[recordIdx] = record
    } else {
      passengerCollections.push(record)
    }
    let layer = this.stopLayerGroup
    let collectionStopIdx = -1
    let { collectionStopId, stopId, collectionStop, type, status } = record
    if (collectionStopId === stopId) {
      layer = this.routeLayer
      collectionStopIdx = _.findIndex(routeStops, stop => stop.id === collectionStopId)
      if (!collectionStop) {
        collectionStop = routeStops[collectionStopIdx]
      }
    }
    if (!collectionStop) {
      collectionStop = this.stopsMap[collectionStopId]    
    }
    this.removeStopFromGroupLayer(layer, collectionStop)
    if (status === '10ACT') {
      let passengerCollectionType = passengerCollectionTypeMapping[type]
      collectionStop.passengerCollectionType = passengerCollectionType
    } else {
      collectionStop.passengerCollectionType = null
    }
    this.addStopToGroupLayer(layer, collectionStop, collectionStopIdx !== -1 ? collectionStopIdx + 1 : collectionStopIdx)
    routeStops[collectionStopIdx] = collectionStop
    
    // delete old line & draw new line
    if (oldRouteStop) {
      let oldRouteStopId = oldRouteStop.stopId
      let oldRouteStopInfo = routeStopsInfo[oldRouteStopId]
      if (oldRouteStopInfo) {
        let { passengerCollections: collectionOldStops = [] } = oldRouteStopInfo
        let collections = collectionOldStops.filter(stop => stop.id !== record.collectionStopId)
        routeStopsInfo[oldRouteStopId].passengerCollections = collections
      }
    }
    if (colStop && newRouteStop) {
      let { passengerCollections: collectionStops = [] } = newRouteStop
      let collectionIdx = collectionStops.findIndex(stop => stop.id === record.collectionStopId)
      if (collectionIdx !== -1) {
        collectionStops[collectionIdx] = colStop 
      } else {
        collectionStops.push(colStop)
      }
      newRouteStop.passengerCollections = collectionStops
      this.removePolyline(colStop.id)
      this.drawPolyline(newRouteStop, colStop)
    }
    routeStopsInfo[record.stopId] = newRouteStop
    this.setState({ passengerCollections, routeStops, routeStopsInfo })
  }

  render() {
    const {
      stopMenu,
      clickFromMarker,
      center,
      editStopDialog = {},
      confirmDialog,
      tripLength = 0,
      routeStops = [],
      anchorOrigin,
      transformOrigin,
      totalStop,
      canSmartSave,
      setupPassengerCollectionDialog,
      loading,
    } = this.state
    const { translate, history, permissions, width, location } = this.props
    let companyManager = isCompanyManager(permissions)
    const route = this.route ? this.route : {}
    let isXSmall = width === 'xs'
    return (
      <Fragment>
        <Title title={translate('resources.routes.network_plan')} />
        <Map
          forwardRef={this.map}
          maxZoom={18}
          tripLength={tripLength}
          route={route}
          routeStops={[...routeStops]}
          onClick={this.onMapClick}
          onMouseMove={this.onMouseMove}
          createNewStopMarker={this.createNewStopMarker}
          saveRouteToServer={this.openSaveRouteDialog}
          smartSaveRouteToServer={canSmartSave ? this.openSmartSaveRouteDialog : null}
          clearRouteStops={this.openDialogClearStop}
          center={center}
          onKeyUp={this.onKeyUp}
          panMapTo={this.panMapTo}
          onDropStop={this.onDropStop}
          allowDrop={this.allowDrop}
          onMove={this.onMove}
          showStopMenu={this.showStopMenu}
          findItem={this.findItem}
          endDrop={this.endDrop}
          totalStop={totalStop}
          companyManager={companyManager}
          isXSmall={isXSmall}
          location={location}
          editable={ true }
        />
        {stopMenu && <StopContextMenu
          anchor={{ ...stopMenu }}
          onClose={this.hideStopMenu}
          onSetAsStart={this.openSelectStartPointDialog}
          onSetAsEnd={this.openSelectEndPointDialog}
          onAttach={this.attachStopToRoute}
          onDetach={this.detachStopFromRoute}
          onEditStop={this.editStop}
          clickFromMarker={clickFromMarker}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          translate={translate}
          onRemoveStop={this.openRemoveStopDialog}
          swapStop={this.swapStop}
          detachPointFromRoute={this.detachPointFromRoute}
          companyManager={companyManager}
          onSetupPassengerCollection={this.onSetupPassengerCollection}
        />}
        <StopEditDialog editStopDialog={{ ...editStopDialog }} translate={translate} />

        {(!_.isEmpty(routeStops) && setupPassengerCollectionDialog) && <SetupPassengerCollectionDialog
          setupPassengerCollectionDialog={{ ...setupPassengerCollectionDialog }}
          translate={translate}
          editableLocation={true}
          routeStops={routeStops}
          onClose={this.closeSetupPassengerCollectionDialog}
          onDone={this.setupPassengerCollectionDone}
        />}
        <DialogConfirm confirmDialog={confirmDialog} translate={translate} />
        <NavigationBlocker
          history={history}
          doneAction={this.saveRouteToServer}
          cancelAction={this.handleCancelAction}
        />
        {loading && <DialogLoading open={loading} />}
      </Fragment>
    )
  }
}

const enhance = compose(
  translate,
  withWidth(),
  connect(null, { showNotification, changeRouteAction, changeBreadcrumb, push }),
  withStyles(styles)
)

const NetworkPlanningMapWithPermission = props => {
  return <WithPermissions
    render={({ permissions }) => <NetworkPlanningMap permissions={permissions} {...props} />}
  />
}

export default enhance(NetworkPlanningMapWithPermission)
