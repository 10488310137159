import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  translate,
  ReferenceInput,
  ImageInput,
  ImageField,
} from 'react-admin'
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import { DatePickerInput } from '../common/DatePicker'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { renewal as renewalAction } from './action'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import { validate } from './validate'

const RenewalDialog = ({ open, save, onClose, translate, getRecord }) => {
  let extras = { resource: 'licencevalidities', fullWidth: true }
  let rawRecord = getRecord() || {}
  let record = {
    licenceNumber: rawRecord.licenceNumber,
    placeOfIssue: rawRecord.placeOfIssue
  }
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{translate('resources.common.renewal_info')}</DialogTitle>
      <DialogContent>
        <SimpleForm
          validate={validate}
          resource="licencevalidities"
          form="renewal-form"
          record={record}
          save={record => save(record)}
        >
          <TextInput source="licenceNumber" {...extras} />
          <DatePickerInput
            source="validFrom"
            dateFormat="DD/MM/YYYY"
            showLunarDate={true}
            keyboard={true}
            {...extras}
          />
          <DatePickerInput
            source="validTo"
            dateFormat="DD/MM/YYYY"
            showLunarDate={true}
            keyboard={true}
            {...extras}
          />
          <ReferenceInput
            source="placeOfIssue"
            basePath="/provinces"
            reference="provinces"
            resource="provinces"
            perPage={100}
            sort={{ field: 'name', order: 'ASC' }}
            {...extras}
          >
            <FuzzySelectInput
              renderItem={item => {
                return item.name
              }}
              optionText="name"
              {...defaultFuzzySearch()}
            />
          </ReferenceInput>
          <ImageInput
            source="images"
            accept="image/*"
            multiple
            {...extras}
          >
            <ImageField source="src" title="title" />
          </ImageInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  )
}

class RenewalButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  openDialog = () => {
    this.setState({
      open: true
    })
  }

  onClose = () => {
    this.setState({
      open: false
    })
  }

  save = record => {
    let { record: licenceData, renewalAction, handleDone, reset } = this.props
    let { id: licenceId, companyId, licenceValidity: licenceValidities } = licenceData
    let licenceValidity = licenceValidities ? licenceValidities[0] : {}
    let latestRenewalNo = licenceValidity.renewalNo
    record = {
      ...record,
      licenceId,
      latestRenewalNo,
      companyId
    }
    let done = () => {
      this.onClose()
      handleDone()
      reset('renewal-form')
    }
    renewalAction(record, done)
  }

  render() {
    let { translate, getRecord } = this.props
    let { open } = this.state
    return <>
      <Button
        color="primary"
        onClick={this.openDialog}
      >
        {translate('button.renewal')}
      </Button>
      <RenewalDialog
        translate={translate}
        open={open}
        onClose={this.onClose}
        save={this.save}
        getRecord={getRecord}
      />
    </>
  }
}

const enhance = compose(
  translate,
  connect(null, { renewalAction, reset })
)

export default enhance(RenewalButton)