import React, { Component } from 'react'
import {
  Chip,
  withStyles,
  FormControl,
  Input,
  Tooltip,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { translate, showNotification } from 'react-admin'
import { connect } from 'react-redux'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
  })
}

const editInputStyles = (theme) => {
  return ({
    formControl: {
      margin: theme.spacing.unit,
    },
    input: {
      width: 50,
    },
  })
}

class _EditInput extends Component {

  state = {}

  componentDidMount() {
    let { quantity } = this.props
    this.setState({ value: quantity })
  }

  render() {
    let { hideEditMode, handleUpdate, classes } = this.props
    let { value } = this.state
    return <FormControl className={classes.formControl}>
      <Input
        autoFocus
        classes={{ root: classes.input }}
        value={parseFloat(value)}
        type="number"
        onBlur={() => {
          hideEditMode()
        }}
        onChange={(evt) => {
          let { value } = evt.target
          this.setState({ value })
        }}
        onKeyUp={evt => {
          evt.preventDefault()
          let { value } = evt.target
          if (evt.key === 'Enter') {
            handleUpdate(value)
          } else if (evt.key === 'Esc' || evt.key === 'Escape') {
            hideEditMode()
          } else if (evt.which !== 8 && evt.which !== 0 && (evt.which < 48 || evt.which > 57)) {
            evt.preventDefault()
          }
        }}
        inputProps={{
          min: 0,
        }}
      />
    </FormControl>
  }
}

const EditInput = withStyles(editInputStyles)(_EditInput)

class QuantityChip extends Component {

  state = {
    edit: false,
    unLimited: false,
    quantity: 0,
  }

  componentDidMount() {
    let { record, source } = this.props
    let quantity = record[source] || 0
    this.setState({ quantity })
  }

  hideEditMode = () => {
    this.setState({ edit: false })
  }

  handleEdit = () => {
    this.setState({ edit: true })
  }

  handleUpdate = (quantity = -1) => {
    let { record, showNotification, source } = this.props
    let { id, [source]: oldQuantity } = record
    if (quantity === oldQuantity) {
      this.setState({ edit: false })
    } else {
      Provider.dataProvider('REMOTE', 'agencyproducts', {
        method: 'changeQuantity',
        requestMethod: 'POST',
        data: { id, [source]: quantity },
      }).then(() => {
        this.setState({ edit: false, quantity, unLimited: quantity === -1 })
        showNotification('notification.change_quantity_success')
      }).catch(() => {
        this.setState({ amount: oldQuantity, edit: false })
        showNotification('notification.change_quantity_failure', 'warning')
      })
    }
  }

  render() {
    let { classes, isCompanyManager, translate } = this.props
    let { edit, quantity, unLimited  } = this.state
    let unLimitMessage = translate('notification.unlimit_quantity')
    let formatQuantity = parseFloat(quantity).toLocaleString()
    if (unLimited || quantity < 0) {
      formatQuantity = translate('resources.products.no_limit')
    }
    return <Tooltip 
      title={unLimitMessage|| ''}
      placement="right-end"
      open={unLimited && edit}
      classes={{
        tooltip: classes.tooltip
      }}
      arrow="true"
    >
      <Chip
        size="small"
        variant="outlined"
        color="primary"
        label={edit ? <EditInput
          hideEditMode={this.hideEditMode}
          handleUpdate={this.handleUpdate}
          quantity={quantity < 0 ? 0 : quantity}
        /> : formatQuantity}
        className={classes.chip}
        onClick={() => { 
          if (!isCompanyManager) return
          if (!edit) {
            this.handleEdit()
          } else {
            this.handleUpdate()
          }
        }}
      />
    </Tooltip>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification })
)
export default enhance(QuantityChip)
