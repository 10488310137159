import React, { Component } from 'react'
import { 
  TextField,
  InputAdornment,
  withStyles,
} from '@material-ui/core'
import { addField, translate } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import compose from 'recompose/compose'

const style = {
  root: {
    marginTop: 20,
    width: 350,
    marginRight: 16
  }
}

class SearchInput extends Component {

  render() {
    let { 
      label = 'resources.common.search',
      input,
      fullWidth,
      helperText,
      classes,
      translate,
    } = this.props
    return <TextField
      label={translate(label)}
      input={input}
      helperText={translate(helperText)}
      fullWidth={fullWidth}
      className={classes.root}
      defaultValue={input.value}
      onChange={e => input.onChange(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
      }}
    />
  }
}

const enhance = compose(withStyles(style), translate, addField)
export default enhance(SearchInput)
