import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const PriceIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="m8,4c-4.418,0 -8,3.582 -8,8c0,4.418 3.582,8 8,8c4.418,0 8,-3.582 8,-8c0,-4.418 -3.582,-8 -8,-8zm4,0c-0.339,0 -0.672,0.02736 -1,0.06836c3.945,0.493 7,3.85264 7,7.93164c0,4.079 -3.055,7.43864 -7,7.93164c0.328,0.041 0.661,0.06836 1,0.06836c4.418,0 8,-3.582 8,-8c0,-4.418 -3.582,-8 -8,-8zm4,0c-0.339,0 -0.672,0.02736 -1,0.06836c3.945,0.493 7,3.85264 7,7.93164c0,4.079 -3.055,7.43864 -7,7.93164c0.328,0.041 0.661,0.06836 1,0.06836c4.418,0 8,-3.582 8,-8c0,-4.418 -3.582,-8 -8,-8zm-9.90234,3.67578l4.13086,0l0,1.41797l-2.77149,0l-0.15039,1.82813c0.047,-0.03101 0.10392,-0.06552 0.16992,-0.10352c0.066,-0.038 0.14252,-0.07247 0.22852,-0.10547c0.086,-0.033 0.17834,-0.06103 0.27734,-0.08203c0.099,-0.021 0.20546,-0.03125 0.31446,-0.03125c0.355,0 0.6684,0.06436 0.9414,0.19336c0.273,0.129 0.50445,0.3165 0.68945,0.5625c0.185,0.246 0.32588,0.54734 0.42188,0.90234c0.096,0.355 0.14258,0.75989 0.14258,1.21289c0,0.38 -0.04939,0.73999 -0.15039,1.08399c-0.102,0.344 -0.25589,0.6462 -0.46289,0.9082c-0.207,0.262 -0.4673,0.47095 -0.7793,0.62695c-0.312,0.156 -0.68152,0.23438 -1.10352,0.23438c-0.316,0 -0.62292,-0.05697 -0.91992,-0.16797c-0.297,-0.111 -0.56097,-0.27328 -0.79297,-0.48828c-0.232,-0.215 -0.41954,-0.47716 -0.56054,-0.78516c-0.141,-0.308 -0.2128,-0.65969 -0.2168,-1.05468l1.64062,0c0.027,0.355 0.11468,0.63117 0.26368,0.82617c0.149,0.195 0.34022,0.29297 0.57422,0.29297c0.156,0 0.28943,-0.04105 0.39843,-0.12305c0.109,-0.082 0.19858,-0.19299 0.26758,-0.33399c0.069,-0.141 0.11749,-0.30704 0.14649,-0.49804c0.02899,-0.191 0.04296,-0.3981 0.04296,-0.6211c0,-0.223 -0.01959,-0.42637 -0.05859,-0.60937c-0.039,-0.183 -0.10155,-0.33975 -0.18555,-0.46875c-0.084,-0.129 -0.1894,-0.22974 -0.3164,-0.30274c-0.127,-0.073 -0.27713,-0.10742 -0.45313,-0.10742c-0.117,0 -0.22059,0.01402 -0.30859,0.04102c-0.088,0.027 -0.16352,0.06247 -0.22852,0.10547c-0.065,0.043 -0.12097,0.09243 -0.16797,0.14843c-0.047,0.056 -0.0899,0.11483 -0.1289,0.17383l-1.31641,-0.33984l0.42188,-4.33594z"/>
  </SvgIcon>
)

export default PriceIcon