import React, { Component } from 'react'
import {
  Datagrid,
  List,
  DateField,
  Filter as RAFilter,
  NumberField,
  TextField,
  ReferenceInput,
  translate,
  WithPermissions,
  CardActions,
  FunctionField,
} from 'react-admin'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { DatePickerInput } from '../common/DatePicker'
import { moment } from '../common/format'
import { withStyles } from '@material-ui/core'
import { hasOneOfPermission } from '../utils/permission'
import ExportProductChargeButton from './ExportProductChargeButton'
import compose from 'recompose/compose'
import SelectInputCustom from '../common/SelectInputCustom'
import { Provider } from '../provider'
import { getCurrentCompany, getCurrentCompanyIdsByAgency } from '../utils/commonUtil'
import _ from 'lodash'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'

class ActionList extends Component {

  render() {
    let { filterValues } = this.props
    return <CardActions>
      <ExportProductChargeButton
        type="button"
        color="primary"
        filterValues={filterValues}
      />
    </CardActions>
  }
}

const styleFilter = ({
  otherStatus: {
    minWidth: 100,
  },
  otherAgency: {
    minWidth: 100,
  },
  otherProduct: {
    minWidth: 100,
  },
  otherUser: {
    minWidth: 100,
  }
})

class _Filter extends Component {

  state = {
    companyAgencies: []
  }

  async componentDidMount() {
    let currentCompany = getCurrentCompany()

    const companyId = _.get(currentCompany, 'id')
    if (companyId) {
      const res = await Provider.dataProvider('GET_LIST', 'companyagencies', {
        filter: {
          companyId: _.get(currentCompany, 'id'),
          '../include': [
            {
              relation: 'agency',
              scope: {
                fields: ['id', 'name']
              }
            }
          ],
        },
        sort: {},
        pagination: {},
      })

      if (res && res.data) {
        const companyAgencies = res.data
        this.setState({ companyAgencies })
      }
    }
  }

  render() {
    const { translate, classes, permissions, ...props } = this.props

    const { fromDate, companyAgencies } = this.state

    let currentCompany = getCurrentCompany()
    let operatorIds
    if (currentCompany) {
      operatorIds = [currentCompany.id]
    } else {
      operatorIds = getCurrentCompanyIdsByAgency()
    }

    return <RAFilter
      {...props}
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
    >
      <DateTimePickerInput
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <DateTimePickerInput
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
        minDate={fromDate}
      />
      <ReferenceInput
        reference="chargestatuses"
        source="statusCode"
        label="resources.productcharges.fields.status.name"
        classExtra={classes.otherStatus}
        fullWidth
        allowEmpty
        alwaysOn
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
      <ReferenceInput
        reference="companyagencies"
        source="agencyId"
        label="resources.productcharges.fields.agency.name"
        classExtra={classes.otherAgency}
        fullWidth
        allowEmpty
        alwaysOn
      >
        <SelectInputCustom
          defaultChoices={companyAgencies}
          optionValue="agency.id"
          optionText="agency.name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
      <ReferenceInput
        reference="products"
        source="productId"
        label="resources.productcharges.fields.product.name"
        classExtra={classes.otherProduct}
        fullWidth
        allowEmpty
        alwaysOn
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
      {hasOneOfPermission(permissions, 'company-manager') && <ReferenceInput
        reference="users/listCompanyUsers"
        label="resources.productcharges.fields.user.username"
        source="userId"
        classExtra={classes.otherUser}
        fullWidth
        allowEmpty
        alwaysOn
      >
        <SelectInputCustom
          optionText="username"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>}
      <DatePickerInput
        source="firstUse_between"
        label="resources.productcharges.fields.fromDate"
        dateFormat="DD/MM/YYYY"
        keyboard={true}
        allowEmpty
        pickerType='date'
        initialValue=""
        alwaysOn
        clearable={true}
      />
      <ReferenceInput
        source="deviceId"
        reference="devices"
        filter={{ operatorId: { inq: operatorIds } }}
        sort={{ field: 'id', order: 'ASC' }}
        alwaysOn
      >
        <FuzzySelectInput
          optionText="serial"
          renderItem={ ({ serial }) => serial } {...defaultFuzzySearch({ name: 'serial' })}
        />
      </ReferenceInput>
    </RAFilter>
  }
}

const enhanceFilter = compose(withStyles(styleFilter), translate)
const Filter = enhanceFilter(_Filter)

const FilterWithPermission = props => <WithPermissions
  render={({ permissions }) => <Filter permissions={permissions} {...props} />}
/>

const ProductChargeFilter = translate(FilterWithPermission)

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}

class _ProductChargeList extends Component {
  render() {
    let { classes, ...props } = this.props
    return (
      <List
        filters={<ProductChargeFilter />}
        filter={{
          'path': 'search',
          '../fields': [
            'id',
            'code',
            'price',
            'net',
            'createdAt',
            'productId',
            'agencyId',
            'chargeId',
            'statusCode',
            'userId',
            'fromDate',
            'firstUse',
          ],
          '../include': [
            {
              relation: 'product',
              scope: { fields: ['id', 'name'] },
            },
            {
              relation: 'agency',
              scope: { fields: ['id', 'name'] },
            },
            {
              relation: 'status',
              scope: { fields: ['id', 'name'] },
            },
            {
              relation: 'user',
              scope: { fields: ['id', 'username'] },
            },
            {
              relation: 'charge',
              scope: {
                include: [
                  {
                    relation: 'reservation',
                    scope: {
                      include: [{ relation: 'device' }]
                    }
                  }, 
                  {
                    relation: 'chargeStatus'
                  },
                  {
                    relation: 'fare'
                  }
                ],
              },
            },
          ]
        }}
        filterDefaultValues={{
          createdAt_gte: moment().startOf('days').toDate().toISOString(),
          createdAt_lte: moment().endOf('days').toDate().toISOString(),
          statusCode: '10PAID',
        }}
        sort={{ field: 'createdAt', order: 'desc' }}
        bulkActionButtons={false}
        actions={<ActionList />}
        {...props}
      >
        <Datagrid>
          <DateField source="createdAt" locales="vi-VN" showTime />
          <FunctionField
            source="code"
            render={(record) => {
              if (!record) return ''
              
              const { charge } = record

              if (!charge) return ''

              return `${charge.reservationCode}-${charge.id}`
            }}
          />
          <DateField source="firstUse" locales="vi-VN" showTime />
          <NumberField
            source="price"
            locales="vi-VN"
            options={{ style: 'currency', currency: 'VND' }}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          />
          <NumberField 
            source="net"
            locales="vi-VN"
            options={{ style: 'currency', currency: 'VND' }}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          />
          <FunctionField
            source="charge.chargeStatus.name"
            render={(record) => {
              const { charge } = record
              const { cancelReference, chargeStatus = {} } = charge
              return cancelReference ? 'Đã hủy' :  chargeStatus.name
            }}
          />
          <FunctionField
            source="product.name"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            render={(record) => {
              if (!record) return ''
              
              const { charge  = {}, product = {} } = record
              const { fare = {} } = charge
              const fareClass = fare ? fare.name : ''
              const productName = product ? product.name : ''
              return `${productName}-${fareClass}`
            }}
          />
          <TextField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            sortable={false}
          />
          <TextField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="agency.name"
            sortable={false}
          />
          <TextField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="user.username"
            sortable={false}
          />
          <TextField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="charge.reservation.device.serial"
            sortable={false}
          />
        </Datagrid>
      </List>
    )
  }
}

export const ProductChargeList = withStyles(styles)(_ProductChargeList)
