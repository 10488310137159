import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogContentText,
  CircularProgress,
  DialogActions,
} from '@material-ui/core'
import {
  translate,
  refreshView,
  showNotification,
} from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { getCurrentPairedDevice, sanitize } from '../utils/commonUtil'
import { RemotePrintIcon } from '../icons'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'
import { remotePrint } from '../utils/device-action'
import withMQTT from '../mqtt/withMQTT'
import { PAIRING_DEVICE_IN_REMOTE_PRINT_BUTTON } from '../common/mqttEventEmitter'
import { MqttActionName } from '../common/constants'
import { Provider } from '../provider'
import QRCode from 'qrcode.react'
import moment from 'moment'
import _ from 'lodash'

let AGENCY_PACKAGE_NAME = 'vn.nexbus.agency'
let QRCODE_LOADING = 'loading'
let QRCODE_RENDERED = 'rendered'
let QRCODE_TIMEOUT = 'timeout'
let QRCODE_PAIRED_SUCCESS = 'success'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.2em',
    fontSize: 20
  },
}

const dialogStyle = {
  content: {
    margin: 'auto',
  },
  textContent: {
    textAlign: 'center',
  },
  textConfirm: {
    color: 'rgba(0, 0, 0, 0.56)',
  },
}

class _ConfirmDialog extends Component {

state = {
  ids: [],
  pagedItems: [],
  data: {},
  page: 0,
  perPage: 5,
  total: 0,
}

componentDidMount() {
  let currentDevice = getCurrentPairedDevice(AGENCY_PACKAGE_NAME)
  this.setState({ currentDevice })
}

setCurrentDevice = currentDevice => {
  this.setState({ currentDevice })
}

render() {
  let {
    open,
    onClose,
    translate,
    classes,
    print,
  } = this.props
  let { currentDevice } = this.state
  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle>
      <Typography variant="h4">{currentDevice ? translate('notification.remote_print_confirm') : translate('notification.warning_no_paired_device')}</Typography>
    </DialogTitle>
    <DialogContent className={classes.content}>
      {currentDevice ? <span className={classes.textConfirm}>
        {translate('notification.have_paired_device')}
      </span> : <Fragment>
        <DialogContentText className={classes.textContent}>
          {translate('notification.warning_no_agency_paired_device')}
        </DialogContentText>
        <PairingQRCode setCurrentDevice={this.setCurrentDevice} />
      </Fragment>}
    </DialogContent>
    {currentDevice && <DialogActions>
      <Button variant="contained" color="primary" onClick={() => print(currentDevice)}>{translate('button.continue')}</Button>
      <Button onClick={onClose}>{translate('button.close')}</Button>
    </DialogActions>}
  </Dialog> 
}}

const enhanceConfirmDialog = compose(translate, withStyles(dialogStyle)) 
const ConfirmDialog = enhanceConfirmDialog(_ConfirmDialog)

const pairingQRCodeStyle = {
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  textExpired: {
    fontSize: 20,
    textAlign: 'center'
  },
  warningTextExpired: {
    fontSize: 30,
    fontWeight: 'bold'
  },
  qrContainer: {
    marginTop: 16,
    textAlign: 'center',
  },
  loadingContainer: {
    marginTop: 16,
    textAlign: 'center',
  },
  expiredContainer: {
    textAlign: 'center',
  },
}

const deleteAwaitPong = array => {
  let newArr = [...array]
  newArr = newArr.map( ele => {
    let eleWithoutAwaitPong = {...ele}
    delete eleWithoutAwaitPong['awaitPong']
    return eleWithoutAwaitPong
  })
  return newArr
}

class _ParingQRCode extends Component {

  state = {
    timeNow: +moment(),
    QRData: {},
    status: 'loading',
    devices: [],
    newKeyPair: '',
    hasPairing: false,
    error: {}
  }

  getQRData = () => {
    Provider.dataProvider('REMOTE', 'devices', {
      method: 'getPairQRInfo',
      requestMethod: 'GET'
    }).then(res => {
      let { subscribe, mqttEmitter } = this.props
      let { data } = res
      let newKeyPair = data.chanel
      this.setState({
        qrData: { qrDataValue: data.qrData, expiredAt: data.expiredAt },
        status: 'rendered',
        timeNow: data.timeNow,
        newKeyPair
      })
      //subscribe(`device/${newKeyPair}`)
      //mqttEmitter.on(PAIRING_DEVICE_IN_REMOTE_PRINT_BUTTON, (message) => this.onMessage(message))
    }).catch(err => {
      let { currentLanguage } = this.props
      let messageErr = err.message
      if (currentLanguage === 'en') {
        messageErr = err.message_en
      }
      this.setState({ 
        error: {
          message: messageErr
        },
        status: 'error'
      })
    })
  }

  componentDidMount() {
    this.timerId = setInterval(
      () => {
        this.setState(state => ({ timeNow: state.timeNow + 1000 }),
          () => {
            let { qrData, timeNow, status } = this.state
            if (qrData && qrData.expiredAt) {
              if (timeNow >= qrData.expiredAt) {
                if (status !== QRCODE_PAIRED_SUCCESS) {
                  this.setState({ status: QRCODE_TIMEOUT })
                }
              }
            }
          })
      },
      1000
    )
    this.getQRData()
  }

  componentWillUnmount() {
    let { unsubscribe } = this.props
    let { newKeyPair } = this.state
    clearInterval(this.timerId)
    unsubscribe(`device/${newKeyPair}`)
  }

  onHandleClickReload = () => {
    this.setState({ status: QRCODE_LOADING })
    this.getQRData()
  }

  addIntoDevices = device => {
    let { devices } = this.state
    let pos = _.findIndex(devices, {'packageName': device.packageName})
    if (pos !== -1) {
      devices[pos] = device
    } else {
      devices.unshift(device)
    }
    this.setState({ devices })
    devices = deleteAwaitPong(devices)
    localStorage.setItem('devices', JSON.stringify(devices))
  }

  onMessage = message => {
    let data = JSON.parse(message)
    let { newKeyPair } = this.state
    let { setCurrentDevice } = this.props
    if (data && data.name) {
      let newDevice = {}
      const { payload = {}, name } = data
      let { deviceSerial, packageName } = payload
      switch (name) {
        case MqttActionName.PAIR_DEVICE:
          this.setState({ 
            status: QRCODE_PAIRED_SUCCESS,
            hasPairing: true,
          })
          newDevice = {
            deviceSerial: deviceSerial,
            packageName: packageName,
            keyPair: newKeyPair,
            isOnline: true,
            awaitPong: false,
          }
          setCurrentDevice(newDevice)
          this.addIntoDevices(newDevice)
          break
        default:
          console.log(data)
      }
    }
  }

  render() {
    let { classes, translate } = this.props
    let { status, qrData = {}, timeNow } = this.state
    let { qrDataValue = '', expiredAt } = qrData
    return <Fragment> 
      {status === QRCODE_LOADING && <div className={classes.loadingContainer}><CircularProgress disableShrink /></div>}
      {status === QRCODE_RENDERED && <div className={classes.qrContainer}>
        <QRCode value={qrDataValue} size={256} />
        <p className={classes.textExpired}>
          {translate('resources.common.timeLeft')}&nbsp;
          <b>{moment(expiredAt).diff(timeNow, 'seconds')}({translate('resources.common.seconds')})</b>
        </p>
      </div>}
      {status === QRCODE_TIMEOUT && <div className={classes.expiredContainer}>
        <p className={classes.warningTextExpired}>{translate('notification.qrCodeHasExpired')}</p>
        <Button
          variant="contained"
          color="primary"
          onClick={this.onHandleClickReload}
        >
          {translate('button.refresh')}
        </Button>
      </div>}
      {status === QRCODE_PAIRED_SUCCESS && <div></div>}
    </Fragment>
  }
}

const enhancePairingQRCode = compose(
  withMQTT({ eventListener: PAIRING_DEVICE_IN_REMOTE_PRINT_BUTTON }),
  translate,
  withStyles(pairingQRCodeStyle),
)

const PairingQRCode = enhancePairingQRCode(_ParingQRCode)

class RemotePaymentQRButton extends Component {

  state = {
    openConfirmDialog: false,
  }

  openConfirmDialog = () => {
    this.setState({
      openConfirmDialog: true
    })
  }

  onClose = () => {
    this.setState({
      openConfirmDialog: false
    })
  }

  print = async (currentDevice) => {
    let { code } = this.props
    let { deviceSerial, keyPair } = currentDevice
    await remotePrint(deviceSerial, keyPair, code)
    this.onClose()
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      ...props
    } = this.props
    let { openConfirmDialog } = this.state
    let rest = sanitize(props, [ 'reset', 'changeForm', 'showNotification', 'refreshView'])
    return <Fragment>
      {button 
        ? <Button
          color={color}
          onClick={this.openConfirmDialog}
          {...rest}
        >
          <RemotePrintIcon className={classes.icon} size="xs" />
          <span>{translate('button.remote_print')}</span>
        </Button>
        : <Tooltip
          title={translate('button.remote_print')}
          enterDelay={100}
        >
          <LinkField
            className={classes.iconButton}
            icon
            color={color}
            onClick={this.openConfirmDialog}
            {...rest}
          >
            <RemotePrintIcon fontSize="small" />
          </LinkField>
        </Tooltip>
      }
      {openConfirmDialog && <ConfirmDialog 
        open={openConfirmDialog}
        print={this.print}
        onClose={this.onClose}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { refreshView, showNotification, reset, changeForm })
)

export default enhance(RemotePaymentQRButton)
