import React, { Component } from 'react'
import SettingPosItem from './SettingPosItem'
import { driverPosItems, actionDriver } from '../menu/items'

class DriverPosSetting extends Component {

  render() {
    let { record, tabIndexPos, onChangeTabIndexPos, isAdmin, match, location } = this.props
    return <SettingPosItem
      tabIndexPos={tabIndexPos}
      onChangeTabIndexPos={onChangeTabIndexPos}
      record={record}
      resources= "companysettings"
      isAdmin={isAdmin}
      location={location}
      match={match}
      menuItems={driverPosItems}
      actionItems={actionDriver}
      hideMenu="pos.driver"
      actionDriver={1}
    />
  }
}

export default DriverPosSetting
