import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  showNotification,
  translate,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import SelectInputCustom from '../common/SelectInputCustom'
import CustomToolbar from '../common/CustomToolbarForm'

const styles = () => ({
  root: {},
})

const types = [
  { id: 'show', name: 'show' },
  { id: 'auto', name: 'auto' },
]

class FormSave extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    let extra = { resources: 'fareconditions' }

    return (
      <SimpleForm
        toolbar={<CustomToolbar />}
        {...this.props}
        submitOnEnter={false}
      >
        <TextInput
          label="resources.fareconditions.fields.id"
          source="code"
          fullWidth
          { ...extra }
        />
        <TextInput
          label="resources.fareconditions.fields.name"
          source="name"
          fullWidth
          { ...extra }
        />
        <TextInput
          label="resources.fareconditions.fields.default"
          source="default"
          fullWidth
          { ...extra }
        />
        <SelectInputCustom
          label="resources.fareconditions.fields.type"
          choices={types}
          source="type"
          allowEmpty
          fullWidth
          {...extra}
        />
      </SimpleForm>
    )
  }
}

const enhance = compose(
  connect(null, { showNotification }),
  translate,
  withStyles(styles),
)

export default enhance(FormSave)
