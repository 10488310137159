import React, { Component } from 'react'
import {
  ReferenceInput,
  SelectInput,
  SimpleForm,
  showNotification,
  addField,
  refreshView,
  translate,
  required
} from 'react-admin'
import { Dialog, DialogContent, DialogTitle, withStyles } from '@material-ui/core'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { reset } from 'redux-form'
import CustomToolbar from '../common/CustomToolbarForm'

const _SelectStopInput = ({ choices, input, label, helperText, disabled, meta, required }) => {
  return <FuzzySelectInput 
    label={label}
    choices={choices}
    input={input}
    meta ={meta}
    required={required}
    renderItem={item => item.name}
    helperText={helperText}
    disabled={disabled}
    { ...defaultFuzzySearch }
  />
}

const SelectStopInput = addField(_SelectStopInput)

const styles = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

const defaultValue = { status: '10ACT', type: '20BOTH'}

class SetupPassengerCollectionDialog extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState){
    let { setupPassengerCollectionDialog } = nextProps
    let { record = {} } = setupPassengerCollectionDialog
    let { record: currentRecord } = prevState
    if (record !== currentRecord) {
      return({
        ...prevState,
        record: {...record }
      })
    }
    return({
      ...prevState,
    })
  }

  create = record => {
    let { showNotification, onClose, onDone, reset } = this.props
    Provider.dataProvider('CREATE', 'passengercollections', {
      data: record,
    }).then((res) => {
      let data = res.data
      showNotification('notification.setup_passenger_collection_success')
      reset('setup-passenger-collection')
      onClose()
      onDone(data) 
    }).catch(() => {
      showNotification('notification.setup_passenger_collection_fail', 'warning')
      reset('setup-passenger-collection')
      onClose()
    })
  }

  update = record => {
    let { showNotification, onClose, onDone, reset } = this.props
    Provider.dataProvider('UPDATE', 'passengercollections', {
      id: record.id,
      data: record,
    }).then((res) => {
      let data = res.data
      showNotification('notification.setup_passenger_collection_success')
      reset('setup-passenger-collection')
      onClose()
      onDone(data) 
    }).catch(() => {
      showNotification('notification.setup_passenger_collection_fail', 'warning')
      reset('setup-passenger-collection')
      onClose()
    })
  }

  onSave = record => {
    let { id } = record
    if (id) {
      this.update(record)
    } else {
      this.create(record)
    }
  }

  handleClose = () => {
    let { onClose, reset } = this.props
    reset('setup-passenger-collection')
    onClose()
  }

  render() {
    let { 
      setupPassengerCollectionDialog,
      routeStops,
      translate,
      classes,
    } = this.props
    let { record } = this.state
    let { open, disableRelationStop } = setupPassengerCollectionDialog
    let extra = { resource: 'passengercollections', fullWidth: true }
    return <Dialog
      open={open}
      onClose={this.handleClose}
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>
          {translate('resources.passengercollections.title')}
        </span>
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          form="setup-passenger-collection"
          record={record}
          save={this.onSave}
          {...extra}
          toolbar={<CustomToolbar />}
          defaultValue={defaultValue}
        >
          <SelectStopInput
            label={translate('resources.passengercollections.fields.stopId')} 
            source="stopId" 
            choices={routeStops}
            {...extra}
            disabled={disableRelationStop}
            required={true}
            validate={required(translate('resources.passengercollections.helperTextStop'))}
          /> 
          <ReferenceInput
            reference="passengercollectiontypes"
            source="type"
            {...extra}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <ReferenceInput
            reference="statuses"
            source="status"
            filter={{ where: { id : { inq: ['00IAT', '10ACT'] }}}}
            {...extra}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }
}

const enhance = compose(
  translate,
  connect(null, { showNotification, reset, refreshView }),
  withStyles(styles)
)
export default enhance(SetupPassengerCollectionDialog)
