import React, {Component} from 'react'
import {
  translate,
} from 'react-admin'
import {
  Button,
  TextField,
  withStyles,
} from '@material-ui/core'
import {FormControlCustom} from './components'
import compose from 'recompose/compose'

const styles = () => ({
  group: {
    display: 'flex',
    marginBottom: 10,
    flexWrap: 'wrap',
    gap: '10px',
    width: '100%',
  },
  inputUpload: {
    display: 'none'
  }
})

class StageEditor extends Component {
  constructor (props) {
    super(props) 
    this.state = {
      width: null,
      height: null,
    }
  }

  onChangeSize = (e) => {
    this.setState({[e.target.name]: e.target.value})
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { width, height } = this.props
    const updated = (width !== prevProps.width || height !== prevProps.height)
    return { updated }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { width, height } = this.props
      if (width && height) {
        this.setState({width, height})
      }
    }
  }

  componentDidMount() {
    let { width, height } = this.props
    this.setState({width, height})
  }
  
  render () {
    const {
      classes,
      onChangeBackground,
      translate,
      handleActualWidth,
      actualWidth,
    } = this.props
    const {width, height} = this.state
    return <>
        <div className={classes.group}>
          <FormControlCustom unit="mm" label="resources.editor.fields.actualWidth">
            <TextField
              id="actualWidth"
              type="number"
              name="actualWidth" 
              onChange={handleActualWidth}
              value={Number(actualWidth) || 80}
              variant="outlined"
              size="small"
              fullWidth
              required
            />
          </FormControlCustom>
        </div>
        <div className={classes.group}>
          <FormControlCustom unit="px" label="resources.editor.fields.width">
            <TextField
              id="width"
              type="number"
              name="width" 
              onChange={this.onChangeSize}
              value={Number(width) || 0}
              variant="outlined"
              size="small"
              fullWidth
            />
          </FormControlCustom>
          <FormControlCustom unit="px" label="resources.editor.fields.height">
            <TextField
              id="height"
              type="number"
              name="height" 
              onChange={this.onChangeSize}
              value={Number(height) || 0}
              variant="outlined"
              size="small"
              fullWidth
            />
          </FormControlCustom>
        </div>
      <div className={classes.group}>
        <div style={{flex: 1}}>
          <Button 
            variant="outlined"
            onClick={(e)=>onChangeBackground(e, true, {width, height})}
            fullWidth
            style={{flex: 1}}
          >{translate('resources.editor.button.blankBackground')}</Button>
        </div>
        <div style={{flex: 1}}>
          <input
            accept="image/*"
            className={classes.inputUpload}
            id="contained-button-file"
            onChange={(e) =>onChangeBackground(e, false, {width, height})}
            type="file"
          />
          <label htmlFor="contained-button-file">
            <Button fullWidth variant="outlined" component="span">
              {translate('resources.editor.button.uploadBackground')}
            </Button>
          </label>
        </div>
      </div>
    </>
  }
}

export default compose(withStyles(styles), translate)(StageEditor)
