import React, { Component } from 'react'
import {
  TextInput,
  SimpleForm,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import { Grid, withStyles } from '@material-ui/core'
import CustomToolbar from '../common/CustomToolbarForm'

const style = () => ({
  root: {
    width: '100%'
  }
})

class FormSave extends Component {
  render() {
    let { classes } = this.props
    const extra = {
      resource: 'devicemodels',
      fullWidth: true,
    }
    return (
      <SimpleForm toolbar={<CustomToolbar />} {...this.props}>
        <Grid container className={classes.root} >
          <Grid item xs={6} md={6}>
            <TextInput source="model" {...extra} />
            <TextInput 
              source="name"
              {...extra}
              validate={[required(), minLength(6), maxLength(255)]} 
            />
            <TextInput source="category" {...extra} />
            <TextInput source="desc" {...extra} />
            <TextInput source="os" {...extra} />
            <TextInput source="gps" {...extra} />
            <TextInput source="wifiVersion" {...extra} />
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

export default withStyles(style)(FormSave)
