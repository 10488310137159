import 'core-js/stable'
import 'regenerator-runtime/runtime'
import React, { Component } from 'react'
import { Admin, Resource, Login } from 'react-admin'
import './App.css'

// Application Layout
import Layout from './ui/Layout'

// Language
import { Language } from './i18n'

// Provider
import { Provider } from './provider'

// Dashboard
import Dashboard from './dashboard/'

// History
import { createBrowserHistory } from 'history'

// Route
import route from './routes'

// Sagas
import sagas from './sagas'

// Snack bar
import { SnackbarProvider } from './common/notistack'

// Reducer
import customReducer from './reducer'

// Resources
import { CompanyList, CompanyCreate, CompanyEdit } from './company'
import { AgencyList, AgencyCreate, AgencyEdit } from './agency'
import { RouteList, RouteCreate, RouteEdit } from './route'
import { ServiceList, ServiceCreate, ServiceEdit } from './service'
import { StopList, StopCreate, StopEdit } from './stop'
import { StopTimeList, StopTimeCreate, StopTimeEdit } from './stoptime'
import { FareList } from './fare'
import { FareRuleList, FareRuleCreate, FareRuleEdit } from './farerule'
import { TimeTableList, TimeTableCreate, TimeTableEdit } from './timetable'
import { VehicleList, VehicleCreate, VehicleEdit } from './vehicle'
import { TripList, TripCreate, TripEdit } from './trip'
import {
  RoutePatternList,
  RoutePatternCreate,
  RoutePatternEdit,
} from './routepattern'
import TimeScheduler from './timescheduler'
import { RouteGroupList, RouteGroupCreate, RouteGroupEdit } from './routegroup'
import { ScheduleCreate, ScheduleEdit, ScheduleList } from './schedule'
import { DriverList, DriverCreate, DriverEdit } from './driver'
import {
  VehicleTypeList,
  VehicleTypeCreate,
  VehicleTypeEdit,
} from './vehicletype'
import { ProductList, ProductCreate, ProductEdit } from './product'
import {
  TicketLayoutList,
  TicketLayoutCreate,
  TicketLayoutEdit,
} from './ticketlayout'
import {
  ReservationShow,
  ReservationList,
  ReservationCreate,
  ReservationEdit,
} from './reservation'
import { DeviceList, DeviceCreate, DeviceEdit } from './device'
import {
  DeviceConfigList,
  DeviceConfigCreate,
  DeviceConfigEdit,
} from './deviceconfig'
import {
  DeviceModelList,
  DeviceModelCreate,
  DeviceModelEdit,
} from './devicemodel'
import { FareTableList, FareTableEdit, FareTableCreate } from './faretable'
import { LicenceList, LicenceCreate, LicenceEdit } from './licence'
import ViewTripList from './viewtrip/index'
import { SeatMapList, SeatMapCreate, SeatMapEdit } from './seatmap'
import {
  CreateMaintainForm,
  EditMaintainForm,
  VehicleMaintainList,
} from './maintain'
import {
  MaintainTemplateList,
  CreateTemplateForm,
  EditTemplateForm,
} from './maintaintemplate'
import { AppList, AppCreate, AppEdit } from './deviceapp'
import { AppConfigList, AppConfigCreate, AppConfigEdit } from './appconfig'
import {
  NotificationList,
  NotificationCreate,
  NotificationEdit,
} from './notification'
import { UserList, UserEdit } from './user'
import { CallLogList } from './calllog'
import { TransactionList, TransactionShow } from './transaction'
import {
  ConfigurationList,
  ConfigurationEdit,
  ConfigurationCreate,
} from './configuration'
import { withStyles } from '@material-ui/core'
import { JssProvider } from 'react-jss'
import {
  FinanceAccountList,
  FinanceAccountCreate,
  FinanceAccountEdit,
} from './finance_account'
import { AccountCreditList } from './account_credit'
import { CampaignList, CampaignCreate, CampaignEdit } from './campaign'
import {
  AssistantDriverList,
  AssistantDriverCreate,
  AssistantDriverEdit,
} from './assistant_driver'
import * as permission from './utils/permission'
import { CustomerList, CustomerEdit } from './customer'
import {
  ReconciliationList,
  ReconciliationCreate,
  ReconciliationEdit,
} from './reconciliation'
import { ProductChargeList } from './productcharge'
import { TagList, TagCreate, TagEdit } from './tag'
import { PosSessionList, PosSessionEdit } from './possession'
import { MemberCardList, MemberCardEdit, MemberCardShow } from './member_card'
import {
  FareCompanionList,
  FareCompanionCreate,
  FareCompanionEdit,
} from './fare_companion'
import {
  FareConditionList,
  FareConditionCreate,
  FareConditionEdit,
} from './fare_condition'
import {
  ReceivePlaceList,
  ReceivePlaceEdit,
  ReceivePlaceCreate,
  ReceivePlaceShow,
} from './receive_place'
import { EInvoiceList } from './einvoice'

const history = createBrowserHistory()

const LoginPage = (props) => {
  if (localStorage.getItem('token')) {
    window.location = '/'
    return null
  }
  return (
    <JssProvider classNamePrefix="b">
      <Login {...props} backgroundImage="/images/background.png" />
    </JssProvider>
  )
}

class BaseApp extends Component {
  render() {
    return (
      <Admin
        dataProvider={Provider.dataProvider}
        authProvider={Provider.authProvider}
        i18nProvider={Provider.i18nProvider}
        locale={Language.VIETNAMESE}
        dashboard={Dashboard}
        history={history}
        appLayout={Layout}
        title={process.env.REACT_APP_NAME}
        customSagas={sagas}
        customRoutes={route}
        customReducers={customReducer}
        loginPage={LoginPage}
      >
        {(permissions) => {
          let isAdmin = permission.isAdmin(permissions)
          let isCompanyManager = permission.isCompanyManager(permissions)
          return [
            <Resource
              key="companies"
              name="companies"
              list={CompanyList}
              create={isAdmin ? CompanyCreate : null}
              edit={CompanyEdit}
            />,
            <Resource
              name="agencies"
              key="agencies"
              list={AgencyList}
              create={isAdmin ? AgencyCreate : null}
              edit={AgencyEdit}
            />,
            <Resource
              key="routegroups"
              name="routegroups"
              list={RouteGroupList}
              create={isCompanyManager ? RouteGroupCreate : null}
              edit={RouteGroupEdit}
            />,
            <Resource
              key="vehicles"
              name="vehicles"
              list={VehicleList}
              create={isCompanyManager ? VehicleCreate : null}
              edit={VehicleEdit}
            />,
            <Resource key="fares" name="fares" list={FareList} />,
            <Resource
              key="vehicletypes"
              name="vehicletypes"
              list={VehicleTypeList}
              create={isCompanyManager ? VehicleTypeCreate : null}
              edit={VehicleTypeEdit}
            />,
            <Resource
              key="routepatterns"
              name="routepatterns"
              list={RoutePatternList}
              create={RoutePatternCreate}
              edit={RoutePatternEdit}
            />,
            <Resource
              key="drivers"
              name="drivers"
              list={DriverList}
              create={isCompanyManager ? DriverCreate : null}
              edit={DriverEdit}
            />,
            <Resource
              key="seatmaps"
              name="seatmaps"
              list={SeatMapList}
              create={isCompanyManager ? SeatMapCreate : null}
              edit={SeatMapEdit}
            />,
            <Resource
              key="faretables"
              name="faretables"
              list={FareTableList}
              create={isCompanyManager ? FareTableCreate : null}
              edit={FareTableEdit}
            />,
            <Resource
              key="devices"
              name="devices"
              list={DeviceList}
              create={isAdmin ? DeviceCreate : null}
              edit={DeviceEdit}
            />,
            <Resource
              key="apps"
              name="apps"
              list={AppList}
              create={isAdmin ? AppCreate : null}
              edit={AppEdit}
            />,
            <Resource
              key="configurations"
              name="configurations"
              list={ConfigurationList}
              edit={ConfigurationEdit}
              create={isAdmin ? ConfigurationCreate : null}
            />,
            <Resource
              key="financeaccounts"
              name="financeaccounts"
              list={FinanceAccountList}
              edit={FinanceAccountEdit}
              create={isAdmin ? FinanceAccountCreate : null}
            />,
            <Resource
              key="vehiclemaintains"
              name="vehiclemaintains"
              list={VehicleMaintainList}
              create={isCompanyManager ? CreateMaintainForm : null}
              edit={EditMaintainForm}
            />,
            <Resource
              key="services"
              name="services"
              list={ServiceList}
              create={isCompanyManager ? ServiceCreate : null}
              edit={ServiceEdit}
            />,
            <Resource
              key="products"
              name="products"
              list={ProductList}
              create={isCompanyManager ? ProductCreate : null}
              edit={ProductEdit}
            />,
            <Resource
              key="ticketlayouts"
              name="ticketlayouts"
              list={TicketLayoutList}
              create={TicketLayoutCreate}
              edit={TicketLayoutEdit}
            />,
            <Resource
              key="timetablegroups"
              name="timetablegroups"
              list={TimeTableList}
              create={isCompanyManager ? TimeTableCreate : null}
              edit={TimeTableEdit}
            />,
            <Resource
              key="schedules"
              name="schedules"
              list={ScheduleList}
              create={isCompanyManager ? ScheduleCreate : null}
              edit={ScheduleEdit}
            />,
            <Resource
              key="possessions"
              name="possessions"
              list={PosSessionList}
              edit={PosSessionEdit}
            />,
            <Resource key="possessionstatuses" name="possessionstatuses" />,
            <Resource
              key="maintaintemplates"
              name="maintaintemplates"
              list={MaintainTemplateList}
              create={CreateTemplateForm}
              edit={EditTemplateForm}
            />,
            <Resource
              key="routes"
              name="routes"
              list={RouteList}
              create={RouteCreate}
              edit={RouteEdit}
            />,
            <Resource
              key="stops"
              name="stops"
              list={StopList}
              create={StopCreate}
              edit={StopEdit}
            />,
            <Resource
              key="stoptimes"
              name="stoptimes"
              list={StopTimeList}
              create={StopTimeCreate}
              edit={StopTimeEdit}
            />,
            <Resource key="tripstatuses" name="tripstatuses" />,
            <Resource key="routestatuses" name="routestatuses" />,
            <Resource key="triphistories" name="triphistories" />,
            <Resource
              key="trips"
              name="trips"
              list={TripList}
              create={TripCreate}
              edit={TripEdit}
            />,
            <Resource
              key="viewblocks"
              name="viewblocks"
              list={TimeScheduler}
            />,
            <Resource name="agencytypes" key="agencytypes" />,
            <Resource name="actions" key="actions" />,
            <Resource
              key="farerules"
              name="farerules"
              list={FareRuleList}
              create={FareRuleCreate}
              edit={FareRuleEdit}
            />,
            <Resource key="fareruletypes" name="fareruletypes" />,
            <Resource key="farecriteria" name="farecriteria" />,
            <Resource key="farecriteriatypes" name="farecriteriatypes" />,
            <Resource key="farestatuses" name="farestatuses" />,
            <Resource key="currencies" name="currencies" />,
            <Resource key="statuses" name="statuses" />,
            <Resource key="stoptypes" name="stoptypes" />,
            <Resource key="routetypes" name="routetypes" />,
            <Resource key="vehiclestatuses" name="vehiclestatuses" />,
            <Resource key="vehicleclasses" name="vehicleclasses" />,
            <Resource key="fueltypes" name="fueltypes" />,
            <Resource key="zones" name="zones" />,
            <Resource key="countries" name="countries" />,
            <Resource key="cities" name="cities" />,
            <Resource key="provinces" name="provinces" />,
            <Resource
              key="routes/getRouteWithStop"
              name="routes/getRouteWithStop"
            />,
            <Resource key="routes/journeys" name="routes/journeys" />,
            <Resource key="servicedays" name="servicedays" />,
            <Resource key="maps" name="maps" />,
            <Resource key="networks" name="networks" />,
            <Resource key="driverstatuses" name="driverstatuses" />,
            <Resource key="licenceclasses" name="licenceclasses" />,
            <Resource key="producttypes" name="producttypes" />,
            <Resource key="seatmaptemplates" name="seatmaptemplates" />,
            <Resource key="reservationsources" name="reservationsources" />,
            <Resource key="reservationstatuses" name="reservationstatuses" />,
            <Resource
              key="reservations"
              name="reservations"
              show={ReservationShow}
              list={ReservationList}
              create={ReservationCreate}
              edit={ReservationEdit}
            />,
            <Resource key="charges" name="charges" />,
            <Resource key="viewtrips" name="viewtrips" list={ViewTripList} />,
            <Resource key="paymentmethods" name="paymentmethods" />,
            <Resource
              key="transactions"
              name="transactions"
              list={TransactionList}
              show={TransactionShow}
            />,
            <Resource key="transactionstatuses" name="transactionstatuses" />,
            <Resource key="transactiondetails" name="transactiondetails" />,
            <Resource key="transactiontypes" name="transactiontypes" />,
            <Resource
              key="devicemodels"
              name="devicemodels"
              list={DeviceModelList}
              create={isAdmin ? DeviceModelCreate : null}
              edit={DeviceModelEdit}
            />,
            <Resource
              key="deviceconfigs"
              name="deviceconfigs"
              list={DeviceConfigList}
              create={DeviceConfigCreate}
              edit={DeviceConfigEdit}
            />,
            <Resource key="routefaretables" name="routefaretables" />,
            <Resource
              key="licences"
              name="licences"
              list={LicenceList}
              create={LicenceCreate}
              edit={LicenceEdit}
            />,
            <Resource
              key="campaigns"
              name="campaigns"
              list={CampaignList}
              create={isAdmin || isCompanyManager ? CampaignCreate : null}
              edit={CampaignEdit}
            />,
            <Resource key="licencetypes" name="licencetypes" />,
            <Resource key="frequencyunits" name="frequencyunits" />,
            <Resource key="licencestatuses" name="licencestatuses" />,
            <Resource key="licencevehicles" name="licencevehicles" />,
            <Resource key="licencedrivers" name="licencedrivers" />,
            <Resource key="servicetasks" name="servicetasks" />,
            <Resource key="servicereminders" name="servicereminders" />,
            <Resource key="calendar" name="calendar" />,
            <Resource key="servicegroups" name="servicegroups" />,
            <Resource key="maintainactions" name="maintainactions" />,
            <Resource key="maintainitems" name="maintainitems" />,
            <Resource key="maintaingroups" name="maintaingroups" />,
            <Resource key="maintainschedules" name="maintainschedules" />,
            <Resource
              key="servicereminderstatuses"
              name="servicereminderstatuses"
            />,
            <Resource key="licencevalidities" name="licencevalidities" />,
            <Resource
              key="appconfigs"
              name="appconfigs"
              list={AppConfigList}
              create={isAdmin ? AppConfigCreate : null}
              edit={AppConfigEdit}
            />,
            <Resource key="deviceapps" name="deviceapps" />,
            <Resource key="os" name="os" />,
            <Resource
              key="notifications"
              name="notifications"
              list={NotificationList}
              create={NotificationCreate}
              edit={NotificationEdit}
            />,
            <Resource
              key="users"
              name="users"
              list={UserList}
              edit={UserEdit}
            />,
            <Resource key="calllogs" name="calllogs" list={CallLogList} />,
            <Resource key="calltypes" name="calltypes" />,
            <Resource key="calllogstatuses" name="calllogstatuses" />,
            <Resource key="reservationmappings" name="reservationmappings" />,
            <Resource key="companyagencies" name="companyagencies" />,
            <Resource key="configurationgroups" name="configurationgroups" />,
            <Resource
              key="configurationdatatypes"
              name="configurationdatatypes"
            />,
            <Resource key="routeproducttypes" name="routeproducttypes" />,
            <Resource key="routeproducts" name="routeproducts" />,
            <Resource key="productfares" name="productfares" />,
            <Resource key="triptypes" name="triptypes" />,
            <Resource key="faretypes" name="faretypes" />,
            <Resource key="settings" name="settings" />,
            <Resource key="settinggroups" name="settinggroups" />,
            <Resource key="reservationpayments" name="reservationpayments" />,
            <Resource
              key="financeaccountmappings"
              name="financeaccountmappings"
            />,
            <Resource key="accounttypes" name="accounttypes" />,
            <Resource
              key="accountcredits"
              name="accountcredits"
              list={AccountCreditList}
            />,
            <Resource key="routes/list" name="routes/list" />,
            <Resource key="schedules/list" name="schedules/list" />,
            <Resource key="trips/list" name="trips/list" />,
            <Resource key="vehicles/list" name="vehicles/list" />,
            <Resource key="drivers/list" name="drivers/list" />,
            <Resource
              key="assistantdrivers/list"
              name="assistantdrivers/list"
            />,
            <Resource key="companies/list" name="companies/list" />,
            <Resource key="agencies/list" name="agencies/list" />,
            <Resource key="passengercollections" name="passengercollections" />,
            <Resource
              key="passengercollectiontypes"
              name="passengercollectiontypes"
            />,
            <Resource key="campaigntypes" name="campaigntypes" />,
            <Resource key="campaignstatuses" name="campaignstatuses" />,
            <Resource
              key="assistantdrivers"
              name="assistantdrivers"
              list={AssistantDriverList}
              create={isCompanyManager ? AssistantDriverCreate : null}
              edit={AssistantDriverEdit}
            />,
            <Resource
              key="customers"
              name="customers"
              list={CustomerList}
              edit={CustomerEdit}
            />,
            <Resource key="customercompanies" name="customercompanies" />,
            <Resource key="paymentmethod2s" name="paymentmethod2s" />,
            <Resource key="platformtransactions" name="platformtransactions" />,
            <Resource key="platformdebits" name="platformdebits" />,
            <Resource
              key="reconciliations"
              name="reconciliations"
              list={ReconciliationList}
              create={ReconciliationCreate}
              edit={ReconciliationEdit}
            />,
            <Resource
              key="reconciliationstatuses"
              name="reconciliationstatuses"
            />,
            <Resource
              key="platformdebitstatuses"
              name="platformdebitstatuses"
            />,
            <Resource key="routegrouptypes" name="routegrouptypes" />,
            <Resource key="taxes" name="taxes" />,
            <Resource key="agencyproducts" name="agencyproducts" />,
            <Resource key="productfaretypes" name="productfaretypes" />,
            <Resource key="supplierstatuses" name="supplierstatuses" />,
            <Resource key="supplierproducts" name="supplierproducts" />,
            <Resource key="chargestatuses" name="chargestatuses" />,
            <Resource
              key="productcharges"
              name="productcharges"
              list={ProductChargeList}
            />,
            <Resource
              key="users/listCompanyUsers"
              name="users/listCompanyUsers"
            />,
            <Resource
              key="tags"
              name="tags"
              list={TagList}
              create={TagCreate}
              edit={TagEdit}
            />,
            <Resource key="tagstatuses" name="tagstatuses" />,
            <Resource key="productstatuses" name="productstatuses" />,
            <Resource key="usercontacts" name="usercontacts" />,
            <Resource key="usersubscriptions" name="usersubscriptions" />,
            <Resource key="messagetypes" name="messagetypes" />,
            <Resource key="eventtypes" name="eventtypes" />,
            <Resource
              key="membercards"
              name="membercards"
              list={MemberCardList}
              show={MemberCardShow}
              edit={MemberCardEdit}
            />,
            <Resource
              key="farecompanions"
              name="farecompanions"
              list={FareCompanionList}
              create={FareCompanionCreate}
              edit={FareCompanionEdit}
            />,
            <Resource key="fareindices" name="fareindices" />,
            <Resource
              key="fareconditions"
              name="fareconditions"
              list={FareConditionList}
              create={isCompanyManager ? FareConditionCreate : null}
              edit={FareConditionEdit}
            />,
            <Resource
              key="receiveplaces"
              name="receiveplaces"
              list={ReceivePlaceList}
              create={isCompanyManager ? ReceivePlaceCreate : null}
              show={ReceivePlaceShow}
              edit={ReceivePlaceEdit}
            />,
            <Resource key="einvoices" name="einvoices" list={EInvoiceList} />,
          ]
        }}
      </Admin>
    )
  }
}

const style = () => ({
  root: {},
  message: {
    width: 250,
    fontWeight: 300,
  },
  iconInfo: {
    marginRight: 16,
  },
})

//<Connector events={events}>
//</Connector>
const App = withStyles(style)(({ classes }) => (
  <SnackbarProvider
    maxSnack={3}
    classes={{
      root: classes.root,
      message: classes.message,
    }}
  >
    <BaseApp />
  </SnackbarProvider>
))

export default App
