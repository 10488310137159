import React, { Component, Fragment } from 'react'
import {
  Avatar,
  MenuItem,
  Chip,
  Popper,
  MenuList,
  Grow,
  Paper,
  ClickAwayListener,
  ListItemIcon,
  ListItemText,
  withStyles,
  Collapse,
} from '@material-ui/core'
import {
  translate,
  WithPermissions,
  userLogout
} from 'react-admin'
import isEmpty from 'lodash/isEmpty'
import { getCurrentUser, getCurrentCompany, getCurrentAgency } from '../utils/commonUtil'
import * as permission from '../utils/permission'
import { 
  ArrowDropDown as ArrowDropDownIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
  AccountCircle as AccountCircleIcon,
  PowerSettingsNew as PowerSettingsNewIcon,
  ExpandMore,
} from '@material-ui/icons'
import { NavLink } from 'react-router-dom'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import clsx from 'clsx'

const smallStyle = theme => ({
  itemMore: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(-90deg)',
  },
  itemLess: {
    transition: theme.transitions.create(['transform'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: 'rgba(0, 0, 0, 0.54)',
    transform: 'rotate(0deg)',
  },
  parentMenu: {
    minHeight: 26,
  },
  listItemText: {
    padding: 0,
  },
  item: {
    paddingLeft: 48,
  },
  containerLevel2: {
    backgroundColor: '#fff',
    borderLeft: '#21757d 3px solid',
  },
  textBold: {
    fontWeight: 'bold',
  },
  containerIcon: {
    width: '1.0625em',
  },
  icon: {
    fontSize: 17,
  },
})

const _SmallUserMenu = ({
  classes,
  username,
  handleExpand,
  userLink,
  userReload,
  link,
  handleClose,
  companyReload,
  isCompanyRole,
  isAgencyRole,
  userLogout,
  open,
  translate,
}) => {
  return <Fragment> 
    <MenuItem
      onClick={open ? handleClose : handleExpand}
      className={classes.parentMenu}
    >
      <ListItemIcon className={classes.icon}><AccountCircleIcon /></ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        inset
        classes={{ primary: classes.textBold}}
        primary={username}
      />
      <ExpandMore className={clsx(classes.itemMore, { [classes.itemLess]: open })} />
    </MenuItem>
    {open && <Collapse
      component="div"
      in={open}
      className={classes.containerLevel2}
    >

      <MenuItem
        disableGutters
        to={userLink}
        component={userReload && NavLink}
        onClick={handleClose}
        className={classes.item}
      >
        <ListItemIcon className={classes.containerIcon}>
          <PersonIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          inset
          primary={translate('resources.profile')}
        />
      </MenuItem>
      {!isEmpty(link) ? <MenuItem
        disableGutters
        to={link}
        component={companyReload && NavLink}
        onClick={handleClose}
        className={classes.item}
      >
        <ListItemIcon className={classes.containerIcon}>
          <BusinessIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          inset
          primary={isCompanyRole ? translate('resources.company') : (isAgencyRole && translate('resources.agencies.name', { smart_count: 1 }))}

        />
      </MenuItem> : null}
      <MenuItem
        disableGutters
        onClick={userLogout}
        className={classes.item}
      >
        <ListItemIcon className={classes.containerIcon}>
          <PowerSettingsNewIcon className={classes.icon} />
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          inset
          primary={translate('ra.auth.logout')}
        />
      </MenuItem>
    </Collapse>
    }
  </Fragment>
}

const SmallUserMenu = compose(translate, withStyles(smallStyle))(_SmallUserMenu)

const largeStyle = {
  root: {
    marginRight: 16,
  },
  chip: {
    color: '#fff',
    border: '1px solid #fff',
  },
  iconButton: {
    color: '#fff',
  },
  avatar: {
    color: '#fff',
    backgroundColor: 'transparent',
  },
  deleteIcon: {
    color: '#fff',
  }
}

const _LargeUserMenu = ({
  classes,
  username,
  handleExpand,
  anchorEl,
  userLink,
  userReload,
  link,
  handleClose,
  companyReload,
  isCompanyRole,
  isAgencyRole,
  logout,
  open,
  translate,
}) => {
  return <div className={classes.root}>
    <Chip
      avatar={<Avatar className={classes.avatar}><AccountCircleIcon /></Avatar>}
      label={username}
      onClick={handleExpand}
      deleteIcon={<ArrowDropDownIcon className={classes.deleteIcon} />}
      onDelete={handleExpand}
      variant='outlined'
      className={classes.chip}
    />
    <Popper open={open} anchorEl={anchorEl} transition disablePortal>
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          id="menu-list-grow"
          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
        >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>
                <MenuItem
                  to={userLink}
                  component={userReload && NavLink}
                  onClick={handleClose}
                >
                  <span style={{ paddingRight: '1.2em' }}>
                    <PersonIcon />
                  </span>
                  {translate('resources.profile')}
                </MenuItem>
                {!isEmpty(link) ? <MenuItem
                  to={link}
                  component={companyReload && NavLink}
                  onClick={handleClose}
                >
                  <span style={{ paddingRight: '1.2em' }}>
                    <BusinessIcon />
                  </span>
                  {isCompanyRole ? translate('resources.company') : (isAgencyRole && translate('resources.agencies.name', { smart_count: 1 }))}
                </MenuItem> : null}
                {logout}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  </div>
}

const LargeUserMenu = compose(translate, withStyles(largeStyle))(_LargeUserMenu)

class UserMenu extends Component {

  state = {
    open: false,
    anchorEl: null,
    currentPath: '',
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { currentPath } = nextProps
    let { currentPath: prevCurrentPath } = prevState
    if (currentPath !== prevCurrentPath) {
      prevCurrentPath = currentPath
    }
    return { currentPath: prevCurrentPath }
  }

  handleExpand = event => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  render() {
    let { permissions, logout, isXSmall, userLogout} = this.props
    let { anchorEl, open, currentPath } = this.state
    let user = getCurrentUser() || {}
    let { username, id: userId } = user
    let isCompanyRole = permission.isCompanyRole(permissions)
    let isAgencyRole = permission.isAgencyRole(permissions)
    let link = {}
    if (isCompanyRole) {
      let company = getCurrentCompany() || {}
      let { id: companyId } = company
      link = `/companies/${companyId}`
    }
    if (isAgencyRole) {
      let agency = getCurrentAgency() || {}
      let { id: agencyId } = agency
      link = `/agencies/${agencyId}`
    }
    let userLink = `/users/${userId}`
    let companyReload = currentPath !== link
    let userReload = currentPath !== userLink

    return !isXSmall ? <LargeUserMenu
      open={open}
      username={username}
      anchorEl={anchorEl}
      isCompanyRole={isCompanyRole}
      isAgencyRole={isAgencyRole}
      userLink={userLink}
      userReload={userReload}
      companyReload={companyReload}
      handleExpand={this.handleExpand}
      handleClose={this.handleClose}
      link={link}
      logout={logout}
    /> : <SmallUserMenu
      open={open}
      username={username}
      link={link}
      isCompanyRole={isCompanyRole}
      isAgencyRole={isAgencyRole}
      userLink={userLink}
      userReload={userReload}
      companyReload={companyReload}
      handleExpand={this.handleExpand}
      handleClose={this.handleClose}
      userLogout={userLogout}
    />
  }
}

const UserMenuWithPermission = (props) => <WithPermissions
  render={({ permissions }) => <UserMenu permissions={permissions} {...props} />}
/>

const enhance = compose(
  translate,
  connect(
    state => ({
      currentPath: state.router.location.pathname, // force redraw on locale change
    }),{ userLogout }
  )
)

export default enhance(UserMenuWithPermission)
