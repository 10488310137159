import React, { Component } from 'react'
import {
  Chip,
  Popover,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  withStyles,
} from '@material-ui/core'
import { showNotification } from 'react-admin'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'

class TagListMenu extends Component {

  render() {
    let { open, onClose, anchorEl, tags, assignTag } = this.props
    return <Popover
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      onClose={onClose}
    >
      <List component="nav">
        {tags && tags.map((tag, idx) => {
          let { id, color, name } = tag
          return <ListItem
            button
            key={idx}
            onClick={() => assignTag(id)}
          >
            <ListItemAvatar>
              <Avatar style={{ backgroundColor: color }}>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={name} />
          </ListItem>
        })}
      </List>
    </Popover>
  }
}

const style = {
  root: {
    display: 'flex',
  },
  chip: {
    margin: 'auto'
  },
}

class HashTags extends Component {

  state = {
    open: false
  }

  componentDidMount() {
    let { tags, listTag } = this.props
    listTag = _.reduce(listTag, (result, tag) => {
      let { id } = tag
      if (_.findIndex(tags, ele => ele.id === id) === -1) {
        result.push(tag)
      }
      return result
    }, []) 
    this.setState({ tags, listTag })
  }

  open = evt => {
    this.setState({
      open: true,
      anchorEl: evt.target,
    })
  }

  onClose = () => {
    this.setState({ 
      open: false,
      anchorEl: null,
    })
  }

  assignTag = (tagId) => {

    let { principal, principalId, showNotification } = this.props

    Provider.dataProvider('REMOTE', 'tags', {
      method: 'assignTag',
      requestMethod: 'POST',
      data: { principalId, tagId, principal }
    }).then(
      res => {
        let { data: newTag } = res
        let { tags, listTag } = this.state
        tags.push(newTag)
        listTag = _.filter(listTag, tag => tag.id !== tagId)
        this.setState({ tags, listTag })
        showNotification('notification.assign_tag_success')
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    ).finally(
      this.onClose()
    )
  }

  onDelete = tagPrincipalId => {
    let { showNotification } = this.props
    Provider.dataProvider('REMOTE', 'tags', {
      method: 'unassignTag',
      requestMethod: 'POST',
      data: { tagPrincipalId }
    }).then(
      () => {
        let { tags, listTag } = this.state
        let removedTag = tags.find(ele => ele.tagPrincipalId === tagPrincipalId) 
        tags = tags.filter(ele => ele.tagPrincipalId !== tagPrincipalId)
        listTag.push(removedTag)
        this.setState({ tags, listTag })
        showNotification('notification.unassign_tag_success')
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    )
  }

  render() {
    let { tags, open, anchorEl, listTag } = this.state
    let { classes } = this.props
    return <div className={classes.root}>
      {tags && tags.map((tag, idx) => {
        let { name, color, tagPrincipalId } = tag
        return <Chip
          variant="outlined"
          style={{ border: `1px solid ${color}`, color }}
          key={idx}
          label={name}
          onDelete={() => this.onDelete(tagPrincipalId)}
          className={classes.chip}
        />
      })}
      <Chip
        className={classes.chip}
        onClick={this.open}
        color="primary"
        variant="outlined"
        label={<FontAwesomeIcon size="xs" icon={faPlus} />}
      />
      <TagListMenu
        open={open}
        anchorEl={anchorEl}
        onClose={this.onClose}
        tags={listTag}
        assignTag={this.assignTag}
      />
    </div>
  }
}

const enhance = compose(
  withStyles(style),
  connect(null, { showNotification })
)

export default enhance(HashTags)
