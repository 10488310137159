import React, { Component, Fragment } from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import ConfirmDialog from '../common/ConfirmDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import compose from 'recompose/compose'
import { Button, Tooltip, withStyles } from '@material-ui/core'
import { translate, refreshView, showNotification } from 'react-admin'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import LinkField from '../common/LinkField'

const style = {
  iconButton: {
    color: '#fb404b'
  },
  button: {
    display: 'inline-flex',
    fontSize: '0.6964285714285714rem'
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 16
  },
}


class ArchiveButton extends Component {

  state = {
    open: false,
  }

  onClick = () => {
    this.setState({ open: true })
  }

  onClose = () => {
    this.setState({ open: false })
  }

  onOk = () => {
    let { id, refreshView, showNotification } = this.props
    Provider.dataProvider('DELETE', 'ticketlayouts', {id})
      .then(() => {
        this.onClose()
        showNotification('notification.delete_ticket_layout_success')
        refreshView()
      }).catch(e => {
        this.onClose()
        showNotification(e.message, 'warning')
      })
  }

  render() {
    let { classes, button, color = 'primary', translate } = this.props
    let { open } = this.state
    return <Fragment> 
      {button ? <Button
        color={color}
        onClick={this.onClick}
        className={classes.button}
      >
        <span>
          <FontAwesomeIcon className={classes.icon} icon={faTrash} />
          <span>{translate('button.remove')}</span>
        </span>
      </Button> : <Tooltip title={translate('button.remove')} enterDelay={100} >
        <LinkField
          className={classes.iconButton}
          onClick={this.onClick}
          icon
        >
          <FontAwesomeIcon icon={faTrash} size="xs" />
        </LinkField>
      </Tooltip>
      }
      <ConfirmDialog 
        open={open}
        title={translate('resources.ticketlayouts.common.warning_delete_title')}
        content={translate('resources.ticketlayouts.common.warning_delete_content')}
        onClose={this.onClose}
        onOk={this.onOk}
      />
    </Fragment>
  }
}

const enhance = compose(
  withStyles(style),
  translate,
  connect(null, { refreshView, showNotification })
)
export default enhance(ArchiveButton)
