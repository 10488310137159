import React, { Component } from 'react'
import {
  List,
  Datagrid,
  FunctionField,
  CardActions
} from 'react-admin'
import moment from 'moment'
import SettingButton from './SettingButton'
import RenderButton from './RenderButton'

const getCurrentCompany = () => {
  let company = {}
  let userInfoStr = localStorage.getItem('user')
  let userInfo = {}
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr)
    let data = userInfo.data
    company = data ? data.company : {}
  }
  return company.name
}
const Actions = () => (
  <CardActions>
    <SettingButton />
    <RenderButton />
  </CardActions>
)
class TimeScheduler extends Component {

  render() {
    const { ...props } = this.props
    let currentCompanyId = getCurrentCompany()
    return (
      <List
        filterDefaultValues={{ 'companyName': currentCompanyId }}
        sort={{ field: 'departureTime', order: 'ASC'}}
        {...props}
        actions={<Actions />}
      >
        <Datagrid>
          <FunctionField 
            render={
              record => {
                return (
                  <div>
                    28H-99999
                  </div>
                )
              }
            }
          />
          <FunctionField
            label="Hành trình"
            source="departure"
            render={
              record => `${record.departure} -> ${record.arrival}`
            }
          />
          <FunctionField
            label="Thời gian"
            source="departureTime"
            render={
              record => (
                `${record.departure ? moment(record.departureTime).format('DD/MM/YYYY HH:mm') : ''} -> ${record.arrivalTime ? moment(record.arrivalTime).format('DD/MM/YYYY HH:mm') : ''}`
              )}
          />
          {/* <TextField source="timetableData" /> */}
        </Datagrid>
      </List>
    )
  }
}

export default TimeScheduler
