import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { EditController } from 'ra-core'

import { ListActions as DefaultActions, TitleForRecord } from 'react-admin'
import { Card } from '@material-ui/core'

const sanitizeRestProps = ({
  actions,
  children,
  className,
  crudGetOne,
  crudUpdate,
  data,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  id,
  isLoading,
  resetForm,
  resource,
  title,
  translate,
  version,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  undoable,
  ...rest
}) => rest

export const EditView = ({
  actions,
  basePath,
  children,
  className,
  defaultTitle,
  hasList,
  hasShow,
  record,
  redirect,
  resource,
  save,
  title,
  nocard,
  version,
  ...rest
}) => {
  if (typeof actions === 'undefined' && hasShow) {
    actions = <DefaultActions />;
  }

  if (!children) {
    return null;
  }

  return (
    <div
      className={classnames('edit-page', className)}
      {...sanitizeRestProps(rest)}
    >
      <TitleForRecord
        title={title}
        record={record}
        defaultTitle={defaultTitle}
      />

      {
        !nocard ? (
          <Card>
            <div>
              {record ? (
                React.cloneElement(children, {
                  basePath,
                  record,
                  redirect:
                    typeof children.props.redirect === 'undefined'
                      ? redirect
                      : children.props.redirect,
                  resource,
                  save,
                  version,
                })
              ) : (<div>&nbsp;</div>)}
            </div>
          </Card>
        ) : (
            <div>
              {record ? (
                React.cloneElement(children, {
                  basePath,
                  record,
                  redirect:
                    typeof children.props.redirect === 'undefined'
                      ? redirect
                      : children.props.redirect,
                  resource,
                  save,
                  version,
                })
              ) : (<div>&nbsp;</div>)}
            </div>
          )
      }

    </div>
  )
}

EditView.propTypes = {
  actions: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.any,
  version: PropTypes.number,
}

const Edit = props => (
  <EditController {...props}>
    {controllerProps => <EditView {...props} {...controllerProps} />}
  </EditController>
)

Edit.propTypes = {
  actions: PropTypes.element,
  children: PropTypes.node,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.any.isRequired,
  resource: PropTypes.string.isRequired,
  title: PropTypes.any,
}

export default Edit
