import React, { Component } from 'react'
import {
  ExpansionPanelSummary,
  Typography,
  ExpansionPanel
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  DragHandle as DragHandleIcon,
} from '@material-ui/icons'
import { DragLayer } from 'react-dnd'
import { ItemTypes } from './ExpansionPanelRoute'

class ExpansionDragLayer extends Component {
  getLayerStyle = () => {
    const { initialOffset, currentOffset } = this.props
    if (!initialOffset || !currentOffset) {
      return {
        display: 'none',
      }
    }
    let { x, y } = currentOffset
    let drawerWidth = 861
    let windowWidth = Math.max(window.innerWidth, 861)
    let offsetWidth = windowWidth - drawerWidth
    const transform = `translate(${x - offsetWidth}px, ${y}px)`
    return {
      transform,
      WebkitTransform: transform,
    }
  }
  render() {
    let {
      routeName,
      itemType,
      isDragging,
    } = this.props
    if (!isDragging) {
      return null
    }
    return (
      <div style={{
        position: 'fixed',
        pointerEvents: 'none',
        zIndex: 100,
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
      }}>
        <div style={this.getLayerStyle()}>
          {(itemType === ItemTypes.ITEM) ? (<ExpansionPanel
            expanded={false}
          >
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              <DragHandleIcon />
              <Typography style={{
                fontWeight: 'bold',
                margin: 'auto 0 auto 15px'
              }}>{routeName}</Typography>
            </ExpansionPanelSummary>
          </ExpansionPanel>) : null }
        </div>
      </div>
    )
  }
}

const collect = (monitor) => ({
  itemType: monitor.getItemType(),
  isDragging: monitor.isDragging(),
  currentOffset: monitor.getSourceClientOffset(),
  initialOffset: monitor.getInitialSourceClientOffset(),
})

export default DragLayer(collect)(ExpansionDragLayer)