export const SET_VEHICLE_TYPE = 'SET_VEHICLE_TYPE'
export const ADD_VEHICLE = 'ADD_VEHICLE'
export const REMOVE_VEHICLE = 'REMOVE_VEHICLE'
export const UPDATE_VEHICLE = 'UPDATE_VEHICLE'
export const SAVE_VEHICLES = 'SAVE_VEHICLES'
export const FETCH_SAVE_VEHICLES = 'FETCH_SAVE_VEHICLES'
export const FETCH_SAVE_VEHICLES_SUCCESS = 'FETCH_SAVE_VEHICLES_SUCCESS'
export const FETCH_SAVE_VEHICLES_FAILURE = 'FETCH_SAVE_VEHICLES_FAILURE'
export const RESET_FORM_VEHICLE = 'RESET_FORM_VEHICLE'
export const HANDLE_EXIST_VEHICLE = 'HANDLE_EXIST_VEHICLE'
export const UPDATE_VEHICLES_TYPE = 'UPDATE_VEHICLES_TYPE'

export const setVehicleType = record => ({
  type: SET_VEHICLE_TYPE,
  record
})

export const updateVehiclesType = record => ({
  type: UPDATE_VEHICLES_TYPE,
  record
})

export const addVehicle = vehicle => ({
  type: ADD_VEHICLE,
  vehicle
})

export const existVehicles = existsVehicle => ({
  type: HANDLE_EXIST_VEHICLE,
  existsVehicle
})

export const removeVehicle = vehicleIdx => ({
  type: REMOVE_VEHICLE,
  vehicleIdx
})

export const updateVehicle = vehicleIdx => ({
  type: UPDATE_VEHICLE,
  vehicleIdx
})

export const saveVehicles = (vehicles, handleExistsVehicle) => ({
  type: SAVE_VEHICLES,
  vehicles,
  handleExistsVehicle
})

export const resetForm = () => ({
  type: RESET_FORM_VEHICLE
})

export const fetchVehicles = vehicles => ({
  type: FETCH_SAVE_VEHICLES,
  payload: {
    method: 'createVehicles',
    data: vehicles,
  },
  meta: {
    resource: 'vehicles',
    fetch: 'REMOTE'
  }
})
