import React, { Component } from 'react'
import {
  TextInput,
  ReferenceInput,
  translate,
  SelectInput,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import {
  withStyles,
  Grid,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { isNotEmpty2D } from '../utils/array'
import SeatMap from './SeatMap'
import {
  changeTotalSeat as changeTotalSeatAction,
  changeVehicleClass as changeVehicleClassAction,
} from './action'
import { connect } from 'react-redux'
import { change as changeForm } from 'redux-form'
import SelectInputCustom from '../common/SelectInputCustom'

const styles = () => ({
  root: {
    display: 'flex'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 16,
  },
  gridItem: {
    padding: 0
  },
  snackbar: {
    marginTop: 16
  },
  seatMap: {
    display: 'flex',
    width: '50%',
    padding: 16,
  }
})

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = {
      templates: [],
      filterSeatMapTemplate: {},
    }
  }
  
  static getDerivedStateFromProps(nextProps, prevState) {
    let { templates, filterSeatMapTemplate } = nextProps
    let {
      templates: currentTemplates,
      filterSeatMapTemplate: currentFilterSeatMapTemplate,
    } = prevState
    if (currentTemplates !== templates) {
      currentTemplates = templates
    }
    if (currentFilterSeatMapTemplate !== filterSeatMapTemplate) {
      currentFilterSeatMapTemplate = filterSeatMapTemplate
    }
    return {
      templates: currentTemplates,
      filterSeatMapTemplate: currentFilterSeatMapTemplate
    }
  }

  onChangeSeatMap = (event, value) => {
    if (typeof value === 'number' && value > 0) {
      const seatMapSelected = this.props.seatmaps[value]
      let count = 0
      let { codesFistFloor, codesSecondFloor } = seatMapSelected
      if (isNotEmpty2D(codesFistFloor)) {
        for (let row of codesFistFloor) {
          for (let cell of row)
            if (cell.trim()) {
              count++
            }
        }
      }
      if (isNotEmpty2D(codesSecondFloor)) {
        for (let row of codesSecondFloor) {
          for (let cell of row)
            if (cell.trim()) {
              count++
            }
        }
      }
      this.props.changeForm('record-form', 'totalSeat', count)
    } else {
      this.props.changeForm('record-form', 'totalSeat', 0)
    }
  }

  render() {
    const { classes, resource, seatmaps = {}, translate } = this.props
    const extra = { resource, fullWidth: true }
    return (
      <div className={classes.root}>
        <div className={classes.card}>
          <TextInput 
            source="name"
            validate={[required(), minLength(6), maxLength(255)]}
            {...extra}
          />
          <Grid container spacing={8}>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <ReferenceInput
                source="vehicleClass"
                reference="vehicleclasses"
                resource="vehicleclasses"
                // onChange={this.onChangeClass}
                {...extra}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput
                type="number"
                source="totalSeat"
                disabled
                // onChange={this.onChangeTotalSeat}
                {...extra}
              />
            </Grid>
          </Grid>
          <ReferenceInput
            source="seatMap"
            reference="seatmaps"
            onChange={this.onChangeSeatMap}
            {...extra}
            label="resources.vehicletypes.fields.seatMap"
            allowEmpty
            required={true}
            validate={required()}
          >
            <SelectInputCustom
              optionText="name"
              allowEmpty
              empty={translate('resources.vehicletypes.empty_seat_map')}
            />
          </ReferenceInput>
        </div>
        <FormDataConsumer>
          {({ formData = {} }) => (
            <div className={classes.seatMap}>
              <SeatMap seatMapSelected={seatmaps[formData.seatMap]} />
            </div>
          )}
        </FormDataConsumer>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let seatmaps = state.admin.resources['seatmaps'] ? state.admin.resources['seatmaps'].data : {}
  return {
    seatmaps
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    mapStateToProps,
    {
      changeTotalSeatAction,
      changeVehicleClassAction,
      changeForm
    })
)

export default enhance(FormSave)
