import React, { Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import {
  LeafletConsumer,
  Map as OSM,
  TileLayer,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet'
import { MapProps } from './../common/constants'
import NetworkLayer from './network-layer'
import NetworkPlanningLayer from './network-planning-layer'
import NetworkContextMenu from './network-context-menu'

import { mapUpdate, mapAction, openContextMenu } from './actions'
import { connect } from 'react-redux'
import './network.css'

import filesaver from 'file-saver'

import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  CardActions,
  Typography,
  Divider,
  withStyles,
} from '@material-ui/core'

import MoreVertIcon from '@material-ui/icons/MoreVert'
import GetApp from '@material-ui/icons/GetApp'
import SwapHoriz from '@material-ui/icons/SwapHoriz'
import EditLocation from '@material-ui/icons/EditLocation'

import L from 'leaflet'

import 'leaflet/dist/leaflet.css'

delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

//render tile layer
const renderTileLayer = (v) => {
  return <TileLayer attribution={v.attribution}
    type={v.type}
    url={v.url}
    key={v.key}
    identifier={v.identifier} accessToken={v.accessToken} id={v.id}/>
}

const renderNetworkLayer = (v) => {
  let enabled = v.enabled
  return (
    <NetworkLayer
      type={v.type}
      key={v.key}
      identifier={v.identifier}
      enabled={enabled}
    />
  )
}

const renderNetworkPlanningLayer = (v) => {
  let enabled = v.enabled
  return (
    <NetworkPlanningLayer
      type={v.type}
      key={v.key}
      identifier={v.identifier}
      options={{maxZoom: 18}}
      enabled={enabled}
      routeId={v.routeId}
      mapUpdate={v.mapUpdate}
      openContextMenu={v.openContextMenu}
    />
  )
}

//render generic layer
const renderLayerComponent = (v) => {
  switch (v.type) {
    case MapProps.LayerTypes.TILE:
      return renderTileLayer(v)
    case 'network-layer':
      return renderNetworkLayer(v)
    case 'network-planning-layer':
      return renderNetworkPlanningLayer(v)
    default:
      return null
  }
}

//render list of layers
const renderLayers = (layers) => {
  let array = []
  let totalLayer = layers && layers.length
  for (let idx = 0; idx < totalLayer; idx++) {
    let layer = layers[idx]
    if (!layer.enabled && layer.type === 'tilelayer') {
      //ignore render disabled tilelayer
      continue
    }
    let component = renderLayerComponent(layer)
    array.push(component)
  }
  return array
}

class Map extends Component {
  constructor(props) {
    super(props)
    this.state = { layers: [] }
  }

  componentDidMount() {
    this.initializeLayers()
  }

  //switch layer on/off
  switch(key) {
    let { baseLayers } = this.props
    let { layers } = this.state
    baseLayers = baseLayers.map(v => {
      if (v.key === key) {
        v.enabled = !v.enabled
      }
      return v
    })
    layers.map(v => {
      if (v.key === key) {
        v.enabled = !v.enabled
      }
      return v
    })
    this.setState({ baseLayers, layers })
  }

  onKeyUp(evt) {
    if (evt.defaultPrevented) {
      return
    }
    const key = evt.key
    // const altKey = evt.altKey
    const ctrlKey = evt.ctrlKey
    // const shiftKey = evt.shiftKey
    // const keyCode = evt.keyCode
    switch(key) {
      case '1':
      case '2':
      case '3':
      case '5':
        if (ctrlKey) {
          this.switch(key)
        }
        break
      case '+':
      case '=':
        this.map.zoomIn()
        break
      case '-':
        this.map.zoomOut()
        break
      default:
        break
    }
  }

  setContext(ctx) {
    this.map = ctx.map
  }

  initializeLayers() {
    let { viewmode, routeId, baseLayers, mapUpdate, openContextMenu } = this.props
    let allroutes = JSON.parse(JSON.stringify(netwokrLayer))
    let singleroute = JSON.parse(JSON.stringify(planningLayer))
    let layers = []
    if ('planning' === viewmode) {
      allroutes.enabled = false
      layers.push(allroutes)
      singleroute.routeId = routeId
      singleroute.mapUpdate = mapUpdate
      singleroute.openContextMenu = openContextMenu

      layers.push(singleroute)
    } else {
      allroutes.enabled = true
      layers.push(allroutes)
    }
    this.setState({ baseLayers, layers })
  }
  render() {
    let { center, zoom, classes } = this.props
    let { baseLayers, layers } = this.state

    return (
      <div className={classes.container} 
        onKeyUp={ evt => this.onKeyUp(evt) }>
        <div className={classes.mapInfo}>
          <MapInfoEnhance />
        </div>
        <OSM center={center} zoom={zoom} className={classes.map}>
          <ZoomControl position="topright" />
          <ScaleControl position="bottomright" />

          {renderLayers(baseLayers)}
          {renderLayers(layers)}
          <LeafletConsumer>
            { ctx => this.setContext(ctx) }
          </LeafletConsumer>
        </OSM>
        <NetworkContextMenu />
      </div>
    )
  }
}

Map.propTypes = {
  baseLayers: PropTypes.arrayOf(PropTypes.object),
  layers: PropTypes.arrayOf(PropTypes.object),
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  style: PropTypes.object,
}

Map.defaultProps = {
  baseLayers: [{
    identifier: 'OSM_LAYER',
    key: 1,
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    type: 'tilelayer',
    enabled: false
  }, {
    identifier: 'MAPNIK_LAYER',
    key: 2,
    name: 'OpenStreetMap - BW',
    url: 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
    attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    type: 'tilelayer',
    enabled: false
  }, {
    identifier: 'MAPBOX_LAYER',
    key: 3,
    name: 'Mapbox',
    id: 'mapbox.light',
    url: 'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}',
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
    accessToken: 'pk.eyJ1IjoibmV4cGFuZG8iLCJhIjoiY2pydWJtM2h5MTIwNjQzcXNyemtueWNwOSJ9.Etdlea_JNbauP5wzH_VXEA',
    type: 'tilelayer',
    enabled: true
  }
  ],
  layers: [],
  center: [21.02, 105.84], //Ha Noi
  zoom: 14,
  map: {}
}

class MapInfo extends Component {

  downloadPath() {
    let { map } = this.props
    let route = map.route
    if (route.path) {
      let filename = `${route.name}-${route.number}.json`
      filesaver.saveAs(new Blob([JSON.stringify(route.path)], { type: ' application/json' }), filename)
    }
  }

  toggleDrawing(evt){
    let { map, mapAction } = this.props
    let drawing = map.drawing
    mapAction({ cmd: 'draw', value: !drawing })
  }

  render() {
    let { map, translate } = this.props
    let route = map.route
    let { drawing } = map
    let backgroundColor = map.route.color ? map.route.color : 'red'
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label={ route.number } title={ route.number } style={{ background: backgroundColor, color: '#fff'}}>
              { route.number }
            </Avatar>
          }
          action={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          title={ route.name }
          subheader={ route.number }
        />
        <CardContent>
          <Typography component="p">
            { map.route.distance } km
          </Typography>
          <Typography component="p">
            { map.route.numOfStops } { translate('resources.stops.name') }
          </Typography>
        </CardContent>
        <Divider />
        <CardActions>
          <IconButton aria-label="Download path" onClick={(evt) => this.downloadPath(evt)}>
            <GetApp />
          </IconButton>
          <IconButton aria-label="Switch direction" href={`/networks/${route.reversed}/planning`} disabled={ !route.reversed }>
            <SwapHoriz />
          </IconButton>
          <IconButton aria-label="Edit path"  color={ drawing ? 'secondary' : '' } onClick={(evt) => this.toggleDrawing(evt)}>
            <EditLocation />
          </IconButton>
        </CardActions>    
      </Card>
    )
  }
}

MapInfo.propTypes = {
  map: PropTypes.object,
}

MapInfo.defaultProps = {
  map: { route: {}, drawing: false }
}

const mapInfoEnhance = compose(
  translate,
  connect((state) => {
    let { map } = state.network
    return { map }
  }, { mapAction }),
)

export const MapInfoEnhance = mapInfoEnhance(MapInfo)

const netwokrLayer = { identifier: 'network-layer', key: 5, type: 'network-layer', enabled: true }
const planningLayer = { identifier: 'network-planning-layer', key: 6, type: 'network-planning-layer', enabled: true }
const styles = (theme) => {
  return {
    container: {
      position: 'relative',
      'min-height': '100vh'
    },
    mapInfo: {
      position: 'absolute',
      width: '300px',
      height: '100vh',
      background: '#fff',
      zIndex: 3000
    },
    map: {
      'min-height': '100vh'
    },
    itemContainer: {
      padding: 0
    },
    layerControl: {
      zIndex: 401,
    },
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    icon: {
      marginRight: 0
    },
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect((state) => {
    let { center, zoom } = state.map
    return { center, zoom }
  }, { mapUpdate, openContextMenu }),
)

export default enhance(Map)
