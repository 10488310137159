import { UPSERT_GROUP_AVATAR } from './action'

export const viewTrip = (state = {}, action) => {
  let { type, data = {} } = action
  let { tripId } = data
  switch(type) {
    case UPSERT_GROUP_AVATAR:
      return {
        ...state,
        [tripId]: { ...data }
      }
    default:
      return {...state}
  }
}
