import React, { Component } from 'react'
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  withStyles
} from '@material-ui/core'
import { ReferenceManyField } from 'react-admin'

const styles = () => (
  {
    containerFilter: {
      display: 'flex',
      flexDirection: 'column',
      padding: 16
    },
    containerServiceGroup: {
      display: 'flex',
      flexDirection: 'column'
    },
    serviceGroupChild: {
      marginLeft: 32
    }
  }
)

const serviceGroupFilters = {
  LICENSE: {
    childrenResource: 'licencetypes'
  },
  VEHICLE: {
    childrenResource: 'maintainschedules'
  }
}

const renderChild = (
  serviceGroupCode,
  classes,
  parentChecked,
  parent = {},
  handleCheckChild,
  parentId,
) => {
  let resource = serviceGroupFilters[serviceGroupCode].childrenResource

  return <ReferenceManyField
    record={{}}
    basePath={`/${resource}`}
    reference={resource}
    sort={{ field: 'id',  order: 'ASC' }}
    target=""
  >
    <Groups
      classes={classes}
      isChild={true}
      optionValue="id"
      showAll={true}
      optionText="name"
      parentChecked={parentChecked}
      childrenValues={parent.childrens}
      showAllChildren={parent.showAllChildren}
      handleCheck={handleCheckChild}
      parentId={parentId}
    />
  </ReferenceManyField>
}

class Groups extends Component {

  constructor(props) {
    super(props)
    this.state = {
      init: false,
      value: [],
      checkValue: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      ids = [],
      checkValue = {},
      parentChecked,
      isChild,
      showAllChildren,
      childrenValues,
    } = nextProps
    showAllChildren = showAllChildren === undefined ? true : showAllChildren
    let newValue = Object.keys(checkValue)
    newValue = newValue.map(v => {
      let regexNumber = new RegExp(/^\d+$/)
      if (regexNumber.test(v)) {
        v = parseInt(v)
      }
      return v
    })
    let { value: currentValue, checkValue: currentCheckValue } = prevState
    if (isChild) {
      if (parentChecked) {
        currentValue = ids
        if (!showAllChildren) {
          currentValue = childrenValues
        }
      } else {
        currentValue = []
      }
    } else {
      if (currentValue !== newValue) {
        currentValue = newValue
        currentCheckValue = checkValue
      }
    }
    return {
      value: currentValue,
      checkValue: currentCheckValue
    }
  }

  render() {
    let {
      data = {},
      ids = [],
      handleCheck,
      optionValue,
      optionText,
      legend,
      hasChild,
      renderChild,
      classes,
      isChild,
      handleCheckChild,
      parentId,
      parentChange,
    } = this.props
    let { value, checkValue } = this.state
    return <FormControl className={isChild && classes.serviceGroupChild} component="fieldset">
      <FormLabel component="legend">{legend}</FormLabel>
      <FormGroup>
        {ids.map((id, idx) => {
          let checked = value && value.includes(data[id][optionValue])
          let parent = !isChild && checkValue[id]
          return (
            <div className={hasChild && classes.containerServiceGroup} key={idx}>
              <FormControlLabel
                checked={checked}
                onChange={(event, isChecked) => {
                  handleCheck(
                    event,
                    isChecked,
                    value,
                    parentId,
                    parentChange,
                  )
                }}
                value={data[id][optionValue]}
                control={
                  <Checkbox
                    id={String(data[id][optionValue])}
                    color="primary"
                  />
                }
                label={data[id][optionText]}
              />
              {hasChild && renderChild(
                data[id].code,
                classes,
                checked,
                parent,
                handleCheckChild,
                id,
              )
              }
            </div>
          )
        })}
      </FormGroup>
    </FormControl>
  }
}

class Filter extends Component {
  render() {
    let {
      classes,
      handleCheckServiceGroup,
      filter = {},
      handleCheckServiceGroupChild,
      handleCheckReminderStatus
    } = this.props
    let { serviceGroupValue, serviceReminderStatusValue } = filter
    return (
      <div className={classes.containerFilter}>
        <div>
          <ReferenceManyField
            record={{}}
            basePath="/servicegroups"
            reference="servicegroups"
            target=""
          >
            <Groups
              legend="Nhóm"
              optionValue="id"
              optionText="name"
              showAll={true}
              hasChild={true}
              classes={classes}
              renderChild={renderChild}
              handleCheck={handleCheckServiceGroup}
              checkValue={serviceGroupValue}
              handleCheckChild={handleCheckServiceGroupChild}
            />
          </ReferenceManyField>
        </div>
        <div>
          <ReferenceManyField
            record={{}}
            basePath="/servicereminderstatuses"
            reference="servicereminderstatuses"
            target=""
            sort={{ field: 'id', order: 'ASC '}}
          >
            <Groups
              legend="Trạng thái"
              optionValue="id"
              optionText="name"
              checkValue={serviceReminderStatusValue}
              handleCheck={handleCheckReminderStatus}
            /> 
          </ReferenceManyField>
        </div>
      </div>
    )
  }
}

export default withStyles(styles)(Filter)