import React, { Component, Fragment } from 'react'
import {
  Title,
  translate,
  SimpleForm,
  SelectInput,
  required,
  FormDataConsumer,
  ReferenceField,
  SimpleShowLayout,
  TextField,
  FunctionField,
  TextInput,
  showNotification,
  WithPermissions,
  minLength,
  maxLength,
  Responsive,
} from 'react-admin'
import {
  Card,
  withStyles,
  Grid,
  Typography,
  List as MUIList,
  ListItem,
  ListItemText,
  Chip,
  Divider,
  TextField as MUITextField,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import compose from 'recompose/compose'
import SaleTicketToolbar from './SaleTicketToolbar'
import { Provider } from '../provider'
import { change as changeForm } from 'redux-form'
import { green, grey, red, blue } from '@material-ui/core/colors'
import moment from 'moment'
import _ from 'lodash'
import { formatCurrency } from '../utils/formatUtil'
import { connect } from 'react-redux'
import { changeBreadcrumb } from '../breadcrumb/action'
import { DatePickerInput, DatePickerField } from '../common/DatePicker'
import Snackbar from '../common/Snackbar'
import * as permission from '../utils/permission'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { textToDate } from '../utils/time'
import { validate } from './validate'

export const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  '00INACTIVE': { color: 'white', backgroundColor: grey[500] },
  '10ACTIVE': { color: 'white', backgroundColor: green[500] },
}

const StatusField = ({ record }) => {
  if (!record) return null
  let style = statusStyles[record.id]
  return <Chip style={style} label={record.name} />
}

const _RouteDetail = ({ record, classes }) => {
  return <Grid container spacing={8}>
    <Grid item md={4} xs={6} >
      <SimpleShowLayout record={record} resource="routes" className={classes.routeItem}>
        <TextField source="name" />
      </SimpleShowLayout>
    </Grid>
    <Grid item md={2} xs={6} >
      <SimpleShowLayout basePath="" record={record} resource="routes" className={classes.routeItem}>
        <ReferenceField
          source="status"
          reference="routestatuses"
          allowEmpty
          linkType={false}
        >
          <StatusField />
        </ReferenceField>
      </SimpleShowLayout>
    </Grid>
    <Grid item md={2} xs={6} >
      <SimpleShowLayout basePath="" record={record} resource="routes" className={classes.routeItem}>
        <ReferenceField
          source="originId"
          reference="stops"
          allowEmpty
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Grid>
    <Grid item md={2} xs={6} >
      <SimpleShowLayout basePath="" record={record} resource="routes" className={classes.routeItem}>
        <ReferenceField
          source="destinationId"
          reference="stops"
          allowEmpty
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
      </SimpleShowLayout>
    </Grid>
    <Grid item md={2} xs={6} >
      <SimpleShowLayout record={record} resource="routes" className={classes.routeItem}>
        <FunctionField
          source="distance"
          render={({ distance }) => `${distance / 1000} (km)`}
        />
      </SimpleShowLayout>
    </Grid>
  </Grid>
}

const routeInfomationStyle = theme => ({
  root: {
    padding: 18,
  },
  title: {
    marginBottom: 16,
  },
  routeItem: {
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '0 !important',
    }
  }
})

const enhanceRouteDetail = compose(withStyles(routeInfomationStyle), translate)
const RouteDetail = enhanceRouteDetail(_RouteDetail)

const _RouteInformation = ({ classes, translate, record }) => {
  //let { id } = record
  return <Fragment>
    <Typography
      variant="h4"
      className={classes.title}
    >
      {translate('resources.saletickets.routeInfo')}
    </Typography>
    <RouteDetail record={record} />
  </Fragment>
}

//<RoutePatterns routeId={id} />
const enhanceRouteInformation = compose(withStyles(routeInfomationStyle), translate)
const RouteInformation = enhanceRouteInformation(_RouteInformation)

const fareListStyles = theme => ({
  root: {
    width: '100%',
  },
  textContainer: {
    width: '25%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
  },
  moneyContainer: {
    width: '20%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
    }
  },
  unit: {
    display: 'flex',
    flexDirection: 'column'
  },
  priceItem: {
    textAlign: 'right',
    marginBottom: 5,
    fontSize: '0.8571428571428571rem',
  },
  quantityContainer: {
    width: '35%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginRight: 4,
    }
  },
  item: {
    display: 'flex',
    width: '100%',
  },
  secondaryActionRoot: {
    position: 'inherit',
    alignSelf: 'center',
  },
  quantityInput: {
    height: 30,
    width: 30,
    padding: '0px 4px 0px 8px',
  },
  header: {
    backgroundColor: '#fff59d'
  },
  tooltip: {
    backgroundColor: '#f44336'
  },
  subtractButton: {
    border: `1px solid ${red[600]}`,
    color: red[600],
    '&:hover': {
      backgroundColor: red[100]
    },
    borderRadius: 4,
    padding: 0,
    width: 30,
    height: 30,
    marginRight: 8,
  },
  addButton: {
    border: `1px solid ${blue[600]}`,
    color: blue[600],
    '&:hover': {
      backgroundColor: blue[100]
    },
    borderRadius: 4,
    padding: 0,
    width: 30,
    height: 30,
    marginLeft: 8,
  },
})

class _FareListMobile extends Component {

  componentDidMount() {
    //this.firstInputRef && this.firstInputRef.focus()
  }

  render() {
    let {
      productIdx,
      fares,
      classes,
      translate,
      product,
      onChangeQuantity,
      totalFareMapping = {},
      totalQuantity,
    } = this.props
    let endColStyle = { display: 'flex', justifyContent: 'flex-end' }
    return <MUIList disablePadding className={classes.root}>
      {productIdx === 0 && <ListItem className={classes.header}>
        <div className={classes.item}>
          <div className={classes.textContainer}>
            <b>{translate('resources.saletickets.fareType')}</b>
          </div>
          <div className={classes.moneyContainer}>
            <b>{translate('resources.saletickets.unit')}</b>
          </div>
        </div>
      </ListItem>}
      {fares.map((fare, idx) => {
        let { name, amount, id } = fare
        let errorMessage
        if (totalQuantity === 0) {
          errorMessage = translate('resources.saletickets.no_select_product')
        }
        let isFirstProductType = productIdx === 0 && idx === 0
        let totalFare = totalFareMapping[id] || {}
        let { total, quantity = 0 } = totalFare
        return <ListItem key={idx}>
          <div className={classes.item}>
            <ListItemText
              className={classes.textContainer}
              primary={name}
            />
            <div className={classes.unit}>
              <div className={classes.priceItem}>
                {formatCurrency(amount)}
              </div>
              <div >
                <IconButton
                  onClick={() => {
                    if (quantity - 1 < 0) return
                    onChangeQuantity(quantity - 1, product, fare)
                  }}
                  variant="outlined"
                  className={classes.subtractButton}
                  size="small"
                >
                  <RemoveIcon fontSize="small" />
                </IconButton>
                <Tooltip
                  title={errorMessage || ''}
                  placement="right-end"
                  open={!(!errorMessage) && isFirstProductType}
                  classes={{
                    tooltip: classes.tooltip
                  }}
                  arrow="true"
                >
                  <MUITextField
                    variant="outlined"
                    type="number"
                    error={!(!errorMessage) && isFirstProductType}
                    onChange={evt => {
                      let quantity = evt.target.value
                      if (quantity < 0) return
                      onChangeQuantity(quantity, product, fare)
                    }}
                    onBlur={evt => {
                      let quantity = evt.target.value
                      if (_.isEmpty(quantity)) {
                        onChangeQuantity(0, product, fare)
                      }
                    }}
                    value={parseInt(quantity)}
                    //inputRef={ref => {
                    //if (isFirstProductType) {
                    //this.firstInputRef = ref
                    //}
                    //}}
                    InputProps={{
                      classes: { input: classes.quantityInput },
                      min: 0,
                      onKeyPress: (e) => {
                        if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                          e.preventDefault()
                        }
                      },
                    }}
                  />
                </Tooltip>
                <IconButton
                  onClick={() => {
                    if (typeof (quantity) === 'string') {
                      quantity = parseInt(quantity)
                    }
                    onChangeQuantity(quantity + 1, product, fare)
                  }}
                  variant="outlined"
                  className={classes.addButton}
                  size="small"
                >
                  <AddIcon fontSize="small" />
                </IconButton>
              </div>
            </div>
          </div>
        </ListItem>
      })}
    </MUIList>
  }
}
class _FareList extends Component {

  componentDidMount() {
    this.firstInputRef && this.firstInputRef.focus()
  }

  render() {
    let {
      productIdx,
      fares,
      classes,
      translate,
      product,
      onChangeQuantity,
      totalFareMapping = {},
      totalQuantity,
    } = this.props
    let endColStyle = { display: 'flex', justifyContent: 'flex-end' }
    return <MUIList disablePadding className={classes.root}>
      {productIdx === 0 && <ListItem className={classes.header}>
        <div className={classes.item}>
          <div className={classes.textContainer}>
            <b>{translate('resources.saletickets.fareType')}</b>
          </div>
          <div className={classes.moneyContainer}>
            <b>{translate('resources.saletickets.unit')}</b>
          </div>
          <div className={classes.quantityContainer}>
            <b>{translate('resources.saletickets.quantity')}</b>
          </div>
          <div className={classes.moneyContainer} style={endColStyle}>
            <b>{translate('resources.saletickets.total')}</b>
          </div>
        </div>
      </ListItem>}
      {fares.map((fare, idx) => {
        let { name, amount, id } = fare
        let errorMessage
        if (totalQuantity === 0) {
          errorMessage = translate('resources.saletickets.no_select_product')
        }
        let isFirstProductType = productIdx === 0 && idx === 0
        let totalFare = totalFareMapping[id] || {}
        let { total, quantity = 0 } = totalFare
        return <ListItem key={idx}>
          <div className={classes.item}>
            <ListItemText
              className={classes.textContainer}
              primary={name}
            />
            <div className={classes.moneyContainer}>
              {formatCurrency(amount)}
            </div>
            <div className={classes.quantityContainer}>
              <IconButton
                onClick={() => {
                  if (quantity - 1 < 0) return
                  onChangeQuantity(quantity - 1, product, fare)
                }}
                variant="outlined"
                className={classes.subtractButton}
                size="small"
              >
                <RemoveIcon fontSize="small" />
              </IconButton>
              <Tooltip
                title={errorMessage || ''}
                placement="right-end"
                open={!(!errorMessage) && isFirstProductType}
                classes={{
                  tooltip: classes.tooltip
                }}
                arrow="true"
              >
                <MUITextField
                  variant="outlined"
                  type="number"
                  error={!(!errorMessage) && isFirstProductType}
                  onChange={evt => {
                    let quantity = evt.target.value
                    if (quantity < 0) return
                    onChangeQuantity(quantity, product, fare)
                  }}
                  onBlur={evt => {
                    let quantity = evt.target.value
                    if (_.isEmpty(quantity)) {
                      onChangeQuantity(0, product, fare)
                    }
                  }}
                  value={parseInt(quantity)}
                  inputRef={ref => {
                    if (isFirstProductType) {
                      this.firstInputRef = ref
                    }
                  }}
                  InputProps={{
                    classes: { input: classes.quantityInput },
                    min: 0,
                    onKeyPress: (e) => {
                      if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                        e.preventDefault()
                      }
                    },
                  }}
                />
              </Tooltip>
              <IconButton
                onClick={() => {
                  if (typeof (quantity) === 'string') {
                    quantity = parseInt(quantity)
                  }
                  onChangeQuantity(quantity + 1, product, fare)
                }}
                variant="outlined"
                className={classes.addButton}
                size="small"
              >
                <AddIcon fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.moneyContainer} style={endColStyle}>
              {total ? formatCurrency(total) : '0đ'}
            </div>
          </div>
        </ListItem>
      })}
    </MUIList>
  }
}

const FareList = compose(withStyles(fareListStyles), translate)(_FareList)
const FareListMobile = compose(withStyles(fareListStyles), translate)(_FareListMobile)

const productStyles = theme => ({
  root: {
    display: 'flex',
    textAlign: 'center',
    marginBottom: 12,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  productInfo: {
    display: 'flex',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 4,
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      borderRight: 'none',
      marginBottom: 10,
    }
  },
  productText: {
    margin: 'auto',
  },
  textPrimary: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: 10,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  unit: {
    textTransform: 'lowercase'
  },
})

const ProductInput = ({ input, clientParam, onChange }) => {
  switch (clientParam.type) {
    case 'date':
      return <DatePickerField
        fullWidth
        label={clientParam.label}
        value={moment(input).startOf('day')}
        onChange={val => onChange(moment(val).startOf('day'))}
      />
    default:
      return <MUITextField
        fullWidth
        label={clientParam.label}
        value={input}
        onChange={e => onChange(e.target.value)}
      />
  }
}

ProductInput.propTypes = {
  clientParam: PropTypes.object,
  input: PropTypes.object,
  onChange: PropTypes.func,
}

const ProductInputs = ({ input, clientParams, onChange }) => {
  return clientParams
    ? <Fragment>
      {clientParams.map((param, index) => (
        <ProductInput
          key={index}
          input={input[param.key]}
          clientParam={param}
          onChange={data => {
            let newInput = { ...input }
            newInput[param.key] = data
            onChange(newInput)
          }}
        />
      ))}
    </Fragment>
    : null
}

ProductInputs.propTypes = {
  clientParams: PropTypes.object,
  onChange: PropTypes.func,
  input: PropTypes.array,
}

const ProductInputForm = ({ product, input = {}, onChange }) => {
  return (
    <Fragment>
      <ProductInputs
        clientParams={product.clientParams}
        input={input.clientParams || {}}
        onChange={(data) => {
          onChange({
            ...input,
            clientParams: data
          })
        }}
      />
      {(product.childProducts || []).map((childProduct, index) => {
        return !_.isEmpty(childProduct.clientParams) ? <Fragment key={index}>
          <Typography variant="h6">
            {childProduct.name}
          </Typography>
          <ProductInputs
            clientParams={childProduct.clientParams}
            input={_.get(input, `children.${index}.clientParams`, {})}
            onChange={(data) => {
              let children = [...(input.children || [])]
              children[index] = { ...children[index], clientParams: data }
              onChange({
                ...input,
                children,
              })
            }}
          />
        </Fragment> : null
      })}
    </Fragment>
  )
}

ProductInputForm.propTypes = {
  product: PropTypes.object,
  input: PropTypes.object,
  onChange: PropTypes.func,
}

const _Product = ({
  record,
  idx,
  classes,
  translate,
  onChangeQuantity,
  input,
  onChangeInput,
  totalFareMapping,
  totalQuantity,
}) => {
  let { name, fare, supplier, ticket = {}, company } = record
  let { duration = 0, inclusion, exclusion } = ticket ? ticket : {}
  let durationUnit = 'seconds'
  let durationMapping = moment.duration(duration, 'seconds')._data

  for (let unit in durationMapping) {
    let value = durationMapping[unit]
    if (value > 0) {
      duration = value
      durationUnit = unit
    }
  }
  return <div className={classes.root}>
    <div
      style={style}
      className={classes.productInfo}
    >
      <div className={classes.productText}>
        <div className={classes.textPrimary}>{name}</div>
        <div className={classes.textSecondary}>
          <div>{translate('resources.saletickets.duration_label')}:&nbsp;<b className={classes.unit}>{duration}{`(${translate(`resources.saletickets.${durationUnit}`)})`}</b></div>
          {inclusion && <div>{translate('resources.saletickets.inclusion_label')}:&nbsp;{inclusion}</div>}
          {exclusion && <div>{translate('resources.saletickets.exclusion_label')}:&nbsp;{exclusion}</div>}
          {supplier && <div>{translate('resources.saletickets.supplier')}:&nbsp;{supplier.name}</div>}
          {company && <div>{translate('resources.saletickets.company')}:&nbsp;{company.name}</div>}
          <ProductInputForm
            product={record.input}
            input={input}
            onChange={input => {
              onChangeInput(input, record)
            }}
          />
        </div>
      </div>
    </div>
    {!_.isEmpty(fare) && <Responsive 
      xsmall={<FareListMobile 
        productIdx={idx}
        fares={fare}
        product={record}
        onChangeQuantity={onChangeQuantity}
        totalFareMapping={totalFareMapping}
        totalQuantity={totalQuantity}
      />}
      medium={
        <FareList
          productIdx={idx}
          fares={fare}
          product={record}
          onChangeQuantity={onChangeQuantity}
          totalFareMapping={totalFareMapping}
          totalQuantity={totalQuantity}
        />
      }
    />
    }
  </div>
}

const enhanceProduct = compose(withStyles(productStyles), translate)
const Product = enhanceProduct(_Product)

const productInfomationStyle = theme => ({
  root: {
  },
  title: {
    marginBottom: 50,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  totalLabel: {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
    }
  },
  totalValue: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
})

class _ProductInformation extends Component {

  state = {
    productInputs: []
  }

  initFareQuantity = (products) => {
    let { initFareQuantityDefault } = this.props
    if (products && products.length > 0) {
      let productDefault = products[0]
      let fareDefault
      let fares = productDefault.fare
      if (fares) {
        fareDefault = fares[0]
      }
      if (productDefault && fareDefault) {
        initFareQuantityDefault(productDefault, fareDefault)
      }
    }
  }

  async componentDidMount() {
    let { routeId } = this.props
    const products = routeId && await this.getTicketProductFare(routeId)
    this.initFareQuantity(products)
    this.setState({ products })
  }

  getTicketProductFare = async (routeId) => {
    let res = await Provider.dataProvider('REMOTE', 'products', {
      method: 'getProductFare',
      requestMethod: 'GET',
      data: { routeId, productType: { inq: ['40COMBO', '30TICKET'] } }
    })
    if (res && res.data) {
      let productIds = res.data.map(it => it.id)
      let productInputs = await Provider.dataProvider('REMOTE', 'products', {
        method: 'getProductInputs',
        requestMethod: 'GET',
        data: { productIds }
      })
      let productInputMap = _.mapKeys(productInputs.data, it => it.id)
      let products = res.data.map(it => ({
        ...it,
        input: productInputMap[it.id],
      }))

      let productMap = _.mapKeys(products, it => it.id)

      const clientParamsToDefaultParams = (clientParams) => {
        let params = {}
        for (let clientParam of clientParams) {
          let { key, default: def, type } = clientParam
          if (def) {
            if (type === 'date') {
              params[key] = moment(textToDate(def)).startOf('day')
            } else {
              params[key] = def
            }
          }
        }
        return params
      }

      let productFareInput = {}

      productInputs = productInputs.data.map(input => {
        let { id, childProducts, clientParams } = input
        let defaultInput = {
          id,
          clientParams: clientParamsToDefaultParams(clientParams)
        }
        let product = productMap[id]
        productFareInput[product.id] = defaultInput
        if (childProducts) {
          let children = childProducts.map(({ id, clientParams }) => ({
            id,
            clientParams: clientParamsToDefaultParams(clientParams)
          }))
          defaultInput.children = children
        }
        return defaultInput
      })

      this.setState({ productInputs })
      this.props.setFareInput(productFareInput)
      return products
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { routeId } = this.props
    let { routeId: prevRouteId } = prevProps
    const updated = !(prevRouteId === routeId)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { routeId, initFareQuantityDefault } = this.props
      if (!routeId) return
      const products = await this.getTicketProductFare(routeId)
      this.setState({ products })
      if (!_.isEmpty(products)) {
        let productDefault = products[0]
        let fareDefault
        let fares = productDefault.fare
        if (fares) {
          fareDefault = fares[0]
        }
        if (productDefault && fareDefault) {
          initFareQuantityDefault(productDefault, fareDefault)
        }
      }
    }
  }

  render() {
    let {
      classes,
      translate,
      setFareInput,
      onChangeQuantity,
      totalFareMapping,
      totalQuantity,
    } = this.props
    let { products } = this.state
    let total = totalFareMapping && _.sumBy(Object.values(totalFareMapping), 'total')

    return <Grid item xs={12} md={12}>
      <Typography
        variant="h4"
        className={classes.title}
      >
        {translate('resources.saletickets.ticket')}
      </Typography>
      {!_.isEmpty(products) ? <div>
        {products.map((product, idx) => (
          <Product
            key={idx}
            idx={idx}
            record={product}
            onChangeQuantity={onChangeQuantity}
            onChangeInput={(input, product) => {
              let productInputs = [...this.state.productInputs]
              productInputs[idx] = input
              this.setState({ productInputs })
              setFareInput({ [product.id]: input })
            }}
            input={this.state.productInputs[idx]}
            totalFareMapping={totalFareMapping}
            totalQuantity={totalQuantity}
          />
        ))}
        <div className={classes.header}>
          <div className={classes.totalContainer}>
            <div className={classes.totalLabel}>
              <b>{translate('resources.saletickets.total')}</b>
            </div>
            <div className={classes.totalValue}>
              <b>{total ? formatCurrency(total) : '0đ'}</b>
            </div>
          </div>
        </div>
      </div> : <Snackbar
        variant="warning"
        message={translate('notification.empty_product')}
        className={classes.emptySnack}
      />}
    </Grid>
  }
}

const enhanceProductInformation = compose(withStyles(productInfomationStyle), translate)
const ProductInformation = enhanceProductInformation(_ProductInformation)

const customerInfomationStyle = {
  root: {
    marginTop: 18,
    paddingLeft: 32,
    marginLeft: 16,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  title: {
    marginBottom: 16,
  },
  info: {
    display: 'flex',
  },
  cusInfo: {
    width: '100%',
    marginRight: 16,
  },
}

const validateFullName = [minLength(6), maxLength(24)]

const _CustomerInformation = (props) => {
  let { classes, translate } = props
  let extra = { resource: 'products', fullWidth: true }
  return <Fragment>
    <Typography
      variant="h4"
      className={classes.title}
    >
      {translate('resources.saletickets.customerInfo')}
    </Typography>
    <TextInput
      {...extra}
      label="resources.saletickets.phone"
      source="phone"
    />
    <TextInput
      {...extra}
      label="resources.saletickets.cusName"
      source="fullName"
      validate={validateFullName}
    />
    <TextInput
      {...extra}
      label="resources.saletickets.email"
      source="email"
    />
    <TextInput
      {...extra}
      label="resources.saletickets.note"
      source="note"
    />
  </Fragment>
}

const enhanceCustomerInformation = compose(withStyles(customerInfomationStyle), translate)
const CustomerInformation = enhanceCustomerInformation(_CustomerInformation)

const infomationStyle = {
  root: {
    padding: 18,
  },
  productCusContainer: {
    display: 'flex',
  }
}

const _Information = ({
  classes,
  onChangeQuantity,
  setFareInput,
  totalFareMapping,
  totalQuantity,
  initFareQuantityDefault,
  record,
}) => {
  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12} md={8}>
        <RouteInformation record={record} />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomerInformation />
      </Grid>
    </Grid>
    <Divider />
    <Grid container className={classes.productCusContainer}>
      <ProductInformation
        routeId={record.id}
        onChangeQuantity={onChangeQuantity}
        setFareInput={setFareInput}
        totalFareMapping={totalFareMapping}
        totalQuantity={totalQuantity}
        initFareQuantityDefault={initFareQuantityDefault}
      />
    </Grid>
  </div>
}

const enhanceInformation = compose(withStyles(infomationStyle))
const Information = enhanceInformation(_Information)

const style = {
  root: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
  },
  form: {
    width: '100%'
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    position: 'absolute',
  },
  emptySnack: {
    width: '100%',
    maxWidth: '100%',
  },
}

const SALE_TICKET_FORM = 'sale-ticket-form'

class SaleTicketLayout extends Component {

  state = {
    totalQuantity: 0,
    loading: false,
    openAgencyPaymentDialog: false,
    content: {},
    productFareInput: {},
  }

  async componentDidMount() {
    await this.init()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { routeId, companyId } = nextProps
    let { routeDefault, companyDefault, companyChoices, routeChoices } = prevState
    if (!routeId) {
      routeDefault = (routeChoices && routeChoices.length > 0) && routeChoices[0].id
    } else if (routeId !== routeDefault) {
      routeDefault = routeId
    }
    if (!routeId) {
      routeDefault = (companyChoices && companyChoices.length > 0) && companyChoices[0].id
    } else if (companyId !== companyDefault) {
      companyDefault = companyId
    }
    return { routeDefault, companyDefault }
  }

  init = async () => {
    let { translate, permissions, changeBreadcrumb } = this.props
    let agencyRole = permission.isAgencyRole(permissions)
    let companyRole = permission.isCompanyRole(permissions)
    let paths = [
      { label: translate('resources.reservations.name', { smart_count: 2 }), to: '/reservations' },
      { label: translate('resources.saletickets.name'), to: '' },
    ]
    changeBreadcrumb({ paths })
    let { companyChoices, routeChoices, companyDefault, routeDefault } = this.state
    if (agencyRole) {
      companyChoices = await this.getRegistedBuyTicketCompanies()
      if (companyChoices && companyChoices.length > 0) {
        companyDefault = companyDefault || companyChoices[0].id
      }
    }
    routeChoices = await this.getRoutes(companyDefault)
    if (routeChoices && routeChoices.length > 0) {
      routeDefault = routeDefault || routeChoices[0].id
    }
    this.setState({
      routeDefault,
      companyDefault,
      companyChoices,
      routeChoices,
      agencyRole,
      companyRole,
    })
  }

  getRegistedBuyTicketCompanies = async () => {
    let res = await Provider.dataProvider('REMOTE', 'companies', {
      method: 'getBusTourCompaniesByAgency',
      requestMethod: 'GET'
    })
    if (res && res.data) {
      return res.data
    }
  }

  getRoutes = async (companyId) => {
    let res = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: {
        where: {
          status: { neq: '20ARCHIVED' }
        },
        isBusTour: true,
        withNumberOfProduct: true,
        byCompany: companyId,
        fields: [
          'id',
          'name',
          'numberOfProduct',
          'routeGroupId',
          'status',
          'originId',
          'destinationId',
          'distance',
          'companyId'
        ],
        include: {
          relation: 'company',
          scope: {
            fields: ['id', 'name']
          }
        }
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  setFareQuantityDefault = (productDefault, fareDefault) => {
    this.setState({ productDefault, fareDefault })
  }

  initFareQuantityDefault = (productDefault, fareDefault) => {
    this.setState({
      productDefault,
      fareDefault,
    })
    let { amount } = fareDefault
    this.setFareQuantity(1, productDefault, fareDefault, amount)
  }

  setFareInput = (fareInputs) => {
    let productFareInput = { ...this.state.productFareInput, ...fareInputs }
    this.setState({ productFareInput })
  }

  setFareQuantity = (
    quantity,
    product,
    fare,
    total,
    prevRecord = {},
  ) => {
    let { totalFareMapping = {}, totalQuantity, productFareMapping = {} } = prevRecord
    let { id: productId, type: productType, name: productName } = product
    let { id: fareId, name: fareName, amount } = fare
    productFareMapping = {
      ...productFareMapping, [fareId]: {
        productType,
        productId,
        quantity,
        fareId,
        amount,
        fareName,
        productName,
      }
    }
    totalFareMapping = { ...totalFareMapping, [fareId]: { total, quantity } }
    totalQuantity = _.reduce(Object.values(productFareMapping), (sum = 0, ele) => {
      let { quantity } = ele
      sum += parseInt(quantity)
      return sum
    }, 0)
    this.setState({
      totalFareMapping,
      productFareMapping,
      totalQuantity,
    })
  }

  onChangeQuantity = (quantity, product, fare) => {
    let { amount } = fare
    let total = quantity * amount
    let { totalFareMapping = {}, totalQuantity, productFareMapping = {} } = this.state
    let prevRecord = { totalFareMapping, totalQuantity, productFareMapping }
    this.setFareQuantity(quantity, product, fare, total, prevRecord)
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { location } = this.props
    let { location: prevLocation } = prevProps
    const updated = !(prevLocation === location)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      await this.init()
    }
  }

  reduceRoute = async (value) => {
    let routeChoices = await this.getRoutes(value)
    let { changeForm } = this.props
    let routeDefault
    if (routeChoices && routeChoices.length > 0) {
      routeDefault = routeChoices[0].id
      changeForm(SALE_TICKET_FORM, 'routeId', routeDefault)
    }
    this.setState({ routeChoices })
  }

  render() {
    let { translate, classes, changeForm } = this.props
    let {
      totalFareMapping,
      routeDefault,
      companyDefault,
      totalQuantity,
      routeChoices,
      companyChoices,
      agencyRole,
      productFareMapping,
      productFareInput,
    } = this.state
    let sizeSearchItem = agencyRole ? 4 : 6
    let canSale = agencyRole ? (companyDefault && routeDefault) : !(!routeDefault)
    return <Fragment>
      <Title title={translate('resources.saletickets.name')} />
      <Card className={classes.container}>
        {canSale ? <SimpleForm
          form={SALE_TICKET_FORM}
          resource="saletickets"
          className={classes.form}
          toolbar={<SaleTicketToolbar
            form={SALE_TICKET_FORM}
            totalFareMapping={totalFareMapping}
            productFareMapping={productFareMapping}
            productFareInput={productFareInput}
          />}
          record={{ routeId: routeDefault, companyId: companyDefault }}
          asyncBlurFields={['phone']}
          asyncValidate={async (record) => {
            const { phone } = record
            if (!phone) return
            let { phones = {} } = this.state
            let customer = phones[phone]
            if (!customer) {
              phones = { ...phones, phone: {} }
              let result = await Provider.dataProvider('REMOTE', 'customers', {
                method: 'findByPhone',
                data: { phone },
              })
              let _customer = _.get(result, 'data.customer')
              if (!_.isEmpty(_customer)) {
                customer = _customer
                phones = { ...phones, phone: customer }
              }
              this.setState({ phones })
            }
            if (!_.isEmpty(customer)) {
              if (!record.fullName) changeForm(SALE_TICKET_FORM, 'fullName', customer.fullName)
              if (!record.email) changeForm(SALE_TICKET_FORM, 'email', customer.email)
            }
          }}
          validate={validate}
        >
          <Grid container className={classes.root}>
            <Grid item container>
              {agencyRole && <Grid item md={sizeSearchItem} xs={12}>
                <SelectInput
                  fullWidth
                  onChange={(evt, value) => this.reduceRoute(value)}
                  label="resources.saletickets.companyId"
                  source="companyId"
                  resource="companies"
                  choices={companyChoices}
                  optionText="name"
                />
              </Grid>}
              <Grid item md={sizeSearchItem} xs={12}>
                <SelectInput
                  fullWidth
                  source="routeId"
                  resource="routes"
                  choices={routeChoices}
                  translateChoice={false}
                  optionText={record => <span>
                    {record.name}&nbsp;
                    <b>({record.numberOfProduct > 0 ? (
                      translate('resources.saletickets.numberOfProduct', { quantity: record.numberOfProduct })) : (
                      translate('resources.saletickets.product_empty'))
                    })</b>
                  </span>}
                  validate={required()}
                />
              </Grid>
              <Grid item md={sizeSearchItem} xs={12}>
                <DatePickerInput
                  label="resources.saletickets.departureDate"
                  resource="saleticket"
                  source="departureDate"
                  keyboard={true}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <FormDataConsumer>
            {({ formData = {} }) => {
              let { routeId } = formData
              let record = _.find(routeChoices, ele => ele.id === routeId)
              return record ? <Information
                record={record}
                totalFareMapping={totalFareMapping}
                totalQuantity={totalQuantity}
                setFareInput={this.setFareInput}
                onChangeQuantity={this.onChangeQuantity}
                initFareQuantityDefault={this.initFareQuantityDefault}
              /> : null
            }}
          </FormDataConsumer>
        </SimpleForm> : <Snackbar
          variant="warning"
          message={translate('notification.empty_route_tour')}
          className={classes.emptySnack}
        />}
      </Card>
    </Fragment>
  }
}

const mapStateToProps = state => {
  let filterValues = state.form[SALE_TICKET_FORM] && state.form[SALE_TICKET_FORM].values ? state.form[SALE_TICKET_FORM].values : {}
  let { routeId, companyId, departureDate } = filterValues
  return { routeId, companyId, departureDate }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(mapStateToProps, {
    showNotification,
    changeBreadcrumb,
    changeForm,
  })
)

const SaleTicketLayoutWithPermission = ({ ...props }) => <WithPermissions
  render={({ permissions }) => permissions ? <SaleTicketLayout permissions={permissions} {...props} /> : null}
/>

export default enhance(SaleTicketLayoutWithPermission)

