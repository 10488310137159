import XLSX from 'xlsx'
import concat from 'lodash/concat'
import { readExcelFile } from './file'


export async function readXlsx(file, options = { raw: false, dateNF: 'YYYY-MM-DD' } ) {
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  let bstr = await readExcelFile(file)
  let result = []
  let wb = XLSX.read(
    bstr,
    { type: rABS ? 'binary' : 'array' }
  )
  let wsnames = wb.SheetNames
  for (let j = 0; j < wsnames.length; j++) {
    let wsname = wsnames[j]
    let ws = wb.Sheets[wsname]
    let data = XLSX.utils.sheet_to_json(ws, { header: 0, blankrows: false, dateNF: options.dateNF, raw: options.raw })
    if (data && data.length > 0) {
      result = concat(result, data)
    }
  }
  return result
}

export function writeXlsx(array, sheetName, options = { bookType: 'xlsx', bookSST: false, type: 'array' }) {
  let wb = XLSX.utils.book_new()
  let ws = XLSX.utils.aoa_to_sheet(array)
  XLSX.utils.book_append_sheet(wb, ws, sheetName)
  let wbout = XLSX.write(wb, options)
  return wbout
}