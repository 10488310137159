import { Component } from 'react'
import L from 'leaflet'
import { withLeaflet} from 'react-leaflet'
import { Provider } from '../provider'
import PropTypes from 'prop-types'
import { contains, getBounds, coordinatesToLatLngs, hasCoordinates } from './utils'
//helper methods
//-------------------------------------------
/*
function safeRemoveLayer(leafletMap, el) {
  const { overlayPane } = leafletMap.getPanes()
  if (overlayPane && overlayPane.contains(el)) {
    overlayPane.removeChild(el)
  }
}
*/

//class definition
//network layer displays all routes on map
//--------------------------------------------
class NetworkLayer extends Component {
  constructor(props) {
    super(props)
    let { routes = [] } = this.props
    this.state = { routes }
  }
  
  componentDidMount() {
    const map = this.props.leaflet.map
    this.leafletElement = new L.LayerGroup()
    this.leafletElement.addTo(map)
    this.attachEvents()
  }

  async fetchRoutes() {
    let resp = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: {},
      sort: {},
      pagination: { page: 1, perPage: 100 },
    })
    return resp.data
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onKeyPress(evt) {
    const origin = evt.originalEvent
    if (origin.defaultPrevented) {
      return
    }
    const key = origin.key
    switch(key) {
      case 'f':
        this.fitBounds()
        break
      default:
        break
    }
  }

  attachEvents() {
    const map = this.props.leaflet.map
    map.on('keypress', (evt) => this.onKeyPress(evt))
  }

  renderRoute(route) {
    let { routeStyles } = this.props
    if (!hasCoordinates(route)) { return }

    if (!contains(this.leafletElement, route.id)) {
      let latlngs = coordinatesToLatLngs(route.path.coordinates)
      let color = route.color ? route.color : routeStyles.color
      let polyline = L.polyline(latlngs, { color: color, weight: routeStyles.weight, opacity: routeStyles.opacity }).addTo(this.leafletElement)
      //add route id to the layer
      polyline.routeId = route.id
    } 
  }

  async renderRoutes() {
    let routes = await this.fetchRoutes()
    let len = routes.length

    for (let idx = 0; idx < len; idx++) {
      let route = routes[idx]
      this.renderRoute(route)
    }
  }

  clearRoutes() {
    if (!this.leafletElement) { return }
    this.leafletElement.clearLayers()
  }

  fitBounds() {
    const map = this.props.leaflet.map
    let bounds = null
    if (!this.leafletElement) { return }
    this.leafletElement.eachLayer(v => {
      if (v.routeId) {
        let layerBounds = v.getBounds()
        if (layerBounds && layerBounds.isValid()) {
          bounds = getBounds(bounds, layerBounds)
        }
      }
    })
    if (bounds && bounds.isValid()) {
      map.fitBounds(bounds)
    }
  }

  render() {
    let { enabled } = this.props
    if (enabled) {
      this.renderRoutes()
    } else {
      this.clearRoutes()
    }
    return null
  }
}

NetworkLayer.propTypes = {
  routeStyles: PropTypes.object
}

NetworkLayer.defaultProps = {
  routeStyles: { weight: 2, opacity: 1, color: 'blue' }
}
export default withLeaflet(NetworkLayer)
