import React, { Component } from 'react'
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
} from '@material-ui/core'
import {
  translate,
  showNotification,
  Title,
} from 'react-admin'
import compose from 'recompose/compose'
import ReviewStep from './ReviewStep'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import { changeBreadcrumb } from '../breadcrumb/action'
import PrintButton from './PrintButton'
import ExportButton from './ExportButton'
import * as ReservationStatus from '../common/reservation-status'
import RemotePrintButton from '../company_reservation/RemotePrintButton'
import _ from 'lodash'

const styles = () => ({
  card: {
    margin: 8
  },
  title: {
    display: 'flex',
    alignItems: 'center',
  },
  progress: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 50,
  },
})

const ShowAction = ({ id, code, status }) => {
  return status === ReservationStatus.CONFIRMED && <div>
    <PrintButton color="primary" type="button" id={id} />
    <ExportButton color="primary" type="button" id={id} code={code} />
    <RemotePrintButton color="primary" button={true} code={code} />
  </div>
}

class Show extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount = () => {
    this.reload()
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { id: prevId } = prevProps
    let { id } = this.props
    const updated = !(prevId === id)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      this.reload()
    }
  }

  reload = async () => {
    let { id } = this.props
    let resp = await Provider.dataProvider('GET_ONE', 'reservations', {
      id,
      filter: {
        include: [
          'bookingInformation',
          'passengers',
          'paymentInformation',
          {
            relation: 'transactions',
            scope: { order: 'id DESC' }
          },
          {
            relation: 'charges',
            scope: {
              where: { cancelReference: null },
              include: [
                {
                  relation: 'productCharge',
                  scope: {
                    //where: {
                    //firstUse: { neq: null }
                    //},
                    include: [
                      {
                        relation: 'histories',
                        scope: {
                          order: 'createdAt desc'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          { relation: 'tickets' },
          {
            relation: 'histories',
            scope: {
              order: 'createdAt desc'
            }
          },
          {
            relation: 'device',
            scope: { fields: ['id', 'serial'] }
          },
          {
            relation: 'createdByUser',
            scope: { fields: ['id', 'username'] }
          },
          {
            relation: 'updatedByUser',
            scope: { fields: ['id', 'username'] }
          }
        ]
      },
    })
    if (resp && resp.data) {
      let { data } = resp
      this.onDoneGetReservationDetail(data)
    }
  }

  onDoneGetReservationDetail = async (data) => {
    let { changeBreadcrumb, translate, id } = this.props
    let { 
      trips: tripIds,
      bookingInformation,
      passengers,
      charges,
      histories,
      paymentInformation,
      transactions,
      createdAt,
      updatedAt,
      status,
      code,
      source,
      agencyId,
      device,
      createdByUser,
      updatedByUser,
      qrData,
    } = data
    let paths = [
      { label: translate('resources.reservations.name'), to: '/reservations' },
      { label: data.code, to: '' },
    ]
    changeBreadcrumb({ paths })

    let totalPassenger = passengers ? passengers.length : 0
    for (let i = 0; i < totalPassenger; i++) {
      let passenger = passengers[i]
      let { id } = passenger
      passenger.index = i

      let passengerCharges = charges.filter(charge => charge.passengerId === id)
      let totalPassengerCharge = passengerCharges.length
      for (let j = 0; j < totalPassengerCharge; j++) {
        passengerCharges[j].passenger = { index: i }
      }
    }

    let respTrips = await Provider.dataProvider(
      'GET_LIST',
      'trips',
      {
        filter: { 
          id: { inq: tripIds },
          '../fields': [
            'id',
            'name',
            'departureTime',
            'vehicleId',
            'driverId',
            'driver2Id',
            'arrivalTime',
            'routeId',
          ],
          '../include': [{
            relation: 'route',
            scope: {
              fields: ['id', 'name', 'distance', 'originId', 'destinationId']
            }
          }]
        },
        pagination: {},
        sort: {},
      })
    let trips = []
    let routeTripMapping = {}
    if (respTrips && respTrips.data) {
      trips = respTrips.data
      routeTripMapping = _.reduce(trips, (result, trip) => {
        let { routeId } = trip
        if (routeId) {
          result[routeId] = trip
        }
        return result
      }, {})
    }

    let totalFare = 0
    let totalAmount = 0
    let totalDiscount = 0
    let fares = []
    let respCalculateChargeFare = await Provider.dataProvider('REMOTE', 'charges', {
      method: 'calculateChargeFare',
      requestMethod: 'POST',
      data: [ ...charges ],
    })
    if (respCalculateChargeFare && respCalculateChargeFare.data) {
      let { data } = respCalculateChargeFare
      totalFare = data.totalFare
      totalAmount = data.totalAmount
      totalDiscount = data.totalDiscount
      fares = data.fares
    }
    this.setState({ 
      trips,
      routeTripMapping,
      passengers,
      charges,
      paymentInformation,
      transactions,
      bookingInformation,
      reservationInfo: {
        id,
        code,
        status,
        createdAt,
        updatedAt,
        source,
        agencyId,
        device,
        createdByUser,
        updatedByUser,
        qrData
      },
      totalAmount,
      totalFare,
      totalDiscount,
      fares,
      loading: false,
      histories,
    })
  }

  updateBookingInformation = (newBookingInformation) => {
    let { bookingInformation } = this.props
    bookingInformation = { ...bookingInformation, ...newBookingInformation }
    this.setState({ bookingInformation })
  }

  render() {
    let { classes, translate } = this.props
    let { 
      trips,
      passengers,
      charges,
      paymentInformation,
      transactions,
      bookingInformation,
      reservationInfo = {},
      loading,
      totalAmount,
      totalFare,
      totalDiscount,
      fares,
      routeTripMapping,
      histories,
    } = this.state
    let { id, code, status } = reservationInfo
    return loading ? <div className={classes.progress}><CircularProgress /></div> : <Card className={classes.card}>
      <Title title={`${translate('resources.reservations.fields.code')}: #${code}`}/>
      <CardHeader action={<ShowAction id={id} code={code} status={status} />} />
      <CardContent>
        <ReviewStep
          reload={this.reload}
          trips={trips}
          passengers={passengers}
          charges={charges}
          paymentInformation={paymentInformation}
          transactions={transactions}
          bookingInformation={bookingInformation}
          reservationInfo={reservationInfo}
          totalFare={totalFare}
          totalAmount={totalAmount}
          totalDiscount={totalDiscount}
          fares={fares}
          routeTripMapping={routeTripMapping}
          updateBookingInformation={this.updateBookingInformation}
          activities={histories}
        />
      </CardContent>
    </Card>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { push, showNotification, changeBreadcrumb })
)

export default enhance(Show)
