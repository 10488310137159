import React, { Component } from 'react'
import { translate } from 'react-admin'
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Input,
  withStyles,
} from  '@material-ui/core'
import CheckboxMenu from '../common/CheckboxMenu'
import IconButton from '@material-ui/core/IconButton'
import compose from 'recompose/compose'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import SaveIcon from '@material-ui/icons/Save'
import {Provider} from '../provider'
import _ from 'lodash'
import numeral from 'numeral'
// import CheckboxMenu from './CheckboxMenu'
// import { UPDATE } from 'ra-core'

class EditMaintainForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // selectedItems: MaintainSchedule.defaultItems,
      maintainItems: [],
      selectedItems: [],
      actions: [], 
      schedules: [],
      openChecklist: false,
      selectedVehicle: null,
      vehicles: [],
    }    
  }
  componentDidMount() {
    this.fetchRelatedData()
  }
  fetchRelatedData = async () => {
    let responseVehicleMaintain = await Provider.dataProvider('REMOTE', 'vehiclemaintains', {
      method: '',
      requestMethod: 'GET',
      data: {
        filter: { where: { id: this.props.id }}
      }
    })
    
    let responseMaintainItem = await Provider.dataProvider('REMOTE', 'maintainitems', {
      method: '',
      requestMethod: 'GET',
      data: {}
    })

    let responseMaintainAction = await Provider.dataProvider('REMOTE', 'maintainactions', {
      method: '',
      requestMethod: 'GET',
      data: {}
    })
    let responseMaintainSchedule = await Provider.dataProvider('REMOTE', 'maintainschedules', {
      method: '',
      requestMethod: 'GET',
      data: {}
    })

    let allVehicles = await Provider.dataProvider('REMOTE', 'vehicles', {
      method: '',
      requestMethod: 'GET',
      data: {}
    })

    // console.log('maintain schedule:', responseMaintainSchedule.data)
    const sortedSchedule = _.sortBy(responseMaintainSchedule.data, obj => {
      return obj.month
    })
    let selectedItems = []
    let checklistData = []    
    if(responseVehicleMaintain.data.length > 0) {
      const mtInfo = responseVehicleMaintain.data[0].maintainInfo
        
      for (let i = 0; i < mtInfo.length; i++ ) {
        let obj = {...mtInfo[i]}      
        selectedItems.push(obj)
      }
      for (let i = 0, length = responseMaintainItem.data.length; i < length; i++ ) {
        const mtItem = responseMaintainItem.data[i]
        const idx = mtInfo.findIndex(item => item.id === mtItem.id)
        if (idx === -1) {
          const chk = {...mtItem, isChecked: false, value: `${mtItem.id}`}
          checklistData.push(chk)
        }
      }

      this.setState({
        maintainItems: responseMaintainItem.data, 
        actions: responseMaintainAction.data, 
        schedules: sortedSchedule,
        selectedItems,
        checklistData: checklistData.sort((a, b) => a.id < b.id),
        companyId:responseVehicleMaintain.data[0].companyId,
        selectedVehicle: responseVehicleMaintain.data[0].vehicleId,
        vehicles: allVehicles.data
      })
    }
  }
  handleChecklistClose = selectedItems => {
    let copyData = [...this.state.selectedItems]
    let checklistData = [...this.state.checklistData]
    const {actions} =  this.state
    if (Array.isArray(selectedItems) && selectedItems.length > 0) {
      for(let i = 0; i < selectedItems.length; i++) {
        let obj = selectedItems[i]
        const idx = checklistData.findIndex(item => item.id === obj.value)
        if (idx !== -1) {
          checklistData.splice(idx, 1)
        }        
        for (let j = 0; j < actions.length; j++ ) {
          const act = actions[j]
          obj[act.actionCode] = 0
        }
        delete obj.isChecked
        delete obj.value
        copyData.push(obj)
      }
      this.setState({selectedItems: copyData, checklistData })
    }

  }
  doSave = async () => {
    // let responseMaintainItem = await Provider.dataProvider(UPDATE, 'vehiclemaintains', {
    //   id: this.props.id,
    //   data: {vehicleId: this.state.selectedVehicle, maintainInfo: this.state.selectedItems, companyId: this.state.companyId }
    // })
    // console.log('data response' , responseMaintainItem.data)
  }

  handleSelectItem = (rowIdx, actionType, value) => {        
    const copyData = [...this.state.selectedItems]
    const currItem = copyData[rowIdx]
    currItem[actionType] = value
    copyData[rowIdx]= currItem
    this.setState({selectedItems: copyData})
  }
  handleChecklistChange =  value => {
    this.setState({ selected: value })
  }
  renderActionItem = (action, sequenceList, rowIdx) => {   
    const that = this
    const {selectedItems} = this.state
    const currValue = selectedItems[rowIdx][action.actionCode]
    
    return (
      <Select
        value={currValue}
        onChange={ event => {
          that.handleSelectItem(rowIdx, action.actionCode, event.target.value)
        }}
        input={<Input name={`${action.name}`} id="age-label-placeholder" />}
        displayEmpty
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {
          sequenceList.map((item,idx) => {
            return <MenuItem key={idx} value={item.month}>{`${item.month} T/${numeral(item.km*1000).format('0,0')} Km`}</MenuItem>
          })
        }        
      </Select>
    )
  }

  renderSelectVehicle = () => {
    const that = this    
    const {vehicles, selectedVehicle} = this.state
    
    return (
      <div style={{margin: 10}}>
        <span style={{marginRight: 10}}>Bảng số xe</span>
        <Select
          disabled
          style={{margin: 10, minWidth: 100}}
          value={`${selectedVehicle}`}
          onChange={ event => {
            // console.log('event:', event.target)
            that.setState({selectedVehicle: event.target.value})
          }}
          input={<Input name={`${selectedVehicle}`} id="age-label-placeholder" />}
          displayEmpty
        >     
          {
            vehicles.map((item,idx) => {
              return <MenuItem key={idx} value={item.id}>{item.plate}</MenuItem>
            })
          }        
        </Select>
      </div>
    )
  }

  toogleChecklist = () => {
    this.setState({openChecklist: !this.state.openChecklist})
  }
  deleteItem = (item, idx) => {
    let copyData = [...this.state.selectedItems]
    let checklistData = [...this.state.checklistData]
    const removedItem = copyData.splice(idx, 1)
    const {actions} =  this.state
    
    for (let j = 0; j < actions.length; j++ ) {
      const act = actions[j]
      removedItem[0][act.actionCode] = 0
    }
    removedItem[0].isChecked = false
    removedItem[0].value = `${removedItem[0].id}`
    checklistData.push(removedItem[0])
    this.setState({selectedItems: copyData, checklistData})
  }
  render() {
    const {classes} = this.props
    const {selectedItems, actions, schedules, checklistData = []} = this.state

    return (
      <Paper>        
        {this.renderSelectVehicle()}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Tên</TableCell>{
                actions.map((item, idx) => {
                  return (<TableCell align="right" key={idx}>
                    {item.name}
                  </TableCell>)
                })
              }
              <TableCell align="right">
                    Hành động
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                {
                  actions.map((item, idx) => {
                    return (<TableCell align="right" key={idx}>
                      {this.renderActionItem(item, schedules, rowIdx)}
                    </TableCell>)
                  })
                }
                <TableCell align="right">
                  <IconButton className={classes.button} aria-label="Delete" color="primary" onClick={evt => {
                    this.deleteItem(row, rowIdx)
                  }}>
                    <DeleteIcon color="error"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style= {{display: 'flex'}}>
          {checklistData.length>0?(<CheckboxMenu icon={<AddIcon color="primary"/>} options={this.state.checklistData} closeEvent={this.handleChecklistClose} />): null}
          <IconButton className={classes.button} aria-label="Add" color="primary" onClick={ evt => {
            this.doSave()
          }}>
            <SaveIcon color="primary"/>
          </IconButton>
          
        </div>
      </Paper>
    )
  }
}
const styles = {
  table: {},
  formControl: {
    margin: 10,
  }
}
const enhance = compose(translate, withStyles(styles))
export default enhance(EditMaintainForm)
