import React, { Component, Fragment } from 'react'
import { 
  ReferenceField,
  ChipField,
  translate,
  FunctionField,
} from 'react-admin'
import {
  Card,
  CardHeader,
  Avatar,
  CardActions,
  CardContent,
  Typography,
  withStyles,
  Divider,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import compose from 'recompose/compose'
import clsx from 'clsx'
import { moment } from '../common/format'
import CardAvatar from '../common/CardAvatar'
import SettingChildProductButton from './SettingChildProductButton'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CommissionChip from '../common/CommissionChip'
import { ProductTypeIcon } from './constant'
import _ from 'lodash'
import FareChip from './FareChip'
import AgencyList from './AgencyList'

const routeProductStyle = {
  root: {
    marginBottom: 16,
    borderLeft: '8px solid #3f51b5',
    paddingLeft: 8,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
  },
}

class _RouteProduct extends Component {

  state = {}

  render() {
    let { record, classes, updateChildProductFare, productFareMapping } = this.props
    let { route, productFares } = record
    return <div className={classes.root}>
      <Typography className={classes.title}>{route.name}</Typography>
      {productFares && productFares.map((productFare, idx) => {
        return <FareChip
          key={idx}
          record={productFare}
          productFareMapping={productFareMapping}
          source="amount"
          updateChildProductFare={updateChildProductFare}
        />
      })}
    </div>
  }
}

const enhanceRouteProduct = compose(withStyles(routeProductStyle), translate)
const RouteProduct = enhanceRouteProduct(_RouteProduct)

const agencyListChipStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  noPadding: {
    padding: '32px 0',
  },
}

class _AgencyListChip extends Component {

  state = {
    open: false
  }

  open = () => {
    this.setState({ open: true })
  }

  onClose = () => {
    this.setState({ open: false })
  }

  render() {
    let { record, classes, translate, commissionTemplates, isCompanyManager } = this.props
    let { open } = this.state
    return <Fragment>
      <ChipField
        onClick={this.open}
        record={record}
        source="agencyCount"
      />
      <Dialog
        open={open}
        onClose={this.onClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle className={classes.title}>
          <span className={classes.textTitle}>{translate('resources.agencies.name', { smart_count: 2 })}</span>
        </DialogTitle>
        <DialogContent>
          <AgencyList
            hideSupplier
            productId={record.id}
            commissionTemplates={commissionTemplates}
            supplierId={record.supplierId}
            isCompanyManager={isCompanyManager}
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  }
}

const enhaceAgencyListChip = compose(translate, withStyles(agencyListChipStyle))
const AgencyListChip = enhaceAgencyListChip(_AgencyListChip)

const settingStyle = {
  root: {
    margin: 0,
    paddingLeft: 16,
  },
  item: {
    fontSize: 11,
  },
  noConfig: {
    fontSize: 11,
    fontStyle: 'italic',
    color: '#424242',
  },
}

class _Setting extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let { data } = nextProps
    let { data: currentData } = prevState
    if (data !== currentData) {
      return { ...prevState, data }
    }
    return { ...prevState }
  }
  

  getLabels = () => {
    let { translate, classes, ticketValidations } = this.props
    let { data } = this.state
    if (!_.isEmpty(ticketValidations) && !_.isEmpty(data)) {
      let { params, type } = data || {}
      let validation = ticketValidations.find(ele => ele.id === type)
      let { name: validationTypeName, parameters } = validation
      let labels = _.reduce(params, (result, value, key) => {
        let suffix 
        let parameter = parameters[key]
        if (parameter) {
          let { label, values } = parameter
          let prefix = label
          if (value) {
            suffix = value
            if (values) {
              suffix = values[suffix]
            }
          } else {
            suffix = translate('resources.common.no_config')
          }
          let validationValue = <li className={classes.item}>
            <span>{prefix}:&nbsp;</span>
            <b>{suffix}</b>
          </li>
          result.push(validationValue)
        }
        return result
      }, [])
      return { validationTypeName, labels } 
    }
  }

  render() {
    let { translate, classes } = this.props
    let { validationTypeName, labels } = this.getLabels() || {}
    return <div>
      <Typography>
        {translate('resources.validationproducts.configuration_title')}:{validationTypeName && validationTypeName}
      </Typography>
      {labels ? <ul className={classes.root}>
        {labels.map((label, idx) => <span key={idx}>{label}</span>)}
      </ul> : <span className={classes.noConfig}>
        {translate('resources.common.no_config_validation', { smart_count: 2 })}
      </span>}
    </div>
  }
}

const enhaceSetting = compose(translate, withStyles(settingStyle))
const Setting = enhaceSetting(_Setting)

const productCardStyle = theme => ({
  root: {
    overflow: 'inherit',
  },
  icon: {
    marginRight: '0.5em',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  supplierContainer: {
    display: 'flex',
  },
  textContainer: {
    display: 'flex',
    flex: 1,
    margin: 'auto',
  },
  text: {
    fontSize: '0.75rem',
  },
  agencyContainer: {
    display: 'flex',
  },
  agencyLabel: {
    margin: 'auto 0px',
  },
})

class ProductCard extends Component {
  
  state = { expanded: false }

  handleExpandClick = () => {
    let { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    let {
      record,
      classes,
      translate,
      parentId,
      setNewChildProduct,
      routeProducts,
      removeButton,
      addButton,
      isChild,
      noChildProductEditButton,
      commissionTemplates,
      isCompanyManager,
      updateChildProductFare,
      productFareMapping,
      ticketValidations,
    } = this.props
    let { 
      name,
      validTo,
      validFrom,
      quantity,
      type,
      setting,
      supplierCommission,
    } = record
    let { expanded } = this.state
    return <Card className={classes.root}>
      <CardHeader
        avatar={<CardAvatar record={record} size='small' />}
        title={name}
        action={<ReferenceField
          basePath="/producttypes"
          reference="producttypes"
          record={record}
          source="type"
          linkType={false}
          allowEmpty
        >
          <ChipField
            avatar={<Avatar>{ProductTypeIcon[type]}</Avatar>}
            source="name"
            variant="outlined"
            color="primary"
          />
        </ReferenceField>}
        subheader={<span>{moment(validFrom).format('DD/MM/YYYY')}-{moment(validTo).format('DD/MM/YYYY')}</span>}
      />
      <Divider />
      <CardContent>
        <Typography>
          {translate('resources.products.fields.quantity')}:&nbsp;<b>{quantity >= 0 ? quantity : translate('resources.products.no_limit')}</b> 
        </Typography>
        {record.supplierId && <div className={classes.supplierContainer}>
          <Typography className={classes.textContainer}>
            {translate('resources.products.fields.supplierId')}:&nbsp;
            <ReferenceField
              basePath="/agencies"
              reference="agencies"
              source="supplierId"
              linkType={false}
              allowEmpty
              record={record}
            >
              <FunctionField source="name" render={data => <b className={classes.text}>{data.name}</b>} />
            </ReferenceField>
          </Typography>
          <CommissionChip
            label="resources.agencyproducts.fields.commission"
            source="commissionScript"
            method="changeCommission"
            resources="agencyproducts"
            commissionTemplates={commissionTemplates}
            record={supplierCommission || {}}
          />
        </div>}
        {record.parentProductId && <div className={classes.agencyContainer}>
          <Typography className={classes.agencyLabel}>
            {translate('resources.products.fields.agencyCount')}:
          </Typography>&nbsp;
          <div>
            <AgencyListChip
              isCompanyManager={isCompanyManager}
              commissionTemplates={commissionTemplates}
              record={record}
            /> 
          </div>
        </div>}
        <Setting data={setting} ticketValidations={ticketValidations} />
      </CardContent>
      <Divider />
      <CardActions>
        {isChild ? <SettingChildProductButton
          label={translate('button.edit')}
          button
          record={record}
          parentId={parentId}
          setNewChildProduct={setNewChildProduct}
          ticketValidations={ticketValidations}
          icon={<FontAwesomeIcon className={classes.icon} icon={faPen} />}
        /> : noChildProductEditButton}
        {removeButton}
        {addButton}
        {parentId && <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={this.handleExpandClick}
        >
          <ExpandMoreIcon />
        </IconButton>}
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {routeProducts && routeProducts.map((routeProduct, idx) => {
            return <RouteProduct
              record={routeProduct}
              key={idx}
              updateChildProductFare={updateChildProductFare}
              productFareMapping={productFareMapping}
            />
          })}
        </CardContent>
      </Collapse>
    </Card>
  }
}

const enhanceProductCard = compose(withStyles(productCardStyle), translate)
export default enhanceProductCard(ProductCard)
