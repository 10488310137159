import React, { Component } from 'react'
import { CirclePicker } from 'react-color'

class ColorPicker extends Component {

  onChangeColor = event => {
    let { input } = this.props
    let value = event.hex
    input = { ...input, value }
    input.onChange(value)
  }

  render() {
    let { input, width, classes } = this.props
    return (
      <CirclePicker
        color={input.value}
        onChange={this.onChangeColor}
        width={width}
        className={classes.colorPicker}
      />
    )
  }
}

export default ColorPicker