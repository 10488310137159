import React, { Component } from 'react' 
import {
  ReferenceField,
  TextField,
  ReferenceManyField,
  translate,
} from 'react-admin'
import {
  Grid,
  Typography,
  withStyles,
  Divider,
  Chip,
  Tooltip,
  Avatar,
} from '@material-ui/core'
import { PriceIcon } from '../icons'
import { green, grey } from '@material-ui/core/colors'
import compose from 'recompose/compose' 
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import LinkField from '../common/LinkField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = {
  root: {
    display: 'flex',
  }
}

const routeStyles = {
  divider: {
    marginBottom: 8,
  },
  text: {
    fontSize: 13,
    padding: 4,
    display: 'flex',
  },
  textInReferenceField: {
    fontSize: 13,
    lineHeight: 'normal',
  },
  label: {
    fontWeight: 'bold',
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  textHeader: {
    flex: '1 1 auto'
  }
}

const fareStyles = {
  assigned: {
    backgroundColor: green[500],
    color: 'white'
  },
  avatarAssigned: {
    backgroundColor: green[600],
    color: 'white'
  },
  unassigned: {
    backgroundColor: grey[500],
    color: 'white'
  },
  avatarUnassigned: {
    backgroundColor: grey[600],
    color: 'white'
  },
  text: {
    fontSize: 13,
    padding: 4,
    display: 'flex',
  },
  label: {
    fontWeight: 'bold',
  },
  icon: {
    margin: 0,
  }
}

const Patterns = translate(({ translate, ids = [] }) => {
  return ids ? ids.length : translate('resources.routes.no_pattern')
})

const _Fare = ({ routeFare, isCompanyManager, classes, translate, history }) => {
  return routeFare ? <Chip
    onClick={() => {
      if (!isCompanyManager) return
      history.push(`faretables/${routeFare}`)
    }}
    avatar={<Avatar className={classes.avatarAssigned}>
      <PriceIcon className={classes.icon} fontSize="small"/>
    </Avatar>}
    className={classes.assigned}
    label={translate('resources.routes.fare_assigned')}
  /> : <Chip
    onClick={() => {
      if (!isCompanyManager) return
      history.push('faretables')
    }}
    avatar={<Avatar className={classes.avatarUnassigned}>
      <PriceIcon className={classes.icon} fontSize="small"/>
    </Avatar>}
    className={classes.unassigned}
    label={translate('resources.routes.fare_unassigned')}
  />
}

const fareEnhance = compose(withStyles(fareStyles), translate)

const Fare = fareEnhance(_Fare)

const _Route = ({
  record,
  routeFare,
  style,
  routeTrip,
  translate,
  classes,
  history,
  isCompanyManager
}) => {
  let { number, distance, id } = record
  return <Grid style={style} container >
    <Grid item sm={12} md={12}>
      <div className={classes.header}>
        <div className={classes.textHeader}>
          <Typography variant="h6">{record.name}</Typography> 
        </div>
        <div className={classes.text}>
          <LinkField
            className={classes.iconButton}
            onClick={(evt) => {
              evt.preventDefault()
              history.push(`/routes/${id}`) }
            }
            icon
          >
            <Tooltip
              title={translate('resources.routes.edit')}
              enterDelay={100}
            >
              <FontAwesomeIcon icon={faEdit} size="xs" />
            </Tooltip>
          </LinkField>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.text}>
        <span className={classes.label}>{translate('resources.routes.number')}:&nbsp;</span><span>{number}</span>
      </div>
      <div className={classes.text}>
        <span className={classes.label}>{translate('resources.routes.distance')}:&nbsp;</span><span>{distance ? `${distance / 1000} (km)` : '0 (km)'}</span>
      </div>
      <div className={classes.text}>
        <span className={classes.label}>{translate('resources.routes.type')}:&nbsp;</span>
        <ReferenceField
          basePath="/routetypes"
          reference="routetypes"
          record={record}
          source="type"
          linkType={false}
        >
          <TextField className={classes.textInReferenceField} source="name" />
        </ReferenceField>
      </div>
      <div className={classes.text}>
        <span className={classes.label}>{translate('resources.routepatterns.name', { smart_count: 1 })}:&nbsp;</span>
        <ReferenceManyField
          reference="routepatterns"
          resource="routepatterns"
          basePath="/routepatterns"
          record={{}}
          target=""
          filter={{ where: { routeId: record.id }, fields: ['id'] }}
        >
          <Patterns />
        </ReferenceManyField>
      </div>
      <div className={classes.text}>
        <span className={classes.label}>
          {translate('resources.routegroups.fields.countTrip')}:&nbsp;
        </span>
        <span>{routeTrip}</span>
      </div>
      <div className={classes.text}>
        <Fare routeFare={routeFare} isCompanyManager={isCompanyManager} history={history} />
      </div>
    </Grid>
  </Grid>
}

const routeEnhance = compose(withStyles(routeStyles), translate)

const Route = routeEnhance(_Route)

class _Routes extends Component {
  
  render() {
    let { routes, isCompanyManager, routeFares, routeTrips, history } = this.props
    return routes ? routes.map((route, idx) => {
      let { id } = route
      let routeFare = routeFares[id]
      let routeTrip = routeTrips[id]
      let style = idx !== 0 ? { borderLeft: '1px solid rgba(0, 0, 0, 0.12)' } : {}
      return <Route 
        style={style}
        key={idx}
        record={route}
        routeTrip={routeTrip}
        routeFare={routeFare}
        history={history}
        isCompanyManager={isCompanyManager}
      />
    }) : null
  }
}

let enhanceRoute = compose(withStyles(routeStyles))
const Routes = enhanceRoute(_Routes)

class RouteGroupDetail extends Component {
  render(){
    let { record, isCompanyManager, classes, history } = this.props
    let { routes, routeFares, routeTrips } = record
    return <div className={classes.root}>
      <Routes 
        routes={routes}
        routeFares={routeFares}
        routeTrips={routeTrips}
        history={history}
        isCompanyManager={isCompanyManager}
      />
    </div>
  }
}

const enhance = compose(withStyles(styles))
export default enhance(RouteGroupDetail)

