import React, { Component } from 'react'
import {
  Stepper,
  Step,
  StepButton,
  Button,
  withStyles,
} from '@material-ui/core'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import AddInfoStep from './AddInfoStep'
import SelectVehicleTypeStep from './SelectVehicleTypeStep'
import {
  addVehicle as addVehicleAction,
  saveVehicles as saveVehiclesAction,
  existVehicles as handleExistsVehicleAction,
} from './action'
import ReviewStep from './ReviewStep'
import isEmpty from 'lodash/isEmpty'
import { translate, showNotification } from 'react-admin'
import { reset } from 'redux-form'

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  containerButton : {
    marginTop: 16,
    marginLeft: 16,
    marginBottom: 16
  },
})

function getStepContent(
  step,
  vehicleTypeSelected,
  values,
  vehicleSelected
) {
  switch (step) {
    case 0:
      return (
        <SelectVehicleTypeStep
          vehicleTypeSelected={vehicleTypeSelected}
        />
      )
    case 1:
      return (
        <AddInfoStep
          vehicleTypeSelected={vehicleTypeSelected}
          vehicleSelected={vehicleSelected}
          vehicles={values}
        />
      )
    case 2:
      return <ReviewStep />
    default:
      return 'Unknown step'
  }
}

class FormCreate extends Component {
  constructor(props) {
    super(props)
    const { translate } = props
    let steps = [
      translate('resources.common.select_vehicle_type'),
      translate('resources.common.add_info_vehicle'),
      translate('resources.common.review_info_vehicle')
    ]
    this.state = {
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
      vehicleFormValue: {},
      values: [],
      vehicleTypeSelected: {},
      vehicleSelected: {},
      steps,
      newFormAddInfoVehicle: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let {
      vehicleFormValue,
      vehicleTypeSelected,
      values,
      vehicleSelected,
    } = nextProps
    let {
      vehicleFormValue: currentVehicleFormValue,
      completed: currentCompleted,
      vehicleTypeSelected: currentVehicleTypeSelected,
      values: currentValues,
      vehicleSelected: currentVehicleSelected
    } = prevState
    if (currentVehicleTypeSelected !== vehicleTypeSelected) {
      currentVehicleTypeSelected = vehicleTypeSelected
    }
    if (!isEmpty(vehicleTypeSelected) && vehicleTypeSelected.type !== 0) {
      currentCompleted.add(0)
    } else {
      currentCompleted.delete(0)
    }
    if (!isEmpty(values)) {
      currentCompleted.add(1)
    } else {
      currentCompleted.delete(1)
    }
    if (vehicleFormValue !== currentVehicleFormValue) {
      currentVehicleFormValue = vehicleFormValue
    }
    if (currentValues !== values) {
      currentValues = values
    }
    if (currentVehicleSelected !== vehicleSelected) {
      currentVehicleSelected = vehicleSelected
    }
    return {
      vehicleFormValue: currentVehicleFormValue,
      values: currentValues,
      completed: currentCompleted,
      vehicleTypeSelected: currentVehicleTypeSelected,
      vehicleSelected: currentVehicleSelected
    }
  }
  
  totalSteps = () => {
    let { steps } = this.state
    return steps.length
  }

  isStepOptional = step => step === 1

  addVehicle = () => {
    const { vehicleFormValue } = this.state
    const { addVehicleAction, showNotification, reset } = this.props
    let { plate } = vehicleFormValue
    if (!plate) {
      showNotification('notification.vehicle_plate_invalid','warning')
    } else {
      addVehicleAction(vehicleFormValue)
      reset('record-form')
    }
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps()
  }

  handleNext = () => {
    let activeStep

    if (this.isLastStep() && !this.allStepsCompleted()) {
      let { steps } = this.state
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i))
    } else {
      activeStep = this.state.activeStep + 1
    }
    this.setState({
      activeStep,
    })
  }

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }))
  }

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    })
  }

  handleComplete = () => {
    const { values } = this.state
    const { saveVehiclesAction, handleExistsVehicleAction, reset } = this.props
    const handleExistsVehicle = (existsVehicle) => {
      this.setState({
        activeStep: 1, 
      })
      handleExistsVehicleAction(existsVehicle)
    }
    saveVehiclesAction(values, handleExistsVehicle)
    this.setState({
      activeStep: 0,
      completed: new Set(),
    })
    reset('vehicle-type')
  }

  isStepComplete(step) {
    return this.state.completed.has(step)
  }

  completedSteps() {
    return this.state.completed.size
  }

  allPrevStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - 1
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1
  }

  render() {
    const { classes, translate } = this.props
    const {
      activeStep,
      vehicleTypeSelected,
      vehicleSelected,
      values,
      steps,
    } = this.state

    return (
      <>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const props = {}
            const buttonProps = {}
            return (
              <Step key={label} {...props}>
                <StepButton
                  onClick={this.handleStep(index)}
                  completed={this.isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            )
          })}
        </Stepper>
        {getStepContent(activeStep, vehicleTypeSelected, values, vehicleSelected)}
        <div className={classes.containerButton}>
          <Button
            disabled={activeStep === 0}
            onClick={this.handleBack}
            className={classes.button}
          >
            {translate('button.back')}
          </Button>
          {!this.isLastStep() && <Button
            disabled={(activeStep === 1 && values.length === 0)}
            variant="contained"
            color="primary"
            onClick={this.handleNext}
            className={classes.button}
          >
            {translate('button.next')}
          </Button>}
          {activeStep === 1 && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.addVehicle}
              className={classes.button}
              autoFocus
            >
              {translate('button.add')}
            </Button>
          )}
          {activeStep === 2 && this.allPrevStepsCompleted() && (
            <Button
              variant="contained"
              color="primary"
              onClick={this.handleComplete}
            >
              {translate('button.finish')}
            </Button>
          )}
        </div>
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let vehicleFormValue = state.form['record-form'] ? state.form['record-form'].values : {}
  let values = state['vehicle'].values || []
  let vehicleTypeSelected = state['vehicle-type'].selected || {}
  let vehicleSelected = state['vehicle'].selected || {}
  return {
    vehicleFormValue: vehicleFormValue,
    values: values,
    vehicleTypeSelected: vehicleTypeSelected,
    vehicleSelected: vehicleSelected
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, {
    addVehicleAction,
    saveVehiclesAction,
    handleExistsVehicleAction,
    showNotification,
    reset,
  })
)

export default enhance(FormCreate)
