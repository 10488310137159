import React, { Component } from 'react'
import {
  TextInput,
  ReferenceInput,
  SimpleForm,
  showNotification,
  SelectInput,
  refreshView,
  translate
} from 'react-admin'
import {
  Grid,
  withStyles,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Typography,
  Avatar,
  Chip,
  Button
} from '@material-ui/core'
import CustomToolbar from '../common/CustomToolbarForm'
import { reset, change } from 'redux-form'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import { validPhoneNumber } from '../utils/topUpUtil'
import compose from 'recompose/compose'
import RoleChips from './RoleChips'
import { isAdmin } from '../utils/permission'
import RefCard from './RefCard'
import { sanitize } from '../utils/commonUtil'
import { validatePhone } from './validate'
import _ from 'lodash'
import UserContactDrawer from './UserContactDrawer'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import EditIcon from '@material-ui/icons/Edit'

const style = () => ({
  root: {
    width: '100%',
    marginBottom: '20px'
  },
  recipientBorder: {
    borderBottom: '1px solid #d3d3d3',
    borderTop: '1px solid #d3d3d3'
  },
  avatarSize: {
    width: '32px',
    height: '32px'
  },
  userContactTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '36px'
  },
  buttonFlex: {
    display: 'flex',
    alignItems: 'center'
  }
})

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: {},
      userContacts: [],
      errors: {},
      eventTypes: {},
      open: false,
      userContact: {},
      userContactIdx: '',
      action: ''
    }
  }

  componentDidMount = async () => {
    const { id } = this.props
    let res = await Provider.dataProvider('GET_LIST', 'userContacts', {
      filter: {
        where: { userId: id },
        scope: {
          fields: ['messageTypeId', 'recipient'],
        },
        include: {
          relation: 'userSubscriptions',
        }
      },
      sort: {},
      pagination: {},
    })
    let events = await Provider.dataProvider('GET_LIST', 'eventTypes', {
      sort: {},
      pagination: {},
    })

    let eventTypes = _.keyBy(events.data, 'id')
    this.setState({ userContacts: res.data, eventTypes })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { record } = nextProps
    const { record: currentRecord, userContacts } = prevState
    if (record && record !== currentRecord) {
      if (userContacts) {
        return {
          record: { ...record, userContacts }
        }
      }
    } else {
      return null
    }
  }

  save = async record => {
    const { showNotification, refreshView } = this.props
    let { id, fullName, email, status, phone } = record
    let { userContacts } = this.state
    let data = { fullName, email, status, phone, userContacts }
    Provider.dataProvider('REMOTE', 'users', {
      method: id,
      requestMethod: 'PATCH',
      data,
    })
      .then(() => {
        showNotification('notification.update_user_success')
        refreshView()
      }
      )
      .catch(() => showNotification('notification.update_user_failure', 'warning'))
  }

  removeUserSub = (eventType) => {
    const { userContact } = this.state
    let newUserContact = { ...userContact }
    if (userContact) {
      let newUserSubscriptions = newUserContact.userSubscriptions.filter(userSubscription => userSubscription.eventTypeId !== eventType)
      newUserContact = {
        ...newUserContact,
        userSubscriptions: newUserSubscriptions
      }
    }
    this.setState({ userContact: newUserContact })
  }


  addUserSub = (eventTypeId) => {
    const { userContact } = this.state
    let newUserContact = { ...userContact }
    if (userContact) {
      newUserContact = {
        ...newUserContact,
        userSubscriptions: [...userContact.userSubscriptions, { eventTypeId }]
      }
    }
    this.setState({ userContact: newUserContact })
  }

  addUserContact = () => {
    let userContact = {
      messageTypeId: '',
      recipient: '',
      userSubscriptions: []
    }
    this.setState({ userContactIdx: 0, userContact })
  }

  removeUserContact = (idx) => {
    const { userContacts } = this.state
    let newUserContacts = [...userContacts]
    newUserContacts.splice(idx, 1)
    this.setState({ userContacts: newUserContacts })
  }

  onOpen = () => {
    this.addUserContact()
    this.setState({ open: true, action: 'create' })
  }

  onClose = () => {
    this.props.reset('userContact')
    this.setState({ open: false, errors: {}, userContact: {} })
  }

  onEditUserContact = (userContact, userContactIdx) => {
    if (userContact.messageTypeId === 'SMS' || userContact.messageTypeId === 'WHATSAPP') {
      let recipient = userContact.recipient.replace('+84', '0')
      userContact = { ...userContact, recipient }
    }
    this.setState({
      userContact,
      userContactIdx,
      open: true,
      action: 'edit'
    })
  }

  onEdit = (record) => {
    if (!this.validateUserContact()) return
    const { userContacts, userContactIdx, action, userContact } = this.state
    let { id, fullName, email, status, phone } = this.state.record
    const { translate } = this.props
    let { messageTypeId, recipient } = record
    if (record.messageTypeId === 'SMS' || record.messageTypeId === 'WHATSAPP') {
      const specialRegex = /([() .-])/g
      let phoneMatch = record.recipient.replace(specialRegex, '')
      if (!validPhoneNumber(phoneMatch)) {
        let errors = {}
        errors.recipient = translate('error_messages.invalid.users.phone')
        this.setState({
          errors
        })
        return false
      }
      recipient = phoneMatch.replace(phoneMatch[0], '+84')
    }

    let newUserContacts = [...userContacts]
    let newUserContact = {
      ...userContact,
      messageTypeId,
      recipient,
      userSubscriptions: userContact.userSubscriptions
    }
    
    this.props.change('userContact', 'messageTypeId', messageTypeId)
    this.props.change('userContact', 'recipient', recipient)
    
    showNotification('resources.users.message.save_userContact_success')
    if (action === 'create') {
      newUserContacts.unshift(newUserContact)
    } else {
      newUserContacts.splice(userContactIdx, 1, newUserContact)
    }
    this.setState({
      userContacts: newUserContacts,
      userContact: newUserContact,
      open: false,
      errors: {},
    })
    let data = { fullName, email, status, phone, userContacts: newUserContacts }
    Provider.dataProvider('REMOTE', 'users', {
      method: id,
      requestMethod: 'PATCH',
      data,
    })
      .then(() => {
        showNotification('notification.update_user_success')
        refreshView()
      }
      )
      .catch(() => showNotification('notification.update_user_failure', 'warning'))
  }

  validateUserContact = () => {
    let { translate } = this.props
    let { userContact, errors } = this.state
    let newErrors = { ...errors }
    if (userContact.userSubscriptions.length === 0) {
      newErrors.userSubscriptions = translate('error_messages.required.userContacts.userSubscriptions')
      this.setState({
        errors: newErrors
      })
      return false
    }
    return true
  }

  render() {
    let {
      classes,
      permissions,
      location,
      form,
      translate,
      id,
      ...props
    } = this.props
    let {
      action,
      userContacts,
      userContactIdx,
      errors,
      open,
      record,
      eventTypes,
      userContact,
    } = this.state

    const extra = {
      resource: 'users',
      fullWidth: true,
    }
    let admin = isAdmin(permissions)

    const CardIcons = {
      'SMS': '/images/message/sms.png',
      'WHATSAPP': '/images/message/whatsapp.png',
      'SLACK': '/images/message/slack.png',
      'TELEGRAM': '/images/message/telegram.png',
    }

    return (
      <SimpleForm
        {...sanitize(props, ['refreshView', 'showNotification'])}
        form={form}
        record={record}
        toolbar={<CustomToolbar />}
        save={this.save}
        validate={validatePhone}
      >
        <Grid
          container
          className={classes.root}
        >
          <Grid item xs={12} md={6}>
            <TextInput disabled source="username" {...extra} />
            <TextInput source="fullName" {...extra} />
            <TextInput source="email" {...extra} />
            <TextInput source="phone" {...extra} />
            <ReferenceInput
              reference="statuses"
              source="status"
              disabled={!admin}
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            {admin && <RoleChips source="roleMappings" id={id} />}
            <UserContactDrawer
              action={action}
              onEdit={this.onEdit}
              open={open}
              onClose={this.onClose}
              eventTypes={eventTypes}
              admin={admin}
              errors={errors}
              userContacts={userContacts}
              userContactIdx={userContactIdx}
              userContact={userContact}
              removeUserSub={this.removeUserSub}
              getUserContacts={this.getUserContacts}
              addUserSub={this.addUserSub}
            />
          </Grid>
          {admin && <Grid item md={6} xs={12}>
            <RefCard record={record} />
          </Grid>}

          {!admin && <Grid xs={12} md={6} style={{ paddingLeft: '32px' }}>
            <div className={classes.userContactTitle}>
              <Typography style={{ fontSize: '1.5rem', fontWeight: '400' }}>
                {translate('resources.users.fields.userContacts')}
              </Typography>
              <Button color='primary'
                onClick={this.onOpen}
              >
                <span>
                  <FontAwesomeIcon fontSize="small" icon={faPlus} style={{ marginRight: '5px' }} />
                  {translate('button.create')}
                </span>
              </Button>
            </div>
            {userContacts && userContacts.map((userContact, index) => (
              <Card key={index} className={classes.root}>
                <CardHeader
                  avatar={
                    <Avatar src={CardIcons[userContact.messageTypeId]} className={classes.avatarSize} />
                  }
                  title={
                    <span>
                      {userContact.messageTypeId}
                    </span>
                  }
                  subheader={userContact.recipient}
                />
                <CardContent className={classes.recipientBorder}>
                  {userContact.userSubscriptions.map((userSubscription, idx) => <Chip
                    key={idx}
                    style={{ marginRight: '5px' }}
                    label={eventTypes[userSubscription.eventTypeId].name}
                    color="primary" />
                  )}
                </CardContent>
                <CardActions disableSpacing>
                  <Button onClick={() => this.onEditUserContact(userContact, index)}>
                    <span className={classes.buttonFlex}>
                      <EditIcon
                        style={{ marginRight: '5px' }}
                        color='primary'
                        fontSize="small"
                      />
                      {translate('button.edit')}
                    </span>
                  </Button>
                  <Button onClick={() => this.removeUserContact(index)}>
                    <span className={classes.buttonFlex}>
                      <FontAwesomeIcon
                        color='#f50057'
                        icon={faTimes}
                        size="lg"
                        style={{ marginRight: '5px' }}
                      />
                      {translate('button.remove')}
                    </span>
                  </Button>
                </CardActions>
              </Card>
            )
            )
            }
          </Grid>}
        </Grid>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  withStyles(style),
  connect(null, { change, reset, showNotification, refreshView }),
  translate
)

export default enhance(FormSave)
