import React, { Component } from 'react'
import { Chip } from '@material-ui/core'
import { format } from './data-type'

class ChipArrayField extends Component {
  render() {
    const { datum, type, classes = {}, ...props } = this.props
    return datum.map((data, idx) => {
      data = format(data, type)
      return <Chip className={classes.chip} key={idx} label={data ? data : '-'} {...props} />
    })
  }
}

ChipArrayField.defaultProps = {
  datum: [],
}

export default ChipArrayField