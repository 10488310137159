import React, { Component } from 'react'
import { Provider } from '../provider'
import isEmpty from 'lodash/isEmpty'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faBus } from '@fortawesome/free-solid-svg-icons'

const getFirstEle = (array) => {
  if (!array) return ''
  let first = array[0] || {}
  return first
}

const getOptionText = (data, principalTypeObj, optionText) => {
  let { relation, doAction } = principalTypeObj
  let rel = data && doAction(data[relation])
  return rel ? rel[optionText] : ''
}

export const ServiceGroupMapping = {
  LICENSE: {
    principalResource: 'licences',
    fieldShow: 'licenceNumber',
    relation: 'licenceValidity',
    getOptionText: getOptionText,
    doAction: getFirstEle,
    principal: 'Licence',
    hasExtras: true,
    extrasFieldShow: 'validTo',
    icon: <FontAwesomeIcon icon={faFileAlt} />,
    category: 'licencetypes',
    extrasFilter: {
      '../include': {
        relation: 'licenceValidity',
        scope: {
          where: { status: { neq: '40ARC' } }
        }
      }
    }
  },
  VEHICLE: {
    principalResource: 'maintaingroups',
    fieldShow: 'name',
    principal: 'MaintainGroup',
    hasExtras: false,
    icon: <FontAwesomeIcon icon={faBus} />,
  }
}

const PrincipalMapping = {
  'Licence': {
    resource: 'licences',
    extrasFilter: {
      include: {
        relation: 'licenceValidity',
        scope: {
          where: { status: { neq: '40ARC' } }
        }
      }
    }
  },
  'MaintainGroup': {
    resource: 'maintaingroups',
  }
}


class PrincipalField extends Component {

  constructor(props) {
    super(props)
    this.state = {
      data: {},
      pType: {}
    }
  }

  async componentDidMount() {
    let { serviceReminder, principal, pType: prevPtype } = this.props
    let { data, pType } = this.state
    let { serviceTask = {} } = serviceReminder
    let { principalId, principalType, serviceGroup = {} } = serviceTask
    let serviceGroupCode = serviceGroup.code
    if (!prevPtype) {
      pType = ServiceGroupMapping[serviceGroupCode] || {}
    } else {
      pType = prevPtype
    }
    if (!principal) {
      let { resource, extrasFilter } = PrincipalMapping[principalType]
      let params = { id: principalId }
      if (extrasFilter) {
        params = {...params, filter: extrasFilter}
      }
      let res = await Provider.dataProvider('GET_ONE', resource, params)
      if (res && res.data) {
        data = res.data
      }
    } else {
      data = principal
    }
    this.setState({ data, pType })
  }

  render() {
    let { renderChild } = this.props
    let { data, pType } = this.state
    return (!isEmpty(data) && !isEmpty(pType)) ? renderChild(data, pType) : ''
  }
}

export default PrincipalField