import React, { Component } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'
import { Provider } from '../provider'
import FileSaver from 'file-saver'
import moment from 'moment'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import GetAppIcon from '@material-ui/icons/GetApp'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  },
  colorPrimary: {
    color: '#3f51b5'
  },
  iconExport: {
    paddingTop: 10,
    size: 37,
  },
  text: {
    marginBottom: 20,
    paddingBottom: 5,
  }
}

class ExportAccountCreditButton extends Component {

  export = () => {
    let { filterValues, prefix = 'accountcredits' } = this.props
    let { createdAt_gte, createdAt_lte } = filterValues

    createdAt_gte = createdAt_gte || moment().startOf('days').toDate().toISOString()
    createdAt_lte = createdAt_lte || moment().endOf('days').toDate().toISOString()

    let filter = {
      where: {
        and: [
          {createdAt: { gte: createdAt_gte }},
          {createdAt: { lte: createdAt_lte }},
        ],
      }
    }

    Provider.dataProvider('REMOTE', 'accountcredits', {
      method: 'export',
      requestMethod: 'GET',
      data: { filter }
    }).then(
      resp => {
        let data = resp.data
        let filename = `${prefix}_${moment(new Date()).format('YYYMMDDhhmmss')}.csv`
        FileSaver.saveAs(new Blob([data], { type: ' text/plain' }), filename)
      }
    )
  }

  renderComponent = () => {
    let { classes, disabled, translate, button, color, label, type = 'button', ...props } = this.props
    let style = { color: !disabled && '#3f51b5' }

    switch (type) {
      case 'button':
        return <Button
          color={color}
          onClick={this.export}
          className={classes.button}
          disabled={disabled}
          {...props}
        >
          <GetAppIcon />
          <span>{translate(label)}</span>
        </Button>
      case 'menuItem':
        return <MenuItem
          onClick={this.export}
          disabled={disabled}
          color={color}
          style={style}
          {...props}
        >
          <ListItemIcon style={style}>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          <span>{translate(label)}</span>
        </MenuItem>
      default:
        return <Tooltip
          title={translate(label)}
          enterDelay={100}
        >
          <LinkField
            className={classes.iconButton}
            icon
            color={color}
            onClick={this.export}
            disabled={disabled}
            {...props}
          >
            <GetAppIcon fontSize="small" />
          </LinkField>
        </Tooltip>
    }
  }

  render() {
    return this.renderComponent()
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(ExportAccountCreditButton)
