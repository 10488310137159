import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Popover,
  IconButton,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { 
  showNotification,
  translate,
} from 'react-admin'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import FormatColorFillIcon from '@material-ui/icons/FormatColorFill'
import { SwatchesPicker } from 'react-color'

class _MenuTemplate extends Component {

  state = {}

  render() {
    let { 
      open,
      onClose,
      anchorEl,
      group,
      selected,
      onChange,
    } = this.props
    return <Popover
      id="menu-over-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      <SwatchesPicker
        onChangeComplete={(color) => {
          onChange(color.hex)
        }}
      />
    </Popover>
  }
}

const MenuTemplate = compose(
  translate,
  connect(null, { showNotification, reset })
)(_MenuTemplate)

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
    },
    btn: {
      padding: 5,
      width: 24,
      height: 24
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336'
    },
  })
}

class ColorButton extends Component {

  state = {
    open: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { value: currentValue } = prevState
    const { color: value } = nextProps
    if (!currentValue && value && currentValue !== value) {
      return { ...prevState, value }
    }
    return { ...prevState }
  }

  //getSnapshotBeforeUpdate(prevProps) {
  //let { color } = this.props
  //let { color: prevColor } = prevProps
  //const updated = color !== prevColor
  //return { updated }
  //}

  //async componentDidUpdate(prevProps, prevState, snapshot) {
  //if (snapshot.updated) {
  //const { color } = this.props
  //this.setState({ value: color })
  //}
  //}

  handleOpenMenu = evt => {
    this.setState({
      open: true,
      anchorEl: evt.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  handleChange = newValue => {
    let { setColor } = this.props
    this.setState({ value: newValue })
    this.handleClose()
    setColor(newValue)
  }

  render() {
    let { classes, translate, group } = this.props
    let { value, anchorEl, open } = this.state
    return <Fragment>
      <IconButton
        size="small"
        color={value}
        className={classes.btn}
        onClick={this.handleOpenMenu}
      >
        <FormatColorFillIcon fontSize="inherit" style={{ color: value }} />
      </IconButton>
      {open && <MenuTemplate
        open={open}
        anchorEl={anchorEl}
        group={group}
        selected={value}
        onClose={this.handleClose}
        onChange={this.handleChange}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification, reset }),
)

export default enhance(ColorButton)
