import React, { Component } from 'react'
import {
  Tooltip,
  withStyles,
  Button,
} from '@material-ui/core'
import { Provider } from '../provider'
import FileSaver from 'file-saver'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { writeXlsx } from '../utils/xlsx'
import moment from 'moment'
import slugify from 'voca/slugify'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17
  }
}

class ExportPlatformDebitButton extends Component {

  export = () => {
    let { filter, name } = this.props
    let fileName = `${slugify(name)}-${moment(new Date()).format('YYYMMDDhhmmss')}.xlsx`
    Provider.dataProvider('REMOTE', 'reconciliations', {
      method: 'export',
      requestMethod: 'POST',
      data: { filter }
    }).then(
      res => {
        let { sheetname, data } = res.data
        data = writeXlsx(data, sheetname)
        FileSaver.saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), fileName)
      }
    )
  }

  render() {
    let { translate, disabled, classes, color, button, ...props } = this.props
    return button ? <Button
      color={color}
      onClick={this.export}
      className={classes.button}
      {...props}
      disabled={disabled}
    >
      <span>
        <FontAwesomeIcon className={classes.icon} icon={faFileDownload} />
        <span>{translate('button.export')}</span>
      </span>
    </Button> : <LinkField
      className={classes.iconButton}
      icon
      color={color}
      onClick={this.export}
      disabled={disabled}
    >
      <Tooltip title={translate('button.export')} enterDelay={100} >
        <FontAwesomeIcon icon={faFileDownload} size="xs" />
      </Tooltip>
    </LinkField>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(ExportPlatformDebitButton)
