import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  withStyles,
  Link,
} from '@material-ui/core'
import Breadcrumbs from '@material-ui/lab/Breadcrumbs'
import { Link as RouterLink, withRouter } from 'react-router-dom'
import { translate } from 'react-admin'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import compose from 'recompose/compose'
import { connect } from 'react-redux'

const styles = theme => ({
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '8px 8px 4px 8px',
    },
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '1em',
  },
})

class RouterBreadcrumbs extends Component {
    state = {}

    static getDerivedStateFromProps(nextProps, prevState = {}) {
      let { location, translate, locale } = nextProps
      if (!prevState.location || (location && location.key !== prevState.location.key) || (locale !== prevState.locale)) {
        const pathnames = location.pathname.split('/').filter(x => x)
        let paths = pathnames.map((label, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`
          if (index === 0) {
            label = translate(`resources.${label}.name`, { smart_count: 2 })
          }
          return { label, to }
        })

        return ({
          ...prevState,
          location,
          paths,
          locale,
        })
      } else {
        if (nextProps.paths) {
          return ({
            ...prevState,
            location,
            paths: nextProps.paths,
            locale: nextProps.locale,
          })
        }
      }
    }

    render() {
      const { classes } = this.props
      const { paths = [] } = this.state

      return (
        <div className={classes.root}>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="Breadcrumb">
            <Link component={RouterLink} color="inherit" to="/">Home</Link>
            {paths.map(({ label, to }, index) => {
              const last = index === paths.length - 1
              return last ? (
                <Typography color="textPrimary" key={to}>
                  {label}
                </Typography>
              ) : (
                <Link component={RouterLink} color="inherit" to={to} key={to}>
                  {label}
                </Link>
              )
            })}
          </Breadcrumbs>
        </div>
      )
    }
}

RouterBreadcrumbs.propTypes = {
  classes: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  if (state.breadcrumbs && state.breadcrumbs.paths) {
    return { paths: state.breadcrumbs.paths }
  } else {
    return {}
  }
}

const enhance = compose(
  connect(
    mapStateToProps,
    {}, // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles),
  translate,
)

export default enhance(RouterBreadcrumbs)

