import React from 'react'
import _ from 'lodash'
import { deepOrange } from '@material-ui/core/colors'
import { Tooltip, Chip, withStyles } from '@material-ui/core'

export const NO_PAID = '00NOT_PAID'
export const PAID = '10PAID'
export const CANCEL = '20CANCEL'
export const FINISH = '30FINISH'
export const OTHER = '40OTHER'
export const PRE_PAID = '50PREPAID'

export const color = {
  [NO_PAID]: '#FFB900',
  [PAID]: '#429488',
  [CANCEL]: '#F80435',
  [OTHER]: '#9e9e9e',
  [PRE_PAID]: deepOrange[500],
}

const chargeStatusTranslate = {
  [NO_PAID]: 'Chưa thanh toán',
  [PAID]: 'Đã thanh toán',
  [FINISH]: 'Đã hoàn thành',
  [CANCEL]: 'Đã huỷ',
  [PRE_PAID]: 'Đã dat coc',
}

export const paidGroup = { [PAID]: 1 }

export const notPaidGroup = { [NO_PAID]: 1, [PRE_PAID]: 1 }

export function isPrePaid(paid, total) {
  return paid !== 0 && paid < total
}
const styles = {
  chip: {
    color: 'white',
    marginRight: 4,
  },
  moreChip: {
    backgroundColor: '#9e9e9e',
    color: 'white',
    marginRight: 4,
    width: 60,
    fontSize: '15px'
  },
  tooltipMoreChip: {
    paddingLeft: 0,
    '& > li': {
      fontSize: '10px',
      marginBottom: '4px',
    }
  },
  titleMoreChip :{
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
    width: '60px',
    marginBottom: '4px',
  }
}

export const StatusField = withStyles(styles)(({ classes, itemDetail = {}, status, isPrePaid }) => {
  if (isPrePaid) {
    status = PRE_PAID
  }
  return <Tooltip
    title={chargeStatusTranslate[status]} >
    {
      itemDetail && itemDetail.itemCode ?
        <Chip 
          label={itemDetail.itemCode}
          className={classes.chip}
          style={{backgroundColor: color[status], width: itemDetail.itemCode && 60}}
        /> : 
        <Chip 
          label={chargeStatusTranslate[status]} 
          className={classes.chip} 
          style={{backgroundColor: color[status]}}
        />
    }
  </Tooltip>
})

export const StatusFieldMoreChip = withStyles(styles)(({ classes, charges, busType }) =>{
  let title
  if (busType) {
    title = charges.slice(1).map((ele, i) => 
      <Chip 
        key={i}
        label={ _.get(ele, 'itemDetail.itemCode', '') ?
          ele.itemDetail.itemCode : 
          chargeStatusTranslate[ele.status]} 
        style={{backgroundColor: color[ele.status]}}
        className={classes.titleMoreChip}
      /> ) 
  } else {
    let fareTypes = _.groupBy(charges, (v) => v && v.fare && v.fare.name)
    title =  Object.keys(fareTypes).map((key, idx) => {
      return <li key={idx} >{`${key} : ${fareTypes[key].length}`}</li>
    })
  }
  return <Tooltip
    title={<ul className={classes.tooltipMoreChip} >{title}</ul>}
  >
    <Chip label='...' className={classes.moreChip} />
  </Tooltip>
})
