import React, { Component, Fragment } from 'react'
import {
  TextInput,
  ReferenceInput,
  SimpleForm,
  showNotification,
  SelectInput,
  refreshView,
  ReferenceManyField,
  translate,
  Toolbar,
  required,
  SaveButton
} from 'react-admin'
import {
  Grid,
  withStyles,
  Chip,
  Avatar,
  Dialog,
  DialogContent,
  Button
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
const style = () => ({
  addEventButton: {
    marginBottom: '5px'
  },
  gridFlex: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%'
  },
  errors: {
    color: 'red',
    margin: 0,
    marginBottom: '10px',
    fontSize: '14px'
  }
})

const EventTypes = ({ data, addEventType, userContact, userContactIdx, ids }) => {
  let eventTypeIds = []
  if (!_.isEmpty(userContact) && !_.isEmpty(userContact.userSubscriptions)) {
    eventTypeIds = userContact.userSubscriptions.map(userSubs => userSubs.eventTypeId)
  }
  let eventFilter = _.difference(ids, eventTypeIds)
  return (
    <div>
      {
        !_.isEmpty(eventFilter) && eventFilter.map((eventType, index) =>
          <Chip
            key={index}
            label={data[eventType].name}
            avatar={<Avatar><SubscriptionsIcon /></Avatar>}
            onClick={() => addEventType(data[eventType].id, userContactIdx)}
            style={{ marginRight: '5px', marginBottom: '5px' }}
          />
        )
      }
    </div>
  )
}

const EventTypeDialog = (({
  open,
  onClose,
  addEventType,
  userContacts,
  userContactIdx,
  userContact
}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
    >
      <DialogContent>
        <ReferenceManyField
          reference="eventtypes"
          basePath="/eventtypes"
          target=""
          record={{}}
          source="eventTypeId"
        >
          <EventTypes
            userContact={userContact}
            userContacts={userContacts}
            userContactIdx={userContactIdx}
            addEventType={addEventType}
          />
        </ReferenceManyField>
      </DialogContent>
    </Dialog>
  )
})

const CustomToolBar = props => (
  <Toolbar {...props}>
    <SaveButton />
    <Button onClick={props.onClose} variant="contained" style={{ marginLeft: '10px', backgroundColor: '#f50057', padding: '8px 16px' }}>
      <span style={{ color: '#fff' }}>
        <FontAwesomeIcon size="lg" icon={faTimes} style={{ marginRight: '5px' }} />
        {props.translate('button.cancel')}
      </span>
    </Button>
  </Toolbar>
)

class UserContact extends Component {
  state = {
    open: false,
  }

  onOpen = () => {
    this.setState({ open: true })
  }

  onClose = () => {
    this.setState({ open: false })
  }

  addEventType = (eventTypeValue, eventTypeIdx) => {
    this.setState({ open: false })
    this.props.addUserSub(eventTypeValue, eventTypeIdx)
  }

  render() {
    const {
      userContacts,
      eventTypes,
      translate,
      classes,
      admin,
      removeUserSub,
      userContact,
      userContactIdx,
      onClose,
      errors,
      onEdit,
    } = this.props
    const { open } = this.state
    return (
      <Fragment>
        {
          !_.isEmpty(userContact) && (
            <SimpleForm
              record={userContact}
              form='userContact'
              toolbar={<CustomToolBar onClose={onClose} translate={translate} />}
              fullWidth
              save={onEdit}
            >
              <Grid
                fullWidth
                className={classes.gridFlex}
              >
                <Grid xs={12} md={12} style={{ height: '80vh' }}>
                  <ReferenceInput
                    fullWidth
                    reference="messagetypes"
                    source="messageTypeId"
                    resource="messagetypes"
                    validate={required()}
                    record={{}}
                    disabled={admin}
                  >
                    <SelectInput
                      optionText="name"
                      disabled={admin}
                    />
                  </ReferenceInput>
                  <TextInput
                    source="recipient"
                    validate={required()}
                    fullWidth
                    resource="recipient"
                    disabled={admin}
                  />
                  {errors && errors.recipient && <p className={classes.errors}>{errors.recipient}</p>}
                  <div>
                    {
                      userContact
                      && !_.isEmpty(userContact.userSubscriptions)
                      && userContact.userSubscriptions.map((userSubscription, index) => {
                        return (
                          <Chip
                            key={index}
                            label={eventTypes[userSubscription.eventTypeId].name}
                            avatar={<Avatar><SubscriptionsIcon /></Avatar>}
                            onDelete={() => removeUserSub(userSubscription.eventTypeId)}
                            style={{ marginRight: '10px', marginBottom: '5px' }}
                            disabled={admin}
                          />
                        )
                      }
                      )
                    }
                    <Chip
                      className={classes.addEventButton}
                      avatar={<Avatar><FontAwesomeIcon icon={faPlus} /></Avatar>}
                      onClick={() => this.onOpen()}
                      label={translate('button.addEventTypes')}
                      disabled={admin}
                    >
                    </Chip>
                    {errors && errors.userSubscriptions && <p className={classes.errors}>{errors.userSubscriptions}</p>}
                  </div>
                </Grid>
              </Grid>
            </SimpleForm>
          )
        }

        <EventTypeDialog
          userContact={userContact}
          open={open}
          onClose={this.onClose}
          addEventType={this.addEventType}
          userContacts={userContacts}
          userContactIdx={userContactIdx}
        />
      </Fragment>
    )
  }
}

const enhance = compose(
  withStyles(style),
  connect(null, { showNotification, refreshView }),
  translate
)

export default enhance(UserContact)
