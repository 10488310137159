import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  Dialog,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { Provider } from '../provider'
import {
  translate,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  refreshView,
  showNotification,
  TextInput,
} from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { MoneyInput } from '../common/MoneyInput'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'
import { formatCurrency } from '../utils/formatUtil'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  }
}

const AccountMoreInfo = translate(({ platformDeposit, translate }) => {
  return platformDeposit ? <i>
    {translate('resources.financeaccounts.helperPlatformDeposit')}&nbsp;
    <b>{formatCurrency(platformDeposit)}</b>
  </i> : <i>
    {translate('resources.financeaccounts.not_platformDeposit')}
  </i>
})

class _PlatformTopUpForm extends Component {

  state = {}

  render() {
    let {
      open,
      companyId,
      companyAccountId,
      onClose,
      topUp,
      translate,
      platformDeposit,
    } = this.props
    let extra = { resource: 'financeaccounts', fullWidth: true }
    return <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Typography variant="h4">{translate('resources.financeaccounts.depositPlatformTitle')}</Typography>
      </DialogTitle>
      <Divider />
      <SimpleForm
        resource="financeaccounts"
        form="platform-topup-form"
        record={{ topUpType: 'FOR_PLATFORM' }}
        save={record => topUp(record)}
      >
        <ReferenceInput
          record={{}}
          perPage={1000}
          reference="financeaccounts"
          filter={{ '../mappingCondition': { type: 'COM', refId: companyId }}}
          source="companyAccountId"
          defaultValue={companyAccountId}
          {...extra}
        >
          <SelectInput optionValue="id" optionText="accountName" />
        </ReferenceInput>
        {companyAccountId && <AccountMoreInfo platformDeposit={platformDeposit} />}
        <MoneyInput
          autoFocus
          source="topUpValue"
          label="resources.financeaccounts.fields.topUpValue"
        />
        <TextInput source="description" {...extra} />
        <TextInput 
          source="invoiceNumber"
          {...extra}
        />
        <ReferenceInput
          record={{}}
          reference="platformdebitstatuses"
          source="platformDebitStatus"
          defaultValue="00NTY"
          {...extra}
        >
          <SelectInput optionValue="id" optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          source="topUpMethod"
          label="resources.reservations.payment.method"
          reference="paymentmethod2s"
          resource="reservations"
          filter={{ id: { lt: '20' }}}
          defaultValue="00CASH"
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            return formData.topUpMethod === '10BANK' && <Fragment>
              <TextInput
                source="journalNumber"
                {...extra}
              />
            </Fragment>
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Dialog> 
  }
}

const enhanceWithdrawForm = compose(translate, connect(null, { changeForm }))
const PlatformTopUpForm = enhanceWithdrawForm(_PlatformTopUpForm)

class PlatformTopUpButton extends Component {

  state = {
    open: false
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  onClose = () => {
    let { reset } = this.props
    this.setState({
      open: false
    }, () => { reset('platform-topup-form')})
  }

  topUp = record => {
    let { refreshView, showNotification } = this.props
    Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: 'topUp',
      requestMethod: 'POST',
      data: { ...record, topUpValue: parseFloat(record.topUpValue) }
    }).then(
      () => {
        this.onClose()
        refreshView()
        showNotification('notification.topUp_success')
      }
    ).catch(
      () => {
        this.onClose()
        showNotification('notification.topUp_failure', 'warning')
      }
    )
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      isAdmin,
      companyId,
      companyAccountId,
      platformDeposit,
    } = this.props
    let { open } = this.state
    return <Fragment>
      {button ? <Button
        color={color}
        onClick={this.open}
      >
        <div className={classes.container}>
          <AccountBalanceWalletIcon className={classes.icon} />
          <span>{translate('button.topUp')}</span>
        </div>
      </Button> : <LinkField
        className={classes.iconButton}
        icon
        color={color}
        onClick={this.open}
      >
        <Tooltip title={translate('button.topUp')} enterDelay={100} >
          <AccountBalanceWalletIcon />
        </Tooltip>
      </LinkField>}
      <PlatformTopUpForm
        open={open}
        onClose={this.onClose}
        topUp={this.topUp}
        companyId={companyId}
        companyAccountId={companyAccountId}
        isAdmin={isAdmin}
        platformDeposit={platformDeposit}
      />
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { refreshView, showNotification, reset, changeForm })
)

export default enhance(PlatformTopUpButton)
