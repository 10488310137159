import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ResetPasswordIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" id="svg_2" d="m18.39884,8.12624l-1.04939,0l0,-2.09878c0,-2.90331 -2.34363,-5.24694 -5.24694,-5.24694s-5.24694,2.34363 -5.24694,5.24694l0,2.09878l-1.04939,0c-1.15433,0 -2.09878,0.94445 -2.09878,2.09878l0,10.49388c0,1.15433 0.94445,2.09878 2.09878,2.09878l12.59265,0c1.15433,0 2.09878,-0.94445 2.09878,-2.09878l0,-10.49388c0,-1.15433 -0.94445,-2.09878 -2.09878,-2.09878zm-9.54943,-2.09878c0,-1.78396 1.46914,-3.2531 3.2531,-3.2531s3.2531,1.46914 3.2531,3.2531l0,2.09878l-6.5062,0l0,-2.09878zm-2.58849,9.33955l1.57408,0c0,-2.34363 1.8889,-4.23253 4.23253,-4.23253c0.83951,0 1.60906,0.24486 2.23869,0.66461l-0.76955,0.76955c-0.45473,-0.24486 -0.94445,-0.38478 -1.46914,-0.38478c-1.74898,0 -3.18314,1.43416 -3.18314,3.18314l1.57408,0l-2.13376,2.13376l-2.0638,-2.13376zm10.07412,0c0,2.34363 -1.8889,4.23253 -4.23253,4.23253c-0.83951,0 -1.60906,-0.24486 -2.23869,-0.66461l0.76955,-0.76955c0.45473,0.24486 0.94445,0.38478 1.46914,0.38478c1.74898,0 3.18314,-1.43416 3.18314,-3.18314l-1.57408,0l2.13376,-2.13376l2.13376,2.13376l-1.64404,0z"/>
  </SvgIcon>
)

export default ResetPasswordIcon
