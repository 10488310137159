export const RESOURCE_REQUEST = 'RESOURCE_REQUEST'
export const RESOURCE_REQUEST_SUCCESS = 'RESOURCE_REQUEST_SUCCESS'
export const RESOURCE_REQUEST_FAILURE = 'RESOURCE_REQUEST_FAILURE'

/**
 * @example
 * const enhance = compose(
 *   connect(null, { resoureRequest }), 
 * )
 * let { resoureRequest } = this.props
 * let params = {
 *  resource: 'stops',
 *  fetch: 'GET_MANY',
 *  payload: {
 *   ids: stopIds
 *  },
 *  done: (data) => {
 *  }
 * }
 * resourceRequest(params)
 */
export const resourceRequest = (params) => {
  //payload = { method, requestMethod, data... }
  let { resource, fetch = 'REMOTE', payload, start, done, fail } = params  
  return ({
    type: RESOURCE_REQUEST,
    payload,
    meta: {
      resource,
      fetch,
    },
    start,
    done,
    fail,
  })
}

/**
 * @example
 * let { resourceRequest } = this.props
 * await invokeAction(resourceRequest, params)
 */
export const invokeAction = (action, params) => {
  return new Promise((resolve, reject) => {
    action({
      ...params,
      done: (resp) => {
        resolve(resp)
      },
      fail: (resp) => {
        reject(resp)
      }
    })
  })
} 