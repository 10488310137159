import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  FormLabel,
  FormControl,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { 
  showNotification,
  translate,
  SelectInput,
  addField,
  SimpleForm,
  NumberInput,
  TextInput,
  FormDataConsumer,
  required,
} from 'react-admin'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'
import ChipArrayField from '../common/ChipArrayField'
import _ from 'lodash'
import { Provider } from '../provider'
import { VNIS, EInvoiceProviderMapping } from '../common/constants'
import CheckboxInput from '../common/CheckboxInput'

const EINVOICE_SETTING_FORM = 'einvoice-setting-form'

export const EXPIRED = 'expire'
export const START_TIME_TYPE = 'startTimeType'
export const DURATION = 'duration'

export const labelMapping = {
  [EXPIRED]: 'resources.common.product_validate_type.expired',
  [START_TIME_TYPE]: 'resources.common.product_validate_type.start_time_type',
  [DURATION]: 'resources.common.product_validate_type.duration',
}

const BUY_TIME = 'buyTime'
const FIRST_USE = 'firstUse'
const INPUT = 'input'

export const labelStartTimeTypeMapping = {
  [BUY_TIME]: 'resources.common.start_time_type.buy_time',
  [FIRST_USE]: 'resources.common.start_time_type.first_use',
  [INPUT]: 'resources.common.start_time_type.input',
}

const dialogStyles = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  content: {
    padding: 0,
  },
  contentText: {
    paddingLeft: 16,
  }
}

const validationStyles = {
  checkbox: {
    display: 'flex'
  },
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  content: {
    padding: 0,
  },
  contentText: {
    paddingLeft: 16,
  }
}

class _SettingFormDialog extends Component {

  state = {}

  componentDidMount() {
    let { record = {} } = this.props
    let { provider } = record
    this.getEInvoiceProviders()
      .then(res => {
        this.setState({ providers: res })
      })
      .catch(err => {
        console.log('===getEInvoiceProviders err... ', err)
      })
    this.setState({ provider })
  }

  getEInvoiceProviders = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'einvoiceproviders', {
      filter: {
        '../fields': ['id', 'name', 'code']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  save = data => {
    const { setValue, onClose } = this.props
    setValue(data)
    onClose()
  }

  renderInputByProvider = (provider = VNIS) => {
    let { changeForm, translate } = this.props
    let { provider: currentProvider } = this.state
    if (provider !== currentProvider) {
      this.setState({ provider })
      changeForm(EINVOICE_SETTING_FORM, 'provider', provider)
    }
    return provider === VNIS ? <Fragment>
      <NumberInput
        label={translate('resources.einvoicesettings.fields.templateNo')}
        resource="einvoicesettings"
        source="setting.templateNo"
        fullWidth
      />
      <TextInput
        label={translate('resources.einvoicesettings.fields.serialNo')}
        resource="einvoicesettings"
        source="setting.serialNo"
        fullWidth
      />
    </Fragment> : null
  }

  render() {
    let { 
      classes,
      open,
      onClose,
      translate,
      record = {},
    } = this.props
    let { providers } = this.state
    return <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>{translate('resources.validationproducts.configuration_title')}</span>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleForm
          resource="einvoicesettings"
          enableReinitialize={true}
          keepDirtyOnReinitialize={false}
          form={EINVOICE_SETTING_FORM}
          record={record}
          save={this.save}
        >

          <SelectInput
            resource="einvoicesettings"
            choices={providers}
            source="provider"
            validate={required()}
            optionText="name"
            optionValue="code"
            fullWidth
          />
          <CheckboxInput
            resource="einvoicesettings"
            source="allow"
            label={translate('resources.einvoicesettings.fields.allow')}
          />
          <FormDataConsumer>
            {({ formData = {} }) => {
              let { provider } = formData
              return provider ? this.renderInputByProvider(provider) : null
            }}
          </FormDataConsumer>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }
}

const SettingFormDialog = compose(
  withStyles(dialogStyles),
  translate,
  connect(null, { showNotification, changeForm })
)(_SettingFormDialog)

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
      flex: 1,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336'
    },
    fieldSet: {
      marginTop: 24,
      border: '1px solid #3f51b5',
      borderRadius: 4,
    },
    legend: {
      marginLeft: 16,
      color: '#3f51b5',
      padding: '0px 4px'
    },
    chipContainer: {
      display: 'flex',
      padding: '16px 4px',
      boxShadow: 'none',
      flexWrap: 'wrap',
    },
    noConfig: {
      fontSize: 13,
      fontStyle: 'italic',
      marginLeft: 16,
      color: '#424242',
    }
  })
}

class EInvoiceSetting extends Component {

  state = {
    open: false,
  }

  async componentDidMount() {
    let { input, setting } = this.props
    setting = !_.isEmpty(setting) ? setting : {}
    let { value: inputValue } = input
    let record = inputValue || setting
    this.setState({ record })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { input } = nextProps
    let { value: record } = input
    let { record: currentRecord } = prevState 
    if (record !== currentRecord) {
      return { ...prevState, record }
    }
    return { ...prevState }
  }

  handleEdit = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    const { reset } = this.props
    this.setState({
      open: false,
    })
    reset(EINVOICE_SETTING_FORM)
  }

  setValue = record => {
    let { input } = this.props
    this.setState({ record })
    input.onChange(record)
  }

  getLabels = () => {
    let { translate } = this.props
    let { record } = this.state
    if (!_.isEmpty(record)) {
      let { provider, setting, allow } = record
      const providerName = EInvoiceProviderMapping[provider]
      const allowText = allow ? 'resources.einvoicesettings.fields.allow' : 'resources.einvoicesettings.fields.not_allow'
      let labels = _.reduce(setting, (result, value, key) => {
        let suffix
        if (value) {
          let prefix = key
          if (value) {
            suffix = value
          } else {
            suffix = translate('resources.common.no_config')
          }
          let validationValue = `${translate(`resources.einvoicesettings.fields.${prefix}`)}: ${suffix}`
          result.push(validationValue)
        }
        return result
      }, [`${translate('resources.einvoicesettings.fields.provider')}: ${providerName}`, `${translate(allowText)}`])
      return { labels }
    }
  }

  render() {
    let { classes, translate, nonEdit } = this.props
    let { open, record } = this.state
    let { labels } = this.getLabels() || {}
    return <Fragment>
      <FormControl
        component="fieldset"
        variant="outlined"
        fullWidth
        className={classes.fieldSet}
        onClick={() => { 
          if (nonEdit) return
          this.handleEdit()
        }}
      >
        <FormLabel
          component="legend"
          color="primary"
          className={classes.legend}
        >
          {translate('resources.einvoicesettings.configuration_title')}
        </FormLabel>
        <Paper className={classes.chipContainer}>
          {labels ? <ChipArrayField
            color="primary"
            datum={labels}
            variant="outlined"
            className={classes.chip}
          /> : <span className={classes.noConfig}>{translate('resources.common.no_config_validation', { smart_count: 2 })}</span>}
        </Paper>
      </FormControl>
      {open && <SettingFormDialog
        open={open}
        onClose={this.handleClose}
        setValue={this.setValue}
        record={record}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  addField,
  connect(null, { showNotification, reset })
)

export default enhance(EInvoiceSetting)
