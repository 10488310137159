import React, { Component } from 'react'
import {
  translate,
  SimpleForm,
  TextInput,
  showNotification,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import {
  Grid,
  withStyles,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import _ from 'lodash'

const styles = () => ({
  root: {
    width: '100%'
  },
  listWidth: {
    width: '100%'
  },
  chipTrip: {
    margin: 4,
  },
  routeChip: {
    margin: 8,
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  gridLeft: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 24,
  },
  gridRight: {
    padding: 24,
  },
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 16,
  },
  card: {
    margin: 8
  },
  label: {
    paddingLeft: '0.5em',
    marginBottom: 6,
    marginTop: 6,
  }
})

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = { record: {} }
  }

  loadData = async (id) => {
    let res = await Provider.dataProvider('GET_ONE', 'membercards', {
      id,
      filter: {
        include: [
          {
            relation: 'customer',
          },
          {
            relation: 'fare',
          },
        ],
      }
    })
    let memberCard = res && res.data
    let { customer, itemDetail = {} } = memberCard
    let { fullName: customerName, phone: customerPhone, email: customerEmail } = customer
    //let { name: routeName } = fare
    let { segmentName, routeId } = itemDetail
    let record = {
      ...memberCard,
      customerName,
      customerPhone,
      customerEmail,
      segmentName,
      routeId,
    }
    this.setState({ record })
  }

  async componentDidMount() {
    const { record } = this.props
    if (record && record.id) {
      await this.loadData(record.id)
    }
  }

  onSave = (value) => {
    const { showNotification } = this.props
    const { customerId, customerName, customerPhone, customerEmail } = value
    Provider.dataProvider('REMOTE', 'customers', {
      method: customerId,
      requestMethod: 'PATCH',
      data: {
        fullName: customerName,
        phone: customerPhone,
        email: customerEmail
      }
    }).then(
      () => {
        showNotification('notification.update_customer_info_success')
      }
    ).catch(
      err => {
        showNotification(_.get(err, 'body.error.message') || err.message, 'warning')
      }
    )
  }

  render() {
    let { classes, toolbar } = this.props
    const { record } = this.state
    const extra = {
      resource: 'membercards',
      fullWidth: true,
    }

    return <SimpleForm
      record={record}
      basePath="/membercards"
      toolbar={toolbar}
      save={this.onSave}
      {...extra}
    >
      <Grid container className={classes.root}>
        <Grid item xs={12} md={6}>
          <TextInput
            record={record}
            source="code"
            disabled
            {...extra}
          />
          <ReferenceInput
            source="routeId"
            reference="routes"
            label="resources.membercards.fields.routeName"
            disabled
            {...extra}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <TextInput
            record={record}
            source="segmentName"
            disabled
            {...extra}
          />
          <DateTimePickerInput
            source="createdAt"
            dateFormat="DD/MM/YYYY HH:mm"
            ampm={false}
            showLunarDate={true}
            keyboard={true}
            allowEmpty
            pickerType='datetime'
            disabled
            {...extra}
          />
          <ReferenceInput
            source="stopId"
            reference="stops"
            label="resources.saletickets.receive_member_card_place"
            disabled
            {...extra}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <TextInput source="customerName" {...extra} />
          <TextInput source="customerPhone" {...extra} />
          <TextInput source="customerEmail" {...extra} />
        </Grid>
      </Grid>
    </SimpleForm>
  }
}

const enhance = compose(withStyles(styles), translate, connect(null, { showNotification }))

export default enhance(FormSave)
