import moment from 'moment'

export function validate(values, translate) {
  let errors = {}
  // Validate validFrom
  let fromDate = (values && values.departureTime_gte) && moment(values.departureTime_gte)
  let toDate = (values && values.departureTime_lte) && moment(values.departureTime_lte)

  let saleStart = (values && values.saleStart) && moment(values.saleStart)
  let saleEnd = (values && values.saleEnd) && moment(values.saleEnd)
  if (fromDate && toDate) {
    if (toDate.diff(fromDate, 'minutes') < 0) {
      errors.departureTime_gte = translate('error_messages.invalid.viewtrips.departureTime_lte')
    }
    if (fromDate.diff(toDate, 'minutes') > 0) {
      errors.departureTime_lte = translate('error_messages.invalid.viewtrips.departureTime_gte')
    }
  }

  if (saleStart && saleEnd) {
    if (saleEnd.diff(saleStart, 'minutes') < 0) {
      errors.saleStart = translate('error_messages.invalid.viewtrips.departureTime_lte')
    }
    if (saleStart.diff(saleEnd, 'minutes') > 0) {
      errors.saleEnd = translate('error_messages.invalid.viewtrips.departureTime_gte')
    }
  }

  return errors
}