import React, {Component} from 'react'
import { 
  withStyles,
  Card,
  CardHeader,
  CardContent,
} from '@material-ui/core'

class CardCustom extends Component {
  constructor(props) {
    super(props)
    this.state= {}
  }
  render() {
    const {title, action, children, classes } = this.props
    return <Card>
      <CardHeader 
        className={classes.headerCard}
        title={title && title}
        action={action && action}
        classes={{
          root: classes.root,
          action: classes.action,
          title: classes.title
        }} 
      />
      <CardContent className={classes.contentCard}> 
        {children}
      </CardContent>
    </Card>
  }
}

export default withStyles({
  root: {
    padding: '0 16px'
  },
  title: {
    padding: '16px 0'
  },
  action: {
    margin: 0,
    alignSelf: 'center'
  },
  headerCard: {
    display: 'flex',
    alignItems: 'center',
    background: '#e5e5e5',
  },
  contentCard: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
  }
})(CardCustom)
