import React, { Component } from 'react'
import { translate, refreshView, showNotification } from 'react-admin'
import compose from 'recompose/compose'
import { Button, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'
import concat from 'lodash/concat'
import { readText } from '../utils/file'
import { Provider } from '../provider'
import { connect } from 'react-redux'

const style = {
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17
  }
}

class ImportSmartButton extends Component {

  onDrop = async (files) => {
    let result = []
    let { refreshView, showNotification } = this.props
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let data = await readText(file)
      result = concat(result, data)
    }
    Provider.dataProvider('REMOTE', 'schedules', {
      method: 'import',
      requestMethod: 'POST',
      data: result
    }).then(() => {
      showNotification('notification.import_schedule_success')
      refreshView()
    }).catch((e) => {
      showNotification(e.message, 'warning')
    })
  }

  render() {
    const { classes, translate } = this.props
    return (
      <Dropzone onDrop={this.onDrop} disabledStyle={{}}>
        <Button className={classes.button} color="primary">
          <FontAwesomeIcon className={classes.icon} icon={faFileUpload} />
          <span className={classes.label}>
            {translate('button.import')}
          </span>
        </Button>
      </Dropzone>
    )
  }

}

const enhance = compose(
  translate,
  withStyles(style),
  connect(null, { refreshView, showNotification })
)

export default enhance(ImportSmartButton)
