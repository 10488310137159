import { put, race, take, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import {
  GET_LIST_FARE_RULES, GET_LIST_FARE_RULES_SUCCESS, GET_LIST_FARE_RULES_FAILURE,
  GET_FARE_RULES, GET_FARE_RULES_SUCCESS, GET_FARE_RULES_FAILURE,
} from './actions'

// ------------------------------------------------------------------------------------------
function* getListFareRules(action) {
  let { start, done } = action
  start && start()

  const { success, failure } = yield race({
    success: take(GET_LIST_FARE_RULES_SUCCESS),
    failure: take(GET_LIST_FARE_RULES_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      done && done(payload.data)
    } else {
      yield put(showNotification('Get fare rules with no data'));
    }
  }

  if (failure) {
    yield put(showNotification('Cannot get fare rules'));
  }
}

export function* requestGetListFareRules() {
  yield takeEvery(GET_LIST_FARE_RULES, getListFareRules)
}

// ------------------------------------------------------------------------------------------
function* getSelectedFareRules(action) {
  let { start, done } = action
  start && start()

  const { success, failure } = yield race({
    success: take(GET_FARE_RULES_SUCCESS),
    failure: take(GET_FARE_RULES_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      done && done(payload.data)
    } else {
      yield put(showNotification('Get fare rules with no data'));
    }
  }

  if (failure) {
    yield put(showNotification('Cannot get fare rules'));
  }
}

export function* requestGetSelectedFareRules() {
  yield takeEvery(GET_FARE_RULES, getSelectedFareRules)
}