import React, { Component, Fragment } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  CreateButton,
  Edit,
  translate,
  FunctionField,
  CardActions,
  EditButton
} from 'react-admin'
import FormSave, { getNumberOfRowColumn, getNumberOfRowColumnGrid } from './FormSave'
import { EditTitle } from '../common/Title'
import { isNotEmpty2D } from '../utils/array'
import { isCompanyManager } from '../utils/permission'
import { CustomExportButton } from './IOButton'
import ImportListButton from './ImportListButton'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'

const styles = {
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
}

////////////////////////////////////////////////////////////////////////////////
// SeatMap
export const getNumberOfSeat = record => {
  let count = 0
  let { codesFistFloor, codesSecondFloor } = record
  if (isNotEmpty2D(codesFistFloor)) {
    for (let row of codesFistFloor){
      for (let cell of row)
        if (cell && cell.trim()){
          count++
        }
    }
  }
  if (isNotEmpty2D(codesSecondFloor)) {
    for (let row of codesSecondFloor){
      for (let cell of row)
        if (cell && cell.trim()){
          count++
        }
    }
  }
  return count
}

export const _SeatMapList = ({classes, translate, permissions, ...props}) => {
  let companyManager = isCompanyManager(permissions)
  return <List bulkActionButtons={false} actions={<ActionList />} {...props}>
    <Datagrid>
      <TextField source="name" />
      <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        label='resources.seatmaps.fields.numOfRow'
        render={ record => {
          let { numOfRow } = getNumberOfRowColumnGrid(getNumberOfRowColumn(record.codesFistFloor),getNumberOfRowColumn(record.codesSecondFloor))
          return numOfRow
        }}
      />
      <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        label='resources.seatmaps.fields.numOfCol'
        render={ record => {
          let { numOfCol } = getNumberOfRowColumnGrid(getNumberOfRowColumn(record.codesFistFloor),getNumberOfRowColumn(record.codesSecondFloor))
          return numOfCol
        }}
      />
      <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        label='resources.seatmaps.fields.numOfSeat'
        render={ record => {
          return getNumberOfSeat(record)
        }}
      />
      <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        label='resources.seatmaps.fields.numOfDecks'
        render={record => {
          let {codesFistFloor, codesSecondFloor} = record
          let numberOfFloor = 0
          if (isNotEmpty2D(codesFistFloor)) {
            numberOfFloor += 1
          }
          if (isNotEmpty2D(codesSecondFloor)) {
            numberOfFloor += 1
          }
          return translate('resources.seatmaps.fields.numberOfFloor', { count: numberOfFloor, smart_count: numberOfFloor })
        }}
      />
      <FunctionField 
        resource='seatmaps'
        headerClassName={classes.textEnd}
        cellClassName={classes.textEnd}
        render={ record => <Fragment>
          <CustomExportButton record={record} />
          {companyManager && <EditButton basePath='seatmaps' record={record} />}
        </Fragment>
        }
      />
    </Datagrid>
  </List>
}

export const SeatMapList = compose(translate, withStyles(styles))(_SeatMapList)

////////////////////////////////////////////////////////////////////////////////

export class SeatMapCreate extends Component {

  state = {
    importRecord: {}
  }

  handleChangeImportRecord = importRecord => {
    this.setState({ importRecord })
  }

  render() {
    let { resource, history, ...props } = this.props
    let { importRecord } = this.state
    return (
      <Create
        resource={resource}
        title={<EditTitle resource={resource} />}
        history={history}
        {...props}
      >
        <FormSave
          action='create'
          importRecord={importRecord}
          history={history}
        />
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Action List

var ActionList = (props) => {
  let { basePath } = props
  return <CardActions>
    <CreateButton basePath={basePath} />
    <ImportListButton />
  </CardActions>
}

//////////////////////////////////////////////////////////////////////////////
export class SeatMapEdit extends Component {

  state = {
    importRecord: {}
  }

  handleChangeImportRecord = importRecord => {
    this.setState({ importRecord })
  }

  render() {
    const { resource, history, ...props } = this.props
    let { importRecord } = this.state
    return (
      <Edit
        undoable={false}
        history={history}
        resource={resource}
        title={<EditTitle resource={resource} />}
        {...props}
      >
        <FormSave
          action='edit'
          importRecord={importRecord}
          history={history}
        />
      </Edit>
    )
  }
}
