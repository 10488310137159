import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  SimpleForm,
  Edit,
  ReferenceField,
  Filter,
  translate,
  CreateButton,
  ExportButton,
  CardActions,
} from 'react-admin'
import FormSave from './FormSave'
import compose from 'recompose/compose'
import { Button, withStyles } from '@material-ui/core'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormCreate from './FormCreate'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import SearchInput from '../common/SearchInput'
import { isCompanyManager } from '../utils/permission'
import { StatusField } from './StatusField'
import { validate } from './validate'

const styleListAction = () => ({
  rigthLabel: {
    paddingLeft: '0.5em'
  }
})

const styles = {
  textCenter: {
    textAlign: 'center',
  },
  bgColor: {
    color: 'white',
    background: '#4caf50',
    height: '32px',
    cursor: 'default',
    border: 'none',
    display: 'inline-flex',
    padding: '0',
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: '3em',
    width: 240,
  },
  driverInfo: {
    display: 'flex',
  },
  driverAvatar: {
    margin: 0,
  },
  driverName: {
    margin: 'auto',
  },
}

const VehicleFilter = ({ translate, ...props }) => (
  <Filter {...props}>
    <SearchInput helperText="resources.vehicles.helper_text" source="q" alwaysOn />
  </Filter>
)

const ActionList = withStyles(styleListAction)(({
  basePath,
  resource,
  currentSort,
  filterValues,
  exporter,
  total,
  translate,
  history,
  classes,
  companyManager,
}) => {
  return (
    <CardActions>
      {companyManager && <>
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
        <Button
          color="primary"
          onClick={() => {
            history.push(`/${resource}/import`)
          }}
        >
          <FontAwesomeIcon icon={faFileUpload} />
          <span className={classes.rigthLabel}>
            {translate('button.import')}
          </span>
        </Button>
      </>}
    </CardActions>
  )
})

////////////////////////////////////////////////////////////////////////////////
// List
const _VehicleList = ({ classes, translate, history, permissions, ...props }) => {
  let companyManager = isCompanyManager(permissions)
  return <List
    history={history} filters={<VehicleFilter translate={translate} />}
    bulkActionButtons={false}
    actions={<ActionList history={history} companyManager={companyManager} />}
    sort={{ field: 'id', order: 'DESC' }}
    filter={{
      '../fields': [
        'id',
        'name',
        'plate',
        'model',
        'type',
        'status',
        'fuel',
        'capacity'
      ]
    }}
    {...props}
  >
    <Datagrid rowClick={companyManager && 'edit'}>
      <TextField source="plate" />
      <TextField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="name"
      />
      <TextField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="model"
      />
      <ReferenceField
        source="type"
        basePath="vehicletypes"     
        reference="vehicletypes"
        linkType={false}
        allowEmpty
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        source="status"
        basePath="vehiclestatuses"
        reference="vehiclestatuses"
        linkType={false}
        allowEmpty
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter} 
      >
        <StatusField source="name" />
      </ReferenceField>
      <ReferenceField
        source="fuel"
        basePath="fueltypes"
        reference="fueltypes"
        linkType={false}
        allowEmpty
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      >
        <TextField source="name"/>
      </ReferenceField>
      <TextField
        source="capacity"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      />
    </Datagrid>
  </List>
}

const enhanceVehicleList = compose(translate, withStyles(styles))
export const VehicleList = enhanceVehicleList(_VehicleList)
////////////////////////////////////////////////////////////////////////////////
// Create
export const VehicleCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
    undoable="false"
  >
    <FormCreate />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit
// const EditTitle = ({ record }) => (`Xe [${record.plate}] ${record.name}`)
export const VehicleEdit = ({ permissions, ...props }) => {
  return (
    <Edit
      {...props}
      title={<EditTitle
        resource={props.resource}
        render={record => `[${record.plate}] - ${record.name}`}
      />}
      undoable={false}
    >
      <SimpleForm 
        toolbar={<CustomToolbar />}
        validate={validate}
      >
        <FormSave />
      </SimpleForm>
    </Edit>
  )
}
