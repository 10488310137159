import React, { Component } from 'react'
import compose from 'recompose/compose'
import {
  withStyles,
  Grid,
  Card,
  Typography,
} from '@material-ui/core'
import { translate } from 'react-admin'
import {
  faTicketAlt,
} from '@fortawesome/free-solid-svg-icons'
import CardIcon from '../common/CardIcon'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import { green, blue, blueGrey } from '@material-ui/core/colors'

const PIE_DATA_BY_REVENUE = 'revenue'

const styles = (theme) => {
  let { unit } = theme.spacing
  return {
    swipeContainer: {
      width: 400,
    },
    gridRoot: {
      marginLeft: -2 * unit,
      marginRight: -2 * unit,
    },
  }
}

const cardStyles = theme => ({
  main: {
    flex: '1',
    marginTop: 20,
    cursor: 'pointer',
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: '16px 16px 12px 16px',
    minHeight: 52,
  },
  title: {
    minHeight: '3em',
  },
  cardAction: {
    justifyContent: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandContent: {
    padding: 0,
  },
  chartContainer: {
    height: 300
  },
  chartTitle: {
    textAlign: 'start',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#9e9e9e',
    marginBottom: 8,
  },
  emptyRecord: {
    textAlign: 'center',
    fontSize: 14,
    color: '#9e9e9e',
    fontStyle: 'italic',
    marginTop: 16,
  },
})

class _PeriodSumary extends Component {

  render() {
    let { 
      classes,
      title,
      icon,
      bgColor,
      value,
      translate,
    } = this.props

    return <div className={classes.main}>
      <CardIcon icon={icon} bgColor={bgColor} size="4x" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate(title)}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {value}
        </Typography>
      </Card>
    </div>
  }
}

const enhancePeriodSumary = compose(
  withStyles(cardStyles),
  translate
)

const PeriodSummary =  enhancePeriodSumary(_PeriodSumary)

class GeneralReport extends Component {

  state = {}

  getReport = async (filter) => {
    try {
      let resp = await Provider.dataProvider('REMOTE', 'charges', {
        requestMethod: 'GET',
        method: 'statisticInvoice',
        data: { createdAt: filter.createdAt_between }
      })
      if (resp && resp.data) return resp.data
    } catch (e) {
      console.log('Error', e)
    }
  }

  async componentDidMount() {
    let data = await this.getReport()
    this.setState({ data })
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { filter, version } = this.props
    let { filter: prevFilter, version: prevVersion } = prevProps
    const updated = !(prevFilter === filter && version === prevVersion)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { filter } = this.props
      let data = filter && await this.getReport(filter)
      this.setState({ data })
    }
  }

  render() {
    let { classes, filter } = this.props
    let { data = {} } = this.state
    let {unissuedInvoice, issuedInvoice, totalCharge } = data
    return <div className={classes.gridRoot}>
      <Grid container spacing={0}>
        <Grid item xs md={4}>
          <PeriodSummary
            type={PIE_DATA_BY_REVENUE}
            title="resources.einvoices.statistic.number_of_unissued"
            bgColor={blue[500]}
            icon={faTicketAlt}
            size="2x"
            value={unissuedInvoice ? unissuedInvoice  : '0'}
          />
        </Grid>
        <Grid item xs md={4}>
          <PeriodSummary
            title="resources.einvoices.statistic.number_of_issued"
            bgColor={green[500]}
            icon={faTicketAlt}
            size="2x"
            value={issuedInvoice ? issuedInvoice  : '0'}
          />
        </Grid>
        <Grid item xs md={4}>
          <PeriodSummary
            title="resources.einvoices.statistic.total"
            bgColor={blueGrey[500]}
            icon={faTicketAlt}
            size="2x"
            value={totalCharge ? totalCharge  : '0'}
          />
        </Grid>
      </Grid>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  let objectFilter = state.form['filterForm'] || {}
  let filter = objectFilter.values
  return { ...props, filter }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(mapStateToProps)
)

export default enhance(GeneralReport)
