import moment from 'moment'
import { validPhoneNumber } from '../utils/topUpUtil'

export function validate(values, props) {
  let errors = {}
  let { translate } = props
  // let regexPhoneNumber = /^[0-9]{10,11}$/
  let regexEmail = /^\S+@\S+\.\S+$/
  // let { fullName, phone, email, validFrom, validTo } = values
  // Validate fullname
  let fullName = (values && values.fullName) && values.fullName.trim()
  if (!fullName || fullName.length === 0) {
    errors.fullName = translate('error_messages.required.drivers.fullname')
  }

  // Validate phone
  let phone = (values && values.phone)
  if (phone && (!phone.trim() || !validPhoneNumber(phone))) {
    errors.phone = translate('error_messages.invalid.drivers.phone')
  }

  // Validate email
  let email = (values && values.email)
  if (email && (!email.trim() || !regexEmail.test(email))) {
    errors.email = translate('error_messages.invalid.drivers.email')
  }

  // Validate validFrom
  let validFrom = (values && values.validFrom) && moment(values.validFrom)
  let validTo = (values && values.validTo) && moment(values.validTo)
  if (validFrom && validTo) {
    if (validTo.diff(validFrom, 'days') < 0) {
      errors.validFrom = translate('error_messages.invalid.drivers.validFrom')
    }
    if (validFrom.diff(validTo, 'days') > 0) {
      errors.validTo = translate('error_messages.invalid.drivers.validTo')
    }
  }

  return errors
}
