import React, { Component, Fragment, } from 'react'
import {
  Datagrid,
  List,
  Filter,
  NumberField,
  TextField,
  FunctionField,
  translate,
} from 'react-admin'
import { DatePickerInput } from '../common/DatePicker'
import { moment } from '../common/format'
import _ from 'lodash'
import {
  withStyles,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  Tooltip,
  Drawer,
  Typography,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import LinkField from './../common/LinkField'
import {
  History as HistoryIcon ,
} from '@material-ui/icons'

class _Filter extends Component {
  state = {}

  render() {
    const { translate, permissions, ...props } = this.props
    const { fromDate } = this.state
    return <Filter
      {...props}
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
    >
      <DatePickerInput
        label="resources.membercards.fields.rechargeMonth"
        source="rechargedMonth"
        views={['year', 'month']}
        dateMask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        dateFormat="MM/YYYY"
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
        clearable={true}
      />
      <DatePickerInput
        source="rechargedAt"
        dateMask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        dateFormat="DD/MM/YYYY"
        ampm={false}
        keyboard={true}
        allowEmpty
        pickerType='date'
        clearable={true}
        alwaysOn
      />
    </Filter>
  }
}

const MemberCardReportFilter = translate(_Filter)

const showHistoryRechargedButtonStyles = {
  titleContainer: {
    padding: 16,
  },
  title: {
    marginBottom: 0,
  },
  paper: {
    overflow: 'visible',
  },
  label: {
    alignSelf: 'center',
    fontSize: 17,
    fontWeight: 'bold',
    fontStyle: 'italic',
  },
  textItem: {
    padding: 12,
  },
  content: {
    overflowY: 'auto',
    height: 'calc(100% - 62px)',
    fontSize: 15,
  },
}

class _ShowHistoryRechargedButton extends Component {

  state = {
    open: false,
    loading: false,
    saveLoading: false,
  }

  toggleDrawer = () => {
    this.setState({
      loading: false,
      open: true,
    })
  }

  onClose = () => {
    this.setState({ open: false })
  }

  render() {
    let { classes, translate, record } = this.props

    let { open, } = this.state

    let reservations = _.get(record, 'reservations', [])

    reservations = _.map(reservations, reservation => {
      let createdAt = _.get(reservation, 'createdAt', '')
      reservation.createdAt = createdAt ? moment(createdAt).format('DD/MM/YYYY') : createdAt
      return reservation
    })

    const groupReservationByDay = _.groupBy(reservations, 'createdAt')

    return (<Fragment>
      <Tooltip
        title={translate('resources.membercards.fields.historyRecharged')}
        enterDelay={100}
      >
        <LinkField
          className={classes.iconButton}
          icon
          onClick={() => this.toggleDrawer(true)}
        >
          <HistoryIcon fontSize="small" />
        </LinkField>
      </Tooltip>
      <Drawer
        anchor="right"
        open={open}
        onClose={this.onClose}
        classes={{ paper: classes.paper }}
      >
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h4">
            {translate('resources.membercards.fields.historyRecharged')}
          </Typography>
        </div>
        <div className={classes.content}>
          <Stepper nonLinear={true} orientation="vertical">
            {Object.keys(groupReservationByDay).map((day, idx) => (
              <Step active={true} key={idx}>
                <StepLabel icon={<FontAwesomeIcon icon={faClock}/>}>
                  <div className={classes.label}>{`Ngày ${day}`}</div>
                </StepLabel>
                <StepContent className={classes.text}>
                  {groupReservationByDay[day].map((reservation, idx) => {
                    let rechargedMonth = _.get(reservation, 'charges.0.productCharge.fromDate')
                    let paidAt = _.get(reservation, 'transactions.0.paidAt')
                    return <div key={idx} className={classes.textItem}>
                      {`${translate('resources.common.month')} ${moment(rechargedMonth).format('MM/YYYY')}
                        - ${translate('resources.membercards.fields.paidAt')}: ${moment(paidAt).format('HH:mm:ss DD/MM/YYYY')}`}
                    </div>
                  })}
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </div>
      </Drawer>
    </Fragment>)
  }
}

const ShowHistoryRechargedButton = compose(
  withStyles(showHistoryRechargedButtonStyles),
  translate
)(_ShowHistoryRechargedButton)

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}

class _MemberCardReport extends Component {
  render() {
    let { translate, classes, ...props } = this.props

    return (
      <List
        {...props}
        title={translate('resources.membercardreports.name')}
        filters={<MemberCardReportFilter />}
        filter={{
          '../include': [
            {
              relation: 'fare',
              scope: {
                include: [{
                  relation: 'route',
                  scope: {
                    fields: ['id', 'name'],
                  },
                }],
                fields: ['id', 'name', 'amount', 'routeId'],
              },
            },
            {
              relation: 'reservations',
              scope: {
                fields: ['id', 'membercardId', 'createdAt'],
                where: {
                  status: '20CONFIRMED',
                },
                include: [
                  {
                    relation: 'charges',
                    scope: {
                      fields: ['id', 'name', 'status'],
                      where: { status: '10PAID' },
                      include: [
                        {
                          relation: 'productCharge',
                          scope: {
                            fields: ['id', 'name', 'fromDate'],
                            where: { status: '10PAID' },
                          },
                          order: 'fromDate DESC'
                        },
                      ],
                    },
                  },
                  {
                    relation: 'transactions',
                    scope: {
                      fields: ['id', 'status', 'paidAt', 'reservationId' ],
                      where: { status: '20DONE' },
                    },
                  },
                ],
                order: 'createdAt DESC'
              },
            },
          ],
          path: 'getReports',
        }}
        sort={{ field: 'id', order: 'desc' }}
        bulkActionButtons={false}
        resource="membercards"
        basePath="/membercards"
      >
        <Datagrid>
          <TextField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="code"
          />
          <FunctionField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="segmentName"
            render={record => _.get(record, 'itemDetail.segmentName', '') }
            sortable={false}
          />
          <FunctionField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="routeName"
            render={record => _.get(record, 'fare.route.name', '') }
            sortable={false}
          />
          <FunctionField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="rechargeMonth"
            render={record => {
              let reservations = _.get(record, 'reservations', []) 
              let reservation = _.head(reservations)
              let rechargedMonth = _.get(reservation, 'charges.0.productCharge.fromDate')
              return <div>
                {moment(rechargedMonth).format('MM/YYYY')}
              </div>
            }}
            sortable={false}
          />
          <FunctionField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="rechargedAt"
            render={record => {
              let reservations = _.get(record, 'reservations', []) 
              let reservation = _.head(reservations)
              let rechargedAt = _.get(reservation, 'createdAt')
              return <div>
                {moment(rechargedAt).format('DD/MM/YYYY')}
              </div>
            }}
            sortable={false}
          />
          <FunctionField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="paidAt"
            render={record => {
              let reservations = _.get(record, 'reservations', [])
              let reservation = _.last(reservations)
              let paidAt = _.get(reservation, '0.transactions.paidAt')
              return <div>
                {moment(paidAt).format('DD/MM/YYYY')}
              </div>
            }}
            sortable={false}
          />
          <NumberField
            label="resources.membercards.fields.amount"
            source="fare.amount"
            locales="vi-VN"
            options={{ style: 'currency', currency: 'VND' }}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          />
          <FunctionField
            headerClassName={classes.textEnd}
            cellClassName={classes.textEnd}
            render={(record) => {
              return <ShowHistoryRechargedButton
                record={record}
              />
            }}
          />
        </Datagrid>
      </List>
    )
  }
}

export const MemberCardReport = compose(
  withStyles(styles),
  translate,
)(_MemberCardReport)
