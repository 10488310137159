import React, { Component, Fragment } from 'react'
import FuzzyPicker from './fuzzy-picker'
import './index.css'
import {
  TextField,
} from '@material-ui/core'

export default class FuzzySelectArray extends Component {
  state = {
    text: '',
    open: false,
  }
  constructor(props) {
    super(props)
    this.fuzzy = React.createRef()
  }
  render() {
    const {
      label,
      items,
      renderItem,
      itemValue,
      disabled,
      onChange,
    } = this.props
    const {
      text,
      open,
    } = this.state
    return (
      <Fragment>
        <TextField
          disabled={disabled}
          label={label}
          value={text}
          InputProps={{
            readOnly: true,
          }}
          fullWidth
          onClick={() => this.setState({ open: true })}
          onKeyPress={e => {
            // e.target.value = e.key
            this.fuzzy.current.onInputChanged({ target: { value: e.key } })
            var key = e.key
            setTimeout(() => {
              this.fuzzy.current.setInputValue(key)
            }, 0)
            this.setState({ open: true })
          }}
        />
        <FuzzyPicker
          ref={this.fuzzy}
          isOpen={open}
          items={items}
          renderItem={renderItem}
          onClose={() => this.setState({ open: false })}
          itemValue={itemValue}
          showAllItems={true}
          onChange={item => {
            this.setState({
              text: item.name,
              open: false,
            })
            onChange && onChange(item.id)
          }}
        />
      </Fragment>
    )
  }
}
