import React, { Component } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import isEmpty from 'lodash/isEmpty'
import { translate } from 'react-admin'

const groupModes = ['stacked', 'grouped']
const layouts = ['vertical', 'horizontal']

const defaultBarChartProps = {
  margin: {
    'top': 16,
    'bottom': 50,
    'left': 50
  },
  padding: 0.3,
  colors: 'nivo',
  colorBy: 'index',
  borderColor: 'inherit:darker(1.6)',
  labelSkipWidth: 12,
  labelSkipHeight: 12,
  labelTextColor: 'inherit:darker(1.6)',
  animate: true,
  motionStiffness: 90,
  motionDamping: 15,
}

class BarChart extends Component {

  constructor(props) {
    super(props)
    this.state = {
      groupMode: 'stacked',
      layout: 'vertical',
      maxValue: 'auto',
      minValue: 'auto',
      properties: defaultBarChartProps,
      data: []
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let {
      groupMode,
      layout,
      maxValue,
      minValue,
      properties,
      data
    } = nextProps
    let {
      groupMode: currentGroupMode,
      layout: currentLayout,
      maxValue: currentMaxValue,
      minValue: currentMinValue,
      properties: currentProperties,
      data: currentData
    } = prevState

    if (properties && !isEmpty(properties)) {
      for (let prop in properties) {
        currentProperties = { ...currentProperties, [prop]: properties[prop] }
      }
    }

    if (groupMode !== currentGroupMode && groupModes.includes(groupMode)) {
      currentGroupMode = groupMode
    }

    if (layout !== currentLayout && layouts.includes(layout)) {
      currentLayout = layout
    }
    if (maxValue !== currentMaxValue) {
      currentMaxValue = maxValue
    }

    if (minValue !== currentMinValue) {
      currentMinValue = minValue
    }

    if (data !== currentData) {
      currentData = data
    }
    return {
      groupMode: currentGroupMode,
      layout: currentLayout,
      minValue: currentMinValue,
      maxValue: currentMaxValue,
      properties: currentProperties,
      data: currentData
    }
  }

  render () {
    let { keys, axises, legends, gradients, indexBy, translate, tooltip } = this.props
    const { groupMode, layout, minValue, maxValue, properties, data } = this.state
    let { axisBottom, axisLeft } = axises
    let { isTranslate: isTranslateAxisBottom, legend: legendAxisBottom } = axisBottom
    let { isTranslate: isTranslateAxisLeft, legend: legendAxisLeft } = axisLeft
    axisBottom = { ...axisBottom, legend: legendAxisBottom ? (isTranslateAxisBottom ? legendAxisBottom : translate(legendAxisBottom)) : 'N/A' }
    axisLeft = { ...axisLeft, legend: legendAxisLeft ? (isTranslateAxisLeft ? legendAxisLeft : translate(legendAxisLeft)) : 'N/A' }
    axises = { ...axises, axisBottom, axisLeft }
    return (
      <ResponsiveBar
        indexBy={indexBy}
        data={data}
        groupMode={groupMode}
        layout={layout}
        minValue={minValue}
        tooltip={tooltip}
        maxValue={maxValue}
        keys={keys}
        legends={legends || []}
        {...properties}
        {...axises}
        {...gradients}
        labelFormat="~s"
      />
    )
  }
}

export default translate(BarChart)
