import React, { Component } from 'react'
import {
  TextInput,
  SimpleForm,
} from 'react-admin'
import { Grid, withStyles } from '@material-ui/core'
import CustomToolbar from '../common/CustomToolbarForm'

const stringify = v => {
  if (typeof v === 'object') {
    return JSON.stringify(v, null, 2)
  }
  return v
}

const style = () => ({
  root: {
    width: '100%'
  }
})

class FormSave extends Component {
  render() {
    let { classes } = this.props
    const extra = {
      resource: 'deviceconfigs',
      fullWidth: true,
    }
    return (
      <SimpleForm toolbar={<CustomToolbar />} {...this.props}>
        <Grid className={classes.root} container>
          <Grid item xs={6}>
            <TextInput source="name" {...extra} />
            <TextInput
              source="value"
              format={ v => { return stringify(v)} }
              {...extra}
              multiline
            />
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

export default withStyles(style)(FormSave)
