import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  Dialog,
  DialogContent,
  withStyles,
  Grid,
  GridList,
  GridListTile,
  Typography,
  Card,
  CardHeader,
  Avatar,
  AppBar,
  Toolbar,
  IconButton,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Tabs,
  Tab,
  Chip,
} from '@material-ui/core'
import {
  translate,
  ReferenceField,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  showNotification,
  addField,
} from 'react-admin'
import compose from 'recompose/compose'
import { timeFormat } from '../common/format'
import { StatusField } from '../viewtrip/StatusField'
import { SwapSeatConfirmationDialog  } from './TripLayout'
import _ from 'lodash'
import * as chargeStatus from '../common/charge-status'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { Provider } from '../provider'
import { GET_LIST_PAGINATION } from '../provider/get'
import FareTable from '../common/FareTable'
import moment from 'moment'
import { yellow } from '@material-ui/core/colors'
import { getCampaignDiscountByPrice } from '../utils/discount'
import { connect } from 'react-redux'
import { Close as CloseIcon } from '@material-ui/icons'
import classnames from 'classnames'
import { DatePickerInput } from '../common/DatePicker'
import DialogLoading from '../common/DialogLoading'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'
import { formatCurrency } from '../utils/formatUtil'
import { push } from 'react-router-redux'
import { sanitize } from '../utils/commonUtil'

const deckTabStyle = {
  primaryText: {
    color: 'white',
  },
}

export const _DeckTabs = ({ 
  isDoubleDeck,
  label,
  translate,
  tabIndex,
  handleChange,
  className,
  classes,
}) => {
  // filter only decker which has seats
  return <Tabs
    value={tabIndex}
    onChange={handleChange}
    indicatorColor="primary"
    textColor="primary"
    centered
    className={className}
  >
    {isDoubleDeck && <Tab label={<Typography className={classes.primaryText}>{translate('resources.seatmaps.fields.firstFloor')}</Typography>} />}
    {isDoubleDeck && <Tab label={<Typography className={classes.primaryText}>{translate('resources.seatmaps.fields.secondFloor')}</Typography>} />}
    {!isDoubleDeck && <Tab label={<Typography className={classes.primaryText}>{label ? label : translate('resources.seatmaps.name', { smart_count: 1 })}</Typography>} />}
  </Tabs>
}

const DeckTabs = withStyles(deckTabStyle)(_DeckTabs)

const _WarningDialog = ({ translate, open, onClose }) => {
  return <Dialog
    onClose={onClose}
    fullWidth
    maxWidth="xs"
    open={open}
  >
    <DialogTitle>{translate('notification.name')}</DialogTitle>
    <DialogContent>
      <DialogContentText>{translate('notification.warning_no_selected_seat')}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{translate('button.close')}</Button>
    </DialogActions>
  </Dialog>
}

const enhanceWarningDialog = compose(translate)
const WarningDialog = enhanceWarningDialog(_WarningDialog)

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
    color: 'white',
  },
  button: {
    color: 'white',
    fontSize: 12,
    width: 150,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  },
}

const seatCardStyle = {
  selectedSeatCard: {
    background: yellow[100],
    display: 'flex',
    flexDirection: 'column',
  },
  swapSelectedSeatCard: {
    backgroundColor: '#c8e6c9',
    border: '2px solid red',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '16px 8px 16px 8px',
  },
  avatar: {
    marginRight: 8,
  },
}

const _SeatCard = ({
  seat: { seat, charge },
  classes,
  translate,
  isPrePaid,
  isOwner,
  selected,
  swapSelected,
  onClick,
  onDoubleClick,
  disabled,
}) => {
  let phone = _.get(charge, 'reservation.bookingInformation.contact.phone', '')
  let fullName = _.get(charge, 'reservation.bookingInformation.contact.fullName', '') || _.get(charge, 'reservation.bookingInformation.contact.firstName', '')
  let total = _.get(charge, 'reservation.paymentInformation.total', 0)
  let paid = _.get(charge, 'reservation.paymentInformation.paid', 0)
  let state = chargeStatus.OTHER
  let className
  if (selected) {
    className = classes.selectedSeatCard
  }
  if (swapSelected) {
    className = classes.swapSelectedSeatCard
  }
  if (isOwner) {
    isPrePaid = chargeStatus.isPrePaid(paid, total)
    if (isPrePaid) {
      state = chargeStatus.PRE_PAID
    } else {
      state = _.get(charge, 'status') || chargeStatus.OTHER
    }
  }
  if (isOwner) {
    isPrePaid = chargeStatus.isPrePaid(paid, total)
    if (isPrePaid) {
      state = chargeStatus.PRE_PAID
    } else {
      state = _.get(charge, 'status') || chargeStatus.OTHER
    }
  }
  let color = chargeStatus.color[state]
  color = isOwner ? color : '#607d8b'
  let styles = {
    backgroundColor: color,
    width: 30,
    height: 30,
  }
  let isReserved = !(!charge)
  let title = phone && phone.length <= 10 ? phone : `${phone.substring(0, 3)}...${phone.substring(phone.length - 4, phone.length)}` 
  let style = { backgroundColor: disabled && '#bdbdbd', height: 68 }
  return <Card
    className={className}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    style={style}
  >
    <CardHeader
      className={classes.header}
      classes={{ avatar: classes.avatar }}
      avatar={<Avatar
        aria-label={seat}
        style={styles}
      >
        <b style={{ fontSize: 15 }}>{seat}</b>
      </Avatar>}
      title={isReserved ? <b className={classes.seatTitle}>{title}</b> : translate('resources.charges.fields.empty_seat')}
      subheader={charge ? <b className={classes.seatSubTitle}>{fullName}</b> : ''}
    />
  </Card>
}

const enhanceSeatCard = compose(withStyles(seatCardStyle), translate)
const SeatCard = enhanceSeatCard(_SeatCard)

const Fares = ({ fareTable }) => {
  let prices = _.get(fareTable, 'fareTable.prices', [])
  let conditions = _.get(fareTable, 'fareTable.conditions', [])
  let defaultPrice = prices[0] || {}
  let tables = defaultPrice.table
  let defaultTable = tables[0] || {}
  let fares = _.get(defaultTable, 'data')
  let conditionKeys = conditions.map(ele => ele.key)
  fares = _.reduce(conditionKeys, (result, ele) => {
    let fare = fares[ele]
    if (fare) {
      result.push(fare)
    }
    return result
  }, [])
  return fares ? <div style={{ marginBottom: 8 }}>
    {fares.map((fare, key) => {
      let { value, name } = fare
      return <Chip style={{ marginRight: 4 }} key={key} label={`${name}: ${formatCurrency(value)}`} />
    })}
  </div> : ''
}

const seatMapStyle = {
  root: {
    borderRight: '1px solid',
  },
  seatMap: {
    backgroundColor: '#eee',
    margin: 5,
    width: '100%',
  },
  container: {
    display: 'flex',
  },
  text: {
    width: '90%'
  },
  action: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  button: {
    fontSize: 8
  },
}

class _SeatMap extends Component {

  state = {
    tabIndex: 0
  }

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex })
  }

  render() {
    let { 
      classes,
      translate,
      vehicle,
      licensePlateNum,
      trip,
      fareTable,
      isDoubleDeck,
      floors,
      isCompanyManager,
      selectedSeats,
      swapSelectedSeat,
      reservationChargeCount,
      reservationId,
      relatedSeats,
      allStop,
      onlyView,
      singleSelect,
      multipleSelect,
      selectReservation,
      panelSelected,
      className,
      label,
      classNameTitle,
      rightPanel,
      tripId,
      push,
      onCloseDialog,
    } = this.props
    return <Fragment>
      {vehicle && <div className={classes.flex}>
        <div className={classes.container}>
          <div className={classes.text}>
            <Typography variant="h6" color="inherit">
              {licensePlateNum} &nbsp;
          ({moment(trip.departureTime).format(timeFormat)} - {moment(trip.arrivalTime).format(timeFormat)}) &nbsp;
              {moment(trip.departureTime).format('DD/MM/YYYY')} &nbsp;
              <ReferenceField
                basePath="/tripstatuses"
                reference="tripstatuses"
                resource="tripstatuses"
                source="status"
                linkType={false}
                allowEmpty
                record={trip}
              >
                <StatusField source="name" />
              </ReferenceField>
            </Typography>
          </div>
          {rightPanel && <div className={classes.action}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              className={classes.button}
              onClick={() => {
                onCloseDialog(false, tripId)
                push(`/reservations/trip_layout/${tripId}`)
              }}
            >
              {translate('button.linkToTrip')}
            </Button>
          </div>}
        </div>
        <Fares fareTable={fareTable} />
        <Typography variant="subtitle2" color="inherit">
          {trip.departure} - {trip.arrival}
        </Typography>
      </div>}
      {fareTable && <Fragment>
        <DeckTabs
          label={label}
          tabIndex={this.state.tabIndex}
          handleChange={this.handleChange}
          isDoubleDeck={isDoubleDeck}
          translate={translate}
          className={classNameTitle}
        />
        {floors.map((floor, index) => {
          if (!floor || floor.length === 0) return null
          const cols = floor[0].length
          return <GridList
            className={classnames(classes.seatMap, className)}
            key={index}
            cols={cols}
            cellHeight="auto"
            spacing={24}
            style={{ display: index !== this.state.tabIndex ? 'none' : 'flex' }}
          >
            {floor.map(row =>
              row.map((seat, cindex) => {
                let { charge, seat: code } = seat
                let disabled
                if (panelSelected) {
                  disabled = !charge
                } else {
                  disabled = !(!charge)
                }
                let isOwner = true
                if (!isCompanyManager) {
                  if (charge) {
                    isOwner = this.isOwner(charge.agencyId)
                  }
                }
                return <GridListTile key={cindex} className={classes.seat}>
                  {code && <SeatCard
                    seat={seat}
                    selected={code in selectedSeats}
                    swapSelected={code in swapSelectedSeat}
                    changeSwapMode={this.changeSwapMode}
                    reservationChargeCount={reservationChargeCount}
                    disabled={disabled}
                    onDoubleClick={(evt) => {
                      if (disabled) {
                        return
                      }
                      evt.preventDefault()
                      selectReservation(code, evt)
                    }}
                    onClick={(evt) => {
                      if (disabled) {
                        return
                      }
                      if (evt.ctrlKey || evt.metaKey) {
                        multipleSelect(code, evt, !panelSelected)
                      } else {
                        singleSelect(code, !panelSelected)
                      }
                    }}
                    onAddSeat={this.onAddSeat}
                    onEditSeat={this.onEditSeat}
                    onCancelSeat={this.onCancelSeat}
                    isOwner={isOwner}
                    reservationId={reservationId}
                    relatedSeats={relatedSeats}
                    allStop={allStop}
                    onlyView={onlyView}
                    isSmall={true}
                  />}
                </GridListTile>
              })
            )}
          </GridList>
        })}
      </Fragment>}
    </Fragment>
  }
}

const enhanceSeatMap = compose(translate, withStyles(seatMapStyle), connect(null, { push }))
const SeatMap = enhanceSeatMap(_SeatMap)

const seatMapLeftStyle = {
  root: {
    borderRight: '1px solid',
    backgroundColor: '#eee'
  },
  gridList: {
    marginLeft: 24,
  },
  tabs: {
    backgroundColor: '#78909c',
    margin: '24px 12px 8px -12px',
  }
}

class _SeatMapLeft extends Component {

  render() {
    let {
      vehicle,
      trip,
      licensePlateNum,
      fareTable,
      isDoubleDeck,
      floors,
      isCompanyManager,
      styleSeatLayout,
      selectedSeats,
      swapSelectedSeat,
      reservationChargeCount,
      reservationId,
      relatedSeats,
      allStop,
      onlyView,
      singleSelect,
      multipleSelect,
      panelSelected,
      classes,
      selectReservation,
      translate,
    } = this.props
    let className = classes.gridList
    return <SeatMap
      classNameTitle={classes.tabs}
      className={className}
      trip={trip}
      vehicle={vehicle}
      licensePlateNum={licensePlateNum}
      fareTable={fareTable}
      isDoubleDeck={isDoubleDeck}
      floors={floors}
      isCompanyManager={isCompanyManager}
      styleSeatLayout={styleSeatLayout}
      selectedSeats={selectedSeats}
      swapSelectedSeat={swapSelectedSeat}
      reservationChargeCount={reservationChargeCount}
      reservationId={reservationId}
      relatedSeats={relatedSeats}
      allStop={allStop}
      onlyView={onlyView}
      singleSelect={singleSelect}
      multipleSelect={multipleSelect}
      panelSelected={panelSelected}
      selectReservation={selectReservation}
      label={translate('resources.common.old_seatmap')}
    />
  }
}

const enhanceSeatMapLeft = compose(translate, withStyles(seatMapLeftStyle))
const SeatMapLeft = enhanceSeatMapLeft(_SeatMapLeft)

const seatMapRightStyle = {
  gridList: {
    marginLeft: 24,
  },
  tabs: {
    backgroundColor: '#26a69a',
    margin: '24px 12px 8px -12px',
  }
}

class _SeatMapRight extends Component {

  render() {
    let {
      rightInfo,
      swapSelectedSeat = [],
      selectedSeats = [],
      singleSelect,
      multipleSelect,
      selectReservation,
      translate,
      isCompanyManager,
      classes,
      onCloseDialog,
    } = this.props
    let { 
      trip = {},
      floors = [],
      fareTable,
      reservationChargeCount,
      allStop,
      reservationSelected = {},
      swapLoading,
    } = rightInfo
    let { id, vehicle } = trip || {}
    let styleSeatLayout = { opacity: swapLoading ? 0.5 : 1 }
    let { id: reservationId, relatedSeats } = reservationSelected
    let decks = floors.filter(v => v && v.length > 0)
    let isDoubleDeck = decks && decks.length > 1
    let licensePlateNum = vehicle && `${translate('resources.vehicles.name', { smart_count: 1 })}: ${vehicle.plate}`
    let onlyView = trip.status === '40ARC'
    return <SeatMap
      classNameTitle={classes.tabs}
      trip={trip}
      multipleSelect={multipleSelect}
      vehicle={vehicle}
      licensePlateNum={licensePlateNum}
      fareTable={fareTable}
      isDoubleDeck={isDoubleDeck}
      floors={floors}
      isCompanyManager={isCompanyManager}
      styleSeatLayout={styleSeatLayout}
      selectedSeats={selectedSeats}
      swapSelectedSeat={swapSelectedSeat}
      reservationChargeCount={reservationChargeCount}
      reservationId={reservationId}
      relatedSeats={relatedSeats}
      allStop={allStop}
      onlyView={onlyView}
      singleSelect={singleSelect}
      selectReservation={selectReservation}
      label={translate('resources.common.new_seatmap')}
      rightPanel={true}
      tripId={id}
      onCloseDialog={onCloseDialog}
    />
  }
}

const enhanceSeatMapRight = compose(translate, withStyles(seatMapRightStyle))
const SeatMapRight = enhanceSeatMapRight(_SeatMapRight)

const dialogStyle = {
  root: {
    height: '100%',
  },
  gridLeft: {
    paddingLeft: 36,
    marginTop: 125,
  },
  searchForm: {
    marginLeft: -36
  },
  gridRight: {
  },
}

const getEarliestLeftTrip = (leftRouteId, departureTime, list, rightDepartureDate, rightRouteId) => {

  let leftDepartureDate = moment(departureTime).format('DD/MM/YYYY')
  rightDepartureDate = moment(rightDepartureDate).format('DD/MM/YYYY')

  if (leftDepartureDate !== rightDepartureDate || leftRouteId !== rightRouteId) return list[0].id

  let nextTrips = _.reduce(list, (result, ele) => {
    let { id, departureTime: rightDepartTime } = ele
    let diffTime = moment(rightDepartTime).diff(departureTime, 'hours') 
    if (diffTime > 0) {
      result.push({ id, diffTime })
    }
    return result
  }, [])

  if (nextTrips && nextTrips.length > 0) {
    let minNextTrip = _.minBy(nextTrips, 'diffTime') 
    return minNextTrip.id
  } else {
    return list[0].id
  }
}

const tripSelectInputStyle = {
  textContainer: {
    display: 'flex',
  },
  vehicle: {
    width: '70%',
  },
  place: {
    width: '30%',
    textAlign: 'end',
  },
}

class _TripSelectInput extends Component {

  state ={}


  getRightTrip = async (routeId, departureDate, leftDepartureTime) => {
    let resp = await Provider.dataProvider('GET_LIST', 'trips', {
      filter: {
        where: {
          vehicleId: { neq: null },
          status: { lte: '30PND' },
          departureTime_gte: moment(departureDate).startOf('day'),
          departureTime_lte: moment(departureDate).endOf('day'),
          departureTime: { neq: leftDepartureTime },
          routeId,
        },
        fields: [
          'id',
          'name',
          'departureTime',
          'status',
          'vehicleId',
          'seatAvailable',
        ],
        include: {
          relation: 'vehicle',
          scope: {
            fields: ['plate']
          }
        }
      },
      pagination: {},
      sort: { field: 'departureTime', order: 'ASC' },
    })
    if (resp && resp.data) {
      return resp.data
    }
  }

  initData = async () => {
    let { input, leftDepartureTime, leftRouteId, routeId, departureDate } = this.props
    let tripChoices = await this.getRightTrip(routeId, departureDate, leftDepartureTime)
    tripChoices = tripChoices.map(ele => {
      let { departureTime, vehicle = {} } = ele
      let { plate } = vehicle
      let search = `${moment(departureTime).format('HH:mm')}-${plate}`
      ele = { ...ele, search }
      return ele
    })
    let tripDefault = getEarliestLeftTrip(leftRouteId, leftDepartureTime, tripChoices, departureDate, routeId)
    this.setState({ tripChoices, tripDefault })
    input.onChange(tripDefault)
  }

  async componentDidMount() {
    await this.initData()
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { routeId: prevRouteId, departureDate: prevDepartureDate } = prevProps
    let { routeId, departureDate } = this.props
    const updated = !(prevRouteId === routeId && prevDepartureDate === departureDate)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      await this.initData()
    }
  }

  render() {
    let { changTrip, input, label, translate, classes } = this.props
    let { tripChoices = [] } = this.state
    return <FuzzySelectInput
      choices={tripChoices}
      label={label}
      onChange={changTrip}
      renderOptionText={({ departureTime, vehicle = {}, seatAvailable }) => `${moment(departureTime).format('HH:mm')} - ${vehicle.plate} (${seatAvailable} ${translate('resources.reservations.empty_place')})`}
      renderItem={({ departureTime, vehicle = {}, seatAvailable }) =><div className={classes.textContainer}>
        <div className={classes.vehicle}>
          {moment(departureTime).format('HH:mm')}&nbsp;-&nbsp;{vehicle.plate}
        </div>
        <div className={classes.place}>
          (<b>{seatAvailable}&nbsp;{translate('resources.reservations.empty_place')}</b>)
        </div>
      </div>}
      input={input}
      {...defaultFuzzySearch({ name: 'search' })}
    />
  }
}

const enhanceTripSelectInput = compose(translate, withStyles(tripSelectInputStyle), addField)
const TripSelectInput = enhanceTripSelectInput(_TripSelectInput)

class _SwapTripDialog extends Component {

  state = {
    selectedSeats: {},
    swapSelectedSeat: {},
    leftFloors: [],
    loading: false,
  }

  componentDidMount() {
    let { leftTrip, leftFloors, leftCharges } = this.props
    let { routeId, departureTime } = leftTrip
    this.setState({
      leftFloors,
      leftCharges,
      filter: {
        routeId,
        departureDate: moment(departureTime).startOf('day'),
      }
    })
  }

  getTrip = async tripId => {
    let response = await Provider.dataProvider('GET_ONE', 'trips', {
      id: tripId,
      filter: {
        include: {
          relation: 'vehicle',
          scope: {
            fields: ['name', 'plate', 'type'],
            include: {
              relation: 'typeObj',
              scope: {
                fields: 'seatMap',
                include: {
                  relation: 'seatMapObj',
                  scope: { fields: ['codesFistFloor', 'codesSecondFloor'] }
                },
              }
            }
          }
        },
        fields: ['id', 'departureTime', 'arrivalTime', 'arrival', 'departure', 'routeId', 'type', 'vehicleId', 'status', 'companyId']
      }
    })
    if (response) return response.data
  }

  getCharges = async tripId => {
    let response = await Provider.dataProvider('GET_LIST', 'charges', {
      filter: {
        where: { tripId, status: { nin: ['30FINISH', '20CANCEL'] }, cancelReference: null },
        include: {
          relation: 'reservation',
          scope: {
            include: [
              { relation: 'bookingInformation', scope: { fields: ['id', 'contact'] } },
              { relation: 'paymentInformation', scope: { fields: ['total', 'dept', 'paid'] } },
              { relation: 'agency', scope: { fields: 'name' } },
            ],
          },
        },
      },
      ...GET_LIST_PAGINATION,
    })
    if (response) return response.data
  }

  getTripAndFares = async (tripId) => {
    let trip = await this.getTrip(tripId)
    let fareTable
    if (trip) {
      fareTable = await FareTable.createFromTrip(trip)
    }
    return [trip, fareTable]
  }

  getTripCampaigns = async (tripId) => {
    let response = await Provider.dataProvider('REMOTE', 'trips', {
      method: 'findTripCampaigns',
      requestMethod: 'POST',
      data: { ids: [tripId] }
    })
    if (response) return response.data
  }

  updateSeats = (charges, seatMap) => {
    let { codesFistFloor: floor1, codesSecondFloor: floor2 } = seatMap || {}
    let floors = [floor1]
    if (floor2) {
      floors.push(floor2)
    }
    floors = floors.map(
      floor => floor.map(
        row => row.map(
          seat => ({
            seat,
            charge: seat ? charges[seat] : undefined,
          })
        )
      )
    )
    return floors
  }

  updateLeftSeatMap = (oldChargeIds) => {
    let { leftVehicle } = this.props
    let { typeObj = {} } = leftVehicle || {}
    let { seatMapObj } = typeObj
    let { leftFloors, leftCharges } = this.state
    let updatedCharges = {}
    for(let code in leftCharges) {
      let charge = leftCharges[code]
      let { id: chargeId } = charge
      if (oldChargeIds.indexOf(chargeId) < 0) {
        updatedCharges[code] = charge
      }
    }
    leftFloors = this.updateSeats(updatedCharges, seatMapObj)
    this.setState({ leftFloors, leftCharges: updatedCharges })
  }

  getTripInfo = async (tripId, chargeOnly = false) => {

    let { rightInfo = {} } = this.state
    let { trip, charges, fareTable, allStop, campaigns, tripCampaigns } = rightInfo
    if (!chargeOnly) {
      [trip, fareTable] = await this.getTripAndFares(tripId)
      charges = await this.getCharges(tripId)
      let res = await this.getTripCampaigns(tripId)
      campaigns = res.campaigns
      tripCampaigns = res.tripCampaigns
    } else {
      charges = await this.getCharges(tripId)
    }

    if (trip && charges) {
      let reservationChargeCount = charges.reduce(function (acc, charge) {
        let { reservationId } = charge
        acc[reservationId] = (acc[reservationId] || 0) + 1
        return acc
      }, {})

      charges = _.mapKeys(charges, charge => charge.itemDetail.itemCode)
      // checks it's a double deck vehicle
      let { typeObj = {} } = trip.vehicle || {}
      let { seatMapObj } = typeObj
      let floors = this.updateSeats(charges, seatMapObj)
      this.setState({
        rightInfo: {
          trip,
          floors,
          charges,
          fareTable,
          reservationChargeCount,
          allStop,
          tripCampaigns,
          campaigns,
        }
      })
    }
  }

  multipleSelect = (code, event, swapChoosen) => {
    event.preventDefault()
    event.stopPropagation()
    let { selectedSeats, leftCharges, swapSelectedSeat, reservationSelected = {} } = this.state
    if (!swapChoosen) {
      let selectedCode = leftCharges[code]
      let currentReservationId = reservationSelected.id
      let newReservationId = selectedCode.reservationId
      if (currentReservationId !== newReservationId) {
        selectedSeats = {}
      }
      if (code in selectedSeats) {
        delete (selectedSeats[code])
      } else {
        selectedSeats[code] = 1
      }
      this.setState({ selectedSeats, reservationSelected: { id: newReservationId } })
    } else {
      if (!selectedSeats || _.isEmpty(selectedSeats)) {
        this.setState({
          warningDialog: {
            open: true,
          }
        })
        return
      }
      if (code in swapSelectedSeat) {
        delete (swapSelectedSeat[code])
      } else {
        let selectedSeatsLen = Object.values(selectedSeats).length
        let swapSelectedSeatLen = Object.values(swapSelectedSeat).length
        if (selectedSeatsLen === swapSelectedSeatLen) {
          let codes = Object.keys(swapSelectedSeat)
          let lastestCode = _.last(codes)
          delete (swapSelectedSeat[lastestCode])
          swapSelectedSeat[code] = 1
          let reservationId = reservationSelected.id
          this.swapSeat(selectedSeats, swapSelectedSeat, reservationId)
        } else if (swapSelectedSeatLen < selectedSeatsLen) {
          swapSelectedSeat[code] = 1
          swapSelectedSeatLen = Object.values(swapSelectedSeat).length
          if (selectedSeatsLen === swapSelectedSeatLen) {
            let reservationId = reservationSelected.id
            this.setState({ swapLoading: true }, () => {
              this.swapSeat(selectedSeats, swapSelectedSeat, reservationId) 
            })
          }
        }
        this.setState({ swapSelectedSeat })
      }
    }
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  onSwapSeat = (oldChargeIds, newCharges, reservationId) => {
    let { showNotification, leftTrip } = this.props
    let oldTripId = leftTrip.id
    let { tripId: newTripId, confirmSwapSeatDialog = {} } = this.state
    let { open: openConfirmSwapSeatDialog } = confirmSwapSeatDialog
    this.startLoading()
    Provider.dataProvider('REMOTE', 'reservations', {
      method: 'swapSeat',
      requestMethod: 'POST',
      data: { oldTripId, oldChargeIds, newTripId, newCharges, reservationId }
    }).then(() => {
      this.getTripInfo(newTripId, true)
      this.updateLeftSeatMap(oldChargeIds)
      this.endLoading()
      showNotification('notification.swap_seat_success')
    }).catch((e) => {
      this.endLoading()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
    if (openConfirmSwapSeatDialog) {
      this.onCloseConfirmSwapSeatDialog()
    }
    this.setState({
      selectedSeats: {},
      swapSelectedSeat: {},
    })
  }

  getCurrentCampaign = () => {
    let { rightInfo } = this.state
    let { campaigns, tripCampaigns } = rightInfo
    let campaign
    if (!_.isEmpty(tripCampaigns)) {
      let campaignId = Object.values(tripCampaigns)[0][0]
      campaign = campaigns[campaignId]
    }

    let { voucherCampaign } = this.state
    if (voucherCampaign) campaign = voucherCampaign
    return campaign
  }

  buildCharges = (charges) => {
    let { rightInfo } = this.state
    let { fareTable } = rightInfo
    let currentFares = fareTable.getPriceTable()
    let currentCampaign = this.getCurrentCampaign()
    let totalDiff = 0
    for(let i = 0; i < charges.length; i++) {
      let charge = charges[i]
      if (charge) {
        let chargeFare = {}
        let { itemDetail, amount: prevAmount } = charge
        let { fare = {} } = itemDetail
        let { column: code } = fare
        let fareIdx = _.findIndex(currentFares, { key: code })
        fareIdx = fareIdx >= 0 ? fareIdx : 0
        let currentFare = currentFares[fareIdx]
        chargeFare = fareTable.getChargeFare({ index: fareIdx, code }) 
        itemDetail = { ...itemDetail, ...chargeFare }
        let discount = 0
        let campaignId = 0
        if (currentCampaign) {
          discount = getCampaignDiscountByPrice(currentCampaign.promotion, currentFare.value)
          campaignId = currentCampaign.id
        }
        itemDetail = { ...itemDetail, discount, campaignId }
        charge.itemDetail = itemDetail
        let newAmount = currentFare.value - discount
        charge.amount = newAmount
        totalDiff +=(prevAmount - newAmount)
      }
    }
    return { charges, totalDiff }
  }

  swapSeat = (oldSeats, newSeats, reservationId) => {
    let { leftCharges } = this.props
    let { tripId: newTripId } = this.state
    let oldChargeIds = []
    let index = 0
    let swappedCharges = []
    newSeats = Object.keys(newSeats)
    for (let seat in oldSeats) {
      let charge = leftCharges[seat]
      if (charge) {
        let newSeat = newSeats[index]
        let { id, itemDetail, amount } = charge
        itemDetail.itemCode = newSeat
        let swappedCharge = { itemDetail, amount, tripId: newTripId }
        swappedCharges.push(swappedCharge)
        oldChargeIds.push(id)
      }
      index++
    }
    let { charges: newCharges, totalDiff } = this.buildCharges(swappedCharges)
    if (totalDiff !== 0) {
      this.setState({
        confirmSwapSeatDialog: {
          open: true,
          totalDiff,
          oldChargeIds,
          newCharges,
          reservationId,
        },
      })
    } else {
      this.onSwapSeat(oldChargeIds, newCharges, reservationId)
    }
  }

  singleSelect = (code, swapChoosen) => {
    let { selectedSeats: currentSelectedSeats, reservationSelected } = this.state
    let { leftCharges = {} } = this.props
    if (!swapChoosen) {
      let { reservationId } = leftCharges[code] || {}
      let selectedSeats = { [code]: 1 }
      this.setState({ selectedSeats, reservationSelected: { id: reservationId } })
    } else {
      if (!currentSelectedSeats || _.isEmpty(currentSelectedSeats)) {
        this.setState({
          warningDialog: {
            open: true,
          }
        })
        return
      }
      let swapSelectedSeat = { [code]: 1 }
      let currentSelectedSeatsLen = Object.values(currentSelectedSeats).length
      let swapSelectedSeatLen = Object.values(swapSelectedSeat).length
      this.setState({ swapSelectedSeat })
      if (currentSelectedSeatsLen === swapSelectedSeatLen) {
        this.setState({ swapLoading: true }, () => {
          let reservationId = reservationSelected.id
          this.swapSeat(currentSelectedSeats, swapSelectedSeat, reservationId) 
        })
      }
    }
  }

  selectReservation = (code) => {
    let { leftCharges, selectedSeats: currentSelectedSeats } = this.state
    if (!currentSelectedSeats || _.isEmpty(currentSelectedSeats)) {
      return
    }
    let charge = leftCharges[code]
    if (!charge) return
    let selectedSeats = {}
    let { reservationId } = charge
    for (let chargeCode in leftCharges) {
      let { reservationId: rId } = leftCharges[chargeCode]
      if (reservationId === rId) {
        selectedSeats[chargeCode] = 1
      }
    }
    this.setState({ selectedSeats })
  }

  onCloseConfirmSwapSeatDialog = () => {
    this.setState({
      confirmSwapSeatDialog: {
        open: false,
        totalDiff: 0,
        oldChargeIds: [],
        newCharges: [],
        reservationId: 0,
      },
      swapSelectedSeat: {},
    })
  }

  onOkConfirmSwapSeatDialog = (oldChargeIds, newCharges, reservationId) => {
    this.onSwapSeat(oldChargeIds, newCharges, reservationId)
  }

  onCloseWarningDialog = () => {
    this.setState({
      warningDialog: {
        open: false,
      },
    })
  }

  render() {
    let { 
      open,
      reservationId,
      onClose,
      isCompanyManager,
      classes,
      leftVehicle,
      leftTrip,
      leftLicensePlateNum,
      leftFareTable,
      leftIsDoubleDeck,
      leftStyleSeatLayout,
      leftReservationChargeCount,
      leftRelatedSeats,
      leftAllStop,
      leftOnlyView,
      translate,
    } = this.props
    let { 
      selectedSeats,
      rightInfo,
      leftFloors,
      swapSelectedSeat,
      filter,
      loading,
      confirmSwapSeatDialog = {},
      warningDialog = {},
    } = this.state
    let { open: openConfirmSwapSeatDialog, oldChargeIds, newCharges, totalDiff, reservationId: selectedReservationId } = confirmSwapSeatDialog
    let { open: openWarningDialog } = warningDialog
    return <Fragment>
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
      >
        <DialogTitle>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <IconButton
                color="inherit"
                onClick={onClose}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" color="inherit">{translate('button.swap_trip')}</Typography>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <Grid container className={classes.root}>
            <Grid item className={classes.gridLeft} md={6} xs={6}>
              <SeatMapLeft
                isCompanyManager={isCompanyManager}
                reservationId={reservationId}
                trip={leftTrip}
                vehicle={leftVehicle}
                licensePlateNum={leftLicensePlateNum}
                fareTable={leftFareTable}
                isDoubleDeck={leftIsDoubleDeck}
                styleSeatLayout={leftStyleSeatLayout}
                selectedSeats={selectedSeats}
                swapSelectedSeat={{}}
                reservationChargeCount={leftReservationChargeCount}
                relatedSeats={leftRelatedSeats}
                allStop={leftAllStop}
                onlyView={leftOnlyView}
                singleSelect={this.singleSelect}
                multipleSelect={this.multipleSelect}
                selectReservation={this.selectReservation}
                panelSelected={true}
                floors={leftFloors}
              />
            </Grid>
            <Grid item className={classes.gridRight} md={6} xs={6}>
              <SimpleForm
                record={filter}
                toolbar={false}
                style={{ padding: 0 }}
                resource="trips"
                validate={(value) => {
                  let { tripId: newTripId } = value
                  let { tripId: oldTripId } = this.state
                  if (oldTripId !== newTripId) {
                    this.getTripInfo(newTripId)
                    this.setState({ tripId: newTripId })
                  }
                }}
              >
                <Grid container fullWidth className={classes.searchForm}>
                  <Grid item md={3} xs={3}>
                    <ReferenceInput
                      label="resources.trips.fields.newRouteId"
                      reference="routes"
                      resource="trips"
                      source="routeId"
                      filter={{ 
                        status: { neq: '20ARCHIVED' },
                        '../fields': ['id', 'name']
                      }}
                      fullWidth
                      perPage={1000}
                    >
                      <FuzzySelectInput
                        renderItem={item => item.name}
                        {...defaultFuzzySearch()}
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item md={3} xs={3}>
                    <DatePickerInput
                      resource="trips"
                      keyboard={true}
                      label="resources.trips.fields.newDepartureDate"
                      source="departureDate"
                      fullWidth
                    />
                  </Grid>
                  <FormDataConsumer>
                    {({ formData = {} }) => {
                      let { routeId, departureDate } = formData
                      return <Grid item md={6} xs={6}>
                        <TripSelectInput
                          label="resources.trips.fields.newDepartureTime"
                          resource="trips"
                          source="tripId"
                          leftDepartureTime={leftTrip.departureTime}
                          leftRouteId={leftTrip.routeId}
                          routeId={routeId}
                          departureDate={departureDate}
                        />
                      </Grid>
                    }}
                  </FormDataConsumer>
                </Grid>
              </SimpleForm>
              {rightInfo && <SeatMapRight
                isCompanyManager={isCompanyManager}
                leftTripId={leftTrip.id}
                rightInfo={rightInfo}
                selectedSeats={{}}
                swapSelectedSeat={swapSelectedSeat}
                singleSelect={this.singleSelect}
                multipleSelect={this.multipleSelect}
                selectReservation={this.selectReservation}
                onCloseDialog={onClose}
              />}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      {loading && <DialogLoading open={loading} />}
      {openConfirmSwapSeatDialog && <SwapSeatConfirmationDialog
        open={openConfirmSwapSeatDialog}
        onClose={this.onCloseConfirmSwapSeatDialog}
        reservationId={selectedReservationId}
        onOk={this.onOkConfirmSwapSeatDialog}
        oldChargeIds={oldChargeIds}
        totalDiff={totalDiff}
        newCharges={newCharges}
      />}
      {openWarningDialog && <WarningDialog
        open={openWarningDialog}
        onClose={this.onCloseWarningDialog}
      />}
    </Fragment>
  }
}

const enhanceDialog = compose(
  connect(null, { showNotification}),
  translate,
  withStyles(dialogStyle)
)
const SwapTripDialog = enhanceDialog(_SwapTripDialog)

class SwapSeatToTripButton extends Component {

  state = {
    open: false,
  }

  onClick = () => {
    this.setState({
      open: true,
    })
  }

  onClose = (chargeOnly = true, tripId) => {
    let { reloadTripLayout } = this.props
    this.setState({
      open: false,
    })
    reloadTripLayout(chargeOnly, tripId)
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      vehicle,
      trip,
      licensePlateNum,
      fareTable,
      isDoubleDeck,
      floors,
      isCompanyManager,
      styleSeatLayout,
      reservationChargeCount,
      reservationId,
      relatedSeats,
      allStop,
      onlyView,
      charges,
      startLoading,
      endLoading,
      canSwapTrip,
      ...props
    } = this.props
    let { open } = this.state
    let rest = sanitize(props, ['reloadTripLayout', 'isSwapMode', 'swapSelectedSeat', 'selectedSeats'])
    return <Fragment>
      {button ? <Button
        color={color}
        onClick={this.onClick}
        className={classes.button}
        disabled={!canSwapTrip}
        {...rest}
      >
        <div className={classes.container}>
          <SwapHorizIcon className={classes.icon} />
          <span>{translate('button.swap_trip')}</span>
        </div>
      </Button> : <Tooltip
        title={translate('button.swap_trip')}
        enterDelay={100}
      >
        <div>
          <IconButton
            className={classes.iconButton}
            onClick={this.onClick}
            disabled={!canSwapTrip}
            {...rest}
          >
            <SwapHorizIcon />
          </IconButton>
        </div>
      </Tooltip>}
      {(open && floors && !_.isEmpty(floors)) && <SwapTripDialog 
        open={open}
        onClose={this.onClose}
        leftTrip={trip}
        leftVehicle={vehicle}
        leftLicensePlateNum={licensePlateNum}
        leftFareTable={fareTable}
        leftIsDoubleDeck={isDoubleDeck}
        leftFloors={floors}
        isCompanyManager={isCompanyManager}
        leftStyleSeatLayout={styleSeatLayout}
        leftReservationChargeCount={reservationChargeCount}
        reservationId={reservationId}
        leftRelatedSeats={relatedSeats}
        leftAllStop={allStop}
        leftOnlyView={onlyView}
        leftCharges={charges}
        startLoading={startLoading}
        endLoading={endLoading}
      />}
    </Fragment>
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(SwapSeatToTripButton)
