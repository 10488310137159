import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles } from '@material-ui/core'
import React from 'react'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import SelectControl from './SelectControl'

const style = {}

const menuIcon = (faIcon = faBars) => (
  <FontAwesomeIcon
    icon={faIcon}
    style={{ width: '0.5em', color: '#CECFD0' }}
  />
)

const DropDownMenu = ({
  classes,
  icon,
  label,
  menu,
  iconButton,
  children,
  faIcon,
  ...rest
}) => (
  <SelectControl
    className={classes.selector}
    icon={icon}
    label={label ? label : menuIcon(faIcon) }
    elements={menu}
    iconButton={iconButton}
    {...rest}
  >
    {children}
  </SelectControl>
)


const enhance = compose(
  withStyles(style),
  translate
)

export default enhance(DropDownMenu)