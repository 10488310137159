import React, { Component } from 'react'
import { Stepper, Step, StepContent, StepLabel, withStyles } from '@material-ui/core'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import compose from 'recompose/compose'
import { translate, ReferenceField, FunctionField } from 'react-admin'

const style = () => ({
  text: {
    fontSize: 13
  },
  label: {
    fontWeight: 'bold'
  }
})

class LicenceValidityList extends Component {
  render() {
    let { translate, classes, datum = [] } = this.props
    return <Stepper nonLinear={true} orientation="vertical">
      {datum.map((data, idx) => (
        <Step active={true} key={idx}>
          <StepLabel icon={<FontAwesomeIcon icon={faClock} />}>
            {moment(data.updatedAt).format('DD/MM/YYYY')}
          </StepLabel>
          <StepContent className={classes.text}>
            <div>
              <span className={classes.label}>{translate('resources.licences.fields.licenceNumber')}:</span>&nbsp;
              <span>{data.licenceNumber}</span>
            </div>
            <div>
              <span className={classes.label}>{translate('resources.drivers.fields.validFrom')}:</span>&nbsp;
              <span>{moment(data.validFrom).format('DD/MM/YYYY')} {translate('notification.to')} {moment(data.validTo).format('DD/MM/YYYY')}</span>
            </div>
            {data.placeOfIssue && <ReferenceField
              basePath="/provinces"
              reference="provinces"
              resource="provinces"
              record={data}
              source="placeOfIssue"
              linkType={false}
            >
              <FunctionField render={ record => <div>
                <span className={classes.label}>
                  {translate('resources.drivers.fields.placeOfIssue')}:
                </span>
                &nbsp;
                <span>{record.name}</span>
              </div>}
              />
            </ReferenceField>}
        
          </StepContent>
        </Step>
      ))}
    </Stepper>
  }
}

const enhance = compose(translate, withStyles(style))

export default enhance(LicenceValidityList)