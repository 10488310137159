import numeral from 'numeral'

export const format = x => {
  if (x && typeof x === 'number') {
    if (x < 1000) {
      return x.toString()
    }
    const rs = numeral(x).format('0,0')
    return rs
  }
  return x
}
