import React, { Component } from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'

const progressStyle = {
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}

const Progress = withStyles(progressStyle)(({ classes }) => <CircularProgress size={24} className={classes.root} />)

class LoadingComponent extends Component {

  state = {
    isLoading: false,
  }

  componentDidMount() {
    let { isLoading } = this.props
    this.setState({ isLoading })
  }

  setLoading = isLoading => {
    this.setState({ isLoading })
  }

  render() {
    let { Component, inside, ...props } = this.props
    let { isLoading } = this.state
    return <div style={{ position: 'relative' }}>
      <Component
        setLoading={this.setLoading}
        isLoading={isLoading}
        {...props}
      />
      {isLoading && <Progress />}
    </div>
  }
}

export function withLoading(Component) {
  return function withLoadingComponent(props) {
    return <LoadingComponent Component={Component} {...props} />
  }
}
