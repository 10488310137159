export const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      height: '100%'
    },
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    primary: {},
    icon: {
      marginRight: 0
    },
    menu: {},
    contentContainer: {},
    extendedIcon: {
      marginRight: theme.spacing.unit,
    },
    fab: {
      position: 'absolute',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2,
    }
  }
}