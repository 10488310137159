import React, { Component } from 'react'
import {
  Datagrid,
  Filter,
  ReferenceField,
  List,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  NumberField,
  TextInput,
  TabbedForm,
  FormTab,
  ArrayInput
} from 'react-admin'
import Create from './../ui/Create'
import Edit from './../ui/Edit'
import FormSave from './FormSave'
import StopTime from './StopTime'
import { Card } from '@material-ui/core'

////////////////////////////////////////////////////////////////////////////////
// List
const StopTimeListFilter = (props) => {

  return (
    <Filter {...props}>
      <TextInput label="resources.common.search" source="q" alwaysOn fullWidth />
      <ReferenceInput label="resources.timetables.name" source="timetableId" resource="timetables"
        reference="timetables" alwaysOn>
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

export class StopTimeList extends Component {
  render() {
    let { ...props } = this.props
    return (
      <List {...props}
        filters={<StopTimeListFilter />}
        sort={{ field: 'departureTime', order: 'ASC' }}
      >
        <Datagrid rowClick="edit">
          <ReferenceField label="resources.timetables.name" source="timetableId"
            reference="timetables">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="departureTime" label="resources.stopTimes.departureTime" />
          <TextField source="arrivalTime" label="resources.stopTimes.arrivalTime" />
          <NumberField source="count" label="resources.stopTimes.totalStop" />
        </Datagrid>
      </List>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Create
export class StopTimeCreate extends Component {
  render() {
    let { ...props } = this.props

    return (
      <Create {...props} nocard>
        <SimpleForm>
          <FormSave />
        </SimpleForm>
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export class StopTimeEdit extends Component {
  render() {
    let { ...props } = this.props
    let id = this.props.id

    return (
      <Edit {...props} nocard >
        <TabbedForm>
          <FormTab label="resources.common.summary">
            <Card fullWidth style={{ marginTop: 16 }}>
              <FormSave id={id} />
            </Card>
          </FormTab>
          <FormTab label="resources.stopTimes.stop">
            <ArrayInput source="data" label="" fullWidth>
              <StopTime />
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Edit>
    )
  }
}