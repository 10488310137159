import React, { Component, Fragment } from 'react'
import {
  CardActions,
  CreateButton,
  ExportButton,
  showNotification,
  translate,
} from 'react-admin'
import {
  Drawer,
  CircularProgress,
  Typography,
  Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {
  Sort as SortIcon,
  Save as SaveIcon,
  Replay as ReplayIcon,
  IndeterminateCheckBox as CollapseIcon,
  AddBox as OpenIcon
} from '@material-ui/icons'
import { DragDropContext } from 'react-dnd'
import Html5Backend from 'react-dnd-html5-backend'
import compose from 'recompose/compose'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import _ from 'lodash'
import ExpansionPanelRoute from './ExpansionPanelRoute'
import ExpansionLayer from './ExpansionDragLayer'

const styles = theme => ({
  raActionButton: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: '7px 8px',
    minWidth: 64,
    fontSize: '0.6964285714285714rem',
    color: '#3f51b5',
    textTransform: 'uppercase',
    lineHeight: '1.4em',
    letterSpacing: '0.02857em',
    minHeight: 32,
    cursor: 'pointer',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: 'rgba(63, 81, 181, 0.08)'
    },
  },
  spanRAButton: {
    width: '100%',
    display: 'inherit',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  spanText: {
    paddingLeft: '0.5em',
  },
  icon: {
    fontSize: 20,
  },
  bottomToolbar: {
    backgroundColor: '#f5f5f5',
    minHeight: 64,
    paddingLeft: 18,
    paddingRight: 18,
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  saveButton: {
    color: '#fff',
    backgroundColor: '#3f51b5',
    padding: '8px 16px',
    fontSize: '0.75rem',
    minHeight: 36,
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    display: 'inline-flex',
    alignItems: 'center',
    border: 0,
    cursor: 'pointer'
  },
  saveIcon: {
    marginRight: theme.spacing.unit,
    fontSize: 18
  },
  saveLoadingIcon: {
    marginRight: theme.spacing.unit,
    color: '#fff'
  },
  disabledButton: {
    color: 'rgba(0, 0, 0, 0.26)',
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    padding: '8px 16px',
    fontSize: '0.75rem',
    minHeight: 36,
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    display: 'inline-flex',
    alignItems: 'center',
    border: 0,
    cursor: 'default',
    pointerEvents: 'none',
    boxShadow: 'none'
  },
  heading: {
    fontWeight: 'bold',
    margin: 'auto 0 auto 15px'
  },
  paper: {
    overflow: 'visible',
    //width: '100%',
  },
  titleContainer: {
    padding: 16,
  },
  title: {
    marginBottom: 16,
  },
  subTitleContainer: {
    fontSize: 13,
    fontStyle: 'italic',
  },
  content: {
    overflowY: 'auto',
    height: 'calc(100% - 62px)'
  },
})

function normalize(rawData) {
  let fares = _.reduce(rawData, (result, product) => {
    let { fare: fares, name: productName } = product
    if (!_.isEmpty(fares)) {
      let productFares = fares.map(fare => {
        return { ...fare, productName }
      })
      result = [...result, ...productFares]
    }
    return result
  }, [])
  let routeFare = _.groupBy(fares, 'routeId')
  return _.reduce(routeFare, (result, fares, routeId) => {
    let firstFare = fares[0]
    if (firstFare) {
      fares = _.sortBy(fares, 'position')
      result = { 
        ...result,
        [routeId]: {
          data: fares,
          name: firstFare.route.name,
          id: parseInt(routeId),
          expanded: true,
        }}
    }
    return result
  }, {})
}

class _SortButton extends Component {

  state = {
    open: false,
    productObject: {},
    loading: false,
    saveLoading: false,
    originProductObject: {},
    isDirty: false,
    routes: [],
    originRoutes: [],
    tempExpanded: {},
    routeNameDragging: '',
    swapFares: [],
    productSelected: [],
    rowsChecked: {},
  }

  handleGetProduct = (index, routeId) => {
    let { productSelected: products, rowsChecked } = this.state
    let routeChecked = rowsChecked[routeId]
    let idx = _.findIndex(products, pos => pos === index)
    if (idx !== -1) {
      _.remove(products, pos => pos === index)
      routeChecked[index] = false
    } else {
      routeChecked[index] = true
      products = [
        ...products,
        index,
      ]
    }
    rowsChecked[routeId] = [...routeChecked]
    this.setState({ productSelected: [...products], rowsChecked })
  }


  toggleDrawer = async () => {
    let resp = await Provider.dataProvider('REMOTE', 'products', {
      method: 'getListProductWithFare',
      requestMethod: 'GET',
    })
    if (resp && resp.data) {
      let productObject = normalize(resp.data)
      let routes = _.reduce(productObject, (result, value, routeId) => {
        if (routeId) {
          result.push(parseInt(routeId))
        }
        return result
      }, [])
      let rowsChecked = _.reduce(productObject, (result, value, routeId) => {
        if (routeId) {
          let { data=[] } = value
          let ids = Object.keys(data)
          result[routeId] = ids.map(id => id = false)
        }
        return result
      }, {})
      this.updateByRoutes(routes, productObject)
      let tempExpanded = _.reduce(routes, (result, route) => {
        result[route] = true
        return result
      }, {})

      this.setState({
        loading: false,
        open: true,
        productObject,
        rowsChecked,
        routes,
        originProductObject: _.cloneDeep(productObject),
        originRoutes: [...routes],
        isDirty: false,
        tempExpanded,
      })
    }
  }

  onClose = () => {
    this.setState({ open: false })
  }

  updateByRoutes = (routes, groupBy) => {
    let count = 0
    routes.forEach(routeId => {
      let { data } = groupBy[routeId]
      groupBy[routeId].data = data.map((fare, fareIdx) => ({
        ...fare,
        position: count + fareIdx + 1
      }))
      count += groupBy[routeId].data.length
    })
  }

  swapPosition = (fromIdx, toIdx, fares = []) => {
    let fromFare = fares[fromIdx]
    let toFare = fares[toIdx]
    if (!fromFare || !toFare) return
    fares[fromIdx] = toFare
    fares[toIdx] = fromFare
    return [...fares]
  }

  swapPositionGroup = (productSelected = [], fares = [], toRouteId) => {
    let { rowsChecked } = this.state
    let routeChecked = rowsChecked[toRouteId]
    productSelected.sort((a, b) => a-b)
    let productTop = productSelected.map(pos => {
      routeChecked[pos] = false
      return fares[pos]
    })
    let productBot = _.pullAll(fares, productTop)
    let products = _.concat(productTop, productBot)
    rowsChecked[toRouteId] = [...routeChecked]
    this.setState({ rowsChecked })
    return [...products]
  }

  reorderItem = (dragIndex, hoverIndex, toRouteId, fromRouteId) => {
    if (toRouteId !== fromRouteId) return
    let { productObject } = this.state
    let { data: fares = [] } = productObject[toRouteId]
    fares = this.swapPosition(dragIndex, hoverIndex, fares)
    productObject[toRouteId].data = fares
    this.setState({ productObject, isDirty: true })
  }

  reorderItems = (productSelected, toRouteId) => {
    let { productObject } = this.state
    let { data: fares = [] } = productObject[toRouteId]
    fares = this.swapPositionGroup(productSelected, fares, toRouteId)
    productObject[toRouteId].data = fares
    this.setState({ productObject, productSelected: [], isDirty: true })
  }

  onDrop = (overIdx, dragId, routeId) => {
    let { originProductObject, swapFares } = this.state
    let { data: fares = [] } = originProductObject[routeId]
    let toFare = fares[overIdx]
    let fromFare = fares.find(ele => ele.id === dragId)
    let { position: fromPosition } = fromFare 
    let { position: toPosition } = toFare 
    fromFare = { ...fromFare, position: toPosition }
    toFare = { ...toFare, position: fromPosition }
    swapFares = [...swapFares, fromFare, toFare ]
    this.setState({ isDirty: true, swapFares })
  }

  onReset = () => {
    let tempExpanded = {}
    Object.keys(this.state.originRoutes).forEach(routeId => {
      tempExpanded[routeId] = true
    })
    this.setState(state => ({
      productObject: _.cloneDeep(state.originProductObject),
      isDirty: false,
      swapFares: [],
      routes: [...state.originRoutes],
      tempExpanded,
    }))
  }

  onSave = async () => {
    let { showNotification } = this.props
    this.setState({ saveLoading: true })
    let { /*swapFares,*/ productObject, routes } = this.state
    Provider.dataProvider('REMOTE', 'fares', {
      method: 'updatePositions',
      requestMethod: 'POST',
      data: {
        fareIds: _.reduce(routes, (acc, routeId) => {
          acc = acc.concat(_.map(productObject[routeId].data, it => it.id))
          return acc
        }, [])
      }
    }).then(() => {
      this.setState({
        saveLoading: false,
        open: false,
        openingPanelRoute: false,
        swapFares: [],
      })
      showNotification('resources.common.saved')
    }).catch(() => {
      this.setState({ saveLoading: false, openingPanelRoute: false })
      showNotification('resources.fares.error.save', 'warning')
    })
  }

  handleChangePanel = panel => (event, expanded) => {
    let { productObject, tempExpanded } = this.state
    tempExpanded[panel] = expanded
    this.setState({ 
      productObject: {
        ...productObject,
        [panel]: {
          ...productObject[panel],
          expanded
        }
      },
      tempExpanded
    })
  }

  collapseAll = () => {
    let { productObject, routes, tempExpanded } = this.state
    routes.forEach(routeId => {
      productObject[routeId].expanded = false
      tempExpanded[routeId] = false
    })
    this.setState({ productObject, tempExpanded })
  }

  openAll = () => {
    let { productObject, routes, tempExpanded } = this.state
    routes.forEach(routeId => {
      productObject[routeId].expanded = true
      tempExpanded[routeId] = true
    })
    this.setState({ productObject, tempExpanded })
  }

  onDropExpan = () => {
    let { routes, productObject, tempExpanded } = this.state
    this.updateByRoutes(routes, productObject)
    Object.keys(productObject).forEach(routeId => {
      productObject[routeId].expanded = tempExpanded[routeId]
    })
    this.setState({ isDirty: true, productObject })
  }

  reorderExpansion = (dragIndex, hoverIndex) => {
    let { routes } = this.state
    let dragId = routes.splice(dragIndex, 1)
    routes.splice(hoverIndex, 0, ...dragId)
    this.setState({ routes })
  }

  beginDrag = (routeId) => {
    let { productObject, routes, routeNameDragging } = this.state
    routes.forEach(routeId => {
      productObject[routeId].expanded = false
    })
    routeNameDragging = productObject[routeId].name
    this.setState({ productObject, routeNameDragging })
  }

  render() {
    let { classes, translate } = this.props
    let { 
      open, 
      loading, 
      isDirty, 
      saveLoading, 
      routes, 
      productObject,
      rowsChecked,
      productSelected,
      routeNameDragging,
    } = this.state
    return (<Fragment>
      {loading ? <div className={classes.raActionButton}>
        <span className={classes.spanRAButton}>
          <CircularProgress size={16} />
          <span className={classes.spanText}>Loading</span>
        </span>
      </div> : <div
        className={classes.raActionButton}
        onClick={() => this.toggleDrawer(true)}
      >
        <span className={classes.spanRAButton}>
          <SortIcon className={classes.icon} />
          <span className={classes.spanText}>{translate('resources.common.sort')}</span>
        </span>
      </div>}
      <Drawer
        anchor="right"
        open={open}
        onClose={this.onClose}
        classes={{
          paper: classes.paper
        }}
      >
        <ExpansionLayer routeName={routeNameDragging} />
        <div className={classes.titleContainer}>
          <Typography className={classes.title} variant="h4"> 
            {translate('resources.products.sort.sortTitle')}
          </Typography>
          <div className={classes.subTitleContainer}>
            <Typography variant="subtitle1">
              {translate('resources.products.sort.sortFare')}
            </Typography>
            <Typography variant="subtitle1">
              {translate('resources.products.sort.sortRoute')}
            </Typography>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            color="primary" 
            style={{ margin: 5 }}
            onClick={this.openAll}
          >
            <OpenIcon style={{ marginRight: 5 }} />
            {translate('resources.products.openAll')}
          </Button>
          <Button 
            color="primary" 
            style={{ margin: 5 }}
            onClick={this.collapseAll}
          >
            <CollapseIcon style={{ marginRight: 5 }} />
            {translate('resources.products.collapseAll')}
          </Button>
          <div style={{ width: 10 }}></div>
        </div>
        <div className={classes.content}>
          {routes.map((routeId, index) => {
            let route = productObject[routeId]
            let routeChecked = rowsChecked[routeId]
            return <ExpansionPanelRoute
              key={routeId}
              route={{...route}}
              reorderItem={this.reorderItem}
              reorderItems={this.reorderItems}
              handleGetProduct={this.handleGetProduct}
              productSelected={productSelected}
              onDrop={this.onDrop}
              handleChangePanel={this.handleChangePanel}
              routeIndex={index}
              reorderExpansion={this.reorderExpansion}
              onDropExpan={this.onDropExpan}
              beginDrag={this.beginDrag}
              routeChecked={routeChecked}
            />
          })}
        </div>
        <div className={classes.bottomToolbar}>
          <button
            className={classes.saveButton}
            onClick={() => {
              if (saveLoading) return
              this.onSave()
            }}
          >
            {saveLoading ? <CircularProgress
              className={classes.saveLoadingIcon}
              size={16}
            /> : <SaveIcon className={classes.saveIcon} />}
            <span>{translate('button.save')}</span>
          </button>
          <button
            className={isDirty ? classes.saveButton : classes.disabledButton}
            style={{ marginLeft: 16 }}
            onClick={this.onReset}
          >
            <ReplayIcon className={classes.saveIcon} />
            {translate('button.reset')}
          </button>
        </div>
      </Drawer>
    </Fragment>)
  }
}

const SortButton = compose(
  DragDropContext(Html5Backend),
  withStyles(styles),
  connect(null, { showNotification }),
  translate
)(_SortButton)

const ProductAction = ({
  basePath,
  currentSort,
  exporter,
  filterValues,
  resource,
  total
}) => (
  <CardActions>
    <SortButton />
    <CreateButton basePath={basePath} />
    <ExportButton
      disabled={total===0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
)

export default ProductAction
