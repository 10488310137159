import React,{ Component } from 'react'
import {
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Divider,
} from '@material-ui/core'
import CardAvatar from '../common/CardAvatar'
import { 
  translate,
  ReferenceField,
  TextField,
  FunctionField,
} from 'react-admin'
import compose from 'recompose/compose'
import moment from 'moment'

const cardStyle = ()  => ({
  card: {
    marginTop: 8
  },
  cardActions: {
    padding: 0
  },
  textContainer: {
    fontSize: 13,
    marginBottom: 4,
    display: 'flex',
  },
  label: {
    fontWeight: 'bold'
  },
  textInReferenceField: {
    fontSize: 13,
    lineHeight: 'normal',
  },
  avatarCol: {
    marginTop: 12,
    marginBottom: 8,
    display: 'flex',
    marginLeft: 10,
  },
  name: {
    lineHeight: 'normal',
    padding: 0,
    marginLeft: 8,
    fontSize: 13,
  },
})

class _ProductsGroupCard extends Component {
  render() {
    let { record, translate, classes, history, } = this.props
    let { name, routeCount, id } = record
    return <div className={classes.card} >
      <Card onClick={() => history.push(`/products/${id}`)}>
        <FunctionField
          className={classes.textContainer}
          record={record} 
          render={record => {
            return(
              <div className={classes.avatarCol}>
                <CardAvatar record={record} size='small' />
                <CardHeader className={classes.name} title={name} />
              </div>
            )}}
        />
        <Divider/>
        <CardContent>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.products.fields.type')}:</span>&nbsp;<span>              
              <ReferenceField
                basePath="/producttypes"
                resource="producttypes"
                reference="producttypes"
                record={record}                
                source="type"
                allowEmpty
                linkType={false}
              >
                <TextField className={classes.textInReferenceField} source="name" />
              </ReferenceField>
            </span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.products.fields.validDate')}:</span>&nbsp;<span>              
              <FunctionField 
                source="validDate"
                className={classes.textInReferenceField}
                sortable={false}
                render={({ validFrom, validTo }) => `${moment(validFrom).format('HH:mm DD/MM/YYYY')} - ${moment(validTo).format('HH:mm DD/MM/YYYY')}`}
              />
            </span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.products.fields.quantity')}:</span>&nbsp;<span>              
              <FunctionField 
                source="quantity"
                className={classes.textInReferenceField}
                render={({ quantity }) => quantity < 0 ? translate('resources.products.no_limit') : (quantity ? quantity.toLocaleString() : 0)}
              />
            </span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.products.fields.agencyCount')}:</span>&nbsp;<span>              
              <FunctionField
                sortable={false}
                className={classes.textInReferenceField}
                source="agencyCount"
                render={({ agencyCount = 0 }) => agencyCount > 0 ? agencyCount - 1 : 0}
              />
            </span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.products.fields.routeCount')}:</span>&nbsp;<span>{routeCount}</span>
          </div>
        </CardContent>
      </Card>
    </div>
  }
}

const enhance = compose(translate, withStyles(cardStyle))
const ProductsGroupCard = enhance(_ProductsGroupCard)

const style = {
  root: {
    padding: 8
  }
}

class ProductsMobileGrid extends Component {
  render() {
    let { ids, data, classes, companyManager, history, translate } = this.props    
    return <div> 
      {ids.map( id => { 
        return <ProductsGroupCard  
          key={id}
          record={data[id]}
          classes={classes}
          companyManager={companyManager}
          history={history}
          translate={translate}
        />
      })}
    </div>     
  }
}

export default withStyles(style)(ProductsMobileGrid)
