import React, { Component } from 'react'
import { ReferenceField, TextField, ReferenceManyField, translate, FunctionField } from 'react-admin'
import Snackbar from '../common/Snackbar'
import { ListItem, ListItemAvatar, Avatar, ListItemText, Typography, Chip } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import { DriverIcon } from '../icons'
import { getDays } from '../utils/time'
import moment from 'moment'
import red from '@material-ui/core/colors/red'

// const label = {
//   vehicles: 'resources.vehicles.name',
//   drivers: 'resources.drivers.name',
// }

const typeMap = {
  '00VNS': { // Giáy đăng kiểm
    resourceAssign: 'licencevehicles',
    resource: 'vehicles',
    optionText: 'plate',
    source: 'vehicleId',
  },
  '20VNR': { // Bảo hiểm xe
    resourceAssign: 'licencevehicles',
    resource: 'vehicles',
    optionText: 'plate',
    source: 'vehicleId',
  },
  '10LID': { // Bằng lái xe
    resourceAssign: 'licencedrivers',
    resource: 'drivers',
    optionText: 'fullName',
    source: 'driverId',
  },
  '30VRC': { // Giấy đăng ký xe
    resourceAssign: 'licencevehicles',
    resource: 'vehicles',
    optionText: 'plate',
    source: 'vehicleId',
  },
}


export const ExpiredText = translate(({ validTo, fontSize, translate }) => {
  let currentDate = moment(new Date()).startOf('day').toISOString()
  let days = getDays(currentDate, validTo)
  let colorText = 'green'
  if (days < 14) {
    colorText = red[500]
  }
  return days > 0
    ? <span style={{ color: colorText, fontSize }}>{translate('notification.days_expired', { days })}</span>
    : <span style={{ color: colorText, fontSize }}>{translate('notification.licence_expired')}</span>
})

const ObjectText = ({
  resourceChild,
  source,
  optionText,
  data,
  ids,
  icon,
  companyManager,
}) => {
  if (!data || !ids) return null
  let record = data[ids[0]]
  return (
    <Typography
      variant="subtitle1"
      component="span"
    >
      <ReferenceField
        basePath={`/${resourceChild}`}
        reference={`${resourceChild}`}
        resource={`${resourceChild}`}
        record={record}
        source={`${source}`}
        linkType={!!companyManager}
      >
        {icon ? (
          <FunctionField render={(record) => <Chip avatar={icon} label={record[optionText]} />} />
        ) : (
          <TextField source={`${optionText}`} />
        )}
      </ReferenceField>
    </Typography>
  )
}

const MessageComponent = ({ record, showText, translate, icon, companyManager }) => {
  let { type, status, id } = record
  let tm = typeMap[type] || {}
  let { resourceAssign, resource, optionText, source } = tm
  return status === '30UNA'
    ? translate('notification.licence_unassign')
    : (
      resourceAssign ? (
        <ReferenceManyField
          basePath={`/${resourceAssign}`}
          reference={`${resourceAssign}`}
          resource={`${resourceAssign}`}
          record={{}}
          filter={{ licenceId: id }}
          target=""
        >
          <ObjectText
            translate={translate}
            showText={showText}
            resourceChild={resource}
            optionText={optionText}
            source={source}
            icon={icon}
            companyManager={companyManager}
          />
        </ReferenceManyField>
      ) : null
    )
}

const Icon = ({ resource }) => (
  <Avatar>
    {resource === 'vehicles'
      ? <FontAwesomeIcon icon={faBus} />
      : <DriverIcon />
    }
  </Avatar>
)

class ObjectLicence extends Component {
  render() {
    let { record, showText, style, showWithChip, translate, companyManager } = this.props
    let { type, status, validTo } = record
    let tm = typeMap[type] || {}
    let { resource } = tm
    let assigned = status !== '30UNA'

    return (
      assigned ? (
        !showText ? (
          showWithChip ? (
            <MessageComponent
              translate={translate}
              showText={showText}
              record={record}
              icon={<Icon resource={resource} />}
              companyManager={companyManager}
            />
          ) : (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  {resource === 'vehicles'
                    ? <FontAwesomeIcon icon={faBus} size="2x" />
                    : <DriverIcon />
                  }
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={<MessageComponent
                  translate={translate}
                  showText={showText}
                  record={record}
                  companyManager={companyManager}
                />}
                secondary={<ExpiredText validTo={validTo} />}
              />
            </ListItem>
          )) : (
          <MessageComponent
            translate={translate}
            showText={showText}
            record={record}
            companyManager={companyManager}
          />
        )) : (
        <Snackbar
          style={{ ...style }}
          variant='warning'
          message={<MessageComponent translate={translate} showText={showText} record={record} />}
        />
      )
    )
  }
}

ObjectLicence.defaultProps = {
  record: {}
}

export default translate(ObjectLicence)