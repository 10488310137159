import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  SimpleForm,
  Edit,
  SingleFieldList,
  ReferenceArrayField,
  ChipField,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import { validate } from './validate'
import CustomToolbar from '../common/CustomToolbarForm'
import { isCompanyManager } from '../utils/permission'

////////////////////////////////////////////////////////////////////////////////
// Service
export const ServiceList = ({ permissions, ...props}) => {
  let companyManager = isCompanyManager(permissions)
  return (
    <List bulkActionButtons={false} {...props}>
      <Datagrid rowClick={companyManager && 'edit'}>
        <TextField source="name" />
        <TextField source="desc" />
        <ReferenceArrayField reference="servicedays" source="days" sortable={false}>
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
      </Datagrid>
    </List>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Create
export const ServiceCreate = props => (
  <Create
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm toolbar={<CustomToolbar />} validate={validate} >
      <FormSave />
    </SimpleForm>
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit
export const ServiceEdit = props => (
  <Edit
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm toolbar={<CustomToolbar />} validate={validate} >
      <FormSave />
    </SimpleForm>
  </Edit>
)
