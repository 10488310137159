import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import {
  DialogActions,
  DialogTitle,
  Button,
  withStyles,
  Dialog,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Drawer,
  CardContent,
  Typography,
  Divider,
  Popover,
  Card,
  CardHeader,
  Avatar,
  IconButton,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Tooltip,
  CardActions,
} from '@material-ui/core'
import classnames from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import { translate, showNotification } from 'react-admin'
import BigCalendar from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import '../../node_modules/react-big-calendar/lib/addons/dragAndDrop/styles.css'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { fullDateTimeFormat, timeFormat, dateFormat } from '../common/format'
import moment from 'moment'
import { limitTextLength } from '../utils/formatUtil'
import { withLoading } from '../common/withLoading'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import CloneTripsRecur from './CloneTripsRecur'
const localizer = BigCalendar.momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(BigCalendar)

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 3,
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.06), 0px 3px 4px 0px rgba(0, 0, 0, 0), 0px 3px 3px -2px rgba(0, 0, 0, 0)',
    borderRadius: 4,
    outline: 'none',
    width: '100%',
    height: '100%',
    paddingTop: 0,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  calendarHeight: {
    height: '800px',
    margin: 'auto',
    minWidth: '100%',
  },
  actionButton: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    alignItems: 'center',
  },
  filterContent: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '10px 0'
  },
  tripCount: {
    color: '#303f9f',
  },
  selectFilter: {
    marginRight: '10px'
  },
  list: {
    width: 250,
  },
  containerFilter: {
    display: 'flex',
    flexDirection: 'column',
    padding: 16
  },
  cardContent: {
    marginTop: 0, paddingTop: 0
  },
  timeInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  textSizeContent: {
    fontSize: 13
  },
  timeInfoItem: {
    display: 'inline-block',
    margin: 4,
    justifyContent: 'center',
  },
  cursor: {
    cursor: 'cell'
  },
  closeButton: {
    width: '24px',
    height: '24px',
    margin: '12px',
  },
  selectGap: {
    marginRight: 30,
    marginTop: 20,
  }
})

class ViewTripsClone extends Component {
  state = {
    events: [],
    openDialog: false,
    event: {},
    filterValue: [],
    id: '0',
    anchorEl: null,
    openDrawer: false,
    value: [],
    checkEvent: {},
    selectSlotEvent: {},
    cursor: false,
    recurInfor: {},
    recurOptions: 0
  }

  componentDidMount = async () => {
    const startOfMonth = moment().startOf('month')
    const endOfMonth = moment().endOf('month')

    const { setLoading } = this.props
    setLoading(true)

    const trips = await Provider.dataProvider('GET_LIST', 'trips', {
      filter: {
        where: {
          and: [
            { departureTime: { gte: startOfMonth } },
            { departureTime: { lte: endOfMonth } },
          ],
        },
        fields: [
          'arrivalTime',
          'assistantDriverId',
          'departureTime',
          'driver2Id',
          'driverId',
          'name',
          'routeId',
          'type',
          'vehicleId',
          'id'
        ],
        include: [{
          relation: 'route',
          scope: {
            fields: ['name', 'color']
          }
        }]
      },
      sort: {},
      pagination: {},
    })
    if (trips) {
      this.groupByKey(trips.data)
      setLoading(false)
    }
  }

  groupByKey = (trips) => {
    const newTrips = trips.map((trip) => {
      let { departureTime, routeId, arrivalTime } = trip
      return {
        ...trip,
        groupKey: `${moment(departureTime).format(
          fullDateTimeFormat
        )}/${moment(arrivalTime).format(
          fullDateTimeFormat
        )}/${routeId}`,
      }
    })

    this.setState({ newTrips, id: '0' })
    this.loadData(newTrips)
    this.loadFilter(newTrips)
  }

  loadData = (newTrips) => {
    let arrayResult = _.chain(newTrips)
      .groupBy('groupKey')
      .map((value, key) => ({ groupKey: key, value }))
      .value()

    const events = arrayResult.map((trip, index) => {
      return {
        id: index,
        title: this.props.translate('resources.viewtripsclone.countTrips', {
          count: trip.value.length,
        }),
        start: new Date(moment(trip.groupKey.substring(0, 10)).format()),
        end: new Date(moment(trip.groupKey.substring(11, 21)).format()),
        resource: trip.value,
        routeId: trip.value[0].routeId,
        routeName: trip.value[0].route.name,
        name: trip.value[0].name,
        backgroundColor: trip.value[0].route.color,
        count: trip.value.length,
      }
    })
    this.setState({ events })
  }

  loadFilter = (events) => {
    let arrayResult = _.chain(events)
      .groupBy('routeId')
      .map((value, key) => ({ routeId: key, value }))
      .value()

    const filterValue = arrayResult.map((trip, index) => {
      return {
        id: index,
        resource: trip.value,
        routeId: trip.value[0].routeId,
        name: trip.value[0].name,
        routeName: trip.value[0].route.name,
      }
    })

    this.setState({ filterValue })
  }

  loadDataRecur = (newTrips) => {
    const { events } = this.state
    let arrayResult = _.chain(newTrips)
      .groupBy('groupKey')
      .map((value, key) => ({ groupKey: key, value }))
      .value()

    let newEvents = [...events]
    arrayResult.forEach((trip, index) => {
      newEvents.push({
        id: events.length + index,
        title: this.props.translate('resources.viewtripsclone.countTrips', {
          count: trip.value.length,
        }),
        start: new Date(moment(trip.groupKey.substring(0, 10)).format()),
        end: new Date(moment(trip.groupKey.substring(11, 21)).format()),
        resource: trip.value,
        routeId: trip.value[0].routeId,
        routeName: trip.value[0].route.name,
        name: trip.value[0].name,
        backgroundColor: trip.value[0].route.color,
        count: trip.value.length,
      })
    })
    this.setState({ events: newEvents })
  }

  handleChangeFilter = (id) => {
    const { filterValue, newTrips } = this.state
    if (id !== '0') {
      filterValue.forEach(trip => {
        if (trip.routeId === parseInt(id)) {
          this.setState({ id })
          this.loadData(trip.resource)
        }
      })
    } else {
      this.loadData(newTrips)
      this.setState({
        id: '0'
      })
    }
  }

  getColorEvents = (event) => {
    var style = {
      backgroundColor: event.backgroundColor ? event.backgroundColor : '',
      borderRadius: '8px',
      color: '#fff',
      border: '1px',
      fontSize: '0.8rem',
      padding: '5px'
    }
    return {
      style
    }
  }

  eventOnDrag = (event) => {
    const { showNotification } = this.props
    if (moment().format('YYYYMMDD') <= moment(event.start).format('YYYYMMDD')) {
      this.setState({ openDialog: true, event, action: 'drag' })
    } else {
      showNotification('resources.trips.messages.cloneFailed', 'warning')
      this.setState({
        openDialog: false
      })
    }
  }

  handleCancel = (events) => {
    this.setState({
      anchorEl: null,
      events,
      openDialog: false,
      checkEvent: {},
      event: {},
      recurInfor: {},
      recurOptions: 0,
    })
  }

  handleComfirmDrag = (comfirmEvent) => {
    const { events = [], recurInfor, recurOptions } = this.state

    const { start, end, event } = comfirmEvent

    const newResource = event.resource.map((data) => {
      return { ...data, newDepartureDay: start, newArrivalDay: end }
    })
    const tripIds = event.resource.map((data) => {
      return data.id
    })

    let newDepartureDay = moment(start).format(fullDateTimeFormat).toString()
    let newArrivalDay = moment(end).format(fullDateTimeFormat).toString()

    const updatedEvent = {
      ...event,
      start,
      end,
      id: events.length,
      resource: newResource,
    }
    const nextEvents = [...events, updatedEvent]
    this.onSaveWhenClone(newDepartureDay, newArrivalDay, tripIds, recurInfor, recurOptions, nextEvents)

  }

  onSaveWhenClone = (newDepartureDay, newArrivalDay, tripIds, recurInfor, recurOptions, nextEvents,) => {
    const { showNotification } = this.props
    Provider.dataProvider('REMOTE', 'trips', {
      method: 'cloneTrips',
      requestMethod: 'POST',
      data: {
        newDepartureDay,
        newArrivalDay,
        tripIds,
        recurInfor,
        recurOptions
      },
    })
      .then((res) => {
        console.log(res)
        showNotification('resources.trips.messages.saveSuccess')
      })
      .catch((error) => {
        console.log(error)
        showNotification('resources.trips.messages.saveFailed', 'warning')
      })
      .finally(() => {
        this.setState({
          openDialog: false,
          events: nextEvents,
          event: {},
          checkEvent: {},
          recurInfor: {},
          recurOptions: 0
        })
      })
  }

  eventOnClick = (event, synctheticEvent) => {
    let newValue = event.resource.map(trip => trip.id)

    this.setState({
      anchorEl: synctheticEvent.target,
      event,
      value: newValue,
      checkEvent: {}
    })
  }

  openDrawer = () => {
    this.setState({ openDrawer: true, openDialog: false, anchorEl: null })
  }

  handleEventTimeChecked = (eventValue, isChecked, currentValue) => {
    const { event } = this.state

    let newEvent = []
    let newValue = eventValue.target.value
    newValue = parseInt(newValue) || 0

    if (isChecked) {
      currentValue.push(newValue)
    } else {
      currentValue = currentValue.filter(v => v !== newValue)
    }
    newEvent = event.resource.filter(function (trip) {
      return currentValue.includes(trip.id)
    })

    this.setState({ value: currentValue, checkEvent: { ...event, resource: newEvent } })
  }

  cloneChecked = () => {
    const { event, checkEvent, recurOptions } = this.state
    const { showNotification } = this.props

    if (recurOptions === 0) {
      showNotification('resources.trips.messages.cloneSuccess')
      if (checkEvent && checkEvent.resource) {
        this.setState({
          event: { ...checkEvent },
          checkEvent,
          openDrawer: false,
          anchorEl: null,
          cursor: true,
        })
      } else {
        this.setState({
          event,
          checkEvent: { ...event },
          openDrawer: false,
          anchorEl: null,
          cursor: true,
        })
      }
    } else {
      if (checkEvent && checkEvent.resource) {
        this.setState({
          event: { ...checkEvent },
          checkEvent,
          openDrawer: false,
          anchorEl: null,
          cursor: false,
          openDialog: true,
          action: 'recur',
        })
      } else {
        this.setState({
          event,
          checkEvent: { ...event },
          openDrawer: false,
          anchorEl: null,
          cursor: false,
          openDialog: true,
          action: 'recur',
        })
      }
    }
  }

  onDayClick = (selectSlotEvent) => {
    const { checkEvent } = this.state
    const { showNotification } = this.props
    if (moment().format('YYYYMMDD') <= moment(selectSlotEvent.start).format('YYYYMMDD') && !_.isEmpty(checkEvent)) {
      this.setState({
        openDialog: true,
        selectSlotEvent,
        action: 'click',
        cursor: false
      })
    } else if (moment().format('YYYYMMDD') > moment(selectSlotEvent.start).format('YYYYMMDD') && !_.isEmpty(checkEvent)) {
      showNotification('resources.trips.messages.cloneFailed', 'warning')
    } else {
      return
    }
  }

  handleComfirmCheck = (selectSlotEvent) => {
    const { events = [], checkEvent = {}, recurInfor, recurOptions } = this.state
    const { start, end } = selectSlotEvent

    const endDate = moment(checkEvent.end)
    const startDate = moment(checkEvent.start)
    const diffDay = endDate.diff(startDate, 'days')
    const newEndDay = moment(end).add(diffDay, 'days').toDate()

    const newResource = checkEvent.resource.map((data) => {
      return {
        ...data,
        newDepartureDay: start,
        newArrivalDay: newEndDay,
        backgroundColor: checkEvent.backgroundColor
      }
    })
    const tripIds = checkEvent.resource.map((data) => {
      return data.id
    })

    let newDepartureDay = moment(start).format(fullDateTimeFormat)
    let newArrivalDay = moment(newEndDay).format(fullDateTimeFormat)

    const updatedEvent = {
      ...checkEvent,
      start,
      end: newEndDay,
      id: events.length,
      resource: newResource,
    }
    const nextEvents = [...events, updatedEvent]

    const arrayResult = nextEvents.map(trip => {
      return {
        ...trip,
        title: this.props.translate('resources.viewtripsclone.countTrips', {
          count: trip.resource.length,
        }),
        count: trip.resource.length
      }
    })

    this.onSaveWhenClone(newDepartureDay, newArrivalDay, tripIds, recurInfor, recurOptions, arrayResult)
  }

  handleOptionsChange = (name, value) => {
    const { recurData } = this.state
    recurData[name] = value
    this.setState({ recurData: { ...recurData } })
  }

  onNavigate = async (event) => {
    const startOfMonth = moment(event).startOf('month')
    const endOfMonth = moment(event).endOf('month')
    const { setLoading } = this.props
    setLoading(true)
    const trips = await Provider.dataProvider('GET_LIST', 'trips', {
      filter: {
        where: {
          and: [
            { departureTime: { gte: startOfMonth } },
            { departureTime: { lte: endOfMonth } },
          ],
        },
        fields: [
          'arrivalTime',
          'assistantDriverId',
          'departureTime',
          'driver2Id',
          'driverId',
          'name',
          'routeId',
          'type',
          'vehicleId',
          'id'
        ],
        include: [{
          relation: 'route',
          scope: {
            fields: ['name', 'color']
          }
        }]
      },
      sort: {},
      pagination: {},
    })
    if (trips) {
      this.groupByKey(trips.data)
      setLoading(false)
    }
  }

  getDataRecur = (data) => {
    const { recurInfor, recurOptions } = data
    this.setState({ recurInfor, recurOptions })
  }

  handleSaveRecur = (selectSlotEvent) => {
    const { checkEvent = {}, recurInfor, recurOptions } = this.state
    const { start, end } = selectSlotEvent
    const { showNotification } = this.props

    const tripIds = checkEvent.resource.map((data) => {
      return data.id
    })

    let newDepartureDay = moment(start).format(fullDateTimeFormat)
    let newArrivalDay = moment(end).format(fullDateTimeFormat)

    Provider.dataProvider('REMOTE', 'trips', {
      method: 'cloneTrips',
      requestMethod: 'POST',
      data: {
        newDepartureDay,
        newArrivalDay,
        tripIds,
        recurInfor,
        recurOptions,
        routeId: selectSlotEvent.routeId
      },
    })
      .then((res) => {
        this.loadDataRecur(res.data)
        showNotification('resources.trips.messages.saveSuccess')
      })
      .catch((error) => {
        console.log(error)
        showNotification('resources.trips.messages.saveFailed', 'warning')
      })
      .finally(() => {
        this.setState({
          openDialog: false,
          event: {},
          checkEvent: {},
          recurInfor: {},
          recurOptions: 0,
        })
      })
  }

  render() {
    const {
      events,
      openDialog,
      event,
      action,
      filterValue,
      id,
      anchorEl,
      openDrawer,
      value,
      selectSlotEvent,
      checkEvent,
      cursor,
    } = this.state
    const { classes, translate } = this.props

    return (
      <Grid container className={classes.container} spacing={8}>

        {/* Filter */}
        <Grid item className={classes.filterContent}>
          <FormControl>
            <Select
              className={classes.selectFilter}
              value={id}
              onChange={(e) => { this.handleChangeFilter(e.target.value) }}
            >
              <MenuItem value='0' >{translate('resources.viewtripsclone.allRoute')}</MenuItem>
              {
                filterValue.map(trip => (
                  <MenuItem value={trip.routeId.toString()} >
                    {
                      (trip.routeName.length < 40)
                        ? trip.routeName
                        : limitTextLength(trip.routeName, 30)
                    }
                  </MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <span style={{ color: '#3f51b5' }}>{translate('resources.viewtripsclone.routeFilter')}</span>
        </Grid>

        {/* Calendar */}
        <Grid item className={classnames(classes.calendarHeight, cursor ? classes.cursor : '')}>
          <DragAndDropCalendar
            style={{ height: '90%' }}
            selectable
            localizer={localizer}
            events={events}
            onEventDrop={this.eventOnDrag}
            resizable
            defaultView="month"
            defaultDate={new Date()}
            popup={true}
            views={['month', 'week', 'day']}
            onSelectEvent={this.eventOnClick}
            eventPropGetter={(this.getColorEvents)}
            onSelectSlot={this.onDayClick}
            onNavigate={this.onNavigate}
          />
        </Grid>

        {/* Dialog */}
        <Dialog events={events} open={openDialog} onClose={this.handleClose}>
          {action === 'drag' && (
            <Fragment>
              <DialogTitle id="alert-dialog-title">
                {translate('resources.viewtripsclone.cloneComfirm')}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => this.handleCancel(events)}
                  color="secondary"
                  variant="contained"
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  onClick={() => this.handleComfirmDrag(event)}
                  color="primary"
                  variant="contained"
                >
                  {translate('button.ok')}
                </Button>
              </DialogActions>
            </Fragment>
          )}
          {action === 'click' && (
            <Fragment>
              <DialogTitle id="alert-dialog-title">
                {translate('resources.viewtripsclone.cloneComfirm')}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => this.handleCancel(events)}
                  color="secondary"
                  variant="contained"
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  onClick={() => this.handleComfirmCheck(selectSlotEvent)}
                  color="primary"
                  variant="contained"
                >
                  {translate('button.ok')}
                </Button>
              </DialogActions>
            </Fragment>
          )}

          {action === 'recur' && (
            <Fragment>
              <DialogTitle id="alert-dialog-title">
                {translate('resources.viewtripsclone.cloneComfirm')}
              </DialogTitle>
              <DialogActions>
                <Button
                  onClick={() => this.handleCancel(events)}
                  color="secondary"
                  variant="contained"
                >
                  {translate('button.cancel')}
                </Button>
                <Button
                  onClick={() => this.handleSaveRecur(event)}
                  color="primary"
                  variant="contained"
                >
                  {translate('button.ok')}
                </Button>
              </DialogActions>
            </Fragment>
          )}
        </Dialog>

        {/* Popup */}
        <Popover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => this.handleCancel(events)}
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Card style={{ maxWidth: 800, minWidth: 350 }}>
            <CardHeader
              style={{ padding: '4px 10px' }}
              avatar={<Avatar style={{ backgroundColor: event.backgroundColor }}>{event.count}</Avatar>}
              title={
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h5"
                >
                  {event && limitTextLength(event.name, 30)}
                </Typography>
              }
              action={
                <IconButton
                  color="secondary"
                  onClick={() => this.handleCancel(events)}
                  className={classes.closeButton}>
                  <CloseIcon />
                </IconButton>
              }
            />
            <Divider />
            <CardContent className={classes.cardContent} style={{ padding: '4px 10px' }}>
              <div className={classes.timeInfo}>
                <span className={classnames(classes.timeInfoItem, classes.textSizeContent)}>
                  {translate('resources.trips.fields.departureDay')}:&nbsp;{moment(event.start).format(dateFormat)}
                </span>
              </div>
              <div className={classes.timeInfo}>
                <span className={classnames(classes.timeInfoItem, classes.textSizeContent)}>
                  {translate('resources.trips.fields.arrivalDay')}:&nbsp;{moment(event.end).format(dateFormat)}
                </span>
              </div>

            </CardContent>
            <Divider />
            <CardActions style={{ padding: '6px 10px' }}>
              <Button
                color="primary"
                size="small"
                onClick={this.cloneChecked}
                disabled={checkEvent && checkEvent.resource && !checkEvent.resource.length}
              >
                <FileCopyIcon style={{ marginRight: '5' }} />{translate('resources.viewtripsclone.cloneAll')}
              </Button>
              <Button
                color="secondary"
                size="small"
                onClick={this.openDrawer}
                style={{ marginLeft: '10px' }}
                disabled={checkEvent && checkEvent.resource && !checkEvent.resource.length}
              >
                <FileCopyIcon style={{ marginRight: '5' }} />{translate('resources.viewtripsclone.cloneByTime')}
              </Button>
              <CloneTripsRecur getDataRecur={this.getDataRecur} dayClick={event.start} />
            </CardActions>
          </Card>
        </Popover>

        {/*  Drawer */}
        <Drawer anchor='right' open={openDrawer} onClose={() => this.setState({ openDrawer: false, checkEvent: {} })}>
          <div className={classes.list} role="presentation">
            <CardContent>
              <Typography component="h2" variant="h6">
                {translate('resources.viewtripsclone.cloneTimesTrip')}
              </Typography>
              <Typography component="h2" variant="subtitle1">
                {translate('resources.viewtripsclone.unCheckTime')}
              </Typography>
            </CardContent>
            <Divider />
            <div className={classes.containerFilter}>
              {
                event && event.resource && (
                  <span >
                    {moment(event.resource[0].departureTime).format(dateFormat) + ' - ' + moment(event.resource[0].arrivalTime).format(dateFormat)}
                  </span>
                )
              }
              <FormGroup>
                {
                  event && event.resource && event.resource.map((trip, idx) => {
                    let checked = value.includes(trip.id)
                    return (
                      <FormControlLabel
                        key={idx}
                        checked={checked}
                        value={String(trip.id)}
                        onChange={(event, isChecked) => {
                          this.handleEventTimeChecked(
                            event,
                            isChecked,
                            value,
                          )
                        }}
                        control={
                          <Checkbox
                            id={String(trip.id)}
                            name={String(trip.id)}
                          />
                        }
                        label={moment(trip.departureTime).format(timeFormat) + ' - ' + moment(trip.arrivalTime).format(timeFormat)}
                      />
                    )
                  })
                }
              </FormGroup>
            </div>
            <Divider />
            <div>
              <Tooltip title={translate('resources.viewtripsclone.copyCheckedTime')} placement="right-start">
                <Button
                  color="primary"
                  size="small"
                  onClick={this.cloneChecked}
                  style={{ margin: '10px' }}
                  disabled={checkEvent && checkEvent.resource && !checkEvent.resource.length}
                >
                  <FileCopyIcon style={{ marginRight: '5' }} />{translate('resources.viewtripsclone.copy')}
                </Button>
              </Tooltip>
            </div>
          </div>
        </Drawer>
      </Grid>
    )
  }
}

export default compose(
  connect(null, { showNotification }),
  translate,
  withLoading,
  withStyles(styles)
)(ViewTripsClone)
