import React, { Component, Fragment } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  ReferenceField,
  FunctionField,
  Edit,
  ReferenceInput,
  Filter,
  ChipField,
  translate,
  TextInput,
  CardActions,
  WithPermissions,
} from 'react-admin'
import FormSave from './FormSave'
import Show from './Show'
import {
  withStyles,
  Tooltip,
  Chip,
  Avatar,
  IconButton,
  MenuItem,
  ListItemIcon,
  Divider,
  MenuList,
  Popover,
} from '@material-ui/core'
import moment from 'moment'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { DatePickerInput } from '../common/DatePicker'
import get from 'lodash/get'
import compose from 'recompose/compose'
import { Provider } from '../provider'
import printJS from 'print-js'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import EditIcon from '@material-ui/icons/Edit'
import * as ReservationStatus from '../common/reservation-status'
import { formatCurrency } from '../utils/formatUtil'
import PrintButton from './PrintButton'
import ExportButton from './ExportButton'
import CancelReservationButton from './CancelReservationButton'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import WebIcon from '@material-ui/icons/Web'
import LanguageIcon from '@material-ui/icons/Language'
import PosIcon from '../icons/pos'
import { SeatIcon } from '../icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTicketAlt,
  faPuzzlePiece,
  faMoneyBill,
  faQrcode,
  faCheck,
} from '@fortawesome/free-solid-svg-icons'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import {
  FuzzySelectInput,
  defaultFuzzySearch,
} from '../common/react-fuzzy-picker'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import _ from 'lodash'
import GeneralReport from './GeneralReport'
import ExportReservationButton from './ExportReservationButton'
import {
  getCurrentCompany,
  getCurrentCompanyIdsByAgency,
} from '../utils/commonUtil'
import SelectInputCustom from '../common/SelectInputCustom'
import Badge from '@material-ui/core/Badge'
import { isCompanyManager, hasOneOfPermission } from '../utils/permission'

const styles = (theme) => ({
  icon: {
    marginRight: '0.5em',
    fontSize: 20,
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  chipTrip: {
    margin: 4,
  },
  routeChip: {
    margin: 8,
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  marginBadge: {
    margin: theme.spacing.unit * 2.5,
  },
  customBadge: {
    backgroundColor: 'red',
    color: 'white',
    fontWeight: 'bold',
  },
})

const sourceIcon = {
  '00POS': <PosIcon />,
  '10WEBADMIN': <WebIcon />,
  '20MOBILE': <SmartphoneIcon />,
  '30PORTAL': <LanguageIcon />,
  '40VNPAY': (
    <IconButton>
      {' '}
      <img src="/images/logo.png" />{' '}
    </IconButton>
  ),
}

const productTypeObjDefault = {
  icon: <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPuzzlePiece} />,
  suffix: 'resources.reservations.unit.product',
}

const productTypeIcon = {
  '00SEAT': {
    icon: <SeatIcon style={{ fontSize: 17 }} />,
    suffix: 'resources.reservations.unit.seat',
  },
  '20ADDON': {
    icon: <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPuzzlePiece} />,
    suffix: 'resources.reservations.unit.product',
  },
  '30TICKET': {
    icon: <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTicketAlt} />,
    suffix: 'resources.reservations.unit.ticket',
  },
}

const paidIcon = (code) => {
  switch (code) {
    case 'CASH':
      return <FontAwesomeIcon style={{ fontSize: 16 }} icon={faMoneyBill} />
    case 'QRCODE':
      return <FontAwesomeIcon style={{ fontSize: 16 }} icon={faQrcode} />
    default:
      return <CreditCardIcon />
  }
}

export const StatusField = ({ record }) => {
  if (!record) return null
  let status = get(record, 'id') || ReservationStatus.OTHER
  let style = ReservationStatus.style[status]
  return <ChipField record={record} source="name" style={style} />
}

class _MenuAction extends Component {
  render() {
    let {
      record,
      anchorEl,
      onClose,
      push,
      translate,
      updatedChart,
      permissions,
    } = this.props
    let { status, code, id, bookingInformation = {}, charges } = record
    const checkIn = isCheckin(charges)
    let isComfirmed = status === ReservationStatus.CONFIRMED
    return (
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList autoFocusItem={Boolean(anchorEl)}>
          <PrintButton
            type="menuItem"
            disabled={!isComfirmed}
            iconFontSize="small"
            id={id}
          />
          <ExportButton
            type="menuItem"
            disabled={!isComfirmed}
            id={id}
            code={code}
          />
          <Divider />
          <MenuItem
            onClick={() => {
              push(`/reservations/${id}/show`)
            }}
            style={{ color: '#3f51b5' }}
          >
            <ListItemIcon style={{ color: '#3f51b5' }}>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <span>{translate('button.edit')}</span>
          </MenuItem>
          {isCompanyManager(permissions) && !checkIn && (
            <CancelReservationButton
              type="menuItem"
              updatedChart={updatedChart}
              id={id}
              code={code}
              bookingInformationId={bookingInformation.id}
              closeMenu={onClose}
              disabled={!ReservationStatus.canCancelReservation(status)}
            />
          )}
        </MenuList>
      </Popover>
    )
  }
}

const enhanceMenuAction = compose(connect(null, { push }), translate)

const MenuAction = enhanceMenuAction(_MenuAction)

const MenuActionWithPermission = (props) => (
  <WithPermissions
    render={({ permissions }) => (
      <MenuAction permissions={permissions} {...props} />
    )}
  />
)

const styleFilter = {
  otherStatus: {
    minWidth: 100,
  },
  route: {
    minWidth: 300,
  },
}

class _ReservationFilters extends Component {
  state = {};

  render() {
    let { translate, classes, permissions, ...props } = this.props
    const { fromDate } = this.state
    let currentCompany = getCurrentCompany()
    let operatorIds
    if (currentCompany) {
      operatorIds = [currentCompany.id]
    } else {
      operatorIds = getCurrentCompanyIdsByAgency()
    }
    return (
      <Filter
        // form="reservation-filter-form"
        validate={(values) => {
          const minDate = moment(values.createdAt_gte)
            .endOf('days')
            .toDate()
            .toISOString()
          if (values.createdAt_lte < minDate) {
            values.createdAt_lte = minDate
          }
          if (this.state.fromDate !== minDate) {
            this.setState({ fromDate: minDate })
          }
        }}
        {...props}
      >
        <DateTimePickerInput
          source="createdAt_gte"
          dateFormat="DD/MM/YYYY HH:mm"
          ampm={false}
          showLunarDate={true}
          keyboard={true}
          allowEmpty
          pickerType="datetime"
          alwaysOn
        />
        <DateTimePickerInput
          source="createdAt_lte"
          dateFormat="DD/MM/YYYY HH:mm"
          ampm={false}
          showLunarDate={true}
          keyboard={true}
          minDate={fromDate}
          allowEmpty
          pickerType="datetime"
          alwaysOn
        />
        <TextInput source="code_like" alwaysOn />
        <ReferenceInput
          source="deviceId"
          reference="devices"
          filter={{ operatorId: { inq: operatorIds } }}
          sort={{ field: 'id', order: 'ASC' }}
          alwaysOn
        >
          <FuzzySelectInput
            optionText="serial"
            renderItem={({ serial }) => serial}
            {...defaultFuzzySearch({ name: 'serial' })}
          />
        </ReferenceInput>
        <ReferenceInput
          source="status"
          reference="reservationstatuses"
          sort={{ field: 'id', order: 'ASC' }}
          alwaysOn
          fullWidth
          allowEmpty
          label="resources.reservations.fields.status"
          classExtra={classes.otherStatus}
        >
          <SelectInputCustom
            optionText="name"
            all={translate('resources.common.all')}
          />
        </ReferenceInput>
        <ReferenceInput
          source="routeId"
          reference="routes"
          filter={{ status: '10ACTIVE' }}
          sort={{ field: 'id', order: 'ASC' }}
          alwaysOn
          fullWidth
          allowEmpty
          label="resources.reservations.fields.routeId"
          classExtra={classes.route}
        >
          <FuzzySelectInput
            optionText="name"
            renderItem={({ name }) => name}
            {...defaultFuzzySearch({ name: 'name' })}
          />
        </ReferenceInput>
        <DatePickerInput
          source="departureDate"
          dateFormat="DD/MM/YYYY"
          ampm={false}
          keyboard={true}
          allowEmpty
          pickerType="date"
          alwaysOn
          clearable={true}
        />
        {hasOneOfPermission(permissions, 'company-manager') && (
          <ReferenceInput
            reference="users/listCompanyUsers"
            label="resources.productcharges.fields.user.username"
            source="createdBy"
            classExtra={classes.otherUser}
            fullWidth
            allowEmpty
            alwaysOn
          >
            <FuzzySelectInput
              optionText="username"
              renderItem={({ username }) => username}
              {...defaultFuzzySearch({ name: 'username' })}
            />
          </ReferenceInput>
        )}

        <ReferenceInput
          source="paymentMethodId"
          reference="paymentmethods"
          sort={{ field: 'id', order: 'ASC' }}
          alwaysOn
          fullWidth
          classExtra={classes.route}
          allowEmpty
          label="resources.reservations.fields.paymentMethodId"
        >
          <SelectInputCustom
            optionText="name"
            all={translate('resources.common.all')}
          />
        </ReferenceInput>
      </Filter>
    )
  }
}

const enhanceReservationFilters = compose(translate, withStyles(styleFilter))
const ReservationFilters = enhanceReservationFilters(_ReservationFilters)

const FilterWithPermission = (props) => (
  <WithPermissions
    render={({ permissions }) => (
      <ReservationFilters permissions={permissions} {...props} />
    )}
  />
)

const isCheckin = (charges) => {
  for (const charge of charges) {
    const { productCharge } = charge
    if (productCharge) return true
  }
  return false
}

export const CodeField = ({ record }) => {
  let { source, code, status, charges } = record
  let style = ReservationStatus.style[status]
  let styleAvatar = ReservationStatus.styleForAvatar[status]
  let icon = sourceIcon[source]
  const checkIn = isCheckin(charges)
  return (
    <Chip
      style={style}
      avatar={<Avatar style={styleAvatar}>{icon}</Avatar>}
      label={code}
      onDelete={checkIn ? () => {} : null}
      deleteIcon={
        checkIn ? (
          <FontAwesomeIcon
            style={{ fontSize: 16, color: '#fff', marginLeft: 2 }}
            icon={faCheck}
          />
        ) : null
      }
    />
  )
}

function groupChargeByFare(charges) {
  let chargeFare = _.groupBy(charges, 'itemDetail.fareId')
  return chargeFare
}

const productFieldStyle = {
  container: {
    display: 'flex',
    fontSize: 10,
    alignItems: 'center',
  },
  text: {
    color: 'white',
    marginRight: 4,
    fontSize: 10,
  },
}

const _ProductField = ({ record, translate, classes }) => {
  let { status, charges } = record
  let style = ReservationStatus.style[status]
  let styleAvatar = ReservationStatus.styleForAvatar[status]
  let icon, suffix
  let label
  let chargeFare, chargeFareIds
  if (charges && charges.length > 0) {
    let firstCharge = charges[0]
    let productType = _.get(firstCharge, 'itemDetail.productType')
    let productTypeObj = productTypeIcon[productType] || productTypeObjDefault
    if (productTypeObj) {
      icon = productTypeObj.icon
      suffix = productTypeObj.suffix
    }
    label = `${charges.length} ${translate(suffix)}`
    chargeFare = groupChargeByFare(charges)
    chargeFareIds = Object.keys(chargeFare)
  }
  return icon ? (
    <Tooltip
      title={
        <Fragment>
          {chargeFareIds.map((id, index) => {
            let chargeFares = chargeFare[id]
            return (
              <div className={classes.container} key={index}>
                <ReferenceField
                  basePath="/fares"
                  reference="fares"
                  source="fareId"
                  record={{ fareId: id }}
                  allowEmpty
                  linkType={false}
                >
                  <FunctionField
                    className={classes.text}
                    source="name"
                    render={({ name }) => `${name}:`}
                  />
                </ReferenceField>
                <span>{chargeFares.length}</span>
              </div>
            )
          })}
        </Fragment>
      }
    >
      <Chip
        style={style}
        avatar={<Avatar style={styleAvatar}>{icon}</Avatar>}
        label={label}
      />
    </Tooltip>
  ) : null
}

const enhanceProductField = compose(withStyles(productFieldStyle), translate)
export const ProductField = enhanceProductField(_ProductField)

const ActionList = withStyles(productFieldStyle)(
  ({ translate, filterValues }) => {
    return (
      <CardActions>
        <ExportReservationButton
          color="primary"
          label={'button.download_confirmed_reservations'}
          prefix={'reservations_confirmed'}
          status={'20CONFIRMED'}
          filterValues={filterValues}
          translate={translate}
        />
        <ExportReservationButton
          color="primary"
          label={'button.download_reservations'}
          prefix={'reservations'}
          filterValues={filterValues}
          translate={translate}
        />
      </CardActions>
    )
  }
)

////////////////////////////////////////////////////////////////////////////////
// Reservation
export class _ReservationList extends Component {
  state = {
    reportVersion: 0,
  };

  async handlePrint(e, record) {
    e.preventDefault()
    e.stopPropagation()
    let { id } = record

    let resp = await Provider.dataProvider('REMOTE', 'reservations', {
      method: `${id}/pdfticket`,
      requestMethod: 'GET',
    })
    if (resp && resp.data) {
      printJS({ printable: resp.data, type: 'pdf', base64: true })
    }
  }

  pushToTripLayout = (record) => {
    const { push } = this.props
    let { id } = record
    push(`/reservations/trip_layout/${id}`)
  };

  openMenuAction = (evt, record) => {
    this.setState({
      anchorEl: evt.target,
      currentRecord: record,
    })
  };

  closeMenuAction = () => {
    this.setState({
      anchorEl: undefined,
      currentRecord: {},
    })
  };

  updatedChart = () => {
    let { reportVersion } = this.state
    this.setState({ reportVersion: reportVersion + 1 })
  };

  render() {
    let { translate, classes, ...props } = this.props
    let { anchorEl, currentRecord, reportVersion } = this.state
    return (
      <Fragment>
        <GeneralReport version={reportVersion} />
        <br />
        <List
          actions={<ActionList {...props} />}
          bulkActions={false}
          filters={<FilterWithPermission translate={translate} />}
          filter={{
            '../include': [
              {
                relation: 'bookingInformation',
                scope: {
                  fields: ['contact', 'id', 'note'],
                },
              },
              {
                relation: 'paymentInformation',
                scope: {
                  fields: ['total', 'debt', 'paid', 'agencyKeep'],
                },
              },
              {
                relation: 'createdByUser',
              },
              {
                relation: 'reservationTrips',
                scope: {
                  fields: ['id', 'name', 'departureTime'],
                },
              },
              {
                relation: 'charges',
                scope: {
                  fields: ['id', 'itemDetail', 'status', 'cancelReference'],
                  where: { cancelReference: null },
                  include: [
                    {
                      relation: 'productCharge',
                      scope: {
                        where: {
                          firstUse: { neq: null },
                        },
                      },
                    },
                  ],
                },
              },
              {
                relation: 'transactions',
                scope: {
                  order: 'id DESC',
                  fields: ['id', 'paymentMethodId'],

                  include: [
                    {
                      relation: 'paymentMethod',
                      scope: {
                        fields: ['id', 'code'],
                      },
                    },
                  ],
                },
              },
            ],
            path: 'search',
            '../fields': [
              'id',
              'code',
              'trips',
              'bookingInformation',
              'status',
              'createdAt',
              'createdBy',
              'agencyId',
              'source',
            ],
          }}
          filterDefaultValues={{
            createdAt_gte: moment().startOf('days').toDate().toISOString(),
            createdAt_lte: moment()
              .add(7, 'days')
              .endOf('days')
              .toDate()
              .toISOString(),
          }}
          {...props}
        >
          <Datagrid>
            <FunctionField
              source="code"
              render={(record) => {
                let { bookingInformation = {} } = record
                let { note } = bookingInformation
                return (
                  <Badge
                    classes={{ badge: classes.customBadge }}
                    className={classes.marginBadge}
                    badgeContent={'!'}
                    invisible={!note}
                  >
                    <CodeField record={record} />
                  </Badge>
                )
              }}
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="trips"
              render={({ reservationTrips = [], charges }) => {
                if (reservationTrips.length > 0) {
                  return reservationTrips.map((record, idx) => (
                    <Tooltip
                      title={
                        <Fragment>
                          <p>{`[${moment(record.departureTime).format(
                            'HH:mm DD/MM'
                          )}] ${record.name}`}</p>
                        </Fragment>
                      }
                      key={idx}
                    >
                      <Chip
                        size="small"
                        className={classes.routeChip}
                        key={idx}
                        label={`[${moment(record.departureTime).format(
                          'HH:mm DD/MM'
                        )}] ${
                          record.name
                            ? record.name.length > 20
                              ? `${record.name.substring(0, 17)}...`
                              : record.name
                            : ''
                        }`}
                        onClick={() => this.pushToTripLayout(record)}
                      />
                    </Tooltip>
                  ))
                } else if (charges && charges.length > 0) {
                  let firstCharge = charges[0]
                  if (!firstCharge) return ''
                  let { itemDetail = {} } = firstCharge
                  let { routeId } = itemDetail
                  return (
                    <ReferenceField
                      basePath="/routes"
                      reference="routes"
                      allowEmpty
                      record={{ routeId }}
                      source="routeId"
                      linkType={false}
                    >
                      <FunctionField
                        render={({ name }) => {
                          return (
                            <Tooltip
                              title={
                                <Fragment>
                                  <p>{name}</p>
                                </Fragment>
                              }
                            >
                              <Chip
                                size="small"
                                className={classes.routeChip}
                                label={
                                  name
                                    ? name.length > 20
                                      ? `${name.substring(0, 17)}...`
                                      : name
                                    : ''
                                }
                              />
                            </Tooltip>
                          )
                        }}
                      />
                    </ReferenceField>
                  )
                }
              }}
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="departureDate"
              render={({ charges }) => {
                if (charges && charges.length > 0) {
                  let firstCharge = charges[0]
                  if (!firstCharge) return ''
                  let { itemDetail = {} } = firstCharge
                  let { departureDate } = itemDetail
                  return departureDate
                    ? moment(departureDate).format('DD/MM/YYYY')
                    : ''
                }
                return ''
              }}
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="createdAt"
              render={({ createdAt }) =>
                moment(createdAt).format('HH:mm DD/MM')
              }
            />
            <ReferenceField
              source="agencyId"
              reference="agencies/list"
              linkType={false}
              allowEmpty
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
            >
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="productAmount"
              sortable={false}
              render={(record) => <ProductField record={record} />}
            />
            <FunctionField
              source="paid"
              headerClassName={classes.textEnd}
              cellClassName={classes.textEnd}
              sortable={false}
              render={(record) => {
                let {
                  paymentInformation = {},
                  status,
                  transactions = [],
                } = record
                let { total, debt = 0, paid, agencyKeep } = paymentInformation
                let debtStyle = ReservationStatus.outlineStyle[status]
                let styleAvatar = ReservationStatus.styleForAvatarPaid[status]
                let { paymentMethod = {} } = transactions[0] || {}
                let { code: paymentMethodCode } = paymentMethod
                let icon = paidIcon(paymentMethodCode)

                return (
                  <Tooltip
                    title={
                      <Fragment>
                        {[
                          {
                            translateKey: 'resources.reservations.totalFare',
                            value: total,
                          },
                          {
                            translateKey: 'resources.reservations.paid',
                            value: paid,
                          },
                        ].map((el) => {
                          return (
                            <p key={el.translateKey}>
                              {translate(el.translateKey)}:{' '}
                              <b>
                                {el.value ? formatCurrency(el.value) : '0đ'}
                              </b>
                            </p>
                          )
                        })}
                        {status !== '60EXPIRED' && agencyKeep > 0 && (
                          <p>
                            {translate('resources.reservations.agencyKeep')}:{' '}
                            <b>
                              {agencyKeep ? formatCurrency(agencyKeep) : '0đ'}
                            </b>
                          </p>
                        )}
                        {debt < 0 ? (
                          <p>
                            {translate('resources.reservations.refund')}:{' '}
                            <b>
                              {debt ? formatCurrency(Math.abs(debt)) : '0đ'}
                            </b>
                          </p>
                        ) : (
                          <p>
                            {translate('resources.reservations.debt')}:{' '}
                            <b>{debt ? formatCurrency(debt) : '0đ'}</b>
                          </p>
                        )}
                      </Fragment>
                    }
                  >
                    <Chip
                      variant="outlined"
                      style={debtStyle}
                      avatar={<Avatar style={styleAvatar}>{icon}</Avatar>}
                      label={total ? formatCurrency(total) : '0đ'}
                    />
                  </Tooltip>
                )
              }}
            />
            <TextField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="createdByUser.username"
              sortable={false}
            />
            <FunctionField
              headerClassName={classes.textEnd}
              cellClassName={classes.textEnd}
              render={(record) => (
                <IconButton
                  onClick={(evt) => this.openMenuAction(evt, record)}
                  color="primary"
                >
                  <MoreVertIcon />
                </IconButton>
              )}
            />
          </Datagrid>
        </List>
        {anchorEl && (
          <MenuActionWithPermission
            anchorEl={anchorEl}
            record={currentRecord}
            onClose={this.closeMenuAction}
            updatedChart={this.updatedChart}
          />
        )}
      </Fragment>
    )
  }
}

const enhance = compose(translate, withStyles(styles), connect(null, { push }))

export const ReservationList = enhance(_ReservationList)

////////////////////////////////////////////////////////////////////////////////
// Show
export const ReservationShow = ({ id }) => {
  return <Show id={id} />
}

////////////////////////////////////////////////////////////////////////////////
// Create
export const ReservationCreate = (props) => (
  <Create {...props}>
    <FormSave />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit
export const ReservationEdit = (props) => (
  <Edit {...props}>
    <FormSave />
  </Edit>
)
