import React, { Component } from 'react'
import {
  withStyles,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Map, { BaseLayers } from './Map'
import { MapProps } from './../common/constants'
import {
  faLayerGroup,
  faSmile,
} from '@fortawesome/free-solid-svg-icons'
import LayersControlFrame from './LayersControlFrame'
import TransitiveFrame from './transitive-frame'
import RouteFrame from './route-frame'
import RouteEditFrame from './route-edit-frame'

const styles = () => ({
  map: {
    width: '100%',
    height: '100%',
  },
  rigthLabel: {
    paddingLeft: '0.5em'
  }
})

const Frame1 = () => {
  return (<div key="Frame1" style={{
    width: 300,
    height: '100%',
    background: '#222'
  }}> Frame1 </div>)
}

export class MapField extends Component {

  render() {
    let { classes, layers } = this.props
    return (
      <>
        <Map
          style={{ width: '100%', height: 780 }}
          minZoom={1}
          className={classes.map}
          zoom={13}
          baseLayers={BaseLayers.OSM_LAYER}
          frames={[
            {
              type: MapProps.LayerTypes.NODE,
              identifier: 'route-edit-control',
              name: 'Route Edit Control',
              node: {
                icon: faLayerGroup,
                element: <RouteEditFrame />
              }
            },
            {
              type: MapProps.LayerTypes.NODE,
              identifier: 'route-control',
              name: 'Route Control',
              node: {
                icon: faLayerGroup,
                element: <RouteFrame />
              }
            },
            {
              type: MapProps.LayerTypes.NODE,
              identifier: 'Layers Control',
              name: 'Layers Control',
              node: {
                icon: faLayerGroup,
                element: <LayersControlFrame />,
              }
            },
            {
              type: MapProps.LayerTypes.NODE,
              identifier: 'Frame1',
              name: 'Frame1',
              node: {
                icon: faSmile,
                element: Frame1,
              }
            },
            {
              type: MapProps.LayerTypes.NODE,
              identifier: 'transitive-frame',
              name: 'Transitve Frame',
              node: {
                icon: faLayerGroup,
                element: <TransitiveFrame />,
              }
            }
          ]}
          layers={layers}
        />
      </>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  connect(
    (state) => {
      return { layers: state.map.layers }
    },
    null)
)

export default enhance(MapField)
