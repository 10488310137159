import { take, race, put, fork } from 'redux-saga/effects'
import {
  // EXPORT_TEMPLATE,
  // GET_TEMPLATE_DATA_FAILURE,
  // GET_TEMPLATE_DATA_SUCCESS,
  INIT_IMPORT_TRIP,
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAILURE,
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAILURE,
  SAVE_FILES,
  SAVE_FILES_SUCCESS,
  SAVE_FILES_FAILURE,
  GET_ROUTES_SUCCESS,
  GET_ROUTES_FAILURE,
  // GET_TRIPS_BY_TIME_SUCCESS,
  // GET_TRIPS_BY_TIME_FAILURE,
  GET_BLOCKS_SUCCESS,
  GET_BLOCKS_FAILURE,
  INIT_LIST_TRIP,
  CHANGE_VIEW_MODE,
  // INIT_VIEW_TRIP_BY_TIME,
  INIT_VIEW_TRIP_BY_ROUTE,
  GET_TRIP_BY_ROUTE_SUCCESS,
  GET_TRIP_BY_ROUTE_FAILURE,
  // FILTER_TRIP,
  CHOOSE_DATE,
  CLICK_FILTER,
  CHOOSE_VEHICLES,
  CHOOSE_BLOCKS,
  CHOOSE_ROUTES,
  DELETE_TRIP_SUCCESS,
  DELETE_TRIP_FAILURE,
  // viewTripTime as viewTripTimeAction,
  viewTripRoute as viewTripRouteAction,
  getRoutes as getRoutesAction,
  getBlocks as getBlocksAction,
  // getTripsBlockByTime as getTripsBlockByTimeAction,
  filterTrip as filterTripAction,
  // getTemplateData as getTemplateDataAction,
  getVehicles as getVehiclesAction,
  getDrivers as getDriversAction,
  getTripsByRoute as getTripsByRouteAction,
  getRouteShow as getRouteShowAction,
  updateRouteShow as updateRouteShowAction,
  deleteTrip as deleteTripAction,
  removeTrip as removeTripAction,
  assignVehicle as assignVehicleAction,
  CHOOSE_ASSIGN_OPTION,
  CLICK_ASSIGNED_VEHICLE,
  CLICK_OK_DELETE_CONFIRM,
  ASSIGN_VEHICLE_SUCCESS,
  ASSIGN_VEHICLE_FAILURE
} from './action'
import { showNotification } from 'react-admin'
import { refreshView } from 'ra-core'
import { isSameDay } from 'date-fns'
import isEmpty from 'lodash/isEmpty'

const compareMomentArrays = (arr1, arr2) => {
  let arr1Len = arr1.length
  let arr2Len = arr2.length
  if (arr1Len !== arr2Len) {
    return false
  }
  for (let i = 0; i < arr1Len; i++ ) {
    for ( let j =0; j < arr2Len; j++ ) {
      if (!isSameDay(arr1[i], arr2[j])) {
        return false
      }
    }
  }
}

const compareArrays = (arr1, arr2) => {
  if (!arr1 || !arr2) {
    return false
  }
  let arr1Len = arr1.length
  let arr2Len = arr2.length
  if (arr1Len !== arr2Len) {
    return false
  }
  for (let i = 0; i < arr1Len; i++ ) {
    for ( let j =0; j < arr2Len; j++ ) {
      if (arr1[i] !== arr2[j]) {
        return false
      }
    }
  }
}

function* handleInit() {
  while (true) {
    let { onDone } = yield take(INIT_IMPORT_TRIP)
    let vehicles = {}
    let drivers = {}
    yield put(getVehiclesAction())
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_VEHICLES_SUCCESS),
        failure: take(GET_VEHICLES_FAILURE)
      })
      if (success) {
        let { data } = success.payload
        vehicles = data
        break
      }
      if (failure) {
        break
      }
    }
    yield put(getDriversAction())
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_DRIVERS_SUCCESS),
        failure: take(GET_DRIVERS_FAILURE)
      })
      if (success) {
        let { data } = success.payload
        drivers = data
        break
      }
      if (failure) {
        break
      }
    }
    onDone(vehicles, drivers)
  }
}

function* handleImport() {
  while (true) {
    yield take(SAVE_FILES)
    while (true) {
      let { success, failure } = yield race({
        success: take(SAVE_FILES_SUCCESS),
        failure: take(SAVE_FILES_FAILURE)
      })
      if (success) {
        let data = success.payload.data.data.result
        let length = data.length
        if (length > 0) {
          yield put(showNotification('notification.import_trip_success', 'info', { messageArgs: { amount: length }} ))
        } else {
          yield put(showNotification('notification.import_trip_exists'))
        }
        break
      }
      if (failure) {
        yield put(showNotification('notification.import_trip_failure', 'warning'))
        break
      }
    }
  }
}

// function* handleExportTemplate() {
//   while (true) {
//     let { companyId,onDone } = yield take(EXPORT_TEMPLATE)
//     yield put(getTemplateDataAction(companyId))
//     while (true) {
//       let { success, failure } = yield race({
//         success: take(GET_TEMPLATE_DATA_SUCCESS),
//         failure: take(GET_TEMPLATE_DATA_FAILURE)
//       })
//       if (success) {
//         let { data } = success.payload
//         onDone(data)
//         break
//       }
//       if (failure) {
//         let { error } = failure
//         yield put(showNotification(error, 'warning'))
//         break
//       }
//     }
//   }
// }

function* handleInitListTrip() {
  let vehicles = {}
  let routes = {}
  let blocks = {}
  while (true) {
    let { companyId, onDone } = yield take(INIT_LIST_TRIP)
    yield put(getVehiclesAction())
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_VEHICLES_SUCCESS),
        failure: take(GET_VEHICLES_FAILURE)
      })
      if (success) {
        let { data } = success.payload
        vehicles = data
        break
      }
      if (failure) {
        vehicles = []
        let { error } = failure
        yield put(showNotification(error, 'warning'))
        break
      }
    }
    yield put(getRoutesAction(companyId))
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_ROUTES_SUCCESS),
        failure: take(GET_ROUTES_FAILURE)
      })
      if (success) {
        let { data } = success.payload
        routes = data
        yield put(getRouteShowAction(routes))
        break
      }
      if (failure) {
        routes = []
        let { error } = failure
        yield put(showNotification(error, 'warning'))
        break
      }
    }
    yield put(getBlocksAction())
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_BLOCKS_SUCCESS),
        failure: take(GET_BLOCKS_FAILURE)
      })
      if (success) {
        let { data } = success.payload
        blocks = data
        break
      }
      if (failure) {
        blocks= []
        let { error } = failure
        yield put(showNotification(error, 'warning'))
        break
      }
    }
    onDone({ vehicles, routes, blocks })
  }
}

function* handleFilterByDate() {
  let newFilter = {}
  let isChanged = false
  while (true) {
    let { chooseDate, isClick } = yield race({
      chooseDate: take(CHOOSE_DATE),
      isClick: take(CLICK_FILTER)
    })
    if (chooseDate) {
      let { dates, filter } = chooseDate
      if (!compareMomentArrays(dates, filter.dates)) {
        isChanged = true
        newFilter = {...filter, dates}
      }
    }
    if (isClick) {
      if (isChanged) {
        yield put(filterTripAction(newFilter))
        isChanged = false
      }
    }
  }
}

function* handleFilterByVehicle() {
  let newFilter = {}
  let isChanged = false
  while (true) {
    let { chooseVehicle, isClick } = yield race({
      chooseVehicle: take(CHOOSE_VEHICLES),
      isClick: take(CLICK_FILTER)
    })
    if (chooseVehicle) {
      let { vehicles, filter } = chooseVehicle
      if (!compareArrays(vehicles, filter.vehiclePlates)) {
        isChanged = true
        newFilter = { ...filter, vehiclePlates: vehicles }
      }
    }
    if (isClick) {
      if (isChanged) {
        yield put(filterTripAction(newFilter))
        isChanged = false
      }
    }
  }
}

function* handleFilterByBlock() {
  let newFilter = {}
  let isChanged = false
  while (true) {
    let { chooseBlock, isClick } = yield race({
      chooseBlock: take(CHOOSE_BLOCKS),
      isClick: take(CLICK_FILTER)
    })
    if (chooseBlock) {
      let { blocks, filter } = chooseBlock
      if (!compareArrays(blocks, filter.blockNumbers)) {
        isChanged = true
        newFilter = { ...filter, blockNumbers: blocks }
      }
    }
    if (isClick) {
      if (isChanged) {
        yield put(filterTripAction(newFilter))
        isChanged = false
      }
    }
  }
}

function* handleFilterByRoute() {
  let newFilter = {}
  let isChanged = false
  while (true) {
    let { chooseRoute, isClick } = yield race({
      chooseRoute: take(CHOOSE_ROUTES),
      isClick: take(CLICK_FILTER)
    })
    if (chooseRoute) {
      let { routeIds, filter } = chooseRoute
      if (!compareArrays(routeIds, filter.routeIds)) {
        isChanged = true
        newFilter = { ...filter, routeIds: routeIds }
      }
    }
    if (isClick) {
      if (isChanged) {
        yield put(filterTripAction(newFilter))
        isChanged = false
        yield put(updateRouteShowAction(newFilter.routeIds))
      }
    }
  }
}

function* handleFilterByAssignOption() {
  let newFilter = {}
  let isChanged = false
  while (true) {
    let { assignOption, filter } = yield take(CHOOSE_ASSIGN_OPTION)
    if (assignOption !== filter.assignedVehicle) {
      isChanged = true
      newFilter = { ...filter, assignedVehicle: assignOption }
    }
    if (isChanged) {
      yield put(filterTripAction(newFilter))
      isChanged = false
      yield put(updateRouteShowAction(newFilter.routeIds))
    }
  }
}

// function* handleFilter() {
//   while (true) {
//     let { filter } = yield take(FILTER_TRIP)
//     yield put(getTripsBlockByTimeAction(filter))
//     while (true) {
//       let { success, failure } = yield race({
//         success: take(GET_TRIPS_BY_TIME_SUCCESS),
//         failure: take(GET_TRIPS_BY_TIME_FAILURE)
//       })
//       if (success) {
//         let data = success.payload.data
//         yield put(viewTripTimeAction(data))
//         break
//       }
//       if (failure) {
//         let { error } = failure
//         yield put(showNotification(error, 'warning'))
//         break
//       }
//     }
//   }
// }

// function* handleInitViewByTime() {
//   while (true) {
//     let { dates, assignedVehicle, routes, companyId } = yield take(INIT_VIEW_TRIP_BY_TIME)
//     let filter = {
//       assignedVehicle: assignedVehicle,
//       dates: dates,
//       routeIds: routes,
//       companyId: companyId
//     }
//     yield put(getTripsBlockByTimeAction(filter))
//     while (true) {
//       let { success, failure } = yield race({
//         success: take(GET_TRIPS_BY_TIME_SUCCESS),
//         failure: take(GET_TRIPS_BY_TIME_FAILURE)
//       })
//       if (success) {
//         let data = success.payload.data
//         yield put(viewTripTimeAction(data))
//       }
//       if (failure) {
//         let { error } = failure
//         yield put(showNotification(error, 'warning'))
//         break
//       }
//     }
//   }
// }

function* handleInitViewByRoute() {
  while (true) {
    let { routes, companyId } = yield take(INIT_VIEW_TRIP_BY_ROUTE)
    yield put(getTripsByRouteAction(routes, companyId))
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_TRIP_BY_ROUTE_SUCCESS),
        failure: take(GET_TRIP_BY_ROUTE_FAILURE)
      })
      if (success) {
        let data = success.payload.data
        yield put(viewTripRouteAction(data))
      }
      if (failure) {
        let { error } = failure
        yield put(showNotification(error, 'warning'))
        break
      }
    }
  }
}

function* handleChangeViewMode() {
  while (true) {
    yield take(CHANGE_VIEW_MODE)
    yield put(refreshView())
  }
}

function* handleDeleteTrip() {
  while (true) {
    let { id, date, onDone } = yield take(CLICK_OK_DELETE_CONFIRM)
    yield put(deleteTripAction(id))
    while (true) {
      let { success, failure } = yield race({
        success: take(DELETE_TRIP_SUCCESS),
        failure: take(DELETE_TRIP_FAILURE)
      })
      if (success) {
        onDone()
        yield put(removeTripAction(id, date))
        yield put(showNotification('notification.delete_trip_success'))
        break
      }
      if (failure) {
        onDone()
        yield put(showNotification('notification.delete_trip_failure', 'warning'))
        break
      }
    }
  }
}

function* handleAssignedVehicle() {
  while (true) {
    let { filter, onDone } = yield take(CLICK_ASSIGNED_VEHICLE)
    yield put(assignVehicleAction(filter))
    while (true) {
      let { success, failure } = yield race({
        success: take(ASSIGN_VEHICLE_SUCCESS),
        failure: take(ASSIGN_VEHICLE_FAILURE)
      })
      if (success) {
        let datas = success.payload.data
        let numberOfTrip = 0
        for (let i = 0; i < datas.length; i++) {
          let { data } = datas[i]
          if (!isEmpty(data)) {
            numberOfTrip += data.trips.length
          }
        }
        yield put(showNotification('notification.assigned_vehicle_success', 'info', { messageArgs:{ amountTrip: numberOfTrip } } ))
        onDone()
        break
      }
      if (failure) {
        yield put(showNotification('notification.assigned_vehicle_failure', 'warning'))
        onDone()
        break
      }
    }
  }
}

export default function* trip() {
  yield fork(handleInit)
  // yield fork(handleExportTemplate)
  yield fork(handleImport)
  yield fork(handleInitViewByRoute)
  // yield fork(handleFilter)
  yield fork(handleInitListTrip)
  yield fork(handleChangeViewMode)
  // yield fork(handleInitViewByTime)
  yield fork(handleFilterByDate)
  yield fork(handleFilterByVehicle)
  yield fork(handleFilterByBlock)
  yield fork(handleFilterByRoute)
  yield fork(handleDeleteTrip)
  yield fork(handleFilterByAssignOption)
  yield fork(handleAssignedVehicle)
}
