import React, { Component } from 'react'
import compose from 'recompose/compose'
import {
  IconButton,
  withStyles,
} from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import { 
  translate,
  Datagrid,
  Pagination,
  FunctionField,
} from 'react-admin'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPen } from '@fortawesome/free-solid-svg-icons'
import {
  removeVehicle as removeVehicleAction,
  updateVehicle as updateVehicleAction,
} from './action'
import _ from 'lodash'

const vehicleRowStyle = (record) => ({
  backgroundColor: record.error ? red[100] : 'white',
})
const styles = (theme) => ({
  errorRow: {
    fontSize: 12,
    backgroundColor: red[100],
  },
  row: {
    fontSize: 12,
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  }, 
  iconSizeEdit: {
    fontSize: 11,
    color: 'blue',
  },
  iconSizeDel: {
    fontSize: 11,
    color: 'red',
  },
})


class VehicleTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      perPage: 10,
      rows: [],
      total: 0,
      pagedItems: [],
      data: [],
    }
  }

  static getDerivedStateFromProps(nextProps, state) { 
    let { rows } = nextProps
    let currentRows = state.rows
    if (currentRows !== rows ) {
      currentRows = rows
    }
    let total = currentRows.length
    let { page, perPage } = state
    let offset = (page - 1) * perPage
    let pagedItems = _.drop(currentRows, offset).slice(0, perPage)
    return {
      rows: currentRows,
      pagedItems,
      total,
    }
  }

  setPage = page => {
    let { rows: items, perPage} = this.state
    this.setState({ page })
    this.getData(items, page, perPage)
  }

  setPerPage = perPage => {
    let { rows: items, page } = this.state
    this.setState({ perPage })
    this.getData(items, page, perPage)
  }

  getData = (items, page, perPage) => {
    let offset = (page - 1) * perPage
    let pagedItems = _.drop(items, offset).slice(0, perPage)
    this.setState({ pagedItems })
  }

  removeVehicle = vehicleIdx => {
    let { removeVehicleAction } = this.props
    removeVehicleAction(vehicleIdx)
  }

  updateVehicle = vehicleIdx => {
    let { updateVehicleAction } = this.props
    updateVehicleAction(vehicleIdx)
  }
  
  render() {
    const { classes } = this.props
    let {
      page,
      perPage,
      total,
      pagedItems=[],
    } = this.state
    let ids = Object.keys(pagedItems)
    let index = (page - 1) * perPage + 1
    pagedItems = pagedItems.map(item => {
      item.index = index++
      return item
    })
    return (
      <>
      <Datagrid
        ids={ids}
        data={pagedItems}
        currentSort={{}}
        rowStyle={vehicleRowStyle}
      >
        <FunctionField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          className={classes.emptyRow}
          label="resources.common.no"
          render = { record => {
            let id = record.index
            return id
          }}
        />
        <FunctionField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="name"
          label="resources.vehicles.fields.name"
          className={classes.emptyRow}
          render = { record => {
            let { plate, name } = record
            return `[${plate}] ${name}`
          }}
        />
        <FunctionField
          cellClassName={classes.textRight}
          render = { record => {
            let { vehicleIdx } = record
            return <div>
              <IconButton
                onClick={() => this.removeVehicle(vehicleIdx)}
              >
                <FontAwesomeIcon className={classes.iconSizeDel} icon={faTrashAlt} />
              </IconButton>
              <IconButton
                onClick={() => this.updateVehicle(vehicleIdx)}
              >
                <FontAwesomeIcon className={classes.iconSizeEdit} icon={faPen} />
              </IconButton>
            </div>
          }}
        />
      </Datagrid>
      <Pagination 
        page={page}
        perPage={perPage}
        setPage={this.setPage}
        setPerPage={this.setPerPage}
        total={total}
      />
      </>
    )
  }
}

const enhance = compose (
  withStyles(styles),
  translate,
  connect(null, { removeVehicleAction, updateVehicleAction })
)

export default enhance(VehicleTable)
