import React, { Component } from 'react'
import {
  showNotification,
  translate,
} from 'react-admin'
import {
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import TripGeneralInformation from './TripGeneralInformation'
import TripHistory from './TripHistory'
import TripCharge from './TripCharge'
import FormBody from '../common/FormBody'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  tabContainer: { padding: theme.spacing.unit * 3 }
})

const TabHeaders = ({ classes, translate, tabIndex, onChange, ...props }) => {
  let { action } = props
  return (
    <Tabs
      value={tabIndex}
      onChange={(k, v) => onChange(v)}
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
    >
      <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.trips.fields.generalInformation')}
      />
      {
        action === 'update' && <Tab
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={translate('resources.trips.fields.history')}
        />
      }

      {
        action === 'update' && <Tab
          disableRipple
          classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
          label={translate('resources.trips.fields.tickets')}
        />
      }
    </Tabs>
  )
}

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0
    }
  }

  changeTab = (idx) => {
    this.setState({ tabIndex: idx })
  }

  render() {
    let { tabIndex } = this.state
    let { classes, translate, action, record } = this.props

    return (
      <FormBody>
        <div className={classes.root}>
          <TabHeaders
            classes={classes}
            tabIndex={tabIndex}
            onChange={(v) => this.changeTab(v)}
            action={action}
            translate={translate}
          />

          {
            tabIndex === 0 &&
          <div className={classes.tabContainer}>
            <TripGeneralInformation record={record} />
          </div>
          }

          {
            action === 'update' && tabIndex === 1 &&
          <div className={classes.tabContainer}>
            <TripHistory record={record} />
          </div>
          }
          {
            action === 'update' && tabIndex === 2 &&
          <div className={classes.tabContainer}>
            <TripCharge record={record} />
          </div>
          }
        </div>
      </FormBody>
    )
  }
}

const enhance = compose(
  connect(null,
    {
      showNotification,
    }),
  translate,
  withStyles(styles),
)

export default enhance(FormSave)
