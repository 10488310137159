import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Popper,
  TextField,
  InputAdornment,
  Paper,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemText,
  ClickAwayListener,
  Chip,
} from '@material-ui/core'
import { Provider } from '../provider'
import { translate, WithPermissions  } from 'react-admin'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faRoute, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { moment } from '../common/format'
import { StatusField, StatusFieldMoreChip} from '../common/charge-status'
import { styleForCode as reservationStatusStyle } from '../common/reservation-status'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import * as permission from '../utils/permission'
import _ from 'lodash'

const styles = {
  inputContainer: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    marginLeft: 8,
    marginRight: 16,
    borderRadius: 4,
    paddingLeft: 8,
  },
  adorment: {
    marginRight: 16,
  },
  colorInput: {
    color: 'white',
  },
}

const listStyles = {
  popover: {
    zIndex: 1,
  },
  empty: {
    padding: 32,
    textAlign: 'center',
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.54)',
  }
}

class _SearchResultList extends Component {

  state = {}

  getReservationsByPhoneOrReservationCode = async filterValue => {
    let res = await Provider.dataProvider('REMOTE', 'reservations', {
      method: 'findByPhoneOrReservationCode',
      requestMethod: 'GET',
      data: { filterValue },
    })

    if (res && res.data) {
      return res.data
    }
    return []
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { filterValue, anchorEl } = this.props
    let { filterValue: prevFilterValue, anchorEl: prevAnchorEl } = prevProps
    const updated = !(prevFilterValue === filterValue && prevAnchorEl === anchorEl)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { filterValue } = this.props
      let datum = filterValue && await this.getReservationsByPhoneOrReservationCode(filterValue)
      this.setState({ datum })
    }
  }

  render() {
    let {
      anchorEl,
      filterValue,
      classes,
      translate,
      handleClose,
      permissions,
    } = this.props
    let { datum } = this.state
    let style={ 
      width: datum && datum.length > 0 ? 'fit-content' : (anchorEl ? anchorEl.clientWidth + 330 : null),
    }

    const isAdmin = permission.isAdmin(permissions)
    return <Popper
      className={classes.popover}
      anchorEl={anchorEl}
      style={style}
      open={Boolean(anchorEl)}
      placement="bottom"
      popperOptions={{
        adaptive: true,
      }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <Paper>
          {(filterValue && filterValue !== null) ? (
            !_.isEmpty(datum) ? datum.map((ele, idx) => <SearchResult
              key={idx}
              isAdmin={isAdmin}
              record={ele}
              onClose={handleClose}
            />) : <div className={classes.empty}>
              {translate('resources.common.no_reservation')}&nbsp;&quot;<b>{filterValue}</b>&quot;
            </div>)
            : null}
        </Paper>
      </ClickAwayListener>
    </Popper>
  }
}

const SearchResultList = compose(translate, withStyles(listStyles))(_SearchResultList)

const SearchResultListWithPermission = (props) => <WithPermissions
  render={({ permissions }) => permissions && <SearchResultList permissions={permissions} {...props} />}
/>

const SearchResult = ({ record, onClose, isAdmin }) => {
  let {
    fullName,
    email,
    phone,
    reservations,
  } = record
  return <div>
    <CustomerInfo
      fullName={fullName}
      email={email}
      phone={phone}
    />
    <Divider />
    <ReservationList onClose={onClose} reservations={reservations} isAdmin={isAdmin} />
  </div>
}

const customerStyles = {
  root: {
    display: 'flex',
    padding: 8,
  },
  textContainer: {
    width: '100%',
    display: 'flex',
    padding: 4,
    marginLeft: 8,
    flexDirection: 'column',
  },
  textPrimary: {
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}

const _CustomerInfo = ({ fullName, classes, phone }) => {
  return <div className={classes.root}>
    <Avatar>A</Avatar>
    <div className={classes.textContainer}>
      <div className={classes.textPrimary}>{phone}</div>
      <div className={classes.textSecondary}>{fullName}</div>
    </div>
  </div>
}

const CustomerInfo = compose(withStyles(customerStyles))(_CustomerInfo)

const reservationListStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}

const _ReservationList = ({ reservations, onClose, classes, isAdmin }) => {
  return <div className={classes.root}>
    {reservations && reservations.map((ele, idx) => <ReservationItem
      key={idx}
      isAdmin={isAdmin}
      onClose={onClose}
      record={ele}
    />)}
  </div>
}

const ReservationList = compose(withStyles(reservationListStyles))(_ReservationList)

const reservationItemStyles = {
  root: {
    display: 'flex',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  reservationInfo: {
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(235, 235, 235)',
    }
  },
  textPrimary: {
    fontSize: 20,
    fontWeight: 'bold',
    margin:'.3rem 0' 
  },
  textSecondary: {
    fontSize: 13,
    color: 'rgba(0, 0, 0, 0.54)',
  },
}

const iconRouteGroupMapping = {
  '00BUS': <FontAwesomeIcon icon={faRoute} />,
  '10BUSTOUR': <FontAwesomeIcon icon={faTicketAlt} />,
}
const BUS_TYPE = '00BUS'

const _ReservationItem = ({ record, onClose, classes, push, isAdmin }) => {
  let { debt, total, paid, status, id, routeGroupType } = record
  let isPrePaid = false
  if (debt !== 0 && paid !== 0 && paid < total) {
    isPrePaid = true
  }
  let style = reservationStatusStyle[status]
  return <div className={classes.root} >
    <div
      style={style}
      className={classes.reservationInfo}
      onClick={() => {
        if(isAdmin) return
        push(`/reservations/${id}/show`)
        onClose()
      }}
    >
      <Chip label={routeGroupType && iconRouteGroupMapping[routeGroupType]} />
      <div className={classes.textPrimary} >{record.code}</div>
      <div className={classes.textSecondary} >
        {moment(record.createdAt).format('HH:mm DD/MM/YYYY')}
      </div>
    </div>
    <TripList isPrePaid={isPrePaid} record={record} isAdmin={isAdmin} />
  </div>
}

const ReservationItem = compose(
  withStyles(reservationItemStyles),
  connect(null, { push }),
)(_ReservationItem)

const tripListStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textContainer: {
    minWidth: 300,
    maxWidth: 300,
    '& > span': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& > p': {
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
}
const ShowSeatChips = ({ charges, isPrePaid , busType }) => {
  let seatLength = charges.length
  let isShowMore = seatLength > 2
  return (
    <Fragment>
      {
        seatLength <= 2 ?
          charges.map((charge, idx) =>{
            let { itemDetail, status } = charge
            return  <StatusField
              key={idx}
              itemDetail={itemDetail}
              status={status}
              isPrePaid={isPrePaid}
            />
          }) : 
          <StatusField
            itemDetail={charges[0].itemDetail}
            status={charges[0].status}
            isPrePaid={isPrePaid}
          />
      }
      {isShowMore && <StatusFieldMoreChip charges={charges} busType={busType} />}
    </Fragment>
  )
}

const _TripList = ({ record, classes, push, isPrePaid, translate, isAdmin }) => {

  let { chargeGroups, routeGroupType } = record

  const handleClick = (e, tripId) => {
    if(isAdmin) return
    if(tripId){
      e.preventDefault()
      push(`/reservations/trip_layout/${tripId}`)
    }
  }

  return <List disablePadding className={classes.root} >
    {chargeGroups && chargeGroups.map((chargeGroup, idx) => {
      let {
        tripName,
        fareName,
        routeName,
        tripId,
        charges,
        tripDepartureTime,
      } = chargeGroup 
      return <ListItem 
        key={idx}
        button
        onClick={(e) => handleClick(e, tripId)}
        className={classes.item}
        style={{cursor: routeGroupType !== BUS_TYPE && 'default'}}
      >
        <ListItemText
          className={classes.textContainer}
          primary={routeGroupType === BUS_TYPE ? tripName : fareName}
          secondary={
            <Fragment>
              {tripDepartureTime ? <span>
                <b>{moment(tripDepartureTime).format('HH:mm')}</b>
                &nbsp;{moment(tripDepartureTime).format('DD/MM/YYYY')}
              </span> : <span>{routeName}</span>}
              <strong>{translate('resources.products.fields.quantity')}: {charges && charges.length}</strong>
            </Fragment>}
        />
        <ListItemText >
          <ShowSeatChips charges={charges} isPrePaid={isPrePaid} busType={routeGroupType === BUS_TYPE} /> 
        </ListItemText>
      </ListItem>
    })}
  </List>
}

const TripList = compose(
  translate,
  withStyles(tripListStyles),
  connect(null, { push })
)(_TripList)

class ReservationSearchInput extends Component {

  state = {
    open: false,
    anchorEl: null,
    widthDefault: 250,
    isFocus: false,
  }

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  onChange = evt => {
    let { target } = evt
    let { value } = target
    this.setState({
      anchorEl: target,
      filterValue: value,
    })
  }

  onBlur = () => {
    this.setState({
      widthDefault: 250, 
      isFocus: false,
    })
  }

  onClose= () => {
    let { isFocus } = this.state
    if (!isFocus) {
      this.setState({
        anchorEl: null, 
      })
    }
  }

  onFocus = evt => {
    this.setState({
      anchorEl: evt.target,
      widthDefault: 300, 
      isFocus: true,
    })
  }

  render() {
    let { translate, isXSmall, classes } = this.props
    let { anchorEl, filterValue, widthDefault } = this.state
    let style = { 
      width: widthDefault,
      transition: 'width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    }
    return <Fragment>
      {!isXSmall ? <TextField
        style={style}
        classes={{ root: classes.inputContainer }}
        placeholder={translate('resources.common.search_reservation_placeholder')}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        inputRef={input => this.input = input}
        InputProps={{
          startAdornment: (
            <InputAdornment className={classes.adorment} position="end">
              <FontAwesomeIcon icon={faSearch} />
            </InputAdornment>
          ),
          disableUnderline: true,
          classes: { root: classes.colorInput },
        }}
        autoComplete='off'
      /> : null}
      <SearchResultListWithPermission  
        filterValue={filterValue}
        anchorEl={anchorEl}
        handleClose={this.onClose}
      />
    </Fragment>  
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(ReservationSearchInput)
