import React, { Component } from 'react'
import {
  Datagrid,
  Create,
  Edit,
  FunctionField,
  List,
  SimpleForm,
  ReferenceManyField,
  ReferenceField,
  TextField,
  TextInput,
  addField,
  translate,
  CardActions,
  required,
} from 'react-admin'
import {
  Grid,
  Checkbox,
  FormControlLabel,
  List as MUIList,
  ListItem,
  ListItemText,
  Avatar,
  Typography,
  withStyles,
  Button,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Provider } from '../provider'
import { changeBreadcrumb } from '../breadcrumb/action'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { parseUrl } from 'query-string'
import { Link } from 'react-router-dom'
import Snackbar from '../common/Snackbar'
import { validate } from './validate'
import CustomToolbar from '../common/CustomToolbarForm'
import { EditTitle } from '../common/Title'
import { isCompanyManager } from '../utils/permission'
import _ from 'lodash'

const styles = {
  node: {
    width: '100%',
  },
  snackbar: {
    width: '50%',
    maxWidth: '50%',
  },
}

const actionListStyles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  }
})

const ActionRoutePatternList = withStyles(actionListStyles)(({
  basePath,
  classes,
  routeId,
  routeGroupId,
  translate,
  companyManager,
}) => (
  <CardActions>
    {companyManager ? <Button
      component={Link}
      to={`${basePath}/create?routeId=${routeId}&routeGroupId=${routeGroupId}`}
      color="primary"
    >
      <FontAwesomeIcon className={classes.leftIcon} icon={faPlus} />
      {translate('button.create')}
    </Button> : null}
  </CardActions>
))

class _RoutePatternList extends Component {

  state = {}  
  async componentDidMount() {
    let { location, changeBreadcrumb, translate } = this.props
    let paths = []
    changeBreadcrumb({ paths })

    let options = parseUrl(location.search)
    if (options && options.query) {
      try {
        var filter = JSON.parse(options.query.filter)
      } catch (e) {
        // Do nothing
      }
      let { routeId } = filter || {}
      if (!routeId) return
      let route = await Provider.dataProvider('GET_ONE', 'routes', {
        id: routeId,
        filter: {
          fields: ['id', 'name', 'routeGroupId'],
          include: {
            relation: 'routeGroup',
            scope: {
              fields: ['id', 'name'],
            },
          },
        },
      })
      if (route && route.data) {
        route = route.data
        paths = [
          { label: translate('resources.routegroups.name', { smart_count: 2 }), to: '/routegroups' },
          { label: route.routeGroup.name, to: `/routegroups/${route.routeGroupId}` },
          { label: translate('resources.routepatterns.name', { smart_count: 2 }), to: '' },
        ]
        changeBreadcrumb({ paths })
        this.setState({ routeGroupId: route.routeGroupId, routeId: routeId })
      }
    }
  }

  render() {
    let { permissions, ...props } = this.props
    let { routeGroupId, routeId } = this.state
    let companyManager = isCompanyManager(permissions)
    return (
      <List
        actions={
          <ActionRoutePatternList
            routeGroupId={routeGroupId}
            routeId={routeId}
            companyManager={companyManager}
          />}
        bulkActionButtons={false}
        {...props}
        filterDefaultValues={{ status: { neq: '20ARCHIVED' } }}
      >
        <Datagrid rowClick={(id, basePath) => {
          if (!companyManager) return false
          return `${basePath}/${id}?routeGroupId=${this.state.routeGroupId}`
        }}>
          <TextField source="name" />
          <FunctionField label="resources.timetables.totalStop" render={record => {
            return (`${record.stops && record.stops.length}`)
          }} />
          <ReferenceField
            source="routeId"
            reference="routes"
            linkType={false}
          >
            <TextField source="name" />
          </ReferenceField>
        </Datagrid>
      </List>
    )
  }
}

const enhanceList = compose(
  translate,
  connect(null, { changeBreadcrumb })
)

export const RoutePatternList = enhanceList(_RoutePatternList)

const Nodes = ({
  classes,
  ids = [],
  data,
  onChange,
  sorted = [],
  nodes = [],
  stopCodes,
  companyManager,
}) => {
  let checks = nodes.reduce(function(pre, item) {
    pre[item.id] = 1
    return pre
  }, {})

  return ids.map((id, index) => {
    let item = data[id]
    if (stopCodes)
      item.code = stopCodes[item.id]
    sorted.push(item)
    return (
      <FormControlLabel
        key={index}
        className={classes.node}
        disabled={!companyManager}
        control={<Checkbox
          checked={checks[id]?true:false}
          onChange={(event, value) => {
            onChange(sorted, item, value)
          }} />}
        label={item.code ? `(${item.code}) - ${item.name}` : item.name}
      />
    )
  })
}

class _PatternNodes extends Component {

  changeNode(nodes, node, selected) {
    let { input } = this.props
    let { value } = input
    value = value || []
    let setId = value.reduce(function(pre, item) {
      pre[item.id] = 1
      return pre
    }, {})
    if (selected) {
      setId[node.id] = 1
    } else {
      delete setId[node.id]
    }
    let last = null
    let newVal = nodes.reduce(function(pre, item) {
      if (setId[item.id]) {
        let { id, name, code, lat, lon } = item
        let distance = 0
        if (pre.length > 0) {
          distance = ((item.properties.location - last.properties.location) / 1000).toFixed(1) + 'km'
        }
        pre.push({ id, name, code, lat, lon, distance })
        last = item
      }
      return pre
    }, [])
    input.onChange(newVal)
  }

  render() {
    let { 
      classes,
      translate,
      input,
      record,
      stopCodes,
      meta,
      companyManager,
    } = this.props
    let { value = [] } = input
    value = (value || !_.isEmpty(value)) ? value : [] 
    let { error } = meta
    return <Grid container>
      <Grid item xs={12} md={8}>
        {(value.length < 2 || error) && <Snackbar
          variant="error"
          message={translate('resources.routes.no_stop_for_routepattern')}
          className={classes.snackbar}
        />}
        <MUIList>
          {value.map((item, index) => {
            let { code, name, distance } = item
            return (
              <ListItem key={index} style={{borderLeft: '3px solid #ccc'}}>
                <Avatar style={{marginLeft: -29, width: 23, height: 23}}>
                  <FontAwesomeIcon size="xs" icon={faBus} />
                </Avatar>
                <ListItemText primary={`(${code}) - ${name}`} secondary={distance} />
              </ListItem>
            )
          })}
        </MUIList>
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography component="h2" variant="h6">
          {translate('resources.stops.name', { smart_count: 2 })}
        </Typography>
        <ReferenceManyField
          basePath="/"
          reference="routes/getRouteWithStop"
          resource="routes"
          target="companyId"
          filter={{ id: parseInt(record.routeId) }}
          record={{ companyId: 1 }}
        >
          <Nodes
            classes={classes}
            nodes={value}
            stopCodes={stopCodes}
            companyManager={companyManager}
            onChange={(data, item, val) => {
              this.changeNode(data, item, val)
            }}
          />
        </ReferenceManyField>
      </Grid>
    </Grid>
  }
}

const PatternNodes = addField(translate(_PatternNodes))

class _Form extends Component {
  state = { stopCodes: null }

  async componentDidMount() {
    let { record, translate, changeBreadcrumb } = this.props
    let paths = []
    changeBreadcrumb({ paths })

    let { routeId } = record
    routeId = parseInt(routeId)
    let route = await Provider.dataProvider('GET_ONE', 'routes', {
      id: routeId,
      filter: {
        fields: ['id', 'routeGroup', 'routeGroupId'],
        include: {
          relation: 'routeGroup',
          scope: {
            fields: ['stops', 'name'],
          }
        },
      },
    })
    if (!route || !route.data) return
    let { routeGroupId, routeGroup: { stops: stopCodes, name: groupName } } = route.data
    paths = [
      { label: translate('resources.routegroups.name', { smart_count: 2 }), to: '/routegroups' },
      { label: groupName, to: `/routegroups/${routeGroupId}` },
      {
        label: translate('resources.routepatterns.name', { smart_count: 2 }),
        to: `/routepatterns?filter=${JSON.stringify({ routeId })}`,
      },
      { label: record.id ? `#${record.id}` : translate('resources.common.create'), to: '' },
    ]
    changeBreadcrumb({ paths })
    this.setState({ routeGroupId, stopCodes })
  }

  render() {
    let { record, changeBreadcrumb, translate, companyManager, ...rest } = this.props
    let { routeGroupId, stopCodes } = this.state
    let { routeId } = record
    return(
      <SimpleForm
        record={record}
        {...rest}
        validate={validate}
        redirect={
          routeGroupId
            ? `/routegroups/${routeGroupId}`
            : `/routes/${routeId}`
        }
        toolbar={companyManager ? <CustomToolbar /> : null}
      >
        <TextInput validate={required()} source="name" fullWidth />
        <TextInput style={{display: 'none'}} source="routeId" />
        <ReferenceField
          source="routeId"
          reference="routes"
          label={translate('resources.routes.name', { smart_count: 1 })}
        >
          <TextField source="name" />
        </ReferenceField>
        <PatternNodes
          companyManager={companyManager}
          source="stops"
          stopCodes={stopCodes}
          classes={rest.classes}
        />
      </SimpleForm>
    )
  }
}

const enhanceForm = compose(
  translate,
  withStyles(styles),
  connect(null, { changeBreadcrumb })
)
const Form = enhanceForm(_Form)

export class RoutePatternCreate extends Component {
  render() {
    let { props } = this
    let { location, permissions } = props
    let companyManager = isCompanyManager(permissions)
    return (
      <Create
        title={<EditTitle resource={props.resource} />}
        {...props}
      >
        <Form location={location} companyManager={companyManager} />
      </Create>
    )
  }
}

export class RoutePatternEdit extends Component {
  render() {
    let { location, permissions, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <Edit
        title={<EditTitle
          resource={props.resource}
          render={record => record.name}
        />}
        location={location}
        {...props}
        undoable={false}
      >
        <Form location={location} companyManager={companyManager} />
      </Edit>
    )
  }
}
