import React, { Component, Fragment, } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  FunctionField,
  DateField,
  CardActions,
  translate,
  SelectInput,
  showNotification,
  ChipField,
} from 'react-admin'
import LinkField from './../common/LinkField'
import {
  VnisInvoiceStatuses,
  VnisInvoiceStatusesMapping,
  VnisSignStatusesMapping,
  EInvoiceProviders,
  VNIS_INVOICE_STATUS_INITIAL,
  VNIS_INVOICE_STATUS_ORIGIN,
  VNIS_INVOICE_STATUS_DELETE,
  VNIS_INVOICE_STATUS_DELETE_CANCEL,
  VNIS_INVOICE_STATUS_NEXBUS_CANCEL,
  VNIS_SIGN_STATUS_WATING,
  VNIS_SIGN_STATUS_ERROR,
  VNIS_SIGN_STATUS_SUCCESS,
  VNIS_SIGN_STATUS_SIGNING,
} from './../common/constants'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { Provider } from '../provider'
import { faFileExport } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
import _ from 'lodash'
import { green, grey, red, yellow, blueGrey } from '@material-ui/core/colors'
import moment from 'moment'
import GeneralReport from './GeneralReport'
import ExportEInvoiceSumaryButton from './ExportEInvoiceSumaryButton'
import { DatePickerInput } from '../common/DatePicker'

export const invoiceStatusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  [VNIS_INVOICE_STATUS_NEXBUS_CANCEL]: { color: 'white', backgroundColor: blueGrey[500] },
  [VNIS_INVOICE_STATUS_ORIGIN]: { color: 'white', backgroundColor: green[500] },
  [VNIS_INVOICE_STATUS_INITIAL]: { color: 'white', backgroundColor: grey[500] },
  [VNIS_INVOICE_STATUS_DELETE]: { color: 'white', backgroundColor: grey[500] },
  [VNIS_INVOICE_STATUS_DELETE_CANCEL]: { color: 'white', backgroundColor: grey[500] },
}

////////////////////////////////////////////////////////////////////////////////POS Session
export const InvoiceStatusField = withStyles(invoiceStatusStyles)(({ classes, ...props }) => {
  let status = _.get(props.record, 'id')
  let className = _.get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})

export const signStatusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  [VNIS_SIGN_STATUS_SUCCESS]: { color: 'white', backgroundColor: green[500] },
  [VNIS_SIGN_STATUS_WATING]: { color: 'white', backgroundColor: yellow[500] },
  [VNIS_SIGN_STATUS_SIGNING]: { color: 'white', backgroundColor: grey[500] },
  [VNIS_SIGN_STATUS_ERROR]: { color: 'white', backgroundColor: red[500] },
}

////////////////////////////////////////////////////////////////////////////////POS Session
export const SignStatusField = withStyles(signStatusStyles)(({ classes, ...props }) => {
  let status = _.get(props.record, 'id')
  let className = _.get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})

////////////////////////////////////////////////////////////////////////////////
// List
const styles = {
  item: {
    maxWidth: 180
  }
}

const EInvoiceListFilter = withStyles(signStatusStyles)(({ classes, translate, ...props }) => {
  return (
    <Filter {...props}>
      <DatePickerInput
        label={translate('resources.einvoices.fields.createdAt')}
        source="createdAt_between"
        dateFormat="DD/MM/YYYY"
        keyboard={true}
        alwaysOn
      />
      <TextInput label="resources.common.search" source="q" alwaysOn fullWidth />
      <SelectInput
        optionText="name"
        source="provider"
        choices={EInvoiceProviders}
        fullWidth
        alwaysOn
        className={classes.item}
      />
      <SelectInput
        optionText="name"
        source="invoiceStatus"
        choices={VnisInvoiceStatuses}
        fullWidth
        alwaysOn
        className={classes.item}
      />
    </Filter>
  )
})

class _IssueEInvoiceButton extends Component {

  issueEInvoices = async () => {
    const { showNotification, selectedIds, filterValues } = this.props
    const allDay = _.isEmpty(selectedIds)
    const { createdAt_between } = filterValues
    const result = await Provider.dataProvider('REMOTE', 'charges', {
      method: 'issueEinvoices',
      requestMethod: 'POST',
      data: { createdAt: createdAt_between, eInvoiceIds: selectedIds, allDay }
    })

    if (result) {
      showNotification('notification.send_issue_einvoice_request_success', 'success')
    }
  }

  render() {
    let { translate, classes, color } = this.props
    return <LinkField
      color={color}
      onClick={this.issueEInvoices}
    >
      <span>
        <FontAwesomeIcon icon={faFileExport} />&nbsp;{translate('button.issue_invoice')}
      </span>
    </LinkField>
  }
}

const enhance = compose(withStyles(styles), translate, connect(null, { showNotification }))

export const IssueEInvoiceButton = enhance(_IssueEInvoiceButton)

const EInvoiceBulkActionButtons = props => (
  <Fragment>
    <IssueEInvoiceButton  {...props} />
  </Fragment>
)

const EInvoiceActions = (props) => {
  const { filterValues, translate } = props
  return (
    <CardActions>
      <IssueEInvoiceButton filterValues={filterValues} />
      <ExportEInvoiceSumaryButton
        label={translate('button.einvoice_sumary')}
        type="button"
        color="primary"
        filterValues={filterValues}
      />
    </CardActions>
  )
}

const styleList = () => ({
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
})

const _EInvoiceList = props => {
  let { classes, translate } = props
  return <Fragment>
    <GeneralReport />
    <br />
    <List
      filters={<EInvoiceListFilter translate={translate} />}
      actions={<EInvoiceActions translate={translate} />}
      bulkActionButtons={<EInvoiceBulkActionButtons />}
      filterDefaultValues={{
        invoiceStatus: VNIS_INVOICE_STATUS_INITIAL,
        createdAt_between: moment().toISOString()
      }}
      filter={{
        order: 'createdAt DESC',
      }}
      {...props}
    >
      <Datagrid>
        <TextField source="erp" />
        <TextField source="transactionId" />
        <TextField source="invoiceNo" />
        <FunctionField
          source="invoiceStatus"
          render={(record) => {
            const { invoiceStatus } = record
            const name = VnisInvoiceStatusesMapping[invoiceStatus]
            const rd = { id: invoiceStatus, name: translate(name) }
            return <InvoiceStatusField record={rd} source="name" />
          }}
        />
        <FunctionField
          source="signStatus"
          render={(record) => {
            const { signStatus } = record
            const name = VnisSignStatusesMapping[signStatus]
            const rd = { id: signStatus, name: translate(name) }
            return <SignStatusField record={rd} source="name" />
          }}
        />
        <DateField source="issuedDate" locales="vi-VN" showTime />
        <DateField source="createdAt" locales="vi-VN" showTime />
      </Datagrid>
    </List>
  </Fragment>
}

const enhanceList = compose(withStyles(styleList), translate)
export const EInvoiceList = enhanceList(_EInvoiceList)
