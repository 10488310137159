import React, { Component, Fragment } from 'react'
import { Chip, Menu, MenuItem, ListItemText } from '@material-ui/core'
import { translate } from 'react-admin'
import _ from 'lodash'
import { Provider } from '../provider'

const passengerCollectionMapping = {
  '00PICKUP': { source: 'pickUpPoint', label: 'resources.charges.pickUpPoint', emptyMessage: 'resources.charges.empty_pickUpPoint'},
  '10DROPOFF': { source: 'dropOffPoint', label: 'resources.charges.dropOffPoint', emptyMessage: 'resources.charges.empty_dropOffPoint'}
}

const PassengerCollectionMenu = translate(({ 
  datum,
  onClose,
  open,
  anchorEl,
  translate,
  selected,
  setupPassengerCollection,
  passengerCollectionType,
}) => {
  let { label } = passengerCollectionMapping[passengerCollectionType]
  return <Menu
    anchorEl={anchorEl}
    open={open}
    placement="right-start"
    onClose={() => onClose()}
  >
    <MenuItem disabled={true}>{translate(label)}</MenuItem>
    {datum.map((passengerCollection, index) => (
      <MenuItem
        key={index}
        selected={passengerCollection.id === selected}
        onClick={() => setupPassengerCollection(passengerCollection.id)}
      >
        <ListItemText>{passengerCollection.name}</ListItemText>
      </MenuItem>
    ))}
  </Menu>
})

class PassengerCollectionChip extends Component {

  state = {
    record: {},
    open: false,
    anchorEl: null,
    itemDetail: {},
  }

  componentDidMount() {
    let { datum, charge, passengerCollectionType } = this.props
    let { source } = passengerCollectionMapping[passengerCollectionType]
    let selected = parseInt(charge.itemDetail[source])
    this.findPassengerCollection(datum, selected)
  }

  findPassengerCollection = (datum, selected) => {
    let record = _.find(datum, data => data.id === selected)
    this.setState({ record: record, selected })
  }

  open = event => {
    this.setState({
      open: true,
      anchorEl: event.target,
    })
  }

  close = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  setupPassengerCollection = id => {
    let { charge, passengerCollectionType, datum, reload } = this.props
    let { id: chargeId } = charge
    let { source } = passengerCollectionMapping[passengerCollectionType]
    let data = { chargeId, passengerCollection: { key: source, value: id } }
    Provider.dataProvider('REMOTE', 'charges', {
      method: 'updatePassengerCollection',
      requestMethod: 'POST',
      data,
    }).then(
      () => {
        this.findPassengerCollection(datum, id)
        reload(true)
        this.close()
      }
    ).catch(() => {
      this.close()
    })
  }

  render() {
    let { record, open, anchorEl, selected } = this.state
    let { datum = [], translate, passengerCollectionType  } = this.props
    let { emptyMessage } = passengerCollectionMapping[passengerCollectionType]
    return <Fragment>
      <Chip onClick={this.open} label={record ? (record.name ? (record.name.length > 20 ? `${record.name.substring(0, 17)}...` : record.name) : '') : translate(emptyMessage)} />
      <PassengerCollectionMenu 
        open={open}
        anchorEl={anchorEl}
        datum={datum}
        onClose={this.close}
        selected={selected}
        setupPassengerCollection={this.setupPassengerCollection}
        passengerCollectionType={passengerCollectionType}
      />
    </Fragment>
  }
}

export default translate(PassengerCollectionChip)
