import { Component } from 'react'

class PreviewListener extends Component {

  constructor(props) {
    super(props)
    this.state = {
      src: ''
    }
  }

  componentDidMount() {
    const { source, record, onDrop } = this.props
    let src = record[source]
    this.setState({
      src: src
    })
    onDrop(src)
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { source, record, onDrop } = nextProps
    let currentSrc = this.state.src
    if (!record) return
    let src = record[source]
    if (src !== currentSrc) {
      this.setState({
        src: src
      })
      onDrop(src)
    }
  }

  render() {
    return null
  }
}

export default PreviewListener
