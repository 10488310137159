import React, { Component, Fragment } from 'react'
import {
  Title,
  translate,
  SimpleForm,
  SelectInput,
  required,
  TextInput,
  showNotification,
  WithPermissions,
  minLength,
  maxLength,
} from 'react-admin'
import {
  Card,
  withStyles,
  Grid,
  Typography,
  Divider,
  TextField as MUITextField,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import compose from 'recompose/compose'
import SaleTicketToolbar from './SaleTicketToolbar'
import { Provider } from '../provider'
import { change as changeForm } from 'redux-form'
import { green, grey, red, blue } from '@material-ui/core/colors'
import moment from 'moment'
import _ from 'lodash'
import { formatCurrency } from '../utils/formatUtil'
import { connect } from 'react-redux'
import { changeBreadcrumb } from '../breadcrumb/action'
import { DatePickerField } from '../common/DatePicker'
import Snackbar from '../common/Snackbar'
import * as permission from '../utils/permission'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import PropTypes from 'prop-types'
import { validate } from './validate'
import { FuzzySelectArrayInput, defaultFuzzySearch, } from '../common/react-fuzzy-picker'
import Pagination from '../common/Pagination'

const fareStyles = theme => ({
  root: {
    width: '100%',
  },
  textContainer: {
    width: '100%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    padding: '10px',
    textAlign: 'center',
  },
  moneyContainer: {
    width: '100%',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    padding: '10px',
    textAlign: 'center',
  },
  quantityContainer: {
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    padding: '10px',
    textAlign: 'center',
  },
  item: {
    width: '100%',
    padding: '10px',
    margin: 'auto',
  },
  quantityInput: {
    height: 30,
    width: 30,
    padding: '0px 0px 0px 10px',
    textAlign: 'center',
    display: 'flex-end',
    alignContent: 'center',
  },
  header: {
    backgroundColor: '#fff59d'
  },
  tooltip: {
    backgroundColor: '#f44336',
    height: 30,
    width: 30,
  },
  subtractButton: {
    border: `1px solid ${red[600]}`,
    color: red[600],
    '&:hover': {
      backgroundColor: red[100]
    },
    borderRadius: 4,
    padding: 0,
    width: 30,
    height: 30,
    marginRight: 8,
  },
  addButton: {
    border: `1px solid ${blue[600]}`,
    color: blue[600],
    '&:hover': {
      backgroundColor: blue[100]
    },
    borderRadius: 4,
    padding: 0,
    width: 30,
    height: 30,
    marginLeft: 8,
  },
  textSecondary: {
    fontSize: 14,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '8px 0px 16px',
    position: 'relative',
  },
  textLast: {
    fontSize: 14,
    border: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '8px 0px 16px',
    position: 'relative',
  },
  textTop: {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: '#ffc107',
    padding: 4,
    fontSize: 11,
  },
})

class _Fare extends Component {

  componentDidMount() {
    this.firstInputRef && this.firstInputRef.focus()
  }

  render() {
    let {
      fare = {},
      classes,
      translate,
      onChangeQuantity,
      confirmChangeSegment,
      totalFareMapping = {},
      segmentFareMapping,
      totalQuantity,
      idx,
      segment: currentSegment,
      lastFare = {},
    } = this.props
    let { name, amount, id } = fare
    let discountDefault = '100%'
    let discount = _.get(fare, 'fareCondition.default', discountDefault)
    let errorMessage
    if (totalQuantity === 0) {
      errorMessage = translate('resources.saletickets.no_select_product')
    }
    let isFirstProductType = idx === 0
    let totalFare = totalFareMapping[id] || {}
    let currentSegmentId = _.get(currentSegment, 'id')
    let segmentId = _.get(segmentFareMapping[id], 'segmentId')
    let quantity = segmentId ===  currentSegmentId ? _.get(totalFare, 'quantity', 0) : 0
    let className = _.isEqual(fare, lastFare) ? classes.textLast : classes.textSecondary

    return <Grid item xs={3} className={className}>
      {discount !== discountDefault && <div className={classes.textTop}>{`-${discount}`}</div>}
      <div className={classes.textContainer}>{name}</div>
      <div className={classes.moneyContainer}>{formatCurrency(amount)}</div>
      <div className={classes.quantityContainer}>
        <IconButton
          onClick={() => {
            if (quantity - 1 < 0) return
            if (segmentId && currentSegmentId !== segmentId) {
              confirmChangeSegment(currentSegment, fare)
            } else {
              onChangeQuantity(quantity - 1, currentSegment, fare)
            }
          }}
          variant="outlined"
          className={classes.subtractButton}
          size="small"
        >
          <RemoveIcon fontSize="small" />
        </IconButton>
        <Tooltip
          title={errorMessage || ''}
          placement="right-end"
          open={!(!errorMessage) && isFirstProductType}
          classes={{
            tooltip: classes.tooltip
          }}
          arrow="true"
        >
          <MUITextField
            variant="outlined"
            type="number"
            error={!(!errorMessage) && isFirstProductType}
            onChange={evt => {
              let quantity = evt.target.value
              if (quantity < 0) return
              if (segmentId && currentSegmentId !== segmentId) {
                confirmChangeSegment(currentSegment, fare)
              } else {
                onChangeQuantity(quantity, currentSegment, fare)
              }
            }}
            value={parseInt(quantity)}
            inputRef={ref => {
              if (isFirstProductType) {
                this.firstInputRef = ref
              }
            }}
            InputProps={{
              classes: { input: classes.quantityInput },
              min: 0,
              onKeyPress: (e) => {
                if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                  e.preventDefault()
                }
              },
            }}
          />
        </Tooltip>
        <IconButton
          onClick={() => {
            if (typeof (quantity) === 'string') {
              quantity = parseInt(quantity)
            }
            if (segmentId && currentSegmentId !== segmentId) {
              confirmChangeSegment(currentSegment, fare)
            } else {
              onChangeQuantity(quantity + 1, currentSegment, fare)
            }
          }}
          variant="outlined"
          className={classes.addButton}
          size="small"
        >
          <AddIcon fontSize="small" />
        </IconButton>
      </div>
    </Grid>
  }
}

const Fare = compose(withStyles(fareStyles), translate)(_Fare)

const productStyles = {
  root: {
    display: 'flex',
    textAlign: 'center',
    marginBottom: 12,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  productInfo: {
    display: 'flex',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 4,
  },
  productText: {
    margin: 'auto',
  },
  textPrimary: {
    fontSize: 14,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    margin: '8px 0px 16px',
  },
  textSecondary: {
    fontSize: 14,
    border: '1px solid rgba(0, 0, 0, 0.12)',
  },
  unit: {
    textTransform: 'lowercase'
  }
}

const _Product = ({
  record = {},
  idx,
  classes,
  translate,
  onChangeQuantity,
  input,
  onChangeInput,
  totalFareMapping,
  totalQuantity,
  segmentFareMapping,
  confirmChangeSegment,
}) => {
  let name = _.get(record, 'name', '')
  let fares = _.get(record, 'fares', [])
  let lastFare = _.last(fares)

  return <Grid container spacing={8} >
    <Grid item xs={3} className={classes.textPrimary}>{name}</Grid>
    {!_.isEmpty(fares) ? _.map(fares, (fare, idx) => <Fare
      key={idx}
      fare={fare}
      segment={record}
      onChangeQuantity={onChangeQuantity}
      confirmChangeSegment={confirmChangeSegment}
      totalFareMapping={totalFareMapping}
      totalQuantity={totalQuantity}
      segmentFareMapping={segmentFareMapping}
      lastFare={lastFare}
    />) : null}
  </Grid>
}

const enhanceProduct = compose(withStyles(productStyles), translate)
const Product = enhanceProduct(_Product)

const productInfomationStyle = {
  title: {
    marginBottom: 50,
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  totalLabel: {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalValue: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
}

class _ProductInformation extends Component {

  state = {
    page: 1,
    perPage: 5,
    currentFares: []
  }

  async componentDidMount() {
    await this.init()
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let { fares: items, segmentId } = nextProps
    let { page, perPage, currentSegmentId } = prevState
    if (currentSegmentId !== segmentId) {
      let offset = (page - 1) * perPage
      let pagedItems = _.drop(items, offset).slice(0, perPage)
      return { currentFares: pagedItems, currentSegmentId: segmentId }
    }
  }

  async init() {
    let { fares } = this.props
    let { page, perPage, currentSegmentId } = this.state
    let currentFares = this.getData(fares, page, perPage)
    this.setState({ currentFares })
  }

  setPage = page => {
    let { fares: items } = this.props
    let { perPage } = this.state
    this.setState({ page })
    let currentFares = this.getData(items, page, perPage)
    this.setState({ currentFares })
  }

  setPerPage = perPage => {
    let { fares: items } = this.props
    let { page } = this.state
    this.setState({ perPage })
    let currentFares = this.getData(items, page, perPage)
    this.setState({ currentFares })
  }

  getData = (items, page, perPage) => {
    let offset = (page - 1) * perPage
    let pagedItems = _.drop(items, offset).slice(0, perPage)
    return pagedItems
  }

  render() {
    let {
      classes,
      translate,
      fares,
      productId,
      onChangeQuantity,
      totalFareMapping,
      totalQuantity,
      segmentFareMapping,
      confirmChangeSegment,
      setFareInput,
      segmentId,
    } = this.props

    let { page, perPage, currentFares, } = this.state

    return <Grid item xs={12} md={12}>
      <Typography
        variant="h4"
        className={classes.title}
      >
        {translate('resources.saletickets.ticket')}
      </Typography>
      {!_.isEmpty(currentFares) ? <div>
        {_.map(currentFares, (item, idx) => (
          <Product
            key={idx}
            idx={idx}
            record={item}
            onChangeQuantity={onChangeQuantity}
            onChangeInput={(input) => {
              setFareInput({ [productId]: input })
            }}
            totalFareMapping={totalFareMapping}
            totalQuantity={totalQuantity}
            segmentFareMapping={segmentFareMapping}
            confirmChangeSegment={confirmChangeSegment}
          />
        ))}
        { !segmentId && <Pagination
          className={classes.pagination}
          page={page}
          perPage={perPage}
          setPage={this.setPage}
          setPerPage={this.setPerPage}
          total={fares.length}
        />}
      </div> : <Snackbar
        variant="warning"
        message={translate('notification.empty_product')}
        className={classes.emptySnack}
      />}
    </Grid>
  }
}
const enhanceProductInformation = compose(withStyles(productInfomationStyle), translate)
const ProductInformation = enhanceProductInformation(_ProductInformation)

const customerInfomationStyle = {
  root: {
    marginTop: 18,
    paddingLeft: 32,
    marginLeft: 16,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  title: {
    marginBottom: 16,
  },
  info: {
    display: 'flex',
  },
  cusInfo: {
    width: '100%',
    marginRight: 16,
  },
}

const validateFullName = [minLength(6), maxLength(24)]

const _CustomerInformation = (props) => {
  let { classes, translate } = props
  let extra = { resource: 'saleticketbysegments', fullWidth: true }
  return <Fragment>
    <Typography
      variant="h4"
      className={classes.title}
    >
      {translate('resources.saletickets.customerInfo')}
    </Typography>
    <TextInput
      {...extra}
      label="resources.saletickets.phone"
      source="phone"
    />
    <TextInput
      {...extra}
      label="resources.saletickets.cusName"
      source="fullName"
      validate={validateFullName}
    />
    <TextInput
      {...extra}
      label="resources.saletickets.email"
      source="email"
    />
    <TextInput
      {...extra}
      label="resources.saletickets.note"
      source="note"
    />
  </Fragment>
}

const enhanceCustomerInformation = compose(withStyles(customerInfomationStyle), translate)
const CustomerInformation = enhanceCustomerInformation(_CustomerInformation)

const orderInfomationStyle = {
  root: {
    marginTop: 18,
    paddingLeft: 32,
    marginLeft: 16,
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
  },
  title: {
    marginBottom: 16,
    marginTop: 32,
  },
  item: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sumTotal: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
  },
  text: {
    margin: '16px 0px 8px',
  },
}

const _OrderIformation = (props) => {
  let {
    classes,
    translate,
    totalFareMapping,
    segmentFareMapping,
  } = props
  let keys = _.keys(totalFareMapping)
  keys = _.map(keys, key => Number(key))
  let segmentFare = _.get(segmentFareMapping, _.head(keys), {})
  let sumTotal = 0, sumQuantity = 0
  return <Fragment>
    <Typography
      variant="h4"
      className={classes.title}
    >
      {translate('resources.saleticketbysegments.orderInfo')}
    </Typography>
    <div className={classes.text}>{_.get(segmentFare, 'segmentName', '')}</div>
    {!_.isEmpty(keys) ? _.map(keys, key => {
      let segment = _.get(segmentFareMapping, key)
      let totalFare = _.get(totalFareMapping, key)
      let total = _.get(totalFare, 'total', 0)
      let quantity = _.get(totalFare, 'quantity', 0)
      sumTotal += total
      sumQuantity += quantity
      return <Grid container>
        <Grid item xs={6}>{_.get(segment, 'fareName', '')}</Grid>
        <Grid item xs={2} className={classes.item}>{`x${quantity}`}</Grid>
        <Grid item xs={4} className={classes.item}>{formatCurrency(total)}</Grid>
      </Grid>
    }) : null}
    <Grid container>
      <Grid item xs={6}>{translate('resources.saleticketbysegments.total')}</Grid>
      <Grid item xs={2} className={classes.item}>{`${sumQuantity}`}</Grid>
      <Grid item xs={4} className={classes.item}>{formatCurrency(sumTotal)}</Grid>
    </Grid>
  </Fragment>
}

const enhanceOrderInformation = compose(withStyles(orderInfomationStyle), translate)
const OrderInformation = enhanceOrderInformation(_OrderIformation)

const infomationStyle = {
  root: {
    padding: 18,
  },
  productCusContainer: {
    display: 'flex',
  }
}

const _Information = ({
  classes,
  onChangeQuantity,
  setFareInput,
  totalFareMapping,
  totalQuantity,
  initFareQuantityDefault,
  fares,
  segmentFareMapping,
  confirmChangeSegment,
  productId,
  segmentId,
}) => {
  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12} md={8}>
        <ProductInformation
          fares={fares}
          productId={productId}
          segmentId={segmentId}
          onChangeQuantity={onChangeQuantity}
          setFareInput={setFareInput}
          totalFareMapping={totalFareMapping}
          totalQuantity={totalQuantity}
          initFareQuantityDefault={initFareQuantityDefault}
          segmentFareMapping={segmentFareMapping}
          confirmChangeSegment={confirmChangeSegment}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomerInformation />
        {!_.isEmpty(totalFareMapping) && <OrderInformation 
          fares={fares}
          totalFareMapping={totalFareMapping}
          segmentFareMapping={segmentFareMapping}
        />}
      </Grid>
    </Grid>
    <Divider />
  </div>
}

const enhanceInformation = compose(withStyles(infomationStyle))
const Information = enhanceInformation(_Information)

const confirmDialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  container: {
    padding: 0
  },
  line: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: 8,
  },
}

const _ConfirmDialog = ({
  classes,
  translate,
  open,
  onClose,
  handleConfirm,
}) => {
  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle className={classes.title}>
      <span className={classes.textTitle}>{translate('notification.create_cart_order')}</span>
    </DialogTitle>
    <DialogContent className={classes.container}>
      <div className={classes.line}>
        {translate('notification.change_segment_confirm')}
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{translate('button.close')}</Button>
      <Button 
        onClick={handleConfirm}
        variant="contained"
        color="primary"
      >
        {translate('button.create')}
      </Button>
    </DialogActions>
  </Dialog>
}
const ConfirmDialog = compose(translate, withStyles(confirmDialogStyle))(_ConfirmDialog)

const style = {
  root: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
  },
  form: {
    width: '100%'
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    position: 'absolute',
  },
  emptySnack: {
    width: '100%',
    maxWidth: '100%',
  },
}

const SALE_TICKE_SEGMENT_FORM = 'sale-ticket-segment-form'

class SaleTicketSegmentLayout extends Component {

  state = {
    totalQuantity: 0,
    loading: false,
    openAgencyPaymentDialog: false,
    routeChoices: [],
    segments: [],
    fares: [],
    openConfirm: false,
  }

  async componentDidMount() {
    await this.init()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { routeId, companyId } = nextProps
    let { routeDefault, companyDefault, companyChoices, routeChoices } = prevState
    if (!routeId) {
      routeDefault = (routeChoices && routeChoices.length > 0) && _.get(routeChoices, '0.id')
    } else if (routeId !== routeDefault) {
      routeDefault = routeId
    }
    if (!routeId) {
      routeDefault = (companyChoices && companyChoices.length > 0) && _.get(companyChoices, '0.id')
    } else if (companyId !== companyDefault) {
      companyDefault = companyId
    }
    return { routeDefault, companyDefault }
  }

  init = async () => {
    let { translate, permissions, changeBreadcrumb } = this.props
    let agencyRole = permission.isAgencyRole(permissions)
    let companyRole = permission.isCompanyRole(permissions)
    let paths = [
      { label: translate('resources.reservations.name', { smart_count: 2 }), to: '/reservations' },
      { label: translate('resources.saleticketbysegments.name'), to: '' },
    ]
    changeBreadcrumb({ paths })
    let { companyChoices, routeChoices, companyDefault, routeDefault, } = this.state
    if (agencyRole) {
      companyChoices = await this.getRegistedBuyTicketCompanies()
      if (companyChoices && companyChoices.length > 0) {
        companyDefault = companyDefault || _.get(companyChoices, '0.id')
      }
    }
    routeChoices = await this.getRoutes(companyDefault)
    let segments = [], currentRouteProductId
    if (routeChoices && routeChoices.length > 0) {
      routeDefault = routeDefault || _.get(routeChoices, '0.id')
      currentRouteProductId = _.get(routeChoices, '0.routeProductId')
      segments = currentRouteProductId ? await this.getSegments(currentRouteProductId) : []
    }
    this.setState({
      routeDefault,
      companyDefault,
      companyChoices,
      routeChoices,
      agencyRole,
      companyRole,
      segments,
      currentRouteProductId,
      fares: segments,
    })
  }

  getRegistedBuyTicketCompanies = async () => {
    let res = await Provider.dataProvider('REMOTE', 'companies', {
      method: 'getBusTourCompaniesByAgency',
      requestMethod: 'GET'
    })
    if (res && res.data) {
      return res.data
    }
  }

  getRoutes = async (companyId) => {
    let res = await Provider.dataProvider('REMOTE', 'routes', {
      method: 'getRoutesByProductType',
      requestMethod: 'GET',
      data: { productType: '60SEAT_BY_SEGMENT' }
    })
    if (res && res.data) {
      let routes = _.get(res, 'data.routes', [])
      let productId = _.get(res, 'data.productId')
      this.setState({ productId })
      return routes
    }
  }

  setFareQuantityDefault = (productDefault, fareDefault) => {
    this.setState({ productDefault, fareDefault })
  }

  initFareQuantityDefault = (productDefault, fareDefault) => {
    this.setState({
      productDefault,
      fareDefault,
    })
    let { amount } = fareDefault
    this.setFareQuantity(1, productDefault, fareDefault, amount)
  }

  setFareInput = (fareInputs) => {
    let productFareInput = { ...this.state.productFareInput, ...fareInputs }
    this.setState({ productFareInput })
  }

  setFareQuantity = (
    quantity,
    segment,
    fare,
    total,
    prevRecord = {},
  ) => {
    let { productId } = this.state
    let { totalFareMapping = {}, totalQuantity, segmentFareMapping = {} } = prevRecord
    let { id: segmentId, name: segmentName } = segment
    let { id: fareId, name: fareName, amount } = fare
    segmentFareMapping = {
      ...segmentFareMapping, [fareId]: {
        segmentId,
        quantity,
        fareId,
        amount,
        fareName,
        segmentName,
        productType: '60SEAT_BY_SEGMENT',
        productId,
      }
    }
    totalFareMapping = { ...totalFareMapping, [fareId]: { total, quantity } }
    totalQuantity = _.reduce(Object.values(segmentFareMapping), (sum = 0, ele) => {
      let { quantity } = ele
      sum += parseInt(quantity)
      return sum
    }, 0)
    this.setState({
      totalFareMapping,
      segmentFareMapping,
      totalQuantity,
    })
  }

  onChangeQuantity = (quantity, segment, fare) => {
    let { amount } = fare
    let total = quantity * amount
    if (quantity) {
      let { totalFareMapping = {}, totalQuantity, segmentFareMapping = {} } = this.state
      let prevRecord = { totalFareMapping, totalQuantity, segmentFareMapping }
      this.setFareQuantity(quantity, segment, fare, total, prevRecord)
    } else {
      this.resetOrder()
    }
  }

  confirmChangeSegment = (currentSegment, currentFare) => {
    this.setState({ openConfirm: true, currentSegment, currentFare })
  }

  onClose = () => {
    this.setState({ openConfirm: false })
  }

  resetOrder = () => {
    this.setState({
      totalFareMapping: {},
      segmentFareMapping: {},
      totalQuantity: 0,
    })
  }

  handleConfirm = () => {
    let { productId, currentFare, currentSegment } = this.state
    let amount = _.get(currentFare, 'amount')
    let { id: segmentId, name: segmentName } = currentSegment
    let { id: fareId, name: fareName } = currentFare
    let totalFareMapping = {
      [fareId]: { total: amount, quantity: 1 },
    }
    let totalQuantity = 1
    let segmentFareMapping = {
      [fareId]: {
        segmentId,
        quantity: 1,
        fareId,
        amount,
        fareName,
        segmentName,
        productType: '60SEAT_BY_SEGMENT',
        productId,
      },
    }
    this.setState({ totalFareMapping, totalQuantity, segmentFareMapping })
    this.onClose()
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { location } = this.props
    let { location: prevLocation } = prevProps
    const updated = !(prevLocation === location)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      await this.init()
    }
  }

  reduceRoute = async (value) => {
    let routeChoices = await this.getRoutes(value)
    let { changeForm } = this.props
    let routeDefault
    if (routeChoices && routeChoices.length > 0) {
      routeDefault = _.get(routeChoices, '0.id')
      changeForm(SALE_TICKE_SEGMENT_FORM, 'routeId', routeDefault)
    }
    this.setState({ routeChoices })
  }

  onChangeRoute = async (e, value) => {
    const { routeChoices = [], currentRouteId, } = this.state

    if (currentRouteId !== value) {
      const route = _.find(routeChoices, item => item.id === Number(value))
      let routeProductId = _.get(route, 'routeProductId')
      let segments = await this.getSegments(routeProductId)
      this.setState({
        currentRouteId: value,
        currentRouteProductId: routeProductId,
        segments,
        fares: segments,
      })
    }
  }

  getSegments = async (routeProductId, segmentId) => {
    let filter = segmentId ? { id: segmentId } : {}
    let res = await Provider.dataProvider('REMOTE', 'routeproducts', {
      method: 'getInformation',
      requestMethod: 'GET',
      data: {
        routeProductId,
        groupBy: 2,
        filter: filter,
      },
      sort: {},
      pagination: {}
    })
    if (res && res.data) {
      let segs = _.get(res, 'data.segments', [])
      const segments = _.reduce(segs, (result, item) => {
        let { fareIndexId, id } = item
        let segment = {
          id,
          name: `${_.get(item, 'originName', '')} - ${_.get(item, 'destinationName', '')}`,
          fareIndexId,
          ...item,
        }
        result.push(segment)
        return result
      }, [])
      return segments
    }
  }

  onChangeSegment = async (e, value) => {
    let { changeForm } = this.props
    const { currentSegmentId, currentRouteProductId, perPage } = this.state
    const segmentId = Number(value)
    let segments = []
    if (!value) {
      segments = await this.getSegments(currentRouteProductId)
    } else if (segmentId && currentSegmentId !== segmentId) {
      segments = await this.getSegments(currentRouteProductId, segmentId)
      changeForm(SALE_TICKE_SEGMENT_FORM, 'segmentId',segmentId)
      this.resetOrder()
    }
    this.setState({ currentSegmentId: segmentId, fares: segments, })
  }

  render() {
    let { translate, classes, changeForm } = this.props
    let {
      totalFareMapping,
      routeDefault,
      companyDefault,
      totalQuantity,
      routeChoices,
      companyChoices,
      agencyRole,
      segmentFareMapping = [],
      productFareInput,
      segments,
      fares,
      currentSegmentId,
      openConfirm,
      productId,
    } = this.state
    let sizeSearchItem = agencyRole ? 4 : 6
    let canSale = agencyRole ? (companyDefault && routeDefault) : !(!routeDefault)
    return <Fragment>
      <Title title={translate('resources.saleticketbysegments.name')} />
      <Card className={classes.container}>
        {canSale ? <Fragment>
          <SimpleForm
            form={SALE_TICKE_SEGMENT_FORM}
            resource="saleticketbysegments"
            className={classes.form}
            toolbar={<SaleTicketToolbar
              form={SALE_TICKE_SEGMENT_FORM}
              totalFareMapping={totalFareMapping}
              productFareMapping={segmentFareMapping}
              productFareInput={productFareInput}
            />}
            record={{ routeId: routeDefault, companyId: companyDefault }}
            asyncBlurFields={['phone']}
            asyncValidate={async (record) => {
              const { phone } = record
              if (!phone) return
              let { phones = {} } = this.state
              let customer = phones[phone]
              if (!customer) {
                phones = { ...phones, phone: {} }
                let result = await Provider.dataProvider('REMOTE', 'customers', {
                  method: 'findByPhone',
                  data: { phone },
                })
                let _customer = _.get(result, 'data.customer')
                if (!_.isEmpty(_customer)) {
                  customer = _customer
                  phones = { ...phones, phone: customer }
                }
                this.setState({ phones })
              }
              if (!_.isEmpty(customer)) {
                if (!record.fullName) changeForm(SALE_TICKE_SEGMENT_FORM, 'fullName', customer.fullName)
                if (!record.email) changeForm(SALE_TICKE_SEGMENT_FORM, 'email', customer.email)
              }
            }}
            validate={validate}
          >
            <Grid container className={classes.root}>
              <Grid item container>
                {agencyRole && <Grid item md={sizeSearchItem} xs={12}>
                  <SelectInput
                    fullWidth
                    onChange={(evt, value) => this.reduceRoute(value)}
                    label="resources.saletickets.companyId"
                    source="companyId"
                    resource="companies"
                    choices={companyChoices}
                    optionText="name"
                  />
                </Grid>}
                <Grid item md={sizeSearchItem} xs={12}>
                  <SelectInput
                    fullWidth
                    source="routeId"
                    resource="routes"
                    choices={routeChoices}
                    translateChoice={false}
                    onChange={this.onChangeRoute}
                    optionText={record => <span>
                      {record.name}
                    </span>}
                    validate={required()}
                  />
                </Grid>
                <Grid item md={sizeSearchItem} xs={12}>
                  <FuzzySelectArrayInput
                    label={translate('resources.saletickets.segment')}
                    source="segmentId"
                    disabled={_.isEmpty(segments)}
                    items={segments}
                    renderItem={({ name }) => name }
                    onChange={this.onChangeSegment}
                    allowEmpty
                    {...defaultFuzzySearch({ name: 'name' })}
                  />
                </Grid>
              </Grid>
            </Grid>
            { !_.isEmpty(fares) ? <Information
              fares={fares}
              totalFareMapping={totalFareMapping}
              totalQuantity={totalQuantity}
              setFareInput={this.setFareInput}
              onChangeQuantity={this.onChangeQuantity}
              initFareQuantityDefault={this.initFareQuantityDefault}
              segmentFareMapping={segmentFareMapping}
              confirmChangeSegment={this.confirmChangeSegment}
              productId={productId}
              segmentId={currentSegmentId}
            /> : null}
          </SimpleForm> 
          {openConfirm && <ConfirmDialog
            open={openConfirm}
            handleConfirm={this.handleConfirm}
            onClose={this.onClose}
          />}
        </Fragment>
          : <Snackbar
            variant="warning"
            message={translate('notification.empty_route_tour')}
            className={classes.emptySnack}
          />}
      </Card>
    </Fragment>
  }
}

const mapStateToProps = state => {
  let filterValues = state.form[SALE_TICKE_SEGMENT_FORM] && state.form[SALE_TICKE_SEGMENT_FORM].values ? state.form[SALE_TICKE_SEGMENT_FORM].values : {}
  let { routeId, companyId, departureDate } = filterValues
  return { routeId, companyId, departureDate }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(mapStateToProps, {
    showNotification,
    changeBreadcrumb,
    changeForm,
  })
)

const SaleTicketSegmentLayoutWithPermission = ({ ...props }) => <WithPermissions
  render={({ permissions }) => permissions ? <SaleTicketSegmentLayout permissions={permissions} {...props} /> : null}
/>

export default enhance(SaleTicketSegmentLayoutWithPermission)

