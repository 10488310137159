import { withStyles } from '@material-ui/core'
import React from 'react'
import { TextInput, ReferenceInput, SelectInput } from 'react-admin'
import compose from 'recompose/compose'
import GridFormIterator from '../ui/GridFormIterator'
import PropTypes from 'prop-types'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  }
}

const StopTime = ({ classes, ...props }) => (
  <GridFormIterator className={classes.formIterator} {...props} >
    <TextInput label="resources.stopTimes.time" type="time" source="time" />
    <TextInput label="resources.stopTimes.seq" type="number" source="seq" />
    <TextInput label="resources.stopTimes.legSeq" type="number" source="legSeq" />
    <ReferenceInput label="resources.stopTimes.stop"
      source="stopId" reference="stops" resource="stops">
      <SelectInput optionText="name" />
    </ReferenceInput>
  </GridFormIterator>
)

StopTime.propTypes = {
  classes: PropTypes.object,
}

const enhance = compose(
  withStyles(styles),
)

export default enhance(StopTime)
