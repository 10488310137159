import React, { Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  EmailField,
  FunctionField,
  Create,
  SimpleForm,
  Edit,
  CardActions,
  Filter,
  translate,
  NumberField,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import { validate } from './validate'
import CustomToolbar from '../common/CustomToolbarForm'
import AddSettingButton from './AddSettingButton'
import * as permission from '../utils/permission'
import CompanyGeneralInformation from './CompanyGeneralInformation'
import AddAgencyButton from './AddAgencyButton'
import { withStyles } from '@material-ui/core'
import ChipArrayField from '../common/ChipArrayField'
import SearchInput from '../common/SearchInput'
import PlatformTopUpButton from '../finance_account/PlatformTopUpButton'
import WithdrawButton from '../finance_account/WithdrawButton'
import * as dataType from '../common/data-type'
import compose from 'recompose/compose'
import CommissionChip from '../common/CommissionChip'

const listStyle = {
  chip: {
    margin: 8,
    width: 100,
  },
  textCenter: {
    textAlign: 'center',
  }
}

const CompanyFilter = props => <Filter {...props}>
  <SearchInput 
    helperText="resources.companies.helper_text"
    source="q"
    alwaysOn
  />
</Filter>

////////////////////////////////////////////////////////////////////////////////
// Company
export const _CompanyList = ({ classes, translate, permissions, ...props }) => {
  return <List
    filters={<CompanyFilter />}
    bulkActionButtons={false}
    {...props}
    filterDefaultValues={{
      '../account': true,
      '../fields': [
        'id',
        'name',
        'email',
        'taxNumber',
        'registrationNumber',
        'tel',
        'longName',
        'address',
        'status',
        'accountId',
        'platformDeposit',
        'platformCommission',
      ],
      '../include': [
        {
          relation: 'agencies',
          scope: {
            fields: ['id']
          }
        },
        {
          relation: 'users',
          scope: {
            fields: ['id']
          }
        }
      ]
    }}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EmailField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="email"
      />
      <FunctionField
        sortable={false}
        source="tel"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        render={record => {
          let tel = (record.tel && record.tel !== null) ? record.tel : []
          return <ChipArrayField type={dataType.PHONE} classes={classes} datum={tel} />
        }}
      />
      <TextField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="registrationNumber"
      />
      <TextField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="taxNumber"
      />
      <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        sortable={false}
        source="numberOfAgency"
        render={record => {
          let agencies = record && record.agencies
          let count = 0
          if (agencies) {
            count = agencies.length
          }
          return count
        }}
      />
      <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        sortable={false}
        source="numberOfUser"
        render={record => {
          let users = record && record.users
          let count = 0
          if (users) {
            count = users.length
          }
          return count
        }}
      />
      <NumberField source="platformDeposit" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
    </Datagrid>
  </List>
}

const enhanceCompanyList = compose(withStyles(listStyle), translate)

export const CompanyList = enhanceCompanyList(_CompanyList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const CompanyCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm toolbar={<CustomToolbar />} validate={validate} >
      <CompanyGeneralInformation />
    </SimpleForm>
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit

const CompanyEditActions = ({
  id,
  data: record,
  changeTab,
  changeTabPos,
  changeTabIndexPos,
  isAdmin,
  isCompanyManager,
}) => {
  return <CardActions>
    {(isAdmin && record && record.platformCommissionScript) && <CommissionChip 
      record={record}
      source="platformCommissionScript"
      method="changePlatformCommission"
      resources="companies"
      isPlatformComission
    />}
    {isAdmin && <AddAgencyButton id={id} />}
    {!isAdmin && isCompanyManager && <AddSettingButton
      id={id}
      changeTab={changeTab}
      changeTabPos={changeTabPos}
      changeTabIndexPos={changeTabIndexPos}
    />}
    {(!isAdmin && isCompanyManager && record) && <PlatformTopUpButton 
      button
      color="primary"
      type="COM"
      companyId={record.id}
      companyAccountId={record.accountId}
      platformDeposit={record.platformDeposit}
    />}
    {(!isAdmin && isCompanyManager && record) && <WithdrawButton
      button
      color="primary"
      type="COM"
      fromId={record.id}
      fromAccountDefault={record.accountId}
      fromRefName={record.name}
      platformDeposit={record.platformDeposit}
    />}
  </CardActions>
}

class CompanyFormSave extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record } = nextProps
    let { record: currentRecord } = prevState 
    if ( record !== currentRecord) {
      currentRecord = record
    }
    return {
      ...prevState,
      record: currentRecord,
    }
  }

  render() {
    let {
      tabIndex,
      tabPos,
      tabIndexPos,
      location,
      history,
      match,
      isAdmin,
      isCompanyManager,
      redirect,
      resource,
      changeTab,
      changeTabPos,
      changeTabIndexPos,
      basePath,
      ...props
    } = this.props
    redirect = !isCompanyManager && redirect
    let { record } = this.state
    return <SimpleForm
      toolbar={(isCompanyManager || isAdmin) ? <CustomToolbar /> : null}
      resource={resource}
      basePath={basePath}
      validate={validate}
      redirect={redirect}
      history={history}
      location={location}
      match={match}
      record={record}
      {...props}
    >
      <FormSave
        history={history}
        action="update"
        location={location}
        isAdmin={isAdmin}
        isCompanyManager={isCompanyManager}
        match={match}
        tabIndex={tabIndex}
        changeTab={changeTab}
        tabPos={tabPos}
        changeTabPos={changeTabPos}
        tabIndexPos={tabIndexPos}
        changeTabIndexPos={changeTabIndexPos}
      />
    </SimpleForm>
  }
}

export class CompanyEdit extends Component {

  state = {
    tabIndex: 0,
    tabPos: 0,
    tabIndexPos: 0,
  }

  changeTab = (idx) => {
    this.setState({ tabIndex: idx })
  }

  changeTabPos = (idx) => {
    this.setState({ tabPos: idx, tabIndexPos: 0})
  }

  changeTabIndexPos = (idx) => {
    this.setState({ tabIndexPos: idx})
  }

  render() {
    let { id, location, history, match, permissions, redirect, resource, ...props } = this.props
    let isAdmin = permission.isAdmin(permissions)
    let isCompanyManager = permission.isCompanyManager(permissions)
    let { tabIndex, tabPos, tabIndexPos, } = this.state
    redirect = !isCompanyManager && redirect
    return <Edit
      id={id}
      undoable={false}
      title={<EditTitle
        resource={resource}
      />}
      actions={<CompanyEditActions
        isAdmin={isAdmin}
        isCompanyManager={isCompanyManager}
        id={id}
        changeTab={this.changeTab}
        changeTabPos={this.changeTabPos}
        changeTabIndexPos={this.changeTabIndexPos}
        {...props}
      />}
      history={history}
      location={location}
      match={match}
      resource={resource}
      {...props}
    >
      <CompanyFormSave
        history={history}
        action="update"
        location={location}
        isAdmin={isAdmin}
        isCompanyManager={isCompanyManager}
        match={match}
        tabIndex={tabIndex}
        tabPos={tabPos}
        tabIndexPos={tabIndexPos}
        changeTab={this.changeTab}
        changeTabPos={this.changeTabPos}
        changeTabIndexPos={this.changeTabIndexPos}
      />
    </Edit>
  }
}
