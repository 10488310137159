import React, { Component, Fragment } from 'react'
import { withStyles, Button } from '@material-ui/core'
import {
  TextInput,
  SimpleForm,
  translate,
  required,
  showNotification,
} from 'react-admin'
import compose from 'recompose/compose'
import _ from 'lodash'
import {
  FareMatrix,
  BaseCellFormatter,
  CellEditor,
  LabelCellFormatter,
} from './FareMatrix'
import CustomToolbar from '../common/CustomToolbarForm'
import { Provider } from '../provider'
import SelectInputCustom from '../common/SelectInputCustom'
import Snackbar from '../common/Snackbar'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { change as changeForm, reset as resetForm } from 'redux-form'
import { formatCurrency } from '../utils/formatUtil'
import DialogLoading from '../common/DialogLoading'

const styles = {
  maskInput: {
    width: '100%',
    marginLeft: '1em',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    flexDirection: 'row',
    flex: 1,
  },
  selected: {
    backgroundColor: 'rgb(118, 118, 118)',
    color: 'white',
    marginRight: 8
  },
  btn: {
    marginRight: 8
  }
}

class _FormSave extends Component {
  state = {
    version: new Date().getTime(),
    status: true,
    mode: 'money',
    stops: [],
    stopIds: {},
    fareIndexIds: {},
    canEdit: false,
    loading: false,
  }

  constructor(props) {
    super(props)
    this.tooltip = React.createRef()
  }

  //initForm = () => {
  //let { resetForm } = this.props
  ////resetForm(FARE_COMPANION_FORM)
  //}

  componentWillUnmount() {
    //this.initForm()
    this.setState({})
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  generateFareCompanionDetailsFromRows = (rows) => {
    let mapping = {}
    const data = _.reduce(rows, (result, item) => {
      const { data } = item
      result = [...result, ...data]
      return result
    }, [])

    for (const item of data) {
      const { fareIndex, originId, destinationId } = item
      mapping = { ...mapping, [`${originId}_${destinationId}`]: fareIndex }
    }

    return mapping
  }

  getFareIndexByDistance = (fareIndexs, distance) => {
    if (distance <= 0) return { value: 0, isLock: true }
    //const values = fareIndexs.map(item => item.value)
    return { ...fareIndexs[0], isLock: false, properties: { distance } }
  }

  autoFill = (stops, newFareIndexs) => {
    let { rows, fareIndexs = newFareIndexs } = this.state 
    if (_.isEmpty(fareIndexs)) return {}
    let mapping = this.generateFareCompanionDetailsFromRows(rows)
    for (const origin of stops) {
      for (const destination of stops) {
        const key = [`${origin.id}_${destination.id}`]
        if (!mapping[key]) {
          let distance = ((destination.properties.location - origin.properties.location) / 1000).toFixed(0)
          let fareCompanionDetail = this.getFareIndexByDistance(fareIndexs, distance)
          mapping = {
            ...mapping,
            [key]: fareCompanionDetail,
          }
        }

      }
    }
    return mapping
  }

  getFareCompanionDetails = (stops, fareIndexs) => {
    const mapping = this.autoFill(stops, fareIndexs)
    return mapping
  }

  onStopClick = (stopId) => {
    let { stopIds, stops } = this.state  

    stopIds = { ...stopIds, [stopId]: !stopIds[stopId] }
    stops = stops.filter(item => stopIds[item.id])

    const fareCompanionDetails = this.getFareCompanionDetails(stops)
    const { rows, columns } = this.buildTable(stops, fareCompanionDetails) 
    this.setState({ stopIds, rows, columns })
  }

  onFareIndexClick = (fareIndexId) => {
    let { fareIndexIds, fareIndexs, currentFareIndex } = this.state

    let currentFareIndexId = _.get(currentFareIndex, 'fareIndexId')
    if (currentFareIndexId && currentFareIndexId !== fareIndexId) {
      fareIndexIds = { ...fareIndexIds, [currentFareIndexId]: true }
    }
    fareIndexIds = { ...fareIndexIds, [fareIndexId]: !fareIndexIds[fareIndexId] }
    currentFareIndex = fareIndexs.find(item => item.fareIndexId === fareIndexId)

    let tooltip = this.tooltip.current
    tooltip.style.display = 'block'
    tooltip.style.color = currentFareIndex.color
    if (fareIndexIds[fareIndexId]) {
      tooltip.style.display = 'none'
    }

    //const fareCompanionDetails = this.getFareCompanionDetails(stops)
    //const { rows, columns } = this.buildTable(stops, fareCompanionDetails) 

    this.setState({
      fareIndexIds,
      currentFareIndex: currentFareIndexId !== fareIndexId ? currentFareIndex : undefined
    })
  }

  buildTable = (stops, fareCompanionDetails, newFareIndexs) => {
    let { mode, canEdit, fareIndexs } = this.state 
    if (newFareIndexs) {
      fareIndexs = newFareIndexs
    }
    const columns = _.reduce(stops, (result, item) => {


      if (_.isEmpty(result)) {
        result.push({
          key: 'rowLabel',
          name: '',
          formatter: <LabelCellFormatter
            onClick={this.switchColumnLock}
            setColor={this.setColor}
          />,
        })
      }
      result.push({
        key: item.id,
        name: item.name,
        formatter: <BaseCellFormatter onClick={this.switchColumnLock} />,
        editable: !canEdit,
        editor: <CellEditor options={fareIndexs} />,
      })
      return result
    }, [])
    const rows = []
    for (const origin of stops) {
      const { id: originId, name: originName } = origin
      let fareCompanionDetail = fareCompanionDetails[`${originId}_${originId}`]
      let color
      if (fareCompanionDetail && fareCompanionDetail.properties) {
        color = fareCompanionDetail.properties.color
      }
      let row = { originId }
      const destinations = []
      const data = []
      let view = { rowLabel: originName, color, originId }
      for (const destination of stops) {
        const destinationId = destination.id
        const key = `${originId}_${destinationId}`
        const fareCompanionDetail = fareCompanionDetails[key]
        let fareIndexId = _.get(fareCompanionDetail, 'fareIndexId')
        let fareIndex = _.find(fareIndexs, item => item.fareIndexId === fareIndexId)
        destinations.push(destinationId)
        data.push({
          originId,
          destinationId,
          ...fareCompanionDetail,
          color: _.get(fareIndex, 'color')
        })
        view = {
          ...view,
          [destinationId]: {
            ...fareCompanionDetail,
            color: _.get(fareIndex, 'color'),
          },
          mode
        }
      }
      row = {
        ...row,
        view,
        mode,
        destinations,
        data,
      }
      rows.push(row)
    }
    return { columns, rows }
  }

  getProducts = async (routeId, canEdit) => {
    let res = await Provider.dataProvider('GET_LIST', 'products', {
      filter: {
        where: {
          status: '10ACT'
        },
        ignore: !canEdit && { routeId }
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data.filter(item => item.id)
    }
  }

  getRoutes = async (companyId) => {
    let res = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: {
        where: {
          status: { neq: '20ARCHIVED' }
        },
        isBusTour: true,
        byCompany: companyId,
        fields: [
          'id',
          'name',
          'numberOfProduct',
          'routeGroupId',
          'status',
          'originId',
          'destinationId',
          'distance',
          'companyId',
          'stops'
        ],
        include: {
          relation: 'company',
          scope: {
            fields: ['id', 'name']
          }
        }
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getStops = async (routeId) => {
    let res = await Provider.dataProvider('GET_LIST', 'routes/getRouteWithStop', {
      filter: {
        where: {
          id: routeId
        },
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getFareTables = async (routeId) => {
    let res = await Provider.dataProvider('GET_LIST', 'routefaretables', {
      filter: {
        where: {
          routeId
        },
        include: [{
          relation: 'fareTable'
        }]
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getFareIndices = async (fareTableId) => {
    let res = await Provider.dataProvider('GET_LIST', 'fareindices', {
      filter: {
        where: {
          fareTableId
        },
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      let fareIndexs = res.data
      fareIndexs = fareIndexs.map(item => ({ value: item.value, fareIndexId: item.id, color: item.color }))
      return fareIndexs
    }
  }
  
  onChangeFareTable = async (evt, fareTableId) => {
    if (fareTableId) {
      const { stops } = this.state
      const fareIndexs = await this.getFareIndices(fareTableId)
      const fareIndexIds = _.reduce(fareIndexs, (fareIndexIds, item = {}) => fareIndexIds = { ...fareIndexIds, [item.fareIndexId]: true }, {})
      const fareCompanionDetails = this.getFareCompanionDetails(stops, fareIndexs)
      const { rows, columns } = this.buildTable(stops, fareCompanionDetails, fareIndexs) 
      this.setState({ rows, columns, fareIndexs, fareIndexIds })
    } else {
      console.log('This route does not stops')
    }
  }

  onChangRoute = async (evt, routeId) => {
    const { routes } = this.state
    const route = routes.find(item => item.id === Number(routeId))
    if (route) {
      const stops = await this.getStops(routeId)

      let fareTables = await this.getFareTables(routeId)
      fareTables = fareTables.map(item => item.fareTable)

      const products = await this.getProducts(routeId)
      const stopIds = _.reduce(stops, (result, item) => {
        const { id } = item
        result = { ...result, [id]: true }
        return result
      }, {})

      this.setState({ products, routeId, stops, stopIds, fareTables })
    } else {
      console.log('This route does not stops')
    }
  }

  getStopsByRecord = (details, allStops) => {
    let stops = []
    let fareCompanionDetailsMapping = {}
    const stopIds = _.reduce(details, (result, item) => {
      const { originId, fareIndex = {}, status, destinationId, id, properties } = item
      fareCompanionDetailsMapping = {
        ...fareCompanionDetailsMapping,
        [`${originId}_${destinationId}`]: {
          id,
          value: fareIndex.value || 0,
          fareIndexId: fareIndex.id,
          isLock: status === '00IAT',
          properties
        }}
      result = { ...result, [originId]: true }
      return result
    }, {})

    for (const stop of allStops) {
      if (stopIds[stop.id]) {
        stops.push(stop)
      }
    }
    return { fareCompanionDetails: fareCompanionDetailsMapping, stops, stopIds }
  }

  setColor = (color, originId) => {
    const { rows: stateRows } = this.state
    const rows = _.cloneDeep(stateRows)
    const idx = rows.findIndex(item => item.originId === originId)
    let row = rows[idx]
    let { data: rowData, view: rowView = {}, destinations } = row
    rowData = rowData.map(item => {
      let { properties } = item
      properties = { ...properties, color }
      return { ...item, properties }
    })
    for (const destination of destinations) {
      const item = rowView[destination]
      if (item) {
        let { properties = {} } = item
        properties = { ...properties, color }
        rowView[destination] = { ...item, properties }
      }
    }
    row = { ...row, data: rowData, view: rowView }
    rows[idx] = row
    this.setState({ rows: [...rows] })
  }

  switchColumnLock = (rowIdx, columnIdx) => {
    const { rows: stateRows } = this.state
    const rows = _.cloneDeep(stateRows)
    let row = rows[rowIdx]
    let { data: rowData, view: rowView = {} } = row
    let column = rowData[columnIdx - 1]

    const { destinationId } = column
    let columnView = rowView[destinationId] 

    columnView = { ...columnView, isLock: !columnView.isLock }
    column = { ...column, isLock: !column.isLock }

    rowView = { ...rowView, [destinationId]: columnView }
    rowData[columnIdx-1] = column

    row = { ...row, data: rowData, view: rowView }
    rows[rowIdx] = row

    this.setState({ rows: [...rows] })
  } 

  init = async () => {
    const { record, changeForm } = this.props
    let products, fareTables, fareIndexs, allStops, stopIds, rows, columns, fareIndexIds
    this.startLoading()
    if (!_.isEmpty(record)) {
      const { details, routeProduct = {}, fareTableId, id } = record
      const { routeId, productId } = routeProduct
      const canEdit = Boolean(id)
      if (fareTableId) {
        fareIndexs = await this.getFareIndices(fareTableId)
        fareIndexIds = _.reduce(fareIndexs, (fareIndexIds, item = {}) => fareIndexIds = { ...fareIndexIds, [item.fareIndexId]: true }, {})
      }
      if (routeId) {
        products = await this.getProducts(routeId, canEdit)
        fareTables = await this.getFareTables(routeId)
        fareTables = fareTables.map(item => item.fareTable)
        allStops = await this.getStops(routeId)
        const result = this.getStopsByRecord(details, allStops)
        const fareCompanionDetails = result.fareCompanionDetails
        stopIds = result.stopIds
        const stops = result.stops
        const table = this.buildTable(stops, fareCompanionDetails, fareIndexs)
        rows = table.rows
        columns = table.columns
      }
      changeForm('record-form', 'id', id)
      changeForm('record-form', 'routeId', routeId)
      changeForm('record-form', 'productId', productId)
      changeForm('record-form', 'fareTableId', fareTableId)

      this.setState({
        rows,
        columns,
        routeId,
        products,
        canEdit,
        fareTables,
        fareIndexs,
        stops: allStops,
        stopIds,
        fareIndexIds,
      })
    }
    this.endLoading()
  }

  async componentDidMount() {
    const routes = await this.getRoutes()
    await this.init()
    let tooltip = this.tooltip.current
    window.onmousemove = function (e) {
      var x = e.clientX, y = e.clientY
      tooltip.style.top = (y + 10) + 'px'
      tooltip.style.left = (x + 10) + 'px'
    }
    this.setState({ routes })
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { record } = this.props
    let { record: prevRecord } = prevProps
    const updated = !(_.isEqual(prevRecord, record))
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      await this.init()
    }
  }

  save = async (values) => {
    this.startLoading()
    const { showNotification, push, } = this.props
    let { rows, routeId, name, desc, productId, fareTableId, id } = values
    const data = _.reduce(rows, (result, item) => {
      const { data } = item
      result = [...result, ...data]
      return result
    }, [])

    const body = {
      id,
      name,
      desc,
      routeId,
      data,
      productId,
      fareTableId
    }

    const res = await Provider.dataProvider('REMOTE', 'farecompanions', {
      method: 'saveFareCompanion',
      requestMethod: 'POST',
      data: body 
    })
    this.endLoading()
    if (res) {
      showNotification('notification.create_fare_companion_success')
      push('/farecompanions')
    } else {
      showNotification('notification.create_fare_companion_failure', 'warning')
    }
  }

  setRows = (newRows) => {
    this.setState({ rows: [...newRows]})
  }

  setFareCell= (rowIdx, columnIdx) => {
    const { rows: stateRows, currentFareIndex } = this.state
    if (!currentFareIndex) return
    const rows = _.cloneDeep(stateRows)
    let row = rows[rowIdx]
    let { data: rowData, view: rowView = {} } = row
    let column = rowData[columnIdx - 1]

    const { destinationId } = column
    let columnView = rowView[destinationId]

    columnView = { ...columnView, ...currentFareIndex, }
    column = { ...column, ...currentFareIndex, }

    rowView = { ...rowView, [destinationId]: columnView }
    rowData[columnIdx-1] = column

    row = { ...row, data: rowData, view: rowView }
    rows[rowIdx] = row

    this.setState({ rows: [...rows] })
  }

  render() {
    let { classes, resource, translate, record, redirect } = this.props
    let {
      columns,
      routes,
      rows,
      mode,
      products,
      routeId,
      stops,
      stopIds,
      fareTables,
      fareIndexs,
      fareIndexIds,
      currentFareIndex,
      loading,
    } = this.state
    let extra = { resource, fullWidth: true }
    return (
      <Fragment>
        <SimpleForm
          toolbar={<CustomToolbar />}
          {...this.props}
          basePath="/farecompanions"
          submitOnEnter={false}
          //form={FARE_COMPANION_FORM}
          save={this.save}
          style={{position: 'relative'}}
        >
          <TextInput source="name" {...extra} />
          <TextInput source="desc" {...extra} />
          <SelectInputCustom
            label="resources.farecompanions.fields.routeId"
            choices={routes}
            onChange={this.onChangRoute}
            source="routeId"
            allowEmpty
            validate={required()}
            disabled={record.id}
            {...extra}
          />
          <span
            style={{ display: 'none', position: 'fixed', overflow: 'hidden', zIndex: 9999}}
            ref={this.tooltip}>
            {_.get(currentFareIndex, 'value', '')}
          </span>
          {routeId ? (!_.isEmpty(products) ? <Fragment>
            {stops && stops.map(item => {
              return <Button
                key={item.fareIndexId}
                className={stopIds[item.id] ? classes.selected : classes.btn}
                onClick={() => this.onStopClick(item.id)}
              >
                {item.name}
              </Button>
            })}
            <SelectInputCustom
              label="resources.farecompanions.fields.fareTableId"
              choices={fareTables}
              source="fareTableId"
              validate={required()}
              disabled={record.id}
              onChange={this.onChangeFareTable}
              allowEmpty
              {...extra}
            />
            {fareIndexs && fareIndexs.map(item => {
              let selected = {
                color: item.color || 'black',
                marginRight: 8
              }
              let btn = {
                color: 'white',
                backgroundColor: item.color || 'rgb(118, 118, 118)',
                marginRight: 8
              }
              return <Button
                key={item.fareIndexId}
                style={fareIndexIds[item.fareIndexId] ? btn : selected}
                onClick={() => this.onFareIndexClick(item.fareIndexId)}
              >
                {formatCurrency(item.value, 'vi', '')}
              </Button>
            })}
            <SelectInputCustom
              label="resources.farecompanions.fields.productId"
              choices={products}
              source="productId"
              disabled={record.id}
              validate={required()}
              allowEmpty
              {...extra}
            />
            {(columns && rows) && <FareMatrix
              source="rows"
              columns={columns}
              rows={rows}
              setRows={this.setRows}
              setFareCell={this.setFareCell}
            />}
          </Fragment> : <Snackbar
            variant="warning"
            message={translate('resources.farecompanions.no_valid_product')}
          />) : null}
        </SimpleForm>
        {loading && <DialogLoading open={loading}/> }
      </Fragment>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { changeForm, resetForm, showNotification, push, })
)

export default enhance(_FormSave)
