import { put, race, take, takeEvery } from 'redux-saga/effects'
import {
  RESERVATION_SEARCH_TRIP,
  RESERVATION_SEARCH_TRIP_SUCCESS,
  RESERVATION_SEARCH_TRIP_FAILURE,

  RESERVATION_SELECT_TRIP,
  RESERVATION_SELECT_SEAT_MAP,
  RESERVATION_RESERVED_SEAT,
  RESERVATION_UPDATE_TOTAL_CHARGE_FARE,

  RESERVATION_GET_TRIP_DETAIL,
  RESERVATION_GET_TRIP_DETAIL_SUCCESS,
  RESERVATION_GET_TRIP_DETAIL_FAILURE,

  RESERVATION_GET_SEAT_MAP,
  RESERVATION_GET_SEAT_MAP_SUCCESS,
  RESERVATION_GET_SEAT_MAP_FAILURE,

  RESERVATION_GET_RESERVED_SEAT,
  RESERVATION_GET_RESERVED_SEAT_SUCCESS,
  RESERVATION_GET_RESERVED_SEAT_FAILURE,

  RESERVATION_CALCULATE_CHARGE_FARE,
  RESERVATION_CALCULATE_CHARGE_FARE_SUCCESS,
  RESERVATION_CALCULATE_CHARGE_FARE_FAILURE,

  RESERVATION_MAKE_RESERVATION,
  RESERVATION_MAKE_RESERVATION_SUCCESS,
  RESERVATION_MAKE_RESERVATION_FAILURE,

  RESERVATION_GET_DETAIL,
  RESERVATION_GET_DETAIL_SUCCESS,
  RESERVATION_GET_DETAIL_FAILURE,

  doFetchAction,
  RESERVATION_MAKE_QUOTATION,
  RESERVATION_MAKE_QUOTATION_SUCCESS,
  RESERVATION_MAKE_QUOTATION_FAILURE,
} from './actions'

// ------------------------------------------------------------------------------------------
function* searchTrip(action) {
  let { start, done, error } = action
  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_SEARCH_TRIP_SUCCESS),
    failure: take(RESERVATION_SEARCH_TRIP_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      done && done(payload.data)
    } else {
      error && error()
    }
  }

  if (failure) {
    error && error(failure.error)
  }
}

export function* requestSearchTrip() {
  yield takeEvery(RESERVATION_SEARCH_TRIP, searchTrip)
}

// ------------------------------------------------------------------------------------------
function* getTripDetail(action) {
  let { transferData, start, done, error } = action
  let { roundType } = transferData
  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_GET_TRIP_DETAIL_SUCCESS),
    failure: take(RESERVATION_GET_TRIP_DETAIL_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      let trip = payload.data
      done && done(trip)
      yield put({ type: RESERVATION_SELECT_TRIP, payload: { trip, roundType } })

      yield put(
        doFetchAction(
          RESERVATION_GET_SEAT_MAP,
          `${trip.vehicleType}/seatMapObj`,
          'GET',
          null,
          'vehicletypes',
          { roundType }))

      yield put(
        doFetchAction(
          RESERVATION_GET_RESERVED_SEAT,
          `${trip.tripId}/reservedSeats`,
          'GET',
          null,
          'trips',
          { roundType }))

    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestGetTripDetail() {
  yield takeEvery(RESERVATION_GET_TRIP_DETAIL, getTripDetail)
}

// ------------------------------------------------------------------------------------------
function* getSeatMap(action) {
  let { transferData, start, done, error } = action
  let { roundType } = transferData

  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_GET_SEAT_MAP_SUCCESS),
    failure: take(RESERVATION_GET_SEAT_MAP_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      let seatMap = payload.data
      done && done(seatMap)
      yield put({ type: RESERVATION_SELECT_SEAT_MAP, payload: { roundType, seats: seatMap } })
    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestGetSeatMap() {
  yield takeEvery(RESERVATION_GET_SEAT_MAP, getSeatMap)
}


// ------------------------------------------------------------------------------------------
function* getReservedSeat(action) {
  let { transferData, start, done, error } = action
  let { roundType } = transferData

  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_GET_RESERVED_SEAT_SUCCESS),
    failure: take(RESERVATION_GET_RESERVED_SEAT_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      let data = payload.data
      done && done(data)
      let { reservedSeats } = data
      yield put({ type: RESERVATION_RESERVED_SEAT, payload: { roundType, reservedSeats } })

    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestGetReservedSeat() {
  yield takeEvery(RESERVATION_GET_RESERVED_SEAT, getReservedSeat)
}

// ------------------------------------------------------------------------------------------
function* calculateChargesFare(action) {
  let { start, done, error } = action
  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_CALCULATE_CHARGE_FARE_SUCCESS),
    failure: take(RESERVATION_CALCULATE_CHARGE_FARE_FAILURE),
  })

  if (success) {
    const { payload } = success
    if (payload.data) {
      let data = payload.data
      done && done(data)
      let { totalFare, totalDiscount, totalAmount } = data
      yield put({ type: RESERVATION_UPDATE_TOTAL_CHARGE_FARE, payload: { totalFare, totalDiscount, totalAmount } })

    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestCalculateChargesFare() {
  yield takeEvery(RESERVATION_CALCULATE_CHARGE_FARE, calculateChargesFare)
}

// ------------------------------------------------------------------------------------------
function* makeReservation(action) {
  let { start, done, error } = action
  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_MAKE_RESERVATION_SUCCESS),
    failure: take(RESERVATION_MAKE_RESERVATION_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      let data = payload.data
      done && done(data)
    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestMakeReservation() {
  yield takeEvery(RESERVATION_MAKE_RESERVATION, makeReservation)
}


// ------------------------------------------------------------------------------------------
function* makeQuotation(action) {
  let { start, done, error } = action
  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_MAKE_QUOTATION_SUCCESS),
    failure: take(RESERVATION_MAKE_QUOTATION_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      let data = payload.data
      done && done(data)
    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestMakeQuotation() {
  yield takeEvery(RESERVATION_MAKE_QUOTATION, makeQuotation)
}

// ------------------------------------------------------------------------------------------
function* getReservationDetail(action) {
  let { start, done, error } = action
  start && start()

  const { success, failure } = yield race({
    success: take(RESERVATION_GET_DETAIL_SUCCESS),
    failure: take(RESERVATION_GET_DETAIL_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      let data = payload.data
      done && done(data)
    } else {
      error && error()
    }
  }

  if (failure) {
    error && error()
  }
}

export function* requestGetReservationDetail() {
  yield takeEvery(RESERVATION_GET_DETAIL, getReservationDetail)
}
