import React from 'react'
import { Button } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const IconButton = ({ icon, children, ...rest }) => (
  <Button {...rest}>
    <FontAwesomeIcon style={{ margin: 5 }} icon={icon} />
    {children}
  </Button>
)

export default IconButton