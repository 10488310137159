import React, { Component } from 'react'
import {
  Button,
  IconButton,
} from '@material-ui/core'
import { Link } from 'react-router-dom'

class LinkField extends Component {

  render() {
    let {
      classes,
      path,
      children,
      state,
      icon = false,
      submitOnEnter,
      saving,
      pristine,
      invalid,
      handleSubmitWithRedirect,
      handleSubmit,
      basePath,
      search,
      color = 'primary',
      styleColor,
      ...rest
    } = this.props
    let ButtonComponent = icon ? IconButton : Button
    return <ButtonComponent
      size="medium"
      color={color}
      style={{ color: styleColor }}
      component={Link}
      to={{
        pathname: path,
        search,
        state
      }}
      className={classes && classes.link}
      {...rest}
    >
      {children}
    </ButtonComponent>
  }
}

export default LinkField
