import { SET_WIDTH_APP_BAR } from './action'

export const appBar = (state = {}, action) => {
  let { type, width } = action
  switch(type) {
    case SET_WIDTH_APP_BAR: {
      return {
        ...state,
        width: width,
      }
    }
    default: return {...state}
  }
}
