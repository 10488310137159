import React, { Component } from 'react'
import {
  withStyles,
  Modal,
  Button
} from '@material-ui/core'
import {
  translate,
  SimpleForm,
  Toolbar as RAToolbar,
  SaveButton,
} from 'react-admin'
import compose from 'recompose/compose'
import PaymentStep from './PaymentStep'

function getModalStyle() {
  const top = 50
  const left = 50

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  }
}

const styles = (theme) => {
  return ({
    paper: {
      position: 'absolute',
      width: 750,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 3,
      outline: 'none',
    },
  })
}

const toolBarStyle = {
  closeButton: {
    marginLeft: 16
  }
}

class _Toolbar extends Component {

  handleClick = () => {
    const { handleSubmit, onOk } = this.props
    return handleSubmit(data => {
      onOk(data)
    })
  }

  render() {
    let { onClose, translate, isBlock, classes } = this.props
    return <RAToolbar>
      <SaveButton
        variant="contained"
        label="button.paid"
        submitOnEnter={true}
        handleSubmitWithRedirect={this.handleClick}
        color="primary"
        disabled={isBlock}
      />
      <Button
        variant="flat"
        onClick={onClose && onClose}
        className={classes.closeButton}
      >
        {translate('button.close')}
      </Button>
    </RAToolbar>
  }
}

const enhanceToolbar = compose(withStyles(toolBarStyle), translate)

const Toolbar = enhanceToolbar(_Toolbar)

class PaymentModal extends Component {

  render() {
    let { 
      classes,
      open,
      onClose,
      onOk,
      record,
      totalAmount,
      isPayLater,
      isBlock,
    } = this.props
    return (
      <Modal
        open={open}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        onClose={onClose}
      >
        <div style={getModalStyle()} className={classes.paper}>
          <SimpleForm
            record={record}
            resource="transactions"
            toolbar={<Toolbar onOk={onOk} onClose={onClose} isBlock={isBlock} />}
            validate={(record, { translate }) => {
              let { paid } = record
              let errors = {}
              paid = parseFloat(paid) || 0
              if (!paid || paid === 0) {
                errors['paid'] = translate('error_messages.invalid.payment.paid_must_be_more_than_zero')
              }
              return errors
            }}
          >
            <PaymentStep totalAmount={totalAmount} isPayLater={isPayLater} />
          </SimpleForm>
        </div>
      </Modal>
    )
  }
}

const enhance = compose( withStyles(styles), translate)
export default enhance(PaymentModal)
