import compose from 'recompose/compose'
import { connect } from 'react-redux'

import L from 'leaflet'
import { MapLayer, withLeaflet} from 'react-leaflet'
import throttle from 'lodash/throttle'
import { MapProps } from '../common/constants'
import { Provider } from '../provider'
import { updateRoute } from './actions'

const baseURL = 'http://rocket.nexpando.com:11111/'
const routeURL =`${baseURL}route?points_encoded=false&vehicle=car&`
const defaultColor = '#ea001b'

const defaultPlolyineStyle = {
  weight: 9,
  opacity: 1,
  color: defaultColor
}
const defaultGuideStyle = {
  weight: 9,
  opacity: 0.5,
  dashArray: '5 10'
}

const getRoute = async ({ from, to, via }) => {
  let url = getURL({ from, to, via })
  let resp = await get(url)
  const data = await resp.json()
  if (data && data.paths && data.paths.length > 0) {
    const path = data.paths[0]
    if (path.points && path.points.coordinates) {
      return coordinatesToLatLngs(path.points.coordinates)
    }
  }
  return []
}

function coordinatesToLatLngs(coordinates) {
  let latlngs = coordinates.map(v => {
    return L.latLng(v[1], v[0])
  })
  return latlngs
}

function latlngsToLineString(latlngs) {
  let linestring = {
    type: 'LineString',
    coordinates: []
  }
  let len = latlngs.length
  for (let idx = 0; idx < len; idx++) {
    let latlng = latlngs[idx]
    linestring.coordinates.push([latlng.lng, latlng.lat])
  }
  return linestring
}

const getURL = ({ from, to, via }) => {
  let points = [encodeURI(`point=${from.join(',')}`)]
  if (via) {
    points.push(encodeURI(`point=${via.join(',')}`))
  }
  points.push(encodeURI(`point=${to.join(',')}`))
  let url = `${routeURL}${points.join('&')}`
  return url
}

const get = async (url) => {
  return await fetch(url)
}

/*
  segemnt = { from: latlng, to: latlng, line: {} }
  list = { head: {}, tail: {} }
*/
/*
function prepend(list, value) {
  list.head = value
  
  if (!list.tail) {
    list.tail = list.head
  }

  return list
}
*/

function append(list, value) {
  if (!list.head) {
    list.head = value
    list.tail = value
    return list
  }

  list.tail.next = value
  list.tail = value
  return list
}

function removeTail(list) {
  const tail = list.tail
  if (list.head === list.tail) {
    list.head = null
    list.tail = null
    return tail
  }
  let curr = list.head
  while (curr.next) {
    if (!curr.next.next) {
      curr.next = null
    } else {
      curr = curr.next
    }
  }
  list.tail = curr
  return tail
}

//function removeHead(list) {
//  if (!list.head) {
//    return null
//  }

//  const head = list.head
//  if (head.next) {
//    list.head = list.head.next
//  } else {
//    list.head = null
//    list.tail = null
//  }
//  return head
//}

//function toarray(list) {
//  const array = []
//  let curr = list.head

//  while (curr) {
//    array.push(curr)
//    curr = curr.next
//  }
//  return array
//}

function iterate(list, fnc) {
  let curr = list.head
  while (curr) {
    if (fnc) {
      fnc(curr)
    }
    curr = curr.next
  }
}

const safeRemoveLayer = (leafletMap, el) => {
  const { overlayPane } = leafletMap.getPanes()
  if (overlayPane && overlayPane.contains(el)) {
    overlayPane.removeChild(el)
  }
}

function getMouseEvtLatLng(map, evt) {
  let xy = map.mouseEventToLayerPoint(evt.originalEvent)
  let latlng = map.layerPointToLatLng(xy)
  return latlng
}

function contains(map, routeId) {
  let retval = null
  map.eachLayer(v => {
    if (v.routeId && v.routeId === routeId) {
      retval = v
    }
  })
  return retval
}

//count num of layer which has route id property
function getNumOfLayers(map) {
  let count = 0
  map.eachLayer(v => {
    if (v.routeId) {
      count++
    }
  })
  return count
}

function getBounds(lbounds, rbounds) {
  const maxLon = Math.max(lbounds.getNorthEast().lng, rbounds.getNorthEast().lng)
  const maxLat = Math.max(lbounds.getNorthEast().lat, rbounds.getNorthEast().lat)
  
  const minLon = Math.min(lbounds.getSouthWest().lng, rbounds.getSouthWest().lng)
  const minLat = Math.min(lbounds.getSouthWest().lat, rbounds.getSouthWest().lat)

  const ne = { lng: maxLon, lat: maxLat }
  const sw = { lng: minLon, lat: minLat }
  return L.latLngBounds(L.latLng(sw), L.latLng(ne))
}

//accumulate distance
function distance(from, to, d) {
  return from.distanceTo(to) + (d || 0)
}

function clear(model) {
  model.segments = { head: null, tail: null }
  model.lines = { head: null, tail: null }
  model.distances = { head: null, tail: null }
  if (model.poly) {
    model.poly.setLatLngs([])
  }
  if (model.polyGuide) {
    model.polyGuide.setLatLngs([])
  }
}

function goBack(model) {
  let tail = removeTail(model.segments)
  removeTail(model.lines)
  removeTail(model.distances)
  let latlngs = getLatLngs(model)
  if (model.poly) {
    model.poly.setLatLngs(latlngs)
  }
  if (model.polyGuide) {
    model.polyGuide.setLatLngs([])
  }
  return tail
}

function getLatLngs(model) {
  let latlngs = []
  iterate(model.lines, (v) => {
    let len = v.length
    for (let idx = 0; idx < len; idx++) {
      latlngs.push(v[idx])
    }
  })
  return latlngs
}

//function getDistance(model) {
//  let d = 0
//  iterate(model.distances, (v) => {
//    let len = v.length
//    for (let idx = 0; idx < len; idx++) {
//      d += v[idx]
//    }
//  })
//  return d
//}

function updateSegment(model, latlng) {
  append(model.segments, latlng)
}

function updateLine(model, latlngs) {
  if (latlngs.length > 1) {
    append(model.lines, latlngs.slice(1))
  } else {
    append(model.lines, latlngs)
  }
}

function updateDistance(model, latlngs) {
  let len = latlngs.length
  let d = 0
  for (let idx = 1; idx < len; idx++) {
    d = distance(latlngs[idx - 1], latlngs[idx], d)
  }
  append(model.distances, { value: d })
}

function updateMap(model, latlngs, map) {
  if (!model.poly) {
    let style = defaultPlolyineStyle
    if (model.route && model.route.color) {
      style.color = model.route.color
    }
    model.poly = L.polyline([], style).addTo(map)
  }
  let len = latlngs.length
  for (let idx = 0; idx < len; idx++) {
    let latlng = latlngs[idx]
    model.poly.addLatLng(latlng)
  }
}

// update map location after new point added
function updateMapLocation(map, prev, curr) {
  if (prev && curr) {
    let p1 = map.latLngToLayerPoint(prev)
    let p2 = map.latLngToLayerPoint(curr)
    let offset = { x: p2.x - p1.x, y: p2.y - p1.y }
    map.panBy(offset)
  }
}

function calcDistance(model) {
  let d = 0
  iterate(model.distances, v => {
    d += v.value
  })
  return d
}

//update map upon visual guide
function update(model, latlng, map) {
  //if insert the start point
  if (!model.segments.head) {
    updateSegment(model, latlng)
    updateMap(model, [], map)
    return
  }
  //otherwise insert points from visual guide
  if (model.polyGuide) {
    let latlngs = model.polyGuide.getLatLngs()
    let len = latlngs.length
    if (len === 0) { return }
    let last = latlngs[len - 1]
    // update last point
    updateSegment(model, last)
    // update line
    updateLine(model, latlngs)
    // update distance
    updateDistance(model, latlngs)
    updateMap(model, latlngs, map)
  } else {
    updateMap(model, [], map)
  }
}

function save(model, route) {
  let latlngs = []
  if (model.poly) {
    latlngs = model.poly.getLatLngs()
  }
  let linestring = latlngsToLineString(latlngs)
  route.path = linestring

  let d = calcDistance(model)
  model.route.distance = Math.floor(d)
  Provider.dataProvider('UPDATE', 'routes', { id: route.id, data: route }).then(() => {
    // console.log('Route saved')
  }).catch((e) => {
    console.error(e)
  })
}

class RouteLayer extends MapLayer {
  constructor(props) {
    super(props)
    
    this.model = {
      route: null,
      segments: { head: null, tail: null },
      lines: { head: null, tail: null },
      distances: { head: null, tail: null },
      poly: null,
      polyGuide: null
    }

    this.routeLayers = {}
    this.onMouseMoveThrottled = throttle(this.onMouseMove, 500)
    this.state = {
      drawing: false
    }
  }

  componentDidMount() {
    const map = this.props.leaflet.map
    const mapSize = map.getSize()
    this._el = L.DomUtil.create('div', 'leaflet-route-container')

    this._el.style.position = 'absolute'
    this._el.style.width = mapSize.x + 'px'
    this._el.style.height = mapSize.y + 'px'    
    //const bounds = map.getBounds()
    //const bbox = [
    //  [bounds.getWest(), bounds.getSouth()],
    //  [bounds.getEast(), bounds.getNorth()]
    //]

    const el = this._el
    const Layer = L.Layer.extend({
      onAdd: (leafletMap) => {
        leafletMap.getPanes().overlayPane.appendChild(el)
      },
      addTo: (leafletMap) => {
        leafletMap.addLayer(this)
        return this
      },
      onRemove: (leafletMap) => {
        safeRemoveLayer(leafletMap, el)
      }
    })

    this.leafletElement = new Layer()

    this.mouseMarker = L.marker(map.getCenter(), {
      icon: L.divIcon({
        className: 'leaflet-mouse-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40]
      }),
      opacity: 0,
      zIndexOffset: 2000
    })
    this.mouseMarker.addTo(map)

    this.startMarker = L.marker(map.getCenter(), {
      icon: L.divIcon({
        className: 'start-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
        html: 'Start'
      }),
      opacity: 0,
      zIndexOffset: 2000
    })

    this.endMarker = L.marker(map.getCenter(), {
      icon: L.divIcon({
        className: 'stop-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
        html: 'End'
      }),
      opacity: 0,
      zIndexOffset: 5000
    })

    this.startMarker.addTo(map)
    this.endMarker.addTo(map)

    super.componentDidMount()
    this.attachEvents()
    this.fitBounds()
  }

  componentWillReceiveProps() {}

  componentWillUnmount() {
    safeRemoveLayer(this.props.leaflet.map, this._el)
  }

  componentDidUpdate() {
    this.reset()
  }

  shouldComponentUpdate() {
    return true
  }

  fitBounds() {
    const map = this.props.leaflet.map
    let bounds = null
    if (this.model.poly) {
      bounds = this.model.poly.getBounds()
      if (bounds && bounds.isValid()) {
        map.fitBounds(bounds)
      }
      return
    }
    map.eachLayer(v => {
      if (v.routeId) {
        if (!bounds) {
          bounds = v.getBounds()
        } else {
          bounds = getBounds(bounds, v.getBounds())
        }
      }
    })
    if (bounds) {
      map.fitBounds(bounds)
    }
  }

  reset() {
    const map = this.props.leaflet.map
    const bounds = map.getBounds()
    const topLeft = map.latLngToLayerPoint(bounds.getNorthWest())
    L.DomUtil.setPosition(this._el, topLeft)
  }

  async updateGuide(latlng) {
    const map = this.props.leaflet.map
    const tail = this.model.segments.tail
    if (tail) {
      let from = [ tail.lat, tail.lng ]
      let to = [ latlng.lat, latlng.lng ]
      let latlngs = await getRoute({ from, to })

      if (!this.model.polyGuide) {
        let style = defaultGuideStyle
        if (this.model.route && this.model.route.color) {
          style.color = this.model.route.color
        }
        this.model.polyGuide = L.polyline([], style).addTo(map)
      }
      this.model.polyGuide.setLatLngs(latlngs)
    }
  }

  clearGuide() {
    if (this.model.polyGuide) {
      this.model.polyGuide.setLatLngs([])
    }
  }

  erase() {
    clear(this.model)
    this.save()
    this.updateStartEndLocation()
  }

  back() {
    const map = this.props.leaflet.map
    let prev = goBack(this.model)
    this.save()
    updateMapLocation(map, prev, this.model.segments.tail)
    this.updateStartEndLocation()
  }

  save() {
    let { route, updateRoute } = this.props
    save(this.model, route)
    updateRoute(route)
  }

  updateStartEndLocation() {
    const map = this.props.leaflet.map
    if (this.model.segments.head) {
      this.startMarker.setLatLng(this.model.segments.head)
      this.startMarker.setOpacity(1)
    } else {
      this.startMarker.setOpacity(0)
    }
    if (this.model.segments.tail) {
      let latlng = this.model.segments.tail
      let point = map.latLngToLayerPoint(latlng)
      point.x = point.x + 15
      point.y = point.y + 15
      latlng = map.layerPointToLatLng(point)
      this.endMarker.setLatLng(latlng)
      this.endMarker.setOpacity(1)
    } else {
      this.endMarker.setOpacity(0)
    }
  }

  onZoomEnd(evt) {
  }

  onMoveStart(evt) {
    const map = this.props.leaflet.map
    map.off('mousemove', this.onMouseMoveThrottled)
  }

  onMoveEnd(evt) {
    const map = this.props.leaflet.map
    map.on('mousemove', this.onMouseMoveThrottled)
    this.updateStartEndLocation()
  }

  onResize(evt) {
  }

  onDrag(evt) {
  }

  onMouseMove(evt) {
    if (!this.state || !this.state.drawing) {
      return
    }
    const map = this.props.leaflet.map
    let latlng = getMouseEvtLatLng(map, evt)
    this.updateGuide(latlng)
    // Update the mouse marker position
    this.mouseMarker.setLatLng(latlng)
  }

  onMouseOut(evt) {
  }

  onMouseDown(evt) {
  }

  onMouseUp(evt) {
    if (!this.state.drawing) {
      return
    }
    const map = this.props.leaflet.map
    let latlng = getMouseEvtLatLng(map, evt)
    this.insert(latlng)
  }

  insert(latlng) {
    if (!this.state.drawing) {
      this.startDrawing()
      return
    }
    const map = this.props.leaflet.map
    let prev =this.model.segments.tail
    // update model
    update(this.model, latlng, map)
    // persit model
    this.save()
    updateMapLocation(map, prev, this.model.segments.tail)
    this.updateStartEndLocation()
  }

  onKeyPress(evt) {
    const map = this.props.leaflet.map
    const origin = evt.originalEvent
    if (origin.defaultPrevented) {
      return
    }
    const key = origin.key
    // const keyCode = origin.keyCode
    switch(key) {
      case 'i': //insert
        this.insert()
        break
      case 'b': //back
        this.back()
        break
      case 'd': //erase
        this.erase()  
        break
      case 'a': //jump to start location
        this.panTo(this.model.segments.head)
        break
      case 'e': //jump to end location
        this.panTo(this.model.segments.tail)
        break
      case 'f': //fit bounds
        this.fitBounds()
        break
      case 'p': //pan
        break
      case '-': //zoom out
        map.zoomOut()
        break
      case '+': //zoom in
      case '=':
        map.zoomIn()
        break
      case 's': //save
        this.finishDrawing()
        break
      case 'Esc':
      case 'Escape':
      case 'q': //quit
        this.finishDrawing()
        break
      default:
        break
    }
  }

  panTo(latlng) {
    const map = this.props.leaflet.map
    if (latlng) {
      map.panTo(latlng)
    }
  }

  startDrawing() {
    this.setState({ drawing: true })
  }

  finishDrawing() {
    this.setState({ drawing: false })
    this.save()
    this.clearGuide()
  }

  attachEvents() {
    const map = this.props.leaflet.map

    map.on('mousemove', (evt) => this.onMouseMoveThrottled(evt))
    map.on('zoomlevelschange', (evt) => this.onZoomEnd(evt))
    map.on('zoomend', (evt) => this.onZoomEnd(evt))
    map.on('movestart', (evt) => this.onMoveStart(evt))
    map.on('moveend', (evt) => this.onMoveEnd(evt))
    map.on('resize', (evt) => this.onResize(evt))
    map.on('drag', (evt) => this.onDrag(evt))
    map.on('keypress', (evt) => this.onKeyPress(evt))

    this.mouseMarker.on('mousemove', (evt) => this.onMouseMoveThrottled(evt))
    this.mouseMarker.on('mouseout', (evt) => this.onMouseOut(evt))
    this.mouseMarker.on('mousedown', (evt) => this.onMouseDown(evt))
    this.mouseMarker.on('mouseup', (evt) => this.onMouseUp(evt))

    let self = this
    this.endMarker.on('mouseover', (evt) => {
      let elem = self.endMarker.getElement()
      if (!self.state.drawing) {
        elem.innerHTML = '+'
      }
    })
    this.endMarker.on('click', (evt) => {
      if (!self.state.drawing) {
        self.startDrawing()
      } else {
        self.finishDrawing()
      }
      L.DomEvent.preventDefault(evt.originalEvent)
    })
    this.endMarker.on('mouseout', (evt) => {
      let elem = self.endMarker.getElement()
      elem.innerHTML = 'End'
    })
  }

  createLeafletElement() {
    return null
  }

  updateLeafletElement() {
  }

  //draw all routes passed to the layer
  drawRoutes() {
    let { routes } = this.props
    const map = this.props.leaflet.map
    const beforeNumOfLayers = getNumOfLayers(map)
    let keys = Object.keys(routes)
    let len = keys.length
    for (let idx = 0; idx < len; idx++) {
      let key = keys[idx]
      let route = routes[key]
      if (!route.path || !route.path.coordinates) { continue }

      if (route.visibility === 
        MapProps.LayerStates.VISIBLE) {
        //if the layer not in the map, add a new one
        if (!contains(map, key)) {
          let latlngs = coordinatesToLatLngs(route.path.coordinates)
          let polyline = L.polyline(latlngs, { weight: 5, color: route.color 
            || 'blue', opacity: 0.95 }).addTo(map)
          //add route id to the layer
          polyline.routeId = key
        }
      }
    }
    for (let idx = 0; idx < len; idx++) {
      let key = keys[idx]
      let route = routes[key]
      if (!route.path) continue

      if (route.visibility === 
        MapProps.LayerStates.INVISIBLE) {
        let removal = contains(map, key)
        if (removal) {
          map.removeLayer(removal)
        }
      }
    }
    //fit bounds only if the map was empty before
    if (beforeNumOfLayers === 0) {
      this.fitBounds()
    }
  }

  initialize() {
    let { route } = this.props
    if (!route) { return }
    clear(this.model)
    this.model.route = route
    let path = this.model.route.path
    if (path && path.coordinates) {
      let latlngs = coordinatesToLatLngs(path.coordinates)
      let len = latlngs.length
      if (len > 0) {
        // start point
        updateSegment(this.model, latlngs[0])
        // end point
        updateSegment(this.model, latlngs[len - 1])
        // line between start point and end point
        updateLine(this.model, latlngs)
        // update distance
        updateDistance(this.model, latlngs)
      }
    }
  }

  redraw() {
    if (!this.model.route) { return }
    const map = this.props.leaflet.map
    if (!this.model.poly) {
      let style = defaultPlolyineStyle
      if (this.model.route && this.model.route.color) {
        style.color = this.model.route.color
      }
      this.model.poly = L.polyline([], style).addTo(map)
    }
    iterate(this.model.lines, v => {
      let len = v.length
      for (let idx = 0; idx < len; idx++) {
        let latlng = v[idx]
        this.model.poly.addLatLng(latlng)
      }
    })
    let bounds = this.model.poly.getBounds()
    if (bounds && bounds.isValid()) {
      map.fitBounds(bounds)
    }
    this.updateStartEndLocation()
  }

  drawSelectedRoute() {
    this.initialize()
    this.redraw()
  }

  render() {
    this.drawRoutes()
    this.drawSelectedRoute()
    return null
  }
}

const enhance = compose(
  connect(
    (state) => {
      let { routes, route } = state.map
      return { routes, route }
    }, { updateRoute })
)
export default withLeaflet(enhance(RouteLayer))
