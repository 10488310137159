import React, { Component, Fragment } from 'react'
import AsyncFuzzyPicker from './async-fuzzy-picker'
import PropTypes from 'prop-types'
import './index.css'
import {
  InputAdornment,
  IconButton,
  TextField,
  withStyles,
} from '@material-ui/core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldTitle } from 'react-admin'
import { Field } from 'redux-form'
import { Provider } from '../../provider'

const styles = theme => {
  let { unit } = theme.spacing
  return {
    search: {
      margin: unit,
      marginTop: 16,
      marginBottom: 8,
    },
    clear: {
      width: 6 * unit,
      height: 6 * unit,
      margin: 0,
      padding: 0,
      right: -unit,
    }
  }
}

class _FuzzyAsyncPickerInput extends Component {

  constructor(props) {
    super(props)
    this.state = {
      itemSelected: null,
      textValue: '',
      open: false,
      value: '',
      items: [],
      initItems: []
    }
    this.textInput = React.createRef()
  }

  async componentDidMount() {
    let { input, resources, optionValue, optionText } = this.props
    if (input.value) {
      let dataInit = await Provider.dataProvider('GET_ONE', resources, {
        [optionValue]: input.value
      })
      dataInit = dataInit.data
      this.setState({ textValue: dataInit[optionText], itemSelected: dataInit, items: [{ ...dataInit }] })
    } else {
      let dataInit = await Provider.dataProvider('GET_LIST', resources, {
        limit: 10,
        sort: {},
        pagination: {}
      })
      dataInit = dataInit.data
      this.setState({ items: dataInit, itemSelected: null, initItems: [...dataInit] })
    }
  }

  // async componentDidUpdate(prevProps, prevState) {
  //   let { input, resources, optionValue, optionText } = this.props
  //   let { value: nextValue } = input
  //   let { value: preValue } = prevState
  //   if (nextValue !== preValue) {
  //     let dataInit = await Provider.dataProvider('GET_ONE', resources, {
  //       [optionValue]: input.value
  //     })
  //     dataInit = dataInit.data
  //     this.setState({ text: dataInit[optionText], itemSelected: dataInit, value: nextValue })
  //   }
  // }

  clearData = evt => {
    let { input } = this.props
    let { initItems } = this.state
    input.onChange('')
    this.setState({
      itemSelected: null,
      textValue: '',
      items: [...initItems]
    })
    evt.preventDefault()
    evt.stopPropagation()
  }

  onClose = () => {
    this.setState({ open: false }, () => {
      this.textInput.current.focus()
    })
  }

  fetchItems = value => {
    let { resources, optionText, filter = {} } = this.props
    let { initItems } = this.state
    if (!value) {
      this.setState({ items: initItems, textValue: '' })
      return Promise.resolve(initItems)
    }
    else return Provider.dataProvider('GET_LIST', resources, {
      filter: {
        ...filter,
        [optionText]: {
          ilike: `%${value}%`
        }
      },
      pagination: { page: 1, perPage: 10 },
      sort: {},
    }).then(res => {
      this.setState({ items: res.data, textValue: value })
      return Promise.resolve(res.data)
    })
  }

  handleSelectItem = (item) => {
    let { input, optionText, optionValue, renderOptionText } = this.props

    item = item || {}
    let textValue = item[optionText]
    if (renderOptionText) {
      textValue = renderOptionText(item)
    }
    this.setState({
      itemSelected: item,
      textValue,
      open: false,
      items: [{ ...item }]
    }, () => {
      this.textInput.current.focus()
    })
    input.onChange(item[optionValue])
  }

  openPicker = () => {
    this.setState({ open: true })
  }

  render() {
    const {
      label,
      input,
      resources,
      classes,
      helperText,
      disabled = false,
      ...props
    } = this.props
    const { textValue, open, items } = this.state
    return <Fragment>
      <TextField
        inputRef={this.textInput}
        label={<FieldTitle
          label={label}
          source={input.name}
          resource={resources}
        />}
        value={textValue}
        helperText={helperText}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.clear}
                aria-label="Clear"
                onClick={this.clearData}
                tabIndex={-1}
              >
                <FontAwesomeIcon icon={faTimes} size="xs" />
              </IconButton>
            </InputAdornment>
          )
        }}
        margin="normal"
        fullWidth
        disabled={disabled}
        onClick={() => {
          if (disabled) return
          this.openPicker()
        }}
        {...props}
      />
      <AsyncFuzzyPicker
        isOpen={open}
        onClose={this.onClose}
        label={label}
        fetchItems={this.fetchItems}
        onChange={this.handleSelectItem}
        items={items}
        showAllItems={true}
        textValue={textValue}
        {...this.props}
      />
    </Fragment>
  }
}

const FuzzyAsyncPickerInput = (props) => (
  <Field name={props.source} component={_FuzzyAsyncPickerInput} {...props} />
)

const FuzzyAsyncPickerInputWithStyle = withStyles(styles)(FuzzyAsyncPickerInput)

FuzzyAsyncPickerInputWithStyle.propTypes = {
  resources: PropTypes.string.isRequired, //Model will call API get list (ex: countries)
  optionText: PropTypes.string.isRequired, //Property of model will show on fuzzy select (ex: name)
  optionValue: PropTypes.string.isRequired, //Property of model will pass into redux-form (ex: id)
  source: PropTypes.string.isRequired, //item property (ex: countryId)
  label: PropTypes.string, //Label input (ex: translate('country.name'))
  renderItem: PropTypes.node, //define row renderer in suggestion list (ex: <div>item</div>)
  disabled: PropTypes.bool, //disable FuzzyAsyncPickerInput, default: false
  filter: PropTypes.object //object filter dataProvider
}

export default FuzzyAsyncPickerInputWithStyle
