import React, { Component } from 'react'
import compose from 'recompose/compose'
import {
  withStyles,
  Paper,
} from '@material-ui/core'
import { 
  translate,
  ReferenceField,
  ImageField,
  Datagrid,
  Pagination,
  FunctionField,
  TextField,
} from 'react-admin'
import { connect } from 'react-redux'
import Snackbar from '../common/Snackbar'
import isEmpty from 'lodash/isEmpty'
import _ from 'lodash'

const styles = (theme) => ({
  row: {
    fontSize: 12,
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  textLeft: {
    textAlign: 'left',
  },
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  }, 
})

class ReviewStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      perPage: 10,
      rows: [],
      total: 0,
      pagedItems: [],
      data: [],
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) { 
    let { rows, isStep1Completed, isStep2Completed } = nextProps
    let {
      rows : currentRows,
      isStep1Completed: currentIsStep1Completed,
      isStep2Completed: currentIsStep2Completed,
      page,
      perPage,
    } = prevState
    if (currentRows !== rows) {
      currentRows = rows
    }
    if (currentIsStep1Completed !== isStep1Completed) {
      currentIsStep1Completed = isStep1Completed
    }
    if (currentIsStep2Completed !== isStep2Completed) {
      currentIsStep2Completed = isStep2Completed
    }
    let total = currentRows.length        
    let offset = (page - 1) * perPage    
    let pagedItems = _.drop(currentRows, offset).slice(0, perPage)
    return {
      rows: currentRows,
      isStep1Completed: currentIsStep1Completed,
      isStep2Completed: currentIsStep2Completed,      
      pagedItems,
      total,
    }
  }

  setPage = page => {
    let { rows: items, perPage } = this.state
    this.setState({ page })
    this.getData(items, page, perPage)
  }

  setPerPage = perPage => {
    let { rows: items, page } = this.state
    this.setState({ perPage })
    this.getData(items, page, perPage)
  }

  getData = (items, page, perPage) => {
    let offset = (page - 1) * perPage    
    let pagedItems = _.drop(items, offset).slice(0, perPage)
    this.setState({ pagedItems })
  }

  render() {
    const { classes, translate } = this.props
    let {
      page,
      isStep1Completed,
      isStep2Completed,
      total,
      pagedItems=[],
      perPage,
    } = this.state
    let ids = Object.keys(pagedItems)
    let index = (page - 1) * perPage + 1
    pagedItems = pagedItems.map(item => {
      item.index = index++
      return item
    })
    return (isStep1Completed && isStep2Completed) ? (
      <Paper className={classes.root}>
        <Datagrid
          ids={ids}
          data={pagedItems}
          currentSort={{}}
        >
          <FunctionField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.common.no"
            render = { record => {
              let id = record.index
              return id
            }}
          />
          <TextField 
            source="plate"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.vehicles.fields.plate"
          />
          <TextField
            source="name"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.common.name"
          />
          <TextField
            source="brand"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.vehicles.fields.brand"
          />
          <TextField
            source="model"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.vehicles.fields.model"
          />
          <TextField
            source="color"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.vehicles.fields.color"
          />
          <ReferenceField
            record={pagedItems}
            basePath="/fueltypes"
            source="fuel"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            reference="fueltypes"
            label="resources.vehicles.fields.fuel"
            linkType={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField
            source="capacity"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.vehicles.fields.capacity"
          />
          <TextField
            source="seatAvailable"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.vehicles.fields.seatAvailable"
          />
          <ReferenceField
            record={pagedItems}
            basePath="/vehicletypes"
            source="type"
            reference="vehicletypes"
            label="resources.common.type"
            linkType={false}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            record={pagedItems}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            basePath="/vehiclestatuses"
            source="status"
            reference="vehiclestatuses"
            label="resources.common.status"
            linkType={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ImageField
            record={pagedItems}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="images"
            src="src"
            title="picture.title"
            label="resources.vehicles.fields.images"
          />
        </Datagrid>
        <Pagination
          page={page}
          perPage={perPage}
          setPage={this.setPage}
          setPerPage={this.setPerPage}
          total={total}
        />
      </Paper>
    ) : (
      <Snackbar
        className={classes.snackbar}
        variant="warning"
        message={!isStep1Completed ? translate('notification.select_vehicle_type_uncompleted') : translate('notification.add_info_uncompleted')}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let rows = state['vehicle'].values || []
  let vehicleType = state['vehicle-type']
  let isStep1Completed = !isEmpty(vehicleType) && !isEmpty(vehicleType.selected)
  let isStep2Completed = rows.length > 0
  return { rows, isStep1Completed, isStep2Completed }
}

const enhance = compose (
  translate,
  connect(mapStateToProps),
  withStyles(styles)
)

export default enhance(ReviewStep)
