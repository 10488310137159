import React, { Fragment } from 'react'
import { translate } from 'react-admin'
import { compose } from 'recompose'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  withStyles,
  Avatar,
  Divider,
} from '@material-ui/core'
import _ from 'lodash'
import * as chargeStatus from '../common/charge-status'
import { formatCurrency } from '../utils/formatUtil'
import PrintButton from '../reservation/PrintButton'
import RemotePrintButton from './RemotePrintButton'
import ExportButton from '../reservation/ExportButton'
import EditReservationButton from './EditReservationButton'
import AddReservationButton from './AddReservationButton'
import CancelReservationButton from './CancelReservationButton'
import SwapSeatButton from './SwapSeatButton'
import { yellow } from '@material-ui/core/colors'
import { validSwapTrip } from '../viewtrip/StatusField'

const emptySeatCardStyle = {
  seatHeader: {
    padding: 4
  },
  seatAvatar: {
    backgroundColor: 'white',
    color: 'black'
  },
  seatTitle: {
    fontSize: 16,
  },
  seatContent: {
    padding: '8px 16px 0',
    flex: 1,
  },
  cardActions: {
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
    color: '#555',
  },
  selectSwapSeatText: {
    fontSize: 15,
    fontStyle: 'italic',
    fontWeight: 500,
    color: 'gray',
  }
}

const _EmptySeatCard = ({
  onClick,
  onDoubleClick,
  translate,
  className,
  classes,
  seat,
  onlyView,
  onAddSeat,
  styles,
  selected,
  isSwapMode,
  isSmall,
}) => {
  return <Card
    style={{ border: isSwapMode && '1px solid grey' }}
    className={className}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <CardHeader
      avatar={<Avatar
        aria-label={seat}
        style={styles}
      >
        <b>{seat}</b>
      </Avatar>}
      title={translate('resources.charges.fields.empty_seat')}
    />
    <Divider />
    <CardContent className={classes.seatContent}>
      {isSwapMode && <span className={classes.selectSwapSeatText}>{translate('resources.charges.select_swap_seat')}</span>}
    </CardContent>  
    {(!isSwapMode || isSmall) && <CardActions className={classes.cardActions}>
      <AddReservationButton
        className={classes.iconButton}
        disabled={onlyView}
        seat={seat}
        onAddSeat={onAddSeat}
        selected={selected}
      />
    </CardActions>}
  </Card>
}

const EmptySeatCard = compose(withStyles(emptySeatCardStyle), translate)(_EmptySeatCard)

const reservedSeatCardStyle = {
  seatHeader: {
    padding: 4
  },
  seatAvatar: {
    backgroundColor: 'white',
    color: 'black'
  },
  seatTitle: {
    fontSize: 16,
  },
  seatSubTitle: {
    color: 'black'
  },
  seatContent: {
    padding: '8px 16px 0',
    flex: 1,
  },
  cardActions: {
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
    color: '#555',
  },
  rightText: {
    float: 'right',
  },
}

const _ReservedSeatCard = ({
  customerInfo,
  isOwner,
  onClick,
  onDoubleClick,
  translate,
  className,
  classes,
  seat,
  charge,
  reservation,
  paymentInfo,
  reservationChargeCount,
  onlyView,
  onEditSeat,
  passengerCollection,
  selected,
  isSwapMode,
  changeSwapMode,
  onCancelSeat,
  styles,
  isSmall,
  canSwapTrip,
}) => {
  let { reservationId, reservationCode, state } = reservation
  let { pickUpPointName, dropOffPointName } = passengerCollection
  let { phone, fullName } = customerInfo
  let { paid, isPrePaid, discount } = paymentInfo
  let title = phone && phone.length <= 10 ? phone : `${phone.substring(0, 3)}...${phone.substring(phone.length - 4, phone.length)}` 
  if (!isOwner) {
    title = translate('resources.charges.fields.reserved_seat')
  }
  return <Card
    className={className}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
  >
    <CardHeader
      avatar={<Avatar
        aria-label={seat}
        style={styles}
      >
        <b>{seat}</b>
      </Avatar>}
      title={<b className={classes.seatTitle}>{title}</b>}
      subheader={charge ? <b className={classes.seatSubTitle}>{fullName}</b> : ''}
    />
    <Divider />
    <CardContent className={classes.seatContent}>
      <Typography>
        {translate('resources.charges.fields.id')}:&nbsp;
        <b>{reservationId}</b>
        {isPrePaid ? <span className={classes.rightText}>{`(${translate('resources.reservations.deposit')}: ${formatCurrency(paid)})`}</span> : ''}
      </Typography>
      <Typography>
        {translate('resources.charges.fields.code')}:&nbsp;
        <b>{_.get(charge, 'reservation.code')}</b>
        {reservationId ? <span className={classes.rightText}>{`(${reservationChargeCount[reservationId]} ${translate('resources.reservations.place')})`}</span> : ''}
      </Typography>
      <Typography>
        {translate('resources.charges.fields.price')}:&nbsp;
        <b>{charge.amount && formatCurrency(charge.amount)}</b>
        <i className={classes.rightText}>{!!discount && `(${translate('resources.campaigns.discounted')} ${formatCurrency(discount)})`}</i>
      </Typography>
      <Typography>
        {translate('resources.charges.fields.agency')}:&nbsp;
        <b>{_.get(charge, 'reservation.agency.name')}</b>
      </Typography>
      <Typography>
        {translate('resources.charges.pickUpPoint')}:&nbsp;<b>{pickUpPointName}</b>
      </Typography>
      <Typography>
        {translate('resources.charges.dropOffPoint')}:&nbsp;<b>{dropOffPointName}</b>
      </Typography>
    </CardContent>
    {!isSmall && <CardActions className={classes.cardActions}>
      {isOwner ? <Fragment>
        <SwapSeatButton
          isSwap={isSwapMode && selected}
          className={classes.iconButton}
          disabled={onlyView || !canSwapTrip}
          seat={seat}
          reservationId={reservationId}
          changeSwapMode={changeSwapMode}
          selected={selected}
        />
        {state === chargeStatus.PAID && <Fragment>
          <RemotePrintButton className={classes.iconButton} disabled={onlyView} code={reservationCode} />
          <PrintButton className={classes.iconButton} disabled={onlyView} id={reservationId} />
          <ExportButton className={classes.iconButton} disabled={onlyView} id={reservationId} code={reservationCode} />
        </Fragment>}
        <EditReservationButton
          className={classes.iconButton}
          disabled={onlyView || !canSwapTrip}
          seat={seat}
          onEditSeat={onEditSeat}
          charge={charge}
        />
        <CancelReservationButton 
          className={classes.iconButton}
          disabled={onlyView || !canSwapTrip}
          seat={seat}
          onCancelSeat={onCancelSeat}
          charge={charge}
          reservationId={reservationId}
          selected={selected}
        />
      </Fragment> : null}
    </CardActions>}
  </Card>
}

const ReservedSeatCard = compose(withStyles(reservedSeatCardStyle), translate)(_ReservedSeatCard)

const styles = {
  seatCard: {
    margin: 3,
    height: 280 - 6,
    display: 'flex',
    flexDirection: 'column',
  },
  selectedSeatCard: {
    margin: 3,
    height: 280 - 6,
    background: yellow[100],
    display: 'flex',
    flexDirection: 'column',
  },
  swapSelectedSeatCard: {
    margin: 3,
    height: 280 - 6,
    backgroundColor: '#c8e6c9',
    border: '2px solid red',
    display: 'flex',
    flexDirection: 'column',
  },
}

const SeatCard = ({
  seat: { seat, charge },
  reservationChargeCount,
  classes,
  onClick,
  onDoubleClick,
  selected,
  swapSelected,
  onAddSeat,
  onEditSeat,
  onCancelSeat,
  isOwner,
  allStop,
  onlyView,
  changeSwapMode,
  isSwapMode,
  isSmall,
  tripStatus,
}) => {
  let total = _.get(charge, 'reservation.paymentInformation.total', 0)
  let paid = _.get(charge, 'reservation.paymentInformation.paid', 0)
  let state = chargeStatus.OTHER
  let reservationId = _.get(charge, 'reservation.id')
  let reservationCode = _.get(charge, 'reservation.code')
  let isPrePaid = false
  let pickUpPoint = _.get(charge, 'itemDetail.pickUpPoint')
  let dropOffPoint = _.get(charge, 'itemDetail.dropOffPoint')
  let discount = _.get(charge, 'itemDetail.discount')
  let pickUpPointName = (pickUpPoint && allStop[pickUpPoint]) ? allStop[pickUpPoint].name : ''
  let dropOffPointName = (dropOffPoint && allStop[dropOffPoint]) ? allStop[dropOffPoint].name : ''
  let phone = _.get(charge, 'reservation.bookingInformation.contact.phone', '')
  let fullName = _.get(charge, 'reservation.bookingInformation.contact.fullName', '') || _.get(charge, 'reservation.bookingInformation.contact.firstName', '')
  if (isOwner) {
    isPrePaid = chargeStatus.isPrePaid(paid, total)
    if (isPrePaid) {
      state = chargeStatus.PRE_PAID
    } else {
      state = _.get(charge, 'status') || chargeStatus.OTHER
    }
  }
  let color = chargeStatus.color[state]
  color = isOwner ? color : '#607d8b'
  let styles = { backgroundColor: color }
  let className = classes.seatCard
  if (selected) {
    className = classes.selectedSeatCard
  }
  if (swapSelected) {
    className = classes.swapSelectedSeatCard
  }
  let isReserved = !(!charge)
  let canSwapTrip = validSwapTrip(tripStatus)
  return isReserved ? <ReservedSeatCard
    customerInfo={{ phone, fullName }}
    isOwner={isOwner}
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    className={className}
    seat={seat}
    styles={styles}
    charge={charge}
    reservation={{ reservationId, reservationCode, state }}
    paymentInfo={{ paid, total, discount, isPrePaid }}
    reservationChargeCount={reservationChargeCount}
    onlyView={onlyView}
    onEditSeat={onEditSeat}
    passengerCollection={{ pickUpPointName, dropOffPointName }}
    selected={selected}
    isSwapMode={isSwapMode}
    changeSwapMode={changeSwapMode}
    onCancelSeat={onCancelSeat}
    isSmall={isSmall}
    canSwapTrip={canSwapTrip}
  /> : <EmptySeatCard 
    onClick={onClick}
    onDoubleClick={onDoubleClick}
    className={className}
    seat={seat}
    styles={styles}
    onlyView={onlyView}
    onAddSeat={onAddSeat}
    selected={selected}
    isSwapMode={isSwapMode}
    isSmall={isSmall}
  />
}

const enhance = compose(withStyles(styles))
export default enhance(SeatCard)
