import React, { Component } from 'react'
import {
  ExpansionPanelSummary,
  Typography,
  ExpansionPanelDetails,
  withStyles,
  ExpansionPanel
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon,
  DragHandle as DragHandleIcon,
} from '@material-ui/icons'
import SortFareTable from './SortFareTable'
import compose from 'recompose/compose'
import { DragSource, DropTarget, } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'

const styles = {
  heading: {
    fontWeight: 'bold',
    margin: 'auto 0 auto 15px'
  }
}

class ExpansionPanelRoute extends Component {
  componentDidMount() {
    let { connectDragPreview } = this.props
    if (connectDragPreview)
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true
      })
  }
  render() {
    let {
      route,
      routeChecked,
      onDrop,
      reorderItem,
      reorderItems,
      handleGetProduct,
      productSelected,
      classes,
      handleChangePanel,
      isHover,
      connectDragSource,
      connectDropTarget,
    } = this.props
    let opacity = isHover ? 0 : 1
    return connectDropTarget(connectDragSource(<div>
      <ExpansionPanel
        expanded={route.expanded}
        onChange={handleChangePanel(route.id)}
        style={{ opacity }}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <DragHandleIcon />
          <Typography className={classes.heading}>{route.name}</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <SortFareTable
            data={route.data}
            tableChecked={routeChecked}
            ids={route.data.map((v, i) => i)}
            reorder={reorderItem}
            reorderGroup={reorderItems}
            handleGetProduct={handleGetProduct}
            productSelected={productSelected}
            onDrop={onDrop}
            routeId={route.id}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>))
  }
}

export const ItemTypes = {
  ITEM: 'EXPANSION_ROUTE_DND_ITEM'
}

export default compose(
  withStyles(styles),
  DragSource(ItemTypes.ITEM, {
    beginDrag: (props) => {
      props.beginDrag(props.route.id)
      return {
        index: props.routeIndex,
        routeId: props.route.id
      }
    },
  }, (connect) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
  })),
  DropTarget(ItemTypes.ITEM, {
    hover: (props, monitor, component) => {
      if (!component) return null
      const dragIndex = monitor.getItem().index
      const hoverIndex = props.routeIndex
      if (dragIndex === hoverIndex) {
        return
      }
      props.reorderExpansion(dragIndex, hoverIndex)
      monitor.getItem().index = hoverIndex
    },
    drop: (props) => {
      props.onDropExpan()
    },
  }, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isHover: monitor.isOver(),
    // canDrop: monitor.canDrop()
  })),
)(ExpansionPanelRoute)
