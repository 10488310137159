import XLSX from 'xlsx'
import { readExcelFile } from '../utils/file'
import { getNumberOfSeat } from './index'

export const getCsvFromSeatMap = (name, codesFistFloor, codesSecondFloor, privateCode, translate) => {
  let workBook = XLSX.utils.book_new()
  let options = { bookType: 'xlsx', bookSST: false, type: 'array' }
  let workSheet1 = XLSX.utils.aoa_to_sheet([
    [
      translate('resources.common.name'), 
      translate('resources.seatmaps.fields.numOfSeat'),
      translate('resources.seatmaps.fields.privateCode')
    ], 
    [
      name, 
      getNumberOfSeat({codesFistFloor, codesSecondFloor}).toString(),
      (privateCode && privateCode.length)? JSON.stringify(privateCode) : translate('resources.common.empty')
    ]
  ])
  XLSX.utils.book_append_sheet(workBook, workSheet1, translate('resources.common.title_form_info'))
  let workSheet2 = XLSX.utils.aoa_to_sheet(codesFistFloor)
  let workSheet3 = XLSX.utils.aoa_to_sheet(codesSecondFloor)
  XLSX.utils.book_append_sheet(workBook, workSheet2, translate('resources.seatmaps.fields.firstFloor'))
  XLSX.utils.book_append_sheet(workBook, workSheet3, translate('resources.seatmaps.fields.secondFloor'))
  let wbout = XLSX.write(workBook, options)
  return wbout
}

export const readXlsx = async (file) => {
  const reader = new FileReader()
  const rABS = !!reader.readAsBinaryString
  let bstr = await readExcelFile(file)
  let wb = XLSX.read(
    bstr,
    { type: rABS ? 'binary' : 'array' }
  )
  let options = { header: 1, blankrows: false, raw: false }
  let wsnames = wb.SheetNames
  let objInfo = XLSX.utils.sheet_to_json(wb.Sheets[wsnames[0]], options)[1]
  let name = objInfo[Object.keys(objInfo)[0]]
  let codesFistFloor = XLSX.utils.sheet_to_json(wb.Sheets[wsnames[1]], options)
  let codesSecondFloor = XLSX.utils.sheet_to_json(wb.Sheets[wsnames[2]], options)
  let privateCode
  try {
    privateCode = JSON.parse(objInfo[Object.keys(objInfo)[2]])
  } catch (error) {
    privateCode = []
  }
  return { name, codesFistFloor, codesSecondFloor, privateCode }
}