import React, { Component } from 'react'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { withStyles } from '@material-ui/core'
import ChipArrayField from '../common/ChipArrayField'
import { Grid } from '@material-ui/core'
import { isNotEmpty2D } from '../utils/array'

const styles = () => ({
  labelFloor: {
    padding: 12,
    fontSize: 13,
    fontWeight: 'bold',
  },
  containerSeat: {
    padding: 12,
  },
  leftCard: {
    borderRight: '1px solid #e8e8e8'
  },
  seat: {
    marginRight: 8,
    marginBottom: 8,
    width: '3em',
  },
})

class SeatMap extends Component {
  constructor(props) {
    super(props)
    this.state = {
      seatMapSelected: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { seatMapSelected } = nextProps
    let currentSeatMapSelected = prevState.seatMapSelected
    if (currentSeatMapSelected !== seatMapSelected) {
      currentSeatMapSelected = seatMapSelected
    }
    return {
      seatMapSelected: currentSeatMapSelected || {}
    }
  }

  render() {
    const { translate, classes } = this.props
    let { seatMapSelected } = this.state
    let { codesFistFloor, codesSecondFloor } = seatMapSelected
    const isDoubleDeck = isNotEmpty2D(codesSecondFloor)

    return codesFistFloor ? (
      <>
        <Grid container>
          <Grid item className={classes.leftCard}>
            <span className={classes.labelFloor}>
              {translate('resources.seatMaps.fist_floor')}
            </span>
            <div className={classes.containerSeat}>
              {codesFistFloor && codesFistFloor.map((codeFistFloor, idx) => {
                return codesFistFloor ? (
                  <div key={idx}>
                    <ChipArrayField
                      datum={codeFistFloor}
                      className={classes.seat}
                      classes={classes}
                    />
                  </div>
                ) : <span>{translate('resources.seatMaps.empty_seat_map')}</span>
              })}
            </div>
          </Grid>
          {isDoubleDeck &&
          <Grid item>
            <span className={classes.labelFloor}>
              {translate('resources.seatMaps.second_floor')}
            </span>
            <div className={classes.containerSeat}>
              {codesSecondFloor.map((codeSecondFloor, idx) => {
                return codesSecondFloor ? (
                  <div key={idx}>
                    <ChipArrayField
                      datum={codeSecondFloor}
                      className={classes.seat}
                    />
                  </div>
                ) : <span>{translate('resources.seatMaps.empty_seat_map')}</span>
              })}
            </div>
          </Grid>
          }
        </Grid>
      </>
    ) : null
  }
}

const enhance = compose(
  translate,
  withStyles(styles)
)

export default enhance(SeatMap)
