import React, { Component, Fragment } from 'react'
import {
  Card,
  CardHeader,
  Button,
  MenuItem,
  ListItemText,
  Popover,
  Dialog,
  DialogTitle,
  CardActions,
} from '@material-ui/core'
import { typeMapping } from './index'
import {
  ReferenceField,
  ReferenceManyField,
  SimpleForm,
  translate,
  ReferenceInput,
  refreshView,
  showNotification,
} from 'react-admin'
import Snackbar from '../common/Snackbar'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { Provider } from '../provider'
import { reset } from 'redux-form'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import _ from 'lodash'

export function getRecord(data, ids) {
  if (ids.length < 2) {
    let id = ids[0]
    return data[id]
  }
  for (let i = 0; i < ids.length; i++) {
    let id = ids[i]
    if (id) {
      let record = data[id] || {}
      let { type } = record
      if (type === 'COM') {
        return data[id]
      }
    }
  }
}

const CardComponent = translate(({ record, principal, translate, unMapping }) => {
  return <Card>
    <CardHeader
      title={record.name}
      subheader={translate(principal)}
    />
    <CardActions>
      <Button
        color="primary"
        variant="contained"
        onClick={() => unMapping()}
      >
        {translate('button.unassign')}
      </Button>
    </CardActions>
  </Card>
})

const action = (onClick, labelButtonAction) => {
  return [
    <Button
      key={0}
      onClick={(event) => onClick(event)}
      style={{ color: '#ffff' }}
    >
      {labelButtonAction}
    </Button>
  ]
}

const Actions = ({ data = {}, ids = [], handleLink }) => {
  return ids.map((id, idx) => (
    <MenuItem
      key={idx}
      onClick={() => handleLink(id)}
    >
      <ListItemText>{data[id].name}</ListItemText>
    </MenuItem>
  ))
}

let anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
let transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const ActionMenu = ({ open, onClose, anchorEl, handleLink }) => {
  return <Popover
    anchorEl={anchorEl}
    open={open}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <ReferenceManyField
      basePath="/accounttypes"
      resource="accounttypes"
      reference="accounttypes"
      record={{}}
      target=""
      filter={{ id: { neq: 'PLA' } }}
    >
      <Actions handleLink={handleLink} />
    </ReferenceManyField>
  </Popover>
}

class RefForm extends Component {

  render() {
    let { type, mapping } = this.props
    if (!type) return null
    let { resource, label } = typeMapping[type]
    let agencyFilter = resource === 'agencies' ? { '../ignore': { agencyIsCompany: true }} : {}
    return resource ? <SimpleForm
      resource="financeaccountmappings"
      form="add-ref-form"
      save={mapping}
    >
      <ReferenceInput
        label={label}
        resource="financeaccountmapping"
        basePath={`/${resource}`}
        reference={resource}
        record={{}}
        source="refId"
        fullWidth
        filter={agencyFilter}
        perPage={1000}
      >
        <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
      </ReferenceInput>
    </SimpleForm> : <div></div>
  }
}

const RefFormDialog = translate(({ translate, accountId, type, open, onClose, mapping }) => {
  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle>{translate('button.add_reference')}</DialogTitle>
    <RefForm type={type} accountId={accountId} mapping={mapping} />
  </Dialog>
})

class RefCard extends Component {

  state = {
    actionMenu: {
      open: false,
      anchorEl: null,
    },
    dialog: {
      open: false,
      type: '',
    }
  }

  openMenu = (event) => {
    this.setState({
      actionMenu: {
        open: true,
        anchorEl: event.target,
      }
    })
  }

  handleCloseMenu = () => {
    this.setState({
      actionMenu: {
        open: false,
        anchorEl: null,
      }
    })
  }

  openDialog = (type) => {
    this.setState({
      dialog: {
        open: true,
        type,
      }
    })
    this.handleCloseMenu()
  }

  handleCloseDialog = () => {
    this.setState({
      dialog: {
        open: false,
        type: '',
      }
    })
  }

  unMapping = () => {
    let { record, refreshView, showNotification } = this.props
    Provider.dataProvider('REMOTE', 'financeaccountmappings', {
      method: 'unMappingPrincipal',
      requestMethod: 'POST',
      data: { accountId: record.id }
    }).then(() => {
      refreshView()
      showNotification('notification.remove_principal_success')
    }).catch((e) => {
      refreshView()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  mapping = (record) => {
    let { accountId, reset, refreshView, showNotification } = this.props
    let { dialog } = this.state
    let { type } = dialog
    let { refId } = record
    Provider.dataProvider('REMOTE', 'financeaccountmappings', {
      method: 'mappingPrincipal',
      requestMethod: 'POST',
      data: { accountId, type, refId },
    }).then(() => {
      reset('add-ref-form')
      this.handleCloseDialog()
      refreshView()
      showNotification('notification.add_principal_success')
    }).catch((e) => {
      reset('add-ref-form')
      this.handleCloseDialog()
      refreshView()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  render() {
    let { record, translate } = this.props
    let { actionMenu, dialog } = this.state
    let { id, type } = record
    if (type) {
      let { resource, label } = typeMapping[type]
      return resource ? <ReferenceField
        basePath={`/${resource}`}
        resource={resource}
        reference={resource}
        record={record}
        source="refId"
        linkType={false}
      >
        <CardComponent principal={label} unMapping={this.unMapping} />
      </ReferenceField> : null
    }
    return <Fragment>
      <Snackbar
        variant="warning"
        message={translate('notification.no_reference')}
        onClick={this.openMenu}
        labelButtonAction={translate('button.add_reference')}
        action={action}
      />
      <ActionMenu
        open={actionMenu.open}
        onClose={this.handleCloseMenu}
        anchorEl={actionMenu.anchorEl}
        handleLink={this.openDialog}
      />
      <RefFormDialog
        open={dialog.open}
        type={dialog.type}
        accountId={id}
        onClose={this.handleCloseDialog}
        mapping={this.mapping}
      />
    </Fragment>
  }
}

const enhance = compose(translate, connect(null, { reset, refreshView, showNotification }))

export default enhance(RefCard)
