import React from 'react'
import {
  Card,
  CardContent,
  Button,
} from '@material-ui/core'
import { Provider } from '../provider'

const ProductReservation = () => {
  const makeReservation = async () => {
    let product = await Provider.dataProvider('REMOTE', 'products', {
      method: '',
      remoteMethod: 'GET',
      filter: JSON.stringify({ where: { name: 'Vé 24h' } }),
    })

    let fare = await Provider.dataProvider('REMOTE', 'fares', {
      method: '',
      remoteMethod: 'GET',
      filter: JSON.stringify({ where: { code: '24H-ADULT' } }),
    })

    console.log('product, fare', product, fare)

    const contact = {
      fullName: 'Nguyen Phu Quang',
      phone: '0902221044',
      email: 'nguyenphuquang@gmail.com',
      note: 'Khong co gi',
    }
    const charges = []
    charges.push({
      index: 0,
      type: '10NO_REFUND',
      itemDetail: {
        productId: product.data.id,
        productType: '30TICKET',
        fareId: fare.data.id,
      },
      amount: 320000,
    })

    const reservation = {
      passengers: [],
      trips: [],
      charges,
      bookingInformation: { contact },
      transactions: [],
      source: '10WEBADMIN',
      status: '00NEW',
      agencyId: 2,
    }
    console.log('product reservation request', reservation)
    let result = await Provider.dataProvider('REMOTE', 'reservations', {
      method: 'make',
      data: reservation,
    })

    console.log('product reservation result', result)
  }
  return (
    <Card>
      <CardContent>
        <div>
          Make reservation
        </div>
        <div>
          <Button color="primary" onClick={() => makeReservation()}>
            Make reservation
          </Button>
        </div>
      </CardContent>
    </Card>
  )
}
export default ProductReservation
