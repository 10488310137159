import React, {Component} from 'react'
import {
  withStyles,
  Typography,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { translate } from 'react-admin'

class FormControlCustom extends Component {
  constructor(props) {
    super(props)
    this.state= {}
  }
  render() {
    const {label, children, classes, fullWidth = false, unit, translate } = this.props
    return <div id={label} className={fullWidth ? classes.fullWidthRoot : classes.root}>
      <Typography style={{marginRight: 10}}>
        {label ? translate(label) : ' '}
        {unit ? ` (${unit})` : ''}
      </Typography>
      <div className={classes.content}>{children}</div>
    </div>
  }
}

export default FormControlCustom = compose(withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  fullWidthRoot: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flex: 1
  },
  content: {
    display: 'flex',
    flex: 1,
    alignItems: 'center'
  }
}), translate)(FormControlCustom)
