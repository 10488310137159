import React, { Fragment, Component, createRef } from 'react'
import {
  Datagrid,
  List,
  Create,
  SimpleForm,
  Edit,
  TextInput,
  ReferenceManyField,
  FunctionField,
  translate,
  Responsive,
  ReferenceInput,
  SelectInput,
  CardActions,
  CreateButton,
} from 'react-admin'
import {
  withStyles,
  Toolbar,
  Typography,
  Divider,
  CardHeader,
  CardContent,
  Card,
  Avatar,
  Grid,
  Step,
  Stepper,
  StepButton,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  List as MUIList,
  ListItem,
  ListItemText,
  Popover,
  CircularProgress,
  Chip,
  Tooltip,
} from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { Link, withRouter } from 'react-router-dom'
import { faPlus, faRoute, faCalendar, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import IconButton from '../common/IconButton'
import compose from 'recompose/compose'
import Snackbar from '../common/Snackbar'
import { grey, green, yellow } from '@material-ui/core/colors'
import AlertDialog from '../common/AlertDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Provider } from '../provider'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import CheckboxInput from '../common/CheckboxInput'
import * as permission from '../utils/permission'
import RouteGroupDetail from './RouteGroupDetail'
import MobileGrid from './MobileGrid'
import _ from 'lodash'
import { PriceIcon } from '../icons'
import ArchiveButton from './ArchiveButton'
import LinkField from '../common/LinkField'
import EditIcon from '@material-ui/icons/Edit'
import { validate } from './validate'
import { validateName, MIN, MAX } from '../common/validateName'

const styles = {
  toolbar: {
    width: '100%',
    minHeight: '3em',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  snackbar: {
    width: '100%',
    maxWidth: '100%',
  },
  expansion_detail: {
    backgroundColor: grey[100],
    flexDirection: 'column',
  },
  panelDetailRoot: {
    display: 'flex',
    padding: '6px 12px 12px',
  },
  cardHeaderTitle: {
    fontSize: '1.15em'
  },
  avatarRoot: {
    width: 50,
    height: 50,
  },
  progress: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 50,
  },
  unAssigned: {
    backgroundColor: grey[500],
    color: 'white'
  },
  avatarUnassigned: {
    backgroundColor: grey[600],
    color: 'white'
  },
  assignedAll: {
    backgroundColor: green[500],
    color: 'white'
  },
  avatarAssignedAll: {
    backgroundColor: green[600],
    color: 'white'
  },
  assignedAPart: {
    backgroundColor: yellow[500]
  },
  avatarAssignedAPart: {
    backgroundColor: yellow[600]
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
}

const enhance = compose(
  withStyles(styles),
  translate,
)

const ListCardAction = ({ basePath }) => {
  return <CardActions>
    <CreateButton basePath={basePath} />
  </CardActions>
}

const iconRouteGroupMapping = {
  '00BUS': <FontAwesomeIcon icon={faRoute} />,
  '10BUSTOUR': <FontAwesomeIcon icon={faTicketAlt} />,
}

////////////////////////////////////////////////////////////////////////////////
// List
export const _RouteGroupList = ({
  translate,
  history,
  classes,
  permissions,
  ...props
}) => {
  let isCompanyManager = permission.isCompanyManager(permissions)
  return (
    <List
      {...props}
      filterDefaultValues={{
        status: { neq: '20ARCHIVED' },
        '../count': { route: 1, timetable: 1, schedule: 1 },
        '../fields': [
          'id',
          'name',
          'route', 
          'type',
          'timetable',
          'countRoute',
          'countTimetable',
          'countSchedule',
          'routeFares',
          'routes',
          'routeTrips',
        ]
      }}
      bulkActionButtons={false}
      actions={<ListCardAction />}
    >
      <Responsive
        xsmall={<MobileGrid isCompanyManager={isCompanyManager} history={history} />}
        medium={
          <Datagrid expand={<RouteGroupDetail isCompanyManager={isCompanyManager} history={history} />}>
            <FunctionField 
              source="name"
              render={({ type, name }) => {
                let avatar = iconRouteGroupMapping[type]
                return <Chip
                  avatar={<Avatar>{avatar}</Avatar>}
                  label={name ? (name.length > 30 ? `${name.substring(0, 27)}...` : name) : ''}
                />
              }}
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="countRoute"
              render={({ countRoute }) => {
                let smartCount = countRoute > 1 ? 2 : 1
                return translate('resources.routes.total_route', { count: countRoute, smart_count: smartCount })
              }}
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="countTimetable"
              render={({ countTimetable }) => {
                let smartCount = countTimetable > 1 ? 2 : 1
                return translate('resources.routes.total_timetable', { count: countTimetable, smart_count: smartCount })}
              }
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="countSchedule"
              render={({ countSchedule }) => {
                let smartCount = countSchedule > 1 ? 2 : 1
                return translate('resources.routes.total_schedule', { count: countSchedule, smart_count: smartCount })}
              }
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="countTrip"
              render={({ routeTrips }) => {
                if (!routeTrips) return ''
                let countTrip = _.reduce(Object.values(routeTrips), (total, ele) => {
                  total += ele
                  return total
                }, 0)
                let smartCount = countTrip > 1 ? 2 : 1
                return translate('resources.routes.total_trip', { count: countTrip, smart_count: smartCount })}
              }
            />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              source="routeFares"
              render={({ routeFares }) => {
                if (!routeFares) return ''
                let unAssigned = _.filter(routeFares, routeFare => !routeFare)
                let total = Object.values(routeFares).length
                let unAssignedLength = unAssigned.length
                if (total === unAssignedLength) {
                  return <Chip
                    avatar={<Avatar className={classes.avatarUnassigned}>
                      <PriceIcon className={classes.icon} fontSize="small"/>
                    </Avatar>}
                    className={classes.unAssigned}
                    label={translate('resources.routes.fare_unassigned')}
                  />
                }
                if (unAssignedLength === 0) {
                  return <Chip
                    avatar={<Avatar className={classes.avatarAssignedAll}>
                      <PriceIcon className={classes.icon} fontSize="small"/>
                    </Avatar>}
                    className={classes.assignedAll} 
                    label={translate('resources.routes.fare_assigned_all')}
                  />
                }
                return <Chip
                  avatar={<Avatar className={classes.avatarAssignedAPart}>
                    <PriceIcon className={classes.icon} fontSize="small"/>
                  </Avatar>}
                  className={classes.assignedAPart}
                  label={translate('resources.routes.fare_assigned_a_part', { number: total - unAssignedLength })}
                />
              }}
            />
            <FunctionField
              sortable={false}
              cellClassName={classes.textEnd}
              render={({ id }) => <Fragment>
                <Tooltip title={translate('button.edit')} enterDelay={100} >
                  <LinkField
                    className={classes.iconButton}
                    path={`/routegroups/${id}`}
                    icon
                  >
                    <EditIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
                {isCompanyManager && <ArchiveButton id={id} />}
              </Fragment>
              } 
            />
          </Datagrid>}
      />
    </List>
  )
}

export const RouteGroupList = enhance(_RouteGroupList)

///////////////////////////////////////////////////////////////////////////////
// Create
const _RouteGroupCreate = props => (
  <Create title={<EditTitle resource={props.resource} />} {...props}>
    <SimpleForm
      validate={validate}
    >
      <TextInput source="name" validate={validateName(MIN, MAX)} fullWidth />
      <ReferenceInput
        reference="routegrouptypes"
        fullWidth
        defaultValue="00BUS"
        source="type"
      >
        <SelectInput optionText="name" />
      </ReferenceInput>

      <CheckboxInput
        defaultValue="create"
        source="subroute"
        label={props.translate('resources.routegroups.createSubRoute')}
      />
    </SimpleForm>
  </Create>
)

export const RouteGroupCreate = compose(translate)(_RouteGroupCreate)

const RouteStepper = withRouter(({
  record,
  translate,
  routeGroupId,
  history,
  showAlert,
  planningDone,
  companyManager,
}) => {
  if (!record) return null
  let { stops = [], patterns = [] } = record
  return (
    <Stepper nonLinear>
      <Step>
        <StepButton
          completed={true}
          onClick={() => {
            if (!companyManager) return
            history.push(`/routes/${record.id}`)
          }}
        >
          {translate('resources.routes.edit')}
        </StepButton>
      </Step>
      <Step>
        <StepButton
          completed={stops.length >= 2}
          onClick={() => {
            if (!companyManager) return
            history.push(`/networks/${record.id}/planning`)}
          }
        >
          {translate('resources.routes.network_plan')}
        </StepButton>
      </Step>
      <Step>
        <StepButton
          completed={patterns.length > 0}
          onClick={async () => {
            if (!companyManager) return
            if (!planningDone) {
              showAlert({
                title: translate('resources.common.warning'),
                content: translate('resources.routes.no_stop_need_design'),
              })
              return
            }
            // TODO: check stops is object before create
            await Provider.dataProvider('REMOTE', 'routegroups', {
              method: 'label',
              requestMethod: 'GET',
              data: { id: parseInt(routeGroupId) },
            })
            history.push(`/routepatterns/create?routeId=${record.id}&routeGroupId=${routeGroupId}`)
          }}
        >
          {translate('resources.routes.create_pattern')}
        </StepButton>
      </Step>
    </Stepper>
  )
})

const RouteCards = withStyles(styles)(({
  id,
  ids = [],
  data,
  classes,
  translate,
  onLoad,
  showAlert,
  companyManager,
  loadedOnce,
}) => {
  if (!loadedOnce) {
    return <div className={classes.progress}><CircularProgress /></div>
  }
  let networkplanningCount = 0
  let patternCount = 0
  let routeCount = ids.length
  let planningDone = false
  ids.forEach(id => {
    let route = data[id] || {}
    let { patterns, stops } = route
    if (patterns) {
      patternCount += patterns.length
    }
    if (stops) {
      planningDone = stops.length !== 0
      networkplanningCount = stops.length !== 0 ? networkplanningCount + 1 : networkplanningCount
    }
  })
  if (onLoad && data) {
    onLoad({ patternCount })
  }
  return (
    <ExpansionPanel defaultExpanded={true}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            {ids.length > 0 ? (
              <Fragment>{translate('resources.routes.total_route', { count: ids.length, smart_count: ids.length > 1 ? 2 : 1 })}</Fragment>
            ) : (
              <Fragment>{translate('resources.routes.no_route')}</Fragment>
            )}
          </Typography>
          {companyManager && <IconButton
            icon={faPlus}
            color="inherit"
            component={Link}
            to={`/routes/create?routeGroupId=${id}`}
          >
            {translate('resources.routes.add_route')}
          </IconButton>}
        </Toolbar>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails
        className={classes.expansion_detail}
        classes={{ root: classes.panelDetailRoot }}
      >
        <Grid container>
          <Grid item xs={12}>
            {networkplanningCount < 2 && <Snackbar
              variant="error"
              message={translate('resources.routes.network_plan_not_enough')}
              className={classes.snackbar}
            />}
            {routeCount === 0 && <Snackbar
              variant="error"
              message={translate('resources.routes.no_route_need_create')}
              className={classes.snackbar}
            />}
            {routeCount === 1 && <Snackbar
              variant="warning"
              message={translate('resources.routes.should_two_way')}
              className={classes.snackbar}
            />}
            {patternCount === 0 && <Snackbar
              variant="error"
              message={translate('resources.routes.no_pattern_need_create')}
              className={classes.snackbar}
            />}
          </Grid>
          {!loadedOnce && <div><CircularProgress /></div>}
          {ids.map(rid => {
            let record = data[rid]
            if (!record) return null
            let { stops = [], patterns = [] } = record
            return (
              <Grid key={rid} item xs={12} sm={6}>
                <Card>
                  <CardHeader
                    title={record.name}
                    subheader={record.number}
                    avatar={<Avatar classes={{ root: classes.avatarRoot }} style={{ backgroundColor: record.color}}><FontAwesomeIcon icon={faRoute} /></Avatar>}
                    classes={{
                      title: classes.cardHeaderTitle
                    }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="subtitle1" component="h2">
                      {translate('resources.routes.count_stop', { count: stops.length, smart_count: stops.length > 1 ? 2 : 1 })}
                    </Typography>
                    <Typography gutterBottom variant="subtitle1" component="h2">
                      {
                        patterns.length === 0
                          ? translate('resources.routes.count_pattern', { count: patterns.length, smart_count: patterns.length > 1 ? 2 : 1 })
                          : <Link
                            to={`/routepatterns?filter=${encodeURI(JSON.stringify({ routeId: rid }))}`}
                          >
                            {translate('resources.routes.count_pattern', { count: patterns.length, smart_count: patterns.length > 1 ? 2 : 1 })}
                          </Link>
                      }
                    </Typography>
                  </CardContent>
                  <RouteStepper
                    translate={translate}
                    record={record}
                    routeGroupId={id}
                    showAlert={showAlert}
                    planningDone={planningDone}
                    companyManager={companyManager}
                  />
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
})

const TimetableItems = translate(({ translate, ids = [], data, onClick }) => (
  <Fragment>
    <MUIList>
      <ListItem>
        <Typography component="h2" variant="title">
          {translate('resources.schedules.select_schedule_to_create_trip')}
        </Typography>
      </ListItem>
      <Divider />
      {ids.map(id => {
        let timetable = data[id]
        return (
          <Fragment key={id}>
            <ListItem button onClick={() => onClick(id)}>
              <Avatar>+</Avatar>
              <ListItemText
                primary={timetable.name}
                secondary={timetable.service && timetable.service.name}
              />
            </ListItem>
            <Divider />
          </Fragment>
        )
      })}
    </MUIList>
  </Fragment>
))

class _TimetableStepper extends Component {
  state = {
    showSchedule: false
  }
  render() {
    let { record, tripCount, translate, routeGroupId, history, showAlert, companyManager } = this.props
    return (
      <Fragment>
        <Popover
          anchorEl={this.state.anchorEl}
          open={this.state.showSchedule}
          onClose={() => {
            this.setState({ anchorEl: null, showSchedule: false })
          }}
        >
          <ReferenceManyField
            reference="schedules"
            basePath=""
            record={{}}
            target=""
            filter={{ timetableGroupId: record.id, '../include': ['service'] }}
          >
            <TimetableItems onClick={id => {
              this.setState({ anchorEl: null, showSchedule: false })
              history.push(`/schedules/createtrip?scheduleId=${id}`)
            }} />
          </ReferenceManyField>
        </Popover>
        <Stepper nonLinear>
          <Step>
            <StepButton
              completed
              onClick={() => {
                if (!companyManager) return
                history.push(`/timetablegroups/${record.id}?routeGroupId=${routeGroupId}`)
              }}
            >
              {translate('resources.routes.edit_timetable')}
            </StepButton>
          </Step>
          <Step>
            <StepButton
              completed={record.countSchedule > 0}
              onClick={() => {
                if (!companyManager) return
                if (tripCount === 0) {
                  showAlert({
                    title: translate('resources.common.warning'),
                    content: translate('resources.routes.no_trip_need_create'),
                  })
                  return
                }
                history.push(`/schedules/create?timetableGroupId=${record.id}`)
              }}
            >
              {translate('resources.routes.create_schedule')}
            </StepButton>
          </Step>
          <Step>
            <StepButton
              onClick={(evt) => {
                if (!companyManager) return
                if (record.countSchedule === 0) {
                  showAlert({
                    title: translate('resources.common.warning'),
                    content: translate('resources.routes.no_schedule_need_create'),
                  })
                  return
                }
                this.setState({ anchorEl: evt.target, showSchedule: true })
              }}
            >
              {translate('resources.routes.create_trips')}
            </StepButton>
          </Step>
        </Stepper>
      </Fragment>
    )
  }
}

const TimetableStepper = withRouter(_TimetableStepper)

const TimetableCards = withStyles(styles)(({
  id,
  ids = [],
  data,
  patternCount = -1,
  classes,
  translate,
  showAlert,
  companyManager,
  loadedOnce,
}) => {
  if (!loadedOnce) {
    return <div className={classes.progress}><CircularProgress /></div>
  }
  return (
    <ExpansionPanel defaultExpanded={true} disabled={patternCount <= 0}>
      <ExpansionPanelSummary expandIcon={<ExpandMore />}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit">
            {ids.length > 0 ? (
              <Fragment>{translate('resources.routes.total_timetable', { count: ids.length, smart_count: ids.length > 1 ? 2 : 1 })}</Fragment>
            ) : (
              <Fragment>{translate('resources.routes.no_timetable')}</Fragment>
            )}
          </Typography>
          {companyManager && <IconButton
            icon={faPlus}
            color="inherit"
            component={Link}
            to={`/timetablegroups/create?routeGroupId=${id}`}
          >
            {translate('resources.routes.add_timetable')}
          </IconButton>}
        </Toolbar>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails className={classes.expansion_detail} classes={{ root: classes.panelDetailRoot }}>
        <Grid container>
          <Grid item xs={12}>
            {
              ids.length === 0 &&
              <Snackbar
                variant="error"
                message={translate('resources.routes.no_timetable_need_create')}
                className={classes.snackbar}
              />
            }
          </Grid>
          {ids.map(rid => {
            let timetable = data[rid]
            if (!timetable || !timetable.data) return null
            let { data: timetableData, countSchedule } = timetable
            let tripCount = 0
            timetableData.forEach(function (row) {
              if (row.data && row.data.rows) {
                tripCount += row.data.rows.length - 1
              }
            })
            return (
              <Grid key={rid} item xs={12} sm={6}>
                <Card>
                  <CardHeader
                    title={timetable.name}
                    avatar={<Avatar classes={{ root: classes.avatarRoot }}><FontAwesomeIcon icon={faCalendar} /></Avatar>}
                    classes={{
                      title: classes.cardHeaderTitle
                    }}
                  />
                  <CardContent>
                    <Typography variant="subtitle1">
                      {translate('resources.routes.count_pattern', { count: timetableData.length, smart_count: timetableData.length > 1 ? 2 : 1 })}
                    </Typography>
                    <Typography variant="subtitle1">
                      {translate('resources.routes.count_trip', { count: tripCount, smart_count: tripCount > 1 ? 2 : 1 })}
                    </Typography>
                    <Typography variant="subtitle1">
                      {
                        countSchedule === 0
                          ? translate('resources.routes.count_schedule', { count: countSchedule, smart_count: countSchedule > 1 ? 2 : 1 })
                          : <Link
                            to={`/schedules?filter=${encodeURI(JSON.stringify({ timetableGroupId: rid }))}`}
                          >
                            {translate('resources.routes.count_schedule', { count: countSchedule, smart_count: countSchedule > 1 ? 2 : 1 })}
                          </Link>
                      }
                    </Typography>
                  </CardContent>
                  <TimetableStepper
                    tripCount={tripCount}
                    translate={translate}
                    record={timetable}
                    routeGroupId={id}
                    showAlert={showAlert}
                    companyManager={companyManager}
                  />
                </Card>
              </Grid>
            )
          })}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
})

class _RouteGroupEdit extends Component {
  state = {
    patternCount: -1,
  }
  alert = createRef()

  showAlert = ({ title, content, onOk, onCancel }) => {
    if (this.alert.current) {
      this.alert.current.open({ title, content, onOk, onCancel })
    }
  }

  render() {
    const { translate, resource, id, classes, permissions, ...props } = this.props
    let { patternCount } = this.state
    let companyManager = permission.isCompanyManager(permissions)
    return (
      <Edit
        id={id}
        resource={resource}
        title={<EditTitle resource={resource} />}
        undoable={false}
        {...props}
      >
        <SimpleForm
          toolbar={companyManager ? <CustomToolbar /> : null}
          validate={validate}
        >
          <AlertDialog forwardRef={this.alert} />
          <Grid container fullWidth>
            <Grid item md={6} xs={12}>
              <TextInput
                resource="routegroups"
                source="name"
                validate={validateName(MIN, MAX)}
                fullWidth
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <ReferenceInput
                reference="routegrouptypes"
                source="type"
                fullWidth
                disabled
                resource="routegroups"
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
          </Grid>
          <ReferenceManyField
            basePath="/"
            reference="routes"
            target="routeGroupId"
            filter={{
              '../fields': [
                'id',
                'number',
                'name',
                'longName',
                'originId',
                'destinationId',
                'desc',
                'type',
                'stops',
                'numberOfLeg',
                'distance',
                'duration',
                'numberOfPrivateSeat',
                'color',
                'routeGroupId'
              ],
              '../include': {
                relation: 'patterns',
                scope: {
                  fields: 'id'
                }
              },
            }}
            addLabel={false}
          >
            <RouteCards
              companyManager={companyManager}
              id={id}
              classes={classes}
              translate={translate}
              onLoad={({ patternCount }) => {
                if (this.state.patternCount !== patternCount)
                  this.setState({ patternCount })
              }}
              showAlert={this.showAlert}
            />
          </ReferenceManyField>
          <ReferenceManyField
            basePath="/"
            reference="timetablegroups"
            target="routeGroupId"
            filter={{ countSchedule: 1 }}
            addLabel={false}
          >
            <TimetableCards
              companyManager={companyManager}
              id={id}
              classes={classes}
              translate={translate}
              patternCount={patternCount}
              showAlert={this.showAlert}
            />
          </ReferenceManyField>
        </SimpleForm>
      </Edit>
    )
  }
}

export const RouteGroupEdit = enhance(_RouteGroupEdit)
