import React, { Component } from 'react'
import {
  translate,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  DateField,
  FunctionField
} from 'react-admin'
import {
  withStyles,
  Typography,
  Tooltip,
  Chip,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import classnames from 'classnames'
import LinkField from './../common/LinkField'
import { red, green, blue } from '@material-ui/core/colors'

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing.unit * 3
    },
    bold: {
      fontWeight: 'bold'
    },
    money: {
      textAlign: 'right',
      fontSize: 18
    },
  }
}

const fieldStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  '10PAID': { color: 'white', backgroundColor: green[500] },
  '30FINISH': { color: 'white', backgroundColor: blue[500] },
  '20CANCEL': { color: 'white', backgroundColor: red[500] },
}

const chargeStatusTranslate = {
  '00NOT_PAID': 'Chưa thanh toán',
  '10PAID': 'Đã thanh toán',
  '30FINISH': 'Đã hoàn thành',
  '20CANCEL': 'Đã huỷ',
}

class TripCharge extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = async () => {
    let { record } = this.props
    let { id } = record
    let response = await Provider.dataProvider('REMOTE', 'charges', {
      method: '',
      requestMethod: 'GET',
      data: {
        filter: {
          where: {
            tripId: id,
            cancelReference: null,
            status: { neq: '20CANCEL' }
          },
        }
      }
    })

    let rawDatum = response ? response.data : []
    let totalEl = rawDatum.length
    let ids = []
    let charges = {}
    let total = 0

    for (let i = 0; i < totalEl; i++) {
      let charge = rawDatum[i]
      ids.push(charge.id)
      charges[`${charge.id}`] = charge
      total += charge.amount
    }

    this.setState({ ids, charges, total })
  }

  render() {
    let { classes, translate } = this.props
    let { ids, charges, total } = this.state

    return (
      <div className={classes.root}>
        <Datagrid
          data={charges}
          ids={ids}
          currentSort={{ field: 'id', order: 'desc' }}
        >
          <DateField
            label={translate('resources.charges.fields.createdAt')}
            source="createdAt"
            locales="vi-VN"
            showTime />

          <FunctionField
            label={translate('resources.charges.fields.reservationId')}
            source="reservationId"
            render={record => {
              return (
                <>
                  <LinkField
                    classes={classes}
                    path={`/reservations/${record.reservationId}/show`}>
                    {record.reservationCode}
                  </LinkField>
                </>
              )
            }}
          />

          <ReferenceField
            label={translate('resources.charges.fields.productId')}
            source="productId"
            reference="products"
            basePath="/products"
            linkType={false}>
            <TextField source="name" />
          </ReferenceField>

          {/* <ChipField
            label={translate('resources.charges.fields.itemDetail.itemCode')}
            source="itemDetail.itemCode"
          /> */}

          <FunctionField
            label={translate('resources.charges.fields.reservationId')}
            source="reservationId"
            render={charge => {
              let { status, itemDetail } = charge
              let style = fieldStyles[status]

              return (
                <Tooltip title={chargeStatusTranslate[status]}
                  style={{ position: 'absolute', right: 32, top: -12 }}
                >
                  <Chip label={itemDetail ? itemDetail.itemCode : '-'} style={style} />
                </Tooltip>
              )
            }}
          />

          <NumberField
            label={translate('resources.charges.fields.amount')}
            source="amount"
            locales="vi-VN"
            options={{ style: 'currency', currency: 'VND' }}
            className={classnames(classes.money)}
          />
        </Datagrid>

        <br />
        <Typography component="h2" variant="headline" gutterBottom align="right">
          {translate('resources.transactions.fields.total')}: &nbsp;
          <b>{total && total.toLocaleString()}</b>
        </Typography>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate,
  withStyles(styles),
)

export default enhance(TripCharge)
