import React from 'react'
import { green, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core'
import { ChipField } from 'react-admin'
import get from 'lodash/get'

export const INACTIVE = '00IAT'
export const ACTIVE = '10ACT'

export const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500]},
  [ACTIVE]: { color: 'white', backgroundColor: green[500]},
  [INACTIVE]: { color: 'white', backgroundColor: 'rgb(204,204,204)'},
}

////////////////////////////////////////////////////////////////////////////////Vehicle
export const StatusField = withStyles(statusStyles)(({ classes, ...props }) => {
  let status = get(props.record, 'id')
  let className = get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})

