import { Provider } from './index'

const GET_LIST_PAGINATION = {
  sort: {},
  pagination: { page: 1, perPage: 100 },
}

async function getFareConditions() {
  let response = await Provider.dataProvider('GET_LIST', 'fareConditions', {
    filter: { where: {} },
    ...GET_LIST_PAGINATION,
  })
  if (response && response.data) {
    return response.data
  }
}

async function getFareTable({ routeId, departureTime }) {
  try {
    let response = await Provider.dataProvider('REMOTE', 'faretables', {
      method: 'getByRoute',
      data: { routeId, date: departureTime },
    })
    // .catch(err => console.log(err))
    // console.log('response', response)
    if (response) return response.data
  } catch(e) {
    console.log('error', e)
  }
}

export {
  GET_LIST_PAGINATION,
  getFareConditions,  
  getFareTable,
}
