import React, { Component, Fragment } from 'react'
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  withStyles,
  Tooltip,
} from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { red } from '@material-ui/core/colors'
import RemoveIcon from '@material-ui/icons/Remove'

const style = {
  iconButton: {
    border: `1px solid ${red[600]}`,
    color: red[600],
    '&:hover': {
      backgroundColor: red[100] 
    },
    borderRadius: 4,
    padding: 0,
    width: 32,
    height: 32,
  },
}

const confirmDialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

class _ConfirmDialog extends Component {
  render() {
    let {
      open,
      onClose,
      translate,
      onSave,
      classes,
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle className={classes.title}>
          <span className={classes.textTitle}>{translate('button.unregister_agency')}</span>
        </DialogTitle>
        <DialogContent>
          {translate('notification.warning_unregister_fare')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onSave}
            color="primary"
            autoFocus
            variant="contained"
          >
            {translate('button.ok')}
          </Button>
          <Button onClick={onClose}>
            {translate('button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const enhanceConfirmDialog = compose(withStyles(confirmDialogStyle), translate)
export const ConfirmDialog = enhanceConfirmDialog(_ConfirmDialog)

class UnRegisterAgencyButton extends Component {

  state = {
    open: false,
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  onSave = () => {
    let { record, showNotification, handleDeleteDone } = this.props
    Provider.dataProvider('REMOTE', 'agencyproducts', {
      method: 'removeAgency',
      requestMethod: 'POST',
      data: { id: record.id },
    }).then(() => {
      showNotification('notification.unregister_fare_success')
      this.closeDialog()
      handleDeleteDone(record.id)
    }).catch(() => {
      showNotification('notification.unregister_fare_failure', 'warning')
      this.closeDialog()
    })
  }

  render() {
    let { translate, button, classes } = this.props
    let { open } = this.state
    return <Fragment>
      { button ? <Button
        color='primary'
        onClick={this.openDialog}
      >
        {translate('button.unregister_agency')}
      </Button> : <Tooltip title={translate('button.unregister_agency')}> 
        <IconButton
          onClick={this.openDialog}
          variant="outlined"
          className={classes.iconButton}
          size="small"
        >
          <RemoveIcon fontSize="small" />
        </IconButton> 
      </Tooltip>}
      <ConfirmDialog
        open={open}
        onClose={this.closeDialog}
        onSave={this.onSave}
      />
    </Fragment>
  }
}

const enhance = compose(translate, withStyles(style), connect(null, { showNotification, refreshView }))

export default enhance(UnRegisterAgencyButton)
