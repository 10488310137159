import React, { Component, Fragment } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Edit,
  FunctionField,
  translate,
  Filter,
  TextInput,
  Show,
} from 'react-admin'
import { EditTitle } from '../common/Title'
import { DateTimePickerInput } from '../common/DateTimePicker'
import moment from 'moment'
import FormSave from './FormSave'
import FormView from './FormView'
import { withStyles, Tooltip } from '@material-ui/core'
import LinkField from './../common/LinkField'
import compose from 'recompose/compose'
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon ,
  Print as PrintIcon 
} from '@material-ui/icons'
import CustomToolbar from '../common/CustomToolbarForm'
import { Provider } from '../provider'
import printJS from 'print-js'
import _ from 'lodash'
import DialogLoading from '../common/DialogLoading'
//import { FuzzySelectInput, defaultFuzzySearch, FuzzySelectArrayInput, } from '../common/react-fuzzy-picker'

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
  },
  otherStatus: {
    marginBottom: 8,
    marginTop: 16,
    minWidth: 100,
  },
  fuzzySearch: {
    minWidth: 300,
  },
})

class _MemberCardListFilter extends Component {
  state = {
    segments: [],
  }

  onChangeRoute = (e, value) => {
    Provider.dataProvider('REMOTE', 'farecompaniondetails', {
      method: 'getSegments',
      requestMethod: 'GET',
      data: {
        routeProductId: value,
      },
    }).then(res => {
      let data = _.get(res, 'data', [])
      let segments = _.reduce(data, (result, item) => {
        let { origin, destination, fareIndexId, id } = item
        let segment = {
          id,
          name: `${_.get(origin, 'name', '')} - ${_.get(destination, 'name', '')}`,
          fareIndexId
        }
        result.push(segment)
        return result
      }, [])
      this.setState({ segments })
    }).catch(err => console.log('=====[onChangRoute]: err', err))
    this.setState({ routeProductId: value })
  }

  validate = values => {
    const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
    if (values.createdAt_lte < minDate) {
      values.startAt_lte = minDate
    }
    if (this.state.fromDate !== minDate) {
      this.setState({ fromDate: minDate })
    }
  }

  render() {
    let { classes, translate, ...props } = this.props
    let { fromDate, routeProductId, segments } = this.state
    return <Filter
      validate={this.validate}
      {...props}
    >
      <DateTimePickerInput
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <DateTimePickerInput
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        minDate={fromDate}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <TextInput
        source="code"
        alwaysOn
      />
    </Filter>
  }
}

//<ReferenceInput
//source="routeId"
//reference="routes"
//filter={{ status: '10ACTIVE' }}
//sort={{ field: 'id', order: 'ASC' }}
//alwaysOn
//fullWidth
//allowEmpty
//label="resources.reservations.fields.routeId"
//classExtra={classes.fuzzySearch}
//onChange={this.onChangeRoute}
//>
//<FuzzySelectInput
//optionText="name"
//renderItem={ ({ name }) => name } {...defaultFuzzySearch({ name: 'name' })}
///>
//</ReferenceInput>
//<FuzzySelectArrayInput
//disabled={_.isEmpty(segments) || !routeProductId}
//items={segments}
//renderItem={ ({ name }) => name } {...defaultFuzzySearch({ name: 'name' })}
//label={translate('resources.saletickets.segment')}
//source="segmentId"
//alwaysOn
//classExtra={classes.fuzzySearch}
///>
const enhanceListFilter = compose(translate, withStyles(styleListFilter))
const MemberCardListFilter = enhanceListFilter(_MemberCardListFilter)


////////////////////////////////////////////////////////////////////////////////
// MemberCard
const styleList = () => ({
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  avatarCol: {
    display: 'flex'
  },
  name: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
})

// List
class _MemberCardList extends Component  {

  state = { loading: false }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  handlePrint = async (e, id) => {
    e.preventDefault()
    e.stopPropagation()
    this.startLoading()
    let resp = await Provider.dataProvider('REMOTE', 'membercards', {
      method: `print/${id}`,
      requestMethod: 'GET'
    })
    if (resp && resp.data) {
      printJS({ printable: resp.data, type: 'pdf', base64: true })
      this.endLoading()
    }
  }

  render () {
    let { translate, classes, history, permissions, ...props } = this.props
    let { loading } = this.state
    return <Fragment>
      <List
        filters={<MemberCardListFilter />}
        bulkActionButtons={false}
        history={history}
        filter={{
          '../order': 'createdAt DESC',
          '../include': [
            {
              relation: 'customer',
              scope: {
                fields: ['id', 'fullName', 'phone', 'email']
              }
            },
            {
              relation: 'reservations',
              scope: {
                where: {
                  status: '20CONFIRMED',
                },
                order: 'id DESC',
                limit: 1
              }
            },
            {
              relation: 'fare',
              scope: {
                fields: ['id', 'name', 'routeId'],
                include: [
                  {
                    relation: 'route',
                    scope: {
                      fields: ['id', 'name']
                    },
                  },
                ],
              }
            },
            {
              relation: 'stop',
              scope: {
                fields: ['id', 'originId', 'destinationId', 'name']
              },
            },
          ]
        }}
        filterDefaultValues={{
          createdAt_gte: moment().subtract(7, 'days').startOf('days').toDate().toISOString(),
          createdAt_lte: moment().add(7, 'days').endOf('days').toDate().toISOString(),
        }}
        {...props}
      >
        <Datagrid>
          <TextField source="code"/>
          <FunctionField
            source="createdAt"
            render={({ createdAt }) => moment(createdAt).format('HH:mm DD/MM/YYYY')}
          />
          <FunctionField
            source="segmentName"
            render={({ itemDetail }) => _.get(itemDetail, 'segmentName','')}
          />
          <FunctionField
            source="routeName"
            render={({ fare }) => _.get(fare, 'route.name', '')}
          />
          <FunctionField
            source="customerName"
            render={({ customer }) => _.get(customer, 'fullName', '')}
          />
          <FunctionField
            source="customerPhone"
            render={({ customer }) => _.get(customer, 'phone', '')}
          />
          <FunctionField
            source="customerEmail"
            render={({ customer }) => _.get(customer, 'email', '')}
          />
          <FunctionField
            label="resources.saletickets.receive_member_card_place"
            source="stopId"
            render={({ stop }) => _.get(stop, 'name', '')}
          />
          <FunctionField
            headerClassName={classes.textEnd}
            cellClassName={classes.textEnd}
            render={({ id }) => {
              return <Fragment>
                <Tooltip
                  title={translate('button.view_detail')}
                  enterDelay={100}
                >
                  <LinkField
                    className={classes.iconButton}
                    path={`/membercards/${id}/show`}
                    icon
                  >
                    <VisibilityIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
                <Tooltip
                  title={translate('button.edit')}
                  enterDelay={100}
                >
                  <LinkField
                    className={classes.iconButton}
                    path={`/membercards/${id}`}
                    icon
                  >
                    <EditIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
                <Tooltip
                  title={translate('button.print')}
                  enterDelay={100}
                  onClick={(e) => this.handlePrint(e, id)}
                >
                  <LinkField
                    className={classes.iconButton}
                    icon
                  >
                    <PrintIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
              </Fragment>
            }}
          />
        </Datagrid>
      </List>
      {loading && <DialogLoading open={loading} />}
    </Fragment>
  }
}

export const MemberCardShow = (props) => {
  let { history } = props
  return (
    <Show
      {...props}
      title={<EditTitle
        resource={props.resource}
        render={(record) => record.code}
      />}
      actions={false}
      undoable={false}
    >
      <FormView history={history} />
    </Show>
  )
}

export const MemberCardEdit = (props) => {
  let { history } = props
  return (
    <Edit
      {...props}
      title={<EditTitle
        resource={props.resource}
        render={(record) => record.code}
      />}
      actions={false}
      undoable={false}
    >
      <FormSave
        history={history}
        toolbar={<CustomToolbar />}
      />
    </Edit>
  )
}

const enhanceList = compose(withStyles(styleList), translate)
export const MemberCardList = enhanceList(_MemberCardList)

