import React, { Component } from 'react'
import {
  Typography,
  Toolbar,
  withStyles,
  IconButton,
  withWidth,
  AppBar as MuiAppBar,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { toggleSidebar as toggleSidebarAction } from 'ra-core'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
  LoadingIndicator,
  translate,
  WithPermissions,
} from 'react-admin'
import UserMenu from './UserMenu'
import { sanitize } from '../utils/commonUtil'
import Language from './Language'
import ParingButton from './ParingButton'
import { setWidthAppBar as setWidthAppBarAction } from './action'
import { withSize } from 'react-sizeme'
import { withRouter } from 'react-router-dom'
import AppBarChips from './AppBarChips'
import ReservationSearchInput from './ReservationSearchInput'
import clsx from 'clsx'

const _AppBarRightContainer = ({
  classes,
  setWidthAppBarAction,
  size,
  logout,
  width,
}) => {
  setWidthAppBarAction(size.width)
  let isXSmall = width === 'xs'
  return <div className={classes.toolbarRightContainer}>
    <div className={classes.toolbarRight}>
      <Typography
        variant="h6"
        color="inherit"
        className={classes.title}
        id="react-admin-title"
      />
      <div className={classes.headerContent}>&nbsp;</div>
      <ReservationSearchInput isXSmall={isXSmall} />
      <AppBarChips
        width={width}
        isXSmall={isXSmall}
      />
      <Language />
      {!isXSmall && <ParingButton />}
      {!isXSmall && <LoadingIndicator />}
      {!isXSmall && <UserMenu logout={logout} />}
    </div>
  </div>
}

const enhanceAppBarRightContainer = compose(
  translate,
  withWidth(),
  withSize(),
  withRouter,
  connect(null, { setWidthAppBarAction }),
)
const AppBarRightContainer = enhanceAppBarRightContainer(_AppBarRightContainer)

const styles = theme => {
  return ({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      zIndex: 1500,
      backgroundColor: 'transparent',
    },
    toolbar: {
      backgroundColor: '#303f9f'
    },
    menuButton: {
      right: 4
    },
    menuButtonIconClosed: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(0deg)',
    },
    menuButtonIconOpen: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      transform: 'rotate(180deg)',
    },
    toolbarLeftContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#121c57',
    },
    toolbarLeftContainerOpen: {
      [theme.breakpoints.up('sm')] : {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#121c57',
      },
      [theme.breakpoints.down('sm')] : {
        display: 'none',
      }
    },
    toolbarLeft: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
    },
    toolbarRightContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100vw'
    },
    toolbarRight: {
      paddingLeft: theme.spacing.unit * 3,
      display: 'flex',
      flexDirection: 'row',
      position: 'relative',
      alignItems: 'center',
    },
    titleOpened: {
      display: 'block',
      overflow: 'hidden',
      marginRight: 3,
      marginLeft: 4,
      width: 185,
      textAlign: 'center',
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    titleClosed: {
      display: 'block',
      overflow: 'hidden',
      width: 0,
      marginRight: 3,
      marginLeft: 4,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    headerContent: {
      flex: 1,
      width: '100%'
    },
    loadingIndicator: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 1200,
      marginBottom: 14,
      marginTop: 14,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    logout: {
      color: theme.palette.secondary.contrastText,
    },
    dividerLeft: {
      backgroundColor: theme.palette.common.white,
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20
    },
    dividerRight: {
      backgroundColor: '#ccc',
      marginBottom: 10,
      marginLeft: 20,
      marginRight: 20
    },
  })
}

class AppBar extends Component {

  render() {
    let {
      classes,
      className,
      userMenu,
      logout,
      open,
      title,
      toggleSidebar,
      translate,
      permissions,
      match,
      ...rest
    } = this.props
    rest = sanitize(rest, ['currentPath'])
    return <div>
      <MuiAppBar
        className={classNames(classes.appBar, className)}
        elevation={0}
        position="fixed"
        {...rest}
      >
        <Toolbar disableGutters variant="dense" className={classes.toolbar}>
          <div
            className={clsx(classes.toolbarLeftContainer, {
              [classes.toolbarLeftContainerOpen]: open,
            })}
          >
            <div className={classes.toolbarLeft}>
              <Typography
                variant="h6"
                color="inherit"
                className={
                  open ? classes.titleOpened : classes.titleClosed
                }
              >
                {typeof title === 'string'
                  ? title
                  : React.cloneElement(title)}
              </Typography>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleSidebar}
                className={classNames(classes.menuButton)}
              >
                <MenuIcon
                  classes={{
                    root: open
                      ? classes.menuButtonIconOpen
                      : classes.menuButtonIconClosed,
                  }}
                />
              </IconButton>
            </div>
          </div>
          <AppBarRightContainer 
            classes={classes}
            logout={logout}
          />
        </Toolbar>
      </MuiAppBar>
    </div>
  }
}

AppBar.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
}

const enhance = compose(
  translate,
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(styles, { name: 'AppBar' }),
)

const AppBarWithPermissions = props => <WithPermissions
  render={({ permissions }) => <AppBar
    permissions={permissions}
    {...props}
  />}
/>

export default enhance(AppBarWithPermissions)
