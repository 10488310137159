import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  Edit,
  translate,
  showNotification,
  CardActions,
  Filter,
  ReferenceInput,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { resourceRequest } from '../action'
import { push } from 'react-router-redux'
import CreateUserButton from './CreateUserButton'
import ResetPasswordButton from './ResetPasswordButton'
import ChangePasswordButton from './ChangePasswordButton'
import { getCurrentUser, sanitize } from '../utils/commonUtil'
import { isAdmin } from '../utils/permission'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import SearchInput from '../common/SearchInput'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  chip: {
    marginRight: 4
  }
})

const mapStateFromProps = (state, ownProps) => {
  let { selectedIds } = ownProps
  let selected = {}
  let { devices } = state.admin.resources
  if (devices) {
    let { data = {} } = devices
    selected = data[selectedIds] || {}
  }
  return { selected }
}

const enhance = compose(
  connect(mapStateFromProps, { resourceRequest, showNotification, push }),
  withStyles(styles), translate,
)

const ListAction = ({ permissions }) => {
  let admin = isAdmin(permissions)
  return <CardActions>
    {admin && <CreateUserButton />}
  </CardActions>
}

const EditAction = ({ id, permissions }) => {
  let admin = isAdmin(permissions)
  let currentUser = getCurrentUser()
  id = typeof id === 'string' ? parseInt(id) : id
  let owner = currentUser.id === id
  return <CardActions>
    {admin && <ResetPasswordButton id={id} />}
    {owner && <ChangePasswordButton id={id} />}
  </CardActions>
}

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
    marginRight: 16,
  }
})

const UserListFilter = withStyles(styleListFilter)(({ classes, ...props }) => {
  return <Filter {...props}>
    <SearchInput helperText="resources.users.helper_text" source="q" alwaysOn />
    <ReferenceInput
      reference="agencies"
      source="agencyId"
      filter={{ '../fields': ['id', 'name'] }}
      className={classes.otherSearchInput}
      alwaysOn
    >
      <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
    </ReferenceInput>
    <ReferenceInput
      reference="companies"
      source="companyId"
      filter={{ '../fields': ['id', 'name'] }}
      className={classes.otherSearchInput}
      alwaysOn
    >
      <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
    </ReferenceInput>
  </Filter>
})

////////////////////////////////////////////////////////////////////////////////
// User
export const UserList = enhance(({ classes, permissions, ...props }) => (
  <List
    bulkActionButtons={false}
    actions={<ListAction permissions={permissions} />}
    filters={<UserListFilter />}
    {...sanitize(props, ['resourceRequest', 'showNotification'])}
  >
    <Datagrid rowClick="edit" >
      <TextField source="username" />
      <TextField source="fullName" />
      <TextField source="email" />
      <TextField source="phone" />
      <ReferenceField
        allowEmpty
        source="companyId"
        reference="companies"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField
        allowEmpty
        source="agencyId"
        reference="agencies"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
))


////////////////////////////////////////////////////////////////////////////////
// Edit
export const UserEdit = ({ id, record, location, basePath, permissions, ...props }) => {
  return <Edit
    id={id}
    record={record}
    basePath={basePath}
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} render={record => record.username} />}
    actions={<EditAction id={id} permissions={permissions} />}
  >
    <FormSave
      id={id}
      permissions={permissions}
      location={location}
      record={record}
    />
  </Edit>
}
