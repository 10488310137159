import React, { Component } from 'react'
import MqttContext from './MqttContext'
import getDisplayName from '../common/notistack/utils/getDisplayName'

function parse(message) {
  try {
    const item = JSON.parse(message)
    return item
  } catch (e) {
    return message.toString()
  }
}

function defaultDispatch(topic, message, packet) {
  const { state } = this
  const m = parse(message)
  const newData = [
    m,
    ...state.data
  ]
  this.setState({ data: newData })
}

class MQTTClient extends Component {

  constructor(props) {
    super(props)
    this.client = props.context.mqtt
    this.mqttEmitter = props.context.mqttEmitter
    this.state = {
      subcribe: false,
      data: [],
    }
  }

  componentDidMount() {
    this.subscribe()
  }

  componentWillUnmount() {
    let { eventListener } = this.props
    this.unsubscribe()
    this.mqttEmitter.removeEventListener(eventListener)
  }

  subscribe = (topic) => {
    topic = topic ? topic : this.props.topic
    if (!topic) return
    this.client && this.client.subscribe(topic)
    this.setState({ subscribed: true })
  }

  unsubscribe = (topic) => {
    topic = topic ? topic : this.props.topic
    if (!topic) return
    this.client && this.client.unsubscribe(topic)
    this.setState({ subscribed: false })
  }

  render() {
    return React.createElement(this.props.Component, {
      data: this.state.data,
      mqtt: this.client,
      mqttEmitter: this.mqttEmitter,
      subscribe: this.subscribe,
      unsubscribe: this.unsubscribe,
      ...this.props,
    })
  }
}

const withMQTT = (options = {}) => {
  let { topic, dispatch, eventListener } = options
  dispatch = defaultDispatch
  return (TargetComponent) => {
    const WrappedComponent = React.forwardRef((props, ref) => (
      <MqttContext.Consumer>
        {context => {
          return context && context.mqtt && <MQTTClient
            context={context}
            dispatch={dispatch}
            topic={topic}
            eventListener={eventListener}
            ref={ref}
            Component={TargetComponent}
            {...props}
          />
        }}
      </MqttContext.Consumer>
    ))
    if (process.env.NODE_ENV !== 'production') {
      WrappedComponent.displayName = `withMQTT(${getDisplayName(TargetComponent)})`
    }
    return WrappedComponent
  }
}

export default withMQTT
