import React, { Component } from 'react'
import {
  Datagrid,
  Filter,
  List,
  FunctionField,
  SimpleForm,
  TextInput,
  LongTextInput,
  TextField,
  Edit,
  Create,
  translate,
  ReferenceInput,
  SelectInput,
  DateField,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceField,
  required,
  addField,
  WithPermissions,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import {
  withStyles,
  Card,
  CardContent,
  CardHeader,
  Grid,
  TextField as MTextField,
} from '@material-ui/core'
import RuleInput from './rule-input'
import compose from 'recompose/compose'
import CheckboxGroupInput from '../common/CheckboxGroupInput'
import { getCurrentCompany, getCurrentAgency } from '../utils/commonUtil'
import _ from 'lodash'
import { Provider } from '../provider'
import { MoneyInput } from '../common/MoneyInput'
import CustomToolbar from '../common/CustomToolbarForm'
import { EditTitle } from '../common/Title'
import { DatePickerInput } from '../common/DatePicker'
import { RRule } from 'rrule'
import { moment } from '../common/format'
import SearchInput from '../common/SearchInput'
import * as permission from '../utils/permission'
import { change as changeForm } from 'redux-form'
import { connect } from 'react-redux'

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}
////////////////////////////////////////////////////////////////////////////////
// List
const CampaignListFilter = (props) => {
  return (
    <Filter {...props}>
      <SearchInput helperText="resources.campaigns.helper_text" source="q" alwaysOn />
    </Filter>
  )
}

const timeRange = [
  { id: 1*60, name: '1 min' },
  { id: 5*60, name: '5 min' },
  { id: 10*60, name: '10 min' },
  { id: 15*60, name: '15 min' },
  { id: 30*60, name: '30 min' },
  { id: 60*60, name: '1 hour' },
  { id: 24*60*60, name: '1 day' },
  { id: 24*7*60*60, name: '1 week' },
  { id: 30*24*7*60*60, name: '1 month' },
]

export class _CampaignList extends Component {

  render() {
    let { classes, ...props } = this.props
    return (
      <List
        {...props}
        bulkActionButtons={false}
        filters={<CampaignListFilter />}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="description"
          />
          <ReferenceField 
            source="status"
            reference="campaignstatuses"
            linkType={false}
            allowEmpty
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="startTime"
            locales="vi-VN"
          />
          <DateField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="endTime"
            locales="vi-VN"
          />
          <FunctionField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="rules"
            render={(record) => {
              const { rules } = record
              if (!rules) return ''
              return <div>
                {rules.map(({ruleText}, index) => {
                  let rule = ruleText && RRule.fromString(ruleText)
                  return <p key={index}>{rule ? rule.toText() : ''}</p>
                })}
              </div>
            }} />
        </Datagrid>
      </List>
    )
  }
}
export const CampaignList = withStyles(styles)(_CampaignList)
////////////////////////////////////////////////////////////////////////////////
// Edit

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, { changeForm })
)

const _TextInputCustom = ({ input, meta, helperText, ...props }) => {
  let { error } = meta
  return <MTextField
    error={Boolean(error)}
    helperText={error ? error : helperText}
    value={input.value}
    onChange={(evt) => {
      let value = evt.target.value
      input.onChange(value)
    }}
    {...props}
  />
}

const TextInputCustom = addField(_TextInputCustom)

class BaseForm extends Component {
  state = {}

  async componentDidMount() {
    let currentCompany = getCurrentCompany() || {}
    let companyId = currentCompany.id
    let currentAgency = getCurrentAgency() || {}
    let agencyId = currentAgency.id
    if (companyId) {
      let companyAgencies = await Provider.dataProvider('GET_LIST', 'companyagencies', {
        filter: {
          where: { companyId },
          include: 'agency',
        },
        sort: {},
        pagination: { page: 1, perPage: 20 },
      })
      companyAgencies = companyAgencies ? companyAgencies.data : []
      let agencies = _.reduce(companyAgencies, (result, { agency }) => {
        let { id } = agency
        if (id !== parseInt(agencyId)) {
          result.push(agency)
        }
        return result
      }, [])
      this.setState({ agencies, companyId, agencyId })
    }
  }

  validate = (values, { translate }) => {
    let error = { promotion: {} }
    let { type, promotion = {}, startTime, endTime } = values
    let name = (values && values.name) && values.name.trim()
    if (!name || name.length === 0) {
      error.name = translate('error_messages.required.name')
    }
    let description = (values && values.description) && values.description.trim()
    if (!description || description.length === 0) {
      error.description = translate('error_messages.required.campaigns.description')
    }
    let regexNumberPercent = /^\d+%$/
    if (type === 'DISCOUNT' || type === 'VOUCHER') {
      promotion = promotion !== null ? promotion : {}
      let { discount, company, agency, totalLimit, platform } = promotion
      let discountIsPercent = regexNumberPercent.test(discount)
      discount = discountIsPercent ? parseFloat(!_.isEmpty(discount.substring(0, discount.length - 1)) ? discount.substring(0, discount.length - 1) : '0') : parseFloat(discount) 
      totalLimit = parseFloat(totalLimit)
      if (discount === 0 || isNaN(discount)) {
        error.promotion.discount = translate('error_messages.invalid.campaign.discount_must_be_more_than_zero')
      } else if (discountIsPercent && discount > 100) {
        error.promotion.discount = translate('error_messages.invalid.campaign.discount_must_be_less_than_100')
      } else if (!discountIsPercent && discount > totalLimit) {
        error.promotion.discount = translate('error_messages.invalid.campaign.discount_must_be_less_than_budget')
      }
      if (!discountIsPercent && totalLimit < discount) {
        error.promotion.totalLimit = translate('error_messages.invalid.campaign.budget_must_be_more_than_discount')
      }
      platform = platform ? parseInt(platform) : 0
      company = company ? parseInt(company) : 0
      agency = agency ? parseInt(agency) : 0
      let isValidCompany = (company >= 0 && company <= 100) && (company <= 100 - agency)
      if (!isValidCompany) {
        error.promotion.company = translate('error_messages.invalid.campaign.number_percent_must_be_between_0_an_100')
      }
      let isValidAgency = (agency >= 0 && agency <= 100) && (agency <= 100 - company)
      if (!isValidAgency) {
        error.promotion.agency = translate('error_messages.invalid.campaign.number_percent_must_be_between_0_an_100')
      }
      if (isValidAgency && isValidCompany) {
        platform = 100 - company - agency
        promotion.platform = platform
        values.promotion = promotion
        if (platform < 0 || platform > 100) {
          error.promotion.platform = translate('error_messages.invalid.campaign.number_percent_must_be_between_0_an_100')
        }
      }
    }
    startTime = moment(startTime).startOf('day')
    endTime = moment(endTime).endOf('day')
    //let minEndTime = moment().endOf('day') 
    //if (endTime.isBefore(minEndTime)) {
    //endTime = minEndTime
    //}
    if (endTime.isBefore(startTime)) {
      error.startTime = translate('resources.campaigns.fields.time_range_error')
      error.endTime = translate('resources.campaigns.fields.time_range_error')
    }
    //values.startTime = startTime
    //values.endTime = endTime
    return error
  }

  render() {
    let { translate, record, permissions, changeForm } = this.props
    let { agencies, companyId, agencyId } = this.state
    let isCompanyManager = permission.isCompanyManager(permissions)
    let isAdmin = permission.isAdmin(permissions)
    let { id } = record
    if (!id) {
      let companies = isCompanyManager && [companyId]
      let agencies = isCompanyManager && [agencyId]
      changeForm('record-form', 'companies', companies)
      changeForm('record-form', 'agencies', agencies)
    }
    return (
      <SimpleForm
        toolbar={<CustomToolbar />}
        validate={this.validate}
        {...this.props}
      >
        <TextInput
          source="name"
          fullWidth
          validate={required()}
        />
        <LongTextInput 
          source="description"
          fullWidth
          validate={required()}
        />
        <RichTextInput
          source="fullDescription"
          fullWidth
          validate={required()}
        />
        <DatePickerInput
          keyboard={true}
          source="startTime"
          fullWidth
        />
        <DatePickerInput
          keyboard={true}
          source="endTime"
          fullWidth
        />
        <ReferenceInput
          source="type"
          basePath="/campaigntypes"
          reference="campaigntypes"
          validate={required()}
          filter={{ id : { inq:  ['DISCOUNT', 'VOUCHER'] }} }
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <FormDataConsumer>
          {({ formData }) => {
            let { type }  = formData
            if (type === 'DISCOUNT' || type === 'VOUCHER') {
              return <Card>
                <CardHeader title={translate('resources.campaigns.promotion_info')} />
                <CardContent>
                  {type === 'VOUCHER' && <Grid container>
                    <Grid xs={4} item>
                      <TextInputCustom 
                        label={translate('resources.campaigns.fields.promotion.code')}
                        source="promotion.code"
                        fullWidth
                        required={true}
                        validate={required()}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <TextInputCustom 
                        label={translate('resources.campaigns.fields.promotion.countLimit')}
                        helperText={translate('resources.campaigns.promotion_countLimit_helperText')}
                        source="promotion.countLimit"
                        fullWidth
                        type="number"
                        required={true}
                        validate={required()}
                        inputProps={{
                          min: 0,
                          step: 1
                        }}
                      />
                    </Grid>
                    <Grid xs={4} item>
                      <SelectInput 
                        label={translate('resources.campaigns.fields.promotion.limitTime')}
                        helperText={translate('resources.campaigns.promotion_limitTime_helperText')}
                        source="promotion.limitTime"
                        fullWidth
                        validate={required()}
                        choices={timeRange}
                        style={{ marginTop: 0 }}
                      />
                    </Grid>
                  </Grid>}
                  <Grid container>
                    <Grid xs={6} item>
                      <TextInputCustom 
                        helperText={translate('resources.campaigns.discount_helperText')}
                        label={translate('resources.campaigns.fields.promotion.discount')}
                        source="promotion.discount"
                        fullWidth
                        validate={required()}
                        required={true}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextInputCustom 
                        helperText={translate('resources.campaigns.promotion_company_helperText')}
                        label={translate('resources.campaigns.fields.promotion.company')}
                        source="promotion.company"
                        fullWidth
                        type="number"
                        InputProps={{ 
                          endAdornment: '%',
                          inputProps: {
                            min: 0,
                            max: 100,
                            step: 1
                          }
                        }}
                        validate={required()}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={6} item>
                      <MoneyInput 
                        label="resources.campaigns.fields.promotion.discountMax"
                        source="promotion.discountMax"
                        fullWidth 
                        nonecss
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextInputCustom 
                        helperText={translate('resources.campaigns.promotion_agency_helperText')}
                        label={translate('resources.campaigns.fields.promotion.agency')}
                        source="promotion.agency"
                        fullWidth
                        type="number"
                        InputProps={{ 
                          endAdornment: '%',
                          inputProps: {
                            min: 0,
                            max: 100,
                            step: 1
                          }
                        }}
                        validate={required()}
                        required={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid xs={6} item>
                      <MoneyInput
                        label="resources.campaigns.fields.promotion.totalLimit"
                        source="promotion.totalLimit"
                        fullWidth
                        validate={required()}
                        nonecss
                        required={true}
                      />
                    </Grid>
                    <Grid xs={6} item>
                      <TextInputCustom 
                        helperText={translate('resources.campaigns.promotion_platform_helperText')}
                        label={translate('resources.campaigns.fields.promotion.platform')}
                        source="promotion.platform"
                        fullWidth
                        type="number"
                        InputProps={{ endAdornment: '%' }}
                        disabled
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            }
          }}
        </FormDataConsumer>
        <ReferenceInput
          source="status"
          basePath="/campaignstatuses"
          reference="campaignstatuses"
          validate={required()}
          defaultValue="10ACTIVE"
          fullWidth 
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        {isAdmin && <ReferenceArrayInput
          id="companies"
          source="companies"
          reference="companies"
          required
        >
          <CheckboxGroupInput direction="row" />
        </ReferenceArrayInput>}
        {isCompanyManager && <ReferenceArrayInput
          id="routes"
          source="routes"
          reference="routes"
          filter={{ status: { neq: '20ARCHIVED' } }}
          required
        >
          <CheckboxGroupInput direction="row" />
        </ReferenceArrayInput>}
        {agencies && <CheckboxGroupInput
          source="agencies"
          choices={agencies}
          required
        />}
        <ArrayInput source="rules" defaultValue={[]} fullWidth>
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, id, getSource }) => {
                let subRecord = _.get(formData, id)
                getSource('')
                if (!subRecord) return
                return (
                  <RuleInput source={id} />
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    )
  }
}

const FormWithPermission = ({...props}) => {
  return <WithPermissions
    render={({ permissions }) => <BaseForm permissions={permissions} {...props}/>}
  />
}

const Form = enhance(FormWithPermission)

////////////////////////////////////////////////////////////////////////////////
// create
export class CampaignCreate extends Component {
  render() {
    let { resource, ...props } = this.props
    return (
      <Create
        resource={resource}
        title={<EditTitle resource={resource} />}
        {...props}
      >
        <Form />
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export class CampaignEdit extends Component {
  render() {
    let { resource, ...props } = this.props
    return <Edit
      title={<EditTitle resource={resource} />} 
      undoable={false} 
      resource={resource}
      {...props}
    >
      <Form />
    </Edit>
  }
}
