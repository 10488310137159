import React, { Component } from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  ImageInput,
  ImageField,
} from 'react-admin'
import {
  withStyles,
  InputAdornment,
  Grid,
  Typography,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { Field } from 'redux-form'
import QRCodeVehicle from './QRCodeVehicle'
import Licences from './Licences'
import VehicleActivity from './VehicleActivity'
import { validateVehicleName } from './AddInfoStep'
import { Provider } from '../provider'
import _ from 'lodash'

const styles = () => ({
  root: {
    display: 'flex'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    padding: 16,
  },
  gridItem: {
    padding: 0
  },
  licence: {
    marginBottom: 32
  },
})

class VehicleInfomation extends Component {
  state = {
    recentMaintains: [],
    nextMaintains: [],
    oldTrips: [],
    nextTrips: [],
    tripAvailable: false
  }

  componentDidMount = async() => {
    const { record } = this.props
    let res = await Provider.dataProvider('GET_LIST', 'trips', {
      filter: {
        where: { vehicleId: record.id, status: '10ACT' },
        scope: {
          relation: 'route'
        }
      },
      sort: {},
      pagination: { page: 1, perPage: 1 },
    })
    if (!_.isEmpty(res.data)) {
      this.setState({ tripAvailable: true})
    } else {
      this.setState({ tripAvailable: false})
    }
  }
  render() {
    const { classes, resource, record, translate } = this.props
    const { tripAvailable } = this.state
    const extra = { resource, fullWidth: true }
    return (
      <div className={classes.root}>
        <div className={classes.card}>
          <Field name="plate" component={QRCodeVehicle} />
          <Grid container spacing={8}>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput source="plate" validate={validateVehicleName} {...extra} />
            </Grid>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput source="name" {...extra} />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput source="brand" {...extra} />
            </Grid>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput source="model" {...extra} />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput source="color" {...extra} />
            </Grid>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <ReferenceInput reference="fueltypes" source="fuel" {...extra}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput
                disabled={tripAvailable}
                source="capacity"
                InputProps={{
                  endAdornment: <InputAdornment position="end">chỗ</InputAdornment>
                }}
                {...extra}
              />
            </Grid>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <TextInput
                disabled={tripAvailable}
                source="seatAvailable"
                InputProps={{
                  endAdornment: <InputAdornment position="end">chỗ</InputAdornment>
                }}
                {...extra}
              />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <ReferenceInput reference="vehicletypes" source="type" {...extra}>
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
            <Grid className={classes.gridItem} item xs={6} md={6}>
              <ReferenceInput reference="vehiclestatuses" source="status" {...extra} >
                <SelectInput optionText="name"  />
              </ReferenceInput>
            </Grid>
          </Grid>
          <ImageInput
            source="images"
            accept="image/*"
            multiple
            {...extra}
          >
            <ImageField source="src" />
          </ImageInput>
        </div>
        <div className={classes.card}>
          <div className={classes.licence}>
            <Licences vehicle={record} />
          </div>
          <div className={classes.licence}>
            <Typography variant="h4" component="div">
              {translate('resources.vehicles.activity')}
            </Typography>
            <VehicleActivity record={record} />
          </div>
        </div>
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(VehicleInfomation)
