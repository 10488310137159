import React, { Component } from 'react'
import {
  translate,
  showNotification,
} from 'react-admin'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core'
import moment from 'moment'
import { connect } from 'react-redux'
import { shortDateTimeFormat } from '../common/format'
import { Provider } from '../provider'
import _ from 'lodash'
import {
  DriverChip,
  AssistantDriverChip,
  DriverMenu,
  AssistantDriverMenu,
  VehicleChip,
  VehicleMenu,
} from '../trip/ActionDialog'
import { upsertGroupAvatar as upsertGroupAvatarAction } from './action'

const moreInfoStyle = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    padding: 12,
  },
  label: {
    width: '15%',
    alignSelf: 'center',
    fontSize: 13,
    fontWeight: 'bold',
    marginRight: 32,
  },
  text: {
    fontSize: 13,
    fontWeight: 500,
  },
  chip: {
    marginRight: 32,
  },
  saleDateContainer: {
    display: 'flex',
  },
  saleDate: {
    display: 'flex',
    margin: 'auto',
  }
}

class TripMoreInfo extends Component {
  
  state = {
    openDriverMenu: false,
    anchorElDriverMenu: null,
    edit: false,
  }

  componentDidMount() {
    let { record } = this.props
    this.setState({ record })
  }

  //static getDerivedStateFromProps(nextProps, prevState) {
  //let { trip } = nextProps
  //let { record: currentTrip } = prevState
  //if (currentTrip !== trip) {
  //return { ...prevState, record: trip }
  //}
  //return {...prevState}
  //}
  changeEdit = () => {
    this.setState({ edit: true })
  }

  unAssignDriver = async (tripId, driverIdx) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    let source = driverIdx === 1 ? 'driverId' : 'driver2Id'
    let updateTrip = { id: tripId, [source]: null }
    await Provider.dataProvider('REMOTE', 'trips', {
      method: 'unAssignDriver',
      requestMethod: 'PUT',
      data: updateTrip,
    }).then(() => {
      record = {...record, [source]: null }
      this.setState({ record })
      upsertGroupAvatarAction(record)
      showNotification('notification.unassigned_driver_success')
    }).catch((e) => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  handleAssignDriver = (itemIdxDriverMapping, itemIdxs, driverIdx) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    let { tripId } = record
    let firstItemIdx = _.first(itemIdxs)
    let driverId = itemIdxDriverMapping[firstItemIdx]
    let source = driverIdx === 1 ? 'driverId' : 'driver2Id'
    if (driverId) {
      let updateTrips = []
      updateTrips.push({ id: tripId, [source]: driverId })
      Provider.dataProvider('REMOTE', 'trips', {
        method: 'assignDriver',
        data: updateTrips,
      }).then(() => {
        record = {...record, [source]: driverId }
        this.setState({ record })
        upsertGroupAvatarAction(record)
        showNotification('notification.assign_driver_success')
      }).catch((e) => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      })
    }
  }

  openDriverMenu = (event, driverIdx, value) => {
    let { companyManager, drivers } = this.props
    if (!companyManager) return
    let { record } = this.state
    let cloneDrivers = { ...drivers }
    let ignoreDriverId = driverIdx === 1 ? record.driver2Id : record.driverId
    if (ignoreDriverId){
      delete cloneDrivers[ignoreDriverId]
    }
    this.setState({
      openDriverMenu: true,
      anchorElDriverMenu: event.target,
      driverIdx,
      driversMenu: cloneDrivers,
      value,
    })
  }

  closeDriverMenu = () => {
    this.setState({
      openDriverMenu: false,
      anchorElDriverMenu: null,
      driverIdx: 0,
    })
  }

  handleAssignAssistantDriver = (assistantDriver, tripId) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    let { id: assistantDriverId } = assistantDriver
    let updateTrips = []
    if (assistantDriverId) {
      updateTrips.push({ id: tripId, assistantDriverId })
    }
    Provider.dataProvider('REMOTE', 'trips', {
      method: 'assignAssistantDriver',
      requestMethod: 'PUT',
      data: { trips: updateTrips },
    }).then(() => {
      record = {...record, assistantDriverId }
      this.setState({ record })
      upsertGroupAvatarAction(record)
      showNotification('notification.assign_assistant_driver_success')
    }).catch((e) => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  unAssignAssistantDriver = (tripId) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    let data = { tripIds: [tripId] }
    Provider.dataProvider('REMOTE', 'trips', {
      method: 'unAssignAssistantDriver',
      requestMethod: 'PUT',
      data
    }).then(() => {
      record = {...record, assistantDriverId: null }
      this.setState({ record })
      upsertGroupAvatarAction(record)
      showNotification('notification.unassign_assistant_driver_success')
    }).catch((e) => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })

  }

  openAssistantDriverMenu = (event, value) => {
    let { companyManager, assistantDrivers } = this.props
    if (!companyManager) return
    let cloneAssistantDrivers = { ...assistantDrivers }
    delete cloneAssistantDrivers[value]
    this.setState({
      openAssistantDriverMenu: true,
      anchorElAssistantDriverMenu: event.target,
      assistanceDriversMenu: cloneAssistantDrivers,
      valueAssistanceDriverChip: value,
    })
  }

  closeAssistantDriverMenu = () => {
    this.setState({
      openAssistantDriverMenu: false,
      anchorElAssistantDriverMenu: null,
    })
  }

  handleAssignVehicle = (vehicle, tripId) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    let { id: vehicleId } = vehicle
    let updateTrips = []
    if (vehicleId) {
      updateTrips.push({ id: tripId, vehicleId })
    }
    Provider.dataProvider('REMOTE', 'trips', {
      method: 'assignVehicles',
      requestMethod: 'PUT',
      data: updateTrips,
    }).then(() => {
      record = {...record, vehicleId }
      this.setState({ record })
      upsertGroupAvatarAction(record)
      showNotification('notification.assigned_vehicle_success')
    }).catch((e) => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  unAssignVehicle = (tripId) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    let data = { trip: [tripId] }
    Provider.dataProvider('REMOTE', 'trips', {
      method: 'unAssignVehicleInTimeLine',
      requestMethod: 'PUT',
      data
    }).then(() => {
      record = {...record, vehicleId: null }
      this.setState({ record })
      upsertGroupAvatarAction(record)
      showNotification('notification.unassigned_vehicle_success')
    }).catch((e) => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  openVehicleMenu = (event, value) => {
    let { companyManager, vehicles } = this.props
    if (!companyManager) return
    let cloneVehicles = { ...vehicles }
    delete cloneVehicles[value]
    this.setState({
      openVehicleMenu: true,
      anchorElVehicleMenu: event.target,
      vehiclesMenu: cloneVehicles,
      valueVehicleChip: value,
    })
  }

  closeVehicleMenu = () => {
    this.setState({
      openVehicleMenu: false,
      anchorElVehicleMenu: null,
    })
  }

  onDoneConfigSaleTime = (newSaleStart, newSaleEnd) => {
    let { showNotification, upsertGroupAvatarAction } = this.props
    let { record } = this.state
    record = { ...record, saleStart: newSaleStart, saleEnd: newSaleEnd }
    upsertGroupAvatarAction(record)
    this.setState({
      record,
      edit: false
    })
    showNotification('notification.set_up_sale_time_success')
  }

  onErrorConfigSaleTime = (e) => {
    let { showNotification } = this.props
    showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
  }

  render() {
    let {
      classes,
      companyManager,
      assistantDrivers,
      translate,
      drivers,
      vehicles,
    } = this.props
    let { 
      record = {},
      openDriverMenu,
      anchorElDriverMenu,
      driverIdx,
      driversMenu,
      value: valueDriverChip,
      openAssistantDriverMenu,
      anchorElAssistantDriverMenu,
      valueAssistanceDriverChip,
      openVehicleMenu,
      anchorElVehicleMenu,
      vehiclesMenu,
      valueVehicleChip,
    } = this.state
    let { tripId } = record
    return <div className={classes.root}>
      <div className={classes.row}>
        <div className={classes.label}>{translate('resources.viewtrips.fields.routeName')}:</div>
        <div className={classes.text}>{record.routeName}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>{translate('resources.common.departure')}:</div>
        <div className={classes.text}>{moment(record.departureTime).format(shortDateTimeFormat)} - {record.departure}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>{translate('resources.common.arrival')}:</div>
        <div className={classes.text}>{moment(record.arrivalTime).format(shortDateTimeFormat)} - {record.arrival}</div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>{translate('resources.vehicles.name', { smart_count: 1 })}:</div>
        <div className={classes.chip}>
          {vehicles && <VehicleChip
            companyManager={companyManager}
            record={record}
            openVehicleMenu={this.openVehicleMenu}
            vehicles={vehicles}
            unAssignVehicle={this.unAssignVehicle}
          />}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>{translate('resources.drivers.name', { smart_count: 1 })}:</div>
        <div className={classes.chip}>
          {(drivers && !_.isEmpty(drivers)) && <DriverChip
            driverIdx={1}
            companyManager={companyManager}
            record={record}
            openDriverMenu={this.openDriverMenu}
            drivers={drivers}
            unAssignDriver={this.unAssignDriver}
            labelEmpty="resources.drivers.unassign_driver_1"
          />}
        </div>
        <div className={classes.chip}>
          {(drivers && !_.isEmpty(drivers)) && <DriverChip
            driverIdx={2}
            companyManager={companyManager}
            record={record}
            openDriverMenu={this.openDriverMenu}
            drivers={drivers}
            unAssignDriver={this.unAssignDriver}
            labelEmpty="resources.drivers.unassign_driver_2"
          />}
        </div>
      </div>
      <div className={classes.row}>
        <div className={classes.label}>{translate('resources.assistantdrivers.name', { smart_count: 1 })}:</div>
        <div className={classes.chip}>
          {assistantDrivers && <AssistantDriverChip
            companyManager={companyManager}
            record={record}
            openAssistantDriverMenu={this.openAssistantDriverMenu}
            assistantDrivers={assistantDrivers}
            unAssignAssistantDriver={this.unAssignAssistantDriver}
          />}
        </div>
      </div>

      {openDriverMenu && <DriverMenu
        dayItem={{ tripId }}
        drivers={driversMenu}
        anchorEl={anchorElDriverMenu}
        open={openDriverMenu}
        onClose={this.closeDriverMenu}
        assignDriver={this.handleAssignDriver}
        driverIdx={driverIdx}
        value={valueDriverChip}
      />}
      {openAssistantDriverMenu && <AssistantDriverMenu
        dayItem={{ tripId }}
        assistantDrivers={assistantDrivers}
        anchorEl={anchorElAssistantDriverMenu}
        open={openAssistantDriverMenu}
        onClose={this.closeAssistantDriverMenu}
        assignAssistantDriver={this.handleAssignAssistantDriver}
        value={valueAssistanceDriverChip}
      />}
      {openVehicleMenu && <VehicleMenu
        dayItem={{ tripId }}
        vehicles={vehiclesMenu}
        anchorEl={anchorElVehicleMenu}
        open={openVehicleMenu}
        onClose={this.closeVehicleMenu}
        assignVehicle={this.handleAssignVehicle}
        value={valueVehicleChip}
      />}
    </div>
  }
}

//const mapStateToProps = (state, props) => {
//let { record } = props
//let currentTripId = record.tripId
//let viewTripState = state['view-trip']
//let trip = viewTripState[currentTripId] || {}
//return { trip }
//}

const enhanceMoreInfo = compose(
  withStyles(moreInfoStyle),
  translate,
  connect(null, { showNotification, upsertGroupAvatarAction })
)

//<div className={classes.row}>
//<div className={classes.label}>{translate('resources.viewtrips.fields.saleTime')}:</div>
//{!edit ? <div className={classes.saleDateContainer}>
//<div className={classnames(classes.text, classes.saleDate)}>
//{moment(record.saleStart).format('DD/MM/YYYY')} - {moment(record.saleEnd).format('DD/MM/YYYY')}
//</div>
//{companyManager && <div>
//<IconButton
//onClick={this.changeEdit}
//color="primary"
//className={classes.actionButton}
//>
//<EditIcon fontSize="small" />
//</IconButton>
//</div>}
//</div> : <ConfigSaleTimeForm
//record={{ tripId, saleStart: record.saleStart, saleEnd: record.saleEnd }}
//onDone={this.onDoneConfigSaleTime}
//onError={this.onErrorConfigSaleTime}
///>}
//</div>

export default enhanceMoreInfo(TripMoreInfo)
