import React, { Component } from 'react'
import { Popper, Grow } from '@material-ui/core'

class MenuAction extends Component {
  render() {
    const { onClose, anchorEl, open, component } = this.props
    return (
      <Popper
        open={open}
        anchorEl={anchorEl}
        transition
        placement="right-start"
        style={{ zIndex: 3 }}
        onClose={onClose}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps}>{component}</Grow>
        )}
      </Popper>
    )
  }
}

export default MenuAction
