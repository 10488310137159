import React, { Component } from 'react'
import {
  TextInput,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  required,
  minLength,
  maxLength
} from 'react-admin'
import { Grid, withStyles } from '@material-ui/core'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import CustomToolbar from '../common/CustomToolbarForm'
import { Provider } from '../provider'

const style = () => ({
  root: {
    width: '100%'
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    flexDirection: 'row',
    flex: 1,
  },
})

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      deviceApps: [],
      version: new Date().getTime(),
    }
  }

  getApps = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'apps', {
      sort: {},
      pagination: {}
    })
    let record = res && res.data
    return record
  }

  async componentDidMount() {
    let deviceApps = await this.getApps()
    this.setState({ deviceApps })
  }


  render() {
    const { classes, ...props } = this.props
    let { deviceApps } = this.state
    const extra = {
      resource: 'devices',
      fullWidth: true,
    }
    return (
      <SimpleForm
        {...props}
        redirect="list"
        toolbar={<CustomToolbar />}
      >
        <Grid container className={classes.root} >
          <Grid item xs={6} md={6}>
            <TextInput
              source="name"
              {...extra} 
              validate={[required(), minLength(6), maxLength(256)]}
            />
            <TextInput 
              source="serial"
              {...extra} 
              validate={[required(), minLength(6), maxLength(24)]}
            />
            <TextInput source="deviceId" {...extra} />
            <TextInput source="imei" {...extra} />
            <ReferenceInput 
              reference="devicemodels"
              source="modelId"
              {...extra} 
              validate={required()}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              reference="companies"
              source="operatorId" 
              perPage={1000}
              {...extra} 
              validate={required()}
              required={true}
            >
              <FuzzySelectInput
                renderItem={item => item.name}
                {...defaultFuzzySearch()}
              />
            </ReferenceInput>
            <ArrayInput source="deviceApps" fullWidth>
              <SimpleFormIterator>
                <SelectInput
                  source="id"
                  optionText="name"
                  choices={deviceApps}
                  formClassName={classes.input}
                  fullWidth
                />
              </SimpleFormIterator>
            </ArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

export default withStyles(style)(FormSave)
