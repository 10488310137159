import React, { Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  SimpleForm,
  Edit,
  ReferenceField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'

////////////////////////////////////////////////////////////////////////////////
// List
const StopListFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="resources.common.search" source="q" alwaysOn fullWidth />
      <ReferenceInput label="resources.common.zone" source="zoneId"
        reference="zones">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

export const StopList = props => (
  <List {...props}
    filters={<StopListFilter />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="code" />
      <TextField source="address" />
      
      <ReferenceField
        label="resources.zones.fields.name"
        source="zoneId"
        reference="zones"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
)

////////////////////////////////////////////////////////////////////////////////
// Create
export const StopCreate = props => (
  <Create
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm>
      <FormSave />
    </SimpleForm>
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit

export class StopEdit extends Component {

  onSave = func => (data => {
    console.log(data)
  })

  render() {
    return <Edit
      {...this.props}
      undoable={false}
      title={<EditTitle resource={this.props.resource} />}
    >
      <SimpleForm redirect={false} handleSubmit={this.onSave}>
        <FormSave />
      </SimpleForm>
    </Edit>
  }
}
// export const StopEdit = props => (

// )