import React, { Component, Fragment } from 'react'
import FuzzyPicker from './fuzzy-picker'
import './index.css'
import {
  InputAdornment,
  IconButton,
  TextField,
  withStyles,
} from '@material-ui/core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldTitle } from 'react-admin'
import { sanitize } from '../../utils/commonUtil'

const styles = theme => {
  let { unit } = theme.spacing
  return {
    search: {
      margin: unit,
      marginTop: 16,
      marginBottom: 8,
    },
    clear: {
      width: 6 * unit,
      height: 6 * unit,
      margin: 0,
      padding: 0,
      right: -unit,
    }
  }}

class FuzzySelectInput extends Component {

  constructor(props) {
    super(props)
    this.fuzzy = React.createRef()
    this.input = React.createRef()
    this.state = {
      itemSelected: {},
      text: '',
      open: false,
    }
  }

  // componentDidMount() {
  //   let { multiple, choices, input, optionText } = this.props
  //   if (!multiple) {
  //     let itemSelecteds = choices.filter(choice => choice.id === input.value) || {}
  //     let itemSelected = itemSelecteds[0] || {}
  //     this.setState({
  //       itemSelected: itemSelected,
  //       text: itemSelected[optionText] || '',
  //     })
  //   }
  // }

  static getDerivedStateFromProps(nextProps, prevState = {}) {
    let { multiple, choices, input, optionText, renderOptionText } = nextProps
    let { itemSelected } = prevState
    if (input.value !== itemSelected.id) {
      if (!multiple) {
        let itemSelecteds = choices.filter(choice => choice.id === input.value) || {}
        itemSelected = itemSelecteds[0] || {}
      }
    }
    let text = itemSelected[optionText] || ''  
    if (renderOptionText) {
      text = renderOptionText(itemSelected)
    }
    return {
      itemSelected: itemSelected,
      text,
    }
  }

  handleSelectItem = (item) => {
    let { input, optionText, optionValue, renderOptionText } = this.props
    let text = item[optionText] || ''  
    if (renderOptionText) {
      text = renderOptionText(item)
    }
    this.setState({
      itemSelected: item || {},
      text,
      open: false,
    })
    input.onChange(item[optionValue])
    input = this.input.current
    setTimeout(() => input.focus(), 300)
  }

  clearData = (evt) => {
    let { input } = this.props
    input.onChange('')
    this.setState({
      itemSelected: {},
      text: '',
    })
    evt.preventDefault()
    evt.stopPropagation()
    return false
  }

  openPicker = () => this.setState({ open: true })

  onKeyPress = (e) => {
    let key = e.key
    if (key && key.length === 1)
      setTimeout(() => this.fuzzy.current.setInputValue(key), 100)
    this.setState({ open: true })
  }

  onClose = () => {
    this.setState({ open: false })
    let input = this.input.current
    setTimeout(() => input.focus(), 300)
  }

  render() {
    const {
      label,
      renderItem,
      itemValue,
      choices,
      allowEmpty,
      resource,
      classes,
      input,
      helperText,
      disabled = false,
      required = false,
      meta = {},
      ...props
    } = this.props
    const {
      text,
      open,
    } = this.state
    let restInputProps = sanitize(props, [
      'optionValue',
      'translateChoice',
      'setSort',
      'setPagination',
      'setFilter',
      'basePath',
      'isRequired',
      'optionText'
    ])
    let { error, touched } = meta
    return (
      <Fragment>
        <TextField
          {...restInputProps}
          inputRef={this.input}
          margin="normal"
          label={<FieldTitle
            label={label}
            source={input.name}
            resource={resource}
          />}
          value={text}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.clear}
                  aria-label="Clear"
                  onClick={this.clearData}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          onClick={() => {
            if (disabled) return
            this.openPicker()
          }}
          onKeyPress={this.onKeyPress}
          disabled={disabled}
          error={Boolean(error) && touched}
          required={required}
          helperText={error}
        />
        <FuzzyPicker
          ref={this.fuzzy}
          isOpen={open}
          items={choices}
          renderItem={renderItem}
          onClose={this.onClose}
          itemValue={itemValue}
          showAllItems={true}
          onChange={this.handleSelectItem}
          allowEmpty={allowEmpty}
        />
      </Fragment>
    )
  }
}

FuzzySelectInput.defaultProps = {
  optionText: 'name',
  optionValue: 'id'
}

export default withStyles(styles)(FuzzySelectInput)
