import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined'
import { translate, Button as RAButton } from 'react-admin'
import clipboard from '../utils/clipboard'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import Dropzone from 'react-dropzone'
import ReactDatagrid from 'react-data-grid'

import { resourceRequest, invokeAction } from '../action'
import { EXTENSION_EXCEL, EXTENSION_TXT, getExtensionFile, } from '../utils/file'
import { readCsv, csvToGrid, arrayToGrid, gridToCsv } from '../utils/csv'
import { readXlsx, writeXlsx } from '../utils/xlsx'
import moment from 'moment'
import { hourToMin } from '../utils/time'
import FileSaver from 'file-saver'
import { createListOfStopDetails } from '../network/utils'

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    marginLeft: 12,
    marginRight: 12,
  },
  button: {
    margin: theme.spacing.unit,
  },
})

class CellEditor extends Component {
  constructor(props) {
    super(props)
    this.isObject = typeof props.value === 'object'
  }

  getValue() {
    let updated = {}
    let { props, isObject } = this
    let value = this.getInputNode().value
    updated[props.column.key] = isObject ? {...props.value, value} : value
    return updated
  }

  getInputNode() {
    return this.input
  }

  render() {
    let { props, isObject } = this
    return (
      <input
        ref={ref => this.input = ref}
        defaultValue={isObject ? props.value.value : props.value}
        type="text"
      />
    )
  }
}

class Import extends Component {
  constructor(props) {
    super(props)
    this.state = { data: [], columns: [], rows: [], version: new Date().getTime() }
  }

  onCopy(v) {
    const { columns, rows } = this.state
    const csv = gridToCsv({columns, rows})
    clipboard(csv)
  }

  /**
   * Drops files into upload area
   * @param {*} files 
   */
  async onDrop(files) {
    let len = files.length
    let data = []
    for (let idx = 0; idx < len; idx++) {
      let file = files[idx]
      let extensionFile = getExtensionFile(file.name)
      let array = []
      if (EXTENSION_TXT.indexOf(extensionFile) !== -1) {
        array = await readCsv(file)
      }
      if (EXTENSION_EXCEL.indexOf(extensionFile) !== -1) {
        array = await readXlsx(file) || []
      }
      data = [...data, ...array]
    }
    let { columns, rows } = arrayToGrid(data)
    columns = columns.map(v => { return {editable: true, editor: CellEditor, ...v }})
    this.setState({ data, columns, rows })
  }

  
  onPaste(evt) {
    evt.preventDefault()
    evt.stopPropagation()
    let paste = (evt.clipboardData || window.clipboardData).getData('text')
    let { data, columns, rows } = csvToGrid(paste)
    columns = columns.map(v => { return {editable: true, editor: CellEditor, ...v }})
    this.setState({ data, columns, rows })
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const rows = this.state.rows.slice()
    for (let i = fromRow; i <= toRow; i++) {
      rows[i] = { ...rows[i], ...updated }
    }
    this.setState({ rows, version: new Date().getTime()})
  }

  validate(columns, rows) {
    return true
  }

  params() {
    let { resource } = this.props.match.params
    let { rows } = this.state
    rows = rows.map(v => { 
      delete v.id
      return v
    })

    if (resource === 'trips') {
      // preprocessing
      // route;departure;arrival;departureDate;departureTime;arrivalDate;arrivalTime;capacity;seatAvailable;vehicle
      rows = rows.map(v => {
        let minutes = hourToMin(v.departureTime)
        v.departureTime = moment(v.departureDate, 'YYYY-MM-DD').add(minutes, 'minutes').toDate()
        minutes = hourToMin(v.arrivalTime)
        v.arrivalTime = moment(v.arrivalDate, 'YYYY-MM-DD').add(minutes, 'minutes').toDate()
        v.departureDate = moment(v.departureDate, 'YYYY-MM-DD').toDate()
        v.arrivalDate = moment(v.arrivalDate, 'YYYY-MM-DD').toDate()

        v.capacity = Math.max(0, v.capacity - 0)
        v.seatAvailable = Math.max(0, v.seatAvailable - 0)
        return v
      })
      return {
        resource,
        payload: {
          method: 'import',
          requestMethod: 'POST',
          data: rows
        }
      }
    } else if (resource === 'vehicles' 
      || resource === 'drivers'
      || resource === 'licences'
    ) {
      return {
        resource,
        payload: {
          method: 'import',
          requestMethod: 'POST',
          data: rows
        }
      }
    } 
    let params = {
      resource: resource,
      fetch: 'CREATE',
      payload: {
        data: rows
      }
    }
    return params
  }

  async import() {
    let { resourceRequest } = this.props
    try {
      let resp = await invokeAction(resourceRequest, this.params())
      this.setState({ error: resp.message })
      this.cancel()
    } catch (e) {
      let msg =(e.error && e.error.message) ? e.error.message : 'Internal error!'
      this.setState({ error: msg })
    }
  }

  cancel() {
    this.setState({ data: [], columns: [], rows: [], error: null })
  }

  isTemplateAvailable() {
    let { resource } = this.props.match.params
    return resource === 'trips' || resource === 'vehicles' || resource === 'drivers' || resource === 'licences'
  }

  downloadTemplate(e) {
    let { resource } = this.props.match.params
    let header = []
    let sheetname = resource
    let filename =  `template_${resource}.xlsx`

    if (resource === 'trips') {
      header = ['route', 'departure', 'arrival', 'departureDate', 'departureTime', 'arrivalDate', 'arrivalTime', 'capacity', 'seatAvailable', 'vehicle' ]
    } else if (resource === 'vehicles') {
      header = ['name', 'vin', 'brand', 'model', 'plate', 'capacity', 'seatAvailable', 'type', 'fuel' ]
    } else if (resource === 'drivers') {
      header = ['fullName', 'phone', 'email', 'birthday' ]
    } else if (resource === 'licences') {
      header = ['licenceNumber', 'validFrom', 'validTo', 'renewalPeriod', 'renewalPeriodUnit', 'type', 'vehicle', 'driver']
    }
    let data = writeXlsx([header], sheetname)
    FileSaver.saveAs(new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), filename)
  }


  stopDetailsEnabled(columns) {
    let enabled = columns.findIndex(v => { return v.key === 'zoneId' }) < 0
    enabled &= columns.findIndex(v => { return v.key === 'name' }) >= 0
    enabled &= columns.findIndex(v => { return v.key === 'code' }) >= 0
    enabled &= columns.findIndex(v => { return v.key === 'lat' }) >= 0
    enabled &= columns.findIndex(v => { return v.key === 'lon' }) >= 0
    return enabled
  }

  /**
   * name, code, lat, lon available
   * and zoneId not present
   */
  async getStopDetails() {
    let { rows, columns } = this.state
    if (rows.length === 0) return
    rows = rows.map(v => {
      v.lat = v.lat - 0
      v.lon = v.lon - 0
      return v
    })
    rows = await createListOfStopDetails(rows)
    rows = rows.map(v => {
      delete v.id
      return v
    })
    let head = Object.keys(rows[0])
    columns = head.map(v => { return {editable: true, editor: CellEditor, key: v, name: v }})
    this.setState({ columns, rows, version: new Date().getTime() })
  }

  render() {
    const { resource } = this.props.match.params
    const { classes, translate } = this.props
    let { columns, rows, error } = this.state
    let enabled = columns && columns.length > 0
    let hasTemplate = this.isTemplateAvailable()
    let stopDetails = Boolean(resource === 'stops' && this.stopDetailsEnabled(columns))

    return (
      <div className={classes.root}>
        <Grid container spacing={24}>
          <Grid item xs={8}>
            { stopDetails
              && <RAButton
                onClick={ async v => await this.getStopDetails(v)}
                variant="contained"
                className={classes.button}
                color="secondary"
                label="Get Stop Details"
              />
            }
            <RAButton
              onClick={v => this.downloadTemplate(v)}
              variant="contained"
              disabled={ !hasTemplate }
              className={classes.button}
              color="secondary"
              label={ translate('button.template') }
            />
            <RAButton
              variant="contained"
              className={classes.button}
              color="secondary"
              disabled={!enabled}
              label={ translate('button.validate') }
            />
            <RAButton
              variant="contained"
              color="primary"
              className={classes.button}
              disabled={!enabled}
              onClick={(evt)=> this.import(evt) }
              label={ translate('button.import') }
            />
            <RAButton
              variant="contained"
              className={classes.button}
              onClick={(evt)=> this.cancel(evt)}
              disabled={ !enabled }
              label={ translate('button.cancel') }
            />
            <RAButton
              onClick={ ()=>{ window.location.href = `/${resource}` } }
              label={ translate('button.close') }
            />
            <div className={'clipboard'} onClick={ v => this.onCopy(v) } disabled={ !enabled }>
              <FileCopyOutlined />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <Dropzone onDrop={ (files)=> this.onDrop(files) } disabledStyle={{}}>
              <Paper className={classes.paper} >{ translate('resources.common.drop_file_here')}</Paper>
            </Dropzone>
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            {
              error && <Paper className={classes.paper} >{ error }</Paper>
            }
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div onPaste={ (evt) => this.onPaste(evt) } contentEditable="true">
              <ReactDatagrid
                key={this.state.version}
                className={classes.grid}
                columns={columns}
                rowGetter={i => rows[i]}
                rowsCount={rows.length}
                enableCellSelect={true}
                onGridRowsUpdated={this.onGridRowsUpdated}
              />
            </div>
          </Grid>
        </Grid>
        
      </div>
    )
  }
}

Import.propTypes = {
  resource: PropTypes.string,
}

Import.defaultProps = {
}

const enhance = compose(
  connect(null, { resourceRequest }),
  withStyles(styles), translate,
)

export default enhance(Import)
