import React, { Component, Fragment } from 'react'
import {
  TextInput,
  SelectInput,
  ReferenceInput,
  SimpleForm,
  translate,
  FormDataConsumer,
  ImageInput,
  ImageField,
  showNotification,
  required,
} from 'react-admin'
import { DatePickerInput } from '../common/DatePicker'
import {
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core'
import { validate } from './validate'
import CustomToolbar from '../common/CustomToolbarForm'
import ProductValidateChip from './ProductValidateChip'
import EInvoiceSetting from './EInvoiceSetting'
import compose from 'recompose/compose'
import * as permission from '../utils/permission'
import _ from 'lodash'
import classnames from 'classnames'
import TemplateChip from './TemplateChip'
import TemplateChip1 from './TemplateChip1'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ChildProducts from './ChildProducts'
import QuantityInput from './QuantityInput'
import SettingChildProductButton from './SettingChildProductButton'
import CheckboxInput from '../common/CheckboxInput'
import { push } from 'react-router-redux'
import { ProductType, TicketTemplates, TicketTemplates1 } from './constant'
import moment from 'moment'
import RouteFares from './RouteFares'
import AgencyList from './AgencyList'
import { MIN, MAX, validateName } from '../common/validateName'
import SelectInputCustom from '../common/SelectInputCustom'

const styles = {
  leftCard: {
    borderRight: '1px solid #e8e8e8'
  },
  title: {
    textDecoration: 'underline'
  },
  agencyContainer: {
    marginTop: 64,
  },
  chipTemplateContaner: {
    marginTop: 64,
  },
  content: {
    marginTop: 16,
    display: 'flex',
    alignItem: 'center'
  },
  titleText: {
    flex: 1,
  },
  titleContainer: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
  },
  nonPaddingLeft: {
    paddingLeft: 0,
  },
  nonPaddingRight: {
    paddingRight: 0,
  },
  formControl: {
    marginBottom: 8,
    marginTop: 16,
  }
}

export function getProductFareByParentFareId(routeProducts) {
  let childFares = _.reduce(routeProducts, (result, ele) => {
    let { childFares } = ele
    if (childFares) {
      for (let productId in childFares) {
        let { productFares } = childFares[productId]
        productFares = productFares.map(productFare => ({ ...productFare, productId: parseInt(productId) }))
        result = [...result, ...productFares]
      }
      return result
    }
  }, [])
  let parentFares = _.reduce(routeProducts, (result, ele) => {
    let { fares } = ele
    return [...result, ...fares]
  }, [])
  let parentFareMapping = _.keyBy(parentFares, 'productFareId')
  let productFareByParentFareId = _.groupBy(childFares,'parentProductFareId')
  return _.reduce(parentFareMapping, (result, parentFare, productFareId) => {
    result[productFareId] = parentFare
    let childFares = productFareByParentFareId[productFareId] || []
    if (childFares) {
      result[productFareId] = { ...parentFare, childFares }
    }
    return result
  }, {})
}

function getChildRouteProducts(childFares, childRouteProduct) {
  for (let productId in childFares) {
    let childFare = childFares[productId]
    let { routeProductId, productFares } = childFare
    let fares = childRouteProduct[productId] || []
    if (_.isEmpty(fares)) {
      fares.push(childFare)
    } else {
      let fareIdx = fares.findIndex(fare => fare.routeProductId === routeProductId)
      if (fareIdx >= 0) {
        let fare = fares[fareIdx]
        fare = { ...fare, productFares: [...fare.productFares, ...productFares]}
        fares[fareIdx] = fare
      } else {
        fares.push(childFare)
      }
    }
    childRouteProduct[productId] = fares
  }
  return childRouteProduct
}

class _FormGrid extends Component {

  state = {}
  
  async componentDidMount() {
    await this.loadOtherProduct()
    await this.getTicketValidations()
  }

  loadOtherProduct = async () => {
    let { id = 0 } = this.props
    if (typeof id === 'string') {
      id = parseInt(id)
    }
    let resp = await Provider.dataProvider('GET_LIST', 'products', {
      filter: {
        where: {
          or: [{ parentProductId: id }, { parentProductId: null }],
          type: { nin: [
            ProductType.SEAT,
            ProductType.COMBO,
            ProductType.SERVICE,
            ProductType.SEASON,
          ]
          },
        },
        withOtherAgencyCount: true,
      },
      pagination: {},
      sort: {},
    })
    if (resp && resp.data) {
      let products = resp.data
      let childProducts = products.filter(product => product.parentProductId === id)
      let otherProducts = products.filter(product => product.parentProductId === null)
      this.setState({ childProducts, otherProducts })
    }
  }

  getTicketValidations = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'ticketvalidations', {
      filter: {
        '../fields': ['id', 'name', 'parameters']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      this.setState({ ticketValidations: res.data })
    }
  }

  setNewChildProduct = updatedProduct => {
    let { childProducts, otherProducts } = this.state
    let updateProductIdx = _.findIndex(childProducts, product => product.id === updatedProduct.id)
    if (updateProductIdx >= 0) {
      let product = childProducts[updateProductIdx]
      updatedProduct = { ...product, ...updatedProduct }
      childProducts[updateProductIdx] = updatedProduct
    } else {
      childProducts.push(updatedProduct)
      otherProducts = otherProducts.filter(product => product.id !== updatedProduct.id)
    }
    this.setState({ childProducts: [ ...childProducts ], otherProducts })
  }

  updateAgencyCount = () => {
    let { childProducts } = this.state
    let updateChildProducts = childProducts.map(product => ({ ...product, agencyCount: product.agencyCount - 1 }))
    this.setState({ childProducts: [ ...updateChildProducts ] })
  }

  updateNonChildProduct = updatedProduct => {
    let { otherProducts, childProducts } = this.state
    let updateProductIdx = _.findIndex(otherProducts, product => product.id === updatedProduct.id)
    if (updateProductIdx >= 0) {
      let product = otherProducts[updateProductIdx]
      updatedProduct = { ...product, ...updatedProduct }
      otherProducts[updateProductIdx] = updatedProduct
    } else {
      otherProducts.push(updatedProduct)
      childProducts = childProducts.filter(product => product.id !== updatedProduct.id)
    }
    this.setState({ otherProducts: [ ...otherProducts ], childProducts })
  }

  setChildRouteProducts = (newChildRouteProduct, newProductFareMapping) => {
    let { childRouteProduct, productFareMapping = {} } = this.state
    childRouteProduct = { ...childRouteProduct, ...newChildRouteProduct }
    for (let parentProductFareId in newProductFareMapping) {
      let productFare = newProductFareMapping[parentProductFareId]
      let { childFares } = productFare
      if (productFareMapping[parentProductFareId]) {
        let existsProductFare = productFareMapping[parentProductFareId]
        let { childFares: existsChildFares } = existsProductFare 
        productFareMapping = {
          ...productFareMapping,
          [parentProductFareId]: {
            ...existsProductFare,
            childFares: [...existsChildFares, ...childFares],
          }
        }
      } else {
        productFareMapping = { ...productFareMapping, [parentProductFareId]: productFare }
      }
    }
    this.setState({ childRouteProduct, productFareMapping })
  }

  updateChildRouteProductsAfterRemoveRoute = routeId => {
    let { childRouteProduct } = this.state
    childRouteProduct = _.reduce(childRouteProduct, (result, routeProducts, productId) => {
      routeProducts = _.reduce(routeProducts, (newRouteProduct, routeProduct) => {
        let { route } = routeProduct
        let { id } = route
        if (routeId !== id) {
          newRouteProduct.push(routeProduct)
        }
        return newRouteProduct
      }, [])
      result[productId] = routeProducts
      return result
    }, {})
    this.setState({ childRouteProduct })
  }

  updateChildRouteProductsAfterRemoveFare = (parentProductFareId, routeId) => {
    let { childRouteProduct } = this.state
    childRouteProduct = _.reduce(childRouteProduct, (result, routeProducts, productId) => {
      routeProducts = _.reduce(routeProducts, (newRouteProduct, routeProduct) => {
        let { route } = routeProduct
        let { id } = route
        if (routeId === id) {
          let { productFares } = routeProduct
          productFares = productFares.filter(ele => ele.parentProductFareId !== parentProductFareId)
          routeProduct = { ...routeProduct, productFares }
        }
        newRouteProduct.push(routeProduct)
        return newRouteProduct
      }, [])
      result[productId] = routeProducts
      return result
    }, {})
    this.setState({ childRouteProduct })
  }

  updateChildRouteProductsAfterAdd = newRouteProduct => {
    let { childRouteProduct, productFareMapping } = this.state
    let newProductFareMapping = getProductFareByParentFareId([newRouteProduct])
    productFareMapping = { ...productFareMapping, ...newProductFareMapping }
    let { childFares } = newRouteProduct
    childRouteProduct = getChildRouteProducts(childFares, childRouteProduct) 
    this.setState({ childRouteProduct: { ...childRouteProduct }, productFareMapping })
  }

  removeChildProductFare = childProductId => {
    let { productFareMapping } = this.state
    for (let parentProductFareId in productFareMapping) {
      let { childFares, ...rest } = productFareMapping[parentProductFareId]
      childFares = childFares.filter(ele => ele.productId !== childProductId)
      productFareMapping = { ...productFareMapping, [parentProductFareId]: { ...rest, childFares } }
    }
    this.setState({ productFareMapping })
  }

  updateChildProductFare = (parentProductFareId, productFareId, amount) => {
    let { productFareMapping } = this.state
    let { childFares, ...rest } = productFareMapping[parentProductFareId]
    let childFareIdx = _.findIndex(childFares, ele => ele.productFareId === productFareId)
    let childFare = childFares[childFareIdx]
    childFare = { ...childFare, amount: parseFloat(amount) }
    childFares[childFareIdx] = childFare
    productFareMapping = { ...productFareMapping, [parentProductFareId]: { childFares, ...rest } }
    this.setState({ productFareMapping })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { newRouteProduct, newAgencyProduct } = nextProps
    let { 
      newRouteProduct: currentNewRouteProduct,
      newAgencyProduct: currentNewAgencyProduct,
      childRouteProduct: currentChildRouteProduct,
      childProducts: currentChildProducts,
      productFareMapping,
    } = prevState

    if (newRouteProduct !== currentNewRouteProduct) {
      let { childFares } = newRouteProduct
      let childRouteProduct = getChildRouteProducts(childFares, currentChildRouteProduct) 
      let newProductFareMapping = getProductFareByParentFareId([newRouteProduct])
      productFareMapping = { ...productFareMapping, ...newProductFareMapping }
      currentNewRouteProduct = newRouteProduct
      currentChildRouteProduct = { ...childRouteProduct }
    }

    if (newAgencyProduct !== currentNewAgencyProduct) {
      currentNewAgencyProduct = newAgencyProduct
      let updateChildProducts = currentChildProducts.map(product => ({ ...product, agencyCount: product.agencyCount + 1 }))
      currentChildProducts = [...updateChildProducts]
    }

    return {
      ...prevState,
      newRouteProduct: currentNewRouteProduct,
      newAgencyProduct: currentNewAgencyProduct,
      childRouteProduct: currentChildRouteProduct,
      childProducts: currentChildProducts,
      productFareMapping,
    }
  }

  render() {
    let {
      id,
      translate,
      isCompanyManager,
      classes,
      commissionTemplates,
      newRouteProduct,
      newAgencyProduct,
    } = this.props
    let {
      childRouteProduct,
      childProducts,
      otherProducts,
      productFareMapping,
      ticketValidations,
    } = this.state

    const extra = {
      resource: 'products',
      fullWidth: true,
    }
    
    return <Grid container>
      <Grid
        item
        md={5}
        xs={12}
        className={classes.leftCard}
      >
        <TextInput source="name" validate={validateName(MIN, MAX)} {...extra} />
        <Grid container spacing={8}>
          <Grid className={classes.nonPaddingLeft} item md={6} xs={12}>
            <DatePickerInput
              source="validFrom"
              dateFormat="DD/MM/YYYY"
              showLunarDate={true}
              keyboard={true}
              {...extra} 
            />
          </Grid>
          <Grid item className={classes.nonPaddingRight} md={6} xs={12}>
            <DatePickerInput
              source="validTo"
              dateFormat="DD/MM/YYYY"
              showLunarDate={true}
              keyboard={true}
              {...extra}
            />
          </Grid>
        </Grid>
        <ReferenceInput
          reference="producttypes"
          source="type"
          validate={required()}
          disabled={!(!id)}
          sort={{ field: 'id', order: 'ASC' }}
          filter={{ id: { nin: ['00SEAT', '50SEASON'] }}}
          {...extra}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
          reference="productstatuses"
          source="status"
          sort={{ field: 'id', order: 'ASC' }}
          defaultValue='00IAT'
          {...extra}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <QuantityInput source="quantity" extra={extra} />
        <FormDataConsumer>
          {({ formData = {} }) => {
            let { companyId } = formData
            return companyId ? <ReferenceInput
              reference="agencies"
              source="supplierId"
              filter={{
                type: { inq: ['30SUP', '40MSUP'] },
                '../belongsTo': { companyId }
              }}
              {...extra}
              allowEmpty
              label="resources.suppliers.name"
            >
              <SelectInputCustom
                optionText="name"
                allowEmpty
                empty={translate('resources.products.empty_supplier')}
                all={translate('resources.products.no_supplier')}
              />
            </ReferenceInput> : null
          }}
        </FormDataConsumer>
        <CheckboxInput
          source="isGroup"
          label={translate('resources.products.fields.isGroup')}
          {...extra}
        />
        <ProductValidateChip
          isCompanyManager={isCompanyManager}
          source="setting"
          ticketValidations={ticketValidations}
          {...extra}
        />
        <EInvoiceSetting
          isCompanyManager={isCompanyManager}
          source="eInvoiceSetting"
          {...extra}
        />
        <ReferenceInput
          reference="taxes"
          source="taxId"
          {...extra}
          allowEmpty
          label="resources.products.fields.tax"
        >
          <SelectInputCustom
            optionText="name"
            allowEmpty
            empty={translate('resources.products.empty_tax')}
            all={translate('resources.products.no_tax')}
          />
        </ReferenceInput>
        <TextInput source="desc" {...extra} />
        <TextInput source="note" {...extra} />
        <ImageInput
          source="images"
          accept="image/*"
          multiple
          {...extra}
        >
          <ImageField source="src" />
        </ImageInput>
      </Grid>
      {id && <Grid item md={7} xs={12}>
        <FormDataConsumer>
          {({ formData = {} }) => {
            let { parentProductId, validFrom, validTo, type, supplierId } = formData
            return <Fragment>
              <div>
                <Typography
                  variant="h4"
                  className={classes.title}
                >
                  {translate('resources.common.price_table')}
                </Typography>
                <RouteFares
                  productId={id}
                  isLoading={true}
                  isCompanyManager={isCompanyManager}
                  newRouteProduct={newRouteProduct}
                  parentProductId={parentProductId}
                  setChildRouteProducts={this.setChildRouteProducts}
                  productFareMapping={productFareMapping}
                  updateChildRouteProductsAfterRemoveRoute={this.updateChildRouteProductsAfterRemoveRoute}
                  updateChildRouteProductsAfterRemoveFare={this.updateChildRouteProductsAfterRemoveFare}
                  updateChildRouteProductsAfterAdd={this.updateChildRouteProductsAfterAdd}
                />
              </div>
              <div className={classes.agencyContainer}>
                <Typography 
                  variant="h4"
                  className={classes.title}
                >
                  {translate('resources.agencies.name', { smart_count: 2 })}
                </Typography>
                <AgencyList
                  productId={id}
                  isLoading={true}
                  isCompanyManager={isCompanyManager}
                  commissionTemplates={commissionTemplates}
                  newAgencyProduct={newAgencyProduct}
                  updateAgencyCount={this.updateAgencyCount}
                  showPlatformCommission={!parentProductId}
                  parentProductId={parentProductId}
                  hideSupplier={parentProductId}
                  supplierId={supplierId}
                />
              </div>
              {!parentProductId && <div className={classes.chipTemplateContaner}>
                <Typography
                  variant="h4"
                  className={classes.title}
                >
                  {translate('resources.common.template')}
                </Typography>
                <div className={classes.content}>
                  {TicketTemplates.map((ele, idx) => {
                    let { id: code, icon, source } = ele
                    return <TemplateChip
                      productId={id}
                      group={code}
                      key={idx}
                      icon={icon}
                      source={source}
                    />
                  })}
                </div>
              </div>}
              {!parentProductId && <div className={classes.chipTemplateContaner}>
                <Typography
                  variant="h4"
                  className={classes.title}
                >
                  {translate('resources.common.templatelayout')}
                </Typography>
                <div className={classes.content}>
                  {TicketTemplates1.map((ele, idx) => {
                    let { id: code, icon, source } = ele
                    return <TemplateChip1
                      productId={id}
                      group={code}
                      key={idx}
                      icon={icon}
                      source={source}
                    />
                  })}
                </div>
              </div>}
              {(!parentProductId && type === ProductType.COMBO) && <div className={classes.chipTemplateContaner}>
                <div className={classes.titleContainer}>
                  <Typography
                    variant="h4"
                    className={classnames(classes.title, classes.titleText)}
                  >
                    {translate('resources.common.child_product')}
                  </Typography>
                  <SettingChildProductButton
                    parentId={parseInt(id)}
                    setNewChildProduct={this.setNewChildProduct}
                    setChildRouteProducts={this.setChildRouteProducts}
                    updateNonChildProduct={this.updateNonChildProduct}
                    commissionTemplates={commissionTemplates}
                    ticketValidations={ticketValidations}
                    parentValid={{ validFrom, validTo }}
                    icon={<FontAwesomeIcon
                      icon={faCog}
                      fontSize="small"
                      className={classes.icon}
                    />}
                    label={translate('button.setting')}
                    products={otherProducts}
                    button
                  />
                </div>
                <div className={classes.content}>
                  {childProducts && <ChildProducts 
                    isCompanyManager={isCompanyManager}
                    parentId={parseInt(id)}
                    setNewChildProduct={this.setNewChildProduct}
                    childRouteProduct={childRouteProduct}
                    updateNonChildProduct={this.updateNonChildProduct}
                    products={childProducts}
                    commissionTemplates={commissionTemplates}
                    removeProductFare={this.removeChildProductFare}
                    updateChildProductFare={this.updateChildProductFare}
                    productFareMapping={productFareMapping}
                    ticketValidations={ticketValidations}
                  />}
                </div>
              </div>}
            </Fragment>
          }}
        </FormDataConsumer>
      </Grid>}
    </Grid>
  }
}

const enhanceFormGrid = compose(translate, withStyles(styles))
const FormGrid = enhanceFormGrid(_FormGrid)

class FormSave extends Component {
  
  state = {}

  componentDidMount() {
    let { record } = this.props
    if (_.isEmpty(record)) {
      let validFrom = moment()
      let validTo = moment().add(1, 'y')
      record = { validFrom, validTo, quantity: -1 }
    }
    this.setState({ record })
  }

  create = (data) => {
    let { showNotification, push } = this.props
    Provider.dataProvider('CREATE', 'products', {
      data
    }).then(
      res => {
        let { data = {} } = res
        let { id } = data
        showNotification('notification.create_product_success')
        push(`/products/${id}`)
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    )
  }

  update = data => {
    let { showNotification, id } = this.props
    Provider.dataProvider('UPDATE', 'products', {
      id,
      data,
    }).then(
      () => {
        showNotification('notification.update_product_success')
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    )
  }

  save = (data) => {
    let { id } = this.props
    if (!id) {
      this.create(data)
    } else {
      this.update(data)
    }
  }

  render() {
    let {
      permissions,
      id,
      commissionTemplates,
      newRouteProduct,
      newAgency,
      showNotification,
      push,
      submitOnEnter,
      record,
      ...props
    } = this.props
    let { record: currentRecord } = this.state
    let isCompanyManager = permission.isCompanyManager(permissions)
    return <SimpleForm
      toolbar={<CustomToolbar />}
      validate={validate}
      save={this.save}
      submitOnEnter={false}
      record={currentRecord}
      {...props}
    >
      <FormGrid
        id={id}
        newRouteProduct={newRouteProduct}
        newAgencyProduct={newAgency}
        isCompanyManager={isCompanyManager}
        commissionTemplates={commissionTemplates}
      />
    </SimpleForm>
  }
}

const enhanceFormSave = compose(connect(null, { showNotification, push }))
export default enhanceFormSave(FormSave)
