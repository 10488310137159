import React from 'react'
import { green, grey, yellow, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core'
import { ChipField } from 'react-admin'
import get from 'lodash/get'

export const NOT_PAID = '00NOT_PAID'
export const SENT = '10SENT'
export const PAID = '20PAID'

export const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  [NOT_PAID]: { color: 'white', backgroundColor: grey[500] },
  [SENT]: { color: 'white', backgroundColor: yellow[700] },
  [PAID]: { color: 'white', backgroundColor: green[500] },
}

////////////////////////////////////////////////////////////////////////////////
// ViewTrip
export const StatusField = withStyles(statusStyles)(({ classes, ...props }) => {
  let status = get(props.record, 'id')
  let className = get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})
