import React, { Component } from 'react'
import {
  Datagrid,
  List,
  DateField,
  Filter,
  NumberField,
  TextField,
  FunctionField,
  translate,
  CardActions
} from 'react-admin'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { moment } from '../common/format'
import _ from 'lodash'
import { withStyles } from '@material-ui/core'
import ExportAccountCreditButton from './ExportAccountCreditButton'

const ActionList = ({ translate, filterValues, }) => {
  return (
    <CardActions>
      <ExportAccountCreditButton
        color="primary"
        label={'button.export'}
        filterValues={filterValues}
        translate={translate}
      />
    </CardActions>
  )
}

class _Filter extends Component {
  state = {}

  render() {
    const { translate, permissions, ...props } = this.props
    const { fromDate } = this.state
    return <Filter
      {...props}
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
    >
      <DateTimePickerInput
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <DateTimePickerInput
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
        minDate={fromDate}
      />
    </Filter>
  }
}

const AccountCreditFilter = translate(_Filter)

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}

class _AccountCreditList extends Component {
  render() {
    let { classes, ...props } = this.props
    return (
      <List
        {...props}
        actions={<ActionList {...props} />}
        filters={<AccountCreditFilter />}
        filter={
          {
            'path': 'search',
            '../fields': [
              'id',
              'credit',
              'description',
              'createdAt',
              'transactionId',
            ],
            '../include': {
              relation: 'transaction',
              scope: {
                fields: ['paid', 'reservationId'],
                include: {
                  relation: 'reservation',
                  scope: {
                    fields: ['code', 'agencyId', 'source', 'status'],
                    include: [{
                      relation: 'agency',
                      fields: ['name'],
                    }, {
                      relation: 'reservationTrips',
                      scope: {
                        fields: ['name', 'companyId'],
                        include: {
                          relation: 'company',
                          fields: ['name'],
                        },
                      },
                    }],
                  },
                }
              },
            },
          }
        }
        filterDefaultValues={{
          createdAt_gte: moment().startOf('days').toDate().toISOString(),
          createdAt_lte: moment().endOf('days').toDate().toISOString(),
        }}
        sort={{ field: 'createdAt', order: 'desc' }}
        bulkActionButtons={false}
      >
        <Datagrid>
          <DateField source="createdAt" locales="vi-VN" showTime />
          <NumberField
            source="credit"
            locales="vi-VN"
            options={{ style: 'currency', currency: 'VND' }}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          />
          <NumberField 
            source="transaction.paid"
            locales="vi-VN"
            options={{ style: 'currency', currency: 'VND' }}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            sortable={false}
          />
          <TextField 
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="transaction.reservation.code"
            sortable={false}
          />
          <TextField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="description"
          />
          <TextField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="transaction.reservation.agency.name" 
            sortable={false}
          />
        </Datagrid>
      </List>
    )
  }
}

export const AccountCreditList = withStyles(styles)(_AccountCreditList)
