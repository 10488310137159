import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const SeatIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="m6.00706,2.58424c-0.32238,-0.03025 -0.65075,0.00256 -0.96875,0.10156c-1.316,0.406 -2.05639,1.80795 -1.65039,3.12695c0.015,0.051 0.06739,0.24202 0.15039,0.54102c0.532,1.926 2.14855,7.78636 3.18555,10.56836c0.145,0.392 0.5195,0.65234 0.9375,0.65234l1.33398,0l-0.68945,2.75781c-0.075,0.299 -0.00541,0.61347 0.18359,0.85547c0.189,0.243 0.47911,0.38477 0.78711,0.38477l7,0c0.308,0 0.59811,-0.14177 0.78711,-0.38477c0.189,-0.242 0.25859,-0.55747 0.18359,-0.85547l-0.68945,-2.75781l0.21875,0c1.378,0 2.5,-1.121 2.5,-2.5c0,-1.379 -1.122,-2.5 -2.5,-2.5l-6.21289,0c-0.213,-0.679 -0.41628,-1.348 -0.61328,-2l3.32617,0l0,-2l-3.91797,0c-0.468,-1.614 -0.8353,-2.94739 -1.0293,-3.65039c-0.09,-0.328 -0.14801,-0.53389 -0.16601,-0.58789c-0.197,-0.638 -0.63166,-1.16066 -1.22266,-1.47266c-0.2955,-0.155 -0.61122,-0.24904 -0.93359,-0.27929zm5.05078,14.99023l3.4375,0l0.5,1.99805l-4.4375,0l0.5,-1.99805z"/>
  </SvgIcon>
)

export default SeatIcon