import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  IconButton,
  CardMedia,
  Typography,
} from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import React, { Component } from 'react'
import { translate, ReferenceManyField } from 'react-admin'

import compose from 'recompose/compose'
import { editRoute } from './actions'
import { connect } from 'react-redux'
import { styles } from './frame-styles'

class RouteEdit extends Component{ 
  constructor(props) {
    super(props)
    this.state = { route: null }
  }

  componentDidMount() {
    const { ids, data, editRoute } = this.props
    if (ids && ids.length > 0) {
      let id = ids[0]
      let route = data[id]
      this.setState({ route })
      editRoute(route)
    }
  }

  render() {
    let { route } = this.state
    if (!route) { return null }
    let distance = route.distance - 0
    distance = (distance / 1000).toFixed(2)
    return (
      <Card>
        <CardHeader
          avatar={
            <Avatar aria-label="Route">
              {route.number}
            </Avatar>
          }
          action={
            <IconButton>
              <MoreVertIcon />
            </IconButton>
          }
          title={ route.name }
          subheader={ route.longName } />
        <CardMedia
          image="/static/images/cards/paella.jpg"
          title="Paella dish"
        />
        <CardContent>
          <Typography component="p">
            { distance } km
          </Typography>
        </CardContent>       
      </Card>
    )
  }
}

class RouteControlFrame extends Component {
  
  render() {
    let { routeId, editRoute } = this.props
    routeId = routeId || 1
    return (
      <div key={ `route-edit-${routeId}}` } style={{
        width: 300,
        height: '100%',
        background: 'white',
        padding: 10,
        overflow: 'auto'
      }}>
        <ReferenceManyField
          addLabel={false}
          basePath="/"
          reference="routes"
          resource="routes"
          filter={{ id: routeId }}
          record={{}}
          fullWidth
        >
          <RouteEdit editRoute={ editRoute }/>
        </ReferenceManyField>
      </div >
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { route } = state.map
      return { route }
    },
    {
      editRoute
    })
)

export default enhance(RouteControlFrame)
