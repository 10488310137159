import React, { Component, Fragment } from 'react'
import LinkField from '../common/LinkField'
import {
  Button,
  Tooltip,
  withStyles,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { Provider } from '../provider'
import printJS from 'print-js'
import PrintIcon from '@material-ui/icons/Print'
import DialogLoading from '../common/DialogLoading'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.2em',
    fontSize: 17
  },
  colorPrimary: {
    color: '#3f51b5'
  }
}

class PrintButton extends Component {

  state = { loading: false }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  handlePrint = async (e) =>  {
    e.preventDefault()
    e.stopPropagation()
    let { id } = this.props

    this.startLoading()
    let resp = await Provider.dataProvider('REMOTE', 'reservations', {
      method: `${id}/pdfticket`,
      requestMethod: 'GET'
    })
    if (resp && resp.data) {
      printJS({ printable: resp.data, type: 'pdf', base64: true })
      this.endLoading()
    }
  }

  renderComponent = () => {
    let { classes, iconFontSize, disabled, translate, type, button, color, ...props } = this.props
    let style = { color: !disabled && '#3f51b5' }
    switch (type) {
      case 'button':
        return <Button
          color={color}
          onClick={this.handlePrint}
          className={classes.button}
          disabled={disabled}
          {...props}
        >
          <PrintIcon className={classes.icon} fontSize={iconFontSize} />
          <span>{translate('resources.reservations.fields.print')}</span>
        </Button>
      case 'menuItem':
        return <MenuItem
          onClick={this.handlePrint}
          style={style}
          disabled={disabled}
          {...props}
        >
          <ListItemIcon style={style}>
            <PrintIcon fontSize={iconFontSize} />
          </ListItemIcon>
          <span>{translate('resources.reservations.fields.print')}</span>
        </MenuItem>
      default:
        return <Tooltip
          title={translate('resources.reservations.fields.print')}
          enterDelay={100}
        >
          <LinkField
            className={classes.iconButton}
            icon
            color={color}
            onClick={this.handlePrint}
            disabled={disabled}
            {...props}
          >
            <PrintIcon fontSize={iconFontSize} />
          </LinkField>
        </Tooltip>
    }
  }

  render() {
    let { loading } = this.state
    return <Fragment>
      {this.renderComponent()}
      {loading && <DialogLoading open={loading} />}
    </Fragment>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(PrintButton)
