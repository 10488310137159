import _ from 'lodash'

export const checkAllPermission = (permissions, roles) => {
  for (let role of roles) {
    if (!(role in permissions)) return false
  }
  return true
}

export const checkPermission = (permissions, roles) => {
  if (!roles) return true
  if (!permissions) return false
  for (let role of roles) {
    if (_.isArray(role)) {
      if (checkAllPermission(permissions, role)) return true
    } else {
      if (role in permissions) return true
    }
  }
  return false
}

