import {
  withStyles,
  Grid,
  Chip,
  Tooltip,
  GridList,
  GridListTile,
} from '@material-ui/core'
import {
  translate,
} from 'react-admin'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import SeatModal from './SelectSeatModal'
import {
  RESERVATION_ADD_PASSENGER_CHARGE,
  doAction,
} from './actions'
import { Provider } from '../provider'
import _ from 'lodash'

const styles = (theme) => {
  return ({
    rightIcon: {
      marginLeft: theme.spacing.unit,
    },
    paper: {
      position: 'absolute',
      width: 650,
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing.unit * 3,
      outline: 'none',
    },
    chip: {
      width: '100%',
    },
    seatMap: {
      minWidth: 180,
    },
  })
}

class SelectSeat extends Component {

  constructor(props) {
    super(props)
    this.state = {
      productId: 0,
      fareId: 0,
      contextModal: {
        selectSeat: {
          open: false,
          seat: ''
        }
      }
    }
  }

  componentDidMount = async () => {
    let { trips, roundType } = this.props
    let companyId = trips && trips[roundType] && trips[roundType].companyId

    if (companyId) {
      let response = await Provider.dataProvider('GET_LIST', 'products', {
        filter: { where: { type: '00SEAT', companyId } },
        sort: {},
        pagination: { page: 1, perPage: 1 },
      })

      if (response) {
        let { data } = response
        if (data && data.length > 0) {
          this.setState({ productId: data[0].id })
        }
      }
    }
  }

  changeSelectSeatModalState = (seat, open) => {
    let { contextModal } = this.state
    let { selectSeat } = contextModal
    selectSeat.open = open
    selectSeat.seat = seat
    this.setState({ ...contextModal, ...selectSeat })
  }

  getSeatState = (itemCode) => {
    let { roundType, reservedSeatMap, inProgressBooking } = this.props
    let { seats } = inProgressBooking && inProgressBooking[roundType]

    if (!itemCode || itemCode === '-') {
      return {
        passengerName: '',
        clickable: false,
        color: 'default'
      }
    }

    if (!reservedSeatMap[roundType]) {
      return null
    }

    if (reservedSeatMap[roundType].includes(itemCode)) {
      return {
        passengerName: '',
        clickable: false,
        color: 'secondary'
      }
    }

    if (seats && Object.keys(seats).includes(itemCode)) {
      let passenger = seats[itemCode]
      let { phone, lastName, firstName } = passenger
      let passengerName = null

      if (lastName && firstName) {
        passengerName = `${lastName}, ${firstName}`
      } else if (phone) {
        passengerName = phone
      }

      return {
        passengerName,
        clickable: true,
        color: 'primary'
      }
    }

    return {
      passengerName: '<New Reservation>',
      clickable: true,
      color: 'default'
    }
  }

  renderSeat = (seatCode) => {
    if (!seatCode) return null

    let { classes } = this.props
    let seatState = this.getSeatState(seatCode)

    return (
      <>
        {
          seatState &&
          <Tooltip title={seatState.passengerName} placement="right">
            <Chip
              className={classes.chip}
              color={seatState.color}
              label={seatCode}
              clickable={seatState.clickable}
              onClick={(e) => seatState.clickable &&
                  this.changeSelectSeatModalState(e.target.textContent, true)}
            />
          </Tooltip>
        }
      </>
    )
  }

  renderFloor = () => {
    let { seatMap, roundType, classes, translate } = this.props
    if (!seatMap) {
      return <Grid item xs={12}> </Grid>
    }

    if (!seatMap[roundType]) {
      return <Grid item xs={12}> </Grid>
    }

    let { codesFistFloor, codesSecondFloor } = seatMap[roundType]
    if (!codesFistFloor && !codesSecondFloor) {
      return <Grid item xs={12}> </Grid>
    }

    return (
      <Grid container
        item
        xs={12}
        spacing={8}>
        {!_.isEmpty(codesFistFloor) && <Grid item xs={4}>
          <span className={classes.labelFloor}>{translate('resources.seatMaps.fist_floor')}</span>
          <div className={classes.containerSeat}>
            <GridList
              cellHeight={32}
              cols={codesFistFloor[0].length}
              className={classes.seatMap}
            >
              {codesFistFloor.map((floor, row) => 
                floor.map((seatCode, col) =>
                  <GridListTile key={`${row}-${col}`}>
                    {this.renderSeat(seatCode)}
                  </GridListTile>
                )
              )}
            </GridList>
          </div>
        </Grid>}
        {!_.isEmpty(codesSecondFloor) && <Grid item xs={4}>
          <span className={classes.labelFloor}>{translate('resources.seatMaps.second_floor')}</span>
          <div className={classes.containerSeat}>
            <GridList
              cellHeight={32}
              cols={codesSecondFloor[0].length}
              className={classes.seatMap}
            >
              {codesSecondFloor.map((floor, row) => 
                floor.map((seatCode, col) =>
                  <GridListTile key={`${row}-${col}`}>
                    {this.renderSeat(seatCode)}
                  </GridListTile>
                )
              )}
            </GridList>
          </div>
        </Grid>}
      </Grid>
    )
  }

  onSeatSelected = ({ firstName, lastName, phone, email, address, seat, fare, fareId }) => {
    let { doAction, roundType } = this.props
    let passenger = { firstName, lastName, phone, email, address }

    let charge = {
      type: '10NO_REFUND',
      itemDetail: { itemCode: seat, productId: 1, fareId, fare }
    }
    doAction(RESERVATION_ADD_PASSENGER_CHARGE, null, { roundType, passenger, charge })
  }

  render() {
    let { trips, roundType } = this.props
    let { contextModal, productId } = this.state

    let seat = contextModal.selectSeat.seat

    return (
      <Grid container spacing={8}
        justify="center"
        alignItems="center">

        {trips && this.renderFloor()}

        {
          productId &&
          <SeatModal
            roundType={roundType}
            open={contextModal.selectSeat.open}
            seat={seat}
            productId={productId}
            onSave={(data) => {
              this.onSeatSelected(data)
              this.changeSelectSeatModalState(contextModal.selectSeat.seat, false)
            }}
            onClose={() => this.changeSelectSeatModalState(contextModal.selectSeat.seat, false)} />
        }

      </Grid>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { reservation } = state
      let { trips, seatMap, inProgressBooking, reservedSeatMap } = reservation
      return { trips, seatMap, inProgressBooking, reservedSeatMap }
    },
    { doAction }
  )
)

export default enhance(SelectSeat)
