import React, { Component, Fragment } from 'react'
import { Chip, withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { format } from '../utils/number'
import { Provider } from '../provider'
import { translate, WithPermissions } from 'react-admin'
import { getCurrentAgency } from '../utils/commonUtil'
import { APPBAR_CHIP } from '../common/mqttEventEmitter'
import withMQTT from '../mqtt/withMQTT'
import { MessageTypes } from '../viewtrip/index'
import * as ReservationStatus from '../common/reservation-status'
import moment from 'moment'
import * as permission from '../utils/permission'

const styles = {
  depositChip: {
    color: 'white',
    marginRight: 4,
    fontSize: 10,
  },
  earningChip: {
    color: 'white',
    fontSize: 10,
  },
}

class AppBarChips extends Component {

  state = {}

  consumeNewReservation = () => {
    let { mqttEmitter, subscribe } = this.props
    let agency = getCurrentAgency() || {}
    let topic = `agency/${agency.id}/reservation/+`
    this.setState({ topic })
    subscribe(topic)
    mqttEmitter.on(APPBAR_CHIP, (message) => this.onMessage(message))
  }

  getReservationById = async id => {
    let result
    let reservation = await Provider.dataProvider('GET_ONE', 'reservations', {
      id,
      filter: {
        fields: ['id', 'status'],
      }
    })
    if (reservation && reservation.data) {
      result = reservation.data
    }
    return result
  }

  onMessage = async (message) => {
    // Handle notification for company
    let data = JSON.parse(message)
    let { name } = data
    let { payload } = data
    switch (name) {
      case MessageTypes.RESERVATION_CHANGED: {
        let reservation = await this.getReservationById(payload.reservationId)
        let { status } = reservation
        if (status === ReservationStatus.CONFIRMED) {
          this.loadValue()
        }
        break
      }
      default:
        break
    }
  }

  //componentWillUnmount() {
  //let { unsubscribe } = this.props
  //let { topic } = this.state
  //unsubscribe(topic)
  //}

  async componentDidMount() {
    await this.loadValue()
    //this.consumeNewReservation()
  }

  loadValue = async () => {
    const { permissions } = this.props
    let isAdmin = permission.isAdmin(permissions)
    if(isAdmin) return
    let fromDate = moment().startOf('day')
    let toDate = moment().endOf('day')
    let deposit = await this.getValue({ resource: 'financeaccounts', method: '/getDeposit' })
    let earningMoney = await this.getValue({
      resource: 'financeaccounts',
      method: 'getEarningMoney',
      filter: { fromDate, toDate }
    })
    this.setState({ deposit, earningMoney})
  }

  getValue = async renderValue => {
    let { resource, method, filter } = renderValue
    let result = await Provider.dataProvider('REMOTE', resource, {
      method,
      requestMethod: 'GET',
      data: { ...filter },
    })

    if (result && result.data) {
      return result.data
    }

    return 0
  }

  render() {
    let { classes, permissions, translate } = this.props
    let { deposit = 0, earningMoney = 0 } = this.state
    deposit = format(parseFloat(deposit))
    earningMoney = format(parseFloat(earningMoney))
    let isCompanyRole = permission.isCompanyRole(permissions)
    let isAgencyRole = permission.isAgencyRole(permissions)
    return (isCompanyRole || isAgencyRole ) ? <Fragment>
      {isAgencyRole && <Chip
        className={classes.depositChip}
        label={`${translate('resources.financeaccounts.fields.deposit')}: ${deposit}`}
        size="small"
        variant="outlined"
      />}
      <Chip
        label={`${translate('resources.dashboard.revenue')}: ${earningMoney}`}
        size="small"
        variant="outlined"
        className={classes.earningChip}
      />
    </Fragment> : null
  }
}

const AppBarChipWithPermission = (props) => <WithPermissions
  render={({ permissions }) => permissions && <AppBarChips permissions={permissions} {...props} />}
/>

const enhance = compose(
  withStyles(styles),
  translate,
  //withMQTT({ eventListener: APPBAR_CHIP }),
)

export default enhance(AppBarChipWithPermission)
