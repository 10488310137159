import papa from 'papaparse'
import { readText } from './file'
import isEmpty from 'lodash/isEmpty'

export async function readCsv(file) {
  const text = await readText(file)
  let csv = papa.parse(text, { header: true })
  if (!csv || isEmpty(csv)) return []
  let data = csv.data
  return data
}


export function arrayToGrid(array, header = true, labelColumn = '') {
  let columns = [], rows = []
  if (!Array.isArray(array) || array.length === 0) {
    return { rows, columns }
  }
  
  const head = array[0]
  if (header) {
    columns = Object.keys(head).map(v => { return { key: v, name: v } })
    rows = array.map((v, ridx) => ({ id: ridx, ...v}))
  } else {
    const len = head.length
    for (let idx = 0; idx < len; idx++) {
      columns = [...columns, { key: idx, name: `${labelColumn} ${((+idx)+1).toString()}` }]
    }
    rows = array.map((v, ridx) => {
      let x = {...v}
      return { id: ridx, ...x }
    })
  }
  return { rows, columns }
}

export function csvToGrid(text, options = { header: true }) {
  let csv = papa.parse(text, options)
  if (!csv || isEmpty(csv)) return {}
  let { data } = csv

  let { rows, columns } = arrayToGrid(data, options.header)
  return { data, columns, rows }
}

export function gridToArray({columns, rows}) {
  //let csvColumns = columns.map(v => v.name)
  let csvColumns = columns.map(v => v.key)
  let colLen = csvColumns.length
  let rowLen = rows.length

  let csvRows = []
  for (let idx = 0; idx < rowLen; idx++) {
    let row = rows[idx]
    let array = []
    for (let cidx = 0; cidx < colLen; cidx++) {
      let col = csvColumns[cidx]
      array = [...array, row[col]]
    }
    csvRows.push(array)
  }
  return { columns: csvColumns, rows: csvRows }
}
export function gridToCsv({columns, rows}) {
  let data = gridToArray({ columns, rows })
  const csv = papa.unparse({fields: data.columns, data: data.rows }, {delimiter: '\t'})
  return csv
}
