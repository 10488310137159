import React, { Component, Fragment } from 'react'
import {
  translate,
  TextField,
  ReferenceField,
  Datagrid,
  DateField,
  NumberField,
  FunctionField,
  SimpleShowLayout,
  SimpleForm,
  ImageInput,
  ImageField,
} from 'react-admin'
import {
  Typography,
  withStyles,
  Grid,
  Divider,
  Chip,
  Button,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField as TextInput,
  List,
} from '@material-ui/core'
import Pagination from '../common/Pagination'
import { Provider } from '../provider'
import moment from 'moment'
import compose from 'recompose/compose'
import { StatusField } from './StatusField'
import { StatusField as StatusReservationField, CodeField, ProductField } from '../reservation'
import * as ReservationStatus from '../common/reservation-status'
import { formatCurrency } from '../utils/formatUtil'
import _ from 'lodash'
import EditIcon from '@material-ui/icons/Edit'
import CustomToolbar from '../common/CustomToolbarForm'
import ConfirmDialog from '../common/ConfirmDialog'
import CloseDialog from './CloseDialog'

const styles = () => ({
  listWidth: {
    width: '100%'
  },
  chipTrip: {
    margin: 4,
  },
  routeChip: {
    margin: 8,
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  gridLeft: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 24,
  },
  gridRight: {
    padding: 24,
  },
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 16,
  },
  card: {
    margin: 8
  },
  label: {
    paddingLeft: '0.5em',
    marginBottom: 6,
    marginTop: 6,
  }
})

const styleAction = () => ({
  container: {
    display: 'inline-flex',
    fontSize: 14,
    alignItems: 'center',
    marginRight: 14,
  },
  text: {
    color: 'white',
    marginBottom: 3,
    marginTop: 3,
    fontSize: 14,
  },
})

const _ActionList = ({
  classes,
  translate,
  handleOpenCloseDialog,
  isClosed,
}) => {
  return (
    <div className={classes.container}>
      <Button
        disabled={isClosed}
        variant="contained"
        color="primary"
        onClick={() => handleOpenCloseDialog() }
      >
        <span className={classes.text}>
          {translate('button.close')}
        </span>
      </Button>
    </div>
  )
}
const enhanceAction = compose(withStyles(styleAction), translate)
const ActionList = enhanceAction(_ActionList)

const listNoteStyle = {
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  noteText: {
    wordBreak: 'break-all'
  },
  addNoteForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: 8,
  },
}

class _ListNote extends Component {

  onChangeText = evt => {
    let { value } = evt.target
    let { onChangeNote } = this.props
    onChangeNote(value)
  }

  render() {
    let { notes=[], classes, translate, newestNote } = this.props
    return <div>
      <List>
        {!_.isEmpty(notes) && notes.map((ele={}, idx) => {
          let { note, createdAt, createdBy } = ele
          return <ListItem key={idx}>
            <ListItemIcon><EditIcon /></ListItemIcon>
            <ListItemText className={classes.noteText} primary={'['+translate('resources.possessions.fields.notes')+'] '+createdBy+' '+createdAt+': '+note } />
          </ListItem>
        })}
      </List>
      <TextInput
        label={translate('resources.possessions.fields.note')}
        fullWidth
        onChange={this.onChangeText}
        value={newestNote}
        multiline={true}
        variant="filled"
      />
    </div>
  }
}

const enhanceListNote = compose(withStyles(listNoteStyle), translate)
const ListNote = enhanceListNote(_ListNote)

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: {},
      data: [],
      page: 1,
      perPage: 5,
      totalList: 0,
      pagedItems: [],
      newestNote: null,
      isClosed: false,
      open: false,
      openConfirm: false,
      balanceCloseReal: 0,
      note: null,
    }
  }

  loadData = async () => {
    let { record } = this.props
    let { id } = record
    let resp = await Provider.dataProvider('GET_ONE', 'possessions', {
      id,
      filter: {
        include: [{
          relation: 'reservations',
          scope: {
            order: 'id DESC',
            include: [{
              relation: 'paymentInformation',
              scope: {
                field: ['agencyKeep', 'debt', 'paid', 'reservationId', 'total']
              }
            },{
              relation: 'transactions',
              scope: {
                order: 'id DESC',
                fields: ['id', 'paymentMethodId', 'total', 'paid'],
                include: [{
                  relation: 'paymentMethod',
                  scope: {
                    fields: ['id', 'code']
                  }
                }]
              }
            },{
              relation: 'charges',
              scope: {
                where: { cancelReference: null },
                fields: ['id', 'itemDetail', 'reservationId', 'status']
              }
            },{
              relation: 'reservationTrips',
              scope: {
                fields: ['id', 'name', 'departureTime']
              }
            }],
            fields: [
              'charges',
              'code',
              'id',
              'source',
              'status',
              'trips',
              'createdAt',
              'agencyId',
              'paymentInformation',
              'reservationTrips',
              'transactions',
            ]
          }
        }],
        fields: [
          'agencyId',
          'balanceStart',
          'balanceClose',
          'balanceCloseReal',
          'balanceDiff',
          'chargeCount',
          'chargeCancelledCount',
          'closeAt',
          'createdBy',
          'deviceId',
          'id',
          'images',
          'name',
          'notes',
          'receiptsExpenses',
          'reservationCancelledCount',
          'reservationCount',
          'reservations',
          'source',
          'startAt',
          'status',
          'totalExpense',
          'totalRevenue',
          'totalIncome',
          'totalPaymentAmount',
          'otherExpenses',
          'otherIncome',
        ]
      },
    })
    if (!_.isEmpty(resp) && !_.isEmpty(resp.data)) {
      let { data } = resp
      let { reservations=[], status } = data
      let totalList = reservations.length
      let { page, perPage } = this.state
      let offset = (page - 1) * perPage
      let pagedItems = _.drop(reservations, offset).slice(0, perPage)
      let isClosed = status === '20CLS' ? true : false
      this.setState({
        pagedItems,
        totalList,
        record: data,
        data: reservations,
        isClosed,
      })
    }
  }

  componentDidMount() {
    this.loadData()
  }

  setPage = page => {
    let { data: items, perPage} = this.state
    this.setState({ page })
    this.getData(items, page, perPage)
  }

  setPerPage = perPage => {
    let { data: items, page } = this.state
    this.setState({ perPage })
    this.getData(items, page, perPage)
  }

  getData = (items, page, perPage) => {
    let offset = (page - 1) * perPage
    let pagedItems = _.drop(items, offset).slice(0, perPage)
    this.setState({ pagedItems })
  }

  onChangeNote = newestNote => this.setState({ newestNote })

  convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

  processFile = async file => {
    let base64Picture = await this.convertFileToBase64(file)
    let { name, type } = file
    return {
      src: base64Picture,
      name,
      type,
    }
  }

  handleSave = async (images=[]) => {
    let imageBase64 = images.map(id => id = {})
    await _.reduce(images, async (result, image, index) => {
      let { rawFile } = image
      if(!_.isEmpty(rawFile)) {
        result = await this.processFile(rawFile)
        imageBase64[index] = result
      } else {
        imageBase64[index] = image
      }
    }, 0)
    let { newestNote } = this.state
    if (newestNote) {
      this.updatePosSessionNote(newestNote)
    }
    if (imageBase64) {
      this.updatePosSessionImages(imageBase64)
    }
  }

  updatePosSessionImages = async (images) => {
    let { record={} } = this.state
    let { id } = record
    let res = await Provider.dataProvider('REMOTE', 'possessions', {
      method: '/saveImages',
      requestMethod: 'POST',
      data: {
        posSessionId: id,
        images,
      }
    })
    if (!_.isEmpty(res) && !_.isEmpty(res.data)) {
      let { data } = res
      this.setState({ record: data })
    }
  }

  updatePosSessionNote = async (newestNote) => {
    let { record={} } = this.state
    let { id } = record
    let res = await Provider.dataProvider('REMOTE', 'possessions', {
      method: '/addNote',
      requestMethod: 'POST',
      data: {
        posSessionId: id,
        note: newestNote,
      }
    })
    if (!_.isEmpty(res) && !_.isEmpty(res.data)) {
      let { data } = res
      this.setState({ newestNote: '', record: data })
    }
  }

  handleCloseSession = async () => {
    let { record={}, balanceCloseReal=0, note } = this.state
    let { history } = this.props
    if (!_.isEmpty(record)) {
      let { id } = record
      await Provider.dataProvider('REMOTE', 'possessions', {
        method: 'closeSession',
        requestMethod: 'POST',
        data: {
          id,
          balanceCloseReal,
          note,
        }
      })
      this.setState({ isClosed: true })
    }
    this.handleCloseConfirm()
    history.push('/possessions')
  }

  handleOpenConfirm = (balanceCloseReal, note) => {
    this.setState({ openConfirm: true, balanceCloseReal, note })
  }

  handleCloseConfirm = () => {
    this.setState({ openConfirm: false })
  }

  handleOpenCloseDialog = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  render() {
    let { classes, translate } = this.props
    let {
      record={},
      pagedItems=[],
      page, perPage,
      totalList,
      newestNote,
      isClosed,
      open,
      openConfirm,
    } = this.state
    let ids = Object.keys(pagedItems)
    let { startAt , closeAt, notes, id } = record
    const extra = {
      resource: 'possessions',
      fullWidth: true,
    }
    return <Card className={classes.card}>
      <CardHeader action={<ActionList
        isClosed={isClosed}
        handleOpenCloseDialog={this.handleOpenCloseDialog}
      />}
      />
      <CardContent>
        <Grid container>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" className={classes.tripTitle}>
                {translate('resources.possessions.infoDetail')}
              </Typography>
              <Grid container md={12}>
                <Grid item md={7}>
                  <SimpleShowLayout
                    fullWidth
                    record={record}
                    basePath="/possessions"
                    resource="possessions"
                  >
                    <TextField source="name" />
                    { startAt && <DateField source="startAt" locales="vi-VN" showTime /> }
                    { closeAt && <DateField source="closeAt" locales="vi-VN" showTime /> }
                    <ReferenceField
                      headerClassName={classes.textCenter}
                      cellClassName={classes.textCenter}
                      source="deviceId"
                      reference="devices"
                      linkType={false}
                      allowEmpty
                    >
                      <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField
                      reference="users"
                      source="createdBy"
                      allowEmpty
                      linkType={false}
                      headerClassName={classes.textCenter}
                      cellClassName={classes.textCenter}
                    >
                      <TextField source="username" />
                    </ReferenceField>
                    <ReferenceField
                      source="source"
                      reference="reservationsources"
                      linkType={false}
                      allowEmpty
                    >
                      <TextField source="name" />
                    </ReferenceField>
                  </SimpleShowLayout>
                </Grid>
                <Grid item md={5}>
                  <SimpleShowLayout
                    fullWidth
                    record={record}
                    basePath="/possessions"
                    resource="possessions"
                  >
                    <ReferenceField
                      source="status"
                      basePath="possessionstatuses"
                      reference="possessionstatuses"
                      linkType={false}
                      allowEmpty
                      headerClassName={classes.textCenter}
                      cellClassName={classes.textCenter}
                    >
                      <StatusField source="name" />
                    </ReferenceField>
                    <NumberField source="reservationCount" />
                    <NumberField source="reservationCancelledCount" />
                    <NumberField source="chargeCount" />
                    <NumberField source="chargeCancelledCount" />
                  </SimpleShowLayout>
                </Grid>
              </Grid>
              <Grid container md={12}>
                <Grid item md={7}>
                  <SimpleShowLayout
                    fullWidth
                    record={record}
                    basePath="/possessions"
                    resource="possessions"
                  >
                    <NumberField source="balanceStart" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                    <NumberField source="totalExpense" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                    <NumberField source="balanceClose" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                    <NumberField source="totalPaymentAmount" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                  </SimpleShowLayout>
                </Grid>
                <Grid item md={5}>
                  <SimpleShowLayout
                    fullWidth
                    record={record}
                    basePath="/possessions"
                    resource="possessions"
                  >
                    <NumberField source="totalRevenue" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                    <NumberField source="totalIncome" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                    <NumberField source="balanceCloseReal" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                    <NumberField source="balanceDiff" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
                  </SimpleShowLayout>
                </Grid>
              </Grid>
            </Grid>
            <Grid  md={6}>
              <SimpleForm
                toolbar={<CustomToolbar style={{ margin: 16 }} />}
                record={record}
                save={({ images }) => this.handleSave(images)}
                {...extra}
              >
                <ListNote
                  newestNote={newestNote}
                  notes={notes}
                  onChangeNote={this.onChangeNote}
                  {...extra}
                />
                <ImageInput
                  source="images"
                  multiple
                  {...extra}
                >
                  <ImageField source="src" />
                </ImageInput>
              </SimpleForm>
            </Grid>
          </Grid>
          <Divider style={{ width: '100%' }} />
          <Grid item xs={12} md={6} className={classes.gridLeft}>
            <Typography variant="h4" className={classes.tripTitle}>
              {translate('resources.possessions.other_expenses')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={classes.gridRight}>
            <Typography variant="h4" className={classes.tripTitle}>
              {translate('resources.possessions.other_income')}
            </Typography>
          </Grid>
          <Divider style={{ width: '100%' }} />
          <Grid item xs={12}>
            <Typography variant="h4" className={classes.tripTitle}>
              {translate('resources.possessions.reservations')}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {pagedItems.length > 0 ? <Datagrid
              currentSort={{ field: 'id', order: 'desc' }}
              resource="possessions"
              ids={ids}
              data={pagedItems}
              rowClick={row => {
                let data = pagedItems[row]
                let { id } = data
                return `/reservations/${id}/show`
              }}
            >
              <FunctionField
                source="code"
                render={record => <CodeField record={record} />}
              />
              <FunctionField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                sortable={false}
                source="trips"
                render={({ reservationTrips = [], charges }) => {
                  if (reservationTrips.length > 0) {
                    return reservationTrips.map((record, idx) => <Chip
                      size="small"
                      className={classes.routeChip}
                      key={idx}
                      label={`[${moment(record.departureTime).format('HH:mm DD/MM')}] ${record.name ? (record.name.length > 20 ? `${record.name.substring(0, 17)}...` : record.name) : ''}`}
                    />
                    )
                  } else if (charges && charges.length > 0) {
                    let firstCharge = charges[0]
                    if (!firstCharge) return ''
                    let { itemDetail={} } = firstCharge
                    let { routeId } = itemDetail
                    return <ReferenceField
                      basePath="/routes"
                      reference="routes"
                      allowEmpty
                      record={{ routeId }}
                      source="routeId"
                      linkType={false}
                    >
                      <FunctionField render={({ name }) => <Chip
                        size="small"
                        className={classes.routeChip}
                        label={ name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''}
                      />}
                      />
                    </ReferenceField>
                  }
                }}
              />
              <FunctionField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                source="productAmount"
                render={record => <ProductField record={record} />}
              />
              <ReferenceField
                source="status"
                basePath="reservationstatuses"
                reference="reservationstatuses"
                linkType={false}
                allowEmpty
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
              >
                <StatusReservationField source="name" />
              </ReferenceField>
              <FunctionField
                source="paidCash"
                headerClassName={classes.textEnd}
                cellClassName={classes.textEnd}
                sortable={false}
                render={record => {
                  let { paymentInformation={}, status, transactions=[] } = record
                  let { agencyKeep } = paymentInformation
                  let paidCash = 0, totalCash = 0
                  if (!_.isEmpty(transactions)) {
                    _.reduce(transactions, (result, transaction) => {
                      let { paymentMethod={}, paid, total } = transaction
                      let { code } = paymentMethod
                      if (code === 'CASH') {
                        paidCash += paid
                        totalCash = (status === '30CANCELLED' || status === '60EXPIRED') ? totalCash : totalCash + total
                      }
                    }, 0)
                  }
                  let debt = totalCash - paidCash
                  let debtStyle = ReservationStatus.outlineStyle[status]
                  return <Tooltip
                    title={
                      <Fragment>
                        {[
                          { translateKey: 'resources.reservations.totalFare', value: totalCash },
                          { translateKey: 'resources.reservations.paid', value: paidCash },
                        ].map(el => {
                          return <p key={el.translateKey}>
                            {translate(el.translateKey)}: <b>{el.value ? formatCurrency(el.value) : '0đ'}</b>
                          </p>
                        })}
                        {(status !== '60EXPIRED' && agencyKeep > 0) && <p>
                          {translate('resources.reservations.agencyKeep')}: <b>{agencyKeep ? formatCurrency(agencyKeep) : '0đ'}</b>
                        </p>}
                        {(debt < 0 || status === '30CANCELLED' || status === '60EXPIRED') ? <p>
                          {translate('resources.reservations.refund')}: <b>{debt ? formatCurrency(Math.abs(debt)) : '0đ'}</b>
                        </p> : <p>
                          {translate('resources.reservations.debt')}: <b>{debt ? formatCurrency(debt) : '0đ'}</b>
                        </p>}
                      </Fragment>
                    }
                  >
                    <Chip
                      variant="outlined"
                      style={debtStyle}
                      label={totalCash ? formatCurrency(totalCash) : '0đ'}
                    />
                  </Tooltip>
                }}
              />
              <FunctionField
                source="paidOnline"
                headerClassName={classes.textEnd}
                cellClassName={classes.textEnd}
                sortable={false}
                render={record => {
                  let { paymentInformation={}, status, transactions=[] } = record
                  let { agencyKeep } = paymentInformation
                  let paidOnline = 0, totalOnline = 0
                  if (!_.isEmpty(transactions)) {
                    _.reduce(transactions, (result, transaction) => {
                      let { paymentMethod={}, paid, total } = transaction
                      let { code } = paymentMethod
                      if (code !== 'CASH') {
                        paidOnline += paid
                        totalOnline = (status === '30CANCELLED' || status === '60EXPIRED') ? totalOnline : totalOnline + total
                      }
                    }, 0)
                  }
                  let debt = totalOnline - paidOnline
                  let debtStyle = ReservationStatus.outlineStyle[status]
                  return <Tooltip
                    title={
                      <Fragment>
                        {[
                          { translateKey: 'resources.reservations.totalFare', value: totalOnline },
                          { translateKey: 'resources.reservations.paid', value: paidOnline },
                        ].map(el => {
                          return <p key={el.translateKey}>
                            {translate(el.translateKey)}: <b>{el.value ? formatCurrency(el.value) : '0đ'}</b>
                          </p>
                        })}
                        {(status !== '60EXPIRED' && agencyKeep > 0) && <p>
                          {translate('resources.reservations.agencyKeep')}: <b>{agencyKeep ? formatCurrency(agencyKeep) : '0đ'}</b>
                        </p>}
                        {(debt < 0 || status === '30CANCELLED' || status === '60EXPIRED') ? <p>
                          {translate('resources.reservations.refund')}: <b>{debt ? formatCurrency(Math.abs(debt)) : '0đ'}</b>
                        </p> : <p>
                          {translate('resources.reservations.debt')}: <b>{debt ? formatCurrency(debt) : '0đ'}</b>
                        </p>}
                      </Fragment>
                    }
                  >
                    <Chip
                      variant="outlined"
                      style={debtStyle}
                      label={totalOnline ? formatCurrency(totalOnline) : '0đ'}
                    />
                  </Tooltip>
                }}
              />
              <FunctionField
                source="paid"
                headerClassName={classes.textEnd}
                cellClassName={classes.textEnd}
                sortable={false}
                render={record => {
                  let { paymentInformation={}, status } = record
                  let { total, debt = 0, paid, agencyKeep } = paymentInformation
                  let debtStyle = ReservationStatus.outlineStyle[status]
                  return <Tooltip
                    title={
                      <Fragment>
                        {[
                          { translateKey: 'resources.reservations.totalFare', value: total },
                          { translateKey: 'resources.reservations.paid', value: paid },
                        ].map(el => {
                          return <p key={el.translateKey}>
                            {translate(el.translateKey)}: <b>{el.value ? formatCurrency(el.value) : '0đ'}</b>
                          </p>
                        })}
                        {(status !== '60EXPIRED' && agencyKeep > 0) && <p>
                          {translate('resources.reservations.agencyKeep')}: <b>{agencyKeep ? formatCurrency(agencyKeep) : '0đ'}</b>
                        </p>}
                        {debt < 0 ? <p>
                          {translate('resources.reservations.refund')}: <b>{debt ? formatCurrency(Math.abs(debt)) : '0đ'}</b>
                        </p> : <p>
                          {translate('resources.reservations.debt')}: <b>{debt ? formatCurrency(debt) : '0đ'}</b>
                        </p>}
                      </Fragment>
                    }
                  >
                    <Chip
                      variant="outlined"
                      style={debtStyle}
                      label={total ? formatCurrency(total) : '0đ'}
                    />
                  </Tooltip>
                }}
              />
            </Datagrid> : <span>{translate('resources.possessions.no_reservation')} </span>}
          </Grid>
          {totalList > 0 && <Pagination
            page={page}
            perPage={perPage}
            setPage={this.setPage}
            setPerPage={this.setPerPage}
            total={totalList}
          />}
        </Grid>
        <ConfirmDialog
          title={translate('resources.possessions.close_session')}
          content={translate('resources.possessions.close_session_detail')}
          open={openConfirm}
          onClose={this.handleCloseConfirm}
          onOk={this.handleCloseSession}
        />
        <CloseDialog
          title={translate('resources.possessions.close_session')}
          posSessionId={id}
          open={open}
          onClose={this.handleClose}
          onOpenConfirm={this.handleOpenConfirm}
        />
      </CardContent>
    </Card>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(FormSave)
