import { GET_MANY, GET_LIST } from 'react-admin'

// -----------------------------------------------------------------------------
export const changeAmount = (value) => {
  return ({
    type: '@@redux-form/CHANGE',
    meta: {
      form: 'record-form',
      field: 'amount',
      touch: false,
      persistentSubmitErrors: false
    },
    payload: value
  })
}

// -----------------------------------------------------------------------------
export const GET_LIST_FARE_RULES = 'GET_LIST_FARE_RULES'
export const GET_LIST_FARE_RULES_FAILURE = 'GET_LIST_FARE_RULES_FAILURE'
export const GET_LIST_FARE_RULES_SUCCESS = 'GET_LIST_FARE_RULES_SUCCESS'

export const getListFareRules = (start, done, params) => {
  return ({
    type: GET_FARE_RULES,
    payload: params,
    meta: {
      resource: 'farerules',
      fetch: GET_LIST,
    },
    start,
    done,
  })
}

// -----------------------------------------------------------------------------
export const GET_FARE_RULES = 'GET_FARE_RULES'
export const GET_FARE_RULES_FAILURE = 'GET_FARE_RULES_FAILURE'
export const GET_FARE_RULES_SUCCESS = 'GET_FARE_RULES_SUCCESS'

export const getSelectedFareRules = (start, done, params) => {
  return ({
    type: GET_FARE_RULES,
    payload: {
      ids: params.ids
    },
    meta: {
      resource: 'farerules',
      fetch: GET_MANY,
    },
    start,
    done,
  })
}

