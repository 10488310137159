import { GET_LIST } from 'react-admin'
export const CHANGE_TOTAL_SEAT = 'CHANGE_TOTAL_SEAT'
export const CHANGE_VEHICLE_CLASS = 'CHANGE_VEHICLE_CLASS'
export const SET_TOTAL_SEAT = 'SET_TOTAL_SEAT'
export const SET_VEHICLE_CLASS = 'SET_VEHICLE_CLASS'
export const GET_SEAT_MAP_TEAMPLATE = 'GET_SEAT_MAP_TEAMPLATE'
export const GET_SEAT_MAP_TEAMPLATE_SUCCESS = 'GET_SEAT_MAP_TEAMPLATE_SUCCESS'
export const GET_SEAT_MAP_TEAMPLATE_FAILURE = 'GET_SEAT_MAP_TEAMPLATE_FAILURE'
export const SET_SEAT_MAP_TEAMPLATE = 'SET_SEAT_MAP_TEAMPLATE'
export const HAS_SEAT_MAP = 'HAS_SEAT_MAP'
export const FETCH_SEAT_MAP = 'FETCH_SEAT_MAP'

export const changeTotalSeat = (totalSeat, filterSeatMapTemplate) => ({
  type: CHANGE_TOTAL_SEAT,
  totalSeat,
  filterSeatMapTemplate
})

export const changeVehicleClass = (vehicleClass, filterSeatMapTemplate) => ({
  type: CHANGE_VEHICLE_CLASS,
  vehicleClass,
  filterSeatMapTemplate
})

export const setTotalSeat = totalSeat => ({
  type: SET_TOTAL_SEAT,
  totalSeat
})

export const setVehicleClass = vehicleClass => ({
  type: SET_VEHICLE_CLASS,
  vehicleClass
})

export const getSeatMapTemplates = (filter) => ({
  type: GET_SEAT_MAP_TEAMPLATE,
  payload: {
    filter,
    pagination: {},
    sort: {}
  },
  meta: {
    resource: 'seatmaptemplates',
    fetch: GET_LIST
  }
})

export const setSeatMapTemplates = (seatmaptemplates) => ({
  type: SET_SEAT_MAP_TEAMPLATE,
  seatmaptemplates
})

export const hasSeatMap = (updateHasSeatMap) => ({
  type: HAS_SEAT_MAP,
  updateHasSeatMap,
})

export const fetchSeatMap = () => ({
  type: FETCH_SEAT_MAP,
  payload: {
    filter: {},
    pagination: {},
    sort: {},
  },
  meta: {
    resource: 'seatmaps',
    fetch: 'GET_LIST',
  }
})