import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import {
  SimpleForm,
  TextInput,
  showNotification,
  required,
  translate,
} from 'react-admin'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { validate } from './validate'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faKey } from '@fortawesome/free-solid-svg-icons'
import { reset, SubmissionError } from 'redux-form'

const style = () => ({
  label: {
    paddingLeft: '0.5em'
  }
})

class ChangePasswordButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  openDialog = () => {
    this.setState({
      open: true
    })
  }

  closeDialog = () => {
    let { reset } = this.props
    reset('change-password')
    this.setState({
      open: false
    })
  }

  save = async record => {
    let { showNotification, id, reset } = this.props
    await Provider.dataProvider('REMOTE', 'users', {
      method: 'changePassword',
      requestMethod: 'PUT',
      data: { ...record, id }
    }).then(
      res => {
        showNotification('notification.change_password_success')
      }
    ).catch(
      error => {
        error = error.body.error
        let fieldErro = error.field
        if (fieldErro) {
          throw new SubmissionError({
            [fieldErro]: error.message,
          })
        } else {
          showNotification('notification.change_password_failure', 'warning')
        }
      }
    )
    reset('change-password')
    this.setState({
      open: false
    })
  }

  render() {
    let { open } = this.state
    const { translate, classes } = this.props
    let extra = { fullWidth: true, resource: 'users' }
    return <>
      <Button color="primary" onClick={this.openDialog}>
        <FontAwesomeIcon icon={faKey} />
        <span className={classes.label}>{translate('button.change_password')}</span>
      </Button>
      <Dialog
        open={open}
        onClose={this.closeDialog}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{translate('resources.users.page.change_password')}</DialogTitle>
        <DialogContent>
          <SimpleForm
            resource="users"
            validate={validate}
            form="change-password"
            save={this.save}
          >
            <TextInput source="oldPassword" type="password" {...extra} validate={required()} />
            <TextInput source="newPassword" type="password" {...extra} validate={required()} />
            <TextInput source="confirmPassword" type="password" {...extra} validate={required()} />
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  }
}

const enhance = compose(
  withStyles(style),
  translate,
  connect(null, { showNotification, reset })
)

export default enhance(ChangePasswordButton)
