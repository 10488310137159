import React, { Component } from 'react'
import QRCode from 'qrcode.react'

class QRCodeVehicle extends Component {

  render() {
    let { input } = this.props
    return (
      <QRCode value={input.value} />
    )
  }

}

export default QRCodeVehicle