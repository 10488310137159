import { stringify } from 'query-string'
import {
  fetchUtils,
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  UPDATE_MANY,
  DELETE,
  DELETE_MANY,
} from 'react-admin'
import isArray from 'lodash/isArray'
import { handleError } from './error'
import moment from 'moment'

// const TEST_URL = document.API_URL
export const ROOT_URL = process.env.REACT_APP_API_URL

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json; charset=utf-8' })
  }

  const token = localStorage.getItem('token')
  if (token) {
    options.headers.set('Authorization', `${token}`)
    options.headers.set('Accept', 'application/json')
  }

  return fetchUtils.fetchJson(url, options)
}

let filterFromParams = params => {
  const { page, perPage } = params.pagination
  let filter
  if (params.filter && params.filter.where) {
    filter = { ...params.filter, where: { ...params.filter.where } }
  } else {
    var where = { ...params.filter }
    //for (let key in where) {
    //if (where[key] === null) {
    //delete where[key]
    //}
    //}
    filter = { where }
  }

  if (filter.where.q) {
    filter.where.$text = {
      search: filter.where.q,
    }
    delete filter.where.q
  }

  filter.limit = perPage
  filter.skip = (page - 1) * perPage
  let paramSorts = []
  let { sort } = params
  if (!isArray(sort)) {
    sort = [sort]
  }

  if (sort) {
    for (let i = 0; i < sort.length; i++) {
      let paramSort = sort[i]
      let { order, field } = paramSort
      if (order && field) {
        let strOrder = field + ' ' + order
        paramSorts.push(strOrder)
      }
    }
  }

  if (paramSorts.length > 0) {
    filter.order = paramSorts
  }

  if (filter && filter.where) {
    for (let key in filter.where) {
      if (key.startsWith('../')) {
        let newKey = key.substr(3)
        filter[newKey] = filter.where[key]
        delete filter.where[key]
      }

      if (key.endsWith('_gte')) {
        let value = filter.where[key]
        let newKey = key.substring(0, key.indexOf('_gte'))

        if (!filter.where[newKey]) {
          filter.where[newKey] = { ...filter.where[newKey], gte: value }
        } else {
          filter.where['and'] = [{ [newKey]: { ...filter.where[newKey] } }, { [newKey]: { gte: value } }]
          delete filter.where[newKey]
        }
        delete filter.where[key]
      }

      if (key.endsWith('_lte')) {
        let value = filter.where[key]
        let newKey = key.substring(0, key.indexOf('_lte'))

        if (!filter.where[newKey]) {
          filter.where[newKey] = { ...filter.where[newKey], lte: value }
        } else {
          filter.where['and'] = [{ [newKey]: { ...filter.where[newKey] } }, { [newKey]: { lte: value } }]
          delete filter.where[newKey]
        }
        delete filter.where[key]
      }

      if (key.endsWith('_like')) {
        let value = filter.where[key]
        let newKey = key.substring(0, key.indexOf('_like'))

        if (!filter.where[newKey]) {
          filter.where[newKey] = { like: `%${value}%` }
        } else {
          filter.where['and'] = [{ [newKey]: { like: `%${value}%` }}]
          delete filter.where[newKey]
        }
        delete filter.where[key]
      }
      if (key.endsWith('_between')) {
        let value = filter.where[key]
        let newKey = key.substring(0, key.indexOf('_between'))
        let startDay = moment(value).startOf('day').toISOString()
        let endDay = moment(value).endOf('day').toISOString()

        filter.where[newKey] = { between: [startDay, endDay ] }
        delete filter.where[key]
      }
    }
  }

  return filter
}

/**
 * Maps react-admin queries to a simple REST API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_LIST     => GET http://my.api.url/posts?sort=['title','ASC']&range=[0, 24]
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts?filter={ids:[123,456,789]}
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
let simpleRestProvider = (apiUrl, httpClient = fetchUtils.fetchJson) => {
  /**
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} { url, options } The HTTP request parameters
   */
  const convertDataRequestToHTTP = (type, resource, params) => {
    let url = ''

    let path = ''
    if (params.filter) {
      path = params.filter.path || ''
      delete params.filter.path
    }

    const options = {}
    switch (type) {
      case GET_LIST: {
        let query = {
          filter: JSON.stringify(filterFromParams(params)),
        }
        url = `${apiUrl}/${resource}/${path}?${stringify(query)}`
        break
      }
      case GET_ONE: {
        let { id, filter } = params
        let query = null
        if (filter) {
          query = {
            filter: JSON.stringify(filter),
          }
        }

        url = `${apiUrl}/${resource}/${id}`

        if (query) {
          url = url.concat(`?${stringify(query)}`)
        }
      }
        break
      case GET_MANY: {
        const query = {
          filter: JSON.stringify({
            where: {
              id:
                params.ids.length === 1
                  ? params.ids[0]
                  : { inq: params.ids },
            },
          }),
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      case GET_MANY_REFERENCE: {
        let filter = filterFromParams(params)
        filter.where[params.target] = params.id
        let query = {
          filter: JSON.stringify(filter),
        }
        url = `${apiUrl}/${resource}?${stringify(query)}`
        break
      }
      case UPDATE:
        url = `${apiUrl}/${resource}/${params.id}`
        options.method = 'PUT'
        options.body = JSON.stringify(params.data)
        break
      case CREATE:
        url = `${apiUrl}/${resource}`
        options.method = 'POST'
        options.body = JSON.stringify(params.data)
        break
      case DELETE:
        url = `${apiUrl}/${resource}/${params.id}`
        if (params.extras) {
          url = `${url}?${stringify(params.extras)}`
        }
        options.method = 'DELETE'
        break
      case 'REMOTE':
        url = `${apiUrl}/${resource}/${params.method}`
        options.method = params.requestMethod || 'POST'
        switch (options.method) {
          case 'GET': {
            if (params.data && typeof params.data === 'object') {
              let pairs = []
              for (let key in params.data) {
                let value = params.data[key]
                if (typeof value === 'object') value = JSON.stringify(value)
                pairs.push(`${key}=${encodeURI(value)}`)
              }
              if (pairs.length > 0) {
                url += '?' + pairs.join('&')
              }
            }
            break
          }
          case 'POST': {
            options.body = JSON.stringify(params.data)
            break
          }
          case 'PATCH': {
            options.body = JSON.stringify(params.data)
            break
          }
          case 'PUT': {
            options.body = JSON.stringify(params.data)
            break
          }
          default:
            break
        }
        break
      default:
        throw new Error(`Unsupported fetch action type ${type}`)
    }
    return { url, options }
  }

  /**
   * @param {Object} response HTTP response from fetch()
   * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
   * @param {String} resource Name of the resource to fetch, e.g. 'posts'
   * @param {Object} params The data request params, depending on the type
   * @returns {Object} Data response
   */
  const convertHTTPResponse = (response, type, resource, params) => {
    const { headers, json } = response
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        if (!headers.has('content-range')) {
          throw new Error(
            'The Content-Range header is missing in the HTTP Response. The simple REST data provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?'
          )
        }
        return {
          data: json,
          total: parseInt(
            headers
              .get('content-range')
              .split('/')
              .pop(),
            10
          ),
        }
      case CREATE:
        return { data: { ...params.data, ...json } }
      default:
        return { data: json }
    }
  }

  /**
   * @param {string} type Request type, e.g GET_LIST
   * @param {string} resource Resource name, e.g. "posts"
   * @param {Object} payload Request parameters. Depends on the request type
   * @returns {Promise} the Promise for a data response
   */
  return (type, resource, params) => {
    // simple-rest doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
    if (type === UPDATE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }
    // simple-rest doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
    if (type === DELETE_MANY) {
      return Promise.all(
        params.ids.map(id =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          })
        )
      ).then(responses => ({
        data: responses.map(response => response.json),
      }))
    }
    const { url, options } = convertDataRequestToHTTP(type, resource, params)
    return httpClient(url, options).then(
      response => convertHTTPResponse(response, type, resource, params),
    ).catch(error => { 
      return error && handleError(error)
    })
  }
}

const restProvider = simpleRestProvider(ROOT_URL, httpClient)

export default (type, resource, params) => new Promise(resolve => resolve(restProvider(type, resource, params)))
