import {
  withStyles,
  Stepper,
  Step,
  StepLabel,
  Button,
  Toolbar,
  AppBar,
} from '@material-ui/core'
import { translate, showNotification } from 'react-admin'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import SelectTripStep from './SelectTripStep'
import BookingInformationStep from './BookingInformationStep'
import PassengersStep from './PassengersStep'
import ReviewStep from './ReviewStep'
import PaymentStep from './PaymentStep'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import {
  RESERVATION_RESET,
  RESERVATION_MAKE_RESERVATION,
  doAction,
  doFetchAction
} from './actions'
import ReservationStep from './ReservationStep'
import {
  validateTrip,
  validateBookingInformation,
  validatePassengers,
  validateReview,
  validatePayment
} from './validation'
import { changeBreadcrumb } from '../breadcrumb/action'

const styles = {
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  toolbar: {
    alignItems: 'center',
    justifyContent: 'center',
  },
}

const Direction = {
  FORWARD: 'forward',
  BACKWARD: 'backward'
}

const StepIndex = {
  TRIP: 0,
  BOOKING_INFORMATION: 1,
  PASSENGERS: 2,
  REVIEW: 3,
  PAYMENT: 4
}

let tripStep = new ReservationStep(StepIndex.TRIP, 'trip')
let bookingInformationStep = new ReservationStep(StepIndex.BOOKING_INFORMATION, 'bookingInformation')
let passengersStep = new ReservationStep(StepIndex.PASSENGERS, 'passengers')
let reviewStep = new ReservationStep(StepIndex.REVIEW, 'review')
let paymentStep = new ReservationStep(StepIndex.PAYMENT, 'payment')

tripStep.refs(null, bookingInformationStep)
bookingInformationStep.refs(tripStep, passengersStep)
passengersStep.refs(bookingInformationStep, reviewStep)
reviewStep.refs(passengersStep, paymentStep)
paymentStep.refs(reviewStep, null)

// const stepMap = {
//   IDX_TRIP_STEP: tripStep,
//   IDX_BOOKING_INFORMATION_STEP: bookingInformationStep,
//   IDX_PASSENGERS_STEP: passengersStep,
//   IDX_REVIEW_STEP: reviewStep,
//   IDX_PAYMENT_STEP: paymentStep
// }

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activeStep: tripStep
    }
    const { translate, changeBreadcrumb } = props
    const paths = [
      { label: translate('resources.reservations.name'), to: '/reservations' },
      { label: translate('resources.common.create'), to: '' },
    ]
    changeBreadcrumb({ paths })
  }

  handleDirection = (direction) => {
    let { showNotification } = this.props
    let { activeStep } = this.state
    let step = activeStep

    let validateResult = this.validate(activeStep)
    let { result, message } = validateResult
    if (!result) {
      showNotification(message, 'warning')
      return
    }

    switch (direction) {
      case Direction.FORWARD: {
        if (activeStep.canForward()) {
          step = activeStep.move(Direction.FORWARD)
        }
        break
      }
      case Direction.BACKWARD: {
        if (activeStep.canBackward()) {
          step = activeStep.move(Direction.BACKWARD)
        }
        break
      }
      default:
        break
    }
    this.setState({ activeStep: step })
  }

  handleComplete = () => {
    let {
      doAction,
      doFetchAction,
      trips,
      bookingInformation,
      passengers,
      charges,
      isPayLater,
      transaction,
      showNotification,
      push
    } = this.props

    let { activeStep } = this.state
    let validateResult = this.validate(activeStep)
    let { result, message } = validateResult
    if (!result) {
      showNotification(message, 'warning')
      return
    }

    let tripIds = []
    Object.values(trips).map(trip => tripIds.push(trip.tripId))

    let reqBody = {
      trips: tripIds,
      bookingInformation,
      passengers,
      charges,
      source: '10WEBADMIN'
    }

    if (!isPayLater) {
      reqBody.transactions = [transaction]
    }

    doFetchAction(
      RESERVATION_MAKE_RESERVATION,
      'make',
      'POST',
      reqBody,
      'reservations',
      null,
      null,
      () => {
        doAction(RESERVATION_RESET)
        showNotification('notification.reservation.success')
        push('/reservations')
      }, (error) => {
        console.log(error)
        showNotification('notification.reservation.error', 'warning')
      })
  }

  isLastStep() {
    let { activeStep } = this.state
    return activeStep.index === StepIndex.PAYMENT
  }

  validate = (activeStep) => {
    let { index } = activeStep
    let { trips, bookingInformation, passengers, charges, isPayLater, transaction } = this.props
    let data = null
    let method = null
    switch (index) {
      case StepIndex.TRIP:
        data = { trips, passengers, charges }
        method = validateTrip
        break
      case StepIndex.BOOKING_INFORMATION:
        data = { bookingInformation }
        method = validateBookingInformation
        break
      case StepIndex.PASSENGERS:
        data = { passengers }
        method = validatePassengers
        break
      case StepIndex.REVIEW:
        data = {}
        method = validateReview
        break
      case StepIndex.PAYMENT:
        data = { isPayLater, transaction }
        method = validatePayment
        break
      default:
        break
    }

    let res = activeStep.validate(data, method)

    return res
  }

  renderStepContent = (activeStep) => {
    let { index } = activeStep
    switch (index) {
      case StepIndex.TRIP:
        return <SelectTripStep />
      case StepIndex.BOOKING_INFORMATION:
        return <BookingInformationStep />
      case StepIndex.PASSENGERS:
        return <PassengersStep />
      case StepIndex.REVIEW:
        return <ReviewStep />
      case StepIndex.PAYMENT:
        return <PaymentStep />
      default:
        return 'Unknown step'
    }
  }

  render() {
    let { classes, translate, steps } = this.props
    let { activeStep } = this.state
    let activeStepIndex = activeStep.index

    return (
      <>
        <Stepper alternativeLabel nonLinear activeStep={activeStepIndex}>
          {Object.values(steps).map((step, index) => {
            let { label, error } = step
            return (
              <Step key={`${label}-${index}`}>
                <StepLabel error={error}>
                  {translate(label)}
                </StepLabel>
              </Step>
            )
          })}
        </Stepper>

        {/* reservation step */}
        {this.renderStepContent(activeStep)}

        {/* actions */}
      <AppBar position="static" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Button
            disabled={activeStepIndex === 0}
            onClick={() => this.handleDirection(Direction.BACKWARD)}
            size="large"
          >
            Back
          </Button>

          {!this.isLastStep() && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.handleDirection(Direction.FORWARD)}
            >
              Next
            </Button>
          )}

          {this.isLastStep() && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => this.handleComplete()}
            >
              Finish
            </Button>
          )}
        </Toolbar>
      </AppBar>
      </>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { reservation } = state
      let {
        steps,
        isRoundTrip,
        trips,
        bookingInformation,
        passengers,
        charges,
        isPayLater,
        transaction
      } = reservation
      return {
        steps,
        isRoundTrip,
        trips,
        bookingInformation,
        passengers,
        charges,
        isPayLater,
        transaction
      }
    },
    {
      doAction,
      doFetchAction,
      push,
      showNotification,
      changeBreadcrumb,
    }
  )
)

export default enhance(FormSave)
