import React, { Component } from 'react'
import { Grid, withStyles, Typography } from '@material-ui/core'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  ImageInput,
  ImageField,
  FormDataConsumer,
  translate,
  ReferenceManyField,
  required,
  SimpleForm,
} from 'react-admin'
import { DatePickerInput } from '../common/DatePicker'
import ObjectLicence from './ObjectLicence'
import CheckboxInput from '../common/CheckboxInput'
import compose from 'recompose/compose'
import ServiceTaskList from './ServiceTaskList'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { Provider } from '../provider'
import { validate } from './validate'
import LicenceValidityList from './LicenceValidityList'

const styles = () => ({
  root: {
    width: '100%'
  },
  containerServiceTask: {
    marginTop: 16,
  }
})

const VEHICLE_INSPECTION = '00VNS'
const DRIVER_LICENCE = '10LID'
const VEHICLE_INSURANCE = '20VNR'
const VEHICLE_REGISTRATION_CERTIFICATE = '30VRC'

const licenceTypeOfVehicles = [
  VEHICLE_INSPECTION,
  VEHICLE_INSURANCE,
  VEHICLE_REGISTRATION_CERTIFICATE
]


const ServiceGroupMapping = {
  LICENSE: {
    principalResource: 'licences',
    fieldShow: 'licenceNumber',
    principal: 'Licence'
  },
  VEHICLE: {
    principalResource: 'vehicles',
    fieldShow: 'plate',
    principal: 'Vehicle'
  }
}

const choice = {
  id: 1, name: 'Bạn có muốn tạo lời nhắc khi giấy tờ hết hạn không'
}

const ExtraDriver = ({ record, translate }) => {
  const extra = {
    resource: 'licences',
    fullWidth: true,
  }
  return <div>
    <ReferenceInput
      source="extras.driverId"
      basePath="/drivers"
      reference="drivers"
      resource="drivers"
      validate={required()}
      perPage={1000}
      label={translate('resources.drivers.name', { smart_count: 1 })}
      record={record}
      {...extra}
    >
      <FuzzySelectInput
        renderItem={item => {
          return item.fullName
        }}
        optionText="fullName"
        {...defaultFuzzySearch({ name: 'fullName' })}
      />
    </ReferenceInput>
    <ReferenceInput
      record={record}
      source="extras.licenceClass"
      basePath="/licenceclasses"
      reference="licenceclasses"
      resource="licenceclasses"
      validate={required()}
      label={translate('resources.drivers.fields.licenceClass')}
      {...extra}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </div>
}
const ExtraVehicle = ({ record, translate }) => {
  const extra = {
    resource: 'licences',
    fullWidth: true,
  }
  return (<ReferenceInput
    source="extras.vehicleId"
    basePath="/vehicles"
    reference="vehicles"
    label={translate('resources.vehicles.fields.plate')}
    resource="vehicles"
    validate={required()}
    record={record}
    perPage={1000}
    {...extra}
  >
    <FuzzySelectInput
      renderItem={item => {
        return item.plate
      }}
      optionText="plate"
      {...defaultFuzzySearch({ name: 'plate' })}
    />
  </ReferenceInput>)
}

class BaseFormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      record: {},
      archiveLicenceValidities: []
    }
  }

  getData = async (id) => {
    let res = await Provider.dataProvider('GET_ONE', 'licences', {
      id,
      filter: {
        include: {
          relation: 'licenceValidity',
          scope: {
            order: 'renewalNo DESC'
          }
        },
      }
    })
    let licenceWithValidity = res && res.data
    let { licenceValidity: licenceValidities } = licenceWithValidity
    let firstlicenceValidity = licenceValidities[0]
    let { licenceNumber, validTo, validFrom, placeOfIssue, images } = firstlicenceValidity
    let archiveLicenceValidities = licenceValidities.slice(1, licenceValidities.length)
    let record = {
      ...licenceWithValidity,
      licenceNumber,
      validTo,
      validFrom,
      placeOfIssue,
      images
    }
    return { record, archiveLicenceValidities }
  }

  async componentDidMount() {
    let { id, record: currentRecord } = this.props
    if (id) {
      let { record, archiveLicenceValidities } = await this.getData(id)
      this.setState({ record, archiveLicenceValidities })
    } else {
      let { extras } = currentRecord
      if (extras) {
        try {
          currentRecord = { ...currentRecord, extras: JSON.parse(extras)}
        } catch (e) {
          console.log(e)
        }
      }
      this.setState({ record: { ...currentRecord }})
    }
  }

  handleChangeDone = async () => {
    let { id } = this.props
    let { record, archiveLicenceValidities } = await this.getData(id)
    this.setState({ record, archiveLicenceValidities })
  }

  getRecord = () => {
    let { record } = this.state
    return record
  }

  render() {
    let { classes, translate, isEdit, save, redirect, toolbar, companyManager } = this.props
    let { record, archiveLicenceValidities } = this.state
    const extra = {
      resource: 'licences',
      fullWidth: true,
    }
    let principalId = record && record.id
    const formType = record.type
    const isVehicle = licenceTypeOfVehicles.indexOf(formType) !== -1
    return (
      <SimpleForm
        redirect={redirect}
        record={record}
        validate={validate}
        save={save}
        toolbar={toolbar}
      >
        <Grid className={classes.root} container>
          <Grid item xs={6}>
            {isEdit && <FormDataConsumer>
              {({ formData }) => {
                return <ObjectLicence record={formData} />
              }
              }
            </FormDataConsumer>}
            <TextInput
              source="licenceNumber"
              validate={required()}
              {...extra}
            />
            <ReferenceInput
              source="type"
              basePath="/licencetypes"
              reference="licencetypes"
              resource="licencetypes"
              validate={required()}
              disabled
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            {!isEdit && <>
              {formType === DRIVER_LICENCE && <FormDataConsumer>
                {({ formData }) => <ExtraDriver record={formData} translate={translate} />}
              </FormDataConsumer>}
              {isVehicle && <FormDataConsumer>
                {({ formData }) => <ExtraVehicle record={formData} translate={translate} />}
              </FormDataConsumer>}
            </>
            }
            <DatePickerInput
              source="validFrom"
              dateFormat="DD/MM/YYYY"
              showLunarDate={true}
              keyboard={true}
              {...extra}
            />
            <DatePickerInput
              source="validTo"
              dateFormat="DD/MM/YYYY"
              showLunarDate={true}
              keyboard={true}
              {...extra}
            />
            <ReferenceInput
              source="placeOfIssue"
              basePath="/provinces"
              reference="provinces"
              resource="provinces"
              perPage={100}
              sort={{ field: 'name', order: 'ASC' }}
              {...extra}
            >
              <FuzzySelectInput
                renderItem={item => {
                  return item.name
                }}
                optionText="name"
                {...defaultFuzzySearch()}
              />
            </ReferenceInput>
            <ReferenceInput
              source="status"
              basePath="/licencestatuses"
              reference="licencestatuses"
              resource="licencestatuses"
              defaultValue="30UNA"
              disabled
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput
              defaultValue={0}
              type="number"
              source="numberOfRenewal"
              {...extra}
            />
            <TextInput source="desc" {...extra} />
            <ImageInput
              source="images"
              accept="image/*"
              multiple
              {...extra}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            {!isEdit && <CheckboxInput
              content={translate('resources.licences.auto_create_reminder')}
              source="doCreateServicetask"
              label=""
              choice={choice}
              defaultValue={choice.id}
            />}
          </Grid>
          {isEdit && <Grid item xs={6}>
            <div className={classes.containerServiceTask}>
              <Typography variant="h4">
                {translate('resources.common.title_service_task')}
              </Typography>
              <ReferenceManyField
                basePath="/servicetasks"
                reference="servicetasks"
                resource="servicetasks"
                record={{}}
                filter={{ principalId: principalId, principalType: 'Licence' }}
                target=""
              >
                <ServiceTaskList
                  principal={record}
                  companyManager={companyManager}
                  principalType={ServiceGroupMapping['LICENSE']}
                />
              </ReferenceManyField>
            </div>
            <div>
              <div className={classes.containerServiceTask}>
                <Typography variant="h4">
                  {translate('resources.common.title_activity')}
                </Typography>
                <LicenceValidityList datum={archiveLicenceValidities}/>
              </div>
            </div>
          </Grid>
          }
        </Grid>
      </SimpleForm>
    )
  }
}

const FormSave = ({ forwardRef, ...props }) => (
  <BaseFormSave {...props} ref={forwardRef} />
)

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(FormSave)
