export function getCampaignDiscountByPrice(promotion, price) {
  let { discount } = promotion
  if (typeof discount == 'string') {
    if (discount.endsWith('%')) {
      discount = price * parseFloat(discount) / 100
    } else {
      discount = parseFloat(discount)
    }
  }

  if (promotion.discountMax > 0 && discount > promotion.discountMax) {
    discount = promotion.discountMax
  }
  return discount
}
