import moment from 'moment'
import { isEmpty } from 'lodash'

export function validate(values, props) {
  let errors = {}
  let { translate } = props
  let { validFrom, validTo, quantityType, quantity } = values
  // Validate validFrom
  validFrom = moment(validFrom)
  validTo = moment(validTo)
  if (validFrom && validTo) {
    if (validTo.diff(validFrom, 'days') < 0) {
      errors.validFrom = translate('error_messages.invalid.drivers.validFrom')
    }
    if (validFrom.diff(validTo, 'days') > 0) {
      errors.validTo = translate('error_messages.invalid.drivers.validTo')
    }
  }
  if (quantityType === 'OTHER' && quantity === -1) {
    values.quantity = 0
  }

  let name = values && values.name && values.name.trim()
  if (!name || name.length === 0) {
    errors.name = translate('error_messages.required.name')
  }

  //validate fare name in registe fare
  let fares = values && values.fares
  if (fares && fares.length) {
    let faresArrayErrors = []
    fares.forEach((fare, fareIndex) => {
      let fareErrors = {}
      let name = fare && fare.name
      if (name && !name.trim()) {
        fareErrors.name = translate('error_messages.required.name')
        faresArrayErrors[fareIndex] = fareErrors
      }
    })
    if (faresArrayErrors.length) {
      errors.fares = faresArrayErrors
    }
  }

  return errors
}

export function validateProductFareCode(code) {
  if (!isEmpty(code) && code.length < 6) {
    return false
  }
  return true
}
