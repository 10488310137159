import React, { Component } from 'react'
import BigCalendar from 'react-big-calendar'
import {
  GET_LIST,
  GET_ONE,
  ReferenceManyField,
  translate,
  Title,
} from 'react-admin'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import moment from 'moment'
import {
  withStyles,
  Typography,
  Card,
  CardContent,
  IconButton,
  Icon,
  CardHeader,
  Avatar,
  Dialog,
  DialogTitle,
  Popover,
  MenuItem,
  ListItemText,
  Grid,
  Drawer,
  Button,
  Divider,
  CardActions,
} from '@material-ui/core'
import EventWrapper from './eventWrapper'
import { Provider } from '../provider'
import './event.css'
import ReminderButton from '../licence/ReminderButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faFileAlt, faBus, faEye, faClock } from '@fortawesome/free-solid-svg-icons'
import ServiceReminderFormSave from './ServiceReminderFormSave'
import { saveReminder as saveReminderAction } from '../licence/action'
import DeleteReminderButton from './DeleteReminderButton'
import isEmpty from 'lodash/isEmpty'
import Filter from './Filter'
import { ServiceReminderList } from './index'
import MaintainGroupItem from '../maintain/MaintainGroupItem'
import classnames from 'classnames'
import MakeDoneButton from './MakeDoneButton'
import RestoreButton from './RestoreButton'
import { ServiceGroupMapping } from './PrincipalField'

const DUE_SOON = '10DUS'
const EXPIRED = '00EPR'
const VALID = '20VAL'
const DUE_AT = '30DUE'
const VIEW_TABLE = 1
const VIEW_CALENDAR = 2

const reminderStatuses = {
  ids : [ DUE_SOON, EXPIRED, VALID, DUE_AT ]
}

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing.unit * 3,
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.06), 0px 3px 4px 0px rgba(0, 0, 0, 0), 0px 3px 3px -2px rgba(0, 0, 0, 0)',
    borderRadius: 4,
    outline: 'none',
    width: '100%',
    height: '100%',
  },
  iconButton: {
    margin: 0
  },
  timeInfo: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  timeInfoItem: {
    display: 'inline-block',
    margin: 4,
    justifyContent: 'center',
  },
  textSizeContent: {
    fontSize: 13
  },
  cardContent: {
    marginTop: 0, paddingTop: 0
  },
  chip: {
    width: 18,
    height: 18,
    marginRight: 10,
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
  actionContainer: {
    marginBottom: 16
  },
  switchContainer: {
    marginLeft: 8,
    display: 'inline-block',
    whiteSpace: 'nowrap',
    border: 'solid 1px #ccc',
    borderRadius: 4
  },
  switchButtonActive: {
    backgroundImage: 'none',
    boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
    backgroundColor: '#e6e6e6',
    borderColor: '#adadad',
    borderRadius: 0,
  },
  actions: {
    justifyContent: 'flex-end'
  }
})

const localizer = BigCalendar.momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(BigCalendar)

const buildFilter = (serviceGroups) => {
  let filter = {
    serviceGroupValue: {},
    serviceReminderStatusValue: {}
  }
  let serviceGroupValue = {}
  let serviceReminderStatusValue = {}
  for (let i = 0; i < serviceGroups.length; i++) {
    let serviceGroup = serviceGroups[i]
    let serviceGroupId = serviceGroup.id
    serviceGroupValue[serviceGroupId] = {
      childrens: [],
      showAllChildren: true
    }
  }
  let { ids } = reminderStatuses
  for (let i = 0; i < ids.length; i++) {
    let serviceReminderStatusId = ids[i]
    serviceReminderStatusValue[serviceReminderStatusId] = {
      childrens: [],
      showAllChildren: true
    }
  }
  filter = { ...filter, serviceGroupValue, serviceReminderStatusValue }
  return filter
}

const serviceGroupIcon = (serviceGroupCode) => {
  if (serviceGroupCode === 'LICENSE') {
    return <FontAwesomeIcon icon={faFileAlt} />
  } else if (serviceGroupCode === 'VEHICLE') {
    return <FontAwesomeIcon icon={faBus} />
  }
}

const PrincipalMapping = {
  'Licence': {
    resource: 'licences',
    extrasFilter: {
      include: {
        relation: 'licenceValidity',
        scope: {
          where: { status: { neq: '40ARC' } }
        }
      }
    }
  },
  'MaintainGroup': {
    resource: 'maintaingroups',
  }
}

const ServiceGroupItem = ({ data, ids, handleOpenForm }) => {
  return ids ? ids.map((id, idx) => (
    <MenuItem key={idx} onClick={() => handleOpenForm(data[id])}>
      {serviceGroupIcon(data[id].code)}
      <ListItemText>
        {data[id].name}
      </ListItemText>
    </MenuItem>
  )) : null
}

const DrawerFilter = ({
  open,
  onClose,
  filter,
  handleCheckServiceGroup,
  handleCheckServiceGroupChild,
  handleCheckReminderStatus,
  classes
}) => <Drawer
  className={classes.drawer}
  anchor="right"
  open={open}
  onClose={() => onClose()}
  classes={{
    paper: classes.drawerPaper,
  }}
>
  <CardContent>
    <Typography component="h2" variant="h6">
      Tìm kiếm
    </Typography>
    <Typography component="h2" variant="subtitle1">
      Tìm kiếm lời nhắc theo nhóm và trạng thái
    </Typography>
  </CardContent>
  <Divider />
  <Filter
    handleCheckServiceGroup={handleCheckServiceGroup}
    handleCheckServiceGroupChild={handleCheckServiceGroupChild}
    handleCheckReminderStatus={handleCheckReminderStatus}
    filter={filter}
  />
</Drawer>

const MenuEvent = ({
  anchorEl,
  onClose,
  classes,
  event = {},
  principal = {},
  open,
  done,
  error,
  doneForDelete,
  errorForDelete,
  handleView,
  translate,
}) => {
  let serviceReminder = event ? event.originData : {}
  let serviceTask = serviceReminder.serviceTask || {}
  let serviceGroup = serviceTask.serviceGroup || {}
  let serviceGroupCode = serviceGroup.code
  let principalType = ServiceGroupMapping[serviceGroupCode] || {}
  let { icon, principal: resource, fieldShow, getOptionText } = principalType
  let avatar = icon
  let { id, repeat, dueAt, desc, status, isDone, doneDate } = serviceReminder
  let object = getExpiredColor(status, dueAt, translate)
  return <Popover
    anchorEl={anchorEl}
    open={open}
    onClose={onClose}
    anchorOrigin={{
      horizontal: 'right',
      vertical: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left'
    }}
  >
    <Card style={{ maxWidth: 500, minWidth: 350 }}>
      <CardHeader
        style={{ padding: 4 }}
        avatar={<Avatar>{avatar}</Avatar>}
        title={
          <Typography
            gutterBottom
            variant="h5"
            component="h5"
            className={classes.timeInfoItem}
          >
            {event && event.title}
          </Typography>
        }
        action={
          <div style={{ margin: 4 }}>
            {resource === 'MaintainGroup'
              ? <IconButton
                size="small"
                onClick={() => handleView && handleView(principal.vehicleId, principal.scheduleId)}
                className="iconButton"
              >
                <FontAwesomeIcon icon={faEye} size="xs" />
              </IconButton>
              : null
            }
            <ReminderButton
              principal={principal}
              servicetaskRecord={serviceTask}
              reminderRecord={serviceReminder}
              icon={<EditIcon />}
              isCreate={false}
              principalType={principalType}
              doneFunc={done}
              errorFunc={error}
            />
            <DeleteReminderButton
              id={id}
              repeat={repeat}
              done={doneForDelete}
              error={errorForDelete}
            />
            <IconButton size="small" onClick={() => onClose()} className="iconButton">
              <Icon>close</Icon>
            </IconButton>
          </div>
        }
      />
      <CardContent className={classes.cardContent}>
        <div style={{ color: object.color }} className={classes.timeInfo}>
          <span className={classnames(classes.timeInfoItem, classes.textSizeContent)}>
            <FontAwesomeIcon icon={faClock} />&nbsp;
            {moment(dueAt).format('ll')}&nbsp;
            {`(${object.message})`}
          </span>
        </div>
        <div className={classes.timeInfo}>
          <span className={classnames(classes.timeInfoItem, classes.textSizeContent)}>
            {translate('resources.servicereminders.fields.principalName')}:&nbsp;
            {getOptionText ? getOptionText(principal, principalType, fieldShow) : principal[fieldShow]}
          </span>
        </div>
        <div className={classes.timeInfo}>
          <span className={classnames(classes.timeInfoItem, classes.textSizeContent)}>
            {translate('resources.servicereminders.fields.desc')}:&nbsp;
            {desc}
          </span>
        </div>
        {isDone && <div className={classes.timeInfo}>
          <span className={classnames(classes.timeInfoItem, classes.textSizeContent)}>
            {translate('resources.servicereminders.fields.doneDate')}:&nbsp;
            {moment(doneDate).format('ll')}
          </span>
        </div>}
      </CardContent>
      <CardActions className={classes.actions}>
        {isDone ? <RestoreButton
          id={id}
          repeat={repeat}
          done={doneForDelete}
          error={errorForDelete}
        /> : <MakeDoneButton
          id={id}
          repeat={repeat}
          done={doneForDelete}
          error={errorForDelete}
        />}
      </CardActions>
    </Card>
  </Popover>
}

export const MenuNewServiceTask = withStyles(styles)(({
  open,
  anchorEl,
  onClose,
  handleOpenForm,
}) => <Popover
  anchorEl={anchorEl}
  open={open}
  onClose={onClose}
  anchorOrigin={{
    horizontal: 'right',
    vertical: 'center',
  }}
  transformOrigin={{
    vertical: 'top',
    horizontal: 'left'
  }}
>
  <ReferenceManyField
    record={{}}
    basePath="/servicegroups"
    reference="servicegroups"
    target=""
  >
    <ServiceGroupItem handleOpenForm={handleOpenForm} />
  </ReferenceManyField>
</Popover>
)

export const DialogNewServiceReminder = ({
  open,
  handleClose,
  record,
  isCreate,
  principalType,
  defaultDueAt,
  done,
  error,
  translate,
}) => {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth
      maxWidth="sm"
      disableEnforceFocus
    >
      <DialogTitle>{translate('resources.servicereminders.title.create')}</DialogTitle>
      <ServiceReminderFormSave
        record={record}
        isCreate={isCreate}
        principalType={principalType}
        defaultDueAt={defaultDueAt}
        done={done}
        error={error}
      />
    </Dialog>
  )
}

const EditIcon = () => (
  <FontAwesomeIcon icon={faPen} />
)

export const getExpiredColor = (status, dueAt, translate) => {
  let result
  let currentDate = moment(new Date()).startOf('day')
  dueAt = moment(dueAt).startOf('day')
  let rangeOfCurrentToDueAt = dueAt.diff(currentDate, 'days')
  switch (status) {
    case EXPIRED:
      result = {
        color: 'gray',
        message: translate('notification.expired', { days: rangeOfCurrentToDueAt * -1 }),
      }
      break
    case DUE_AT:
      result = {
        color: 'red',
        message: translate('notification.due_at'),
      }
      break
    case DUE_SOON:
      result = {
        color: 'yellow',
        message: translate('notification.after_expired', { days: rangeOfCurrentToDueAt }),
      }
      break
    case VALID:
      result = {
        color: 'green',
        message: translate('notification.after_expired', { days: rangeOfCurrentToDueAt }),
      }
      break
    default:
      result = {}
  }
  return result
}

class _calendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      currentEvent: null,
      events: [],
      isShowMenu: false,
      currentAnchor: null,
      cachedService: [],
      principal: {},
      openDialogNewServiceReminder: false,
      openMenuNewServiceReminder: false,
      showMaintainItem: false,
      principalType: '',
      nEvent: {},
      defaultDueAt: new Date(),
      openMenuEvent: false,
      defaultDate: new Date(),
      filter: {
        serviceGroupValue: {},
        serviceReminderStatusValue: {}
      },
      openDrawer: false,
      viewMode: VIEW_CALENDAR,
    }
  }
  componentDidMount() {
    this.loadData(true)
  }

  loadData = async (init) => {
    let date = this.state.defaultDate
    const beginMonth = moment(date).startOf('month').toISOString()
    const endMonth = moment(date).endOf('month').toISOString()
    let { filter: currentFilter, events } = this.state
    try {
      const filter = {
        where: { dueAt: { between: [beginMonth, endMonth] } },
        include: {
          relation: 'serviceTask',
          scope: {
            include: {
              relation: 'serviceGroup'
            }
          }
        },
        extrasFilter: !init && { ...currentFilter }
      }
      let serviceReminder = await Provider.dataProvider(
        GET_LIST,
        'servicereminders',
        {
          filter,
          sort: {},
          pagination: {}
        }
      )
      if (init) {
        let serviceGroups = await Provider.dataProvider(
          GET_LIST,
          'servicegroups',
          {
            filter: {},
            sort: {},
            pagination: {}
          }
        )
        if (serviceGroups && serviceGroups.total > 0) {
          currentFilter = buildFilter(serviceGroups.data)
        }
      }
      if (serviceReminder) {
        let { data } = serviceReminder
        events = this.formatData(data)
      }
      this.setState({
        events,
        filter: currentFilter
      })

    } catch (exception) {
      this.setState({ events: [] })
    }
  }

  formatToDatagridData = datum => {
    let ids = []
    let data = {}
    let total = datum ? datum.length : 0
    if (total > 0) {
      for (let i = 0; i < total; i++) {
        let record = datum[i]
        if (record) {
          let { id } = record
          ids.push(id)
          data[id] = record
        }
      }
    }
    return { ids, data, total }
  }

  formatData = data => {
    if (!data || !Array.isArray(data)) {
      return []
    }
    let { translate } = this.props
    const newData = []
    for (let i = 0; i < data.length; i++) {
      const dtI = data[i]
      let { id, dueAt, name, status, isDone } = dtI
      let obj = getExpiredColor(status, dueAt, translate)
      let { color } = obj
      let textDecoration = isDone ? 'line-through' : ''
      const it = {
        id: id,
        title: name,
        start: moment(dueAt).startOf('day').toDate(),
        end: moment(dueAt).endOf('day').toDate(),
        originData: dtI,
        colorBorder: color,
        textDecoration
      }
      newData.push(it)
    }
    return newData
  }

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    const { events } = this.state

    const idx = events.indexOf(event)
    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay }

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    this.setState({
      events: nextEvents,
    })
  }

  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id === event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      events: nextEvents,
    })
  }

  onNavigate = (date, view) => {
    if (view === 'month') {
      this.setState({
        defaultDate: date
      }, () => this.loadData())
    }
  }

  showMaintainListItem = () => {
    this.setState({ showMaintainItem: true })
  }

  componentDidUpdate(preProps, prevState) {
    if (prevState.events !== this.state.events) {
      let newEventEle = window.document.querySelectorAll('.rbc-event.new')[0]
      if (newEventEle) {
        this.setState({
          currentAnchor: newEventEle,
          openMenuNewServiceReminder: true
        })
      }
    }
  }

  handleCheckServiceGroup = (event, isChecked, currentValue) => {
    let newValue = event.target.value
    newValue = parseInt(newValue) || 0
    let { filter } = this.state
    if (isChecked) {
      currentValue.push(newValue)
    } else {
      currentValue = currentValue.filter(v => v !== newValue)
    }
    let serviceGroupValue = {}
    for (let i = 0; i < currentValue.length; i++) {
      let value = currentValue[i]
      serviceGroupValue[value] = {
        childrens: [],
        showAllChildren: true
      }
    }
    this.setState({
      filter: { ...filter, serviceGroupValue: { ...serviceGroupValue } }
    }, () => this.loadData())
  }

  handleCheckServiceGroupChild = (
    event,
    isChecked,
    currentValue,
    parentId,
  ) => {
    let newValue = event.target.value
    let regexNumber = new RegExp(/^\d+$/)
    if (regexNumber.test(newValue)) {
      newValue = parseInt(newValue)
    }
    let { filter } = this.state
    let { serviceGroupValue } = filter
    let parent = serviceGroupValue[parentId] || {}
    if (isChecked) {
      currentValue.push(newValue)
    } else {
      currentValue = currentValue.filter(v => v !== newValue)
    }
    let showAllChildren = parent.showAllChildren === undefined ? true : false
    if (isEmpty(currentValue) && !showAllChildren) {
      delete serviceGroupValue[parentId]
    } else {
      parent = {
        childrens: currentValue,
        showAllChildren: false,
      }
      serviceGroupValue = { ...serviceGroupValue, [parentId]: parent }
    }
    this.setState({
      filter: { ...filter, serviceGroupValue: { ...serviceGroupValue } }
    }, () => this.loadData())
  }

  handleCheckReminderStatus = (event, isChecked, currentValue) => {
    let newValue = event.target.value
    let regexNumber = new RegExp(/^\d+$/)
    if (regexNumber.test(newValue)) {
      newValue = parseInt(newValue)
    }
    let { filter } = this.state
    if (isChecked) {
      currentValue.push(newValue)
    } else {
      currentValue = currentValue.filter(v => v !== newValue)
    }
    let serviceReminderStatus = {}
    for (let i = 0; i < currentValue.length; i++) {
      let value = currentValue[i]
      serviceReminderStatus[value] = {
        childrens: [],
        showAllChildren: true
      }
    }
    this.setState({
      filter: { ...filter, serviceReminderStatusValue: { ...serviceReminderStatus } }
    }, () => this.loadData())
  }

  // Select Event
  // Open menu event
  onSelectEvent = async (event, synctheticEvent) => {
    let { currentEvent, principal } = this.state

    if (currentEvent && currentEvent.id === event.id) {
      this.setState({
        currentEvent: null,
        openMenuEvent: false,
        currentAnchor: null,
        principal: {},
      })
    } else {
      this.setState({
        currentEvent: event,
        openMenuEvent: true,
        currentAnchor: synctheticEvent.target,
        principal: {}
      })
      const { principalType, principalId } = event.originData.serviceTask
      let principalTypeObj = PrincipalMapping[principalType] || {}
      let { resource, extrasFilter } = principalTypeObj
      let params = {
        id: principalId,
      }
      if (extrasFilter) {
        params = {...params, filter: extrasFilter} 
      }
      const res = await Provider.dataProvider(GET_ONE, resource, params)
      if (res.data && res.data !== null) {
        principal = res.data
      }
      this.setState({ principal })
    }
  }

  // Close menu event
  closeMenuEvent = () => {
    this.setState({
      currentEvent: null,
      openMenuEvent: false,
      currentAnchor: null,
      openMenuNewServiceReminder: false,
    })
  }

  doneForUpdate = () => {
    this.loadData()
    this.closeMenuEvent()
  }

  errorForUpdate = () => {
    this.closeMenuEvent()
  }

  newEvent = (event) => {
    let { events } = this.state
    this.setState({
      events: [...events, { ...event, title: 'Không có tiêu đề', identifier: '00NEW' }],
      nEvent: event,
    })
  }

  handleCloseDialogNewServiceReminder = () => {
    this.setState({
      openDialogNewServiceReminder: false,
    })
  }

  doneForCreate = () => {
    this.loadData()
    this.setState({
      openMenuNewServiceReminder: false,
      openDialogNewServiceReminder: false,
      currentAnchor: null,
    })
  }

  errorForCreate = () => {
    this.handleCloseDialogNewServiceReminder()
  }

  doneForDelete = () => {
    this.loadData()
    this.setState({
      openMenuEvent: false,
      currentAnchor: null,
    })
  }

  errorForDelete = () => {
    this.setState({
      openMenuEvent: false,
      currentAnchor: null,
    })
  }

  // Close Menu New Reminder
  handleCloseMenuNewServiceReminder = () => {
    let { events } = this.state
    events = events.filter((event) => event.identifier !== '00NEW')
    this.setState({
      currentAnchor: null,
      openMenuNewServiceReminder: false,
      events
    })
  }

  // Open new reminder form
  handleOpenForm = (serviceGroup) => {
    let { nEvent } = this.state
    this.setState({
      openDialogNewServiceReminder: true,
      principalType: ServiceGroupMapping[serviceGroup.code],
      defaultDueAt: nEvent.start
    })
  }

  //customize event style for type
  eventPropGetter = (event) => {
    let { originData = {}, colorBorder, textDecoration } = event
    let { serviceTask = {} } = originData
    let { serviceGroup = {} } = serviceTask
    let newStyle = {
      backgroundColor: serviceGroup.color || 'lightgrey',
      color: 'black',
      borderRadius: '0px',
      border: `2px solid ${colorBorder}`,
      fontWeight: '200',
      fontSize: 16,
      paddingTop: 4,
      paddingBottom: 4,
      textDecoration
    }
    return {
      className: event.identifier === '00NEW' ? 'new' : '',
      style: newStyle
    }
  }

  openDrawer = () => {
    this.setState({
      openDrawer: true
    })
  }

  closeDrawer = () => {
    this.setState({
      openDrawer: false
    })
  }

  switchView = (viewMode) => {
    this.setState({
      viewMode
    })
  }

  render() {
    const {
      currentAnchor,
      currentEvent,
      events,
      principal,
      openDialogNewServiceReminder,
      openMenuNewServiceReminder,
      principalType,
      defaultDueAt,
      openMenuEvent,
      filter,
      openDrawer,
      viewMode,
      showMaintainItem
    } = this.state
    const { classes, match, location, translate } = this.props
    return (
      <>
        <Grid container className={classes.container} spacing={8}>
          <Grid item>
            <DrawerFilter
              classes={classes}
              open={openDrawer}
              onClose={this.closeDrawer}
              handleCheckServiceGroup={this.handleCheckServiceGroup}
              handleCheckServiceGroupChild={this.handleCheckServiceGroupChild}
              handleCheckReminderStatus={this.handleCheckReminderStatus}
              filter={filter}
            />
          </Grid>
          <Grid
            item
            style={{
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              // margin: 'auto',
              minWidth: '100%'
            }}
          >
            <div className={classes.actionContainer}>
              {viewMode === VIEW_CALENDAR && <Button
                onClick={this.openDrawer}
                color="primary"
                variant="contained"
                size="small"
              >
                {translate('button.search')}
              </Button>}
              <span className={classes.switchContainer}>
                <Button
                  onClick={() => {
                    this.switchView(VIEW_CALENDAR)
                    this.loadData()
                  }}
                  className={viewMode === VIEW_CALENDAR ? classes.switchButtonActive : ''}
                  size="small"
                >
                  {translate('button.calendar')}
                </Button>
                <Button
                  onClick={() => this.switchView(VIEW_TABLE)}
                  className={viewMode === VIEW_TABLE ? classes.switchButtonActive : ''}
                  size="small"
                >
                  {translate('button.list')}
                </Button>
              </span>
            </div>
            {viewMode === VIEW_CALENDAR && <>
              <Title title={translate('resources.reminders.name', { smart_count: 2 })} />
            <DnDCalendar
              popup
              selectable
              localizer={localizer}
              events={events}
              onEventDrop={this.moveEvent}
              resizable
              onEventResize={this.resizeEvent}
              onSelectSlot={this.newEvent}
              onNavigate={this.onNavigate}
              defaultView={BigCalendar.Views.MONTH}
              eventPropGetter={this.eventPropGetter}
              onSelectEvent={this.onSelectEvent}
              components={{
                eventWrapper: EventWrapper,
                // agenda: {
                //   event: AgendaEvent
                // }
              }}
              defaultDate={new Date()}
            />
            </>}
            {viewMode === VIEW_TABLE && <ServiceReminderList translate={translate} match={match} location={location} />}
          </Grid>
        </Grid>
        <MenuEvent
          anchorEl={currentAnchor}
          classes={classes}
          open={openMenuEvent}
          onClose={this.closeMenuEvent}
          event={currentEvent}
          principal={principal}
          done={this.doneForUpdate}
          error={this.errorForUpdate}
          doneForDelete={this.doneForDelete}
          errorForDelete={this.errorForDelete}
          handleView={this.showMaintainListItem}
          translate={translate}
        />
        <MenuNewServiceTask
          open={openMenuNewServiceReminder}
          anchorEl={currentAnchor}
          handleOpenForm={this.handleOpenForm}
          onClose={this.handleCloseMenuNewServiceReminder}
        />
        <DialogNewServiceReminder
          open={openDialogNewServiceReminder}
          handleClose={this.handleCloseDialogNewServiceReminder}
          isCreate={true}
          principalType={principalType}
          defaultDueAt={defaultDueAt}
          done={this.doneForCreate}
          error={this.errorForCreate}
          translate={translate}
        />
        {showMaintainItem && <MaintainGroupItem
          open={showMaintainItem}
          vehicleId={principal.vehicleId}
          scheduleId={principal.scheduleId}
          handleClose={() => { this.setState({ showMaintainItem: false }) }}
        />}
      </>
    )
  }
}

export default compose(
  connect(null, { saveReminderAction }),
  withStyles(styles),
  translate
)(_calendar)
