import React, { Component } from  'react'
import { Provider } from '../provider'
import { translate } from 'react-admin'
import {
  Dialog,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  TableHead,
  DialogTitle,
} from '@material-ui/core'
import compose from 'recompose/compose'

class MaintainGroupItem extends Component {
  state = {
    schedules: [],
    maintainItems: [],
    open: false,
    isLoading: false,
    requiredMaintainItems: [],
  }
  componentDidMount() {
    
    this.loadData()

  }
  loadData = async () => {
    if (!this.props.vehicleId || !this.props.scheduleId) {
      return      
    }
    this.setState({isLoading: true})
    let rsSchedule = await Provider.dataProvider('REMOTE', 'maintainschedules',
      { 
        method: '', 
        requestMethod: 'GET',
        data: {}
      })
    let rsAllItems = await Provider.dataProvider('REMOTE', 'maintainitems',
      { 
        method: '', 
        requestMethod: 'GET',
        data: {}
      }
    )
    // console.log('vehicleId', this.props.vehicleId)

    let rsGroupMaintain = await Provider.dataProvider('REMOTE', 'vehiclemaintains',
      { 
        method: 'getMaintainSchedule', 
        requestMethod: 'GET',
        data: {vehicleId: this.props.vehicleId}
      }
    )

    // let rsMaintainGroup = await Provider.dataProvider('REMOTE', 'maintaingroups',
    //   { 
    //     method: '', 
    //     requestMethod: 'GET',
    //     data: { id: this.props.maintainGroupId }
    //   }
    // )
    // // console.log('vehicleId', rsMaintainGroup)

    let dtSchedule = rsSchedule.data.sort((a, b) => (a.month-b.month))
    const {
      schedules, maintainItems 
    } = this.buildTable(dtSchedule, rsAllItems.data,  rsGroupMaintain.data.data.maintainGroups)
    let requiredMaintainItems = schedules.find(item => item.id === this.props.scheduleId) || {}
    const items = requiredMaintainItems.items.sort((a,b)=> (a.item.id - b.item.id))
    this.setState({
      schedules: schedules ,
      maintainGroups: rsGroupMaintain.data.data.maintainGroups,
      maintainItems: maintainItems,
      requiredMaintainItems: items || [],
      isLoading: false
    })
  }
  buildTable = (schedules, maintainItems, groupItemSchedule) => {
    for (let i = 0, lengthI = groupItemSchedule.length; i < lengthI; i++) {
      const grI = groupItemSchedule[i]
      const scheduleIdx = schedules.findIndex(obj => obj.id === grI.scheduleId)
      if (scheduleIdx !== -1) {
        let scheduleItems = []
        grI.items.forEach(item => {
          const it = maintainItems.find(mtItem => mtItem.id === item.itemId)
          if (it) {
            scheduleItems.push({...it, ...item, scheduleId: grI.scheduleId})
          }
        })
        schedules[scheduleIdx].items = scheduleItems
      }
    }
    for (let i = 0; i < schedules.length; i++) {
      let scI = schedules[i]
      for (let j = i+1; j < schedules.length; j++) {
        let scJ = schedules[j]
        if (scJ.month % scI.month === 0) {
          scI.items && scI.items.forEach(item => {
            if (!scJ.items) {
              scJ.items = []
            }
            let ctItem = scJ.items.find(it => (item.itemId === it.itemId && item.actionCode === it.actionCode))
            if (!ctItem) {
              scJ.items.push({...item})
            }            
          })
        }
      }
    }
    return {
      schedules, maintainItems
    }

  }
  handleOpen = () => {
    this.loadData()
  }
  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.loadData()
    }
  }
  handleHeaderClick = schedule => {
    // console.log('schedule selected', schedule)
  }

  // renderCellItem = (row)
  render() {
    const { requiredMaintainItems } = this.state
    const { classes, open, handleClose } = this.props
    return (       
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Thông tin lịch bảo dưỡng</DialogTitle>
        <div style={{display: 'flex', marginLeft: 20}}>
          <span style={{margin: 10}}>C:  Kiểm tra</span>
          <span style={{margin: 10}}>R:  Thay thế</span>
          <span style={{margin: 10}}>M:  Bảo dưỡng</span>
        </div>      
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={30} className={classes.head}>Hạng mục</TableCell>
              <TableCell width={30} className={classes.head}>Hoạt động</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {              
              requiredMaintainItems.map((item, idx) => {
                return <TableRow key={idx}>
                  <TableCell className={classes.cell}>{item.name}</TableCell>
                  <TableCell className={classes.cell}>{item.actionCode}</TableCell>
                </TableRow>
              })
            }
          </TableBody>
        </Table>
      </Dialog>
    )
  }
}
const styles = {
  table: {
    // minWidth: 300,
    width: 500
  },
  cell: {
    borderWidth: 1,
    borderColor: 'darkgray',
    borderStyle: 'solid',
    align: 'center',
    maxWidth: 40,
    width:'auto',
    padding: 3,
    textAlign: 'center',
  },
  head: {
    backgroundColor: 'lightgray',
    position: 'sticky',
    top: 0,
    borderWidth: 1,
    borderColor: 'darkgray',
    borderStyle: 'solid',
    align: 'center',
    width: 'auto',
    padding: 3,
    textAlign: 'center',
  }

}
const enhance = compose(withStyles(styles), translate)
export default enhance(MaintainGroupItem)
