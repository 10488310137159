import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import { AssignDialog } from './AddSettingButton'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { reset } from 'redux-form'

class EditCompanyAgencyButton extends Component {

  state = {
    open: false,
    record: {},
  }

  openDialog = () => {
    let { record } = this.props
    this.setState({ open: true, record })
  }

  closeDialog = () => {
    this.setState({ open: false, record: {} })
  }

  onSaveDone = () => {
    let { showNotification, refreshView } = this.props
    this.closeDialog()
    refreshView()
    showNotification('notification.register_company_success')
  }

  onSaveError = () => {
    let { showNotification,refreshView } = this.props
    this.closeDialog()
    refreshView()
    showNotification('notification.register_company_failure', 'warning')
  }

  render() {
    let { translate } = this.props
    let { open, record } = this.state
    return <>
      <Button
        onClick={this.openDialog}
        color='primary'
      >
        {translate('button.edit')}
      </Button>
      <AssignDialog
        open={open}
        onClose={this.closeDialog}
        translate={translate}
        onDone={this.onSaveDone}
        onError={this.onSaveError}
        record={record}
        action="edit"
        form="setting-edit"
      />
    </>
  }
}

const enhance = compose(translate, connect(null, { reset, showNotification, refreshView }))

export default enhance(EditCompanyAgencyButton)