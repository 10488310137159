import {
  TOGGLE_LAYER_STATE,
  ADD_MAP_LAYER,
  MAP_MOVE,
  ADD_ROUTE,
  TOGGLE_ROUTE,
  EDIT_ROUTE,
  UPDATE_ROUTE,
} from './actions'

// Template for 1 layer
//  'route1': {
//       type: MapProps.LayerTypes.GEO_JSON,
//       shape: MapProps.Shapes.LINE,
//       data: geoJson1,
//       identifier: 'route1',
//       name: 'route1',
//       color: 'blue',
//       visibility: MapProps.LayerStates.VISIBLE,
//       actions: {
//         mouseover: (feature, layer) => {},
//         mouseout: (feature, layer) => {}
//       }
//     }

function addRoute(state, action) {
  let data = action.payload
  let { routes } = state
  let id = data.id
  routes[id] = data
  return { ...state, routes: { ...routes }}
}

function toggleRoute(state, action) {
  let data = action.payload
  let { routes } = state
  let id = data.id
  routes[id] = data
  return { ...state, routes: { ...routes } }
}

function editRoute(state, action) {
  let route = action.payload
  return { ...state, route: route }
}

function updateRoute(state, action) {
  let route = action.payload
  return { ...state, route: route }
}

export const map = (state = {
  routes: {},
  layers: {},
  center: [21, 105.85],
  zoom: 13,
  bbox: []
}, action) => {
  switch (action.type) {
    case ADD_MAP_LAYER: {

      let mapLayer = action.payload
      let { layers } = state

      let identifier = mapLayer.identifier
      layers[identifier] = mapLayer

      return { ...state, layers: { ...layers } }
    }
    case MAP_MOVE: {
      let { zoom, center, bbox } = action.payload
      return { ...state, zoom, center, bbox }
    }
    case TOGGLE_LAYER_STATE: {
      let { payload } = action
      let { identifier, visibility } = payload
      let { layers } = state

      if (layers[identifier]) {
        layers[identifier].visibility = visibility
      }
      return { ...state, layers: { ...layers } }
    }
    case ADD_ROUTE:
      return addRoute(state, action)
    case TOGGLE_ROUTE:
      return toggleRoute(state, action)
    case EDIT_ROUTE:
      return editRoute(state, action)
    case UPDATE_ROUTE:
      return updateRoute(state, action)
    default:
      return state
  }
}