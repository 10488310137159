import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Error as ErrorIcon,
  Info as InfoIcon,
  Close as CloseIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
} from '@material-ui/icons'
import {
  green,
  amber,
} from '@material-ui/core/colors'
import {
  SnackbarContent,
  IconButton,
  withStyles,
} from '@material-ui/core'

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const styles = theme => ({
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
})

const defaultAction = (classes, onClose) => {
  return [
    <IconButton
      key="close"
      aria-label="Close"
      color="inherit"
      className={classes.close}
      onClick={onClose}
    >
      <CloseIcon className={classes.icon} />
    </IconButton>
  ]
}

function MySnackbarContent(props) {
  const { 
    classes,
    action,
    className,
    message,
    onClose,
    onClick,
    labelButtonAction,
    variant,
    ...other
  } = props
  const Icon = variantIcon[variant]
  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={action ? action(onClick, labelButtonAction) :  onClose && defaultAction(classes, onClose) }
      {...other}
    />
  )
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
}

export default withStyles(styles)(MySnackbarContent)
