import React, { Component, Fragment } from 'react'
import {
  Title,
  translate,
  SimpleForm,
  TextInput,
  showNotification,
  WithPermissions,
} from 'react-admin'
import {
  Card,
  withStyles,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import compose from 'recompose/compose'
import RechargeToolbar from './RechargeToolbar'
import { Provider } from '../provider'
import { change as changeForm } from 'redux-form'
import _ from 'lodash'
import { connect } from 'react-redux'
import { changeBreadcrumb } from '../breadcrumb/action'
import { DatePickerInput } from '../common/DatePicker'
import * as permission from '../utils/permission'
import { validate } from './validate'
import moment from 'moment'
import { MoneyInput } from '../common/MoneyInput'
import { formatCurrency } from '../utils/formatUtil'
import querystring from 'querystring'

const resultDialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  container: {
    padding: 0
  },
  line: {
    justifyContent: 'space-between',
    display: 'flex',
    padding: 8,
  },
}

const _ResultDialog = ({
  classes,
  translate,
  open,
  onClose,
  record = {},
}) => {
  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle className={classes.title}>
      <span className={classes.textTitle}>{translate('notification.recharge_member_card_success')}</span>
    </DialogTitle>
    <DialogContent className={classes.container}>
      <div className={classes.line}>
        <div>{_.get(record, 'segmentName', '')}</div>
        <div>{_.get(record, 'code', '')}</div>
      </div>
      <div className={classes.line}>
        <div>{translate('resources.membercards.fields.rechargeMonth')}</div>
        <div>{_.get(record, 'rechargeMonth', '')}</div>
      </div>
      <div className={classes.line}>
        <div>{translate('resources.membercards.fields.amount')}</div>
        <div>{formatCurrency(_.get(record, 'amount', ''), 'vi-VN', 'VNĐ')}</div>
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>{translate('button.close')}</Button>
    </DialogActions>
  </Dialog>
}
const ResultDialog = compose(translate, withStyles(resultDialogStyle))(_ResultDialog)

const infomationStyle = {
  root: {
    padding: 18,
  },
  noPaddingVertical: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}

const _Information = ({
  classes,
  translate,
  rechargedMonths = [],
  changeForm,
}) => {
  const extra = {
    resource: 'membercards',
    fullWidth: true,
  }
  const now = moment()
  const date = now.get('date')
  const minDate = date > 10 ? moment(now).add(1, 'month').toDate() : now.toDate()

  const handleChangeMonth = (value) => {
    const month = value ? moment(value).format('MM/YYYY') : value
    const isRechargedMonth = _.findIndex(rechargedMonths, rechargedMonth => rechargedMonth === month) >= 0
    const status = isRechargedMonth ? 'resources.membercards.status.rechargedMonth' : 'resources.membercards.status.noRechargedMonth'
    changeForm(RECHARGE_MEMBER_CARD_FORM, 'status', translate(status))
  }

  return <div className={classes.root}>
    <Typography variant="h4" >
      {translate('resources.membercards.title.recharge')}
    </Typography>
    <Grid container>
      <Grid item xs={12} md={6} className={classes.noPaddingVertical}>
        <TextInput
          source="code"
          required
          {...extra}
        />
        <DatePickerInput
          source="rechargeMonth"
          views={['year', 'month']}
          dateMask={[/\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          dateFormat="MM/YYYY"
          minDate={minDate}
          handleChangeMonth={handleChangeMonth}
          keyboard={true}
          clearable={true}
          isRequired
          allowEmpty
          {...extra}
        />
        <MoneyInput
          disabled
          suffix="VNĐ"
          source="amount"
          {...extra}
        />
      </Grid>
      <Grid item xs={12} md={6} className={classes.noPaddingVertical}>
        <TextInput
          source="segmentName"
          disabled
          {...extra}
        />
        <TextInput
          source="status"
          disabled
          {...extra}
        />
      </Grid>
    </Grid>
  </div>
}

const enhanceInformation = compose(translate, withStyles(infomationStyle))
const Information = enhanceInformation(_Information)

const style = {
  root: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
  },
  form: {
    width: '100%'
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    position: 'absolute',
  },
  emptySnack: {
    width: '100%',
    maxWidth: '100%',
  },
}

const RECHARGE_MEMBER_CARD_FORM = 'recharge-member-card-form'

class Recharge extends Component {

  state = {
    loading: false,
    openResult: false,
    content: {},
    rechargedMonths: [],
    isRechargedMonth: false,
    infoData: {},
  }

  async componentDidMount() {
    await this.init()
    let { infoData } = this.state
    if (!_.isEmpty(infoData)) {
      this.openResultDialog()
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { routeId, companyId } = nextProps
    let { routeDefault, companyDefault, companyChoices, routeChoices } = prevState
    if (!routeId) {
      routeDefault = (routeChoices && routeChoices.length > 0) && routeChoices[0].id
    } else if (routeId !== routeDefault) {
      routeDefault = routeId
    }
    if (!routeId) {
      routeDefault = (companyChoices && companyChoices.length > 0) && companyChoices[0].id
    } else if (companyId !== companyDefault) {
      companyDefault = companyId
    }
    return { routeDefault, companyDefault }
  }

  init = async () => {
    let { translate, permissions, changeBreadcrumb, location } = this.props
    let agencyRole = permission.isAgencyRole(permissions)
    let companyRole = permission.isCompanyRole(permissions)
    let paths = [
      { label: translate('resources.membercards.name', { smart_count: 2 }), to: '/membercards' },
      { label: translate('resources.membercards.recharge'), to: '' },
    ]
    changeBreadcrumb({ paths })
    let { companyChoices, routeChoices, companyDefault, routeDefault } = this.state
    if (agencyRole) {
      companyChoices = await this.getRegistedBuyTicketCompanies()
      if (companyChoices && companyChoices.length > 0) {
        companyDefault = companyDefault || companyChoices[0].id
      }
    }
    routeChoices = await this.getRoutes(companyDefault)
    if (routeChoices && routeChoices.length > 0) {
      routeDefault = routeDefault || routeChoices[0].id
    }
    let search = _.get(location, 'search', '')
    let infoData = {}
    if (search) {
      let params = querystring.parse(search.slice(1))
      let id = _.get(params, 'memberCardId')
      let rechargeMonth = _.get(params, 'rechargeMonth')
      if (id && rechargeMonth) {
        let res = await Provider.dataProvider('REMOTE', 'memberCards', {
          method: id,
          requestMethod: 'GET',
          data: {
            filter: {
              include: [
                {
                  relation: 'fare',
                  scope: {
                    fields: ['id', 'name', 'amount', 'routeId'],
                    include: [{
                      relation: 'route',
                      scope: {
                        fields: ['id', 'name']
                      }
                    }],
                  },
                }
              ],
            }
          }
        })
        if (res && res.data) {
          let memberCard = _.get(res, 'data')
          let code = _.get(memberCard, 'code')
          let amount = _.get(memberCard, 'fare.amount')
          let segmentName = `${_.get(memberCard, 'itemDetail.segmentName','')} (${_.get(memberCard, 'fare.route.name','')})`
          infoData = {
            segmentName,
            code,
            rechargeMonth,
            amount,
          }
        }
      }
    }
    this.setState({
      routeDefault,
      companyDefault,
      companyChoices,
      routeChoices,
      agencyRole,
      companyRole,
      infoData
    })
  }

  getRegistedBuyTicketCompanies = async () => {
    let res = await Provider.dataProvider('REMOTE', 'companies', {
      method: 'getBusTourCompaniesByAgency',
      requestMethod: 'GET'
    })
    if (res && res.data) {
      return res.data
    }
  }

  getRoutes = async (companyId) => {
    let res = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: {
        where: {
          status: { neq: '20ARCHIVED' }
        },
        isBusTour: true,
        withNumberOfProduct: true,
        byCompany: companyId,
        fields: [
          'id',
          'name',
          'numberOfProduct',
          'routeGroupId',
          'status',
          'originId',
          'destinationId',
          'distance',
          'companyId'
        ],
        include: {
          relation: 'company',
          scope: {
            fields: ['id', 'name']
          }
        }
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { location } = this.props
    let { location: prevLocation } = prevProps
    const updated = !(prevLocation === location)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      await this.init()
    }
  }

  openResultDialog = () => {
    this.setState({ openResult: true })
  }

  closeResultDialog = () => {
    this.setState({ openResult: false })
  }

  render() {
    let { translate, classes, changeForm, } = this.props
    let {
      companyDefault,
      memberCardId,
      rechargedMonths,
      isRechargedMonth,
      openResult,
      infoData,
    } = this.state
    return <Fragment>
      <Title title={translate('resources.membercards.recharge')} />
      <Card className={classes.container}>
        <SimpleForm
          form={RECHARGE_MEMBER_CARD_FORM}
          resource="membercards"
          className={classes.form}
          toolbar={<RechargeToolbar
            form={RECHARGE_MEMBER_CARD_FORM}
            memberCardId={memberCardId}
            isRechargedMonth={isRechargedMonth}
            openResultDialog={this.openResultDialog}
          />}
          record={{ companyId: companyDefault }}
          asyncBlurFields={['code']}
          asyncValidate={async (record) => {
            let { code, rechargeMonth, } = record
            if (!code) {
              throw { code: translate('error_messages.required.member_cards.code') }
            }
            return Provider.dataProvider('REMOTE', 'membercards', {
              method: 'findByCode',
              data: { code },
            }).then (result => {
              const memberCard = _.get(result, 'data.memberCard')
              if (_.isEmpty(memberCard)) return
              const rechargedMonths = _.get(result, 'data.rechargeMonths', [])
              rechargeMonth = moment(rechargeMonth).format('MM/YYYY')
              const isRechargedMonth = _.findIndex(rechargedMonths, rechargedMonth => rechargedMonth === rechargeMonth) >= 0 
              const status = isRechargedMonth ? 'resources.membercards.status.rechargedMonth' : 'resources.membercards.status.noRechargedMonth'
              const amount = _.get(memberCard, 'fare.amount', 0)
              const segmentName = `${_.get(memberCard, 'itemDetail.segmentName', '')} (${_.get(memberCard, 'fare.route.name', '')})`
              this.setState({
                memberCardId: memberCard.id,
                rechargedMonths,
                isRechargedMonth,
                infoData: {
                  code,
                  rechargeMonth,
                  segmentName,
                  amount,
                },
              })
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'rechargeMonth', moment().toDate())
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'segmentName', segmentName)
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'amount', amount)
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'status', translate(status))
            }).catch(err => {
              let messageCode = _.get(err, 'code') || ''
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'rechargeMonth', '')
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'segmentName', '')
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'amount', '')
              changeForm(RECHARGE_MEMBER_CARD_FORM, 'status', '')
              throw { code: translate(`error_messages.codes.member_cards.${messageCode.toLowerCase()}`) }
            })
          }}
          validate={validate}
        >
          <Information
            rechargedMonths={rechargedMonths}
            changeForm={changeForm}
          />
        </SimpleForm>
      </Card>
      { openResult && <ResultDialog
        open={openResult}
        onClose={this.closeResultDialog}
        record={infoData}
      /> }
    </Fragment>
  }
}

const mapStateToProps = state => {
  let filterValues = state.form[RECHARGE_MEMBER_CARD_FORM] && state.form[RECHARGE_MEMBER_CARD_FORM].values ? state.form[RECHARGE_MEMBER_CARD_FORM].values : {}
  let { routeId, companyId, departureDate } = filterValues
  return { routeId, companyId, departureDate }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(mapStateToProps, {
    showNotification,
    changeBreadcrumb,
    changeForm,
  })
)

const RechargeWithPermission = ({ ...props }) => <WithPermissions
  render={({ permissions }) => permissions ? <Recharge permissions={permissions} {...props} /> : null}
/>

export default enhance(RechargeWithPermission)
