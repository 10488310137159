import React, { Component } from 'react'
import {
  TextInput,
  FunctionField,
  SimpleForm,
  addField,
  translate,
  showNotification,
  required,
  minLength,
  maxLength
} from 'react-admin'
import {
  Grid,
  withStyles,
  TextField
} from '@material-ui/core'
import { Field } from 'redux-form'
import ReactDatagrid from 'react-data-grid'
import { csvToGrid, arrayToGrid, gridToArray } from '../utils/csv'
import { compose } from 'recompose'
import CustomToolbar from '../common/CustomToolbarForm'
import ChipArrayInput from './../common/ChipArrayInput'
import { connect } from 'react-redux'
import ConfirmDialog from '../common/ConfirmDialog'
import { Provider } from '../provider'
import { isEmpty2D } from '../utils/array'
import _ from 'lodash'

const styles = theme => {
  return {
    seatChip: {
      margin: theme.spacing.unit
    },
  }
}

class CellEditor extends Component {
  constructor(props) {
    super(props)
    this.isObject = typeof props.value === 'object'
  }

  getValue() {
    let updated = {}
    let { props, isObject } = this
    let value = this.getInputNode().value
    updated[props.column.key] = isObject ? { ...props.value, value } : value
    return updated
  }

  getInputNode() {
    return this.input
  }

  render() {
    let { props, isObject } = this
    return (
      <input
        ref={ref => this.input = ref}
        defaultValue={isObject ? (props.value ? props.value.value : '') : props.value}
        type="text"
      />
    )
  }
}

function get2DArrayValue(array, row, col) {
  let rowLen = array.length
  if (row >= rowLen) return ''

  let rowValue = array[row]
  if (!Array.isArray(rowValue)) return ''

  let colLen = rowValue.length
  if (col >= colLen) return ''

  return !rowValue[col] ? '' : rowValue[col]
}

/**
 * Wrap the given array to 5x10 array
 * 
 * @param {*} array 
 */
function wrap(array, numRow, numCol) {
  if (!numRow || !numCol) return array

  const rowLen = numRow
  const colLen = numCol

  let wrapped = new Array(rowLen)
  for (let rowIdx = 0; rowIdx < rowLen; rowIdx++) {
    wrapped[rowIdx] = new Array(colLen)
    for (let colIdx = 0; colIdx < colLen; colIdx++) {
      let val = get2DArrayValue(array, rowIdx, colIdx)
      wrapped[rowIdx][colIdx] = val
    }
  }
  return wrapped
}

const unwrap = (codesFistFloor, codesSecondFloor) => {
  let isEmptyRow = row => {
    if (!row) return true
    return row.every(value => !value.trim())
  }
  let minRow = Math.min(
    _.findIndex(codesFistFloor, row => !isEmptyRow(row)),
    _.findIndex(codesSecondFloor, row => !isEmptyRow(row)),
  )
  codesFistFloor = _.drop(codesFistFloor, minRow)
  codesSecondFloor = _.drop(codesSecondFloor, minRow)
  let maxRow = Math.max(
    _.findLastIndex(codesFistFloor, row => !isEmptyRow(row)),
    _.findLastIndex(codesSecondFloor, row => !isEmptyRow(row))
  )
  codesFistFloor = _.dropRight(codesFistFloor, Math.max(codesFistFloor.length - maxRow - 1, 0))
  codesSecondFloor = _.dropRight(codesSecondFloor, Math.max(codesSecondFloor.length - maxRow - 1, 0))
  let minCol = _.min(_.concat(
    codesFistFloor.map(row => {
      let res = _.findIndex(row, cell => !!cell)
      return res === -1 ? row.length : res
    }),
    codesSecondFloor.map(row => {
      let res = _.findIndex(row, cell => !!cell)
      return res === -1 ? row.length : res
    })
  ))
  for (let rIdx = 0; rIdx < codesFistFloor.length; rIdx++){
    codesFistFloor[rIdx] = _.drop(codesFistFloor[rIdx], minCol)
    codesSecondFloor[rIdx] = _.drop(codesSecondFloor[rIdx], minCol)
  }
  let maxCol = _.max(_.concat(
    codesFistFloor.map(row => _.findLastIndex(row, cell => !!cell)),
    codesSecondFloor.map(row => _.findLastIndex(row, cell => !!cell))
  ))
  for (let rIdx = 0; rIdx < codesFistFloor.length; rIdx++){
    codesFistFloor[rIdx] = _.dropRight(codesFistFloor[rIdx], Math.max(codesFistFloor[rIdx].length - maxCol - 1, 0))
    codesSecondFloor[rIdx] = _.dropRight(codesSecondFloor[rIdx], Math.max(codesSecondFloor[rIdx].length - maxCol - 1, 0))
  }
  return {codesFistFloor, codesSecondFloor}
}

const emptyArr = (numOfRow, numOfColumn) => {
  let arr = new Array(numOfRow)
  for (let i = 0; i < arr.length; i++) {
    arr[i] = new Array(numOfColumn)
    for (let j = 0; j < arr[i].length; j++) {
      arr[i][j] = ''
    }
  }
  return arr
}

export const getNumberOfRowColumn = (array) => {
  let numOfRow = array ? array.length : 0
  let numOfCol = 0
  if (array instanceof Array) {
    let maxCol = 0
    for (let row of array) {
      if (row && (row.length > maxCol)) maxCol = row.length
    }
    numOfCol = maxCol
  }
  return {
    numOfCol,
    numOfRow
  }
}

export const getNumberOfRowColumnGrid = (obj1, obj2) => {
  let tempObj = {}
  tempObj.numOfCol = Math.max(obj1.numOfCol, obj2.numOfCol)
  tempObj.numOfRow = Math.max(obj1.numOfRow, obj2.numOfRow)
  return tempObj
}

class _FieldNumberCustom extends Component {
  render() {
    const { input, source: name, label, onChangeRowCol } = this.props
    return <TextField
      inputProps={{
        min: '1',
        max: '20'
      }}
      value={input.value}
      onChange={e => {
        let value = parseInt(e.target.value)
        onChangeRowCol(value, name)
        input.onChange(parseInt(value))
      }}
      type="number"
      label={label}
      fullWidth
    />
  }
}

const enhanceFieldNumber = compose(translate, addField)

const FieldNumberCustom = enhanceFieldNumber(_FieldNumberCustom)

class _SeatMapInput extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      data: [], 
      columns: [], 
      rows: [], 
      version: new Date().getTime(), 
      label: props.label 
    }
  }
  static getDerivedStateFromProps(nextProps, previousState){
    let { numOfCol, numOfRow, input, translate } = nextProps
    let { value } = input
    value = wrap(value, numOfRow, numOfCol)
    let { rows, columns } = arrayToGrid(value, false, translate('resources.seatmaps.fields.column'))
    columns = columns.map(v => {
      return { editable: true, editor: CellEditor, ...v }
    })
    if (rows !== previousState.rows || columns !== previousState.columns){
      return({ rows, columns })
    }
    return null
  }

  onPaste(evt) {
    let { input } = this.props
    evt.preventDefault()
    evt.stopPropagation()
    let paste = (evt.clipboardData || window.clipboardData).getData('text')
    let { data, columns, rows } = csvToGrid(paste, { header: false })
    columns = columns.map(v => {
      return { editable: true, editor: CellEditor, ...v }
    })
    this.setState({ data, columns, rows, version: new Date().getTime() })
    let array = gridToArray({ columns, rows })
    let inputValue = array.rows
    input.onChange(inputValue)
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    let { input } = this.props
    let { columns, rows } = this.state
    let column = Object.keys(updated)[0]
    let startValue = updated[column]
  
    rows[fromRow] = {...rows[fromRow], ...{[column]: startValue}}

    if (fromRow < toRow) {
      const regexp =  /^(.*?)([0-9]*)$/
      if (regexp.test(startValue)) {
        //A1 -> ['A1', 'A', '1']
        const array = startValue.match(regexp)

        const originalValue = array[0]
        const identifier = array[1]
        const num = Number(array[2])

        if (num) {
          for (let idx = fromRow; idx <= toRow; idx++) {
            const runningNum = num + idx - fromRow
            const value = `${identifier}${runningNum}`
            let newObj = {
              [column]: value
            }
            rows[idx] = { ...rows[idx], ...newObj }
          }
        } else {
          for (let idx = fromRow; idx <= toRow; idx++) {
            let newObj = {
              [column]: identifier || originalValue
            }
            rows[idx] = { ...rows[idx], ...newObj }
          }
        }
      }
    }
    
    let array = gridToArray({ columns, rows })
    let inputValue = array.rows
    input.onChange(inputValue)
    this.setState({ rows, version: new Date().getTime() })
  }

  render() {
    let { columns, rows, label } = this.state
    return (
      <div onPaste={(evt) => this.onPaste(evt)} style={{ outline: 'none', cursor: 'text' }}>
        <p>{label}</p>
        <ReactDatagrid
          columns={columns}
          rowGetter={i => rows[i]}
          rowsCount={rows.length}
          enableCellSelect={true}
          onGridRowsUpdated={(v) => this.onGridRowsUpdated(v)}
        />
      </div>
    )
  }
}

const SeatMapInput = compose(
  addField,
  translate
)(_SeatMapInput)

class FormSave extends Component {
  state = {
    record: {},
    showDialog: false,
    showSaveDialog: false,
    recordEditing: {},
  }

  static getDerivedStateFromProps(props, state) {
    let { record: data, importRecord } = props
    let { record } = state
    if (!_.isEmpty(importRecord)) {
      record = {
        ...record,
        ...importRecord
      }
    } else {
      if (data && data.id) {
        if (record !== data) {
          let { numOfCol, numOfRow } = getNumberOfRowColumnGrid(getNumberOfRowColumn(data.codesFistFloor), getNumberOfRowColumn(data.codesSecondFloor))
          record = {
            ...data, 
            numOfCol,
            numOfRow,
          }
        }
      } else {
        let tempArr = emptyArr(10,5)
        record = {
          numOfRow: 10,
          numOfCol: 5,
          codesFistFloor: tempArr,
          codesSecondFloor: tempArr,
          privateCode: null,
          name: ''
        }
      }
    }
    return {
      ...state,
      record,
    }
  }

  onSave = record => {
    let { action } = this.props
    if (isEmpty2D(record.codesFistFloor) && isEmpty2D(record.codesSecondFloor)){
      this.setState({ showSaveDialog: true, recordEditing: record })
    } else {
      record = {
        ...record,
        ...unwrap(record.codesFistFloor, record.codesSecondFloor)
      }
      if (action === 'edit') {
        this.edit(record)
      } else if(action === 'create') {
        this.create(record)
      }
    }
  }

  onSaveOk = () => {
    let { action } = this.props
    let { recordEditing } = this.state
    if (action === 'edit') {
      this.edit(recordEditing)
    } else if(action === 'create') {
      this.create(recordEditing)
    }
    this.setState({ showSaveDialog: false })
  }

  onSaveCancel = () => {
    let { history } = this.props
    this.setState({ showSaveDialog: false })
    history.push('/seatmaps')
  }

  create = record => {
    let { history, showNotification } = this.props
    Provider.dataProvider('CREATE', 'seatmaps', {
      data: record
    }).then(() => {
      history.push('/seatmaps')
      showNotification('notification.save_seatmaps_sucess')
    }).catch(() => {
      history.push('/seatmaps')
      showNotification('notification.save_seatmaps_fail')
    })
  }

  edit = record => {
    let { history, showNotification } = this.props
    Provider.dataProvider('UPDATE', 'seatmaps', {
      id: record.id,
      data: record
    }).then(() => {
      history.push('/seatmaps')
      showNotification('notification.save_seatmaps_sucess')
    }).catch(() => {
      history.push('/seatmaps')
      showNotification('notification.save_seatmaps_fail')
    })
  }

  onHandleChangeRowCol = (value, name) => {
    let { record } = this.state
    this.setState({
      record: {...record, [name]: value },
    })
  }

  render() {
    let { translate, classes } = this.props
    const extra = {
      resource: 'seatmaps',
      fullWidth: true,
    }
    let { record, showSaveDialog } = this.state
    return <>
        <SimpleForm
          record={record}
          toolbar={<CustomToolbar />}
          save={this.onSave}
        >
          <Grid container fullWidth>
            <Grid item xs={12}>
              <TextInput 
                source="name"
                validate={[required(), minLength(6), maxLength(255)]}
                {...extra}
              />
            </Grid>
            <Grid item xs>
              <FieldNumberCustom
                onChangeRowCol={this.onHandleChangeRowCol} 
                source="numOfRow"
                label={translate('resources.seatmaps.fields.numOfRow')} 
              />
            </Grid>
            <Grid item xs>
              <FieldNumberCustom 
                onChangeRowCol={this.onHandleChangeRowCol} 
                source="numOfCol"
                label={translate('resources.seatmaps.fields.numOfCol')}
              />
            </Grid>
            <Grid item xs={9}>
            </Grid>
            <Grid item xs={6}>
              <SeatMapInput
                numOfCol={record.numOfCol}
                numOfRow={record.numOfRow}
                source="codesFistFloor"
                label={translate('resources.seatmaps.fields.firstFloor')}
              />
            </Grid>
            <Grid item xs={6}>
              <SeatMapInput
                numOfCol={record.numOfCol}
                numOfRow={record.numOfRow}
                source="codesSecondFloor"
                label={translate('resources.seatmaps.fields.secondFloor')}
              />
            </Grid>
            <Grid item xs={12}>
              <p>{translate('resources.seatmaps.fields.privateCode')}</p>
              <FunctionField
                source="privateCode"
                render={() => {
                  return (
                    <Field
                      name='privateCode'
                      classes={classes}
                      component={ChipArrayInput}
                    />
                  )
                }} 
              />
            </Grid>
          </Grid>
        </SimpleForm>
        <ConfirmDialog
          open={showSaveDialog}
          onClose={()=>this.setState({showSaveDialog: false})}
          content={translate('notification.save_seatmaps_warning')}
          onCancel={this.onSaveCancel}
          onOk={this.onSaveOk}
          title={translate('resources.common.warning')}
          data={{}}
          translate={translate}
        />
      </>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification })
)

export default enhance(FormSave)
