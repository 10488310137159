import React, { Fragment, Component }from 'react'
import {
  Datagrid,
  List,
  TextField,
  ReferenceField,
  Create,
  Edit,
  translate,
  FunctionField,
  showNotification,
  EditButton,
  Filter,
  ReferenceInput,
} from 'react-admin'
import { withStyles, Button, Chip } from '@material-ui/core'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import SearchInput from '../common/SearchInput'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { resourceRequest, invokeAction } from '../action'
import { push } from 'react-router-redux'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'

const styles = theme => ({
  button: {
    margin: theme.spacing.unit,
  },
  chip: {
    marginRight: 4
  }
})

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
  }
})

const mapStateFromProps = (state, ownProps) => {
  let { selectedIds } = ownProps
  let selected = {}
  let { devices } = state.admin.resources

  if (devices) {
    let { data = {} } = devices
    selected = data[selectedIds] || {}
  }
  return { selected }
}

const enhance = compose(
  connect(mapStateFromProps, { resourceRequest, showNotification, push }),
  withStyles(styles),
  translate,
)

const DeviceListFilter = withStyles(styleListFilter)(({ classes, ...props}) => {
  return <Filter {...props}>
    <SearchInput 
      helperText="resources.devices.helper_text"
      source="q"
      alwaysOn
    />
    <ReferenceInput
      reference="companies"
      source="operatorId"
      filter={{ '../fields': ['id', 'name'] }}
      perPage={1000}
      alwaysOn
      className={classes.otherSearchInput}
    >
      <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
    </ReferenceInput>
  </Filter>
})

class PushButton extends Component {
  state = {
    selected: {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { selected } = nextProps
    let { selected: currentSelected } = prevState
    if (selected !== currentSelected) {
      currentSelected = selected
    }
    return {
      selected: currentSelected
    }
  }

  async handleClick(v) {
    let { resourceRequest, showNotification } = this.props
    let { selected } = this.state
    let { deviceApps: apps, id: deviceId } = selected

    if (apps && apps.length > 0) {
      let datum = []
      for (let i = 0; i < apps.length; i++) {
        let appId = apps[i].id
        datum.push({ appId, deviceId })
      }
      let params = {
        resource: 'devices',
        payload: {
          method: 'push_config',
          requestMethod: 'POST',
          data: datum
        }
      }
  
      let res = await invokeAction(resourceRequest, params)
      if (res) {
        showNotification('notification.sent_notification_success')
      } 
    } else {
      showNotification('notification.sent_notification_failure', 'warning')
    }
  }

  render() {
    let { translate } = this.props
    let buttonLabel = translate('resources.devices.push_config')
    return (
      <Button
        color="primary"
        onClick={(k) => this.handleClick(k)}
      >
        {buttonLabel}
      </Button>
    )
  }
}

const EnhanedPushButton = enhance(PushButton)

const DeviceBulkActions = (props) => {
  return (
    <Fragment>
      <EnhanedPushButton {...props} />
    </Fragment>
  )
}
////////////////////////////////////////////////////////////////////////////////
// Device
export const DeviceList = enhance(({ classes, push, showNotification, resourceRequest, ...props }) => (
  <List 
    bulkActionButtons={<DeviceBulkActions />}
    {...props}
    filters={<DeviceListFilter />}
  >
    <Datagrid>
      <TextField source="name" />
      <TextField source="serial" />
      <TextField source="deviceId" />
      <FunctionField
        sortable={false}
        source="apps"
        render={ record => {
          let { deviceApps: apps } = record
          return (apps && apps.length) ? apps.map((app, idx) => (
            <Chip
              key={idx}
              className={classes.chip}
              label={app.name}
              onClick={() => {
                push(`/apps/${app.id}`)
              }}
            />
          )) : ''
        }}
      />
      <ReferenceField allowEmpty
        source="operatorId"
        reference="companies"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
))                                                                                              

////////////////////////////////////////////////////////////////////////////////
// Create
export const DeviceCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit
export const DeviceEdit = props => {
  return <Edit
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Edit>
}
