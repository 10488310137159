import React, { Component } from 'react'
import CalendarTimeline from '../common/calendar-timeline'
import {
  Paper,
  Fade,
  Typography,
  withStyles,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
} from '@material-ui/core'
import MenuAction from '../common/MenuAction'
import {
  updateTripsDriver,
  assignDriverManyTrips,
  assignDriverToVehicle,
  tripsToTimelineState,
} from './tripFunctions'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getColor } from '../utils/color'
import { DropTarget } from 'react-dnd'
import { isArray, findIndex, reduce, difference } from 'lodash'
import { translate, showNotification, refreshView } from 'react-admin'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import ActionDialog from './ActionDialog'
import { MAIN_PADDING, PAPER_PADDING, viewByModes } from '../common/constants'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'

const styles = {
  timeline: {
    MozUserSelect: 'none',
    WebkitUserSelect: 'none',
    msUserSelect: 'none',
  },
  tooltip: {
    padding: '0.5em',
  },
  groupTitle: {
    cursor: 'pointer'
  }
}

const ItemTypes = {
  DRIVER: 'driver',
}

// Drag drop Item
const itemTarget = {

  canDrop(props, monitor) {
    if (monitor.isOver()) {
      return true
    }
    return false
  },

  hover(props, monitor) {
    const { id: draggedId, selectedDriverIds } = monitor.getItem()
    const { index: overIndex } = props
    props.unHoverDriversInVehicleCol()
    if (monitor.isOver()) {
      if (overIndex >= 0) {
        props.onHover(draggedId, overIndex, selectedDriverIds)

      }
    }
  },

  drop(props, monitor) {
    const { id: draggedId, selectedDriverIds } = monitor.getItem()
    const { index: overIndex } = props
    if (overIndex >= 0) {
      props.onDrop(draggedId, overIndex, selectedDriverIds)
      return { isSuccess: true }
    }
    return { isSuccess: false }
  },
}

class _Item extends Component {

  constructor(props) {
    super(props)
    this.state = {
      item: {},
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { item } = nextProps
    let currentItem = prevState.item
    if (item !== currentItem) {
      currentItem = item
    }
    return {
      item: currentItem,
    }
  }

  render() {
    let {
      connectDropTarget,
      getItemProps,
      itemContext,
      handleTooltip,
      openDayMenu,
      openActionDialog,
      companyManager,
      translate,
    } = this.props
    let { item } = this.state
    let opacity = 1
    itemContext.title = itemContext.title || 
      translate('resources.routes.total_trip', {
        count: item.count,
        smart_count: item.count
      })
    const { isHover, hoverBgColor, color } = item
    let background = isHover ? hoverBgColor :item.bgColor
    const borderColor = itemContext.selected
      ? itemContext.dragging
        ? 'black'
        : (item.selectedBgColor || 'black')
      : item.color
    const borderWidth = itemContext.selected ? 1 : 0

    const border = `1px solid ${item.bgColor}`
    return connectDropTarget(
      <div
        {...getItemProps({
          style: {
            background,
            color,
            border,
            borderColor,
            borderStyle: 'solid',
            borderWidth,
            borderRadius: 4,
            opacity,
          },
        })}
        onMouseEnter={handleTooltip(item, true)}
        onMouseLeave={handleTooltip(item, false)}
        onClick={(event) => {
          if(item.isDay) {
            if (!companyManager) return
            openDayMenu(event, item)
          }else {
            openActionDialog(item)
          }
        }}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center'
          }}
        >
          {itemContext.title}
        </div>
      </div>
    )
  }
}

export const ItemWithDragDrop = translate(DropTarget(
  ItemTypes.DRIVER,
  itemTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(_Item))

const containerTarget = {
  drop() {
    //
  },
}

const vehicleColTarget = {

  canDrop(props) {
    return props.index >= 0
  },

  hover(props, monitor) {
    const { id: draggedId, selectedDriverIds } = monitor.getItem()
    const { index: overIndex } = props
    if (monitor.isOver({ shallow: true })) {
      props.unHoverDriversInItem()
      if (overIndex >= 0) {
        props.onHover(draggedId, overIndex, selectedDriverIds)
      }
    }
  },

  drop(props, monitor) {
    const { id: draggedId, selectedDriverIds } = monitor.getItem()
    const { index: overIndex } = props
    if (overIndex >= 0) {
      props.onDrop(draggedId, overIndex, selectedDriverIds)
      return { isSuccess: true }
    }
    return { isSuccess: false }
  },
}

class _VehicleCol extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isSelected: false,
      isError: false,
      isHover: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isSelected, isError, style } = nextProps
    let currentIsSelected = prevState.isSelected
    if (isSelected !== currentIsSelected) {
      currentIsSelected = isSelected
    }
    let currentIsError = prevState.isError
    if (isError !== currentIsError) {
      currentIsError = isError
    }
    let currentStyle = prevState.style
    if (style !== currentStyle) {
      currentStyle = style
    }
    return {
      isSelected: currentIsSelected,
      isError: currentIsError,
      style: currentStyle
    }
  }

  render() {
    let {
      connectDropTarget,
      classes,
      id,
      vehiclePlate,
      clickTitle,
      index,
      translate,
    } = this.props
    let { style } = this.state
    return connectDropTarget(
      <div
        style={{ ...style }}
        className={classes.groupTitle}
        onClick={(event) => clickTitle(event, id)}
      >
        {vehiclePlate ? vehiclePlate : `${translate('resources.vehicles.name', { smart_count: 1 })} ${index + 1}`}
      </div>
    )
  }
}

export const VehicleColWithDragDrop = compose(
  DropTarget(
    ItemTypes.DRIVER,
    vehicleColTarget,
    (connect, monitor) => ({
      connectDropTarget: connect.dropTarget(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })), translate)(_VehicleCol)

class BaseViewTripByTimeline extends Component {

  constructor(props) {
    super(props)
    let { timeStart, timeEnd, trips, viewBy } = props
    timeStart = timeStart.toDate().getTime()
    timeEnd = timeEnd.toDate().getTime()
    this.state = {
      trips,
      displayTooltip: false,
      timeStart,
      timeEnd,
      hoverSelected: [],
      driverVehicles: {},
      vehicles: {},
      actionDialog: {
        open: false,
        record: {}
      },
      appBarWidth: 0,
      viewBy: viewBy || viewByModes.STATUS,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { driverVehicles, vehicles, appBarWidth } = nextProps
    let {
      driverVehicles: currentDriverVehicles,
      vehicles: currentVehicles,
      appBarWidth: currentAppBarWidth,
    } = prevState
    if (driverVehicles !== currentDriverVehicles) {
      currentDriverVehicles = driverVehicles
    }
    if (vehicles !== currentVehicles) {
      currentVehicles = vehicles
    }
    if (appBarWidth !== currentAppBarWidth) {
      currentAppBarWidth = appBarWidth
    }
    return {
      driverVehicles: currentDriverVehicles,
      vehicles: currentVehicles,
      appBarWidth: currentAppBarWidth,
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { viewBy: prevViewBy } = prevProps
    let { viewBy } = this.props
    const updated = !(prevViewBy === viewBy)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { viewBy } = this.props
      let { trips } = this.state
      this.setState({ viewBy })
      this.reloadTrips(trips, viewBy)
    }
  }

  clickTitle = (event, vehicleId) => {
    this.setState({
      groupTitleMenuShow: true,
      groupTitleAnchor: event.target,
      groupTitleVehicleId: vehicleId,
    })
  }

  unHoverDriversInVehicleCol = () => {
    this.hoverDriversInVehicleCol(-1, -1)
  }

  hoverDriversInVehicleCol = (draggerId, index) => {
    let { groups, hoverSelected } = this.state
    if (!isArray(draggerId)) {
      draggerId = [draggerId]
    }

    let fromId = index
    let toId = index + draggerId.length
    let newHoverSelected = draggerId.map((id, i) => index + i)
    if (JSON.stringify(newHoverSelected) === JSON.stringify(hoverSelected)) return

    let newGroups =  groups.map((group, i) => {
      let { vehicleId, rightTitle } = group
      let isHover = fromId <= i && i < toId
      return { ...group, title: this.groupTitle(vehicleId, rightTitle, i, isHover) }
    })
    this.setState({
      version: this.state.version + 1,
      hoverSelected: newHoverSelected,
      groups: newGroups
    })
  }

  onHoverVehicleCol = (driverIdDragger, index, selectedDriverIds) => {
    if (selectedDriverIds) {
      this.hoverDriversInVehicleCol(selectedDriverIds, index)
    } else {
      this.hoverDriversInVehicleCol(driverIdDragger, index)
    }
  }

  unHoverDriversInItem = () => {
    this.hoverDriversInItem(-1, -1)
  }

  hoverDriversInItem = (draggerId, overIdx) => {
    let { showDay, hoverItemSelected } = this.state
    let itemType = showDay ? 'dayItems' : 'items'
    let currentItems = [...this.state[itemType]]
    if (!isArray(draggerId)) {
      draggerId = [draggerId]
    }

    let newHoverItemSelected = draggerId.reduce((prev, id, i) => {
      prev[overIdx + i] = id
      return prev
    }, {})

    if (JSON.stringify(newHoverItemSelected) === JSON.stringify(hoverItemSelected)) return

    let unHoverItems = difference(Object.keys(hoverItemSelected || {}), Object.keys(newHoverItemSelected))
    unHoverItems.forEach(function(item) {
      if (item < 0) return
      currentItems[item] = {...currentItems[item], isHover: false, hoverBgColor: '' }
    })
    for (let newHoverIdx in newHoverItemSelected) {
      if (newHoverIdx >= 0) {
        let driverId = newHoverItemSelected[newHoverIdx]
        currentItems[newHoverIdx] = {
          ...currentItems[newHoverIdx],
          isHover: true,
          hoverBgColor: getColor(driverId),
        }
      }
    }
    this.setState({
      version: this.state.version + 1,
      hoverItemSelected: newHoverItemSelected,
      [itemType]: currentItems,
    })
  }

  onHoverItem = (driverIdDragger, overIdx, selectedVehicleIds) => {
    if (selectedVehicleIds) {
      this.hoverDriversInItem(selectedVehicleIds, overIdx)
    } else {
      this.hoverDriversInItem(driverIdDragger, overIdx)
    }
  }

  dropDriversInVehicleCol = async (draggerId, index) => {
    if (!isArray(draggerId)) {
      draggerId = [draggerId]
    }
    let { showNotification } = this.props
    let { groups } = this.state
    let group
    let groupTitleVehicleIds = []
    let vehicleDriverMapping = {}
    for (let i = 0; i < draggerId.length; i++) {
      group = groups[index + i]
      let groupTitleVehicleId = group ? group.vehicleId : 0
      vehicleDriverMapping[groupTitleVehicleId] = draggerId[i]
      groupTitleVehicleIds.push(groupTitleVehicleId)
    }
    let success = await this.assignDriver(vehicleDriverMapping, groupTitleVehicleIds)
    if (success) {
      showNotification('notification.assign_driver_success')
    } 
  }

  //setDriverVehicles = (vehicleDriverMapping, vehicleIds, oldDrivers) => {
  //let { driverVehicles } = this.state
  //let { updateDrawer } = this.props
  //for (let i = 0; i < vehicleIds.length; i++) {
  //let vehicleId = vehicleIds[i]
  //let driverId = vehicleDriverMapping[vehicleId]
  //if (!oldDrivers) {
  //oldDrivers = {}
  //for (let id in driverVehicles) {
  //let vs = driverVehicles[id]
  //let index = findIndex(vs, { 'id': vehicleId })
  //let vehicle = vs[index]
  //if (vehicle) {
  //oldDrivers[id] = vehicle.count
  //}
  //}
  //}
  //// Lấy số lượng chuyến đi của những tài xế bị hủy phân công trong 1 khoảng thời gian (ngày, tuần....)
  //let otherNumberOfTrips = reduce(Object.keys(oldDrivers),
  //(sum = 0, oldDriverId) => {
  //if (driverId !== oldDriverId) {
  //sum += oldDrivers[oldDriverId]
  //}
  //return sum
  //},
  //0
  //)
  //// Nếu otherNumberOfTrips = 0  thì tài xế đã lái tất cả những chuyến tại thời gian trên
  //if (otherNumberOfTrips === 0) {
  //return
  //}
  //// cập những xe và số lượng chuyến của tài xế
  //let vehicles = driverVehicles[driverId]
  //// kiểm tra tài xế đã được phân công lái xe trước đó chưa?
  //if (vehicles) {
  //// Kiểm tra tài xe đã lái xe có id là giá trị "vehicleId" chưa?
  //let idx = findIndex(vehicles, { 'id': vehicleId })
  //if (idx === -1) {
  //vehicles.push({ id: vehicleId, count: otherNumberOfTrips })
  //} else {
  //let vehicle = vehicles[idx]
  //vehicles[idx] = { ...vehicle, count: vehicle.count + otherNumberOfTrips }
  //}
  //} else {
  //vehicles = [{ id: vehicleId, count: otherNumberOfTrips }]
  //}
  //// cập nhật lại số chuyến đi của những tài xế bị hủy phân công
  //for (let prop in oldDrivers) {
  //if (prop !== driverId) {
  //let odvs = driverVehicles[prop]
  //let idx = findIndex(odvs, { 'id': vehicleId })
  //if (idx !== -1) {
  //let vs = odvs[idx]
  //odvs[idx] = { ...vs, count: vs.count - oldDrivers[prop] }
  //driverVehicles = { ...driverVehicles, [prop]: odvs }
  //}
  //}
  //}
  //driverVehicles = { ...driverVehicles, [driverId]: vehicles }
  //this.setState({
  //driverVehicles
  //}, () => updateDrawer())
  //}
  //}

  getDriverVehicles = () => {
    return this.state.driverVehicles
  }

  onDropInVehicleCol = (driverIdDragger, index, selectedDriverIds) => {
    if (selectedDriverIds) {
      this.dropDriversInVehicleCol(selectedDriverIds, index)
    } else {
      this.dropDriversInVehicleCol(driverIdDragger, index)
    }
  }

  dropDriversInItem = async (draggerId, index) => {
    if (!isArray(draggerId)) {
      draggerId = [draggerId]
    }
    let dayItemDriverMapping = {}
    let dayItemIdxs = []
    for (let i = 0; i < draggerId.length; i++) {
      index = index + i
      dayItemIdxs.push(index)
      dayItemDriverMapping[index] = draggerId[i]
    }
    await this.assignDriverInItem(dayItemDriverMapping, dayItemIdxs)
  }

  onDropInItem = (driverIdDragger, index, selectedDriverIds) => {
    if (selectedDriverIds) {
      this.dropDriversInItem(selectedDriverIds, index)
    } else {
      this.dropDriversInItem(driverIdDragger, index)
    }
  }

  groupTitle = (vehicleId, vehiclePlate, index, isHover) => {
    const { classes } = this.props
    let backgroundColor = isHover && 'rgba(0, 0, 0, 0.14)'
    return <VehicleColWithDragDrop
      classes={classes}
      id={vehicleId}
      vehiclePlate={vehiclePlate}
      onDrop={this.onDropInVehicleCol}
      clickTitle={this.clickTitle}
      onHover={this.onHoverVehicleCol}
      index={index}
      style={{ backgroundColor }}
      unHoverDriversInItem={this.unHoverDriversInItem}
      unHoverDriversInVehicleCol={this.unHoverDriversInVehicleCol}
    />
  }

  assignDriver = async (vehicleDriverMapping, groupTitleVehicleIds) => {
    let { showNotification, viewBy, historicalAssignEnabled, unassignDriverEnabled } = this.props
    let { trips } = this.state
    trips = assignDriverToVehicle(trips, groupTitleVehicleIds, vehicleDriverMapping)
    this.reloadTrips(trips, viewBy)
    this.setState({ groupTitleMenuShow: false })
    //this.setDriverVehicles(vehicleDriverMapping, groupTitleVehicleIds)
    let result = await updateTripsDriver(trips, showNotification)
    if (result) {
      historicalAssignEnabled(trips)
      unassignDriverEnabled(trips)
    }
    return result
  }

  assignDriverInItem = async (dayItemDriverMapping, dayItemIdxs, driverIdx) => {
    let { showNotification, viewBy, historicalAssignEnabled, unassignDriverEnabled } = this.props
    let { showDay, actionDialog, trips } = this.state
    let itemType = showDay ? 'dayItems' : 'items'
    let items = this.state[itemType]
    let dayItems = reduce(dayItemIdxs, (result, ele) => {
      if (items[ele]) {
        result.push(items[ele])
      }
      return result
    }, [])
    let success = false
    let assignTrips = []
    let tripDriverMapping = {}
    if (showDay) {
      for (let i = 0; i < dayItems.length; i++) {
        let dayItem = dayItems[i]
        let { index, tripIds } = dayItem
        if (tripIds) {
          let driverId = dayItemDriverMapping[index]
          assignTrips = [...assignTrips, ...tripIds]
          for (let j = 0; j < tripIds.length; j++) {
            let tripId = tripIds[j]
            tripDriverMapping[tripId] = driverId
          }
        }
      }
      if (!tripDriverMapping) return
      trips = assignDriverManyTrips(trips, assignTrips, tripDriverMapping)
      success = await updateTripsDriver(trips, showNotification)
    } else {
    //let vId = findVehicleIdFromGroupId(groups, dayItem.group)
      for (let i = 0; i < dayItems.length; i++) {
        let { tripId, index } = dayItems[i]
        let driverId = dayItemDriverMapping[index]
        tripDriverMapping[tripId] = driverId
        assignTrips.push(tripId)
      }
      trips = assignDriverManyTrips(trips, assignTrips, tripDriverMapping, driverIdx)
      success = await updateTripsDriver(trips, showNotification)
      if (success) {
        actionDialog = {...actionDialog, record: trips[dayItems[0].index] }
        this.setState({ dayMenuShow: false, actionDialog })
      }
    }
    if (success) {
      historicalAssignEnabled(trips)
      unassignDriverEnabled(trips)
      this.reloadTrips(trips, viewBy)
      showNotification('notification.assign_driver_success')
    }
  }

  groupTitleMenu = () => {
    let { groupTitleMenuShow, groupTitleAnchor, groupTitleVehicleId } = this.state
    let { drivers = {}, translate } = this.props
    if (!groupTitleMenuShow) return null
    return (
      <Menu
        anchorEl={groupTitleAnchor}
        open={groupTitleMenuShow}
        placement="right-start"
        onClose={() => this.setState({ groupTitleMenuShow: false })}
      >
        <MenuItem disabled={true}>{translate('button.assign_driver')}</MenuItem>
        {Object.values(drivers).map((driver, index) => (
          <MenuItem
            key={index}
            onClick={ async () => {
              let { showNotification } = this.props
              let success = await this.assignDriver({ [groupTitleVehicleId]: driver.id }, [ groupTitleVehicleId ])
              if (success) {
                showNotification('notification.assign_driver_success')
              }
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                color={getColor(driver.id)}
              />
            </ListItemIcon>
            <ListItemText>{driver.fullName}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    )
  }

  openDayMenu = (event, item) => {
    this.setState({
      dayMenuShow: true,
      dayAnchor: event.target,
      dayItem: item,
    })
  }

  assignAssistantDriver = async (assistantDriver, tripId) => {
    let { showNotification, viewBy } = this.props
    let { trips, actionDialog } = this.state
    let { id: assistantDriverId } = assistantDriver
    let updateTrip = { id: tripId, assistantDriverId }
    await Provider.dataProvider('REMOTE', 'trips', {
      method: 'assignAssistantDriver',
      requestMethod: 'PUT',
      data: { trips: [updateTrip] },
    }).then(()=> {
      let tripUpdatedIdx = findIndex(trips, trip => trip.id === tripId )
      let tripUpdated = trips[tripUpdatedIdx]
      tripUpdated = { ...tripUpdated, assistantDriverId }
      trips[tripUpdatedIdx] = tripUpdated
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
        actionDialog: {
          ...actionDialog,
          record: tripUpdated
        }
      }, () => {
        this.reloadTrips(trips, viewBy)
      })
      showNotification('notification.assign_assistant_driver_success')
    }).catch(()=> {
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
      })
      showNotification('notification.assign_assistant_driver_failure', 'warning')
    })
  }

  unAssignAssistantDriver = async (tripId) => {
    let { showNotification, viewBy } = this.props
    let { assistantDrivers, trips, actionDialog } = this.state
    await Provider.dataProvider('REMOTE', 'trips', {
      method: 'unAssignAssistantDriver',
      requestMethod: 'PUT',
      data: { tripIds: [tripId] },
    }).then(() => {
      let tripUpdatedIdx = findIndex(trips, trip => trip.id === tripId )
      let tripUpdated = trips[tripUpdatedIdx]
      tripUpdated = { ...tripUpdated, assistantDriverId: undefined }
      trips[tripUpdatedIdx] = tripUpdated
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
        assistantDrivers,
        actionDialog: {
          ...actionDialog,
          record: tripUpdated
        }
      }, () => {
        this.reloadTrips(trips, viewBy)
      })
      showNotification('notification.unassign_assistant_driver_success')
    }).catch(() => {
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
      })
      showNotification('notification.unassign_assistant_driver_failure', 'warning')
    })
  }

  assignVehicle = async (vehicle, tripId) => {
    let { showNotification, viewBy } = this.props
    let { vehicles, trips, actionDialog } = this.state
    let { id: vehicleId } = vehicle
    let updateTrip = { id: tripId, vehicleId }
    await Provider.dataProvider('REMOTE', 'trips', {
      method: 'assignVehicleInTimeline',
      requestMethod: 'PUT',
      data: updateTrip,
    }).then(()=> {
      vehicles[vehicleId] = vehicle
      let tripUpdatedIdx = findIndex(trips, trip => trip.id === tripId )
      let tripUpdated = trips[tripUpdatedIdx]
      let status = vehicleId ? '10ACT' : '00IAT'
      tripUpdated = { ...tripUpdated, vehicleId, status }
      trips[tripUpdatedIdx] = tripUpdated
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
        vehicles,
        actionDialog: {
          ...actionDialog,
          record: tripUpdated
        }
      }, () => {
        this.reloadTrips(trips, viewBy)
      })
      showNotification('notification.assigned_vehicle_success')
    }).catch(()=> {
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
      })
      showNotification('notification.assigned_vehicle_failure', 'warning')
    })
  }

  unAssignVehicle = async (tripId) => {
    let { showNotification, viewBy } = this.props
    let { vehicles, trips, actionDialog } = this.state
    await Provider.dataProvider('REMOTE', 'trips', {
      method: 'unAssignVehicleInTimeline',
      requestMethod: 'PUT',
      data: { trip: tripId },
    }).then(() => {
      let tripUpdatedIdx = findIndex(trips, trip => trip.id === tripId )
      let tripUpdated = trips[tripUpdatedIdx]
      let status = '00IAT'
      tripUpdated = { ...tripUpdated, vehicleId: undefined, status }
      trips[tripUpdatedIdx] = tripUpdated
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
        vehicles,
        actionDialog: {
          ...actionDialog,
          record: tripUpdated
        }
      }, () => {
        this.reloadTrips(trips, viewBy)
      })
      showNotification('notification.unassigned_vehicle_success')
    }).catch(() => {
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
      })
      showNotification('notification.unassigned_vehicle_failure', 'warning')
    })
  }

  unAssignDriver = async (tripId, driverIdx) => {
    let { showNotification, viewBy, historicalAssignEnabled, unassignDriverEnabled } = this.props
    let { trips, actionDialog } = this.state
    let updateTrip = { id: tripId }
    if (driverIdx === 1) {
      updateTrip = { ...updateTrip, driverId: null }
    }
    if (driverIdx === 2) {
      updateTrip = { ...updateTrip, driver2Id: null }
    }
    await Provider.dataProvider('REMOTE', 'trips', {
      method: 'unAssignDriver',
      requestMethod: 'PUT',
      data: updateTrip,
    }).then(() => {
      let tripUpdatedIdx = findIndex(trips, trip => trip.id === tripId )
      let tripUpdated = trips[tripUpdatedIdx]
      if (driverIdx === 1) {
        tripUpdated = { ...tripUpdated, driverId: null }
      }
      if (driverIdx === 2) {
        tripUpdated = { ...tripUpdated, driver2Id: null }
      }
      trips[tripUpdatedIdx] = tripUpdated
      this.setState({
        actionDialog: {
          ...actionDialog,
          record: tripUpdated
        }
      }, () => {
        historicalAssignEnabled(trips)
        unassignDriverEnabled(trips)
        this.reloadTrips(trips, viewBy)
      })
      showNotification('notification.unassigned_driver_success')
    }).catch(() => {
      this.setState({
        dayMenuShow: false,
        dayAnchor: null,
        dayItem: {},
      })
      showNotification('notification.unassigned_driver_failure', 'warning')
    })
  }

  dayMenu = () => {
    let { dayMenuShow, dayAnchor, dayItem } = this.state
    let { drivers = {}, translate, allVehicles={} } = this.props
    if (!dayMenuShow) return null
    let { hasVehicle, tripId } = dayItem
    return hasVehicle ? (
      <Menu
        anchorEl={dayAnchor}
        open={dayMenuShow}
        placement="right-start"
        onClose={() => this.setState({ dayMenuShow: false })}
      >
        <MenuItem disabled={true}>{translate('button.assign_driver')}</MenuItem>
        {Object.values(drivers).map((driver, index) => (
          <MenuItem
            key={index}
            onClick={ async () => {
              await this.assignDriverInItem({ [dayItem.index]: driver.id }, [dayItem.index])
              this.setState({ dayMenuShow: false })
            }}
          >
            <ListItemIcon>
              <FontAwesomeIcon
                icon={faUserCircle}
                size="2x"
                color={getColor(driver.id)}
              />
            </ListItemIcon>
            <ListItemText>{driver.fullName}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    ) : (
      <Menu
        anchorEl={dayAnchor}
        open={dayMenuShow}
        placement="right-start"
        onClose={() => this.setState({ dayMenuShow: false })}
      >
        <MenuItem disabled={true}>{translate('button.assign_vehicle')}</MenuItem>
        {Object.values(allVehicles).map((vehicle, index) => (
          <MenuItem
            key={index}
            onClick={() => this.assignVehicle(vehicle, tripId)}
          >
            <ListItemText>{vehicle.plate}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    )
  }

  reloadTrips = (trips, viewBy) => {
    let { drivers, assistantDrivers, translate } = this.props
    let { version = 0, vehicles } = this.state
    version++
    let newState = tripsToTimelineState({
      trips,
      vehicles,
      drivers,
      assistantDrivers,
      groupTitleFunc: this.groupTitle,
      version,
      translate,
      viewBy
    })
    if (newState) {
      this.setState({ ...newState, trips, version })
    }
  }

  componentDidMount() {
    let { timeStart, timeEnd, trips, viewBy } = this.state
    this.handleZoom({
      visibleTimeStart: timeStart,
      visibleTimeEnd: timeEnd,
    })
    this.reloadTrips(trips, viewBy)
  }

  handleTooltip = (hoverItem, open) => event => {
    if (!hoverItem.tooltip) return
    const { currentTarget } = event
    this.setState({
      anchorEl: currentTarget,
      displayTooltip: open,
      hoverItem,
    })
  };

  itemRenderer = ({
    item,
    itemContext,
    getItemProps,
  },
  companyManager,
  ) => {
    return (
      <ItemWithDragDrop
        id={item.id}
        index={item.index}
        item={{...item}}
        itemContext={itemContext}
        getItemProps={getItemProps}
        handleTooltip={this.handleTooltip}
        openDayMenu={this.openDayMenu}
        onHover={this.onHoverItem}
        onDrop={this.onDropInItem}
        unHoverDriversInItem={this.unHoverDriversInItem}
        unHoverDriversInVehicleCol={this.unHoverDriversInVehicleCol}
        openActionDialog={this.openActionDialog}
        companyManager={companyManager}
      />
    )
  }

  openActionDialog = (dayItem) => {
    let { trip } = dayItem
    this.setState({
      actionDialog: {
        open: true,
        record: trip,
        dayItem: dayItem,
      }
    })
  }

  closeActionDialog = () => {
    this.setState({
      actionDialog: {
        open: false,
        record: {},
      }
    })
  }

  handleZoom = ({ visibleTimeStart, visibleTimeEnd }) => {
    let days = (visibleTimeEnd - visibleTimeStart) / (1000 * 24 * 60 * 60)
    this.setState({
      groupTitleMenuShow: false,
      displayTooltip: false,
      showDay: days > 3,
      timeStart: visibleTimeStart,
      timeEnd: visibleTimeEnd,
    })
  }

  handleTimeChange = (visibleTimeStart, visibleTimeEnd) => {
    this.handleZoom({ visibleTimeStart, visibleTimeEnd })
  }

  render() {
    let {
      showDay,
      timeStart,
      timeEnd,
      anchorEl,
      displayTooltip,
      hoverItem = {},
      actionDialog,
      appBarWidth,
    } = this.state
    let {
      classes,
      connectDropTarget,
      translate,
      drivers,
      allVehicles,
      companyManager,
      assistantDrivers,
    } = this.props
    let { open: openActionDialog, record: trip, dayItem } = actionDialog
    let timelineWidth = appBarWidth - MAIN_PADDING - PAPER_PADDING
    const {
      groups = [],
      items = [],
      dayItems = [],
    } = this.state
    if (groups.length === 0) return null
    let currentItems = showDay ? dayItems : items
    const vehicleLabel = translate('resources.vehicles.name', { smart_count: 2 })
    return connectDropTarget(
      <div className={classes.timeline}>
        <CalendarTimeline
          resizeDetector={containerResizeDetector}
          groups={groups}
          items={currentItems}
          style={{
            width: timelineWidth,
          }}
          sidebarContent={
            <p
              style={{
                textAlign: 'center',
                margin: '5px 0'
              }}
            >
              { vehicleLabel }
            </p>
          }
          canMove={false}
          itemRenderer={(item) => this.itemRenderer(item, companyManager)}
          visibleTimeStart={timeStart}
          visibleTimeEnd={timeEnd}
          onZoom={this.handdleZoom}
          onTimeChange={this.handleTimeChange}
        />
        <MenuAction
          anchorEl={anchorEl}
          open={displayTooltip}
          placement="right-start"
          component={
            <Fade timeout={350}>
              <Paper className={classes.tooltip}>
                <Typography color="textSecondary">{hoverItem.tooltip}</Typography>
              </Paper>
            </Fade>
          }
        />
        <ActionDialog
          open={openActionDialog}
          record={trip}
          onClose={this.closeActionDialog}
          drivers={drivers}
          assistantDrivers={assistantDrivers}
          assignDriver={this.assignDriverInItem}
          assignVehicle={this.assignVehicle}
          unAssignVehicle={this.unAssignVehicle}
          unAssignDriver={this.unAssignDriver}
          assignAssistantDriver={this.assignAssistantDriver}
          unAssignAssistantDriver={this.unAssignAssistantDriver}
          dayItem={dayItem}
          vehicles={allVehicles}
          companyManager={companyManager}
        />
        {this.groupTitleMenu()}
        {this.dayMenu()}
      </div>
    )
  }
}

const ForwardRefViewTripByTimeline = ({ forwardRef, ...props }) => (
  <BaseViewTripByTimeline {...props} ref={forwardRef} />
)

const mapStateToProps = (state) => {
  let appBar = state['appBar'] || {}
  let appBarWidth = appBar.width
  return { appBarWidth }
}

const enhance = compose(
  DropTarget(
    ItemTypes.DRIVER,
    containerTarget,
    connect => ({
      connectDropTarget: connect.dropTarget()
    })
  ),
  withStyles(styles),
  translate,
  connect(mapStateToProps, { showNotification, refreshView })
)

export const ViewTripByTimeline = enhance(ForwardRefViewTripByTimeline)
