import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button
} from '@material-ui/core'
import React, { Component } from 'react'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { toggleLayerState, addMapLayer, getSquareGrid } from './actions'
import { connect } from 'react-redux'
import { MapProps } from './../common/constants'
import AddIcon from '@material-ui/icons/Add'
import { styles } from './frame-styles'

class LayersControlFrame extends Component {

  render() {
    let {
      classes,
      layers, bbox,
      addMapLayer, toggleLayerState, getSquareGrid } = this.props

    return (<div key="Frame0" style={{
      width: 300,
      height: '100%',
      background: 'white',
      padding: 10
    }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Layers</FormLabel>
        <FormGroup>
          {
            layers && Object.values(layers).map(layer => {
              return (
                <FormControlLabel
                  key={layer.identifier}
                  control={
                    <Checkbox checked={layer.visibility === MapProps.LayerStates.VISIBLE}
                      onChange={() => {
                        toggleLayerState({
                          identifier: layer.identifier,
                          visibility: layer.visibility === MapProps.LayerStates.VISIBLE ? MapProps.LayerStates.INVISIBLE : MapProps.LayerStates.VISIBLE
                        })
                      }} value={layer.identifier} />
                  }
                  label={layer.name}
                />
              )
            })
          }
        </FormGroup>
      </FormControl>

      <Button variant="fab" mini
        color="secondary"
        aria-label="Add"
        className={classes.fab}
        onClick={() => {
          getSquareGrid({
            query: {
              bound: bbox,
              cellSide: 0.25,
              unit: 'kilometers'
            },
            start: () => { 
              // console.log('start get square grid')
            },
            done: (data) => {
              addMapLayer({
                geoJson: data.grid,
                actions: {
                  mouseover: (feature, layer) => {
                    let map = layer._map

                    let bbox = layer.getBounds()
                    let sw = bbox.getSouthWest()
                    let ne = bbox.getNorthEast()
                    let nw = bbox.getNorthWest()
                    let se = bbox.getSouthEast()

                    layer.bindPopup(
                      '<b>SouthWest:</b> <br/>' + sw + ' - ' + map.latLngToLayerPoint(sw)
                      + '<br/><b>NorthEast:</b> <br/>' + ne + ' - ' + map.latLngToLayerPoint(ne)
                      + '<br/><b>NorthWest:</b> <br/>' + nw + ' - ' + map.latLngToLayerPoint(nw)
                      + '\n<b>SouthEast:</b> <br/>' + se + ' - ' + map.latLngToLayerPoint(se)
                    )
                  }
                }
              })
            }
          })
        }}>
        <AddIcon />
      </Button>
    </div >)
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { layers, bbox, zoom, center } = state.map
      return { layers, bbox, zoom, center }
    },
    {
      toggleLayerState,
      addMapLayer,
      getSquareGrid
    })
)

export default enhance(LayersControlFrame)
