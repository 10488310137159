import React, { Component } from 'react'
import { Avatar, withStyles } from '@material-ui/core'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import { DriverIcon, AssistantDriverIcon } from '../icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { upsertGroupAvatar as upsertGroupAvatarAction } from './action'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

const styles = {
  avatarGroup: {
    display: 'flex',
  },
  avatar: {
    margin: 2,
    width: 18,
    height: 18,
  },
  iconSvg: {
    fontSize: '0.85em',
  }
}

class GroupAvatar extends Component {
  
  state = {}

  componentDidMount() {
    let { 
      tripId,
      vehicleId,
      driverId,
      driver2Id,
      assistantDriverId,
    } = this.props
    this.setState({ 
      tripId,
      vehicleId,
      driverId,
      driver2Id,
      assistantDriverId,
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { vehicleId, driverId, driver2Id, assistantDriverId } = nextProps
    let { 
      vehicleId: currentVehicleId,
      driverId: currentDriverId,
      driver2Id: currentDriver2Id,
      assistantDriverId: currentAssistantDriverId,
    } = prevState
    if (vehicleId !== currentVehicleId) {
      currentVehicleId = vehicleId
    }
    if (driverId !== currentDriverId) {
      currentDriverId = driverId
    }
    if (driver2Id !== currentDriver2Id) {
      currentDriver2Id = driver2Id
    }
    if (assistantDriverId !== currentAssistantDriverId) {
      currentAssistantDriverId = assistantDriverId
    }
    return {
      ...prevState,
      vehicleId: currentVehicleId,
      driverId: currentDriverId,
      driver2Id: currentDriver2Id,
      assistantDriverId: currentAssistantDriverId,
    }
  }

  render() {
    let { classes } = this.props
    let { vehicleId, driverId, driver2Id, assistantDriverId } = this.state
    return <div className={classes.avatarGroup}>
      <Avatar
        style={vehicleId ? { backgroundColor: '#43a047' } : {}}
        className={classes.avatar}
      >
        <FontAwesomeIcon icon={faBus} size="xs" />
      </Avatar>
      <Avatar
        style={driverId ? { backgroundColor: '#43a047' } : {}}
        className={classes.avatar}
      >
        <DriverIcon className={classes.iconSvg} />
      </Avatar>
      <Avatar
        style={driver2Id ? { backgroundColor: '#43a047' } : {}}
        className={classes.avatar}
      >
        <DriverIcon className={classes.iconSvg} />
      </Avatar>
      <Avatar
        style={assistantDriverId ? { backgroundColor: '#43a047' } : {}}
        className={classes.avatar}
      >
        <AssistantDriverIcon className={classes.iconSvg} />
      </Avatar>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  let { tripId } = props
  let viewTripState = state['view-trip']
  let newRecord = viewTripState[tripId]
  if (newRecord) {
    let { vehicleId, driverId, driver2Id, assistantDriverId } = newRecord
    return { vehicleId, driverId, driver2Id, assistantDriverId }
  }
}

const enhance = compose(withStyles(styles), connect(mapStateToProps, { upsertGroupAvatarAction }))
export default enhance(GroupAvatar)
