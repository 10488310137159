import {
  withStyles,
  Grid,
  Button,
  Icon,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  SimpleForm,
  translate,
} from 'react-admin'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
  RESERVATION_TRIP_TYPE,
  RESERVATION_CHANGE_ENDPOINT,
  RESERVATION_SEARCH_TRIP,
  RESERVATION_GET_TRIP_DETAIL,
  doFetchOneAction,
  doFetchAction,
  doAction
} from './actions'
import moment from 'moment'
import SelectSeat from './SelectSeat'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { DatePickerInput } from '../common/DatePicker'
import FormBody from '../common/FormBody'

const styles = (theme) => {
  return ({
    rightIcon: {
      marginLeft: theme.spacing.unit,
    }
  })
}

class SelectTripStep extends Component {

  constructor(props) {
    super(props)
    this.state = { trips: {} }
  }

  onSearchTripDone = (type, response) => {
    let resData = response.data
    if (resData.resultCode === 'SUCCESS') {
      let { trips } = this.state
      let { data } = resData
      trips[type] = data
      this.setState({ ...trips })
    }
  }

  searchTrip = (roundType) => {
    let { search, doFetchAction } = this.props
    let { vehiclePlate, originId, destinationId, departureTime } = search[roundType]

    let req = {
      filter: {
        where: {
          departureTime: { gte: departureTime }
        },
        order: 'departureTime ASC',
        limit: 50
      }
    }

    if (vehiclePlate) {
      req.filter.where.vehiclePlate = { like: `%${vehiclePlate}%` }
    }

    if (originId) {
      req.filter.where.originId = originId
    }

    if (destinationId) {
      req.filter.where.destinationId = destinationId
    }

    doFetchAction(
      RESERVATION_SEARCH_TRIP,
      'search',
      'GET',
      req,
      'viewtrips',
      null,
      null,
      (data) => this.onSearchTripDone(roundType, data)
    )
  }

  selectTrip = (roundType, viewTripId) => {
    if (viewTripId) {
      let { doFetchOneAction } = this.props
      doFetchOneAction(RESERVATION_GET_TRIP_DETAIL, 'viewtrips', viewTripId, null, { roundType, viewTripId })
    }
  }

  onEndpointChange = (roundType, changeAttr) => {
    let { search, doAction } = this.props
    let payload = search[roundType]
    payload = { ...payload, ...changeAttr, roundType }
    doAction(RESERVATION_CHANGE_ENDPOINT, null, payload)
  }

  onChangeTripType = (value) => {
    let { doAction } = this.props
    doAction(
      RESERVATION_TRIP_TYPE,
      null,
      { isRoundTrip: value }
    )
  }

  renderSearchTrip = (roundType) => {
    let { selectedTrips, classes, translate } = this.props
    let { trips } = this.state
    let searchResult = trips && trips[roundType]
    return (
      <>
        <Grid container spacing={8}
          alignItems="center">

          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {translate(roundType === 'INBOUND' ? 'resources.trips.fields.inbound' : 'resources.trips.fields.outbound')}
          </Grid>

          <Grid item xs={1}></Grid>
          <Grid item xs={2}>
            <TextInput
              label={translate('resources.reservations.vehiclePlate')}
              source="vehiclePlate"
              onChange={(e, vehiclePlate) => {
                this.onEndpointChange(roundType, { vehiclePlate })
              }}
              fullWidth
            />
          </Grid>

          {roundType === 'OUTBOUND' &&
            <>
              <Grid item xs={2}>
                <ReferenceInput
                  source="origin"
                  reference="zones"
                  resource="reservations"
                  filter={{ '../fields': ['id', 'name'] }}
                  perPage={1000}
                  onChange={(e, id) => {
                    this.setState({ originId: id })
                    this.onEndpointChange(roundType, { originId: id })
                    this.onEndpointChange('INBOUND', { destinationId: id })
                  }}
                  fullWidth
                >
                  <FuzzySelectInput
                    renderItem={item => item.name}
                    {...defaultFuzzySearch()}
                  />
                </ReferenceInput>
              </Grid>

              <Grid item xs={2}>
                <ReferenceInput
                  source="destination"
                  reference="zones"
                  resource="reservations"
                  filter={{ '../fields': ['id', 'name'] }}
                  perPage={1000}
                  onChange={(e, id) => {
                    this.setState({ destinationId: id })
                    this.onEndpointChange(roundType, { destinationId: id })
                    this.onEndpointChange('INBOUND', { originId: id })
                  }}
                  fullWidth
                >
                  <FuzzySelectInput
                    renderItem={item => item.name}
                    {...defaultFuzzySearch()}
                  />
                </ReferenceInput>
              </Grid>
            </>
          }
          <Grid item xs={2}>

            <DatePickerInput
              label={translate('resources.reservations.departureTime')}
              source="departureTime"
              dateFormat="DD/MM/YYYY"
              showLunarDate={true}
              keyboard={true}
              onChange={(e) => {
                this.setState({ departureTime: moment(e).toISOString() })
                this.onEndpointChange(roundType, { departureTime: moment(e).toISOString() })
              }}
              fullWidth
            />
          </Grid>

          <Grid item xs={2}>
            <Button variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => this.searchTrip(roundType)}
            >
              {translate('button.search')}
              <Icon className={classes.rightIcon}>search</Icon>
            </Button>
          </Grid>
        </Grid>

        {searchResult && searchResult.length > 0 &&
          <Grid container spacing={8}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <SelectInput
                label={translate('resources.reservations.trips')}
                source={`${roundType}-id`}
                choices={searchResult}
                optionText={(choice) => {
                  let { vehiclePlate, departureTime, arrivalTime } = choice
                  let showFormat = 'HH:mm'
                  return `${vehiclePlate} - ${moment(departureTime).format(showFormat)} - ${moment(arrivalTime).format(showFormat)}`
                }}
                optionValue='id'
                helperText={`${translate('resources.reservations.totalFoundTrips')}: ${searchResult.length}`}
                onChange={(e, id) => this.selectTrip(roundType, id)}
                fullWidth
              />
            </Grid>

            {/* Select seat with selected trip */}
            <Grid item xs={9}>
              {selectedTrips[roundType] && <SelectSeat roundType={roundType} />}
            </Grid>
          </Grid>
        }
      </>
    )
  }

  render() {
    let { isRoundTrip, translate } = this.props
    return (
      <SimpleForm
        form="select-trip-form"
        toolbar={null}
      >
        <FormBody>
          {/* is rounded trip */}
          <Grid container spacing={8}>
            <Grid item xs={1}></Grid>
            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={isRoundTrip}
                    onChange={(k, v) => this.onChangeTripType(v)}
                    value="isRoundTrip"
                  />
                }
                label={translate('resources.trips.fields.round')}
              />
            </Grid>
          </Grid>

          {this.renderSearchTrip('OUTBOUND')}
          {isRoundTrip && this.renderSearchTrip('INBOUND')}
        </FormBody>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect((state) => {
    let { reservation } = state
    let { trips, search, isRoundTrip } = reservation
    return { selectedTrips: trips, search, isRoundTrip }
  }, {
    doFetchOneAction,
    doFetchAction,
    doAction
  }
  )
)

export default enhance(SelectTripStep)
