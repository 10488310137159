import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  Dialog,
  Grid,
  DialogTitle,
  Typography,
  DialogContent,
} from '@material-ui/core'
import { Provider } from '../provider'
import {
  translate,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  refreshView,
  showNotification,
  TextInput,
} from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import { MoneyInput } from '../common/MoneyInput'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'
import { formatCurrency } from '../utils/formatUtil'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  }
}

const AccountMoreInfo = translate(({ deposit, translate }) => {
  return deposit ? <i>
    {translate('resources.financeaccounts.helperTextAccountId')}&nbsp;
    <b>{formatCurrency(deposit)}</b>
  </i> : <i>
    {translate('resources.financeaccounts.not_topUp_yet')}
  </i>
})

const mappingType = {
  AGE:{
    title: 'resources.financeaccounts.depositAgencyTitle',
    fromRef: 'agencies',
    fromRefSource: 'agencyId',
    fromRefFilter: { 
      '../ignore': { agencyIsCompany: true },
      '../account': true,
    },
    fromAccountSource: 'agencyAccountId',
    fromRefType: 'AGE',
    toRef: 'companies',
    toRefSource: 'companyId',
    toAccountSource: 'companyAccountId',
    toRefType: 'COM',
    toRefExtraFilter: {},
  },
  COM: {
    title: 'resources.financeaccounts.depositCompanyTitle',
    fromRef: 'companies',
    fromRefSource: 'companyId',
    fromRefFilter: { 
      '../account': true,
    },
    fromAccountSource: 'companyAccountId',
    fromRefType: 'COM',
    toRef: 'agencies',
    toRefSource: 'agencyId',
    toAccountSource: 'agencyAccountId',
    toRefType: 'AGE',
    toRefExtraFilter: {},
  }
}

class _TopUpForm extends Component {

  state = {
    moneyCanDeposit: 0,
  }

  getMoneyCanDeposit = async (companyAccountId) => {
    let { moneyCanDeposit } = this.state
    if (!companyAccountId) return
    let moneyCanDepositRes = await Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: 'getMoneyCanDeposit',
      requestMethod: 'GET',
      data: { companyAccountId }
    })
    if (moneyCanDepositRes && moneyCanDeposit !== moneyCanDepositRes.data) {
      this.setState({
        moneyCanDeposit: moneyCanDepositRes.data,
        companyAccountId,
      })
    }
  }

  getAccountDefault = async (refId, type, toAccountSource, toRefSource) => {
    let toAccountSourceValue = this.state[toAccountSource]
    let { deposit } = this.state
    let { changeForm } = this.props
    let getDefaultAccountRes = await Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: 'getAccountIdByTypeAndRefId',
      requestMethod: 'GET',
      data: { filter: { refId, type } }
    })
    let data = getDefaultAccountRes ? getDefaultAccountRes.data : {}
    if (!data) return
    let { id: accountDefaultId, deposit: newDeposit } = data
    if (toAccountSourceValue !== accountDefaultId) {
      toAccountSourceValue = accountDefaultId
      this.setState({
        [toAccountSource]: toAccountSourceValue,
        [toRefSource]: refId,
        deposit: type === 'AGE' ? newDeposit : deposit
      }, () => {
        changeForm('top-up-form', toAccountSource, toAccountSourceValue )
      })
    }
  }

  onEnter = () => {
    let { deposit } = this.props
    this.setState({ deposit })
  }

  onExit = () => {
    this.setState({
      moneyCanDeposit: 0,
      companyAccountId: 0,
      agencyAccountId: 0,
      agencyId: 0,
      companyId: 0,
      deposit: 0,
    })
  }

  render() {
    let {
      open,
      fromId,
      fromRefName,
      fromAccountDefault,
      type,
      onClose,
      topUp,
      translate,
    } = this.props
    let { deposit } = this.state
    if (!type) return null
    let { 
      fromRefSource,
      fromAccountSource,
      fromRefType,
      toRef,
      toRefSource,
      toAccountSource,
      toRefType,
      toRefExtraFilter,
      title
    } = mappingType[type]
    let size = type === 'COM' ? 6 : 12
    let extra = { resource: 'financeaccounts', fullWidth: true }
    return <Dialog
      open={open}
      onClose={() => { onClose() }}
      fullWidth
      onExit={this.onExit}
      onEnter={this.onEnter}
      maxWidth="md"
    >
      <DialogTitle>
        <Typography variant="h4">{translate(title)}&nbsp;<b>{fromRefName}</b></Typography>
      </DialogTitle>
      <DialogContent>
        <SimpleForm
          resource="financeaccounts"
          form="top-up-form"
          record={{ topUpType: 'FOR_AGENCY' }}
          save={record => topUp(record)}
          validate={record => {
            let error = {}
            let { topUpValue } = record
            if (topUpValue <= 0) {
              error.topUpValue = 'Required'
            } 
            return error
          }}
        >
          <FormDataConsumer>
            {({ formData }) => {
              let toRefSourceValue = this.state[toRefSource]
              if (formData[toRefSource] && toRefSourceValue !== formData[toRefSource]) {
                this.getAccountDefault(formData[toRefSource], toRefType, toAccountSource, toRefSource)
              }

              return fromId && <Grid container fullWidth>
                <Grid item md={size} xs={12}>
                  <ReferenceInput
                    record={{}}
                    perPage={1000}
                    reference="financeaccounts"
                    filter={{ '../mappingCondition': { type: fromRefType, refId: formData[fromRefSource] }}}
                    source={fromAccountSource}
                    defaultValue={fromAccountDefault}
                    {...extra}
                  >
                    <SelectInput optionValue="id" optionText="accountName" />
                  </ReferenceInput>
                  {(type === 'AGE' && formData[fromAccountSource]) && <AccountMoreInfo deposit={deposit} />}
                  {type === 'COM' && <ReferenceInput
                    perPage={1000}
                    reference={toRef}
                    source={toRefSource}
                    record={{}}
                    filter={{
                      ...toRefExtraFilter,
                      '../belongsTo': {
                        [fromRefSource]: fromId,
                      }
                    }}
                    {...extra}
                  >
                    <SelectInput optionValue="id" optionText="name" />
                  </ReferenceInput>}
                  {formData[toRefSource] && <ReferenceInput
                    record={{}}
                    perPage={1000}
                    reference="financeaccounts"
                    filter={{ '../mappingCondition': { type: toRefType, refId: formData[toRefSource] }}}
                    source={toAccountSource}
                    {...extra}
                  >
                    <SelectInput optionValue="id" optionText="accountName" />
                  </ReferenceInput>}
                  {(type === 'COM' && formData[toAccountSource]) && <AccountMoreInfo deposit={deposit} />}
                </Grid>
                <Grid item md={size} xs={12}>
                  <MoneyInput autoFocus source="topUpValue" label="resources.financeaccounts.fields.topUpValue" />
                  <TextInput source="description" {...extra} />
                  <TextInput 
                    source="invoiceNumber"
                    {...extra}
                  />
                  <ReferenceInput
                    source="topUpMethod"
                    label="resources.reservations.payment.method"
                    reference="paymentmethod2s"
                    filter={{ id: { lt: '20' }}}
                    defaultValue="00CASH"
                    {...extra}
                  >
                    <SelectInput optionText="name" />
                  </ReferenceInput>
                  {formData.topUpMethod === '10BANK' && <Fragment>
                    <TextInput
                      source="journalNumber"
                      {...extra}
                    />
                  </Fragment>}
                </Grid>
              </Grid>
            }}
          </FormDataConsumer>
        </SimpleForm>
      </DialogContent>
    </Dialog> 
  }
}

const enhanceTopUpForm = compose(translate, connect(null, { changeForm }))
const TopUpForm = enhanceTopUpForm(_TopUpForm)

class TopUpButton extends Component {

  state = {
    open: false
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  onClose = () => {
    let { reset } = this.props
    this.setState({
      open: false
    }, () => { reset('top-up-form')})
  }

  topUp = record => {
    let { refreshView, showNotification } = this.props
    Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: 'topUp',
      requestMethod: 'POST',
      data: { ...record, topUpValue: parseFloat(record.topUpValue) }
    }).then(
      () => {
        this.onClose()
        refreshView()
        showNotification('notification.topUp_success')
      }
    ).catch(
      () => {
        this.onClose()
        showNotification('notification.topUp_failure', 'warning')
      }
    )
  }

  render() {
    let {
      fromRefName,
      fromAccountDefault,
      translate,
      toId,
      fromId,
      type,
      color,
      button,
      classes,
      isAdmin,
      deposit,
      ...props
    } = this.props
    let { open } = this.state
    return <Fragment>
      {button 
        ? <Button
          color={color}
          onClick={this.open}
          {...props}
        >
          <div className={classes.container}>
            <AccountBalanceWalletIcon className={classes.icon} />
            <span>{translate('button.topUp')}</span>
          </div>
        </Button>
        : <LinkField
          className={classes.iconButton}
          icon
          color={color}
          onClick={this.open}
          {...props}
        >
          <Tooltip title={translate('button.topUp')} enterDelay={100} >
            <AccountBalanceWalletIcon />
          </Tooltip>
        </LinkField>
      }
      <TopUpForm
        open={open}
        onClose={this.onClose}
        topUp={this.topUp}
        toId={toId}
        fromId={fromId}
        type={type}
        fromRefName={fromRefName}
        fromAccountDefault={fromAccountDefault}
        isAdmin={isAdmin}
        deposit={deposit}
      />
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { refreshView, showNotification, reset })
)


export default enhance(TopUpButton)
