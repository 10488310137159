import Card from '@material-ui/core/Card'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { CreateController } from 'ra-core'
import React from 'react'
import { ListActions as DefaultActions, TitleForRecord } from 'react-admin'

const sanitizeRestProps = ({
  actions,
  children,
  className,
  crudCreate,
  isLoading,
  resource,
  title,
  hasCreate,
  hasEdit,
  hasList,
  hasShow,
  match,
  location,
  history,
  options,
  locale,
  permissions,
  translate,
  ...rest
}) => rest

export const CreateView = ({
  actions = <DefaultActions />,
  basePath,
  children,
  className,
  defaultTitle,
  hasList,
  hasShow,
  record = {},
  redirect,
  resource,
  save,
  title,
  nocard,
  ...rest
}) => (
    <div
      className={classnames('create-page', className)}
      {...sanitizeRestProps(rest)}
    >
      {!nocard ?
        <Card>
          <TitleForRecord
            title={title}
            record={record}
            defaultTitle={defaultTitle}
          />
          {React.cloneElement(children, {
            basePath,
            record,
            redirect:
              typeof children.props.redirect === 'undefined'
                ? redirect
                : children.props.redirect,
            resource,
            save,
          })}
        </Card>
        :
        <div>
          <TitleForRecord
            title={title}
            record={record}
            defaultTitle={defaultTitle}
          />
          {React.cloneElement(children, {
            basePath,
            record,
            redirect:
              typeof children.props.redirect === 'undefined'
                ? redirect
                : children.props.redirect,
            resource,
            save,
          })}
        </div>}

    </div>
  )

CreateView.propTypes = {
  actions: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
  defaultTitle: PropTypes.any,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  resource: PropTypes.string,
  save: PropTypes.func,
  title: PropTypes.any,
}

const Create = props => (
  <CreateController {...props}>
    {controllerProps => <CreateView {...props} {...controllerProps} />}
  </CreateController>
)

Create.propTypes = {
  actions: PropTypes.element,
  children: PropTypes.element,
  className: PropTypes.string,
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  title: PropTypes.any,
  record: PropTypes.object,
  hasList: PropTypes.bool,
}

export default Create
