import {
  required,
  minLength,
  maxLength,
} from 'react-admin'

export const validateName = (min, max) => [required(), minLength(min), maxLength(max)]

export const MIN = 6

export const MAX = 150

