import {
  GET_ONE,
} from 'react-admin'

// ------------------------------------------------------------------------

// Search Trip
export const RESERVATION_SEARCH_TRIP = 'RESERVATION_SEARCH_TRIP'
export const RESERVATION_SEARCH_TRIP_SUCCESS = 'RESERVATION_SEARCH_TRIP_SUCCESS'
export const RESERVATION_SEARCH_TRIP_FAILURE = 'RESERVATION_SEARCH_TRIP_FAILURE'

// Get Trip Detail
export const RESERVATION_GET_TRIP_DETAIL = 'RESERVATION_GET_TRIP_DETAIL'
export const RESERVATION_GET_TRIP_DETAIL_SUCCESS = 'RESERVATION_GET_TRIP_DETAIL_SUCCESS'
export const RESERVATION_GET_TRIP_DETAIL_FAILURE = 'RESERVATION_GET_TRIP_DETAIL_FAILURE'

// Get Seat Map of Trip
export const RESERVATION_GET_SEAT_MAP = 'RESERVATION_GET_SEAT_MAP'
export const RESERVATION_GET_SEAT_MAP_SUCCESS = 'RESERVATION_GET_SEAT_MAP_SUCCESS'
export const RESERVATION_GET_SEAT_MAP_FAILURE = 'RESERVATION_GET_SEAT_MAP_FAILURE'

// Get Reserved Seat of Trip
export const RESERVATION_GET_RESERVED_SEAT = 'RESERVATION_GET_RESERVED_SEAT'
export const RESERVATION_GET_RESERVED_SEAT_SUCCESS = 'RESERVATION_GET_RESERVED_SEAT_SUCCESS'
export const RESERVATION_GET_RESERVED_SEAT_FAILURE = 'RESERVATION_GET_RESERVED_SEAT_FAILURE'

// Calculate charge fare
export const RESERVATION_CALCULATE_CHARGE_FARE = 'RESERVATION_CALCULATE_CHARGE_FARE'
export const RESERVATION_CALCULATE_CHARGE_FARE_SUCCESS = 'RESERVATION_CALCULATE_CHARGE_FARE_SUCCESS'
export const RESERVATION_CALCULATE_CHARGE_FARE_FAILURE = 'RESERVATION_CALCULATE_CHARGE_FARE_FAILURE'

// Make reservation
export const RESERVATION_MAKE_RESERVATION = 'RESERVATION_MAKE_RESERVATION'
export const RESERVATION_MAKE_RESERVATION_SUCCESS = 'RESERVATION_MAKE_RESERVATION_SUCCESS'
export const RESERVATION_MAKE_RESERVATION_FAILURE = 'RESERVATION_MAKE_RESERVATION_FAILURE'

// Make quotation
export const RESERVATION_MAKE_QUOTATION = 'RESERVATION_MAKE_QUOTATION'
export const RESERVATION_MAKE_QUOTATION_SUCCESS = 'RESERVATION_MAKE_QUOTATION_SUCCESS'
export const RESERVATION_MAKE_QUOTATION_FAILURE = 'RESERVATION_MAKE_QUOTATION_FAILURE'

// Get reservation
export const RESERVATION_GET_DETAIL = 'RESERVATION_GET_DETAIL'
export const RESERVATION_GET_DETAIL_SUCCESS = 'RESERVATION_GET_DETAIL_SUCCESS'
export const RESERVATION_GET_DETAIL_FAILURE = 'RESERVATION_GET_DETAIL_FAILURE'

// Observe
export const RESERVATION_INIT = 'RESERVATION_INIT'
export const RESERVATION_CHANGE_ENDPOINT = 'RESERVATION_SELECT_ENDPOINT'
export const RESERVATION_TRIP_TYPE = 'RESERVATION_TRIP_TYPE'
export const RESERVATION_SELECT_TRIP = 'RESERVATION_SELECT_TRIP'
export const RESERVATION_SELECT_SEAT_MAP = 'RESERVATION_SELECT_SEAT_MAP'
export const RESERVATION_RESERVED_SEAT = 'RESERVATION_RESERVED_SEAT'
export const RESERVATION_ADD_PASSENGER_CHARGE = 'RESERVATION_ADD_PASSENGER_CHARGE'
export const RESERVATION_UPDATE_BOOKING_INFORMATION = 'RESERVATION_UPDATE_BOOKING_INFORMATION'
export const RESERVATION_UPDATE_PASSENGER_INFORMATION = 'RESERVATION_UPDATE_PASSENGER_INFORMATION'
export const RESERVATION_UPDATE_TOTAL_CHARGE_FARE = 'RESERVATION_UPDATE_TOTAL_CHARGE_FARE'
export const RESERVATION_UPDATE_PAY_LATER = 'RESERVATION_UPDATE_PAY_LATER'
export const RESERVATION_UPDATE_TRANSACTION = 'RESERVATION_UPDATE_TRANSACTION'
export const RESERVATION_UPDATE_PAYMENT_INFORMATION = 'RESERVATION_UPDATE_PAYMENT_INFORMATION'
export const RESERVATION_RESET = 'RESERVATION_RESET'
export const RESERVATION_TRANSACTION = 'RESERVATION_TRANSACTION'

// ------------------------------------------------------------------------
export const doAction = (type, meta, payload, start, done, error) => {
  return { type, meta, payload, start, done, error }
}

// ------------------------------------------------------------------------
export const doFetchOneAction = (type, resource, id, filter, transferData, start, done, error) => {
  return {
    type,
    payload: {
      id,
      filter
    },
    meta: {
      resource,
      fetch: GET_ONE,
    },
    transferData, start, done, error
  }
}

// ------------------------------------------------------------------------
export const doFetchAction = (type, method, requestMethod, data, resource, transferData, start, done, error) => {
  return {
    type,
    payload: {
      method,
      requestMethod,
      data,
    },
    meta: {
      resource,
      fetch: 'REMOTE'
    },
    transferData, start, done, error
  }
}