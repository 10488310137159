import React, { Component } from 'react'
import {
  translate,
  Datagrid,
  FunctionField,
  ReferenceField,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import UnRegisterAgencyButton from './UnRegisterAgencyButton'
import QuantityChip from './QuantityChip'
import compose from 'recompose/compose'
import { formatNumber } from '../utils/formatUtil'
import { getCurrentAgency } from '../utils/commonUtil'
import _ from 'lodash'
import CommissionChip from '../common/CommissionChip'
import { withLoading } from '../common/withLoading'
import { Provider } from '../provider'

const saveNewAgencyProduct = (data, ids, newAgencyProduct) => {
  let { id: agencyProductId } = newAgencyProduct
  if (!data[agencyProductId]) {
    ids.unshift(agencyProductId)
    data = { ...data, [agencyProductId]: newAgencyProduct }
  }
  return { ids, data }
}

const agencyListStyle = {
  textCenter: {
    textAlign: 'center',
  },
  agencyEmpty: {
    marginTop: 18,
    fontStyle: 'italic',
    width: '100%',
    paddingLeft: 18,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 17,
  },
  endCol: {
    textAlign: 'end',
  },
}

class AgencyList extends Component {

  state = {}

  async componentDidMount() {
    let { setLoading } = this.props
    let { ids, data } = await this.loadData()
    this.setState({ ids, data }, () => { setLoading(false) })
  }

  loadData = async () => {
    let { productId, supplierId, parentProductId } = this.props
    let resp = await Provider.dataProvider('GET_LIST', 'agencyproducts', {
      filter: {
        where: {
          productId,
        },
        include: [{
          relation: 'agency',
          field: ['id', 'name', 'identifier'],
        }],
        fields: [
          'id',
          'agencyId',
          'commissionScript',
          'platformCommissionScript',
          'quantity',
        ],
        ignore: { supplierId: parentProductId || supplierId },
      },
      pagination: {},
      sort: {},
    })
    if (resp && resp.data) {
      let data = _.keyBy(resp.data, 'id')
      let ids = resp.data.map(ele => ele.id)
      return { ids, data }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { newAgencyProduct } = nextProps
    let { ids: currentIds, data: currentData, newAgencyProduct: currentNewAgencyProduct } = prevState 
    if (newAgencyProduct && newAgencyProduct !== currentNewAgencyProduct) {
      let { data: newData, ids: newIds } = saveNewAgencyProduct(currentData, currentIds, newAgencyProduct)
      return { ...prevState, ids: newIds, data: newData, newAgencyProduct }
    }
    return { ...prevState }
  }

  handleDeleteDone = agencyProductId => {
    let { updateAgencyCount } = this.props
    let { ids, data } = this.state
    ids = _.reduce(ids, (result, id) => {
      if (id !== agencyProductId) {
        result.push(id)
      }
      return result
    }, [])
    data = _.omit(data, agencyProductId)
    updateAgencyCount()
    this.setState({ ids, data })
  }

  render() {
    let {
      classes,
      isCompanyManager,
      commissionTemplates,
      translate,
      showAgencyCompany,
      showPlatformCommission,
      supplierId,
      hideSupplier,
    } = this.props
    let { ids, data } = this.state
    let currentAgency = getCurrentAgency()
    let currentAgencyId = currentAgency.id
    if (!showAgencyCompany && ids) {
      ids = _.reduce(ids, (result, ele) => {
        let { agencyId } = data[ele]
        if (agencyId !== currentAgencyId) {
          result.push(ele)
        }
        return result
      }, [])
    }
    return ids ? (ids.length > 0 ? <Datagrid
      resource="agencyproducts"
      currentSort={{}}
      ids={ids}
      data={data}
    >
      <ReferenceField
        basePath="/agencies"
        reference="agencies"
        source="agencyId"
        resource="agencyproducts"
        linkType={false}
        allowEmpty
      >
        <FunctionField
          source="name"
          render={({ id, name }) => <span>{name}&nbsp;{supplierId === id && `(${translate('resources.products.fields.supplierId')})`}</span>}
        />
      </ReferenceField>
      <CommissionChip
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        isCompanyManager={isCompanyManager}
        label="resources.agencyproducts.fields.commission"
        source="commissionScript"
        method="changeCommission"
        resources="agencyproducts"
        commissionTemplates={commissionTemplates}
      />
      {showPlatformCommission && <FunctionField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="platformCommissionScript"
        render={(record) => {
          if (!record) return null
          let { platformCommissionScript = {} } = record 
          let { params = {} } = platformCommissionScript 
          let { percent, fee = 0 } = params
          let commission = percent ? `${percent}%` : formatNumber(fee)
          return commission
        }}
      />}
      <QuantityChip
        isCompanyManager={isCompanyManager}
        source="quantity"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      />
      {(isCompanyManager && !hideSupplier) && <FunctionField 
        headerClassName={classes.endCol}
        cellClassName={classes.endCol}
        render={(record) => { 
          let { agencyId } = record
          let currentAgency = getCurrentAgency() || {}
          let currentAgencyId = currentAgency.id
          let isDefaultAgency = agencyId === currentAgencyId
          return !isDefaultAgency ? <UnRegisterAgencyButton
            record={record}
            handleDeleteDone={this.handleDeleteDone}
          /> : null
        }} 
      />}
    </Datagrid> : <div className={classes.agencyEmpty}>
      {translate('resources.products.product_no_agency')}
    </div>) : null
  }
}

const enhanceAgencyList = compose(
  withStyles(agencyListStyle),
  translate,
  withLoading,
)
export default enhanceAgencyList(AgencyList)

