import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft'
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter'
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight'
import FormatBoldIcon from '@material-ui/icons/FormatBold'
import FormatItalicIcon from '@material-ui/icons/FormatItalic'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import _ from 'lodash'

const styles = theme => ({
  toggleContainer: {
    height: 56,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: `${theme.spacing.unit}px 0`,
    background: theme.palette.background.default,
  },
  root: {
    boxShadow: '0px 1px 5px 0px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 3px 1px -2px rgb(0 0 0 / 12%)',
    background: '#fff'
  }
})

class ToggleButtons extends React.Component {
  state = {
    value: undefined
  };

  handleFormat = (event, formats) => {
    this.props.handleStyleItem('fontStyle', formats)
  }

  handleAlignment = (event, alignment) => {
    this.props.handleStyleItem('align', alignment)
  }

  componentDidMount() {
    const {value, type} = this.props
    let val = value
    if(type === 'format' && !_.isEmpty(value)) {
      val = value.split(' ')
    }
    this.setState({value: val})
  }

  componentDidUpdate(prevProps) {
    const {value, type} = this.props
    if (value !== prevProps.value) {
      let val = value
      if(type === 'format' && !_.isEmpty(value)) {
        val = value.split(' ')
      }
      this.setState({value: val})
    }
  }

  render() {
    const { type, classes } = this.props
    const { value } = this.state
    return (
      <div>
        {
          type === 'align' && 
            <ToggleButtonGroup value={value} classes={{root: classes.root}} exclusive onChange={this.handleAlignment}>
              <ToggleButton value="left">
                <FormatAlignLeftIcon />
              </ToggleButton>
              <ToggleButton value="center">
                <FormatAlignCenterIcon />
              </ToggleButton>
              <ToggleButton value="right">
                <FormatAlignRightIcon />
              </ToggleButton>
            </ToggleButtonGroup>
        }
        {
          type === 'format' && 
            <ToggleButtonGroup value={value} classes={{root: classes.root}} onChange={this.handleFormat}>
              <ToggleButton value="bold">
                <FormatBoldIcon />
              </ToggleButton>
              <ToggleButton value="italic">
                <FormatItalicIcon />
              </ToggleButton>
            </ToggleButtonGroup>
        }
      </div>
    )
  }
}

ToggleButtons.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ToggleButtons)
