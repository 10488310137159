import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const CallCenterIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" id="svg_2" d="m20.85193,18.99964l0,2.56592l-18.38961,0l0,-2.56649c0,-1.21169 1.09839,-2.70335 2.4402,-3.30947l2.98579,-1.34911l2.07267,4.10776l1.06122,-2.73642c0.20924,0.03764 0.41669,0.08782 0.63493,0.08782s0.42508,-0.05018 0.63493,-0.08782l1.00726,2.60184l2.0319,-4.0188l3.08233,1.39415c1.34001,0.60613 2.43839,2.09778 2.43839,3.31061zm-15.67721,-8.99327l0,-1.85317c0,-0.31418 0.17207,-0.58959 0.43168,-0.74811c0.34834,-2.8841 2.92763,-5.13184 6.05192,-5.13184s5.70419,2.24775 6.05313,5.13184c0.25901,0.15852 0.43108,0.43393 0.43108,0.74811l0,1.85317c0,0.49208 -0.41909,0.89123 -0.93711,0.89123s-0.93711,-0.39915 -0.93711,-0.89123l0,-0.38945c-0.50722,2.42622 -2.37484,4.67853 -4.60939,4.67853c-2.23455,0 -4.10218,-2.25231 -4.6094,-4.67853l0,0.38945c0,0.39914 -0.2788,0.73328 -0.65952,0.84675c0.37293,1.20712 1.20031,2.25002 2.34307,2.93427c0.21105,-0.06614 0.47725,-0.0536 0.73566,0.07413c0.41489,0.20528 0.62654,0.60441 0.46945,0.88952c-0.15648,0.2851 -0.61934,0.34897 -1.03664,0.14312c-0.30457,-0.15111 -0.49763,-0.40484 -0.51801,-0.64205c-1.30884,-0.796 -2.23695,-2.01453 -2.62007,-3.42009c-0.34414,-0.13115 -0.58876,-0.45046 -0.58876,-0.82565zm1.0702,-2.8094c0.02818,0.00171 0.05516,0.00228 0.08214,0.01197c0.06775,0.02395 0.11691,0.07014 0.15108,0.1243c0.20326,0.08211 0.36514,0.23208 0.46466,0.41796c0.2764,-2.46385 2.27891,-3.76734 4.71432,-3.76734c2.4354,0 4.43792,1.30349 4.71432,3.76734c0.13729,-0.25602 0.3981,-0.43905 0.71047,-0.47726c-0.4053,-2.50491 -2.68002,-4.43106 -5.42479,-4.43106c-2.71659,0.00057 -4.97333,1.88738 -5.4122,4.35408z"/>
  </SvgIcon>
)

export default CallCenterIcon
