import React, { Component, Fragment } from 'react'
import {
  Chip,
  withStyles,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core'
import { formatNumber } from '../utils/formatUtil'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { 
  showNotification,
  translate,
  SimpleForm,
  SelectInput,
  addField,
  ReferenceField,
  FunctionField,
  required,
} from 'react-admin'
import { connect } from 'react-redux'
import _ from 'lodash'
import { reset } from 'redux-form'
import { MoneyInput } from '../common/MoneyInput'

const COMMISSION_FORM = 'commission-form'

class IncludePaymentFee extends Component {

  handleChange = (evt, value) => {
    let { input } = this.props
    input.onChange(value)
  }

  render() {
    let { label, input } = this.props
    return <FormControlLabel
      control={
        <Checkbox
          checked={input.value}
          onChange={this.handleChange}
          color="primary"
        />
      }
      label={label}
    />
  }
}

export const IncludePaymentFeeInput = addField(IncludePaymentFee)

class Percent extends Component {

  handleChange = (evt) => {
    let { input } = this.props
    let { value } = evt.target
    if (value) {
      value = parseFloat(value)
    }
    input.onChange(value)
  }

  render() {
    let { input, meta, fullWidth, range = {}, label, ...props } = this.props
    let { min = 0, max = 100 } = range
    let { error } = meta
    return <TextField
      style={{ 
        marginTop: 16,
        marginBottom: 8,
        width: fullWidth ? '100%' : undefined,
      }}
      label={label}
      value={input.value}
      type="number"
      onChange={this.handleChange}
      error={!(!error)}
      helperText={error && error}
      inputProps={{
        min,
        max,
      }}
      {...props}
    />
  }
}

export const PercentInput = addField(Percent)

const dialogStyles = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  content: {
    padding: 0,
  },
  contentText: {
    paddingLeft: 16,
  }
}

class _CommissionFormDialog extends Component {

  state = {}

  componentDidMount() {
    let { commissionTemplateMapping, template } = this.props
    if (!template) {
      this.setState({ template: commissionTemplateMapping[1] })
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { template } = nextProps
    let { template: currentTemplate } = prevState
    if (template !== currentTemplate) {
      return { ...prevState, template }
    }
    return { ...prevState }
  }

  handleChangeTemplate = (evt, value) => {
    let { reset, commissionTemplateMapping  } = this.props
    let template = commissionTemplateMapping[value]
    this.setState({ template })
    reset(COMMISSION_FORM)
  }

  renderTemplate = (parameters) => {
    let { percent, includePaymentFee, fee, range } = parameters
    let { translate } = this.props
    return <Fragment>
      {percent && <PercentInput
        required
        range={range}
        label={translate('resources.commissiontemplates.fields.params.value')}
        source="params.percent"
        fullWidth
        validate={required()}
      />}
      {fee && <MoneyInput
        required
        label="resources.commissiontemplates.fields.params.value"
        source="params.fee"
        fullWidth
      />}
      {includePaymentFee && <IncludePaymentFeeInput
        label={translate('resources.commissiontemplates.fields.params.includePaymentFee')}
        source="params.includePaymentFee"
      />}
    </Fragment>
  }

  render() {
    let { 
      classes,
      open,
      onClose,
      translate,
      commissionTemplateChoices,
      record = {},
      agencyId,
      handleUpdate,
      isPlatformComission,
    } = this.props
    let { template = {} } = this.state
    let { parameters = {} } = template
    return <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.title}>
        {!isPlatformComission && <ReferenceField
          basePath="/agencies"
          reference="agencies"
          source="agencyId"
          resource="agencyproducts"
          record={{ agencyId }}
          linkType={false}
          allowEmpty
        >
          <FunctionField
            render={({ name }) => {
              return <span className={classes.textTitle}>
                {translate('resources.commissiontemplates.dialog_title')}&nbsp;-&nbsp;{name}
              </span>
            }}
          />
        </ReferenceField>}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleForm
          resource="commissiontemplates"
          record={record}
          save={handleUpdate}
          form={COMMISSION_FORM}
          validate={value => {
            let { percent: percentTemplate } = parameters
            let { params = {} } = value
            let { fee, percent } = params
            let error = { params: {} }
            if (percentTemplate) {
              if (percent < 0 || percent > 100) {
                error.params.percent = translate('error_messages.invalid.commission.invalid_percent_value')
              }
            }
            if (fee < 0) {
              error.params.fee = translate('error_messages.invalid.commission.invalid_value')
            }
            return error
          }}
        >
          <SelectInput
            resource="commissiontemplates"
            source="templateId"
            validate={required()}
            choices={commissionTemplateChoices}
            optionText="name"
            onChange={this.handleChangeTemplate}
            fullWidth
          />
          {parameters && this.renderTemplate(parameters)}
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }
}

const CommissionFormDialog = compose(
  withStyles(dialogStyles),
  translate,
  connect(null, { showNotification, reset })
)(_CommissionFormDialog)

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336',
    },
  })
}

class CommissionChip extends Component {

  state = {
    open: false,
    commission: 0,
  }

  getCommissionTemplates = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'commissiontemplates', {
      filter: {
        '../fields': ['id', 'name', 'parameters']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  async componentDidMount() {
    let { record = {}, source, commissionTemplates } = this.props
    if (!commissionTemplates) {
      commissionTemplates = await this.getCommissionTemplates()
    }
    let commissionTemplateChoices = commissionTemplates.map(({ id, name }) => ({ id, name }))
    let commissionTemplateMapping = _.keyBy(commissionTemplates, 'id')
    let script = !_.isEmpty(record[source]) ? record[source] : { templateId: 1 }
    this.setState({
      script,
      commissionTemplateChoices,
      commissionTemplateMapping,
      agencyId: record.agencyId,
    })
  }

  handleEdit = () => {
    let { script, commissionTemplateMapping } = this.state
    let { templateId } = script
    this.setState({
      open: true,
      template: commissionTemplateMapping[templateId]
    })
  }

  handleClose = () => {
    let { reset } = this.props
    this.setState({
      open: false,
      template: null,
    })
    reset(COMMISSION_FORM)
  }

  handleUpdate = (newScript) => {
    let { script, commissionTemplateMapping } = this.state
    let { record, resources, method, showNotification, source } = this.props
    let { id } = record
    if (script === newScript) {
      this.handleClose()
    } else {
      let { templateId: newTemplateId } = newScript
      let { templateId } = script
      if (templateId !== newTemplateId) {
        let newTemplate = commissionTemplateMapping[newTemplateId]
        let { parameters } = newTemplate
        let { percent, fee } = parameters
        if (percent) {
          newScript = _.omit(newScript, ['params.fee'])
        }
        if (fee) {
          newScript = _.omit(newScript, ['params.percent'])
        }
      }
      Provider.dataProvider('REMOTE', resources, {
        method,
        requestMethod: 'POST',
        data: { id, [source]: newScript },
      }).then(() => {
        showNotification('notification.change_commission_success')
        this.setState({ script: newScript })
        this.handleClose()
      }).catch(() => {
        this.handleClose()
        showNotification('notification.change_commission_failure', 'warning')
      })
    }
  }

  render() {
    let { classes, translate, isPlatformComission } = this.props
    let { 
      errorMessage,
      open,
      commissionTemplateMapping,
      commissionTemplateChoices,
      template,
      script = {},
      agencyId,
    } = this.state
    let { params = {} } = script 
    let { percent, fee } = params
    let commission = (percent !== undefined || fee !== undefined) ? (percent !== undefined ? `${percent}%` : formatNumber(fee)) : translate('resources.common.no_config_commission')
    let label = isPlatformComission ? `${translate('resources.companies.fields.platformCommission')}: ${commission}` : commission
    return <Fragment>
      <Tooltip 
        title={errorMessage || ''}
        placement="right-end"
        open={errorMessage}
        classes={{
          tooltip: classes.tooltip
        }}
        arrow="true"
      >
        <Chip
          size="small"
          variant="outlined"
          color="primary"
          label={label}
          className={classes.chip}
          onClick={() => { 
            this.handleEdit()
          }}
        />
      </Tooltip>
      {open && <CommissionFormDialog
        open={open}
        isPlatformComission={isPlatformComission}
        onClose={this.handleClose}
        template={template}
        commissionTemplateMapping={commissionTemplateMapping}
        commissionTemplateChoices={commissionTemplateChoices}
        record={script}
        agencyId={agencyId}
        handleUpdate={this.handleUpdate}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification, reset })
)

export default enhance(CommissionChip)
