import React from 'react'
import {
  List,
  TextField,
  Datagrid,
  EditButton as RAEditButton,
  ReferenceField,
  FunctionField,
  translate,
  Create,
  Edit,
  Filter,
  CreateButton,
  NumberField,
} from 'react-admin'
import { Chip, Toolbar, withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import * as permission from '../utils/permission'
import SearchInput from '../common/SearchInput'

export const typeMapping = {
  COM: { label: 'resources.financeaccounts.fields.type.company', resource: 'companies' },
  AGE: { label: 'resources.financeaccounts.fields.type.agency', resource: 'agencies' },
  PLA: { label: 'resources.financeaccounts.fields.type.platform' },
}

const refStyle = {
  root: {
    marginRight: 4
  }
}

const _RefField = ({ record, translate, history, isAdmin, classes }) => {
  let { type, refId } = record
  if (!type) return null
  let { label, resource } = typeMapping[type]
  return resource ? <ReferenceField
    reference={resource}
    resource={resource}
    record={record}
    basePath={`/${resource}`}
    source="refId"
    linkType={false}
    allowEmpty
  >
    <FunctionField
      render={record => isAdmin ? <Chip
        onClick={() => {
          history.push(`${resource}/${refId}`)
        }}
        className={classes.root}
        label={`[${translate(label)}] ${record.name}`}
      /> : `[${translate(label)}] ${record.name}`}
    />
  </ReferenceField> : translate(label)
}

const enhanceRefField = compose(withStyles(refStyle), translate )
const RefField = enhanceRefField(_RefField)

const FinanceAccountListFilter = props => {
  return <Filter {...props}>
    <SearchInput 
      helperText="resources.financeaccounts.searchHelperText"
      source="q"
      alwaysOn
    />
  </Filter>
}

const Actions = ({ basePath }) => {
  return <Toolbar>
    <CreateButton basePath={basePath} />
  </Toolbar>
}

const styleList = {
  textCenter: {
    textAlign: 'center'
  },
  textEnd: {
    textAlign: 'end'
  }
}

export const FinanceAccountList = withStyles(styleList)(({
  history,
  permissions,
  classes,
  ...props
}) => {
  let isAdmin = permissions && permission.isAdmin(permissions)
  return <List
    {...props}
    history={history}
    bulkActionButtons={false}
    filters={<FinanceAccountListFilter />}
    actions={<Actions isAdmin={isAdmin} />}
    filterDefaultValues={{
      '../moreInfo': true,
      '../fields': [
        'id',
        'accountName',
        'accountNumber',
        'bank',
        'deposit',
        'platformDeposit',
        'refId',
        'type'
      ],
    }}
  >
    <Datagrid>
      <FunctionField
        label="resources.financeaccounts.fields.belongsTo"
        render={record => <RefField
          history={history}
          isAdmin={isAdmin}
          record={record}
        />}
      />
      <TextField source="accountName" />
      <TextField source="accountNumber" />
      <TextField source="bank" />
      <NumberField source="deposit" options={{ style: 'currency', currency: 'VND' }} locales="vi-Vn" />
      <NumberField source="platformDeposit" options={{ style: 'currency', currency: 'VND' }} locales="vi-Vn" />
      <FunctionField
        cellClassName={classes.textEnd}
        render={record => record.type === 'PLA' ? null : <RAEditButton basePath="/financeaccounts" record={record} />}
      />
    </Datagrid>
  </List>
})

export const FinanceAccountCreate = ({ permissions, ...props}) => {
  return <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave permissions={permissions} />
  </Create>
}

export const FinanceAccountEdit = ({ id, permissions, ...props }) => {
  return <Edit
    id={id}
    title={<EditTitle
      resource={props.resource}
      render={record => `${record.accountName ? record.accountName : `#${record.id}`}`}
    />}
    undoable={false}
    {...props}
  >
    <FormSave id={id} permissions={permissions} />
  </Edit>
}
