import React, { Component } from 'react'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { Button, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import Dropzone from 'react-dropzone'
import { styleLabelButton } from '../common/style'
import concat from 'lodash/concat'
import {
  normalize,
} from './utils/data'
import {
  EXTENSION_EXCEL, 
  EXTENSION_TXT, 
  getExtensionFile, 
} from '../utils/file'
import { readCsv } from '../utils/csv'
import { readXlsx } from '../utils/xlsx'

class ImportButton extends Component {

  onDrop = async (files) => {
    let { timetableGroup, handleImportBlocks } = this.props
    let result = []
    for (let i = 0; i < files.length; i++) {
      let file = files[i]
      let data = []
      let extensionFile = getExtensionFile(file.name)
      if (EXTENSION_TXT.indexOf(extensionFile) !== -1) {
        data = await readCsv(file)
      }
      if (EXTENSION_EXCEL.indexOf(extensionFile) !== -1) {
        data = await readXlsx(file) || []
      }
      result = concat(result, data)
    }
    let patterns = timetableGroup.data
    if (!patterns || patterns.length === 0) return
    let blocks = normalize(result, patterns)
    handleImportBlocks(blocks)
  }

  render() {
    const { classes, translate } = this.props
    return (
      <Dropzone onDrop={this.onDrop} disabledStyle={{}}>
        <Button color="primary">
          <FontAwesomeIcon icon={faFileUpload} />
          <span className={classes.label}>
            {translate('button.import')}
          </span>
        </Button>
      </Dropzone>
    )
  }

}

const enhance = compose(
  translate,
  withStyles(styleLabelButton),
  // connect(null, { importTripAction })
)

export default enhance(ImportButton)
