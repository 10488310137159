import { Grid, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import {
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  translate,
} from 'react-admin'
import compose from 'recompose/compose'
import { FareRuleTypes, FareCriteriaTypes } from './../common/constants'
import { Field } from 'redux-form'
import ChipArrayInput from './../common/ChipArrayInput'
import MaskedInput from 'react-text-mask'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    card: {
      padding: 12
    },
    chip: {
      margin: theme.spacing.unit / 2,
    },
  })
}

const ConditionSelectValue = ({ choices }) => (
  <SelectInput
    optionValue="code"
    choices={choices}
    optionText="name"
    source="conditionValue"
    resource="farerules"
    fullWidth
  />
)


const ChipArrayInputMask = ({ ...props }) => {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      id: 0,
      companyId: 0,
      valueHelperText: '',
    }
  }

  static getDerivedStateFromProps = (props, state) => {
    let currentId = state.id
    let consumeId = props.id
    if (currentId !== consumeId) {
      currentId = consumeId
    }

    return {
      id: currentId
    }
  }

  getHelperText = (value) => {
    let { translate } = this.props

    let dimension = (value && value < 0)
      ? translate('resources.fares.rules.decrease')
      : translate('resources.fares.rules.increase')

    let helperText = `${dimension} ${Math.abs(value).toLocaleString()}`
    return helperText
  }

  componentDidMount = () => {
    let { record } = this.props
    let ruleValue = record.ruleValue
    let helperText = this.getHelperText(ruleValue)
    this.setState({ valueHelperText: helperText })
  }

  onRuleValueChange = (k, v) => {
    let ruleValue = v
    let helperText = this.getHelperText(ruleValue)
    this.setState({ valueHelperText: helperText })
  }

  render() {
    let { classes, resource } = this.props
    let { valueHelperText } = this.state
    const extra = { resource, fullWidth: true }

    return (
      <Grid container spacing={24}>
        {/* Timetable information */}
        <Grid item xs={6} className={classes.card}>
          <Grid item xs={12}>
            <TextInput source="name" {...extra} />
          </Grid>

          <Grid item xs={12}>
            <TextInput source="desc" {...extra} />
          </Grid>

          <Grid item xs={12}>
            <ReferenceInput source="ruleType" reference="fareruletypes" {...extra}>
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <TextInput source="conditionName" {...extra} />
            </Grid>

            <Grid item xs={6}>
              <FormDataConsumer>
                {({ formData }) => {
                  let ruleType = formData && formData.ruleType
                  let component = ''
                  switch (ruleType) {
                    case FareRuleTypes.DEFAULT:
                      component = ''
                      break
                    case FareRuleTypes.HOLIDAY:
                      component = <Field
                        name="conditionValue"
                        classes={classes}
                        maskComponent={ChipArrayInputMask}
                        component={ChipArrayInput}
                      />
                      break
                    case FareRuleTypes.BEFORE_DAYS:
                      component = <NumberInput source="conditionValue" {...extra} />
                      break
                    case FareRuleTypes.CLASS:
                      component =
                        <ReferenceInput
                          label=" "    // work around
                          source=""
                          reference="farecriteria" 
                          resource={resource}
                          filter={{ type: FareCriteriaTypes.PASSENGER_CLASS }}
                          fullWidth
                        >
                          <ConditionSelectValue />
                        </ReferenceInput>
                      break
                    default:
                      break
                  }

                  return component
                }}
              </FormDataConsumer>

            </Grid>
          </Grid>

          <Grid item xs={12}>
            <NumberInput
              source="ruleValue"
              onChange={(k, v) => this.onRuleValueChange(k, v)}
              helperText={valueHelperText} {...extra}
            />
          </Grid>
        </Grid>
      </Grid >
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(FormSave)