import React, { Component } from 'react'
import {
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  IconButton,
  DialogActions
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedo } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { restoreReminder as restoreReminderAction } from './action'

const style = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
})

const CHANGE_ONE = '1'
const CHANGE_AFTER = '2'

const deleteChoices = [
  { id: CHANGE_ONE, name: 'Lời nhắc này' },
  { id: CHANGE_AFTER, name: 'Lời nhắc này và các lời nhắc sau' },
]

const RestoreReminderConfirm = ({ open, onClose, value, onChange, onDone, translate }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">
        {translate('resources.servicereminders.title.confirm_changing')}
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {deleteChoices.map((updateChoice, idx) => (
            <FormControlLabel
              key={idx}
              value={updateChoice.id}
              control={<Radio />}
              label={updateChoice.name}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {translate('button.cancel')}
        </Button>
        <Button onClick={() => onDone()} color="primary">
          {translate('button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

class RestoreButton extends Component {

  constructor(props) {
    super(props)
    this.state = {
      openConfirmDialog: false,
      confirmValue: CHANGE_ONE
    }
  }

  handleClick = () => {
    let { repeat } = this.props
    if (!repeat) {
      this.makeDone()
      return
    }
    this.setState({
      openConfirmDialog: true
    })
  }

  handleClose = () => {
    this.setState({
      openConfirmDialog: false,
      confirmValue: CHANGE_ONE
    })
  }

  handleChangeConfirmValue = (value) => {
    this.setState({
      confirmValue: value
    })
  }

  handleOkClick = () => {
    this.makeDone()
  }

  makeDone = () => {
    let { id, restoreReminderAction, done, error } = this.props
    let { confirmValue } = this.state
    let onDone = () => {
      done && done()
      this.setState({
        openConfirmDialog: false,
      })
    }
    let onError = () => {
      error && error()
      this.setState({
        openConfirmDialog: false,
      })
    }
    restoreReminderAction(id, { restoreType: confirmValue }, onDone, onError)
  }

  render() {
    let { openConfirmDialog, confirmValue } = this.state
    let { translate, classes, iconButton } = this.props
    return <>
      {iconButton ? (
        <IconButton onClick={this.handleClick} color="primary">
          <FontAwesomeIcon icon={faRedo} />
        </IconButton>
      ): (
        <Button onClick={this.handleClick} color="primary">
          <FontAwesomeIcon className={classes.leftIcon} icon={faRedo} />
          {translate('button.restore')}
        </Button>
      )}
      <RestoreReminderConfirm
        value={confirmValue}
        open={openConfirmDialog}
        onClose={this.handleClose}
        onDone={this.handleOkClick}
        onChange={this.handleChangeConfirmValue}
        translate={translate}
      />
    </>
  }
}

const enhance = compose(
  withStyles(style),
  translate,
  connect(null, { restoreReminderAction })
)

export default enhance(RestoreButton)