import React from 'react'
import {
  addField,
} from 'react-admin'
import TextMask from 'react-text-mask'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputLabel,
  Input,
} from '@material-ui/core'

// https://material-ui.com/demos/text-fields/
const MaskedInput = addField(({ input, className, ...rest }) => (
  <FormControl margin="normal" className={className}>
    <InputLabel htmlFor={rest.source}>{rest.label}</InputLabel>
    <Input
      {...rest}
      value={input.value}
      onChange={(evt) => {
        input.onChange(evt.target.value)
      }}
      component={TextMask}
    />
  </FormControl>
))

MaskedInput.propTypes = {
  input: PropTypes.func.isRequired,
}

export default MaskedInput
