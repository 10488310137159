import React, { Component } from 'react'
import { 
  Card,
  CardHeader,
  CardContent,
  withStyles,
  Divider,
} from '@material-ui/core'
import CardAvatar from '../common/CardAvatar'
import { 
  translate,
  ReferenceField,
  TextField,
  FunctionField,
} from 'react-admin'
import compose from 'recompose/compose'
const cardStyle = theme => ({
  card: {
    marginTop: 8
  },
  cardActions: {
    padding: 0
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  textContainer: {
    fontSize: 13,
    marginBottom: 4,
    display: 'flex',
  },
  label: {
    fontWeight: 'bold'
  },
  textInReferenceField: {
    fontSize: 13,
    lineHeight: 'normal',
  },
  avatarCol: {
    marginTop: 12,
    marginBottom: 8,
    display: 'flex',
    marginLeft: 10,
  },
  fullName: {
    lineHeight: 'normal',
    padding: 0,
    marginLeft: 8,
    fontSize: 13,
  },
})

class _DriversGroupCard extends Component {
  render() {
    let { record, translate, classes, history } = this.props
    let { fullName, phone, id } = record
    return <div className={classes.card} >
      <Card onClick={() => history.push(`/drivers/${id}`)}
      >
        <FunctionField
          className={classes.textContainer}
          record={record} 
          render={record => {
            return(
              <div className={classes.avatarCol}>
                <CardAvatar record={record} size='small' />
                <CardHeader className={classes.fullName} title={fullName} />
              </div>
            )}}
        />
        <Divider/>
        <CardContent>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.drivers.fields.phone')}:</span>&nbsp;<span>{phone}</span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.drivers.fields.status')}:</span>&nbsp;<span>              
              <ReferenceField
                basePath="/driverstatuses"
                reference="driverstatuses"
                source="status"
                resource="driverstatuses"
                record={record}
                linkType={false}
                allowEmpty
              >
                <TextField className={classes.textInReferenceField} source="name" />
              </ReferenceField>
            </span>
          </div>
        </CardContent>
      </Card>
    </div>
  }
}

const enhance = compose(translate, withStyles(cardStyle))
const DriversGroupCard = enhance(_DriversGroupCard)

const style = {
  root: {
    padding: 8
  }
}

class DriversMobileGrid extends Component {
  
  render() {
    let { ids, data, classes, companyManager, history } = this.props    
    return <div> 
      { ids.map( id => { 
        return <DriversGroupCard  
          key={id}
          record={data[id]}
          classes={classes}
          companyManager={companyManager}
          history={history}
        />
      })}
    </div>     
  }
}

export default withStyles(style)(DriversMobileGrid)

