import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import MomentUtils from '@date-io/moment'
import 'moment/locale/vi'
import 'moment/locale/en-gb'
import {
  MuiPickersUtilsProvider,
  DatePicker as DP,
  InlineDatePicker as IDP,
} from 'material-ui-pickers'
import compose from 'recompose/compose'
import { translate, addField, FieldTitle } from 'react-admin'
import Day from './Day'
import { getLunarDateFromMoment } from '../utils/lunar-date'
import { Field } from 'redux-form'
import { moment } from './format'
import momentJS from 'moment'

const styles = (theme) => {
  let { unit } = theme.spacing
  return {
    grid: {
      width: '60%',
    },
    date: {
      fontSize: 12
    },
    lunarDate: {
      fontSize: 10,
      color: '#bbb'
    },
    button: {
      width: 6 * unit,
      height: 6 * unit,
      margin: 0,
      padding: 0,
      right: -unit,
    }
  }
}

momentJS.locale('vi')

export const DatePickerField = ({ value, onChange, ...props }) => (
  <MuiPickersUtilsProvider
    utils={MomentUtils}
    locale={'vi'}
    moment={momentJS}
  >
    <DP
      margin="normal"
      fullWidth
      value={value}
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
      format={'DD/MM/YYYY'}
      onChange={onChange}
      {...props}
    />
  </MuiPickersUtilsProvider>
)

class DatePicker extends Component {

  constructor(props) {
    super(props)
    let { initValue = null, input, locale } = props
    let selectedDate
    let selectedLunarDate
    if (!input.value) {
      selectedDate = initValue && moment(initValue)
      if (selectedDate) {
        input.onChange(selectedDate.toISOString())
        selectedLunarDate = getLunarDateFromMoment(selectedDate)
      }
    }
    this.state = {
      currentLocale: locale,
      selectedDate,
      selectedLunarDate,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { input } = nextProps
    let { selectedDate: currentSelectedDate } = prevState
    let selectedDate = input.value ? moment(input.value) : currentSelectedDate
    if (currentSelectedDate !== selectedDate) {
      currentSelectedDate = selectedDate
    }
    return {
      selectedDate: currentSelectedDate,
      selectedLunarDate: getLunarDateFromMoment(selectedDate)
    }
  }

  handleDateChange = (k) => {
    let selectedDate = k ? k.toISOString() : k
    let { input, handleChangeMonth } = this.props
    handleChangeMonth && handleChangeMonth(selectedDate)
    input.onChange(selectedDate)
    this.setState({ selectedDate })
  }

  renderLunarDate = (date, selectedDate, dayInCurrentMonth, { ...props }) => {
    let { classes, showLunarDate } = this.props
    let lunarDate = getLunarDateFromMoment(date)
    let selected = selectedDate.isSame(date)
    let now = moment()
    let current = now.format('YYYY-MM-DD') === (date.format('YYYY-MM-DD'))

    let { minDate, disablePast } = props
    let disabled = false
    if (disablePast) {
      disabled = moment(date).isBefore(now)
    } else if (minDate) {
      let min = moment(minDate)
      disabled = moment(date).isBefore(min)
    }

    return (
      <Day
        selected={selected}
        current={current}
        hidden={!dayInCurrentMonth}
        disabled={disabled}
        {...props}
      >
        <span>
          <span className={classes.date}>{moment(date).format('DD')}</span>
          {
            showLunarDate &&
            <React.Fragment>
              <br />
              <span className={classes.lunarDate}>
                {lunarDate.day !== 1 ? `${lunarDate.day}` : `${lunarDate.day}/${lunarDate.month}`}
              </span>
            </React.Fragment>
          }
        </span>
      </Day>
    )
  }

  render() {
    const {
      classes,
      input,
      meta = {},
      locale,
      translate,
      dateFormat,
      dateMask,
      showLunarDate,
      picker,
      pickerType,
      keyboard,
      views,
      clearable,
      ...props
    } = this.props
    const { error } = meta
    let { selectedDate } = this.state
    let DatePickerComponent = pickerType === 'inline' ? IDP : DP
    return (
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale={locale}
        moment={momentJS}
      >
        <DatePickerComponent
          views={views}
          keyboard={keyboard}
          margin="normal"
          value={selectedDate}
          mask={dateMask || [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          format={dateFormat}
          onChange={(value) => {
            this.handleDateChange(value)
          }}
          renderDay={(day, selectedDate, dayInCurrentMonth) =>
            this.renderLunarDate(day, selectedDate, dayInCurrentMonth)}
          TextFieldComponent={picker && picker()}
          error={!!error}
          helperText={error && <span>{error}</span>}
          clearable={clearable}
          {...props}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

DatePicker.defaultProps = {
  keyboard: false,
  showLunarDate: false,
  pickerType: 'inline',
  dateFormat: 'DD/MM/YYYY',
}

const enhance = compose(
  translate,
  withStyles(styles)
)

const _DatePicker = enhance(DatePicker)
export default _DatePicker

const enhanceField = compose(
  translate,
  withStyles(styles),
  addField,
)

export const DatePickerInput = enhanceField(props => {
  const { resource, source, input, label, isRequired, basePath, classes = {}, ...rest } = props
  return (
    <Field
      name={input.name}
      label={
        label === false ? (
          label
        ) : (
          <FieldTitle
            label={label}
            source={input.name}
            resource={resource}
            isRequired={isRequired}
          />
        )
      }
      KeyboardButtonProps={{
        className: classes.button,
        tabIndex: -1,
      }}
      component={_DatePicker}
      {...rest}
    />
  )
})
