import React from 'react'
import { yellow, green, grey, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core'
import { ChipField } from 'react-admin'
import get from 'lodash/get'

export const PENDING = '00PENDING'
export const CANCEL = '10CANCEL'
export const DONE = '20DONE'
export const FAIL = '30FAIL'
export const TIMEOUT = '40TIMEOUT'

export const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  [DONE]: { color: 'white', backgroundColor: green[500] },
  [TIMEOUT]: { color: 'white', backgroundColor: grey[700] },
  [FAIL]: { color: 'white', backgroundColor: red[500] },
  [PENDING]: { color: 'white', backgroundColor: yellow[500] },
  [CANCEL]: { color: 'white', backgroundColor: grey[500] },
}

////////////////////////////////////////////////////////////////////////////////
// ViewTrip
export const StatusField = withStyles(statusStyles)(({ classes, ...props }) => {
  let status = get(props.record, 'id')
  let className = get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})
