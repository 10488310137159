import React, { Component } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  translate,
  DateField,
  ReferenceManyField,
  SingleFieldList,
  Filter,
} from 'react-admin'
import { SeatIcon } from '../icons'
import {
  Tooltip,
  Chip,
  IconButton,
  withStyles,
} from '@material-ui/core'
import LinkField from './../common/LinkField'
import moment from 'moment'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { DateTimePickerInput } from '../common/DateTimePicker'
import GeneralReport from './GeneralReport'
import { Provider } from '../provider'
import CallIcon from '../icons/call'
import { getCurrentCompany, getCurrentUser } from '../utils/commonUtil'
import { makeCall as dial } from '../utils/device-action'
import { OUTGOING } from './CallCenter'

const styles = {
  textCenter: {
    textAlign: 'center',
  },
}

class CallLogListFilter extends Component {
  state = {}

  render() {
    let {
      translate,
      fromDate,
      toDate,
      onFromDateChange,
      onToDateChange,
      ...props
    } = this.props
    return <Filter
      {...props}
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
    >
      <DateTimePickerInput
        label={translate('resources.calllogs.fields.startGte')}
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        onChange={(k, v) => { onFromDateChange(v) }}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />

      <DateTimePickerInput
        label={translate('resources.calllogs.fields.startLte')}
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        minDate={moment(fromDate).add('days')}
        onChange={(k, v) => (v)}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      
    </Filter>
  }
}

class _CallLogList extends Component {

  constructor(props) {
    super(props)
    let currentCompany = getCurrentCompany() || {}
    let currentUser = getCurrentUser() || {}

    let companyId = currentCompany.id
    let userId = currentUser.id

    this.state = {
      fromDate: moment().startOf('days'),
      toDate: moment().endOf('days'),
      report: {},
      companyId,
      userId
    }
  }

  async componentDidMount() {
    this.getReport()
  }

  makeCall = async phoneNumber => {
    let { companyId, userId } = this.state
    dial({ phoneNumber, companyId, userId })
  }

  getReport = async () => {
    let { fromDate, toDate } = this.state
    let res = await Provider.dataProvider('REMOTE', 'calllogs', {
      method: 'report',
      requestMethod: 'GET',
      data: {
        from: fromDate.toISOString(),
        to: toDate.toISOString(),
      },
    })
    if (res && res.data) {
      this.setState({ report: res.data })
    }
  }

  onFromDateChange = (v) => {
    let fromDate = moment(v)
    this.setState({ fromDate }, () => this.getReport())
  }

  onToDateChange = (v) => {
    let toDate = moment(v)
    this.getReport()
    this.setState({ toDate }, () => this.getReport())
  }

  pushToReservation = (record) => {
    const { push } = this.props
    let { id } = record
    push(`/reservations/${id}/show`)
  }

  render() {
    const { translate, classes, ...props } = this.props
    let { fromDate, toDate, report } = this.state
    return <>
      <GeneralReport report={report} />
      <List
        bulkActionButtons={false}
        filters={<CallLogListFilter
          translate={translate}
          onFromDateChange={this.onFromDateChange}
          onToDateChange={this.onToDateChange}
          fromDate={fromDate}
          toDate={toDate}
        />}
        filterDefaultValues={{
          createdAt_gte: moment().startOf('days').toDate().toISOString(),
          createdAt_lte: moment().endOf('days').toDate().toISOString(),
        }}
        sort={{ field: 'start', order: 'DESC' }}
        {...props}
      >
        <Datagrid>
          <TextField source="username" />
          <TextField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="from"
          />
          <TextField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="to"
          />
          <DateField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            source="start"
            locales="vi-VN"
            showTime
          />
          <FunctionField
            source="duration"
            render={record => {
              return record.duration && moment(moment.duration(record.duration).asMilliseconds()).format('mm:ss')
            }}
          />
          <ReferenceField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            reference="calltypes"
            source="type"
            allowEmpty
            linkType={false}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceManyField
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            label="resources.calllogs.fields.reservations"
            reference="reservations"
            target="callLogId"
            allowEmpty
          >
            <SingleFieldList linkType={false}>
              <FunctionField
                render={record => <Chip
                  label={`${record.code}`}
                  onClick={() => this.pushToReservation(record)} />
                }
              />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceField
            reference="calllogstatuses"
            source="status"
            allowEmpty
            linkType={false}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            render={(record) => {
              let { id, from, to, type } = record
              let phoneNumber = from
              if (type === OUTGOING) {
                phoneNumber = to
              }
              return (
                <>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      this.makeCall(phoneNumber)
                    }}
                  >
                    <CallIcon fontSize="small" />
                  </IconButton>
                  <LinkField
                    path="reservations/company_create"
                    search={`customer_phone=${phoneNumber}&&call_log=${id}`}
                    icon
                  >
                    <Tooltip
                      title={translate('resources.viewtrips.fields.makeReservation')}
                      enterDelay={100}
                    >
                      <SeatIcon fontSize="small" />
                    </Tooltip>
                  </LinkField>
                </>
              )
            }}
          />
        </Datagrid>
      </List>
    </>
  }
}

const enhance = compose(translate, withStyles(styles), connect(null, { push }))
export const CallLogList = enhance(_CallLogList)
