import React, { Component } from 'react'
import compose from 'recompose/compose'
import { ReferenceField, TextField, FunctionField, translate } from 'react-admin'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import { 
  setVehicleType as setVehicleTypeAction,
  updateVehiclesType as updateVehiclesTypeAction
} from './action'
import SeatMap from '../vehicletype/SeatMap'
import Snackbar from '../common/Snackbar'
import isEmpty from 'lodash/isEmpty'

const styles = () => ({
  label: {
    paddingTop: 12,
    paddingBottom: 12,
    paddingRigth: 12,
    paddingLeft: 0,
    fontSize: 13,
    fontWeight: 'bold'
  },
  text: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingRigth: 10,
    paddingLeft: 4,
    fontSize: 13,
  },
  container: {
    display: 'flex'
  }
})


class VehicleTypeForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record, setVehicleTypeAction, updateVehiclesTypeAction } = nextProps
    let currentRecord = prevState.record
    if (record.id !== currentRecord.id) {
      currentRecord = record
      setVehicleTypeAction(currentRecord)
      updateVehiclesTypeAction(currentRecord)
    }
    return {
      record: {...currentRecord}
    }
  }

  render() {
    const { classes, translate } = this.props
    const { record } = this.state
    return !isEmpty(record) ? (
      <>
        <div className={classes.container}>
          <span className={classes.label}>
            {translate('resources.vehicletypes.fields.vehicleClass')}:
          </span>
          <ReferenceField
            record={record}
            basePath="/vehicleclasses"
            reference="vehicleclasses"
            resource="vehicleclasses"
            source="vehicleClass"
            linkType={false}
          >
            <TextField className={classes.text} source="name" />
          </ReferenceField>
        </div>
        <div className={classes.container}>
          <span className={classes.label}>
            {translate('resources.vehicletypes.fields.totalSeat')}:
          </span>
          <TextField className={classes.text} record={record} source="totalSeat" />
        </div>
        {(record && record.seatMap) ? (
          <ReferenceField
            record={record}
            basePath="/seatmaps"
            reference="seatmaps"
            resource="seatmaps"
            source="seatMap"
            linkType={false}
          >
            <FunctionField render={record => {
              return record ? <SeatMap seatMapSelected={record} /> : <span></span>
            }}
            />
          </ReferenceField>
        ) : (
          <Snackbar
            variant="warning"
            message={translate('notification.seat_map_not_exists')}
          />
        )}
      </>
    ) : null
  }
}

VehicleTypeForm.defaultProps = {
  record: {}
}

const mapStateToProps = (state, ownProps) => {
  let { vehicleTypeId } = ownProps
  let vehicletypes = state.admin.resources['vehicletypes'] ? state.admin.resources['vehicletypes'].data : {}
  let record = vehicletypes[vehicleTypeId]
  return { record } || {}

}
const enhance = compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps, { setVehicleTypeAction, updateVehiclesTypeAction })
)

export default enhance(VehicleTypeForm)
