import _ from 'lodash'

export const getCurrentCompany = () => {
  let company = {}
  let userInfoStr = localStorage.getItem('user')
  let userInfo = {}
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr)
    let data = userInfo.data
    company = data ? data.company : {}
  }
  return company
}

export const getCurrentUser = () => {
  let user = {}
  let userInfoStr = localStorage.getItem('user')
  let userInfo = {}
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr)
    let data = userInfo.data
    user = data ? data.user : {}
  }
  return user
}

export const getCurrentAgency = () => {
  let agency = {}
  let userInfoStr = localStorage.getItem('user')
  let userInfo = {}
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr)
    let data = userInfo.data
    agency = data ? data.agency : {}
  }
  return agency
}

export const getCurrentCompanyIdsByAgency = () => {
  let userInfoStr = localStorage.getItem('user')
  let userInfo = {}
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr)
    let { data } = userInfo
    let { agency } = data
    let { companies } = agency || {}
    return companies ? companies.map(ele => ele.id) : []
  }
  return []
}

export const getCurrentAccountId = () => {
  let financeAccountId = 0
  let userInfoStr = localStorage.getItem('user')
  let userInfo = {}
  if (userInfoStr) {
    userInfo = JSON.parse(userInfoStr)
    let data = userInfo.data
    financeAccountId = data ? data.financeAccountId : 0
  }
  return financeAccountId
}

export const getCurrentPairedDevices = () => {
  try {
    let devices = JSON.parse(localStorage.getItem('devices'))
    return devices
  } catch(e) {
    return []
  }
}

export const getCurrentPairedDevice = (type) => {
  try {
    let devices = JSON.parse(localStorage.getItem('devices'))
    let device = _.find(devices, ele => ele.packageName === type)
    return device
  } catch(e) {
    return []
  }
}

export function sanitize(props = {}, unacceptProps = []) {
  unacceptProps.forEach(key => props[key] && delete props[key])
  return props
}

export const getAccessToken = () => {
  return localStorage.getItem('token')
}
