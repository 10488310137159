import React from 'react'
import {
  faRoute,
  faBus,
  faCalendarDay,
  faCube,
  faFileAlt,
  faBell,
  faWrench,
  faBook,
  faCogs,
  faToolbox,
  faUser,
  faClock,
  faTicketAlt,
  faStream,
  faTable,
  faListOl,
  faGripVertical,
  faHandHoldingUsd,
  faDollarSign,
  faTags,
  faHandshake,
  faInfoCircle,
  faCalendarAlt,
  faArrowAltCircleLeft,
  faCommentDollar,
  faThList,
  faHome,
  faChartBar,
  faMapMarkedAlt,
  faMoneyBill,
  faUserCheck,
  faReceipt,
  faFileInvoiceDollar,
  faBriefcase,
  faPrint,
  faAddressCard,
  faMapPin
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  SeatIcon,
  DriverIcon,
  PriceIcon,
  TripIcon,
  AssistantDriverIcon,
} from '../icons'
import CallCenterIcon from '../icons/callcenter'

const Icon = ({ titleAccess, ...props }) => <FontAwesomeIcon {...props} />

export const companyItems = [
  {
    name: 'finance',
    icon: <Icon icon={faDollarSign} />,
    childrens: [
      {
        name: 'accountcredits',
        icon: <Icon icon={faHandHoldingUsd} />,
      },
      {
        name: 'reconciliations',
        icon: <Icon icon={faHandshake} />,
      },
    ]
  },
  {
    name: 'routes',
    icon: <Icon icon={faRoute} />,
  },
  {
    name: 'stops',
    icon: <Icon icon={faMapPin} />,
    childrens: [
      {
        name: 'receiveplaces',
        icon: <Icon icon={faAddressCard} />,
      },
    ],
  },
  {
    name: 'schedules',
    icon: <Icon icon={faClock} />,
  },
  {
    name: 'fares',
    icon: <PriceIcon fontSize="small" />,
    childrens: [
      {
        name: 'fares.prices',
        icon: <Icon icon={faTicketAlt} />,
      },
      {
        name: 'faretables',
        icon: <PriceIcon fontSize="small" />,
      },
      {
        name: 'farecompanions',
        icon: <PriceIcon fontSize="small" />,
      },
      {
        name: 'fareconditions',
        icon: <PriceIcon fontSize="small" />,
      },
    ]
  }, {
    name: 'trips',
    icon: <TripIcon fontSize="small" />,
    childrens: [
      {
        name: 'viewtrips',
        icon: <TripIcon fontSize="small" />,
      }, {
        name: 'timeline',
        icon: <Icon icon={faStream} />,
      }
    ]
  }, {
    name: 'reservations',
    icon: <SeatIcon fontSize="small" />,
    childrens: [
      {
        name: 'reservations',
        icon: <Icon icon={faTable} />,
      },
      {
        name: 'reservations2', 
        text: 'resources.reservations.company_create',
        icon: <Icon icon={faStream} />,
      },
      {
        name: 'saletickets',
        icon: <Icon icon={faTicketAlt} />,
      },
      {
        name: 'saleticketbysegments',
        icon: <Icon icon={faTicketAlt} />,
      },
      {
        name: 'saleseasonickets',
        icon: <Icon icon={faTicketAlt} />,
      },
      {
        name: 'possessions',
        icon: <Icon icon={faBriefcase} />,
      },
    ]
  }, {
    name: 'vehicles',
    icon: <Icon icon={faBus} />,
    childrens: [
      {
        name: 'vehicles',
        icon: <Icon icon={faBus} />,
      }, {
        name: 'vehicletypes',
        icon: <Icon icon={faListOl} />,
      }, {
        name: 'seatmaps',
        icon: <Icon icon={faGripVertical} />,
      }
    ]
  },
  {
    name: 'calllogs',
    icon: <CallCenterIcon fontSize="small" />,
  },
  {
    name: 'drivers',
    icon: <DriverIcon fontSize="small" />,
  },
  {
    name: 'assistantdrivers',
    icon: <AssistantDriverIcon fontSize="small" />,
  },
  {
    name: 'maintainance',
    icon: <Icon icon={faToolbox} />,
    childrens: [
      {
        name: 'licences',
        icon: <Icon icon={faFileAlt} />,
      }, {
        name: 'vehiclemaintains',
        icon: <Icon icon={faWrench} />,
      }, {
        name: 'maintaintemplates',
        icon: <Icon icon={faBook} />,
      }
    ]
  },
  {
    name: 'membercards',
    icon: <Icon icon={faAddressCard} />,
    childrens: [
      {
        name: 'membercards',
        icon: <Icon icon={faAddressCard} />,
      },
      {
        id: 'registration',
        text: 'resources.membercards.registration',
        icon: <Icon icon={faAddressCard} />,
      },
      {
        id: 'recharge',
        text: 'resources.membercards.recharge',
        icon: <Icon icon={faAddressCard} />,
      }
    ]
  },
  {
    name: 'campaigns',
    icon: <Icon icon={faTags} />,
  },
  {
    name: 'customers',
    icon: <Icon icon={faUser} />,
  },
  {
    name: 'configurations',
    icon: <Icon icon={faCogs} />,
  },
  {
    name: 'configurations',
    icon: <Icon icon={faCogs} />,
    childrens: [
      {
        name: 'services',
        icon: <Icon icon={faCalendarDay} />,
      }, {
        name: 'products',
        icon: <Icon icon={faCube} />,
      },
      {
        name: 'ticketlayouts',
        icon: <Icon icon={faCube} />,
      }
    ]
  },
  {
    name: 'reminders',
    icon: <Icon icon={faCalendarDay} />,
  },
  {
    name: 'notifications',
    icon: <Icon icon={faBell} />,
  },
]

export const agencyPosItems = [
  {
    name: 'bar_home',
    icon: <Icon icon={faHome} />
  },
  {
    name: 'bar_sales',
    icon: <Icon icon={faReceipt} />
  }, 
  {
    name: 'menu_tickets',
    icon: <Icon icon={faTicketAlt} />
  },
  {
    name: 'bar_payment',
    icon: <Icon icon={faMoneyBill} />
  },
  {
    name: 'menu_info',
    icon: <Icon icon={faInfoCircle} />
  },
  {
    name: 'menu_commission',
    icon: <Icon icon={faCommentDollar} />
  },
  {
    name: 'menu_reloadconfig',
    icon: <Icon icon={faCogs} />
  },
  {
    name: 'menu_transaction',
    icon: <Icon icon={faFileInvoiceDollar} />
  },
]

export const driverPosItems = [ 
  {
    name: 'bar_sales',
    icon: <Icon icon={faReceipt} />
  },
  {
    name: 'menu_tickets',
    icon: <Icon icon={faTicketAlt} />
  },
  {
    name: 'bar_payment',
    icon: <Icon icon={faMoneyBill} />
  },
  {
    name: 'bar_currenttrip',
    icon: <Icon icon={faRoute} />
  },
  {
    name: 'menu_info',
    icon: <Icon icon={faInfoCircle} />
  },
  {
    name: 'menu_schedule',
    icon: <Icon icon={faCalendarAlt} />
  },
  {
    name: 'bar_check',
    icon: <Icon icon={faUserCheck} />
  },
  {
    name: 'menu_notification',
    icon: <Icon icon={faBell} />
  },
  {
    name: 'menu_reloadconfig',
    icon: <Icon icon={faCogs} />
  },
  {
    name: 'menu_changevehicle',
    icon: <Icon icon={faArrowAltCircleLeft} />
  },
  {
    name: 'bar_reservations',
    icon: <Icon icon={faThList} />
  }, 
  {
    name: 'menu_revenue',
    icon: <Icon icon={faDollarSign} />
  },
  {
    name: 'menu_session',
    icon: <Icon icon={faBriefcase} />
  },
]

export const managerItems = [
  {
    name: 'Home',
    icon: <Icon icon={faHome} />,
  },
  {
    name: 'Chart',
    icon: <Icon icon={faChartBar} />,
  },
  {
    name: 'VehicleList',
    icon: <Icon icon={faBus} />,
  },
  {
    name: 'VehicleTracking',
    icon: <Icon icon={faMapMarkedAlt} />,
  },
  {
    name: 'Profile',
    icon: <Icon icon={faUser} />,
  },
  {
    name: 'InfoApp',
    icon: <Icon icon={faInfoCircle} />,
  },
  {
    name: 'Route',
    icon: <Icon icon={faRoute} />,
  }
]

export const agencyItems = [
  {
    name: 'finance',
    icon: <Icon icon={faDollarSign} />,
    childrens: [
      {
        name: 'accountcredits',
        icon: <Icon icon={faHandHoldingUsd} />,
      },
      {
        name: 'reconciliations',
        icon: <Icon icon={faHandshake} />,
      },
    ]
  },
  {
    name: 'trips',
    icon: <TripIcon fontSize="small" />,
    childrens: [
      {
        name: 'viewtrips',
        icon: <TripIcon fontSize="small" />,
      }, {
        name: 'timeline',
        icon: <Icon icon={faStream} />,
      }
    ]
  }, {
    name: 'reservations',
    icon: <SeatIcon fontSize="small" />,
    childrens: [
      {
        name: 'reservations',
        icon: <Icon icon={faTable} />,
      },
      {
        name: 'reservations2', 
        icon: <Icon icon={faStream} />,
      },
      {
        name: 'saletickets',
        icon: <Icon icon={faTicketAlt} />,
      },

    ]
  }, 
]

export const actionAgency = [
  {
    name: 'autoPrint',
    icon: <Icon icon={faPrint} />,
  },
]

export const actionDriver = [
  {
    name: 'autoPrint',
    icon: <Icon icon={faPrint} />,
  },
  {
    name: 'usePOSSession',
    icon: <Icon icon={faBriefcase} />,
  },
  {
    name: 'cancelTime',
    icon: <Icon icon={faDollarSign} />,
  },
]
