import React, { Component } from 'react'
import {
  GET_LIST,
  translate,
  Datagrid,
  TextField,
  FunctionField,
  DateField,
  ReferenceField,
  ChipField
} from 'react-admin'
import {
  withStyles,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { red, blue, green, grey } from '@material-ui/core/colors'
import _ from 'lodash'

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing.unit * 3
    },
  }
}

const actionStyles = {
  '00CREATE': { color: 'white', backgroundColor: green[500] },
  '10UPDATE': { color: 'white', backgroundColor: blue[500] },
  '20DELETE': { color: 'white', backgroundColor: red[500] },
}

const ActionField = withStyles(actionStyles)(({ classes, ...props }) => {
  let status = _.get(props.record, 'id')
  let className = _.get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})

const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  '10ACT': { color: 'white', backgroundColor: green[500] },
  '50DEP': { color: 'white', backgroundColor: blue[500] },
  '60ARV': { color: 'white', backgroundColor: grey[500] },
  '80CAN': { color: 'white', backgroundColor: red[500] },
}

const StatusField = withStyles(statusStyles)(({ classes, ...props }) => {
  let status = _.get(props.record, 'id')
  let className = _.get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})

class TripHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount = async () => {
    let { record } = this.props
    let rs = await Provider.dataProvider(
      GET_LIST,
      'triphistories',
      {
        filter: { where: { tripId: record.id } },
        sort: { field: 'createdAt', order: 'DESC' },
        pagination: {}
      }
    )

    let historyIds = []
    let historyDatum = {}

    if (rs && rs.data) {
      let histories = rs.data
      for (let i = 0; i < histories.length; i++) {
        let el = histories[i]
        historyIds.push(el.id)
        historyDatum[`${el.id}`] = el
      }
    }
    this.setState({ historyIds, historyDatum })
  }

  render() {
    let { classes, translate } = this.props
    let { historyIds, historyDatum } = this.state

    return (
      <div className={classes.root}>
        <Datagrid
          data={historyDatum}
          ids={historyIds}
          currentSort={{ field: 'createdAt', order: 'DESC' }}
          translate={translate}
        >

          <ReferenceField
            label={translate('resources.actions.fields.action')}
            source="action"
            reference="actions"
            basePath="/actions"
            linkType={false}>
            <ActionField source="name" />
          </ReferenceField>

          <TextField
            label={translate('resources.triphistories.fields.field')}
            source="field"
          />

          <FunctionField
            label={translate('resources.triphistories.fields.oldValue')}
            source="oldValue"
            render={(record) => {
              if (record.oldValue) {
                return (record.field === 'status')
                  ? <ReferenceField
                    record={record}
                    source="oldValue"
                    basePath="/tripstatuses"
                    reference="tripstatuses"
                    linkType={false}
                  >
                    <StatusField source="name" />
                  </ReferenceField>
                  :
                  <TextField
                    record={record}
                    source="oldValue"
                  />
              }
              return
            }}
          />

          <FunctionField
            label={translate('resources.triphistories.fields.newValue')}
            source="newValue"
            render={(record) => {
              return (record.field === 'status')
                ? <ReferenceField
                  record={record}
                  source="newValue"
                  basePath="/tripstatuses"
                  reference="tripstatuses"
                  linkType={false}>
                  <StatusField source="name" />
                </ReferenceField>
                : <TextField record={record} source="newValue" />
            }}
          />

          <TextField
            label={translate('resources.triphistories.fields.desc')}
            source="desc"
          />

          <DateField
            label={translate('resources.triphistories.fields.createdAt')}
            source="createdAt"
            locales="vi-VN"
            showTime
          />
        </Datagrid>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate,
  withStyles(styles),
)

export default enhance(TripHistory)
