import React, { Component } from 'react'
import { managerItems as items } from '../menu/items'
import MenuSetting from './MenuSetting'

class SettingMenuManager extends Component {

  render() {
    let { record } = this.props
    return <MenuSetting 
      items={items}
      record={record}
      hideMenu="manager"
      resources= "companysettings"
    /> 
  }
}

export default SettingMenuManager
