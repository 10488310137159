import React from 'react'
import {
  ReferenceArrayField,
  SingleFieldList,
  FunctionField,
  DateField,
  ReferenceField,
  translate,
} from 'react-admin'
import moment from 'moment'
import { Card, withStyles, CardHeader, CardContent, Divider } from '@material-ui/core'
import { StatusField } from '../reservation'
import compose from 'recompose/compose'
import ReservationPayment from './ReservationPayment'
import PropTypes from 'prop-types'

const styles = () => ({
  container: {
    display: 'flex',
    fontStyle: 'italic'
  },
  label: {
    fontWeight: 'bold'
  },
})

const ReservationInfo = ({ record, translate, classes }) => (
  <Card className={classes.root}>
    <CardHeader
      title={<span>
        <div className={classes.contentItem}>
          <span className={classes.label}>{translate('resources.transactions.fields.reservationId')}:</span>&nbsp;
          <span>{record.code}</span>
        </div>
      </span>}
      subheader={
        <div className={classes.container}>
          <div>{translate('resources.reservations.fields.createdAt')}:</div>&nbsp;
          <DateField record={record} source="createdAt" locales="vi-VN" showTime />
        </div>
      }
    />
    <Divider />
    <CardContent>
      <ReferenceArrayField
        basePath="/trips"
        reference="trips/list"
        source="trips"
        record={record}
        linkType={false}
        resource="reservations"
      >
        <SingleFieldList linkType={false}>
          <FunctionField
            render={record => <div className={classes.contentItem}>
              <span className={classes.label}>{translate('resources.reservations.fields.trips')}:</span>&nbsp;
              <span>{`[${moment(record.departureTime).format('HH:mm DD/MM')}] ${record.name}`}</span>
            </div>}
          />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceField
        basePath="/reservationsources"
        source="source"
        record={record}
        reference="reservationsources"
        linkType={false}
      >
        <FunctionField
          render={record => <div className={classes.contentItem}>
            <span className={classes.label}>{translate('resources.reservations.fields.source')}:</span>&nbsp;
            <span>{record.name}</span>
          </div>}
        />
      </ReferenceField>
      <ReferenceField
        basePath="/agencies"
        source="agencyId"
        record={record}
        reference="agencies/list"
        linkType={false}
      >
        <FunctionField
          render={record => <div className={classes.contentItem}>
            <span className={classes.label}>{translate('resources.reservations.fields.agencyId')}:</span>&nbsp;
            <span>{record.name}</span>
          </div>}
        />
      </ReferenceField>
      <div className={classes.container}>
        <ReferenceField
          basePath="/reservationstatuses"
          record={record}
          source="status"
          reference="reservationstatuses"
          linkType={false}
        >
          <StatusField source="name" />
        </ReferenceField>
        <ReferenceField
          record={record}
          source="id"
          basePath="/reservationpayments"
          reference="reservationpayments"
          resource="reservations"
          linkType={false}
          allowEmpty
        >
          <ReservationPayment reservation={record} />
        </ReferenceField>
      </div>
    </CardContent>
  </Card>
)

ReservationInfo.propTypes = {
  translate: PropTypes.func,
  record: PropTypes.object,
  classes: PropTypes.object,
}

const enhance = compose(translate, withStyles(styles))

export default enhance(ReservationInfo)
