import { put, race, take, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import { GET_SQUARE_GRID, GET_SQUARE_GRID_FAILURE, GET_SQUARE_GRID_SUCCESS,
    GET_JOURNEY, GET_JOURNEY_SUCCESS, GET_JOURNEY_FAILURE
} from './actions'

// ------------------------------------------------------------------------------------------
function* getSquareGrid(action) {
    let { start, done } = action
    start && start()

    const { success, failure } = yield race({
        success: take(GET_SQUARE_GRID_SUCCESS),
        failure: take(GET_SQUARE_GRID_FAILURE),
    })

    if (success) {
        const { payload } = success

        if (payload.data) {
            done && done(payload.data)
        } else {
            yield put(showNotification('Get square grid with no data'));
        }
    }

    if (failure) {
        yield put(showNotification('Cannot get square grid'));
    }
}

export function* requestGetSquareGrid() {
    yield takeEvery(GET_SQUARE_GRID, getSquareGrid)
}

// ------------------------------------------------------------------------------------------
function* getJourney(action) {
    let { start, done } = action
    start && start()

    const { success, failure } = yield race({
        success: take(GET_JOURNEY_SUCCESS),
        failure: take(GET_JOURNEY_FAILURE),
    })

    if (success) {
        const { payload } = success

        if (payload.data) {
            done && done(payload.data)
        } else {
            yield put(showNotification('Get journey with no data'));
        }
    }

    if (failure) {
        yield put(showNotification('Cannot get journey'));
    }
}

export function* requestGetJourney() {
    yield takeEvery(GET_JOURNEY, getJourney)
}