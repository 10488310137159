import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = {
  button: {
    margin: '0.5em',
  },
}

class AlertDialogSlide extends Component {
  state = {
    open: false,
  };

  componentDidMount() {
    let { forwardRef } = this.props
    if (forwardRef) forwardRef.current = this
  }

  open = ({ title, content, onOk, onCancel, onClose }) => {
    this.setState({ open: true, title, content, onOk, onCancel, onClose })
  }

  close = () => {
    this.setState({ open: false, onOk: null })
  }

  render() {
    let { translate } = this.props
    let { title = '', content, onOk, onCancel, onClose } = this.state
    return (
      <div>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.close}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {onCancel && <Button onClick={() => {
              this.close()
              onCancel()
            }}>
              {translate('button.cancel')}
            </Button>}

            {onClose && <Button onClick={() => {
              this.close()
            }}>
              {translate('button.close')}
            </Button>}
            
            <Button onClick={() => {
              this.close()
              if (onOk) onOk()
            }} color="primary">
              {translate('button.ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(AlertDialogSlide)
