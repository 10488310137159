import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import momentJS from 'moment'
import MomentUtils from '@date-io/moment'
import 'moment/locale/vi'
import 'moment/locale/en-gb'
import {
  MuiPickersUtilsProvider,
  DateTimePicker as DP,
  InlineDateTimePicker as IDP
} from 'material-ui-pickers'
import compose from 'recompose/compose'
import { translate, addField, FieldTitle } from 'react-admin'
import Day from './Day'
import { getLunarDateFromMoment } from '../utils/lunar-date'
import isEmpty from 'lodash/isEmpty'
import { Field } from 'redux-form'
import { moment } from './format'

const styles = (theme) => {
  let { unit } = theme.spacing
  return {
    grid: {
      width: '60%',
    },
    date: {
      fontSize: 12
    },
    lunarDate: {
      fontSize: 10,
      color: '#bbb'
    },
    button: {
      width: 6 * unit,
      height: 6 * unit,
      margin: 0,
      padding: 0,
      right: -unit,
    }
  }
}


class DateTimePicker extends Component {

  constructor(props) {
    super(props)
    let { locale, initialValue, input } = props
    let selectedDate = !isEmpty(input.value) ? moment(input.value) : moment(initialValue || new Date())
    this.state = {
      currentLocale: locale,
      selectedDate,
      selectedLunarDate: getLunarDateFromMoment(selectedDate)
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { input } = nextProps
    let { selectedDate: currentSelectedDate } = prevState
    let selectedDate = input.value ? moment(input.value) : currentSelectedDate
    if (currentSelectedDate !== selectedDate) {
      currentSelectedDate = selectedDate
    }
    return {
      selectedDate: currentSelectedDate,
      selectedLunarDate: getLunarDateFromMoment(selectedDate)
    }
  }

  handleDateChange = (k) => {
    let { input } = this.props
    this.setState({ selectedDate : k.toISOString() })
    input.onChange(k.toISOString())
  }

  renderLunarDate = (date, selectedDate, dayInCurrentMonth, props) => {
    let { classes, showLunarDate } = this.props
    let lunarDate = getLunarDateFromMoment(date)
    let selected = selectedDate.isSame(date)
    let now = moment()
    let current = now.format('YYYY-MM-DD') === (date.format('YYYY-MM-DD'))

    let { minDate, disablePast } = props
    let disabled = false
    if (disablePast) {
      disabled = moment(date).isBefore(now)
    } else if (minDate) {
      let min = moment(minDate)
      disabled = moment(date).isBefore(min)
    }

    return (
      <Day
        selected={selected}
        current={current}
        disabled={disabled}
        hidden={!dayInCurrentMonth}
      >
        <span>
          <span className={classes.date}>{moment(date).format('DD')}</span>
          {
            showLunarDate &&
            <React.Fragment>
              <br />
              <span className={classes.lunarDate}>{lunarDate.day !== 1 ? `${lunarDate.day}` : `${lunarDate.day}/${lunarDate.month}`}</span>
            </React.Fragment>
          }
        </span>
      </Day>
    )
  }

  render() {
    const {
      classes,
      input,
      meta = {},
      locale,
      translate,
      dateFormat,
      dateMask,
      showLunarDate,
      picker,
      pickerType,
      keyboard,
      allowEmpty,
      initialValue,
      helperText,
      ...props
    } = this.props
    const { error } = meta
    let { selectedDate } = this.state
    let DatePickerComponent = pickerType === 'inline' ? IDP : DP
    return (
      <MuiPickersUtilsProvider
        utils={MomentUtils}
        locale={locale}
        moment={momentJS}
      >
        <DatePickerComponent
          {...props}
          keyboard={keyboard}
          margin="normal"
          value={selectedDate}
          mask={dateMask ? dateMask : [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]}
          format={dateFormat}
          onChange={this.handleDateChange}
          renderDay={(day, selectedDate, dayInCurrentMonth) => this.renderLunarDate(day, selectedDate, dayInCurrentMonth, props)}
          TextFieldComponent={picker && picker}
          error={!!error}
          helperText={(error || helperText) && <span>{error ? error : helperText}</span>}
        />
      </MuiPickersUtilsProvider>
    )
  }
}

DateTimePicker.defaultProps = {
  keyboard: false,
  showLunarDate: false,
  pickerType: 'inline',
  dateFormat: 'DD/MM/YYYY HH:mm'
}

const enhance = compose(
  translate,
  withStyles(styles),
)

const _DateTimePicker = enhance(DateTimePicker)
export default _DateTimePicker

const enhanceField = compose(
  translate,
  withStyles(styles),
  addField,
)

export const DateTimePickerInput = enhanceField(props => {
  const { resource, source, label, isRequired, classes = {}, ...rest } = props
  return (
    <Field
      name={props.input.name}
      label={label || <FieldTitle
        label={label}
        source={props.input.name}
        resource={resource}
        isRequired={isRequired}
      />}
      KeyboardButtonProps={{
        className: classes.button,
        tabIndex: -1,
      }}
      component={_DateTimePicker}
      {...rest}
    />
  )
})
