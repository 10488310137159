import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  Datagrid,
  DateField,
  translate,
  ReferenceField,
  TextField,
  List,
  Filter,
  ReferenceInput,
  FunctionField,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import CustomToolbar from '../common/CustomToolbarForm'
import { Grid, Typography, withStyles } from '@material-ui/core'
import RefCard from './RefCard'
import * as permission from '../utils/permission'
import { getCurrentCompany, getCurrentAgency } from '../utils/commonUtil'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import { initialize } from 'redux-form'
import { connect } from 'react-redux'
import moment from 'moment'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { formatCurrency } from '../utils/formatUtil'
import SelectInputCustom from '../common/SelectInputCustom'

const styles = {
  transactionContainer: {
    width: '100%',
    marginTop: 32,
  },
  transactionList: {
    boxShadow: 'none',
  },
  transactionTitle: {
    marginBottom: 32
  }
}

const stylePlatformDebitFilter = () => ({
  other: {
    minWidth: 100 
  }
})
class _PlatformDebitFilter extends Component {
  state = {}

  render() {
    const { classes, translate, permissions, ...props } = this.props
    const { fromDate } = this.state
    return <Filter
      {...props}
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
    >
      <DateTimePickerInput
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <DateTimePickerInput
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
        minDate={fromDate}
      />
      <ReferenceInput
        source="status"
        reference="platformdebitstatuses"
        fullWidth
        alwaysOn
        allowEmpty
        label="resources.platformdebits.fields.status"
        classExtra={classes.other}
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.comm.all')}
        />
      </ReferenceInput>
    </Filter>
  }
}

const PlatformDebitFilter = compose(translate, withStyles(stylePlatformDebitFilter))(_PlatformDebitFilter)

class FormSave extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let { currentPathName, initialize, record } = nextProps
    let { currentPathName: prevCurrentPathName } = prevState
    if (currentPathName !== prevCurrentPathName ) {
      prevCurrentPathName = currentPathName
    } else {
      initialize('record-form', record)
    }
    return { currentPathName: prevCurrentPathName }
  }

  render() {
    let { 
      id,
      record,
      permissions,
      translate,
      redirect,
      classes,
      location,
      history,
      match,
      ...props
    } = this.props
    let extra = { resource: 'financeaccounts', fullWidth: true }
    let isAdmin = permission.isAdmin(permissions)
    let company = getCurrentCompany()
    let link = ''
    if (company) {
      link = `/companies/${company.id}`
    } else {
      let agency = getCurrentAgency()
      if (agency) {
        link = `/agencies/${agency.id}`
      }
    }
    redirect = isAdmin ? redirect : link
    return <SimpleForm
      record={record}
      toolbar={<CustomToolbar />}
      redirect={redirect}
      {...props}
      {...extra}
    >
      <Grid container fullWidth>
        <Grid item md={6} xs={6}>
          <TextInput 
            source="accountName"
            {...extra}
            validate={[required(), minLength(6), maxLength(24)]} 
          />
          <TextInput source="accountNumber" {...extra} />
          <TextInput source="bank" {...extra} />
        </Grid>
        {(isAdmin && id) && <Grid item md={6} xs={6}>
          <RefCard accountId={id} record={record} />
        </Grid>}
      </Grid>
      {id && <div className={classes.transactionContainer}>
        <Typography className={classes.transactionTitle} variant="h4">
          {translate('resources.platformdebits.name', { smart_count: 1 })}
        </Typography>
        <List
          className={classes.transactionList}
          resource="platformdebits"
          basePath="/platformdebits"
          location={location}
          history={history}
          match={match}
          actions={null}
          title=" "
          bulkActionButtons={false}
          filters={<PlatformDebitFilter />}
          filterDefaultValues={{
            createdAt_gte: moment().startOf('days').toDate().toISOString(),
            createdAt_lte: moment().add(1, 'weeks').endOf('days').toDate().toISOString(),
            accountId: id,
          }}
        >
          <Datagrid>
            <DateField showTime locales="vi-Vn" source="createdAt"/>
            <ReferenceField
              reference="platformdebitstatuses"
              source="status"
              linkType={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <FunctionField
              source="debit"
              render={record => formatCurrency(record.debit)}
            />
            <FunctionField
              source="credit"
              render={record => formatCurrency(record.credit)}
            />
          </Datagrid>
        </List>
      </div>}
    </SimpleForm>
  }
}

const enhance = compose(
  withStyles(styles),
  withRouter,
  translate,
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
      currentPathName: state.router.location.pathname,
    }),
    { initialize })
)

export default enhance(FormSave)
