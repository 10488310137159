import React, { Component } from 'react'
import compose from 'recompose/compose'
import {
  withStyles,
  Card,
  Typography
} from '@material-ui/core'
import {
  translate,
} from 'react-admin'
import CardIcon from '../common/CardIcon'

const styles = {
  main: {
    flex: '1',
    marginTop: 20,
    cursor: 'pointer',
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: 16,
    minHeight: 52,
  },
  title: {
    minHeight: '3em',
  },
}

class PeriodRevenue extends Component {

  render() {
    let { classes, title, icon, bgColor, value, onClick } = this.props

    return (
      <div className={classes.main} onClick={() => onClick && onClick()}>
        <CardIcon icon={icon} bgColor={bgColor} size="4x" />
        <Card className={classes.card}>
          <Typography className={classes.title} color="textSecondary">
            {title}
          </Typography>

          <Typography variant="h3" gutterBottom>
            {value}
          </Typography>
        </Card>
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(PeriodRevenue)
