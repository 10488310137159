const viettelRegx = /^(096|097|098|0162|0163|0164|0165|0166|0167|0168|0169|086|032|033|034|035|036|037|038|039)\d{7}$/
const vinaRegx = /^(094|091|088|081|082|083|084|085)\d{7}$/
const mobiRegx = /^(090|093|089|070|076|077|078|079)\d{7}$/
const vietnammobiRegx = /^(092|0188|0186|056|058|052)\d{7}$/
const beelineRegx = /^(099|0199|059)\d{7}$/
const mobileRegx = /^(096|097|098|0162|0163|0164|0165|0166|0167|0168|0169|086|032|033|034|035|036|037|038|039|0129|0127|0125|0124|0123|094|091|088|081|082|083|084|085|090|093|0120|0121|0122|0126|0128|089|070|076|077|078|079|092|0188|0186|056|058|099|0199|059|052)\d{7}$/
// const emailRegx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const VIETTEL = 'VIETTEL'
const VINAPHONE = 'VINAPHONE'
const MOBILEPHONE = 'MOBILEPHONE'
const VIETNAMMOBILE = 'VIETNAMMOBILE'
const BEELINE = 'BEELINE'

export function detectmobile (mobile) {
  let result
  let isCheckViettel = viettelRegx.test(mobile)
  if (isCheckViettel) {
    result = VIETTEL
  }
  
  let isCheckVina = vinaRegx.test(mobile)
  if (isCheckVina) {
    result = VINAPHONE
  }
  
  let isCheckMobi = mobiRegx.test(mobile)
  if (isCheckMobi) {
    result = MOBILEPHONE
  }
  
  let isCheckVietnamMobi = vietnammobiRegx.test(mobile)
  if (isCheckVietnamMobi) {
    result = VIETNAMMOBILE
  }
  
  let isCheckBeeLine = beelineRegx.test(mobile)
  if (isCheckBeeLine) {
    result = BEELINE
  }
  return result
}

export function validPhoneNumber(mobile) {
  if (mobileRegx.test(mobile)) {
    return true
  }
  return false
}

// export function validEmail(email) {
//   if (emailRegx.test(email)) {
//     return true
//   }
//   return false
// }
