import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'


////////////////////////////////////////////////////////////////////////////////
// DeviceModel
export const DeviceModelList = props => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid rowClick="edit">
      <TextField source="model" />
      <TextField source="name" />
      <TextField source="category" />
      <TextField source="os" />
      <TextField source="desc" />
    </Datagrid>
  </List>
)

////////////////////////////////////////////////////////////////////////////////
// Create
export const DeviceModelCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit
export const DeviceModelEdit = props => (
  <Edit
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource}  />}
  >
    <FormSave />
  </Edit>
)
