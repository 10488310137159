import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Fuse from 'fuse.js'
import _ from 'lodash'
import { defaultFuzzySearch } from './index'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
} from '@material-ui/core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class FuzzyPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: 0, // which item is selected?
    }
    this.input = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { items: propItems, autoSort = true } = nextProps
    if (propItems !== prevState.propItems) {
      let { itemValue, options } = nextProps
      if (!options) options =  defaultFuzzySearch().options
      let items = propItems
      let { keys = ['key'] } = options
      items.forEach(itemValue)
      items = autoSort ? _.sortBy(items, keys[0]) : items

      let fuse = new Fuse(items, options)

      return { propItems, sortedItems: items, items, fuse }
    }
    return null
  }

  // Move the selected index up or down.
  onMoveUp() {
    if (this.state.selectedIndex > 0) {
      this.selectIndex(this.state.selectedIndex - 1)

    // User is at the start of the list. Should we cycle back to the end again?
    } else if (this.props.cycleAtEndsOfList) {
      this.selectIndex(this.state.items.length - 1)
    }
  }

  onMoveDown() {
    let itemsLength = this.state.items.length - 1
    if (this.state.selectedIndex < itemsLength) {
      this.selectIndex(this.state.selectedIndex + 1)

    // User is at the end of the list. Should we cycle back to the start again?
    } else if (this.props.cycleAtEndsOfList) {
      this.selectIndex(0)
    }
  }

  // handle key events in the textbox
  onKeyDown(event) {
    switch (event.key) {
      // Moving up and down
      // Either arrow keys, tab/shift+tab, or ctrl+j/ctrl+k (what's used in vim sometimes)
      case 'ArrowUp': {
        this.onMoveUp()
        event.preventDefault()
        break
      }
      case 'ArrowDown': {
        this.onMoveDown()
        event.preventDefault()
        break
      }
      case 'j': {
        if (event.ctrlKey) {
          this.onMoveDown()
        }
        break
      }
      case 'k': {
        if (event.ctrlKey) {
          this.onMoveUp()
        }
        break
      }
      case 'Tab': {
        if (event.shiftKey) {
          this.onMoveUp()
        } else {
          this.onMoveDown()
        }
        event.preventDefault()
        break
      }

      case 'Enter': { // Enter key
        let item = this.state.items[this.state.selectedIndex]
        if (item) {
          this.setState({items: this.getInitialItems(this.props)})
          this.props.onChange(item)
          if (this.props.autoCloseOnEnter) {
            this.props.onClose()
          }
        }
        break
      }
      case 'Escape': {
        this.setState({items: this.getInitialItems(this.props)})
        this.props.onClose()
        break
      }
      default:
        break
    }
  }

  // Get the list of initial items.
  // Defaults to none, but the 'showAllItems' property can be enabled
  // to show all items by default.
  getInitialItems(props) {
    if (props.showAllItems) {
      return this.state.sortedItems
    } else {
      return []
    }
  }

  // When the user types into the textbox, this handler is called.
  // Though the textbox is an uncontrolled input, this is needed to regenerate the
  // list of choices under the textbox.
  onInputChanged = ({target: {value}}) => {
    let { convertSearchText } = this.props
    if (value.length) {
      // Pick the closest matching items if possible.
      //let items = this.props.items.filter(item => fuzzysearch(
      //  value.toLowerCase(),
      //  this.props.itemValue(item).toLowerCase()
      //))

      value = convertSearchText ? convertSearchText(value) : value
      let items = this.state.fuse.search(value)
      items = items.reduce(function(acc, item) {
        if (!_.isEmpty(item.matches)) {
          acc.push(item.item)
        }
        return acc
      }, [])
 
      this.setState({items: items.slice(0, this.props.displayCount), selectedIndex: 0})
    } else {
      // initially, show an empty picker or all items.
      this.setState({items: this.getInitialItems(this.props), selectedIndex: 0})
    }
  }

  setInputValue(value) {
    if (!this.input.current) return
    this.input.current.value = value
    this.onInputChanged({ target: { value }})
  }

  // Highlight the given item
  selectIndex(ct) {
    this.props.onChangeHighlightedItem(this.state.items[ct]) // fire a callback
    this.setState({selectedIndex: ct}) // update the state for real
  }

  render() {
    let { isOpen, onClose, onChange, renderItem, label } = this.props
    let { items, selectedIndex } = this.state
    if (isOpen) {
      return <Dialog
        open={isOpen}
        onClose={onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {label}
          <TextField
            inputRef={this.input}
            autoFocus
            fullWidth
            onKeyDown={this.onKeyDown.bind(this)}
            onChange={this.onInputChanged}
            helperText="[tab] or ↑↓ to navigate. [enter] to select. [esc] to dismiss"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </InputAdornment>
              )
            }}
          />
        </DialogTitle>
        <DialogContent>
          <List>
            {items.map((item, ct) => {
              let disabled = _.get(item, 'disabled', false)
              return <ListItem
                selected={ct===selectedIndex}
                button
                key={ct}
                onClick={!disabled && onChange.bind(this, item)}
              >
                <ListItemText primary={renderItem(item)} />
              </ListItem>
            })}
          </List>
        </DialogContent>
      </Dialog>
    }
    return null
  }

}

FuzzyPicker.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  showAllItems: PropTypes.bool,
  label: PropTypes.string,
  displayCount: PropTypes.number,
  cycleAtEndsOfList: PropTypes.bool,
  onChangeHighlightedItem: PropTypes.func,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  autoCloseOnEnter: PropTypes.bool,

  renderItem: PropTypes.func,
  itemValue: PropTypes.func,
}
FuzzyPicker.defaultProps = {
  showAllItems: false,
  label: 'Search', // The text above the searchbox that describes what's happening
  displayCount: 5, // How many items to display at once
  cycleAtEndsOfList: true, // When a user arrows past the end of the list, should the highlight wrap?
  onChangeHighlightedItem(item) {}, // Called when the user highlights a new item
  onChange(item) {}, // Called when an item is selected
  onClose() {}, // Called when the popup is closed
  autoCloseOnEnter: false,

  // By default, the item as its value (ie, each item is a string.)
  renderItem(item) { return item },
  itemValue(item) { return item },
}
