import React from 'react'
import {
  Public as PublicIcon,
  Lock as LockIcon,
  PictureAsPdf as PictureAsPdfIcon,
  Email as EmailIcon,
} from '@material-ui/icons'
import PosIcon from '../icons/pos'
import { faPuzzlePiece, faTicketAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const NO_LIMIT = 'NO_LIMIT'
export const OTHER = 'OTHER'

export const quantityOptions = [
  { id: NO_LIMIT, name: 'resources.products.no_limit' },
  { id: OTHER, name: 'resources.products.other_quantity' },
]

export const SEAT = '00SEAT'
export const SERVICE = '10SERVICE'
export const ADDON = '20ADDON'
export const TICKET = '30TICKET'
export const COMBO = '40COMBO'
export const SEASON = '50SEASON'

export const ProductType = {
  SEAT: '00SEAT',
  SERVICE: '10SERVICE',
  ADDON: '20ADDON',
  TICKET: '30TICKET',
  COMBO: '40COMBO',
  SEASON: '50SEASON',
}

export const PRIVATE_PRODUCT_FARE_TYPE = '00PRV'
export const PUBLISH_PRODUCT_FARE_TYPE = '10PUB'


export const ProductFareTypeIconMapping = {
  [PRIVATE_PRODUCT_FARE_TYPE]: <LockIcon />,
  [PUBLISH_PRODUCT_FARE_TYPE]: <PublicIcon />,
}

export const DELETE = 'DELETE'
export const UPDATE = 'UPDATE'
export const CREATE = 'CREATE'



export const PDF_TICKET_TEMPLATE = '20PDFTEMPLATE'
export const PRINT_TICKET_TEMPLATE = '50PRINTTEMPLATE'
export const EMAIL_TICKET_TEMPLATE = '60EMAILTEMPLATE'

export const ProductTypeIcon = {
  [ADDON]: <FontAwesomeIcon style={{ fontSize: 16 }} icon={faPuzzlePiece} />,
  [TICKET]: <FontAwesomeIcon style={{ fontSize: 16 }} icon={faTicketAlt} />,
}

export const TicketTemplates = [
  { id: PDF_TICKET_TEMPLATE, icon: <PictureAsPdfIcon />, source: 'templatePdfCode' },
  { id: PRINT_TICKET_TEMPLATE, icon: <PosIcon />, source: 'templatePrintCode' },
  { id: EMAIL_TICKET_TEMPLATE, icon: <EmailIcon />, source: 'templateEmailCode' },
]

export const TicketTemplates1 = [
  { id: PDF_TICKET_TEMPLATE, icon: <PictureAsPdfIcon />, source: 'pdfTicketLayoutId' },
  { id: PRINT_TICKET_TEMPLATE, icon: <PosIcon />, source: 'printTicketLayoutId' },
  { id: EMAIL_TICKET_TEMPLATE, icon: <EmailIcon />, source: 'emailTicketLayoutId' },
]
