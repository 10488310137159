import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'

const convertFileToBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

const uploadFile = ({ types, resources }) => {
  return requestHandler => (type, resource, params) => {
    let resrc = resources[resource]
    if (resrc) {
      if (types.indexOf(type) !== -1) {
        const process = async () => {
          let data = params.data
          let { fields } = resrc
          if (fields && !isEmpty(fields)) {
            for (let i = 0; i < fields.length; i++) {
              let field = fields[i]
              if (!isEmpty(data)) {
                if (isArray(data)) {
                  data = await processDatas(data, field)
                } else {
                  data = await processData(data, field)
                }
              }
            }
          }
          return requestHandler(
            type,
            resource,
            {
              ...params,
              data,
            }
          )
        }

        const processDatas = async (datas, field) => {
          let result = []
          for (let i = 0; i < datas.length; i++) {
            let data = datas[i]
            data = await processData(data, field)
            result.push(data)
          }
          return result
        }

        const processData = async (data, field) => {
          let newVal = {}
          let fieldName = field.name
          let isMulti = field.isMulti
          let val = data[fieldName]
          if (val) {
            if (isMulti) {
              newVal = await processFiles(val)
            } else {
              newVal = await processFile(val)
            }
            data = { ...data, [fieldName]: newVal }
          }
          return data
        }

        const processFile = async file => {
          let { rawFile } = file
          if (!rawFile) return file
          if (!rawFile.name) return file
          var base64Picture = await convertFileToBase64(rawFile)
          return {
            src: base64Picture,
            name: rawFile.name,
            type: rawFile.type,
          }
        }

        const processFiles = async (files) => {
          let newfiles = []
          for (var i = 0; i < files.length; i++) {
            let file = files[i]
            let newFile = await processFile(file)
            newfiles.push(newFile)
          }
          return newfiles
        }

        return new Promise((resolve, reject) => {
          process()
            .then(resolve)
            .catch(reject)
        })
      }
    }
    return requestHandler(type, resource, params)
  }
}

export default uploadFile
