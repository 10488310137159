import React, { Component, Fragment } from 'react'
import { deepOrange, green } from '@material-ui/core/colors'
import { formatCurrency } from '../utils/formatUtil'
import { Tooltip } from '@material-ui/core'
import { translate, ChipField } from 'react-admin'
import * as ReservationStatus from '../common/reservation-status'

class ReservationPayment extends Component {
  render() {
    let { record = {}, reservation, translate } = this.props
    record = record && record !== null ? record : {}
    let { total, debt = 0, paid } = record
    let { status } = reservation
    let debtStyle = debt !== 0 ? (
      debt > 0 ? {
        backgroundColor: deepOrange[500],
        color: 'white',
      } : {
        backgroundColor: green[500],
        color: 'white',
      }) : {}
    if (status === ReservationStatus.CANCELLED) {
      return ''
    }

    return <Tooltip
      title={
        <Fragment>
          {[
            { translateKey: 'resources.reservations.totalFare', value: total },
            { translateKey: 'resources.reservations.paid', value: paid },
          ].map(el => {
            return (
              <p key={el.translateKey}>
                {translate(el.translateKey)}: <b>{formatCurrency(el.value)}</b>
              </p>
            )
          })}
          {debt < 0 ? <p>
            {translate('resources.reservations.refund')}: <b>{formatCurrency(Math.abs(debt))}</b>
          </p> : <p>
            {translate('resources.reservations.debt')}: <b>{debt ?formatCurrency(debt) : '0đ'}</b>
          </p>}
        </Fragment>
      }
    >
      <ChipField
        style={debtStyle}
        record={{ debt: debt ? formatCurrency(debt) : '0đ' }}
        source="debt"
      />
    </Tooltip>
  }
}

export default translate(ReservationPayment)
