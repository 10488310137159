import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const EndCallIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" id="svg_2" d="m0,13.366l0.01491,2.576l0.00149,0.004c0.00447,0.588 0.36159,1.008 0.76196,1.004l0.00447,0.004l5.45225,-0.059c0.42124,-0.004 0.75077,-0.463 0.74556,-1.019l-0.01566,-2.578l-0.26467,0.002c0.08276,-1.07 1.05198,-1.323 2.98222,-1.609l4.64556,-0.052c1.11833,0.146 2.20163,0.415 2.27991,1.58l-0.14837,0.002l0.01566,2.573l0.00075,0.003c0.00373,0.594 0.36234,1.012 0.7627,1.006l0.00447,0.006l5.4515,-0.062c0.42124,-0.001 0.74928,-0.462 0.7463,-1.019l-0.01566,-2.575l-0.19683,0.002c-0.19235,-1.566 -2.11961,-6.622 -11.22285,-6.668c-9.44917,-0.048 -11.66123,5.198 -11.77456,6.877l-0.23112,0.002z"/>
  </SvgIcon>
)

export default EndCallIcon