import React, { Component } from 'react'
import {
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  BooleanInput,
  ReferenceInput,
  translate,
  required,
  SimpleForm,
} from 'react-admin'
import CustomToolbar from '../common/CustomToolbarForm'
import { withStyles, Grid } from '@material-ui/core'
import compose from 'recompose/compose'
import { MIN, MAX, validateName } from '../common/validateName'
import { isJsonString } from './utils/ticketEditor'

const stringify = v => {
  if (typeof v === 'object') {
    return JSON.stringify(v, null, 2)
  }
  return v
}

const validate = (values) => {
  const {layout = ''} = values
  const err = {}
  if(layout && !isJsonString(layout)) {
    err.layout = 'sai dinh dang'
  }
  return err
}

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    stopList: {
      padding: 12
    },
    card: {
      padding: 12
    },
    checkbox: {
      display: 'flex'
    }
  })
}

class FormSave extends Component {

  render() {
    const { classes, resource, record, translate, ...props } = this.props
    const extra = { resource, fullWidth: true }
    return (
      <SimpleForm 
        toolbar={<CustomToolbar />}
        validate={validate}
        submitOnEnter={false}
        record={record}
        {...props}
      >
        <Grid container fullWidth>
          <Grid item xs={6}>
            <TextInput
              source="name"
              validate={validateName(MIN, MAX)}
              {...extra}
            />
            <ReferenceInput
              source="group"
              reference="settinggroups"
              validate={[required()]}
              filter={{where : {id: {inq: ['20PDFTEMPLATE', '50PRINTTEMPLATE', '60EMAILTEMPLATE']}}}}
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <SelectInput 
              source="layoutType"
              defaultValue="normal"
              choices={[
                { id: 'normal', name: translate('resources.ticketlayouts.fields.normal') },
                { id: 'dynamic', name: translate('resources.ticketlayouts.fields.dynamic') },
              ]}
              {...extra}
            />
            <TextInput
              source="layout"
              format={ v => { return v ? stringify(v) : ''} }
              multiline
              {...extra}
            />
            <ImageInput
              source="background"
              accept="image/*"
              {...extra}
            >
              <ImageField source="src" />
            </ImageInput>
            <BooleanInput source="default" {...extra} />
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(FormSave)
