import { take, put, race, fork } from 'redux-saga/effects'
import {
  GET_OBJECT_NO_LICENCE,
  FETCH_OBJECT_NO_LICENCE,
  FETCH_OBJECT_NO_LICENCE_FAILURE,
  FETCH_OBJECT_NO_LICENCE_SUCCESS,
  ASSIGN_LICENCE_TO_OBJECT,
  FETCH_ASSIGN_LICENCE_TO_OBJECT,
  FETCH_ASSIGN_LICENCE_TO_OBJECT_SUCCESS,
  FETCH_ASSIGN_LICENCE_TO_OBJECT_FAILURE,
  UNASSIGN_LICENCE_TO_OBJECT,
  FETCH_UNASSIGN_LICENCE_TO_OBJECT,
  FETCH_UNASSIGN_LICENCE_TO_OBJECT_SUCCESS,
  FETCH_UNASSIGN_LICENCE_TO_OBJECT_FAILURE,
  SAVE_REMINDER,
  UPSERT_SERVICE_TASK,
  FETCH_UPSERT_OBJECT,
  FETCH_UPSERT_OBJECT_SUCCESS,
  FETCH_UPSERT_OBJECT_FAILURE,
  doFetchAction,
  doFetchUpSertAction,
  FETCH_RENEWAL_LICENCE_SUCCESS,
  FETCH_RENEWAL_LICENCE_FAILURE,
  RENEWAL_LICENCE,
  FETCH_RENEWAL_LICENCE,
} from './action'
import { showNotification, refreshView } from 'ra-core'

function* handleGetObjectNoLicence() {
  while (true) {
    let { resources, done, error } = yield take(GET_OBJECT_NO_LICENCE)
    let method
    if (resources === 'vehicles') {
      method = 'getVehicleNoLicences'
    } else if (resources === 'drivers') {
      method = 'getDriverNoLicences'
    }
    if (!method) break
    yield put(
      doFetchAction(
        FETCH_OBJECT_NO_LICENCE,
        method,
        'GET',
        [],
        resources
      )
    )
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_OBJECT_NO_LICENCE_SUCCESS),
        failure: take(FETCH_OBJECT_NO_LICENCE_FAILURE)
      })
      if (success) {
        let data = success.payload.data
        done && done(data)
        break
      }
      if (failure) {
        error && error()
        break
      }
    }
  }
}

function* handleAssignLicenceToObject() {
  while (true) {
    let { data, done, error } = yield take(ASSIGN_LICENCE_TO_OBJECT)
    yield put(
      doFetchAction(
        FETCH_ASSIGN_LICENCE_TO_OBJECT,
        'assignLicence',
        'POST',
        data,
        'licences'
      )
    )
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_ASSIGN_LICENCE_TO_OBJECT_SUCCESS),
        failure: take(FETCH_ASSIGN_LICENCE_TO_OBJECT_FAILURE)
      })
      if (success) {
        done()
        yield put(showNotification('notification.assign_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error()
        yield put(showNotification('notification.assign_failure', 'warning'))
        break
      }
    }
  }
}

function* handleUnAssignLicenceToObject() {
  while (true) {
    let { data, done, error } = yield take(UNASSIGN_LICENCE_TO_OBJECT)
    yield put(
      doFetchAction(
        FETCH_UNASSIGN_LICENCE_TO_OBJECT,
        'unAssignLicence',
        'POST',
        data,
        'licences'
      )
    )
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_UNASSIGN_LICENCE_TO_OBJECT_SUCCESS),
        failure: take(FETCH_UNASSIGN_LICENCE_TO_OBJECT_FAILURE)
      })
      if (success) {
        done && done()
        yield put(showNotification('notification.unassign_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error && error()
        yield put(showNotification('notification.unassign_failure', 'warning'))
        break
      }
    }
  }
}

function* handleSaveReminder() {
  while (true) {
    let { data, done, error } = yield take(SAVE_REMINDER)
    let { id } = data
    let fetch = 'CREATE'
    let resource = 'servicereminders'
    let pathParam = ''
    if (id) {
      pathParam = id
      fetch = 'UPDATE'
    }
    yield put(
      doFetchUpSertAction(
        FETCH_UPSERT_OBJECT,
        pathParam,
        data,
        resource,
        fetch
      )
    )
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_UPSERT_OBJECT_SUCCESS),
        failure: take(FETCH_UPSERT_OBJECT_FAILURE)
      })
      if (success) {
        done && done()
        yield put(showNotification('notification.save_service_reminder_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error && error()
        yield put(showNotification('notification.save_service_reminder_failure', 'warning'))
        break
      }
    }
  }
}

function* handleUpsertServiceTask() {
  while (true) {
    let { data, done, error } = yield take(UPSERT_SERVICE_TASK)
    let { id } = data
    let fetch = 'CREATE'
    let resource = 'servicetasks'
    let pathParam = ''
    if (id) {
      pathParam = id
      fetch = 'UPDATE'
    }
    yield put(
      doFetchUpSertAction(
        FETCH_UPSERT_OBJECT,
        pathParam,
        data,
        resource,
        fetch
      )
    )
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_UPSERT_OBJECT_SUCCESS),
        failure: take(FETCH_UPSERT_OBJECT_FAILURE)
      })
      if (success) {
        done()
        yield put(showNotification('notification.save_service_task_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error()
        yield put(showNotification('notification.save_service_task_failure', 'warning'))
        break
      }
    }
  }
}

function* handleRenewal() {
  while (true) {
    let { data, done, error } = yield take(RENEWAL_LICENCE)
    yield put(
      doFetchAction(
        FETCH_RENEWAL_LICENCE,
        'renewal',
        'POST',
        data,
        'licences'
      )
    )
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_RENEWAL_LICENCE_SUCCESS),
        failure: take(FETCH_RENEWAL_LICENCE_FAILURE)
      })
      if (success) {
        done && done()
        yield put(showNotification('notification.renewal_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error && error()
        yield put(showNotification('notification.renewal_failure', 'warning'))
        break
      }
    }
  }
}

export default function* handleLicence() {
  yield fork(handleGetObjectNoLicence)
  yield fork(handleAssignLicenceToObject)
  yield fork(handleUnAssignLicenceToObject)
  yield fork(handleSaveReminder)
  yield fork(handleUpsertServiceTask)
  yield fork(handleRenewal)
}