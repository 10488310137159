import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Grid,
  Typography,
  Divider,
  Chip,
  Tooltip,
  Button,
  List as MUList,
  ListItem,
  ListItemText,
  ListItemIcon,
  TextField as TextInput,
  Stepper,
  Step,
  StepContent,
  StepLabel,
} from '@material-ui/core'
import {
  translate,
  TextField,
  FunctionField,
  ReferenceField,
  DateField,
  SimpleShowLayout,
  Datagrid,
  showNotification,
  NumberField,
  WithPermissions,
} from 'react-admin'
import compose from 'recompose/compose'
import moment from 'moment'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import { faMoneyBill, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import PaymentModal from './PaymentModal'
import { red } from '@material-ui/core/colors'
import { refreshView } from 'ra-core'
import {
  PRE_PAID,
  color as chargeStatusColor,
  StatusField as ChargeStatusField,
} from '../common/charge-status'
import { formatCurrency } from '../utils/formatUtil'
import { reset } from 'redux-form'
import _ from 'lodash'
import { StatusField } from './index'
import { StatusField as TransactionStatusField } from '../transaction/Status'
import { Provider } from '../provider'
import DialogLoading from '../common/DialogLoading'
import EditIcon from '@material-ui/icons/Edit'
import {
  ACTION_CREATE,
  ACTION_UPDATE,
  ACTION_CANCEL,
  ACTION_CHECKIN,
  ACTION_PRINT,
  ReservationStatusMapping 
} from '../common/constants'
import CancelChargeButton from './CancelChargeButton'
import { isCompanyManager  } from '../utils/permission'
import QRCode from 'qrcode.react'

const getActivityDesc = (action) => {
  switch (action) {
    case ACTION_CREATE:
      return 'resources.reservations.activity.create'
    case ACTION_UPDATE:
      return 'resources.reservations.activity.update'
    case ACTION_CANCEL:
      return 'resources.reservations.activity.cancel'
    case ACTION_CHECKIN:
      return 'resources.reservations.activity.checkin'
    case ACTION_PRINT:
      return 'resources.reservations.activity.print'
    default:
      return ''
  }
}

export class _ReservationActivity extends Component {
  render() {
    const { activities, translate } = this.props
    return <Fragment>
      <Typography variant="h5">
        <b>Lịch sử đặt chỗ</b>
      </Typography>
      <Stepper nonLinear={true} orientation="vertical">
        {activities.map((activity, idx) => {
          let { createdAt, action, oldValue, newValue, userId } = activity
          const desc = getActivityDesc(action)
          let old = ReservationStatusMapping[oldValue] 
          let nValue = ReservationStatusMapping[newValue] 
          old = old ? translate(old) : ''
          nValue = nValue ? translate(nValue) : ''
          return <Step active={true} key={idx}>
            <StepLabel icon={<FontAwesomeIcon icon={faClock} />}>
              {moment(createdAt).format('HH:mm DD/MM/YYYY')}
            </StepLabel>
            <StepContent>
              <div key={idx}>{translate(desc, { oldValue: old, newValue: nValue })}</div>
              <div>
                <ReferenceField
                  basePath="users"
                  reference="users"
                  resource="users"
                  source="id"
                  record={{ id: userId }}
                  linkType={false}
                  allowEmpty
                >
                  <FunctionField
                    render={(record) => <span>{record ? `${translate('resources.reservations.activity.createdBy', { createdBy: record.username})}` : ''}</span>}
                  />
                </ReferenceField>
              </div>
            </StepContent>
          </Step>
        })}
      </Stepper>
    </Fragment>
  }
} 

const enhanceReservationActivity = compose(translate)
const ReservationActivity = enhanceReservationActivity(_ReservationActivity)

export class _TicketActivity extends Component {
  render() {
    const { record, translate } = this.props
    const { productCharge = {} } = record
    const { histories: activities = [] } = productCharge
    return <Fragment>
      <Typography variant="h5">
        <b>{translate('resources.common.title_activity')}</b>
      </Typography>
      <Stepper nonLinear={true} orientation="vertical">
        {activities ? activities.map((activity, idx) => {
          let { createdAt, action, oldValue, newValue, userId, deviceId } = activity
          const desc = getActivityDesc(action)
          return <Step active={true} key={idx}>
            <StepLabel icon={<FontAwesomeIcon icon={faClock} />}>
              {moment(createdAt).format('HH:mm DD/MM/YYYY')}
            </StepLabel>
            <StepContent>
              <div key={idx} style={{ fontSize: 16 }}>{translate(desc, { oldValue, newValue })}</div>
              <div>
                <ReferenceField
                  basePath="users"
                  reference="users"
                  resource="users"
                  source="id"
                  allowEmpty
                  record={{ id: userId }}
                  linkType={false}
                >
                  <FunctionField
                    render={(record) => <span>{record ? `${translate('resources.reservations.activity.createdBy', { createdBy: record.username})}` : ''}</span>}
                  />
                </ReferenceField>
                <ReferenceField
                  basePath="devices"
                  reference="devices"
                  resource="devices"
                  source="id"
                  allowEmpty
                  record={{ id: deviceId }}
                  linkType={false}
                >
                  <FunctionField
                    render={(record) => <span>{record ? `${translate('resources.reservations.activity.deviceCheckIn', { serial: record.serial })}` : ''}</span>}
                  />
                </ReferenceField>
              </div>
            </StepContent>
          </Step>
        }) : null}
      </Stepper>
    </Fragment>
  }
} 

const enhanceTicketActivity = compose(translate)
const TicketActivity = enhanceTicketActivity(_TicketActivity)

const styles = (theme) => {
  return ({
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '33.33%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    debt: {
      backgroundColor: red[500],
    },
    gridLeft: {
      //borderRight: '1px solid rgba(0, 0, 0, 0.12)',
      padding: 24,
    },
    gridRight: {
      padding: 24,
    }
  })
}

const fareViewStyle = {
  container: {
    marginTop: 32,
  },
  money: {
    textAlign: 'end',
  },
  paymentButton: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: -18
  },
  endLine: {
    paddingLeft: 118
  },
  buttonIcon: {
    marginRight: 8,
  },
}

//------------------------------------------------------------------------
const _TripView = ({ trip, routeName }) => {
  return <Fragment>
    <Typography variant="h5">
      <b>{routeName}</b>
    </Typography>
    <Grid container spacing={8}>
      <Grid item xs={2}>
        <SimpleShowLayout record={trip} resource="trips">
          <FunctionField 
            source="routeName"
            render={({ routeName: name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} 
          />
          <FunctionField 
            source="distance"
            render={({ distance }) => distance ? `${distance / 1000} (km)` : '0 (km)'}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={2}>
        <SimpleShowLayout basePath="" record={trip} resource="trips">
          <ReferenceField
            source="originId"
            reference="stops"
            allowEmpty
            linkType={false}
          >
            <FunctionField render={({ name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} />
          </ReferenceField>
          <FunctionField
            source="departureTime"
            render={record => {
              let { departureTime } = record
              return departureTime ? moment(departureTime).format('HH:mm DD/MM/YYYY') : ''
            }} 
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={2}>
        <SimpleShowLayout basePath="" record={trip} resource="trips">
          <ReferenceField
            source="destinationId"
            reference="stops"
            allowEmpty
            linkType={false}
          >
            <FunctionField render={({ name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} />
          </ReferenceField>
          <FunctionField
            source="arrivalTime"
            render={record => {
              let { arrivalTime } = record
              return arrivalTime ? moment(arrivalTime).format('HH:mm DD/MM/YYYY') : ''
            }}
          />
        </SimpleShowLayout>
      </Grid>

      <Grid item xs={2}>
        <SimpleShowLayout record={trip} resource="trips">
          <TextField
            label="resources.vehicles.fields.plate"
            source="vehiclePlate"
          />
          <TextField
            label="resources.vehicletypes.fields.name"
            source="vehicleTypeName"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={2}>
        <SimpleShowLayout basePath="" record={trip} resource="trips">
          <ReferenceField
            source="driverId"
            reference="drivers/list"
            allowEmpty
            linkType={false}
          >
            <TextField source="fullName" />
          </ReferenceField>
          <ReferenceField
            source="driver2Id"
            reference="drivers/list"
            allowEmpty
            linkType={false}
          >
            <TextField source="fullName" />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item xs={2}>
        <SimpleShowLayout basePath="" record={trip} resource="trips">
          <ReferenceField
            source="assistantDriverId"
            reference="assistantdrivers/list"
            allowEmpty
            linkType={false}
          >
            <TextField source="fullName" />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  </Fragment>
}

const TripView = translate(_TripView)

//------------------------------------------------------------------------
const _FareView = ({
  totalFare,
  totalDiscount,
  totalAmount,
  onMakeBalanceClicked,
  classes,
  translate,
  debt,
  paid,
  status,
  agencyKeep,
}) => {
  return <Grid container className={classes.container}>
    <Grid item xs={8} md={8}>
      <b>{translate('resources.transactions.fields.total')}</b>
    </Grid>
    <Grid item xs={4} md={4} className={classes.money}>
      <i>{totalFare ? formatCurrency(totalFare) : '0đ'}</i>
    </Grid>
    <Grid item xs={8} md={8}>
      <b>{translate('resources.customers.fields.totalDiscount')}</b>
    </Grid>
    <Grid item xs={4} md={4} className={classes.money}>
      <i>{totalDiscount ? formatCurrency(totalDiscount) : '0đ'}</i>
    </Grid>
    <Grid item xs={8} md={8}>
      <b>{translate('resources.reservations.totalAmount')}</b>
    </Grid>
    <Grid item xs={4} md={4} className={classes.money}>
      <i>{totalAmount ? formatCurrency(totalAmount) : '0đ'}</i>
    </Grid>
    <Divider style={{ width: '100%' }} />
    {(status !== '60EXPIRED' && agencyKeep > 0) && <Fragment>
      <Grid item xs={8} md={8}>
        <b>{translate('resources.reservations.agencyKeep')}</b>
      </Grid>
      <Grid item xs={4} md={4} className={classes.money}>
        <i>{agencyKeep ? formatCurrency(agencyKeep) : '0đ'}</i>
      </Grid>
    </Fragment>}
    <Grid item xs={8} md={8}>
      <b>{translate('resources.reservations.paid')}</b>
    </Grid>
    <Grid item xs={4} md={4} className={classes.money}>
      <i>{paid ? formatCurrency(paid) : '0đ'}</i>
    </Grid>
    {paid === 0 ?
      <Fragment>
        <Grid item xs={8} md={8}>
          <b>{translate('resources.reservations.debt')}</b>
        </Grid>
        {debt <= 0 ?
          <Grid item xs={4} md={4} className={classes.money}> <i>0đ</i></Grid> : debt > 0 && <Fragment>
            <Grid item xs={4} md={4} className={classes.money}>
              <Tooltip title={translate('resources.reservations.makeBalance')} >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onMakeBalanceClicked}
                >
                  <span>
                    <FontAwesomeIcon className={classes.buttonIcon} icon={faMoneyBill} />
                    <span>{debt ? formatCurrency(debt) : '0đ'}</span>
                  </span>
                </Button>
              </Tooltip>
            </Grid>
          </Fragment>}
      </Fragment> : debt < 0 && <Fragment>
        <Grid item xs={8} md={8}>
          <b>{translate('resources.reservations.refund')}</b>
        </Grid>
        <Grid item xs={4} md={4} className={classes.money}>
          <Tooltip title={translate('resources.reservations.refund')} >
            <Button
              variant="contained"
              color="primary"
              onClick={onMakeBalanceClicked}
            >
              <span>
                <FontAwesomeIcon className={classes.buttonIcon} icon={faMoneyBill} />
                <span>{debt && formatCurrency(Math.abs(debt))}</span>
              </span>
            </Button>
          </Tooltip>
        </Grid>
      </Fragment>}
  </Grid>
}

const enhanceFareView = compose(translate, withStyles(fareViewStyle))

const FareView = enhanceFareView(_FareView)

const chargeStatusTranslate = {
  '00NOT_PAID': 'Chưa thanh toán',
  '10PAID': 'Đã thanh toán',
  '30FINISH': 'Đã hoàn thành',
  '20CANCEL': 'Đã huỷ',
}


//let res = getData(passengers, charges)
//let { datum, ids } = res

//const renderCharges = (charges, isPrePaid) => {
//return <>
//{charges && charges.map((charge, idx) => {
//let { itemDetail, status } = charge
//if (isPrePaid) {
//status = chargeStatus.PRE_PAID
//}
//let style = {
//backgroundColor: chargeStatus.color[status],
//color: 'white',
//marginRight: 4,
//width: 60,
//}
//let label = itemDetail ? itemDetail.itemCode : '-'
//if (label.length > 10) {
//label = `${label.substring(0, 7)}...`
//}
//})}
//</>
//}

//const renderTrip = (charges) => {
//let exists = []
//return <>
//{charges && charges.map((charge, idx) => {
//let { tripId } = charge
//let isRender = false
//if (exists.indexOf(tripId) < 0) {
//isRender = true
//exists.push(tripId)
//}
//return isRender ? (
//<ReferenceField
//key={idx}
//record={{ tripId }}
//source="tripId"
//basePath="/trips"
//reference="trips/list"
//linkType={false}
//>
//<TextField source="name" />
//</ReferenceField>
//) : null
//})}
//</>
//}

//<FunctionField
//label={translate('resources.reservations.charges')}
//render={record => renderCharges(record.charges, isPrePaid)}
///>
//<Grid container spacing={8}>
//<Grid item xs={12}>
//<Datagrid
//data={datum}
//ids={ids}
//currentSort={{ field: 'id', order: 'desc' }}
//>
//<TextField
//label={translate('resources.reservations.passenger.firstName')}
//source="firstName"
///>
//<TextField
//label={translate('resources.reservations.passenger.phone')}
//source="phone"
///>
//</Datagrid>
//</Grid>
//</Grid>
const PassengerInfoTable = ({ ids, data, isPrePaid, originId, destinationId }) => {
  return <Datagrid
    resource="reservations"
    currentSort={{ field: 'id', order: 'desc' }}
    ids={ids}
    data={data}
  >
    <TextField label="resources.reservations.passenger.firstName" source="passenger.firstName"/>
    <FunctionField
      label="resources.reservations.pickUpPoint"
      render={({ itemDetail }) => {
        let { pickUpPoint, productType } = itemDetail
        return productType !== '20ADDON' && (pickUpPoint ?
          <ReferenceField
            basePath="/stops"
            record={itemDetail}
            source="pickUpPoint"
            linkType={false}
            reference="stops"
            allowEmpty
          >
            <FunctionField render={({ name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} />
          </ReferenceField> : <ReferenceField
            basePath="/stops"
            source="originId"
            record={{ originId }}
            linkType={false}
            reference="stops"
            allowEmpty
          >
            <FunctionField render={({ name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} />
          </ReferenceField>
        )}}
    />
    <FunctionField
      label="resources.reservations.dropOffPoint"
      render={({ itemDetail }) => {
        let { dropOffPoint, productType } = itemDetail
        return productType !== '20ADDON' && (dropOffPoint ? <ReferenceField
          basePath="/stops"
          record={itemDetail}
          source="dropOffPoint"
          linkType={false}
          reference="stops"
          allowEmpty
        >
          <FunctionField render={({ name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} />
        </ReferenceField> : <ReferenceField
          basePath="/stops"
          source="destinationId"
          record={{ destinationId }}
          linkType={false}
          reference="stops"
          allowEmpty
        >
          <FunctionField render={({ name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''} />
        </ReferenceField>
        )}}
    />
    <ReferenceField
      label="resources.reservations.productType"
      basePath="/producttypes"
      source="itemDetail.productType"
      linkType={false}
      reference="producttypes"
    >
      <TextField source="name" />
    </ReferenceField>
    <FunctionField 
      label="resources.reservations.product"
      source="itemDetail.itemCode"
      render={({ itemDetail, status }) => {
        if (isPrePaid) {
          status = PRE_PAID
        }
        let style = {
          backgroundColor: chargeStatusColor[status],
          color: 'white',
          marginRight: 4,
          width: 60,
        }
        return <Tooltip
          title={chargeStatusTranslate[status]}
          style={{ position: 'absolute', right: 32, top: -12 }}
        >
          <Chip label={itemDetail.itemCode} style={style} />
        </Tooltip>
      }}
    />
    <FunctionField
      label="resources.reservations.amount"
      source="amount"
      render={({ amount }) => formatCurrency(amount)}
    />
  </Datagrid>
}

const getData = (passengers, charges, tripId) => {

  let data = {}
  let ids = []

  if (!passengers || !charges) {
    return { data, ids }
  }
  for (let i = 0; i < charges.length; i++) {
    let charge = charges[i]
    let { id, itemDetail, passengerId, tripId: chargeTripId, cancelReference, amount, status } = charge
    if (chargeTripId === parseInt(tripId) && !cancelReference) {
      let { productType } = itemDetail
      let passenger = _.find(passengers, { id: passengerId })
      charge.passenger = passenger
      if (productType === '20ADDON') {
        charge = {...{}, itemDetail, status, amount }
      }
      ids.push(id)
      data[id] = charge
    }
  }
  return { data, ids }
}

const buildChargeByTrip = (passengers, charges) => {
  let chargeGroupByTrip = _.groupBy(charges, 'tripId')
  let chargeTrip = {} 
  let tripIds = Object.keys(chargeGroupByTrip)
  for (let i = 0; i < tripIds.length; i++) {
    let tripId = tripIds[i]
    if (tripId && tripId !== 'null') {
      chargeTrip[tripId] = getData(passengers, charges, tripId)
    }
  }
  return chargeTrip
}

const buildChargeTicket = (charges) => {
  let chargeTickets = _.reduce(charges, (result, ele) => {
    //let { itemDetail = {} } = ele
    result.push(ele)
    //if (itemDetail.productType === '30TICKET' || itemDetail.productType === '20ADDON') { }
    return result
  }, [])
  return chargeTickets
}

const passengerViewStyle = {
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  table: {
    marginTop: 32
  },
}

const listNoteStyle = {
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  noteText: {
    wordBreak: 'break-all'
  },
  addNoteForm: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    marginTop: 8,
  },
}

class _ListNote extends Component {

state = {}

updateBookingInformationNote = () => {
  let { newestNote } = this.state
  let { bookingInformation = {}, reservationInfo, updateBookingInformation } = this.props
  let { id: bookingInformationId } = bookingInformation
  let { id: reservationId } = reservationInfo
  Provider.dataProvider('REMOTE', 'reservations', {
    method: '/addNote',
    requestMethod: 'POST',
    data: { bookingInformationId, reservationId, note: newestNote }
  }).then(
    (res) => {
      let data = res.data
      this.setState({ newestNote: '' })
      updateBookingInformation(data)
    })
}

onChangeText = evt => {
  let { value } = evt.target
  this.setState({ newestNote: value })
}

render() {
  let { note, classes, translate } = this.props
  let { newestNote } = this.state
  let notes = !_.isEmpty(note) && note.split('\\n')
  return <div>
    <MUList>
      {notes && notes.map((ele, idx) => {
        return <ListItem key={idx}>
          <ListItemIcon><EditIcon /></ListItemIcon>
          <ListItemText className={classes.noteText} primary={ele} />
        </ListItem>
      })}
      <ListItem>
        <div className={classes.addNoteForm}>
          <div>
            <TextInput
              label={translate('resources.reservations.fields.note')}
              fullWidth
              onChange={this.onChangeText}
              value={newestNote}
              multiline={true}
              variant="filled"
            />
          </div>
          <div className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.updateBookingInformationNote}
            >
              {translate('button.save')}
            </Button>
          </div>
        </div>
      </ListItem>
    </MUList>
  </div>
}
}

const enhanceListNote = compose(withStyles(listNoteStyle), translate)
const ListNote = enhanceListNote(_ListNote)

class _PassengerView extends Component {

  render() {
    let { translate, bookingInformation = {}, reservationInfo = {}, classes } = this.props
    let { id, status, createdAt, updatedAt, agencyId, source, device, createdByUser, updatedByUser, qrData } = reservationInfo
    let record = {
      fullName: _.get(bookingInformation, 'contact.fullName', ''),
      phone: _.get(bookingInformation, 'contact.phone', ''),
      email: _.get(bookingInformation, 'contact.email', ''),
      reservationDate: createdAt,
      createdAt,
      updatedAt,
      status,
      id,
      agencyId,
      source,
      note: _.get(bookingInformation, 'note'),
      device,
      createdByUser,
      updatedByUser
    }
    return <Grid container>
      <Grid item xs={12}>
        <Typography variant="h4" className={classes.tripTitle}>
          {translate('resources.reservations.infoDetail')}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={12}>
        <Grid item xs={3} md={3} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <QRCode value={qrData} />
          <p>{translate('resources.reservations.fields.code')}</p>
        </Grid>
      </Grid>
      <Grid item md={6} xs={12}>
        <SimpleShowLayout fullWidth record={record} basePath="/reservations" resource="reservations">
          <TextField source="fullName" />
          <TextField source="phone" />
          <TextField source="email" />
          <DateField source="reservationDate" locales="vi-VN" showTime />
          {createdByUser && <TextField source="createdByUser.username" label="resources.reservations.user.createdBy" />}
          {updatedByUser && <TextField source="updatedByUser.username" label="resources.reservations.user.updatedBy" />}
          {device && <TextField source="device.serial" />}
        </SimpleShowLayout>
      </Grid>
      <Grid item md={6}>
        <SimpleShowLayout fullWidth record={record} basePath="/reservations" resource="reservations">
          {status && <ReferenceField
            reference="reservationstatuses"
            source="status"
            linkType={false}
            allowEmpty
          >
            <StatusField source="name" />
          </ReferenceField>}
          {agencyId && <ReferenceField
            source="agencyId"
            reference="agencies/list"
            linkType={false}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>}
          {source && <ReferenceField
            source="source"
            reference="reservationsources"
            linkType={false}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>}
          { createdAt && <DateField locales="vi-VN" showTime source="createdAt" /> }
          { updatedAt && <DateField locales="vi-VN" showTime source="updatedAt" /> }
        </SimpleShowLayout>
      </Grid>
    </Grid>
  }

}

const passengerViewEnhance = compose(withStyles(passengerViewStyle), translate)
const PassengerView = passengerViewEnhance(_PassengerView)

const passengerTripStyle = {
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  table: {
    marginTop: 32
  },
}

const _PassengerTrips = ({ chargeByTrip, trips, classes, isPrePaid, translate }) => {
  return Object.keys(chargeByTrip).map((tripId, idx) => {
    let { ids, data } = chargeByTrip[tripId]
    trips = Object.values(trips) || []
    let trip = _.find(trips, { id: parseInt(tripId) }) || {}
    let { departureTime, name, originId, destinationId } = trip
    name = name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''
    return <div className={idx > 0 && classes.table} key={idx}>
      <Typography className={classes.tripTitle} variant="h4">
        {`${translate('resources.trips.name', { smart_count: 1 })}: ${moment(departureTime).format('HH:mm DD/MM/YYYY')} ${name}`}
      </Typography>
      <PassengerInfoTable 
        ids={ids}
        data={data}
        isPrePaid={isPrePaid}
        originId={originId}
        destinationId={destinationId}
      />
    </div>
  })
}

const passengerTripsEnhance = compose(withStyles(passengerTripStyle), translate)
const PassengerTrips = passengerTripsEnhance(_PassengerTrips)

const ticketViewStyle = {
  title: {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  textCenter: {
    textAlign: 'center'
  },
  textEnd: {
    textAlign: 'end'
  },
}

class _TicketView extends Component {

  state = {
    tickets: [],
  }

  componentDidMount() {
    const { chargeTickets } = this.props
    this.setState({ tickets: [...chargeTickets] })
  }

  updateTicket = (newData) => {
    const { reload } = this.props
    const { tickets } = this.state
    const index = tickets.findIndex(item => item.id === newData.id)
    tickets[index] = { ...newData } 
    this.setState({ tickets: [...tickets ]})
    reload() 
  }

  render() {
    const { translate, classes, permissions } = this.props
    const { tickets } = this.state
    let chargeTicket = tickets && _.keyBy(tickets, 'id')
    let chargeTicketIds = tickets && tickets.map(ele => ele.id)
    return <Fragment>
      <Typography className={classes.title} variant="h4">{translate('resources.saletickets.ticket')}</Typography>
      <Datagrid 
        data={chargeTicket}
        ids={chargeTicketIds}
        currentSort={{}}
        resource="charges"
        expand={<TicketActivity />}
      >
        <TextField source="id" />
        <FunctionField
          source="itemDetail.productId"
          render={(record) => {
            let productType = _.get(record, 'itemDetail.productType')
            let segmentName = _.get(record, 'itemDetail.segmentName')
            return productType === '60SEAT_BY_SEGMENT' ? <div>{segmentName}</div> : <ReferenceField
              basePath="/charges"
              resource="charges"
              source="itemDetail.productId"
              reference="products"
              allowEmpty
              linkType={false}
              record={record}
            >
              <TextField source="name" />
            </ReferenceField>
          }}
        />
        <ReferenceField
          basePath="/charges"
          resource="charges"
          source="itemDetail.fareId"
          reference="fares"
          allowEmpty
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField 
          source="status"
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          render={(record) => <ChargeStatusField status={record.status} />}
        />
        <NumberField source="amount" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
        <FunctionField 
          source="checkIn"
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          render={(record) => record && record.productCharge ?
            <DateField
              record={record.productCharge}
              source="firstUse"
              locales="vi-VN"
              showTime
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
            /> : ''}
        />
        <FunctionField
          headerClassName={classes.textEnd}
          cellClassName={classes.textEnd}
          render={(record) => {
            const { productCharge = {}, id, status } = record
            const { firstUse }  = productCharge
            const checkIn = Boolean(firstUse)
            const disabled = !isCompanyManager(permissions) || checkIn || status == '20CANCEL'
            return <CancelChargeButton id={id} type="button" disabled={disabled} onDone={this.updateTicket} data={record} />
          }}
        />

      </Datagrid>
    </Fragment>
  }

}

const ticketViewEnhance = compose(withStyles(ticketViewStyle), translate)
const TicketView = ticketViewEnhance(_TicketView)

//------------------------------------------------------------------------
const transactionViewStyle = {
  title: {
    fontWeight: 'bold',
    marginBottom: 16,
  },
  textCenter: {
    textAlign: 'center'
  },
}

const _TransactionView = ({ translate, classes, transactions }) => {

  let datum = {}
  let ids = []
  if (transactions) {
    ids = transactions.map(ele => ele.id)
    datum = _.keyBy(transactions, 'id')
  }

  return <Grid style={{ marginBottom: 32 }} container spacing={8}>
    <Typography
      className={classes.title}
      variant="h4"
    >
      {translate('resources.transactions.name', { smart_count: 2 })}
    </Typography>
    <Grid item xs={12}>
      {ids.length > 0 ? <Datagrid
        data={datum}
        ids={ids}
        currentSort={{ field: 'id', order: 'desc' }}
        resource="transactions"
        basePath="/transactions"
      >
        <DateField locales="vi-VN" showTime source="paidAt" />
        <ReferenceField
          source="paymentMethodId"
          reference="paymentmethods"
          linkType={false}
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="attempt"
        />
        <ReferenceField
          source="type"
          reference="transactiontypes"
          linkType={false}
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="status"
          reference="transactionstatuses"
          linkType={false}
        >
          <TransactionStatusField source="name" />
        </ReferenceField>
        <NumberField source="total" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
        <NumberField 
          source="paid"
          locales="vi-VN"
          options={{ style: 'currency', currency: 'VND' }}
        />
      </Datagrid> : <span style={{ fontSize: 14 }}>{translate('resources.common.no_record')}</span>}
    </Grid>
  </Grid> 
}

const transactionViewEnhance = compose(withStyles(transactionViewStyle), translate)
const TransactionView = transactionViewEnhance(_TransactionView)

const RouteView = ({ record }) => {
  record = record && record !== null ? record : {}
  let { name: routeName } = record
  let trip = { ...record, routeName }
  return <TripView trip={trip} />
}
//------------------------------------------------------------------------
class _ReviewStep extends Component {

  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      paymentModal: {
        open: false,
        record: {},
      },
      chargeByTrip: {},
      chargeTickets: {},
    }
  }

  getPaymentMethod = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'paymentMethods', {
      filter: { code: 'CASH' },
      pagination: {},
      sort: {}
    })
    if (res && res.data) {
      let cashMethod = res.data[0]
      let cashMethodId = cashMethod.id
      return cashMethodId
    }
  }

  static getDerivedStateFromProps = (nextProps, prevState) => {
    let { charges, passengers } = nextProps
    let { charges: currentCharges, chargeByTrip, chargeTickets } = prevState
    if (charges !== currentCharges) {
      chargeByTrip = buildChargeByTrip(passengers, charges)
      chargeTickets = buildChargeTicket(charges)
      return {...prevState, chargeByTrip, chargeTickets}
    }
    return prevState
  }

  componentDidMount = async () => {
    let cashMethodId = await this.getPaymentMethod() 
    this.setState({ cashMethodId })
  }

  onMakeBalanceClicked = () => {
    let { paymentInformation } = this.props
    let { debt, paid } = paymentInformation
    let type
    if (paid === 0) {
      type = '00NORMAL'
    } else if (debt < 0) {
      type = '10REFUND'
    }
    this.setState({
      paymentModal: {
        open: true,
        record: { type, paid: Math.abs(debt) },
      }
    })
  }

  requestOnlinePayment = async (reservationId, amount, source) => {
    let currentHost = window.location.origin
    let pathname = `/reservations/${reservationId}/show`
    let returnUrl = `${currentHost}${pathname}`
    let res = await Provider.dataProvider('REMOTE', 'payments', {
      method: 'card-request',
      requestMethod: 'POST',
      data: {
        reservationId,
        amount,
        source,
        orderType: 'bill payment',
        returnUrl,
      }
    })
    if (res && res.data) {
      let url = _.get(res.data, 'data')
      if (url) {
        window.location.href = url
      }
    }
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  onConfirmMakeBalance = (record) => {
    let { type, paymentMethodCode, paid } = record
    this.startLoading()
    if (type === '10REFUND') {
      paid = paid * -1
      record = { ...record, paid }
    }
    let {
      showNotification,
      reservationInfo,
      isPayLater,
      reload,
      reset,
    } = this.props
    let { id: reservationId } = reservationInfo
    let reqBody = {}
    if (!isPayLater && paymentMethodCode === 'CASH') {
      let { cashMethodId } = this.state
      record = { ...record, paymentMethodId: cashMethodId }
      reqBody.transactions = [record]
    }
    Provider.dataProvider('REMOTE', 'reservations', {
      method: `${reservationId}/quotation`,
      data: reqBody,
    }).then( async res => {
      let reservation = _.get(res.data, 'data', {})
      let { id, source} = reservation
      if (!isPayLater && paymentMethodCode !== 'CASH') {
        await this.requestOnlinePayment(id, paid, source)
      } else {
        reload()
        this.endLoading()
      }
      reset('record-form')
      this.setState({ paymentModal: { open: false } })
    }).catch(e => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      reset('record-form')
      this.endLoading()
    })
  }

  render() {
    let { paymentModal, chargeTickets, chargeByTrip, loading } = this.state
    let {
      classes,
      trips,
      passengers,
      charges,
      transactions,
      bookingInformation = {},
      paymentInformation,
      reservationInfo,
      totalFare,
      totalDiscount,
      routeTripMapping,
      totalAmount,
      updateBookingInformation,
      activities,
      permissions,
      reload,
    } = this.props
    // transaction list excluding pending
    let { status } = reservationInfo
    let { debt, paid, agencyKeep } = paymentInformation
    let firstCharge = charges[0]
    let { itemDetail = {} } = firstCharge
    let { routeId } = itemDetail
    let isPrePaid = false
    if (debt !== 0 && paid !== 0 && paid < totalFare) {
      isPrePaid = true
    }
    transactions = transactions && transactions.filter(v => v.status > '00PENDING')
    return <Fragment>
      <Grid container>
        <Grid item xs={12} md={6}>
          <PassengerView
            passengers={passengers}
            charges={charges}
            isPrePaid={isPrePaid}
            bookingInformation={bookingInformation}
            trips={trips}
            reservationInfo={reservationInfo}
            updateBookingInformation={updateBookingInformation}
            permissions={permissions}
          />
          {(routeTripMapping && !_.isEmpty(routeTripMapping)) ? Object.values(routeTripMapping).map((trip, index) => {
            let { route = {} } = trip
            let { originId, destinationId, distance, name } = route
            trip = { ...trip, distance, originId, destinationId, routeName: name }
            return <TripView
              key={index}
              routeName={name}
              trip={trip}
            />
          }) : <ReferenceField
            basePath="/routes"
            reference="routes"
            allowEmpty
            record={{ routeId }}
            source="routeId"
            linkType={false}
          >
            <RouteView />
          </ReferenceField>}

        </Grid>
        <Grid item xs={12} md={6}>
          <ListNote
            bookingInformation={bookingInformation}
            updateBookingInformation={updateBookingInformation}
            reservationInfo={reservationInfo}
            note={bookingInformation.note}
          />
          {activities && <ReservationActivity activities={activities} />}
        </Grid>
      </Grid>
      <Divider />
      <Grid container>
        <Grid item xs={12} md={12} className={classes.gridLeft}>
          {(chargeByTrip && !_.isEmpty(chargeByTrip)) && <PassengerTrips
            chargeByTrip={chargeByTrip}
            isPrePaid={isPrePaid}
            trips={trips}
          />}
          {(chargeTickets && !_.isEmpty(chargeTickets)) && <TicketView
            chargeTickets={chargeTickets}
            permissions={permissions}
            reload={reload}
          />}

        </Grid>
        <Grid item xs={12} md={12} className={classes.gridRight}>
          <TransactionView transactions={transactions} />
          <PaymentModal
            open={paymentModal.open}
            record={paymentModal.record}
            onClose={() => this.setState({ paymentModal:{ open: false, record: {} } } )}
            onOk={this.onConfirmMakeBalance}
            isBlock={loading}
          />
          <FareView
            totalFare={totalFare}
            totalDiscount={totalDiscount}
            totalAmount={totalAmount}
            debt={debt}
            status={status}
            agencyKeep={agencyKeep}
            paid={paid}
            onMakeBalanceClicked={this.onMakeBalanceClicked}
          />
        </Grid>
      </Grid>
      {loading && <DialogLoading open={loading} />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, {
    push,
    showNotification,
    refreshView,
    reset,
  })
)

const ReviewStep =  enhance(_ReviewStep)

const ReviewStepWithPermission = props => <WithPermissions
  render={({ permissions }) => <ReviewStep permissions={permissions} {...props} />}
/>

export default ReviewStepWithPermission

