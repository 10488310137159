import React, { Component, Fragment } from 'react'
import {
  faHome,
  faBuilding,
  faRoute,
  faBus,
  faCalendarDay,
  faCube,
  faFileAlt,
  faBell,
  faMobile,
  faWrench,
  faBook,
  faCogs,
  faToolbox,
  faUser,
  faClock,
  faMoneyBillAlt,
  faTicketAlt,
  faStream,
  faTable,
  faListOl,
  faGripVertical,
  faHandHoldingUsd,
  faDollarSign,
  faCreditCard,
  faTags,
  faHandshake,
  faBriefcase,
  faAddressCard, 
  faMapPin,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Collapse,
  ListItemIcon,
  ListItemText,
  Menu as MUMenu,
  MenuItem,
  withStyles,
  Typography,
  withWidth,
  Divider,
} from '@material-ui/core'
import ExpandMore from '@material-ui/icons/ExpandMore'
import classnames from 'classnames'
import {
  DashboardMenuItem,
  MenuItemLink,
  WithPermissions,
  translate,
} from 'react-admin'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import compose from 'recompose/compose'
import {
  SeatIcon,
  DriverIcon,
  PriceIcon,
  TripIcon,
  AssistantDriverIcon,
} from '../icons'
import { checkPermission } from '../common/permission'
import CallCenterIcon from '../icons/callcenter'
import PropTypes from 'prop-types'
import _ from 'lodash'
import UserMenu from '../ui/UserMenu'

const Icon = ({ titleAccess, ...props }) => <FontAwesomeIcon {...props} />

export const items = [
  {
    name: 'finance',
    text: 'resources.reports.finance',
    icon: <Icon icon={faDollarSign} />,
    roles: [
      'admin',
      'company-supplier',
      'company-manager',
      'company-employee',
      'agency-manager',
      'agency-employee',
      'accounting',
      'finance-viewer'
    ],
    childrens: [
      {
        name: 'transactions',
        icon: <Icon icon={faMoneyBillAlt} />,
        link: 'transactions',
        roles: ['admin'],
      },
      {
        name: 'accountcredits',
        icon: <Icon icon={faHandHoldingUsd} />,
        link: 'accountcredits',
        roles: [
          'admin',
          'company-manager',
          'agency-manager',
          'company-employee',
          'accounting',
          'finance-viewer'
        ],
      },
      {
        name: 'reconciliations',
        icon: <Icon icon={faHandshake} />,
        link: 'reconciliations',
        roles: [
          'admin',
          'company-manager',
          'agency-manager',
          'company-employee',
          'accounting'
        ],
      },
      {
        name: 'productcharges',
        icon: <Icon icon={faHandHoldingUsd} />,
        link: 'productcharges',
        roles: [
          'admin',
          'company-supplier',
          'company-manager',
          'agency-manager',
          'company-employee',
          'accounting',
          'finance-viewer'
        ],
      },
      {
        name: 'chargereports',
        icon: <Icon icon={faFileAlt} />,
        link: 'chargereports',
        roles: [
          'admin',
          'company-supplier',
          'company-manager',
          'finance-viewer',
          'accounting',
        ],
      },
      {
        name: 'membercardreports',
        icon: <Icon icon={faAddressCard} />,
        link: 'membercardreports',
        roles: [
          'admin',
          'company-manager',
          'accounting',
          'finance-viewer',
        ],
      },
      {
        name: 'einvoices',
        icon: <Icon icon={faTable} />,
        text: 'resources.einvoices.name',
        link: 'einvoices',
        roles: [
          'company-employee',
          'company-manager',
          'accounting'
        ],
      },
    ]
  },
  {
    name: 'companies',
    icon: <Icon icon={faBuilding} />,
    link: 'companies',
    roles: ['admin'],
  },
  {
    name: 'agencies',
    icon: <Icon icon={faBuilding} />,
    link: 'agencies',
    roles: ['admin'],
  },
  {
    name: 'users',
    icon: <Icon icon={faUser} />,
    link: 'users',
    roles: ['admin'],
  },
  {
    name: 'financeaccounts',
    icon: <Icon icon={faCreditCard} />,
    link: 'financeaccounts',
    roles: ['admin'],
  },
  {
    name: 'campaigns',
    icon: <Icon icon={faTags} />,
    link: 'campaigns',
    roles: ['admin', 'company-manager'],
  },
  {
    name: 'devices',
    icon: <Icon icon={faMobile} />,
    roles: ['admin'],
    childrens: [
      {
        name: 'devices',
        link: 'devices',
        roles: ['admin'],
      },
      {
        name: 'devicemodels',
        link: 'devicemodels',
        roles: ['admin'],
      }, {
        name: 'apps',
        link: 'apps',
        roles: ['admin'],
      },
      {
        name: 'appconfigs',
        link: 'appconfigs',
        roles: ['admin'],
      },
    ]
  },
  {
    name: 'routes',
    icon: <Icon icon={faRoute} />,
    link: 'routegroups',
    roles: ['company-manager'],
  },
  {
    name: 'stops',
    icon: <Icon icon={faMapPin} />,
    roles: ['company-manager'],
    childrens: [
      {
        name: 'receiveplaces',
        icon: <Icon icon={faAddressCard} />,
        link: 'receiveplaces',
        roles: ['company-manager'],
      },
    ],
  },
  {
    name: 'schedules',
    icon: <Icon icon={faClock} />,
    link: 'schedules',
    roles: ['company-manager'],
  },
  {
    name: 'fares',
    icon: <PriceIcon fontSize="small" />,
    roles: ['company-manager'],
    childrens: [
      {
        name: 'fares.prices',
        icon: <Icon icon={faTicketAlt} />,
        link: 'fares',
        roles: ['company-manager'],
      },
      {
        name: 'faretables',
        icon: <PriceIcon fontSize="small" />,
        link: 'faretables',
        roles: ['company-manager'],
      },
      {
        name: 'farecompanions',
        icon: <PriceIcon fontSize="small" />,
        link: 'farecompanions',
        roles: ['company-manager'],
      },
      {
        name: 'fareconditions',
        icon: <PriceIcon fontSize="small" />,
        link: 'fareconditions',
        roles: ['company-manager'],
      },
    ]
  }, {
    name: 'trips',
    icon: <TripIcon fontSize="small" />,
    roles: ['agency-manager', 'agency-employee', 'company-manager'],
    childrens: [
      {
        name: 'viewtrips',
        icon: <TripIcon fontSize="small" />,
        link: 'viewtrips',
        roles: ['agency-manager', 'agency-employee', 'company-manager'],
      }, {
        name: 'timeline',
        icon: <Icon icon={faStream} />,
        link: 'trips',
        roles: ['agency-manager', 'agency-employee', 'company-manager'],
      }
    ]
  }, {
    name: 'reservations',
    icon: <SeatIcon fontSize="small" />,
    roles: [
      'company-employee',
      'agency-manager',
      'agency-employee',
      'company-manager',
      'accounting',
      'mornitoring'
    ],
    childrens: [
      {
        name: 'reservations',
        icon: <Icon icon={faTable} />,
        text: 'resources.reservations.list',
        link: 'reservations',
        roles: [
          'agency-employee',
          'agency-manager',
          'company-employee',
          'company-manager',
          'accounting',
          'mornitoring'
        ],
      },
      {
        name: 'reservations2',
        icon: <Icon icon={faStream} />,
        text: 'resources.reservations.company_create',
        link: 'reservations/company_create',
        roles: [
          'company-employee',
          'agency-manager',
          'agency-employee',
          'company-manager',
          'accounting'
        ],
      },
      {
        name: 'saletickets',
        icon: <Icon icon={faTicketAlt} />,
        text: 'resources.saletickets.name',
        link: 'reservations/saletickets',
        roles: [
          'company-employee',
          'agency-manager',
          'agency-employee',
          'company-manager',
          'accounting'
        ],
      },
      {
        name: 'saleticketbysegments',
        icon: <Icon icon={faTicketAlt} />,
        text: 'resources.saleticketbysegments.name',
        link: 'reservations/saleticketbysegments',
        roles: [
          'company-employee',
          'company-manager',
          'accounting'
        ],
      },
      {
        name: 'possessions',
        link: 'possessions',
        icon: <Icon icon={faBriefcase} />,
        text: 'resources.possessions.name',
        roles: [
          'company-employee',
          'company-manager',
          'accounting'
        ],
      },

    ]
  }, {
    name: 'vehicles',
    icon: <Icon icon={faBus} />,
    roles: ['company-manager'],
    childrens: [
      {
        name: 'vehicles',
        icon: <Icon icon={faBus} />,
        link: 'vehicles',
        roles: ['company-manager'],
      }, {
        name: 'vehicletypes',
        icon: <Icon icon={faListOl} />,
        link: 'vehicletypes',
        roles: ['company-manager'],
      }, {
        name: 'seatmaps',
        icon: <Icon icon={faGripVertical} />,
        link: 'seatmaps',
        roles: ['company-manager'],
      }
    ]
  },
  {
    name: 'calllogs',
    icon: <CallCenterIcon fontSize="small" />,
    link: 'calllogs',
    roles: ['company-employee', 'company-manager'],
  },
  {
    name: 'drivers',
    icon: <DriverIcon fontSize="small" />,
    link: 'drivers',
    roles: ['company-manager'],
  },
  {
    name: 'assistantdrivers',
    icon: <AssistantDriverIcon fontSize="small" />,
    link: 'assistantdrivers',
    roles: ['company-manager'],
  },
  {
    name: 'maintainance',
    icon: <Icon icon={faToolbox} />,
    roles: ['company-manager'],
    childrens: [
      {
        name: 'licences',
        icon: <Icon icon={faFileAlt} />,
        link: 'licences',
        roles: ['company-manager'],
      }, {
        name: 'vehiclemaintains',
        icon: <Icon icon={faWrench} />,
        link: 'vehiclemaintains',
        roles: ['company-manager']
      }, {
        name: 'maintaintemplates',
        text: 'resources.maintaintemplates.name',
        link: 'maintaintemplates',
        icon: <Icon icon={faBook} />,
        roles: ['company-manager'],
      }
    ]
  },
  {
    name: 'membercards',
    icon: <Icon icon={faAddressCard} />,
    roles: ['company-manager'],
    childrens: [
      {
        name: 'membercards',
        text: 'resources.membercards.list',
        icon: <Icon icon={faAddressCard} />,
        link: 'membercards',
        roles: ['company-manager'],
      },
      {
        name: 'membercards',
        text: 'resources.membercards.registration',
        icon: <Icon icon={faAddressCard} />,
        link: 'membercards/registration',
        roles: ['company-manager'],
      },
      {
        name: 'membercards',
        text: 'resources.membercards.recharge',
        icon: <Icon icon={faAddressCard} />,
        link: 'membercards/recharge',
        roles: ['company-manager'],
      }
    ]
  },
  {
    name: 'customers',
    icon: <Icon icon={faUser} />,
    link: 'customers',
    roles: ['company-manager'],
  },
  {
    name: 'configurations',
    icon: <Icon icon={faCogs} />,
    roles: ['admin'],
    link: 'configurations',
  },
  {
    name: 'tags',
    icon: <Icon icon={faTags} />,
    roles: ['admin'],
    link: 'tags',
  },
  {
    name: 'configurations',
    icon: <Icon icon={faCogs} />,
    roles: ['company-manager'],
    childrens: [
      {
        name: 'services',
        icon: <Icon icon={faCalendarDay} />,
        link: 'services',
        roles: ['company-manager'],
      },
      {
        name: 'products',
        icon: <Icon icon={faCube} />,
        link: 'products',
        roles: ['company-manager'],
      },
      {
        name: 'ticketlayouts',
        icon: <Icon icon={faCube} />,
        link: 'ticketlayouts',
        roles: ['company-manager'],
      }
    ]
  },
  {
    name: 'reminders',
    icon: <Icon icon={faCalendarDay} />,
    link: 'reminders',
    roles: ['company-employee', 'company-manager'],
  },
  {
    name: 'notifications',
    icon: <Icon icon={faBell} />,
    link: 'notifications',
    roles: ['company-employee', 'company-manager'],
  },
]

const styles = theme => {
  return ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      height: '100%',
      paddingLeft: 4,
    },
    level1: {
      paddingLeft: 48,
      borderLeftWidth: 1,
    },
    containerLevel1: {
      backgroundColor: '#fff',
      borderLeft: '#21757d 3px solid',
    },
    level2: {
      paddingLeft: 48,
      borderLeftWidth: 1,
    },
    containerLevel2: {
      backgroundColor: '#2a3642',
      borderLeft: '#21757d 9px solid',
    },
    level3: {
      paddingLeft: 96,
      borderLeftWidth: 1,
    },
    containerLevel3: {
      backgroundColor: '#2a3642',
      borderLeft: '#21757d 12px solid',
    },
    itemMore: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      color: 'rgba(0, 0, 0, 0.54)',
      transform: 'rotate(-90deg)',
    },
    itemLess: {
      transition: theme.transitions.create(['transform'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      color: 'rgba(0, 0, 0, 0.54)',
      transform: 'rotate(0deg)',
    },
    listItemText: {
      padding: 0,
    },
    titleContainer: {
      textAlign: 'center',
      padding: 8
    },
    parentMenu: {
      minHeight: 26,
    },
    text: {
      color: 'rgba(0, 0, 0, 0.54)',
    }
  })
}

class MenuWithChild extends Component {
  static propTypes = {
    open: PropTypes.bool,
    hide: PropTypes.any,
    path: PropTypes.string,
  }
  state = {
    anchorEl: null,
  }

  handleOpen = (event) => {
    const { open } = this.props
    if (open) {
      this.setState({ openChild: !this.state.openChild })
    } else {
      this.setState({ anchorEl: event.currentTarget })
    }
  }

  handleClose = () => {
    const { open } = this.props
    if (!open) {
      this.setState({ anchorEl: null })
    }
  }

  render() {
    const { classes, item, translate, open, level, path, hide, ...props } = this.props
    const { openChild } = this.state
    return <div>
      <MenuItem
        level={level + 1}
        onClick={this.handleOpen}
        className={classes.parentMenu}
      >
        <ListItemIcon className={classes.icon}>
          {item.icon}
        </ListItemIcon>
        <ListItemText
          className={classes.listItemText}
          inset
          primary={open && <span className={classes.text}>{translate(item.text || `resources.${item.name}.name`, { smart_count: 1 })}</span>}
        />
        {open && <ExpandMore className={classes[openChild ? 'itemLess' : 'itemMore']} />}
      </MenuItem>
      {!open
        ? <MUMenu
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuWithPermission
            level={level + 1}
            items={item.childrens}
            open={true}
            onMenuClick={this.handleClose}
            indent={false}
            path={path}
            hide={hide}
            classes={classes}
            translate={translate}
            {...props}
          />
        </MUMenu>
        : <Collapse
          component="div"
          in={openChild}
          className={classes[`containerLevel${level + 1}`]}
        >
          <MenuWithPermission
            level={level + 1}
            items={item.childrens}
            open={true}
            path={path}
            hide={hide}
            classes={classes}
            translate={translate}
            {...props}
          />
        </Collapse>
      }
    </div>
  }
}

class Menu extends Component {
  static propTypes = {
    onMenuClick: PropTypes.func,
    level: PropTypes.number,
    logout: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
    items: PropTypes.array,
    classes: PropTypes.object,
    translate: PropTypes.func,
    open: PropTypes.bool,
  }
  state = {}

  render() {
    let {
      onMenuClick,
      level,
      translate,
      logout,
      classes,
      open,
      items,
      indent = true,
      width,
      path = '',
      hide,
      permissions,
    } = this.props
    let isXSmall = width === 'xs'
    if (typeof hide == 'undefined') {
      let user = JSON.parse(localStorage.getItem('user') || '{}')
      let hideAgency = _.get(user, 'data.agency.setting.webapp.menu.hide', {})
      let hideCompany = _.get(user, 'data.company.setting.webapp.menu.hide', {})

      if (!_.isEmpty(hideCompany)) {
        hide = _.isEmpty(hideAgency)
          ? { ...hideCompany }
          : Object.keys(hideCompany).reduce((acc, key) => {
            if (hideAgency[key]) acc[key] = 1
            return acc
          }, {})
      } else {
        hide = { ...hideAgency }
      }
      if (_.isEmpty(hide)) hide = false
    }
    return <div className={classes.main}>
      {isXSmall && <Fragment>
        <div className={classes.titleContainer}>
          <Typography variant="h4">Nexbus</Typography>
        </div>
        <Divider />
      </Fragment>}
      {isXSmall && <div>
        <UserMenu logout={logout} isXSmall={isXSmall} />
        <Divider />
      </div>}
      {level === 0 && <div>
        <DashboardMenuItem
          onClick={onMenuClick}
          leftIcon={<Icon icon={faHome} />}
          primaryText={open && translate('resources.home.name')}
        />
      </div>
      }
      {items.map(item => {
        if (!checkPermission(permissions, item.roles)) return null
        let _path = hide ? `${path}/${item.name}` : ''
        if (hide && _path in hide) return null
        if (item.link) {
          return <div key={item.name}>
            <MenuItemLink
              to={`/${item.link}`}
              primaryText={open && translate(item.text || `resources.${item.name}.name`, { smart_count: 1 })}
              leftIcon={item.icon}
              onClick={onMenuClick}
              path={_path}
              className={indent ? classnames(classes[`level${level}`]) : ''}
            />
          </div>
        }
        if (item.childrens) {
          return (
            <MenuWithChild
              open={open}
              classes={classes}
              key={item.name}
              item={item}
              level={level}
              path={_path}
              hide={hide}
              translate={translate}
            />
          )
        }
        return null
      })}
    </div>
  }
}

const MenuWithPermission = ({ ...props }) => <WithPermissions render={({ permissions }) => <Menu permissions={permissions} {...props} />} />


const enhance = compose(
  withRouter,
  withWidth(),
  connect(
    state => ({
      theme: state.theme,
      locale: state.i18n.locale,
      items,
      level: 0,
    }),
  ),
  translate,
  withStyles(styles, { name: 'LeftMenu' }),
)

export default enhance(MenuWithPermission)
