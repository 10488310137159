import React, { Component } from 'react'
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  required,
  TextInput,
  translate,
  minValue,
} from 'react-admin'
import { Provider } from '../provider'
import CustomToolbar from '../common/CustomToolbarForm'
import { MoneyInput } from '../common/MoneyInput'
import SelectCustom from '../common/SelectCustom'
import { validate } from './validate'

class ProductRouteForm extends Component {

  state = {}

  componentDidMount() {
    this.loadProductFareType()
  }

  loadProductFareType = async () => {
    let productFareTypeResp = await Provider.dataProvider('GET_LIST', 'productfaretypes', {
      sort: {},
      pagination: {},
    })
    if (productFareTypeResp && productFareTypeResp.data) {
      this.setState({ productFareTypes: productFareTypeResp.data })
    }
  }

  onSave = record => {
    let { action, productId } = this.props
    record = { ...record, productId }
    if (action === 'edit') {
      this.edit(record)
    } else if(action === 'create') {
      this.create(record)
    }
  }

  create = record => {
    let { onDone, onError } = this.props
    Provider.dataProvider('REMOTE', 'routeproducts', {
      method: 'registerRoute',
      requestMethod: 'POST',
      data: record
    }).then((res) => {
      onDone && onDone(res.data)
    }).catch(() => {
      onError && onError()
    })
  }

  edit = record => {
    let { onDone, onError } = this.props
    Provider.dataProvider('REMOTE', 'routeproducts', {
      method: 'registerRoute',
      requestMethod: 'POST',
      data: record
    }).then((res) => {
      onDone && onDone(res.data)
    }).catch(() => {
      onError && onError()
    })
  }

  render() {
    let { form, record, translate } = this.props
    let { productFareTypes } = this.state
    return <SimpleForm
      record={record}
      form={form}
      basePath="/routeproducts"
      resource="routeproducts"
      save={this.onSave}
      validate={validate}
      toolbar={<CustomToolbar />}
    >
      <ReferenceInput
        reference="routes"
        source="routeId"
        fullWidth
        perPage={1000}
        validate={required()}
        filter={{ 
          status: { neq: '20ARCHIVED' },
          '../isBusTour': true,
          '../fields': ['id', 'name', 'routeGroupId'],
        }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ArrayInput fullWidth source="fares">
        <SimpleFormIterator>
          <TextInput
            fullWidth
            label="resources.routeproducts.fields.fares.code"
            source="code"
          />
          <TextInput
            fullWidth
            label="resources.routeproducts.fields.fares.name"
            source="name"
            validate={required()}
          />
          <MoneyInput 
            label="resources.routeproducts.fields.fares.amount"
            source="amount"
            fullWidth 
            validate={minValue(0)}
          />
          <SelectCustom
            optionText="name"
            source="type"
            defaultValue='00PRV'
            isTranslateText={false}
            fullWidth
            label={translate('resources.routeproducts.fields.fares.type')}
            choices={productFareTypes}
          />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  }
}
export default translate(ProductRouteForm)
