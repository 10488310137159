import moment from 'moment'

export function validate(values, props) {
  let errors = {}
  let { translate } = props

  // Validate validFrom
  let validFrom = (values && values.validFrom) && moment(values.validFrom)
  let validTo = (values && values.validTo) && moment(values.validTo)
  if (validFrom && validTo) {
    if (validTo.diff(validFrom, 'days') < 0) {
      errors.validFrom = translate('error_messages.invalid.drivers.validFrom')
    }
    if (validFrom.diff(validTo, 'days') > 0) {
      errors.validTo = translate('error_messages.invalid.drivers.validTo')
    }
  }

  let number = values && values.licenceNumber && values.licenceNumber.trim()
  if (!number || number.length === 0) {
    errors.licenceNumber = translate('error_messages.required.licences.number')
  }
  return errors
}
