import React, { Component } from 'react'
import { translate, addField } from 'react-admin'
import {
  withStyles,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { isArray } from 'util'

const styles = () => ({
  formControl: {
    marginBottom: 8,
    marginTop: 32,
  }
})

class RadioButtonGroupInput extends Component {

  componentDidMount() {
    let { defaultValue, otherChoices, defaultOption, choices, input, optionValue } = this.props
    let field = optionValue
    let value
    if (defaultValue) {
      value = defaultValue
    } else if (defaultOption) {
      field = defaultOption.field
      value = defaultOption.value
    }
    if (!value) return
    let fullChoices = [...otherChoices, ...choices]
    if (fullChoices && fullChoices.length > 0) {
      for (let i = 0; i < fullChoices.length; i++) {
        let choice = fullChoices[i]
        if (choice[field] === value) {
          let valueInput = choice[optionValue]
          input = { ...input, value: valueInput }
          input.onChange(valueInput)
        }
      }
    }
  }

  onChange = e => {
    let { input, choices, optionValue } = this.props
    // temp
    const first = choices[0][optionValue]
    let typeOf = typeof first

    let { value } = e.target
    value = typeOf === 'number' ? parseInt(value) : value
    input = { ...input, value }
    input.onChange(value)
  }

  isRender = (choice) => {
    let { filter } = this.props
    if (filter && filter.length) {
      for (let i = 0; i < filter.length; i++) {
        let { field, value } = filter[i]
        if (isArray(value)) {
          if (value.indexOf(choice[field]) === -1) return false
        } else {
          if (choice[field] !== value) return false
        }
      }
    }
    return true
  }

  render() {
    const {
      classes,
      choices = [],
      label,
      optionValue = 'id',
      optionText,
      fullWidth,
      translate,
      input,
      isTranslateText,
      otherChoices,
      row = false,
    } = this.props
    return <FormControl className={classes.formControl} margin="none" fullWidth={fullWidth}>
      <FormLabel component="legend">{translate(label)}</FormLabel>
      <RadioGroup row={row} value={input.value} onChange={this.onChange}>
        {(otherChoices && otherChoices.length > 0) && otherChoices.map((choice, key) => this.isRender(choice) && (
          <FormControlLabel
            key={key}
            value={choice[optionValue] && choice[optionValue]}
            control={<Radio />}
            label={isTranslateText ? translate(choice[optionText]) : choice[optionText]}
          />
        ))}
        {choices.map((choice, key) => this.isRender(choice) && (
          <FormControlLabel
            key={key}
            value={choice[optionValue] && choice[optionValue]}
            control={<Radio />}
            label={isTranslateText ? translate(choice[optionText]) : choice[optionText]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  }
}

RadioButtonGroupInput.defaultProps = {
  optionValue: 'id',
  optionText: 'name',
  choices: [],
  isTranslateText: true
}

const enhance = compose(
  translate,
  withStyles(styles),
  addField
)

export default enhance(RadioButtonGroupInput)
