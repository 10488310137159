import React, { Component } from 'react'
import { 
  Tabs,
  Tab,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  withStyles,
} from '@material-ui/core'
import { addField, translate } from 'react-admin'
import compose from 'recompose/compose'
import isEmpty from 'lodash/isEmpty'

const style = {
  paper: {
    maxHeight: 360,
    overflowY: 'scroll',
    boxShadow: 'none'
  },
  empty: {
    padding: 4,
    fontSize: 14,
    fontStyle: 'Italic',
  }
}
class PassengerCollectionTabs extends Component {

  constructor(props) {
    super(props)
    let { pickUpPoints, dropOffPoints } = props
    let passengerCollection = { pickUpPoint: pickUpPoints[0] && pickUpPoints[0].id.toString(), dropOffPoint: dropOffPoints[0] && dropOffPoints[0].id.toString() }
    this.state = {
      idx: 0,
      passengerCollection,
    }
  }
  
  handleChange = (evt, idx) => {
    this.setState({ idx: idx })
  }

  handleSelectPassengerCollection = (event) => {
    let { idx, passengerCollection } = this.state
    let { input } = this.props
    let value = event.target.value
    if (idx === 0) {
      passengerCollection = {...passengerCollection, pickUpPoint: value }
    }
    if (idx === 1) {
      passengerCollection = {...passengerCollection, dropOffPoint: value }
    }
    input.onChange(passengerCollection)
    this.setState({ passengerCollection })
  }

  render() {
    let { idx, passengerCollection } = this.state
    let { pickUpPoint, dropOffPoint } = passengerCollection 
    let { pickUpPoints, dropOffPoints, translate, classes } = this.props
    return <>
      <Tabs
        value={idx}
        onChange={this.handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
        aria-label="full width tabs example"
      >
        <Tab label={translate('resources.charges.pickUpPoint')} />
        <Tab label={translate('resources.charges.dropOffPoint')} />
      </Tabs>
      {idx === 0 && (
        !isEmpty(pickUpPoints) ? <Paper className={classes.paper}>
          <RadioGroup value={pickUpPoint} onChange={this.handleSelectPassengerCollection}>
            {pickUpPoints && pickUpPoints.map((pickUpPoint, idx) => {
              return <FormControlLabel
                key={idx} 
                value={pickUpPoint.id.toString()}
                label={pickUpPoint.name}
                control={<Radio />}
              />
            })}
          </RadioGroup> 
        </Paper>: <span className={classes.empty}>{translate('notification.pickup_point_empty')}</span>
      )}
    {idx === 1 && (
      !isEmpty(dropOffPoints) ? <Paper className={classes.paper}>
        <RadioGroup value={dropOffPoint} onChange={this.handleSelectPassengerCollection}>
          {dropOffPoints && dropOffPoints.map((dropOffPoint, idx) => {
            return <FormControlLabel
              key={idx} 
              value={dropOffPoint.id.toString()}
              label={dropOffPoint.name}
              control={<Radio />}
            />
          })}
        </RadioGroup>
      </Paper> : <span className={classes.empty}>{translate('notification.drop_off_point_empty')}</span>
    )}
    </>
  }
}

const enhance = compose(addField, translate, withStyles(style))
export default enhance(PassengerCollectionTabs)
