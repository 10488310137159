import React, { Component } from 'react'
import UserContact from './UserContact'
import {
  withStyles,
  Drawer,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'

const styles = () => ({
  drawerPaper: {
    width: 400,
    overflowY: 'hidden'
  },
  gridFlexCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 18px',
    paddingBottom: '0'
  }
})

class UserContactDrawer extends Component {
  render() {
    const {
      userContacts,
      translate,
      eventTypes,
      userContact,
      classes,
      onEdit,
      admin,
      onClose,
      open,
      removeUserSub,
      userContactIdx,
      addUserSub,
      errors,
      action,
    } = this.props
    return (
      <Drawer
        anchor='right'
        open={open}
        classes={{ paper: classes.drawerPaper, }}
        onClose={() => onClose()}
      >
        <div className={classes.gridFlexCenter}>
          <span>
            {
              action === 'edit'
                ? translate('resources.users.userContacts.editUserContact')
                : translate('resources.users.userContacts.createUserContact')
            }
          </span>
        </div>

        <UserContact
          userContactIdx={userContactIdx}
          onClose={onClose}
          eventTypes={eventTypes}
          userContact={userContact}
          errors={errors}
          onEdit={onEdit}
          admin={admin}
          userContacts={userContacts}
          addUserSub={addUserSub}
          removeUserSub={removeUserSub}
        />
      </Drawer>
    )
  }
}

export default compose(
  translate,
  withStyles(styles)
)(UserContactDrawer)
