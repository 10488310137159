import React, { Component, Fragment } from 'react'
import {
  Title,
  translate,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  showNotification,
  WithPermissions,
  required,
} from 'react-admin'
import {
  Card,
  withStyles,
  Grid,
  Typography,
  ListSubheader,
  ListItemText,
} from '@material-ui/core'
import compose from 'recompose/compose'
import RegistrationToolbar from './RegistrationToolbar'
import { Provider } from '../provider'
import { change as changeForm } from 'redux-form'
import _ from 'lodash'
import { connect } from 'react-redux'
import { changeBreadcrumb } from '../breadcrumb/action'
import { DatePickerInput } from '../common/DatePicker'
import * as permission from '../utils/permission'
import RadioGroupInputCustom from '../common/RadioGroupInputCustom'
import SelectInputCustom from '../common/SelectInputCustom'
import { formatCurrency } from '../utils/formatUtil'
import { FuzzySelectArrayInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import voca from 'voca'

const productInfomationStyle = {
  noPaddingVertical: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  subItem: {
    marginLeft: 40,
  },
  item: {
    fontSize: 15,
  },
  fuzzy: {
    margin: '16px 0px 8px',
  },
}

class _ProductInformation extends Component {

  state = {
    fares: [],
    currentRouteId: 0,
    amount: 0,
    segments: [],
    stops: [],
  }

  async componentDidMount() {
    await this.getStops()
  }

  getStops = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'stops', {
      filter: {
        where: {
          type: '50RECEIVE',
        },
        include: [
          {
            relation: 'parent',
            scope: { fields: ['id', 'name'] },
          },
        ],
      },
      sort: { field: 'parentId', order: 'DESC'},
      pagination: {}
    })
    if (res && res.data) {
      const stops = _.reduce(res.data, (result, stop) => {
        let { id, name, parentId, parent = {} } = stop
        let value = { id, name, isParent: !parentId, parent, parentId, }
        result.push(value)
        return result
      }, [])
      this.setState({ stops })
    }
  }

  getFares = async (fareIndexId) => {
    let res = await Provider.dataProvider('REMOTE', 'fares', {
      method: 'getFaresByFareIndexId',
      requestMethod: 'GET',
      data: {
        fareIndexId,
      },
      sort: {},
      pagination: {}
    })
    if (res && res.data) {
      const fares = _.reduce(res.data, (result, item) => {
        let { id, name, amount } = item
        result.push({ id, name: `${name} (${formatCurrency(amount)})` })
        return result
      }, [])
      this.setState({ fares })
    }
  }

  getFareIndexs = async (routeProductId) => {
    let res = await Provider.dataProvider('REMOTE', 'farecompaniondetails', {
      method: 'getSegments',
      requestMethod: 'GET',
      data: {
        routeProductId
      },
      sort: {},
      pagination: {}
    })
    if (res && res.data) {
      const segments = _.reduce(res.data, (result, item) => {
        let { origin, destination, fareIndexId, id } = item
        let segment = {
          id,
          name: `${_.get(origin, 'name', '')} - ${_.get(destination, 'name', '')}`,
          fareIndexId
        }
        result.push(segment)
        return result
      }, [])
      this.setState({ segments, routeProductId, })
    }
  }

  onChangRoute = async (evt, value) => {
    let { routes } = this.props
    const { currentRouteId } = this.state

    if (currentRouteId !== value) {
      const route = routes.find(item => item.id === Number(value))
      await this.getFareIndexs(route.routeProductId)
      this.setState({ currentRouteId: value })
    }
  }

  onChangeFareIndex = async (e, value) => {
    let { changeForm } = this.props
    const { currentFareIndexId, segments = [] } = this.state
    const segmentId = Number(value)
    const segment = segments.find(item => item.id === segmentId)
    const fareIndexId = _.get(segment, 'fareIndexId')
    if (fareIndexId && currentFareIndexId !== fareIndexId) {
      await this.getFares(fareIndexId)
      changeForm(SALE_TICKE_FORM, 'fareIndexId', fareIndexId)
      changeForm(SALE_TICKE_FORM, 'segmentId',segmentId)
      this.setState({ currentFareIndexId: fareIndexId })
    }
  }

  render() {
    let {
      classes,
      translate,
      routes,
    } = this.props
    let { segments, fares, stops, } = this.state
    const extra = { fullWidth: true, resource: 'membercards' }
    return <Fragment>
      <Typography variant="h4" >
        {translate('resources.saletickets.routeInfo')}
      </Typography>
      <Grid container>
        <Grid item md={12} xs={12} className={classes.noPaddingVertical}>
          <RadioGroupInputCustom
            row={true}
            choices={routeTypeOptions}
            source="routeType"
            validate={required()}
            {...extra}
          />
        </Grid>
        <FormDataConsumer>
          {( { formData }) => {
            const { routeType } = formData
            return <Fragment>
              <Grid item md={6} xs={12} className={classes.noPaddingVertical}>
                {routeType !== MULTI_ROUTE ? <SelectInputCustom
                  label="resources.saletickets.route"
                  choices={routes}
                  onChange={this.onChangRoute}
                  source="routeId"
                  validate={required()}
                  {...extra}
                /> : null}
                {routeType !== MULTI_ROUTE ? <FuzzySelectArrayInput
                  label={translate('resources.saletickets.segment')}
                  source="segmentId"
                  disabled={_.isEmpty(segments)}
                  items={segments}
                  renderItem={({ name }) => name }
                  onChange={this.onChangeFareIndex}
                  {...defaultFuzzySearch({ name: 'name' })}
                  {...extra}
                />: null}
                <FuzzySelectArrayInput
                  label={translate('resources.saletickets.receive_member_card_place')}
                  source="stopId"
                  items={stops}
                  autoSort={false}
                  renderItem={(record) => {
                    let isParent = _.get(record, 'isParent', false)
                    let text = _.get(record, 'name', '')
                    return isParent ? <ListSubheader>{text}</ListSubheader>
                      : <ListItemText className={classes.subItem}>{text}</ListItemText>
                  }}
                  options = {{
                    tokenize: true,
                    includeMatches: true,
                    threshold: 0,
                    keys: ['searchText'],
                  }}
                  groupData={(items) => {
                    return _.reduce(items, (result, value) => {
                      let parentId = _.get(value, 'parentId')
                      if (parentId) {
                        let idx = _.findIndex(result, item => _.get(item, 'id') === parentId)
                        if (idx >= 0 ) {
                          result.splice(idx+1, 0, value)
                          result.join()
                        }
                      } else {
                        result.push({ ...value, disabled: true })
                      }
                      return result
                    }, [])
                  }}
                  itemValue={(item) => {
                    if (!item.textName) {
                      const re = new RegExp(/[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/, 'g')
                      let parentId = _.get(item, 'parentId')
                      let parentName = _.get(item, 'parent.name','').toLowerCase().split(re).join('')
                      let itemName = _.get(item, 'name','').toLowerCase().split(re).join('')
                      let searchText = parentId ? `${parentName}${itemName}` : `${itemName}`
                      item.searchText = voca.latinise(searchText)
                    }
                    return item
                  }}
                  {...extra}
                />
              </Grid>
              <Grid item md={6} xs={12} className={classes.noPaddingVertical}>
                <RadioGroupInputCustom
                  validate={required()}
                  choices={fares}
                  defaultValue={_.get(fares, '0.id')}
                  source="fareId"
                  {...extra}
                />
              </Grid>
            </Fragment>
          }}
        </FormDataConsumer>
      </Grid>
    </Fragment>

  }
}

const enhanceProductInformation = compose(withStyles(productInfomationStyle), translate)
const ProductInformation = enhanceProductInformation(_ProductInformation)

const customerInfomationStyle = {
  noPaddingVertical: {
    paddingBottom: 0,
    paddingTop: 0,
  },
}

const ONE_ROUTE = 'ONE_ROUTE'
const MULTI_ROUTE = 'MULTI_ROUTE'

export const routeTypeOptions = [
  { id: ONE_ROUTE, name: 'resources.saletickets.one_route' },
  { id: MULTI_ROUTE, name: 'resources.saletickets.multi_route' },
]

class _CustomerInformation extends Component {

  getCampain = async (fareIndexId) => {
    let res = await Provider.dataProvider('REMOTE', 'fares', {
      method: 'getFaresByFareIndexId',
      requestMethod: 'GET',
      data: {
        fareIndexId
      },
      sort: {},
      pagination: {}
    })
    if (res && res.data) {
      this.setState({ fares: res.data })
    }
  }

  render() {
    let { classes, translate } = this.props
    let extra = { fullWidth: true }

    return <Fragment>
      <Typography variant="h4" >
        {translate('resources.saletickets.customerInfo')}
      </Typography>
      <Grid container>
        <Grid item md={6} xs={12} className={classes.noPaddingVertical}>
          <TextInput
            label="resources.saletickets.cusName"
            source="fullName"
            validate={required()}
            {...extra}
          />
          <DatePickerInput
            label={translate('resources.saletickets.birthday')}
            source="birthday"
            dateFormat="DD/MM/YYYY"
            keyboard={true}
            {...extra}
          />
          <TextInput
            {...extra}
            label="resources.saletickets.address"
            source="address"
          />
        </Grid>
        <Grid item md={6} xs={12} className={classes.noPaddingVertical}>
          <TextInput
            {...extra}
            label="resources.saletickets.phone"
            source="phone"
            validate={required()}
          />
          <TextInput
            {...extra}
            label="resources.saletickets.email"
            source="email"
            validate={required()}
          />
        </Grid>
      </Grid>
    </Fragment>
  }
}

const enhanceCustomerInformation = compose(withStyles(customerInfomationStyle), translate)
const CustomerInformation = enhanceCustomerInformation(_CustomerInformation)

const infomationStyle = {
  root: {
    padding: 18,
  },
  productCusContainer: {
    display: 'flex',
  }
}

const _Information = ({
  classes,
  routes,
  productId,
  changeForm,
}) => {
  return <div className={classes.root}>
    <Grid container>
      <Grid item xs={12} md={12}>
        <CustomerInformation />
      </Grid>
    </Grid>
    <Grid container className={classes.productCusContainer}>
      <Grid item xs={12} md={12}>
        <ProductInformation
          productId={productId}
          routes={routes}
          changeForm={changeForm}
        />
      </Grid>
    </Grid>
  </div>
}

const enhanceInformation = compose(withStyles(infomationStyle))
const Information = enhanceInformation(_Information)

const style = {
  root: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'flex',
  },
  form: {
    width: '100%'
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    position: 'absolute',
  },
  emptySnack: {
    width: '100%',
    maxWidth: '100%',
  },
}

const SALE_TICKE_FORM = 'sale-ticket-form'

class Registration extends Component {

  state = {
    loading: false,
  }

  async componentDidMount() {
    await this.init()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { routeId, companyId } = nextProps
    let { routeDefault, companyDefault, companyChoices, routeChoices } = prevState
    if (!routeId) {
      routeDefault = (routeChoices && routeChoices.length > 0) && routeChoices[0].id
    } else if (routeId !== routeDefault) {
      routeDefault = routeId
    }
    if (!routeId) {
      routeDefault = (companyChoices && companyChoices.length > 0) && companyChoices[0].id
    } else if (companyId !== companyDefault) {
      companyDefault = companyId
    }
    return { routeDefault, companyDefault }
  }

  init = async () => {
    let { translate, permissions, changeBreadcrumb, changeForm, } = this.props
    let agencyRole = permission.isAgencyRole(permissions)
    let companyRole = permission.isCompanyRole(permissions)
    let paths = [
      { label: translate('resources.membercards.name', { smart_count: 2 }), to: '/membercards' },
      { label: translate('resources.membercards.registration'), to: '' },
    ]
    changeBreadcrumb({ paths })
    let { companyChoices, routeChoices, companyDefault, routeDefault } = this.state
    if (agencyRole) {
      companyChoices = await this.getRegistedBuyTicketCompanies()
      if (companyChoices && companyChoices.length > 0) {
        companyDefault = companyDefault || companyChoices[0].id
      }
    }
    let { routes, productId } = await this.getRoutes()
    changeForm(SALE_TICKE_FORM, 'productId', productId)
    routeChoices = routes || []
    if (routeChoices && routeChoices.length > 0) {
      routeDefault = routeDefault || routeChoices[0].id
    }
    this.setState({
      routeDefault,
      companyDefault,
      companyChoices,
      routeChoices,
      agencyRole,
      companyRole,
      productId,
    })
  }

  getRegistedBuyTicketCompanies = async () => {
    let res = await Provider.dataProvider('REMOTE', 'companies', {
      method: 'getBusTourCompaniesByAgency',
      requestMethod: 'GET'
    })
    if (res && res.data) {
      return res.data
    }
  }

  getRoutes = async () => {
    let res = await Provider.dataProvider('REMOTE', 'routes', {
      method: 'getRoutesByProductType',
      requestMethod: 'GET',
      data: {
        productType: '50SEASON',
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { location } = this.props
    let { location: prevLocation } = prevProps
    const updated = !(prevLocation === location)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      await this.init()
    }
  }

  validate = (values = {}) => {
    let { translate } = this.props
    let errors = {}
    let { routeId, segmentId } = values
    if (routeId && !segmentId) {
      errors.segmentId = translate('error_messages.required.member_cards.segmentId')
    }
    return errors
  }

  render() {
    let { translate, classes, changeForm } = this.props
    let {
      companyDefault,
      routeChoices,
      memberCardId,
      productId,
    } = this.state
    return <Fragment>
      <Title title={translate('resources.membercards.registration')} />
      <Card className={classes.container}>
        <SimpleForm
          form={SALE_TICKE_FORM}
          resource="saletickets"
          className={classes.form}
          toolbar={<RegistrationToolbar
            form={SALE_TICKE_FORM}
            memberCardId={memberCardId}
            productId={productId}
          />}
          record={{ companyId: companyDefault, routeType: ONE_ROUTE }}
          asyncBlurFields={['phone', 'memberCardCode']}
          asyncValidate={async (record) => {
            const { phone, memberCardCode, address, birthday, } = record
            let result
            if (memberCardCode) {
              result = await Provider.dataProvider('REMOTE', 'membercards', {
                method: 'findByCode',
                data: { code: memberCardCode },
              })
              const memberCard = _.get(result, 'data.memberCard')
              if (_.isEmpty(memberCard)) return
              this.setState({ memberCardId: memberCard.id })
              changeForm(SALE_TICKE_FORM, 'fare.name', memberCard.fare.name)
              changeForm(SALE_TICKE_FORM, 'fare.amount', memberCard.fare.amount)
              changeForm(SALE_TICKE_FORM, 'fullName', memberCard.customer.fullName)
              changeForm(SALE_TICKE_FORM, 'email', memberCard.customer.email)
              changeForm(SALE_TICKE_FORM, 'phone', memberCard.customer.phone)
            } else {
              if (!phone) return
              let { phones = {} } = this.state
              let customer = phones[phone]
              if (!customer) {
                phones = { ...phones, phone: {} }
                result = await Provider.dataProvider('REMOTE', 'customers', {
                  method: 'findByPhone',
                  data: { phone },
                })
                let _customer = _.get(result, 'data.customer')
                if (!_.isEmpty(_customer)) {
                  customer = _customer
                  phones = { ...phones, phone: customer }
                }
                this.setState({ phones })
              }
              if (!_.isEmpty(customer)) {
                if (!record.fullName) changeForm(SALE_TICKE_FORM, 'fullName', customer.fullName)
                if (!record.email) changeForm(SALE_TICKE_FORM, 'email', customer.email)
                if (!address) changeForm(SALE_TICKE_FORM, 'address', _.get(customer, 'address', ''))
                if (!birthday) changeForm(SALE_TICKE_FORM, 'birthday', _.get(customer, 'birthday', ''))
              }
            }
          }}
          validate={this.validate}
        >
          <Information
            routes={routeChoices}
            productId={productId}
            changeForm={changeForm}
          />
        </SimpleForm>
      </Card>
    </Fragment>
  }
}

const mapStateToProps = state => {
  let filterValues = state.form[SALE_TICKE_FORM] && state.form[SALE_TICKE_FORM].values ? state.form[SALE_TICKE_FORM].values : {}
  let { routeId, companyId, departureDate } = filterValues
  return { routeId, companyId, departureDate }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(mapStateToProps, {
    showNotification,
    changeBreadcrumb,
    changeForm,
  })
)

const RegistrationWithPermission = ({ ...props }) => <WithPermissions
  render={({ permissions }) => permissions ? <Registration permissions={permissions} {...props} /> : null}
/>

export default enhance(RegistrationWithPermission)
