export const GET_OBJECT_NO_LICENCE = 'GET_OBJECT_NO_LICENCE'
export const FETCH_OBJECT_NO_LICENCE = 'FETCH_OBJECT_NO_LICENCE'
export const FETCH_OBJECT_NO_LICENCE_SUCCESS = 'FETCH_OBJECT_NO_LICENCE_SUCCESS'
export const FETCH_OBJECT_NO_LICENCE_FAILURE = 'FETCH_OBJECT_NO_LICENCE_FAILURE'

export const ASSIGN_LICENCE_TO_OBJECT = 'ASSIGN_LICENCE_TO_OBJECT'
export const FETCH_ASSIGN_LICENCE_TO_OBJECT = 'FETCH_ASSIGN_LICENCE_TO_OBJECT'
export const FETCH_ASSIGN_LICENCE_TO_OBJECT_SUCCESS = 'FETCH_ASSIGN_LICENCE_TO_OBJECT_SUCCESS'
export const FETCH_ASSIGN_LICENCE_TO_OBJECT_FAILURE = 'FETCH_ASSIGN_LICENCE_TO_OBJECT_FAILURE'

export const UNASSIGN_LICENCE_TO_OBJECT = 'UNASSIGN_LICENCE_TO_OBJECT'
export const FETCH_UNASSIGN_LICENCE_TO_OBJECT = 'FETCH_UNASSIGN_LICENCE_TO_OBJECT'
export const FETCH_UNASSIGN_LICENCE_TO_OBJECT_SUCCESS = 'FETCH_UNASSIGN_LICENCE_TO_OBJECT_SUCCESS'
export const FETCH_UNASSIGN_LICENCE_TO_OBJECT_FAILURE = 'FETCH_UNASSIGN_LICENCE_TO_OBJECT_FAILURE'

export const SAVE_REMINDER = 'SAVE_REMINDER'
export const FETCH_SAVE_REMINDER = 'FETCH_SAVE_REMINDER'
export const FETCH_SAVE_REMINDER_SUCCESS = 'FETCH_SAVE_REMINDER_SUCCESS'
export const FETCH_SAVE_REMINDER_FAILURE = 'FETCH_SAVE_REMINDER_FAILURE'

export const UPSERT_SERVICE_TASK = 'UPSERT_SERVICE_TASK'

export const FETCH_UPSERT_OBJECT = 'FETCH_UPSERT_OBJECT'
export const FETCH_UPSERT_OBJECT_SUCCESS = 'FETCH_UPSERT_OBJECT_SUCCESS'
export const FETCH_UPSERT_OBJECT_FAILURE = 'FETCH_UPSERT_OBJECT_FAILURE'

export const RENEWAL_LICENCE = 'RENEWAL_LICENCE'
export const FETCH_RENEWAL_LICENCE = 'FETCH_RENEWAL_LICENCE'
export const FETCH_RENEWAL_LICENCE_SUCCESS = 'FETCH_RENEWAL_LICENCE_SUCCESS'
export const FETCH_RENEWAL_LICENCE_FAILURE = 'FETCH_RENEWAL_LICENCE_FAILURE'

export const getObjectNoLicence = (resources, done, error) => ({
  type: GET_OBJECT_NO_LICENCE,
  resources,
  done,
  error
})

export const assignLicenceToObject = (data, done, error) => ({
  type: ASSIGN_LICENCE_TO_OBJECT,
  data,
  done,
  error,
})

export const unAssignLicenceToObject = (data, done, error) => ({
  type: UNASSIGN_LICENCE_TO_OBJECT,
  data,
  done,
  error
})

export const saveReminder = (data, done, error) => ({
  type: SAVE_REMINDER,
  data,
  done,
  error
})

export const upsertServiceTask = (data, done, error) => ({
  type: UPSERT_SERVICE_TASK,
  data,
  done,
  error
})

export const renewal = (data, done, error) => ({
  type: RENEWAL_LICENCE,
  data,
  done,
  error,
})

// ------------------------------------------------------------------------
export const doFetchAction = (
  type,
  method,
  requestMethod,
  data,
  resource,
) => {
  return {
    type,
    payload: {
      method,
      requestMethod,
      data,
    },
    meta: {
      resource,
      fetch: 'REMOTE'
    },
  }
}

// ------------------------------------------------------------------------
export const doFetchUpSertAction = (
  type,
  id,
  data,
  resource,
  fetch,
) => {
  return {
    type,
    payload: { id, data },
    meta: {
      resource,
      fetch: fetch
    },
  }
}
