export const SAVE_REMINDER_SUCCESS = 'SAVE_REMINDER_SUCCESS'
export const SAVE_REMINDER_REQUEST = 'SAVE_REMINDER_REQUEST'
export const SAVE_REMINDER_FAILURE = 'SAVE_REMINDER_FAILURE'

export const DELETE_REMINDER = 'DELETE_REMINDER'
export const FETCH_DELETE_REMINDER = 'FETCH_DELETE_REMINDER'
export const FETCH_DELETE_REMINDER_SUCCESS = 'FETCH_DELETE_REMINDER_SUCCESS'
export const FETCH_DELETE_REMINDER_FAILURE = 'FETCH_DELETE_REMINDER_FAILURE'

export const GET_LICENCES = 'GET_LICENCES'
export const FETCH_GET_LICENCES = 'FETCH_GET_LICENCES'
export const FETCH_GET_LICENCES_SUCCESS = 'FETCH_GET_LICENCES_SUCCESS'
export const FETCH_GET_LICENCES_FAILURE = 'FETCH_GET_LICENCES_FAILURE'
export const MAKE_DONE_REMINDER = 'MAKE_DONE_REMINDER'
export const FETCH_MAKE_DONE_REMINDER = 'FETCH_MAKE_DONE_REMINDER'
export const FETCH_MAKE_DONE_REMINDER_SUCCESS = 'FETCH_MAKE_DONE_REMINDER_SUCCESS'
export const FETCH_MAKE_DONE_REMINDER_FAILURE = 'FETCH_MAKE_DONE_REMINDER_FAILURE'

export const RESTORE_REMINDER = 'RESTORE_REMINDER'
export const FETCH_RESTORE_REMINDER = 'FETCH_RESTORE_REMINDER'
export const FETCH_RESTORE_REMINDER_SUCCESS = 'FETCH_RESTORE_REMINDER_SUCCESS'
export const FETCH_RESTORE_REMINDER_FAILURE = 'FETCH_RESTORE_REMINDER_FAILURE'

export const deleteReminder = (id, extras, done, error) => ({
  type: DELETE_REMINDER,
  id,
  extras,
  done,
  error
})

export const fetchDeleteReminder = (id, extras) => ({
  type: FETCH_DELETE_REMINDER,
  payload: { id, extras },
  meta: {
    resource: 'servicereminders',
    fetch: 'DELETE'
  },
})

export const makeDoneReminder = (id, extras, done, error) => ({
  type: MAKE_DONE_REMINDER,
  id,
  extras,
  done,
  error
})

export const restoreReminder = (id, extras, done, error) => ({
  type: RESTORE_REMINDER,
  id,
  extras,
  done,
  error
})

export const getLicences = (payload, done, error) => ({
  GET_LICENCES,
  payload,
  done,
  error,
})

export const doFetchAction = (
  type,
  method,
  requestMethod,
  data,
  resource,
) => {
  return {
    type,
    payload: {
      method,
      requestMethod,
      data,
    },
    meta: {
      resource,
      fetch: 'REMOTE'
    },
  }
}