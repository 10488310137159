import {
  withStyles,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  List,
} from '@material-ui/core'
import React, { Component } from 'react'
import { translate, ReferenceManyField } from 'react-admin'
import compose from 'recompose/compose'
import { toggleLayerState, addMapLayer, getJourney } from './actions'
import { connect } from 'react-redux'
import { MapProps } from './../common/constants'
import AddIcon from '@material-ui/icons/Add'
import { styles } from './frame-styles'

class LayerItems extends Component {
  constructor(props) {
    super(props)
    let { data } = this.props
    let layers = Object.values(data)
    this.state = {
      items: layers,
      selected: []
    }
  }

  items() {
    let { addMapLayer, toggleLayerState } = this.props
    let { items, selected } = this.state
    return items.map((v, idx) => 
      <FormControlLabel key={idx} {
      ...{
        key: idx,
        label: v.name,
        checked: selected.includes(v),
        control: <Checkbox {
        ...{
          onChange: (e, checked) => {
            let newState = {}
            if (checked) {
              newState = { selected: [...this.state.selected,...[v]]}
              if (v.stops.length > 0) {
                addMapLayer({
                  type: 'transitive',
                  identifier: v.id,
                  name: v.name,
                  color: v.color,
                  data: v
                })
              }
            } else {
              newState ={ selected: this.state.selected.filter( s => s !== v)}
              toggleLayerState({
                identifier: v.id,
                visibility: MapProps.LayerStates.INVISIBLE
              })
            }
            this.setState(newState)
          }
        }
        }/>
      }
      }/>
    )
  }

  render() {
    return (
      <List>
        { this.items() }
      </List>
    )
  }
}

class TransitiveControlFrame extends Component {
  constructor(props) {
    super(props)
    let layers = [{ name: 'Item 1 long text' }, { name: 'Item 2 long text'}]
    this.state = {
      items: layers
    }
  }
  
  render() {
    let {
      identifier,
      classes,
    } = this.props

    return (<div key={ identifier } style={{
      width: 300,
      height: '100%',
      background: 'white',
      padding: 10
    }}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Layers</FormLabel>
        <FormGroup>
          <ReferenceManyField
            basePath="/"
            reference="routes/journeys"
            resource="routes"
            target="companyId"
            record={{
              companyId: 1, 
            }}
          >
            <LayerItems addMapLayer={addMapLayer} toggleLayerState={toggleLayerState}/>
          </ReferenceManyField>
        </FormGroup>
      </FormControl>

      <Button variant="fab" mini
        color="secondary"
        aria-label="Add"
        className={classes.fab}
        onClick={() => {} }>
        <AddIcon />
      </Button>
    </div >)
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { layers, bbox, zoom, center } = state.map
      return { layers, bbox, zoom, center }
    },
    {
      toggleLayerState,
      addMapLayer,
      getJourney
    })
)

export default enhance(TransitiveControlFrame)
