import {
  SET_VEHICLE_TYPE,
  ADD_VEHICLE,
  REMOVE_VEHICLE,
  UPDATE_VEHICLE,
  RESET_FORM_VEHICLE,
  HANDLE_EXIST_VEHICLE,
  UPDATE_VEHICLES_TYPE,
} from './action'
import _ from 'lodash'

export const vehicleType = (state = {}, action) => {
  switch (action.type) {
    case SET_VEHICLE_TYPE:
      return {
        ...state,
        selected: action.record
      }
    default: return state
  }
}

export const vehicle = (state = { values: [] }, action) => {
  switch (action.type) {
    case ADD_VEHICLE: {
      let values = state.values
      let vehicle = action.vehicle
      let {
        plate,
        name,
        capacity,
        seatAvailable,
        brand,
        model,
        color,
        fuel,
        vehicleIdx 
      } = vehicle
      let existPlate = _.findIndex(values, v => v.plate === plate)
      vehicle = !vehicleIdx && existPlate === -1 ? { ...vehicle, added: true, error: false, vehicleIdx: values.length + 1 } : vehicle
      let idx = existPlate !== -1 ? existPlate : _.findIndex(values, v => v.vehicleIdx === vehicleIdx)

      if (idx !== -1) {
        values[idx] = {
          ...values[idx],
          plate,
          name,
          capacity,
          seatAvailable,
          brand,
          model,
          color,
          fuel,
        }
        values = [...values]
      } else {
        values = [...state.values, ...[vehicle]]
      }
      return {
        ...state,
        selected: {},
        values: values
      }
    }
    case HANDLE_EXIST_VEHICLE: {
      let values = state.values
      let existsVehicle = action.existsVehicle
      for (let i=0; i<existsVehicle.length; i++) {
        let { plate } = existsVehicle[i]
        let idx = _.findIndex(values, vehicle => vehicle.plate === plate)
        values[idx] = { ...values[idx], error: true }
        values = [...values]
      }
      return {
        ...state,
        selected: {},
        values: values,
      }
    }
    case UPDATE_VEHICLE: {
      let vehicleIdx = action.vehicleIdx
      let currentValues = state.values
      let idx = _.findIndex(currentValues, vehicle => vehicle.vehicleIdx === vehicleIdx)
      return {
        ...state,
        selected: currentValues[idx] || {}
      }
    }
    case UPDATE_VEHICLES_TYPE: {
      let values = state.values
      let { type, seatMap, totalSeat } = action.record
      values = _.isEmpty(values) ? values : values.map(vehicle => {
        vehicle = {
          ...vehicle,
          type,
          seatMap,
          totalSeat,
        }
        return vehicle
      })
      return {
        ...state,
        values: values,
      }
    }
    case REMOVE_VEHICLE: {
      let vehicleIdx = action.vehicleIdx
      let currentValues = state.values
      let idx = _.findIndex(currentValues, vehicle => vehicle.vehicleIdx === vehicleIdx)
      let values = [...currentValues.slice(0, idx), ...currentValues.slice(idx + 1)]
      return {
        ...state,
        values: values
      }
    }
    case RESET_FORM_VEHICLE:{
      let selected = {
        plate: '',
        images: [],
        type: 0,
        capacity: 0,
        brand: '',
        model: '',
        color: '',
      }
      return {
        ...state,
        values: [],
        selected: {...selected}
      }
    }
    default: return state
  }
}
