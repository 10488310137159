import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  Create,
  SimpleForm,
  Edit,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import { isCompanyManager } from '../utils/permission'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'

const styles = {
  textCenter: {
    textAlign: 'center',
  },
}

export const _VehicleTypeList = ({ classes, permissions, ...props}) => {
  let companyManager = isCompanyManager(permissions)
  return <List {...props} bulkActionButtons={false}>
    <Datagrid rowClick={companyManager && 'edit'}>
      <TextField source="name" />
      <TextField
        source="totalSeat"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      />
      <ReferenceField
        source="vehicleClass"
        reference="vehicleclasses"
        resource="vehicleclasses"
        allowEmpty
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
}

export const VehicleTypeList = compose(withStyles(styles))(_VehicleTypeList)

export const VehicleTypeCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
    undoable={false}
  >
    <SimpleForm >
      <FormSave />
    </SimpleForm>
  </Create>
)

export const VehicleTypeEdit = props => (
  <Edit
    {...props}
    title={<EditTitle resource={props.resource} />}
    undoable={false}
  >
    <SimpleForm toolbar={<CustomToolbar />} >
      <FormSave />
    </SimpleForm>
  </Edit>
)
