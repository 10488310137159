import _ from 'lodash'
import {
  getFareConditions,
  getFareTable,
} from '../provider/get'
import numeral from 'numeral'
import { currencyFormat } from './format'

export default class FareTable {
  index = 0
  fareCondition = null
  fareTable = null
  static fareConditions = null
  static fareConditionMap = null

  constructor(fareTable) {
    this.fareTable = fareTable

    fareTable.prices.forEach((price, index) => {
      price.index = index
    })
  }

  static async createFromTrip(trip) {
    let fareTable = await getFareTable(trip)

    if (!FareTable.fareConditions) {
      FareTable.fareConditions = await getFareConditions()
      FareTable.fareConditionMap = _.mapKeys(FareTable.fareConditions, item => item.id)
    }
    if (!fareTable) return
    return new FareTable(fareTable)
  }

  static createFromFareTable(fareTable, index) {
    let ret = new FareTable(fareTable.fareTable)
    ret.fareCondition = fareTable.fareCondition
    ret.index = index
    return ret
  }

  async getFareConditions() {
    return FareTable.fareConditions
  }

  getConditionMap() {
    if (!this.conditionMap) {
      this.conditionMap = _.mapKeys(this.fareTable.conditions, item => item.key)
    }
    return this.conditionMap
  }

  setActive(index) {
    this.index = index
  }

  getBasePriceTable() {
    return _.map(this.fareTable.prices, item => ({
      index: item.index,
      basePrice: _.get(item, 'table[0].data.rowBase.value'),
    }))
  }

  getPriceTable() {
    let row = _.get(this.fareTable.prices[this.index], 'table[0].data')
    if (row) {
      let ret = _.reduce(Object.keys(row), (acc, key) => {
        let cell = row[key]
        if (cell.code) {
          acc.push({ ...cell, key })
        }
        return acc
      }, [])
      return ret
    }
  }

  getPriceLabel(r) {
    return `${numeral(r.value).format(currencyFormat)} - ${this.getPriceName(r)}`
  }

  getPriceName(r) {
    return _.get(FareTable.fareConditionMap[r.code], 'name')
  }

  getChargeFare({ index, code }) {
    let price = this.fareTable.prices[index]
    let row = price.table[0]
    let rowKey = row.condition.key
    let group = price.key

    for (let column of Object.keys(row.data)) {
      let cell = row.data[column]
      if (cell.code === code) {
        return {
          fare: { group, row: rowKey, column },
          fareId: cell.fareId,
        }
      }
    }
  }

}
