/*import MQTTService from './mqtt'*/
//import ClientEmitter from './client-emitter'

//const MqttOpts = {
//brokerUrl: process.env.REACT_APP_MQTT_BROKER_URL || 'mqtts://mc.nexpando.com:8883',
//username: process.env.REACT_APP_MQTT_USERNAME || 'nexbus',
//password: process.env.REACT_APP_MQTT_PASSWORD || 'pando.dev',
//}
//let topicSet = new Set()

//let mqttService = new MQTTService({
//options: {
//brokerUrl: MqttOpts.brokerUrl,
//opts: {
//rejectUnauthorized: false,
//username: MqttOpts.username,
//password: MqttOpts.password,
//}
//},
//cb: {
//onUnSubscribed: (client, topic) => {
//topicSet.delete(topic)
//}
//}
//})

//let mqttClient = new ClientEmitter()

//mqttClient.addTopic = (topic) => {
//if (topicSet.has(topic)) {
//return
//}
//if (!mqttService.isConnected()) {
//mqttService.connect()
//}
//mqttService.subscribe([topic])
////   mqttService.subscribe([topic], (receivedTopic, rawMsg) => {
////     var message = decodeURIComponent(rawMsg.toString())
////     console.log(`[<==] Topic: ${receivedTopic} | Message: ${message}`)
////     // topic and receivedTopic might not be the same
////     mqttClient.emit(receivedTopic, message)
////   })
////   topicSet.add(topic)
//}

//mqttClient.publish = (topic, message) => {
//// if (topicSet.has(topic)) {
////   return
//// }

//if (!mqttService.isConnected()) {
//mqttService.connect()
//}

//mqttService.publish(topic, message)
//}

//mqttClient.removeTopic = (topic) => {
//if (!mqttService.isConnected()) {
//return
//}

//mqttService.unsubscribe(topic)
//mqttClient.removeEventListener(topic)
//}


//mqttClient.removeTopics = (topics) => {
//for (let i = 0; i < topics.length; i++) {
//mqttClient.removeTopic(topics[i])
//}
//}

//mqttClient.onMessage = (handler) => {
//mqttService.reciever(handler)
//}

/*export default mqttClient*/
