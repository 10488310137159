import React, { Component, Fragment } from 'react'
import {
  List,
  TextField,
  DateField,
  Datagrid,
  FunctionField,
  ReferenceField,
  Filter,
  ReferenceInput,
  translate,
  CardActions,
  Create,
  Edit,
} from 'react-admin'
import { withStyles, Button, Tooltip } from '@material-ui/core'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHandshake } from '@fortawesome/free-solid-svg-icons'
import * as permission from '../utils/permission'
import { DateTimePickerInput } from '../common/DateTimePicker'
import FormSave from './FormSave'
import moment from 'moment'
import { formatCurrency } from '../utils/formatUtil'
import { EditTitle } from '../common/Title'
import EditIcon from '@material-ui/icons/Edit'
import LinkField from '../common/LinkField'
import PaymentButton from './PaymentButton'
import { StatusField } from './Status'
import { green, red } from '@material-ui/core/colors'
import SelectInputCustom from '../common/SelectInputCustom'

const styleList = () => ({
  avatarCol: {
    display: 'flex'
  },
  fullName: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  textCenter: {
    textAlign: 'center',
  },
  debit: {
    color: red[500],
  },
  credit: {
    color: green[500],
  },
})

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
  },
  other: {
    minWidth: 100,
  }
})

///////////////////////////////////////////
// List
class _ReconciliationListFilter extends Component {
  
  state = {}

  render() {
    let { translate, classes, isAdmin, ...props } = this.props
    let { fromDate } = this.state
    return <Filter
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
      {...props}
    >
      <DateTimePickerInput
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        resource="reconciliations"
        alwaysOn
      />
      <DateTimePickerInput
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        resource="reconciliations"
        minDate={fromDate}
        alwaysOn
      />
      {isAdmin && <ReferenceInput
        basePath="/financeaccounts"
        reference="financeaccounts"
        source="accountId"
        resource="financeaccounts"
        fullWidth
        alwaysOn
        perPage={1000}
        allowEmpty
        label="resources.reconciliations.fields.accountId"
        classExtra={classes.other}
      >
        <SelectInputCustom
          optionText="accountName"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>}
      <ReferenceInput
        basePath="/reconciliationstatuses"
        reference="reconciliationstatuses"
        source="status"
        resource="reconciliationStatuses"
        fullWidth
        alwaysOn
        allowEmpty
        label="resources.reconciliations.fields.status"
        classExtra={classes.other}
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
    </Filter>
  }
}

const ReconciliationListFilter = compose(withStyles(styleListFilter))(_ReconciliationListFilter)

const styleListAction = {
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17
  }
}

const ActionList = withStyles(styleListAction)(({
  classes,
  history,
  translate,
}) => {
  return <CardActions>
    <Button
      color="primary"
      onClick={() => {
        history.push('/reconciliations/create')
      }}
      className={classes.button}
    >
      <span>
        <FontAwesomeIcon icon={faHandshake} className={classes.icon} />
        <span>{translate('button.create_reconciliation')}</span>
      </span>
    </Button>
  </CardActions>
})

class _ReconciliationList extends Component {
  render() {
    let { classes, permissions, history, translate, ...props } = this.props
    let isAdmin = permission.isAdmin(permissions)
    return <List
      {...props}
      bulkActionButtons={false}
      filters={<ReconciliationListFilter isAdmin={isAdmin} />} 
      actions={<ActionList history={history} />}
      history={history}
      filterDefaultValues={{
        createdAt_gte: moment().startOf('days').toDate().toISOString(),
        createdAt_lte: moment().endOf('days').toDate().toISOString(),
      }}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filter={{
        '../include': {
          relation: 'platformTransaction',
        },
      }}
    >
      <Datagrid>
        <FunctionField
          source="statementDate"
          sortable={false}
          //headerClassName={classes.textCenter}
          //cellClassName={classes.textCenter}
          render={({ statementStartDate, statementEndDate }) => {
            if (statementStartDate && statementStartDate) {
              return `${moment(statementStartDate).format('HH:mm DD/MM/YYYY')} - ${moment(statementEndDate).format('HH:mm DD/MM/YYYY')}`
            }
            return ''
          }}
        />
        <DateField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          showTime
          locales="vi-Vn"
          source="createdAt"
        />
        {isAdmin && <ReferenceField
          reference="financeaccounts"
          source="accountId"
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          linkType={false}
          allowEmpty
        >
          <TextField source="accountName" />
        </ReferenceField>}
        <FunctionField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="debit"
          render={record => <span className={classes.debit}>{isAdmin ? formatCurrency(record.credit) : formatCurrency(record.debit)}</span>}
        />
        <FunctionField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="credit"
          render={record => <span className={classes.credit}>{isAdmin ? formatCurrency(record.debit) : formatCurrency(record.credit)}</span>}
        />
        <ReferenceField
          reference="reconciliationstatuses"
          source="status"
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          linkType={false}
          allowEmpty
        >
          <StatusField source="name" />
        </ReferenceField>
        <DateField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          showTime
          locales="vi-Vn"
          source="platformTransaction.createdAt"
        />
        <FunctionField
          sortable={false}
          render={record => <Fragment>
            <PaymentButton isAdmin={isAdmin} reconciliation={record} />
            <LinkField
              className={classes.iconButton}
              path={`/reconciliations/${record.id}`}
              disabled={record.status === '20PAID'}
              icon
            >
              <Tooltip title={translate('button.edit')} enterDelay={100} >
                <EditIcon fontSize="small" />
              </Tooltip>
            </LinkField>
          </Fragment>}
        />
      </Datagrid>
    </List>
  }
}

const enhanceList = compose(withStyles(styleList), translate)
export const ReconciliationList = enhanceList(_ReconciliationList)

export const ReconciliationCreate = ({ resource, ...props }) => <Create
  title={<EditTitle resource={resource} />}
  resource={resource}
  {...props}
>
  <FormSave action="create" />
</Create>

export const ReconciliationEdit = ({ resource, ...props }) => <Edit
  title={<EditTitle resource={resource} />}
  resource={resource}
  {...props}
>
  <FormSave action="edit" />
</Edit>
