import React, { Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  DateField,
  ReferenceField,
  FunctionField,
  Filter,
  ReferenceInput,
  NumberField,
  translate,
  Show,
  ChipField,
  Responsive,
} from 'react-admin'
import LinkField from '../common/LinkField'
import * as permission from '../utils/permission'
import { DateTimePickerInput } from '../common/DateTimePicker'
import TransactionInfo from './TransactionInfo'
import MobileGrid from './MobileGrid'
import { StatusField } from './Status'
import { EditTitle } from '../common/Title'
import { moment } from '../common/format'
import SelectInputCustom from '../common/SelectInputCustom'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core'

const styleTransactionFilter = () => ({
  other: {
    minWidth: 120,
  }
})

class _TransactionFilter extends Component {
  state = {}

  render() {
    const { classes, translate, isAdmin, ...props } = this.props
    const { fromDate } = this.state
    return <Filter
      {...props}
      validate={values => {
        const minDate = moment(values.createdAt_gte).endOf('days').toDate().toISOString()
        if (values.createdAt_lte < minDate) {
          values.createdAt_lte = minDate
        }
        if (this.state.fromDate !== minDate) {
          this.setState({ fromDate: minDate })
        }
      }}
    >
      {isAdmin && <ReferenceInput
        source="companyId"
        basePath="/companies"
        reference="companies"
        resource="companies"
        perPage={1000}
        fullWidth
        alwaysOn
        allowEmpty        
        classExtra={classes.other}
        label="resources.transactions.fields.companyId"
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>}
      <ReferenceInput
        source="type"
        basePath="/transactiontypes"
        reference="transactiontypes"
        fullWidth
        alwaysOn
        style={{ width: '100%'}}
        allowEmpty        
        classExtra={classes.other}
        label="resources.transactions.fields.type"
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
      <ReferenceInput
        source="paymentMethodId"
        basePath="/paymentmethods"
        reference="paymentmethods"
        fullWidth
        alwaysOn
        allowEmpty        
        classExtra={classes.other}
        label="resources.transactions.fields.paymentMethodId"
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
      <ReferenceInput
        source="status"
        reference="transactionstatuses"
        fullWidth
        alwaysOn
        allowEmpty        
        classExtra={classes.other}
        label="resources.transactions.fields.status"
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
      <DateTimePickerInput
        source="createdAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <DateTimePickerInput
        source="createdAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
        minDate={fromDate}
      />
    </Filter>
  }
}

const TransactionFilter = compose(translate, withStyles(styleTransactionFilter))(_TransactionFilter)

export class TransactionList extends Component {
  render() {
    let { classes, history, permissions, ...props } = this.props
    let isAdmin = permission.isAdmin(permissions)
    return (
      <List
        {...props}
        filters={<TransactionFilter isAdmin={isAdmin} />}
        filter={{
          path: 'getByCompanyId',
          '../fields': [
            'id',
            'createdAt',
            'paidAt',
            'paid',
            'total',
            'reservationId',
            'paymentMethodId',
            'type',
            'status'
          ],
        }}
        history={history}
        sort={{ field: 'createdAt', order: 'desc' }}
        filterDefaultValues={{
          createdAt_gte: moment().startOf('days').toDate().toISOString(),
          createdAt_lte: moment().endOf('days').toDate().toISOString(),
        }}
        bulkActionButtons={false}
      >
        <Responsive
          small={<MobileGrid history={history} isAdmin={isAdmin} />}
          medium={<Datagrid rowClick={(id, basePath, record) => `/transactions/${record.transactionId}/show`}>
            <DateField source="createdAt" locales="vi-VN" showTime />
            <DateField source="paidAt" locales="vi-VN" showTime />
            <NumberField source="paid" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
            <NumberField source="total" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
            <FunctionField
              source="reservationId"
              render={record => {
                return isAdmin ? record.reservationCode : (
                  <LinkField
                    classes={classes}
                    path={`/reservations/${record.reservationId}/show`}
                  >
                    {record.reservationCode}
                  </LinkField>
                )
              }}
            />

            <ReferenceField
              source="paymentMethodId"
              reference="paymentmethods"
              linkType={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              source="type"
              reference="transactiontypes"
              linkType={false}
            >
              <TextField source="name" />
            </ReferenceField>
            <ReferenceField
              source="status"
              reference="transactionstatuses"
              linkType={false}
            >
              <StatusField source="name" />
            </ReferenceField>
            <NumberField source="attempt" />
            <ReferenceField
              source="agencyId"
              reference="agencies/list"
              linkType={false}
            >
              <ChipField source="name" />
            </ReferenceField>

            <ReferenceField
              source="companyId"
              reference="companies"
              linkType={false}
            >
              <ChipField source="name" />
            </ReferenceField>
          </Datagrid>}
        />
      </List>
    )
  }
}

export const TransactionShow = props => <Show
  title={<EditTitle
    resource={props.resource}
    render={record => `#${record.id}`}
  />}
  {...props}
>
  <TransactionInfo />
</Show>

