import React, { Component } from 'react'
import {
  translate,
  TextField,
  ReferenceField,
  SimpleShowLayout,
  FunctionField,
} from 'react-admin'
import { withStyles, } from '@material-ui/core'
import compose from 'recompose/compose'
import moment from 'moment'

const styles = () => ({
  listWidth: {
    width: '100%'
  },
  chipTrip: {
    margin: 4,
  },
  routeChip: {
    margin: 8,
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  gridLeft: {
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    padding: 24,
  },
  gridRight: {
    padding: 24,
  },
  tripTitle: {
    fontWeight: 'bold',
    marginBottom: 16,
    marginTop: 16,
  },
  card: {
    margin: 8
  },
  label: {
    paddingLeft: '0.5em',
    marginBottom: 6,
    marginTop: 6,
  }
})

class FormView extends Component {

  render() {
    let { classes, translate, record } = this.props
    const extra = {
      resource: 'membercards',
      fullWidth: true,
    }

    return <SimpleShowLayout
      record={record}
      basePath="/membercards"
      {...extra}
    >
      <TextField source="code" />
      <ReferenceField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="itemDetail.routeId"
        reference="routes"
        linkType={false}
        label={translate('resources.routes.name', { smart_count: 1 })}
        allowEmpty
        {...extra}
      >
        <TextField source="name" />
      </ReferenceField>
      <TextField source="itemDetail.segmentName" label="resources.membercards.fields.segmentName"/>
      <FunctionField
        source="createdAt"
        render={({ createdAt }) => moment(createdAt).format('HH:mm DD/MM/YYYY')}
      />
      <ReferenceField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="customerId"
        reference="customers"
        linkType={false}
        label="resources.customers.fields.fullName"
        allowEmpty
        {...extra}
      >
        <TextField source="fullName" />
      </ReferenceField>
      <ReferenceField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="customerId"
        reference="customers"
        linkType={false}
        allowEmpty
        label="resources.customers.fields.phone"
        {...extra}
      >
        <TextField source="phone" />
      </ReferenceField>
      <ReferenceField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="customerId"
        reference="customers"
        linkType={false}
        label="resources.customers.fields.email"
        allowEmpty
        {...extra}
      >
        <TextField source="email" />
      </ReferenceField>
      <ReferenceField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="stopId"
        reference="stops"
        linkType={false}
        label="resources.saletickets.receive_member_card_place"
        allowEmpty
        {...extra}
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(FormView)
