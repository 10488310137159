import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  CardActions,
  CreateButton,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import PushNotificationButton from './PushNotificationButton'

const ActionList = ({ basePath, ...props }) => <CardActions>
  <CreateButton basePath={basePath} />
  <PushNotificationButton />
</CardActions>

export const NotificationList = props => <List
  actions={<ActionList />}
  {...props}
>
  <Datagrid rowClick="edit" >
    <TextField source="title" />
    <TextField source="subTitle" />
    <TextField source="icon" />
    <TextField source="sound" />
    <TextField source="priority" />
    <TextField source="category" />
  </Datagrid>
</List>


export const NotificationCreate = props => <Create
  {...props}
  title={<EditTitle resource={props.resource} />}
  undoable={false}
>
  <FormSave />
</Create>

export const NotificationEdit = props => <Edit
  {...props}
  title={<EditTitle
    resource={props.resource}
    render={record => record.title}
  />}
  undoable={false}
>
  <FormSave />
</Edit>