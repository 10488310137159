import React, { Component } from 'react'
import compose from 'recompose/compose'
import { addField, translate } from 'react-admin'
import PropTypes from 'prop-types'
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from '@material-ui/core'

// https://github.com/jakubroztocil/rrule
import { RRule } from 'rrule'
import _ from 'lodash'
import moment from 'moment'
import MaskedInput from 'react-text-mask'

const TextMaskInput = ({ inputRef, ...props}) => {
  return <MaskedInput
    mask={[/[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, ':', /[0-9]/, /[0-9]/]}
    ref={ref => {inputRef(ref ? ref.inputElement: null)}}
    onClick={evt => evt.target.select()}
    placeholderChar={'\u2000'}
    showMask
    {...props}
  />
}

const weekdayOptions = [
  { id: 0, name: 'resources.common.daysOfWeek.monday' },
  { id: 1, name: 'resources.common.daysOfWeek.tuesday' },
  { id: 2, name: 'resources.common.daysOfWeek.wednesday' },
  { id: 3, name: 'resources.common.daysOfWeek.thursday' },
  { id: 4, name: 'resources.common.daysOfWeek.friday' },
  { id: 5, name: 'resources.common.daysOfWeek.saturday' },
  { id: 6, name: 'resources.common.daysOfWeek.sunday' },
]

const dayOfMonthOptions = _.range(31).map(i => ({ id: i + 1, name: i + 1 }))

const getCurrentDayOfWeek = () => {
  return moment().weekday()
}

const getCurrentDayOfMonth = () => {
  return moment().date()
}

const applyForOptions = [
  { id: 'BUY_TIME', name: 'resources.common.applyForOptions.buy_time' },
  { id: 'DEPARTURE_TIME', name: 'resources.common.applyForOptions.departure_time' },
]

const validateTimeRange = (text) => {
  if (!text) return
  text = text.replace(/ /g, '')
  const message = 'resources.campaigns.fields.time_range_warning'
  let times = text.split('-')
  if (times.length !== 2) return message
  let start = moment.duration(times[0])
  let end = moment.duration(times[1])
  if (start.isValid() && end.isValid() && start.asSeconds() >= 0 && end.asSeconds() > 0) {
    if (start >= end) return 'resources.campaigns.fields.time_range_error'
    return
  }
  return message
}

const recordToRule = (record) => {
  let { options, range, applyFor } = record
  let duration
  if (range) {
    range = range.replace(/ /g, '')
    let ranges = range.split('-')
    let startTime = moment.duration(ranges[0])
    let endTime = moment.duration(ranges[1])
    duration = moment.duration(endTime - startTime).asSeconds()
    options = {
      ...options,
      dtstart: moment().subtract(1, 'year').startOf('year').toDate(),
      byhour: startTime.hours(),
      byminute: startTime.minutes(),
    }
  } else if (options) {
    let { byhour, byminute, ...rest } = options
    options = rest
  }

  return {
    ruleText: RRule.optionsToString({
      ...options,
      freq: RRule.DAILY,
    }),
    duration,
    options,
    range,
    applyFor,
  }
}

class RuleInput extends Component {
  static propTypes = {
    input: PropTypes.object,
    getSource: PropTypes.func,
  }

  constructor(props) {
    super(props)
    let { input } = props
    let { value } = input
    if (!value || _.isEmpty(value)) {
      value = { 
        options: {
          bymonthday: [getCurrentDayOfMonth()],
          byweekday: [getCurrentDayOfWeek()],
        },
        range: '00:00-23:59',
        applyFor: 'BUY_TIME',
      }
      value = recordToRule(value)
      input.onChange(value)
    }
    this.state = {
      record: value
    }
  }

  updateRecord = (fieldName, value) => {
    let { record } = this.state
    record = _.set(_.cloneDeep(record), fieldName, value)
    record = recordToRule(record)
    this.setState({ record })
    this.props.input.onChange(record)
  }

  render() {
    let { record, rangeError } = this.state
    let { translate } = this.props
    let { options, range, applyFor } = record
    return <Grid container>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{translate('resources.campaigns.fields.daysOfMonth')}</InputLabel>
          <Select
            multiple
            value={options.bymonthday || []}
            onChange={evt => this.updateRecord('options.bymonthday', evt.target.value)}
          >
            {dayOfMonthOptions.map(it => (
              <MenuItem key={it.id} value={it.id}>{'Ngày ' + it.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{translate('resources.campaigns.fields.daysOfWeek')}</InputLabel>
          <Select
            multiple
            value={options.byweekday || []}
            onChange={evt => this.updateRecord('options.byweekday', evt.target.value)}
            fullWidth
          >
            {weekdayOptions.map(it => (
              <MenuItem key={it.id} value={it.id}>{translate(it.name)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <TextField
          error={Boolean(rangeError)}
          label={translate('resources.campaigns.fields.duration')}
          defaultValue={range}
          helperText={translate(rangeError)}
          onChange={evt => {
            let rangeError = validateTimeRange(evt.target.value)
            if (!rangeError) {
              this.updateRecord('range', evt.target.value)
            }
            this.setState({ rangeError })
          }}
          InputProps={{
            inputComponent: TextMaskInput
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel>{translate('resources.campaigns.fields.applyFor')}</InputLabel>
          <Select
            value={applyFor}
            onChange={evt => this.updateRecord('applyFor', evt.target.value)}
            fullWidth
          >
            {applyForOptions.map(it => (
              <MenuItem key={it.id} value={it.id}>{translate(it.name)}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  }
}

const enhance = compose(translate, addField)

export default enhance(RuleInput)
