export const MAP_UPDATE = 'MAP_UPDATE'
export const CHANGE_ROUTE = 'CHANGE_ROUTE'

export const mapUpdate = (data) => {
  return ({
    type: MAP_UPDATE,
    payload: data,
  })
}

export const OPEN_CONTEXT_MENU = 'OPEN_CONTEXT_MENU'

export const openContextMenu = (data) => {
  return ({
    type: OPEN_CONTEXT_MENU,
    payload: data
  })
}

export const NETWORK_UPDATE = 'NETWORK_UPDATE'

export const networkUpdate = (data) => {
  return ({
    type: NETWORK_UPDATE,
    payload: data
  })
}

export const MAP_ACTION = 'MAP_ACTION'

export const mapAction = (data) => {
  return ({
    type: MAP_ACTION,
    payload: data
  })
}

export const GET_ONE_STOP = 'GET_ONE_STOP'

export const getOne = data => {
  return {
    type: GET_ONE_STOP,
    payload: {
      method: 'findOne',
      requestMethod: 'GET',
      data,
    },
    meta: {
      resource: 'stops',
      fetch: 'REMOTE'
    },
  }
}

export const changeRoute = (isChanged) => ({
  type: CHANGE_ROUTE,
  isChanged
})