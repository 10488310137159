import React, { Component, createElement, Fragment } from 'react'
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import Hidden from '@material-ui/core/Hidden'
import classnames from 'classnames'
import deepmerge from 'deepmerge'
import PropTypes from 'prop-types'
import { defaultTheme, Error, Notification, Sidebar } from 'react-admin'
import Menu from './../menu/index'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import compose from 'recompose/compose'
import AppBar from './AppBar'
import Breadcrumb from '../breadcrumb/Breadcrumb'
import NoSsr from '@material-ui/core/NoSsr'
import { JssProvider } from 'react-jss'
import { Typography } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import CallCenter from '../calllog/CallCenter'
import clsx from 'clsx'

const lightTheme = deepmerge(defaultTheme, {
  typography: {
    fontSize: 12,
    useNextVariants: true,
  },
  spacing: {
    unit: 6,
  },
  overrides: {
    MuiDrawer: {
      docked: {
        backgroundColor: 'transparent',
        // scroll leftmenu
        position: 'fixed',
        overflowY: 'auto',
        zIndex: 1300,
        marginTop: '3em',
        height: '100%'
      }
    },
    FilterForm: {
      card: {
        backgroundColor: 'white',
        display: 'block',
      },
      body: {
        display: 'block',
      },
      spacer: {
        display: 'none',
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: 'white'
      },
      elevation1: {
        boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.06), 0px 3px 4px 0px rgba(0, 0, 0, 0), 0px 3px 3px -2px rgba(0, 0, 0, 0)',
      },
    },
    MuiMenuItem: {
      root: {
        paddingRight: '1.5em'
      }
    },
    MuiIconButton: {
      root: {
        width: 48,
        height: 48
      }
    },
    MenuItemLink: {
      root: {
        color: '#fff'
      },
      icon: {
        color: '#fff'
      },
      active: {
        color: '#fff'
      }
    },
    MuiGrid: {
      item: {
        padding: 12,
      }
    },
    TabbedForm: {
      form: {
        // eslint-disable-next-line
        ['@media (min-width: 600px)']: {
          padding: 0,
        },
        // eslint-disable-next-line
        ['@media (max-width:599.95px)']: {
          padding: 0,
        },
        marginRight: -24,
      },
    },
    SimpleFormIterator: {
      root: {
        margin: 1000
      },
      form: {
        backgroundColor: 'black',
        flex: 0
      }
    },
  },
})

export const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    position: 'relative',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    minHeight: '100vh'
  },
  contentContainer: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end'
  },
  content: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    padding: '66px 18px 18px 18px',
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 240px)',
    },
    background: '#eee'
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -240,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '48px 0px 18px 0px',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(100% - 55px)',
      padding: '66px 18px 18px 18px',
    },
    background: '#eee'
  }
})

const sanitizeRestProps = ({
  staticContext,
  history,
  location,
  match,
  ...props
}) => props

class Layout extends Component {


  constructor(props) {
    super(props)
    /**
     * Reset the error state upon navigation
     *
     * @see https://stackoverflow.com/questions/48121750/browser-navigation-broken-by-use-of-react-error-boundaries
     * */
    this.state = { hasError: false, errorMessage: null, errorInfo: null }
    props.history.listen(() => {
      this.setState({ historyVersion: (this.state.historyVersion || 0) + 1 })
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentDidCatch(errorMessage, errorInfo) {
    this.setState({ hasError: true, errorMessage, errorInfo })
  }

  render() {
    const {
      appBar,
      children,
      classes,
      className,
      customRoutes,
      error,
      dashboard,
      logout,
      menu,
      notification,
      open = true,
      title,
      ...props
    } = this.props
    const { hasError, errorMessage, errorInfo } = this.state
    let copyright = `\u00A9 ${new Date().getFullYear()} `
    if (props.location.pathname.match(/^\/reservations\/trip_layout/g)) {
      return (
        <NoSsr>
          <div className={classnames('layout', classes.root, className)} {...sanitizeRestProps(props)}>
            {children}
          </div>
          {createElement(notification)}
        </NoSsr>
      )
    }
    return (
      <NoSsr>
        <div className={classnames('layout', classes.root, className)} {...sanitizeRestProps(props)}>
          <div className={classes.appFrame}>
            <Hidden>
              {createElement(appBar, { title, open, logout })}
            </Hidden>
            <Sidebar>
              {createElement(menu, {
                logout,
                open,
                hasDashboard: !!dashboard,
              })}
            </Sidebar>
            <div className={classes.contentContainer}>
              <main className={clsx(classes.content, {
                [classes.contentShift]: !open,
              })}>
                <Breadcrumb version={this.state.historyVersion} />
                {hasError
                  ? createElement(error, {
                    error: errorMessage,
                    errorInfo,
                  })
                  : children}
              </main>
            </div>
            <footer className={classes.footer}>
              <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                {copyright}
                <Link component="a" variant="body2" href="https://www.nexbus.vn" target="_blank">NEXBUS</Link>
              </Typography>
            </footer>
            {createElement(notification)}
          </div>
        </div>
      </NoSsr>
    )
  }
}

const componentPropType = PropTypes.oneOfType([
  PropTypes.func,
  PropTypes.string,
])

Layout.propTypes = {
  appBar: componentPropType,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  classes: PropTypes.object,
  className: PropTypes.string,
  customRoutes: PropTypes.array,
  dashboard: componentPropType,
  error: componentPropType,
  history: PropTypes.object.isRequired,
  logout: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.string,
  ]),
  menu: componentPropType,
  notification: componentPropType,
  open: PropTypes.bool,
  title: PropTypes.node.isRequired,
  translate: PropTypes.func,
  location: PropTypes.object,
}

Layout.defaultProps = {
  appBar: AppBar,
  error: Error,
  menu: Menu,
  notification: Notification,
}

const mapStateToProps = state => ({
  open: state.admin.ui.sidebarOpen,
})

const EnhancedLayout = compose(
  connect(
    mapStateToProps,
    {} // Avoid connect passing dispatch in props
  ),
  withRouter,
  withStyles(styles, { name: 'Layout' })
)(Layout)

class LayoutWithTheme extends Component {
  constructor(props) {
    super(props)
    this.state = {
      theme: props.theme,
      muiTheme: createMuiTheme(props.theme),
    }
  }

  static getDerivedStateFromProps(nextProps, prevState = {}) {
    if (nextProps.theme !== prevState.theme) {
      return {
        theme: nextProps.theme,
        muiTheme: createMuiTheme(nextProps.theme),
      }
    }
    return null
  }

  render() {
    const { theme, ...rest } = this.props
    return <Fragment>
      <JssProvider classNamePrefix="a">
        <MuiThemeProvider theme={this.state.muiTheme}>
          <EnhancedLayout {...rest} />
        </MuiThemeProvider>
      </JssProvider>
      <CallCenter />
    </Fragment>
  }
}

LayoutWithTheme.propTypes = {
  theme: PropTypes.object,
}

LayoutWithTheme.defaultProps = {
  theme: lightTheme,
}

export default LayoutWithTheme
