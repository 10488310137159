import {
  Grid,
  withStyles,
  FormControlLabel,
  Switch
} from '@material-ui/core'
import {
  SimpleForm,
  TextInput,
  translate,
  SelectInput
} from 'react-admin'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
  RESERVATION_UPDATE_BOOKING_INFORMATION,
  doAction
} from './actions'
import _ from 'lodash'
import FormBody from '../common/FormBody'

const styles = {
  container: {
    width: '100%'
  },
  margin: {
    marginTop: 16,
    marginBottom: 8
  }
}

class BookingInformationStep extends Component {

  constructor(props) {
    super(props)
    this.state = {
      usePassengerInfo: false,
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      address: null
    }
  }

  updateState = (data) => {
    this.setState(data,
      () => this.updateBookingInformation())
  }

  updateBookingInformation = () => {
    let { doAction } = this.props
    let { firstName, lastName, phone, email, address } = this.state
    doAction(
      RESERVATION_UPDATE_BOOKING_INFORMATION,
      null,
      { firstName, lastName, phone, email, address }
    )
  }

  onSelectPassengerInfo = (v) => {
    let { passengers } = this.props
    let selectedPassenger = passengers.find(passenger => passenger.index === v)
    let { firstName, lastName, phone, email, address } = selectedPassenger
    this.setState({ firstName, lastName, phone, email, address },
      () => this.updateBookingInformation())
  }

  getPassengerChoices = (passengers) => {
    let res = {}
    if (passengers && passengers.length > 0) {
      res = _.mapKeys(passengers, passenger => passenger.phone)
    }

    return Object.values(res)
  }

  render() {
    let { classes, translate, passengers } = this.props
    let { usePassengerInfo } = this.state
    let { firstName, lastName, phone, email, address } = this.state

    return (
      <SimpleForm
        form="booking-information-form"
        record={{ firstName, lastName, phone, email, address }}
        toolbar={null}>
        <FormBody>
          <Grid container
            className={classes.container}
            spacing={8}>

            <Grid item xs={1}> </Grid>
            <Grid item xs={2}>
              {/* usePassengerInfo */}
              <FormControlLabel
                className={classes.margin}
                control={
                  <Switch
                    color="primary"
                    checked={usePassengerInfo}
                    onChange={(k, v) => this.setState({ usePassengerInfo: v })}
                    value="usePassengerInfo"
                  />
                }
                label={translate('resources.reservations.usePassengerInfo')}
              />
            </Grid>

            <Grid item xs={9}>
              {
                usePassengerInfo &&
              <SelectInput
                label={translate('resources.reservations.passenger.label')}
                choices={this.getPassengerChoices(passengers)}
                optionText={(choice) => {
                  let { firstName, lastName, phone } = choice
                  let passengerName = ''
                  if (lastName && firstName) {
                    passengerName = `${lastName}, ${firstName}- ${phone}`
                  } else {
                    passengerName = phone
                  }
                  return passengerName
                }}
                optionValue="index"
                onChange={(k, v) => this.onSelectPassengerInfo(v)}
              />
              }
            </Grid>

            <Grid item xs={1}> </Grid>
            <Grid container item xs={10}>
              <Grid item xs={6}>
                <TextInput
                  label={translate('resources.reservations.passenger.firstName')}
                  source="firstName"
                  onChange={(e, value) => this.updateState({ firstName: value })}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  label={translate('resources.reservations.passenger.lastName')}
                  source="lastName"
                  onChange={(e, value) => this.updateState({ lastName: value })}
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  label={translate('resources.reservations.passenger.phone')}
                  source="phone"
                  onChange={(e, value) => this.updateState({ phone: value })}
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={6}>
                <TextInput
                  label={translate('resources.reservations.passenger.email')}
                  source="email"
                  type="email"
                  onChange={(e, value) => this.updateState({ email: value })}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <TextInput
                  label={translate('resources.reservations.passenger.address')}
                  source="address"
                  onChange={(e, value) => this.updateState({ address: value })}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </FormBody>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect((state) => {
    let { reservation } = state
    let { passengers } = reservation
    return { passengers }
  },
  {
    doAction
  }
  )
)

export default enhance(BookingInformationStep)
