import React, { Children, cloneElement, Component } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import get from 'lodash/get'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { withStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { translate, FormInput } from 'react-admin'
import classNames from 'classnames'

const styles = theme => ({
  root: {
    padding: 0,
    marginBottom: 0,
    '& > li:last-child': {
      borderBottom: 'none',
    },
  },
  card: {
    margin: 16,
    boxShadow: '0px 1px 8px 0px rgba(0, 0, 0, 0.06), 0px 3px 4px 0px rgba(0, 0, 0, 0), 0px 3px 3px -2px rgba(0, 0, 0, 0)',
    backgroundColor: 'white'
  },
  line: {
    display: 'inline-flex',
    padding: 16,
    borderBottom: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.down('xs')]: { display: 'block' },
    '&.fade-enter': {
      opacity: 0.01,
    },
    '&.fade-enter-active': {
      opacity: 1,
      transition: 'all 200ms ease-in',
    },
    '&.fade-exit': {
      opacity: 1,
    },
    '&.fade-exit-active': {
      opacity: 0.01,
      transition: 'all 200ms ease-in',
    },
  },
  index: {
    width: '3em',
    paddingTop: '1em',
    [theme.breakpoints.down('sm')]: { display: 'none' },
  },
  form: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  action: {
    paddingTop: '0.5em',
  },
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
})

export class GridFormIterator extends Component {
  constructor(props) {
    super(props)
    this.nextId = props.fields.length
      ? props.fields.length
      : props.defaultValue
        ? props.defaultValue.length
        : 0
    this.ids = this.nextId > 0 ? Array.from(Array(this.nextId).keys()) : []
  }

  removeField = index => () => {
    const { fields } = this.props
    this.ids.splice(index, 1)
    fields.remove(index)
  };

  addField = () => {
    const { fields } = this.props
    this.ids.push(this.nextId++)
    fields.push({})
  };

  render() {
    const {
      basePath,
      classes = {},
      children,
      fields,
      meta: { error, submitFailed },
      record,
      resource,
      source,
      translate,
      disableAdd,
      disableRemove,
    } = this.props
    const records = get(record, source)
    return fields ? (
      <ul className={classes.root}>
        {submitFailed && error && <span>{error}</span>}
        <TransitionGroup>
          {fields.map((member, index) => (
            <CSSTransition
              key={this.ids[index]}
              timeout={300}
              classNames="fade"
            >
              <li className={classNames(classes.line, classes.card)}>
                <Typography
                  variant="body1"
                  className={classes.index}
                >
                  {index + 1}
                </Typography>
                <section className={classes.form}>
                  {Children.map(children, (input, index2) => {
                    return (
                      <FormInput
                        basePath={
                          input.props.basePath || basePath
                        }
                        input={cloneElement(input, {
                          source: input.props.source
                            ? `${member}.${input.props.source}`
                            : member,
                          index: input.props.source
                            ? undefined
                            : index2,
                          label:
                            input.props.label ||
                            input.props.source,
                        })}
                        record={
                          (records && records[index]) ||
                          {}
                        }
                        resource={resource}
                      />
                    )
                  })}
                </section>
                {!disableRemove && (
                  <span className={classes.action}>
                    <Button
                      className={classNames(
                        'button-remove',
                        `button-remove-${source}-${index}`
                      )}
                      size="small"
                      onClick={this.removeField(index)}
                    >
                      <CloseIcon
                        className={classes.leftIcon}
                      />
                    </Button>
                  </span>
                )}
              </li>
            </CSSTransition>
          ))}
        </TransitionGroup>
        {!disableAdd && (
          <li className={classes.line}>
            <span className={classes.action}>
              <Button
                className={classNames(
                  'button-add',
                  `button-add-${source}`
                )}
                size="small"
                onClick={this.addField}
              >
                <AddIcon className={classes.leftIcon} />
                {translate('ra.action.add')}
              </Button>
            </span>
          </li>
        )}
      </ul>
    ) : null
  }
}

GridFormIterator.defaultProps = {
  disableAdd: false,
  disableRemove: false,
}

GridFormIterator.propTypes = {
  defaultValue: PropTypes.any,
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  fields: PropTypes.object,
  meta: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string,
  resource: PropTypes.string,
  translate: PropTypes.func,
  disableAdd: PropTypes.bool,
  disableRemove: PropTypes.bool,
}

export default compose(
  translate,
  withStyles(styles)
)(GridFormIterator)
