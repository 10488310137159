import english from 'ra-language-english'

export default {
  ...english,
  'ra.page.list': '%{name}',
  button: {
    reset: 'Reset',
    remove: 'Remove',
    refresh: 'Refresh',
    pairDevice: 'Pair device',
    create_maintenance: 'Create maintenance',
    create_trip: 'Create Trip',
    template: 'Template',
    view_by_time: 'View by time',
    view_by_route: 'View by route',
    ok: 'OK',
    cancel: 'Cancel',
    save: 'Save',
    search: 'Search',
    validate: 'Validate',
    import: 'Import',
    close: 'Close',
    assign_vehicle: 'Assign vehicle',
    clear_all_stops: 'Clear all stops',
    create_stop: 'Create stop',
    save_route: 'Save route',
    smart_save_route: 'Smart save route',
    set_as_end: 'Set as end',
    set_as_start: 'Set as start',
    attach: 'Attach',
    detach_stop: 'Detach stop',
    edit_stop: 'Edit stop',
    remove_stop: 'Remove stop',
    assign_driver: 'Assign driver',
    back: 'Back',
    next: 'Next',
    finish: 'Finish',
    add: 'Add',
    create_vehicle_type: 'Create vehicle type',
    create_licence: 'Create license',
    add_vehicle: 'Add vehicle',
    add_driver: 'Add driver',
    assign: 'Assign',
    unassign: 'Unassign',
    create_service_task: 'Create service task',
    swap_stop: 'Inverse',
    create: 'Create',
    calendar: 'Calendar',
    list: 'Table',
    make_done: 'Make done',
    restore: 'Restore',
    renewal: 'Renewal',
    view_detail: 'View detail',
    reservation_extra: 'Reservation extra',
    reset_password: 'Reset password',
    change_password: 'Change password',
    assign_role: 'Assign role',
    register_company: 'Register company',
    unregister_company: 'Unregister company',
    edit: 'Edit',
    register_fare: 'Register fare',
    unregister_fare: 'Unregister fare',
    unregister_route: 'Unregister route',
    unregister_agency: 'Unregister agency',
    add_agency: 'Add agency',
    add_setting: 'Add settings',
    download_file: 'Download schedule template',
    assign_fare: 'Assign fare',
    add_finance_account: 'Add finance account',
    add_reference: 'Add company / agency',
    add_supplier: 'Add supplier',
    download_ticket: 'Download ticket',
    download_reservations: 'Download reservations',
    download_confirmed_reservations: 'Confirmed reservations',
    paid: 'Pay',
    cancel_reservation: 'Cancel all',
    cancel_reservation_2: 'Cancel reservation',
    cancle_charge: 'Cancel selected charge',
    setup_passenger_collection: 'Setup pickup / dropoff point',
    unassign_driver: 'Unassign driver',
    unassign_vehicle: 'Unassign vehicle',
    unassign_assistant_vehicle: 'Unassign assistant driver',
    setup_driver: 'Setup driver',
    setup_vehicle: 'Setup vehicle',
    setup_assistant_driver: 'Setup assistant driver',
    edit_booking_information: 'Edit booking information',
    archive: 'Archive',
    help: 'Help',
    notification: 'Notification',
    topUp: 'Top up',
    withdraw: 'Withdraw',
    create_reconciliation: 'Create reconciliation',
    export: 'Export',
    payment_reconciliation: 'Payment',
    remote_print: 'Remote print',
    swap_seat: 'Swap seat',
    swap_trip: 'Swap trip',
    remote_payment_qr: 'Remote payment QR',
    paid_from_customer: 'Paid online',
    paid_from_agency: 'Agency paid',
    pay_later: 'Pay later',
    pay: 'Pay',
    pay_cash: 'Cash',
    add_new_fare: 'New fare',
    register_agency: 'Register agency',
    continue: 'Continue',
    linkToTrip: 'Go to trip',
    print_seat_map: 'Print seat map',
    add_child_product: 'Add child product',
    remove_child_product: 'Remove child product',
    setting: 'Setting',
    addEventTypes: 'Add event types',
    addUserContacts: 'Add user contacts',
    register_member_card: 'Register member card',
    recharge: 'Recharge member card',
    recharge_member_card: {
      pay_cash: 'Pay cash',
      pay_online: 'Pay online',
    },
    print: 'Print',
    issue_invoice: 'Issue invoice',
    einvoice_sumary: 'Export sumary einvoice',
  },
  notification: {
    updated: 'Update successfully',
    vehicle_plate_invalid: 'The form is not valid. Please check for errors.',
    qrCodeHasExpired: 'Code has expired!',
    save_seatmaps_sucess: 'Save seat map successfully!',
    save_seatmaps_fail: 'Save seat map failed!',
    assign_role_success: 'Assign user successfully',
    assign_role_failure: 'Assign user fail',
    unassign_role_success: 'Unassign user successfully',
    unassign_role_failure: 'Unassign user fail',
    reset_password_success: 'Reset password successfully',
    reset_password_failure: 'Reset password fail',
    change_password_success: 'Change password successfully',
    change_password_failure: 'Change password fail',
    create_user_success: 'Create user successfully',
    save_seatmaps_warning: 'Seats are empty, do you still want to save?',
    create_user_failure: 'Create user fail',
    update_user_success: 'Update user successfully',
    update_user_failure: 'Update user fail',
    create_vehicle_success: '%{count} vehile(s) are added',
    create_vehicle_failure: 'Add vehicle doesn\'t successfully',
    vehicle_type_not_exists:
      'Vehicle type is not exists. Please click to create new vehicle type',
    error_vehicle_exists:
      'Add vehicle doesn\'t successfully - %{count} vehicle(s) existed',
    seat_map_not_exists: 'Seat map is not exists',
    import_trip_success: 'Imported %{amount} trips',
    import_trip_exists: 'Trips is exists',
    import_trip_failure: 'Import failure',
    delete_trip_success: 'Delete this trip successfully',
    delete_trip_failure: 'Delete this trip fail',
    assigned_vehicle_success: 'Assign vehicle successfully',
    assigned_vehicle_failure: 'Assigned trip fail',
    clear_all_stop: 'Do you want to clear all stops?',
    clear_all_stop_success: 'Clear all stops success',
    save_route: 'Do you want to save this route?',
    smart_save_route:
      'Do you want to apply data from the route in the opposite direction?',
    save_route_success: 'Save route success',
    set_end_point: 'Do you want to change this stop to end point?',
    set_start_point: 'Do you want to change this stop to start point?',
    set_end_point_success: 'Set end point success',
    set_start_point_success: 'Set start point success',
    create_stop_success: 'Create stop success',
    update_stop_success: 'Update stop success',
    remove_stop: 'Do you want to this stop?',
    remove_stop_success: 'Remove stop success',
    remove_stop_failure: 'Remove stop fail',
    no_route: 'Can\'t pass to this stop',
    delete_all_stops:
      'All stops are removed from this route when it happens successfully',
    unassigned_vehicle_to_trip: 'Unassigned vehicle to trips',
    info_vehicle_not_exsist: 'Infomation of vehicle is not added',
    select_vehicle_type_uncompleted:
      'Vehicle type is not selected. Please come back step 1 again',
    add_info_uncompleted:
      'Infomation of vehicle is not added. Please come back step 2 again',
    reservation: {
      success: 'Make new reservation successfully!',
      error: 'Make new reservation failure!',
      missingTrip: 'Please select a valid trip!',
      missingPassenger: 'Please select a seat and fill passenger information!',
      missingCharge: 'Invalid seat information!',
      missingPayment: 'Please select a valid payment method!',
      missingContact: 'Please enter valid contact information!',
      missingPhone: 'Please enter valid phone number!',
      missingName: 'Please enter valid name!',
      missingTransactionInfo: 'Please enter all payment infomation!',
      cancelSuccess: 'Cancel successfully!',
      cancelFail: 'Fail to cancel!',
      updateBookingInfoSuccess: 'Update booking information successfully',
      updateBookingInfoFail: 'Update booking information fail',
    },
    create_trip_success: 'Trips are created successfully',
    create_trip_failure: 'Trips are created fail',
    assign_success: 'Assign successfully',
    assign_failure: 'Assign fail',
    unassign_success: 'Unassign successfully',
    unassign_failure: 'Unassign fail',
    save_service_task_success: 'Save service task successfully',
    save_service_task_failure: 'Save service task fail',
    save_service_reminder_success: 'Save service reminder successfully',
    save_service_reminder_failure: 'Save service reminder fail',
    licence_unassign: 'The licence is not assigned',
    licence_expired: 'The licence is expired',
    days_expired: 'Exprired is %{days} day(s)',
    prevent_notification: 'Prevent notification',
    delete_reminder_success: 'Delete reminder successfully',
    delete_reminder_failure: 'Delete reminder fail',
    make_done_reminder_success: 'Reminder is done',
    make_done_reminder_fail: 'Make done fail',
    make_restore_success: 'Restore successfully',
    make_restore_fail: 'Restore fail',
    to: 'to',
    renewal_success: 'Renewal is success',
    renewal_failure: 'Renewal is fail',
    no_licence: 'No licences',
    assign_driver_success: 'Assign driver successfully',
    assign_driver_failure: 'Assign driver fail',
    sent_notification_success: 'Sent notification successfully',
    sent_notification_failure: 'Sent notification fail',
    register_company_success: 'Register company successfully',
    register_company_failure: 'Register company fail',
    empty_trip: 'No trip found. Please create new trip for this day',
    register_fare_success: 'Register fare successfully',
    register_fare_failure: 'Register fare fail',
    unregister_fare_success: 'Unregister fare successfully',
    unregister_fare_failure: 'Unregister fare fail',
    edit_product_route_success: 'Edit fare registration successfully',
    edit_product_route_failure: 'Edit fare registration fail',
    warning_unregister_fare: 'Do you want to unregister fare ?',
    warning_change_numOfColRow_seatmaps:
      'Changes will delete the current seat map!',
    unassign_fare_to_trip:
      'Trip doesn\'t assigned fare, Please click "Assign fare" button to assign fare',
    no_reference: 'No refer to Company / Agency',
    no_reference_user: 'No refer to Company / Agency',
    add_supplier: 'Attach this user to supplier',
    add_principal_success_user: 'Assign to Company / Agency successfully',
    remove_principal_success_user: 'Unassign to Company / Agency successfully',
    add_principal_fail_user: 'Assign to Company / Agency fail',
    add_principal_success: 'Assign to Company / Agency successfully',
    remove_principal_success: 'Unassign to Company / Agency successfully',
    add_principal_fail: 'Assign to Company / Agency fail',
    way_to_create_schedule: 'Please link to ROUTE to create new schedule',
    title_unassign_company: 'Unassign company',
    confirm_unassign_company: 'Do you want to unassign to this company ?',
    title_unassign_agency: 'Unassign agency',
    confirm_unassign_agency: 'Do you want to unassign to this agency ?',
    unassign_agency_success: 'Unassign to agency successfully',
    unassign_agency_failure: 'Unassign to agency fail',
    unassign_company_success: 'Unassign to company successfully',
    unassign_company_failure: 'Unassign to company fail',
    confirm_unassign_route: 'Do you want to unassign this route ?',
    confirm_unassign_vehicle_driver:
      'Do you want to unassign this vehicle/ driver ?',
    update_company_success: 'Edit company successfully',
    update_company_failure: 'Edit company fail',
    update_agency_success: 'Edit agency successfully',
    update_agency_failure: 'Edit agency fail',
    set_up_sale_time_success: 'Setup sale time successfully',
    set_up_sale_time_failure: 'Setup sale time fail',
    setup_passenger_collection_success:
      'Setup pickup point / dropoff point successfully',
    setup_passenger_collection_fail: 'Setup pickup point / dropoff point fail',
    pickup_point_empty:
      'Pick up point is empty. Please go to network planing to setup',
    drop_off_point_empty:
      'Drop off point is empty. Please go to network planing to setup',
    warning_unassign_vehicle:
      'Do you want to unassign vehicle from selected trips ?',
    assign_assistant_driver_success: 'Assign assistant driver successfully',
    assign_assistant_driver_failure: 'Assign assistant driver fail',
    unassign_assistant_driver_success: 'Unassign assistant driver successfully',
    unassign_assistant_driver_failure: 'Unassign assistant driver fail',
    warning_unassign_assistant_driver:
      'Do you want to unassign assistant driver ?',
    archive_routegroup_success: 'Archive routegroup successfully',
    archive_routegroup_failure: 'Archive routegroup fail',
    import_schedule_success: 'Import schedule successfully',
    topUp_success: 'Topup successfully',
    topUp_failure: 'Topup fail',
    withdraw_success: 'Withdraw successfully',
    withdraw_failure: 'Withdraw fail',
    create_reconciliation_success: 'Create reconciliation successfully',
    create_reconciliation_failure: 'Create reconciliation fail',
    update_reconciliation_success: 'Update reconciliation successfully',
    update_reconciliation_failure: 'Update reconciliation fail',
    payment_reconciliation_success: 'Payement reconciliation successfully',
    payment_reconciliation_failure: 'Payement reconciliation fail',
    swap_seat_success: 'Swap seat successfully',
    sale_ticket_success: 'Sale ticket successfully',
    change_commission_success: 'Change commission successfully',
    change_commission_failure: 'Change commission fail',
    empty_route_tour: 'No route to support to sale ticket tour',
    empty_product: 'No ticket for this route',
    warning_no_paired_device: 'No paired device',
    warning_no_agency_paired_device: 'Please scan QR code to paired device.',
    remote_print_confirm: 'Remote print ticket',
    have_paired_device: 'Paired device. Do you want to remote print ticket?',
    change_quantity_success: 'Update quantity of product successfully',
    change_quantity_failure: 'Update quantity of product fail',
    unlimit_quantity: 'If do not enter, the value is unlimited',
    no_new_platform_debit_transaction:
      'No new platform transaction to create new reconciliation',
    driver_assigned_those_trips: 'This driver is assigned to those trips',
    name: 'Notification',
    warning_no_selected_seat:
      'No seats selected. Please select from the left seat map',
    change_product_fare_code_success: 'Change product fare code successfully',
    change_product_fare_description_success:
      'Change product fare description successfully',
    change_product_fare_code_failure: 'Change product fare code fail',
    change_product_fare_description_failure:
      'Change product fare description fail',
    add_child_product_success: 'Add product successfully',
    remove_child_product_success: 'Remove product successfully',
    change_fare_success: 'Change fare successfully',
    change_fare_failure: 'Change fare fail',
    register_agency_success: 'Register agency successfully',
    register_agency_failure: 'Register agency fail',
    assign_tag_success: 'Assign tag successfully',
    unassign_tag_success: 'Unassign tag successfully',
    set_default_layout_success: 'Setting default layout successfully',
    delete_ticket_layout_success: 'Delete layout successfully',
    update_customer_info_success: 'Update customer information successfully',
    create_fare_companion_success: 'Create fare companion successfully',
    create_fare_companion_failure: 'Create fare companion fail',
    update_fare_companion_success: 'Update fare companion successfully',
    update_fare_companion_failure: 'Update fare companion fail',
    update_receive_place_success: 'Update receive place successfully',
    create_receive_place_success: 'Create receive place successfully',
    register_member_card_success: 'Register member card successfully',
    recharge_member_card_success: 'Recharge member card successfully',
    send_issue_einvoice_request_success: 'Send issue einvoice request success',
    cancel_charge_fail:
      'Cancel charge fail. Please refresh page to get new status',
  },
  error_messages: {
    required: {
      name: 'Please enter name and delete space not valid',
      campaigns: {
        description: 'Please enter description and delete space not valid',
      },
      drivers: {
        fullname: 'Please enter driver full name and delete space not valid',
      },
      assistant_drivers: {
        fullname:
          'Please enter assistant driver full name and delete space not valid',
      },
      stop: {
        name: 'Stop\'s name is required',
        code: 'Stop\'s code is required',
        code_unique: 'Stop with %{code} exists',
      },
      suppliers: {
        name: 'Please enter supplier name',
      },
      vehicles: {
        plate: 'License plate is required',
      },
      commission: {
        value: 'Required',
      },
      tags: {
        name: 'Please enter tag name',
        space: 'Name is not valid',
      },
      userContacts: {
        messageTypeId: 'Please choose message types',
        recipient: 'Please enter recipient information',
        userSubscriptions: 'Please choose event types you want to subscribe',
      },
      fares: {
        name: 'Please enter ticket fare',
      },
      routes: {
        number: 'Please enter number code and delete space not valid',
        name: 'Please enter name route and delete space not valid',
        plate: 'Please enter plate and delete space not valid',
      },
      licences: {
        number: 'Please enter number',
      },
      viewtrip: {
        min_length_name: 'Must be %{min} characters at least',
        max_length_name: 'Must be %{max} characters or less',
      },
      member_cards: {
        code: 'Please enter member card code and delete space not valid',
        segmentId: 'Required',
      },
      receive_places: {
        name: 'Please enter name receive place and delete space not valid',
      },
    },
    invalid: {
      drivers: {
        phone: 'Phone number is not valid',
        email: 'Email must be abc@domail.com',
        validFrom: 'ValidFrom must be before to ValidTo',
        validTo: 'ValidTo must be after to ValidFrom',
      },
      assistant_drivers: {
        phone: 'Phone number is not valid',
        email: 'Email must be abc@domail.com',
      },
      services: {
        startDate: 'StartDate must be before to EndDate',
        endDate: 'EndDate must be after to StartDate',
      },
      viewtrips: {
        departureTime_gte: 'ToDate must be after FromDate',
        departureTime_lte: 'FromDate must be before ToDate',
      },
      users: {
        confirm_password: 'Confirm password is not matched',
        phone: 'Phone number is not valid',
      },
      payment: {
        paid_must_be_more_than_zero: 'Paid must be more than 0đ',
        paid_must_be_less_than_total: 'Amount is too big, maximun is %{debt}',
      },
      campaign: {
        discount_must_be_more_than_zero: 'Discount must be more than 0',
        discount_must_be_less_than_100:
          'Number of percent must be less than 100%',
        discount_must_be_less_than_budget: 'Discount must be less than budget',
        budget_must_be_more_than_discount: 'Budget must be more than discount',
        sum_of_three_part_must_be_100_percent: 'Sum of three part must be 100%',
        number_percent_must_be_between_0_an_100:
          'Number of percent must be between 0% and 100%',
      },
      commission: {
        invalid_percent_value: 'Percent commission must be between 0 and 100%',
        invalid_value: 'Commission value must not be less than 0',
        wrong_format_and_nagetive:
          'Format of commission must be 1000 or 10%. Value must be positive and do not exceed 100 with percent number',
      },
      product_fare_code: 'Code is too long! Maximun is 6 characters',
      amount_child_fare:
        'Amount of fare is invalid. Fare can not exceed %{maxAmount} |||| Sum of amount of other fares is equal amount of parent product',
    },
    codes: {
      member_cards: {
        error_invalid_code_member_card: 'Không tìm thấy số thẻ!',
        error_member_card_is_existed: 'Phôi vé đã tồn tại!',
        error_invalid_month: 'Tháng nạp tiền không hợp lệ!',
        error_month_is_recharged:
          'Tháng đã được nạp tiền. Vui lòng chọn tháng khác!',
      },
      receive_places: {
        error_invalid_stop: '',
      },
    },
  },
  resources: {
    profile: 'Profile',
    company: 'Company',
    dashboard: {
      resource_use: 'Resource use',
      on_board: 'On board',
      used: 'Used',
      un_used: 'Unused',
      vehicle: 'Vehicle',
      driver: 'Driver',
      trips: 'Trips',
      used_vehicle: 'Used vehicle',
      total_distance: 'total Distance',
      reservation: 'Reservations',
      total_seat: 'total Seat',
      total_reservation: 'total Reservation',
      total_fee: 'total Fee',
      revenue: 'Revenue',
      view_by: 'View by',
      vehicle_chart: 'Vehicle chart',
      sell_ticket_chart: 'Sell ticket chart',
      number_of_vehicle: 'Number of vehicle',
      cost: 'Cost (million)',
      distance: 'Distance (km)',
      time: 'Time (phút)',
      number_of_ticket: 'Number of ticket',
      revenue_axsis: 'Revenue (triệu đồng)',
      day: 'Date',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      quarter: 'Quarter',
      hour: 'Hour',
      money: 'Money (million)',
      date: 'Date',
      fromDate: 'From date',
      toDate: 'To date',
      granularity: 'Granularity',
    },
    charts: {
      extra_vehicle: 'Extra vehicle',
      vehicle: 'Vehicle',
      ticket: 'Ticket',
      student_ticket: 'Student ticket',
      profit: 'Profit',
      revenue: 'Revenue',
      cost_vehicle: 'Cost vehicle',
    },
    common: {
      month: 'Month',
      saved: 'Saved!',
      position: 'Position',
      sort: 'Sort',
      empty: 'Empty',
      app: 'APP',
      action: 'Action',
      titleDevicesTable: 'Paired devices',
      manipulation: 'Manipulation',
      seconds: 'seconds',
      timeLeft: 'Time remaining',
      seeAll: 'See all',
      markAll: 'Mark all as read',
      noNotification: 'No notification',
      no: 'No.',
      id: 'ID',
      name: 'Name',
      price_table: 'Price table',
      departure_date: 'Departure date',
      departure_time: 'Departure time',
      departure: 'Departure',
      arrival_date: 'Arrival date',
      arrival_time: 'Arrival time',
      arrival: 'Arrival',
      long_name: 'Long name',
      full_name: 'Fullname',
      address: 'Address',
      desc: 'Description',
      type: 'Type',
      status: 'Status',
      edit: 'Edit',
      search: 'Search',
      title_form_info: 'Information',
      title_maintain_calendar: 'Maintainence Calendar',
      title_activity: 'Activity',
      title_trips: 'Trip',
      title_service_task: 'Service task',
      zone: 'Zone',
      city: 'City',
      province: 'Province',
      country: 'Country',
      summary: 'Summary',
      code: 'Code',
      date: 'Date',
      time: 'Time',
      seat: 'Seat',
      warning: 'Warning',
      message_warning_delete: 'Do you want to delete the %{time} trip ?',
      message_warning_assign_vehicle:
        'Do you want to assign vehicle automatically ?',
      block_number: '#Block',
      unassigned_vehicle: 'Unassigned vehicle',
      assigned_vehicle: 'Assigned vehicle',
      assigned_vehicle_label: 'Assign vehicle',
      start_date: 'Start date',
      end_date: 'End date',
      location: 'Location',
      drop_file_here: 'Drop the files here to import',
      delimiter: 'delimiter',
      quoting: 'quoting',
      length: 'Length',
      stops: 'Stops',
      enter_address: 'Enter address',
      confirm: 'Confirm',
      required: 'You must enter value',
      create: 'Create',
      select_vehicle_type: 'Select vehicle type',
      add_info_vehicle: 'Add info vehicle',
      review_info_vehicle: 'Review info vehicle',
      renewal_info: 'Renewal information',
      periodTime: {
        byWeek: 'Week',
        byMonth: 'Month',
        byQuarter: 'Quarter',
      },
      weekValue: 'Week %{value}',
      monthValue: 'Month %{value}',
      quarterValue: 'Quarter %{value}',
      daysOfWeek: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      applyForOptions: {
        buy_time: 'Buy time',
        departure_time: 'Departure time',
      },
      paidAt: 'at',
      no_record: 'No record',
      no_reservation:
        'No information of reservation from this phone number / reservation code',
      search_reservation_placeholder: 'Phone number / reservation code',
      new_fare_more_than_old_fare: 'Total new fare is more than',
      new_fare_less_than_old_fare: 'Total new fare is less than',
      want_to_continue: 'Do you want to continue?',
      old_seatmap: 'Old seatmap',
      new_seatmap: 'New seatmap',
      cancel_reason: 'Reason',
      confirm_cancel_reservation_title: 'Cancel reservation confirm',
      confirm_cancel_charge_tile: 'Cancel charge confirm with id %{chargeId}',
      confirm_cancel_charge_content:
        'You are cancelling charge. Do you want to continue ?',
      template: 'Template of ticket',
      templatelayout: 'Self-designed ticket template',
      child_product: 'Products',
      product_validate_type: {
        expired: 'Expired',
        start_time_type: 'Start time type',
        duration: 'Duration',
      },
      start_time_type: {
        buy_time: 'Buy time',
        first_use: 'First use',
        input: 'Input',
      },
      no_config_validation:
        'Click to config ticket validation |||| No config ticket validation',
      no_config: 'No configuration',
      no_config_commission: 'No config commission',
      all: 'All',
    },
    home: {
      name: 'Home',
    },
    blocks: {
      name: 'Block |||| Blocks',
    },
    routes: {
      name: 'Route |||| Routes',
      name_route: 'Route name',
      long_name: 'Long name',
      number: 'Route No.',
      desc: 'Description',
      type: 'Route type',
      url: 'Url',
      color: 'Color',
      text_color: 'Text color',
      sort_order: 'Position',
      timetable: 'List Timetable',
      distance: 'Distance',
      duration: 'Duration (Min)',
      numberOfPrivateSeat: 'Number of private seat(s)',
      numberOfLeg: 'Number of leg',
      origin: 'Origin',
      destination: 'Destination',
      create_timetable: 'Create timetable',
      create_pattern: 'Create pattern',
      pattern: 'Pattern',
      network_plan: 'Design route',
      count_pattern: '%{count} pattern |||| %{count} patterns',
      count_stop: '%{count} stop |||| %{count} stops',
      count_trip: '%{count} trip |||| %{count} trips',
      count_schedule: '%{count} schedule |||| %{count} schedules',
      edit: 'Edit route',
      edit_timetable: 'Edit timetable',
      create_schedule: 'Create schedule',
      total_route: '%{count} sub route |||| %{count} sub routes',
      no_route: 'No sub route',
      no_stop_for_routepattern: 'Please choose least two stop',
      total_timetable: '%{count} timetable |||| %{count} timetables',
      total_trip: '%{count} trip |||| %{count} trips',
      no_timetable: 'No timetable',
      total_schedule: '%{count} schedule |||| %{count} schedules',
      no_schedule: 'No schedule',
      no_pattern: 'No pattern',
      fare_assigned: 'Assigned fare',
      fare_unassigned: 'Unassigned fare',
      fare_assigned_all: 'All routes',
      fare_assigned_a_part: '%{number} to route(s)',
      add_route: 'Add sub route',
      add_timetable: 'Add timetable',
      no_route_need_create: 'There is no route, please create at least one',
      no_pattern_need_create: 'There is no pattern, please create at least one',
      no_timetable_need_create:
        'There is no pattern, please create at least one',
      no_stop_need_design:
        'There is no stop in route. You must design network plan for 2 sub route (2 ways)',
      no_trip_need_create:
        'Timetable haven\'t got any trip. Please edit timetable, select pattern and add row',
      network_plan_not_enough:
        'You must design network plan for 2 sub route (2 ways)',
      no_schedule_need_create: 'You must create schedule first',
      should_two_way: 'Route group should has 2 sub route (2 ways)',
      no_stop: 'No stop in route',
      create_trips: 'Create trips',
      swap_dimension_route_success: 'Change dimension successfully',
      swap_dimension_route_failure: 'Change dimension fail',
      navigate_when_not_save_route:
        'The route doesn\'t save, Do you want to leave this page ?',
      reload_when_not_save_route:
        'The route doesn\'t save, Do you want to reload this page ?',
      fields: {
        numberOfPrivateSeat: 'Number of private seat(s)',
        name: 'Name',
        routeId: 'Route',
        originId: 'Origin',
        destinationId: 'Destination',
        distance: 'Distance',
        duration: 'Duration',
        status: 'Status',
      },
    },
    schedules: {
      name: 'Schedule |||| Schedules',
      drawer_vehicle_desc:
        'Select vehicles and drag to the first column in schedule, use Shift & Ctrl to select multiple vehicles',
      no_schedule: 'No timetable for above service',
      select_schedule_to_create_trip: 'Select schedule to create trips',
      create_trip_confirm_title: 'Create trip',
      create_trip_unassign_vehicle:
        'Vehicles are not assigned. Do you want to create trip ?',
      empty: 'No schedule found',
      create_schedule_reminder: 'Please create schedule from route/timetable',
      fields: {
        name: 'Name',
        desc: 'Description',
        serviceId: 'Service',
        startDate: 'Start date',
        endDate: 'End date',
        every: 'Frequency interval',
        recurType: 'Recuring type',
        timetableGroupId: 'Timetable',
        createdAt: 'Created at',
      },
      buttons: {
        createTrip: 'Create trips',
      },
    },
    servicetasks: {
      fields: {
        name: 'Name',
        desc: 'Description',
        serviceGroupId: 'Service group',
        status: 'Status',
      },
    },
    services: {
      name: 'Service |||| Services',
      fields: {
        name: 'Service name',
        desc: 'Description',
        days: 'Service days',
        startDate: 'Start date',
        endDate: 'End date',
        status: 'Status',
      },
    },
    ticketlayouts: {
      name: 'Ticket Layout',
      common: {
        default: 'Default',
        setDefault: 'Set default',
        warning_delete_title: 'Confirm',
        warning_delete_content:
          'Are you sure you want to remove this ticket layout?',
      },
      fields: {
        name: 'Name',
        group: 'Group',
        default: 'Default',
        background: 'Background',
        layout: 'Template',
        layoutType: 'Layout print type',
        dynamic: 'Aggregate printing',
        normal: 'Single printing',
      },
    },
    servicereminders: {
      title: {
        create: 'Create reminder',
        update: 'Update reminder',
        confirm_changing: 'Update period reminders',
      },
      fields: {
        principalType: 'Principal type',
        principalName: 'Principal',
        serviceTaskId: 'Of',
        name: 'Title',
        dueAt: 'Due at',
        notifyBefore: 'Notify before',
        notifyFrequencyUnit: '',
        repeat: 'Repeat',
        dueInterval: 'Due interval',
        dueFrequencyUnit: '',
        daysOfMonth: 'Days of month',
        finishType: 'Finish by',
        applyTo: 'Apply to',
        applyToNumber: 'Apply for',
        daysOfWeek: 'Days of week',
        desc: 'Description',
        dueAtGte: 'From',
        dueAtLte: 'To',
        serviceTask: 'Task',
        doneDate: 'Done date',
        status: 'Status',
      },
    },
    stops: {
      name: 'Stop |||| Stops',
      code: 'Code',
      name_stop: 'Stop name',
      address: 'Address',
      desc: 'Description',
      type: 'Stop type',
      url: 'Url',
      lat: 'Latitude',
      lon: 'Longitude',
      fields: {
        name: 'Stop name',
        code: 'Code',
        address: 'Address',
        stopIds: 'Stop list',
      },
      notifications: {
        notFoundAddress: 'Not found address on map!',
      },
      helperText: {
        address: 'Enter address to find on map!',
      },
      create_new_stop: 'Create a new Stop',
    },
    notifications: {
      name: 'Notification',
      fields: {
        title: 'Title',
        subTitle: 'Subtitle',
        body: 'Body',
        badge: 'Badge',
        icon: 'Icon',
        iconUrl: 'Icon url',
        sound: 'Sound',
        priority: 'Priority',
        category: 'Category',
        payload: 'Payload',
        when: 'When',
      },
    },
    einvoices: {
      name: 'E-Invoices',
      fields: {
        erp: 'Invoice code',
        transactionId: 'Transaction',
        invoiceStatus: 'Invoice status',
        signStatus: 'Sign status',
        issuedDate: 'Issued Date',
        createdAt: 'CreatedAt',
      },
      statuses: {
        initial: 'Inital',
        origin: 'Success',
        deleted: 'Delete',
        nexbus_cancel: 'Ticket canceled',
        deleted_cancel: 'Cancel',
        sign_success: 'Sign Success',
        sign_error: 'Sign Error',
        signing: 'Signing',
        sign_waiting: 'Sign waiting',
        sign_initial: 'Sing initial',
      },
      statistic: {
        number_of_unissued: 'Number of unissued envoices',
        number_of_issued: 'Number of issued envoices',
        total: 'Total',
      },
    },
    timetables: {
      name: 'Timetable |||| Timetables',
      data: 'Data',
      route: 'Route',
      routes: {
        name: 'Routes',
      },
      stop: 'Stop',
      seq: 'Sequence',
      legSeq: 'Leg sequence',
      time: 'Time',
      totalStop: 'Total stop',
      stopTimes: {
        name: 'Stop times',
      },
      departure: {
        time: 'Departure time',
        place: 'Departure',
      },
      arrival: {
        time: 'Arrival time',
        place: 'Arrival',
      },
      days: 'Days',
      from: 'from',
      to: 'to',
      hour: 'hour',
      tripPerMinute: 'minute per trip',
      speed: 'Speed',
      pickedItem: '1 item selected |||| %{count} items selected',
    },
    stopTimes: {
      stop: 'Stops',
      departureTime: 'Departure time',
      arrivalTime: 'Arrival time',
      totalStop: 'Total stop',
    },
    vehicles: {
      name: 'Vehicle |||| Vehicles',
      helper_text: 'Search by name or plate',
      generalInformation: 'General Information',
      activity: 'Activity',
      numberOfTripByStatus: '%{status} number of trip',
      maintainHistory: 'Maintainence history',
      fields: {
        name: 'Vehicle type',
        plate: 'License plate',
        model: 'Model',
        capacity: 'Capacity',
        fuelType: 'Fuel type',
        run: 'Run (km)',
        depot: 'Depot',
        images: 'Images',
        seatAvailable: 'Seat available',
        fuel: 'Fuel type',
        color: 'Color',
        brand: 'Brand',
        type: 'Vehicle type',
        status: 'Status',
        vehicleId: 'Vehicle',
      },
      unassign_vehicle: 'Unassign vehicle',
    },
    vehicletypes: {
      name: 'Vehicle type |||| Vehicle types',
      empty_seat_map: 'Seat map not found',
      fields: {
        name: 'Vehicle type name',
        totalSeat: 'Total seat',
        vehicleClass: 'Class',
        seatMap: 'Seat map',
      },
    },
    licences: {
      name: 'Licence |||| Licences',
      fields: {
        licenceNumber: 'Licence Number',
        validFrom: 'Valid from',
        validTo: 'Valid to',
        type: 'Licence type',
        numberOfRenewal: 'Number of renewal',
        renewalPeriod: 'Renewal period',
        renewalPeriodUnit: 'Renewal period by',
        status: 'Status',
        placeOfIssue: 'Place of issue',
        desc: 'Description',
        images: 'Images',
        applyFor: 'Apply for',
        note: 'Note',
        serviceTask: 'Task',
      },
      auto_create_reminder: 'Create reminder automatically',
    },
    licencevalidities: {
      fields: {
        licenceNumber: 'New licence number',
        validFrom: 'New valid from',
        validTo: 'New valid to',
        placeOfIssue: 'New place of issue',
        images: 'New images',
      },
    },
    seatMaps: {
      fist_floor: 'Lower deck',
      second_floor: 'Upper deck',
      empty_seat_map: 'Empty seat map',
    },
    tripstatuses: {
      name: 'Trip status',
      fields: {
        status: 'Trip status',
      },
    },
    trips: {
      name: 'Trip |||| Trips',
      drawer_driver_desc:
        'Select drivers and drag to the first column in trip table, use Shift & Ctrl to select multiple drivers',
      messages: {
        no_trips: 'There is no trip from %{start} to %{end}',
        saveFailed: 'Create trip failed!',
        saveSuccess: 'Create trip successfully!',
        cloneSuccess: 'Copied trip!',
        cloneFailed: 'Cloned faield can\'t clone trip to the previous day',
      },
      status: {
        active: 'Active',
        deparated: 'Deparated',
        arrived: 'Arrived',
        inactive: 'Inactive',
        cancelled: 'Cancelled',
        completed: 'Completed',
      },
      fields: {
        generalInformation: 'General information',
        history: 'History',
        tickets: 'Tickets',
        departureTime: 'Departure time',

        capacity: 'Capacity',
        seatAvailable: 'Seat available',
        route: 'Route',
        routeName: 'Route',
        routeNumber: 'Route number',
        tripId: 'Trip Id',
        tripName: 'Trip name',
        arrivalTime: 'Arrival time',
        originId: 'Origin',
        destinationId: 'Destination',
        round: 'Round trip',
        oneWay: 'One-way trip',
        outbound: 'Outbound',
        inbound: 'Inbound',
        desc: 'Description',
        name: 'Name',
        longName: 'Long name',
        departure: 'Departure',
        arrival: 'Arrival',
        duration: 'Duration (Min)',
        distance: 'Distance (Km)',
        actualDepartureTime: 'Actual departure time',
        actualArrivalTime: 'Actual arrival time',
        date: 'Date',
        status: 'Status',
        type: 'Type',
        vehicleId: 'Vehicle',
        driverId: 'Driver 1',
        routeId: 'Route',
        scheduleId: 'Schedule',
        driver2Id: 'Driver 2',
        assistantDriverId: 'Assistant driver',
        newRouteId: 'New route',
        newDepartureDate: 'New departure date',
        newDepartureTime: 'New departure time',
        departureDay: 'Departure day',
        arrivalDay: 'Arrival day',
      },
      buttons: {
        assignDriverSmart: 'Assign driver - Smart',
        unassignDriver: 'Unassign Driver',
        quickCreateTrip: 'Quick create trip',
      },
      dialogs: {
        assignDriverSmart:
          'Drivers will be assigned to trips based on the drivers data in the past',
        unassignDriver:
          'Are you sure you want to reset drivers for the current trips',
      },
      viewBy: {
        status: 'Status',
        driver: 'Driver',
      },
      validate: {
        routeError: 'Route is required!',
        arrivedTimeError: 'Arrival time must be after departure time!',
      },
    },
    drivers: {
      name: 'Driver |||| Drivers',
      fields: {
        avatar: 'Avatar',
        fullName: 'Fullname',
        phone: 'Phone',
        email: 'Email',
        birthday: 'Birthday',
        address: 'Address',
        desc: 'Description',
        status: 'Status',
        licenceNumber: 'Licence number',
        placeOfIssue: 'Place of issue',
        byDriver: 'by driver',
        validFrom: 'Valid from',
        validTo: 'Valid to',
        licenceClass: 'Licence class',
        driverId: 'Driver',
        driver_1: 'Driver 1',
        driver_2: 'Driver 2',
      },
      unassign_driver_1: 'Unassign driver 1',
      unassign_driver_2: 'Unassign driver 2',
    },
    farerules: {
      name: 'Fare rule',
      fields: {
        name: 'Ticket fare',
        desc: 'Description',
        ruleType: 'Rule type',
        ruleValue: 'Rule value',
        conditionName: 'Condition name',
        conditionValue: 'Condition value',
      },
    },
    fares: {
      name: 'Fare |||| Fares',
      type: 'Fare type',
      fields: {
        name: 'Fare name',
        code: 'Fare code',
        desc: 'Description',
        status: 'Status',
        amount: 'Amount',
        currencyId: 'Currency',
        applyFrom: 'Applied from',
        applyRules: 'Applied rules',
        seats: 'Seats',
        price: 'Price',
        total: 'Total',
        type: 'Type',
        conditions: 'Condition',
        routeId: 'Route',
      },
      fares: {
        name: 'Ticket fare',
        amountHelper: 'Fare is calculated from rules!',
      },
      rules: {
        name: 'Fare rule',
        value: 'Value',
        decrease: 'Decrease:',
        increase: 'Increase:',
      },
      prices: {
        name: 'Price',
        amountHelper: 'Price is defined by rule of price',
      },
      error: {
        getListProduct: 'Get list product failed!',
        save: 'Save failed!',
      },
    },
    maps: {
      name: 'Map',
    },
    products: {
      name: 'Product |||| Products',
      no_limit: 'No limit',
      no_product_fare_code: 'No record',
      no_product_fare_description: 'No record',
      other_quantity: 'Other quantity',
      searchHelperText: 'Search by name or description of product',
      route_no_fare: 'The route does not have fares ',
      product_no_route: 'No route is registed',
      product_no_agency: 'No registed agency',
      template_not_exists: 'Template does not exists',
      no_child_product: 'No child product',
      save_success: 'Save success',
      save_failed: 'Change failed',
      empty_supplier: 'Suppliers not found',
      no_supplier: 'No supplier',
      empty_tax: 'Tax not found',
      no_tax: 'No tax',
      fields: {
        name: 'Product name',
        desc: 'Description',
        validDate: 'Valid date',
        agencyCount: 'Registed agency No.',
        routeCount: 'Registed route No.',
        validFrom: 'Valid from',
        validTo: 'Valid to',
        note: 'Note',
        type: 'Product type',
        productfares: {
          route: 'Route',
          amount: 'Amount',
          type: 'Type',
        },
        ticket: {
          duration: 'Duration (second)',
          inclusion: 'Inclusion',
          exclusion: 'Exclusion',
          note: 'More information about ticket',
        },
        quantityType: 'Quantity',
        quantity: 'Quantity',
        tax: 'Tax',
        images: 'Images',
        supplierId: 'Supplier',
        status: 'Status',
      },
      sort: {
        sortTitle: 'Sort product fare',
        sortFare: 'Drag and drop product fares to order the fares in a route.',
        sortRoute: 'Drag and drop route to order multiple fares',
      },
      openAll: 'open all',
      collapseAll: 'collapse all',
    },
    reservations: {
      name: 'Reservation',
      list: 'List',
      generalInfomation: 'General information',
      infoDetail: 'Information detail',
      totalRefund: 'Total refund',
      agencyKeep: 'Agency keep',
      fields: {
        code: 'Code',
        origin: 'Origin',
        destination: 'Destination',
        deviceId: 'Device',
        createdAt_gte: 'From date',
        createdAt_lte: 'To date',
        createdAt: 'Created at',
        updatedAt: 'Updated at',
        status: 'Status',
        agencyId: 'Agency',
        source: 'Source',
        debt: 'Debt',
        total: 'Total',
        paid: 'Paid',
        code_like: 'Code',
        print: 'Print ticket',
        trips: 'Trip',
        fullName: 'Fullname',
        phone: 'Phone',
        email: 'Email',
        reservationDate: 'Reservation date',
        departureDate: 'Departure date',
        productType: 'Product type',
        note: 'Note',
        productAmount: 'Product No.',
        routeId: 'Route',
        paymentMethodId: 'Payment method',
        createdByUser: {
          username: 'CreatedBy',
        },
      },
      steps: {
        trip: 'Trip',
        bookingInformation: 'Booking Information',
        passengers: 'Passengers',
        charges: 'Charges',
        payment: 'Payment',
        review: 'Review',
      },
      trips: 'Trips',
      charges: 'Charges',
      vehiclePlate: 'Vehicle plate',
      origin: 'Origin',
      destination: 'Destination',
      departureTime: 'Departure date',
      totalFoundTrips: 'Found trip',
      fare: 'Fare',
      totalFare: 'Total fare',
      paid: 'Paid',
      debt: 'Debt',
      refund: 'Refund',
      selectedSeat: 'Selected seat',
      selectedTrip: 'Selected trip',
      passenger: {
        label: 'Passenger',
        firstName: 'First name',
        lastName: 'Last name',
        phone: 'Phone',
        email: 'Email',
        address: 'Address',
        class: 'Class',
      },
      payment: {
        method: 'Payment method(s)',
      },
      currency: 'Currency',
      transaction: {
        type: 'Transaction type',
        status: 'Transaction status',
      },
      payLater: 'Pay later',
      enoughMoney: 'Received enough money',
      makeBalance: 'Make balance',
      note: 'Note',
      usePassengerInfo: 'Use passenger information',
      totalAmount: 'Total',
      paidAmount: 'Paid',
      changeAmount: 'Change',
      debtAmount: 'Debt',
      company_create: 'Quick reservation',
      new: 'New',
      prepaid50: 'Prepaid 50.000đ',
      prepaid100: 'Prepaid 100.000đ',
      paidAll: 'Paid all',
      cancelSeatWarning: 'Cancel seat',
      swapSeatWarning: 'Do you want to swap ?',
      cancelSeatConfirm: 'Selected seat',
      cancelAll: 'All seats',
      deposit: 'Deposit',
      place: 'place(s)',
      empty_place: 'empty place(s)',
      error_charge: 'Amount is too much, maximum is %{amount}',
      choose: 'Choose',
      pickUpPoint: 'Pickup point',
      dropOffPoint: 'Drop off point',
      productType: 'Product type',
      product: 'Product',
      amount: 'Price',
      saletickets: 'Sale ticket',
      device: {
        serial: 'Device serial',
      },
      user: {
        createdBy: 'Created By',
        updatedBy: 'Updated By',
      },
      unit: {
        seat: 'seat(s)',
        ticket: 'ticket(s)',
        product: 'product(s)',
      },
      report: {
        number_reservedSeat_time: 'Confirmed tickets',
        number_revenue_time: 'Revenue total',
        number_ticket_total: 'Ticket total',
        revenue_chart_title: 'Revenue chart',
        quantity_chart_title: 'Confirmed tickets quantity chart',
        total_chart_title: 'Total of ticket chart',
        empty_record: 'Data do not exists',
      },
      status: {
        new: 'New',
        standby: 'Stand by',
        confirmed: 'Confirmed',
        cancelled: 'Cancelled',
        checkedIn: 'Check in',
        finalized: 'Finalized',
        expired: 'Expired',
        other: 'Other',
      },
      activity: {
        create: 'Reservation is created',
        update: 'Reservation is changed %{oldValue} to %{newValue}',
        cancel: 'Reservation is cancelled',
        checkin: 'Reservation is checked in',
        print: 'Reservation is printed',
        createdBy: 'Created by %{createdBy}',
        deviceCheckIn: 'Checkin on device %{serial}',
      },
    },
    possessions: {
      name: 'Pos Session',
      searchHelperText: 'Search by user created',
      reservations: 'List reservation',
      other_expenses: 'List expenses',
      other_income: 'List income',
      no_reservation: 'No reservation',
      infoDetail: 'POS Session information detail',
      close_session: 'Close session',
      close_session_detail: 'Are you sure you want to close session ?',
      fields: {
        name: 'Session name',
        startAt: 'Opening date',
        closeAt: 'Closing date',
        startAt_gte: 'From date',
        startAt_lte: 'To date',
        totalPaymentAmount: 'Total payment amount',
        reservationCount: 'Reservation ordered quantity',
        reservationCancelledCount: 'Reservation cancelled quantity',
        chargeCount: 'Charge ordered quantity',
        chargeCancelledCount: 'Charge cancelled quantity',
        balanceStart: 'Balance start',
        balanceCloseReal: 'Balance close real',
        totalExpense: 'Total expense',
        totalRevenue: 'Total revenue',
        totalIncome: 'Total income',
        receiptsExpenses: 'Receipts expenses',
        balanceClose: 'Balance close',
        balanceDiff: 'Balance diff',
        source: 'Source',
        notes: 'Notes',
        status: 'Status',
        createdBy: 'Create by',
        deviceId: 'Device',
        code: 'Code',
        trips: 'Trips',
        productAmount: 'Product amount',
        paid: 'Total paid',
        paidCash: 'Total paid cash',
        paidOnline: 'Total paid online',
        otherExpenses: 'Other expenses',
        otherIncome: 'Other icome',
        images: 'Images',
        note: 'Note',
      },
    },
    membercards: {
      name: 'Membercard',
      list: 'List member card',
      title: {
        recharge: 'Please enter member card code to buy season ticket',
      },
      registration: 'Registration',
      recharge: 'Recharge',
      status: {
        rechargedMonth: 'Ticket is recharged this month',
        noRechargedMonth: 'Ticket is not recharged this month',
      },
      fields: {
        code: 'Membercard Number',
        createdAt: 'Created at',
        createdAt_gte: 'From date',
        createdAt_lte: 'To Date',
        customerName: 'Customer name',
        customerPhone: 'Customer phone',
        customerEmail: 'Customer email',
        segmentName: 'Segment Name',
        status: 'Status',
        amount: 'Amount/Month',
        historyRecharged: 'History recharged',
        rechargeMonth: 'Recharge month',
        rechargedAt: 'Recharged at',
        paidAt: 'Paid at',
      },
    },
    payment: {
      methods: {
        cash: 'Cash',
        atm: 'ATM',
        visa: 'VISA',
        qrCode: 'QR Code',
      },
    },
    routepatterns: {
      name: 'Pattern |||| Patterns',
      fields: {
        name: 'Name',
        routeId: 'Route',
      },
    },
    routegroups: {
      name: 'Route |||| Routes',
      warning_archived_title: 'Do you want to archive this route?',
      warning_archived_content:
        'If this routegroup is archived successfully, The children route, routepattern, timetable, schedule and trip relate to this routegroup will be change ARCHIVED status.\n Routegroups have trips which have reservations can not archive',
      createSubRoute: 'Create subroute(s)',
      fields: {
        name: 'Name',
        countRoute: 'Number of subroute',
        countTimetable: 'Number of timetable',
        countSchedule: 'Number of schedule',
        countTrip: 'Number of trip',
        routeFares: 'Assigned fare',
        type: 'Type',
        subroute: 'Subroute',
      },
    },
    timetablegroups: {
      name: 'Timetable |||| Timetables',
      fields: {
        name: 'Name',
        desc: 'Description',
        routeGroupId: 'Route',
      },
      apply: 'Apply',
      remove: 'Remove',
      create_timetable: 'Create timetable for pattern',
      add_pattern_title: 'Add pattern to timetable',
      add_pattern_desc: 'Select pattern, choose time frame then Apply',
      apply_timetable_desc: 'Please enter time frame and click [Apply]',
      no_service: 'Have not added to timetable yet',
      no_timetable: 'No timetable, please add at least 1 pattern to timetable',
      confirm_remove: 'Do you really want to remove this timetable?',
      hideStop: 'Hide one stop |||| Hide %{smart_count} stops',
    },
    devicemodels: {
      name: 'Device Model |||| Device Models',
      fields: {
        name: 'Name',
        model: 'Model',
        category: 'Category',
        desc: 'Description',
        os: 'Operating system',
        gps: 'GPS',
        wifiVersion: 'Wifi version',
      },
    },
    devices: {
      name: 'Device |||| Devices',
      helper_text: 'Search by name, serial No., identifier',
      fields: {
        name: 'Name',
        serial: 'Serial number',
        imei: 'IMEI',
        deviceId: 'Device identifier',
        userLanguage: 'Language',
        lastSeen: 'Last seen',
        lastLocation: 'Last location',
        installedApp: 'Installed app',
        modelId: 'Model',
        companyId: 'Company',
        operatorId: 'Operator',
        configId: 'Config',
        apps: 'Application',
        status: 'Status',
      },
      push_config: 'Push config to devices',
    },
    apps: {
      name: 'Application',
      fields: {
        name: 'Name',
        applicationId: 'Application id',
        os: 'Operating system',
        versionName: 'Version name',
        versionCode: 'Version code',
        desc: 'Description',
        configId: 'Configuration',
        category: 'Category',
      },
    },
    appconfigs: {
      name: 'Application configuration',
      fields: {
        name: 'Name',
        value: 'Value',
      },
    },
    deviceconfigs: {
      name: 'Device Config |||| Device Configs',
      fields: {
        name: 'Name',
        value: 'Value',
      },
    },
    configurations: {
      name: 'Configuration |||| Configurations',
      fields: {
        code: 'Code',
        value: 'Value',
        group: 'Group',
        desc: 'Description',
        dataType: 'Data type',
        bValue: 'Value',
      },
    },
    tags: {
      name: 'Tag |||| Tags',
      helper_text: 'Search by name',
      fields: {
        name: 'Name',
        color: 'Color',
        status: 'Status',
      },
    },
    companies: {
      name: 'Company |||| Companies',
      helper_text: 'Search by name, address, registration No., tax No.',
      fields: {
        identifier: 'Identifier',
        name: 'Name',
        desc: 'Description',
        longName: 'Long name',
        address: 'Address',
        email: 'Email',
        url: 'URL',
        tel: 'Telephone(s)',
        fax: 'Fax',
        status: 'Status',
        countryId: 'Country',
        provinceId: 'Province',
        cityId: 'City',
        agencyList: 'Agency list',
        taxNumber: 'Tax number',
        registrationNumber: 'Registration number',
        generalInformation: 'General information',
        setting: 'Setting',
        settingMenu: 'Admin Menu Setting',
        settingMenuManager: 'Manager Menu Setting',
        settingPos: 'Pos Setting',
        settingMenuPos: 'Menu Setting',
        settingActionPos: 'Action Setting',
        settingPosAgency: 'Agency Setting',
        settingPosDriver: 'Driver Setting',
        agency: {
          name: 'Agency',
        },
        commission: 'Commission',
        numberOfAgency: 'Agency No.',
        numberOfUser: 'User No.',
        platformDeposit: 'Platform deposit',
        platformCommission: 'Platform commission',
        showOrHideMenu: 'Show or hide menu',
        showOrHideMenuManager: 'Show or hide menu Manager',
        showOrHideMenuPosAgency: 'Show or hide menu Agency',
        showOrHideMenuPosDriver: 'Show or hide menu Driver',
      },
    },
    financeaccounts: {
      name: 'Finance account |||| Finance accounts',
      searchHelperText: 'Search by name or description of finance account',
      helperTextAccountId: 'This account deposited',
      helperPlatformDeposit: 'The platform deposited',
      depositAgencyTitle: 'Topup deposit for agency',
      depositCompanyTitle: 'Topup deposit for company',
      depositPlatformTitle: 'Topup deposit for platform',
      withdrawAgencyTitle: 'Withdraw deposit for agency',
      withdrawCompanyTitle: 'Withdraw deposit from company',
      not_topUp_yet: 'This account hasn\'t deposited yet',
      not_platformDeposit: 'Platform hasn\'t deposited yet',
      warning_deposit_exceed_to_platform_deposit: 'Deposit value exceed to',
      warning_withdraw_exceed_to_platform_deposit:
        'Withdraw exceed to %{money}',
      warning_withdraw_more_than_zero: 'Withdraw value must be more than zero',
      amount_can_withdraw: 'Amount can withdraw %{money}',
      company_agency_not_deposit: 'Company/Agency doesn\'t deposit',
      fields: {
        accountName: 'Account name',
        accountNumber: 'Account number',
        bank: 'Bank',
        belongsTo: 'Company / Agency',
        type: {
          company: 'Company',
          agency: 'Agency',
          platform: 'Platform',
        },
        agencyId: 'Agency',
        agencyAccountId: 'Agency account',
        companyId: 'Company',
        companyAccountId: 'Company account',
        topUpValue: 'TopUp value',
        withdrawValue: 'Withdraw value',
        description: 'Description',
        invoiceNumber: 'Invoice No.',
        journalNumber: 'Journalnumber',
        deposit: 'Deposit',
        platformDeposit: 'Platform deposit',
        platformDebitStatus: 'Deposit type',
      },
    },
    platformdebits: {
      name: 'Platform transactions',
      fields: {
        createdAt: 'Created at',
        debit: 'Debit',
        credit: 'Credit',
        status: 'Status',
        createdAt_lte: 'To',
        createdAt_gte: 'From',
        accountId: 'Account',
        refTransaction: {
          id: 'Reference transaction',
        },
        description: 'Description',
      },
    },
    reconciliations: {
      name: 'Reconciliations',
      reconciledValue_helperText:
        'If the value is less than zero, Platform must be debit. If the value is more than zero, Platform must be credit',
      statementStartDate_helperText: 'Date of unreconciled debit is the oldest',
      statementEndDate_helperText: 'Date of unreconciled debit is the newest',
      balance_less_than_zero_note:
        '** If the balance is less than zero, Account must transfer to platform.',
      balance_more_than_zero_note:
        '** If the balance is more than zero, Account must be recieved from platform.',
      detail: 'Reconciliation detail',
      suggestion_name: 'Reconciliation from %{fromDate} to %{toDate}',
      fields: {
        createdAt: 'Created at',
        debit: 'Debit',
        credit: 'Credit',
        status: 'Status',
        createdAt_gte: 'From',
        createdAt_lte: 'To',
        fromDate: 'From',
        toDate: 'To',
        accountId: 'Account',
        sumOfCredit: 'Amount platform debit',
        sumOfDebit: 'Amount platform credit',
        reconciledValue: 'Amount platform credit/debit',
        desc: 'Description',
        statementStartDate: 'Statement start date',
        statementEndDate: 'Statement end date',
        statementDate: 'Statement date',
        name: 'Name',
        paid: 'Total paid',
        invoiceNumber: 'Invoice No.',
        journalNumber: 'Journal No.',
        platformTransaction: {
          createdAt: 'Payment created at',
        },
        pay: 'Pay',
        recieve: 'Recieve',
      },
    },
    agencies: {
      name: 'Agency |||| Agencies',
      fields: {
        identifier: 'Identifier',
        name: 'Name',
        desc: 'Description',
        address: 'Address',
        email: 'Email',
        url: 'URL',
        tel: 'Telephone(s)',
        fax: 'Fax',
        type: 'Type',
        taxNumber: 'Tax number',
        registrationNumber: 'Registration number',
        longName: 'Long name',
        status: 'Status',
        countryId: 'Country',
        provinceId: 'Province',
        cityId: 'City',
        companyId: 'Company',
        numberOfCompany: 'Company No.',
        deposit: 'Deposit',
      },
    },
    faretables: {
      name: 'Fare table |||| Fare tables',
      addRoute: 'Add route',
      fields: {
        name: 'Name',
        desc: 'Description',
        conditions: 'Criterias',
        routes: 'For routes',
        farePrices: 'Fare prices',
        fareCompanionId: 'Fare companion',
      },
    },
    farecompanions: {
      name: 'FareCompanion |||| FareCompanions',
      no_valid_product: 'This route does not support this product',
      fields: {
        name: 'Name',
        desc: 'Description',
        routeId: 'Route',
        productId: 'Product',
        fareTableId: 'Fare table',
      },
    },
    timeline: {
      name: 'Timeline',
    },
    viewtripsclone: {
      name: 'Clone Trips',
      clonedTrips: 'Cloned Trips',
      countTrips: '%{count} trips',
      cloneComfirm: 'Do you want to clone these trips',
      routeFilter: 'Filter routes',
      cloneTimesTrip: 'Clone times trip',
      unCheckTime: 'Uncheck time you don\'t want to clone',
      openTripTime: 'Choose trip time to clone',
      copyCheckedTime: 'Copy trips',
      cloneAll: 'Clone all',
      cloneByTime: 'Clone By Time',
      copy: 'Copy',
      allRoute: 'All routes',
      customRecurrence: 'Custom recurrence',
      reapeatEvery: 'Repeat every',
      repeatOn: 'Repeat on',
      ends: 'Ends',
      endYear: 'To the end of the year',
      on: 'On',
      after: 'After',
      custom: 'Custom...',
      occurrences: 'occurrences',
      daily: 'Daily',
      weeklyOn: 'Weekly on %{weekdays}',
      monthlyOn: 'Monthly on %{day}',
      everyWeekdays: 'Every weekday (Monday to Friday)',
      noRepeat: 'Does not repeat',
      monday: 'Mo',
      tuesday: 'Tu',
      webnesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    viewtrips: {
      name: 'Trip |||| Trips',
      fields: {
        tripName: 'Trip name',
        routeName: 'Route name',
        departure: 'Departure',
        arrival: 'Arrival',
        departureTime: 'Departure time',
        status: 'Status',
        driverName: 'Driver name',
        vehiclePlate: 'Vehicle plate',
        seatAvailable: 'Seat available',
        reservedSeats: 'Reserved seats',
        timeline: 'Timeline',
        date_gte: 'From date',
        date_lte: 'To date',
        totalFare: 'Total fare',
        makeReservation: 'Make reservation',
        departureTime_gte: 'From date',
        departureTime_lte: 'To date',
        vehicleId: 'Vehicle',
        driverId: 'Driver 1',
        driver2Id: 'Driver 2',
        assignDriver1: 'Assign driver 1',
        assignDriver2: 'Assign driver 2',
        assignVehicle: 'Assign vehicle',
        selectVehicle: 'Select vehicle',
        selectDriver: 'Select driver',
        selectAssistantDriver: 'Select assistan driver',
        type: 'Type',
        saleStart: 'Sale start',
        saleEnd: 'Sale end',
        saleTime: 'Sale time',
        routeId: 'Route',
        originId: 'Origin',
        destinationId: 'Destination',
        helperText_selected_driver1:
          'Selected driver will be driver 1 for this trip',
        helperText_selected_driver2:
          'Selected driver will be driver 2 for this trip',
        assistantDriverId: 'Assistant driver',
      },
      selectUnassignDialog: {
        title: 'Which driver do you unassign ?',
        onlyDriver1: 'Only driver 1',
        onlyDriver2: 'Only driver 2',
        all: 'Driver 1 and driver 2',
      },
    },
    actions: {
      name: 'Action',
      fields: {
        action: 'Action',
      },
    },
    triphistories: {
      name: 'Trip histories',
      fields: {
        field: 'Field',
        oldValue: 'Old value',
        newValue: 'New value',
        desc: 'Description',
        createdAt: 'Created at',
      },
    },
    reminders: {
      name: 'Reminder |||| Reminders',
    },
    maintainance: {
      name: 'Maintainance |||| Maintainances',
    },
    vehiclemaintains: {
      name: 'Vehicle maintance',
      fields: {
        vehicleId: 'Vehicle',
        nextEvent: 'Next maintain item',
        name: 'Name',
        action: 'Action',
        chooseTemplate: 'Choose template',
        chooseVehicle: 'Choose vehicle',
      },
    },
    maintaintemplates: {
      name: 'Maintain template',
      fields: {},
    },
    charges: {
      name: 'Charge',
      select_swap_seat: 'Click to select seat which want to swap',
      fields: {
        id: 'ID',
        createdAt: 'Created at',
        reservationId: 'Reservation',
        productId: 'Product',
        itemDetail: {
          itemCode: 'Item code',
          productId: 'Product name',
          fareId: 'Ticket type',
        },
        amount: 'Amount',
        paid: 'Paid',
        no_paid: 'No paid',
        code: 'Code',
        price: 'Price',
        agency: 'Agency',
        status: 'Status',
        empty_seat: 'Empty',
        reserved_seat: 'Reserved',
        move_to: 'Move to',
      },
      newCharge: 'new charge',
      cancelCharge: 'cancel charge',
      pickUpPoint: 'Pick up point',
      dropOffPoint: 'drop off point',
      fareInfo: 'Fare infomation',
      reserveInfo: 'Reservation infomation',
      empty_pickUpPoint: 'Pick up point empty',
      empty_dropOffPoint: 'Drop off point empty',
    },
    zones: {
      name: 'Zone',
      fields: {
        name: 'Zone',
      },
    },
    seatmaps: {
      name: 'Seatmap |||| Seatmaps',
      fields: {
        name: 'Name',
        firstFloor: 'Lower deck',
        secondFloor: 'Upper deck',
        privateCode: 'Private seat code(s)',
        numberOfFloor: '%{count} deck |||| %{count} decks',
        numOfCol: 'Number of columns',
        numOfRow: 'Number of rows',
        numOfFloor: 'Number of floors',
        numOfDecks: 'Number of decks',
        numOfSeat: 'Number of seats',
        column: 'Column',
      },
    },
    users: {
      name: 'User |||| Users',
      add_role: 'Add role',
      helper_text: 'Search by username, name, phone number, email',
      fields: {
        name: 'Name',
        fullName: 'Fullname',
        username: 'Username',
        password: 'Password',
        email: 'Email',
        phone: 'Phone',
        status: 'Status',
        companyId: 'Company',
        agencyId: 'Agency',
        oldPassword: 'Old password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        userType: 'Apply for',
        userContacts: 'User Contacts',
      },
      userContacts: {
        createUserContact: 'Create User Contact',
        editUserContact: 'Edit User Contact',
      },
      page: {
        create_user: 'New user',
        reset_password: 'Reset password',
        change_password: 'Change password',
      },
      message: {
        save_userContact_success: 'Save User Contact successfully!',
      },
    },
    messagetypes: {
      fields: {
        messageTypeId: 'Message types',
      },
    },
    recipient: {
      fields: {
        recipient: 'Recipient information',
      },
    },
    calllogs: {
      name: 'Call center',
      lastestTrip: '%{number} lastest trips',
      fields: {
        from: 'From',
        to: 'To',
        start: 'Start date',
        duration: 'Duration',
        type: 'Type',
        reservations: 'Reservation code',
        startCall: 'Start call',
        endCall: 'End call',
        startGte: 'From',
        startLte: 'To',
        lastestReservation: 'Lastest',
        noRecord: 'No record',
        status: 'Status',
      },
      report: {
        total: 'Total',
        totalReceived: 'Total received',
        totalMissed: 'Total missed',
        totalReservated: 'Total reservated',
      },
    },
    transactions: {
      name: 'Transaction |||| Transactions',
      title: 'General Infomation',
      fields: {
        transactionNumber: 'Transaction No.',
        total: 'Total',
        paid: 'Amount',
        paidAt: 'Paid date',
        createdAt: 'Created at',
        status: 'Status',
        reservationId: 'Reservation',
        paymentMethodId: 'Payment method',
        type: 'Type',
        createdAt_lte: 'To',
        createdAt_gte: 'From',
        companyId: 'Company',
        decs: 'Note',
        attempt: 'Number of request payment',
        agencyId: 'Agency',
      },
    },
    transactiondetails: {
      title: 'Detail infomation',
      fields: {
        product: 'Product type',
        productCode: 'Product',
        paid: 'Paid',
        total: 'Total',
        company: 'Company',
        agency: 'Agency',
      },
    },
    paymentmethods: {
      name: 'Payment method |||| Payment methods',
    },
    companyagencies: {
      fields: {
        companyId: 'Company',
        type: 'Type',
        status: 'Status',
      },
    },
    routeproducts: {
      fields: {
        routeId: 'Route',
        fareId: 'Fare',
        type: 'Type',
        fares: 'Fares',
        'fares.name': 'Fare name',
        'fares.amount': 'Amount',
        'fares.type': 'Apply for',
        'fares.code': 'Code',
      },
    },
    settings: {
      fields: {
        code: 'Code',
        name: 'Name',
        group: 'Group',
        dataType: 'Data type',
        value: 'Value',
        bValue: 'Value',
        desc: 'Description',
      },
    },
    reports: {
      revenue: 'Revenue',
      totalTrip: 'Total trip',
      totalVehicle: 'Total vehicle',
      totalDriver: 'Total driver',
      runningTrip: 'Running trip',
      finance: 'Finance report',
    },
    accountcredits: {
      name: 'Income',
      fields: {
        createdAt_gte: 'From date',
        createdAt_lte: 'To date',
        createdAt: 'Created at',
        credit: 'Credit',
        'transaction.paid': 'Paid',
        'transaction.reservation.code': 'Reservation code',
        trip: 'Trips',
        company: 'Company',
        'transaction.reservation.agency.name': 'Agency',
        description: 'Description',
      },
    },
    passengercollections: {
      title: 'Setup pick up point / drop off point',
      fields: {
        type: 'Type',
        status: 'Status',
        stopId: 'Related stop',
      },
      helperTextStop:
        'Please select nearest stop to setup relation with pick up point or drop off point',
    },
    campaigns: {
      name: 'Campaign |||| Campaigns',
      promotion_info: 'Promotion information',
      discount_helperText:
        'Amount or number of percent of this campaign is discounted, If % character is appear in the end of input value, this value is discounted by percent',
      promotion_company_helperText: 'Number of percent is invested by company',
      promotion_agency_helperText: 'Number of percent is invested by agency',
      promotion_platform_helperText:
        'Number of percent is invested by platform',
      promotion_limitTime_helperText:
        'Customer use promotion code 1 time in this range of time',
      promotion_countLimit_helperText: 'Set 0 for no times limit',
      helper_text: 'Search by name, description',
      discounted: 'Discounted',
      fields: {
        name: 'Name',
        description: 'Description',
        startTime: 'Start time',
        endTime: 'End time',
        rules: 'Rule',
        fullDescription: 'Full description',
        agencies: 'Apply for agencies',
        routes: 'Apply for routes',
        companies: 'Apply for companies',
        type: 'Type',
        status: 'Status',
        daysOfMonth: 'Day of month',
        daysOfWeek: 'Day of week',
        duration: 'Duration',
        applyFor: 'Apply for',
        promotion: {
          discount: 'Discount',
          discountMax: 'Maximun discount',
          totalLimit: 'Budget',
          company: 'Invest of company',
          agency: 'Invest of agency ',
          platform: 'Invest of platform',
          code: 'Promotion code',
          countLimit: 'Max used times / customer',
          limitTime: 'Range of time apply promotion code',
        },
        time_range_warning:
          'You must enter time range with format: 06:00-18:30',
        time_range_error: 'Start time must be less than end time',
      },
    },
    assistantdrivers: {
      name: 'Assistant driver |||| Assistant drivers',
      fields: {
        fullName: 'Fullname',
        assistantDriverId: 'Assistant driver',
        email: 'Email',
        phone: 'Phone number',
        birthday: 'Birthday',
        address: 'Address',
        desc: 'Description',
        status: 'Status',
        avatar: 'Avatar',
      },
      unassign_assistant_driver: 'Unassign assistant driver',
    },
    customers: {
      name: 'Customer |||| Customers',
      searchHelperText: 'Search by fullName, phone number and email',
      fields: {
        fullName: 'Full name',
        email: 'Email',
        phone: 'Phone',
        firstCompanyId: 'First company',
        firstAgencyId: 'First agency',
        totalPay: 'Total pay',
        totalDiscount: 'Total discount',
        orderCount: 'Order count',
        totalLength: 'Total length',
        lastOrderDate: 'The lastest reserved date',
        reservationCount: 'Number of reservation',
        lastCompany: {
          name: 'The last reserved company',
        },
        lastAgency: {
          name: 'The last reserved agency',
        },
      },
    },
    customercompanies: {
      fields: {
        trips: 'Trip',
        amount: 'Amount',
        reservation: {
          createdAt: 'Reserved date',
        },
      },
    },
    saletickets: {
      name: 'Sale ticket',
      routeInfo: 'Route information',
      ticket: 'Ticket',
      duration_label: 'Valid Range',
      inclusion_label: 'Inclusion',
      exclusion_label: 'Exclusion',
      supplier: 'Supplier',
      company: 'Company',
      seconds: 'Second(s)',
      minutes: 'Minute(s)',
      hours: 'Hour(s)',
      days: 'Day(s)',
      months: 'Month(s)',
      years: 'Year(s)',
      quantity: 'Quantity',
      fareType: 'Fare type',
      unit: 'Unit',
      total: 'Total',
      customerInfo: 'Customer information',
      phone: 'Phone',
      cusName: 'Name',
      email: 'Email',
      paymentMethod: 'Payment method',
      note: 'Note',
      no_select_product: 'Please select a ticket type',
      total_ticket: 'Amount ticket',
      agency_commission: 'Agency commission',
      agency_paid_total: 'Total agency paid',
      departureDate: 'Departure date',
      numberOfProduct: '%{quantity} product(s)',
      product_empty: 'no product',
      companyId: 'Company',
      productName: 'Product',
      sale_ticket_confirm: 'List ticket confirmation',
      type: 'Apply for',
      fareCode: 'Code',
      address: 'Address',
      normal_customer: 'Normal',
      piority_customer: 'Piority',
      one_route: 'One route',
      multi_route: 'Multiple route',
      route: 'Route',
      segment: 'Segment',
      receive_member_card_place: 'Recieve member card place',
    },
    suppliers: {
      name: 'Supplier |||| Suppliers',
      helper_text: 'Search by name, address, phone, email.',
      products: 'List products',
      product_empty: 'No product',
      fields: {
        name: 'Name',
        address: 'Address',
        email: 'Email',
        hashPayment: 'Support Payment',
        statusCode: 'Status',
        quantity_products: 'Quantity',
      },
    },
    agencyproducts: {
      fields: {
        agency: {
          name: 'Agency',
        },
        agencyId: 'Agency',
        agencyQuantityType: 'Quantity',
        agencyQuantity: 'Quantity',
        commission: 'Commission',
        platformCommission: 'Commission of platform',
        platformCommissionScript: 'Commission of platform',
        quantity: 'Quantity',
      },
    },
    commissiontemplates: {
      dialog_title: 'Update commission',
      fields: {
        templateId: 'Commission type',
        params: {
          value: 'Value',
          includePaymentFee: 'Include payment fee',
        },
      },
    },
    finance: {
      name: 'Finance report',
    },
    // pos action
    autoPrint: {
      name: 'Auto Print',
    },
    usePOSSession: {
      name: 'Use POS session',
    },
    cancelTime: {
      name: 'Time cancel reservation in POS',
    },
    //pos menu item
    bar_home: {
      name: 'Home',
    },
    bar_sales: {
      name: 'Sale',
    },
    bar_payment: {
      name: 'Payment',
    },
    menu_transaction: {
      name: 'Transaction',
    },
    bar_check: {
      name: 'Check ticket',
    },
    menu_info: {
      name: 'Information',
    },
    menu_commission: {
      name: 'Commission',
    },
    menu_reloadconfig: {
      name: 'Reload Config',
    },
    menu_logout: {
      name: 'Logout',
    },
    bar_currenttrip: {
      name: 'Current Trip',
    },
    bar_menu: {
      name: 'Menu',
    },
    menu_schedule: {
      name: 'Schedule',
    },
    menu_tickets: {
      name: 'Tickets',
    },
    menu_revenue: {
      name: 'Revenue',
    },
    menu_notification: {
      name: 'Notification',
    },
    menu_changevehicle: {
      name: 'Change Vehicle',
    },
    menu_setting: {
      name: 'Setting',
    },
    menu_session: {
      name: 'Pos session',
    },
    bar_reservations: {
      name: 'Reservations',
    },

    //manager menu item
    VehicleTracking: {
      name: 'Vehicle Tracking',
    },
    Home: {
      name: 'Dashboard',
    },
    Chart: {
      name: 'Charts',
    },
    Profile: {
      name: 'Profile',
    },
    InfoApp: {
      name: 'About us',
    },
    VehicleList: {
      name: 'Vehicles',
    },
    Route: {
      name: 'Route',
    },
    validationproducts: {
      configuration_title: 'Configuration check ticket',
      fields: {
        type: 'Type',
        params: {
          serviceDays: 'Service days',
        },
      },
    },
    einvoicesettings: {
      configuration_title: 'E-Invoice configuration',
      fields: {
        provider: 'Provider',
        allow: 'Issue synchronous',
        not_allow: 'Issue asynchronous',
        serialNo: 'Serial No.',
        templateNo: 'Template No.',
      },
    },
    productcharges: {
      name: 'Product charged |||| Product charged',
      searchHelperText: 'Search by code',
      fields: {
        price: 'Total price',
        net: 'Net price',
        Code: 'Code',
        createdAt_gte: 'From',
        createdAt_lte: 'To',
        createdAt: 'Created at',
        status: {
          name: 'Status',
        },
        product: {
          name: 'Product',
        },
        agency: {
          name: 'Agency',
        },
        user: {
          username: 'Account',
        },
        charge: {
          chargeStatus: {
            name: 'Trạng thái',
          },
          reservation: {
            device: {
              serial: 'Device',
            },
          },
        },
        fromDate: 'Departure',
        firstUse: 'Departure',
      },
    },
    reservations2: {
      name: 'Quick reservation',
    },
    chargereports: {
      name: 'Charge Report',
      fields: {
        fromDate: 'From date',
        toDate: 'To date',
        reportType: 'Report type',
        date: 'Date',
      },
      name: 'Sales Report',
      by_company: 'Report by company',
      by_day: 'Report by day',
      detail: 'Report detail',
      by_product: 'Report by product',
    },
    fareconditions: {
      name: 'Fare Condition',
      fields: {
        id: 'Code',
        name: 'Name',
        default: 'Discount',
        type: 'Type',
        status: 'Status',
      },
    },
    receiveplaces: {
      name: 'Receive Places',
      fields: {
        name: 'Name',
        code: 'Code',
        address: 'Address',
        parentId: 'Zone',
        zones: 'Zones',
      },
    },
    editor: {
      name: 'Design ticket layout',
      common: {
        editObj: 'Edit Object',
        createObj: 'Create Object',
      },
      button: {
        preview: 'Preview',
        save: 'Save',
        delete: 'Delete',
        blankBackground: 'Blank background',
        uploadBackground: 'Upload background',
        cancel: 'Cancel',
        apply: 'Apply',
      },
      fields: {
        x: 'x',
        y: 'y',
        width: 'Width',
        height: 'Height',
        actualWidth: 'Actual width',
        fontSize: 'Font size',
        fontFamily: 'Font Family',
        fontStyle: 'Format',
        size: 'Size',
        content: 'Content',
        rotation: 'Rotation',
        align: 'Align',
        text: 'Text',
        color: 'Color',
        formatType: 'Format type',
      },
    },
    membercardreports: {
      name: 'Member card sales report',
    },
  },
  error: {
    forbidden: 'Forbidden',
    authorization_required: 'Authorization required',
    bad_request: 'Bad request',
    error_request_timeout: 'Request timeout',
    not_found: 'Not found',
    not_acceptable: 'Not acceptable',
    login_failed: 'Login failed',
    change_failed: 'Change failed!',
    undefined: 'Undefined',
  },
}
