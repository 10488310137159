export function validate(values, props){
  let errors = {}
  let { translate } = props

  let number = values && values.number && values.number.trim()
  if (!number || number.length === 0) {
    errors.number = translate('error_messages.required.routes.number')
  }

  let name = values && values.name && values.name.trim()
  if (!name || name.length === 0) {
    errors.name = translate('error_messages.required.name')
  }

  let longName = values && values.longName && values.longName.trim()
  if (!longName || longName.length === 0) {
    errors.longName = translate('error_messages.required.routes.name')
  }

  return errors
}
