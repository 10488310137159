import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { TextField, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

class _CancelTimeInput extends Component {

  render() {
    let { inputRef, options, value = 0, onChange, ...props } = this.props
    return (
      <NumberFormat
        {...props}
        value={value}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: parseInt(values.value),
            },
          })
        }}
        suffix="phút"
      />
    )
  }

}

const styles = {
  input: {
    marginTop: 16,
    marginBottom: 8,
  }
}

class CancelTimeInput extends Component {
  state = {
    value: 30
  }

  loadData = () => {
    let { defaultValue: value } = this.props
    this.setState({ value })
  }

  componentDidMount() {
    this.loadData()
  }

  handleChange = value => {
    this.setState({ value })
  }

  render () {
    let {
      disabled,
      translate,
      nonecss,
      classes,
      label,
      required,
      extraChange,
      autoFocus,
    } = this.props
    let { value } = this.state

    return <TextField
      autoFocus={autoFocus}
      className={!nonecss && classes.input}
      label={translate(label)}
      //helperText={error ? error : helperText}
      InputProps={{
        inputComponent: _CancelTimeInput,
      }}
      //error={!!error}
      value={value}
      disabled={disabled}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        let val = e.target.value
        extraChange && extraChange(val)
        this.handleChange(val)
      }}
      required={required}
      fullWidth
    />
  }
}

CancelTimeInput.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  helperText: PropTypes.string,
  meta: PropTypes.object,
  translate: PropTypes.func,
  input: PropTypes.object,
  classes: PropTypes.object,
  nonecss: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}

const enhanceField = compose(
  withStyles(styles),
  translate,
)

export default enhanceField(CancelTimeInput)
