import React, { Component } from 'react'
import moment from 'moment'
import { getMinFromDate } from '../utils/time'
import CalendarTimeline from '../common/calendar-timeline'
import MenuAction from '../common/MenuAction'
import {
  Paper,
  Grid,
  Fade,
  Typography,
} from '@material-ui/core'
import { getColor } from '../utils/color'
import { difference, findIndex, find, remove } from 'lodash'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { MAIN_PADDING, PAPER_PADDING } from '../common/constants'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'

const ITEM_TYPE = {
  line: 'line',
  trip: 'trip',
  holder: 'holder'
}

const colorLineWarning = 'red'
const colorLineNormal = 'gray'

class ScheduleTimelineComponent extends Component {

  constructor(props) {
    super(props)
    props.onRef(this)
    this.state = {
      displayTooltip: false,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { value, doImport, translate, appBarWidth } = nextProps
    let vehicleLabel = translate('resources.vehicles.name', { smart_count: 2 })
    let {
      value: currentValue,
      groups,
      items,
      lastMin =0,
      appBarWidth: currentAppBarWidth,
    } = prevState
    if (value !== currentValue && difference(value, currentValue).length !== 0) {
      currentValue = value
      if (!doImport && value && items) {
        let newCar = value[value.length - 1]
        if (newCar) {
          let groupId = value.length
          let newGroup = {
            id: groupId,
            title: newCar.id + 1,
            vehicleIdx: groupId - 1,
            isSelected: false,
            rightTitle: `${vehicleLabel} ${newCar.id}`,
            hasBlocks: false
          }
          groups.push(newGroup)
        }
      }
      if (doImport || ((value && value.length > 0) && !items)) {
        groups = []
        items = []
        let beginOfDay = moment().startOf('day')
        let myColors = {}
        let preItems = {}
        let itemTrips = []
  
        value.forEach((car, carIdx) => {
          let groupId = carIdx + 1
          let hasBlocks = car.blocks.length > 0
          groups.push({
            id: groupId,
            title: car.id + 1,
            vehicleIdx: carIdx,
            isSelected: false,
            rightTitle: `${vehicleLabel} ${car.id}`,
            hasBlocks
          })
          car.blocks.forEach((block) => {
            if (block) {
              let { lastStop = {}, firstStop = {}, patternId } = block
              if (!(patternId in myColors)) {
                myColors[patternId] = getColor(Object.keys(myColors).length)
              }
              let start_time = beginOfDay.clone().add(firstStop.min, 'minute')
              // vehilce runs overnight, next day required
              let timespan = lastStop.min
              if (lastStop.min < firstStop.min) {
                timespan += 24 * 60
              }
              let end_time = beginOfDay.clone().add(timespan, 'minute')
              lastMin = Math.max(lastMin, timespan)

              itemTrips.push({
                id: itemTrips.length + 1,
                group: groupId,
                vehicleIdx: carIdx,
                title: car.id + 1,
                canMove: true,
                canResize: false,
                canSelect: true,
                isSelected: false,
                bgColor: myColors[patternId],
                start_time,
                end_time,
                block,
                type: ITEM_TYPE.trip
              })
            }
          })
        })
  
        itemTrips.sort((item1, item2) => item1.start_time - item2.start_time)
  
        let lineId = 10000
        itemTrips.forEach( item => { 
          let preItem = preItems[`${item.group}`]
          if (preItem) {
            let bgColor = colorLineNormal
            if (item.block.patternId === preItem.block.patternId) {
              bgColor = colorLineWarning
            }
            items.push({
              id: lineId,
              group: item.group,
              vehicle: item.vehicle,
              vehicleIdx: item.vehicleIdx,
              title: '',
              canMove: false,
              canResize: false,
              canSelect: false,
              start_time: preItem.end_time,
              end_time: item.start_time,
              type: ITEM_TYPE.line,
              bgColor
            })
            lineId++
          }
          preItems[`${item.group}`] = item
          items.push(item)
        })
      }
    }
    if (appBarWidth !== currentAppBarWidth) {
      currentAppBarWidth = appBarWidth
    }
    return {
      groups,
      items,
      lastMin,
      value: currentValue,
      appBarWidth: currentAppBarWidth,
    }
  }

  updateValue = ({ groups, items }) => {
    let cars = groups.map(group => {
      return {
        id: group.id,
        blocks: []
      }
    })
    items.forEach(item => {
      if (item.type === ITEM_TYPE.trip) {
        let block = {...item.block}
        block.firstStop = { ...block.firstStop, min: getMinFromDate(item.start_time) }
        block.lastStop = { ...block.firstStop, min: getMinFromDate(item.end_time) }
        block.rows = [...block.rows]
        block.rows[0] = block.firstStop
        block.rows[block.rows.length-1] = block.lastStop
        if (cars[item.group]) {
          cars[item.group].blocks.push(block)
        }
      }
      
    })

    this.props.onChange(cars)
  }

  handleOutBound =(visibleTimeStart, visibleTimeEnd) => {
    const { lastMin } = this.state

    let deltaDay = Math.ceil(lastMin / (24 * 60))
    let defaultTimeStart = moment()
      .startOf('day')
      .toDate().getTime()
    let defaultTimeEnd = moment()
      .startOf('day')
      .add(deltaDay, 'day')
      .add(1, 'hour')
      .toDate().getTime()
    let residualTime = 0
    if (visibleTimeStart < defaultTimeStart) {
      residualTime = defaultTimeStart - visibleTimeStart
      visibleTimeStart = defaultTimeStart
    }

    if (residualTime > 0) {
      visibleTimeEnd += residualTime
      if (visibleTimeEnd > defaultTimeEnd)
        visibleTimeEnd = defaultTimeEnd
    } else if (visibleTimeEnd > defaultTimeEnd) {
      residualTime = visibleTimeEnd - defaultTimeEnd
      visibleTimeEnd = defaultTimeEnd
      visibleTimeStart -= residualTime
      if (visibleTimeStart < defaultTimeStart) {
        visibleTimeStart = defaultTimeStart
      }
    }
    return { visibleTimeStart, visibleTimeEnd }
  }

  handleZoom = ({ visibleTimeStart,
    visibleTimeEnd}) => {
    this.setState(this.handleOutBound(visibleTimeStart, visibleTimeEnd ))
  }

  handleTimeChange = (
    visibleTimeStart,
    visibleTimeEnd,
  ) => {
    this.setState(this.handleOutBound(visibleTimeStart, visibleTimeEnd ))
  };

  handleItemMove = (itemId, dragTime, newGroupOrder) => {
    //console.log('Moved', itemId, dragTime, newGroupOrder)
    // TODO: check if new postion is valid
    let { items, groups } = this.state

    const group = groups[newGroupOrder]

    if ( !this.canMove(itemId, newGroupOrder)) return
    items = items.map(item =>
      item.id === itemId
        ? Object.assign({}, item, {
          group: group.id,
          title: group.id
        })
        : item
    )
    let newitems = this.addRelations( items)
    this.setState({ items: newitems })
    this.updateValue({ groups, items: newitems })
  }

  updateLastStop = (vehicle) => {
    let { blocks } = vehicle
    if (!blocks) return vehicle
    let lastBlock = blocks[blocks.length - 1]
    if (!lastBlock) return vehicle
    let { lastStop } = lastBlock
    if (!lastStop) return vehicle
    let { stop, min } = lastStop
    vehicle.lastStopId = stop
    vehicle.lastStopMin = min
    return vehicle
  } 

  updateData = (itemSelected, itemMove) => {
    let { value } = this.state
    let { updateRecord } = this.props
    // Update data to save db
    let vehicleSelectedIdx = itemSelected.vehicleIdx
    let vehicleSelected = value[vehicleSelectedIdx]
    let vehicleMoveIdx = itemMove.vehicleIdx
    let vehicleMove = value[vehicleMoveIdx]

    if (!vehicleMove || !vehicleSelected) return
    let blockMove = itemMove.block
    let { blocks: vehicleMoveBlocks } = vehicleMove
    let blockMoveIdx = findIndex(vehicleMoveBlocks, blockMove)
    vehicleMove.blocks.splice(blockMoveIdx, 1)
    vehicleSelected.blocks.splice(blockMoveIdx, 0, blockMove)
    vehicleMove = this.updateLastStop(vehicleMove)
    vehicleSelected= this.updateLastStop(vehicleSelected)
    value[vehicleSelectedIdx] = vehicleSelected
    value[vehicleMoveIdx] = vehicleMove
    // Update hasBlocks in group

    this.setState({ value })
    updateRecord(value)
  }

  getValue = () => {
    return this.state.value || []
  }

  handleSelected = (itemId, e, time) => {
    let { items, groups } = this.state

    let itemSelect = find(items, { id: itemId })
    
    if (itemSelect.type === ITEM_TYPE.holder) {
      let itemMove = find(items, { id: itemSelect.itemHolder })
      this.updateData(itemSelect, itemMove)
      itemMove.isSelected = false
      itemMove.group = itemSelect.group
      itemMove.vehicleIdx = itemSelect.vehicleIdx

      remove(items, (item) => {
        return item.type === ITEM_TYPE.holder && item.itemHolder === itemMove.id
      })
      
      let newItems = this.addRelations(items)
      this.setState({ items: newItems})
      return
    }

    if (itemSelect.type !== ITEM_TYPE.trip) return

    if (this.isSelectGroup(groups)) return

    items = items.map(item =>
      item.id === itemId && item.type === ITEM_TYPE.trip
        ? Object.assign({}, item, {
          isSelected: true
        })
        : item
    )
    let newItems = this.addHolders(items, groups)
    this.setState({ items: newItems, groups })
  }

  handleCanvasClick = (groupId, e, time) => {
    let { items, groups } = this.state

    groups = groups.map(group =>
      Object.assign({}, group, {
        isSelected: false
      }) 
    )
  
    items = items.map(item =>
      item.type === ITEM_TYPE.trip
        ? Object.assign({}, item, {
          isSelected: false
        })
        : item
    )
    let newitems = this.removeHolders(items)
    this.setState({ items: newitems, groups })
  }

  selectGroup = id => {
    let { items, groups } = this.state
    groups = groups.map(group =>
      group.id === id 
        ? Object.assign({}, group, {
          isSelected: !group.isSelected
        })
        : group
    )

    items = items.map(item =>
      item.type === ITEM_TYPE.trip
        ? Object.assign({}, item, {
          isSelected: this.selectedGroup(item.group, groups)
        })
        : item
    )
    let newitems = this.removeHolders(items)
    
    this.setState({ items: newitems, groups })

  }

  isSelectGroup = (groups) => {
    for (let i in groups) {
      if (groups[i].isSelected) return true
    }
   
    return false
  }

  selectedGroup = (id, groups) => {
    for (let i in groups) {
      if (groups[i].id === id) return groups[i].isSelected
    }
   
    return false
  }

  getSelectGroup = (groups) => {
    let selected = []
    if (!groups) return selected
    groups.forEach(group => {
      if (group.isSelected)
        selected.push(group.id)
    })
    return selected
  }

  handleDeselected = (e) => {
    // console.log('deselected', e)
  }

  getSelected = (items) => {
    let selected = []
    if (!items) return selected
    items.forEach(item => {
      if (item.isSelected)
        selected.push(item.id)
    })
    return selected
  }

  isSelectItems = ( items ) => {
    for(let i in items) {
      if (items[i].isSelected) return true
    }
   
    return false
  }

  addRelations = (items) => {
    remove(items, (item) => {
      return item.type === ITEM_TYPE.line
    })

    let newitems = []
    
    items.sort((item1, item2) => item1.start_time - item2.start_time)
    

    let preItems = {}
    let lineId = 10000
    items.forEach( item => { 
      
      let preItem = preItems[`${item.group}`]
      if (preItem && preItem.type === ITEM_TYPE.trip && item.type === ITEM_TYPE.trip) {
        let bgColor = colorLineNormal
        if (item.block.patternId === preItem.block.patternId) {
          bgColor = colorLineWarning
        }
        newitems.push({
          id: lineId,
          group: item.group,
          title: '',
          canMove: false,
          canResize: false,
          canSelect: false,
          start_time: preItem.end_time,
          end_time: item.start_time,
          type: ITEM_TYPE.line,
          bgColor
        })

        lineId++
      }

      preItems[`${item.group}`] = item
      newitems.push(item)
    })

    return newitems
    
  }

  addHolders = ( items, groups) => {
    remove(items, (item) => {
      return item.type === ITEM_TYPE.holder
    })

    let newitems = []
  
    let holderId = 20000
    items.forEach ( item => {
      if (item.isSelected) {
        groups.forEach( group => {
          if (item.group !== group.id && this.canMove(item.id, group.id)) {
            newitems.push({
              id: holderId,
              group: group.id,
              vehicleIdx: group.vehicleIdx,
              title: '+',
              canMove: false,
              canResize: false,
              canSelect: true,
              start_time: item.start_time,
              end_time: item.end_time,
              type: ITEM_TYPE.holder,
              bgColor: item.bgColor,
              itemHolder: item.id
            })
            holderId++
          }
        })
      }
      newitems.push(item)
    })

    newitems = this.addRelations(newitems)
   
    return newitems
  }

  removeHolders = ( items) => {
    remove(items, (item) => {
      return item.type === ITEM_TYPE.holder
    })
    let newitems = this.addRelations(items)
    return newitems
  }

  canMove = (itemId, newGroupOrder) => {

    let { items } = this.state
    let itemMove = find(items, { id: itemId })

    for( let i in items ) {
      let item = items[i]
      if (item.type !== ITEM_TYPE.trip || item.id === itemId) continue
    
      if (item.group === newGroupOrder) {
        if ( itemMove.start_time <= item.start_time && itemMove.end_time >= item.start_time)
          return false
        if ( itemMove.start_time <= item.end_time && itemMove.end_time >= item.end_time)
          return false
      }
    }

    return true

  }

  handleTooltip = (hoverItem, open) => event => {
    const { currentTarget } = event
    this.setState({
      anchorEl: currentTarget,
      displayTooltip: open,
      hoverItem,
    })
  }

  itemRenderer = ({
    item,
    itemContext,
    getItemProps,
  }) => {
    let { items } = this.state
    let isSelectedItems = this.isSelectItems(items)
    
    let opacity = 1
    if (isSelectedItems && !item.isSelected)
      opacity = 0.5
    
    const background = item.bgColor
    const borderColor = itemContext.selected
      ? itemContext.dragging
        ? 'black'
        : (item.selectedBgColor || 'black')
      : item.color
    const borderWidth = itemContext.selected ? 1 : 0
  
    const border = `1px solid ${item.bgColor}`
    
    if (item.type === ITEM_TYPE.line)
      return (
        <div
          {...getItemProps({
            style: {
              background: 'transparent',
              borderColor: 'transparent'
            },
          })}
        >
          <hr
            style={{
              height: '1px',
              border: '1px dashed',
              borderColor: item.bgColor
            }}
          >
          </hr>
        </div>
      )
    
    if (item.type === ITEM_TYPE.holder)
      return (
        <div
          {...getItemProps({
            style: {
              background: 'white',
              color: item.color,
              border: `2px dashed ${item.bgColor}`,
              borderRadius: 4,
            },
          })}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center'
            }}
          >
            {itemContext.title} 
          </div>
        </div>
      )

    return (
      <div
        {...getItemProps({
          style: {
            background,
            color: item.color,
            border,
            borderColor,
            borderStyle: 'solid',
            borderWidth,
            borderRadius: 4,
            opacity: opacity
          },
        })}
        onMouseEnter={this.handleTooltip(item, true)}
        onMouseLeave={this.handleTooltip(item, false)}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {itemContext.title} 
        </div>
      </div>
    )
  }
  render() {
    const { translate } = this.props
    const vehicleLabel = translate('resources.vehicles.name', { smart_count: 1 })
    // const { value } = this.state
    var defaultTimeStart = moment()
      .startOf('day')
      .toDate().getTime()
    var defaultTimeEnd = moment()
      .startOf('day')
      .add(1, 'day')
      .toDate().getTime()
    const {
      groups = [],
      items = [], 
      visibleTimeStart,
      visibleTimeEnd,
      appBarWidth,
      hoverItem,
    } = this.state

    let timelineWidth = appBarWidth - MAIN_PADDING - PAPER_PADDING
    if (groups.length === 0) return null
    if (visibleTimeStart)
      defaultTimeStart = visibleTimeStart
    if (visibleTimeEnd)
      defaultTimeEnd = visibleTimeEnd

    let stopStartNames = ''
    let stopEndNames = ''
    let stopStartTimes = ''
    let stopEndTimes = ''
  
    if (hoverItem) {
      let descriptionBlock = hoverItem.block.descriptionBlock
      let countStop = descriptionBlock.stopNames.length
  
      stopStartNames = `${descriptionBlock.stopNames[0]}`
      stopEndNames = `${descriptionBlock.stopNames[countStop - 1]}`
      stopStartTimes = `${descriptionBlock.times[0]}`
      stopEndTimes = `${descriptionBlock.times[countStop - 1]}`
    }

    let selected = this.getSelected(items)

    const newGroups = groups
      .map(group => {
        return Object.assign({}, group, {
          title: (
            <div onClick={() => this.selectGroup(parseInt(group.id))}>
              {`${vehicleLabel} ${group.title}`}
            </div>
          )
        })
      })

    return (
      <div>
        <CalendarTimeline
          groups={newGroups}
          items={items}
          sidebarContent={<div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              background: 'rgba(0,0,0,0.3)',
            }}
          >
            {vehicleLabel}
          </div>}
          fullUpdate
          stackItems
          canMove={true}
          itemRenderer={this.itemRenderer}
          style={{ width: timelineWidth }}
          // onItemMove={this.handleItemMove}
          onTimeChange={this.handleTimeChange}
          onZoom={this.handleZoom}
          onItemSelect={this.handleSelected}
          onItemDeselect={this.handleDeselected}
          onCanvasClick={this.handleCanvasClick}
          visibleTimeStart={defaultTimeStart}
          visibleTimeEnd={defaultTimeEnd}
          headerLabelGroupHeight={0}
          selected={selected}
          resizeDetector={containerResizeDetector}
        />
        <MenuAction
          anchorEl={this.state.anchorEl}
          open={this.state.displayTooltip}
          placement="right-start"
          component={
            <Fade timeout={350}>
              <Paper>
                <Grid container spacing={8}>
                  <Grid item style={{marginTop: 16, height: 40}}>
                    <Typography color="textSecondary">{stopStartTimes}</Typography>
                    <Typography color="textSecondary">{stopEndTimes}</Typography>
                  </Grid> 
                  <Grid item xs={12} sm container>
                    <Grid item xs>
                      <Typography gutterBottom>{stopStartNames}</Typography>
                      <Typography gutterBottom>{stopEndNames}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Fade>
          }
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  let appBar = state['appBar'] || {}
  let appBarWidth = appBar.width
  return { appBarWidth }
}

const enhance = compose(translate, connect(mapStateToProps, {}))

export default enhance(ScheduleTimelineComponent)
