import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import {
  SimpleForm,
  FunctionField,
  ArrayField,
  ReferenceInput,
  TextInput,
  Datagrid,
  LongTextInput,
  translate,
  required,
  FormDataConsumer,
  showNotification,
  TextField,
  ReferenceField,
  minValue,
} from 'react-admin'
import * as reservationStatus from '../common/reservation-status'
import { reset, getFormSyncErrors, touch, change as changeForm } from 'redux-form'
import { Provider } from '../provider'
import {
  Dialog,
  DialogActions,
  Divider,
  Grid,
  DialogContent,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Chip,
  Select,
  Button,
  IconButton,
  withStyles,
  Popover,
  DialogTitle,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatCurrency } from '../utils/formatUtil'
import { faPlus, faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import { timeFormat } from '../common/format'
import { connect } from 'react-redux'
import PassengerCollectionTabs from './PassengerCollectionTabs'
import compose from 'recompose/compose'
import { MoneyInput } from '../common/MoneyInput'
import classnames from 'classnames'
import { push } from 'react-router-redux'
import { validPhoneNumber } from '../utils/topUpUtil'
import { getCampaignDiscountByPrice } from '../utils/discount'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import PayLaterIcon from '../icons/pay-later'
import DialogLoading from '../common/DialogLoading'
import { StatusField } from '../viewtrip/StatusField'
import { validate } from './validate'

//<Grid container>
//<Grid item xs={6}>
//<ListItem className={classes.textOriginContainer}>
//<Avatar className={classes.bigAvatar}>
//{trip.departureTime.format(timeFormat)}
//</Avatar>
//<ListItemText
//className={classes.textOrigin}
//primary={trip.departure}
//secondary={trip.departureTime.format(dateFormat)}
///>
//</ListItem>
//</Grid>
//<Grid item xs={6}>
//<ListItem className={classes.textOriginContainer}>
//<Avatar className={classes.bigAvatar}>
//{trip.arrivalTime.format(timeFormat)}
//</Avatar>
//<ListItemText
//className={classes.textOrigin}
//primary={trip.arrival}
//secondary={trip.arrivalTime.format(dateFormat)}
///>
//</ListItem>
//</Grid>
//</Grid>
const styles = theme => ({
  chips: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%'
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  seatChip: {
    marginRight: 6,
  },
  moreInfo: {
    fontSize: 18,
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingTop: 0
  },
  selectPrice: {
    marginTop: 16,
  },
  container: {
    //marginTop: 12,
  },
  textOrigin: {
    flex: 0,
    marginTop: 8,
    textAlign: 'center'
  },
  textOriginContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  text: {
    fontSize: 14
  },
  label: {
    fontWeight: 'bold'
  },
  number: {
    textAlign: 'end'
  },
  dialogTitle: {
    padding: '12px 0px 0px 12px',
    backgroundColor: '#3f51b5',
  },
  dialogTitleTextPrimary: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },
  dialogTitleTextSecondary: {
    color: 'white',
  },
  item :{
    paddingTop: 0,
  },
  content: {
    padding: 0,
    width: '100%',
  },
  action: {
    borderTop: `1px solid ${theme.palette.divider}`,
    paddingTop: 16,
    marginTop: 0,
    width: '100%',
  },
})

const styleMoreInfo = () => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 2,
  },
})

const RESERVATION_FROM_SEAT_VIEW = 'RESERVATION_FROM_SEAT_VIEW'

const PaymentMethod = {
  BANK: 1,
  CASH: 1,
}

const MoreInfo = withStyles(styleMoreInfo)(({
  classes,
  open,
  anchorEl,
  handlePopoverClose,
  content
}) => {
  return <Popover
    className={classes.popover}
    classes={{
      paper: classes.paper,
    }}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={() => handlePopoverClose()}
    disableRestoreFocus
  >
    <Typography>{content}</Typography>
  </Popover>
})

const paylaterButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#424242',
    color: 'white',
    '&:hover': {
      backgroundColor: '#757575',
    }
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const _PayLaterButton = ({
  translate,
  onClick,
  classes,
  disabled,
}) => {
  return <Button
    className={classes.button}
    onClick={onClick}
    disabled={disabled}
    variant="contained"
  >
    <PayLaterIcon className={classes.icon} />
    {translate('button.pay_later')}
  </Button>
}

const PayLaterButton = compose(translate, withStyles(paylaterButtonStyle))(_PayLaterButton)


const paymenthMethodStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  },
  cash: {
    backgroundColor: '#00796b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009688'
    },
  },
  online: {
    backgroundColor: '#303f9f',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5c6bc0'
    },
  }
}

const _PaymentMethodList = ({ translate, choices, classes, onClick, disabled }) => {
  choices = _.reduce(choices, (result, ele) => {
    let { code } = ele
    if (PaymentMethod[code]) {
      result.push(ele)
    }
    return result
  }, [])
  return choices.map((choice, index) => {
    let { code, name, id } = choice
    return code === 'CASH' ? <Button
      key={index}
      className={classnames(classes.button, classes.cash)}
      variant="contained"
      onClick={() => onClick(id, code)}
      disabled={disabled}
    >
      <FontAwesomeIcon icon={faMoneyBill} className={classes.icon} />
      {name}
    </Button> : <Button
      key={index}
      className={classnames(classes.button, classes.online)}
      variant="contained"
      onClick={() => onClick(id, code)}
      disabled={disabled}
    >
      <CreditCardIcon className={classes.icon} />
      {translate('button.paid_from_customer')}
    </Button>
  })
}

export const PaymentMethodList = compose(withStyles(paymenthMethodStyle), translate)(_PaymentMethodList)

const ItemChips = withStyles(styles)(({ classes, seats, handlePopoverOpen }) => {
  let numberChipShow = 2
  let seatLength = seats.length
  let isShowMore = seatLength > 3
  if (seatLength === 3) {
    numberChipShow = 3
  }
  return <div className={classes.chips}>
    {seats.map((seat, idx) => {
      return idx < numberChipShow && <Chip className={classes.seatChip} key={seat} label={seat} />
    })}
    {isShowMore && <Chip
      className={classnames(classes.seatChip, classes.moreInfo)}
      label="..."
      onMouseEnter={(event) => handlePopoverOpen(event, seats.slice(2, seats.length).join(','))}
      onMouseLeave={(event) => handlePopoverOpen(event)}
    />}
  </div> 
})

class AddReservationDialog extends Component {

  state = {
    fareGroup: 0,
    chargesWithStatus: {},
    moreInfo: {
      open: false,
    },
    loading: false,
  }

  onEnter = () => {
    let { selectedSeats, reset, fareTable, campaigns, tripCampaigns } = this.props
    reset(RESERVATION_FROM_SEAT_VIEW)
    let seats = Object.keys(selectedSeats)

    let fares = fareTable.getPriceTable()
    let passengers = fares.map((fare, index) => ({
      index,
      code: fare.code,
      name: fareTable.getPriceName(fare),
      seats: [],
      amount: fare.value,
      amountWithoutDiscount: fare.value,
    }))

    if (!_.isEmpty(tripCampaigns)) {
      let campaignId = Object.values(tripCampaigns)[0][0]
      let campaign = campaigns[campaignId]
      if (campaign && campaign.promotion) {
        passengers.forEach(passenger => {
          passenger.discount = getCampaignDiscountByPrice(campaign.promotion, passenger.amount)
          passenger.campaignId = campaignId
          passenger.amount -= passenger.discount 
        })
      }
    }

    passengers[0].seats = seats
    let { totalWithoutDiscount, totalDiscount, total } = this.calculateTotal(passengers)
    let { charge, total: oldTotal } = this.state
    if (charge === oldTotal) {
      charge = total
    }

    this.setState({ 
      passengers,
      seats,
      total,
      totalDiscount,
      totalWithoutDiscount,
      charge,
      oldPromoCode: undefined,
      voucherHelper: ''
    })
  }

  updateVoucherCampaign = (voucherCampaign) => {
    let { fareGroup, oldPromoCode } = this.state
    let voucherHelper = ''
    if (oldPromoCode) {
      if (voucherCampaign)
        voucherHelper = voucherCampaign.description
      else
        voucherHelper = `Mã [${oldPromoCode}] không hợp lệ`
    }
    this.setState({ voucherCampaign, voucherHelper }, () => this.onChangeFareGroup(fareGroup))
  }

  onChangeFareGroup = (fareGroup) => {
    let { fareTable, campaigns, tripCampaigns, changeForm } = this.props
    let { passengers } = this.state
    fareTable.setActive(fareGroup)
    let fares = fareTable.getPriceTable()

    let campaign
    if (!_.isEmpty(tripCampaigns)) {
      let campaignId = Object.values(tripCampaigns)[0][0]
      campaign = campaigns[campaignId]
    }

    let { voucherCampaign } = this.state
    if (voucherCampaign) campaign = voucherCampaign

    passengers = passengers.map((passenger, index) => {
      let fare = fares[index]
      let discount = campaign ? getCampaignDiscountByPrice(campaign.promotion, fare.value) : 0
      return {
        ...passenger,
        campaignId: campaign ? campaign.id : undefined,
        discount,
        amount: fare.value - discount,
        amountWithoutDiscount: fare.value,
      }
    })
    
    let { totalWithoutDiscount, totalDiscount, total } = this.calculateTotal(passengers)
    let { charge, total: oldTotal } = this.state
    let updateCharge = charge === oldTotal
    charge = total
    this.setState({ fareGroup, passengers, totalWithoutDiscount, totalDiscount, total, charge }, () => {
      if (updateCharge) changeForm(RESERVATION_FROM_SEAT_VIEW, 'charge', charge)
    })
  }

  calculateTotal = (passengers) => {
    let totalWithoutDiscount = 0
    let totalDiscount = 0
    let total = 0
    for (let i = 0; i < passengers.length; i++) {
      let passenger = passengers[i]
      let { seats, amountWithoutDiscount, discount, amount } = passenger
      let seatLen = seats.length
      totalWithoutDiscount += seatLen * amountWithoutDiscount
      totalDiscount += seatLen * discount
      total += seatLen * amount
    }
    return { totalWithoutDiscount, totalDiscount, total }
  }

  addPassengerToType = (index) => {
    let { passengers, charge, total: oldTotal } = this.state
    let { changeForm } = this.props
    const froms = key => key === 0 ? 'last' : 'first'
    const tos = key => key === 0 ? 'last' : 'first'
    let removePassenger = (passenger, from = 'last') => {
      if (passenger.seats.length > 0) {
        let newSeats = from === 'last' ? passenger.seats.slice(0, -1) : passenger.seats.slice(1)
        let last = from === 'last' ? _.last(passenger.seats) : _.first(passenger.seats)
        passenger = { ...passenger, seats: newSeats }
        return [passenger, last]
      }
      return [passenger, null]
    }

    let addPassenger = (passenger = {}, seat, to = 'first') => {
      if (to === 'last')
        return { ...passenger, seats: [...passenger.seats, seat] }
      else
        return { ...passenger, seats: [seat, ...passenger.seats] }
    }

    let next = _.findIndex(passengers, item => item.seats.length > 0 && item.index !== index)
    if (next === -1) return
    let [passenger, seat] = removePassenger(passengers[next], froms(next))
    if (seat) {
      let nextPassenger = addPassenger(passengers[index], seat, tos[index])
      passengers = [...passengers]
      passengers[next] = passenger
      passengers[index] = nextPassenger
      let { totalWithoutDiscount, totalDiscount, total } = this.calculateTotal(passengers)
      let updateCharge = charge === oldTotal
      charge = total
      this.setState({ passengers, total, totalWithoutDiscount, totalDiscount, charge }, () => {
        if (updateCharge) changeForm(RESERVATION_FROM_SEAT_VIEW, 'charge', charge)
      })
    }
  }

  onSaveForm = async (record, transactions, code) => {
    this.startLoading()
    this.setState({ isMakingReservation: true })
    let { passengers, total, fareGroup } = this.state
    let {
      trip,
      products,
      fareTable,
      reloadCharges,
      onClose,
      showNotification,
      push,
    } = this.props
    let tripId = trip.id
    let { callLogId, passengerCollection, fullName, phone, email, note, charge: paid } = record
    let bookingInformation = {
      contact: {
        fullName: fullName,
        phone: phone,
        email: email,
      },
      note,
    }

    let charges = []
    passengers.forEach(passenger => {
      let chargeFare = fareTable.getChargeFare({ index: fareGroup, code: passenger.code })
      let { campaignId, discount } = passenger
      passenger.seats.forEach(seat => {
        charges.push({
          index: charges.length,
          type: '10NO_REFUND',
          tripId,
          itemDetail: {
            itemCode: seat,
            ...chargeFare,
            productId: products['00SEAT'].id,
            productType: products['00SEAT'].type,
            ...passengerCollection,
            campaignId,
            discount,
          }
        })
      })
    })
    let status
    if (paid === 0) {
      status = reservationStatus.NEW
    } else if (paid < total) {
      status = reservationStatus.STANDBY
    } else {
      status = reservationStatus.CONFIRMED
    }
    let reservation = {
      trips: [tripId],
      passengers: [],
      charges,
      bookingInformation,
      transactions,
      source: '10WEBADMIN',
      callLogId,
      status,
    }
    Provider.dataProvider('REMOTE', 'reservations', {
      method: 'make',
      data: reservation,
    }).then( async res => {
      let reservation = _.get(res.data, 'data', {})
      let { id, source} = reservation
      if (code && code !== 'CASH') {
        await this.requestOnlinePayment(id, tripId, paid, source, callLogId)
      } else {
        if (callLogId) {
          push('/calllogs')
        } else {
          showNotification('notification.reservation.success')
          reloadCharges()
          onClose()
        }
        this.endLoading()
      }
    }).catch(e => {
      this.endLoading()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
    this.setState({ isMakingReservation: false })
  }

  requestOnlinePayment = async (reservationId, tripId, amount, source, callLogId) => {
    let currentHost = window.location.origin
    let pathname = `/reservations/trip_layout/${tripId}`
    if (callLogId) {
      pathname = '/calllogs'
    }
    let returnUrl = `${currentHost}${pathname}`
    let res = await Provider.dataProvider('REMOTE', 'payments', {
      method: 'card-request',
      requestMethod: 'POST',
      data: {
        reservationId,
        amount,
        source,
        orderType: 'bill payment',
        returnUrl,
      }
    })
    if (res && res.data) {
      let url = _.get(res.data, 'data')
      if (url) {
        window.location.href = url
      }
    }
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  makeReservation = (paymentMethodId, formData, code) => {
    let { syncErrors, touch } = this.props
    if (_.isEmpty(syncErrors)) {
      let { total } = this.state
      let charge = parseFloat(formData.charge)
      if (paymentMethodId === 0) charge = 0
      let transactions = []
      if (charge !== 0 && code === 'CASH') {
        transactions.push({
          paid: charge,
          total,
          paymentMethodId,
          currencyId: 1,
          type: '00NORMAL',
          status: '20DONE',
        })
      }
      this.onSaveForm(formData, transactions, code)
    } else {
      touch(RESERVATION_FROM_SEAT_VIEW, ...Object.keys(syncErrors))
    }
  }

  handlePopoverOpen = (event, content) => {
    let moreInfo = {
      open: true,
      anchorEl: event.target,
      content: content
    }
    if (!content) {
      this.handlePopoverClose()
      return
    }
    this.setState({ moreInfo })
  }

  handlePopoverClose = () => {
    this.setState({
      moreInfo: {
        open: false,
        anchorEl: null,
        content: '',
      }
    })
  }

  render() {
    let {
      classes,
      open,
      trip,
      fareTable,
      onClose,
      translate,
      changeForm,
      touch,
      cusPhone = '',
      callLogId,
      canSale,
      passengerCollections,
      licensePlateNum = '78H1-216.17',
    } = this.props
    let {
      passengers = [],
      total = 0,
      moreInfo,
      phones = {},
      voucherHelper,
      totalDiscount = 0,
      totalWithoutDiscount = 0,
      loading,
    } = this.state
    let { open : openMoreInfo, anchorEl, content } = moreInfo
    let basePrices = fareTable.getBasePriceTable()
    let { dropOffPoints, pickUpPoints } = passengerCollections
    let passengerCollection = (dropOffPoints && pickUpPoints) && { dropOffPoint: dropOffPoints[0] && dropOffPoints[0].id.toString(), pickUpPoint: pickUpPoints[0] && pickUpPoints[0].id.toString() }
    return <Fragment>
      <Dialog
        open={open}
        onEnter={this.onEnter}
        onClose={onClose}
        fullWidth
        maxWidth="lg"
        autoFocus={false}
      >
        <DialogTitle className={classes.dialogTitle}>
          <Typography className={classes.dialogTitleTextPrimary}>
            {licensePlateNum}
                  &nbsp;&nbsp;
                  ({trip.departureTime.format(timeFormat)} - {trip.arrivalTime.format(timeFormat)})
                  &nbsp;
            {trip.departureTime.format('DD/MM/YYYY')}
                  &nbsp;
            <ReferenceField
              basePath="/tripstatuses"
              reference="tripstatuses"
              resource="tripstatuses"
              source="status"
              linkType={false}
              allowEmpty
              record={trip}
            >
              <StatusField source="name" />
            </ReferenceField>
          </Typography>
          <Typography className={classes.dialogTitleTextSecondary}>
            {trip.departure} - {trip.arrival}
          </Typography>
        </DialogTitle>
        <SimpleForm
          form={RESERVATION_FROM_SEAT_VIEW}
          toolbar={null}
          style={{ minWidth: 800 }}
          record={{ charge: total, phone: cusPhone, callLogId, passengerCollection: passengerCollection }}
          validate={ record => {
            let errors = {}
            if (record.charge > total) {
              errors.charge = translate('resources.reservations.error_charge', { amount: formatCurrency(total) })
            }
            let extraErrors =  validate(record, { translate })
            return { ...errors, ...extraErrors }
          }}
          asyncBlurFields={['phone', 'promocode']}
          asyncValidate={async (record) => {
            const { phone, promocode } = record
            const { oldPromoCode } = this.state
            if (validPhoneNumber(phone)) {
              let customer = phones[phone]
              if (!customer) {
                phones = { ...phones, phone: {} }
                let result = await Provider.dataProvider('REMOTE', 'customers', {
                  method: 'findByPhone',
                  data: { phone },
                })
                if (result && result.data && result.data.customer && !_.isEmpty(result.data.customer)) {
                  customer = result.data.customer
                  phones = { ...phones, phone: customer }
                }
                this.setState({ phones })
              }
              if (!_.isEmpty(customer)) {
                if (!record.fullName) changeForm(RESERVATION_FROM_SEAT_VIEW, 'fullName', customer.fullName)
                if (!record.email) changeForm(RESERVATION_FROM_SEAT_VIEW, 'email', customer.email)
              }
            }

            if (promocode !== oldPromoCode) {
              this.setState({ oldPromoCode: promocode }, async () => {
                let voucher = promocode && await Provider.dataProvider('REMOTE', 'campaigns', {
                  method: 'getCampaignByCode',
                  data: { code: promocode, tripId: trip.id },
                })
                if (voucher && voucher.data) {
                  this.updateVoucherCampaign(voucher.data)
                } else {
                  this.updateVoucherCampaign(null)
                }
              })
            }
          }}
        >
          <DialogContent className={classes.content}> 
            <Grid container className={classes.container} >
              <Grid className={classes.divider} item sm={12} md={4}>
                <Typography variant="h5">{translate('resources.charges.fareInfo')}</Typography>
                {basePrices.length > 0 &&
                    <FormControl fullWidth className={classes.selectPrice}>
                      <InputLabel>{translate('resources.fares.type')}</InputLabel>
                      <Select
                        value={this.state.fareGroup}
                        onChange={e => this.onChangeFareGroup(e.target.value)}
                        fullWidth
                      >
                        {basePrices.map((item, index) => (
                          <MenuItem value={index} key={index}>
                            {item.basePrice && formatCurrency(item.basePrice)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                }
                <TextInput
                  source="promocode"
                  label="resources.campaigns.fields.promotion.code"
                  helperText={voucherHelper}
                  fullWidth
                />
                <ArrayField
                  record={{ passengers }}
                  source="passengers"
                >
                  <Datagrid translate={translate}>
                    <TextField label="" source="name" />
                    <FunctionField
                      source="seats"
                      label="resources.fares.fields.seats"
                      render={({ seats }) => seats && !_.isEmpty(seats) 
                        ? <ItemChips seats={seats} handlePopoverOpen={this.handlePopoverOpen} />
                        : null
                      }
                    />
                    <FunctionField
                      textAlign="right"
                      source="price"
                      label="resources.fares.fields.price"
                      render={(record) => `${record.seats.length}x${record.amountWithoutDiscount && formatCurrency(record.amountWithoutDiscount)}`}
                    />
                    <FunctionField textAlign="right" render={(record) => (
                      <IconButton onClick={() => this.addPassengerToType(record.index)}>
                        <FontAwesomeIcon icon={faPlus} size="xs" />
                      </IconButton>
                    )} />
                  </Datagrid>
                </ArrayField>
                <Grid className={classes.text} container>
                  <Grid className={classes.label} item xs={8} md={8}>{translate('resources.transactions.fields.total')}:</Grid>
                  <Grid className={classes.number} item xs={4} md={4}>{formatCurrency(totalWithoutDiscount)}</Grid>
                </Grid>
                <Grid className={classes.text} container>
                  <Grid className={classes.label} item xs={8} md={8}>{translate('resources.customers.fields.totalDiscount')}:</Grid>
                  <Grid className={classes.number} item xs={4} md={4}>{totalDiscount ? formatCurrency(totalDiscount) : '0đ'}</Grid>
                </Grid>
                <Divider />
                <Grid className={classes.text} container>
                  <Grid className={classes.label} item xs={8} md={8}>{translate('resources.reservations.totalAmount')}:</Grid>
                  <Grid className={classes.number} item xs={4} md={4}>{formatCurrency(total)}</Grid>
                </Grid>
              </Grid>
              <Grid item sm={12} md={4} className={classes.divider} >
                <Typography variant="h5">{translate('resources.charges.reserveInfo')}</Typography>
                <TextInput
                  source="phone"
                  label="resources.reservations.passenger.phone"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source="fullName"
                  label="resources.reservations.passenger.firstName"
                  fullWidth
                />
                <TextInput
                  source="email"
                  label="resources.reservations.passenger.email"
                  fullWidth
                />
                <MoneyInput
                  label="resources.reservations.makeBalance"
                  source="charge"
                  defaultValue={total}
                  validate={minValue(0)}
                />
                <LongTextInput source="note" label="resources.reservations.note" fullWidth />
                <span>
                  {total >= 50000 &&
                      <Button
                        onClick={() => changeForm(RESERVATION_FROM_SEAT_VIEW, 'charge', 50000)}
                      >
                        {translate('resources.reservations.prepaid50')}
                      </Button>}
                  {total >= 100000 &&
                      <Button
                        onClick={() => changeForm(RESERVATION_FROM_SEAT_VIEW, 'charge', 100000)}
                      >
                        {translate('resources.reservations.prepaid100')}
                      </Button>}
                  <Button
                    onClick={() => changeForm(RESERVATION_FROM_SEAT_VIEW, 'charge', this.state.total)}
                  >
                    {translate('resources.reservations.paidAll')}
                  </Button>
                </span>
              </Grid>
              <Grid item sm={12} md={4}>
                {(dropOffPoints && pickUpPoints) ? <PassengerCollectionTabs source="passengerCollection" dropOffPoints={dropOffPoints} pickUpPoints={pickUpPoints} /> : null }
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions className={classes.action}>
            <FormDataConsumer>
              {({ formData }) => {
                let { isMakingReservation } = this.state
                return <Fragment>
                  <ReferenceInput
                    label="resources.reservations.payment.method"
                    source="paymentMethod"
                    reference="paymentmethods"
                    resource="reservations"
                    fullWidth
                  >
                    <PaymentMethodList
                      onClick={(paymentMethodId, code) => {
                        this.makeReservation(paymentMethodId, formData, code)
                      }}
                      disabled={(isMakingReservation || !canSale) || loading}
                    />
                  </ReferenceInput>
                  <span style={{ width: 60 }}></span>
                  <PayLaterButton
                    onClick={() => {
                      changeForm(RESERVATION_FROM_SEAT_VIEW, 'charge', 0)
                      touch(RESERVATION_FROM_SEAT_VIEW, 'charge')
                      this.makeReservation(0, { ...formData, charge: 0 })
                    }}
                    disabled={(isMakingReservation || !canSale) || loading}
                  />
                </Fragment>
              }}
            </FormDataConsumer>
          </DialogActions>
        </SimpleForm>
        <MoreInfo open={openMoreInfo} onClose={this.handlePopoverClose} content={content} anchorEl={anchorEl} />
      </Dialog>
      {loading && <DialogLoading open={loading} />}
    </Fragment>
  }
}

const enhanceReservationDialog = compose(
  withStyles(styles),
  translate,
  connect(state => ({
    syncErrors: getFormSyncErrors(RESERVATION_FROM_SEAT_VIEW)(state),
  }),
  { reset, touch, changeForm, showNotification, push }
  )
)
export default enhanceReservationDialog(AddReservationDialog)
