import React, { Component } from 'react'
import {
  translate,
  ReferenceInput,
  SelectInput,
  TextInput,
  required
} from 'react-admin'
import { 
  Grid, 
  withStyles, 
} from '@material-ui/core'
import compose from 'recompose/compose'
import ColorPicker from '../common/ColorPicker'
import { Field } from 'redux-form'

const style = () => ({
  root: {
    width: '100%'
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    flexDirection: 'row',
    flex: 1,
  },
  avatarCol: {
    display: 'flex'
  },
  name: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
  leftCard: {
    borderRight: '1px solid #e8e8e8'
  },
  textHeading: {
    paddingBottom: 'inherit',
  },
  colorPicker: {
    marginTop: 25
  },
})

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      record: {},
    }
  }

  render() {
    let { classes } = this.props
    const extra = {
      resource: 'tags',
      fullWidth: true,
    }
    return (
      <Grid container>
        <Grid item xs={6} md={6}>
          <TextInput 
            source="name"
            defaultValue="#"
            //InputProps={{
            //  startAdornment: <InputAdornment position="start">#</InputAdornment>
            //}}
            {...extra} 
          />
          <ReferenceInput
            basePath="tagstatuses"
            reference="tagstatuses"
            source="status"
            resource="tagstatuses"
            defaultValue="00IAT"
            {...extra}
          >    
            <SelectInput optionText="name" />
          </ReferenceInput>
          <Field
            name="color"
            width="100%"
            classes={classes}
            component={ColorPicker}
            validate={required()}
          />
        </Grid>
      </Grid>
    )  
  }
}

const enhance = compose(
  translate,
  withStyles(style),
)

export default enhance(FormSave)
