import { GET_LIST, DELETE } from 'react-admin'
//-----------------------------------------------------------------------
// Import trip
export const IMPORT_TRIP = 'IMPORT_TRIP'
export const DELETE_FILE = 'DELETE_FILE'
export const CHOOSE_TRIP = 'CHOOSE_TRIP'
export const CHANGE_TRIP_INFO = 'CHANGE_TRIP_INFO'
export const SAVE_TRIP_INFO = 'SAVE_TRIP_INFO'
export const SAVE_FILES = 'SAVE_FILES'
export const SAVE_FILES_SUCCESS = 'SAVE_FILES_SUCCESS'
export const SAVE_FILES_FAILURE = 'SAVE_FILES_FAILURE'
export const EXPORT_TEMPLATE = 'EXPORT_TEMPLATE'
export const GET_TEMPLATE_DATA = 'GET_TEMPLATE_DATA'
export const GET_TEMPLATE_DATA_SUCCESS = 'GET_TEMPLATE_DATA_SUCCESS'
export const GET_TEMPLATE_DATA_FAILURE = 'GET_TEMPLATE_DATA_FAILURE'
export const INIT_IMPORT_TRIP = 'INIT_IMPORT_TRIP'
export const GET_VEHICLES = 'GET_VEHICLES'
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS'
export const GET_VEHICLES_FAILURE = 'GET_VEHICLES_FAILURE'
export const GET_DRIVERS = 'GET_DRIVERS'
export const GET_DRIVERS_SUCCESS = 'GET_DRIVERS_SUCCESS'
export const GET_DRIVERS_FAILURE = 'GET_DRIVERS_FAILURE'
export const GET_ROUTES = 'GET_ROUTES'
export const GET_ROUTES_SUCCESS = 'GET_ROUTES_SUCCESS'
export const GET_ROUTES_FAILURE = 'GET_ROUTES_FAILURE'
export const GET_BLOCKS = 'GET_BLOCKS'
export const GET_BLOCKS_SUCCESS = 'GET_BLOCKS_SUCCESS'
export const GET_BLOCKS_FAILURE = 'GET_BLOCKS_FAILURE'
export const FILTER_TRIP = 'FILTER_TRIP'
export const CHOOSE_DATE = 'CHOOSE_DATE'
export const DELETE_TRIP = 'DELETE_TRIP'
export const DELETE_TRIP_SUCCESS = 'DELETE_TRIP_SUCCESS'
export const DELETE_TRIP_FAILURE = 'DELETE_TRIP_FAILURE'
export const CLICK_OK_DELETE_CONFIRM = 'CLICK_OK_DELETE_CONFIRM'
export const REMOVE_TRIP = 'REMOVE_TRIP'
export const CHOOSE_ASSIGN_OPTION = 'CHOOSE_ASSIGN_OPTION'
export const ASSIGN_VEHICLE = 'ASSIGN_VEHICLE'
export const ASSIGN_VEHICLE_SUCCESS = 'ASSIGN_VEHICLE_SUCCESS'
export const ASSIGN_VEHICLE_FAILURE = 'ASSIGN_VEHICLE_FAILURE'
export const CLICK_ASSIGNED_VEHICLE = 'CLICK_ASSIGNED_VEHICLE'

export const initImportTrip = (onDone) => ({
  type: INIT_IMPORT_TRIP,
  onDone
})

export const importTrip = (fileName, routeName, trips, file) => ({
  type: IMPORT_TRIP,
  fileName,
  routeName,
  trips,
  file
})

export const deleteFile = fileName => ({
  type: DELETE_FILE,
  fileName
})

export const chooseTrip = trip => ({
  type: CHOOSE_TRIP,
  trip
})

export const changeTripInfo = (field, value, tripIdentifier) => ({
  type: CHANGE_TRIP_INFO,
  field,
  value,
  tripIdentifier
})

export const saveTripInfo = tripIdentifier => ({
  type: SAVE_TRIP_INFO,
  tripIdentifier
})

export const saveFiles = (files, companyId) => ({
  type: SAVE_FILES,
  payload: {
    method: 'import',
    data: { files: files, companyId: companyId },
  },
  meta: {
    resource: 'trips',
    fetch: 'REMOTE'
  }
})

export const exportTemplate = (companyId, onDone) => ({
  type: EXPORT_TEMPLATE,
  companyId,
  onDone
})

export const getVehicles = () => ({
  type: GET_VEHICLES,
  payload: {
    pagination: {},
    sort: {},
    filter: {}
  },
  meta: {
    resource: 'vehicles',
    fetch: GET_LIST
  }
})

export const getDrivers = () => ({
  type: GET_DRIVERS,
  payload: {
    pagination: {},
    sort: {},
    filter: {}
  },
  meta: {
    resource: 'drivers',
    fetch: GET_LIST
  }
})

export const getRoutes = companyId => ({
  type: GET_ROUTES,
  payload: {
    pagination: {},
    sort: {},
    filter: {
      companyId: companyId
    }
  },
  meta: {
    resource: 'routes',
    fetch: GET_LIST
  }
})

export const getBlocks = () => ({
  type: GET_BLOCKS,
  payload: {
    pagination: {},
    sort: {},
    filter: {}
  },
  meta: {
    resource: 'blocks',
    fetch: GET_LIST
  }
})

// export const getTemplateData = companyId => ({
//   type: GET_TEMPLATE_DATA,
//   payload: {
//     pagination: {},
//     sort: {},
//     filter: {
//       companyId: companyId,
//     }
//   },
//   meta: {
//     resource: 'trips/templateData',
//     fetch: GET_LIST
//   }
// })

export const CHANGE_VIEW_MODE = 'CHANGE_VIEW_MODE'
export const changeViewMode = mode => ({
  type: CHANGE_VIEW_MODE,
  mode
})

//-------------------------------------------------------------------
// Init index Trip
export const INIT_LIST_TRIP = 'INIT_LIST_TRIP'

export const initListTrip = (companyId, onDone) => ({
  type: INIT_LIST_TRIP,
  companyId,
  onDone
})


//--------------------------------------------------------------------
// View trip by time
export const VIEW_TRIP_TIME = 'VIEW_TRIP_TIME'
export const GET_TRIPS_BY_TIME = 'GET_TRIPS_BY_TIME'
export const GET_TRIPS_BY_TIME_SUCCESS = 'GET_TRIPS_BY_TIME_SUCCESS'
export const GET_TRIPS_BY_TIME_FAILURE = 'GET_TRIPS_BY_TIME_FAILURE'
export const INIT_VIEW_TRIP_BY_TIME = 'INIT_VIEW_TRIP_BY_TIME'

export const initViewTripByTime = (dates, assignedVehicle, routes, companyId) => ({
  type: INIT_VIEW_TRIP_BY_TIME,
  dates,
  assignedVehicle,
  routes,
  companyId
})

// export const getTripsBlockByTime = (filter, onDone) => ({
//   type: GET_TRIPS_BY_TIME,
//   payload: {
//     pagination: {},
//     sort: {},
//     filter: filter
//   },
//   meta: {
//     resource: 'trips/getTripsBlockByTime',
//     fetch: GET_LIST
//   },
//   onDone
// })

export const viewTripTime = data => ({
  type: VIEW_TRIP_TIME,
  data
})

export const filterTrip = filter => ({
  type: FILTER_TRIP,
  filter
})

//--------------------------------------------------------------------
// View trip by route
export const INIT_VIEW_TRIP_BY_ROUTE = 'INIT_VIEW_TRIP_BY_ROUTE'
export const VIEW_TRIP_ROUTE = 'VIEW_TRIP_ROUTE'
export const GET_TRIP_BY_ROUTE = 'GET_TRIP_BY_ROUTE'
export const GET_TRIP_BY_ROUTE_SUCCESS = 'GET_TRIP_BY_ROUTE_SUCCESS'
export const GET_TRIP_BY_ROUTE_FAILURE = 'GET_TRIP_BY_ROUTE_FAILURE'
export const INIT_LIST_TABLE_TRIP = 'INIT_LIST_TABLE_TRIP'
export const CLICK_FILTER = 'CLICK_FILTER'
export const CHOOSE_VEHICLES = 'CHOOSE_VEHICLES'
export const CHOOSE_BLOCKS = 'CHOOSE_BLOCKS'
export const CHOOSE_ROUTES = 'CHOOSE_ROUTES'
export const GET_ROUTE_SHOW = 'GET_ROUTE_SHOW'
export const UPDATE_ROUTE_SHOW = 'UPDATE_ROUTE_SHOW'

export const initViewTripByRoute = (routes, companyId) => ({
  type: INIT_VIEW_TRIP_BY_ROUTE,
  routes,
  companyId
})

export const getTripsByRoute = (routes, companyId) => ({
  type: GET_TRIP_BY_ROUTE,
  payload: {
    pagination: {},
    sort: {},
    filter: {
      routes: routes,
      companyId: companyId
    }
  },
  meta: {
    resource: 'trips/getTripsByRoute',
    fetch: GET_LIST
  }
})

export const viewTripRoute = data => ({
  type: VIEW_TRIP_ROUTE,
  data
})

export const chooseDate = (filter, dates) => ({
  type: CHOOSE_DATE,
  filter,
  dates
})

export const chooseAssignOption = (filter, assignOption) => ({
  type: CHOOSE_ASSIGN_OPTION,
  filter,
  assignOption
})

export const chooseVehicles = (filter, vehicles) => ({
  type: CHOOSE_VEHICLES,
  filter,
  vehicles
})

export const chooseBlocks = (filter, blocks) => ({
  type: CHOOSE_BLOCKS,
  filter,
  blocks
})

export const chooseRoutes = (filter, routeIds) => ({
  type: CHOOSE_ROUTES,
  filter,
  routeIds
})

export const clickFilter = isClick => ({
  type: CLICK_FILTER,
  isClick
})

export const getRouteShow = routes => ({
  type: GET_ROUTE_SHOW,
  routes
})

export const updateRouteShow = routeIds => ({
  type: UPDATE_ROUTE_SHOW,
  routeIds
})

export const clickOkDeleteConfirm = (id, date, onDone) => ({
  type: CLICK_OK_DELETE_CONFIRM,
  id,
  date,
  onDone
})

export const deleteTrip = id => ({
  type: DELETE_TRIP,
  payload: { id: id },
  meta: {
    resource: 'trips',
    fetch: DELETE
  }
})

export const removeTrip = (id, date) => ({
  type: REMOVE_TRIP,
  id,
  date
})

export const clickAssignedVehicle = (filter, onDone) => ({
  type: CLICK_ASSIGNED_VEHICLE,
  filter,
  onDone
})

export const assignVehicle = filter => ({
  type: ASSIGN_VEHICLE,
  payload: {
    data: filter,
    method: 'assignVehicle'
  },
  meta: {
    resource: 'trips',
    fetch: 'REMOTE'
  }
})
