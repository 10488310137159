import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import ConfirmDialog from '../common/ConfirmDialog'

class CancelCompanyAgencyButton extends Component {

  state = {
    open: false,
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  onDone = () => {
    let { showNotification, refreshView } = this.props
    this.closeDialog()
    refreshView()
    showNotification('notification.unassign_company_success')
  }

  onError = () => {
    let { showNotification,refreshView } = this.props
    this.closeDialog()
    refreshView()
    showNotification('notification.unassign_company_failure', 'warning')
  }

  onOk = (record) => {
    Provider.dataProvider('DELETE', 'companyagencies', {
      id: record.id
    })
      .then(() => this.onDone())
      .catch(() => this.onError())
  }

  render() {
    let { translate, record } = this.props
    let { open } = this.state
    return <>
      <Button
        onClick={this.openDialog}
        color='primary'
      >
        {translate('button.cancel')}
      </Button>
      <ConfirmDialog
        open={open}
        data={record}
        onClose={this.closeDialog}
        title={translate('resources.common.confirm')}
        content={translate('notification.confirm_unassign_company')}
        onOk={this.onOk}
      />
    </>
  }
}

const enhance = compose(translate, connect(null, { showNotification, refreshView }))

export default enhance(CancelCompanyAgencyButton)
