import React, { Component, Fragment } from 'react'
import {
  translate,
  WithPermissions,
  Title,
  FETCH_ERROR,
  SimpleForm,
  SelectInput
} from 'react-admin'
import compose from 'recompose/compose'
import {
  withStyles,
  Grid,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core'
import { Provider } from '../provider'
import { moment } from '../common/format'
import BarChart from '../common/BarChart'
import { getCurrentCompany } from '../utils/commonUtil'
import { format } from '../utils/number'
import * as permission from '../utils/permission'
import { connect } from 'react-redux'
import { DatePickerInput } from '../common/DatePicker'

const bigNumberNames = [
  'number_tripCurrent',
  'number_trip-fromTo_date',
  'number_tripFinished-fromTo_date',
  'number_distanceTraveled-fromTo_date',
  'number_revenue-fromTo_date', 
]

const barChartNames = [
  'table_fromTo-revenue_date',
]

const styles = {
  flex: {
    display: 'flex',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  leftCol: {
    flex: 1,
    marginRight: '1em',
  },
  rightCol: {
    flex: 1,
    marginLeft: '1em',
  },
  singleCol: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  card: {
    minHeight: 300,
  },
  rightBorder: {
    borderRight: '2px solid rgba(0, 0, 0, 0.3)',
    textAlign: 'center',
  },
  center: {
    textAlign: 'center',
  },
  chart: {
    height: 400,
  },
  textCard: {
    fontSize: 10
  },
  singleCard: {
    marginTop: 40
  },
  titleCard: {
    fontWeight: 'bold'
  },
  bigNumber: {
    textAlign: 'center',
    fontWeight: 'bold'
  }
}

class _Filter extends Component {

  constructor(props) {
    super(props)
  }

  render() {
    const { handleDateChange, filterValues, translate } = this.props

    return (
      <SimpleForm fullWidth record={filterValues} toolbar={null} form="DASHBOARD_FILTER" onChange={handleDateChange}>
        <Grid fullWidth container>
          <Grid item xs={12} sm={4}>
            <DatePickerInput
              fullWidth
              dateFormat="DD/MM/YYYY" 
              showLunarDate={true}
              keyboard={true}
              allowEmpty
              pickerType="datetime"
              source="fromDate"
              label={translate('resources.dashboard.fromDate')}
              maxDate={filterValues.toDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <DatePickerInput
              fullWidth
              source="toDate"
              dateFormat="DD/MM/YYYY"
              showLunarDate={true}
              keyboard={true}
              allowEmpty
              pickerType="datetime"
              label={translate('resources.dashboard.toDate')}
              minDate={filterValues.fromDate}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <SelectInput fullWidth choices={[
              { id: 'day', name: 'resources.dashboard.day' },
              { id: 'month', name: 'resources.dashboard.month' },
              { id: 'quarter', name: 'resources.dashboard.quarter' },
              { id: 'year', name: 'resources.dashboard.year' },
            ]} source="granularity" label={translate('resources.dashboard.granularity')}/>
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

const DashboardFilter = translate(_Filter)

const enhance = compose(
  translate,
  withStyles(styles)
)

class Dashboard extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fromDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      granularity: 'day'
    }
  }

  normalizeForBarChart = (rawDatas, chartKeys, keys) => {
    let datas = []
    let keysWithTranslate = []
    let { translate } = this.props
    for (let i = 0; i < rawDatas.length; i++) {
      let rawData = rawDatas[i]
      let data = this.formatDataBarChart(rawData, chartKeys, keys)
      datas.push(data)
    }

    for (let prop in keys) {
      let key = keys[prop]
      keysWithTranslate.push(translate(key))
    }

    return { datas, keys: keysWithTranslate }
  }

  normalizeForLineChart = (rawDatas, legendKeys) => {
    let { translate } = this.props
    for (let i = 0; i < rawDatas.length; i++) {
      let rawData = rawDatas[i]
      let { name, color } = legendKeys[rawData.id]
      rawData.id = translate(name)
      rawData.color = color
      rawDatas[i] = rawData
    }
    return rawDatas
  }

  handleDateChange = (value) => {
    this.setState({
      fromDate: moment(value.fromDate).format('YYYY-MM-DD'),
      toDate: moment(value.toDate).format('YYYY-MM-DD'),
      granularity: value.granularity
    })
  }


  render() {
    let { translate, permissions } = this.props
    let { fromDate, toDate, granularity } = this.state
    let company = getCurrentCompany()
    let companyId = company && company.id
    permissions = permissions && permissions !== null ? permissions : {}
    let isCompanyManager = permission.isCompany(permissions)
    let isAdmin = permission.isAdmin(permissions)
    return <Fragment>
      <Title title={translate('resources.home.name')} />
      <DashboardFilter filterValues={this.state} handleDateChange={this.handleDateChange}/>
      <Grid container>
        {bigNumberNames.map((bigNumberName, idx) => {
          return <Grid key={idx} item xs={12} md={6} lg={3} >
            <BigNumber
              name={bigNumberName}
              companyId={companyId}
              isAdmin={isAdmin}
              isCompanyManager={isCompanyManager} 
              fromDate={fromDate}
              toDate={toDate}
            />
          </Grid>
        })}
        {barChartNames.map((barChartName, idx) => {
          return <Grid key={idx} item xs={12} md={12} >
            <BarChartComponent
              name={barChartName}
              companyId={companyId}
              fromDate={fromDate}
              toDate={toDate}
              isAdmin={isAdmin}
              isCompanyManager={isCompanyManager}
              granularity={granularity}
            />
          </Grid>
        })}
      </Grid>
    </Fragment>
  }
}

const getMeasure = async ({ dispatch, name, type, companyId, fromDate, toDate, granularity }) => {
  try {
    let resp = await Provider.dataProvider('REMOTE', 'analytics', {
      method: 'findByName',
      data: { companyId, fromDate, toDate, granularity, transformTo: 'nivo', name, type },
    })
    if (resp && resp.data) return resp.data
  } catch (e) {
    const { statusCode } = e
    if (statusCode === 401 || statusCode === 403) {
      dispatch({ type: FETCH_ERROR, payload: e })
    }
    console.log('Error', e)
  }
}

class _BarChartComponent extends Component {

  constructor(props) {
    super(props)
    this.state = { 
      companyId: null,
      indexBy: '',
      keys: [],
      data: [],
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { companyId, isAdmin, isCompanyManager, granularity } = this.props
    let { 
      companyId: prevCompanyId,
      isAdmin: prevIsAdmin,
      isCompanyManager: prevIsCompanyManager,
      granularity: prevGranularity,
    } = prevProps
    const updated = !(prevCompanyId === companyId &&
      prevIsAdmin === isAdmin &&
      prevIsCompanyManager === isCompanyManager &&
      prevGranularity === granularity
    )
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { dispatch, isAdmin, isCompanyManager, name, companyId, fromDate, toDate, granularity } = this.props
      let canRender = isAdmin || isCompanyManager
      const measure = canRender ? await getMeasure({ dispatch, name, type: 'barChart', companyId, fromDate, toDate, granularity }) : {}
      if (measure) {
        const { indexBy, keys, data, title, type } = measure
        this.setState({ 
          indexBy,
          keys,
          data,
          title,
          type,
        })
      }
    }
  }
  
  renderTooltip = (record) => {
    let { translate } = this.props
    let { indexBy } = this.state
    let { value, indexValue } = record
    if (indexBy === 'month') {
      indexValue = moment(indexValue).format('MM')
    }
    return <span><b>{translate(`resources.dashboard.${indexBy}`)}&nbsp;{indexValue}:</b>&nbsp;{format(value).toString().toUpperCase()}</span>
  }

  render() {
    const { indexBy, keys, data = [], title } = this.state
    const { classes } = this.props
    const axises = {
      axisBottom: {
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 20,
        legend: indexBy && `resources.dashboard.${indexBy}`,
        legendPosition: 'middle',
        legendOffset: 40
      },
      axisLeft: {
        tickSize: 5,
        tickPadding: 10,
        tickRotation: 0,
        legend: 'VNĐ',
        legendPosition: 'middle',
        legendOffset: -45,
        isTranslate: true,
        format: '~s',
      }
    }
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.titleCard} color="textSecondary">
            {title}
          </Typography>
        </CardContent>
        <CardContent className={classes.chart}>
          <BarChart
            indexBy={indexBy}
            classes={classes}
            data={data}
            keys={keys}
            axises={axises}
            tooltip={this.renderTooltip}
          />
        </CardContent>
      </Card>
    )
  }
}

const enhanceConnect = compose(
  connect(null, dispatch => ({ dispatch })),
  translate,
  withStyles(styles)
)
const BarChartComponent = enhanceConnect(_BarChartComponent)

class _BigNumber extends Component {

  constructor(props) {
    super(props)
    this.state = { companyId: null, indexBy: '', keys: [], data: [] }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { companyId, isAdmin, isCompanyManager, fromDate, toDate} = this.props
    let { 
      companyId: prevCompanyId,
      isAdmin: prevIsAdmin,
      isCompanyManager: prevIsCompanyManager,
      fromDate: prevFromDate,
      toDate: prevToDate,
    } = prevProps
    const updated = !(prevCompanyId === companyId &&
      prevIsAdmin === isAdmin &&
      prevIsCompanyManager === isCompanyManager && 
      prevFromDate === fromDate &&
      prevToDate === toDate
    )
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { dispatch, isAdmin, isCompanyManager, name, companyId, fromDate, toDate } = this.props
      let canRender = isAdmin || isCompanyManager
      const measure = canRender ? await getMeasure({ dispatch, name, type: 'numeric', companyId, fromDate, toDate }) : {}
      if (measure) {
        let { data, title, type } = measure
        if (name === 'number_distanceTraveled-fromTo_date') {
          data = data ? parseFloat(data).toFixed(2) : 0
        }
        this.setState({ data, title, type })
      }
    }
  }

  render() {
    const { data, title } = this.state
    const { classes } = this.props
    return <Card>
      <CardContent className={classes.center}>
        <Typography variant="h3" component="h3" style={{ fontWeight: 800 }}>
          {data || data === 0 ? format(data).toString().toUpperCase() : 'N/A'}
        </Typography>
        <Typography className={classes.center} color="textSecondary" style={{ fontWeight: 500 }}>
          {title || 'N/A'}
        </Typography>
      </CardContent>
    </Card>
  }
}

const BigNumber = enhanceConnect(_BigNumber)

const DashboardWithPermissions = props => <WithPermissions
  render={({ permissions }) => <Dashboard permissions={permissions} {...props} />}
/>

export default enhance(DashboardWithPermissions)





