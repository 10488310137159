export const fontFamilies = ['Open Sans', 'Times New Roman']

export const contents = {
  'reservation_name': 'Reservation name',
  'reservation_code': 'Reservation code',
  'order_code': 'Order code',
  'product_name': 'Product name',
  'agency_name': 'Agency name',
  'other': 'Other...'
}
export const contentsRect = {
  'order': 'Order code',
  'ticket': 'Ticket code',
  'ticket_month': 'Ticket month code',
  'reservation': 'Reservation code',
  'qrcode': 'QR code',
  'other': 'Other...'
}

export const genId = (length = 8) =>  {
  let result = ''
  let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let charactersLength = characters.length
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const mmToPx = (mm, dpi = 96) => {
  return (mm * dpi) / 25.4
}

export const ptsToPx = (px, dpi = 96) => {
  return (px * dpi) / 72
}

export function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

