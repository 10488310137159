import {
  withStyles,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@material-ui/core'
import React, { Component } from 'react'
import { translate, ReferenceManyField } from 'react-admin'

import compose from 'recompose/compose'
import { addRoute, toggleRoute } from './actions'
import { connect } from 'react-redux'
import { styles } from './frame-styles'
import { MapProps } from './../common/constants'

class RouteList extends Component{ 
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      selected: []
    }
  }

  componentDidMount() {
    const{ ids = [], data, addRoute } = this.props
    let selected = []
    let items = []
    for (let idx = 0; idx < ids.length; idx++) {
      let id = ids[idx]
      let v = data[id]
      v.visibility = MapProps.LayerStates.VISIBLE
      addRoute(v)
      items.push(v)
      selected.push(v)
    }
    let newState = { items: [...this.state.items,...items], 
      selected: [...this.state.selected,...selected] }
    this.setState(newState)
  }
 
  onChange = (e, checked, v) => {
    const { toggleRoute } = this.props
    let newState = {}
    if (checked) {
      newState = { selected: [...this.state.selected,...[v]]}
      //turn route on
      v.visibility = MapProps.LayerStates.VISIBLE
    } else {
      newState ={ selected: this.state.selected.filter( s => s !== v)}
      //turn route off
      v.visibility = MapProps.LayerStates.INVISIBLE
    }
    this.setState(newState)
    toggleRoute(v)
  }

  items() {
    let { items } = this.state
    let array = []
    let len = items.length
    for (let idx = 0; idx < len; idx++) {
      let v = items[idx]
      let item =  <ListItem style={ { 'background-color': v.color || '#cdcdcd' }  }>
        <Checkbox onChange={(e, checked) => this.onChange(e, checked, v)} />
        <ListItemText primary={v.name} secondary={v.number} />
      </ListItem>
      /*
      let element = <FormControlLabel {
        ...{
          key: idx,
          label: v.name,
          checked: selected.includes(v),
          control: <Checkbox {
            ...{
              onChange: (e, checked) => this.onChange(e, checked, v)
            }
          }/>,
          style: {
            background: 'red'
          }
        }
      }/>
      array.push(element)
      */
      array.push(item)
      array.push(<Divider />)
    }
    return array
  }

  render() {
    return (
      <List>
        { this.items() }
      </List>
    )
  }
}

class RouteControlFrame extends Component {
  constructor(props) {
    super(props)
    let layers = [{ name: 'Item 1 long text' }, { name: 'Item 2 long text'}]
    this.state = {
      items: layers
    }
  }
  
  render() {
    let { identifier, addRoute, toggleRoute } = this.props

    return (<div key={ identifier } style={{
      width: 300,
      height: '100%',
      background: 'white',
      padding: 10,
      overflow: 'auto'
    }}>
      <ReferenceManyField
        addLabel={false}
        basePath="/"
        reference="routes"
        resource="routes"
        record={{}}
        fullWidth
      >
        <RouteList addRoute={ addRoute } toggleRoute={ toggleRoute } />
      </ReferenceManyField>
    </div >)
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { bbox, zoom, center } = state.map
      return { bbox, zoom, center }
    },
    {
      addRoute, toggleRoute
    })
)

export default enhance(RouteControlFrame)
