import React, { Component } from 'react'
import NumberFormat from 'react-number-format'

class PhoneInput extends Component {

  render() {
    let { inputRef, options, value, onChange, ...props } = this.props
    return (
      <NumberFormat
        {...props}
        value={value}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          })
        }}
        format="### ### ####"
      />
    )
  }

}

export default PhoneInput