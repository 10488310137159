import React, { Component, createRef } from 'react'
import moment from 'moment'
import CalendarTimeline from '../common/calendar-timeline'
import { getColor } from '../utils/color'
import { toarray } from '../utils/string'
import { DragSource, DropTarget } from 'react-dnd'
import isArray from 'lodash/isArray'
import { multiSelectTo as multiSelect } from '../utils/mutiselect'
import slugify from 'voca/slugify'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { red } from '@material-ui/core/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import { connect } from 'react-redux'
import { MAIN_PADDING, PAPER_PADDING } from '../common/constants'

const ITEM_TYPE = {
  line: 'line',
  trip: 'trip',
  holder: 'holder'
}

const VEHICLE_DRAWER = 240
const colorLineWarning = 'red'
const colorLineNormal = 'gray'

const mapStateToProps = (state) => {
  let appBar = state['appBar'] || {}
  let appBarWidth = appBar.width
  return { appBarWidth }
}

const enhance = compose(
  translate,
  connect(mapStateToProps, {})
)

const ItemTypes = {
  VEHICLE_COL: 'vehiclecol',
}

const cardTarget = {
  drop() {
    //
  },
}

const itemSource = {
  beginDrag(props) {
    let { id, index } = props
    return {
      id: id,
      originalIndex: index,
    }
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id
  },
  endDrag(props, monitor) {
    const { id, originalIndex } = monitor.getItem()
    const didDrop = monitor.didDrop()
    const resultDrop = monitor.getDropResult()
    let isSuccess = resultDrop && resultDrop.isSuccess
    if (!didDrop) {
      props.removeVehicle(id, originalIndex)
    } else {
      if (!isSuccess) {
        props.removeVehicle(id, originalIndex)
      }
    }
  }
}

const vehicleColTarget = {

  canDrop(props, monitor) {
    const { id: draggedId, selectedVehicleIds } = monitor.getItem()
    const { id: overId } = props
    if (selectedVehicleIds) {
      return selectedVehicleIds.indexOf(overId) === -1
    }
    return draggedId !== overId
  },

  hover(props, monitor) {
    const { id: draggedId, selectedVehicleIds } = monitor.getItem()
    const { id: overId, index: overIndex } = props
    if (!monitor.isOver({ shallow: true })) {
      if (draggedId !== overId || (selectedVehicleIds && selectedVehicleIds.indexOf(overId) === -1)) {
        props.onHover(draggedId, overIndex, selectedVehicleIds)
      }
    }
  },

  drop(props, monitor) {
    const { id: draggedId, selectedVehicleIds } = monitor.getItem()
    const { id: overId, index: overIndex } = props
    if (draggedId !== overId || (selectedVehicleIds && selectedVehicleIds.indexOf(overId) === -1)) {
      props.onDrop(draggedId, overIndex, selectedVehicleIds)
      props.unHover()
      return { isSuccess: true }
    }
    return { isSuccess: false }
  },
}

const IconError = () => <FontAwesomeIcon color={red[500]} icon={faExclamationCircle} />

class _VehicleCol extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isSelected: false,
      isError: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isSelected, isError } = nextProps
    let currentIsSelected = prevState.isSelected
    if (isSelected !== currentIsSelected) {
      currentIsSelected = isSelected
    }
    let currentIsError = prevState.isError
    if (isError !== currentIsError) {
      currentIsError = isError
    }
    return {
      isSelected: currentIsSelected,
      isError: currentIsError
    }
  }

  render(){
    let {
      connectDragSource,
      connectDropTarget,
      index,
      plate,
      isHover,
      translate
    } = this.props
    let { isSelected, isError } = this.state
    let backgroundColor = isHover && 'rgba(0, 0, 0, 0.14)'
    let color = isSelected ? 'red' : 'black'
    // backgroundColor = isError && red[200]
    // Xe 8, Vehicle 8
    let vehicleLabel = plate ? plate : `${translate('resources.vehicles.name', { smart_count: 1 })} ${index + 1}`
    return (
      connectDragSource(
        connectDropTarget(
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor,
            color
          }}>
            {vehicleLabel}
            {isError && <IconError />}
          </div>
        )
      )
    )
  }}


export const VehicleColWithDragDrop = DropTarget(
  ItemTypes.VEHICLE_COL,
  vehicleColTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(DragSource(
  ItemTypes.VEHICLE_COL,
  itemSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(enhance(_VehicleCol)))

/**
 * This class encapsulates functionalities of vehicle assigment for generating trips on the basis of schedules
 */
class BaseCarSelectorBySchedule extends Component {

  state = { displayTooltip: false }
  calendarTimeline = createRef()

  static getDerivedStateFromProps(nextProps, prevState) {
    let { appBarWidth, value, invalidVehicles: nextInvalidVehicles } = nextProps
    let { appBarWidth: currentAppBarWidth, items, groups, invalidVehicles: currInvalidVehicles, selectedVehicles } = prevState
    if (appBarWidth !== currentAppBarWidth) {
      currentAppBarWidth = appBarWidth
    }
    if (nextInvalidVehicles && (nextInvalidVehicles !== currInvalidVehicles)) {
      currInvalidVehicles = nextInvalidVehicles
      for (let groupIdx in selectedVehicles) {
        let value = selectedVehicles[+groupIdx]
        groups[+groupIdx] = {...groups[+groupIdx], isError: nextInvalidVehicles.includes(value.toString())}
      }
    } 
    if (value && !items) {
      groups = []
      items = []
      let beginOfDay = moment().startOf('day')
      let myColors = {}
      let preItems = {}
      let itemTrips = []
      value.forEach(car => {
        let groupId = car.id + 1
        groups.push({
          id: groupId,
          title: car.id + 1,
          isSelected: false,
          rightTitle: `Vehicle ${car.id}`,
          isError: false
        })
        car.blocks.forEach(block => {
          if (!(block.patternId in myColors)) {
            myColors[block.patternId] = getColor(Object.keys(myColors).length)
          }

          itemTrips.push({
            id: itemTrips.length + 1,
            group: groupId,
            title: car.id + 1,
            canMove: false,
            canResize: false,
            canSelect: false,
            bgColor: myColors[block.patternId],
            start_time: beginOfDay.clone().add(block.firstStop.min, 'minute'),
            end_time: beginOfDay.clone().add(block.lastStop.min, 'minute'),
            block,
            type: ITEM_TYPE.trip
          })
        })
      })

      itemTrips.sort((item1, item2) => item1.start_time - item2.start_time)

      let lineId = 10000
      itemTrips.forEach(item => {
        let preItem = preItems[`${item.group}`]
        if (preItem) {
          let bgColor = colorLineNormal
          if (item.block.patternId === preItem.block.patternId) {
            bgColor = colorLineWarning
          }
          items.push({
            id: lineId,
            group: item.group,
            title: '',
            canMove: false,
            canResize: false,
            canSelect: false,
            start_time: preItem.end_time,
            end_time: item.start_time,
            type: ITEM_TYPE.line,
            bgColor
          })
          lineId++
        }
        preItems[`${item.group}`] = item
        items.push(item)
      })
    }

    return {
      ...prevState,
      appBarWidth: currentAppBarWidth,
      groups,
      items,
      invalidVehicles: currInvalidVehicles,
    }
  }

  constructor(props) {
    super(props)
    this.state = { version: new Date().getTime() }
  }

  toggleSelection = (vehicleId) => {
    const selectedVehicleIds = this.state.selectedVehicleIds || []
    const wasSelected = selectedVehicleIds.includes(vehicleId)
    let newVehicleIds = []
    if (!wasSelected) {
      newVehicleIds = [vehicleId]
    }
    if (selectedVehicleIds.length > 1) {
      newVehicleIds = [vehicleId]
    }
    this.setState({
      selectedVehicleIds: newVehicleIds || [],
    })
  }

  toggleSelectionInGroup = (vehicleId) => {
    const selectedVehicleIds = this.state.selectedVehicleIds
    const index = selectedVehicleIds.indexOf(vehicleId)

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        selectedVehicleIds: [...selectedVehicleIds, vehicleId] || [],
      })
      return
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedVehicleIds]
    shallow.splice(index, 1)
    this.setState({
      selectedTaskIds: shallow,
    })
  }

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = (newVehicleId) => {
    let { dragVehicles, selectedVehicleIds } = this.state
    const updated = multiSelect(dragVehicles, selectedVehicleIds, newVehicleId)

    if (updated === null) {
      return
    }

    this.setState({
      selectedVehicleIds: updated || [],
    })
  }

  onKeyDown = (event, provided, snapshot) => {
    if (provided.dragHandleProps) {
      provided.dragHandleProps.onKeyDown(event)
    }

    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.keyCode !== 'enter') {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    this.performAction(event)
  }

  onClick = (event, vehicleId) => {
    if (!vehicleId || vehicleId === -1) return
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== 0) {
      return
    }

    // marking the event as used
    event.preventDefault()

    this.performAction(event, vehicleId)
  }

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = (event) => event.shiftKey

  performAction = (event, vehicleId) => {
    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      this.toggleSelectionInGroup(vehicleId)
      return
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      this.multiSelectTo(vehicleId)
      return
    }

    this.toggleSelection(vehicleId)
  }

  hoverVehicles = (draggerId, index) => {
    let hoverSelected = []
    if (!isArray(draggerId)) {
      draggerId = [draggerId]
    }
    if (draggerId && draggerId.length === 1) {
      hoverSelected.push(index)
    } else {
      for (let i = 0; i < draggerId.length; i++) {
        hoverSelected.push(index + i)
      }
    }
    return { hoverSelected }
  }

  removeVehicles = (id, startIdx) => {
    let { selectedVehicles } = this.state
    let { updateVehicleInDrawer } = this.props
    if (!selectedVehicles) return
    delete selectedVehicles[startIdx]
    this.setState({ selectedVehicles })
    this.unHover()
    updateVehicleInDrawer(id, startIdx)
  }

  removeVehicle = (id, idx) => {
    let { selectedVehicles, groups } = this.state
    let { updateVehicleInDrawer } = this.props
    if (!selectedVehicles) return
    delete selectedVehicles[idx]
    groups[idx] = {...groups[idx], isError: false}
    this.setState({
      selectedVehicles
    })
    this.unHover()
    updateVehicleInDrawer(id, idx)
  }

  addVehicle = (draggerId, index) => {
    let { selectedVehicles, swapSelectedVehicleContainer } = this.state
    let draggerIdx = -1
    // check vehicleIdDragger
    for (let pos in selectedVehicles) {
      let vehicleId = selectedVehicles[pos]
      if (vehicleId === draggerId) {
        draggerIdx = pos
      }
    }
    let vehicleIdOver = selectedVehicles && selectedVehicles[index]
    //swap vehicle
    if (draggerIdx !== -1) {
      selectedVehicles = {
        ...selectedVehicles,
        [index]: draggerId,
        [draggerIdx]: vehicleIdOver,
      }
      swapSelectedVehicleContainer = { ...selectedVehicles }
    } else {
      //add new vehicle
      selectedVehicles = {
        ...selectedVehicles,
        [index]: draggerId,
      }
    }
    return { selectedVehicles, swapSelectedVehicleContainer }
  }

  addVehicles = (draggerVehicles, startIndex) => {
    let { selectedVehicles, groups, swapSelectedVehicleContainer } = this.state
    let lenDraggers = draggerVehicles.length
    let lenVehicles = groups.length
    if (lenDraggers > lenVehicles) {
      lenDraggers = lenVehicles
    }
    for (let i = 0; i < lenDraggers; i++) {
      let draggerId = draggerVehicles[i]
      let idx = startIndex + i
      if (idx < lenVehicles) {
        selectedVehicles = {
          ...selectedVehicles,
          [idx]: draggerId,
        }
      }
    }
    return { selectedVehicles, swapSelectedVehicleContainer }
  }

  onHover = (vehicleIdDragger, index, selectedVehicleIds) => {
    let result
    if (selectedVehicleIds) {
      result = this.hoverVehicles(selectedVehicleIds, index)
    } else {
      result = this.hoverVehicles(vehicleIdDragger, index)
    }

    this.setState({
      hoverSelected: result.hoverSelected
    })
  }

  onDrop = (vehicleIdDragger, index, selectedVehicleIds) => {
    let result
    if (selectedVehicleIds) {
      result = this.addVehicles(selectedVehicleIds, index)
    } else {
      result = this.addVehicle(vehicleIdDragger, index)
    }
    this.setState({
      selectedVehicles: result.selectedVehicles,
      swapSelectedVehicleContainer: result.swapSelectedVehicleContainer,
    })
  }

  unHover = () => {
    this.setState({
      hoverSelected: []
    })
  }

  // resetSelectVehicles = () => {
  //   let { selectedVehicles } = this.state
  //   const { updateVehicleInDrawer } = this.props
  //   if (!selectedVehicles) return
  //   for (let pos in selectedVehicles ) {
  //     let id = selectedVehicles[pos]
  //     updateVehicleInDrawer(id, pos)
  //   }
  //   this.setState({
  //     selectedVehicles: {},
  //     invalidVehicles: []
  //   })
  // }

  unselectAll = () => {
    this.setState({
      selectedVehicleIds: [],
    })
  }

  handleTooltip = (hoverItem, open) => event => {
    const { currentTarget } = event
    this.setState({
      anchorEl: currentTarget,
      displayTooltip: open,
      hoverItem,
    })
  };

  itemRenderer = ({
    item,
    // timelineContext,
    itemContext,
    getItemProps,
    // getResizeProps
  }) => {
    let opacity = 1

    const background = item.bgColor
    const borderColor = itemContext.selected
      ? itemContext.dragging
        ? 'black'
        : (item.selectedBgColor || 'black')
      : item.color
    const borderWidth = itemContext.selected ? 1 : 0

    const border = `1px solid ${item.bgColor}`

    if (item.type === ITEM_TYPE.line)
      return (
        <div
          {...getItemProps({
            style: {
              backgroundColor: 'rgba(0,0,0,0)',
              borderColor: 'rgba(0,0,0,0)'
            },
          })}
        >
          <hr
            style={{
              height: '1px',
              border: '1px dashed',
              borderColor: item.bgColor
            }}
          >
          </hr>
        </div>
      )

    if (item.type === ITEM_TYPE.holder)
      return (
        <div
          {...getItemProps({
            style: {
              background: 'white',
              color: item.color,
              border: `2px dashed ${item.bgColor}`,
              borderRadius: 4,
            },
          })}
        >
          <div
            style={{
              height: itemContext.dimensions.height,
              overflow: 'hidden',
              paddingLeft: 3,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              textAlign: 'center'
            }}
          >
            {itemContext.title}
          </div>
        </div>
      )

    return (
      <div
        {...getItemProps({
          style: {
            background,
            color: item.color,
            border,
            borderColor,
            borderStyle: 'solid',
            borderWidth,
            borderRadius: 4,
            opacity: opacity
          },
        })}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {itemContext.title}
        </div>
      </div>
    )
  }

  getVehicle = index => {
    let { vehicles } = this.props
    if (!vehicles) return null
    let plate
    let {
      hoverSelected,
      selectedVehicleIds,
      selectedVehicles,
      groups,
    } = this.state
    let isHover = hoverSelected && hoverSelected.indexOf(index) !== -1
    let isSelected = false
    let vehicleId = selectedVehicles ? selectedVehicles[index] : -1
    let { isError } = groups[index]
    if (vehicleId !== -1) {
      isSelected = selectedVehicleIds && selectedVehicleIds.indexOf(vehicleId) !== -1
      let vs = vehicles.filter(v => v.id === vehicleId)
      if (vs && vs.length > 0) {
        plate = vs[0].plate
      }
    }
    return (
      <VehicleColWithDragDrop
        id={vehicleId}
        plate={plate}
        onDrop={this.onDrop}
        index={index}
        isHover={isHover}
        onHover={this.onHover}
        unHover={this.unHover}
        removeVehicle={this.removeVehicle}
        isSelected={isSelected}
        unselectAll={this.unselectAll}
        selectedVehicleIds={selectedVehicleIds}
        isError={isError}
      />
    )
  }

  getSelectedVehicles = () => {
    return this.state.selectedVehicles || {}
  }

  getAllBlocks = () => {
    return this.state.groups || []
  }

  getSwapSelectedVehiclesContainer = () => {
    return this.state.swapSelectedVehicleContainer || {}
  }

  onPaste(evt) {
    let { vehicles, updateVehicleInDrawerAddVehicleToTimeline } = this.props
    evt.preventDefault()
    evt.stopPropagation()
    let paste = (evt.clipboardData || window.clipboardData).getData('text')
    let licensePlates = toarray(paste)
    let selected = {}
    for (let idx in licensePlates) {
      let vehilce = vehicles.find(v => {
        return slugify(v.plate) === slugify(licensePlates[idx])
      })
      if (vehilce) {
        selected[idx] = vehilce.id
      }
    }
    updateVehicleInDrawerAddVehicleToTimeline(selected)
    this.setState({
      selectedVehicles: {
        ...this.state.selectedVehicles,
        ...selected
      }
    })
  }

  render() {
    let { translate, connectDropTarget } = this.props
    let vehicleLabel = translate('resources.vehicles.name', { smart_count: 2 })
    var defaultTimeStart = moment()
      .startOf('day')
      .toDate().getTime()
    var defaultTimeEnd = moment()
      .startOf('day')
      .add(1, 'day')
      .toDate().getTime()
    const {
      groups = [],
      items = [],
      visibleTimeStart,
      visibleTimeEnd,
      appBarWidth,
    } = this.state
    if (groups.length === 0) return null
    if (visibleTimeStart)
      defaultTimeStart = visibleTimeStart
    if (visibleTimeEnd)
      defaultTimeEnd = visibleTimeEnd
    const newGroups = groups
      .map((group, index) => {
        return Object.assign({}, group, {
          title: (
            this.getVehicle(index)
          )
        })
      })
    let timelineWidth = appBarWidth - MAIN_PADDING - PAPER_PADDING - VEHICLE_DRAWER
    return (
      connectDropTarget(
        <div>
          <CalendarTimeline
            ref={this.calendarTimeline}
            groups={newGroups}
            items={items}
            resizeDetector={containerResizeDetector}
            sidebarContent={<p
              onPaste={(evt) => this.onPaste(evt)}
              contentEditable="true"
              style={{
                textAlign: 'center',
                margin: '5px 0'
              }}
            >
              { vehicleLabel }
            </p>}
            style={{ width: timelineWidth }}
            stackItems
            canMove={false}
            itemRenderer={this.itemRenderer}
            visibleTimeStart={defaultTimeStart}
            visibleTimeEnd={defaultTimeEnd}
            headerLabelGroupHeight={0}
          />
        </div>
      )
    )
  }
}

const ForwardRefCarSelectorBySchedule = ({ forwardRef, ...props }) => (
  <BaseCarSelectorBySchedule {...props} ref={forwardRef} />
)

export const CarSelectorBySchedule = DropTarget(
  ItemTypes.VEHICLE_COL,
  cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  })
)(enhance(ForwardRefCarSelectorBySchedule))
