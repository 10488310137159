import React, { Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  refreshView,
} from 'react-admin'
import {
  withStyles,
} from '@material-ui/core'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Create from './../ui/Create'
import Edit from './../ui/Edit'

////////////////////////////////////////////////////////////////////////////////
// List
const FareConditionListFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="resources.common.search" source="q" alwaysOn fullWidth />
      <ReferenceInput label="resources.common.fareCondition" source="fareConditionId"
        reference="fareConditions">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

const _FareConditionList = props => (
  <List {...props}
    filters={<FareConditionListFilter />}
    filter={{}}
    bulkActionButtons={false}
  >
    <Datagrid rowClick="edit">
      <TextField source="code" />
      <TextField source="name" />
      <TextField source="default" />
      <TextField source="type" />
    </Datagrid>
  </List>
)

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit,
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
})

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, { refreshView })
)

export const FareConditionList = enhance(_FareConditionList)
////////////////////////////////////////////////////////////////////////////////
// Create
export const FareConditionCreate = props => (
  <Create
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit

export class FareConditionEdit extends Component {

  render() {
    return <Edit
      {...this.props}
      undoable={false}
      title={<EditTitle resource={this.props.resource} />}
    >
      <FormSave />
    </Edit>
  }
}
