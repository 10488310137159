import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = {
  button: {
    margin: '0.5em',
  },
}

class ConfirmDialog extends Component {

  render() {
    let {
      open,
      onClose,
      content,
      onCancel,
      onOk,
      title,
      data,
      translate,
    } = this.props
    return (
      <div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => onClose()}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">{content}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => onOk && onOk(data)}
              variant="contained"
              color="primary"
            >
              {translate('button.ok')}
            </Button>
            {onCancel && <Button onClick={onCancel}>
              {translate('button.cancel')}
            </Button>}
            {onClose && <Button onClick={onClose}>
              {translate('button.close')}
            </Button>}
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), translate)
export default enhance(ConfirmDialog)
