export const CHANGE_BREADCRUMB = 'CHANGE_BREADCRUMB'
export const CHANGE_LOCATION = 'CHANGE_LOCATION'

export const changeBreadcrumb = ({ paths }) => {
  return ({
    type: CHANGE_BREADCRUMB,
    payload: paths,
  })
}

export const changeLocation = ({ location }) => {
  return ({
    type: CHANGE_LOCATION,
    payload: location,
  })
}