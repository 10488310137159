import React, { Component } from 'react'
import {
  Button,
  withStyles,
} from '@material-ui/core'
import { translate, showNotification } from 'react-admin'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import _ from 'lodash'
import { connect } from 'react-redux'
import { faUnlink } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withLoading } from '../common/withLoading'

const style = {
  icon: {
    marginRight: '0.5em',
  },
}

export function getProductFareByParentFareId(productId, routeProducts) {
  let childFares = _.reduce(routeProducts, (result, routeProduct) => {
    let { productFares } = routeProduct
    productFares = productFares.map(productFare => ({ ...productFare, productId }))
    result = [...result, ...productFares]
    return result
  }, [])
  let productFareByParentFareId = _.groupBy(childFares,'parentProductFareId')
  return _.reduce(productFareByParentFareId, (result, childFares, parentProductFareId) => {
    result[parentProductFareId] = { childFares }
    return result
  }, {})
}

class RemoveChildProductButton extends Component {

  state = {}

  removeChildProduct = () => {
    let {
      id,
      product,
      parentId,
      showNotification,
      updateNonChildProduct,
      removeProductFare,
      setLoading,
    } = this.props
    setLoading(true)
    Provider.dataProvider('REMOTE', 'products', {
      method: 'removeChildProduct',
      requestMethod: 'POST',
      data: { parentId, childId: id },
    }).then(
      (res) => {
        let { removedAgencyCount } = res.data 
        let removedProduct = { 
          ...product,
          agencyCount: product.agencyCount - removedAgencyCount,
          parentProductId: null,
        }
        updateNonChildProduct(removedProduct)
        removeProductFare(id)
        showNotification('notification.remove_child_product_success')
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    ).finally(() => {
      setLoading(false)
    })
  }

  render() {
    let { classes, translate, isLoading } = this.props
    return <Button
      disabled={isLoading}
      onClick={this.removeChildProduct}
    >
      <span>
        <FontAwesomeIcon className={classes.icon} icon={faUnlink} />
        {translate('button.remove_child_product')}
      </span>
    </Button>
  }
}

const enhance = compose(
  withStyles(style),
  translate,
  withLoading,
  connect(null, { showNotification })
)
export default enhance(RemoveChildProductButton)

