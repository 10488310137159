import React, { Component } from 'react'
import {
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import { ROOT_URL } from '../provider/data'
import { translate, showNotification, SimpleForm } from 'react-admin'
import compose from 'recompose/compose'
import GetAppIcon from '@material-ui/icons/GetApp'
import { connect } from 'react-redux'
import { DatePickerInput } from '../common/DatePicker'
import CustomToolbar from '../common/CustomToolbarForm'
import moment from 'moment'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  },
  colorPrimary: {
    color: '#3f51b5'
  },
}

class ExportForm extends Component {

  state = {
    record: {},
  }

  onSave = (record) => {
    let { onExport } = this.props
    onExport(record)
  }

  render() {
    let { record } = this.props
    return <SimpleForm
      resource="exports"
      record={record}
      toolbar={<CustomToolbar />}
      save={this.onSave}
    >
      <DatePickerInput
        resource="exports"
        source="date"
        keyboard={true}
        initValue={moment()}
        maxDate={moment().add(1, 'days')}
      />
    </SimpleForm>
  }
}

export class _ExportDialog extends Component {
  render() {
    let {
      open,
      onClose,
      translate,
      onExport,
      onError,
      record,
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
      >
        <DialogTitle>{translate('button.einvoice_sumary')}</DialogTitle>
        <DialogContent>
          <ExportForm
            onExport={onExport}
            record={record}
            onError={onError}
          />
        </DialogContent>
      </Dialog>
    )
  }
}

const enhanceDialog = compose(translate)
const ExportDialog = enhanceDialog(_ExportDialog)

class ExportEInvoiceSumaryButton extends Component {

  state = {
    open: false
  } 

  onOpen = () => {
    this.setState({ open: true })
  }

  onClose = () => {
    this.setState({ open: false })
  }

  export = (filter) => {
    let { provider = '00VNIS', date } = filter
    const token = localStorage.getItem('token')
    if (!date) {
      date = moment().toISOString()
    }
    window.location = `${ROOT_URL}/Charges/exportInvoiceRevenueToExcel?provider=${provider}&date=${date}&access_token=${token}`
    this.onClose()
  }

  render() {
    const { label, color, classes, disabled, ...props } = this.props
    const { open } = this.state
    return <> 
      <Button
        color={color}
        onClick={this.onOpen}
        className={classes.button}
        disabled={disabled}
        {...props}
      >
        <span>
          <GetAppIcon className={classes.icon} fontSize="small" />
          <span>{label ? label : translate('button.export')}</span>
        </span>
      </Button>
      <ExportDialog onExport={this.export} open={open} onClose={this.onClose} />
  </>
  }
}

const enhance = compose(withStyles(styles), translate, connect(null, { showNotification }))

export default enhance(ExportEInvoiceSumaryButton)
