import { take, put, race, fork } from 'redux-saga/effects'
import {
  CREATE_TRIP_FROM_SCHEDULE,
  fetchTripFromSchedule as fetchTripFromScheduleAction,
  FETCH_TRIP_FROM_SCHEDULE_SUCCESS,
  FETCH_TRIP_FROM_SCHEDULE_FAILURE,
} from './actions'
import { showNotification } from 'ra-core'

function* handleCreateTrip() {
  while (true) {
    let { params, onDone, handleError } = yield take(CREATE_TRIP_FROM_SCHEDULE)
    yield(put(fetchTripFromScheduleAction(params)))
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_TRIP_FROM_SCHEDULE_SUCCESS),
        failure: take(FETCH_TRIP_FROM_SCHEDULE_FAILURE)
      })
      if (success) {
        yield put(showNotification('notification.create_trip_success'))
        onDone()
        break
      }
      if (failure) {
        let error = failure.payload ? failure.payload.error : {}
        let { invalidVehicleIds } = error
        if (invalidVehicleIds) {
          let invalidVehicles = invalidVehicleIds.split(',')
          handleError(invalidVehicles)
        }
        yield put(showNotification('notification.create_trip_failure', 'warning'))
        break
      }
    }

  }
}

export function* schedule() {
  yield fork(handleCreateTrip)
}