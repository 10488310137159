import React from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  translate,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import compose from 'recompose/compose'

const enhance = compose(translate)


////////////////////////////////////////////////////////////////////////////////
// DeviceConfig
export const DeviceConfigList = enhance(props => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="value" />
    </Datagrid>
  </List>
))

////////////////////////////////////////////////////////////////////////////////
// Create
export const DeviceConfigCreate = enhance(props => (
  <Create
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Create>
))

////////////////////////////////////////////////////////////////////////////////
// Edit
export const DeviceConfigEdit = enhance(props => (
  <Edit
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <FormSave />
  </Edit>
))