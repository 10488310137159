import React from 'react'
import { 
  Tabs,
  Tab,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'

const TabPosHeaders = translate(({
  classes,
  isAdmin,
  isManager,
  translate,
  tabIndexPos,
  onChangeTabIndexPos,
  ...props
}) => {
  let { action } = props
  return (
    <Tabs
      value={tabIndexPos}
      onChange={(k, v) => onChangeTabIndexPos(v)}
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
    >
      {isAdmin && action === 'update' && <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.settingMenuPos')}
        value={0}
      />}
      {isAdmin && action === 'update' && <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.settingActionPos')}
        value={1}
      />}
    </Tabs>
  )
})

let styles = theme => ({
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
})

export default compose(
  translate,
  withStyles(styles)
)(TabPosHeaders)
