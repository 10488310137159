import React, { Component, Fragment } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  FunctionField,
  translate,
  refreshView,
  showNotification,
  ReferenceField,
} from 'react-admin'
import * as permission from '../utils/permission'
import FormSave from './FormSave'
import { withStyles, Tooltip, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import LinkField from './../common/LinkField'
import { Edit as EditIcon, Check as CheckIcon, Brush as BrushIcon } from '@material-ui/icons'
import _ from 'lodash'
import DeleteButton from './DeleteButton'
import { EditTitle } from '../common/Title'

const styleList = () => ({
  textCenter: {
    textAlign: 'center',
  },
  avatarCol: {
    display: 'flex'
  },
  name: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  checkDefault: {
    color: '#4caf50',
    display: 'flex',
    alignItems: 'center'
  },
  iconDefault: {
    width: 20,
    height: 20,
    marginRight: 5
  },
  btnDefault: {
    textTransform: 'none'
  }
})


export class _TicketLayoutList extends Component {

  state = {}

  getTags = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'ticketlayouts', {
      filter: {},
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  onSetDefault = async (id) => {
    try {
      let { refreshView, showNotification } = this.props
      let res = await Provider.dataProvider('REMOTE', 'ticketlayouts', {
        method: `settingDefault?id=${id}`,
        requestMethod: 'GET',
      })
      if (res && res.data) {
        refreshView()
        showNotification('notification.set_default_layout_success')
      }
    } catch (e) {
      showNotification(e.message, 'warning')
    }
  }

  render() {
    let { classes, translate, permissions, ...props } = this.props
    let isCompanyManager = permission.isCompanyManager(permissions)
    return (
      <List bulkActionButtons={false} {...props}>
        <Datagrid >
          <TextField source="name" />
          <ReferenceField
            reference="settinggroups"
            source="group"
            linkType={false}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField source="default" render={(record) => {
            const {id, default: layDefault =  false} = record
            return layDefault ? <div className={classes.checkDefault}>
              <CheckIcon className={classes.iconDefault}/>
              {translate('resources.ticketlayouts.common.default')} 
            </div> : <Button 
              className={classes.btnDefault}
              variant="outlined"
              color="primary"
              onClick={() => this.onSetDefault(id)}
            >{translate('resources.ticketlayouts.common.setDefault')}</Button>
          }}/>
          <FunctionField
            render={({ id }) => {
              return <Fragment>
                <Tooltip
                  title={translate('button.designTicket')}
                  enterDelay={100}
                >
                  <LinkField
                    path={`/ticketlayouts/${id}/editor`}
                    icon
                  >
                    <BrushIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
                <Tooltip
                  title={translate('button.edit')}
                  enterDelay={100}
                >
                  <LinkField
                    path={`/ticketlayouts/${id}`}
                    icon
                  >
                    <EditIcon fontSize="small" />
                  </LinkField>
                </Tooltip>
                {isCompanyManager && <DeleteButton id={id} />}
              </Fragment>
            }}
          />
        </Datagrid>
      </List>
    )
  }
} 

const enhanceList = compose(withStyles(styleList), translate, connect(null, { refreshView, showNotification }))
export const TicketLayoutList = enhanceList(_TicketLayoutList)

export const TicketLayoutCreate = props => (
  <Create
    undoable="false"
    title={<EditTitle resource={props.resource} />}
    {...props}
  >
    <FormSave />
  </Create>
)

export class TicketLayoutEdit extends Component {

  render() {
    let { id, permissions, resource, ...props } = this.props
    id = _.toNumber(id)
    return <Edit
      id={id}
      undoable={false}
      resource={resource}
      {...props}
    >
      <FormSave
        id={id}
      />
    </Edit>
  }
}
