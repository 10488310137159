import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  translate,
  Create,
  Edit,
  ReferenceField,
  FunctionField,
  SimpleForm
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { EditTitle } from '../common/Title'
import SearchInput from '../common/SearchInput'
import { validate } from './validate'
import FormSave from './FormSave'
import CustomToolbar from '../common/CustomToolbarForm'
import { ColorField } from './ColorField'
import { StatusField } from './StatusField'

const styleList = () => ({
  name: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  textCenter: {
    textAlign: 'center',
  },
  textRight: {
    textAlign: 'right',
  },
})

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 21,
  }
})

const TagFilter = withStyles(styleListFilter)(({ translate, classes, ...props }) => (
  <Filter {...props}>
    <SearchInput 
      helperText="resources.tags.helper_text"
      source="q"
      alwaysOn
    />
  </Filter>
))
const _TagList = ({ record, classes, translate, history, permissions, id, ...props }) => {
  return <List
    history={history}
    filters={<TagFilter translate={translate} />}
    bulkActionButtons={false}
    sort={{}}
    filterDefaultValues={{
      '../fields': [
        'id',
        'name',
        'color',
        'status',
      ]
    }}
    {...props}
  >
    <Datagrid rowClick="edit">
      <TextField 
        source="name"
      />
      <FunctionField
        source="color"
        render={ record => {
          let { color } = record
          return <ColorField color={color} />
        }}
      />      
      <ReferenceField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="status"
        reference="tagstatuses"
        linkType={false}
        allowEmpty
      >
        <StatusField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
}

const enhanceTagList = compose(withStyles(styleList), translate)
export const TagList = enhanceTagList(_TagList)
////////////////////////////////////////////////////////////////////////////////
// Create
export const TagCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
    undoable="false"
  >   
    <SimpleForm
      toolbar={<CustomToolbar />}
      validate={validate}
    >
      <FormSave />
    </SimpleForm>
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit
export const TagEdit = ({ id, record, location, basePath, permissions, ...props } ) => {
  return <Edit
    id={id}
    record={record}
    basePath={basePath}
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} render={record => record.name} />}
  >
    <SimpleForm
      toolbar={<CustomToolbar />}
      validate={validate}
    >
      <FormSave 
        id={id}
        record={record}
        basePath={basePath}
      />
    </SimpleForm>
  </Edit>
}
