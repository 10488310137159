import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import {
  SimpleForm,
  ArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  FormDataConsumer,
} from 'react-admin'
import Principal from './Principal'

const principals = [
  { id: 'devices', name: 'resources.devices.name' },
  { id: 'apps', name: 'resources.apps.name' },
]

export class NotificationDialog extends Component {

  render() {
    let { open, handleClose, sendNotification } = this.props
    return <Dialog
      open={open}
      onClose={() => handleClose()}
      fullWidth={true}
      maxWidth="md"
    >
      <DialogTitle>Gửi thông báo</DialogTitle>
      <DialogContent>
        <SimpleForm save={ record =>sendNotification(record) } >
          <ReferenceInput
            resource="notifications"
            basePath="/notifications"
            reference="notifications"
            record={{}}
            source="notification"
            fullWidth
          >
            <SelectInput optionText="title" />
          </ReferenceInput>
          <ArrayInput source="principals" fullWidth>
            <SimpleFormIterator>
              <SelectInput source="principalType" choices={principals} optionText="name" fullWidth/>
              <FormDataConsumer>
                {({ scopedFormData = {}, getSource }) => {
                  let { principalType } = scopedFormData
                  return principalType && <Principal
                    principalType={principalType}
                    source={getSource('ids')}
                  />
                }}
              </FormDataConsumer>
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }
}

class PushNotificationButton extends Component {
  state = {
    openDialog: false
  }

  openDialog = () => {
    this.setState({
      openDialog: true
    })
  }

  handleClose = () => {
    this.setState({
      openDialog: false
    })
  }

  sendNotification = async record => {
    // let res = await Provider.dataProvider('REMOTE', 'notifications', {
    //   method: 'send',
    //   requestMethod: 'POST',
    //   data: record
    // })
    // console.log('res', res)
    this.setState({
      openDialog: false
    })
  }

  render() {
    let { openDialog } = this.state
    return <>
      <Button
        color="primary"
        onClick={this.openDialog}
      >
        Push
      </Button>
      <NotificationDialog
        open={openDialog}
        handleClose={this.handleClose}
        sendNotification={this.sendNotification}
      />
    </>
  }
}

export default PushNotificationButton
