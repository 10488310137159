import React, { Component } from 'react'
import MqttClient from './../model/mqtt-client'
import { getCurrentCompany, getCurrentUser } from '../utils/commonUtil'
import { withSnackbar } from '../common/notistack'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import {
  IconButton,
  Tooltip,
  withStyles,
  Fade,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography,
  CardActions,
} from '@material-ui/core'
import { SeatIcon } from '../icons'
import { translate } from 'react-admin'
import { push } from 'react-router-redux'
import CallIcon from '../icons/call'
import EndCallIcon from '../icons/endcall'
import { moment } from '../common/format'
import { pickUp, rejectCall } from '../utils/device-action'
import withMQTT from '../mqtt/withMQTT'
import { CALL_CENTER } from '../common/mqttEventEmitter'
import { Provider } from '../provider'
import { format } from '../common/data-type'

export const anchorOriginSnackBar = {
  vertical: 'top',
  horizontal: 'right',
}

const styles = () => ({
  actionButton: {
    color: 'white',
    margiRight: 8,
  }
})

const styleSnackbar = () => ({
  root: {
    backgroundColor: 'rgba(76,76,76)',
    color: 'white',
    width: 435,
  },
  container: {
    display: 'flex',
    flexDirection: 'column'
  },
  callCenterInfo: {
    fontSize: 14,
    color: 'white',
  },
  containerInfo: {
    display: 'flex',
  },
  icon: {
    height: 50,
    width: 50,
    padding: 13,
    backgroundColor: 'rgba(172,220,234)',
    border: '1px solid white',
  },
  containerSumaryText: {
    display: 'flex',
    padding: 8,
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 16,
    width: '70%'
  },
  button: {
    paddingLeft: 16,
    width: '30%'
  },
  startCall: {
    backgroundColor: 'rgba(127,186,0)',
    color: 'white',
    marginRight: 8
  },
  endCall: {
    backgroundColor: 'rgba(232,17,35)',
    color: 'white',
    marginRight: 8,
  },
  nonePadding: {
    padding: 0,
  },
  rootHeader: {
    padding: 8,
  },
  rootContent: {
    padding: 4,
  },
  expand: {
    margin: 0,
    width: '100%',
    backgroundColor: 'rgba(76,76,76)',
  },
  expandSumary: {
    paddingLeft: 16
  },
  textColor: {
    color: 'white'
  },
  menu: {
    margin: 0,
    paddingLeft: 24,
    paddingRight: 16,
    //listStyle: 'none',
  },
  menuItem: {
    marginBottom: 4,
  },
  containerMenu: {
    marginBottom: 8,
    marginLeft: 12, 
  }
})

export const INCOMING = '00INC'
export const MISSING = '20MIS'
export const OUTGOING = '10OUT'

const CALLING = '00CAL'
const DIALING = '10DIA'
const END = '20END'

class _SnackbarCustom extends Component {

  state = {
    popover: {
      open: false,
      anchorEl: null,
      content: '',
    }
  }

  handlePopoverOpen = event => {
    let { translate, reservationDate } = this.props
    this.setState({
      popover: {
        open: true,
        anchorEl: event.target,
        content: `${translate('resources.calllogs.fields.lastestReservation')} : ${reservationDate ? moment(reservationDate).format('l') : translate('resources.calllogs.fields.noRecord')}`
      }
    })
  }

  handlePopoverClose = () => {
    this.setState({
      popover: {
        open: false,
        anchorEl: null,
        content: '',
      }
    })
  }

  render() {
    let {
      callLogId,
      endCall,
      pushToTrip,
      classes,
      phoneNumer,
      customerName,
      callCenterPhone,
      callCenter,
      translate,
      answer,
      type,
      userId,
      companyId,
      status,
      trips,
    } = this.props
    //let { popover } = this.state
    return <Card
      onMouseEnter={this.handlePopoverOpen}
      onMouseLeave={this.handlePopoverClose}
      classes={{ root: classes.root }}>
      <CardHeader
        title={<Typography className={classes.textColor} variant="h6">
          {translate('resources.calllogs.name')}: {callCenter} {callCenterPhone ? `[${callCenterPhone}]` : ''}
        </Typography>}
        classes={{ root: classes.rootHeader }}
      />
      <Divider />
      <CardContent classes={{ root: classes.rootContent }}>
        <div className={classes.containerSumaryText}>
          <div className={classes.text}>
            <Typography className={classes.textColor} variant="h6">
              {customerName ? (customerName.length <= 20 ? customerName : `${customerName.substring(0, 20)}...` ) : 'Khach hang moi'}
            </Typography>
            <Typography className={classes.textColor} variant="subtitle1">
              {phoneNumer ? format(phoneNumer, 'phone') : ''}
            </Typography>
          </div>
          <div className={classes.button}>
            {status === DIALING &&
                <IconButton
                  onClick={() => pushToTrip(phoneNumer, callLogId)}
                  className={classes.startCall}
                >
                  <Tooltip
                    title={translate('resources.viewtrips.fields.makeReservation')}
                    enterDelay={100}
                  >
                    <SeatIcon fontSize="small" />
                  </Tooltip>
                </IconButton>}
            {status === CALLING && type === INCOMING && <IconButton
              onClick={(event) => {
                event.preventDefault()
                answer({ userId, companyId, callLogId })
              }}
              className={classes.startCall}
            >
              <Tooltip
                title={translate('resources.calllogs.fields.startCall')}
                enterDelay={100}
              >
                <CallIcon fontSize="small" />
              </Tooltip>
            </IconButton>}
            <IconButton
              className={classes.endCall}
              onClick={(event) => {
                event.preventDefault()
                endCall({ phoneNumer, userId, companyId, callLogId })
              }}
            >
              <Tooltip
                title={translate('resources.calllogs.fields.endCall')}
                enterDelay={100}
              >
                <EndCallIcon fontSize="small" />
              </Tooltip>
            </IconButton>
          </div>
        </div>
      </CardContent>
      {trips && trips.length > 0 && <>
      <Divider />
      <CardActions className={classes.nonePadding}>
        <div className={classes.containerMenu}>
          <Typography 
            variant="subtitle1"
            className={classes.textColor}
          >
            {translate('resources.calllogs.lastestTrip', { number: trips.length })}:
          </Typography>
          <Typography variant="body1" className={classes.textColor}>
            <ul className={classes.menu}>
              {trips.map((trip, idx) => { 
                return <li key={idx} className={classes.menuItem}>
                  {moment(trip.departureTime).format('HH:mm DD/MM')}&nbsp;-&nbsp;{trip.name}
                </li>
              })}
            </ul>
          </Typography>
        </div>
      </CardActions>
    </>}
    </Card>
  }
}

const SnackbarCustom = withStyles(styleSnackbar)(_SnackbarCustom)

class CallCenter extends Component {

  constructor(props) {
    super(props)
    let currentCompany = getCurrentCompany() || {}
    let currentUser = getCurrentUser() || {}
    let companyId = currentCompany.id
    let userId = currentUser.id
    let observeAll = false
    this.state = {
      snackBar: {},
      companyId,
      userId,
      callLog: {},
      expandedPanel: false,
      observeAll,
    }
  }

  // a callcenter can listen to all agents or only single agent
  getTopic = () => {
    let { companyId, userId } = this.state
    if (!companyId) {
      return null
    }
    if (!userId) {
      return null
    }
    return `user/${userId}`
  }

  async componentDidMount() {
    let topic = this.getTopic()
    let { mqttEmitter, subscribe } = this.props
    this.setState({ topic })
    subscribe(topic)
    mqttEmitter.on(CALL_CENTER, (message) => this.onMessage(message))
  }

  componentWillUnmount = () => {
    let { unsubscribe } = this.props
    let { topic } = this.state
    unsubscribe(topic)
  }

  handleChangeExpand = (expanded) => {
    this.setState({ expandedPanel: expanded })
  }

  getCustomerByPhone = async phone => {
    let res = await Provider.dataProvider('REMOTE', 'customers', {
      method: 'findByPhone',
      data: { phone, withHistory: 1 }
    })
    if (res && res.data) {
      return res.data
    }
  }

  onMessage = async (message) => {
    let data = JSON.parse(message)
    let { payload } = data
    let { status, type, from, to } = payload
    let phoneNumer = from
    if (!phoneNumer) return
    let { customer = {}, trips } = await this.getCustomerByPhone(phoneNumer)
    customer = customer !== null ? customer : {}
    payload ={ ...payload, customerName: customer.fullName, trips }
    if (status === CALLING) {
      this.receiveCall(payload)
    }
    if (status === DIALING) {
      this.calling(payload)
    }
    if (status === END) {
      if (type === OUTGOING) {
        phoneNumer = to
      }
      this.disposeCallDialog(phoneNumer)
    }
  }

  observeCall = () => {
    let { topic } = this.state
    if (topic) {
      MqttClient.addTopic(topic)
      MqttClient.onMessage(this.onMessage)
    }
  }

  pushToTrip = (from, id) => {
    let { push } = this.props
    push({
      pathname: '/reservations/company_create',
      search: `customer_phone=${from}&call_log=${id}`
    })
  }

  answer = ({ userId, companyId, callLogId }) => {
    pickUp({ userId, companyId, callLogId })
  }

  endCall = ({ phoneNumer, userId, companyId, callLogId }) => {
    rejectCall({ phoneNumer, userId, companyId, callLogId })
    this.disposeCallDialog(phoneNumer)
  }

  disposeCallDialog = phoneNumer => {
    let { snackBar } = this.state
    const { closeSnackbar } = this.props
    let existKeys = snackBar[phoneNumer]
    if (existKeys) {
      for (let i = 0; i< existKeys.length; i++) {
        closeSnackbar(existKeys[i])
      }
    }
    this.setState({ snackBar: { ...snackBar, [phoneNumer]: [] } })
  }

  receiveCall = (callLog) => {
    let { enqueueSnackbar, translate } = this.props
    let { snackBar } = this.state
    let {
      from,
      customerName,
      code,
      reservationDate,
      to,
      id,
      type,
      username,
      userId,
      companyId,
      status,
      trips,
    } = callLog

    let phoneNumer = from
    let callCenterPhone = to
    if (type === OUTGOING) {
      phoneNumer = to
      callCenterPhone = from
    }
    let message = `${customerName} ${from} --> ${to} [${reservationDate}]${code}`
    let key = enqueueSnackbar(message, {
      persist: true,
      anchorOrigin: anchorOriginSnackBar,
      offsetObj: { view: 50, snackbar: 36 },
      children: <SnackbarCustom
        payload={{...callLog}}
        callLogId={id}
        endCall={this.endCall}
        phoneNumer={phoneNumer}
        customerName={customerName}
        callCenterPhone={callCenterPhone}
        reservationDate={reservationDate}
        answer={this.answer}
        callCenter={username}
        translate={translate}
        type={type}
        userId={userId}
        companyId={companyId}
        status={status}
        trips={trips}
      />
    })
    let existKeys = snackBar[phoneNumer] || []
    existKeys.push(key)
    this.setState({
      snackBar: {
        ...snackBar,
        [phoneNumer]: existKeys,
      },
    })
  }

  calling = callLog => {
    let { enqueueSnackbar, translate, closeSnackbar } = this.props
    let { from, id, customerName, to, reservationDate, username, userId, companyId, status, type, trips } = callLog
    let phoneNumer = from
    if (type === OUTGOING) {
      phoneNumer = to
    }
    let message = `${customerName} ${from} --> ${to}`
    let { snackBar } = this.state
    let existKeys = snackBar[phoneNumer]
    if (existKeys) {
      for (let i = 0; i< existKeys.length; i++) {
        closeSnackbar(existKeys[i])
      }
    }
    existKeys = []
    let key = enqueueSnackbar(message, {
      persist: true,
      TransitionComponent: Fade,
      anchorOrigin: anchorOriginSnackBar,
      offsetObj: { view: 50, snackbar: 36 },
      children: <SnackbarCustom
        payload={{...callLog}}
        callLogId={id}
        status={status}
        endCall={this.endCall}
        phoneNumer={from}
        customerName={customerName}
        callCenter={username}
        callCenterPhone={to}
        reservationDate={reservationDate}
        translate={translate}
        pushToTrip={this.pushToTrip}
        userId={userId}
        companyId={companyId}
        trips={trips}
      />
    })
    existKeys.push(key)
    this.setState({ snackBar: { ...snackBar, [phoneNumer]: existKeys } })
  }

  render() {
    return <div></div>
  }
}

const enhance = compose(
  withSnackbar,
  connect(null, { push }),
  translate,
  withStyles(styles),
  withMQTT({ eventListener: CALL_CENTER }),
)

export default enhance(CallCenter)
