import momentJS from 'moment'

export const dateFormat = 'DD/MM/YYYY'
export const dateTimeFormat = 'HH:mm DD/MM/YYYY'
export const timeFormat = 'HH:mm'
export const shortDateTimeFormat = 'HH:mm DD/MM'
export const currencyFormat = '0,0'
export const fullDateTimeFormat = 'YYYY-MM-DD'

export const moment = (date, format = moment.ISO_8601) => momentJS(date, format)
