import { put, race, take, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import {
  GET_LIST_SERVICE_DAYS,
  GET_LIST_SERVICE_DAYS_SUCCESS,
  GET_LIST_SERVICE_DAYS_FAILURE,
  GET_SERVICE_DAYS,
  GET_SERVICE_DAYS_FAILURE,
  GET_SERVICE_DAYS_SUCCESS
} from './actions'

// ------------------------------------------------------------------------------------------
function* getListServiceDays(action) {
  let { start, done } = action
  start && start()

  const { success, failure } = yield race({
    success: take(GET_LIST_SERVICE_DAYS_SUCCESS),
    failure: take(GET_LIST_SERVICE_DAYS_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      done && done(payload.data)
    } else {
      yield put(showNotification('Get service days with no data'));
    }
  }

  if (failure) {
    yield put(showNotification('Cannot get service days'));
  }
}

export function* requestGetListServiceDays() {
  yield takeEvery(GET_LIST_SERVICE_DAYS, getListServiceDays)
}

// ------------------------------------------------------------------------------------------
function* getSelectedServiceDays(action) {
  let { start, done } = action
  start && start()

  const { success, failure } = yield race({
    success: take(GET_SERVICE_DAYS_SUCCESS),
    failure: take(GET_SERVICE_DAYS_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      done && done(payload.data)
    } else {
      yield put(showNotification('Get service days with no data'));
    }
  }

  if (failure) {
    yield put(showNotification('Cannot get service days'));
  }
}

export function* requestGetSelectedServiceDays() {
  yield takeEvery(GET_SERVICE_DAYS, getSelectedServiceDays)
}