export function validate(values, props){  
  let errors = {}
  let { translate } = props

  let name = values && values.name && values.name.trim()
  if (!name || name.length === 0) {
    errors.name = translate('error_messages.required.routes.name')
  }

  return errors
}
