const validateContact = ({ firstName, lastName, phone }) => {
  let result = false
  let message = null

  if (!phone) {
    result = false
    message = 'notification.reservation.missingPhone'
    return { result, message }
  }

  // if (!firstName) {
  //   result = false
  //   message = 'notification.reservation.missingName'
  //   return { result, message }
  // }

  // if (!lastName) {
  //   result = false
  //   message = 'notification.reservation.missingName'
  //   return { result, message }
  // }

  result = true
  return { result, message }
}

// ------------------------------------------------------------------------
export const validateTrip = (data) => {
  let { trips, passengers, charges } = data

  let result = false
  let message = null

  if (!trips) {
    result = false
    message = 'notification.reservation.missingTrip'
    return { result, message }
  }

  let selectedTrips = Object.values(trips)
  if (selectedTrips.length <= 0) {
    result = false
    message = 'notification.reservation.missingTrip'
    return { result, message }
  }

  if (!passengers || passengers.length <= 0) {
    result = false
    message = 'notification.reservation.missingPassenger'
    return { result, message }
  }

  let totalPassenger = passengers.length
  for (let i = 0; i < totalPassenger; i++) {
    let passenger = passengers[i]
    let { firstName, lastName, phone } = passenger
    let res = validateContact({ firstName, lastName, phone })
    if (!res.result) {
      return res
    }
  }

  if (!charges || charges.length <= 0) {
    result = false
    message = 'notification.reservation.missingCharge'
    return { result, message }
  }

  result = true
  return { result, message }
}

// ------------------------------------------------------------------------
export const validateBookingInformation = (data) => {
  let { bookingInformation } = data
  let result = false
  let message = null

  if (!bookingInformation) {
    result = false
    message = 'notification.reservation.missingContact'
    return { result, message }
  }

  let { contact } = bookingInformation
  if (!contact) {
    result = false
    message = 'notification.reservation.missingContact'
    return { result, message }
  }

  let { firstName, lastName, phone } = contact
  return validateContact({ firstName, lastName, phone })
}

// ------------------------------------------------------------------------
export const validatePassengers = (data) => {
  let { passengers } = data
  let result = false
  let message = null

  if (!passengers || passengers.length <= 0) {
    result = false
    message = 'notification.reservation.missingPassenger'
    return { result, message }
  }

  let totalPassenger = passengers.length
  for (let i = 0; i < totalPassenger; i++) {
    let passenger = passengers[i]
    let { firstName, lastName, phone } = passenger
    let res = validateContact({ firstName, lastName, phone })
    if (!res.result) {
      return res
    }
  }

  result = true
  return { result, message }
}

// ------------------------------------------------------------------------
export const validateReview = (data) => {
  let result = data !== undefined && data !== null
  let message = null

  return { result, message }
}

// ------------------------------------------------------------------------
export const validatePayment = (data) => {
  let { isPayLater, transaction } = data
  
  let result = false
  let message = null

  if (!isPayLater) {
    let {
      paid,
      paymentMethodId,
      type,
      status,
      currencyId
    } = transaction

    if (!paid || !paymentMethodId || !type || !status || !currencyId) {
      result = false
      message = 'notification.reservation.missingTransactionInfo'
      return { result, message }
    }
  }

  result = true
  return { result, message }
}
