import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  Avatar,
  Grid,
  Button,
  withStyles,
  ExpansionPanel,
  ExpansionPanelActions,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  GridList,
  GridListTile,
  GridListTileBar,
  Divider,
  ListItem,
  ListItemText,
  ListItemAvatar,
  IconButton,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import { connect } from 'react-redux'
import { mapAction } from './actions'
import * as _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faPlus, faSave, faExchangeAlt } from '@fortawesome/free-solid-svg-icons'
import ListStopLeftMenu from './ListStopLeftMenu'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  DropTarget,
  DragSource,
  DragDropContext,
} from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

const ItemTypes = {
  ITEM: 'ItemMobile',
}

const cardTarget = {
  drop() {
    //
  },
}

const itemSource = {
  beginDrag(props) {
    let { id, index } = props
    return {
      id: id,
      originalIndex: index,
    }
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id
  },
  async endDrag(props, monitor) {
    const { id, originalIndex } = monitor.getItem()
    const didDrop = monitor.didDrop()
    if (!didDrop) {
      props.onMove(id, originalIndex)
    } else {
      await props.endDrop()
    }
  }
}

const itemTarget = {
  canDrop() {
    return false
  },

  hover(props, monitor) {
    const { id: draggedId } = monitor.getItem()
    const { id: overId, index: overIndex } = props
    if (!monitor.isOver({ shallow: true })) {
      if (draggedId !== overId) {
        props.onMove(draggedId, overIndex)
      }
    }
  },
}

class _ItemWithDragDrop extends Component {
  render() {
    const {
      index,
      stop,
      panMapTo,
      connectDragSource,
      connectDropTarget,
      isDragging,
    } = this.props
    let opacity = isDragging ? 0 : 1
    return connectDragSource(
      connectDropTarget(
        <div style={{ ...{}, opacity }} >
          <Item
            panMapTo={panMapTo}
            key={index}
            stop={stop}
            index={index}
          />
        </div>
      )
    )
  }
}

export const ItemWithDragDrop = DropTarget(
  ItemTypes.ITEM,
  itemTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  }))(DragSource(
  ItemTypes.ITEM,
  itemSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }),
)(_ItemWithDragDrop))

const itemStyles = {
  root: {
    width: 100,
    height: 100,
    marginRight: 8,
    boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
  },
  avatar: {
    margin: 'auto',
    marginTop: 8,
    background: 'transparent',
    color: 'black',
    fontSize: 30,
    fontWeight: 'bold',
  },
  tileBar: {
    background: '#121c57',
  },
  rootSubtitle: {
    height: 50,
  },
}

class _Item extends Component {
  render() {
    const {
      classes,
      index,
      stop,
      panMapTo,
    } = this.props
    return <GridListTile
      className={classes.root}
      onClick={() => panMapTo(stop)}
    >
      <Avatar
        aria-label={stop.name}
        title={index}
        className={classes.avatar}
      >
        {index + 1}
      </Avatar>
      <GridListTileBar
        className={classes.tileBar}
        classes={{
          rootSubtitle: classes.rootSubtitle,
        }}
        title={stop.name}
        subtitle={`${stop.distance ? stop.distance.toFixed(1) : 0}km`}
      />
    </GridListTile>
  }
}

const enhanceItem = compose(withStyles(itemStyles))
const Item = enhanceItem(_Item)

const itemsStyles = theme => ({
  rootGridList: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    display: 'flex',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    width: '100%',
    display: 'flex',
    padding: 16,
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
    margin: 0,
  },
  noMargin: {
    margin: 0,
  },
})
const _Items = ({
  routeStops,
  classes,
  panMapTo,
  connectDropTarget,
  onMove,
  endDrop,
  findItem,
  isDragging,
}) => {
  return connectDropTarget(
    <div className={classes.rootGridList}>
      <GridList className={classes.gridList} cols={2.5}>
        {routeStops.map((stop, index) => stop.type === 'stop' ? <ItemWithDragDrop
          isDragging={isDragging}
          panMapTo={panMapTo}
          key={index}
          stop={stop}
          index={index}
          onMove={onMove}
          findItem={findItem}
          endDrop={endDrop}
        /> : <Item
          panMapTo={panMapTo}
          key={index}
          stop={stop}
          index={index}
        />
        )}
      </GridList>
    </div>
  )

}

const DragDropItems = DragDropContext(HTML5Backend)(
  DropTarget(ItemTypes.ITEM, cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  }))(_Items)) 

const enhanceItems = compose(withStyles(itemsStyles))
const Items = enhanceItems(DragDropItems)

const horizontalStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    zIndex: 1700,
    padding: 0,
  },
  noPadding: {
    padding: 0,
  },
  actionContainer: {
    padding: 0,
    margin: 0,
  },
  headerContainer: {
    padding: 0,
  },
  expanded: {
    margin: 0,
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 600,
    paddingTop: 8
  },
  buttonContainer: {
    display: 'flex',
    width: '100%',
  },
  button: {
    flex: 1,
  },
  removeButton: {
    flex: 1,
    color: '#fb404b',
  },
  textBold: {
    fontWeight: 'bold',
  },
  rootGridList: {
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    display: 'flex',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    width: '100%',
    display: 'flex',
    padding: 16,
  },
  content: {
    '&$expanded': {
      margin: 0,
    },
    margin: 0,
  },
  noMargin: {
    margin: 0,
  },
})

const _HorizontalMapInfo = ({
  classes,
  tripLength,
  routeStops,
  route,
  translate,
  createNewStopMarker,
  saveRouteToServer,
  smartSaveRouteToServer,
  clearRouteStops,
  panMapTo,
  totalStop,
  companyManager,
  onMove,
  findItem,
  endDrop,
  isDragging,
}) => {

  let backgroundColor = route.color ? route.color : 'red'
  let routeStopsLength = routeStops.length
  let numberOfMiddleStops = _.reduce(routeStops, (result = 0, routeStop) => {
    if (routeStop && routeStop.type === 'stop') {
      result++
    }
    return result
  }, 0)
  return <div className={classes.root}>
    <ExpansionPanel 
      defaultExpanded
    >
      <ExpansionPanelSummary
        className={classes.headerContainer}
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar
              aria-label={route.number}
              title={route.number}
              style={{
                background: backgroundColor,
                color: '#fff',
                textAlign: 'center',
                fontSize: 8
              }}
            >
              {route.number}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{
              primary: classes.textBold
            }}
            primary={route.name}
            secondary={routeStopsLength > 0 ? (
              <div>
                <div>{`${translate('resources.common.length')}: ${tripLength.toFixed(1)}km`}</div>
                <div>{`${translate('resources.common.stops')}: ${routeStopsLength}/${totalStop}`}</div>
              </div>
            ) : <span>{translate('resources.routes.no_stop')}</span>
            }
          />
        </ListItem>
      </ExpansionPanelSummary>
      <Divider />
      <ExpansionPanelDetails className={classes.noPadding}>
        <Items
          routeStops={routeStops}
          isDragging={isDragging}
          panMapTo={panMapTo}
          findItem={findItem}
          endDrop={endDrop}
          onMove={onMove}
        />
      </ExpansionPanelDetails>
      <Divider />
      <ExpansionPanelActions
        className={classes.actionContainer}
        classes={{
          action: classes.noMargin
        }}
      >
        {companyManager && <div className={classes.buttonContainer}>
          <IconButton
            color="primary"
            onClick={() => clearRouteStops()}
            disabled={!(numberOfMiddleStops > 0)}
            className={classes.removeButton}
          >
            <FontAwesomeIcon icon={faTrash} size="xs" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => createNewStopMarker()}

            className={classes.button}
          >
            <FontAwesomeIcon icon={faPlus} size="xs" />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => saveRouteToServer()}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faSave} size="xs" />
          </IconButton>
          {smartSaveRouteToServer && <IconButton
            color="primary"
            onClick={() => smartSaveRouteToServer()}
            className={classes.button}
          >
            <FontAwesomeIcon icon={faExchangeAlt} size="xs" />
          </IconButton>
          }
        </div>}
      </ExpansionPanelActions>
    </ExpansionPanel>
  </div>
}

const enhanceHorizontalMapInfo = compose(withStyles(horizontalStyles), translate)

const HorizontalMapInfo = enhanceHorizontalMapInfo(_HorizontalMapInfo)

const verticleStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  dialog: {
    minWidth: 600,
  },
  buttonText: {
    fontSize: 10,
    fontWeight: 600,
    paddingTop: 8
  },
  menuButton: {
    display: 'grid',
    justifyItems: 'center'
  },
  menuRemoveButton: {
    display: 'grid',
    justifyItems: 'center',
    color: '#fb404b',
  },
  buttonContainer: {
    padding: 8
  }
}

const _VerticleMapInfo = ({
  classes,
  tripLength,
  routeStops,
  route,
  translate,
  createNewStopMarker,
  saveRouteToServer,
  smartSaveRouteToServer,
  clearRouteStops,
  panMapTo,
  onMove,
  showStopMenu,
  findItem,
  endDrop,
  totalStop,
  companyManager,
  isXSmall,
}) => {

  let backgroundColor = route.color ? route.color : 'red'
  let routeStopsLength = routeStops.length
  let numberOfMiddleStops = _.reduce(routeStops, (result = 0, routeStop) => {
    if (routeStop && routeStop.type === 'stop') {
      result++
    }
    return result
  }, 0)
  return <Card className={classes.root}>
    <CardHeader
      avatar={
        <Avatar
          aria-label={route.number}
          title={route.number}
          style={{
            background: backgroundColor,
            color: '#fff',
            textAlign: 'center',
            fontSize: 8
          }}
        >
          {route.number}
        </Avatar>
      }
      title={route.name}
      subheader={
        routeStopsLength > 0 ? (
          <div>
            <div>{`${translate('resources.common.length')}: ${tripLength.toFixed(1)}km`}</div>
            <div>{`${translate('resources.common.stops')}: ${routeStopsLength}/${totalStop}`}</div>
          </div>
        ) : (<span>{translate('resources.routes.no_stop')}</span>)
      }
    />
    {companyManager && <Grid container className={classes.buttonContainer} spacing={8}>
      <Grid item xs={4} md={4}>
        <Button
          color="primary"
          onClick={() => clearRouteStops()}
          disabled={!(numberOfMiddleStops > 0)}
        >
          <span className={classes.menuRemoveButton}>
            <FontAwesomeIcon icon={faTrash} size="lg" />
            <span className={classes.buttonText}>
              {translate('button.clear_all_stops')}
            </span>
          </span>
        </Button>
      </Grid>
      <Grid item xs={4} md={4}>
        <Button
          color="primary"
          onClick={() => createNewStopMarker()}
        >
          <span className={classes.menuButton}>
            <FontAwesomeIcon icon={faPlus} size="lg" />
            <span
              className={classes.buttonText}
            >
              {translate('button.create_stop')}
            </span>
          </span>
        </Button>
      </Grid>
      <Grid item xs={4} md={4}>
        <Button
          color="primary"
          onClick={() => saveRouteToServer()}
        >
          <span className={classes.menuButton}>
            <FontAwesomeIcon icon={faSave} size="lg" />
            <span
              className={classes.buttonText}
            >
              {translate('button.save_route')}
            </span>
          </span>
        </Button>
      </Grid>
      {smartSaveRouteToServer && <Grid item xs={4} md={4}>
        <Button
          color="primary"
          onClick={() => smartSaveRouteToServer()}
        >
          <span className={classes.menuButton}>
            <FontAwesomeIcon icon={faExchangeAlt} size="lg" />
            <span
              className={classes.buttonText}
            >
              {translate('button.smart_save_route')}
            </span>
          </span>
        </Button>
      </Grid>
      }
    </Grid>}
    <ListStopLeftMenu
      routeStops={[...routeStops]}
      panMapTo={panMapTo}
      onMove={onMove}
      showStopMenu={showStopMenu}
      findItem={findItem}
      endDrop={endDrop}
      companyManager={companyManager}
      isXSmall={isXSmall}
    />
  </Card>
}

const enhanceVerticleMapInfo = compose(withStyles(verticleStyles), translate)

const VerticleMapInfo = enhanceVerticleMapInfo(_VerticleMapInfo)

class MapInfo extends Component {

  render() {
    let {
      tripLength,
      routeStops,
      route,
      createNewStopMarker,
      saveRouteToServer,
      smartSaveRouteToServer,
      clearRouteStops,
      panMapTo,
      onMove,
      showStopMenu,
      findItem,
      endDrop,
      totalStop,
      companyManager,
      isXSmall,
    } = this.props
    return !isXSmall ? <VerticleMapInfo
      routeStops={routeStops}
      tripLength={tripLength}
      route={route}
      createNewStopMarker={createNewStopMarker}
      saveRouteToServer={saveRouteToServer}
      smartSaveRouteToServer={smartSaveRouteToServer}
      clearRouteStops={clearRouteStops}
      panMapTo={panMapTo}
      onMove={onMove}
      showStopMenu={showStopMenu}
      findItem={findItem}
      endDrop={endDrop}
      totalStop={totalStop}
      companyManager={companyManager}
      isXSmall={isXSmall}
    /> : <HorizontalMapInfo
      routeStops={routeStops}
      tripLength={tripLength}
      route={route}
      createNewStopMarker={createNewStopMarker}
      saveRouteToServer={saveRouteToServer}
      smartSaveRouteToServer={smartSaveRouteToServer}
      clearRouteStops={clearRouteStops}
      panMapTo={panMapTo}
      totalStop={totalStop}
      companyManager={companyManager}
      isXSmall={isXSmall}
      findItem={findItem}
      endDrop={endDrop}
      onMove={onMove}
    />
  }
}

MapInfo.propTypes = {
  map: PropTypes.object,
}

MapInfo.defaultProps = {
  map: { route: {}, drawing: false }
}

const mapInfoEnhance = compose(
  connect((state) => {
    let { map } = state.network
    return { map }
  }, { mapAction }),
)

export default mapInfoEnhance(MapInfo)
