import { hourToMin, minToHour } from '../../utils/time'

function getInterval(startTimes, service) {
  let fromHour = startTimes[0]
  let toHour = startTimes[startTimes.length - 1]
  let nextFromHour = startTimes[1]
  let from = hourToMin(fromHour)
  let nextFrom = hourToMin(nextFromHour)
  let step = nextFrom - from
  return {
    service,
    fromHour,
    toHour,
    step,
  }
}

function createTimeTable(pattern, startTimes) {
  if (!startTimes) return
  let rows = startTimes.map((start, index) => {
    let cells = { index: index }
    let startItem = hourToMin(start)
    pattern.stops.forEach(function (item, i) {
      i++
      let step = Math.round(parseFloat(item.distance) / 40 * 60)
      startItem += step
      cells[i] = {
        index: i + 1,
        value: minToHour(startItem),
        base: step,
        step,
      }
    })
    return cells
  })
  let firstRow = {index: '-'}
  rows = [firstRow].concat(rows)
  return rows
}

export function createPattern(timetables, service, patternId, startTimes) {
  let newPattern = {}
  for (let i = 0; i < timetables.length; i++) {
    let pattern = timetables[i]
    if (pattern && pattern.patternId === patternId) {
      newPattern = pattern
    }
  }
  if (newPattern) {
    let { pattern } = newPattern
    let interval = getInterval(startTimes, service)
    let rows = createTimeTable(pattern, startTimes)
    let data = { interval, rows }
    newPattern.data = data
  }
  return newPattern
}
