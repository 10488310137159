import _ from 'lodash'

export const isEmpty = (array) => {
  return _.every(array, _.isEmpty)
}

export const isEmpty2D = (array) => {
  return _.every(array, isEmpty)
}

export const isNotEmpty2D = (array) => {
  return !isEmpty2D(array)
}
