const Direction = {
  FORWARD: 'forward',
  BACKWARD: 'backward'
}

export default class ReservationStep {

  constructor(index, name) {
    this.index = index
    this.name = name
    this.next = null
    this.prev = null
  }

  refs = (prev, next) => {
    this.prev = prev
    this.next = next
    this.refs = { prev, next }
  }

  move = (direction) => {
    switch (direction) {
      case Direction.FORWARD: {
        return this.next
      }
      case Direction.BACKWARD: {
        return this.prev
      }
      default:
        return
    }
  }

  canBackward = () => {
    return this.prev !== undefined && this.prev !== null
  }

  canForward = () => {
    return this.next !== undefined && this.next !== null
  }

  validate = (data, method) => {
    let res = {
      result: false,
      message: null
    }

    if (method) {
      res = method(data)
    }

    return res
  }
}
