import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const MissedCallIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" id="svg_2" d="m5.49967,9l1.00001,0c0.27615,0 0.50003,-0.26121 0.50003,-0.58339l0,-2.43376l4.29308,5.00878c0.19533,0.2279 0.45118,0.34182 0.70704,0.34182s0.51175,-0.11392 0.70704,-0.34182l6.14676,-7.17138c0.19529,-0.22784 0.19529,-0.59717 0,-0.82502l-0.70699,-0.82485c-0.19529,-0.22784 -0.51184,-0.22784 -0.70713,0l-5.43963,6.34653l-3.58604,-4.18388l2.086,0c0.27615,0 0.50003,-0.26121 0.50003,-0.58339l0,-1.16673c0,-0.32219 -0.22388,-0.58339 -0.50003,-0.58339l-4.50013,0c-0.5523,0 -1.00001,0.52236 -1.00001,1.16673l0,5.25035c0,0.32219 0.22383,0.58339 0.49998,0.58339z"/>
    <path stroke="null" id="svg_3" d="m23.59146,17.22304c-3.0952,-3.79765 -7.21202,-5.88953 -11.5921,-5.88953c-4.37956,0 -8.49639,2.09194 -11.59112,5.88953c-0.54498,0.66882 -0.54498,1.75641 0,2.42462l2.30505,2.82972c0.54208,0.66428 1.50412,0.66428 2.0462,0c0.77112,-0.94627 1.6477,-1.72622 2.60098,-2.31524c0.47762,-0.28713 0.78626,-0.89158 0.78185,-1.46299l0.34867,-3.06615c2.55214,-0.95026 4.49141,-0.95081 7.01814,-0.00055l0.34379,2.9898c0,0.64661 0.29401,1.23684 0.77454,1.54503c0.95815,0.59192 1.83474,1.37182 2.60637,2.31754c0.27104,0.33329 0.63438,0.51618 1.02312,0.51618c0.38823,0 0.75157,-0.18289 1.02261,-0.51557l2.3119,-2.83711c0.54503,-0.66887 0.54503,-1.75646 0,-2.42528z"/>
  </SvgIcon>
)

export default MissedCallIcon