import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const ReservationIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" id="svg_2" d="m4.43739,0a0.68758,1.33347 0 0 0 -0.66066,0.96875a0.68758,1.33347 0 0 0 0,0.01043l-2.75006,18.65624a0.68758,1.33347 0 0 0 0.66066,1.69792l3.51814,0l0.11011,1.52083a0.68758,1.33347 0 0 0 0.67946,1.14584l16.31773,0a0.68758,1.33347 0 0 0 0.66604,-1.65624l-2.75006,-21.33333a0.68758,1.33347 0 0 0 -0.66604,-1.01043l-15.12532,0zm0.78957,2.66667l13.79864,0l2.4063,18.66667l-14.84065,0l-1.36429,-18.66667zm-1.01516,5.05208l0.79763,10.94792l-2.41168,0l1.61405,-10.94792zm3.66316,2.94792l0,2.66667l11.00023,0l0,-2.66667l-11.00023,0z"/>
  </SvgIcon>
)

export default ReservationIcon