import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import CompanyAgencyForm from './CompanyAgencyForm'
import { connect } from 'react-redux'
import compose from 'recompose/compose'

export class AssignDialog extends Component {
  render() {
    let {
      open,
      onClose,
      translate,
      onDone,
      onError,
      record,
      action,
      form,
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={() => onClose()}
      >
        <DialogTitle>{translate('button.register_company', { smart_count: 2 })}</DialogTitle>
        <DialogContent>
          <CompanyAgencyForm onDone={onDone} onError={onError} record={record} action={action} form={form} />
        </DialogContent>
      </Dialog>
    )
  }
}

class RegisterCompanyButton extends Component {

  state = {
    open: false,
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  onSaveDone = () => {
    let { showNotification, refreshView } = this.props
    this.closeDialog()
    refreshView()
    showNotification('notification.register_company_success')
  }

  onSaveError = () => {
    let { showNotification,refreshView } = this.props
    this.closeDialog()
    refreshView()
    showNotification('notification.register_company_failure', 'warning')
  }

  render() {
    let { translate, id: agencyId } = this.props
    let { open } = this.state
    let record = { agencyId }
    return <>
      <Button
        color='primary'
        onClick={this.openDialog}
      >
        {translate('button.register_company')}
      </Button>
      <AssignDialog
        open={open}
        onClose={this.closeDialog}
        translate={translate}
        onDone={this.onSaveDone}
        onError={this.onSaveError}
        record={record}
        action="create"
        form="company-agency-create"
      />
    </>
  }
}

const enhance = compose(translate, connect(null, { showNotification, refreshView }))

export default enhance(RegisterCompanyButton)