// TRIP
import { tripImport, tripTime, tripRoute } from './trip/reducer'

// VIEW TRIP
import { viewTrip } from './viewtrip/reducer'
// MAP
import { map } from './map/reducer'

// NETWORK
import { network } from './network/reducer'

// Vehicle
import { vehicleType, vehicle } from './vehicle/reducer'

// RESERVATION
import { reservation } from './reservation/reducer'

import { onChangeBreadcrumb } from './breadcrumb/reducer'

//Layout
import { appBar } from './ui/reducer'

export default {
  'trips-imp': tripImport,
  'trips-time': tripTime,
  'trips-route': tripRoute,
  'view-trip': viewTrip,
  'map': map,
  'network': network,
  'vehicle-type': vehicleType,
  'vehicle': vehicle,
  'reservation': reservation,
  'breadcrumbs': onChangeBreadcrumb,
  'appBar': appBar
}
