import React, { Component, Fragment } from 'react'
import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  EditButton,
  translate,
  refreshView,
} from 'react-admin'
import Create from './../ui/Create'
import Edit from './../ui/Edit'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import { getFareConditions } from '../provider/get'
import _ from 'lodash'
import {
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  withStyles,
  Popover,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faRoute, faMoneyBill, faLayerGroup } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import { formatCurrency } from '../utils/formatUtil'
import { isCompanyManager } from '../utils/permission'
import ConfirmDialog from '../common/ConfirmDialog'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit,
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
})

const styleMoreInfo = () => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: 2,
  },
})

const RouteMoreInfo = withStyles(styleMoreInfo)(({
  classes,
  open,
  anchorEl,
  handlePopoverClose,
  content
}) => {
  return <Popover
    className={classes.popover}
    classes={{
      paper: classes.paper,
    }}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    onClose={() => handlePopoverClose()}
    disableRestoreFocus
  >
    <Typography>{content}</Typography>
  </Popover>
})

const RouteDialog = translate(({
  routeDialog = {},
  routes = [],
  onClose,
  addRoute,
  translate,
  handlePopoverOpen,
  handlePopoverClose,
}) => {
  let { open, record } = routeDialog
  let data = _.mapKeys(routes, route => route.id)
  let ids = Object.keys(data)
  return (
    <Dialog
      open={open || false}
      aria-labelledby="dialog-route"
      onClose={onClose}
    >
      <DialogTitle id="dialog-route">{translate('resources.faretables.addRoute')}</DialogTitle>
      <DialogContent>
        <Datagrid
          ids={ids}
          data={data}
          currentSort={{}}
          resource="routes"
          rowClick={async (id) => {
            let result = await addRoute(record.id, parseFloat(id))
            if (result) onClose()
          }}
        >
          <FunctionField
            render={record => <span
              onMouseEnter={(event) => handlePopoverOpen(event, record.desc)}
              onMouseLeave={(event) => handlePopoverClose(event)}
            >
              {record.name}
            </span>}
          />
        </Datagrid>
      </DialogContent>
    </Dialog>
  )
})

////////////////////////////////////////////////////////////////////////////////
// List
class _FareTableList extends Component {
  state = {
    moreInfo: {
      open: false,
      anchorEl: null,
      content: '',
    },
    confirm: {
      open: false
    }
  }
  async componentDidMount() {
    // get list of route
    const getRoutes = async () => {
      let patternIds = await Provider.dataProvider('GET_LIST', 'routepatterns', {
        filter: {
          where: {},
          fields: ['routeId'],
        },
        pagination: {},
        sort: {}
      })
      if (patternIds && patternIds.data) {
        patternIds = patternIds.data.map(pattern => pattern.routeId)
      } else {
        return null
      }

      let response = await Provider.dataProvider('GET_LIST', 'routes', {
        filter: {
          where: {
            id: { inq: patternIds },
            status: { neq: '20ARCHIVED' }
          },
          fields: ['id', 'name', 'desc'],
        },
        pagination: {},
        sort: {}
      })
      if (response && response.data) return response.data
    }

    let [routes, fareConditions] = await Promise.all([getRoutes(), getFareConditions()])
    if (fareConditions) {
      fareConditions = _.mapKeys(fareConditions, item => item.id)
      this.setState({ fareConditions, routes })
    }
  }

  addRouteDialog = (record) => {
    this.setState({
      routeDialog: {
        open: true,
        record,
      }
    })
  }

  removeRoute = async (data) => {
    let { fareTableId, routeId } = data
    let response = await Provider.dataProvider('REMOTE', 'faretables', {
      method: 'removeRoute',
      data: {
        id: fareTableId,
        routeId,
      }
    })
    if (response && response.data) {
      // done
      let { refreshView } = this.props
      refreshView()
      this.handleConfirmClose()
    }
  }

  addRoute = async (fareTableId, routeId) => {
    routeId = parseFloat(routeId)
    let response = await Provider.dataProvider('REMOTE', 'faretables', {
      method: 'addRoute',
      data: { id: fareTableId, routeId }
    })
    if (response && response.data) {
      let { refreshView } = this.props
      refreshView()
      return true
    }
  }

  handlePopoverOpen = (event, content) => {
    let moreInfo = {
      open: true,
      anchorEl: event.target,
      content: content
    }
    if (!content) {
      this.handlePopoverClose()
      return
    }
    this.setState({ moreInfo })
  }

  handlePopoverClose = () => {
    this.setState({
      moreInfo: {
        open: false,
        anchorEl: null,
        content: '',
      }
    })
  }

  handleConfirmOpen = (data) => {
    this.setState({
      confirm: {
        open: true,
        data: data,
      }
    })
  }

  handleConfirmClose = () => {
    this.setState({
      confirm: {
        open: false,
        data: {},
      }
    })
  }

  render() {
    let { classes, translate, refreshView, permissions, ...props } = this.props
    let { fareConditions, routeDialog, routes, moreInfo, confirm } = this.state
    if (!fareConditions) return <div className={classes.progress}><CircularProgress /></div>
    let companyManager = isCompanyManager(permissions)
    return (
      <Fragment>
        <List
          {...props}
          filter={{
            '../fields': [
              'id',
              'name',
              'conditions',
              'farePrices',
              'routes',
              'prices',
            ],
            '../include': {
              relation: 'routes',
              scope: {
                fields: ['id', 'name', 'desc'],
                where: { status: { neq: '20ARCHIVED' }}
              }
            }
          }}
          bulkActionButtons={false}
        >
          <Datagrid>
            <TextField source="name" />
            <FunctionField
              source="conditions"
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              sortable={false}
              render={record =>
                fareConditions && record.conditions &&
              record.conditions.map((item, index) =>
                <Chip
                  key={index}
                  className={classes.chip}
                  avatar={<Avatar><FontAwesomeIcon icon={faLayerGroup} /></Avatar>}
                  label={_.get(fareConditions[item.code], 'name')}
                />
              )
              } />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="farePrices"
              sortable={false}
              render={record =>
                record.prices && record.prices.map((price, index) => {
                  let color = _.get(price, 'color', '')
                  let chipStyle = { backgroundColor: color, color: color ? 'white' : 'black' }
                  let avtStyle = { backgroundColor: color }
                  return <Chip
                    key={index}
                    className={classes.chip}
                    style={chipStyle}
                    avatar={<Avatar style={avtStyle}><FontAwesomeIcon style={{ color: 'white' }} icon={faMoneyBill} /></Avatar>}
                    label={_.get(price, 'table[0].data.rowBase.value') && formatCurrency(_.get(price, 'table[0].data.rowBase.value'))}
                  />
                })
              } />
            <FunctionField
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              source="routes"
              sortable={false}
              render={record =>
                <Fragment>
                  {record.routes && record.routes.map((route, index) =>
                    companyManager ? <Chip
                      onMouseEnter={(event) => this.handlePopoverOpen(event, route.desc)}
                      onMouseLeave={this.handlePopoverClose}
                      key={index}
                      className={classes.chip}
                      avatar={<Avatar><FontAwesomeIcon icon={faRoute} /></Avatar>}
                      onDelete={() => this.handleConfirmOpen({ fareTableId: record.id, routeId: route.id })}
                      label={_.get(route, 'name')}
                    /> : <Chip
                      onMouseEnter={(event) => this.handlePopoverOpen(event, route.desc)}
                      onMouseLeave={this.handlePopoverClose}
                      key={index}
                      className={classes.chip}
                      avatar={<Avatar><FontAwesomeIcon icon={faRoute} /></Avatar>}
                      label={_.get(route, 'name')}
                    />
                  )}
                  {companyManager && <Chip
                    className={classes.chip}
                    avatar={<Avatar><FontAwesomeIcon icon={faPlus} /></Avatar>}
                    onClick={() => this.addRouteDialog(record)}
                    label={translate('resources.faretables.addRoute')}
                  >
                  </Chip>}
                </Fragment>
              }
            />
            {companyManager && <EditButton />}
          </Datagrid>
        </List>
        <RouteDialog
          routeDialog={routeDialog}
          routes={routes}
          onClose={() => this.setState({ routeDialog: { open: false } })}
          handlePopoverClose={this.handlePopoverClose}
          handlePopoverOpen={this.handlePopoverOpen}
          addRoute={this.addRoute}
        />
        <RouteMoreInfo
          open={moreInfo.open}
          anchorEl={moreInfo.anchorEl}
          handlePopoverClose={this.handlePopoverClose}
          content={moreInfo.content}
        />
        <ConfirmDialog
          open={confirm.open}
          title={translate('resources.common.confirm')}
          content={translate('notification.confirm_unassign_route')}
          onClose={this.handleConfirmClose}
          data={confirm.data}
          onOk={this.removeRoute}
        />
      </Fragment>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, { refreshView })
)

export const FareTableList = enhance(_FareTableList)

////////////////////////////////////////////////////////////////////////////////
// Create
export class FareTableCreate extends Component {
  render() {
    let { ...props } = this.props

    return (
      <Create
        {...props}
        title={<EditTitle resource={props.resource} />}
      >
        <FormSave />
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export class FareTableEdit extends Component {
  render() {
    let { ...props } = this.props

    return (
      <Edit
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} />}
      >
        <FormSave />
      </Edit>
    )
  }
}
