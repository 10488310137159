import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  CircularProgress,
} from '@material-ui/core'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FuzzyPicker from './fuzzy-picker'

export default class AsyncFuzzyPicker extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedIndex: 0, // which item is selected?
      items: [],
      textValue: '',
      loading: false,
    }
    this.input = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, preState) {
    let { 
      items,
      showAllItems,
      textValue: nextTextValue
    } = nextProps
    let {
      textValue: currentTextValue
    } = preState
    if (!nextTextValue) return {
      items: showAllItems ? items : [],
      textValue: '',
    }
    if (nextTextValue && nextTextValue !== currentTextValue) {
      return {
        ...preState,
        textValue: nextTextValue
      }
    }
    return { items }
  }
  // Move the selected index up or down.
  onMoveUp() {
    if (this.state.selectedIndex > 0) {
      this.selectIndex(this.state.selectedIndex - 1)

    // User is at the start of the list. Should we cycle back to the end again?
    } else if (this.props.cycleAtEndsOfList) {
      this.selectIndex(this.state.items.length - 1)
    }
  }

  onMoveDown() {
    let itemsLength = this.state.items.length - 1
    if (this.state.selectedIndex < itemsLength) {
      this.selectIndex(this.state.selectedIndex + 1)

    // User is at the end of the list. Should we cycle back to the start again?
    } else if (this.props.cycleAtEndsOfList) {
      this.selectIndex(0)
    }
  }

  // handle key events in the textbox
  onKeyDown(event) {
    switch (event.key) {
      // Moving up and down
      // Either arrow keys, tab/shift+tab, or ctrl+j/ctrl+k (what's used in vim sometimes)
      case 'ArrowUp': {
        this.onMoveUp()
        event.preventDefault()
        break
      }
      case 'ArrowDown': {
        this.onMoveDown()
        event.preventDefault()
        break
      }
      case 'j': {
        if (event.ctrlKey) {
          this.onMoveDown()
        }
        break
      }
      case 'k': {
        if (event.ctrlKey) {
          this.onMoveUp()
        }
        break
      }
      case 'Tab': {
        if (event.shiftKey) {
          this.onMoveUp()
        } else {
          this.onMoveDown()
        }
        event.preventDefault()
        break
      }

      case 'Enter': { // Enter key
        let item = this.state.items[this.state.selectedIndex]
        if (item) {
          this.setState({items: this.getInitialItems(this.props)})
          this.props.onChange(item)
          if (this.props.autoCloseOnEnter) {
            this.props.onClose()
          }
        }
        break
      }
      case 'Escape': {
        this.setState({items: this.getInitialItems(this.props)})
        this.props.onClose()
        break
      }
      default:
        break
    }
  }

  // Get the list of initial items.
  // Defaults to none, but the 'showAllItems' property can be enabled
  // to show all items by default.
  getInitialItems(props) {
    if (props.showAllItems) {
      return props.items
    } else {
      return []
    }
  }

  // When the user types into the textbox, this handler is called.
  // Though the textbox is an uncontrolled input, this is needed to regenerate the
  // list of choices under the textbox.
  onInputChanged = ({target: {value}}) => {
    this.setState({ loading: true })
    return this.props.fetchItems(value).then(items => {
      if (Array.isArray(items)) {
        this.setState({ items, loading: false })
      } else {
        throw new Error('Resolved data isn\'t an array, and react-fuzzy-picker expects an array of strings to be resolved - like ["foo", "bar", "baz"]')
      }
    })
  }

  // Highlight the given item
  selectIndex(ct) {
    this.props.onChangeHighlightedItem(this.state.items[ct]) // fire a callback
    this.setState({selectedIndex: ct}) // update the state for real
  }

  onClose = () => {
    this.setState({ init: null })
    this.props.onClose()
  }

  render() {
    let { isOpen, onChange, renderItem, label, optionText } = this.props
    let { items, selectedIndex, textValue, loading } = this.state
    if (isOpen) {
      return <Dialog
        open={isOpen}
        onClose={this.onClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {label}
          <TextField
            inputRef={this.input}
            autoFocus
            fullWidth
            onKeyDown={this.onKeyDown.bind(this)}
            onChange={this.onInputChanged}
            value={textValue}
            helperText="[tab] or ↑↓ to navigate. [enter] to select. [esc] to dismiss"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} size="1x" />
                </InputAdornment>
              ),
              endAdornment: loading ? (
                <InputAdornment position="end">
                  <CircularProgress size={20} />
                </InputAdornment>
              ) : null
            }}
          />
        </DialogTitle>
        <DialogContent>
          <List>
            {items.map((item, ct) => {
              return <ListItem
                selected={ct===selectedIndex}
                button
                key={ct}
                onClick={() => {
                  onChange(item)
                }}
              >
                <ListItemText primary={renderItem(item[optionText])} />
              </ListItem>
            })}
          </List>
        </DialogContent>
      </Dialog>
    }
    return null
  }
}
AsyncFuzzyPicker.propTypes = Object.assign({}, FuzzyPicker.PropTypes, {
  fetchItems: PropTypes.func.isRequired,
  optionText: PropTypes.string.isRequired
})
// delete AsyncFuzzyPicker.propTypes.items // reset the value of items since that isn't needed here.
AsyncFuzzyPicker.defaultProps = Object.assign({}, FuzzyPicker.defaultProps, {
  // by default, don't show any items.
  fetchItems: () => {
    return Promise.resolve([])
  },
})
