import React, { Component } from 'react'
import {
  Datagrid,
  List,
  ReferenceField,
  SimpleForm,
  TextField,
  NumberField,
  translate,
  Create,
  Edit,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'

////////////////////////////////////////////////////////////////////////////////
// List
class _FareRuleList extends Component {
  render() {
    let { ...props } = this.props
    return (
      <List {...props} sort={{ field: 'code', order: 'ASC' }}>
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="desc" />
          <ReferenceField source="ruleType" reference="fareruletypes" linkType={false}>
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="ruleValue" />
        </Datagrid>
      </List>
    )
  }
}

export const FareRuleList = translate(_FareRuleList)

////////////////////////////////////////////////////////////////////////////////
// Create
export class FareRuleCreate extends Component {
  render() {
    let { ...props } = this.props

    return (
      <Create
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} />}
      >
        <SimpleForm>
          <FormSave />
        </SimpleForm>
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export class FareRuleEdit extends Component {
  render() {
    let { ...props } = this.props
    let id = this.props.id

    return (
      <Edit
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} />}
      >
        <SimpleForm>
          <FormSave id={id} />
        </SimpleForm>
      </Edit>
    )
  }
}