import { put, race, take, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import { RESOURCE_REQUEST, RESOURCE_REQUEST_SUCCESS, RESOURCE_REQUEST_FAILURE } from './index'

function* resourceRequest(action) {
  let { start, done, fail } = action
  start && start()

  const { success, failure } = yield race({
    success: take(RESOURCE_REQUEST_SUCCESS),
    failure: take(RESOURCE_REQUEST_FAILURE),
  })

  if (success) {
    const { payload } = success

    if (payload.data) {
      done && done(payload.data)
    } else {
      yield put(showNotification('payload has no data'))
    }
  }

  if (failure) {
    yield put(showNotification('Cannot invoke resource request'))
    fail && fail(failure.payload)
  }
}

export function* takeResourceRequest() {
  yield takeEvery(RESOURCE_REQUEST, resourceRequest)
}