import React, { Component, Fragment } from 'react'
import {
  Datagrid,
  List,
  TextField,
  EditButton,
  translate,
  refreshView,
} from 'react-admin'
import Create from './../ui/Create'
import Edit from './../ui/Edit'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import { getFareConditions } from '../provider/get'
import _ from 'lodash'
import {
  withStyles,
  CircularProgress,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { isCompanyManager } from '../utils/permission'

const styles = theme => ({
  chip: {
    margin: theme.spacing.unit,
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
})

////////////////////////////////////////////////////////////////////////////////
// List
class _FareCompanionList extends Component {
  state = {
    moreInfo: {
      open: false,
      anchorEl: null,
      content: '',
    },
    confirm: {
      open: false
    }
  }
  async componentDidMount() {
    // get list of route
    const getRoutes = async () => {
      let patternIds = await Provider.dataProvider('GET_LIST', 'routepatterns', {
        filter: {
          where: {},
          fields: ['routeId'],
        },
        pagination: {},
        sort: {}
      })
      if (patternIds && patternIds.data) {
        patternIds = patternIds.data.map(pattern => pattern.routeId)
      } else {
        return null
      }

      let response = await Provider.dataProvider('GET_LIST', 'routes', {
        filter: {
          where: {
            id: { inq: patternIds },
            status: '10ACTIVE',
          },
          fields: ['id', 'name', 'desc'],
        },
        pagination: {},
        sort: {}
      })
      if (response && response.data) return response.data
    }

    let [routes, fareConditions] = await Promise.all([getRoutes(), getFareConditions()])
    if (fareConditions) {
      fareConditions = _.mapKeys(fareConditions, item => item.id)
      this.setState({ fareConditions, routes })
    }
  }

  addRouteDialog = (record) => {
    this.setState({
      routeDialog: {
        open: true,
        record,
      }
    })
  }

  removeRoute = async (data) => {
    let { fareCompanionId, routeId } = data
    let response = await Provider.dataProvider('REMOTE', 'faretables', {
      method: 'removeRoute',
      data: {
        id: fareCompanionId,
        routeId,
      }
    })
    if (response && response.data) {
      // done
      let { refreshView } = this.props
      refreshView()
      this.handleConfirmClose()
    }
  }

  addRoute = async (fareCompanionId, routeId) => {
    routeId = parseFloat(routeId)
    let response = await Provider.dataProvider('REMOTE', 'faretables', {
      method: 'addRoute',
      data: { id: fareCompanionId, routeId }
    })
    if (response && response.data) {
      let { refreshView } = this.props
      refreshView()
      return true
    }
  }

  handlePopoverOpen = (event, content) => {
    let moreInfo = {
      open: true,
      anchorEl: event.target,
      content: content
    }
    if (!content) {
      this.handlePopoverClose()
      return
    }
    this.setState({ moreInfo })
  }

  handlePopoverClose = () => {
    this.setState({
      moreInfo: {
        open: false,
        anchorEl: null,
        content: '',
      }
    })
  }

  handleConfirmOpen = (data) => {
    this.setState({
      confirm: {
        open: true,
        data: data,
      }
    })
  }

  handleConfirmClose = () => {
    this.setState({
      confirm: {
        open: false,
        data: {},
      }
    })
  }

  render() {
    let { classes, translate, refreshView, permissions, ...props } = this.props
    let { fareConditions, routeDialog, routes, moreInfo, confirm } = this.state
    if (!fareConditions) return <div className={classes.progress}><CircularProgress /></div>
    let companyManager = isCompanyManager(permissions)
    return (
      <Fragment>
        <List
          {...props}
          bulkActionButtons={false}
        >
          <Datagrid>
            <TextField source="name" />
            {companyManager && <EditButton />}
          </Datagrid>
        </List>
      </Fragment>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, { refreshView })
)

export const FareCompanionList = enhance(_FareCompanionList)

////////////////////////////////////////////////////////////////////////////////
// Create
export class FareCompanionCreate extends Component {

  render() {
    let { ...props } = this.props
    return (
      <Create
        {...props}
        title={<EditTitle resource={props.resource} />}
      >
        <FormSave />
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export class FareCompanionEdit extends Component {

  render() {
    let { ...props } = this.props
    return (
      <Edit
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} />}
      >
        <FormSave />
      </Edit>
    )
  }
}
