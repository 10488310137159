import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  Dialog,
  Grid,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { Provider } from '../provider'
import {
  translate,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  refreshView,
  showNotification,
  TextInput,
  required,
} from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import ReceiptIcon from '@material-ui/icons/Receipt'
import { MoneyInput } from '../common/MoneyInput'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  }
}

class _PaymentForm extends Component {

  state = {}

  componentDidMount() {
    let { reconciliation, isAdmin } = this.props
    let paid = isAdmin ? reconciliation.debit : reconciliation.credit
    let record = {
      accountId: reconciliation.accountId,
      paid,
    }
    this.setState({ record })
  }

  render() {
    let {
      open,
      onClose,
      payment,
      translate,
      reconciliation,
      isAdmin,
    } = this.props
    let { record } = this.state
    let extra = { resource: 'reconciliations', fullWidth: true }
    return <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        <Typography variant="h5">{translate('button.payment_reconciliation')}&nbsp;<b>{reconciliation.name}</b></Typography>
      </DialogTitle>
      <Divider />
      <SimpleForm
        resource="reconciliations"
        form="payment-form"
        record={record}
        save={record => payment(record)}
      >
        <FormDataConsumer>
          {({ formData }) => {
            return <Grid container fullWidth>
              {isAdmin && <ReferenceInput
                reference="financeaccounts"
                source="accountId"
                disabled
                {...extra}
              >
                <SelectInput translateChoice={false} optionText="accountName" />
              </ReferenceInput>}
              <MoneyInput
                disabled
                source="paid"
                label="resources.reconciliations.fields.paid"
                {...extra}
              />
              <TextInput
                source="invoiceNumber"
                validate={required()}
                {...extra}
              />
              <ReferenceInput
                source="paymentMethod"
                label="resources.reservations.payment.method"
                reference="paymentmethod2s"
                filter={{ id: { lt: '20' }}}
                defaultValue="00CASH"
                {...extra}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              {formData.paymentMethod === '10BANK' && <Fragment>
                <TextInput validate={required()} source="journalNumber" {...extra} />
              </Fragment>}
              <TextInput source="desc" {...extra} />
            </Grid>
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Dialog> 
  }
}

const enhanceWithdrawForm = compose(translate, connect(null, { changeForm }))
const PaymentForm = enhanceWithdrawForm(_PaymentForm)

class PaymentButton extends Component {

  state = {
    open: false
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  onClose = () => {
    let { reset } = this.props
    this.setState({
      open: false
    }, () => { reset('payment-form')})
  }

  payment = record => {
    let { refreshView, showNotification, reconciliation } = this.props
    let { desc, paymentMethod, invoiceNumber, journalNumber } = record
    let data = {
      reconciliationId: reconciliation.id,
      desc,
      paymentMethod,
      invoiceNumber,
      journalNumber,
    }
    Provider.dataProvider('REMOTE', 'reconciliations', {
      method: 'payment',
      requestMethod: 'POST',
      data,
    }).then(
      () => {
        refreshView()
        this.onClose()
        showNotification('notification.payment_reconciliation_success')
      }
    ).catch(
      () => {
        this.onClose()
        showNotification('notification.payment_reconciliation_failure', 'warning')
      }
    )
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      reconciliation,
      isAdmin,
    } = this.props
    let { open } = this.state
    let disabled = reconciliation.status === '20PAID' || !((isAdmin && reconciliation.debit > 0) || (!isAdmin && reconciliation.credit > 0))
    return <Fragment>
      {button 
        ? <Button
          color={color}
          onClick={this.open}
          disabled={disabled}
        >
          <div className={classes.container}>
            <ReceiptIcon className={classes.icon} />
            <span>{translate('button.payment_reconciliation')}</span>
          </div>
        </Button>
        : <LinkField
          className={classes.iconButton}
          icon
          color={color}
          onClick={this.open}
          disabled={disabled}
        >
          <Tooltip title={translate('button.payment_reconciliation')} enterDelay={100} >
            <ReceiptIcon />
          </Tooltip>
        </LinkField>
      }
      {reconciliation && <PaymentForm
        open={open}
        isAdmin={isAdmin}
        onClose={this.onClose}
        payment={this.payment}
        reconciliation={reconciliation}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { refreshView, showNotification, reset, changeForm })
)

export default enhance(PaymentButton)
