import { Component } from 'react'
import compose from 'recompose/compose'
import { changeBreadcrumb } from '../breadcrumb/action'
import { connect } from 'react-redux'
import { translate } from 'react-admin'
import { Provider } from '../provider'

class _EditTitle extends Component {
  state = {
    label: ''
  }
  async componentDidMount() {
    let paths = []
    const { record, translate, base, resource } = this.props
    let label = await this.getLabel()
    if (base) {
      let { resource: baseResource, target } = base
      paths.push({
        label: translate(`resources.${baseResource}.name`, { smart_count: 2 }),
        to: `/${baseResource}`,
      })
      let id = record[target]
      if (id) {
        let response = await Provider.dataProvider('GET_ONE', baseResource, {
          id,
          filter: {
            fields: ['id', 'name'],
          },
        })
        if (response && response.data) {
          paths.push({
            label: response.data.name,
            to: `/${baseResource}/${id}`,
          })
        }
      }
    } else {
      paths.push({
        label: translate(`resources.${resource}.name`, { smart_count: 2 }),
        to: `/${resource}`,
      })
    }
    label = label ? (label.length > 30 ? `${label.substring(0, 27)}...` : label) : ''
    paths.push({ label, to: '' })
    this.setState({ paths, label })
    let { changeBreadcrumb } = this.props
    changeBreadcrumb({ paths })
  }

  async getLabel() {
    const { record, translate, render } = this.props
    let label = record.id
      ? (render ? await render(record) : record.name)
      : translate('resources.common.create')
    return label || record.id
  }

  render() {
    return this.state.label
  }
}

const enhance = compose(connect(null, { changeBreadcrumb }), translate)

export const EditTitle = enhance(_EditTitle)
