import React, { Component, Fragment } from 'react'
import {
  Chip,
  withStyles,
  List,
  ListItem,
  ListItemText,
  Popover,
  Avatar,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { 
  showNotification,
  translate,
  addField,
  ReferenceManyField,
} from 'react-admin'
import { connect } from 'react-redux'
import { reset } from 'redux-form'
import _ from 'lodash'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

const listTemplateStyle = {
  root: {
    width: 250,
  },
  title: {
    fontSize: 16,
    color: 'white',
    fontWeight: 'bold',
    padding: 8,
    backgroundColor: '#303f9f',
  },
  empty: {
    paddingLeft: 8,
    fontSize: 13,
    fontStyle: 'italic',
  }
}

const _ListTemplate = ({
  selected,
  onChange,
  data,
  ids,
  translate,
  classes
}) => {
  return <div className={classes.root}>
    <div className={classes.title}>
      <span>{translate('resources.common.template')}</span>
    </div>
    <List component="nav">
      {!_.isEmpty(ids) ? ids.map((id, idx) => {
        let choice = data[id] || {}
        let { code } = choice
        return <ListItem
          key={idx}
          button
          onClick={() => onChange(code)}
          selected={selected === code}
        >
          <ListItemText primary={code} />
        </ListItem>
      }) : <span className={classes.empty}>{translate('resources.common.no_record')}</span>}
    </List>
  </div>
}

const listTemplateEnhance = compose(translate, withStyles(listTemplateStyle))

const ListTemplate = listTemplateEnhance(_ListTemplate)

class _MenuTemplate extends Component {

  state = {}

  render() {
    let { 
      open,
      onClose,
      anchorEl,
      group,
      selected,
      onChange,
    } = this.props
    return <Popover
      id="menu-over-popover"
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClose={onClose}
    >
      <ReferenceManyField
        resource="settings"
        reference="settings"
        basePath="/settings"
        record={{}}
        target=""
        filter={{
          group,
          '../fields': ['id', 'code']
        }}
      >
        <ListTemplate
          selected={selected}
          onChange={onChange}
        />
      </ReferenceManyField>
    </Popover>
  }
}

const MenuTemplate = compose(
  translate,
  connect(null, { showNotification, reset })
)(_MenuTemplate)

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336'
    },
  })
}

class TemplateChip extends Component {

  state = {
    open: false,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { input } = nextProps
    const { value } = input
    const { value: currentValue } = prevState
    if (currentValue !== value) {
      return { ...prevState, value }
    }
    return { ...prevState }
  }

  handleOpenMenu = evt => {
    this.setState({
      open: true,
      anchorEl: evt.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    })
  }

  save = (data, newCode) => {
    let { productId, showNotification, input } = this.props
    Provider.dataProvider('REMOTE', 'products', {
      method: productId,
      requestMethod: 'PATCH',
      data,
    }).then(() => {
      showNotification('notification.update_template_success')
      this.setState({ value: newCode })
      input.onChange(newCode)
      this.handleClose()
    }).catch(() => {
      this.handleClose()
      showNotification('notification.update_template_failure', 'warning')
    })
  }

  handleChange = newCode => {
    let { value: oldCode } = this.state
    let { source } = this.props
    if (oldCode === newCode) {
      this.handleClose()
    } else {
      let data = {[source]: newCode }
      this.save(data, newCode)
    }
  }

  onDelete = () => {
    let { source } = this.props
    let data = {[source]: '' }
    this.save(data, '')
  }

  render() {
    let { classes, translate, group, icon } = this.props
    let { value, anchorEl, open } = this.state
    let color = value ? 'primary' : 'default'
    return <Fragment>
      <Chip
        avatar={<Avatar>{icon}</Avatar>}
        size="small"
        aria-owns={open ? 'menu-over-popover' : undefined}
        aria-haspopup="true"
        variant="outlined"
        color={color}
        label={value ? value : translate('resources.products.template_not_exists')}
        className={classes.chip}
        onClick={this.handleOpenMenu}
        onDelete={() => { 
          if (!value) return
          this.onDelete()
        }}
        deleteIcon={value ? <HighlightOffIcon /> : <span></span>}
      />
      {open && <MenuTemplate
        open={open}
        anchorEl={anchorEl}
        group={group}
        selected={value}
        onClose={this.handleClose}
        onChange={this.handleChange}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification, reset }),
  addField,
)

export default enhance(TemplateChip)
