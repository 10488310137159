import _ from 'lodash'
import moment from 'moment'

export const VIEW_TRIP_MODE = {
  time: 'time',
  route: 'route'
}

export const VNIS = '00VNIS'
export const EInvoiceProviderMapping = {
  [VNIS]: 'Vnis'
}

export const EInvoiceProviders  = [
  { id: VNIS, name: 'Vnis' },
]

export const ACTION_CREATE = '00CREATE'
export const ACTION_UPDATE = '10UPDATE'
export const ACTION_CANCEL = '30CANCEL'
export const ACTION_CHECKIN = '40CHECKIN'
export const ACTION_PRINT = '50PRINT'


export const VNIS_SIGN_STATUS_SUCCESS = 5
export const VNIS_SIGN_STATUS_ERROR = 1
export const VNIS_SIGN_STATUS_SIGNING = 4
export const VNIS_SIGN_STATUS_WATING = 3
export const VNIS_SIGN_STATUS_INITIAL = 2
/// status of vnins invoice
export const VNIS_INVOICE_STATUS_NEXBUS_CANCEL = -1
export const VNIS_INVOICE_STATUS_ORIGIN = 1
export const VNIS_INVOICE_STATUS_DELETE = 2
export const VNIS_INVOICE_STATUS_DELETE_CANCEL = 9
export const VNIS_INVOICE_STATUS_INITIAL = 99

export const VnisInvoiceStatuses = [
  { id: VNIS_INVOICE_STATUS_NEXBUS_CANCEL, name: 'resources.einvoices.statuses.nexbus_cancel' },
  { id: VNIS_INVOICE_STATUS_INITIAL, name: 'resources.einvoices.statuses.initial' },
  { id: VNIS_INVOICE_STATUS_ORIGIN, name: 'resources.einvoices.statuses.origin' },
  { id: VNIS_INVOICE_STATUS_DELETE, name: 'resources.einvoices.statuses.deleted' },
  { id: VNIS_INVOICE_STATUS_DELETE_CANCEL, name: 'resources.einvoices.statuses.deleted_cancel' }
]

export const VnisInvoiceStatusesMapping = {
  [VNIS_INVOICE_STATUS_INITIAL]: 'resources.einvoices.statuses.initial',
  [VNIS_INVOICE_STATUS_NEXBUS_CANCEL]: 'resources.einvoices.statuses.nexbus_cancel',
  [VNIS_INVOICE_STATUS_ORIGIN]: 'resources.einvoices.statuses.origin',
  [VNIS_INVOICE_STATUS_DELETE]: 'resources.einvoices.statuses.deleted',
  [VNIS_INVOICE_STATUS_DELETE_CANCEL]: 'resources.einvoices.statuses.deleted_cancel'
}

export const VnisSignStatusesMapping = {
  [VNIS_SIGN_STATUS_SUCCESS]: 'resources.einvoices.statuses.sign_success',
  [VNIS_SIGN_STATUS_ERROR]: 'resources.einvoices.statuses.sign_error',
  [VNIS_SIGN_STATUS_SIGNING]: 'resources.einvoices.statuses.signing',
  [VNIS_SIGN_STATUS_WATING]: 'resources.einvoices.statuses.sign_waiting',
  [VNIS_SIGN_STATUS_INITIAL]: 'resources.einvoices.statuses.sign_initial'
}

export const MAIN_PADDING = 36
export const PAPER_PADDING = 32

export const FareRuleTypes = {
  DEFAULT: '00DEF',
  HOLIDAY: '10HOL',
  BEFORE_DAYS: '20BEF',
  CLASS: '30CLA'
}

export const MqttActionName = {
  PAYMENT_CONFIRMATION: 'payment_confirmation',
  UPDATE_CONFIGURATION: 'update_configuration',
  UPDATE_TRIP: 'update_trip',
  NEW_CHARGE: 'new_charge',
  UPDATE_CHARGE: 'update_charge',
  CANCELED_CHARGE: 'canceled_charge',
  NEW_RESERVATION: 'new_reservation',
  UPDATE_RESERVATION: 'update_reservation',
  NOTIFICATION: 'notification',
  RECEIVE_PHONE_NUMBER: 'receive_phone_number',
  PICK_UP: 'pick_up',
  MAKE_CALL: 'make_call',
  REJECT_CALL: 'reject_call',
  RESERVATION_CHANGED: 'RESERVATION_CHANGED',
  TRIP_CHANGED: 'TRIP_CHANGED',
  RESERVATION_OPEN: 'RESERVATION_OPEN',
  DEVICE_ACTION_REQUEST: 'DEVICE_ACTION_REQUEST',
  PAIR_DEVICE: 'pair_device',
  PRINT_TICKET: 'PRINT_TICKET',
  SEND_SMS: 'send_sms',
  LOGOUT_DEVICE: 'logout_device',
  LOGIN_DEVICE: 'login_device',
}

export const FareCriteriaTypes = {
  PASSENGER_CLASS: '00PAS',
  PAYMENT_METHOD: '10PAM',
  DISTRIBUTOR: '20DIS',
  OTHER: '30OTH'
}

export const MapProps = {
  LayerTypes: {
    TILE: 'tilelayer',
    GEO_JSON: 'geojson',
    MARKER: 'marker',
    NODE: 'node',
    TRANSITIVE: 'transitive',
    ROUTE: 'route'
  },
  LayerStates: {
    VISIBLE: 'VISIBLE',
    INVISIBLE: 'INVISIBLE'
  },
  Shapes: {
    POINT: 'point',
    LINE: 'line',
    POLYGON: 'polygon',
  },
  Position: {
    TOP: 'top',
    LEFT: 'left',
    RIGHT: 'right',
    BOTTOM: 'bottom',
    CENTER_LEFT: 'center-left',
    CENTER_RIGHT: 'center-right',
    CENTER_TOP: 'center-top',
    CENTER_BOTTOM: 'center-bottom'
  }
}

export const viewByModes = {
  STATUS: 'status',
  DRIVER: 'driver'
}

export const BY_WEEK = '00BYWEEK'
export const BY_MONTH = '10BYMONTH'
export const BY_QUARTER = '20BYQUARTER'

export const periodTimeOptions = [
  { id: BY_WEEK, name: 'resources.common.periodTime.byWeek' },
  { id: BY_MONTH, name: 'resources.common.periodTime.byMonth' },
  { id: BY_QUARTER, name: 'resources.common.periodTime.byQuarter' },
]

export function getEndISOWeek(periodTimeValue) {
  return moment().week(periodTimeValue).endOf('isoweek')
}

export function getEndMonth(periodTimeValue) {
  return moment().month(periodTimeValue).endOf('month')
}

export function getEndQuarter(periodTimeValue) {
  return moment().quarter(periodTimeValue).endOf('quarter')
}

export function getEndPeriodTime(periodTime, periodTimeValue) {
  switch(periodTime) {
    case BY_WEEK: {
      return getEndISOWeek(periodTimeValue)
    }
    case BY_MONTH: {
      return getEndMonth(periodTimeValue)
    }
    case BY_QUARTER: {
      return getEndQuarter(periodTimeValue)
    }
    default: {
      return
    }
  }
}

export function getStartISOWeek(periodTimeValue) {
  return moment().week(periodTimeValue).startOf('isoweek')
}

export function getStartMonth(periodTimeValue) {
  return moment().month(periodTimeValue).startOf('month')
}

export function getStartQuarter(periodTimeValue) {
  return moment().quarter(periodTimeValue).startOf('quarter')
}

export function getStartPeriodTime(periodTime, periodTimeValue) {
  switch(periodTime) {
    case BY_WEEK: {
      return getStartISOWeek(periodTimeValue)
    }
    case BY_MONTH: {
      return getStartMonth(periodTimeValue)
    }
    case BY_QUARTER: {
      return getStartQuarter(periodTimeValue)
    }
    default: {
      return
    }
  }
}

export function getWeeks() {
  return _.range(53).map(i => ({ id: i + 1, name: i + 1 }))
}

export function getMonths() {
  return _.range(12).map(i => ({ id: i, name: i + 1 }))
}

export function getQuarters() {
  return _.range(4).map(i => ({ id: i + 1, name: i + 1 }))
}

export function getPeriodTimeValue(periodTime) {
  switch(periodTime) {
    case BY_WEEK: {
      return getWeeks()
    }
    case BY_MONTH: {
      return getMonths()
    }
    case BY_QUARTER: {
      return getQuarters()
    }
    default: {
      return
    }
  }
} 

export const ReservationStatus = {
  NEW: '00NEW',
  STANDBY: '10STANDBY',
  CONFIRMED: '20CONFIRMED',
  CANCELLED: '30CANCELLED',
  CHECKED_IN: '40CHECKED_IN',
  FINALIZED: '50FINALIZED',
  EXPIRED: '60EXPIRED',
}

export const ReservationStatusMapping = {
  [ReservationStatus.NEW]: 'resources.reservations.status.new',
  [ReservationStatus.STANDBY]: 'resources.reservations.status.standby',
  [ReservationStatus.CONFIRMED]: 'resources.reservations.status.confirmed',
  [ReservationStatus.CANCELLED]: 'resources.reservations.status.cancelled',
  [ReservationStatus.CHECKED_IN]: 'resources.reservations.status.checkedIn',
  [ReservationStatus.FINALIZED]: 'resources.reservations.status.finalized',
  [ReservationStatus.EXPIRED]: 'resources.reservations.status.expired',
}
