import React, { Component } from 'react'
import {
  translate,
} from 'react-admin'
import {
  withStyles,
  List as MUIList,
  ListItem,
  ListItemText,
  Avatar,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBus } from '@fortawesome/free-solid-svg-icons'

const styles = (theme) => {
  return {
    root: {
      padding: theme.spacing.unit * 3
    },
    bold: {
      fontWeight: 'bold'
    },
    money: {
      textAlign: 'right'
    },
  }
}

class TripStop extends Component {
  constructor(props) {
    super(props)
    this.state = {
      stops: []
    }
  }

  componentDidMount = async () => {
    let { record } = this.props
    let { patternId } = record
    let response = await Provider.dataProvider('GET_ONE', 'routepatterns', { id: patternId })
    if (response && response.data) {
      this.setState({ stops: response.data.stops })
    }
  }

  render() {
    let { classes } = this.props
    let { stops } = this.state

    return (
      <div className={classes.root}>
        <MUIList>
          {stops && stops.map((item, index) => {
            return (
              <ListItem key={index} style={{ borderLeft: '3px solid #ccc' }}>
                <Avatar style={{ marginLeft: -29, width: 23, height: 23 }}>
                  <FontAwesomeIcon size="xs" icon={faBus} />
                </Avatar>
                <ListItemText primary={`(${item.code}) - ${item.name}`} secondary={item.distance} />
              </ListItem>
            )
          })}
        </MUIList>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate,
  withStyles(styles),
)

export default enhance(TripStop)
