import React, { Component } from 'react'
import {
  Datagrid,
  List,
  ReferenceField,
  SimpleForm,
  TextField,
  NumberField,
  DateField,
  translate,
} from 'react-admin'
import { withStyles } from '@material-ui/core'
import Create from './../ui/Create'
import Edit from './../ui/Edit'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import { isCompanyManager } from '../utils/permission'
import compose from 'recompose/compose'
import { validate } from './validate'

////////////////////////////////////////////////////////////////////////////////
// List

const styles = {
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
}

class _FareList extends Component {
  render() {
    let { permissions, classes, ...props } = this.props
    //let companyManager = isCompanyManager(permissions)
    return (
      <List
        {...props}
        bulkActionButtons={false}
        filterDefaultValues={{
          type: { neq: '00ROUTE' },
          '../fields': [
            'id',
            'name',
            'status',
            'applyFrom',
            'amount',
            'routeId',
          ]
        }}
      >
        <Datagrid>
          <TextField source="name" />
          <ReferenceField
            source="status"
            reference="farestatuses"
            linkType={false}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <DateField
            source="applyFrom"
            locales="vi-VN"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          />
          <ReferenceField
            source="routeId"
            reference="routes"
            linkType={false}
            allowEmpty
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField
            source="amount"
            locales="vi-VN"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            options={{ style: 'currency', currency: 'VND' }}
          />
        </Datagrid>
      </List>
    )
  }
}

const enhance = compose(translate, withStyles(styles))

export const FareList = enhance(_FareList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const FareCreate = props => {
  return (
    <Create
      {...props}
      title={<EditTitle resource={props.resource} />}
    >
      <SimpleForm validate={validate}>
        <FormSave />
      </SimpleForm>
    </Create>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export const FareEdit = ({ permissions, resource, ...props }) => {
  let companyManager = isCompanyManager(permissions)
  return (
    <Edit
      undoable={false}
      resource={resource}
      title={<EditTitle resource={resource} />}
      {...props}
    >
      <SimpleForm
        toolbar={ companyManager ? <CustomToolbar /> : null }
        validate={validate}
      >
        <FormSave />
      </SimpleForm>
    </Edit>
  )
}
