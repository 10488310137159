import React, { Component, Fragment } from 'react'
import {
  SimpleForm,
  ReferenceInput,
  SelectInput,
  translate,
  ReferenceManyField,
  FunctionField,
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
  Pagination,
  TextInput,
  showNotification,
  required,
  WithPermissions,
} from 'react-admin'
import { 
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core'
import compose from 'recompose/compose'
import CustomToolbar from '../common/CustomToolbarForm'
import { DatePickerInput } from '../common/DatePicker'
import { DateTimePickerInput } from '../common/DateTimePicker'
import moment from 'moment'
import { Provider } from '../provider'
import { change as changeForm } from 'redux-form'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { formatCurrency } from '../utils/formatUtil'
import ExportPlatformDebitButton from './ExportPlatformDebitButton'
import classnames from 'classnames'
import * as permission from '../utils/permission'
import { getCurrentAccountId } from '../utils/commonUtil'
import _ from 'lodash'
import TransactionInfo from '../transaction/TransactionInfo'
import { green, red } from '@material-ui/core/colors'

const platformTableStyle = {
  platformTableTitle: {
    padding: 0,
  },
  textCenter: {
    textAlign: 'center',
  },
  containerExportButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  debit: {
    color: red[500],
  },
  credit: {
    color: green[500],
  },
}

const TransactionInfoWrapper = ({ record }) => {
  record = record && record !== null ? record : {}
  let { refTransaction } = record
  return refTransaction ? <TransactionInfo 
    record={refTransaction}
    hideTitle
    noPadding
  /> : null
}

const _PlatformTransactionDatagrid = ({ 
  ids,
  classes,
  data,
  isAdmin,
  accountId,
  statementDate,
  reconciliationId,
  name,
  translate,
}) => {
  return <Fragment>
    <Grid item md={12} xs={12} container>
      <Grid item md={10} xs={10} className={classes.platformTableTitle}>
        <Typography variant="h4">{translate('resources.platformdebits.name')}</Typography>
      </Grid>
      <Grid item md={2} xs={2} className={classes.containerExportButton}>
        <ExportPlatformDebitButton
          button
          disabled={!accountId || !(ids && ids.length > 0)}
          color="primary"
          filter={{ accountId, statementDate, reconciliationId }}
          name={name}
        />
      </Grid>
    </Grid>
    <Datagrid 
      resource="platformdebits"
      basePath="reconciliations"
      ids={ids}
      data={data}
      currentSort={{}}
      expand={<TransactionInfoWrapper />}
    >
      {isAdmin && <ReferenceField
        reference="financeaccounts"
        source="accountId"
        linkType={false}
        allowEmpty
      >
        <TextField source="accountName" />
      </ReferenceField>}
      <DateField
        showTime
        locales="vi-Vn"
        source="createdAt"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      />
      <ReferenceField
        reference="platformdebitstatuses"
        source="status"
        linkType={false}
        allowEmpty
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      >
        <TextField source="name" />
      </ReferenceField>
      <DateField
        label="resources.platformdebits.fields.refTransaction.id"
        showTime
        locales="vi-Vn"
        source="refTransaction.createdAt"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
      />
      <FunctionField
        source="debit"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        render={({ debit, credit }) => {
          debit = isAdmin ? credit : debit
          return debit ? <span className={classes.debit}>{formatCurrency(debit)}</span> : '0đ'
        }}
      />
      <FunctionField
        source="credit"
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        render={({ credit, debit }) => {
          credit = isAdmin ? debit : credit
          return credit ? <span className={classes.credit}>{formatCurrency(credit)}</span> : '0đ'
        }}
      />
      <TextField
        headerClassName={classes.textCenter}
        cellClassName={classes.textCenter}
        source="description"
      />
    </Datagrid>
  </Fragment>
}

const enhancePlatformDebitTransaction = compose(
  translate,
  withStyles(platformTableStyle),
)

const PlatformTransactionDatagrid = enhancePlatformDebitTransaction(_PlatformTransactionDatagrid)

const style = {
  root: {
    width: '100%',
  },
  container: {
    display: 'flex',
  },
  buttonContainer: {
    alignSelf: 'center',
    paddingLeft: 80
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17
  },
  platformTable: {
    width: '100%',
    borderBottom: '1px solid #e8e8e8',
    borderTop: '1px solid #e8e8e8',
  },
  moneyContainer: {
    borderLeft: '1px solid #e8e8e8',
    display: 'flex',
    flexDirection: 'column',
  },
  balanceContainer: {
    borderTop: '1px solid #e8e8e8',
    paddingTop: 32,
  },
  moneyItem: {
    display: 'flex',
  },
  moneyItemLabel: {
    width: '40%',
  },
  moneyItemValue: {
    width: '60%',
    textAlign: 'end'
  },
  platformTableTitle: {
    padding: 0,
  },
  note: {
    fontSize: 12,
    fontStyle: 'italic',
  },
  debit: {
    color: red[500],
  },
  credit: {
    color: green[500],
  },
}

class FormSave extends Component {

  state = {
    statementDate: moment().endOf('days').toDate().toISOString(),
    accountId: 0,
    record: {},
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record, permissions, action } = nextProps
    if (action === 'create') {
      let isAdmin = permission.isAdmin(permissions)
      if (!isAdmin) {
        let accountId = getCurrentAccountId()
        return {
          ...prevState,
          record: { accountId }
        }
      }
      return {...prevState}
    } 
    let { record: currentRecord } = prevState
    if (record !== currentRecord) {
      let { statementStartDate, statementEndDate } = record
      return {
        ...prevState,
        record,
        fromDate: statementStartDate,
        toDate: statementEndDate,
      }
    }
    return {...prevState}
  }

  findPlatformDebit = () => {
    let { changeForm, action, translate } = this.props
    let { statementDate, accountId, record } = this.state
    if (!accountId) return
    let reconciliationId = action === 'create' ? null : record.id
    Provider.dataProvider('REMOTE', 'reconciliations', {
      method: 'findPlatformDebit',
      requestMethod: 'GET',
      data: { statementDate, accountId, reconciliationId }
    }).then(
      res => {
        let { data= {} } = res
        let { sumOfCredit, sumOfDebit, statementStartDate, statementEndDate, balance } = data
        let name = translate('resources.reconciliations.suggestion_name', { 
          fromDate: moment(statementStartDate).format('HH:mm DD/MM/YYYY'),
          toDate: moment(statementEndDate).format('HH:mm DD/MM/YYYY'),
        })
        this.setState({ sumOfDebit, sumOfCredit, balance, name })
        changeForm('record-form', 'statementStartDate', statementStartDate)
        changeForm('record-form', 'statementEndDate', statementEndDate)
        if (statementStartDate && statementEndDate) {
          changeForm('record-form', 'name', name)
        }
      }
    ).catch( e => {
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  save = record => {
    let { name, accountId, desc, statementStartDate, statementEndDate, id } = record
    let { showNotification, push, action } = this.props
    let { balance, sumOfDebit = 0, sumOfCredit = 0 } = this.state
    if (sumOfDebit === 0 && sumOfCredit === 0) {
      showNotification('notification.no_new_platform_debit_transaction', 'warning')
      return
    }
    if (action === 'create') {
      Provider.dataProvider('CREATE', 'reconciliations', {
        data: {
          name,
          accountId,
          desc,
          debit: balance < 0 ? Math.abs(balance) : 0,
          credit: balance > 0 ? balance : 0,
          status: '00NOT_PAID',
          statementStartDate,
          statementEndDate,
        }
      }).then(
        () => {
          push('/reconciliations')
          showNotification('notification.create_reconciliation_success')
        }
      ).catch(e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      })
    } else {
      Provider.dataProvider('REMOTE', 'reconciliations', {
        method: id,
        requestMethod: 'PATCH',
        data: { desc, name }
      }).then(
        () => {
          push('/reconciliations')
          showNotification('notification.update_reconciliation_success')
        }
      ).catch(e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      })
    }
  }

  render() {
    let { action, classes, permissions, translate } = this.props
    let {
      statementDate,
      accountId,
      record,
      sumOfDebit = 0,
      sumOfCredit = 0,
      name,
    } = this.state
    let isAdmin = permission.isAdmin(permissions)
    let reconciliationId = action === 'create' ? null : record.id
    let accountSize = action === 'create' ? 6 : 12
    let accountDisabled = action !== 'create' || !isAdmin
    let sumOfDebitToShow = isAdmin ? sumOfCredit : sumOfDebit
    let sumOfCreditToShow = isAdmin ? sumOfDebit : sumOfCredit
    let balanceToShow = sumOfCreditToShow - sumOfDebitToShow
    return <SimpleForm
      save={this.save}
      resource="reconciliations"
      toolbar={<CustomToolbar />}
      record={record}
      validate={values => {
        let { accountId, statementDate } = values
        let { accountId: currentAccountId, statementDate: currentStatementDate } = this.state
        statementDate = moment(statementDate).endOf('day').toDate().toISOString()
        if (currentAccountId !== accountId) {
          this.setState({ accountId }, () => {
            this.findPlatformDebit()
          })
        }

        if (currentStatementDate !== statementDate) {
          this.setState({ statementDate }, () => {
            this.findPlatformDebit()
          })
        }
      }}
    >
      <Grid container className={classes.root}>
        <Grid item container>
          <Grid item md={accountSize} xs={12}>
            <ReferenceInput
              source="accountId"
              reference="financeaccounts"
              fullWidth
              perPage={1000}
              resource="reconciliations"
              disabled={accountDisabled}
              validate={required()}
            >
              <SelectInput optionText="accountName" />
            </ReferenceInput>
          </Grid>
          {action === 'create' && <Grid item md={6} xs={12}>
            <DatePickerInput
              source="statementDate"
              dateFormat="DD/MM/YYYY"
              ampm="false"
              showLunarDate={true}
              keyboard={true}
              fullWidth
              resource="reconciliations"
            />
          </Grid>}
        </Grid>
        <Grid item className={classes.platformTable}>
          <ReferenceManyField
            resource="platformdebits"
            basePath="/platformdebits"
            reference="platformdebits"
            target=""
            record={{}}
            filter={{
              createdAt_lte: moment(statementDate).endOf('day').toISOString(),
              accountId: accountId || 0,
              status: '00NTY',
              reconciliationId,
              customerDebitId: { neq: null },
              '../refTransaction': true,
            }}
            pagination={<Pagination />}
            sort={{ field: 'createdAt', order: 'DESC' }}
            perPage={5}
          >
            <PlatformTransactionDatagrid
              reconciliationId={reconciliationId}
              statementDate={statementDate}
              name={name}
              accountId={accountId}
              isAdmin={isAdmin}
            />
          </ReferenceManyField>
        </Grid>
        <Grid item container>
          <Grid item md={12} xs={12}>
            <Typography variant="h4">{translate('resources.reconciliations.detail')}</Typography>
          </Grid>
          <Grid item md={6} xs={12} container>
            <Grid item md={12} xs={12}>
              <TextInput
                fullWidth
                source="name"
                resource="reconciliations"
                disabled
              />
            </Grid>
            {action !== 'create' && <Grid item md={6} xs={12}>
              <ReferenceInput
                source="status"
                reference="reconciliationstatuses"
                fullWidth
                perPage={1000}
                resource="reconciliations"
                disabled
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>}
            {action !== 'create' && <Grid item md={6} xs={12}>
              <DateTimePickerInput
                source="createdAt"
                dateFormat="DD/MM/YYYY HH:mm"
                ampm={false}
                showLunarDate={true}
                keyboard={true}
                pickerType='datetime'
                fullWidth
                disabled
                resource="reconciliations"
              />
            </Grid>}
            <Grid item md={6} xs={12}>
              <DateTimePickerInput
                source="statementStartDate"
                dateFormat="DD/MM/YYYY HH:mm"
                ampm={false}
                showLunarDate={true}
                keyboard={true}
                pickerType='datetime'
                fullWidth
                disabled
                resource="reconciliations"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DateTimePickerInput
                source="statementEndDate"
                dateFormat="DD/MM/YYYY HH:mm"
                ampm={false}
                showLunarDate={true}
                keyboard={true}
                pickerType='datetime'
                fullWidth
                disabled
                resource="reconciliations"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <TextInput fullWidth source="desc" resource="reconciliations" />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12} className={classes.moneyContainer} container>
            <div className={classes.moneyItem}>
              <div className={classes.moneyItemLabel}>
                <b>{translate('resources.reconciliations.fields.sumOfCredit')}</b>
              </div>
              <div className={classes.moneyItemValue}>
                <i className={classes.credit}>{sumOfCreditToShow ? formatCurrency(sumOfCreditToShow) : '0đ'}</i>
              </div>
            </div>
            <div className={classes.moneyItem}>
              <div className={classes.moneyItemLabel}>
                <b>{translate('resources.reconciliations.fields.sumOfDebit')}</b>
              </div>
              <div className={classes.moneyItemValue}>
                <i className={classes.debit}>{sumOfDebitToShow ? formatCurrency(sumOfDebitToShow) : '0đ'}</i>
              </div>
            </div>
            <div className={classnames(classes.moneyItem, classes.balanceContainer)}>
              <div className={classes.moneyItemLabel}>
                <b>{ balanceToShow === 0 ? translate('resources.reconciliations.fields.balance') : (balanceToShow > 0 ? translate('resources.reconciliations.fields.recieve') : translate('resources.reconciliations.fields.pay'))}</b>
              </div>
              <div className={classes.moneyItemValue}>
                <i style={{ color: balanceToShow > 0 ? green[500] : red[500] }}>{balanceToShow ? formatCurrency(Math.abs(balanceToShow)) : '0đ'}</i>
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  }
}
//<div className={classes.note}>
//<p>{translate('resources.reconciliations.balance_less_than_zero_note')}</p>
//<p>{translate('resources.reconciliations.balance_more_than_zero_note')}</p>
//</div>

const enhance = compose(
  translate,
  withStyles(style),
  connect(null, { changeForm, showNotification, push })
)

const FormSaveWithPermission = props => <WithPermissions
  render={({ permissions }) => <FormSave permissions={permissions} {...props} />}
/>

export default enhance(FormSaveWithPermission)
