import React, { Component } from 'react'
import {
  SelectInput,
  ReferenceInput,
  FormDataConsumer,
  SimpleForm,
  translate,
} from 'react-admin'
import VehicleTypeForm from './VehicleTypeForm'
import isEmpty from 'lodash/isEmpty'
import Snackbar from '../common/Snackbar'
import { Field } from 'redux-form'
import { Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'

const action = (onClick, labelButtonAction) => {
  return [
    <Button
      key={0}
      type='variant'
      onClick={() => onClick()}
      style={{ color: '#ffff' }}
    >
      {labelButtonAction}
    </Button>
  ]
}

const sanitizeRestProps = ({
  active,
  autofilled,
  invalid,
  touched,
  valid,
  visited,
  ...rest
}) => rest

const SeatMapSelect = ({
  input,
  translate,
  label,
  choices,
  push,
  meta,
  classes,
  ...props
}) => {
  return (choices && !isEmpty(choices)) ? (
    <SelectInput
      label={translate('resources.vehicletypes.name', { smart_count: 2 })}
      defaultValue={choices[0].id}
      choices={choices}
      optionText="name"
      {...meta}
      {...props}
      {...sanitizeRestProps(props)}
    />
  ) : (
    <Snackbar
      onClick={() => {push('/vehicletypes/create')}}
      action={action}
      labelButtonAction={translate('button.create_vehicle_type')}
      variant="warning"
      message={translate('notification.vehicle_type_not_exists')}
    />
  )
}

class SelectVehicleTypeStep extends Component {
  constructor(props) {
    super(props)
    this.state = {
      record: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { vehicleTypeSelected } = nextProps
    let currentRecord = prevState.record
    if (vehicleTypeSelected !== currentRecord) {
      currentRecord = vehicleTypeSelected
    }
    return {
      record: currentRecord,
    }
  }

  render() {
    const { record } = this.state
    const { translate, push } = this.props
    return (
      <SimpleForm
        record={record}
        resource="vehicletypes"
        toolbar={null}
        form="vehicle-type"
      >
        <ReferenceInput
          basePath="/vehicletypes"
          reference="vehicletypes"
          source="type"
          record={{}}
          push={push}
          fullWidth
        >
          <Field
            name="type"
            translate={translate}
            component={SeatMapSelect}
          />
        </ReferenceInput>
        <FormDataConsumer>
          {({formData, ...rest}) =>{
            return <VehicleTypeForm vehicleTypeId={formData.type} {...rest} />
          }}
        </FormDataConsumer>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  translate,
  connect(null, { push })
)

export default enhance(SelectVehicleTypeStep)
