import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Map from './map'

class Network extends Component {
  render() {
    let { params } = this.props.match
    return (
      <Map routeId={params.id} viewmode={ params.viewmode } zoom={10} maxZoom={18} />
    )
  }
}

Network.propTypes = {
  viewmode: PropTypes.oneOf(['view', 'planning', 'simulation', 'real-time'])
}

Network.defaultProps = {
  viewmode: 'view'
}

export default Network
