import React, { Component, Fragment } from 'react'
import {
  List,
  Create,
  Edit,
  Datagrid,
  SimpleForm,
  TextInput,
  LongTextInput,
  ReferenceInput,
  SelectInput,
  TextField,
  ReferenceField,
  CardActions,
  addField,
  translate,
  required,
  FunctionField,
} from 'react-admin'
import '../common/calendar-timeline/lib/Timeline.scss'
import { Provider } from '../provider'
import _CreateTripFromSchedule from './CreateTripFromSchedule'
import { Schedule } from './models/Schedule'
import ScheduleTimelineComponent from './ScheduleTimelineComponent'
import Pagination from '../common/Pagination'
import compose from 'recompose/compose'
import { withStyles, Button, Tooltip, CircularProgress } from '@material-ui/core'
import { changeBreadcrumb } from '../breadcrumb/action'
import { connect } from 'react-redux'
import { getRouteGroupInfo } from '../common/getData'
import reduce from 'lodash/reduce'
import ImportButton from './ImportButton'
import { createPattern } from './models/Pattern'
import papa from 'papaparse'
import isEmpty from 'lodash/isEmpty'
import { normalize } from './utils/data'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styleLabelButton } from '../common/style'
import CustomToolbar from '../common/CustomToolbarForm'
import { EditTitle } from '../common/Title'
import Snackbar from '../common/Snackbar'
import { validateCreateSchedule } from './validate'
import LinkField from './../common/LinkField'
import AddIcon from '@material-ui/icons/Add'
import { isCompanyManager } from '../utils/permission'
import ExportButton from './ExportButton'
import ImportSmartButton from './ImportSmartButton'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import { MIN, MAX, validateName } from '../common/validateName'

const styles = {
  buttonContain: {
    display: 'flex',
    margin: 16
  },
  label: styleLabelButton().label,
  spacing: {
    marginLeft: 8
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  icon: {
    paddingRight: '0.5em',
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 70,
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  }
}

export const CreateTripFromSchedule = _CreateTripFromSchedule

const ActionList = ({ companyManager }) => {
  return (
    <CardActions>
      {companyManager && <ImportSmartButton />}
    </CardActions>
  )
}

class _ScheduleList extends Component {

  // goto /schedules/createtrip
  gotoCreateTrip = (e, record) => {
    let { history } = this.props
    e.preventDefault()
    e.stopPropagation()
    let { id } = record
    history.push(`/schedules/createtrip?scheduleId=${id}`)
  }

  render() {
    let { classes, permissions, translate, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <List
        pagination={<Pagination
          noResultTitle={translate('resources.schedules.empty')}
          noResultText={translate('resources.schedules.create_schedule_reminder')}
        />}
        actions={<ActionList companyManager={companyManager} />}
        bulkActionButtons={false}
        filter={{
          status: { neq: '20ARCHIVED' },
          '../fields': [
            'id',
            'name',
            'desc',
            'timetableGroupId',
            'serviceId',
            'data',
            'companyId',
            'createdAt',
          ]
        }}
        {...props}
      >
        <Datagrid>
          <TextField source="name" />
          <ReferenceField
            source="timetableGroupId"
            reference="timetablegroups"
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            allowEmpty
            linkType={!!companyManager}
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="serviceId"
            reference="services"
            linkType={false}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
            allowEmpty
          >
            <TextField source="name" />
          </ReferenceField>
          <FunctionField
            source="createdAt"
            render={({ createdAt }) => moment(createdAt).format('HH:mm DD/MM/YYYY')}
            headerClassName={classes.textCenter}
            cellClassName={classes.textCenter}
          />
          {companyManager && <FunctionField
            cellClassName={classes.textEnd}
            render={record => <Fragment>
              <Tooltip 
                title={translate('resources.schedules.buttons.createTrip')}
                enterDelay={100}
              >
                <LinkField
                  className={classes.iconButton}
                  icon
                  onClick={(v) => this.gotoCreateTrip(v, record)}
                >
                  <AddIcon fontSize="small" />
                </LinkField>
              </Tooltip>
              <ExportButton scheduleId={record.id} name={record.name} />
              <Tooltip title={translate('button.edit')} enterDelay={100} >
                <LinkField
                  className={classes.iconButton}
                  path={`/schedules/${record.id}`}
                  icon
                >
                  <EditIcon fontSize="small" />
                </LinkField>
              </Tooltip>
            </Fragment>
            }
          />}
        </Datagrid>
      </List>
    )
  }
}

const enhanceList = compose(
  translate,
  withStyles(styles),
)
export const ScheduleList = enhanceList(_ScheduleList)

function  removeVehicleWithBlockEmpty(vehicles) {
  return reduce(vehicles, (result = [], v) => {
    if (!v) return result
    if (v.blocks && v.blocks.length > 0) {
      result.push(v)
    }
    return result
  }, [])
}

class _ScheduleInput extends Component {

  constructor(props) {
    super(props)
    props.onRef(this)
    this.state = {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { input, schedule, doImport } = nextProps
    let { data } = schedule
    let { data: currentData, doImport: currentDoImport } = prevState
    if (typeof data === 'object' && currentData !== data) {
      currentData = data
      let dataToSave = removeVehicleWithBlockEmpty(data)
      input = { ...input, value: dataToSave }
      input.onChange(dataToSave)
    }
    if (currentDoImport !== doImport) {
      currentDoImport = doImport
    }

    return {
      data: currentData || [],
      doImport: currentDoImport || false
    }
  }

  autoBlock(timetableGroup, serviceId) {
    let schedule = new Schedule(timetableGroup.data, serviceId)
    schedule.autoBlock2()
    let data = schedule.cars
    this.setState({ serviceId })
    return data
  }

  importBlocks(timetableGroup, serviceId, blocks) {
    let schedule = new Schedule(timetableGroup.data, serviceId)
    /*
    let importBlocks = [
      { number: 1, trips: [{ patternId: 2, min: 285 }, { patternId: 1, min: 330 }, { patternId: 2, min: 375 }] },
      { number: 2, trips: [{ patternId: 1, min: 300 }, { patternId: 2, min: 390 }, { patternId: 1, min: 450 }] },
    ]
    */
    schedule.importBlocks(blocks)
    let data = schedule.cars
    this.setState({ serviceId })
    return data
  }

  onChange = (value) => {
    let { input } = this.props
    input.onChange(value)
  }

  updateRecord = (data) => {
    let { input } = this.props
    let dataToSave = removeVehicleWithBlockEmpty(data)
    input.onChange(dataToSave)
    this.setState({ data })
  }

  render() {
    const { onPaste, translate, meta } = this.props
    let { data, serviceId, doImport } = this.state
    let { error } = meta
    return (data && data.length > 0) && !error ?
      <div
        onPaste={(e) => onPaste(e)}
        contentEditable="true"
      >
        <ScheduleTimelineComponent
          onRef={ref => this.scheduleTimeLineInput = ref}
          updateRecord={this.updateRecord}
          key={serviceId}
          value={[...data]}
          onChange={this.onChange}
          doImport={doImport}
        />
      </div> :
      <Snackbar
        variant="error"
        message={translate('resources.schedules.no_schedule')}
      />
  }
}

const enhanceScheduleInput = compose(addField, translate)

const ScheduleInput = enhanceScheduleInput(_ScheduleInput)

class _Form extends Component {
  state = {
    record: {},
    defaultName: '',
    doImport: false,
  }

  async componentDidMount() {
    let { record, translate, changeBreadcrumb } = this.props
    let paths = []
    let defaultName = ''
    let timetableGroup
    let routeGroupId
    let { serviceId } = record
    let response = await Provider.dataProvider('GET_LIST', 'timetablegroups', {
      filter: {
        where: {
          id: parseInt(record.timetableGroupId),
          includePattern: 1
        },
      },
      sort: {},
      pagination: { page: 1, perPage: 1 },
    })

    if (response && response.data && response.data.length === 1) {
      timetableGroup = response.data[0]
      routeGroupId = timetableGroup.routeGroupId
      if (!record.data) {
        this.scheduleInput && this.scheduleInput.autoBlock(timetableGroup, serviceId)
      }
      // update breadcrumb
      let routeGroup = await getRouteGroupInfo(routeGroupId)
      defaultName = routeGroup.name
      paths = [
        { label: translate('resources.routegroups.name', { smart_count: 2 }), to: '/routegroups' },
        { label: routeGroup.name, to: `/routegroups/${routeGroupId}` },
        {
          label: translate('resources.schedules.name', { smart_count: 2 }),
          to: '/schedules'
        },
        { label: record.id ? record.name : translate('resources.common.create'), to: '' },
      ]
    }
    changeBreadcrumb({ paths })
    this.setState({
      record: record,
      defaultName,
      timetableGroup: timetableGroup || {},
      routeGroupId: routeGroupId || 0,
      serviceId: serviceId || 0
    })
  }

  addNewRow = () => {
    let { record } = this.state
    let { data } = record
    if (!data) return

    let lastEle = data[data.length - 1]
    let newEle = {
      id: lastEle.id + 1,
      lastStopId: 0,
      lastStopMin: 0,
      blocks: []
    }
    data.push(newEle)
    record = { ...record, data }
    this.setState({
      record,
      doImport: false,
    })
  }

  handleChangeService = (evt, serviceId) => {
    let { record } = this.state
    let data = this.scheduleInput.autoBlock(this.state.timetableGroup, serviceId)
    record = { ...record, data }
    this.setState({
      serviceId,
      record
    })
  }

  // block = { number: 1000, trips: [{patternId: 2, min: 285}, {patternId: 1, min: 485}] }
  /**
   * @blocks array of blocks
   */
  handleImportBlocks = (blocks) => {
    let { record, serviceId } = this.state
    let data = this.scheduleInput.importBlocks(this.state.timetableGroup, serviceId, blocks)
    record = { ...record, data }
    this.setState({
      serviceId,
      record,
      doImport: true,
    })
  }

  handleImportFile = (vehicleTrips) => {
    let cars = []
    let defaultServiceId = 3
    let { timetableGroup, record } = this.state
    let { data: timetables } = timetableGroup

    for (let vehicleNum in vehicleTrips) {
      let patterns = []
      let schedule = {}
      let vehicleTrip = vehicleTrips[vehicleNum]
      let patternIds = Object.keys(vehicleTrip)

      for (let i = 0; i < patternIds.length; i++) {
        let patternId = patternIds[i]
        let startTimes = vehicleTrip[patternId]

        let pattern = createPattern(timetables, defaultServiceId, patternId, startTimes)
        patterns.push(pattern)
      }
      schedule = new Schedule(patterns, defaultServiceId)
      let car = schedule.assignBlock(vehicleNum)
      cars.push(car)
    }
    record = { ...record, data: [...cars] }
    this.setState({
      record,
      serviceId: defaultServiceId,
      doImport: true,
    })
  }

  onPaste = evt => {
    evt.preventDefault()
    evt.stopPropagation()
    let { timetableGroup } = this.state
    let patterns = timetableGroup.data
    let paste = (evt.clipboardData || window.clipboardData).getData('text')
    let csv = papa.parse(paste, { header: true })
    if (!csv || isEmpty(csv)) return
    let { data } = csv
    if (!data || data.length === 0) return
    let blocks = normalize(data, patterns)
    this.handleImportBlocks(blocks)
  }

  render() {
    const { isCreate, classes, translate, companyManager, ...props } = this.props
    let { routeGroupId, record, doImport, timetableGroup, defaultName } = this.state
    let formRecord = isCreate ? {
      ...{},
      timetableGroupId: record.timetableGroupId,
    } : record
    if (isEmpty(record)) return <div className={classes.progress}><CircularProgress /></div>
    return (
      <SimpleForm
        {...props}
        validate={validateCreateSchedule}
        record={formRecord}
        redirect={`/routegroups/${routeGroupId}`}
        toolbar={companyManager ? <CustomToolbar /> : null}
      >
        <TextInput
          source="name"
          fullWidth
          validate={validateName(MIN, MAX)} defaultValue={defaultName}
        />
        <LongTextInput source="desc" />
        <ReferenceInput
          source="serviceId"
          resource="services"
          reference="services"
          validate={required()}
          onChange={this.handleChangeService}
        >
          <SelectInput source="name" />
        </ReferenceInput>
        <div className={classes.buttonContain}>
          <ImportButton
            handleImportFile={this.handleImportFile}
            handleImportBlocks={this.handleImportBlocks}
            timetableGroup={timetableGroup}
          />
          <Button
            color="primary"
            onClick={this.addNewRow}
            className={classes.spacing}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span className={classes.label}>{translate('button.add_vehicle')}</span>
          </Button>
        </div>
        <ScheduleInput
          schedule={record}
          addLabel={false}
          onRef={ref => this.scheduleInput = ref}
          source="data"
          doImport={doImport}
          onPaste={this.onPaste}
        />
      </SimpleForm>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  connect(null, {
    changeBreadcrumb,
  })
)

const Form = enhance(_Form)

export const ScheduleCreate = ({permissions, resource, ...props}) => {
  let companyManager = isCompanyManager(permissions)
  return (
    <Create
      resource={resource}
      title={<EditTitle resource={resource} />}
      {...props}
    >
      <Form isCreate companyManager={companyManager} />
    </Create>
  )
}

export const ScheduleEdit = ({ permissions, resource, ...props }) => {
  let companyManager = isCompanyManager(permissions)
  return (
    <Edit
      {...props}
      undoable={false}
      resource={resource}
      title={<EditTitle
        resource={resource}
        render={record => record.name}
      />}
    >
      <Form companyManager={companyManager} />
    </Edit>
  )
}
