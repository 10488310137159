import React, { Component } from 'react'
import {
  Grid,
  withStyles,
  Typography,
} from '@material-ui/core'
import {
  SimpleShowLayout,
  DateField,
  ReferenceField,
  TextField,
  NumberField,
  translate,
  FunctionField,
} from 'react-admin'
import ReservationInfo from './ReservationInfo'
import compose from 'recompose/compose'
import { moment } from '../common/format'

const styles = {
  root: {
    padding: 32,
  },
  reservation: {
    width: '100%'
  }
}

class TransactionInfo extends Component {

  render() {
    let { 
      record = {},
      classes,
      hideTitle,
      translate,
      noPadding,
    } = this.props
    let style = { padding: noPadding && 0 }
    return <div className={classes.root} style={style}>
      {!hideTitle && <Typography variant="h4">{translate('resources.transactions.title')}</Typography>}
      <Grid container>
        <Grid container item md={7} xs={12}>
          <Grid item md={6} xs={6}>
            <SimpleShowLayout resource="transactions" basePath="/transactions" record={record}>
              <ReferenceField
                source="status"
                reference="transactionstatuses"
                linkType={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <ReferenceField
                label="resources.transactions.fields.paymentMethodId"
                source="paymentMethodId"
                reference="paymentmethods"
                linkType={false}
              >
                <TextField source="name" />
              </ReferenceField>
              <FunctionField
                source="createdAt"
                render={({ createdAt }) => moment(createdAt).format('HH:mm:ss DD/MM/YYYY')}
              />
              <ReferenceField
                source="type"
                reference="transactiontypes"
                linkType={false}
              >
                <TextField source="name" />
              </ReferenceField>
            </SimpleShowLayout>
          </Grid>
          <Grid item md={6} xs={6}>
            <SimpleShowLayout
              record={record}
              resource="transactions"
              basePath="/transactions"
            >
              <NumberField source="total" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
              <NumberField source="tax" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
              <NumberField source="paid" locales="vi-VN" options={{ style: 'currency', currency: 'VND' }} />
              <DateField source="paidAt" locale="vi-VN" showTime />
              <TextField source="attempt" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
        <Grid container item md={5} xs={12}>
          <SimpleShowLayout
            className={classes.reservation}
            record={record}
            resource="transactions"
            basePath="/transactions"
          >
            <ReferenceField
              source="reservationId"
              reference="reservations"
              addLabel={false}
              linkType={false}
            >
              <ReservationInfo />
            </ReferenceField>
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </div>
  }
}

const enhance = compose(translate, withStyles(styles))
export default enhance(TransactionInfo)
