import React, { Component } from 'react'
import {
  Button,
  withStyles,
  DialogTitle,
  Dialog,
  Divider,
  ListItemIcon,
  ListItemText,
  DialogContent,
  MenuItem,
  MenuList,
  DialogActions,
  TextField
} from '@material-ui/core'
import { FieldTitle } from 'react-admin'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faBus, faUserTie } from '@fortawesome/free-solid-svg-icons'
import compose from 'recompose/compose'

const style = theme => ({
  rightLabel: {
    paddingLeft: '0.5em'
  },
  content: {
    display: 'flex',
    padding: 0
  },
  main: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 1,
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: 150,
      flexShrink: 0,
    },
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.overrides.MenuItemLink.root.color,
      },
    },
  },
  setting: {
    display: 'flex',
    paddingTop: '16px'
  },
  coverTitle: {
    background: theme.palette.primary.main,
  },
  text: {
    color: theme.overrides.MenuItemLink.root.color
  }
})


class SettingButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      formSetting: ''
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.itemClick = this.itemClick.bind(this)
  }

  handleClick() {
    this.setState({
      open: true
    })
  }

  handleClose() {
    this.setState({
      open: false
    })
  }

  itemClick(e) {
    let formSetting = e.target.textContent
    this.setState({
      formSetting: formSetting
    })
  }
  render() {
    const { classes } = this.props
    const { formSetting } = this.state
    const drawer = (
      <div>
        <div className={classes.toolbar} />
        <Divider />
        <MenuList>
          <MenuItem onClick={this.itemClick} className={classes.menuItem}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faBus} />
            </ListItemIcon>
            <ListItemText
              inset
              primary={<span>Verhicle</span>}
            />
          </MenuItem>
          <MenuItem onClick={this.itemClick} className={classes.menuItem}>
            <ListItemIcon>
              <FontAwesomeIcon icon={faUserTie} />
            </ListItemIcon>
            <ListItemText
              inset
              primary="Driver"
            />
          </MenuItem>
        </MenuList>
      </div>
    )

    return (
      <div>
        <Button onClick={this.handleClick} color="primary">
          <FontAwesomeIcon icon={faCog} />
          <span className={classes.rightLabel}>Setting</span>
        </Button>
        <Dialog
          onClose={this.handleClose}
          open={this.state.open}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle className={classes.coverTitle}>
            <span className={classes.text}>Setting</span>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <nav className={classes.drawer}>
              {drawer}
            </nav>
            <main className={classes.main}>
              {formSetting === 'Verhicle' ? (
                <form>
                  <div className={classes.setting}>
                    <TextField
                      label={<FieldTitle label="Số lượng xe" />}
                      fullWidth
                    />
                  </div>
                  <div className={classes.setting}>
                    <TextField
                      label={<FieldTitle label="Thời gian nghỉ của một xe" />}
                      fullWidth
                    />
                  </div>
                  <div className={classes.setting}>
                    <TextField
                      label={<FieldTitle label="Khoảng thời gian xuất phát 1 tuyến xe" />}
                      fullWidth
                    />
                  </div>
                </form>
              ) : null
              }
            </main>
          </DialogContent>
          <DialogActions>
            <Button color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const enhance = compose(withStyles(style, { withTheme: true }))

export default enhance(SettingButton)
