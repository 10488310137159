import React, { Component } from 'react'
import SettingPosItem from './SettingPosItem'
import { agencyPosItems, actionAgency } from '../menu/items'

class AgencyPosSetting extends Component {

  render() {
    let { record, tabIndexPos, onChangeTabIndexPos, isAdmin, match, location } = this.props
    return <SettingPosItem
      tabIndexPos={tabIndexPos}
      onChangeTabIndexPos={onChangeTabIndexPos}
      record={record}
      resources= "companysettings"
      isAdmin={isAdmin}
      location={location}
      match={match}
      menuItems={agencyPosItems}
      actionItems={actionAgency}
      hideMenu="pos.agency"
    />
  }
}

export default AgencyPosSetting
