import React, { Component } from  'react'
import { Provider } from '../provider'
import { translate } from 'react-admin'
import {
  Dialog,
  Table,
  TableBody,
  TableRow,
  TableCell,
  withStyles,
  TableHead,
  IconButton,
  DialogTitle,
  Toolbar,
} from '@material-ui/core'
import {
  Close as CloseIcon,  
} from '@material-ui/icons'
import compose from 'recompose/compose'

class MaintainCheckList extends Component {
  state = {
    schedules: [],
    maintainItems: [],
    open: false,
    isLoading: false
  }
  componentDidMount() {
    
    this.loadData()

  }
  loadData = async () => {
    if (!this.props.vehicleId) {
      return      
    }
    this.setState({isLoading: true})
    let rsSchedule = await Provider.dataProvider('REMOTE', 'maintainschedules',
      { 
        method: '', 
        requestMethod: 'GET',
        data: {}
      })

    let rsAllItems = await Provider.dataProvider('REMOTE', 'maintainitems',
      { 
        method: '', 
        requestMethod: 'GET',
        data: {}
      }
    )

    let rsGroupMaintain = await Provider.dataProvider('REMOTE', 'vehiclemaintains',
      { 
        method: 'getMaintainSchedule', 
        requestMethod: 'GET',
        data: {vehicleId: this.props.vehicleId}
      }
    )
    let dtSchedule = rsSchedule.data.sort((a, b) => (a.month-b.month))
    const {
      schedules, maintainItems 
    } = this.buildTable(dtSchedule, rsAllItems.data,  rsGroupMaintain.data.data.maintainGroups)
    this.setState({
      schedules: schedules ,
      maintainGroups: rsGroupMaintain.data.data.maintainGroups,
      maintainItems: maintainItems,
      isLoading: false
    })
  }
  buildTable = (schedules, maintainItems, groupItemSchedule) => {   
    for (let i = 0, lengthI = groupItemSchedule.length; i < lengthI; i++) {
      const grI = groupItemSchedule[i]
      const scheduleIdx = schedules.findIndex(obj => obj.id === grI.scheduleId)
      if (scheduleIdx !== -1) {
        let scheduleItems = []
        grI.items.forEach(item => {
          const it = maintainItems.find(mtItem => mtItem.id === item.itemId)
          if (it) {
            scheduleItems.push({...it, ...item, scheduleId: grI.scheduleId})
          }
        })
        schedules[scheduleIdx].items = scheduleItems
      }
    }
    for (let i = 0; i < schedules.length; i++) {
      let scI = schedules[i]
      for (let j = i+1; j < schedules.length; j++) {
        let scJ = schedules[j]
        if (scJ.month % scI.month === 0) {
          scI.items && scI.items.forEach(item => {
            if (!scJ.items) {
              scJ.items = []
            }
            let ctItem = scJ.items.find(it => (item.itemId === it.itemId && item.actionCode === it.actionCode))
            if (!ctItem) {
              scJ.items.push({...item})
            }            
          })
        }
      }
    }
    // console.log('Schedules', schedules)
    return {
      schedules, maintainItems
    }

  }
  // handleClose= ()=> {
  //   this.setState({open: false})
  // }

  handleOpen = () => {
    this.loadData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.open && this.props.open) {
      this.loadData()
    }
  }

  handleHeaderClick = schedule => {
    console.log('schedule selected', schedule)
  }

  // renderCellItem = (row)
  render() {
    const {schedules, maintainItems} = this.state
    const {classes, open, handleClose} = this.props
    return (       
      <Dialog open={open} fullScreen onClose={handleClose}>
        <Toolbar>
          <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
            <CloseIcon />
          </IconButton>
        </Toolbar>
        <DialogTitle>Thông tin lịch bảo dưỡng</DialogTitle>        
        <div style={{display: 'flex'}}>
          <span style={{margin: 10}}>C:  Kiểm tra</span>
          <span style={{margin: 10}}>R:  Thay thế</span>
          <span style={{margin: 10}}>M:  Bảo dưỡng</span>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={30} className={classes.head}>Hạng mục\Tháng</TableCell>
              {
                schedules.map((item, idx) => {
                  return (<TableCell onClick={() => {this.handleHeaderClick(item)}} width={30} align="center" key={idx} className={classes.head}>
                    {item.month + 'T'}
                  </TableCell>)
                })
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              maintainItems.map((item, idxItem) => {
                return (<TableRow key={idxItem}>
                  <TableCell className={classes.cell}>{item.name}</TableCell>
                  {                    
                    schedules.map((sc, scIdx) => {
                      if (!sc.items || sc.items.length === 0) {
                        return <TableCell width={30} key={scIdx} className={classes.cell}>{''}</TableCell>
                      }                      
                      const it = sc.items.filter(scItem => scItem.itemId === item.id)
                      if (it.length > 0) {
                        let rs = []
                        it.forEach(it => {
                          rs.push(it.actionCode)
                        })
                        return <TableCell width={30} key={scIdx} className={classes.cell}>{rs.join('&')}</TableCell>
                      }
                      return <TableCell key={scIdx} className={classes.cell}>{''}</TableCell>                        
                    })}
                </TableRow>)
              })
            }
          </TableBody>
        </Table>
      </Dialog>
    )
  }
}
const styles = {
  table: {
    minWidth: 1000,
  },
  cell: {
    borderWidth: 1,
    borderColor: 'darkgray',
    borderStyle: 'solid',
    align: 'center',
    maxWidth: 40,
    width:'auto',
    padding: 3,
    textAlign: 'center',
  },
  head: {
    backgroundColor: 'lightgray',
    position: 'sticky',
    top: 0,
    borderWidth: 1,
    borderColor: 'darkgray',
    borderStyle: 'solid',
    align: 'center',
    width: 'auto',
    padding: 3,
    textAlign: 'center',
  }

}
const enhance = compose(withStyles(styles), translate)
export default enhance(MaintainCheckList)
