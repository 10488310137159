import React, { Component } from 'react'
import { Provider } from '../provider'
import {
  Stepper,
  Step,
  StepContent,
  StepLabel,
  withStyles,
} from '@material-ui/core'
import { translate, ReferenceField, FunctionField } from 'react-admin'
import compose from 'recompose/compose'
import groupBy from 'lodash/groupBy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'

const styles = () => ({})

class DriverActivity extends Component {

  constructor(props) {
    super(props)
    this.state = {
      activities: [],
    }
  }


  async componentDidMount() {
    let { driver } = this.props
    let data = {
      driverId: driver.id
    }
    let res = await Provider.dataProvider('REMOTE', 'drivers', {
      method: 'getActivity',
      requestMethod: 'GET',
      data
    })
    if (res && res.data) {
      let result = res.data.data
      if (result && result.length > 0) {
        let activities = result[0] || []
        activities = groupBy(activities, 'time')
        this.setState({ activities })
      }
    }
  }

  render() {
    let { activities } = this.state
    let { classes, translate } = this.props
    return <Stepper nonLinear={true} orientation="vertical">
      {Object.keys(activities).map((time, idx) => (
        <Step active={true} key={idx}>
          <StepLabel icon={<FontAwesomeIcon icon={faClock} />}>
            {time}
          </StepLabel>
          <StepContent className={classes.text}>
            {activities[time].map((activity, idx) => {
              return <div key={idx}>
                <ReferenceField
                  reosurce="tripstatuses"
                  reference="tripstatuses"
                  basePath="/tripstatuses"
                  record={activity}
                  source="status"
                  linkType={false}
                >
                  <FunctionField
                    render={ record => activity.status !== '10ACT' &&
                      <div>
                        <span>{translate('resources.vehicles.numberOfTripByStatus', { status: record.name.toLowerCase() })}:</span>
                        &nbsp;
                        <span>{activity.numberoftrip}</span>
                      </div>
                    }
                  />
                </ReferenceField>
              </div>
            })}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  }
}
const enhance = compose(withStyles(styles), translate)

export default enhance(DriverActivity)
