import React, { Component } from 'react'
import { translate } from 'react-admin'
import {
  withStyles,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { isArray } from 'util'

const styles = () => ({
  formControl: {
    marginBottom: 8,
    marginTop: 32,
  }
})

class RadioGroupCustom extends Component {

  state = {}

  componentDidMount() {
    let { defaultValue } = this.props
    this.setState({ value: defaultValue })
  }

  isRender = (choice) => {
    let { filter } = this.props
    if (filter && filter.length) {
      for (let i = 0; i < filter.length; i++) {
        let { field, value } = filter[i]
        if (isArray(value)) {
          if (value.indexOf(choice[field]) === -1) return false
        } else {
          if (choice[field] !== value) return false
        }
      }
    }
    return true
  }

  render() {
    const {
      classes,
      choices = [],
      label,
      optionValue = 'id',
      optionText,
      fullWidth,
      translate,
      value,
      onChange,
      isTranslateText,
      row = false,
    } = this.props
    return <FormControl className={classes.formControl} margin="none" fullWidth={fullWidth}>
      <FormLabel component="legend">{label && translate(label)}</FormLabel>
      <RadioGroup row={row} value={value} onChange={onChange}>
        {choices.map((choice, key) => this.isRender(choice) && (
          <FormControlLabel
            key={key}
            value={choice[optionValue] && choice[optionValue]}
            control={<Radio />}
            label={isTranslateText ? translate(choice[optionText]) : choice[optionText]}
          />
        ))}
      </RadioGroup>
    </FormControl>
  }
}

RadioGroupCustom.defaultProps = {
  optionValue: 'id',
  optionText: 'name',
  choices: [],
  isTranslateText: true
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(RadioGroupCustom)
