import React, { Component } from 'react'
import {
  translate,
  ReferenceInput,
  SelectInput,
  TextInput,
  NumberInput,
} from 'react-admin'
import {
  withStyles,
  Grid
} from '@material-ui/core'
import compose from 'recompose/compose'
import { FuzzySelectInput } from '../common/react-fuzzy-picker'
import voca from 'voca'
import TripStop from './TripStop'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { MIN, MAX, validateName } from '../common/validateName'

const styles = {
  root: {
  },
  fuzzySelect: {
    marginTop: 16,
    marginBottom: 8
  }
}

class TripGeneralInformation extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let { classes, record } = this.props
    const extra = {
      resource: 'trips',
      fullWidth: true,
    }

    return (
      <Grid container className={classes.root}>
        <Grid item container xs={6}>
          <ReferenceInput
            source="routeId"
            reference="routes"
            disabled
            {...extra}
          >
            <SelectInput
              resource="routes"
              optionText="name" />
          </ReferenceInput>

          <Grid item xs={6}>
            <TextInput
              source="name"
              validate={validateName(MIN, MAX)}
              {...extra}
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              source="longName"
              {...extra} />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="desc"
              {...extra} />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="departure"
              disabled
              {...extra} />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              source="arrival"
              disabled
              {...extra} />
          </Grid>

          <Grid item xs={6}>
            <NumberInput
              source="duration"
              disabled
              {...extra} />
          </Grid>

          <Grid item xs={6}>
            <NumberInput
              source="distance"
              disabled
              {...extra} />
          </Grid>

          <Grid item xs={6}>
            <DateTimePickerInput
              source="departureTime"
              dateFormat="DD/MM/YYYY HH:mm"
              ampm={false}
              showLunarDate={true}
              keyboard={true}
              initialValue={record.departureTime}
              pickerType='datetime'
              {...extra}
            />
          </Grid>

          <Grid item xs={6}>
            <DateTimePickerInput
              source="arrivalTime"
              dateFormat="DD/MM/YYYY HH:mm"
              ampm={false}
              showLunarDate={true}
              keyboard={true}
              initialValue={record.arrivalTime}
              pickerType='datetime'
              {...extra}
            />
          </Grid>

          <Grid item xs={6}>
            <DateTimePickerInput
              source="actualDepartureTime"
              dateFormat="DD/MM/YYYY HH:mm"
              ampm={false}
              showLunarDate={true}
              keyboard={true}
              initialValue={record.actualDepartureTime}
              pickerType='datetime'
              {...extra}
            />
          </Grid>

          <Grid item xs={6}>
            <DateTimePickerInput
              source="actualArrivalTime"
              dateFormat="DD/MM/YYYY HH:mm"
              ampm={false}
              showLunarDate={true}
              keyboard={true}
              initialValue={record.actualArrivalTime}
              pickerType='datetime'
              {...extra}
            />
          </Grid>

          <Grid item xs={6}>
            <ReferenceInput
              source="status"
              reference="tripstatuses"
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={6}>
            <ReferenceInput
              source="type"
              reference="triptypes"
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={6}>
            <ReferenceInput
              source="driverId"
              reference="drivers/list"
              perPage={1000}
              {...extra}
            >
              <FuzzySelectInput
                optionText="fullName"
                renderItem={item => item.fullName}
                itemValue={item => item.key || (item.key = voca.latinise(item.fullName))}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              source="driver2Id"
              reference="drivers/list"
              perPage={1000}
              {...extra}
            >
              <FuzzySelectInput
                optionText="fullName"
                renderItem={item => item.fullName}
                itemValue={item => item.key || (item.key = voca.latinise(item.fullName))}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              source="vehicleId"
              reference="vehicles"
              perPage={1000}
              {...extra}
            >
              <FuzzySelectInput
                optionText="plate"
                renderItem={item => item.plate}
                itemValue={item => item.key || (item.key = voca.latinise(item.plate))}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput
              source="assistantDriverId"
              reference="assistantdrivers/list"
              perPage={1000}
              {...extra}
            >
              <FuzzySelectInput
                optionText="fullName"
                renderItem={item => item.fullName}
                itemValue={item => item.key || (item.key = voca.latinise(item.fullName))}
              />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Grid item xs={6}>
          <TripStop record={record} />
        </Grid>

      </Grid>
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(TripGeneralInformation)
