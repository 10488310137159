import React, { Component } from 'react'
import {
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  Checkbox,
  withStyles,
  FormControlLabel,
  Button,
  ClickAwayListener,
  IconButton,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import compose from 'recompose/compose'
import { translate } from 'react-admin'

const ALL_ITEM = 'all'
const NONE_ITEM = 'none'

class CheckboxMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      options: [],
    }
  }
  static getDerivedStateFromProps(props, state) {
    if (props.options !== state.options) {
      return {
        options: props.options,
      }
    }
    return null
  }
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  };
  
  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }
    this.setState({ open: false }, ()=> {
      if (this.props.closeEvent && this.props.options) {
        this.props.closeEvent(this.state.options.filter(item => item.isChecked))
      }
    })
  };
  handleSelect = (evt, type) => {
    const options = this.state.options? [...this.state.options]: []
    if (type === ALL_ITEM) {
      for (let i = 0; i < options.length; i++) {
        options[i].isChecked = true
      }
      this.setState({options})
    } else if (type === NONE_ITEM) {
      for (let i = 0; i < options.length; i++) {
        options[i].isChecked = false
      }
      this.setState({options})
    } else {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value === type) {
          options[i].isChecked = evt.target.checked
        }        
      }
      this.setState({options})
    }
  }

  render() {
    const { classes, icon } = this.props
    const { open, options = [] } = this.state
    return (
      <div className={classes.root}>     
        <IconButton buttonRef={node => {
          this.anchorEl = node
        }}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={this.handleToggle}
        >
          {icon?icon:<AddIcon color="primary"/>}
        </IconButton>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <div className={classes.menuList}>                   
                    <MenuList disablePadding > {
                      options.map((item, index) => {
                        return (
                          <MenuItem key = {index}>
                            <FormControlLabel control={
                              <Checkbox checked={item.isChecked} onChange={e => {
                                this.handleSelect(e, item.value)
                              }}></Checkbox>} 
                            label={item.name}
                            >
                            </FormControlLabel>                            
                          </MenuItem>)
                      })
                    }                                     
                    </MenuList>
                    <div
                      className={classes.allOrNoneWrapper}
                      ref={node => {
                        this.selectAllOrNone = node
                      }}
                    >
                      <Button
                        className={classes.allOrNone}
                        batchselect={'all'}
                        onClick={e => this.handleSelect(e, ALL_ITEM)}
                      >
                    Tất cả
                      </Button>
                      <span className={classes.allOrNoneDivider} />
                      <Button
                        className={classes.allOrNone}
                        onClick={e => this.handleSelect(e, NONE_ITEM)}
                      >
                    Bỏ chọn
                      </Button>
                    </div>
                  </div>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}
const styles = {
  table: {},
  allOrNoneWrapper: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    background: 'white',
    borderTop: '1px solid #e0e0e0',
    display: 'flex',
  },
  allOrNone: {
    width: '50%',
    fontSize: '30',
    borderRadius: 0,
    padding: '8px 16px',
    minHeight: '36px'
  },
  paper: {
    marginTop: 5,
    overflow: 'auto',
    zIndex: 1000,    
    minWidth: 200,
    maxHeight: 300,
  },
  menuList: {
    overflow: 'auto',
    marginBottom: 36,
    maxHeight: 300 - 36
  },

}
const enhance = compose(withStyles(styles), translate)
export default enhance(CheckboxMenu)
