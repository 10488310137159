import React, { Component } from 'react'
import compose from 'recompose/compose'
import { addField, ImageInput, TextInput } from 'react-admin'
import ImageBase64 from './ImageBase64'

const stringify = v => {
  if (typeof v === 'object') {
    return JSON.stringify(v, null, 2)
  }
  return v
}

class ValueInput extends Component {


  onChangeValue = (evt) => {
    let { input } = this.props
    let value = evt.target.value
    input = { ...input, value }
    input.onChange(value)
  }

  renderComponent = () => {
    let { type, input, ...props } = this.props
    switch (type) {
      case 'TEXT':
        return <TextInput
          source="value"
          value={input.value}
          onChange={this.onChangeValue}
          format={ v => { return stringify(v)} }
          multiline
          {...props}
        />
      case 'IMAGE':
        return <ImageInput source="bValue" {...props}>
          <ImageBase64 value={input.value} />
        </ImageInput>
      case 'JSON':
        return <TextInput
          source="value"
          value={input.value}
          format={ v => { return stringify(v)} }
          {...props}
          multiline
          onChange={this.onChangeValue}
        />
      default:
        return null
    }
  }

  render() {
    return this.renderComponent()
  }
}

const enhance = compose(addField)

export default enhance(ValueInput)
