import React, { Component } from 'react'
import {
  SimpleForm,
  addField,
  translate,
  showNotification,
  NumberInput,
  SelectInput,
  Title,
  ReferenceInput,
} from 'react-admin'
import _DatePicker from '../common/DatePicker'
import { CarSelectorBySchedule } from './CarSelectorBySchedule'
import { Provider } from '../provider'
import { parseUrl } from 'query-string'
import { createTripFromSchedule } from './actions'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
  Grid,
  Drawer,
  Avatar,
  ListItemText,
  withStyles,
  ListItem,
  Typography,
  CardContent,
  Divider,
} from '@material-ui/core'
import { DragDropContext, DragSource } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { multiSelectTo as multiSelect, findIndexById } from '../utils/mutiselect'
import { push } from 'react-router-redux'
import { changeBreadcrumb } from '../breadcrumb/action'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { indigo } from '@material-ui/core/colors'
import _ from 'lodash'
import { validate } from './validate'
import ConfirmDialog from '../common/ConfirmDialog'
import moment from 'moment'

const ItemTypes = {
  VEHICLE_COL: 'vehiclecol',
}

const DatePicker = addField(_DatePicker)

const styleVehicleDrawer = () => ({
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: '3em',
    width: 240,
  },
  chip: {
    margin: 8,
  },
})

const style = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  assignButton: {
    margin: 4
  },
  filterContainer: {
    display: 'block',
    width: '100%'
  }
})

function findVehicleById(vehicles, id) {
  let vehicle
  let vs = vehicles.filter(v => v.id === id)
  if (vs && vs.length > 0) {
    vehicle = vs[0]
  }
  return vehicle
}

function assignedAllBlock(blocks, selectedVehicles) {
  if (!blocks || (blocks && blocks.length === 0)) return false
  let arraySelectedVehicles = Object.values(selectedVehicles)
  if (blocks.length !== arraySelectedVehicles.length) return false
  return true
}


function isSwap(prevSelectedVehicles, selectedVehicles) {
  let prevValues, vals
  if (prevSelectedVehicles) {
    prevValues = Object.values(prevSelectedVehicles) || []
  }
  if (selectedVehicles) {
    vals = Object.values(selectedVehicles) || []
  }
  if (prevValues && vals) {
    if (prevValues.length !== vals.length) return false
    if (_.difference(prevValues, vals) && _.isEmpty(_.difference(prevValues, vals))) return true
  }
  return false
}

const chipSource = {
  beginDrag(props) {
    let { id, selectedVehicleIds, index } = props
    if (selectedVehicleIds.indexOf(id) !== -1) {
      return { selectedVehicleIds, index }
    }
    props.unselectAll()
    return { id, index }
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id
  },
  endDrag(props, monitor) {
    const { id, selectedVehicleIds, index } = monitor.getItem()
    const didDrop = monitor.didDrop()
    if (didDrop) {
      props.onMove(id, selectedVehicleIds, index)
    }
    props.unHover()
  }
}

class _DnDVehicle extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isSelected: false
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isSelected } = nextProps
    let { isSelected: currentIsSelected } = prevState
    if (isSelected !== currentIsSelected) {
      return { isSelected }
    }
    return { ...prevState }
  }

  render() {
    const {
      connectDragSource,
      isDragging,
      vehicle = {},
      onClick,
      onKeyDown,
    } = this.props
    let { isSelected } = this.state
    let opacity = isDragging ? 0 : 1
    const backgroundColor = isSelected ? indigo[900] : indigo[200]
    return connectDragSource(
      <div style={{ opacity }}>
        <ListItem
          divider
          selected={isSelected}
          onClick={(e) => onClick(e, vehicle.id)}
          onKeyDown={(e) => onKeyDown(e, {}, { ...{}, isDragging })}
        >
          <Avatar style={{ backgroundColor }}><FontAwesomeIcon icon={faBus} size="1x" /></Avatar>
          <ListItemText primary={vehicle.plate} secondary={vehicle.name} />
        </ListItem>
      </div>
    )
  }
}

const DndVehicle = DragSource(
  ItemTypes.VEHICLE_COL,
  chipSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  })
)(_DnDVehicle)

const VehiclesDrawer = withStyles(styleVehicleDrawer)(({
  classes,
  open,
  vehicles = [],
  onMove,
  onClose,
  onClick,
  onKeyDown,
  selectedVehicleIds = [],
  unselectAll,
  unHover,
  translate,
}) => (
  <Drawer
    className={classes.drawer}
    variant="persistent"
    anchor="right"
    open={open}
    onClose={() => onClose()}
    classes={{
      paper: classes.drawerPaper,
    }}
  >
    <CardContent>
      <Typography component="h2" variant="h6">
        {translate('resources.vehicles.name', { smart_count: 2 })}
      </Typography>
      <Typography component="h2" variant="subtitle1">
        {translate('resources.schedules.drawer_vehicle_desc')}
      </Typography>
    </CardContent>
    <Divider />
    {vehicles.map((vehicle, index) => {
      let { id } = vehicle
      let isSelected = selectedVehicleIds.indexOf(id) !== -1
      return (
        <DndVehicle
          id={id}
          index={index}
          classes={classes}
          key={index}
          vehicle={vehicle}
          onMove={onMove}
          onClick={onClick}
          onKeyDown={onKeyDown}
          isSelected={isSelected}
          selectedVehicleIds={selectedVehicleIds}
          unselectAll={unselectAll}
          unHover={unHover}
        />
      )
    })}
  </Drawer>
))

/**
 * This class represents the method to generate trips on the basis on schedules
 */
class CreateTripFromSchedule extends Component {
  state = {
    isOpenVehicleDrawer: true,
    prevSelectedVehicles: [],
    selectedVehicleIds: [],
    confirmDialog: {
      open: false,
      data: {},
    }
  }
  scheduleInput = React.createRef()

  async componentDidMount() {
    let { location } = this.props
    let url = parseUrl(location.search)
    if (!url || !url.query) return

    const getSchedule = async () => {
      let response = await Provider.dataProvider('GET_ONE', 'schedules', {
        id: url.query.scheduleId,
        filter: {
          include: {
            relation: 'timetableGroup',
            scope: {
              fields: ['routeGroupId'],
              include: {
                relation: 'routeGroup',
                scope: {
                  fields: ['id', 'name'],
                },
              },
            },
          },
        },
      })
      if (response) {
        let schedule = response.data
        this.setState({
          scheduleId: url.query.scheduleId,
          scheduleData: schedule.data
        })
        const { changeBreadcrumb, translate } = this.props
        const paths = [
          { label: translate('resources.routegroups.name', { smart_count: 2 }), to: '/routegroups' },
          { label: schedule.timetableGroup.routeGroup.name, to: `/routegroups/${schedule.timetableGroup.routeGroupId}` },
          {
            label: translate('resources.schedules.name', { smart_count: 2 }), to: `/schedules?filter=${JSON.stringify({
              filter: { timetableGroupId: schedule.timetableGroupId }
            })}`
          },
          { label: schedule.name, to: `/schedules/${schedule.id}` },
          { label: translate('button.create_trip'), to: '' },
        ]
        changeBreadcrumb({ paths })
      }
    }

    const getVehicles = async () => {
      let vehicles = await Provider.dataProvider('GET_LIST', 'vehicles', {
        filter: {},
        sort: {},
        pagination: { page: 1, perPage: 500, },
      })
      if (vehicles) {
        this.setState({
          vehicles: vehicles.data,
          dragVehicles: [...vehicles.data]
        })
      }
    }

    await Promise.all([getSchedule(), getVehicles()])
  }

  updateVehicleInDrawerRemoveVehicleFromTimeline = (removeId, removeIdx) => {
    if (!removeId || removeId === -1) return
    let { prevSelectedVehicles, vehicles, dragVehicles } = this.state
    delete prevSelectedVehicles[removeIdx]
    let removeVehicle = findVehicleById(vehicles, removeId)
    let idx = findIndexById(vehicles, removeId)
    dragVehicles.splice(idx, 0, removeVehicle)
    this.setState({
      prevSelectedVehicles: prevSelectedVehicles,
      dragVehicles: dragVehicles
    })
  }

  updateVehicleInDrawerAddVehicleToTimeline = (addVehicle) => {
    if (!addVehicle) return
    let { prevSelectedVehicles, dragVehicles } = this.state
    for (let addIdx in addVehicle) {
      prevSelectedVehicles[addIdx] = addVehicle[addIdx]
    }
    let vehicleIds = Object.values(addVehicle)
    _.remove(dragVehicles, v => vehicleIds.indexOf(v.id) !== -1)
    this.setState({
      prevSelectedVehicles: prevSelectedVehicles,
      dragVehicles
    })
  }

  unselectAll = () => {
    this.setState({
      selectedVehicleIds: [],
    })
  }

  moveVehicle = (
    id,
    index,
    dragVehicles,
    nextSelectedVehicles,
    swapSelectedVehicleContainer,
  ) => {
    let {
      vehicles,
      prevSelectedVehicles,
    } = this.state
    let preVehicle
    if (_.isEmpty(prevSelectedVehicles)) {
      // Initial prevSelectedVehicles
      prevSelectedVehicles = { ...nextSelectedVehicles }
    } else {
      // Update prevSelectedVehicles when swapping happen
      if (isSwap(prevSelectedVehicles, swapSelectedVehicleContainer)) {
        prevSelectedVehicles = { ...swapSelectedVehicleContainer }
      }

      // Get pos of vehicle in nextSelectedVehicles
      let positionOfNewSelectedVehicles = -1
      for (let pos in nextSelectedVehicles) {
        let vehicleId = nextSelectedVehicles[pos]
        if (vehicleId === id) {
          positionOfNewSelectedVehicles = pos
        }
      }

      // Add new vehicle to timeline table
      // - Swap with exists vehicle
      // - Add new vehicle
      if (positionOfNewSelectedVehicles !== -1) {
        let tempId = prevSelectedVehicles[positionOfNewSelectedVehicles]
        if (tempId) {
          preVehicle = findVehicleById(vehicles, tempId)
        }
        prevSelectedVehicles[positionOfNewSelectedVehicles] = id
      } else {
        preVehicle = findVehicleById(vehicles, id)
      }
    }
    if (preVehicle) {
      dragVehicles.splice(index, 0, preVehicle)
    }
    return { dragVehicles, prevSelectedVehicles }
  }

  onMove = (id, selectedVehicleIds, index) => {
    let { dragVehicles } = this.state
    let { scheduleInput } = this
    let ref = scheduleInput
    let result
    // get new SelectedVehicles in timeline table
    let nextSelectedVehicles = ref.current.getSelectedVehicles()
    // get SelectedVehicles when swapping happen in timeline table
    let swapSelectedVehicleContainer = ref.current.getSwapSelectedVehiclesContainer()
    if (_.isEmpty(nextSelectedVehicles)) return
    if (selectedVehicleIds) {
      let avaiableVals = Object.values(nextSelectedVehicles) || []
      for (let i = 0; i < avaiableVals.length; i++) {
        let selectedVehicleId = avaiableVals[i]
        let index = findIndexById(dragVehicles, selectedVehicleId)
        if (index !== -1) {
          dragVehicles.splice(index, 1)
          result = this.moveVehicle(
            selectedVehicleId,
            index,
            dragVehicles,
            nextSelectedVehicles,
            swapSelectedVehicleContainer,
          )
        }
      }
      // Get vehicle overload
      selectedVehicleIds = _.difference(selectedVehicleIds, avaiableVals)
    } else {
      dragVehicles.splice(index, 1)
      result = this.moveVehicle(
        id,
        index,
        dragVehicles,
        nextSelectedVehicles,
        swapSelectedVehicleContainer,
      )
    }
    this.setState({ ...result, selectedVehicleIds })
  }

  openVehicleDrawer = () => {
    let { isOpenVehicleDrawer } = this.state
    this.setState({
      isOpenVehicleDrawer: !isOpenVehicleDrawer
    })
  }

  closeVehicleDrawer = () => {
    this.setState({
      isOpenVehicleDrawer: false
    })
  }

  toggleSelection = (vehicleId) => {
    const selectedVehicleIds = this.state.selectedVehicleIds || []
    const wasSelected = selectedVehicleIds.includes(vehicleId)
    let newVehicleIds = []
    if (!wasSelected) {
      newVehicleIds = [vehicleId]
    }
    if (selectedVehicleIds.length > 1) {
      newVehicleIds = [vehicleId]
    }
    this.setState({
      selectedVehicleIds: newVehicleIds || [],
    })
  }

  toggleSelectionInGroup = (vehicleId) => {
    const selectedVehicleIds = this.state.selectedVehicleIds
    const index = selectedVehicleIds.indexOf(vehicleId)

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        selectedVehicleIds: [...selectedVehicleIds, vehicleId] || [],
      })
      return
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedVehicleIds]
    shallow.splice(index, 1)
    this.setState({
      selectedTaskIds: shallow,
    })
  }

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = (newVehicleId) => {
    let { dragVehicles, selectedVehicleIds } = this.state
    const updated = multiSelect(dragVehicles, selectedVehicleIds, newVehicleId)

    if (updated == null) {
      return
    }

    this.setState({
      selectedVehicleIds: updated || [],
    })
  }

  onKeyDown = (event, provided, snapshot) => {
    if (provided.dragHandleProps) {
      provided.dragHandleProps.onKeyDown(event)
    }

    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.keyCode !== 'enter') {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    this.performAction(event)
  }

  onClick = (event, vehicleId) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== 0) {
      return
    }

    // marking the event as used
    event.preventDefault()

    this.performAction(event, vehicleId)
  }

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = (event) => event.shiftKey

  performAction = (event, vehicleId) => {
    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      this.toggleSelectionInGroup(vehicleId)
      return
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      this.multiSelectTo(vehicleId)
      return
    }

    this.toggleSelection(vehicleId)
  }

  onSave = async record => {
    let { startDate: fromDate, endDate: toDate, recurType, every } = record
    let { scheduleId } = this.state
    scheduleId = +scheduleId
    let { push, showNotification } = this.props
    let { scheduleInput } = this
    let current = scheduleInput.current || {}
    let selectedVehicles = !_.isEmpty(current) ? current.getSelectedVehicles() : []
    let vehicleIds = _.reduce(
      Object.values(selectedVehicles),
      (result, selectedVehicle) => {
        if (selectedVehicle && selectedVehicle !== null) {
          result.push(selectedVehicle)
        }
        return result
      }, [])
    Provider.dataProvider('REMOTE', 'schedules', {
      data: { scheduleId, vehicleIds, fromDate, toDate, recurType, every },
      method: 'createTrip',
      requestMethod: 'POST',
    }).then(() => {
      let startDate = moment(fromDate).startOf('day').toISOString()
      let endDate = moment(toDate).endOf('day').toISOString()
      push(`/trips?scheduleId=${scheduleId}&startDate=${startDate}&endDate=${endDate}`)
      showNotification('notification.create_trip_success')
    }).catch(error => {
      this.handleCloseConfirm()
      let { invalidVehicleIds } = error
      if (invalidVehicleIds) {
        let invalidVehicles = invalidVehicleIds.split(',')
        this.setState({ invalidVehicles })
      }
      showNotification('notification.create_trip_failure', 'warning')
    })
  }

  onValidate(values, props) {
    let { startDate, endDate } = values
    if (startDate && endDate) {
      let mStartDate = moment(startDate)
      let mEndDate = moment(endDate)
      if (mStartDate.format('YYYYMMDD') >= mEndDate.format('YYYYMMDD')) {
        values.endDate = mStartDate.toDate()
      }
    }
    return validate(values, props)
  }

  handleOpenConfirm = record => {
    let { scheduleInput } = this
    let current = scheduleInput.current || {}
    let blocks = !_.isEmpty(current) ? current.getAllBlocks() : []
    let selectedVehicles = !_.isEmpty(current) ? current.getSelectedVehicles() : []
    let isAssingedAll = assignedAllBlock(blocks, selectedVehicles)
    if (!isAssingedAll) {
      this.setState({
        confirmDialog: {
          open: true,
          data: record,
        }
      })
      return
    } else {
      this.onSave(record)
    }
  }

  handleCloseConfirm = () => {
    this.setState({
      confirmDialog: {
        open: false,
        data: {},
      }
    })
  }

  render() {
    let {
      isOpenVehicleDrawer,
      vehicles,
      scheduleData,
      dragVehicles,
      selectedVehicleIds,
      confirmDialog,
      invalidVehicles,
    } = this.state
    const { classes, translate } = this.props
    let { open, data } = confirmDialog
    let { scheduleInput } = this
    let current = scheduleInput.current || {}
    return (
      <div className={classes.root}>
        <Title title={translate('button.create_trip')} />
        <SimpleForm
          save={this.handleOpenConfirm}
          validate={this.onValidate}
        >
          <Grid
            className={classes.filterContainer}
            container
            spacing={8}
          >
            <Grid item xs={10} md={5}>
              <DatePicker
                label={translate('resources.schedules.fields.startDate')}
                source="startDate"
                dateFormat="DD/MM/YYYY"
                showLunarDate={true}
                keyboard={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <DatePicker
                label={translate('resources.schedules.fields.endDate')}
                source="endDate"
                dateFormat="DD/MM/YYYY"
                showLunarDate={true}
                keyboard={true}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={10} md={5}>
              <NumberInput
                label={translate('resources.schedules.fields.every')}
                source="every"
                fullWidth
                inputProps={{
                  min: 1
                }}
                defaultValue={1}
              />
            </Grid>
            <Grid item xs={10} md={5}>
              <ReferenceInput
                basePath="/frequencyunits"
                reference="frequencyunits"
                label={translate('resources.schedules.fields.recurType')}
                source="recurType"
                defaultValue="days"
                fullWidth
              >
                <SelectInput disabled optionText="name" />
              </ReferenceInput>
            </Grid>
          </Grid>
          {/* <CheckboxInput label="Gán xe sau" /> */}
          <Grid container xs={12} md={12}>
            {scheduleData && <CarSelectorBySchedule
              forwardRef={this.scheduleInput}
              value={scheduleData}
              vehicles={vehicles}
              updateVehicleInDrawer={this.updateVehicleInDrawerRemoveVehicleFromTimeline}
              updateVehicleInDrawerAddVehicleToTimeline={this.updateVehicleInDrawerAddVehicleToTimeline}
              invalidVehicles={invalidVehicles}
            />
            }
          </Grid>
        </SimpleForm>
        <VehiclesDrawer
          open={isOpenVehicleDrawer}
          vehicles={dragVehicles}
          onMove={this.onMove}
          onClose={this.closeVehicleDrawer}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          selectedVehicleIds={selectedVehicleIds}
          unselectAll={this.unselectAll}
          unHover={current.unHover}
          translate={translate}
        />
        <ConfirmDialog
          title={translate('resources.schedules.create_trip_confirm_title')}
          content={translate('resources.schedules.create_trip_unassign_vehicle')}
          open={open}
          data={data}
          onClose={this.handleCloseConfirm}
          onOk={this.onSave}
        />
      </div>
    )
  }
}

const enhance = compose(
  DragDropContext(HTML5Backend),
  withStyles(style),
  translate,
  connect(null, {
    createTripFromSchedule,
    push,
    changeBreadcrumb,
    showNotification,
  })
)

export default enhance(CreateTripFromSchedule)
