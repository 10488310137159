import React, { Component, Fragment } from 'react'
import compose from 'recompose/compose'
import {
  withStyles,
  Grid,
  Card,
  Typography,
  IconButton,
  Collapse,
  CardContent,
} from '@material-ui/core'
import { translate } from 'react-admin'
import { getColor } from '../utils/color'
import {
  faMoneyBillWave,
  faTicketAlt,
} from '@fortawesome/free-solid-svg-icons'
import CardIcon from '../common/CardIcon'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import { getCurrentCompany } from '../utils/commonUtil'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PieChart from '../common/PieChart'
import { format } from '../utils/number'
import _ from 'lodash'
import { reservationMapping, colorMapping, nivoColors, CONFIRMED } from '../common/reservation-status'
import clsx from 'clsx'
import { green, blue, blueGrey } from '@material-ui/core/colors'

const PIE_DATA_BY_REVENUE = 'revenue'
const PIE_DATA_BY_QUANTITY = 'quantity'
const PIE_DATA_BY_TOTAL_QUANTITY = 'total_quantity'

const titleMapping = {
  'number_reservedSeat_time': 'resources.reservations.report.number_reservedSeat_time',
  'number_revenue_time': 'resources.reservations.report.number_revenue_time',
  'number_ticket_total': 'resources.reservations.report.number_ticket_total',
}

const pieChartConfig = {
  margin: { top: 0, right: 20, bottom: 150, left: 20 },
  innerRadius: 0.5,
  padAngle: 1,
  cornerRadius: 3,
  borderWidth: 1,
  borderColor: 'inherit:darker(0.2)',
  enableRadialLabels: false,
  radialLabelsSkipAngle: 10,
  radialLabelsTextXOffset: 10,
  radialLabelsTextColor: '#333333',
  radialLabelsLinkOffset: 3,
  radialLabelsLinkDiagonalLength: 10,
  radialLabelsLinkHorizontalLength: 10,
  radialLabelsLinkStrokeWidth: 1,
  radialLabelsLinkColor: 'inherit:darker(0.2)',
  slicesLabelsSkipAngle: 10,
  slicesLabelsTextColor: '#333333',
  animate: true,
  motionStiffness: 90,
  motionDamping: 15
}

const styles = (theme) => {
  let { unit } = theme.spacing
  return {
    swipeContainer: {
      width: 400,
    },
    gridRoot: {
      marginLeft: -2 * unit,
      marginRight: -2 * unit,
    },
  }
}

const cardStyles = theme => ({
  main: {
    flex: '1',
    marginTop: 20,
    cursor: 'pointer',
  },
  card: {
    overflow: 'inherit',
    textAlign: 'right',
    padding: '16px 16px 12px 16px',
    minHeight: 52,
  },
  title: {
    minHeight: '3em',
  },
  cardAction: {
    justifyContent: 'flex-end',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  expandContent: {
    padding: 0,
  },
  chartContainer: {
    height: 300
  },
  chartTitle: {
    textAlign: 'start',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#9e9e9e',
    marginBottom: 8,
  },
  emptyRecord: {
    textAlign: 'center',
    fontSize: 14,
    color: '#9e9e9e',
    fontStyle: 'italic',
    marginTop: 16,
  },
})

class _PeriodSumary extends Component {

  state = {
    expanded: false,
  }

  onExpand = () => {
    let { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    let { 
      classes,
      title,
      icon,
      bgColor,
      value,
      type,
      translate,
      moreInfo,
      pieData,
      unit
    } = this.props

    let { expanded } = this.state
    let { component: componentMoreInfo, props: propsMoreInfo, chartTitle } = moreInfo
    let dataChart = pieData[type]
    propsMoreInfo = { ...propsMoreInfo, data: dataChart }
    return <div className={classes.main}>
      <CardIcon icon={icon} bgColor={bgColor} size="4x" />
      <Card className={classes.card}>
        <Typography className={classes.title} color="textSecondary">
          {translate(title)} {unit ? `(${unit})` : ''}
        </Typography>
        <Typography variant="h3" gutterBottom>
          {value}
        </Typography>
        <div className={classes.cardAction}>
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={() => {
              this.onExpand()
            }}
          >
            <ExpandMoreIcon />
          </IconButton>
        </div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.expandContent}>
            <div className={classes.chartTitle}>{chartTitle}</div>
            {!_.isEmpty(dataChart) ? <div className={classes.chartContainer}>
              {React.createElement(componentMoreInfo, {...propsMoreInfo})}
            </div> : <div className={classes.emptyRecord}>{translate('resources.reservations.report.empty_record')}</div>}
          </CardContent>
        </Collapse>
      </Card>
    </div>
  }
}

const enhancePeriodSumary = compose(
  withStyles(cardStyles),
  translate
)

const PeriodSummary =  enhancePeriodSumary(_PeriodSumary)

const REPORT_REVENUE_NAME = 'number_revenue_time'
const REPORT_AMOUNT_NAME = 'number_reservedSeat_time'
const REPORT_RESERVED_BY_FARE = 'table_productid-product-fareid-fare-status-revenue-quantity_time'

const normalizeFilter = (filter) => {
  let company = getCurrentCompany()
  if (!company) return null
  let companyId = company.id
  let { createdAt_gte: fromDate, createdAt_lte: toDate, ...rest } = filter
  let result = { 
    companyId,
    fromDate, 
    toDate,
    ...rest
  }
  return result
}

function findColor(colorMapping, id) {
  return colorMapping[id]
}

function getTicketByStatus(rawDatum) {
  let dataByStatus = _.groupBy(rawDatum, 'status')
  let sum = _.sumBy(rawDatum, ele => ele.quantity)
  let number = { name: 'number_ticket_total', data: sum }
  let objectMapping = {}
  for (let status in dataByStatus) {
    let datum = dataByStatus[status]
    objectMapping[status] = _.sumBy(datum, ele => ele.quantity)
  }
  return { number, objectMapping }
}

function normalizePieChartDataByStatus(rawData) {
  let result = []
  for (let status in rawData) {
    let value = rawData[status]
    result.push({
      id: status,
      label: status,
      value,
      color: colorMapping[status],
    })
  }
  return result
}

function normalizePieChartData(rawDatum, source) {
  let colorMapping = {}
  rawDatum = _.filter(rawDatum, ele => ele.status === CONFIRMED)
  let pieChartDatum = rawDatum.map((rawData, idx) => {
    let { productid, product, fare, fareid, status, revenue, quantity } = rawData
    let colorId = `${productid}-${fareid}` 
    let color = findColor(colorMapping, colorId)
    if (!color) {
      color = getColor(idx)
      colorMapping[colorId] = color
    }
    return {
      id: `${productid}-${fareid}`,
      label: `${product}-${fare}`,
      product,
      status,
      fare,
      value: rawData[source],
      revenue,
      quantity,
      color: color,
    }
  })
  return pieChartDatum
}

class GeneralReport extends Component {

  state = {
    pieDataBy: PIE_DATA_BY_REVENUE,
  }

  getMeasure = async (name, filter, option) => {
    try {
      let resp = await Provider.dataProvider('REMOTE', 'analytics', {
        method: 'findByName',
        data: { name, ...option, ...filter }
      })
      if (resp && resp.data) return resp.data
    } catch (e) {
      console.log('Error', e)
    }
  }

  getRevenue = async (filter) => {
    let data = await this.getMeasure(REPORT_REVENUE_NAME, filter, { type: 'numeric' })
    if (data) {
      let { name, title } = data
      data = { ...data, title: name ? titleMapping[name] : title }
    }
    return data
  }

  getAmount = async (filter) => {
    let data = await this.getMeasure(REPORT_AMOUNT_NAME, filter, { type: 'numeric' })
    if (data) {
      let { name, title } = data
      data = { ...data, title: name ? titleMapping[name] : title }
    }
    return data
  }

  getReservationsByFare = async (filter) => {
    let resp = await this.getMeasure(REPORT_RESERVED_BY_FARE, filter, { type: 'pieChart', transformTo: 'nivo' })
    let result = {}
    if (resp) {
      let { data: rawPieData } = resp
      let cardTotalData = getTicketByStatus(rawPieData)
      result = { rawPieData, cardTotalData }
    }
    return result
  }

  getReport = async (filter) => {
    filter = normalizeFilter(filter)
    if (!filter) return
    let revenue = await this.getRevenue(filter)
    let amount = await this.getAmount(filter)
    let { rawPieData, cardTotalData = {} } = await this.getReservationsByFare(filter)
    let { number: numberCardData = {} , objectMapping: cardMapping } = cardTotalData
    let { name } = numberCardData
    numberCardData = { ...numberCardData, title: titleMapping[name] }
    this.setState({ revenue, amount, rawPieData, numberCardData, cardMapping },
      () => {
        this.loadAllChart()
      }
    )
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { filter, version } = this.props
    let { filter: prevFilter, version: prevVersion } = prevProps
    const updated = !(prevFilter === filter && version === prevVersion)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { filter } = this.props
      let datum = filter && await this.getReport(filter)
      this.setState({ datum })
    }
  }

  loadAllChart = () => {
    let { rawPieData, pieData, cardMapping } = this.state
    let pieDataTotal = normalizePieChartDataByStatus(cardMapping) 
    let pieDataRevenue = normalizePieChartData(rawPieData, PIE_DATA_BY_REVENUE)
    let pieDataQuantity = normalizePieChartData(rawPieData, PIE_DATA_BY_QUANTITY)
    pieData = { 
      [PIE_DATA_BY_REVENUE]: pieDataRevenue,
      [PIE_DATA_BY_QUANTITY]: pieDataQuantity,
      [PIE_DATA_BY_TOTAL_QUANTITY]: pieDataTotal,
    }
    this.setState({ pieData })
  }

  changePieDataBy = (pieDataBy) => {
    let { rawPieData, pieData, pieDataBy: currentPieDataBy, cardMapping } = this.state
    let pieChartData
    if (pieDataBy !== currentPieDataBy) {
      if (pieDataBy === PIE_DATA_BY_TOTAL_QUANTITY) {
        pieChartData = normalizePieChartDataByStatus(cardMapping)
      } else {
        pieChartData = normalizePieChartData(rawPieData, pieDataBy)
      }

      pieData = { ...pieData, [pieDataBy]: pieChartData }

      this.setState({ pieData, pieDataBy })
    }
  }

  renderTooltipForRevenue = record => {
    let { revenue } = this.state
    let { product, fare, value } = record
    let { data: total } = revenue
    let percent = Math.round(value * 100 / total, 2)
    return <span>{`${product}-${fare}`}:&nbsp;<b>{percent}%</b></span>
  }

  renderTooltipForQuantity = record => {
    let { product, fare, value } = record
    return <span>{`${product}-${fare}`}:&nbsp;<b>{value}</b></span>
  }

  renderTooltipForTotalChart = record => {
    let { value, id } = record
    let { translate } = this.props
    return <span>{translate(reservationMapping[id])}:&nbsp;<b>{value}</b></span>
  }

  renderSliceLabelForTotalChart = record => {
    let { value, id } = record
    let { translate } = this.props
    return <Fragment>
      <tspan x="0" dy="-0.6em">{translate(reservationMapping[id])}</tspan>
      <tspan x="0" dy="1.2em">{value}</tspan>
    </Fragment>
  }

  renderSliceLabelForRevenue = record => {
    let { revenue } = this.state
    let { value } = record
    let { data: total } = revenue
    let percent = Math.round(value * 100 / total, 2)
    return <Fragment>
      <tspan x="0" dy="1.8em">{`${percent}%`}</tspan>
    </Fragment>
  }

  renderSliceLabelForQuantity = record => {
    let { value } = record
    return <Fragment>
      <tspan x="0" dy="1.8em">{value}</tspan>
    </Fragment>
  }

  render() {
    let { classes, translate } = this.props
    let { revenue = {}, amount = {}, pieData = {}, numberCardData = {}, pieDataBy } = this.state
    let { title: revenueTitle, data: revenueValue } = revenue
    let { title: cardTotalTitle, data: cardTotalValue } = numberCardData
    let { title: amountTitle, data: amountValue } = amount
    return <div className={classes.gridRoot}>
      <Grid container spacing={0}>
        <Grid item xs md={4}>
          <PeriodSummary
            selected={pieDataBy === PIE_DATA_BY_REVENUE}
            type={PIE_DATA_BY_REVENUE}
            onClick={this.changePieDataBy}
            title={revenueTitle}
            bgColor={blue[500]}
            icon={faMoneyBillWave}
            size="2x"
            unit="VND"
            pieData={pieData}
            moreInfo={{
              component: PieChart,
              chartTitle: translate('resources.reservations.report.revenue_chart_title'),
              props: {
                height: 450,
                sliceLabel: this.renderSliceLabelForRevenue,
                tooltip: this.renderTooltipForRevenue,
                option: { 
                  ...pieChartConfig,
                  colors: 'nivo',
                  colorBy: 'color',
                }
              }
            }}
            value={(revenueValue && revenueValue !== null) ? format(revenueValue).toString().toUpperCase() : '0'}
          />
        </Grid>
        <Grid item xs md={4}>
          <PeriodSummary
            selected={pieDataBy === PIE_DATA_BY_QUANTITY}
            type={PIE_DATA_BY_QUANTITY}
            onClick={this.changePieDataBy}
            title={amountTitle}
            bgColor={green[500]}
            icon={faTicketAlt}
            size="2x"
            pieData={pieData}
            moreInfo={{
              component: PieChart,
              chartTitle: translate('resources.reservations.report.quantity_chart_title'),
              props: {
                height: 450,
                sliceLabel: this.renderSliceLabelForQuantity,
                tooltip: this.renderTooltipForQuantity,
                option: { 
                  ...pieChartConfig,
                  colors: 'nivo',
                  colorBy: 'color',
                }
              }
            }}
            value={(amountValue && amountValue !== null) ? amountValue : '0'}
          />
        </Grid>
        <Grid item xs md={4}>
          <PeriodSummary
            selected={pieDataBy === PIE_DATA_BY_TOTAL_QUANTITY}
            type={PIE_DATA_BY_TOTAL_QUANTITY}
            onClick={this.changePieDataBy}
            title={cardTotalTitle}
            bgColor={blueGrey[500]}
            icon={faTicketAlt}
            size="2x"
            pieData={pieData}
            moreInfo={{
              component: PieChart,
              chartTitle: translate('resources.reservations.report.total_chart_title'),
              props: {
                height: 450,
                sliceLabel: this.renderSliceLabelForTotalChart,
                tooltip: this.renderTooltipForTotalChart,
                option: { 
                  ...pieChartConfig,
                  colors: nivoColors,
                  colorBy: (d)  => d.color,
                }
              }
            }}
            value={(cardTotalValue && cardTotalValue !== null) ? cardTotalValue : '0'}
          />
        </Grid>
      </Grid>
    </div>
  }
}

const mapStateToProps = (state, props) => {
  let objectFilter = state.form['filterForm'] || {}
  let filter = objectFilter.values
  return { ...props, filter }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(mapStateToProps)
)

export default enhance(GeneralReport)
