import React, { Component } from 'react'
import NumberFormat from 'react-number-format'
import { translate, addField } from 'react-admin'
import compose from 'recompose/compose'
import { TextField, withStyles } from '@material-ui/core'
import _ from 'lodash'
import PropTypes from 'prop-types'

class _MoneyInput extends Component {

  render() {
    let { inputRef, options, value = 0, onChange, suffix, ...props } = this.props
    return (
      <NumberFormat
        {...props}
        value={value}
        getInputRef={inputRef}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          })
        }}
        thousandSeparator="."
        decimalSeparator=","
        suffix={suffix || 'đ'}
      />
    )
  }

}

export default _MoneyInput

const styles = {
  input: {
    marginTop: 16,
    marginBottom: 8,
  }
}

const _PaymentInput = ({ 
  input,
  disabled,
  translate,
  nonecss,
  meta,
  classes,
  label,
  helperText,
  required,
  autoFocus,
  suffix,
}) => {
  let { value } = input
  let { error } = meta
  value = !value || (typeof value === 'string' && _.isEmpty(value)) ? 0 : value
  return <TextField
    autoFocus={autoFocus}
    className={!nonecss && classes.input}
    label={translate(label)}
    helperText={error ? error : helperText}
    inputProps={{
      suffix
    }}
    InputProps={{
      inputComponent: _MoneyInput,
    }}
    error={!!error}
    value={value}
    disabled={disabled}
    InputLabelProps={{ shrink: true, suffix }}
    onChange={(e) => {
      let val = parseInt(e.target.value)
      input.onChange(val)
    }}
    required={required}
    suffix={suffix}
    fullWidth
  />
}

_PaymentInput.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  meta: PropTypes.object,
  translate: PropTypes.func,
  input: PropTypes.object,
  classes: PropTypes.object,
  nonecss: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  suffix: PropTypes.string,
}

const enhanceField = compose(
  withStyles(styles),
  translate,
  addField,
)
export const MoneyInput = enhanceField(_PaymentInput)
