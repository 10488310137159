import React, { Component } from 'react'
import { 
  TextInput, 
  ImageInput, 
  ReferenceInput, 
  SelectInput, 
  translate,
  Responsive,
  required,
  minLength,
  maxLength
} from 'react-admin'
import { Grid, withStyles, Typography } from '@material-ui/core'
import { DatePickerInput } from '../common/DatePicker'
import PreviewFile from '../common/PreviewFile'
import PreviewListener from '../common/PreviewListener'
import compose from 'recompose/compose'

const style = () => ({
  validInput: {
    padding: 0
  },
  licences: {
    marginBottom: 32
  },
  cardRight: {
    padding: 32
  },
})

const validateFullName = [required(), minLength(6), maxLength(24)]

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgUrl: ''
    }
    this.onDropAvatar = this.onDropAvatar.bind(this)
  }
  onDropAvatar = imgUrl => { 
    this.setState({
      imgUrl: imgUrl
    })
  }
  render() {
    const { translate, classes, id } = this.props 
    const { imgUrl } = this.state
    const extra = {
      resource: 'assistantdrivers',
      fullWidth: true,
    }
    return (
      <Responsive
        xsmall={
          <Grid container>
            <Grid container>
              <Grid>
                <ImageInput source="avatar" accept="image/*" {...extra}>
                  <PreviewListener source="src" onDrop={this.onDropAvatar} />
                </ImageInput>
              </Grid>
              <Grid>
                <PreviewFile imgUrl={imgUrl} />
              </Grid>
              <TextInput source="fullName" {...extra} validate={validateFullName} />
              <TextInput source="phone" {...extra} />
              <TextInput source="email" {...extra} />
              <DatePickerInput
                keyboard={true}
                source="birthday"
                {...extra}
              />
              <TextInput source="address" {...extra} />
              <TextInput source="desc" {...extra} />
              <ReferenceInput
                basePath="/driverstatuses"
                reference="driverstatuses"
                source="status"
                resource="driverstatuses"
                defaultValue="10ACT"
                {...extra}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
            {id && <Grid className={classes.cardRight}>
              <div className={classes.licences}>
              </div>
              <div>
                <Typography variant="h5" component="div">
                  {translate('resources.vehicles.activity')}
                </Typography>
              </div>
            </Grid>}
          </Grid>}
        medium={
          <Grid container>
            <Grid container item md={6} xs={6}>
              <Grid item md={6} xs={6}>
                <ImageInput source="avatar" accept="image/*" {...extra}>
                  <PreviewListener source="src" onDrop={this.onDropAvatar} />
                </ImageInput>
              </Grid>
              <Grid item md={6} xs={6}>
                <PreviewFile imgUrl={imgUrl} />
              </Grid>
              <TextInput source="fullName" {...extra} validate={validateFullName} />
              <TextInput source="phone" {...extra} />
              <TextInput source="email" {...extra} />
              <DatePickerInput
                keyboard={true}
                source="birthday"
                {...extra}
              />
              <TextInput source="address" {...extra} />
              <TextInput source="desc" {...extra} />
              <ReferenceInput
                basePath="/driverstatuses"
                reference="driverstatuses"
                source="status"
                resource="driverstatuses"
                defaultValue="10ACT"
                {...extra}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
            </Grid>
            {id && <Grid className={classes.cardRight} item md={6} xs={6}>
              <div className={classes.licences}>
              </div>
              <div>
                <Typography variant="h4" component="div">
                  {translate('resources.vehicles.activity')}
                </Typography>
              </div>
            </Grid>}
          </Grid>}
      />
    )
  }
}

const enhance = compose(withStyles(style), translate)

export default enhance(FormSave)
