import _ from 'lodash'
import { Block, DescriptionBlock } from './Block'

/*
[
  {
    "patternId": 2,
    "pattern": [
      { id: 1},
      { id: 2},
      { id: 3},
      { id: 4},
      { id: 5},
    ],
    "data": [
      {
        "1": 0,
        "2": 8,
        "3": 5,
        "4": 8,
        "5": 8,
        "index": "-"
      },
      {
        "1": {
          "index": 1,
          "value": "09:00",
          "base": 0,
          "step": 0
        },
        "2": {
          "index": 2,
          "value": "09:08",
          "base": 8,
          "step": 8
        },
        "3": {
          "index": 3,
          "value": "09:13",
          "base": 5,
          "step": 5
        },
        "4": {
          "index": 4,
          "value": "09:21",
          "base": 8,
          "step": 8
        },
        "5": {
          "index": 5,
          "value": "09:29",
          "base": 8,
          "step": 8
        },
        "index": 0
      },
*/
export class Schedule {
  blocks = []

  constructor(timetables, serviceId) {
    let blocks = []
    timetables.forEach(timetable => {
      if (timetable.data.interval.service !== serviceId) return
      if (!timetable.pattern) return
      let stopIds = timetable.pattern.stops.map(stop => stop.id)
      let stopNames = timetable.pattern.stops.map(stop => stop.name)
      let patternId = timetable.patternId

      timetable.data.rows.forEach((timetable, index) => {
        if (index === 0) return

        let times = []
        for (let key in timetable) {
          let value = timetable[key].value
          if (value) {
            times.push(value)
          }
        }
        blocks.push(new Block(stopIds, timetable, patternId, new DescriptionBlock(stopNames, times)))

      })
    })
    this.blocks = blocks
  }

  assignBlock(vehicleNum) {
    let blocks = _.sortBy(this.blocks, 'min')
    let lastStop = blocks[blocks.length - 1].lastStop
    return {
      id: parseInt(vehicleNum),
      lastStopId: lastStop.stop,
      lastStopMin: lastStop.min,
      blocks: blocks,
    }
  }

  autoBlock = () => {
    let cars = []
    this.blocks = _.sortBy(this.blocks, 'min')

    for (var i = 0; i < this.blocks.length; i++) {
      // find most suitable car
      // TODO: add min time between 2 blocks
      let block = this.blocks[i]
      let { firstStop : stop = {}, lastStop = {} } = block
      let minCar = _.filter(cars, { lastStopId: stop.stop })
      minCar = _.minBy(minCar, car => block.min >= car.lastStopMin ? block.min - car.lastStopMin : 1e4)
      if (minCar && block.min >= minCar.lastStopMin) {
        // if found
        let car = minCar

        // mark this block as done
        car.blocks.push(block)
        car.lastStopId = lastStop.stop
        car.lastStopMin = lastStop.min
      } else {
        // not found, need find nearest block by time
        let car = {
          id: cars.length,
          lastStopId: lastStop.stop,
          lastStopMin: lastStop.min,
          blocks: [block],
        }
        cars.push(car)
      }
    }
    this.cars = cars
  }

  /**
   * Auto interlining blocks
   */
  autoBlock2 = () => {
    let cars = []
    this.blocks = _.sortBy(this.blocks, 'min')

    for (var i = 0; i < this.blocks.length; i++) {
      // find most suitable car
      // TODO: add min time between 2 blocks
      let block = this.blocks[i]
      let { firstStop : stop = {}, lastStop = {} } = block
      let distance = lastStop.min - stop.min
      let stopId = stop.stop

      const offset = 30
      let minCar = _.filter(cars, car => block.min >= car.lastStopMin + (car.lastStopId !== stopId ? distance : offset))
      minCar = _.minBy(minCar, car => (car.lastStopId !== stop.stop ? distance : offset) + block.min - car.lastStopMin)
      if (minCar) {
        // if found
        let car = minCar

        // mark this block as done
        car.blocks.push(block)
        car.lastStopId = lastStop.stop
        car.lastStopMin = lastStop.min
      } else {
        // not found, need find nearest block by time
        let car = {
          id: cars.length,
          lastStopId: lastStop.stop,
          lastStopMin: lastStop.min,
          blocks: [block],
        }
        cars.push(car)
      }
    }
    this.cars = cars
  }

  /**
   * 
   * @param {*} blocks array of blocks
   * block = { number: 1000, trips: [{patternId: 2, min: 285}, {patternId: 1, min: 485}] }
   */
  importBlocks(blocks) {
    let cars = []
    this.blocks = _.sortBy(this.blocks, 'min')
    const len = blocks.length
    
    for (let idx = 0; idx < len; idx++) {
      let block = blocks[idx]
      let { idx: vehicleIdx, trips } = block

      let vehicle = { id: vehicleIdx, lastStopId: 0, lastStopMin: 0, blocks: [] }
      let tripLen = trips.length

      for (let n = 0; n < tripLen; n++) {
        let trip = trips[n]
        let matchBlock = _.find(this.blocks, function(item) {
          return trip.min === item.min && trip.patternId === item.patternId
        })
        if (matchBlock) {
          vehicle.lastStopId = matchBlock.lastStop.stop
          vehicle.lastStopMin = matchBlock.lastStop.min
          vehicle.blocks.push(matchBlock)
        }
      }
      cars.push(vehicle)
    }
    this.cars = cars
  }
}
