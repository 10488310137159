
export function validate(values, props) {
  let errors = {}
  let { translate } = props
  
  // Validate name
  let name = (values && values.name) && values.name.trim()
  let isSpace
  if (!name || name.length === 0) {
    errors.name = translate('error_messages.required.tags.name')
  } else {
    isSpace = name.split(' ').length > 1
  }
  if (isSpace) {
    errors.name = translate('error_messages.required.tags.space')
  }

  return errors
}
