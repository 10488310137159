import React from 'react'
import { readXlsx } from './xlsxUtil'
import { Provider } from '../provider'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { translate, showNotification, refreshView } from 'react-admin'
import PublishIcon from '@material-ui/icons/Publish'

const _ImportSaveButton = (props) => {
  let onHandleImport = async event => {
    const { showNotification, refreshView  } = props
    const file = event.target.files[0]
    const fileXlsx = await readXlsx(file)
    let { codesSecondFloor, codesFistFloor, name, privateCode } = fileXlsx
    if ((!codesFistFloor && !codesSecondFloor) || !name){
      showNotification('notification.save_seatmaps_fail')
      return
    }
    let importRecord = {
      name,
      codesFistFloor,
      codesSecondFloor,
      privateCode
    }
    Provider.dataProvider('CREATE', 'seatmaps', {
      data: importRecord
    }).then(() => {
      showNotification('notification.save_seatmaps_sucess')
      refreshView()
    }).catch(() => {
      showNotification('notification.save_seatmaps_fail')
    })
  }
  return (
    <Button
      component="label"
      color='primary'
      style={{ fontSize: '0.6964285714285714rem' }}
    >
      <PublishIcon style={{ fontSize: '20px' }} />
      <span style={{ paddingLeft: '0.5em' }}>{props.translate('button.import')}</span>
      <input
        type="file"
        style={{ display: 'none' }}
        accept='.xlsx'
        onChange={onHandleImport}
        onClick={event => {
          event.target.value = null
        }}
      />
    </Button>
  )
}

const ImportSaveButton = compose(
  translate,
  connect(null, { showNotification, refreshView })
)(_ImportSaveButton)

export default ImportSaveButton