import { take, put, race, fork } from 'redux-saga/effects'
import {
  DELETE_REMINDER,
  FETCH_DELETE_REMINDER_SUCCESS,
  FETCH_DELETE_REMINDER_FAILURE,
  MAKE_DONE_REMINDER,
  FETCH_MAKE_DONE_REMINDER,
  FETCH_MAKE_DONE_REMINDER_SUCCESS,
  FETCH_MAKE_DONE_REMINDER_FAILURE,
  RESTORE_REMINDER,
  FETCH_RESTORE_REMINDER,
  FETCH_RESTORE_REMINDER_SUCCESS,
  FETCH_RESTORE_REMINDER_FAILURE,
  fetchDeleteReminder as fetchDeleteReminderAction,
  doFetchAction,
} from './action'
import { showNotification, refreshView } from 'ra-core'

function* handleDeleteReminder() {
  while (true) {
    let { id, extras, done, error } = yield take(DELETE_REMINDER)
    yield put(fetchDeleteReminderAction(id, extras))
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_DELETE_REMINDER_SUCCESS),
        failure: take(FETCH_DELETE_REMINDER_FAILURE)
      })
      if (success) {
        done && done()
        yield put(showNotification('notification.delete_reminder_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error && error()
        yield put(showNotification('notification.delete_reminder_failure', 'warning'))
        yield put(refreshView())
        break
      }
    }
  }
}

function* handleMakeDoneReminder() {
  while (true) {
    let { id, extras, done, error } = yield take(MAKE_DONE_REMINDER)
    let data = { id, extras }
    yield put(doFetchAction(
      FETCH_MAKE_DONE_REMINDER,
      'makeDone',
      'POST',
      data,
      'servicereminders'
    ))
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_MAKE_DONE_REMINDER_SUCCESS),
        failure: take(FETCH_MAKE_DONE_REMINDER_FAILURE)
      })
      if (success) {
        done && done()
        yield put(showNotification('notification.make_done_reminder_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error && error()
        yield put(showNotification('notification.make_done_reminder_fail', 'warning'))
        yield put(refreshView())
        break
      }
    }
  }
}

function* handleRestoreReminder() {
  while (true) {
    let { id, extras, done, error } = yield take(RESTORE_REMINDER)
    let data = { id, extras }
    yield put(doFetchAction(
      FETCH_RESTORE_REMINDER,
      'restore',
      'POST',
      data,
      'servicereminders'
    ))
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_RESTORE_REMINDER_SUCCESS),
        failure: take(FETCH_RESTORE_REMINDER_FAILURE)
      })
      if (success) {
        done && done()
        yield put(showNotification('notification.make_restore_success'))
        yield put(refreshView())
        break
      }
      if (failure) {
        error && error()
        yield put(showNotification('notification.make_restore_fail', 'warning'))
        yield put(refreshView())
        break
      }
    }
  }
}

export default function* handleReminder() {
  yield fork(handleDeleteReminder)
  yield fork(handleMakeDoneReminder)
  yield fork(handleRestoreReminder)
}