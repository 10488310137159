import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  withStyles,
  Divider,
  Chip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
} from '@material-ui/core'
import { translate } from 'react-admin'
import { shortDateTimeFormat } from '../common/format'
import moment from 'moment'
import { red, blue, green, grey } from '@material-ui/core/colors'
import compose from 'recompose/compose'
import { getColor } from '../utils/color'
import { 
  DriverIcon,
  AssistantDriverIcon,
} from '../icons'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = () => ({
  root: {
    width: '100%'
  }
})

const fieldStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  '10ACT': { color: 'white', backgroundColor: green[500] },
  '50DEP': { color: 'white', backgroundColor: blue[500] },
  '60ARV': { color: 'white', backgroundColor: grey[500] },
  '00IAT': { color: 'white', backgroundColor: grey[500] },
  '80CAN': { color: 'white', backgroundColor: red[500] },
}

export const DriverMenu = translate(({
  anchorEl,
  open,
  drivers,
  dayItem,
  onClose,
  assignDriver,
  translate,
  driverIdx,
  value,
}) => {
  return <Menu
    anchorEl={anchorEl}
    open={open}
    placement="right-start"
    onClose={() => onClose()}
  >
    <MenuItem disabled={true}>{translate('button.assign_driver')}</MenuItem>
    {Object.values(drivers).map((driver, index) => (
      <MenuItem
        selected={driver.id === value}
        key={index}
        onClick={ async () => {
          await assignDriver({ [dayItem.index]: driver.id }, [dayItem.index], driverIdx)
          onClose()
        }}
      >
        <ListItemIcon>
          <Avatar style={{ backgroundColor: getColor(driver.id) }}>
            <DriverIcon fontSize="small" nativeColor="white" />
          </Avatar>
        </ListItemIcon>
        <ListItemText>{driver.fullName}</ListItemText>
      </MenuItem>
    ))}
  </Menu>
})


export const VehicleMenu = translate(({
  anchorEl,
  open,
  vehicles,
  onClose,
  dayItem,
  assignVehicle,
  translate,
  value
}) => {
  let { tripId } = dayItem
  return <Menu
    anchorEl={anchorEl}
    open={open}
    placement="right-start"
    onClose={() => onClose()}
  >
    <MenuItem disabled={true}>{translate('button.assign_vehicle')}</MenuItem>
    {Object.values(vehicles).map((vehicle, index) => (
      <MenuItem
        key={index}
        selected={value === vehicle.id}
        onClick={() => {
          assignVehicle(vehicle, tripId)
          onClose()
        }}
      >
        <ListItemIcon>
          <Avatar>
            <FontAwesomeIcon icon={faBus} fontSize="small" color="white" />
          </Avatar>
        </ListItemIcon>
        <ListItemText>{vehicle.plate}</ListItemText>
      </MenuItem>
    ))}
  </Menu>
})

export const AssistantDriverMenu = translate(({
  anchorEl,
  open,
  assistantDrivers,
  onClose,
  dayItem,
  assignAssistantDriver,
  translate,
  value,
}) => {
  let { tripId } = dayItem
  return <Menu
    anchorEl={anchorEl}
    open={open}
    placement="right-start"
    onClose={() => onClose()}
  >
    <MenuItem disabled={true}>{translate('button.assign_assistant_driver')}</MenuItem>
    {Object.values(assistantDrivers).map((assistantDriver, index) => (
      <MenuItem
        key={index}
        onClick={() => {
          assignAssistantDriver(assistantDriver, tripId)
          onClose()
        }}
        selected={assistantDriver.id === value}
      >
        <ListItemIcon>
          <Avatar>
            <AssistantDriverIcon fontSize="small" nativeColor="white" />
          </Avatar>
        </ListItemIcon>
        <ListItemText>{assistantDriver.fullName}</ListItemText>
      </MenuItem>
    ))}
  </Menu>
})
export const DriverChip = translate(({ 
  driverIdx,
  openDriverMenu,
  translate,
  drivers,
  record,
  unAssignDriver,
  companyManager,
  labelEmpty,
}) => {
  let { driverId, driver2Id, id } = record
  let driver = driverIdx === 1 ? drivers[driverId] : drivers[driver2Id]
  let value = driverIdx === 1 ? driverId : driver2Id
  let label = driver ? driver.fullName : translate(labelEmpty)
  let onDelete = driver ? () => {
    unAssignDriver(id, driverIdx)
  } : null
  let backgroundColor = value && getColor(value)
  return <Chip
    avatar={<Avatar style={{ backgroundColor }}>
      <DriverIcon fontSize="small" nativeColor="white" />
    </Avatar>}
    label={label}
    onClick={(event) => openDriverMenu(event, driverIdx, value)} onDelete={companyManager && onDelete} 
  />
})

export const VehicleChip = translate(({ 
  openVehicleMenu,
  translate,
  vehicles,
  record,
  unAssignVehicle,
  companyManager,
}) => {
  let { vehicleId, id } = record
  let vehicle = vehicles[vehicleId]
  let label = vehicle ? vehicle.plate : translate('resources.vehicles.unassign_vehicle')
  let onDelete = vehicle ? () => {
    unAssignVehicle(id)
  } : null
  let backgroundColorAvatar = vehicle && '#43a047'
  return <Chip
    avatar={<Avatar style={{ backgroundColor: backgroundColorAvatar }}>
      <FontAwesomeIcon icon={faBus} fontSize="small" color="white" />
    </Avatar>}
    label={label}
    onClick={(event) => openVehicleMenu(event)}
    onDelete={companyManager && onDelete}
  />
})

export const AssistantDriverChip = translate(({ 
  openAssistantDriverMenu,
  translate,
  assistantDrivers,
  record,
  unAssignAssistantDriver,
  companyManager,
}) => {
  let { assistantDriverId, id } = record
  let assistantDriver = assistantDrivers[assistantDriverId]
  let label = assistantDriver ? assistantDriver.fullName : translate('resources.assistantdrivers.unassign_assistant_driver')
  let onDelete = assistantDriver ? () => {
    unAssignAssistantDriver(id)
  } : null
  let backgroundColorAvatar = assistantDriver && '#43a047'
  return <Chip
    avatar={<Avatar style={{ backgroundColor: backgroundColorAvatar }}>
      <AssistantDriverIcon fontSize="small" nativeColor="white" />
    </Avatar>}
    label={label}
    onClick={(event) => openAssistantDriverMenu(event, assistantDriverId)} 
    onDelete={companyManager && onDelete}
  />
})

class ActionDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      driverMenu: {
        open: false,
        anchorEl: null,
        driverIdx: 1,
        drivers: {},
      },
      vehicleMenu: {
        open: false,
        anchorEl: null,
      },
      assistantDriverMenu: {
        open: false,
        anchorEl: null,
      },
      record: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record } = nextProps
    let { record: currentRecord } = prevState
    if (record !== currentRecord) {
      currentRecord = record
    }
    return {
      record: currentRecord
    }
  }

  openDriverMenu = (event, driverIdx, value) => {
    let { companyManager, record, drivers } = this.props
    if (!companyManager) return
    let cloneDrivers = { ...drivers }
    let ignoreDriverId = driverIdx === 1 ? record.driver2Id : record.driverId
    if (ignoreDriverId){
      delete cloneDrivers[ignoreDriverId]
    }
    this.setState({
      driverMenu: {
        open: true,
        anchorEl: event.target,
        driverIdx,
        drivers: cloneDrivers,
        value,
      }
    })
  }

  openVehicleMenu = event => {
    let { companyManager } = this.props
    if (!companyManager) return
    this.setState({
      vehicleMenu: {
        open: true,
        anchorEl: event.target,
      }
    })
  }

  openAssistantDriverMenu = (event, value) => {
    let { companyManager } = this.props
    if (!companyManager) return
    this.setState({
      assistantDriverMenu: {
        open: true,
        anchorEl: event.target,
        value,
      }
    })
  }

  closeAssistantDriverMenu = () => {
    this.setState({
      assistantDriverMenu: {
        open: false,
        anchorEl: null,
      }
    })
  }

  closeDriverMenu = () => {
    this.setState({
      driverMenu: {
        open: false,
        anchorEl: null,
        driverIdx: 1,
        drivers: {},
      }
    })
  }

  closeVehicleMenu = () => {
    this.setState({
      vehicleMenu: {
        open: false,
        anchorEl: null,
      }
    })
  }

  render() {
    let {
      open,
      onClose,
      dayItem = {},
      vehicles,
      assignVehicle,
      assignDriver,
      unAssignVehicle,
      unAssignDriver,
      companyManager,
      drivers,
      translate,
      assistantDrivers,
      assignAssistantDriver,
      unAssignAssistantDriver,
    } = this.props
    let { driverMenu, vehicleMenu, assistantDriverMenu, record } = this.state
    let { open: openDriverMenu, anchorEl: anchorElDriverMenu, driverIdx, drivers: driversMenu, value: valueDriverChip } = driverMenu
    let { open: openVehicleMenu, anchorEl: anchorElVehicleMenu, value: valueVehicleChip } = vehicleMenu
    let { open: openAssistantDriverMenu, anchorEl: anchorElAssistantDriverMenu, value: valueAssistanceDriverChip } = assistantDriverMenu
    let style = fieldStyles[record.status]
    return <>
      <Dialog
        open={open}
        onClose={() => onClose()}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle style={style}>
          {moment(record.departureTime).format(shortDateTimeFormat)} - {record.departure}
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item md={4} xs={4}>{translate('resources.common.departure')}:</Grid>
            <Grid item md={8} xs={8}>{moment(record.departureTime).format(shortDateTimeFormat)} - {record.departure}</Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={4}>{translate('resources.common.arrival')}:</Grid>
            <Grid item md={8} xs={8}>{moment(record.arrivalTime).format(shortDateTimeFormat)} - {record.arrival}</Grid>
          </Grid>
          <Divider />
          <Grid container>
            <Grid item md={4} xs={4}>{translate('resources.drivers.name', { smart_count: 1 })}:</Grid>
            <Grid item md={4} xs={4}>
              <DriverChip
                driverIdx={1}
                companyManager={companyManager}
                record={record}
                openDriverMenu={this.openDriverMenu}
                drivers={drivers}
                unAssignDriver={unAssignDriver}
                labelEmpty="resources.drivers.unassign_driver_1"
              />
            </Grid>
            <Grid item md={4} xs={4}>
              <DriverChip
                driverIdx={2}
                companyManager={companyManager}
                record={record}
                openDriverMenu={this.openDriverMenu}
                drivers={drivers}
                unAssignDriver={unAssignDriver}
                labelEmpty="resources.drivers.unassign_driver_2"
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={4}>{translate('resources.assistantdrivers.name', { smart_count: 1 })}:</Grid>
            <Grid item md={8} xs={8}>
              <AssistantDriverChip
                companyManager={companyManager}
                record={record}
                openAssistantDriverMenu={this.openAssistantDriverMenu}
                assistantDrivers={assistantDrivers}
                unAssignAssistantDriver={unAssignAssistantDriver}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={4} xs={4}>{translate('resources.vehicles.name', { smart_count: 1 })}:</Grid>
            <Grid item md={8} xs={8}>
              <VehicleChip
                companyManager={companyManager}
                record={record}
                openVehicleMenu={this.openVehicleMenu}
                vehicles={vehicles}
                unAssignVehicle={unAssignVehicle}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <DriverMenu
        dayItem={dayItem}
        drivers={driversMenu}
        anchorEl={anchorElDriverMenu}
        open={openDriverMenu}
        onClose={this.closeDriverMenu}
        assignDriver={assignDriver}
        driverIdx={driverIdx}
        value={valueDriverChip}
      />
      <VehicleMenu
        dayItem={dayItem}
        vehicles={vehicles}
        anchorEl={anchorElVehicleMenu}
        open={openVehicleMenu}
        onClose={this.closeVehicleMenu}
        assignVehicle={assignVehicle}
        value={valueVehicleChip}
      />
      <AssistantDriverMenu
        dayItem={dayItem}
        assistantDrivers={assistantDrivers}
        anchorEl={anchorElAssistantDriverMenu}
        open={openAssistantDriverMenu}
        onClose={this.closeAssistantDriverMenu}
        assignAssistantDriver={assignAssistantDriver}
        value={valueAssistanceDriverChip}
      />
    </>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(ActionDialog)
