import React, { Component } from 'react'
import * as permission from '../utils/permission'
import {
  List,
  TextField,
  DateField,
  Datagrid,
  Create,
  SimpleForm,
  Edit,
  FunctionField,
  ReferenceField,
  Filter,
  ReferenceInput,
  translate,
  CreateButton,
  ExportButton,
  CardActions,
  Responsive,
} from 'react-admin'
import FormSave from './FormSave'
import { validate } from './validate'
import CardAvatar from '../common/CardAvatar'
import { withStyles, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import SearchInput from '../common/SearchInput'
import DriversMobileGrid from './DriversMobileGrid'
import SelectInputCustom from '../common/SelectInputCustom'

const styleList = () => ({
  avatarCol: {
    display: 'flex'
  },
  fullName: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  textCenter: {
    textAlign: 'center',
  }
})

const styleListAction = () => ({
  rigthLabel: {
    paddingLeft: '0.5em',
    paddingRight: '0.5em',
  }
})

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
    marginRight: 17,
  },
  otherStatus: {
    minWidth: 100,
    marginBottom: 21,
  }
})

///////////////////////////////////////////
// List
const DriverFilter = withStyles(styleListFilter)(({ translate, classes, ...props }) => (
  <Filter 
    {...props}
  >
    <SearchInput
      helperText="resources.customers.searchHelperText"
      source="q"
      fullWidth
      alwaysOn
    />
    <ReferenceInput
      basePath="/driverstatuses"
      reference="driverstatuses"
      source="status"
      resource="driverstatuses"
      fullWidth
      alwaysOn
      label="resources.drivers.fields.status"
      classExtra={classes.otherStatus}
      allowEmpty
    >
      <SelectInputCustom
        optionText="name"
        all={translate('resources.common.all')}
      />
    </ReferenceInput>
  </Filter>
))

const ActionList = withStyles(styleListAction)(({
  basePath,
  resource,
  currentSort,
  filterValues,
  exporter,
  classes,
  total,
  history,
  translate,
  companyManager,
}) => {
  return (
    <CardActions>
      {companyManager && <>
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
        <Button
          color="primary"
          onClick={() => { 
            history.push(`/${resource}/import`)
          }}
        >
          <FontAwesomeIcon icon={faFileUpload} />
          <span className={classes.rigthLabel}>
            {translate('button.import')}
          </span>
        </Button>
      </>}
    </CardActions>
  )
})

class _DriverList extends Component {
  render() {
    let { classes, permissions, history, ...props } = this.props
    let companyManager = permission.isCompanyManager(permissions)
    return (
      <List
        {...props}
        filters={<DriverFilter />} 
        actions={<ActionList history={history} companyManager={companyManager} />}
        bulkActionButtons={false}
        history={history}
        filterDefaultValues={{
          '../fields': [
            'id',
            'fullName',
            'birthday',
            'phone',
            'status',
            'avatar',
          ]
        }}
      >
        <Responsive
          xsmall={<DriversMobileGrid companyManager={companyManager} history={history}/>} 
          medium={
            <Datagrid rowClick={ companyManager && 'edit' }>
              <FunctionField
                source="fullName"
                render={record => {
                  return(
                    <div className={classes.avatarCol}>
                      <CardAvatar record={record} size='small' />
                      <span className={classes.fullName}>{record.fullName}</span>
                    </div>
                  )}}
              />
              <DateField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                source="birthday"
                locales="vi-VN"
              />
              <TextField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                source="phone"
              />
              <ReferenceField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                source="status"
                reference="driverstatuses"
                linkType={false}
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>
          }
        />
      </List>
    )
  }
}

const enhanceList = compose(withStyles(styleList), translate)
export const DriverList = enhanceList(_DriverList)

/////////////////////////////////////////
// Create
export class DriverCreate extends Component {

  render() {
    let { props } = this
    return (
      <Create
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} />}
      >
        <SimpleForm validate={validate}>
          <FormSave />
        </SimpleForm>
      </Create>
    )
  }
}

/////////////////////////////////////////
// Edit 
export class DriverEdit extends Component {

  render() {
    let { id, ...props } = this.props
    return (
      <Edit
        id={id}
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} render={record => record.fullName} />}
      >
        <SimpleForm toolbar={<CustomToolbar />} validate={validate}>
          <FormSave id={id} />
        </SimpleForm>
      </Edit>      
    )
  }
}
