import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import {
  TextInput,
  SimpleForm,
  ArrayInput,
  SelectInput,
  FormDataConsumer,
  SimpleFormIterator,
  translate,
} from 'react-admin'
import compose from 'recompose/compose'
import MaskedInput from '../common/MaskedInput'
import _ from 'lodash'
import { getFareConditions } from '../provider/get'
import {
  FareMatrix,
  BaseCellFormatter,
  CellFormatter,
  HeaderRenderer,
  CellEditor,
  recalcRow,
} from './FareMatrix'
import CustomToolbar from '../common/CustomToolbarForm'
import { validateFareName } from '../fare/FormSave'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import { change as changeForm, reset as resetForm } from 'redux-form'

const FARE_TABLE_FORM = 'fare-table-form'

const styles = {
  maskInput: {
    width: '100%',
    marginLeft: '1em',
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    flexDirection: 'row',
    flex: 1,
  },
}

class _FormSave extends Component {

  state = {
    version: new Date().getTime(),
    status: true,
  }

  switchColumnLock = (column) => {
    let { changeForm } = this.props
    let { gridColumns, prices, } = this.state
    let index = _.findIndex(gridColumns, { key: column.key })
    if (index >= 0) {
      gridColumns = [...gridColumns]
      gridColumns[index] = { ...column, lock: !column.lock }
      prices = _.map(prices, price => {
        let data = _.get(price, 'table.0.data')
        const fareStatus = gridColumns[index].lock ? '10ACT' : '00IAT'
        _.set(data[column.key], 'fareStatus', fareStatus)
        _.set(price, 'table.0.data', data)
        return price
      })
      changeForm(FARE_TABLE_FORM, 'prices', prices)
      this.setState({ gridColumns, version: new Date().getTime(), prices })
    }
  }

  setColor = (color, { field, value }) => {
    let { prices = [] } = this.state
    let { changeForm } = this.props
    let priceId = _.findIndex(prices, price => _.get(price, field) === value)
    if (priceId >= 0) {
      _.set(prices, `${priceId}.color`, color)
      changeForm(FARE_TABLE_FORM, 'prices', prices)
    }
  }

  buildTable = ({
    oldTable = [],
    fareTable,
    fareConditions,
    isFirst,
    base = 45000,
  }) => {
    let cols = []
    let rows = []
    let oldRowMap = _.mapKeys(oldTable, item => _.get(item, 'condition.key'))
    let gridColumns = [
      {
        key: 'rowLabel',
        name: '',
      },
      {
        key: 'rowBase',
        name: 'Giá',
        formatter: <BaseCellFormatter
          setColor={this.setColor}
        />,
        editable: true,
        editor: CellEditor,
      },
    ]

    rows.push(oldRowMap['0'] || {
      condition: { key: '0' },
      base,
      data: {
        isBase: 1,
        rowLabel: 'Giá ngày thường',
        rowBase: {
          fomular: base,
          value: base,
        }
      }
    })

    let { conditions = [] } = fareTable
    let fareStatuses = _.get(fareTable, 'prices.0.table.0.data', {})
    conditions = conditions.filter(item => !_.isEmpty(item))
    if (conditions && conditions.length > 0) {
      for (let condition of conditions) {
        // hack for condition key
        let fareCondition = fareConditions[condition.code] || {}
        //if (!condition.key) condition.key = String(new Date().getTime())
        if (!condition.key) condition.key = fareCondition.code
        if (!condition.id) condition.id = fareCondition.id
        if (fareCondition && fareCondition.type === 'auto') {
          let row = oldRowMap[condition.key] || {
            data: {
              rowLabel: fareCondition.name,
              rowBase: {
                fomular: fareCondition.default,
              }
            }
          }
          row.condition = condition
          rows.push(row)
        }
      }
      for (let condition of conditions) {
        let fareCondition = fareConditions[condition.code]
        if (fareCondition && fareCondition.type !== 'auto') {
          cols.push(condition)
          let lock = _.isEmpty(oldTable) ? true : (_.get(fareStatuses, `${condition.key}.fareStatus`, '') === '10ACT' ? true : false)
          gridColumns.push({
            key: String(condition.key),
            name: fareCondition.name,
            lock,
            editable: true,
            formatter: CellFormatter,
            headerRenderer: <HeaderRenderer
              onClick={column => this.switchColumnLock(column)}
            />,
            editor: CellEditor,
          })
          rows.forEach(row => {
            row.data[condition.key] = {
              fomular: fareCondition.default,
              ...row.data[condition.key],
              fareConditionId: fareCondition.id,
              fareConditionName: fareCondition.name,
              fareConditionCode: fareCondition.code
            }
          })
        }
      }
    }

    rows = rows.map((row, index) => {
      let res = recalcRow(gridColumns, row, base, isFirst && index === 0)
      return res
    })

    return { rows, cols, gridColumns }
  }

  getRoutes = async (companyId) => {
    let res = await Provider.dataProvider('GET_LIST', 'routes', {
      filter: {
        where: {
          status: { neq: '20ARCHIVED' }
        },
        isBusTour: true,
        byCompany: companyId,
        fields: [
          'id',
          'name',
          'numberOfProduct',
          'routeGroupId',
          'status',
          'originId',
          'destinationId',
          'distance',
          'companyId',
          'stops'
        ],
        include: {
          relation: 'company',
          scope: {
            fields: ['id', 'name']
          }
        }
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getFareIndexs = async (fareIndexIds) => {
    let res = await Provider.dataProvider('GET_LIST', 'fareindices', {
      filter: {
        where: {
          id: { inq: fareIndexIds },
        },
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }
  
  resetForm = () => {
    const { resetForm } = this.props
    resetForm(FARE_TABLE_FORM)
  }

  componentWillUnmount() {
    this.resetForm()
  }

  async componentDidMount() {
    let { record, changeForm } = this.props
    let prices = _.get(record, 'prices')
    let fareConditions = await getFareConditions()
    if (fareConditions) {
      fareConditions = _.mapKeys(fareConditions, item => item.id)
    }

    changeForm(FARE_TABLE_FORM, 'id', _.get(record, 'id'))
    this.setState({ fareConditions, prices })
  }

  render() {
    let { classes, resource, translate, changeForm } = this.props
    let { fareConditions, status, gridColumns = [], version, } = this.state
    let extra = { resource, fullWidth: true }
    if (!fareConditions) return null
    const priceValidate = (value, allValues, props) => _.isEmpty(value) && props.translate('error_messages.required.fares.name')
    return (
      <SimpleForm
        toolbar={<CustomToolbar />}
        {...this.props}
        submitOnEnter={false}
        form={FARE_TABLE_FORM}
        validate={(formData) => {
          let errors = {}
          let name = formData && formData.name && formData.name.trim()
          if (!name || name.length === 0) {
            errors.name = translate('error_messages.required.name')
            return errors
          }
          let { fareConditions, gridColumns } = this.state
          if (!gridColumns) {
            let { gridColumns } = this.buildTable({ oldTable: formData.prices || [], fareTable: formData, fareConditions })
            this.setState({ gridColumns })
            return
          }

          let { conditions } = formData
          if (!_.isEqual(conditions, this.state.conditions)) {
            let { prices = [] } = formData
            let newGridColumns
            if (prices && prices.length > 0) {
              prices = prices.map((price, index) => {
                const { color, fareIndexId, table } = price
                let { gridColumns, rows } = this.buildTable({
                  oldTable: table,
                  fareTable: formData,
                  fareConditions,
                  isFirst: index === 0,
                })
                newGridColumns = gridColumns
                return { color, fareIndexId, table: rows }
              })
            }
            formData.prices = prices
            this.setState({
              conditions,
              version: new Date().getTime(),
              prices,
            })

            if (newGridColumns) {
              this.setState({ gridColumns: newGridColumns })
            }
          }
        }}
      >
        <TextInput source="name" validate={validateFareName} {...extra} />
        <TextInput source="desc" {...extra} />
        <ArrayInput source="conditions" {...extra}>
          <SimpleFormIterator classes={{ form: classes.form }}>
            <SelectInput
              source="code"
              choices={Object.values(fareConditions)}
              optionText="name"
              fullWidth
              formClassName={classes.input}
            />
            <FormDataConsumer
              fullWidth
              formClassName={classes.input}
            >
              {({ formData, id, getSource }) => {
                let record = _.get(formData, id)
                if (record && _.get(record, 'code') && fareConditions) {
                  // hack for unique key
                  let subRecord = fareConditions[record.code] || {}
                  //if (!record.key) record.key = String(new Date().getTime())
                  if (!record.key) record.key = subRecord.code
                  if (!record.id) record.id = subRecord.id

                  let params = _.get(subRecord, 'params')
                  if (params) {
                    return Object.keys(params).map(key => {
                      let param = params[key]
                      if (param === 'MM/DD') {
                        return <MaskedInput
                          source={getSource(key)}
                          label={key}
                          key={key}
                          mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                          placeholderChar={'\u2000'}
                          showMask
                          className={classes.maskInput}
                          fullWidth
                        />
                      }
                      return null
                    })
                  }
                }
                return null
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput
          key={version}
          label="resources.fares.fields.price"
          source="prices"
          {...extra}
          validate={priceValidate}
        >
          <SimpleFormIterator>
            <FormDataConsumer>
              {({ formData, id, getSource }) => {
                let subRecord = _.get(formData, id)
                if (!subRecord) return
                if (!subRecord.table) {
                  let { rows, gridColumns } = this.buildTable({ fareTable: formData, fareConditions })
                  this.setState({ gridColumns })
                  subRecord.table = rows
                }
                let base = _.get(formData, 'prices[0].table[0].data.rowBase.value', 0)
                if (!subRecord.key) {
                  subRecord.key = new Date().getTime()
                }
                return (
                  <FareMatrix
                    status={status}
                    source={getSource('table')}
                    subRecord={subRecord}
                    base={base}
                    fareTable={formData}
                    gridColumns={gridColumns}
                    isFirst={id === 'prices[0]'}
                  />
                )
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  connect(null, { changeForm, resetForm }),
  translate,
)

export default enhance(_FormSave)
