import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  Button,
  Chip,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import {
  red,
  green,
  indigo,
} from '@material-ui/core/colors'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  translate,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin'
import compose from 'recompose/compose'
import QRCode from 'qrcode.react'
import { Provider } from '../provider'
import moment from 'moment'
import withMQTT from '../mqtt/withMQTT'
import MQTT from '../mqtt/mqtt'
import { PAIRING_DEVICE } from '../common/mqttEventEmitter'
import { MqttActionName } from '../common/constants'
import { connect } from 'react-redux'
import _ from 'lodash'
import { getCurrentPairedDevices } from '../utils/commonUtil'

const styles = {
  iconbutton: {
    width: 36,
    height: 36,
    padding: 0,
    marginleft: 8,
  },
  buttonIcon: {
    marginRight: '0.5em',
    fontSize: 20
  },
  icon: {
    color: 'white',
  },
}

const pairedDeviceListStyle = {
  qrContainer: {
    paddingTop: 40,
    paddingLeft: 90,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)'
  },
  table: {
    tableHead: {
      fontSize: '11px',
      fontWeight: 'bold'
    },
    tableBody: {
      fontSize: '15px'
    }
  }
}

const deleteAwaitPong = array => {
  let newArr = [...array]
  newArr = newArr.map(ele => {
    let eleWithoutAwaitPong = { ...ele }
    delete eleWithoutAwaitPong['awaitPong']
    return eleWithoutAwaitPong
  })
  return newArr
}

class _PairedDeviceList extends Component {

  state = {
    timeNow: +moment(),
    QRData: {},
    status: 'loading',
    devices: [],
    newKeyPair: '',
    hasPairing: false,
    error: {}
  }

  getQRData = () => {
    Provider.dataProvider('REMOTE', 'devices', {
      method: 'getPairQRInfo',
      requestMethod: 'GET'
    }).then(res => {
      let { subscribe, mqttEmitter } = this.props
      let { data } = res
      let newKeyPair = data.chanel
      this.setState({
        QRData: data,
        status: 'renderQR',
        timeNow: data.timeNow,
        newKeyPair
      })
      //subscribe(`device/${newKeyPair}`)
      //mqttEmitter.on(PAIRING_DEVICE, (message) => this.onMessage(message))
    }).catch(err => {
      let messageErr = ''
      if (this.props.currentLanguage === 'en')
        messageErr = err.message_en
      else
        messageErr = err.message
      this.setState({
        error: {
          message: messageErr
        },
        status: 'error'
      })
    })
  }
  unsubscribeChanel = () => {
    let { unsubscribe } = this.props
    let { devices, hasPairing, newKeyPair } = this.state
    devices.forEach(device => {
      unsubscribe(`device/${device.keyPair}`)
    })
    if (!hasPairing) unsubscribe(`device/${newKeyPair}`)
  }
  subscribeChanel = () => {
    let { devices } = this.state
    let { subscribe } = this.props
    devices.forEach(device => {
      subscribe(`device/${device.keyPair}`)
    })
  }
  componentDidMount() {
    if (localStorage && localStorage.getItem('devices')) {
      let devices = getCurrentPairedDevices()
      devices = devices.map(v => ({ ...v, awaitPong: false }))
      //this.setState({ devices }, () => this.subscribeChanel())
    }
    this.timerID = setInterval(
      () => {
        this.setState(state => ({ timeNow: state.timeNow + 1000 }),
          () => {
            let { QRData, timeNow, status } = this.state
            if (QRData && QRData.expiredAt) {
              if (timeNow >= QRData.expiredAt) {
                if (status !== 'success') {
                  this.setState({ status: 'time-out' })
                }
              }
            }
          })
      },
      1000
    )
    this.getQRData()
  }

  componentWillUnmount() {
    clearInterval(this.timerID)
    //this.unsubscribeChanel()
  }
  onHandleClickReload = () => {
    this.setState({ status: 'loading' })
    this.getQRData()
  }
  addIntoDevices = device => {
    let { devices } = this.state
    let pos = _.findIndex(devices, { 'packageName': device.packageName })
    if (pos !== -1) {
      devices[pos] = device
    } else {
      devices.unshift(device)
    }
    this.setState({ devices }, () => {
      devices = deleteAwaitPong(devices)
      localStorage.setItem('devices', JSON.stringify(devices))
    })
  }
  setIsOnline = (packageName, stt) => {
    let { devices } = this.state
    devices = devices.map((device) => {
      if (device.packageName === packageName) {
        return { ...device, isOnline: stt, awaitPong: false }
      }
      return device
    })
    this.setState({ devices }, () => {
      let newDeviceList = deleteAwaitPong(devices)
      localStorage.setItem('devices', JSON.stringify(newDeviceList))
    })
  }

  onMessage = message => {
    let data = JSON.parse(message)
    if (data && data.name) {
      const {
        payload = {
          deviceSerial: 'null',
          packageName: 'null'
        },
        name
      } = data
      let { devices } = this.state
      let { pairedKey } = payload
      let deviceIndex = _.findIndex(devices, device =>
        device.keyPair === pairedKey
      )
      switch (name) {
        case MqttActionName.PAIR_DEVICE:
          this.setState({ status: 'success', hasPairing: true })
          this.addIntoDevices({
            deviceSerial: payload.deviceSerial,
            packageName: payload.packageName,
            keyPair: this.state.newKeyPair,
            isOnline: true,
            awaitPong: false
          })
          break
        case MqttActionName.DEVICE_ACTION_REQUEST:
          switch (payload.actionType) {
            case 'PONG': 
              if (deviceIndex !== -1) {
                this.setIsOnline(devices[deviceIndex].packageName, true)
              }
              break
            case MqttActionName.LOGOUT_DEVICE: 
              if (deviceIndex !== -1) {
                this.setIsOnline(devices[deviceIndex].packageName, false)
              }
              break
            default:
          }
          break
        default:
      }
    }
  }

  pingToDevice = async (deviceSerial) => {
    let mqtt = await MQTT.getInstance()
    let chanel = `device/${deviceSerial}`
    let { devices } = this.state
    let pos = _.findIndex(devices, { 'deviceSerial': deviceSerial })
    let currentDevice = pos >= 0 ? devices[pos] : {}
    let { keyPair: pairedKey } = currentDevice
    let payload = {
      type: 'notification',
      name: MqttActionName.DEVICE_ACTION_REQUEST,
      payload: {
        pairedKey,
        actionType: 'PING',
      }
    }
    devices = devices.map(device => {
      let currDevice = { ...device }
      if (currDevice.deviceSerial === deviceSerial)
        return {
          ...currDevice,
          awaitPong: true
        }
      return currDevice
    })
    await this.setState({ devices })
    mqtt.publish(chanel, JSON.stringify(payload))
    await setTimeout(() => {
      let deviceList = this.state.devices.filter(device =>
        (device.deviceSerial === deviceSerial && device.awaitPong)
      )
      if (!deviceList || !deviceList.length) return
      deviceList.forEach(device => this.setIsOnline(device.packageName, false))
    }, 3000)
  }

  handleDelete = (packageName) => {
    let { devices } = this.state
    const { unsubscribe } = this.props
    let keyPair = ''
    devices = devices.filter(device => {
      if (device.packageName === packageName) {
        keyPair = device.keyPair
        return false
      }
      return true
    })
    unsubscribe(`device/${keyPair}`)
    this.setState(state => ({
      devices,
    }), () => {
      devices = deleteAwaitPong(devices)
      localStorage.setItem('devices', JSON.stringify(devices))
    })
  }

  render() {
    let { timeNow, status, devices, error, QRData = {
      qrData: '',
      expiredAt: 0
    } } = this.state
    let { qrData = '', expiredAt = 0 } = QRData
    let { open, onClose, classes, translate } = this.props
    const btnGetQRCode = () => (
      <Button
        variant="contained"
        color="primary"
        onClick={this.onHandleClickReload}
      >
        {translate('button.refresh')}
      </Button>
    )
    const renderContent = (status) => {
      switch (status) {
        case 'loading':
          return (
            <Grid
              style={{
                display: 'flex',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              item
              xs={12}
              lg={5}
            >
              <CircularProgress disableShrink />
            </Grid>
          )
        case 'renderQR':
          return (
            <Grid item xs={12} lg={5} className={classes.qrContainer}>
              <QRCode value={qrData} size={256} />
              <p style={{ fontSize: '20px' }}>
                {translate('resources.common.timeLeft')}&nbsp;
                {moment(expiredAt).diff(timeNow, 'seconds')}&nbsp;
                {translate('resources.common.seconds')}
              </p>
            </Grid>
          )
        case 'time-out':
          return (
            <Grid
              style={{
                display: 'flex',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
              item
              xs={12}
              lg={5}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ fontWeight: 'bold', fontSize: '30px' }}>{translate('notification.qrCodeHasExpired')}</p>
                {btnGetQRCode()}
              </div>
            </Grid>
          )
        case 'success':
          return (
            <Grid
              style={{
                display: 'flex',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
              item
              xs={12}
              lg={5}
            >
              <CheckBoxIcon style={{ color: green[500], fontSize: '300px' }} />
              {btnGetQRCode()}
            </Grid>
          )
        case 'error':
          return (
            <Grid
              style={{
                display: 'flex',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
              item
              xs={12}
              lg={5}
            >
              <div style={{ textAlign: 'center' }}>
                <p style={{ fontWeight: 'bold', fontSize: '30px' }}>{error.message}</p>
                {btnGetQRCode()}
              </div>
            </Grid>
          )
        default: return ''
      }
    }
    let datas = [], ids = []
    if (devices instanceof Array) {
      for (let i = 0; i < devices.length; i++) {
        ids.push(i)
        datas.push(devices[i])
      }
    }
    return <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        <Grid container>
          {renderContent(status)}
          <Grid item xs={12} lg={7} style={{ height: '400px' }} >
            <Datagrid
              ids={ids}
              data={datas}
              resource="devices"
              currentSort={{}}
            >
              <TextField
                source="deviceSerial"
                resource="devices"
                label="resources.devices.fields.serial"
              />
              <TextField
                source="packageName"
                resource="device"
                label="resources.devices.fields.installedApp"
              />
              <FunctionField
                source="status"
                resource="devices"
                label="resources.devices.fields.status"
                render={({ isOnline }) => <Chip
                  label={isOnline ? 'Online' : 'Offline'}
                  style={{
                    backgroundColor: isOnline ? green[500] : red[500],
                    color: 'white'
                  }}
                />}
              />
              <FunctionField
                label='resources.common.action'
                render={({ deviceSerial, awaitPong, packageName }) => (
                  <div style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}>
                    <Tooltip title="Ping">
                      <IconButton disabled={awaitPong} onClick={() => this.pingToDevice(deviceSerial)}>
                        <NotificationsActiveIcon style={{ color: awaitPong ? indigo[200] : indigo[500] }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={translate('button.remove')}>
                      <IconButton onClick={() => this.handleDelete(packageName)}>
                        <DeleteIcon style={{ color: red[500] }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
              />
            </Datagrid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  }
}

const pairedDeviceListEnhance = compose(
  translate,
  withStyles(pairedDeviceListStyle),
  //withMQTT({ eventListener: PAIRING_DEVICE }),
)
const PairedDeviceList = pairedDeviceListEnhance(_PairedDeviceList)

class ParingButton extends Component {

  state = {
    open: false
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  close = () => {
    this.setState({ open: false })
  }

  render() {
    let { translate, button, variant, classes } = this.props
    let { open } = this.state
    return <Fragment>
      {button ? <Button
        onClick={this.open}
        color="primary"
        variant={variant}
      >
        <CropFreeIcon fontSize="small" className={classes.buttonIcon} />
        {translate('button.pairDevice')}
      </Button> : <Tooltip title={translate('button.pairDevice')} enterDelay={100}>
        <IconButton
          className={classes.iconButton}
          onClick={this.open}
        >
          <CropFreeIcon fontSize="small" className={classes.icon} />
        </IconButton>
      </Tooltip>}
      {open && <PairedDeviceList
        open={open}
        onClose={this.close}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(state => ({
    currentLanguage: state['i18n'].locale
  }), null)
)
export default enhance(ParingButton)
