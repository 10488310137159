export const CREATE_TRIP_FROM_SCHEDULE = 'CREATE_TRIP_FROM_SCHEDULE'
export const FETCH_TRIP_FROM_SCHEDULE = 'FETCH_TRIP_FROM_SCHEDULE'
export const FETCH_TRIP_FROM_SCHEDULE_SUCCESS = 'FETCH_TRIP_FROM_SCHEDULE_SUCCESS'
export const FETCH_TRIP_FROM_SCHEDULE_FAILURE = 'FETCH_TRIP_FROM_SCHEDULE_FAILURE'

export const createTripFromSchedule = (params, onDone, handleError) => ({
  type: CREATE_TRIP_FROM_SCHEDULE,
  params,
  onDone,
  handleError
})

export const fetchTripFromSchedule = (params) => {
  let { scheduleId, vehicleIds, fromDate, toDate, recurType, every } = params
  return {
    type: FETCH_TRIP_FROM_SCHEDULE,
    payload: {
      data: { scheduleId, vehicleIds, fromDate, toDate, recurType, every },
      method: 'createTrip',
      requestMethod: 'POST'
    },
    meta: {
      resource: 'schedules',
      fetch: 'REMOTE'
    },
  }
}