import React from 'react'
import { Avatar, withStyles } from '@material-ui/core'

const styles = {
  small: {
    width: 32,
    height: 32,
  },
  medium: {
    width: 48,
    height: 48,
  },
  big: {
    width: 64,
    height: 64,
  },
}

const CardAvatar = ({ record, source = 'avatar', classes, size = 'medium' }) => {
  let { fullName = '?' } = record || {}
  let avatar = record[source]
  let label = (fullName && fullName.length > 0) ? fullName[0] : ''
  if (Array.isArray(avatar)) {
    avatar = avatar[0]
  }
  return avatar && avatar.src ? (
    <Avatar className={classes[size]} src={avatar.src} />
  ) : (
    <Avatar className={classes[size]} aria-label="Recipe">{ label }</Avatar>
  )
}

export default withStyles(styles)(CardAvatar)
