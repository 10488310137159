import React, { Component, Fragment } from 'react'
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
} from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import { connect } from 'react-redux'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import RemoveIcon from '@material-ui/icons/Remove'
import { red } from '@material-ui/core/colors'
import { DELETE } from './constant'

const confirmDialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

class _ConfirmDialog extends Component {
  render() {
    let {
      open,
      onClose,
      translate,
      onSave,
      classes,
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={onClose}
      >
        <DialogTitle className={classes.title}>
          <span className={classes.textTitle}>{translate('button.unregister_route')}</span>
        </DialogTitle>
        <DialogContent>
          {translate('notification.warning_unregister_fare')}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onSave}
            color="primary"
            variant="contained"
            autoFocus
          >
            {translate('button.ok')}
          </Button>
          <Button onClick={onClose}>
            {translate('button.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

const enhanceConfirmDialog = compose(withStyles(confirmDialogStyle), translate)
export const ConfirmDialog = enhanceConfirmDialog(_ConfirmDialog)

const style = {
  button: {
    border: `1px solid ${red[600]}`,
    color: red[600],
    '&:hover': {
      backgroundColor: red[100] 
    },
  },
  icon: {
    marginRight: '0.5em',
  }
}

class UnRegisterRouteButton extends Component {

  state = {
    open: false,
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    this.setState({ open: false })
  }

  onSave = () => {
    let {
      record,
      updateChildRouteProductsAfterRemoveRoute,
      updateProductRouteMapping,
      showNotification,
      routeId,
    } = this.props
    Provider.dataProvider('REMOTE', 'routeproducts', {
      method: 'unRegisterRoute',
      data: record,
      requestMethod: 'POST'
    }).then(() => {
      showNotification('notification.unregister_fare_success')
      this.closeDialog()
      updateProductRouteMapping(record.routeProductId, DELETE)
      updateChildRouteProductsAfterRemoveRoute(routeId)
    }).catch(() => {
      this.closeDialog()
      showNotification('notification.unregister_fare_failure', 'warning')
    })
  }

  render() {
    let { translate, size, classes } = this.props
    let { open } = this.state
    return <Fragment>
      <Button
        className={classes.button}
        onClick={this.openDialog}
        variant="outlined"
        size={size}
      >
        <RemoveIcon className={classes.icon} fontSize="small" />
        {translate('button.unregister_route')}
      </Button>
      <ConfirmDialog
        open={open}
        onClose={this.closeDialog}
        onSave={this.onSave}
      />
    </Fragment>
  }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(null, { showNotification, refreshView })
)

export default enhance(UnRegisterRouteButton)
