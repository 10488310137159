import React, { Component } from 'react'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  CardActions,
  translate,
  Filter,
  ReferenceInput,
  SelectInput
} from 'react-admin'
import { Chip, Button, Avatar, withStyles } from '@material-ui/core'
import moment from 'moment'
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ExpiredText } from '../licence/ObjectLicence'
import ReminderButton from '../licence/ReminderButton'
import DeleteReminderButton from './DeleteReminderButton'
import { MenuNewServiceTask, DialogNewServiceReminder, getExpiredColor } from './reminderCalendar'
import { DatePickerInput } from '../common/DatePicker'
import PrincipalField, { ServiceGroupMapping } from './PrincipalField'

let defaultValueFilter = {
  dueAt_gte: moment().startOf('days').toDate().toISOString(),
  dueAt_lte: moment().endOf('days').toDate().toISOString(),
  '../include': {
    relation: 'serviceTask',
    scope: {
      include: {
        relation: 'serviceGroup'
      }
    },
  },
}

const styleListAction = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
})

const EditIcon = () => (
  <FontAwesomeIcon icon={faPen} />
)

let props = {
  basePath: '/servicereminders',
  location: {},
  resource: 'servicereminders',
  hasShow: true,
  perPage: 10,
  hasCreate: true,
  hasEdit: true,
  hasList: true,
  bulkActionButtons: false
}

class _CreateButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenuNewServiceReminder: false,
      openDialogNewServiceReminder: false,
      principalType: {}
    }
  }

  onClick = (evt) => {
    this.setState({
      openMenuNewServiceReminder: true,
      currentAnchor: evt.target
    })
  }

  handleOpenForm = (serviceGroup) => {
    this.setState({
      openDialogNewServiceReminder: true,
      principalType: ServiceGroupMapping[serviceGroup.code],
    })
  }

  handleCloseDialogNewServiceReminder = () => {
    this.setState({
      openDialogNewServiceReminder: false,
      // principalType: {}
    })
  }

  handleCloseMenuNewServiceReminder = () => {
    this.setState({
      currentAnchor: null,
      openMenuNewServiceReminder: false,
      // principalType: {}
    })
  }

  doneForCreate = () => {
    this.setState({
      openMenuNewServiceReminder: false,
      openDialogNewServiceReminder: false,
      currentAnchor: null,
      // principalType: {}
    })
  }

  errorForCreate = () => {
    this.handleCloseDialogNewServiceReminder()
  }

  render() {
    let { translate, classes } = this.props
    let {
      openMenuNewServiceReminder,
      currentAnchor,
      openDialogNewServiceReminder,
      principalType
    } = this.state
    return <>
      <Button onClick={this.onClick} color="primary">
        <FontAwesomeIcon className={classes.leftIcon} icon={faPlus} />
        {translate('button.create')}
      </Button>
      <MenuNewServiceTask
        open={openMenuNewServiceReminder}
        anchorEl={currentAnchor}
        handleOpenForm={this.handleOpenForm}
        onClose={this.handleCloseMenuNewServiceReminder}
      />
      <DialogNewServiceReminder
        open={openDialogNewServiceReminder}
        handleClose={this.handleCloseDialogNewServiceReminder}
        isCreate={true}
        principalType={principalType}
        done={this.doneForCreate}
        error={this.errorForCreate}
        translate={translate}
      />
    </>
  }
}

const CreateButton = withStyles(styleListAction)(translate(_CreateButton))

const Action = () => {
  return <CardActions>
    <CreateButton />
  </CardActions>
}

class ServiceReminderFilter extends Component {

  render() {
    let { translate, ...props } = this.props
    return (
      <Filter {...props}>
        <DatePickerInput
          label={translate('resources.servicereminders.fields.dueAtGte')}
          source="dueAt_gte"
          dateFormat="DD/MM/YYYY"
          showLunarDate={true}
          keyboard={true}
          allowEmpty
          pickerType='datetime'
          alwaysOn
        />

        <DatePickerInput
          label={translate('resources.servicereminders.fields.dueAtLte')}
          source="dueAt_lte"
          dateFormat="DD/MM/YYYY"
          showLunarDate={true}
          keyboard={true}
          allowEmpty
          pickerType='datetime'
          alwaysOn
        />
        <ReferenceInput
          source="status"
          reference="servicereminderstatuses"
          basePath="/servicereminderstatuses"
          resource="servicereminders"
          alwaysOn
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
      </Filter>
    )
  }
}

export class ServiceReminderList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      extrasFilter: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState = {}) {
    let { extrasFilter } = nextProps
    let { extrasFilter: currentExtrasFilter } = prevState
    if (currentExtrasFilter !== extrasFilter) {
      currentExtrasFilter = extrasFilter
    }
    return { extrasFilter: currentExtrasFilter }
  }

  render() {
    let { match, location, translate } = this.props
    return (
      <List
        {...props}
        match={match}
        location={location}
        title={translate('resources.reminders.name')}
        actions={<Action />}
        filters={<ServiceReminderFilter />}
        filterDefaultValues={{ ...defaultValueFilter }}
        sort={{ field: 'dueAt', order: 'ASC' }}
      >
        <Datagrid>
          <TextField source="name" />
          <FunctionField
            source="principalType"
            sortable={false}
            render={record => <PrincipalField
              serviceReminder={record}
              principal={record.principal}
              renderChild={(data, pType) => {
                let { category } = pType
                return category ? <ReferenceField
                  basePath={`/${category}`}
                  record={data}
                  source="type"
                  reference={category}
                  linkType={false}
                >
                  <TextField source="name" />
                </ReferenceField> : ''
              }}
            />}
          />
          <FunctionField
            source="principalName"
            sortable={false}
            render={record => <PrincipalField
              serviceReminder={record}
              principal={record.principal}
              renderChild={(data, pType) => {
                let { icon, getOptionText, fieldShow } = pType
                let label = record[fieldShow]
                if (getOptionText) {
                  label = getOptionText(data, pType, fieldShow)
                }
                return <Chip
                  avatar={<Avatar>{icon}</Avatar>}
                  label={label}
                />
              }}
            />}
          />
          <FunctionField
            source="note"
            render={record => <PrincipalField
              serviceReminder={record}
              principal={record.principal}
              renderChild={(data, pType) => {
                let { getOptionText, extrasFieldShow, hasExtras } = pType
                if (hasExtras) {
                  let extrasValue = record[extrasFieldShow]
                  if (getOptionText) {
                    extrasValue = getOptionText(data, pType, extrasFieldShow)
                  }
                  return <ExpiredText validTo={extrasValue} />
                }
                return ''
              }}
            />}
          />
          <FunctionField
            source="dueAt"
            render={record => {
              let { status, dueAt } = record
              let obj = getExpiredColor(status, dueAt, translate)
              return <Chip
                style={{ background: obj.color }}
                label={`${moment(dueAt).format('DD/MM/YYYY')} (${obj.message})`}
              />
            }}
          />
          <FunctionField
            render={record => {
              let { serviceTask = {} } = record
              let serviceReminder = record
              return <PrincipalField
                serviceReminder={serviceReminder}
                principal={record.principal}
                renderChild={(data, pType) => {
                  return <ReminderButton
                    principal={data}
                    servicetaskRecord={serviceTask}
                    reminderRecord={serviceReminder}
                    icon={<EditIcon />}
                    isCreate={false}
                    principalType={pType}
                  />
                }}
              />
            }
            }
          />
          <FunctionField
            render={
              record => {
                let { id, repeat } = record
                return <DeleteReminderButton id={id} repeat={repeat} />
              }
            }
          />
        </Datagrid>
      </List>
    )
  }
}