import { take, race, fork, put } from 'redux-saga/effects'
import {
  CHANGE_TOTAL_SEAT,
  setTotalSeat as setTotalSeatAction,
  setVehicleClass as setVehicleClassAction,
  setSeatMapTemplates as setSeatMapTemplatesAction,
  getSeatMapTemplates as getSeatMapTemplatesAction,
  GET_SEAT_MAP_TEAMPLATE_SUCCESS,
  GET_SEAT_MAP_TEAMPLATE_FAILURE,
  CHANGE_VEHICLE_CLASS,
} from './action'

function* handleChangeTotalSeat() {
  while (true) {
    let { totalSeat, filterSeatMapTemplate } = yield take(CHANGE_TOTAL_SEAT)
    yield put(setTotalSeatAction(totalSeat))
    yield put(getSeatMapTemplatesAction(filterSeatMapTemplate))
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_SEAT_MAP_TEAMPLATE_SUCCESS),
        failure: take(GET_SEAT_MAP_TEAMPLATE_FAILURE)
      })
      if (success) {
        let seatmaptemplates = success.payload.data
        yield put(setSeatMapTemplatesAction(seatmaptemplates))
        break
      }
      if (failure) {
        break
      }
    }
  }
}

function* handleChangeVehicleClass() {
  while (true) {
    let { vehicleClass, filterSeatMapTemplate } = yield take(CHANGE_VEHICLE_CLASS)
    yield put(setVehicleClassAction(vehicleClass))
    yield put(getSeatMapTemplatesAction(filterSeatMapTemplate))
    while (true) {
      let { success, failure } = yield race({
        success: take(GET_SEAT_MAP_TEAMPLATE_SUCCESS),
        failure: take(GET_SEAT_MAP_TEAMPLATE_FAILURE)
      })
      if (success) {
        let seatmaptemplates = success.payload.data
        yield put(setSeatMapTemplatesAction(seatmaptemplates))
        break
      }
      if (failure) {
        break
      }
    }
  }
}

export function* handleVehicleType(){
  yield fork(handleChangeTotalSeat)
  yield fork(handleChangeVehicleClass)
}