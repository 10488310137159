import React, { Component } from 'react'
import { Button, withStyles } from '@material-ui/core'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons'

const style = () => ({
  rightLabel: {
    paddingLeft: '0.5em'
  },
})

class RenderButton extends Component {
  render() {
    const { classes } = this.props
    return (
      <Button color="primary">
        <FontAwesomeIcon icon={faCalendarPlus} />
        <span className={classes.rightLabel}>Render Scheduler</span>
      </Button>
    )
  }
}

const enhance = compose(withStyles(style))

export default enhance(RenderButton)
