import React, { Component, Fragment, createRef } from 'react'
import {
  Datagrid,
  Filter,
  List,
  ReferenceField,
  SimpleForm,
  TextInput,
  LongTextInput,
  TextField,
  Edit,
  Create,
  ReferenceManyField,
  addField,
  translate,
  ReferenceInput,
  SelectInput,
  required,
} from 'react-admin'
import {
  Button,
  Toolbar,
  withStyles,
  ListItem,
  ListItemText,
  Avatar,
  List as MUIList,
  Typography,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import ReactDatagrid from 'react-data-grid'
import MaskedInput from 'react-text-mask'
import { minToHour, hourToMin } from '../utils/time'
import { faBus } from '@fortawesome/free-solid-svg-icons'
import { Provider } from '../provider'
import { grey, red } from '@material-ui/core/colors'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AlertDialog from '../common/AlertDialog'
import Snackbar from '../common/Snackbar'
import { EditTitle } from '../common/Title'
import papa from 'papaparse'
import clipboard from '../utils/clipboard'
import _ from 'lodash'
import CustomToolbar from '../common/CustomToolbarForm'
import { isCompanyManager } from '../utils/permission'
import PropTypes from 'prop-types'
import { validate } from './validate'
// import moment from 'moment'

////////////////////////////////////////////////////////////////////////////////
// List
const TimeTableListFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput source="name" alwaysOn fullWidth />
    </Filter>
  )
}

export class TimeTableList extends Component {

  render() {
    let { ...props } = this.props
    return (
      <List
        {...props}
        bulkActionButtons={false}
        filters={<TimeTableListFilter />}
      >
        <Datagrid rowClick="edit">
          <TextField source="name" />
          <TextField source="desc" />
        </Datagrid>
      </List>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
const styles = {
  rotateText: {
    width: 100,
    height: 30,
    fontSize: 10,
    transform: 'rotate(-60deg) translate(0, 80px)',
    position: 'absolute',
  },
  line: {
    width: 116,
    height: 16,
    borderRadius: 8,
    background: '#ff5050',
    position: 'absolute',
    marginTop: 130,
    '&:hover': {
      background: '#ff505088',
      cursor: 'pointer',
    }
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    background: '#fff',
    position: 'absolute',
    marginTop: 130 + 4,
  },
  container: {
    width: '100%',
    height: 170,
  },
  block: {
    marginBottom: 12,
  },
  span: {
    padding: 5,
    marginLeft: 3,
    marginRight: 3,
  },
  input: {
    padding: 5,
    marginLeft: 3,
    marginRight: 3,
    fontSize: 14,
    width: '6em',
  },
  select: {
    height: 26,
  },
  groupHeader: {
  },
  content: {
    flexDirection: 'column',
  },
  groupContent: {
    background: grey[100],
    width: '100%',
    flexDirection: 'column',
  },
  group: {
    marginBottom: '2em',
  },
  grid: {
    marginLeft: 12,
    marginRight: 12,
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  button: {
    margin: '0.5em',
  },
  deleteButton: {
    backgroundColor: red[500],
    color: 'white',
  },
  rootToolbar: {
    fontSize: 13,
  },
  disabledButton: {
    backgroundColor: 'rgba(0, 0, 0, 0.12)',
    color: 'rgba(0, 0, 0, 0.26)'
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

const cellStyles = {
  normal: {
  },
  step: {
    fontSize: 8,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  slower: {
    color: 'red',
  },
  faster: {
    color: 'green',
  },
}

const _CellFormatter = ({ classes, value }) => {

  if (!value) return null

  if (typeof value === 'object') {
    return (
      <div 
        className={
          value.step === value.base ? classes.normal : (
            value.step > value.base ? classes.slower : classes.faster
          )
        }
      >
        {value.value}
        <div className={classes.step}>{value.step}</div>
      </div>
    )
  } else {
    return <span>{value}</span>
  }
}

const CellFormatter = withStyles(cellStyles)(_CellFormatter)

class CellEditor extends Component {

  static propTypes = {
    value: PropTypes.any,
    column: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.isObject = typeof props.value === 'object'
  }

  getValue() {
    let updated = {}
    let { props, isObject } = this
    let value = this.getInputNode().value
    updated[props.column.key] = isObject ? { ...props.value, value } : value
    return updated
  }

  getInputNode() {
    return this.input
  }

  render() {
    let { props, isObject } = this
    return (
      <input
        ref={ref => this.input = ref}
        defaultValue={isObject && props.value ? props.value.value : props.value}
        type="text"
      />
    )
  }
}

class TimeInterval extends Component {

  static propTypes = {
    classes: PropTypes.object,
    error: PropTypes.object,
    value: PropTypes.object,
    onApply: PropTypes.func,
    onRemove: PropTypes.func,
    handleChange: PropTypes.func,
    translate: PropTypes.func,
  }

  getInterval() {
    let { service, fromHour, toHour, step, speed } = this
    service = service.value ? parseInt(service.value) : 0
    fromHour = fromHour.inputElement.value
    toHour = toHour.inputElement.value
    step = step.inputElement.value
    speed = speed.inputElement.value
    if (!fromHour || !toHour || !step) return

    let from = hourToMin(fromHour)
    let to = hourToMin(toHour)
    step = parseInt(step)
    speed = parseInt(speed)
    if (from > to) return
    let ret = []
    for (var time = from; time <= to; time += step) {
      ret.push(time)
    }
    return {
      timeSeries: ret,
      interval: {
        service,
        fromHour,
        toHour,
        step,
        speed,
      }
    }
  }

  render() {
    const {
      classes,
      translate,
      onApply,
      onRemove,
      services = [],
      value = {},
      companyManager,
      handleChange,
      error,
    } = this.props
    const { service = 3, fromHour = '06:00', toHour = '22:00', step = '15', speed = '40' } = value
    let disabled = !companyManager
    let {
      fromHour: errorFromHour,
      toHour: errorToHour,
      step: errorStep,
      speed: errorSpeed,
    } = error
    let disabledApply = errorStep || errorSpeed || errorToHour || errorFromHour
    let errorStyle = { border: '1px red solid' }
    return (
      <Toolbar classes={{ root: classes.rootToolbar }}>
        <span className={classes.span}>{translate('resources.timetables.days')}</span>
        <span className={classes.span}>
          <select
            className={classes.select}
            disabled={disabled}
            ref={ref => this.service = ref}
            value={service}
            onChange={handleChange('service')}
          >
            {services.map(item =><option value={item.id} key={item.id}>{item.name}</option>)}
          </select>
        </span>
        <span className={classes.span}>{translate('resources.timetables.from')}</span>
        <MaskedInput
          mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
          ref={ref => this.fromHour = ref}
          disabled={disabled}
          placeholderChar={'\u2000'}
          className={classes.input}
          onClick={() => { this.fromHour.inputElement.select() }}
          showMask
          style={errorToHour ? errorStyle : {}}
          value={fromHour}
          onChange={handleChange('fromHour')}
        />
        <span className={classes.span}>{translate('resources.timetables.to')}</span>
        <MaskedInput
          mask={[/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]}
          disabled={disabled}
          ref={ref => this.toHour = ref}
          placeholderChar={'\u2000'}
          className={classes.input}
          onClick={() => { this.toHour.inputElement.select() }}
          showMask
          style={errorToHour ? errorStyle : {}}
          value={toHour}
          onChange={handleChange('toHour')}
          validate={required()}
        />
        <span className={classes.span}>{translate('resources.timetables.hour')}.</span>
        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
          disabled={disabled}
          ref={ref => this.step = ref}
          placeholderChar={'\u2000'}
          className={classes.input}
          onClick={() => { this.step.inputElement.select() }}
          showMask
          value={step}
          style={errorStep ? errorStyle : {}}
          onChange={handleChange('step')}
          validate={required()}
        />
        <span className={classes.span}>{translate('resources.timetables.tripPerMinute')}.</span>
        <span className={classes.span}>{translate('resources.timetables.speed')}</span>
        <MaskedInput
          mask={[/[0-9]/, /[0-9]/, /[0-9]/]}
          disabled={disabled}
          ref={ref => this.speed = ref}
          placeholderChar={'\u2000'}
          className={classes.input}
          onClick={() => { this.speed.inputElement.select() }}
          showMask
          value={speed}
          style={errorSpeed ? errorStyle : {}}
          onChange={handleChange('speed')}
          validate={required()}
        />
        <span className={classes.span}> km/h</span>
        {companyManager && <Fragment>
          <Button
            className={disabledApply ? classes.disabledButton : classes.button}
            variant="contained"
            disabled={disabledApply}
            color="primary"
            size="small"
            onClick={() => onApply(this.getInterval())}
          >
            {translate('resources.timetablegroups.apply')}
          </Button>
          <Button
            size="small"
            className={classes.deleteButton}
            onClick={() => onRemove()}
          >
            {translate('resources.timetablegroups.remove')}
          </Button>
        </Fragment>}
      </Toolbar>
    )
  }
}

class TimeTable extends Component {

  static propTypes = {
    classes: PropTypes.object,
    record: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func,
  }

  constructor(props) {
    super(props)
    this.state = {
      data: this.updateIndexRow(props.data.rows),
      interval: props.data.interval || { service: 3, fromHour: '06:00', toHour: '22:00', step: 15, speed: 40 },
      error: {
        fromHour: false,
        toHour: false,
        step: false,
        speed: false,
      },
      selectedRowIndex: [],
      stopLength: 0,
      scrollLeft: 0,
    }

    // init data-grid's column headers
    let { record, companyManager } = this.props
    let columns = [{ key: 'index', name: '', width: 40 }]
    let { stops = [] } = record
    stops.forEach(function (item, i) {
      i++
      columns.push({
        key: i,
        name: item.distance,
        editable: companyManager,
        width: 100,
        formatter: CellFormatter,
        editor: CellEditor,
      })
    })
    // columns[0] = {...columns[0], sortDescendingFirst: true, sortable: true}
    columns[1] = { ...columns[1], sortable: true, }
    this.columns = columns
  }

  updateRow(rows, rowIndex, key, step, base = 0, value) {
    //rowIndex = 5, key = 1, step = 0, base = 0
    let row = { ...rows[rowIndex] }
    let nCol = this.columns.length
    row[key] = { ...row[key], step }
    if (typeof value !== 'undefined') row[key].value = value
    if (base > 0) {
      row[key].base = base
    }
    for (let col = key; col < nCol; col++) {
      let prev = row[Math.max(1, col - 1)]
      let hour = hourToMin(prev.value)
      let val = hourToMin(minToHour(hour + row[col].step))
      let value = val >= 1440 ? val - 1440 : val
      row[col] = { ...row[col], value: minToHour(value) }
    }
    rows[rowIndex] = row
  }

  createTimeTable = ({ timeSeries, interval }) => {
    let { record, onChange } = this.props
    let { speed } = interval
    speed = parseInt(speed)
    if (!timeSeries) return
    let rows = timeSeries.map((start, index) => {
      let cells = { index: index + 1 }
      let startItem = start
      record.stops.forEach(function (item, i) {
        i++
        let step = Math.round(parseFloat(item.distance) / speed * 60)
        startItem += step
        cells[i] = {
          index: i,
          value: minToHour(startItem),
          base: step,
          step,
        }
      })
      return cells
    })

    let firstRow = { index: '-' }
    record.stops.forEach(function (item, i) {
      i++
      let delta = Math.round(parseFloat(item.distance) / speed * 60)
      firstRow[i] = delta
    })

    rows = [firstRow].concat(rows)
    this.setState({ data: rows, interval })
    onChange({ rows, interval })
  }

  onGridRowsUpdated = (updateInfo) => {
    let { fromRow, updated } = updateInfo
    let rows = (this.state.data || []).slice()
    if (fromRow === 0) {
      // modify step
      for (let key in updated) {
        let value = updated[key]
        let step = parseInt(value)
        rows[0] = { ...rows[0], [key]: step }
        for (let i = 1; i < rows.length; i++) {
          this.updateRow(rows, i, parseInt(key), step, step)
        }
        break
      }
    } else {
      // modify stop time
      for (let key in updated) {
        let updatedValue = updated[key]
        let row = rows[fromRow]
        let inputValue = updatedValue.value
        let value = hourToMin(inputValue)
        if (value) {
          if (key > 1) {
            value -= hourToMin(row[key - 1].value)
            if (value < 0) value = 0
            this.updateRow(rows, fromRow, parseInt(key), value)
          } else {
            this.updateRow(rows, fromRow, parseInt(key), 0, 0, minToHour(value))
          }
        } else if (typeof updatedValue === 'string' && updatedValue.match(/\d+/)) {
          let step = parseInt(updatedValue)
          this.updateRow(rows, fromRow, parseInt(key), step)
        } else {
          return
        }
      }
    }
    this.setState({ data: rows })
    this.props.onChange({
      rows,
      interval: this.state.interval
    })
  }

  /**
   * Copy timetable to clipboard
   */
  onCopy() {
    const { stops } = this.props.record
    const rows = this.state.data || []
    const csvColumns = this.columns.filter(v => v.key !== 'index').map(v => v.key)
    rows.shift()
    let csvRows = rows.map(v => {
      let row = []
      csvColumns.forEach(key => {
        const value = v[key].value
        row.push(value)
      })
      return row
    })
    const columns = stops.map(v => v.name)
    const csv = papa.unparse({ fields: columns, data: csvRows }, { delimiter: '\t' })
    clipboard(csv)
  }

  /**
   * @example
    Bến xe Miền Đông	Buôn Ma Thuột
    08:45
    12:15
    14:00
    21:00
    22:00
    22:30
    23:00
  */
  onPaste = evt => {
    let interval = this.state.interval
    evt.preventDefault()
    evt.stopPropagation()

    let paste = (evt.clipboardData || window.clipboardData).getData('text')
    let csv = papa.parse(paste, { header: false })

    if (!csv || _.isEmpty(csv)) return
    if (csv.errors && csv.errors.length > 0) return
    let array = csv.data
    if (array.length < 1) return
    // remove the header
    let header = array.shift()
    if (!header || !header.length) return

    let timeSeries = array.map(v => { return hourToMin(v[0]) })
    this.createTimeTable({ timeSeries, interval })
  }

  handleChange = field => event => {
    let { value } = event.target
    let { interval = {}, error } = this.state

    if (field === 'fromHour') {
      let time = value && value.split(':')
      if (time && time[1].search('\u2000') >= 0) {
        error = { ...error, [field]: true }
      } else {
        let { toHour } = interval
        if (value > toHour) {
          error = { ...error, [field]: true }
        } else {
          error = { ...error, [field]: false }
        }
      }
    } else if (field === 'toHour') {
      let time = value && value.split(':')
      if (time && time[1].search('\u2000') >= 0) {
        error = { ...error, [field]: true }
      } else {
        let { fromHour } = interval
        if (value < fromHour) {
          error = { ...error, [field]: true }
        } else {
          let timeConvert = value.replace(':', '')
          if (timeConvert > '2400') {
            value = '24:00'
          }
          error = { ...error, [field]: false }
        }
      }
    } else if (value <= 0) {
      error = { ...error, [field]: true }
    } else {
      error = { ...error, [field]: false }
    }

    interval = { ...interval, [field]: value }
    this.setState({ interval, error })
  }

  onRowsSelected = rows => {
    let { selectedRowIndex } = this.state
    this.setState({
      selectedRowIndex: [
        ...selectedRowIndex,
        ...rows.filter(r => !!r.rowIdx).map(r => r.rowIdx)
      ]
    })
  }

  onRowsDeselected = rows => {
    let { selectedRowIndex } = this.state
    let rowIdxs = rows.map(r => r.rowIdx)
    selectedRowIndex = selectedRowIndex.filter(rowIdx => !rowIdxs.includes(rowIdx))
    this.setState({ selectedRowIndex })
  }

  updateIndexRow = (rows) => {
    if (!rows) return []
    rows[0].index = '-'
    for (let idxRow = 1; idxRow < rows.length; idxRow++) {
      rows[idxRow].index = idxRow
    }
    return rows
  }

  onDeleteRow = () => {
    let { selectedRowIndex, data, interval } = this.state
    let { onChange } = this.props
    if (!selectedRowIndex || !selectedRowIndex.length) return
    data = data.filter((item, index) => !selectedRowIndex.includes(index))
    data = this.updateIndexRow(data)
    selectedRowIndex = []
    onChange({ rows: data, interval })
    this.setState({ data, selectedRowIndex })
  }

  onAddRow = () => {
    let { data, interval } = this.state
    let { onChange } = this.props
    let nCol = this.columns.length
    let nRow = data.length
    let newRow = { index: nRow }
    for (let idx = 1; idx < nCol; idx++) {
      let step = data[0][idx]
      newRow[idx] = {
        base: step,
        step,
        index: idx
      }
    }
    newRow[1] = {
      ...newRow[1],
      value: '00:00',
    }
    data.push(newRow)
    data = this.updateIndexRow(data)
    this.updateRow(data, nRow, 1, 0)
    this.setState({ data })
    onChange({ rows: data, interval })
  }

  toolbar = () => {
    let { selectedRowIndex } = this.state
    let { translate, classes } = this.props
    return <div style={{
      color: '#3f51b5',
      height: 64,
      minHeight: 64,
      justifyContent: 'space-between',
      backgroundColor: 'rgb(234, 236, 247)',
      paddingLeft: 24,
      paddingRight: 24,
      display: 'flex',
      alignItems: 'center'
    }}>
      <div style={{
        flexBasis: 'auto',
        fontFamily: 'sans-serif',
        flex: '0 0 auto',
        display: 'block'
      }}>
        <h6
          style={{
            fontSize: '1rem',
            fontWeight: '400',
            letterSpacing: '0.00938em'
          }}
        >
          {selectedRowIndex.length ?
            translate('resources.timetables.pickedItem', { count: selectedRowIndex.length, smart_count: selectedRowIndex.length }) :
            ''
          }
        </h6>
      </div>
      <div style={{
        display: 'flex',
        minHeight: 40,
        alignItems: 'center',
        paddingTop: '40',
        paddingBottom: 8,
        justifyContent: 'flex-end'
      }}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          size="small"
          onClick={this.onAddRow}
        >
          {translate('button.add')}
        </Button>
        <Button
          size="small"
          className={!selectedRowIndex.length ? classes.disabledButton : classes.deleteButton}
          onClick={this.onDeleteRow}
          disabled={!selectedRowIndex.length}
        >
          {translate('button.remove')}
        </Button>
      </div>
    </div>
  }

  onGridSort = (sortColumn, sortDirection) => {
    let { data } = this.state
    const comparer = sortDirection === 'ASC'
      ? (a, b) => a[1] > b[1] ? 1 : -1
      : (a, b) => a[1] < b[1] ? 1 : -1
    let noHeaderData = data.slice(1)
    let indices = noHeaderData.map((item, index) => [index, hourToMin(item[sortColumn].value)])
    indices = indices.sort(comparer)

    let newData = sortDirection === 'NONE' ? data : [data[0], ...indices.map(index => noHeaderData[index[0]])]
    return newData
  }

  render() {
    let {
      onRemove,
      classes,
      record = {},
      translate,
      services,
      companyManager,
      onChange
    } = this.props
    let {
      interval = {},
      data: rows = [],
      error,
      selectedRowIndex,
    } = this.state
    let { stops = [] } = record
    return (
      <ExpansionPanel expanded={true}>
        <ExpansionPanelSummary
          className={classes.groupHeader}
          classes={{ content: classes.content }}
        >
          <Typography component="h2" variant="h6">
            {stops && stops.length > 0 && `${stops[0].name} ==> ${stops[stops.length - 1].name}`}
          </Typography>
          <TimeInterval
            services={services}
            translate={translate}
            classes={classes}
            value={interval}
            onApply={this.createTimeTable}
            companyManager={companyManager}
            onRemove={onRemove}
            handleChange={this.handleChange}
            error={error}
          />
        </ExpansionPanelSummary>
        <Divider />
        <ExpansionPanelDetails className={classes.groupContent}>
          {rows.length === 0 && <Snackbar
            variant="error"
            message={translate('resources.timetablegroups.apply_timetable_desc')}
            className={classes.fullWidth}
          />}
          <div className={classes.fullWidth} style={{ overflow: 'hidden', position: 'relative' }}>
            <div className={classes.container} style={{ marginLeft: this.state.scrollLeft }}>
              {stops.map((item, index) => (
                <div
                  className={classes.rotateText}
                  key={index}
                  style={{ marginLeft: 80 + index * 100 }}
                >
                  ({item.code}) {item.name}
                </div>
              ))}
              {stops.slice(0, -1).map((item, index) => (
                <div
                  className={classes.line}
                  key={index}
                  style={{ marginLeft: 80 + 77 + index * 100 }}
                >
                </div>
              ))}
              {stops.map((item, index) => (
                <div
                  className={classes.dot}
                  key={index}
                  style={{ marginLeft: 80 + 81 + index * 100 }}
                >
                </div>
              ))}
            </div>
            <div
              className={classes.grid}
              onPaste={evt => this.onPaste(evt)}
              style={{
                cursor: companyManager ? 'text' : 'disable'
              }}
            >
              <ReactDatagrid
                className={classes.grid}
                columns={this.columns}
                rowGetter={i => rows[i]}
                rowsCount={rows.length}
                enableCellSelect={companyManager ? true : false}
                onGridRowsUpdated={this.onGridRowsUpdated}
                rowSelection={{
                  showCheckbox: true,
                  onRowsSelected: this.onRowsSelected,
                  onRowsDeselected: this.onRowsDeselected,
                  selectBy: {
                    indexes: selectedRowIndex
                  }
                }}
                toolbar={this.toolbar}
                onGridSort={(sortColumn, sortDirection) => {
                  let rows = this.onGridSort(sortColumn, sortDirection)
                  this.setState({ data: rows })
                  onChange({ rows, interval })
                }}
                onScroll={e => {
                  this.setState({ scrollLeft: -e.scrollLeft })
                }}
              />
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    )
  }
}

class _TimeTableWrapper extends Component {
  render() {
    let {
      classes,
      record,
      onChange,
      onRemove,
      source,
      translate,
      services,
      companyManager,
      changeForm,
    } = this.props

    return (
      <ReferenceField
        basePath=""
        record={record}
        reference="routepatterns"
        source="patternId"
        linkType={false}
      >
        <TimeTable
          services={services}
          companyManager={companyManager}
          translate={translate}
          data={record.data}
          classes={classes}
          onChange={data => onChange(source, data)}
          onRemove={onRemove}
          changeForm={changeForm}
        />
      </ReferenceField>
    )
  }
}

const TimeTableWrapper = enhance(_TimeTableWrapper)

const stopsToLabel = function (record) {
  let { stops } = record
  let labels = []
  if (stops && stops.length > 6) {
    labels = stops.slice(0, 3).concat({ name: '...' }).concat(stops.slice(-3)) || []
  } else {
    labels = stops || []
  }
  return labels.map(stop => `(${stop.code})`).join(' - ')
}

const ListPattern = ({ ids, data, onAdd, patternServices, translate, classes }) => {

  let countService = 0
  if (patternServices) {
    Object.values(patternServices).forEach(service => {
      if (Array.isArray(service)) countService += service.length
    })
  }

  return (
    <MUIList>
      {countService === 0 && <Snackbar
        variant="error"
        message={translate('resources.timetablegroups.no_timetable')}
        className={classes.fullWidth}
      />}
      {ids && ids.map(id => {
        let pattern = data[id]
        if (!pattern) return null
        let { name = '', stops = [] } = pattern
        let service
        if (patternServices && patternServices[id]) {
          service = patternServices[id].join(', ')
        } else {
          service = translate('resources.timetablegroups.no_service')
        }
        let firtStopName = stops[0] ? stops[0].name : ''
        let lastStopName = stops[stops.length - 1] ? stops[stops.length - 1].name : ''
        return (
          <ListItem
            key={id}
            button
            onClick={() => onAdd(id)}
          >
            <Avatar>+</Avatar>
            <ListItemText
              primary={`[${(name && name !== null) ? name : ''}] ---- ${stopsToLabel(pattern)} ----- [${firtStopName} ==> ${lastStopName}]`}
              secondary={service}
            />
          </ListItem>
        )
      })}
    </MUIList>
  )
}

class _TimeTableGroup extends Component {

  state = {}

  componentDidMount() {
    Provider.dataProvider('GET_LIST', 'services', {
      filter: {},
      sort: {},
      pagination: { page: 1, perPage: 50 },
    }).then(response => {
      let services = response.data
      let serviceMap = services.reduce(function (prev, service) {
        prev[service.id] = service.name
        return prev
      }, {})
      this.setState({ services, serviceMap })
    })
      .catch(error => {
        console.error('TimeInterval get services fail', error)
      })
  }

  createByRoutePattern = (patternId) => {
    let { input, companyManager } = this.props
    if (!companyManager) return
    input.onChange([...input.value, {
      patternId,
      data: [{ block: 1, data: [] }]
    }])
  }

  removeRouteByIndex = (index) => {
    let { input } = this.props
    let newValue = input.value && input.value.slice(0, index).concat(input.value.slice(index + 1))
    input.onChange(newValue)
  }

  alert = createRef()

  render() {
    let { input, defaultValue, record, classes, translate, companyManager } = this.props
    let value = input.value || defaultValue
    let { services = [], serviceMap = {} } = this.state

    // Map pattern with choosen services
    let patternServices = value.reduce(function (prev, item) {
      let patternId = item.patternId
      let patternService
      if (patternId in prev) {
        patternService = prev[patternId]
      } else {
        prev[patternId] = patternService = []
      }
      if (item.data && item.data.interval) {
        let { service, fromHour, toHour } = item.data.interval
        patternService.push(`${serviceMap[service]} (${fromHour} - ${toHour})`)
      } else
        patternService.push('...')
      return prev
    }, {})
    
    let timetableList = value.map((item, index) => (
      <TimeTableWrapper
        record={item}
        key={index}
        source={index}
        translate={translate}
        companyManager={companyManager}
        services={services}
        onRemove={() => {
          this.alert.current.open({
            title: translate('resources.common.confirm'),
            content: translate('resources.timetablegroups.confirm_remove'),
            onOk: () => {
              this.removeRouteByIndex(index)
            },
            onClose: true,
          })
        }}
        onChange={(key, data) => {
          // if (!companyManager) return
          let newData = value.slice()
          newData[key] = { ...value[key], data }
          input.onChange(newData)
        }}
      />
    ))

    return (
      <Fragment>
        <AlertDialog forwardRef={this.alert} />
        <ExpansionPanel expanded={true}>
          <ExpansionPanelSummary className={classes.groupHeader} classes={{ content: classes.content }}>
            <ListItem>
              <Avatar><FontAwesomeIcon icon={faBus} /></Avatar>
              <ListItemText
                primary={translate('resources.timetablegroups.add_pattern_title')}
                secondary={translate('resources.timetablegroups.add_pattern_desc')}
              />
            </ListItem>
          </ExpansionPanelSummary>
          <Divider />
          <ExpansionPanelDetails className={classes.groupContent}>
            <ReferenceManyField
              label=""
              basePath=""
              reference="routepatterns"
              resource="timetablegroups"
              record={record}
              filter={{ routeGroupId: record.routeGroupId }}
              target=""
            >
              <ListPattern
                onAdd={this.createByRoutePattern}
                patternServices={patternServices}
                translate={translate}
                classes={classes}
              />
            </ReferenceManyField>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        { timetableList }
      </Fragment>
    )
  }
}

const TimeTableGroup = enhance(addField(_TimeTableGroup))

class _Form extends Component {

  state = {
    defaultName: ''
  }

  async componentDidMount() {
    let { record } = this.props
    let { routeGroupId } = record
    if (!routeGroupId) return
    let response = await Provider.dataProvider('GET_ONE', 'routegroups', {
      id: routeGroupId,
      filter: {
        fields: ['id', 'name'],
      },
    })
    let defaultName = response.data.name || ''
    this.setState({ defaultName })
  }

  render() {
    let { record, companyManager, ...props } = this.props
    let { routeGroupId } = record
    let redirect = routeGroupId ? `/routegroups/${record.routeGroupId}` : 'list'
    return (
      <SimpleForm
        record={record}
        submitOnEnter={false}
        redirect={redirect}
        toolbar={companyManager ? <CustomToolbar /> : null}
        validate={validate}
        {...props}
      >
        <TextInput
          source="name"
          fullWidth
          validate={required()}
          defaultValue={this.state.defaultName}
        />
        <LongTextInput source="desc" />
        {routeGroupId ? <ReferenceField
          source="routeGroupId"
          reference="routegroups"
        >
          <TextField source="name" />
        </ReferenceField> : <ReferenceInput
          source="routeGroupId"
          reference="routegroups"
          fullWidth
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        }
        <TimeTableGroup source="data" defaultValue={[]} companyManager={companyManager} />
      </SimpleForm>
    )
  }
}

const Form = enhance(_Form)

////////////////////////////////////////////////////////////////////////////////
// create
export class TimeTableCreate extends Component {

  static propTypes = {
    permissions: PropTypes.object,
  }

  render() {
    let { permissions, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <Create 
        {...props}
        title={
          <EditTitle
            resource={props.resource}
            base={{ resource: 'routegroups', target: 'routeGroupId' }}
          />
        }
      >
        <Form companyManager={companyManager} />
      </Create>
    )
  }
}

////////////////////////////////////////////////////////////////////////////////
// Edit
export class TimeTableEdit extends Component {

  static propTypes = {
    permissions: PropTypes.object,
  }

  render() {
    let { permissions, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <Edit
        undoable={false}
        title={
          <EditTitle
            resource={props.resource}
            base={{ resource: 'routegroups', target: 'routeGroupId' }}
          />
        }
        {...props}
      >
        <Form companyManager={companyManager} />
      </Edit>
    )
  }
}
