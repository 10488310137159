import {
  IMPORT_TRIP,
  DELETE_FILE,
  CHOOSE_TRIP,
  CHANGE_TRIP_INFO,
  SAVE_TRIP_INFO,
  VIEW_TRIP_ROUTE,
  CHANGE_VIEW_MODE,
  VIEW_TRIP_TIME,
  GET_TRIPS_BY_TIME,
  GET_ROUTE_SHOW,
  UPDATE_ROUTE_SHOW,
  REMOVE_TRIP,
} from './action'
import findIndex from 'lodash/findIndex'
import filter from 'lodash/filter'
import { VIEW_TRIP_MODE } from '../common/constants'
import isEmpty from 'lodash/isEmpty'

const findFileByName = (files, fileName) => {
  let idx = findIndex(
    files,
    file => {
      if (!file) {
        return -1
      }
      return file.fileName === fileName
    })
  return idx
}

const findTripByIdentifier = (trips, identifier) => {
  let idx = findIndex(
    trips,
    trip => {
      if (!trip) {
        return -1
      }
      return trip.identifier === identifier
    })
  return idx
}

const getNewRoutesShow = (routesShow, routeIds) => {
  let newRoutesShow = []
  if (isEmpty(routeIds)) {
    return routesShow
  }
  for (let i = 0; i < routeIds.length; i++) {
    let routeId = routeIds[i]
    for (let j = 0; j < routesShow.length; j++) {
      if (routesShow[j].id === routeId) {
        newRoutesShow.push(routesShow[j])
      }
    }
  }
  return newRoutesShow
}

const removeTrip = (id, date, datas) => {
  let tripByDayIdx = findIndex(
    datas,
    dt => {
      if (!dt) {
        return -1
      }
      return dt.id === date
    }
  )
  let tripsByDay = datas[tripByDayIdx]
  if (!tripsByDay) {
    return datas
  }
  let tripsByDayDatas = tripsByDay.data
  for (let i = 0; i < tripsByDayDatas.length; i++) {
    let tripsByDayData = tripsByDayDatas[i]
    let { trips } = tripsByDayData
    let tripIdx = findIndex(
      trips,
      trip => {
        if (!trip) {
          return -1
        }
        return trip.id === id
      }
    )
    if (tripIdx !== -1) {
      trips = [...trips.slice(0, tripIdx), ...trips.slice(tripIdx+1)]
      tripsByDayData = {...tripsByDayData, trips: trips}
      tripsByDayDatas[i] = tripsByDayData
      tripsByDay = {...tripsByDay, data: tripsByDayDatas}
      datas[tripByDayIdx] = tripsByDay
    }
  }
  return datas
}

export const tripImport =  (state =[], action) => {
  let newfileName = action.fileName
  let prevFiles = state.files
  let idx = findFileByName(prevFiles, newfileName)
  switch(action.type) {
    case IMPORT_TRIP: {
      if (idx === -1) {
        let files = state.files || []
        let filesImp = state.files_imp || []
        let existData = state.data || []
        let data = [...existData, ...action.trips]
        files.push({
          fileName: newfileName,
          data : {
            [action.routeName]: action.trips
          }
        })
        filesImp.push({
          name: newfileName,
          file: action.file
        })
        return {
          ...state,
          files: files,
          data: data,
          files_imp: filesImp
        }
      } else {
        let existFile = state.files[idx]
        let existData = state.data
        let updatedExistFile = {
          ...existFile,
          data: {
            ...existFile.data,
            [action.routeName]: action.trips
          }
        }
        state.files[idx] = updatedExistFile
        state.data = [ ...existData, ...action.trips ]
        return {...state}
      }
    }
    case DELETE_FILE: {
      let files = [
        ...state.files.slice(0, idx),
        ...state.files.slice(idx + 1)
      ]
      let filesImport = state.files_imp
      let resFilesImport = filter(
        filesImport,
        fileImport => {
          return fileImport.name !== newfileName
        }
      )
      let datas = state.data
      let restData = filter(
        datas,
        data => {
          return data.file !== newfileName
        }
      )
      return {
        ...state,
        files: files,
        data: restData,
        files_imp: resFilesImport
      }
    }
    case CHOOSE_TRIP: {
      let trip = action.trip
      let tripIdentider = trip.identifier
      return {
        ...state,
        selected_trip: {
          [tripIdentider]: trip
        }
      }
    }
    case CHANGE_TRIP_INFO: {
      let trip = state.selected_trip[action.tripIdentifier]
      trip = { ...trip, [action.field]: action.value}
      return {
        ...state,
        selected_trip: {
          ...state.selected_trip,
          [action.tripIdentifier]: trip
        }
      }
    }
    case SAVE_TRIP_INFO: {
      let tripIdentifier = action.tripIdentifier
      let selected_trip = state.selected_trip[action.tripIdentifier]
      let fileName = selected_trip.file
      let routeName = selected_trip.routeName
      let files = state.files
      let fileIndex = findFileByName(files, fileName)
      let file = files[fileIndex]
      let trips = file.data[routeName]
      let tripIndex = findTripByIdentifier(trips, tripIdentifier)
      trips[tripIndex] = selected_trip
      let updatedExistFile = {...file, data: {...file.data, [routeName]: trips}}
      state.files[fileIndex] = updatedExistFile
      let data = state.data
      let dataIdx = findTripByIdentifier(data, tripIdentifier)
      state.data[dataIdx] = selected_trip
      return {...state}
    }
    default:
      return state
  }
}

export const tripTime = (state = [], action) => {
  switch(action.type) {
    case GET_TRIPS_BY_TIME: {
      return {
        ...state,
        filter: action.payload.filter
      }
    }
    case VIEW_TRIP_TIME: {
      let { data } = action
      return {
        ...state,
        data: data,
      }
    }
    case GET_ROUTE_SHOW: {
      let { routes } = action
      return {
        ...state,
        routesShow: routes,
        initRoutes: routes

      }
    }
    case UPDATE_ROUTE_SHOW: {
      let { initRoutes } = state
      let { routeIds } = action
      let newRoutesShow = getNewRoutesShow(initRoutes, routeIds)
      return {
        ...state,
        routesShow: newRoutesShow
      }
    }
    case REMOVE_TRIP: {
      let { id, date } = action
      let { data } = state
      data = removeTrip(id, date, data)
      return {
        ...state,
        data: data
      }
    }
    default:
      return state
  }
}


export const tripRoute = (state = [], action) => {
  switch(action.type) {
    case VIEW_TRIP_ROUTE: {
      return {
        ...state,
        data: action.data
      }
    }
    default:
      return state
  }
}

export const viewTrip = (state = { mode: VIEW_TRIP_MODE.time }, action) => {
  switch(action.type) {
    case CHANGE_VIEW_MODE: {
      return {
        ...state,
        mode: action.mode,
      }
    }
    default:
      return state
  }
}