import React, { Component } from 'react'
import { SimpleForm, TextInput } from 'react-admin'
import { Grid, withStyles } from '@material-ui/core'
import CustomToolbar from '../common/CustomToolbarForm'

const stringify = v => {
  if (typeof v === 'object') {
    return JSON.stringify(v, null, 2)
  }
  return v
}

const style = () => ({
  root: {
    width: '100%'
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  input: {
    flexDirection: 'row',
    flex: 1,
  },
})

class FormSave extends Component {
  render() {
    const { classes, ...props } = this.props
    const extra = {
      resource: 'notifications',
      fullWidth: true,
    }
    return <SimpleForm
      {...props}
      redirect="list"
      toolbar={<CustomToolbar />}
    >
      <Grid container className={classes.root} >
        <Grid item xs={6} md={6}>
          <TextInput source="title" {...extra} />
          <TextInput source="subTitle" {...extra} />
          <TextInput source="body" {...extra} />
          <TextInput source="badge" {...extra} />
          <TextInput source="iconUrl" {...extra} />
          <TextInput source="icon" {...extra} />
          <TextInput source="sound" {...extra} defaultValue="default" />
          <TextInput source="priority" {...extra} />
          <TextInput source="category" {...extra} />
          <TextInput
            source="payload"
            format={ v => { return stringify(v)} }
            {...extra}
          />
          <TextInput source="when" {...extra} />
        </Grid>
      </Grid>
    </SimpleForm>
  }
}

export default withStyles(style)(FormSave)