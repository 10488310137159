import React, { Component, createRef } from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import {
  Map as OSM,
  ZoomControl,
  ScaleControl,
} from 'react-leaflet'
import NetworkContextMenu from './network-context-menu'
import { mapUpdate, openContextMenu } from './actions'
import { connect } from 'react-redux'
import './network.css'
import { withStyles } from '@material-ui/core'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import MapInfo from './MapInfo'
import '../common/leaflet-fullscreen/Leaflet.fullscreen'
import '../common/leaflet-fullscreen/leaflet.fullscreen.css'
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch'
import 'leaflet-geosearch/assets/css/leaflet.css'
import { reverse, markerPopup } from './utils'
import _ from 'lodash'
import '../common/leaflet-editable/Leaflet.Editable'

delete L.Icon.Default.prototype._getIconUrl

const provider = new OpenStreetMapProvider()

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const styles = (theme) => {
  return {
    container: {
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        minHeight: '81vh',
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '85vh',
      },
    },
    mapInfo: {
      [theme.breakpoints.up('md')]: {
        position: 'absolute',
        width: '300px',
        height: '85vh',
        background: '#fff',
        zIndex: 1300,
      },
      [theme.breakpoints.down('md')]: {
        position: 'absolute',
        background: '#fff',
        bottom: -21,
        width: '100%',
        zIndex: 1700,
      }
    },
    map: {
      [theme.breakpoints.down('md')]: {
        minHeight: '81vh',
        zIndex: 1600,
      },
      [theme.breakpoints.up('md')]: {
        minHeight: '85vh',
      },
    },
    itemContainer: {
      padding: 0
    },
    layerControl: {
      zIndex: 401,
    },
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    icon: {
      marginRight: 0
    },
  }
}

const MAPBOX_LAYER = L.tileLayer('https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}', {
  attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
  type: 'tilelayer',
  identifier: 'MAPBOX_LAYER',
  id:'mapbox.streets',
  accessToken: 'pk.eyJ1IjoibmV4cGFuZG8iLCJhIjoiY2pydWJtM2h5MTIwNjQzcXNyemtueWNwOSJ9.Etdlea_JNbauP5wzH_VXEA'
})

const CARTO_LIGHT_LAYER = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"', {
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  type: 'tilelayer',
  identifier: 'CARTO_LIGHT_LAYER',
  subdomains: 'abc',
})

const CARTO_VOYAGER_LAYER = L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png', {
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  type: 'tilelayer',
  identifier: 'CARTO_VOYAGER_LAYER',
  subdomains: 'abc',
})

const MAPBOX = 'MAPBOX'
const VOYAGER = 'VOYAGER'
const LIGHT = 'LIGHT'

const baseLayers = {
  [MAPBOX]: MAPBOX_LAYER,
  [VOYAGER]: CARTO_VOYAGER_LAYER,
  [LIGHT]: CARTO_LIGHT_LAYER,
}

class Map extends Component {
  constructor(props) {
    super(props)
    this.mapRef = createRef()
  }

  componentDidMount() {
    let { forwardRef, isXSmall } = this.props
    forwardRef.current = this
    let currentMap = this.getMap()

    this.addSearchControl(currentMap)
    currentMap.addControl(L.control.fullscreen({
      position: 'topright',
      pseudoFullscreen: true,
    }))
    currentMap.removeControl(currentMap.zoomControl)
    // mobile mode. Default map is fullscreen
    if (isXSmall) {
      currentMap.toggleFullscreen({ 
        pseudoFullscreen: true,
      })
    }
    this.addLayerControl(currentMap)
  }

  addLayerControl = (currentMap) => {
    const map = currentMap || this.getMap()
    map.addControl(L.control.layers(baseLayers, {}))
    CARTO_VOYAGER_LAYER.addTo(map)
  }

  addSearchControl = (currentMap) => {
    const map = currentMap || this.getMap()
    const { isXSmall, translate } = this.props
    // add search control
    let searchControl = new GeoSearchControl({
      provider: provider,
      style: 'bar',
      autoClose: true,
      searchLabel: translate('resources.common.enter_address'),
      keepResult: true,
      marker: {
        icon: new L.Icon.Default(),
        draggable: true,
        opacity: 0.5
      }
    })
    let root = map && map.getContainer().querySelector('.leaflet-control-container')
    if (root) {
      map.addControl(searchControl)
      map.on('geosearch/showlocation', ({ marker }) => {
        marker.unbindPopup()
        marker.on('click', _.debounce(async () => {
          let latlng = marker.getLatLng()
          const { lat, lng } = latlng
          try {
            let resp = await reverse(lat, lng)
            let address = resp.address
            if (!address) return
            // generate popup content
            const html = markerPopup(Number(lat).toFixed(6), Number(lng).toFixed(6), address)
            marker.unbindPopup()
            marker.bindPopup(html).openPopup()
          } catch (e) {
            console.log('Error', e)
          }
        }, 1000))
      })
      // change style control search
      let formSearchEle = root.querySelector('div.leaflet-control-geosearch.bar>form')
      let parentFormSearch = formSearchEle.closest('div.leaflet-control-geosearch.bar')
      root.style.display = 'flex'
      parentFormSearch.style.display = 'flex'
      parentFormSearch.style.marginRight = isXSmall && '60px'
      formSearchEle.style.left = !isXSmall && '135px'
      formSearchEle.style.width = '100%'
      formSearchEle.style.marginLeft = isXSmall && '8px'
    }
  }

  getMap() {
    return this.mapRef && this.mapRef.current.leafletElement
  }

  render() {
    let {
      classes,
      onKeyUp,
      renderLayers,
      tripLength,
      route,
      routeStops,
      createNewStopMarker,
      saveRouteToServer,
      smartSaveRouteToServer,
      clearRouteStops,
      panMapTo,
      onDropStop,
      allowDrop,
      onMove,
      showStopMenu,
      findItem,
      endDrop,
      totalStop,
      companyManager,
      isXSmall,
      location,
      ...rest
    } = this.props

    return (
      <div onKeyUp={(evt) => onKeyUp(evt)} className={classes.container}>
        <div className={classes.mapInfo}>
          <MapInfo
            tripLength={tripLength}
            routeStops={routeStops}
            route={route}
            createNewStopMarker={createNewStopMarker}
            saveRouteToServer={saveRouteToServer}
            smartSaveRouteToServer={smartSaveRouteToServer}
            clearRouteStops={clearRouteStops}
            panMapTo={panMapTo}
            onDropStop={onDropStop}
            allowDrop={allowDrop}
            onMove={onMove}
            showStopMenu={showStopMenu}
            findItem={findItem}
            endDrop={endDrop}
            totalStop={totalStop}
            companyManager={companyManager}
            isXSmall={isXSmall}
          />
        </div>
        <OSM className={classes.map} ref={this.mapRef} {...rest}>
          <ZoomControl position="topright" />
          <ScaleControl position="bottomright" />
          {renderLayers && renderLayers()}
        </OSM>
        <NetworkContextMenu />
      </div>
    )
  }
}

Map.propTypes = {
  renderLayers: PropTypes.func,
  center: PropTypes.arrayOf(PropTypes.number),
  zoom: PropTypes.number,
  style: PropTypes.object,
}

Map.defaultProps = {
  center: [21.02, 105.84], // Ha Noi
  zoom: 14,
  map: {}
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect((state) => {
    let { center, zoom } = state.map
    return { center, zoom }
  }, { mapUpdate, openContextMenu }),
)

export default enhance(Map)
