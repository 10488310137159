import React, { Component } from 'react'
import {
  SimpleForm,
  translate,
  Title,
} from 'react-admin'
import { DatePickerInput } from '../common/DatePicker'
import { Provider } from '../provider'
import { compose } from 'recompose'
import {
  Card,
  CardContent,
  Grid,
  Fade,
  withStyles,
  ListItem,
  ListItemText,
  Avatar,
  Divider,
  CircularProgress,
  List as MuiList,
} from '@material-ui/core'
import _ from 'lodash'
import CalendarTimeline from '../common/calendar-timeline'
import MenuAction from '../common/MenuAction'
import { faBus, faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SeatIcon, DriverIcon } from '../icons'
import Snackbar from '../common/Snackbar'
import { dateFormat, timeFormat, moment } from '../common/format'
import { withRouter } from 'react-router-dom'
import { changeBreadcrumb } from '../breadcrumb/action'
import { connect } from 'react-redux'
import { parseUrl } from 'query-string'
import { grey } from '@material-ui/core/colors'
import { MAIN_PADDING, PAPER_PADDING } from '../common/constants'
import containerResizeDetector from 'react-calendar-timeline/lib/resize-detector/container'
import { statusStyles } from '../viewtrip/StatusField'
import { statusMapping } from '../trip/tripFunctions'

const styles = () => ({
  assignButton: {
    margin: 4
  },
  filterContainer: {
    display: 'block'
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 100,
  },
})

const TripMenu = translate(({ classes, anchorEl, open, trip, translate }) => (
  <MenuAction
    anchorEl={anchorEl}
    open={open}
    placement="right-start"
    component={
      <Fade timeout={350}>
        <Card>
          <MuiList>
            <ListItem>
              <Avatar className={classes.bigAvatar}>
                {trip.departureTime.format(timeFormat)}
              </Avatar>
              <ListItemText
                primary={trip.departure}
                secondary={trip.departureTime.format(dateFormat)}
              />
            </ListItem>
            <ListItem>
              <Avatar className={classes.bigAvatar}>
                {trip.arrivalTime.format(timeFormat)}
              </Avatar>
              <ListItemText
                primary={trip.arrival}
                secondary={trip.arrivalTime.format(dateFormat)}
              />
            </ListItem>
          </MuiList>
          <Divider />
          <MuiList>
            <ListItem>
              <Avatar>
                <FontAwesomeIcon icon={faInfoCircle} fontSize="small" color="white" />
              </Avatar>
              <ListItemText primary={translate(statusMapping[trip.status])} />
            </ListItem>
            <ListItem>
              <Avatar>
                <FontAwesomeIcon icon={faBus} fontSize="small" color="white" />
              </Avatar>
              <ListItemText primary={trip.vehicle && trip.vehicle.plate} />
            </ListItem>
            <ListItem>
              <Avatar>
                <SeatIcon />
              </Avatar>
              <ListItemText primary={`Còn ${trip.capacity - trip.reservedSeats}/${trip.capacity} chỗ`} />
            </ListItem>
            <ListItem>
              <Avatar>
                <DriverIcon />
              </Avatar>
              <ListItemText primary={(trip.driver && trip.driver.fullName) || translate('resources.drivers.unassign_driver_1')}/>
            </ListItem>
            <ListItem>
              <Avatar>
                <DriverIcon />
              </Avatar>
              <ListItemText primary={(trip.driver2 && trip.driver2.fullName) || translate('resources.drivers.unassign_driver_2')} />
            </ListItem>
          </MuiList>
        </Card>
      </Fade>
    }
  />
))

class TripsTimeline extends Component {
  state = {
    isOpenVehicleDrawer: true,
    prevSelectedVehicles: [],
    selectedVehicleIds: [],
    cusPhone: '',
    callLogId: 0,
  }

  scheduleInput = React.createRef()

  static getDerivedStateFromProps(nextProps, prevState) {
    let { appBarWidth } = nextProps
    let { appBarWidth: currentAppBarWidth } = prevState
    if (appBarWidth !== currentAppBarWidth) {
      currentAppBarWidth = appBarWidth
    }
    return { appBarWidth: currentAppBarWidth }
  }


  async loadTrips(fromDate, toDate) {
    this.setState({ groups: null, items: null })
    let response = await Provider.dataProvider('GET_LIST', 'trips', {
      filter: {
        path: 'list',
        where: {
          departureTime: {
            between: [fromDate, toDate],
          },
          type: {
            nin: ['90CAN']
          },
          status: { neq: '40ARC' },
        },
        fields: [
          'id',
          'departure',
          'arrival',
          'departureTime',
          'arrivalTime',
          'routeId',
          'vehicleId',
          'driverId',
          'driver2Id',
          'capacity',
          'seatAvailable',
          'reservedSeats',
          'status',
        ],
        include: [
          {
            relation: 'vehicle',
            scope: { fields: ['plate'] }
          },
          {
            relation: 'driver',
            scope: { fields: ['fullName', 'phone'] }
          },
          {
            relation: 'driver2',
            scope: { fields: ['fullName', 'phone'] }
          },
          {relation: 'route',
          scope: {fields:['id','routeGroupId'],
          include:[{relation:'routeGroup',
          scope:{
            fields:['id','type']}}]}}
        ]
      },
      sort: {},
      pagination: { page: 1, perPage: 5000, }
    })

    if (response) {
      let min = 'z', max = ''
      let trips = response.data

      trips = _.filter(trips, (trip) => {
        return _.get(trip, 'route.routeGroup.type') === '00BUS' 
      })
      let items = _.reduce(
        trips,
        (result = [], trip) => {
          let {
            id,
            routeId,
            departureTime,
            arrivalTime,
            vehicleId,
            departure,
            arrival,
          } = trip
          if(vehicleId) {
            if (departureTime < min) min = departureTime
            if (arrivalTime > max) max = arrivalTime
            trip.departureTime = departureTime = moment(departureTime)          
            trip.arrivalTime = arrivalTime = moment(arrivalTime)
            result.push({
              id,
              canMove: false,
              canResize: false,
              start_time: departureTime.toDate().getTime(),
              end_time: arrivalTime.toDate().getTime(),
              group: routeId,
              item: trip,
              routeId,
              vehicleId,
              departure,
              arrival,
            })
          }
          return result
        },
        []
      )
      trips = _.groupBy(items, 'routeId')
      let groups = _.reduce(Object.keys(trips), (result = [], id) => {
        let trip = trips[id][0]
        let { routeId, vehicleId, vehicle, departure, arrival } = trip
        if (vehicleId) {
          result.push({
            id,
            routeId,
            vehicleId,
            route: `${departure} - ${arrival}`,
            vehicle: vehicle ? vehicle.plate : '',
            title: `${departure} - ${arrival}`,
          })
        }
        return result
      }, [])
      min = moment(min).subtract(30, 'minute')
      max = moment(max).add(30, 'minute')
      this.setState({ groups, trips, items, min, max })
    }
  }

  handleTooltip = (hoverItem, open) => event => {
    const { currentTarget } = event
    this.setState({
      anchorEl: currentTarget,
      displayTooltip: open,
      hoverItem,
    })
  }

  onClick = (item) => () => {
    let { history } = this.props
    let { cusPhone, callLogId } = this.state

    let searchs = []
    if (cusPhone) {
      searchs.push(`customer_phone=${cusPhone}`)
    }
    if (callLogId) {
      searchs.push(`call_log=${callLogId}`)
    }
    localStorage.setItem('prevUrl', '/reservations/company_create')
    history.push({
      pathname: `/reservations/trip_layout/${item.id}`,
      search: searchs && searchs.length > 0 ? searchs.join('&') : ''
    })
  }

  itemRenderer = ({
    item,
    itemContext,
    getItemProps,
  }) => {
    let { translate } = this.props
    let opacity = 1
    let { item: itemDetail = {} } = item
    let { reservedSeats = '-', capacity = '-', status } = itemDetail
    let bgColor = statusStyles[status] ?
      statusStyles[status].backgroundColor :
      statusStyles['status'].backgroundColor
    if (reservedSeats === capacity) {
      bgColor = grey[500]
    }
    const background = bgColor
    const borderColor = itemContext.selected
      ? itemContext.dragging
        ? 'black'
        : (item.selectedBgColor || 'black')
      : item.color
    const borderWidth = itemContext.selected ? 1 : 0
    const border = `1px solid ${item.bgColor}`
    let content = reservedSeats > 0 && `${reservedSeats}/${capacity} ${translate('resources.reservations.place')}`
    return (
      <div
        {...getItemProps({
          style: {
            background,
            color: item.color,
            border,
            borderColor,
            borderStyle: 'solid',
            borderWidth,
            borderRadius: 4,
            opacity,
          },
        })}
        onMouseEnter={this.handleTooltip(item, true)}
        onMouseLeave={this.handleTooltip(item, false)}
        onClick={this.onClick(item)}
      >
        <div
          style={{
            height: itemContext.dimensions.height,
            overflow: 'hidden',
            //paddingLeft: 3,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            textAlign: 'center'
          }}
        >
          {content}
        </div>
      </div>
    )
  }

  onChangeBreadcrumb = () => {
    let { changeBreadcrumb, translate } = this.props
    let paths = [
      { label: translate('resources.reservations.name'), to: '/reservations' },
      { label: translate('resources.reservations.company_create'), to: '' },
    ]
    changeBreadcrumb({ paths })
  }

  async componentDidMount() {
    let { location, date } = this.props
    let url = parseUrl(location.search)
    let query = url.query
    if (query) {
      let cusPhone= query['customer_phone']
      let callLogId = query['call_log']
      this.setState({ cusPhone, callLogId })
    }
    this.onChangeBreadcrumb()
    await this.loadTrips(moment(date).startOf('day'), moment(date).endOf('day'))
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { date: prevDate, location: prevLocation } = prevProps
    let { date, location } = this.props
    const updated = !(prevDate === date && prevLocation === location)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { date } = this.props
      this.onChangeBreadcrumb()
      await this.loadTrips(moment(date).startOf('day'), moment(date).endOf('day'))
    }
  }

  render() {
    let {
      groups,
      items,
      hoverItem,
      min,
      max,
      appBarWidth,
    } = this.state
    const { classes, translate } = this.props
    let timelineWidth = appBarWidth - MAIN_PADDING - PAPER_PADDING
    const vehicleLabel = translate('resources.vehicles.name', { smart_count: 2 })
    return (
      <>
      <Title title={translate('resources.reservations.company_create')} />
      <Card>
        <CardContent>
          <Grid className={classes.filterContainer} container spacing={8}>
            <Grid item xs={5} md={5}>
              <SimpleForm toolbar={null} form="RESERVATION_CREATE_FILTER">
                <DatePickerInput
                  keyboard={true}
                  label={translate('resources.trips.fields.date')}
                  source="date"
                  dateFormat={dateFormat}
                  showLunarDate={true}
                  onChange={(e, val) => {
                    this.loadTrips(val.startOf('day'), val.endOf('day'))
                  }}
                  fullWidth
                />
              </SimpleForm>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={12} direction="column" alignItems="center" justify="center">
            {groups && groups.length > 0 && <CalendarTimeline 
              groups={groups}
              items={items}
              sidebarContent={<p
                style={{
                  textAlign: 'center',
                  margin: '5px 0'
                }}
              >
                { vehicleLabel }
              </p>}
              stackItems
              style={{ width: timelineWidth }}
              canMove={false}
              itemRenderer={this.itemRenderer}
              headerLabelGroupHeight={0}
              visibleTimeStart={moment(min).toDate().getTime()}
              visibleTimeEnd={moment(max).toDate().getTime()}
              resizeDetector={containerResizeDetector}
            />}
            {groups && groups.length === 0 && <Snackbar
              variant="warning"
              message={translate('notification.empty_trip')}
              className={classes.fullWidth}
            />}
            {!groups && <div><CircularProgress /></div>}
          </Grid>
          {hoverItem && <TripMenu
            classes={classes}
            anchorEl={this.state.anchorEl}
            open={this.state.displayTooltip}
            trip={hoverItem.item}
          />}
        </CardContent>
      </Card>
      </>
    )
  }
}

const mapStateToProps = state => {
  let filterValues = (state.form['RESERVATION_CREATE_FILTER'] && state.form['RESERVATION_CREATE_FILTER'].values)  ? state.form['RESERVATION_CREATE_FILTER'].values : {}
  let { date } = filterValues
  let appBar = state['appBar'] || {}
  let appBarWidth = appBar.width
  return { date, appBarWidth }
}

const enhance = compose(
  withStyles(styles),
  withRouter,
  translate,
  connect(mapStateToProps, { changeBreadcrumb })
)

export default enhance(TripsTimeline)
