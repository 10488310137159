import React, { Component } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sanitize } from '../utils/commonUtil'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  },
}

class AddReservationButton extends Component {

  onClick = (e) => {
    let { selected, onAddSeat, seat } = this.props
    if (selected) {
      e.preventDefault()
      e.stopPropagation()
    }
    onAddSeat(seat)
    return true
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      ...props
    } = this.props
    let rest = sanitize(props, ['onAddSeat', 'reservationId'])
    return button ? <Button
      color={color}
      onClick={this.onClick}
      {...rest}
    >
      <div className={classes.container}>
        <FontAwesomeIcon icon={faUserPlus} className={classes.icon} />
        <span>{translate('resources.reservations.name')}</span>
      </div>
    </Button> : <Tooltip
      title={translate('resources.reservations.name')}
      enterDelay={100}
      {...rest}
    >
      <LinkField
        className={classes.iconButton}
        icon
        onClick={this.onClick}
      >
        <FontAwesomeIcon icon={faUserPlus} size="xs" />
      </LinkField>
    </Tooltip>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(AddReservationButton)
