export const CSV = 'csv'
export const TXT = 'txt'
export const EXTENSION_TXT = [CSV, TXT]
export const EXTENSION_EXCEL = ['xlsx', 'xls']

export function getExtensionFile(fileName) {
  if (fileName.indexOf('.') === -1) return ''
  let partFileNames = fileName.split('.')
  return partFileNames[partFileNames.length - 1]
}

export const readText = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsText(file)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

export const readExcelFile = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    if (rABS) {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsArrayBuffer(file)
    }

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })