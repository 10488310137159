import React, { Component, Fragment } from 'react'
import {
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import ProductDescriptionForm from './ProductDescriptionForm'

const style = {
  icon: {
    marginRight: '0.5em',
  }
}

const dialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

class _DescriptDialog extends Component {

  render() {
    let {
      open,
      onClose,
      translate,
      productId,
      classes,
    } = this.props
    return <Dialog
      open={open}
      onClose={onClose}
      disableEnforceFocus
      fullScreen
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>{translate('button.view_detail')}</span>
      </DialogTitle>
      <DialogContent>
        <ProductDescriptionForm
          productId={productId}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  }
}

const ehanceDescriptDialog = compose(translate, withStyles(dialogStyle))
const DescriptDialog = ehanceDescriptDialog(_DescriptDialog)

class SaveDescriptionButton extends Component {

  state = {
    open: false,
  }

  open = () => {
    this.setState({
      open: true,
    })
  }

  onClose = () => {
    this.setState({
      open: false,
    })
  }

  render() {
    let { translate, classes, productId } = this.props
    let { open } = this.state
    return <Fragment>
      <Button
        color='primary'
        onClick={this.open}
      >
        <InfoIcon className={classes.icon} />
        <span>{translate('button.view_detail')}</span>
      </Button>
      <DescriptDialog
        open={open}
        onClose={this.onClose}
        productId={productId}
      />
    </Fragment>
  }


}

const enhance = compose(translate, withStyles(style))

export default enhance(SaveDescriptionButton)
