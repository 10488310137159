import React, {Component} from 'react'
import {
  OutlinedInput,
  Select,
  FormControl,
  TextField,
  withStyles,
} from '@material-ui/core'
import { FormControlCustom, ToggleButtons } from './components'
import {contents, contentsRect, fontFamilies} from './utils/ticketEditor'
import {get} from 'lodash'

var MIN_SIZE = 20

const styles = () => ({
  group: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    flexWrap: 'wrap',
    gap: '10px',
    width: '100%',
  }
})

class TextEditor extends Component {
  timeout = null
  constructor (props) {
    super(props) 
    this.state = {
      selected: null,
      text: ''
    }
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { selectedSharp } = this.props
    const updated = !(selectedSharp.getAttrs() === prevProps.selectedSharp.getAttrs())
    return { updated }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {selectedSharp} = this.props
    if (snapshot.updated) {
      const attrs = selectedSharp.getAttrs()
      this.setState({selected: attrs, text: get(attrs, 'text', '')})
    }
    selectedSharp.on('transform', () => this.onTransformItem(selectedSharp))
    selectedSharp.on('dragmove', () => this.onTransformItem(selectedSharp))
  }

  onTransformItem = (selected) => {
    if (this.timeout) {
      return
    }
    this.timeout = setTimeout(() => this.handleTransform(selected), 200)
  }

  handleTransform = (selectedSharp) => {
    const attrs = selectedSharp.getAttrs()
    if (attrs.type !== 'textarea') {
      selectedSharp.setAttrs({
        width: Math.max(selectedSharp.width() * selectedSharp.scaleX(), MIN_SIZE),
        height: attrs.type !== 'textarea' && Math.max(selectedSharp.height() * selectedSharp.scaleY(), MIN_SIZE),
        scaleX: 1,
        scaleY: 1,
      })
    } else {
      selectedSharp.setAttrs({
        width: Math.max(selectedSharp.width() * selectedSharp.scaleX(), MIN_SIZE),
        scaleX: 1,
        scaleY: 1,
      })
    }
    const newAttr = {
      ...attrs,
      x: selectedSharp.x(),
      y: selectedSharp.y(),
      rotation: selectedSharp.rotation(),
      width: selectedSharp.width()
    }
    if (this.timeout) {
      this.setState({selected: newAttr})
    }
    this.timeout = null
  }

  componentWillUnmount() {
    clearTimeout(this.timeout)
    this.timeout = null
  }

  componentDidMount() {
    const {selectedSharp} = this.props
    //selectedSharp.on('transform', this.onTransformItem)
    //selectedSharp.on('dragmove', this.onTransformItem)
    const attrs = selectedSharp.getAttrs()
    this.setState({selected: attrs, text: get(attrs, 'text', '')})
  }

  onChangeContent = (e) => {
    this.setState({text: e.target.value})
    this.props.onHandleStyleText('text', e.target.value)
  }

  handleStyleItem = (name, value) => {
    const {selected} = this.state
    switch(name) {
      case 'content': {
        const mappingText = contents[value]
        this.setState({[name]: value, text: mappingText})
        this.setState({selected: {...selected,[name]: value, text: mappingText}})
        break
      }
      case 'size': {
        this.setState({selected: {...selected, width: value, height: value}})
        break
      }
      case 'fontStyle': {
        value = value.join(' ')
        this.setState({selected: {...selected,[name]: value}})
        break
      }
      default: {
        this.setState({selected: {...selected,[name]: value}})
      }
    }
    value = (!isNaN(value) && value) ? Number(value) : value
    this.props.onHandleStyleText(name, value)
  }

  render () {
    const {selectedId, classes, selectedSharp} = this.props
    const {selected = {}, text} = this.state
    const {type = '', content} = selected || {}
    const choices = type === 'textarea' ? contents : contentsRect
    return <>
      <div className={classes.group}>
        <FormControlCustom unit="px" label="resources.editor.fields.x">
          <TextField
            id="x"
            type="number"
            name="x" 
            onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
            value={selected ? Number(selected.x).toFixed(2) : 0}
            variant="outlined"
            size="small"
            fullWidth
          />
        </FormControlCustom>
        <FormControlCustom unit="px" label="resources.editor.fields.y">
          <TextField
            id="y"
            type="number"
            name="y" 
            onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
            value={selected ? Number(selected.y).toFixed(2) : 0}
            variant="outlined"
            size="small"
            fullWidth
          />
        </FormControlCustom>
      </div>
      <div className={classes.group}>
        <FormControlCustom unit="px" label={`resources.editor.fields.${type === 'textarea' ? 'width' : 'size'}`}>
          <TextField
            id={type === 'textarea' ? 'width' : 'size'}
            type="number"
            name={type === 'textarea' ? 'width' : 'size'} 
            onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
            value={selected ? Number(selected.width).toFixed(2) : 0}
            variant="outlined"
            size="small"
            fullWidth
          />
        </FormControlCustom>
        <FormControlCustom label="resources.editor.fields.rotation">
          <TextField
            id="rotation"
            type="number"
            name="rotation" 
            onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
            value={selected ? Number(selected.rotation).toFixed(2) : 0}
            variant="outlined"
            size="small"
            fullWidth
          />
        </FormControlCustom>
      </div>
      {type === 'textarea' && 
      <>
        <div className={classes.group}>
          <FormControlCustom unit="px" label="resources.editor.fields.fontSize">
            <TextField
              id="fontSize"
              inputProps={{ min:'0', max:'99' }}
              type="number"
              name="fontSize" 
              onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
              value={selected ? Number(selected.fontSize) : 0}
              variant="outlined"
              size="small"
              fullWidth
            />
          </FormControlCustom>
          <FormControlCustom label="resources.editor.fields.align">
            <ToggleButtons 
              type='align'
              handleStyleItem={this.handleStyleItem} 
              value={get(selected, 'align', '')}
            />
          </FormControlCustom>
        </div>
        <div className={classes.group}>
          <FormControlCustom label="resources.editor.fields.fontFamily">
            <FormControl fullWidth variant="outlined" size="small" >
              <Select
                native
                name="fontFamily"
                value={get(selected, 'fontFamily', '')}
                onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
                fullWidth
                input={<OutlinedInput labelWidth={0} name="age" id="outlined-age-simple"/>}
              >
                {fontFamilies.map((ele,idx) => <option key={idx} value={ele}>{ele}</option>)}
              </Select>
            </FormControl>
          </FormControlCustom>
          <FormControlCustom label="resources.editor.fields.fontStyle">
            <ToggleButtons 
              type='format'
              handleStyleItem={this.handleStyleItem} 
              value={get(selected, 'fontStyle', '')}
            />
          </FormControlCustom>
        </div>
        <div className={classes.group}>
          <FormControlCustom label="resources.editor.fields.formatType">
            <FormControl fullWidth variant="outlined" size="small" >
              <Select
                native
                name="formatType"
                value={get(selected, 'formatType','')}
                onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
                fullWidth
                input={<OutlinedInput labelWidth={0} name="age" id="outlined-age-simple"/>}
              >
                {['string', 'date', 'currency'].map((ele,idx) => <option key={idx} value={ele}>{ele}</option>)}
              </Select>
            </FormControl>
          </FormControlCustom>
          <FormControlCustom label="resources.editor.fields.color">
            <TextField
              id="color"
              type="color"
              name="color"
              onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
              value={get(selected, 'fill', '')}
              variant="outlined"
              size="small"
              fullWidth
            />
          </FormControlCustom>
        </div>
      </>
      }
      <div className={classes.group}>
        <FormControlCustom label="resources.editor.fields.content" fullWidth>
          <FormControl variant="outlined" size="small" fullWidth >
            <Select
              native
              name="content"
              id="content"
              value={get(selected, 'content', '')}
              onChange={(e) => this.handleStyleItem(e.target.name, e.target.value)}
              input={<OutlinedInput labelWidth={0} name="age" id="outlined-age-simple"/>}
              fullWidth
            >
              {Object.keys(choices).map((key, idx) => {
                return <option key={idx} value={key}>{choices[key]}</option>
              })}
            </Select>
          </FormControl>
        </FormControlCustom>
      </div>
      {content === 'other' && <TextField multiline rows="4" value={text} onChange={(e) => this.onChangeContent(e)} style={{width: '100%'}} variant="outlined" />}
    </>
  }
}

export default withStyles(styles)(TextEditor)
