import React, { Component, Fragment } from 'react'
import {
  Button,
  withStyles,
  Drawer,
  IconButton,
  Grid,
} from '@material-ui/core'
import {
  translate,
  SimpleForm,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  ImageField,
  ImageInput,
  showNotification,
} from 'react-admin'
import { Provider } from '../provider'
import { DatePickerInput } from '../common/DatePicker'
import compose from 'recompose/compose'
import _ from 'lodash'
import CheckboxInput from '../common/CheckboxInput'
import ProductValidateChip from './ProductValidateChip'
import ProductCard from './ProductCard'
import QuantityInput from './QuantityInput'
import { connect } from 'react-redux'
import { validate } from './validate'
import { ProductType } from './constant'
import { reset, initialize } from 'redux-form'
import { faPlus, faArrowLeft, faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomToolbar from '../common/CustomToolbarForm'
import { getCurrentCompany } from '../utils/commonUtil'
import AddChildProductButton, { getProductFareByParentFareId } from './AddChildProductButton'
import { MIN, MAX, validateName } from '../common/validateName'
import SelectInputCustom from '../common/SelectInputCustom'

const style = {
  button: {
    marginRight: 4,
  },
  icon: {
    marginRight: '0.5em',
  },
}

const ADD_CHILD_FORM = 'add_child_form'

const formSaveStyle = {
  nonPaddingLeft: {
    paddingLeft: 0,
  },
  nonPaddingRight: {
    paddingRight: 0,
  },
}

class _FormSave extends Component {

  state = {}

  create = (data) => {
    let {
      showNotification,
      parentProductId,
      setNewChildProduct,
      onClose,
      setChildRouteProducts,
    } = this.props
    data = { ...data, parentProductId }
    Provider.dataProvider('CREATE', 'products', {
      data
    }).then(
      (res) => {
        let { data } = res
        let { id, routeProducts } = data
        let childProduct = { ...{}, [id]: routeProducts }
        setNewChildProduct(data)
        let childFareByParentFareId = getProductFareByParentFareId(id, routeProducts)
        setChildRouteProducts(childProduct, childFareByParentFareId)
        showNotification('notification.create_product_success')
        onClose()
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    )
  }

  update = data => {
    let {
      showNotification,
      setNewChildProduct,
      record,
      onClose,
      notChild,
      onEditDone,
    } = this.props
    Provider.dataProvider('UPDATE', 'products', {
      id: record.id,
      data,
    }).then(
      (res) => {
        let { data } = res
        if (notChild) {
          onEditDone(data)
        } else {
          onClose()
          setNewChildProduct(data)
        }
        showNotification('notification.update_product_success')
      }
    ).catch(
      e => {
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    )
  }

  save = (data) => {
    let { record = {} } = this.props
    let { id } = record
    if (!id) {
      this.create(data)
    } else {
      this.update(data)
    }
  }

  render() {
    let {
      translate,
      record = {},
      isCompanyManager,
      classes,
      ticketValidations,
    } = this.props 
    let extra = { resource: 'products', fullWidth: true }
    let { id } = record
    let company = getCurrentCompany()
    let companyId = company.id
    return <SimpleForm
      record={record}
      enableReinitialize={true}
      keepDirtyOnReinitialize={false}
      resource="products"
      form={ADD_CHILD_FORM}
      validate={validate}
      save={this.save}
      toolbar={<CustomToolbar />}
    >
      <TextInput source="name" validate={validateName(MIN, MAX)} {...extra} />
      <Grid container spacing={8} fullWidth>
        <Grid className={classes.nonPaddingLeft} item md={6} xs={12}>
          <DatePickerInput
            source="validFrom"
            dateFormat="DD/MM/YYYY"
            showLunarDate={true}
            keyboard={true}
            {...extra}
          />
        </Grid>
        <Grid className={classes.nonPaddingRight} item md={6} xs={12}>
          <DatePickerInput
            source="validTo"
            dateFormat="DD/MM/YYYY"
            showLunarDate={true}
            keyboard={true}
            {...extra}
          />
        </Grid>
      </Grid>
      <ReferenceInput
        reference="producttypes"
        source="type"
        disabled={!(!id)}
        sort={{ field: 'id', order: 'ASC' }}
        filter={{ id: { inq: [ProductType.ADDON, ProductType.TICKET] } }}
        validate={required()}
        {...extra}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        reference="productstatuses"
        source="status"
        sort={{ field: 'id', order: 'ASC' }}
        defaultValue='00IAT'
        {...extra}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput
        reference="taxes"
        source="taxId"
        {...extra}
        allowEmpty
        label="resources.products.fields.tax"
      >
        <SelectInputCustom
          optionText="name"
          allowEmpty
          empty={translate('resources.products.empty_tax')}
          all={translate('resources.products.no_tax')}
        />
      </ReferenceInput>
      <QuantityInput source="quantity" extra={extra} />
      <ReferenceInput
        reference="agencies"
        source="supplierId"
        filter={{
          type: { inq: ['30SUP', '40MSUP'] },
          '../belongsTo': { companyId }
        }}
        {...extra}
        allowEmpty
        label="resources.suppliers.name"
      >
        <SelectInputCustom
          optionText="name"
          allowEmpty
          empty={translate('resources.products.empty_supplier')}
          all={translate('resources.products.no_supplier')}
        />
      </ReferenceInput>
      <TextInput source="desc" {...extra} />
      <TextInput source="note" {...extra} />
      <CheckboxInput
        source="isGroup"
        label={translate('resources.products.fields.isGroup')}
      />
      <ProductValidateChip
        isCompanyManager={isCompanyManager}
        label="resources.agencyproducts.fields.commission"
        source="setting"
        method="changeCommission"
        resources="agencyproducts"
        ticketValidations={ticketValidations}
      />
      <ImageInput
        source="images"
        accept="image/*"
        multiple
        {...extra}
      >
        <ImageField source="src" />
      </ImageInput>
    </SimpleForm>
  }
}

const enhanceFormSave = compose(
  translate,
  withStyles(formSaveStyle),
  connect(null, { showNotification })
)
const FormSave = enhanceFormSave(_FormSave)

const productListStyle = {
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  icon: {
    marginRight: '0.5em',
  },
}


class _ProductList extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let { products } = nextProps
    let { products: currentProducts } = prevState
    if (products !== currentProducts) {
      return { ...prevState, products }
    }
    return { ...prevState }
  }

  render() {
    let {
      classes,
      parentProductId,
      translate,
      edit,
      setNewChildProduct,
      setChildRouteProducts,
      commissionTemplates,
      ticketValidations,
    } = this.props
    let { products } = this.state
    return products && <Grid container className={classes.root}>
      {products.map((product, idx) => {
        return <Grid key={idx} item xs={12} md={12}>
          <ProductCard
            record={product}
            parentId={parentProductId}
            commissionTemplates={commissionTemplates}
            ticketValidations={ticketValidations}
            setNewChildProduct={this.setNewChildProduct}
            addButton={<AddChildProductButton
              id={product.id}
              parentProductId={parentProductId}
              setNewChildProduct={setNewChildProduct}
              setChildRouteProducts={setChildRouteProducts}
            />}
            noChildProductEditButton={<Button
              color='primary'
              onClick={() => edit(product)}
              className={classes.button}
            >
              <span>
                <FontAwesomeIcon className={classes.icon} icon={faPen} />
                {translate('button.edit')}
              </span>
            </Button>}
          />
        </Grid>
      })}
    </Grid>
  }
}

const enhanceProductList = compose(
  translate,
  withStyles(productListStyle),
  connect(null, { showNotification }),
)
const ProductList = enhanceProductList(_ProductList)

const drawerStyle = {
  paper: {
    width: 450
  },
  titleContainer: {
    display: 'flex',
    marginBottom: 8,
    padding: 8,
  },
  title: {
    fontSize: 20,
    margin: 'auto 8px',
    flex: 1,
    fontWeight: 'bold',
  },
  icon: {
    marginRight: '0.5em',
  },
}

class _DrawerForm extends Component {

  state = {
    isUpdate: false,
  }

  componentDidMount() {
    let { record, parentValid } = this.props
    if (_.isEmpty(record)) {
      record = {
        ...parentValid,
        type: ProductType.ADDON,
        quantity: -1
      }
    }
    this.setState({ record })
  }

  changeFormCreate = () => {
    let { isUpdate } = this.state
    let { record, parentValid } = this.props
    if (_.isEmpty(record)) {
      record = {
        ...parentValid,
        type: ProductType.ADDON,
        quantity: -1
      }
    }
    this.setState({ isUpdate: !isUpdate, record })
  }

  edit = record => {
    this.setState({
      isUpdate: true,
      notChild: true,
      record,
    })
  }

  onEditDone = (data) => {
    let { updateNonChildProduct } = this.props
    updateNonChildProduct(data)
    this.setState({
      isUpdate: false,
      record: {},
    })
  }

  render() {
    let {
      classes,
      translate,
      open,
      onClose,
      setNewChildProduct,
      setChildRouteProducts,
      parentProductId,
      products,
      commissionTemplates,
      ticketValidations,
    } = this.props
    let { isUpdate, record = {}, notChild } = this.state
    let { id } = record
    let formMode = isUpdate || id
    return <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.titleContainer}>
        <span className={classes.title}>{translate('resources.products.name', { smart_count: 2 })}</span>
        {!id && <Button
          color='primary'
          onClick={this.changeFormCreate}
        >
          <span>
            <FontAwesomeIcon icon={isUpdate ? faArrowLeft : faPlus} fontSize="small" className={classes.icon} />
            {isUpdate ? translate('button.back') : translate('button.create')}
          </span>
        </Button>}
      </div>
      {formMode ? <FormSave
        record={record}
        setNewChildProduct={setNewChildProduct}
        setChildRouteProducts={setChildRouteProducts}
        onClose={onClose}
        notChild={notChild}
        onEditDone={this.onEditDone}
        parentProductId={parentProductId}
        ticketValidations={ticketValidations}
      /> : <ProductList
        commissionTemplates={commissionTemplates}
        products={products}
        setNewChildProduct={setNewChildProduct}
        setChildRouteProducts={setChildRouteProducts}
        parentProductId={parentProductId}
        ticketValidations={ticketValidations}
        edit={this.edit}
      />}
    </Drawer>
  }
}

const enhanceDrawer = compose(
  translate,
  connect(null, { showNotification }),
  withStyles(drawerStyle),
)
const DrawerForm = enhanceDrawer(_DrawerForm)

class SettingChildProductButton extends Component {

  state = {
    open: false,
  }

  openDrawer = () => {
    this.setState({ open: true })
  }

  closeDrawer = () => {
    let { reset } = this.props
    this.setState({ open: false })
    reset(ADD_CHILD_FORM)
  }

  render() {
    const {
      parentId,
      record,
      classes,
      setNewChildProduct,
      icon,
      button,
      label,
      setChildRouteProducts,
      products,
      updateNonChildProduct,
      parentValid,
      commissionTemplates,
      ticketValidations,
    } = this.props
    const { open } = this.state
    return <Fragment>
      {button ? <Button
        color='primary'
        onClick={this.openDrawer}
        className={classes.button}
      >
        <span>{icon}{label}</span>
      </Button> : <IconButton
        color='primary'
        onClick={this.openDrawer}
      >
        {icon}
      </IconButton>}
      {open && <DrawerForm
        open={open}
        record={record}
        onClose={this.closeDrawer}
        parentProductId={parentId}
        products={products}
        parentValid={parentValid}
        commissionTemplates={commissionTemplates}
        setNewChildProduct={setNewChildProduct}
        setChildRouteProducts={setChildRouteProducts}
        updateNonChildProduct={updateNonChildProduct}
        ticketValidations={ticketValidations}
      />}
    </Fragment>
  }
}

const enhance = compose(withStyles(style), translate, connect(null, { reset, initialize }))
export default enhance(SettingChildProductButton)
