import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const TripIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="m4.01497,5.10008c-1.27706,0 -2.31602,1.03896 -2.31602,2.31601l0,8.80086c0,0.76614 0.62347,1.38961 1.38961,1.38961l0.93817,0c0.11765,1.29651 1.20924,2.31602 2.53585,2.31602c1.32662,0 2.41821,-1.01951 2.53586,-2.31602l6.04516,0c0.11766,1.29651 1.21015,2.31602 2.53676,2.31602c1.32662,0 2.4182,-1.01951 2.53586,-2.31602l0.47406,0c0.76614,0 1.38961,-0.62347 1.38961,-1.38961l0,-3.218c0,-0.69341 -0.13879,-1.36831 -0.41254,-2.00661l-1.68273,-3.92727c-0.51138,-1.19414 -1.68034,-1.96499 -2.97916,-1.96499l-12.99049,0zm0.4632,2.31601l3.70562,0l0,4.63203l-4.16882,0l0,-4.16882c0,-0.25569 0.20751,-0.46321 0.4632,-0.46321zm4.63203,0l4.16883,0l0,4.63203l-4.16883,0l0,-4.63203zm6.94805,0l3.06781,0c0.00232,0.0051 0.00583,0.00894 0.00815,0.01357l1.68182,3.92728c0.09635,0.22511 0.16788,0.45634 0.22346,0.69118l-4.98124,0l0,-4.63203zm-9.49567,8.33766c0.89399,0 1.62121,0.72723 1.62121,1.62121c0,0.89398 -0.72722,1.62121 -1.62121,1.62121c-0.89398,0 -1.62121,-0.72723 -1.62121,-1.62121c0,-0.89398 0.72723,-1.62121 1.62121,-1.62121zm11.11688,0c0.89398,0 1.62121,0.72723 1.62121,1.62121c0,0.89398 -0.72723,1.62121 -1.62121,1.62121c-0.89398,0 -1.62121,-0.72723 -1.62121,-1.62121c0,-0.89398 0.72723,-1.62121 1.62121,-1.62121z"/>
    <path transform="rotate(-6.7554402351379395 20.379432678222656,18.88860702514651) " d="m20.69977,18.02471l0.41806,-0.01163c-0.16523,1.09934 -0.75246,1.48382 -1.47679,1.75105l0.26503,-0.52448c0.40985,-0.24413 0.6573,-0.62197 0.7937,-1.21494z" />
    <path transform="rotate(-6.7554402351379395 9.279829978942868,19.016189575195305) " d="m9.60017,18.15229l0.41806,-0.01163c-0.16523,1.09934 -0.75246,1.48382 -1.47679,1.75105l0.26503,-0.52448c0.40985,-0.24413 0.6573,-0.62197 0.7937,-1.21494z"/>
  </SvgIcon>
)

export default TripIcon