import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faBus } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import {
  getObjectNoLicence as getObjectNoLicenceAction,
  assignLicenceToObject as assignLicenceToObjectAction,
} from './action'
import { DriverIcon } from '../icons'
import { translate } from 'react-admin'
import { push } from 'react-router-redux'

const typeMap = {
  '00VNS': {
    object: 'resources.vehicles.name',
    resource: 'vehicles',
    optionText: 'plate',
    endText: 'button.add_vehicle'
  },
  '20VNR': {
    object: 'resources.vehicles.name',
    resource: 'vehicles',
    optionText: 'plate',
    endText: 'button.add_vehicle'
  },
  '30VRC': {
    object: 'resources.vehicles.name',
    resource: 'vehicles',
    optionText: 'plate',
    endText: 'button.add_vehicle'
  },
  '10LID': {
    object: 'resources.drivers.name',
    resource: 'drivers',
    optionText: 'fullname',
    endText: 'button.add_driver'
  }
}

class _AssignDialog extends Component {
  render() {
    let {
      open,
      handleClose,
      elements,
      record,
      typeObj,
      assignLicence,
      translate,
      push,
    } = this.props
    let { object, optionText, endText, resource } = typeObj
    let licenceId = record.id

    return (
      <Dialog
        open={open}
        onClose={() => handleClose()}
      >
        <DialogTitle>{translate(`${object}`, { smart_count: 2 })}</DialogTitle>
        <div>
          <List>
            {elements.map((element, idx) => (
              <ListItem
                button
                onClick={() => assignLicence(licenceId, element.id, resource)}
                key={idx}
              >
                <ListItemAvatar>
                  <Avatar>
                    {resource === 'vehicles'
                      ? <FontAwesomeIcon icon={faBus} size="1x" />
                      : <DriverIcon />
                    }
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={element[optionText]} />
              </ListItem>
            ))}
            <ListItem button onClick={() => push(`/${resource}/create`)}>
              <ListItemAvatar>
                <Avatar>
                  <FontAwesomeIcon icon={faPlus} size="1x" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={translate(endText)} />
            </ListItem>
          </List>
        </div>
      </Dialog>
    )
  }
}

const AssignDialog = connect(null, { push })(_AssignDialog)

class AssignButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      licenceType: '',
      elements: [],
      typeObj: {}
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { record } = nextProps
    let { type: licenceType } = record
    let { licenceType: currentLicenceType, typeObj } = prevState
    if (licenceType !== currentLicenceType) {
      currentLicenceType= licenceType
      typeObj = typeMap[licenceType]
    }
    return {
      licenceType: currentLicenceType,
      typeObj: {...typeObj}
    }
  }
  

  openDialog = () => {
    const { getObjectNoLicenceAction } = this.props
    let { typeObj } = this.state
    let { resource } = typeObj
    let done = (objects) => {
      this.setState({
        elements: objects
      })
    }
    let error = () => {
      this.setState({
        elements: []
      })
    }
    getObjectNoLicenceAction(resource, done, error)
    this.setState({
      open: true,
      typeObj
    })
  }

  assignLicence = (licenceId, resourceId, resource) => {
    let { assignLicenceToObjectAction, handleDone } = this.props
    let data = { licenceId, resourceId, resource }
    let done = () => {
      handleDone()
      this.setState({
        open: false
      })
    }
    let error = () => {
      this.setState({
        open: false
      })
    }
    assignLicenceToObjectAction(data, done, error)
  }

  handleClose = () => {
    this.setState({
      open: false
    })
  }

  render() {
    let { record, translate } = this.props
    let { open, typeObj, elements } = this.state
    return (
      <>
        <Button
          color="primary"
          onClick={this.openDialog}
        >
          {translate('button.assign')}
        </Button>
        <AssignDialog
          open={open}
          handleClose={this.handleClose}
          record={record}
          typeObj={typeObj}
          elements={elements}
          assignLicence={this.assignLicence}
          translate={translate}
        />
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let record = ownProps.record
  if (state.form && state.form['record-form']) {
    record = state.form['record-form'].values
  }
  return {
    record: record || {}
  }
}

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    {
      getObjectNoLicenceAction,
      assignLicenceToObjectAction,
    })
)

export default enhance(AssignButton)