import React, { Fragment, Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  FunctionField,
  Create,
  SimpleForm,
  Edit,
  Filter,
  translate,
  CardActions,
  NumberField,
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'
import { validate } from './validate'
import CustomToolbar from '../common/CustomToolbarForm'
import RegisterCompanyButton from './RegisterCompanyButton'
import * as permission from '../utils/permission'
import compose from 'recompose/compose'
import { withStyles, Tooltip  } from '@material-ui/core'
import ChipArrayField from '../common/ChipArrayField'
import TopUpButton from '../finance_account/TopUpButton'
import * as dataType from '../common/data-type'
import SearchInput from '../common/SearchInput'
import WithdrawButton from '../finance_account/WithdrawButton'
import EditIcon from '@material-ui/icons/Edit'
import LinkField from '../common/LinkField'
import TabHeader from './TabHeader'
import SettingMenu from '../company/SettingMenu'
import { agencyItems as items } from '../menu/items'
import SettingPosItem from '../company/SettingPosItem'

const listStyle = {
  chip: {
    margin: 8,
    width: 100,
  },
  textEnd: {
    textAlign: 'end',
  },
  textCenter: {
    textAlign: 'center',
  },
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  tabContainer: {
    padding: theme.spacing.unit * 3,
    width: '100%',
  }
})

////////////////////////////////////////////////////////////////////////////////
// Agency
const AgencyFilter = ({ isAdmin, ...props }) => (
  <Filter {...props}>
    {isAdmin && <SearchInput 
      helperText="resources.companies.helper_text"
      source="q"
      alwaysOn
    />
    }
  </Filter>
)

export const _AgencyList = translate(({ permissions, translate, classes, ...props }) => {
  let isAdmin = permission.isAdmin(permissions)
  return (
    <List
      {...props}
      filters={<AgencyFilter isAdmin={isAdmin} />}
      bulkActionButtons={false}
      filterDefaultValues={{
        '../account': true,
        '../fields': [
          'id',
          'name',
          'email',
          'tel',
          'address',
          'registrationNumber',
          'taxNumber',
          'accountId',
          'deposit',
        ],
        '../include': [
          {
            relation: 'companies',
            scope: {
              fields: ['id']
            }
          }
        ]
      }}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField 
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="email"
        />
        <TextField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="registrationNumber"
        />
        <TextField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="taxNumber"
        />
        <FunctionField
          sortable={false}
          source="tel"
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          render={record => {
            let tel = (record.tel && record.tel !== null) ? record.tel : []
            return <ChipArrayField type={dataType.PHONE} classes={classes} datum={tel} />
          }}
        />
        <FunctionField
          sortable={false}
          source="numberOfCompany"
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          render={record => {
            let companies = record && record.companies
            let count = 0
            if (companies) {
              count = companies.length
            }
            return count
          }}
        />
        <NumberField
          source="deposit"
          locales="vi-VN"
          options={{ style: 'currency', currency: 'VND' }}
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
        />
        <FunctionField
          sortable={false}
          headerClassName={classes.textEnd}
          cellClassName={classes.textEnd}
          render={record => <Fragment>
            {isAdmin && <TopUpButton 
              type="AGE"
              fromId={record.id}
              fromAccountDefault={record.accountId}
              fromRefName={record.name}
              deposit={record.deposit}
            />}
            {isAdmin && <WithdrawButton 
              type="AGE"
              fromId={record.id}
              fromAccountDefault={record.accountId}
              fromRefName={record.name}
              deposit={record.deposit}
            />}
            <LinkField
              className={classes.iconButton}
              path={`/agencies/${record.id}`}
              icon
            >
              <Tooltip title={translate('button.edit')} enterDelay={100} >
                <EditIcon fontSize="small" />
              </Tooltip>
            </LinkField>
          </Fragment>}
        />
      </Datagrid>
    </List>
  )
})

const enhanceAgencyList = compose(withStyles(listStyle), translate)

export const AgencyList = enhanceAgencyList(_AgencyList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const AgencyCreate = props => (
  <Create
    {...props}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm toolbar={<CustomToolbar />} validate={validate} >
      <FormSave />
    </SimpleForm>
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit

const EditAction = ({ id, isAdmin }) => {
  return <CardActions>
    {isAdmin && <RegisterCompanyButton id={id} />}
  </CardActions>
}

class _AgencyEdit extends Component {
  state = {
    tabIndex: 0,
    tabIndexPos: 0
  }

  onChangeTab = value => {
    this.setState({ tabIndex: value })
  }

  onChangeTabPos = value => {
    this.setState({ tabIndexPos: value })
  }

  render() {
    let { 
      classes,
      permissions, 
      redirect, 
      id, 
      resource, 
      history,
      location,
      match,
      record,
      ...props 
    } = this.props
    let { tabIndex, tabIndexPos } = this.state
    let isAdmin = permission.isAdmin(permissions)
    let isAgencyManager = permission.isAgencyManager(permissions)
    redirect = !isAgencyManager && redirect
    return <Edit
      id={id}
      undoable={false}
      title={<EditTitle resource={resource} />}
      actions={<EditAction id={id} isAdmin={isAdmin} />}
      history={history}
      location={location}
      resource={resource}
      {...props}
    >
      <SimpleForm
        toolbar={(isAgencyManager || isAdmin) ? <CustomToolbar /> : null}
        validate={validate}
        redirect={redirect}
        resource={resource}
      >
        <TabHeader
          isAdmin={isAdmin}
          isAgencyManager={isAgencyManager}
          tabIndex={this.state.tabIndex}
          onChangeTab={this.onChangeTab}
          tabIndexPos={this.state.tabIndexPos}
          onChangeTabPos={this.onChangeTabPos}
          action='update'
        />
        {tabIndex === 0 && 
          <FormSave 
            id={id} 
            isAdmin={isAdmin} 
            isAgencyManager={isAgencyManager} 
            history={history} 
          />
        }
        {isAdmin && tabIndex === 1 &&
          <SettingMenu 
            match={match} 
            location={location} 
            resources="agencysettings"
            items={items}
          />
        }
        {isAdmin && tabIndex === 2 &&
          <SettingPosItem
            match={match}
            location={location}
            resources="agencysettings"
            tabIndexPos={tabIndexPos}
            onChangePos={this.onChangeTabPos}
            isAdmin={isAdmin}
          />
        }
      </SimpleForm>
    </Edit>
  }
}
const enhanceAgencyEdit = compose(
  translate,
  withStyles(styles),
)

export const AgencyEdit = enhanceAgencyEdit(_AgencyEdit)
