import React, { Component, Fragment } from 'react'
import compose from 'recompose/compose'
import {
  translate,
  Toolbar,
  WithPermissions,
  Datagrid,
  TextField,
  FunctionField,
  showNotification,
} from 'react-admin'
import { 
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import { formatCurrency } from '../utils/formatUtil'
import * as permission from '../utils/permission'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import { getCurrentAgency } from '../utils/commonUtil'
import * as reservationStatus from '../common/reservation-status'
import DialogLoading from '../common/DialogLoading'
import _ from 'lodash'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { change as changeForm } from 'redux-form'
import moment from 'moment'

const chargeListStyle = {
  textCenter: {
    textAlign: 'center'
  },
  textEnd: {
    textAlign: 'end'
  }
}

const _ChargeList = ({ products, segments, classes }) => {
  products = products || segments
  let productArr = Object.values(products)
  let ids = _.reduce(productArr, (result, ele) => {
    let { fareId, quantity } = ele
    if (quantity > 0) {
      result.push(fareId)
    }
    return result
  }, [])
  //let ids = Object.values(products).map(ele => ele.fareId)
  return <Datagrid ids={ids} data={products} currentSort={{}}>
    <FunctionField
      label="resources.saletickets.productName"
      render={({ productName, fareName, segmentName, productType }) =>
        productType === '60SEAT_BY_SEGMENT' ? `${segmentName} ${fareName}` :  `${productName} ${fareName}`
      }
    />
    <TextField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      label="resources.saletickets.quantity"
      source="quantity"
    />
    <FunctionField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      label="resources.saletickets.unit"
      source="amount"
      render={({ amount}) => formatCurrency(amount)}
    />
    <FunctionField
      headerClassName={classes.textEnd}
      cellClassName={classes.textEnd}
      label="resources.saletickets.total"
      render={({ quantity, amount }) => {
        let total = parseFloat(quantity) * parseFloat(amount)
        return formatCurrency(total)
      }}
    />
  </Datagrid>
}

const ChargeList = compose(translate, withStyles(chargeListStyle))(_ChargeList)

const reviewDialogStyle = {
  totalRow: {
    display: 'flex',
    paddingBottom: 16,
  },
  commissionRow: {
    display: 'flex',
    paddingBottom: 16,
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
  },
  netRow: {
    display: 'flex',
    paddingTop: 16,
  },
  label: {
    fontWeight: 'bold',
    width: '40%'
  },
  value: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '60%',
    fontStyle: 'italic',
  },
  commissionValue: {
    color: '#66bb6a',
    fontWeight: 'bold',
  },
  netValue: {
    color: '#c62828',
    fontWeight: 'bold',
  },
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  container: {
    padding: 0
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 32,
    marginBottom: 32,
  },
  totalLabel: {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalValue: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
}

const _ReviewDialog = ({
  classes,
  translate,
  open,
  onOk,
  onClose,
  productFareMapping,
  total,
  agencyAmount,
  netAmount,
  isLock,
}) => {
  return <Dialog
    open={open}
    onClose={onClose}
    fullWidth
    maxWidth="sm"
  >
    <DialogTitle className={classes.title}>
      <span className={classes.textTitle}>{translate('resources.saletickets.sale_ticket_confirm')}</span>
    </DialogTitle>
    <DialogContent className={classes.container}>
      {productFareMapping && <ChargeList products={productFareMapping} />}
      <div className={classes.totalContainer}>
        <div className={classes.totalLabel}>
          <b>{translate('resources.saletickets.total')}</b>
        </div>
        <div className={classes.totalValue}>
          <b>{total ? formatCurrency(total) : '0đ'}</b>
        </div>
      </div>
      {!(!agencyAmount) && <div className={classes.totalContainer}>
        <div className={classes.totalLabel}><b>{translate('resources.saletickets.agency_commission')}</b></div>
        <div className={classnames(classes.totalValue, classes.commissionValue)}>{formatCurrency(agencyAmount)}</div>
      </div>}
      {!(!netAmount) && <div className={classes.totalContainer}>
        <div className={classes.totalLabel}><b>{translate('resources.saletickets.agency_paid_total')}</b></div>
        <div className={classnames(classes.totalValue, classes.netValue)}>{formatCurrency(netAmount)}</div>
      </div>}
    </DialogContent>
    <DialogActions>
      <Button 
        onClick={onOk}
        variant="contained"
        color="primary"
        disabled={isLock}
      >
        {translate('button.continue')}
      </Button>
      <Button onClick={onClose}>{translate('button.close')}</Button>
    </DialogActions>
  </Dialog>
}
const ReviewDialog = compose(translate, withStyles(reviewDialogStyle))(_ReviewDialog)

const agencyPayCashButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#00796b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009688'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const _AgencyPayCashButton = ({
  translate,
  handleSubmit,
  openReviewDialog,
  classes,
  deposit,
}) => {
  deposit = deposit && deposit !== null ? parseFloat(deposit) : 0
  return deposit > 0 ? <Button
    className={classes.button}
    onClick={handleSubmit(values => { openReviewDialog(values, false, true) })}
    variant="contained"
  >
    <FontAwesomeIcon icon={faMoneyBill} className={classes.icon} />
    {translate('button.pay_cash')}
  </Button> : null
}

const AgencyPayCashButton = compose(translate, withStyles(agencyPayCashButtonStyle))(_AgencyPayCashButton)

const payCashButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#00796b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009688'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const _PayCashButton = ({
  translate,
  handleSubmit,
  openReviewDialog,
  classes,
}) => {
  return <Button
    className={classes.button}
    onClick={handleSubmit(values => { openReviewDialog(values, true, true) })}
    variant="contained"
  >
    <FontAwesomeIcon icon={faMoneyBill} className={classes.icon} />
    {translate('button.pay_cash')}
  </Button>
}

const PayCashButton = compose(translate, withStyles(payCashButtonStyle))(_PayCashButton)

const customerPaymentButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#303f9f',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5c6bc0'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const _CustomerPaymentButton = ({ 
  translate,
  classes,
  handleSubmit,
  openReviewDialog,
}) => {
  return <Button
    className={classes.button}
    variant="contained"
    onClick={handleSubmit(values => { openReviewDialog(values, true, false) })}
  >
    <CreditCardIcon className={classes.icon} />
    {translate('button.paid_from_customer')}
  </Button>
}

const CustomerPaymentButton  = compose(withStyles(customerPaymentButtonStyle), translate)(_CustomerPaymentButton)

const agencyPaymentButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#303f9f',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5c6bc0'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const _AgencyPaymentButton = ({ 
  translate,
  handleSubmit,
  openReviewDialog,
  classes,
}) => {
  return <Button
    className={classes.button}
    variant="contained"
    onClick={handleSubmit(values => { openReviewDialog(values, false, false) })}
  >
    <CreditCardIcon className={classes.icon} />
    {translate('button.paid_from_agency')}
  </Button>
}

const AgencyPaymentButton  = compose(translate, withStyles(agencyPaymentButtonStyle))(_AgencyPaymentButton)

class SaleTicketToolbar extends Component {

  state = {
    open: false,
    loading: false,
  }

  async componentDidMount() {
    let cashMethodId = await this.getPaymentMethod() 
    let currentDeposit = await this.getDeposit()
    this.setState({ cashMethodId, currentDeposit })
  }
  
  getPaymentMethod = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'paymentMethods', {
      filter: { code: 'CASH' },
      pagination: {},
      sort: {}
    })
    if (res && res.data) {
      let cashMethod = res.data[0]
      let cashMethodId = cashMethod.id
      return cashMethodId
    }
  }

  getDeposit = async () => {
    let res = await Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: '/getDeposit',
      requestMethod: 'GET',
    })
    if (res && res.data) {
      return res.data
    }
  }

  openReviewDialog = async (value, paidFromCustomer, isPayCash) => {
    let { reservation, total, agencyAmount, netAmount } = await this.buildReservation(value, paidFromCustomer, isPayCash)
    let paidTotal = netAmount || total
    this.setState({
      open: true,
      reservation,
      total,
      agencyAmount,
      netAmount,
      isPayCash,
      paidTotal,
    })
  }

  onClose = () => {
    this.setState({
      open: false,
      reservation: {},
      total: 0,
      agencyAmount: 0,
      netAmount: 0,
      isPayCash: false,
    })
  }

  buildTransactionPaidCash = (total) => {
    let { cashMethodId } = this.state
    let transaction = {
      paid: total,
      total,
      paymentMethodId: cashMethodId,
      currencyId: 1,
      type: '00NORMAL',
      status: '20DONE',
    }
    return transaction
  }

  getTotalPaid = () => {
    let { totalFareMapping } = this.props
    let total = totalFareMapping && _.reduce(Object.values(totalFareMapping), (sum, ele) => {
      let { total } = ele
      sum += total
      return sum
    }, 0)
    return total
  }

  valid = () => {
    let { totalQuantity } = this.state
    let isValid = true
    if (totalQuantity === 0) {
      isValid = false
    }
    return isValid
  }

  getReservationNetPrice = async (charges) => {
    let totalRes = await Provider.dataProvider('REMOTE', 'reservations', {
      method: 'getReservationNetPrice',
      requestMethod: 'POST',
      data: { charges }
    })
    if (totalRes && totalRes.data) {
      let { data } = totalRes
      return data
    }
  }

  buildReservation = async (value, paidFromCustomer, isPayCash) => {
    let currentAgency = getCurrentAgency() || {}
    let { productFareMapping, productFareInput } = this.props
    let { note, phone, fullName, email, departureDate, routeId } = value
    let bookingInformation, contact
    if (phone || email) {
      contact = { phone, fullName, email }
    }

    if (contact || note) {
      bookingInformation = { contact, note }
    }
    let charges = []
    let transactions = []
    let index = 0
    let status = reservationStatus.NEW
    let isValid = this.valid()
    if (!isValid) return
    let data
    let total = 0
    let agencyAmount = 0
    let netAmount = 0
    let agencyKeepCommission = false
    if (productFareMapping) {
      for (let fareId in productFareMapping) {
        let { productType, productId, quantity, amount, segmentId, segmentName, } = productFareMapping[fareId]
        let input = _.get(productFareInput, productId, {})
        if (quantity > 0) {
          for (let i = 0; i < quantity; i++) {
            if (productType !== '00SEAT') {
              let startTime = _.get(input, 'clientParams.startTime')
              departureDate = startTime ? moment(startTime).startOf('day') : (departureDate ? moment(departureDate).startOf('day') : moment().startOf('day'))
            }
            let charge = {
              index,
              type: '10NO_REFUND',
              itemDetail: {
                productId,
                productType,
                fareId,
                routeId,
                departureDate,
                clientParams: input.clientParams,
                children: input.children,
                segmentName,
                segmentId,
              },
              amount,
            }
            charges.push(charge)
            index++
          }
        }
      }
      if (paidFromCustomer) {
        total = this.getTotalPaid()
      } else {
        if (isPayCash) {
          total = this.getTotalPaid()
        } else {
          agencyKeepCommission = true
          let reservationNetPrice = await this.getReservationNetPrice(charges)
          if (reservationNetPrice) {
            total = reservationNetPrice.total
            agencyAmount = reservationNetPrice.agency
            netAmount = reservationNetPrice.net
          }
        }
      }
      if (isPayCash) {
        status = reservationStatus.CONFIRMED
        let transaction = this.buildTransactionPaidCash(total)
        transactions.push(transaction)
      }
      data = {
        passengers: [],
        trips: [],
        charges,
        bookingInformation,
        transactions,
        source: '10WEBADMIN',
        status,
        agencyId: currentAgency.id,
        agencyKeepCommission,
      }
    }
    return { reservation: data, total, agencyAmount, netAmount }
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  requestOnlinePayment = async (reservationId, amount, source) => {
    let currentHost = window.location.origin
    let pathname = `/reservations/${reservationId}/show`
    let returnUrl = `${currentHost}${pathname}`
    let resp = await Provider.dataProvider('REMOTE', 'payments', {
      method: 'card-request',
      requestMethod: 'POST',
      data: {
        reservationId,
        amount,
        source,
        orderType: 'bill payment',
        returnUrl,
      }
    })
    if (resp && resp.data) {
      let url = _.get(resp.data, 'data')
      if (url) {
        window.location.href = url
      }
    }
  }

  resetFields = (fields) => {
    let { changeForm, form } = this.props
    if (!fields || fields.length < 0) return
    for (let i = 0; i < fields.length; i++) {
      let field = fields[i] 
      changeForm(form, field, '')
    }
  }

  makeReservation = () => {
    let { showNotification, push } = this.props
    this.startLoading()
    let { reservation, paidTotal, isPayCash } = this.state
    Provider.dataProvider('REMOTE', 'reservations', {
      method: 'make',
      requestMethod: 'POST',
      data: reservation,
    }).then( async res => {
      if (res.data) {
        let reservation = _.get(res.data, 'data', {})
        let { id, source} = reservation
        if (!isPayCash) {
          await this.requestOnlinePayment(id, paidTotal, source)
        } else {
          let pathname = `/reservations/${id}/show`
          push(pathname)
          this.endLoading()
        }
        this.resetFields(['fullName', 'phone', 'email', 'note'])
      }
    }).catch((e) => {
      this.endLoading()
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }

  render() {
    let { 
      handleSubmit,
      permissions,
      productFareMapping,
      segmentFareMapping,
    } = this.props
    let { open, total, loading, agencyAmount, netAmount, currentDeposit } = this.state
    let isCompanyRole = permission.hasOneOfPermission(permissions, 'company-manager', 'company-employee', 'company-supplier', 'accounting', 'morniting') 
    let isAgencyRole = permission.isAgencyRole(permissions) 
    return <Toolbar>
      {isCompanyRole &&<Fragment>
        <CustomerPaymentButton
          handleSubmit={handleSubmit}
          openReviewDialog={this.openReviewDialog}
        />
        <PayCashButton 
          handleSubmit={handleSubmit}
          openReviewDialog={this.openReviewDialog}
        />
      </Fragment>}
      {isAgencyRole && <Fragment>
        <AgencyPaymentButton 
          handleSubmit={handleSubmit}
          openReviewDialog={this.openReviewDialog}
        />
        <AgencyPayCashButton
          handleSubmit={handleSubmit}
          openReviewDialog={this.openReviewDialog}
          deposit={currentDeposit}
        />
      </Fragment>}
      {open && <ReviewDialog
        open={open}
        onOk={this.makeReservation}
        total={total}
        agencyAmount={agencyAmount}
        netAmount={netAmount}
        productFareMapping={productFareMapping}
        segmentFareMapping={segmentFareMapping}
        onClose={this.onClose}
        isLock={loading}
      />}
      {loading && <DialogLoading open={loading} />}
    </Toolbar>
  }
}

const SaleTicketToolbarWithPermissions = props => <WithPermissions
  render={({ permissions }) => <SaleTicketToolbar permissions={permissions} {...props} />}
/>

const enhance = compose(connect(null, { showNotification, push, changeForm }))
export default enhance(SaleTicketToolbarWithPermissions)
