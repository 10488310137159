import React, { Component, Fragment } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import ProductRouteForm from './ProductRouteForm'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { reset } from 'redux-form'
import { PriceIcon } from '../icons'

const dialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

class _AssignDialog extends Component {
  render() {
    let {
      open,
      onClose,
      translate,
      onDone,
      onError,
      record = {},
      action,
      form,
      productId,
      classes,
    } = this.props
    return <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      disableEnforceFocus
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>{translate('button.register_fare')}</span>
      </DialogTitle>
      <DialogContent>
        <ProductRouteForm 
          onDone={onDone}
          onError={onError}
          record={record}
          action={action}
          form={form}
          productId={productId}
        />
      </DialogContent>
    </Dialog>
  }
}

const enhanceDialog = compose(withStyles(dialogStyle), translate)
export const AssignDialog = enhanceDialog(_AssignDialog)

const PRODUCT_ROUTE_FORM = 'product_route_form'

const style = {
  button: {
    marginRight: 4,
  },
  icon: {
    marginRight: '0.5em',
  },
}

class RegisterRouteButton extends Component {

  state = {
    open: false,
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    let { reset } = this.props
    this.setState({ open: false })
    reset(PRODUCT_ROUTE_FORM)
  }

  onSaveDone = (data) => {
    let { showNotification, setNewRoute } = this.props
    this.closeDialog()
    setNewRoute(data)
    showNotification('notification.register_fare_success')
  }

  onSaveError = () => {
    let { showNotification } = this.props
    this.closeDialog()
    showNotification('notification.register_fare_failure', 'warning')
  }

  render() {
    let { translate, classes, productId } = this.props
    let { open } = this.state
    return <Fragment>
      <Button
        color='primary'
        onClick={this.openDialog}
        className={classes.button}
      >
        <PriceIcon fontSize="small" className={classes.icon} />
        {translate('button.register_fare')}
      </Button>
      {open && <AssignDialog
        open={open}
        onClose={this.closeDialog}
        onDone={this.onSaveDone}
        onError={this.onSaveError}
        productId={productId}
        action="create"
        form={PRODUCT_ROUTE_FORM}
      />}
    </Fragment>
  }
}

const enhance = compose(
  translate,
  withStyles(style),
  connect(null, { showNotification, refreshView, reset }))

export default enhance(RegisterRouteButton)
