import React, { Component } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'
import { ROOT_URL } from '../provider/data'
import moment from 'moment'
import { translate, showNotification } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import GetAppIcon from '@material-ui/icons/GetApp'
import { connect } from 'react-redux'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  },
  colorPrimary: {
    color: '#3f51b5'
  },
}

class ExportProductChargeButton extends Component {

  export = () => {
    let { filterValues } = this.props
    let { createdAt_gte, createdAt_lte, fromDate_between, ...restFilterValues } = filterValues

    createdAt_gte = createdAt_gte || moment().startOf('days').toDate().toISOString()
    createdAt_lte = createdAt_lte || moment().add(7, 'days').endOf('days').toDate().toISOString()

    let fromDateStartDay = fromDate_between ? moment(fromDate_between).startOf('day').toISOString() : undefined
    let fromDateEndDay = fromDate_between ? moment(fromDate_between).endOf('day').toISOString() : undefined

    let between
    if (fromDateStartDay) {
      between = [fromDateStartDay]
    }
    if (fromDateEndDay) {
      between.push(fromDateEndDay)
    }

    let filter = {
      where: {
        and: [
          {createdAt: { gte: createdAt_gte }},
          {createdAt: { lte: createdAt_lte }}
        ],
        firstUse: between ? { between } : undefined,
        ...restFilterValues 
      }
    }

    const token = localStorage.getItem('token')
    window.location = `${ROOT_URL}/productcharges/export?filter=${encodeURIComponent(JSON.stringify(filter))}&access_token=${token}`
  }

  renderComponent = () => {
    let { classes, disabled, translate, type, button, color, ...props } = this.props
    let style = { color: !disabled && '#3f51b5' }
    switch (type) {
      case 'button':
        return <Button
          color={color}
          onClick={this.export}
          className={classes.button}
          disabled={disabled}
          {...props}
        >
          <span>
            <GetAppIcon className={classes.icon} fontSize="small" />
            <span>{translate('button.export')}</span>
          </span>
        </Button>
      case 'menuItem':
        return <MenuItem
          onClick={this.export}
          disabled={disabled}
          color={color}
          style={style}
          {...props}
        >
          <ListItemIcon style={style}>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          <span>{translate('button.export')}</span>
        </MenuItem>
      default:
        return <Tooltip
          title={translate('button.export')}
          enterDelay={100}
        >
          <LinkField
            className={classes.iconButton}
            icon
            color={color}
            onClick={this.export}
            disabled={disabled}
            {...props}
          >
            <GetAppIcon fontSize="small" />
          </LinkField>
        </Tooltip>
    }
  }

  render() {
    return this.renderComponent()
  }
}

const enhance = compose(withStyles(styles), translate, connect(null, { showNotification }))

export default enhance(ExportProductChargeButton)
