import React, { Component, Fragment } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  withStyles,
} from '@material-ui/core'
import { translate, showNotification, refreshView } from 'react-admin'
import AgencyProductForm from './AgencyProductForm'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { reset } from 'redux-form'
import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const style = {
  button: {
    marginRight: 4,
  },
  icon: {
    marginRight: '0.5em',
  },
}

export class AssignDialog extends Component {
  render() {
    let {
      open,
      onClose,
      translate,
      onDone,
      onError,
      record = {},
      action,
      form,
      productId,
      commissionTemplates
    } = this.props
    return <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{translate('button.register_agency')}</DialogTitle>
      <DialogContent>
        <AgencyProductForm 
          onDone={onDone}
          onError={onError}
          record={record}
          action={action}
          form={form}
          productId={productId}
          commissionTemplates={commissionTemplates}
        />
      </DialogContent>
    </Dialog>
  }
}

const AGENCY_PRODUCT_FORM = 'agency_product_form'

class RegisterAgencyButton extends Component {

  state = {
    open: false,
  }

  openDialog = () => {
    this.setState({ open: true })
  }

  closeDialog = () => {
    let { reset } = this.props
    this.setState({ open: false })
    reset(AGENCY_PRODUCT_FORM)
  }

  onSaveDone = (data) => {
    let { showNotification, setNewAgency } = this.props
    this.closeDialog()
    setNewAgency(data)
    showNotification('notification.register_agency_success')
  }

  onSaveError = () => {
    let { showNotification } = this.props
    this.closeDialog()
    showNotification('notification.register_agency_failure', 'warning')
  }

  render() {
    let { translate, commissionTemplates, productId, classes } = this.props
    let { open } = this.state
    return <Fragment>
      <Button
        color='primary'
        onClick={this.openDialog}
      >
        <FontAwesomeIcon className={classes.icon} icon={faBuilding} />
        {translate('button.register_agency')}
      </Button>
      {open && <AssignDialog
        open={open}
        onClose={this.closeDialog}
        translate={translate}
        onDone={this.onSaveDone}
        onError={this.onSaveError}
        productId={productId}
        commissionTemplates={commissionTemplates}
        action="create"
        form={AGENCY_PRODUCT_FORM}
      />}
    </Fragment>
  }
}

const enhance = compose(translate, withStyles(style), connect(null, { showNotification, refreshView, reset }))
export default enhance(RegisterAgencyButton)
