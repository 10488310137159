import React, { Component } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Edit,
  FunctionField,
  translate,
  Filter,
  ReferenceInput,
  ReferenceField,
} from 'react-admin'
import { EditTitle } from '../common/Title'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import moment from 'moment'
import FormSave from './FormSave'
import { withStyles, Chip } from '@material-ui/core'
import compose from 'recompose/compose'
import { getCurrentCompany, getCurrentCompanyIdsByAgency } from '../utils/commonUtil'
import { StatusField } from './StatusField'
import * as ReservationStatus from '../common/reservation-status'
import { formatCurrency } from '../utils/formatUtil'
import SelectInputCustom from '../common/SelectInputCustom'

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
  },
  otherStatus: {
    marginBottom: 8,
    marginTop: 16,
    minWidth: 100,
  }
})

class _PosSessionListFilter extends Component {
  state = {}

  validate = values => {
    const minDate = moment(values.startAt_gte).endOf('days').toDate().toISOString()
    if (values.startAt_lte < minDate) {
      values.startAt_lte = minDate
    }
    if (this.state.fromDate !== minDate) {
      this.setState({ fromDate: minDate })
    }
  }

  render() {
    let { classes, translate, ...props } = this.props
    let { fromDate } = this.state
    let currentCompany = getCurrentCompany()
    let operatorIds
    if (currentCompany) {
      operatorIds = [currentCompany.id]
    } else {
      operatorIds = getCurrentCompanyIdsByAgency()
    }
    return <Filter
      validate={this.validate}
      {...props}
    >
      <DateTimePickerInput
        source="startAt_gte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <DateTimePickerInput
        source="startAt_lte"
        dateFormat="DD/MM/YYYY HH:mm"
        ampm={false}
        showLunarDate={true}
        keyboard={true}
        minDate={fromDate}
        allowEmpty
        pickerType='datetime'
        alwaysOn
      />
      <ReferenceInput
        reference="devices"
        source="deviceId"
        filter={{ operatorId: { inq: operatorIds } }}
        sort={{ field: 'id', order: 'ASC' }}
        fullWidth
        alwaysOn
      >
        <FuzzySelectInput
          optionText="serial"
          renderItem={ ({ serial }) => serial } {...defaultFuzzySearch({ name: 'serial' })}
        />
      </ReferenceInput>
      <ReferenceInput
        reference="possessionstatuses"
        source="status"
        alwaysOn
        allowEmpty
        label="resources.possessions.fields.status"
        classExtra={classes.otherStatus}
      >
        <SelectInputCustom
          optionText="name"
          all={translate('resources.common.all')}
        />
      </ReferenceInput>
    </Filter>
  }
}

const enhanceListFilter = compose(translate, withStyles(styleListFilter))
const PosSessionListFilter = enhanceListFilter(_PosSessionListFilter)


////////////////////////////////////////////////////////////////////////////////
// PosSession
const styleList = () => ({
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  avatarCol: {
    display: 'flex'
  },
  name: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
})

// List
class _PosSessionList extends Component  {

  render () {
    let { translate, classes, history, permissions, ...props } = this.props
    return <List
      filters={<PosSessionListFilter />}
      bulkActionButtons={false}
      history={history}
      filter={{
        '../order': 'startAt DESC',
        'fields': [
          'id',
          'name',
          'startAt',
          'closeAt',
          'status',
          'deviceId',
          'createdBy',
          'totalPaymentAmount',
        ]
      }}
      filterDefaultValues={{
        startAt_gte: moment().startOf('days').toDate().toISOString(),
        startAt_lte: moment().add(7, 'days').endOf('days').toDate().toISOString(),
      }}
      {...props}
    >
      <Datagrid rowClick={'edit'}>
        <TextField source="name"/>
        <FunctionField
          source="startAt"
          render={({ startAt }) => moment(startAt).format('HH:mm DD/MM/YYYY')}
        />
        <ReferenceField
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
          source="deviceId"
          reference="devices"
          linkType={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          reference="users"
          source="createdBy"
          allowEmpty
          linkType={false}
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
        >
          <TextField source="username" />
        </ReferenceField>
        <FunctionField
          source="closeAt"
          render={({ closeAt }) => closeAt ? moment(closeAt).format('HH:mm DD/MM/YYYY') : null}
        />
        <ReferenceField
          source="status"
          basePath="possessionstatuses"
          reference="possessionstatuses"
          linkType={false}
          allowEmpty
          headerClassName={classes.textCenter}
          cellClassName={classes.textCenter}
        >
          <StatusField source="name" />
        </ReferenceField>
        <FunctionField
          source="totalPaymentAmount"
          headerClassName={classes.textEnd}
          cellClassName={classes.textEnd}
          sortable={false}
          render={(record) => {
            let { totalPaymentAmount, status } = record
            let debtStyle = ReservationStatus.outlineStyle[status]
            return <Chip
              variant="outlined"
              style={debtStyle}
              label={totalPaymentAmount ? formatCurrency(totalPaymentAmount) : '0đ'}
            />
          }}
        />
      </Datagrid>
    </List>
  }
}


export const PosSessionEdit = (props) => {
  let { history } = props
  return (
    <Edit
      {...props}
      title={<EditTitle
        resource={props.resource}
      />}
      undoable={false}
    >
      <FormSave history={history} />
    </Edit>
  )
}

const enhanceList = compose(withStyles(styleList), translate)
export const PosSessionList = enhanceList(_PosSessionList)

