import React, { Component, Fragment } from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  NumberInput,
  SimpleForm,
  translate,
  refreshView,
  showNotification,
  ReferenceField,
  FunctionField,
  WithPermissions,
  required,
  minLength,
} from 'react-admin'
import {
  withStyles,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  ListItem,
  Avatar,
  ListItemText,
  Divider,
  Icon,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { getZone as getZoneFunc } from './actions'
import { Field } from 'redux-form'
import ColorPicker from '../common/ColorPicker'
import { getRouteGroupInfo } from '../common/getData'
import { changeBreadcrumb } from '../breadcrumb/action'
import { Provider } from '../provider'
import { push } from 'react-router-redux'
import { limitTextLength } from '../utils/formatUtil'
import * as permission from '../utils/permission'
import { validate } from './validate'

const styles = () => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  noPadding: {
    padding: 0
  },
  inputLeft: {
    paddingTop: 0,
    paddingRight: 8,
    paddingLeft: 0,
    paddingBottom: 0,
  },
  inputOrigin: {
    paddingTop: 15,
    paddingRight: 4,
    paddingLeft: 0,
  },
  inputDes: {
    paddingTop: 15,
    paddingRight: 0,
    paddingLeft: 4,
  },
  colorPicker: {
    marginTop: 25
  },
  networksInfo: {
    width: '100%'
  },
  textOrigin: {
    flex: 0,
    marginTop: 8
  },
  textOriginContainer: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  bigAvatar: {
    width: 60,
    height: 60,
    textAlign: 'center',
    fontSize: 11,
  },
  textHeader: {
    fontSize: 11,
    fontWeight: 'bold',
  },
})

const routeTypeLabel = {
  '00TST': 'resources.routes.status_test',
  '10PRV': 'resources.routes.status_private',
  '20PUB': 'resources.routes.status_public',
}

const DialogConfirm = translate(({
  open,
  onClose,
  onChangeTrip,
  record,
  translate,
  content,
}) => {
  return (
    <Dialog
      open={open || false}
      onClose={() => onClose()}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{translate('resources.routes.warning_change_route_type')}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onChangeTrip(record, true)} color="primary" autoFocus>
          {translate('button.ok')}
        </Button>
        <Button onClick={() => onChangeTrip(record, false)} color="primary">
          {translate('button.cancel')}
        </Button>
        <Button onClick={() => onClose()} color="primary">
          {translate('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
})

const validateLongName = [required(), minLength(6)]

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      typeChanged: false,
      changedTripConfirm: {
        open: false,
        data: {},
        content: '',
      }
    }
  }

  async componentDidMount() {
    let { record, changeBreadcrumb, translate } = this.props
    // let { markers } = this.state
    let paths = []

    // Get routegroup info
    let { routeGroupId } = record
    let routeGroup = await getRouteGroupInfo(routeGroupId)
    if (routeGroup) {
      paths = [
        { label: translate('resources.routegroups.name', { smart_count: 2 }), to: '/routegroups' },
        { label: routeGroup.name, to: `/routegroups/${routeGroupId}` },
        { label: record.id ? record.name : translate('resources.common.create'), to: '' }
      ]
    }
    changeBreadcrumb({ paths })
  }

  handleChangeRouteType = () => {
    this.setState({
      typeChanged: false
    })
  }

  onChangeTrip = (data, isChange) => {
    data = { ...data, changedTrip: isChange }
    this.onUpdate(data)
    this.closeChangeRouteTypeDialog()
  }

  onUpdate = route => {
    let { refreshView, showNotification, push } = this.props
    Provider.dataProvider('UPDATE', 'routes', {
      id: route.id,
      data: route,
    }).then(() => {
      refreshView()
      showNotification('notification.update_route_success')
      push(`/routegroups/${route.routeGroupId}`)
    }).catch(() => {
      refreshView()
      showNotification('notification.update_route_fail', 'warning')
    })
  }

  onCreate = route => {
    let { refreshView, showNotification, push } = this.props
    Provider.dataProvider('CREATE', 'routes', {
      data: route,
    }).then(() => {
      refreshView()
      showNotification('notification.create_route_success')
      push(`/routegroups/${route.routeGroupId}`)
    }).catch(() => {
      refreshView()
      showNotification('notification.create_route_fail', 'warning')
    })
  }

  handleSubmit = value => {
    let { id } = value
    let { translate } = this.props
    if (id) {
      let { typeChanged } = this.state
      if (typeChanged) {
        this.setState({
          changedTripConfirm: {
            open: true,
            content: <span>{translate('resources.routes.change_route_to_trip')} <b>{translate(routeTypeLabel[value.type])}</b> ?</span>,
            data: value
          }
        })
        return
      }
      this.onUpdate(value)
    } else {
      this.onCreate(value)
    }
  }

  closeChangeRouteTypeDialog = () => {
    this.setState({
      changedTripConfirm: {
        open: false,
        content: '',
        data: {}
      }
    })
  }

  render() {
    const {
      classes,
      isCreate = false,
      record,
      toolbar,
      translate,
      permissions,
    } = this.props
    let { changedTripConfirm } = this.state
    let { open, data, content } = changedTripConfirm
    let { stops = [], distance } = record
    let startStop = stops[0]
    let endStop = stops[stops.length -1]
    let isCompanyManager = permission.isCompanyManager(permissions)
    let disabledCode = true
    if (isCreate) {
      disabledCode = false
    } else if (isCompanyManager) {
      disabledCode = false
    }
    return <Fragment>
      <SimpleForm
        toolbar={toolbar}
        record={{ ...record }}
        redirect={`/routegroups/${record.routeGroupId}`}
        save={this.handleSubmit}
        validate={validate}
      >
        {endStop && startStop && <div className={classes.networksInfo}>
          <Grid container>
            <Grid className={classes.noPadding} item xs={4}>
              <ListItem className={classes.textOriginContainer}>
                <Avatar className={classes.bigAvatar}>{translate('resources.common.departure')}</Avatar>
                <ListItemText
                  className={classes.textOrigin}
                  primary={<ReferenceField
                    resource="stops"
                    reference="stops"
                    record={{ id: startStop }}
                    source="id"
                    linkType={false}
                    basePath="/stops"
                  >
                    <FunctionField
                      className={classes.textHeader}
                      render={(record) => record && record.name && limitTextLength(record.name, 30)}
                    />
                  </ReferenceField>}
                />
              </ListItem>
            </Grid>
            <Grid className={classes.noPadding} item xs={4}>
              <ListItem className={classes.textOriginContainer}>
                <Icon style={{ fontSize: 60 }} >arrow_right_alt</Icon>
                <ListItemText
                  classes={{
                    primary: classes.textHeader
                  }}
                  primary={distance ? `${distance / 1000} (km)` : '0 (km)'}
                />
              </ListItem>
            </Grid>
            <Grid className={classes.noPadding} item xs={4}>
              <ListItem className={classes.textOriginContainer}>
                <Avatar className={classes.bigAvatar}>{translate('resources.common.arrival')}</Avatar>
                <ListItemText
                  className={classes.textOrigin}
                  primary={<ReferenceField
                    resource="stops"
                    reference="stops"
                    record={{ id: endStop }}
                    source="id"
                    linkType={false}
                    basePath="/stops"
                  >
                    <FunctionField
                      className={classes.textHeader}
                      render={(record) => record && record.name && limitTextLength(record.name, 30)}
                    />
                  </ReferenceField>}
                />
              </ListItem>
            </Grid>
          </Grid>
          <Divider />
        </div>}
        <Grid container className={classes.root}>
          <Grid item xs={12} md={6}>
            <TextInput
              source="number"
              label="resources.routes.number"
              disabled={disabledCode}
              fullWidth
            />
            <TextInput
              source="name"
              validate={validateLongName}
              label="resources.routes.name_route"
              fullWidth
            />
            <TextInput
              source="longName"
              validate={required()}
              label="resources.routes.long_name"
              fullWidth
            />
            <TextInput
              source="desc"
              label="resources.routes.desc"
              fullWidth
            />
            <ReferenceInput
              source="status"
              resource="routes"
              reference="routestatuses"
              defaultValue="00INACTIVE"
              filter={{ id: { lt: '20ARCHIVED' }}}
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
              source="type"
              label="resources.routes.type"
              resource="routetypes"
              reference="routetypes"
              defaultValue="00TST"
              onChange={this.handleChangeRouteType}
              fullWidth
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <NumberInput
              source="numberOfPrivateSeat"
              label="resources.routes.numberOfPrivateSeat"
              fullWidth
            />
            <Field
              name="color"
              width="100%"
              classes={classes}
              component={ColorPicker}
            />
          </Grid>
        </Grid>
      </SimpleForm>
      <DialogConfirm
        open={open}
        onClose={this.closeChangeRouteTypeDialog}
        onChangeTrip={this.onChangeTrip}
        record={data}
        content={content}
      />
    </Fragment>
  }
}

const FormSaveWithPermission = ({...props}) => <WithPermissions
  render={({ permissions }) => <FormSave permissions={permissions} {...props} />}
/>

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, {
    getZone: getZoneFunc,
    changeBreadcrumb,
    push,
    showNotification,
    refreshView,
  })
)

export default enhance(FormSaveWithPermission)
