import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  withStyles,
} from '@material-ui/core'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  refreshView,
  showNotification,
  translate,
  required,
  SelectInput,
} from 'react-admin'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { reset } from 'redux-form'
import { validateCreateUser } from './validate'

const style = () => ({
  label: {
    paddingLeft: '0.5em'
  }
})

class CreateUserButton extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  openDialog = () => {
    this.setState({
      open: true
    })
  }

  closeDialog = () => {
    let { reset } = this.props
    reset('record-form')
    this.setState({
      open: false
    })
  }

  save = async record => {
    let { refreshView, showNotification, reset } = this.props
    await Provider.dataProvider('CREATE', 'users', {
      data: record
    }).then(
      () => {
        showNotification('notification.create_user_success')
        refreshView()
      }
    ).catch(
      () => {
        showNotification('notification.create_user_failure', 'warning')
      }
    )
    reset('record-form')
    this.setState({
      open: false
    })
  }

  render() {
    let { translate, classes } = this.props
    let { open } = this.state
    let extra = { fullWidth: true, resource: 'users' }
    return <>
      <Button color="primary" onClick={this.openDialog}>
        <FontAwesomeIcon icon={faPlus} />
        <span className={classes.label}>
          {translate('button.create')}
        </span>
      </Button>
      <Dialog
        open={open}
        onClose={this.closeDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{translate('resources.users.page.create_user')}</DialogTitle>
        <DialogContent>
          <SimpleForm validate={validateCreateUser} resource="users" save={this.save}>
            <TextInput source="username" {...extra} validate={required()} />
            <TextInput source="password" type="password" {...extra} validate={required()} />
            <TextInput source="confirmPassword" type="password" {...extra} validate={required()} />
            <ReferenceInput
              reference="statuses"
              source="status"
              defaultValue="10ACT"
              {...extra}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </SimpleForm>
        </DialogContent>
      </Dialog>
    </>
  }
}

const enhance = compose(
  withStyles(style),
  translate,
  connect(null, { refreshView, showNotification, reset })
)

export default enhance(CreateUserButton)
