import reduce from 'lodash/reduce'

export function findIndexById(items, newItem) {
  for (let i = 0; i < items.length; i++) {
    let vehicle = items[i]
    if (newItem === vehicle.id) {
      return i
    }
  }
  return -1
}

export const multiSelectTo = (items, selectedItems, newItem)=> {
  // Nothing already selected
  if (!selectedItems) {
    return [newItem]
  }

  const indexOfNew = findIndexById(items, newItem)

  const lastSelectedId = selectedItems[selectedItems.length - 1]
  const indexOfLast = findIndexById(items, lastSelectedId)

  // multi selecting in the same column
  // need to select everything between the last index and the current index inclusive

  // nothing to do here
  if (indexOfNew === indexOfLast) {
    return null
  }

  const isSelectingForwards = indexOfNew > indexOfLast
  const start = isSelectingForwards ? indexOfLast : indexOfNew
  const end = isSelectingForwards ? indexOfNew : indexOfLast

  const inBetween = items.slice(start, end + 1)

  // everything inbetween needs to have it's selection toggled.
  // with the exception of the start and end values which will always be selected
  let inBetweenId = reduce(inBetween, (result= [], v) => {
    result.push(v.id)
    return result
  }, [])
  const toAdd = inBetweenId.filter(
    (item) => {
      // if already selected: then no need to select it again
      if (selectedItems.includes(item)) {
        return false
      }
      return true
    },
  )

  const sorted = isSelectingForwards ? toAdd : [...toAdd].reverse()
  const combined = [...selectedItems, ...sorted]

  return combined
}