import React, { Component } from 'react'
import { ResponsivePie } from '@nivo/pie'
import { translate } from 'react-admin'

class PieChart extends Component {

  render () {
    let { height, data = [], defs, fill, radialLabel, sliceLabel, tooltip, option } = this.props
    return <ResponsivePie
      data={data}
      height={height}
      radialLabel={d => radialLabel(d)}
      sliceLabel={d => sliceLabel(d)}
      defs={defs}
      fill={fill}
      tooltip={(d) => tooltip(d)}
      {...option}
    />
  }
}

export default translate(PieChart)
