import React from 'react'
import Card from '@material-ui/core/Card'
import { withStyles } from '@material-ui/core/styles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = {
  card: {
    float: 'left',
    margin: '-20px 20px 0 15px',
    zIndex: 100,
    borderRadius: 3,
  },
  icon: {
    float: 'right',
    width: 54,
    height: 54,
    padding: 14,
    color: '#fff',
  },
}

const CardIcon = ({ classes, icon, size, bgColor }) => {
  return (
    <Card
      className={classes.card}
      style={{ backgroundColor: bgColor ? bgColor : 'black' }}
    >
      <FontAwesomeIcon
        className={classes.icon}
        size={size ? size : '2x'}
        icon={icon}
      />
    </Card>
  )
}

export default withStyles(styles)(CardIcon)