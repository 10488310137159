import React, { Component, Fragment } from 'react'
import {
  SimpleForm,
  ReferenceInput,
  translate,
  Title,
  Toolbar,
  WithPermissions,
  FormDataConsumer,
} from 'react-admin'
import { DateTimePickerInput } from '../common/DateTimePicker'
import { DatePickerInput } from '../common/DatePicker'
import { moment } from '../common/format'
import { withStyles, Card, Button } from '@material-ui/core'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { isAdmin } from '../utils/permission'
import RadioGroupInputCustom from '../common/RadioGroupInputCustom'
import compose from 'recompose/compose'
import GetAppIcon from '@material-ui/icons/GetApp'
import { Provider } from '../provider'
import FileSaver from 'file-saver'

const mimeType =
   'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const exportFileByType = (data, filename) => {
  return FileSaver.saveAs(
    new Blob([Buffer.from(data)], { type: mimeType }),
    filename,
  )
}

const REPORT_BY_DAY = 'REPORT_BY_DAY'
const REPORT_DETAIL = 'REPORT_DETAIL'
const REPORT_BY_PRODUCT = 'REPORT_BY_PRODUCT'

const reportChoices = [
  { id: REPORT_BY_DAY, name: 'resources.chargereports.by_day' },
  { id: REPORT_DETAIL, name: 'resources.chargereports.detail' },
  { id: REPORT_BY_PRODUCT, name: 'resources.chargereports.by_product' },
]

const toolbarStyles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
    color: '#3f51b5'
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  },
  colorPrimary: {
    color: '#3f51b5'
  },
}

class _CustomToolbar extends Component {

  render() {
    const { onExport, handleSubmit, classes, translate, ...props } = this.props
    return <Toolbar {...props}>
      <Button
        className={classes.button}
        onClick={handleSubmit(values => onExport(values))}
        {...props}
      >
        <span>
          <GetAppIcon className={classes.icon} fontSize="small" />
          <span>{translate('button.export')}</span>
        </span>
      </Button>
    </Toolbar>
  }
}

const enhanceToolbar = compose(translate, withStyles(toolbarStyles))
const CustomToolbar = enhanceToolbar(_CustomToolbar)

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}

class _ChargeReport extends Component {
  state = {
    record: {
      fromDate: moment().startOf('days').toDate().toISOString(),
      toDate: moment().endOf('days').toDate().toISOString(),
      reportType: REPORT_BY_DAY,
      date: moment(),
      provider: '00VNIS'
    }
  }

   onExport = async (filter) => {
     let { companyId } = filter
     let companyIds
     if (companyId){
       companyIds = [companyId]
     }
     let res = await Provider.dataProvider('REMOTE', 'charges', {
       method: 'export',
       requestMethod: 'GET',
       data: { filter: { ...filter, companyIds } }
     })
     if (res && res.data) {
       const { data, fileName } = res.data
       exportFileByType(data, fileName)
     }
   }

   render() {
     const { permissions, translate } = this.props
     const { record } = this.state
     let admin = isAdmin(permissions)
     return <Card>
       <Title title={translate('resources.chargereports.name')} />
       <SimpleForm
         record={record}
         resouce="chargereports"
         toolbar={<CustomToolbar onExport={this.onExport} />}
       >
         <FormDataConsumer>
           {( { formData } ) => {
             const { reportType } = formData
             return reportType != REPORT_BY_PRODUCT ? <Fragment>
               <DateTimePickerInput
                 label={translate('resources.chargereports.fields.fromDate')}
                 source="fromDate"
                 dateFormat="DD/MM/YYYY HH:mm"
                 ampm={false}
                 showLunarDate={true}
                 keyboard={true}
                 allowEmpty
                 pickerType='datetime'
                 style={{ marginRight: 8 }}
               />
               <DateTimePickerInput
                 label={translate('resources.chargereports.fields.toDate')}
                 source="toDate"
                 dateFormat="DD/MM/YYYY HH:mm"
                 ampm={false}
                 showLunarDate={true}
                 keyboard={true}
                 allowEmpty
                 pickerType='datetime'
                 alwaysOn
               />
             </Fragment> : <DatePickerInput
               label={translate('resources.chargereports.fields.date')}
               source="date"
               dateFormat="DD/MM/YYYY"
               showLunarDate={true}
               keyboard={true}
               allowEmpty
               pickerType='date'
             />
           }}
         </FormDataConsumer>

         {admin && <ReferenceInput
           reference="companies"
           source="companyId"
           label={translate('resources.chargereports.fields.company')}
           fullWidth
           allowEmpty
           alwaysOn
         >
           <FuzzySelectInput
             optionText="name"
             renderItem={ ({ name }) => name } {...defaultFuzzySearch({ name: 'name' })}
           />
         </ReferenceInput>}
         <RadioGroupInputCustom
           source="reportType"
           choices={reportChoices}
         />
       </SimpleForm>
     </Card>
   }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export const ChargeReport = enhance(_ChargeReport)

export const ChargeReportWithPermission  = props => <WithPermissions
  render={({ permissions }) => <ChargeReport permissions={permissions} {...props} />}
/>
