import { moment } from '../common/format'

export function validate(values, props) {
  let errors = {}
  let { translate } = props
  // Validate date
  let startDate = (values && values.startDate) && moment(values.startDate)
  let endDate = (values && values.endDate) && moment(values.endDate)
  if (startDate && endDate) {
    if (endDate.diff(startDate, 'days') < 0) {
      errors.startDate = translate('error_messages.invalid.services.startDate')
    }
    if (startDate.diff(endDate, 'days') > 0) {
      errors.endDate = translate('error_messages.invalid.services.endDate')
    }
  }

  return errors
}
