import React, { Component } from 'react'
import { Grid, withStyles, Typography, Button } from '@material-ui/core'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  ReferenceField,
  FunctionField,
  addField,
  NumberField,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import ChipArrayInput from '../common/ChipArrayInput'
import { FuzzySelectInput, defaultFuzzySearch, FuzzyAsyncPickerInput } from '../common/react-fuzzy-picker'
import compose from 'recompose/compose'
import CancelCompanyAgencyButton from './CancelCompanyAgencyButton'
import { resourceFieldText } from '../utils/resourceText'
import * as dataType from '../common/data-type'
import PhoneInputMask from '../common/PhoneInput'
import CommissionChip from '../common/CommissionChip'
import { Provider } from '../provider'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    title: {
      textDecoration: 'underline',
    },
    stopList: {
      padding: 12
    },
    card: {
      padding: 12
    },
    leftCard: {
      borderRight: '1px solid #e8e8e8'
    },
    checkbox: {
      display: 'flex'
    }
  })
}

class _PhoneInput extends Component {
  render() {
    let { input } = this.props
    return <ChipArrayInput
      input={input}
      maskComponent={PhoneInputMask}
      type={dataType.PHONE}
    />
  }
}

const PhoneInput = addField(_PhoneInput)

class CompanyGeneralInformation extends Component {

  state = {}

  async componentDidMount() {
    let commissionTemplates = await this.getCommissionTemplates()
    this.setState({ commissionTemplates })
  }

  getCommissionTemplates = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'commissiontemplates', {
      filter: {
        '../fields': ['id', 'name', 'parameters'],
        '../include': []
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  render() {
    let {
      history,
      classes,
      resource,
      record = {},
      translate,
      isAdmin,
      isCompanyManager,
    } = this.props
    let { commissionTemplates } = this.state
    let { id } = record
    const extra = { resource, fullWidth: true }
    const _fa = resourceFieldText('financeaccounts') 
    const _a = resourceFieldText('agencies') 
    return <Grid container className={classes.root}>
      <Grid container className={classes.leftCard} item xs={12} md={6} spacing={8}>
        <TextInput 
          source="name"
          {...extra}
          validate={[required(), minLength(6), maxLength(255)]}
        />
        <TextInput source="longName" {...extra} />
          
        <Grid item xs={12} md={6}>
          <TextInput source="email" type="email" {...extra} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="fax" {...extra} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="taxNumber" {...extra} />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextInput source="registrationNumber" {...extra} />
        </Grid>
        <TextInput source="url" {...extra} />
        <PhoneInput source='tel' />
        <ReferenceInput
          source="status"
          reference="statuses"
          {...extra}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <TextInput source="address" {...extra} />
        <Grid item xs={12} md={4}>
          <FuzzyAsyncPickerInput
            optionText='name'
            label={translate('resources.common.country')}
            optionValue='id'
            source='countryId'
            resources='countries'
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            source="provinceId"
            reference="provinces"
            resource="provinces"
            filter={{'../fields': ['id', 'name'] }}
            perPage={1000}
            {...extra}
          >
            <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} md={4}>
          <ReferenceInput
            source="cityId"
            reference="cities"
            resource="cities"
            filter={{'../fields': ['id', 'name'] }}
            perPage={1000}
            {...extra}
          >
            <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
          </ReferenceInput>
        </Grid>
        <TextInput source="desc" {...extra} />
      </Grid>
      {id && <Grid container item md={6} xs={12}>
        <Grid item md={12} xs={12}>
          <Typography className={classes.title} variant="h4">{translate('resources.agencies.name', { smart_count: 1 })}</Typography>
          <ReferenceManyField
            basePath="/companyagencies"
            record={{}}
            reference="companyagencies"
            resource="companies"
            target=""
            filter={{ companyId: id, '../include': {
              relation: 'agency',
              scope: { fields: ['id', 'name'] },
            }}}
            pagination={<Pagination />}
            perPage={5}
          >
            <Datagrid resource="companies">
              <TextField source="agency.name" label={_a('name')} />
              <ReferenceField
                source="type"
                reference="agencytypes"
                allowEmpty
                linkType={false}
                label={_a('type')}
              >
                <TextField  source="name" />
              </ReferenceField>
              <ReferenceField
                source="status"
                reference="statuses"
                allowEmpty
                linkType={false}
                label={_a('status')}
              >
                <TextField source="name" />
              </ReferenceField>
              {isCompanyManager && <FunctionField
                label="resources.companies.fields.commission"
                source="commission"
                render={record => <CommissionChip 
                  isCompanyManager={isCompanyManager}
                  record={record}
                  source="commissionScript"
                  method="changeCommission"
                  resources="companyagencies"
                  commissionTemplates={commissionTemplates}
                />}
              />}
              {isAdmin && <FunctionField render={record => <CancelCompanyAgencyButton record={record} />} />}
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography className={classes.title} variant="h4">{translate('resources.financeaccounts.name', { smart_count: 2 })}</Typography>
          <ReferenceManyField
            basePath="/financeaccountmappings"
            record={{}}
            reference="financeaccountmappings"
            resource="companies"
            target=""
            filter={{ refId: id, type: 'COM', '../include': 'financeAccount' }}
            pagination={<Pagination />}
            perPage={5}
          >
            <Datagrid resource="companies">
              <TextField source="financeAccount.accountName" label={_fa('accountName')} />
              <TextField source="financeAccount.accountNumber" label={_fa('accountNumber')} />
              <TextField source="financeAccount.bank" label={_fa('bank')} />
              <NumberField 
                source="financeAccount.platformDeposit"
                label={_fa('platformDeposit')}
                locales="vi-VN"
                options={{ style: 'currency', currency: 'VND' }}
              />
              {(isAdmin || isCompanyManager) && <FunctionField
                render={record => <Button
                  color="primary"
                  onClick={() => {
                    history.push(`/financeaccounts/${record.accountId}`)
                  }}
                >
                  {translate('button.edit')}
                </Button>}
              />}
            </Datagrid>
          </ReferenceManyField>
        </Grid>
      </Grid>
      }
    </Grid >
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(CompanyGeneralInformation)
