import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput, 
  SelectInput,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import { Grid, withStyles } from '@material-ui/core'
import CustomToolbar from '../common/CustomToolbarForm'

const style = () => ({
  root: {
    width: '100%'
  }
})

class FormSave extends Component {

  render() {
    let { classes } = this.props
    const extra = {
      resource: 'apps',
      fullWidth: true,
    }
    return (
      <SimpleForm toolbar={<CustomToolbar />} {...this.props}>
        <Grid className={classes.root} container>
          <Grid item xs={6}>
            <TextInput 
              source="name"
              {...extra}
              validate={[required(), minLength(6), maxLength(256)]}
            />
            <TextInput source="applicationId" {...extra} />
            <TextInput source="desc" {...extra} />
            <ReferenceInput
              basePath="/os"
              resource="os"
              reference="os"
              source="os"
              {...extra}
              validate={required()}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
            <TextInput source="versionCode" {...extra} />
            <TextInput source="versionName" {...extra} />
            <ReferenceInput
              basePath="/appconfigs"
              resource="appconfigs"
              reference="appconfigs"
              source="configId"
              {...extra}
              validate={required()}
            >
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

export default withStyles(style)(FormSave)
