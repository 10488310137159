import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  withStyles,
  TextField as TextInput,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'
import { Provider } from '../provider'
import CancelIcon from '@material-ui/icons/Cancel'
import { translate, refreshView } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { connect } from 'react-redux'
import ButtonWithLoading from '../common/ButtonWithLoading'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
    color: '',
  }
}

const cancelConfirmDialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  titleText: {
    color: 'white',
  },
}

const _CancelConfirmDialog = ({
  open,
  onClose,
  onChangeCancelReason,
  onDone,
  translate,
  reservationCode,
  classes,
  isLoading,
}) => {
  return <Dialog
    open={open}
    onClose={onClose}
    maxWidth="sm"
    fullWidth
  >
    <DialogTitle className={classes.title}>
      <span className={classes.titleText}>{translate('resources.common.confirm_cancel_reservation_title')}&nbsp;<b>{reservationCode}</b></span>
    </DialogTitle>
    <DialogContent>
      <TextInput
        label={translate('resources.common.cancel_reason')}
        onChange={onChangeCancelReason}
        fullWidth
      />
    </DialogContent>
    <DialogActions>
      <ButtonWithLoading
        isLoading={isLoading}
        onClick={onDone}
        variant="contained"
        color="primary"
      >
        {translate('button.continue')}
      </ButtonWithLoading>
      <Button onClick={onClose}>
        {translate('button.close')}
      </Button>
    </DialogActions>
  </Dialog>
}

const enhanceCancelConfirmDialog = compose(translate, withStyles(cancelConfirmDialogStyle))
const CancelConfirmDialog = enhanceCancelConfirmDialog(_CancelConfirmDialog)

class CancelReservationButton extends Component {

  state = {
    open: false,
    isLoading: false,
  }

  startLoading = () => {
    this.setState({ isLoading: true })
  }

  endLoading = () => {
    this.setState({ isLoading: false })
  }

  cancel = () => {
    let { id, refreshView, closeMenu, updatedChart } = this.props
    let { currentCancelReason } = this.state
    this.startLoading()
    Provider.dataProvider('REMOTE', 'reservations', {
      method: `${id}/cancel`,
      data: { cancelReason: currentCancelReason },
      requestMethod: 'POST',
    }).then(
      () => {
        this.closeConfirm()
        closeMenu && closeMenu()
        refreshView()
        updatedChart()
      }
    ).catch(e => {
      console.log('Error', e.message)
    }).finally(
      () => {
        this.endLoading()
      }
    )
  }

  openConfirm = () => {
    this.setState({
      open: true,
    })
  }

  closeConfirm = () => {
    this.setState({
      open: false,
    })
  }

  onChangeCancelReason = (evt) => {
    let { value } = evt.target
    this.setState({ currentCancelReason: value })
  }

  renderComponent = () => {
    let { classes, translate, type, disabled, button, color, ...props } = this.props
    let style = { color: !disabled && '#fb404b' }
    switch (type) {
      case 'button':
        return <Button
          style={style}
          onClick={this.openConfirm}
          className={classes.button}
          disabled={disabled}
          {...props}
        >
          <span>
            <CancelIcon fontSize="small" />
            <span>{translate('button.cancel_reservation_2')}</span>
          </span>
        </Button>
      case 'menuItem':
        return <MenuItem
          style={style}
          onClick={this.openConfirm}
          disabled={disabled}
        >
          <ListItemIcon style={style}>
            <CancelIcon fontSize="small" />
          </ListItemIcon>
          <span>{translate('button.cancel_reservation_2')}</span>
        </MenuItem>
      default:
        return <Tooltip
          title={translate('button.cancel_reservation_2')}
          enterDelay={100}
        >
          <LinkField
            style={style}
            className={classes.iconButton}
            icon
            color={color}
            disabled={disabled}
            onClick={this.openConfirm}
            {...props}
          >
            <CancelIcon fontSize="small" />
          </LinkField>
        </Tooltip>
    }
  }
  render() {
    let { code } = this.props
    let { open, isLoading } = this.state
    return <Fragment>
      {this.renderComponent()}
      {open && <CancelConfirmDialog
        reservationCode={code}
        open={open}
        isLoading={isLoading}
        onClose={this.closeConfirm}
        onChangeCancelReason={this.onChangeCancelReason}
        onDone={this.cancel}
      />}
    </Fragment>
  }
}

const enhance = compose(withStyles(styles), translate, connect(null, { refreshView }))
export default enhance(CancelReservationButton)
