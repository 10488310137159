import L from 'leaflet'
import { MapLayer, withLeaflet} from 'react-leaflet'
import Transitive from 'transitive-js'
import 'transitive-js/lib/transitive.css'

const safeRemoveLayer = (leafletMap, el) => {
  const { overlayPane } = leafletMap.getPanes()
  if (overlayPane && overlayPane.contains(el)) {
    overlayPane.removeChild(el)
  }
}

class TransitiveLayer extends MapLayer {
  constructor(props) {
    super(props)

    let { data, styles, drawGrid, gridCellSize, renderer } = this.props
    gridCellSize = gridCellSize || 300
    renderer = renderer || 'default'

    this.state = {
      data,
      styles,
      drawGrid,
      gridCellSize,
      renderer
    }
  }

  componentDidMount() {
    let { data, styles, drawGrid, gridCellSize, renderer } = this.state
    
    const map = this.props.leaflet.map
    const mapSize = map.getSize()

    this._el = L.DomUtil.create('div', 'leaflet-transitive-container')

    this._el.style.position = 'absolute'
    this._el.style.width = mapSize.x + 'px'
    this._el.style.height = mapSize.y + 'px'    
    const bounds = map.getBounds()
    const bbox = [
      [bounds.getWest(), bounds.getSouth()],
      [bounds.getEast(), bounds.getNorth()]
    ]
    this._transitive = new Transitive({
      data: data,
      styles: styles,
      drawGrid: drawGrid,
      gridCellSize: gridCellSize,
      initialBounds: bbox,
      displayMargins: {
        right: 400,
        bottom: 50
      },
      draggableTypes: ['PLACE']
    })
    this._transitive.setRenderer(renderer)

    const el = this._el
    const Layer = L.Layer.extend({
      onAdd: (leafletMap) => {
        leafletMap.getPanes().overlayPane.appendChild(el)
      },
      addTo: (leafletMap) => {
        leafletMap.addLayer(this)
        return this
      },
      onRemove: (leafletMap) => {
        safeRemoveLayer(leafletMap, el)
      }
    })

    this.leafletElement = new Layer()
    super.componentDidMount()
    this.attachEvents()
    this.fitBounds()
  }

  componentWillReceiveProps() {}

  componentWillUnmount() {
    safeRemoveLayer(this.props.leaflet.map, this._el)
  }

  componentDidUpdate() {
    this.reset()
  }

  shouldComponentUpdate() {
    return true
  }

  fitBounds() {
    let { data } = this.state
    const stops = data.stops
    if (!stops) return
    const lons = stops.map(v => v.stop_lon)
    const lats = stops.map(v => v.stop_lat)

    const maxLon = lons.reduce((acc, curr) => Math.max(acc, curr))
    const maxLat = lats.reduce((acc, curr) => Math.max(acc, curr))

    const minLon = lons.reduce((acc, curr) => Math.min(acc, curr))
    const minLat = lats.reduce((acc, curr) => Math.min(acc, curr))

    const ne = { lng: maxLon, lat: maxLat }
    const sw = { lng: minLon, lat: minLat }

    this.props.leaflet.map.fitBounds(L.latLngBounds(L.latLng(sw), L.latLng(ne)))
  }

  reset() {
    const map = this.props.leaflet.map
    const bounds = map.getBounds()
    this._transitive.setDisplayBounds([
      [bounds.getWest(), bounds.getSouth()],
      [bounds.getEast(), bounds.getNorth()]
    ])
    const topLeft = map.latLngToLayerPoint(bounds.getNorthWest())
    L.DomUtil.setPosition(this._el, topLeft)
  }

  resize(data) {
    this._transitive.resize(data.newSize.x, data.newSize.y)
    this.reset()
  }

  attachEvents() {
    const map = this.props.leaflet.map
    map.on('moveend', () => this.reset())
    map.on('zoomend', () => this.reset())
    map.on('drag', () => this.reset())
    map.on('resize', (data) => this.resize(data))

    this._transitive.options.zoomEnabled = false
    this._transitive.options.autoResize = false

    this._transitive.setElement(this._el)
    this._transitive.render()

    const self = this
    this._transitive.on('clear data', () => this.reset())
    self._transitive.on('update data',() => {
      self._transitive.render()
      self.reset()
    })
  }

  createLeafletElement() {
    return null
  }

  updateLeafletElement() {
  }

  render() {
    return null
  }
}

export default withLeaflet(TransitiveLayer)
