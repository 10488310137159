import React, { Component } from 'react'
import { translate } from 'react-admin'
import { withStyles, Tabs, Tab } from '@material-ui/core'
import compose from 'recompose/compose'
import CompanyGeneralInformation from './CompanyGeneralInformation'
import { companyItems as items } from '../menu/items'
import Setting from './Setting'
import SettingMenu from './SettingMenu'
import SettingPos from './SettingPos'
import SettingMenuManager from './SettingMenuManager'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  tabContainer: { padding: theme.spacing.unit * 3 }
})

const TabHeaders = translate(({
  classes,
  isAdmin,
  isCompanyManager,
  translate,
  tabIndex,
  onChange,
  ...props
}) => {
  let { action } = props
  return (
    <Tabs
      value={tabIndex}
      onChange={(k, v) => onChange(v)}
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
    >
      <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.generalInformation')}
        value={0}
      />
      {!isAdmin && isCompanyManager && action === 'update' && <Tab
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.setting')}
        value={1}
      />}
      {isAdmin && action === 'update' && <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.settingMenu')}
        value={2}
      />}
      {isAdmin && <Tab
        disableripple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.settingMenuManager')}
        value={3}
      />}
      {isAdmin && <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.companies.fields.settingPos')}
        value={4}
      />}
    </Tabs>
  )
})

class FormSave extends Component {

  render() {
    const {
      classes,
      action,
      record,
      match,
      location,
      changeTab,
      changeTabPos,
      changeTabIndexPos,
      resource,
      isAdmin,
      isCompanyManager,
      tabIndex,
      tabPos,
      tabIndexPos,
      history,
      translate,
    } = this.props
    return <div className={classes.root}>
      <TabHeaders
        classes={classes}
        tabIndex={tabIndex}
        onChange={(v) => {
          changeTab(v)
        }}
        action={action}
        isAdmin={isAdmin}
        isCompanyManager={isCompanyManager}
        changeTabPos={changeTabPos}
        changeTabIndexPos={changeTabIndexPos}
      />

      {tabIndex === 0 &&
        <div className={classes.tabContainer}>
          <CompanyGeneralInformation
            record={record}
            resource={resource}
            isAdmin={isAdmin}
            history={history}
            isCompanyManager={isCompanyManager}
          />
        </div>
      }
      {!isAdmin && isCompanyManager && tabIndex === 1 &&
        <div className={classes.tabContainer}>
          <Setting match={match} location={location} record={record} />
        </div>
      }
      {isAdmin && tabIndex === 2 &&
        <div className={classes.tabContainer}>
          <SettingMenu translate={translate} match={match} location={location} record={record} items={items} resources="companysettings" />
        </div>
      }
      {isAdmin && tabIndex === 3 &&
        <div className={classes.tabContainer}>
          <SettingMenuManager translate={translate} match={match} location={location} record={record} />
        </div>
      }
      {isAdmin && tabIndex === 4 &&
        <SettingPos
          tabPos={tabPos}
          tabIndexPos={tabIndexPos}
          record={record}
          onChangeTabPos={(v) => {
            changeTabPos(v)
          }}
          isAdmin={isAdmin}
          match={match}
          location={location}
          resources="companysettings"
          onChangeTabIndexPos={changeTabIndexPos}
        />
      }
    </div>
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(FormSave)
