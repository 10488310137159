import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  MenuItem,
  ListItemIcon,
} from '@material-ui/core'
import { Provider } from '../provider'
import FileSaver from 'file-saver'
import moment from 'moment'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import GetAppIcon from '@material-ui/icons/GetApp'
import DialogLoading from '../common/DialogLoading'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  button: {
    display: 'inline-flex',
  },
  icon: {
    marginRight: '0.2em',
    fontSize: 17,
  },
  colorPrimary: {
    color: '#3f51b5'
  },
}

class ExportButton extends Component {

  state = { loading: false }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  export = () => {
    this.startLoading()
    let { id, code } = this.props

    Provider.dataProvider('REMOTE', 'reservations', {
      method: `${id}/pdfticket`,
      requestMethod: 'GET',
      data: { id }
    }).then(
      res => {
        let data = atob(res.data)
        let byteNumbers = []
        for (let i = 0; i < data.length; i++) {
          byteNumbers.push(data.charCodeAt(i))
        }
        let byteArray = new Uint8Array(byteNumbers)
        let fileName = `${code}-${moment(new Date()).format('YYYMMDDhhmmss')}.pdf`
        FileSaver.saveAs(new Blob([byteArray], { type: 'application/pdf' }), fileName)
        this.endLoading()
      }
    )
  }

  renderComponent = () => {
    let { classes, disabled, translate, type, button, color, ...props } = this.props
    let style = { color: !disabled && '#3f51b5' }
    switch (type) {
      case 'button':
        return <Button
          color={color}
          onClick={this.export}
          className={classes.button}
          disabled={disabled}
          {...props}
        >
          <GetAppIcon className={classes.icon} fontSize="small" />
          <span>{translate('button.download_ticket')}</span>
        </Button>
      case 'menuItem':
        return <MenuItem
          onClick={this.export}
          disabled={disabled}
          color={color}
          style={style}
          {...props}
        >
          <ListItemIcon style={style}>
            <GetAppIcon fontSize="small" />
          </ListItemIcon>
          <span>{translate('button.download_ticket')}</span>
        </MenuItem>
      default:
        return <Tooltip
          title={translate('button.download_ticket')}
          enterDelay={100}
        >
          <LinkField
            className={classes.iconButton}
            icon
            color={color}
            onClick={this.export}
            disabled={disabled}
            {...props}
          >
            <GetAppIcon fontSize="small" />
          </LinkField>
        </Tooltip>
    }
  }

  render() {
    let { loading } = this.state
    return <Fragment>
      {this.renderComponent()}
      {loading && <DialogLoading open={loading} />}
    </Fragment>
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(ExportButton)
