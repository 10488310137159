import React, { Component, Fragment } from 'react'
import {
  DialogActions,
  DialogTitle,
  Button,
  withStyles,
  Dialog,
  Select,
  MenuItem,
  FormControl,
  Avatar,
  Checkbox,
  FormControlLabel,
  TextField,
  DialogContent,
} from '@material-ui/core'
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import DatePicker from '../common/DatePicker'
import compose from 'recompose/compose'
import { translate } from 'react-admin'
import moment from 'moment'

const styles = () => ({
  selectGap: {
    marginLeft: '10px'
  },
  selectInput: {
    margin: '0 10px',
    width: '60px',
    outline: 'none',
  },
  checkboxSmall: {
    width: '24px',
    height: '24px',
    padding: '4px',
    fontSize: '10px',
  },
  checkboxSmallChecked: {
    width: '24px',
    height: '24px',
    padding: '4px',
    fontSize: '10px',
    backgroundColor: '#1a73e8'
  },
  checkboxItem: {
    width: '24px',
    height: '24px',
    padding: '0',
    marginRight: '5px'
  },
  checkboxContent: {
    display: 'flex',
  },
  recurEvery: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
    height: '44px'
  },
  selectTimeOptions: {
    padding: '10px',
    width: '50px'
  },
  monthSpan: {
    marginTop: '1rem',
    padding: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.12)'
  },
  selectRecurOptions: {
    padding: '8px',
    width: '120px',
    paddingRight: '15px'
  }
})

// endSelects
const TO_END_YEAR = 0
const TO_DATE = 1
const AFTER_RECUR_TIME = 2
const RECUR_DEFAULT = 3
// recurOptions
const NO_RECUR = 0
const EVERYDAY = 1
const EVERY_WEEK_AT_WEEKDAY = 2
const EVERY_MONTH_AT_DATE_CHOOSE = 3
const MONDAY_TO_FRIDAY = 4
const RECUR_CUSTOM = 5

// dayTimeOptions
const WEEK = 1
const MONTH = 2
const YEAR = 3
class CloneTripsRecur extends Component {
  state = {
    recurInfor: {
      dayTimeOptions: 0,
      recurEvery: 1,
      recurTime: 1,
      endSelects: 0,
      dayWeeksCheck: [moment(this.props.dayClick).weekday() + 1],
      endDate: moment(this.props.dayClick).add(1, 'days')
    },
    recurOptions: 0,
  }

  handleClose = () => {
    this.setState({
      recurInfor: {
        dayTimeOptions: 0,
        recurEvery: 1,
        recurTime: 1,
        endSelects: 0,
        dayWeeksCheck: [moment().weekday() + 1],
        endDate: moment(this.props.dayClick).add(1, 'days')
      },
      recurOptions: 0,
    }, () => this.props.getDataRecur(this.state))
  }

  // Change select [day,week,month,year]
  handleChange = (name, value) => {
    const { recurInfor } = this.state
    recurInfor[name] = value
    this.setState({ recurInfor: { ...recurInfor } })
  }

  // WeekDays Check [monday -> sunday]
  handleDayOfWeekChange = (value, isChecked) => {
    const { dayWeeksCheck } = this.state.recurInfor
    let dayCheckValue = [...dayWeeksCheck]

    if (isChecked) {
      dayCheckValue.push(value)
    } else {
      dayCheckValue = dayCheckValue.filter(v => v !== value)
    }

    if (dayCheckValue.length === 0) {
      dayCheckValue.push(moment().weekday() + 1)
    }
    this.setState({ recurInfor: { ...this.state.recurInfor, dayWeeksCheck: [...dayCheckValue] } })
  }

  // Recur Options [everyday -> options]
  handleRecurOptionsChange = (recurOptions) => {
    const { getDataRecur } = this.props

    switch (recurOptions) {
      case NO_RECUR:
        // No Recur
        this.setState({
          recurOptions
        }, () => getDataRecur(this.state))
        break
      case EVERYDAY:
        // Everyday
        this.setState({
          recurInfor: { ...this.state.recurInfor, endSelects: RECUR_DEFAULT },
          recurOptions
        }, () => getDataRecur(this.state))
        break
      case EVERY_WEEK_AT_WEEKDAY:
        // This Weekday EveyWeek
        this.setState({
          recurInfor: { ...this.state.recurInfor, endSelects: RECUR_DEFAULT },
          recurOptions
        }, () => getDataRecur(this.state))
        break
      case EVERY_MONTH_AT_DATE_CHOOSE:
        // Today Everymonth
        this.setState({
          recurInfor: { ...this.state.recurInfor, endSelects: RECUR_DEFAULT },
          recurOptions
        }, () => getDataRecur(this.state))
        break
      case MONDAY_TO_FRIDAY:
        // EveryWeekDay (2 -> 6)
        this.setState({
          recurInfor: { ...this.state.recurInfor, endSelects: RECUR_DEFAULT },
          recurOptions
        }, () => getDataRecur(this.state))
        break
      case RECUR_CUSTOM:
        // recur custom
        this.setState({
          recurInfor: { ...this.state.recurInfor, endSelects: TO_END_YEAR },
          recurOptions
        }, () => getDataRecur(this.state))
        break
      default:
        break
    }
  }

  // DatePicker (pick date)
  handleDateChange = (endDate) => {
    let toDay = moment().format('YYYYMMDD')
    let dayChoose = moment(endDate).format('YYYYMMDD')

    if (toDay < dayChoose) {
      this.setState({ recurInfor: { ...this.state.recurInfor, endDate } })
    } else {
      this.setState({ recurInfor: { ...this.state.recurInfor, endDate: moment().add(1, 'days') } })
    }
  }

  handleComfirm = () => {
    this.props.getDataRecur(this.state)
    this.setState({
      recurInfor: {
        dayTimeOptions: 0,
        recurEvery: 1,
        recurTime: 1,
        endSelects: 0,
        dayWeeksCheck: [moment(this.props.dayClick).weekday() + 1],
        endDate: moment(this.props.dayClick).add(1, 'days')
      },
      recurOptions: 0,
    })
  }

  render() {
    const { dayTimeOptions, recurTime, recurEvery, endSelects, dayWeeksCheck, endDate } = this.state.recurInfor
    const { recurOptions } = this.state
    const { translate, classes, dayClick, locale } = this.props

    const day = moment(dayClick).get('date')
    const weekdays = moment(dayClick).locale(locale).format('dddd')
    const RecurOptions = [
      translate('resources.viewtripsclone.noRepeat'),
      translate('resources.viewtripsclone.daily'),
      translate('resources.viewtripsclone.weeklyOn', { weekdays }),
      translate('resources.viewtripsclone.monthlyOn', { day }),
      translate('resources.viewtripsclone.everyWeekdays'),
      translate('resources.viewtripsclone.custom'),
    ]
    const DayOfWeek = [
      translate('resources.viewtripsclone.sunday'),
      translate('resources.viewtripsclone.monday'),
      translate('resources.viewtripsclone.tuesday'),
      translate('resources.viewtripsclone.webnesday'),
      translate('resources.viewtripsclone.thursday'),
      translate('resources.viewtripsclone.friday'),
      translate('resources.viewtripsclone.saturday')
    ]
    const DayTimeOptions = [
      translate('resources.dashboard.day'),
      translate('resources.dashboard.week'),
      translate('resources.dashboard.month'),
      translate('resources.dashboard.year')
    ]

    return (
      <div>
        <div className={classes.flexCenter}>
          <Dialog open={recurOptions === RECUR_CUSTOM} disableBackdropClick={true}>
            <DialogTitle>{translate('resources.viewtripsclone.customRecurrence')}</DialogTitle>
            <DialogContent>
              <FormControl>
                <Fragment>
                  <FormControl className={classes.recurEvery}>
                    <span >{translate('resources.viewtripsclone.reapeatEvery')}</span>
                    <TextField
                      variant="filled"
                      onChange={(e) => this.handleChange('recurEvery', parseInt(e.target.value))}
                      className={classes.selectInput}
                      type="number"
                      value={recurEvery}
                      InputProps={{
                        inputProps: {
                          max: 365, min: 1, style: { padding: '10px' }
                        }

                      }} />
                    <Select
                      value={dayTimeOptions}
                      onChange={(e) => this.handleChange('dayTimeOptions', e.target.value)}
                      style={{ height: '36px' }}
                      classes={{ select: classes.selectTimeOptions }}
                    >
                      {
                        DayTimeOptions.map((count, index) => {
                          return (
                            <MenuItem key={index} value={index}>{count}</MenuItem>
                          )
                        })
                      }
                    </Select>
                  </FormControl>
                  <div>
                    {
                      dayTimeOptions === WEEK && (
                        <Fragment>
                          <p>{translate('resources.viewtripsclone.repeatOn')}</p>
                          <div className={classes.checkboxContent}>
                            {
                              DayOfWeek.map((day, index) => {
                                let checked = dayWeeksCheck.includes(index)
                                return (
                                  <Checkbox
                                    key={index}
                                    checked={checked}
                                    value={day}
                                    onChange={(e) => this.handleDayOfWeekChange(index, e.target.checked)}
                                    className={classes.checkboxItem}
                                    icon={<Avatar className={classes.checkboxSmall}>{day}</Avatar>}
                                    checkedIcon={<Avatar className={classes.checkboxSmallChecked}>{day}</Avatar>}
                                  />
                                )
                              })
                            }
                          </div>
                        </Fragment>
                      )
                    }


                  </div>
                  {
                    dayTimeOptions === MONTH && (
                      <span className={classes.monthSpan}>
                        {translate('resources.viewtripsclone.monthlyOn', { day })}
                      </span>
                    )
                  }

                  {/* Kết thúc */}
                  <p>{translate('resources.viewtripsclone.ends')}</p>
                  {
                    dayTimeOptions !== YEAR && (
                      <Fragment>
                        <FormControlLabel
                          control={<Checkbox
                            icon={<RadioButtonUnchecked />}
                            checkedIcon={<RadioButtonChecked />}
                          />}
                          checked={endSelects === TO_END_YEAR}
                          onChange={(e) => this.handleChange('endSelects', TO_END_YEAR)}
                          label={translate('resources.viewtripsclone.endYear')}
                        />

                        {/* Vào ngày */}
                        <div className={classes.flexCenter}>
                          <FormControlLabel
                            control={<Checkbox
                              icon={<RadioButtonUnchecked />}
                              checkedIcon={<RadioButtonChecked />}
                            />}
                            onChange={(e) => this.handleChange('endSelects', TO_DATE)}
                            checked={endSelects === TO_DATE}
                            label={translate('resources.viewtripsclone.on')}
                          />
                          <DatePicker
                            disabled={endSelects !== TO_DATE}
                            keyboard={true}
                            dateFormat="DD/MM/YYYY"
                            showLunarDate={true}
                            input={{
                              value: endDate,
                              onChange: (endDate) => this.handleDateChange(endDate)
                            }}
                          />
                        </div>
                      </Fragment>
                    )
                  }

                  {/* Sau */}
                  <div className={classes.flexCenter}>
                    <FormControlLabel
                      control={<Checkbox
                        icon={<RadioButtonUnchecked />}
                        checkedIcon={<RadioButtonChecked />}
                      />}
                      onChange={(e) => this.handleChange('endSelects', AFTER_RECUR_TIME)}
                      checked={endSelects === AFTER_RECUR_TIME}
                      label={translate('resources.viewtripsclone.after')}
                      style={{ width: '90px' }}
                    />
                    <div className={classes.flexCenter}>
                      <TextField
                        variant="filled"
                        onChange={(e) => this.handleChange('recurTime', parseInt(e.target.value))}
                        disabled={endSelects !== AFTER_RECUR_TIME}
                        className={classes.selectInput}
                        type="number"
                        value={recurTime}
                        InputProps={{
                          inputProps: {
                            max: 100, min: 1, style: { padding: '10px' }
                          }
                        }} />
                      <span>{translate('resources.viewtripsclone.occurrences')}</span>
                    </div>
                  </div>
                </Fragment>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary" variant="contained">
                {translate('button.cancel')}
              </Button>
              <Button onClick={this.handleComfirm} color="secondary" variant="contained">
                {translate('button.ok')}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Options */}
          <FormControl className={classes.selectGap}>
            <Select
              classes={{ select: classes.selectRecurOptions }}
              value={recurOptions}
              onChange={(e) => this.handleRecurOptionsChange(e.target.value)}
            >
              {
                RecurOptions.map((count, index) => {
                  return (
                    <MenuItem value={index} key={index}>{count}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
        </div>
      </div>
    )
  }
}


export default compose(
  translate,
  withStyles(styles)
)(CloneTripsRecur)
