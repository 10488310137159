import Client from 'strong-pubsub'
import Adapter from 'strong-pubsub-mqtt'

const options = {
  brokerUrl: process.env.REACT_APP_MQTT_BROKER_URL || 'mqtts://mc.nexpando.com:8883',
  username: process.env.REACT_APP_MQTT_USERNAME || 'nexbus',
  password: process.env.REACT_APP_MQTT_PASSWORD || 'pando.dev',
}

let _client = null

const connect = () => {
  return new Promise((resovle, reject) => {
    _client.connect((err) => {
      if (err) reject()
      resovle()
    })
  })
}

const getInstance = async () => {
  if (_client) return _client

  _client = new Client(options, Adapter)

  try {
    _client.on('connect', (data) => {
      console.log('[MQTT2] Connected to mqtt brokers - %s', _client.options.brokerUrl)
    })
    await connect()
  } catch (err) {
    console.error('[MQTT2] Cannot connect to mqtt brokers - %s', _client.options.brokerUrl)
  }
  return _client
}

export default {
  getInstance
}

