import { Grid, withStyles } from '@material-ui/core'
import React, { Component } from 'react'
import { ReferenceInput, SelectInput, TextInput } from 'react-admin'
import compose from 'recompose/compose'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    stopList: {
      padding: 12
    },
    card: {
      padding: 12
    }
  })
}

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    let { classes } = this.props

    return (
      <Grid container spacing={24}>
        <Grid item xs={12} className={classes.card}>
          <Grid container item xs={12}>
            <ReferenceInput label="resources.timetables.name" source="timetableId" resource="timetables" reference="timetables" fullWidth>
              <SelectInput optionText="name" disabled />
            </ReferenceInput>
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={6}>
              <TextInput type="time" label="resources.stopTimes.departureTime" source="departureTime" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <TextInput type="time" label="resources.stopTimes.arrivalTime" source="arrivalTime" fullWidth />
            </Grid>
          </Grid>
        </Grid>
      </Grid >
    )
  }
}

const enhance = compose(
  withStyles(styles),
)

export default enhance(FormSave)