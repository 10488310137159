import React, { Component } from 'react'
import { 
  showNotification,
  translate,
} from 'react-admin'
import {
  withStyles,
  Grid,
} from '@material-ui/core'
import compose from 'recompose/compose'
import _ from 'lodash'
import { connect } from 'react-redux'
import ProductCard from './ProductCard'
import RemoveChildProductButton from './RemoveChildProductButton'
import { withLoading } from '../common/withLoading'

const style = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  emptyText: {
    marginTop: 18,
    fontStyle: 'italic',
    width: '100%',
    paddingLeft: 18,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 17,
  }
}

class ChildProducts extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let { childRouteProduct, products } = nextProps
    let { 
      childRouteProduct: currentChildRouteProduct,
      products: currentProducts,
    } = prevState

    if (products !== currentProducts) {
      currentProducts = products
    }
    if (childRouteProduct !== currentChildRouteProduct) {
      currentChildRouteProduct = childRouteProduct
    }
    return { 
      ...prevState,
      childRouteProduct: currentChildRouteProduct,
      products: currentProducts,
    }
  }


  render() {
    let { products, childRouteProduct = {} } = this.state
    let { 
      parentId,
      classes,
      commissionTemplates,
      setNewChildProduct,
      isCompanyManager,
      updateChildProductFare,
      productFareMapping,
      updateNonChildProduct,
      removeProductFare,
      translate,
      ticketValidations,
    } = this.props
    return !_.isEmpty(products) ? <Grid container className={classes.root}>
      {products.map((product, idx) => {
        let { id } = product
        let routeProducts = childRouteProduct[id]
        return <Grid key={idx} item xs={12} md={6}> 
          <ProductCard
            isCompanyManager={isCompanyManager}
            record={product}
            parentId={parentId}
            routeProducts={routeProducts}
            commissionTemplates={commissionTemplates}
            setNewChildProduct={setNewChildProduct}
            removeButton ={<RemoveChildProductButton
              id={id}
              product={product}
              parentId={parentId}
              updateNonChildProduct={updateNonChildProduct}
              removeProductFare={removeProductFare}
            />}
            isChild
            updateChildProductFare={updateChildProductFare}
            productFareMapping={productFareMapping}
            ticketValidations={ticketValidations}
          />
        </Grid>
      })}
    </Grid> : <span className={classes.emptyText}>
      {translate('resources.products.no_child_product')}
    </span>
  }
}

const enhance = compose(
  withStyles(style),
  connect(null, { showNotification }),
  translate,
  withLoading,
)
export default enhance(ChildProducts)
