import { green, blue, yellow, red, grey, blueGrey } from '@material-ui/core/colors'

export const NEW = '00NEW'
export const STANDBY = '10STANDBY'
export const CONFIRMED = '20CONFIRMED'
export const CANCELLED = '30CANCELLED'
export const CHECKED_IN = '40CHECKED_IN'
export const FINALIZED = '50FINALIZED'
export const EXPIRED = '60EXPIRED'
export const OTHER = '70OTHER'

export const reservationMapping = {
  [NEW]: 'resources.reservations.status.new',
  [STANDBY]: 'resources.reservations.status.standby',
  [CONFIRMED]: 'resources.reservations.status.confirmed',
  [CANCELLED]: 'resources.reservations.status.cancelled',
  //[CHECKED_IN]: 'resources.reservations.status.checkedIn',
  [FINALIZED]: 'resources.reservations.status.finalized',
  [EXPIRED]: 'resources.reservations.status.expired',
  [OTHER]: 'resources.reservations.status.other',
}

export const nivoColors = [blue[500], yellow[500], red[500], green[500], grey[300], grey[700], blueGrey[300]]

export const colorMapping = {
  [NEW]: blue[500],
  [STANDBY]: yellow[500],
  [CONFIRMED]: green[500],
  [CANCELLED]: grey[300],
  [EXPIRED]: grey[700],
  [OTHER]: blueGrey[300],
}

export const style = {
  [NEW]: { color: 'white', backgroundColor: blue[500] },
  [STANDBY]: { color: 'white', backgroundColor: yellow[500] },
  [CONFIRMED]: { color: 'white', backgroundColor: green[500] },
  [CANCELLED]: { color: 'black', backgroundColor: grey[300] },
  [OTHER]: { color: 'white', backgroundColor: blueGrey[300] },
}

export const outlineStyle = {
  [NEW]: { color: blue[700], border: `1px solid ${blue[700]}` },
  [STANDBY]: { color: yellow[700], border: `1px solid ${yellow[700]}` },
  [CANCELLED]: { color: red[700], border: `1px solid ${red[700]}` },
  [CONFIRMED]: { color: green[700], border: `1px solid ${green[700]}` },
  [CANCELLED]: { color: grey[700], border: `1px solid ${grey[700]}` },
  [OTHER]: { color: blueGrey[700], border: `1px solid ${blueGrey[700]}` },
}

export const styleForAvatarPaid = {
  [NEW]: { color: blue[700], border: `1px solid ${blue[700]}`, background: 'white' },
  [STANDBY]: { color: yellow[700], border: `1px solid ${yellow[700]}`, background: 'white' },
  [CANCELLED]: { color: red[700], border: `1px solid ${red[700]}`, background: 'white' },
  [CONFIRMED]: { color: green[700], border: `1px solid ${green[700]}`, background: 'white' },
  [CANCELLED]: { color: grey[700], border: `1px solid ${grey[700]}`, background: 'white' },
  [OTHER]: { color: blueGrey[700], border: `1px solid ${blueGrey[700]}`, background: 'white' },
}

export const styleForAvatar = {
  [NEW]: { color: 'white', backgroundColor: blue[700] },
  [STANDBY]: { color: 'white', backgroundColor: yellow[700] },
  [CANCELLED]: { color: 'white', backgroundColor: red[700] },
  [CONFIRMED]: { color: 'white', backgroundColor: green[700] },
  [CANCELLED]: { color: 'black', backgroundColor: grey[500] },
  [OTHER]: { color: 'white', backgroundColor: blueGrey[500] },
}

export const styleForCode = {
  [NEW]: { color: blue[500] },
  [STANDBY]: { color: yellow[500] },
  [CANCELLED]: { color: red[500] },
  [CONFIRMED]: { color: green[500] },
  [CANCELLED]: { color: grey[300] },
  [OTHER]: { color: blueGrey[300] },
}

export function canCancelReservation(status) {
  let canCancelGroup = new Set([NEW, STANDBY, CONFIRMED, CHECKED_IN])
  return canCancelGroup.has(status)
}
