import React, { Component} from 'react'
import { withStyles } from '@material-ui/core'
import TabPosHeaders from './TabPosHeader'
import compose from 'recompose/compose'
import MenuSetting from './MenuSetting'

const styles = theme => ({
  tabContainer: {
    padding: theme.spacing.unit * 3,
    width: '100%',
  }
})

class SettingPosItem extends Component {
  render() {
    let {
      record,
      tabIndexPos,
      onChangeTabIndexPos,
      isAdmin,
      classes,
      match,
      location,
      resources,
      menuItems=[],
      actionItems=[],
      hideMenu,
      actionDriver,
    } = this.props
    return <div className={classes.tabContainer}>
      <TabPosHeaders
        tabIndexPos={tabIndexPos}
        onChangeTabIndexPos={(v) => {
          onChangeTabIndexPos(v)
        }}
        isAdmin={isAdmin}
        action='update'
      />
      {isAdmin && tabIndexPos === 0 && <div className={classes.tabContainer}>
        <MenuSetting
          match={match}
          record={record}
          location={location}
          tabIndexPos={tabIndexPos}
          hideMenu={hideMenu}
          resources={resources}
          items={menuItems}
        />
      </div>}
      {isAdmin && tabIndexPos === 1 && <div className={classes.tabContainer}>
        <MenuSetting
          match={match}
          record={record}
          location={location}
          tabIndexPos={tabIndexPos}
          hideMenu={hideMenu}
          resources={resources}
          items={actionItems}
          actionDriver={actionDriver}
          actionSetting="true"
        />
      </div>}
    </div>
  }
}

export default compose(
  withStyles(styles)
)(SettingPosItem)
