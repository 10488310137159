import React, { Component } from 'react'
import { TextInput, SelectInput, ReferenceInput, showNotification, translate } from 'react-admin'
import { withStyles, Grid } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import Map from './../common/Map'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import { changeLocation } from './actions'

const provider = new OpenStreetMapProvider()

const styles = () => ({
  root: {
  },
  map: {
    marginTop: 32
  }
})

class FormSave extends Component {
  constructor(props) {
    super(props)

    this.state = {
      zoom: 13,
      center: {
        lat: props.record.lat,
        lon: props.record.lon,
      },
      stopLocation: {
        title: props.record.name,
        lat: props.record.lat,
        lon: props.record.lon,
      }
    }
  }

  onAddressChange = async (rawAddress) => {
    let { showNotification } = this.props
    if (rawAddress && rawAddress !== '') {
      let searchResult = await provider.search({ query: rawAddress })

      console.log(searchResult)

      if (searchResult && searchResult.length > 0) {
        let geoJson = searchResult[0]
        let { lat, lon } = geoJson.raw
        this.setState({
          center: { lat, lon },
        })
      } else {
        showNotification('resources.stops.notifications.notFoundAddress', 'warning')
      }
    }
  }

  onPickGeoLocation = (k) => {
    let { changeLocation } = this.props
    let { latlng } = k
    let lat = latlng.lat.toFixed(6)
    let lon = latlng.lng.toFixed(6)

    this.setState({
      center: { lat, lon },
      stopLocation: { ...this.state.stopLocation, lat, lon },
    }, () => {
      changeLocation('record-form', 'lat', lat)
      changeLocation('record-form', 'lon', lon)
    })
  }

  render() {
    const { classes, translate } = this.props
    let { center, stopLocation, zoom } = this.state

    return (
      <Grid container className={classes.root}>
        <Grid container item xs={6} >
          <Grid item xs={12} >
            <TextInput source="name" label="resources.stops.name_stop"
              onChange={(k) => {
                this.setState({ stopLocation: { ...stopLocation, title: k.target.value } })
              }}
              fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="address" label="resources.stops.address"
              onBlur={(k) => this.onAddressChange(k.target.value)}
              helperText={translate('resources.stops.helperText.address')}
              fullWidth />
          </Grid>

          <Grid item xs={12}>
            <TextInput source="desc" label="resources.stops.desc" fullWidth />
          </Grid>

          <Grid item xs={6}>
            <TextInput type="number"
              inputProps={{ min: "0", step: "0.01" }}
              source="lat" label="resources.stops.lat"
              onChange={(k) => {
                this.setState({
                  center: { ...center, lat: k.target.value },
                  stopLocation: { ...stopLocation, lat: k.target.value }
                })
              }}
              fullWidth />
          </Grid>

          <Grid item xs={6}>
            <TextInput type="number"
              inputProps={{ min: "0", step: "0.01" }}
              source="lon" label="resources.stops.lon"
              onChange={(k) => {
                this.setState({
                  center: { ...center, lon: k.target.value },
                  stopLocation: { ...stopLocation, lat: k.target.value }
                })
              }}
              fullWidth />
          </Grid>

          <Grid item xs={6}>
            <ReferenceInput source="type" label="resources.stops.type" resource="stoptypes" reference="stoptypes" fullWidth>
              <SelectInput optionText="name" />
            </ReferenceInput>
          </Grid>

          <Grid item xs={6}>
            <TextInput source="url" label="resources.stops.url" fullWidth />
          </Grid>
        </Grid>
        <Grid container item xs={6} >
          <Map className={classes.map} width={'100%'} height={'auto'}
            centerPoint={{ lat: center.lat, lon: center.lon }}
            zoom={zoom}
            onClick={(k) => this.onPickGeoLocation(k)}
            onMoveEnd={({ zoom, center }) => {
              this.setState({ zoom, center: { lat: center.lat, lon: center.lng } })
            }}
            markers={[{ lat: stopLocation.lat, lon: stopLocation.lon, title: stopLocation.title }]}
          />
        </Grid>
      </Grid>
    )
  }
}

const enhance = compose(
  connect(null,
    {
      showNotification,
      changeLocation
    }),
  translate,
  withStyles(styles),
)

export default enhance(FormSave)