import React from 'react'
import moment from 'moment'
import GetAppIcon from '@material-ui/icons/GetApp'
import { getCsvFromSeatMap } from './xlsxUtil'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { translate } from 'react-admin'
import { getNumberOfSeat } from './index'
import slugify from 'voca/slugify'
import FileSaver from 'file-saver'

const _CustomExportButton = (props) => {
  let onHandleExport = () => {
    let { record, translate } = props
    let { codesFistFloor, codesSecondFloor, name, privateCode } = record
    let csvContent = getCsvFromSeatMap(name, codesFistFloor, codesSecondFloor, privateCode, translate)
    const file = new Blob([csvContent], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    let fileName = `${slugify(name)}-${getNumberOfSeat(record)}-${moment().format('YYYYMMDDhhmmss')}.xlsx`
    FileSaver.saveAs(file, fileName)
  }
  return (
    <Button 
      style={{ fontSize: '0.6964285714285714rem' }} 
      onClick={onHandleExport} 
      color="primary" 
      component='span'
    >
      <GetAppIcon style={{fontSize: '20px'}} />
      <span style={{ paddingLeft: '0.5em' }}>{props.translate('button.export')}</span>
    </Button>
  )
}

export const CustomExportButton = compose(
  translate,
)(_CustomExportButton)
