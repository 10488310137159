export const colors = [
  '#F4A460',
  '#4876FF',
  '#9266cc',
  '#4dc9f6',
  '#f67019',
  '#f53794',
  '#537bc4',
  '#acc236',
  '#166a8f',
  '#00a950',
  '#58595b',
  '#8549ba',
  'rgb(255, 99, 132)',
  'rgb(255, 159, 64)',
  'rgb(255, 205, 86)',
  'rgb(75, 192, 192)',
  'rgb(54, 162, 235)',
  'rgb(153, 102, 255)',
  'rgb(201, 203, 207)',
]

const disabledColor = '#ccc'

export const getColor = index => {
  if (index < 0) return disabledColor
  return colors[index % colors.length]
}