import React, { Component } from 'react'
import {
  TextInput,
  ReferenceInput,
  FunctionField,
  Datagrid,
  translate,
  ReferenceManyField,
  TextField,
  Pagination,
  ReferenceField,
  addField,
  NumberField,
  SelectInput,
  required,
  minLength,
  maxLength
} from 'react-admin'
import { withStyles, Grid, Typography, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import ChipArrayInput from '../common/ChipArrayInput'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import CancelCompanyAgencyButton from './CancelCompanyAgencyButton'
import { resourceFieldText } from '../utils/resourceText'
import PhoneInputMask from '../common/PhoneInput'
import * as dataType from '../common/data-type'

const styles = (theme) => {
  return ({
    root: {
      // display: 'flex',
      // flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    stopList: {
      padding: 12
    },
    card: {
      padding: 12
    },
    checkbox: {
      display: 'flex'
    },
    item: {
      padding: 0
    },
  })
}

class _PhoneInput extends Component {
  render() {
    let { input } = this.props
    return <ChipArrayInput
      input={input}
      maskComponent={PhoneInputMask}
      type={dataType.PHONE}
    />
  }
}
const PhoneInput = addField(_PhoneInput)

class FormSave extends Component {
  render() {
    const {
      classes,
      resource,
      id,
      translate,
      isAdmin,
      isAgencyManager,
      history,
    } = this.props
    const extra = { resource, fullWidth: true }
    const _fa = resourceFieldText('financeaccounts') 
    const _c = resourceFieldText('companies') 

    return (
      <Grid container className={classes.root}>
        <Grid item xs={6}>
          <ReferenceInput
            reference="agencytypes"
            source="type"
            allowEmpty
            {...extra}
            validate={required()}
          >
            <SelectInput source="name" />
          </ReferenceInput>
          <TextInput 
            source="name"
            {...extra} 
            validate={[required(), minLength(6), maxLength(256)]}
          />
          <TextInput source="address" {...extra} />
          <TextInput source="desc" {...extra} />
          <Grid container spacing={8}>
            <Grid item xs={6} className={classes.item}>
              <TextInput source="email" type="email" {...extra} />
            </Grid>

            <Grid item xs={6} className={classes.item}>
              <TextInput source="url" {...extra} />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6} className={classes.item}>
              <PhoneInput source='tel' />
            </Grid>
            <Grid item xs={6} className={classes.item}>
              <TextInput source="fax" {...extra} />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={6} className={classes.item}>
              <TextInput source="registrationNumber" {...extra} />
            </Grid>

            <Grid item xs={6} className={classes.item}>
              <TextInput source="taxNumber" {...extra} />
            </Grid>
          </Grid>
          <Grid container spacing={8}>
            <Grid item xs={4} className={classes.item}>
              <ReferenceInput
                source="countryId"
                reference="countries"
                resource="countries"
                filter={{'../fields': ['id', 'name'] }}
                perPage={1000}
                {...extra}
              >
                <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
              </ReferenceInput>
            </Grid>

            <Grid item xs={4} className={classes.item}>
              <ReferenceInput
                source="provinceId"
                reference="provinces"
                resource="provinces"
                filter={{'../fields': ['id', 'name'] }}
                perPage={1000}
                {...extra}
              >
                <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
              </ReferenceInput>
            </Grid>

            <Grid item xs={4} className={classes.item}>
              <ReferenceInput
                source="cityId"
                reference="cities"
                resource="cities"
                filter={{'../fields': ['id', 'name'] }}
                perPage={1000}
                {...extra}
              >
                <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
              </ReferenceInput>
            </Grid>
          </Grid>
        </Grid>
        {id && <Grid container item md={6} xs={6}>
          <Grid item md={12} xs={12}>
            <Typography variant="h4">{translate('resources.companies.name', { smart_count: 1 })}</Typography>
            <ReferenceManyField
              basePath="/companyagencies"
              record={{}}
              reference="companyagencies"
              target=""
              filter={{
                agencyId: id,
                '../include': {
                  relation: 'company',
                  scope: { fields: ['id', 'name'] },
                }
              }}
              pagination={<Pagination />}
              perPage={10}
            >
              <Datagrid>
                <TextField source="company.name" label={_c('name')} />
                <ReferenceField
                  source="status"
                  reference="statuses"
                  allowEmpty
                  linkType={false}
                  label={_c('status')}
                >
                  <TextField source="name" />
                </ReferenceField>
                {isAdmin && <FunctionField render={record => <CancelCompanyAgencyButton record={record} />} />}
              </Datagrid>
            </ReferenceManyField>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant="h4">{translate('resources.financeaccounts.name', { smart_count: 2 })}</Typography>
            <ReferenceManyField
              basePath="/financeaccountmappings"
              record={{}}
              reference="financeaccountmappings"
              target=""
              filter={{ refId: id, type: 'AGE', '../include': 'financeAccount' }}
              pagination={<Pagination />}
              perPage={5}
            >
              <Datagrid>
                <TextField source="financeAccount.accountName" label={_fa('accountName')} />
                <TextField source="financeAccount.accountNumber" label={_fa('accountNumber')} />
                <TextField source="financeAccount.bank" label={_fa('bank')} />
                <NumberField source="financeAccount.deposit" label={_fa('deposit')}  locales="vi-Vn" options={{ style: 'currency', currency: 'VND' }}/>
                {(isAdmin || isAgencyManager) && <FunctionField
                  render={record => <Button
                    color="primary"
                    onClick={() => {
                      history.push(`/financeaccounts/${record.accountId}`)
                    }}
                  >
                    {translate('button.edit')}
                  </Button>}
                />}
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
        }
      </Grid >
    )
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
)

export default enhance(FormSave)
