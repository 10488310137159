import React, { Component } from 'react'
import {
  Chip,
  withStyles,
  FormControl,
  Input,
  Tooltip,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import { translate, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import { formatCurrency } from '../utils/formatUtil'
import _MoneyInput from '../common/MoneyInput'
import _ from 'lodash'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336'
    },
  })
}

const editInputStyles = (theme) => {
  return ({
    formControl: {
      margin: theme.spacing.unit,
    },
  })
}

class _EditInput extends Component {

  state = {}

  componentDidMount() {
    let { amount } = this.props
    this.setState({ value: amount })
  }

  render() {
    let { classes, handleUpdate, hideEditMode, validate } = this.props
    let { value } = this.state
    return <FormControl className={classes.formControl}>
      <Input
        autoFocus
        classes={{ root: classes.input }}
        value={value}
        onChange={evt => {
          let { value } = evt.target
          this.setState({ value })
          validate(value)
        }}
        onBlur={() => {
          hideEditMode()
        }}
        onKeyUp={evt => {
          evt.preventDefault()
          if (evt.key === 'Enter') {
            handleUpdate(value)
          } else if (evt.key === 'Esc' || evt.key === 'Escape') {
            hideEditMode()
          }
        }}
        inputComponent={_MoneyInput}
      />
    </FormControl>
  }
}

const EditInput = withStyles(editInputStyles)(_EditInput)

function getMaxAmount(productFareMapping, parentProductFareId, productFareId) {
  let { childFares, amount: parentAmount } = productFareMapping[parentProductFareId] || {}
  let sumOfOtherProductAmount = _.sumBy(childFares, childFare => {
    if (childFare.productFareId !== productFareId) {
      return childFare.amount || 0
    }
  }) || 0
  return parentAmount - sumOfOtherProductAmount
}

class FareChip extends Component {

  state = {
    edit: false,
    unLimited: false,
    quantity: 0,
  }

  componentDidMount() {
    let { record, source } = this.props
    let amount = record[source] || 0
    this.setState({ amount })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { productFareMapping, record } = nextProps
    let { productFareMapping: currentProductFareMapping } = prevState
    if (productFareMapping !== currentProductFareMapping) {
      let { parentProductFareId, productFareId } = record
      let maxAmount = getMaxAmount(productFareMapping, parentProductFareId, productFareId)
      return { ...prevState, maxAmount, productFareMapping }
    }
    return { ...prevState }
  }

  handleEdit = () => {
    this.setState({ edit: true })
  }

  hideEditMode = () => {
    this.setState({ edit: false, errorMessage: null })
  }

  validate = amount => {
    let { maxAmount } = this.state
    let { translate } = this.props
    let valid = amount <= maxAmount
    if (valid) {
      this.setState({ errorMessage: null })
    } else {
      let errorMessage = translate('error_messages.invalid.amount_child_fare', { maxAmount: formatCurrency(maxAmount), smart_count: maxAmount > 0 ? 1 : 2 })
      this.setState({ errorMessage })
    }
    return valid
  }

  handleUpdate = (amount) => {
    let { record, showNotification, source, updateChildProductFare } = this.props
    let { id, [source]: oldAmount, parentProductFareId, productFareId } = record
    let {errorMessage} = this.state
    if (errorMessage) {
      return
    }
    if (amount === oldAmount) {
      this.setState({ edit: false })
    } else {
      Provider.dataProvider('REMOTE', 'fares', {
        method: id,
        requestMethod: 'PATCH',
        data: { [source]: amount },
      }).then(() => {
        this.setState({ edit: false, amount })
        updateChildProductFare(parentProductFareId, productFareId, amount)
        showNotification('notification.change_fare_success')
      }).catch(() => {
        this.setState({ amount: oldAmount, edit: false })
        showNotification('notification.change_fare_failure', 'warning')
      })
    }
  }

  render() {
    let { classes, hideLabel, record } = this.props
    let { edit, amount, errorMessage  } = this.state
    let label = hideLabel ? (amount ? formatCurrency(amount) : '0đ') : `${record.name}: ${amount ? formatCurrency(amount) : '0đ'}`
    return <Tooltip 
      placement="right-end"
      open={!(!errorMessage)}
      classes={{
        tooltip: classes.tooltip
      }}
      arrow="true"
      title={errorMessage}
    >
      <Chip
        size="small"
        variant="outlined"
        color="primary"
        label={edit ? <EditInput
          onChange={this.onChange}
          handleUpdate={this.handleUpdate}
          validate={this.validate}
          amount={amount}
          hideEditMode={this.hideEditMode}
        /> : label}
        className={classes.chip}
        onClick={() => {
          if (!edit) {
            this.handleEdit()
          }
        }}
      />
    </Tooltip> 
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification })
)
export default enhance(FareChip)
