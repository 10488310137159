import React, { Component, Fragment } from 'react'
import {
  IconButton,
  withStyles,
} from '@material-ui/core'
import {
  addField,
} from 'react-admin'
import _ from 'lodash'
import ReactDatagrid from 'react-data-grid'
import { red } from '@material-ui/core/colors'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faUnlink } from '@fortawesome/free-solid-svg-icons'
import { formatCurrency } from '../utils/formatUtil'
import ColorButton from '../fare_companion/ColorButton'
import PropTypes from 'prop-types'

const styles = {
  icon: {
    width: 32,
    height: 32,
    padding: 0,
    marginTop: -8,
    float: 'right',
  }
}

const cellStyles = {
  normal: {
  },
  basePrice: {
    color: red[800],
    fontWeight: 700,
  },
  fomular: {
    fontSize: 8,
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
}

function fomularEval(baseValue, fomular) {
  if (!fomular || typeof baseValue == 'undefined') return 0

  if (typeof fomular !== 'string') fomular = String(fomular)
  if (fomular.match(/%$/)) {
    return parseInt(baseValue * parseFloat(fomular) / 100)
  } else if (fomular.match(/^(\+|-)/)) {
    return parseInt(baseValue + parseFloat(fomular))
  } else {
    return parseInt(parseFloat(fomular))
  }
}


class _BaseCellFormatter extends Component{

  state = {}

  componentDidMount() {
    let { row } = this.props
    let currentColor = _.get(row, 'color')
    this.setState({ currentColor })
  }

  setCurrentColor = (color) => {
    let { currentColor } = this.state
    if (currentColor !== color) {
      this.setState({ currentColor: color })
    }
  }

  render() {
    let { classes, value, row, setColor, } = this.props
    let { fareIndexId, key } = row
    let { currentColor } = this.state
    if (typeof value === 'object') {
      return (
        <div style={row.isBase ? { color: currentColor } : ''}>
          {value.value && formatCurrency(value.value)}
          <div className={classes.fomular}>
            {!row.isBase ? value.fomular : <ColorButton
              color={currentColor}
              setColor={(newColor) => {
                this.setCurrentColor(newColor)
                fareIndexId && setColor(newColor, { field: 'fareIndexId', value: fareIndexId })
                !fareIndexId && key && setColor(newColor, { field: 'key', value: key })
              }}
            />}
          </div>
        </div>
      )
    } else {
      return <span>{value}</span>
    }
  }
}
const BaseCellFormatter = withStyles(cellStyles)(_BaseCellFormatter)

const _CellFormatter = ({ classes, value }) => {
  if (typeof value === 'object') {
    return (
      <div>
        {value.value && formatCurrency(value.value)}
        <div className={classes.fomular}>{value.fomular}</div>
      </div>
    )
  } else {
    return <span>{value}</span>
  }
}
const CellFormatter = withStyles(cellStyles)(_CellFormatter)

const HeaderRenderer = withStyles(styles)(({ classes, column, onClick }) => {
  return (
    <Fragment>
      {column.name}
      <IconButton
        color="primary"
        className={classes.icon}
        onClick={() => onClick(column)}
      >
        <FontAwesomeIcon icon={column.lock ? faLink : faUnlink} size="xs" />
      </IconButton>
    </Fragment>
  )
})


class CellEditor extends Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    column: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.isObject = typeof props.value === 'object'
  }

  getValue() {
    let updated = {}
    let { props } = this
    let value = this.getInputNode().value
    updated[props.column.key] = value
    return updated
  }

  getInputNode() {
    return this.input
  }

  render() {
    let { props, isObject } = this
    return (
      <input
        ref={ref => this.input = ref}
        defaultValue={isObject ? props.value.fomular : props.value}
        type="number"
      />
    )
  }
}

function recalcRow(gridColumns, row, base, isFirst = false) {
  let data = { ...row.data }
  let { rowBase: { fomular } } = data
  data.rowBase = { fomular, value: isFirst ? parseInt(fomular) : fomularEval(base, fomular) }
  let rowBase = data.rowBase.value

  for (let i = 1; i < gridColumns.length; i++) {
    let key = gridColumns[i].key
    if (data[key] && key !== 'rowBase') {
      let { fomular } = data[key]
      data[key] = {
        ...data[key],
        fomular,
        value: fomularEval(rowBase, fomular),
      }
    }
  }
  return { ...row, data, base }
}

class _FareMatrix extends Component {
  static propTypes = {
    fareTable: PropTypes.object.isRequired,
    gridColumns: PropTypes.array.isRequired,
    base: PropTypes.number.isRequired,
    input: PropTypes.object.isRequired,
    isFirst: PropTypes.bool.isRequired,
  }
  state = {}

  componentDidMount() {
    let { gridColumns } = this.props
    this.setState({ gridColumns })
  }

  static getDerivedStateFromProps(nextProps, prevState = {}) {
    let { base, gridColumns, isFirst, subRecord = {} } = nextProps
    let { color, fareIndexId, key } = subRecord
    if (base !== prevState.base) {
      // change base value
      let { input } = nextProps

      base = parseFloat(base)
      let value = input.value.map((item, index) => {
        let row = recalcRow(gridColumns, item, base, isFirst && index === 0)
        _.set(row, 'data.color', color)
        _.set(row, 'data.fareIndexId', fareIndexId)
        _.set(row, 'data.key', key)
        return row
      })
      input.onChange(value)
      return { base }
    }
    return null
  }

  onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    let { input, base, gridColumns, isFirst } = this.props
    let { value = [] } = input

    // update base price
    if (fromRow === 0 && updated.rowBase && isFirst) {
      base = parseFloat(updated.rowBase)
    }

    value = _.cloneDeep(value)
    let globalFrom = input.value.length - 1
    let globalTo = 0
    Object.keys(updated).forEach(key => {
      let column = _.find(gridColumns, { key })
      let from, to
      if (column.lock) {
        from = 0
        to = input.value.length - 1
      } else {
        from = fromRow
        to = toRow
      }
      globalFrom = Math.min(globalFrom, from)
      globalTo = Math.max(globalTo, to)
      for (let r = from; r <= to; r++) {
        let row = value[r]
        let { data = {} } = row
        const currencyRegex = /([-])/g
        let newFomular = updated[key].replace(currencyRegex, '')
        let oldValue = data[key]
        data[key] = { ...oldValue, fomular: newFomular }
        row.data = data
      }
    })
    for (let r = globalFrom; r <= globalTo; r++) {
      value[r] = recalcRow(gridColumns, value[r], base)
    }

    input.onChange(value)
  }

  render() {
    let { input, fareTable, gridColumns } = this.props
    let rows = input.value || []
    if (!fareTable) return null
    return (
      <ReactDatagrid
        columns={gridColumns}
        rowsCount={rows.length}
        rowGetter={i => rows[i] && rows[i].data}
        enableCellSelect
        onGridRowsUpdated={this.onGridRowsUpdated}
      />
    )
  }
}

const FareMatrix = addField(_FareMatrix)

export {
  FareMatrix,
  BaseCellFormatter,
  CellFormatter,
  HeaderRenderer,
  CellEditor,
  recalcRow,
}

