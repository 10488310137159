import React, { Component, Fragment, } from 'react'
import {
  Datagrid,
  List,
  TextField,
  Create,
  Edit,
  ReferenceField,
  Filter,
  TextInput,
  FunctionField,
  translate,
  Show,
} from 'react-admin'
import FormSave from './FormSave'
import FormView from './FormView'
import { EditTitle } from '../common/Title'
import LinkField from './../common/LinkField'
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon ,
} from '@material-ui/icons'
import { withStyles, Tooltip } from '@material-ui/core'
import compose from 'recompose/compose'

////////////////////////////////////////////////////////////////////////////////
// List
const ReceivePlaceListFilter = (props) => {
  return (
    <Filter {...props}>
      <TextInput label="resources.common.search" source="q" alwaysOn fullWidth />
    </Filter>
  )
}

const styleList = () => ({
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
})

const _ReceivePlaceList = props => {
  let { classes, translate } = props
  props = { ...props, resource: 'stops' }
  return (
    <List
      bulkActionButtons={false}
      filters={<ReceivePlaceListFilter />}
      filter={{
        where: { type: '50RECEIVE' },
        include: [{ relation: 'parent' }],
        order: 'createdAt DESC',
      }}
      {...props}
    >
      <Datagrid>
        <TextField label="resources.receiveplaces.fields.name" source="name" />
        <TextField label="resources.receiveplaces.fields.code" source="code" />
        <TextField label="resources.receiveplaces.fields.address" source="address" />
        <ReferenceField
          label="resources.receiveplaces.fields.parentId"
          source="parentId"
          reference="stops"
          allowEmpty
          linkType={false}
        >
          <TextField source="name" />
        </ReferenceField>
        <FunctionField
          headerClassName={classes.textEnd}
          cellClassName={classes.textEnd}
          render={({ id }) => {
            return <Fragment>
              <Tooltip
                title={translate('button.view_detail')}
                enterDelay={100}
              >
                <LinkField
                  className={classes.iconButton}
                  path={`/receiveplaces/${id}/show`}
                  icon
                >
                  <VisibilityIcon fontSize="small" />
                </LinkField>
              </Tooltip>
              <Tooltip
                title={translate('button.edit')}
                enterDelay={100}
              >
                <LinkField
                  className={classes.iconButton}
                  path={`/receiveplaces/${id}`}
                  icon
                >
                  <EditIcon fontSize="small" />
                </LinkField>
              </Tooltip>
            </Fragment>
          }}
        />
      </Datagrid>
    </List>
  )
}

const enhanceList = compose(withStyles(styleList), translate)
export const ReceivePlaceList = enhanceList(_ReceivePlaceList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const ReceivePlaceCreate = props => {
  props = { ...props, resource: 'stops' }
  return (
    <Create
      {...props}
      undoable={false}
      title={<EditTitle resource="receiveplaces" />}
    >
      <FormSave />
    </Create>
  )
}

////////////////////////////////////////////////////////////////////////////////
// Edit

export class ReceivePlaceEdit extends Component {
  render() {
    let props = { ...this.props, resource: 'stops' }
    return <Edit
      {...props}
      undoable={false}
      title={<EditTitle resource="receiveplaces" />}
    >
      <FormSave />
    </Edit>
  }
}
////////////////////////////////////////////////////////////////////////////////
// Show
export const ReceivePlaceShow = props => {
  props = { ...props, resource: 'stops' }
  return (
    <Show
      {...props}
      actions={false}
      undoable={false}
      title={<EditTitle resource="receiveplaces" />}
    >
      <FormView />
    </Show>
  )
}
