import React, { Component } from 'react'
import {
  TextInput,
  showNotification,
  translate,
  SimpleForm,
  FormDataConsumer,
} from 'react-admin'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import CustomToolbar from '../common/CustomToolbarForm'
import SelectInputCustom from '../common/SelectInputCustom'
import voca from 'voca'
import { change as changeForm, reset as resetForm } from 'redux-form'
import { Provider } from '../provider'
import _ from 'lodash'
import { changeBreadcrumb } from '../breadcrumb/action'

const RECEIVE_PLACE_FORM = 'receive-place-form'

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = { record: {} }
  }

  async componentDidMount() {
    const { record } = this.props
    if (record && record.id) {
      const { code, name, address, parentId } = record
      this.setState({
        record: {
          code,
          name,
          address,
          parentId,
        }
      })
    }
    await this.getZones()
  }

  getZones = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'stops', {
      filter: {
        where: {
          type: '50RECEIVE',
          parentId: null,
        }
      },
      sort: {},
      pagination: {}
    })
    if (res && res.data) {
      const zones = _.reduce(res.data, (result, item) => {
        let { id, name } = item
        result.push({ id, name })
        return result
      }, [])
      this.setState({ zones })
    }
  }

  onSave = (values) => {
    const { showNotification, record, push, resetForm, form } = this.props
    let receivePlaceId = _.get(record, 'id')
    const { name = '', address = '', code = '', parentId = '' } = values
    let data = {
      name,
      address,
      code,
      parentId,
    }
    if (receivePlaceId) {
      Provider.dataProvider('REMOTE', 'stops', {
        method: receivePlaceId,
        requestMethod: 'PATCH',
        data
      }).then(() => {
        resetForm(form)
        push('/receiveplaces')
        showNotification('notification.update_receive_place_success', 'success')
      }
      ).catch(err => {
        showNotification(_.get(err, 'body.error.message') || err.message, 'warning')
      })
    } else {
      Provider.dataProvider('CREATE', 'stops', {
        data: {
          ...values,
          type: '50RECEIVE',
        }
      }).then(() => {
        resetForm(form)
        push('/receiveplaces')
        showNotification('notification.create_receive_place_success', 'success')
      }
      ).catch(err => {
        showNotification(_.get(err, 'body.error.message') || err.message, 'warning')
      })
    }
  }

  render() {
    const { translate, } = this.props
    let { zones, record = {}} = this.state
    let extra = { resource: 'receiveplaces' }

    return (
      <SimpleForm
        {...this.props }
        record={record}
        form={RECEIVE_PLACE_FORM}
        resource="receiveplaces"
        toolbar={<CustomToolbar />}
        submitOnEnter={false}
        save={this.onSave}
        validate={(values) => {
          let name = _.get(values, 'name')
          let errors = {}
          if (!name) {
            errors.name = translate('error_messages.required.receive_places.name')
          } else if (name && !name.trim().length) {
            errors.name = translate('error_messages.required.receive_places.name')
          }
          return errors
        }}
      >
        <TextInput
          label="resources.receiveplaces.fields.name"
          source="name"
          required
          fullWidth
          { ...extra }
        />
        <FormDataConsumer>
          {( { formData } ) => {
            const { name } = formData
            let code = voca.slugify(voca.latinise(name))
            return <TextInput
              defaultValue={name ? code : ''}
              label="resources.receiveplaces.fields.code"
              source="code"
              fullWidth
              { ...extra }
            />
          }}
        </FormDataConsumer>
        <TextInput
          label="resources.receiveplaces.fields.address"
          source="address"
          fullWidth
          { ...extra }
        />
        <SelectInputCustom
          choices={zones}
          label="resources.receiveplaces.fields.zones"
          source="parentId"
          allowEmpty
          all="Không"
          fullWidth
          {...extra}
        />
      </SimpleForm>
    )
  }
}

const enhance = compose(
  translate,
  connect(null, {
    showNotification,
    changeForm,
    resetForm,
    changeBreadcrumb,
    push,
  })
)

export default enhance(FormSave)
