import { GET_ONE } from 'react-admin'

export const GET_ZONES = 'GET_ZONES'
export const GET_ZONES_FAILURE = 'GET_ZONES_FAILURE'
export const GET_ZONES_SUCCESS = 'GET_ZONES_SUCCESS'

export const getZone = (start, done, params) => {
  return ({
    type: GET_ZONES,
    payload: {
      id: params.id
    },
    meta: {
      resource: 'zones',
      fetch: GET_ONE,
    },
    start,
    done
  })
}