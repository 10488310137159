import React, { Component } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = {
  button: {
    margin: '0.5em',
  },
}

//@see https://itnext.io/add-confirmation-dialog-to-react-events-f50a40d9a30d
class Confirm extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      callabck: null
    }
  }
  
  show = callback => event => {
    event.preventDefault()
    event = {
      ...event,
      target: { ...event.target, value: event.target.value }
    }

    this.setState({
      open: true,
      callback: () => callback(event)
    })
  }

  hide = () => this.setState({ open: false, callback: null })

  confirm = () => {
    this.state.callback()
    this.hide()
  }

  render() {
    let { title, desc, translate } = this.props
    return (
      <div>
        {this.props.children(this.show)}
        {this.state.open &&
        <Dialog
          open={ this.state.open }
          onClose={this.hide}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          TransitionComponent={Transition}
          keepMounted
          fullWidth={true}
        >
          <DialogTitle id="alert-dialog-title">
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {desc}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.hide} color="primary">{translate('button.cancel')}</Button>
            <Button onClick={this.confirm} color="primary">{translate('button.ok')}</Button>
          </DialogActions>
        </Dialog>
        }
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(Confirm)
