import { MapProps } from '../common/constants'

// -----------------------------------------------------------------------------
export const MAP_MOVE = 'MAP_MOVE'

export const mapMove = (params) => {
  let { zoom, center, bbox } = params
  return ({
    type: MAP_MOVE,
    payload: { zoom, center, bbox },
  })
}

// -----------------------------------------------------------------------------
export const TOGGLE_LAYER_STATE = 'TOGGLE_LAYER_STATE'

export const toggleLayerState = (params) => {
  let { identifier, visibility } = params
  return ({
    type: TOGGLE_LAYER_STATE,
    payload: {
      identifier,
      visibility
    },
  })
}

// -----------------------------------------------------------------------------
export const ADD_MAP_LAYER = 'ADD_MAP_LAYER'

export const addMapLayer = (params) => {
  let { type, name, color, data, identifier, actions } = params
  
  data = data || {}
  identifier = identifier || `Layer_${Math.random().toString(36).substring(7)}`
  name = name || `Layer_${Math.random().toString(36).substring(7)}`
  color = color || '#' + ((1 << 24) * Math.random() | 0).toString(16)

  let mapLayer = {
    type,
    data,
    identifier,
    name,
    color,
    actions,
    visibility: MapProps.LayerStates.VISIBLE
  }

  return ({
    type: ADD_MAP_LAYER,
    payload: mapLayer
  })
}

// -----------------------------------------------------------------------------
export const ADD_ROUTE = 'ADD_ROUTE'
export const TOGGLE_ROUTE = 'TOGGLE_ROUTE'
export const EDIT_ROUTE = 'EDIT_ROUTE'
export const UPDATE_ROUTE = 'UPDATE_ROUTE'

export const addRoute = (data) => {
  return ({
    type: ADD_ROUTE,
    payload: data
  })
}

export const toggleRoute = (data) => {
  return ({
    type: TOGGLE_ROUTE,
    payload: data
  })
}

export const editRoute = (data) => {
  return ({
    type: EDIT_ROUTE,
    payload: data
  })
}

export const updateRoute = (data) => {
  return ({
    type: UPDATE_ROUTE,
    payload: data
  })
}
// -----------------------------------------------------------------------------
export const GET_SQUARE_GRID = 'GET_SQUARE_GRID'
export const GET_SQUARE_GRID_FAILURE = 'GET_SQUARE_GRID_FAILURE'
export const GET_SQUARE_GRID_SUCCESS = 'GET_SQUARE_GRID_SUCCESS'

//GET /maps/square
export const getSquareGrid = (params) => {
  let { query, start, done } = params
  return {
    type: GET_SQUARE_GRID,
    payload: {
      data: query,
      method: 'square',
      requestMethod: 'GET'
    },
    meta: {
      resource: 'maps',
      fetch: 'REMOTE'
    },
    start,
    done,
  }
}

export const GET_JOURNEY = 'GET_JOURNEY'
export const GET_JOURNEY_SUCCESS = 'GET_JOURNEY_SUCCESS'
export const GET_JOURNEY_FAILURE = 'GET_JOURNEY_FAILURE'

//GET /routes/journey
export const getJourney = (params) => {
  let { id, pattern, start, done } = params
  return {
    type: GET_JOURNEY,
    payload: {
      method: `/${id}/journey/${pattern}`,
      requestMethod: 'GET'
    },
    meta: {
      resource: 'routes',
      fetch: 'REMOTE'
    },
    start,
    done,
  }
}