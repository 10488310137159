import React, { Component, Fragment } from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  withStyles,
  Popover,
  withWidth,
} from '@material-ui/core'
import { localeLanguage } from '../provider/i18n'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { changeLocale  } from 'react-admin'

const languageName = {
  vi: { name: 'Vietnamese', src: '/images/flag/vietnam-flag.png' },
  en: { name: 'English', src: '/images/flag/england-flag.png' },
}

const styleLanguageChoice = theme => ({
  paper: {
    padding: 2,
  },
  image: {
    [theme.breakpoints.down('sm')]:{
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]:{
      marginRight: 4,
    },
    width: 25,
    height: 13,
  },
  icon: {
    [theme.breakpoints.down('sm')]:{
      marginRight: 0,
    },
  },
})

const LanguageChoice = withStyles(styleLanguageChoice)(({
  classes,
  open,
  anchorEl,
  select,
  selected,
  handlePopoverClose,
  isXSmall,
}) => {
  return <Popover
    className={classes.popover}
    classes={{ paper: classes.paper }}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    onClose={() => handlePopoverClose()}
  >
    <List component="nav">
      {localeLanguage && Object.keys(localeLanguage).map((locale, idx) => {
        let { src, name } = languageName[locale]
        return <ListItem
          key={idx}
          button
          onClick={() => select(locale)}
          selected={selected === locale}
        >
          <ListItemIcon className={classes.icon}>
            <img src={src} alt={name} className={classes.image} />
          </ListItemIcon>
          { !isXSmall && <ListItemText primary={name} />}
        </ListItem>
      })}
    </List>
  </Popover>
})

const style = theme => ({
  button: {
    [theme.breakpoints.down('sm')]:{
      padding: 0
    }
  },
  text: {
    color: 'white',
    fontSize: 12,
    display: 'contents',
    textTransform: 'capitalize',
  },
  image: {
    width: 25,
    height: 13,
  },
})

class Language extends Component {

  state = {
    value: '',
    popover: {
      open: false,
      anchorEl: null,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { locale } = nextProps
    let { value } = prevState
    if (locale !== value) {
      return { ...prevState, value: locale }
    }
    return { ...prevState }
  }

  openPopover = event => {
    this.setState({
      popover: {
        open: true,
        anchorEl: event.target,
      }
    })
  }

  closePopover = () => {
    this.setState({
      popover: {
        open: false,
        anchorEl: null,
      }
    })
  }

  select = locale => {
    let { changeLocale } = this.props
    this.setState({
      value: locale,
      popover: {
        open: false,
        anchorEl: null,
      }
    })
    changeLocale(locale)
    localStorage.setItem('locale', locale)
  }

  render() {
    let { classes, width } = this.props
    let { value, popover } = this.state
    let { name, src } = languageName[value]
    let { open, anchorEl } = popover
    let isXSmall = width === 'xs'
    return <Fragment>
      <IconButton className={classes.button} onClick={this.openPopover}>
        <img src={src} alt={name} className={classes.image} />
      </IconButton>
      {open && <LanguageChoice
        isXSmall={isXSmall}
        open={open}
        anchorEl={anchorEl}
        handlePopoverClose={this.closePopover}
        selected={value}
        select={this.select}
      />}
    </Fragment>
  }
}

const mapStateToProps = (state) => {
  let locale = state['i18n'].locale
  return { locale }
}

const enhance = compose(
  withStyles(style),
  connect(mapStateToProps, { changeLocale }),
  withWidth(),
)

export default enhance(Language)
