import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  translate,
} from 'react-admin'
import { MoneyInput } from '../common/MoneyInput'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'

const dialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

class CloseForm extends Component {

  handleSave = (balanceCloseReal, notes) => {
    let {
      onClose,
      onOpenConfirm,
    } = this.props

    onClose()
    onOpenConfirm(balanceCloseReal, notes)
  }

  render() {
    const extra = {
      resource: 'possessions',
      fullWidth: true,
    }
    return <SimpleForm
      save={({balanceCloseReal, notes}) => this.handleSave(balanceCloseReal, notes)}
    >
      <MoneyInput
        label="resources.possessions.fields.balanceCloseReal"
        source="balanceCloseReal"
        fullWidth
        {...extra}
      />
      <TextInput
        label="resources.possessions.fields.notes"
        source="notes"
        {...extra}
      />
    </SimpleForm>
  }
}

const ehanceCloseForm = compose(translate, withStyles(dialogStyle))
export default ehanceCloseForm(CloseForm)
