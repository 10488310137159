import React, { Component } from 'react'
import { Avatar, Chip } from '@material-ui/core'
import { ArrowDropDown as ArrowDropDownIcon } from '@material-ui/icons'
import compose from 'recompose/compose'


class ProductFareTypeChip extends Component {

  render() {
    let { label, openMenu, record, icon, type, parentProductId } = this.props
    let color = type === '10PUB' ? 'primary' : 'default' 
    return <Chip
      variant="outlined"
      color={color}
      avatar={<Avatar color="primary">{icon}</Avatar>}
      label={label}
      onDelete={(event) => {
        if (parentProductId) return
        openMenu(event, record)
      }}
      deleteIcon={<ArrowDropDownIcon />}
    />
  }

}

const enhanceProductFareTypeChip = compose()
export default enhanceProductFareTypeChip(ProductFareTypeChip)

