import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import { unAssignLicenceToObject as unAssignLicenceToObjectAction } from './action'
import { translate } from 'react-admin'
import ConfirmDialog from '../common/ConfirmDialog'

const typeMap = {
  '00VNS': {
    object: 'Vehicle',
    resource: 'vehicles',
    optionText: 'plate',
    endText: 'Add vehicle'
  },
  '30VRC': {
    object: 'Vehicle',
    resource: 'vehicles',
    optionText: 'plate',
    endText: 'Add vehicle'
  },
  '20VNR': {
    object: 'Vehicle',
    resource: 'vehicles',
    optionText: 'plate',
    endText: 'Add vehicle'
  },
  '10LID': {
    object: 'Driver',
    resource: 'drivers',
    optionText: 'fullname',
    endText: 'Add driver'
  }
}

class UnAssignDialog extends Component {

  state = {
    open: false,
  }
  
  unAssignLicence = () => {
    let { record, handleDone, unAssignLicenceToObjectAction } = this.props
    let { type, id } = record
    let { resource } = typeMap[type]
    let data = { licenceId: id, resource }
    let done = () => {
      handleDone()
      this.close()
    }
    unAssignLicenceToObjectAction(data, done)
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  close = () => {
    this.setState({
      open: false
    })
  }

  render() {
    let { translate } = this.props
    let { open } = this.state
    return <>
      <Button
        color="primary"
        onClick={this.open}
      >
        {translate('button.unassign')}
      </Button>
      <ConfirmDialog
        open={open}
        onClose={this.close}
        onOk={this.unAssignLicence}
        title={translate('resources.common.confirm')}
        content={translate('notification.confirm_unassign_vehicle_driver')}
      />
    </>
  }
}

const enhance = compose(
  translate,
  connect(
    null,
    {
      unAssignLicenceToObjectAction,
    })
)

export default enhance(UnAssignDialog)