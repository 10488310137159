import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Create,
  Edit,
  FunctionField,
  ReferenceField,
  EditButton,
  Filter,
  TextInput
} from 'react-admin'
import FormSave from './FormSave'
import { EditTitle } from '../common/Title'

const AppListFilter = props => {
  return <Filter {...props}>
    <TextInput source="name" alwaysOn />
  </Filter>
}


export const AppList = props => (
  <List filters={<AppListFilter />} bulkActionButtons={false} {...props}>
    <Datagrid>
      <TextField source="name" />
      <TextField source="applicationId" />
      <ReferenceField
        allowEmpty
        basePath="/os"
        resource="os"
        reference="os"
        source="os"
        linkType={false}
      >
        <TextField source="name" />
      </ReferenceField>
      <FunctionField
        source="versionCode"
        render={record => {
          let { versionCode, versionName } = record
          versionCode = versionCode ? `[${versionCode}]` : ''
          versionName = versionName ? versionName : ''
          return `${versionCode}${versionName}`
        }}
      />
      <ReferenceField
        allowEmpty
        basePath="/appconfigs"
        resource="appconfigs"
        reference="appconfigs"
        source="configId"
      >
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export const AppCreate = props => <Create
  {...props}
  title={<EditTitle resource={props.resource} />}
>
  <FormSave />
</Create>

export const AppEdit = props => <Edit
  undoable={false}
  title={<EditTitle resource={props.resource} />}
  {...props}
>
  <FormSave />
</Edit>