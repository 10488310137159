/* eslint-disable no-unused-vars */
import React from 'react'
import { Route } from 'react-router-dom'
import MapField from './map'
import Network from './network'
import { CreateTripFromSchedule } from './schedule'
import Import from './import'
import TripsTimeline from './company_reservation/TripsTimeline'
import TripLayout from './company_reservation/TripLayout'
import DnDCalendar from './calendar/reminderCalendar'
import NetworkPlanningMap from './network/network-planning-layer2'
import SaleTicketLayout from './company_reservation/SaleTicketLayout'
import Registration from './member_card/Registration'
import Recharge from './member_card/Recharge'
import ProductReservation from './product/ProductReservation'
import ViewTripsClone from './viewtrip/ViewTripsClone'
import TicketEditor from './ticketlayout/TicketEditor'
import { ChargeReportWithPermission } from './chargereport'
import { MemberCardReport } from './member_card_report'
import SaleTicketSegmentLayout from './company_reservation/SaleTicketSegmentLayout'

export default [
  // <Route key={0} exact path="/trips/import" component={ImportTrip} />,
  <Route key={1} exact path="/maps" component={MapField} />,
  //<Route key={2} exact path="/networks" component={Network} />,
  <Route key={3} exact path="/networks/:id/:viewmode(view|simulation|real-time)" component={Network} />,
  <Route key={4} exact path="/schedules/createtrip" component={CreateTripFromSchedule} />,
  <Route key={5} exact path="/:resource/import" component={Import} />,
  <Route key={6} exact path="/networks/:id/planning" component={NetworkPlanningMap} />,
  <Route key={7} exact path="/reservations/company_create" component={TripsTimeline} />,
  <Route key={8} exact path="/reservations/trip_layout/:tripId" component={TripLayout} />,
  <Route key={9} exact path="/reminders" component={DnDCalendar} />,
  <Route key={10} exact path="/reservations/saletickets" component={SaleTicketLayout} />,
  <Route key={11} exact path="/viewtripsclone" component={ViewTripsClone} />,
  <Route key={12} exact path="/chargereports" component={ChargeReportWithPermission} />,
  <Route key={13} exact path="/membercards/registration" component={Registration} />,
  <Route key={14} exact path="/membercards/recharge" component={Recharge} />,
  <Route key={15} exact path="/product_reservation" component={ProductReservation} />,
  <Route key={16} exact path="/membercardreports" component={MemberCardReport} />,
  <Route key={17} exact path="/reservations/saleticketbysegments" component={SaleTicketSegmentLayout} />,
  <Route key={18} exact path="/ticketlayouts/:id/editor" component={TicketEditor} />,
]
