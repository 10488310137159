import {
  CHANGE_BREADCRUMB,
  CHANGE_LOCATION,
} from './action'

export const onChangeBreadcrumb = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_BREADCRUMB:
      return {
        ...state,
        paths: action.payload,
      }
    case CHANGE_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      }
    default:
      return state
  }
}