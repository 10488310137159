import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_GET_PERMISSIONS,
  fetchUtils
} from 'react-admin'
import { ROOT_URL } from './data'
import _ from 'lodash'
import { handleError } from '../provider/error'

// eslint-disable-next-line
const getBrowserName = () => {
  var ua = window.navigator.userAgent.match(/(opera|chrome|safari|firefox|msie)\/?\s*(\.?\d+(\.\d+)*)/i),
    browser
  if (window.navigator.userAgent.match(/Edge/i)
    || window.navigator.userAgent.match(/Trident.*rv[ :]*11\./i)) {
    browser = 'msie'
  } else {
    browser = ua[1].toLowerCase()
  }
  return browser
}

export default async (type, params) => {
  switch (type) {
    case AUTH_LOGIN: {
      try {
        let { username, password } = params
        let response = await fetchUtils.fetchJson(
          `${ROOT_URL}/users/login`,
          {
            method: 'POST',
            body: JSON.stringify({ username, password }),
          }
        )
        localStorage.setItem('token', response.json.id)
        let user = response.json
        user.roles = _.get(response.json, 'data.roles', []).reduce(function (acc, role) {
          acc[role.name] = 1
          return acc
        }, {})
        localStorage.setItem('user', JSON.stringify(user))
        return
      } catch (err) {
        return err && handleError(err.body)
      }
    }
    case AUTH_LOGOUT: {
      try {
        let token = localStorage.getItem('token')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('devices')
        if(token){
          await fetchUtils.fetchJson(
            `${ROOT_URL}/users/logout?access_token=${token}`, {
              method: 'POST'
            }
          )
        }
        return Promise.resolve()
      } catch (err){
        return err && handleError(err.body)
      }
    }
    case AUTH_ERROR: {
      const { statusCode } = params
      if (statusCode === 401 || statusCode === 403) {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('devices')
        return Promise.reject()
      }
      return Promise.resolve()
    }
    case AUTH_CHECK: {
      if (localStorage.getItem('token')) {
        return
      } else {
        throw new Error('No auth')
      }
      //return localStorage.getItem('token') ? Promise.resolve() : Promise.reject() 
    }
    case AUTH_GET_PERMISSIONS: {
      let user = await localStorage.getItem('user')
      if (user) {
        user = JSON.parse(user)
        return user.roles
      }
      return {}
    }
    default:
      throw new Error('Unknown method')
  }
}
