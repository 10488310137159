import React, { Component } from 'react'
import { addField, showNotification, translate, refreshView } from 'react-admin'
import compose from 'recompose/compose'
import { Chip, withStyles, Menu, MenuItem, ListItemText } from '@material-ui/core'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

const style = () => ({
  root: {
    marginTop: 16
  },
  chip: {
    marginRight: 8,
    padding: 4
  }
})

const RoleMenu = translate(({ anchorEl, open, onClose, roles = [], assignRole, translate }) => {
  return <Menu
    anchorEl={anchorEl}
    open={open}
    placement="right-start"
    onClose={() => onClose()}
  >
    <MenuItem disabled={true}>{translate('button.assign_role')}</MenuItem>
    {roles.map((role, index) => (
      <MenuItem
        key={index}
        onClick={() => assignRole(role.id)}
      >
        <ListItemText>{role.name}</ListItemText>
      </MenuItem>
    ))}
  </Menu>
})

class RoleChips extends Component {

  constructor(props) {
    super(props)
    this.state = {
      roles: [],
      roleChoices: [],
      menu: {
        open: false,
        anchorEl: null,
      }
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let { input } = nextProps
    let { value: roles } = input
    let { roles: rolesCurrent } = prevState
    if (roles !== rolesCurrent) {
      rolesCurrent = roles
    }
    return {
      roles: rolesCurrent || []
    }
  }

  async componentDidMount() {
    let roleChoices = await Provider.dataProvider('REMOTE', 'users', {
      method: 'roles',
      requestMethod: 'GET',
      data: {},
    })
    if (roleChoices && roleChoices.data) {
      roleChoices = roleChoices.data
    }
    this.setState({
      roleChoices: roleChoices || []
    })
  }

  handleOpenMenu = event => {
    this.setState({
      menu: {
        open: true,
        anchorEl: event.target,
      }
    })
  }

  handleCloseMenu = () => {
    this.setState({
      menu: {
        open: false,
        anchorEl: null,
      }
    })
  }

  assignRole = roleId => {
    let { id, showNotification, refreshView } = this.props
    let { roles, roleChoices } = this.state
    let roleMapping = {
      principalId: id,
      roleId,
      principalType: 'USER'
    }
    Provider.dataProvider('REMOTE', 'users', {
      method: 'assignRole',
      requestMethod: 'POST',
      data: roleMapping
    }).then(
      res => {
        let role = filter(roleChoices, choice => choice.id === roleId)
        roles = [...roles, ...role]
        this.setState({
          menu: {
            open: false,
            anchorEl: null,
          },
          roles
        }, () => {
          showNotification('notification.assign_role_success')
          refreshView()
        })
      }
    ).catch(
      error => {
        this.setState({
          menu: {
            open: false,
            anchorEl: null,
          },
        }, () => {
          showNotification('notification.assign_role_failure', 'warning')
          refreshView()
        })
      }
    )
  }

  unAssignRole = roleId => {
    let { id, showNotification, refreshView } = this.props
    let { roles } = this.state
    let roleMapping = {
      principalId: id,
      roleId,
    }
    Provider.dataProvider('REMOTE', 'users', {
      method: 'unAssignRole',
      requestMethod: 'POST',
      data: roleMapping
    }).then(
      res => {
        let roleIdx = findIndex(roles, role => role.id === roleId)
        roles = [...roles.slice(0, roleIdx), ...roles.slice(roleIdx + 1)]
        this.setState({ roles })
        showNotification('notification.unassign_role_success')
        refreshView()
      }
    ).catch(
      error => {
        showNotification('notification.unassign_role_failure', 'warning')
        refreshView()
      }
    )
  }

  render() {
    let { roles, roleChoices, menu } = this.state
    let { classes, translate } = this.props
    let { open, anchorEl } = menu
    return <div className={classes.root}>
      {roles.map((role, idx) => {
        return <Chip
          key={idx}
          className={classes.chip}
          label={role.name}
          onDelete={() => this.unAssignRole(role.id)}
        />
      })}
      <Chip
        icon={<FontAwesomeIcon icon={faPlus} />}
        className={classes.chip}
        label={translate('resources.users.add_role')}
        onClick={(event) => this.handleOpenMenu(event)}
      />
      <RoleMenu
        open={open}
        anchorEl={anchorEl}
        roles={roleChoices}
        assignRole={this.assignRole}
        onClose={this.handleCloseMenu}  
      />
    </div>
  }
}

const enhance = compose(
  translate,
  addField,
  withStyles(style),
  connect(null, { showNotification, refreshView })
)

export default enhance(RoleChips)