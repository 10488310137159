import React, { Component } from 'react'
import { translate } from 'react-admin'
import {
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  Input,
  withStyles,
  Checkbox,
  ListItemText,
  CircularProgress,
  Button
} from  '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import compose from 'recompose/compose'
import DeleteIcon from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import {Provider} from '../provider'
import numeral from 'numeral'
import CheckboxMenu from '../common/CheckboxMenu'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const styles = {
  table: {},
  formControl: {
    margin: 5,
    minWidth: 120,
    maxWidth: 300,
  },
  selectContainer: {
    margin: 10
  },
  selectLabel: {
    marginRight: 10
  },
  select: {
    margin: 10,
    minWidth: 120,
    maxWidth: 400,
  },
  label: {
    paddingLeft: '0.5em'
  }
}

class CreateMaintainForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // selectedItems: MaintainSchedule.defaultItems,
      maintainItems: [],
      selectedItems: [],
      actions: [], 
      schedules: [],
      openChecklist: false,
      selectedVehicle: null,
      selectedVehicles: [],
      vehicles: [],
      maintainTemplates: [],
      selectedTemplate: null,
    }    
  }
  componentDidMount() {
    this.fetchRelatedData()
  }
  fetchRelatedData = async () => {
    let maintainTemplates, maintainItems, addedVehicle, maintainActions, maintainSchedules, vehicles
    let responseMaintainTemplate = await Provider.dataProvider(
      'GET_LIST',
      'maintaintemplates', {
        filter: {
          fields: ['id', 'name']
        },
        sort: {},
        pagination: {},
      })

    if (responseMaintainTemplate && responseMaintainTemplate.data) {
      maintainTemplates = responseMaintainTemplate.data
    }

    let responseMaintainItem = await Provider.dataProvider(
      'GET_LIST',
      'maintainitems',
      {
        filter: {
          fields: ['id', 'name']
        },
        sort: {},
        pagination: {},
      })

    if (responseMaintainItem && responseMaintainItem.data) {
      maintainItems = responseMaintainItem.data
    }

    let responseAddedVehicle = await Provider.dataProvider(
      'GET_LIST',
      'vehiclemaintains',
      {
        filter: {
          fields: ['id', 'vehicleId']
        },
        sort: {},
        pagination: {},
      })

    if (responseAddedVehicle && responseAddedVehicle.data) {
      addedVehicle = responseAddedVehicle.data.map(item => item.vehicleId)
    }

    let responseMaintainAction = await Provider.dataProvider(
      'GET_LIST',
      'maintainactions', {
        sort: {},
        pagination: {},
      })

    if (responseMaintainAction && responseMaintainAction.data) {
      maintainActions = responseMaintainAction.data
    }

    let responseMaintainSchedule = await Provider.dataProvider(
      'GET_LIST',
      'maintainschedules',
      {
        sort: { field: 'month', order: 'asc' },
        pagination: {},
      })

    if (responseMaintainSchedule && responseMaintainSchedule.data) {
      maintainSchedules = responseMaintainSchedule.data
    }

    let vehicleReponse = await Provider.dataProvider('GET_LIST', 'vehicles', {
      filter: {
        where: { id: { nin: addedVehicle } },
        fields: ['id', 'plate']
      },
      sort: { },
      pagination: {},
    })

    if (vehicleReponse && vehicleReponse.data) {
      vehicles = vehicleReponse.data
    }

    let selectedItems = []
    let checklistData = []
    for (let i = 0; i < maintainItems.length; i++ ) {
      let obj = maintainItems[i]
      for (let j = 0; j < maintainActions.length; j++ ) {
        const act = maintainActions[j]
        obj[act.actionCode] = 0
      }
      selectedItems.push(obj)      
    }
    this.setState({
      maintainTemplates: maintainTemplates || [],
      maintainItems: maintainItems || [], 
      actions: maintainActions || [], 
      schedules: maintainSchedules || [],
      selectedItems,
      checklistData,
      vehicles,
      selectedVehicles: [],
      
    })
  }
  onTemplateChanged = () => {
    let selectedItems = []
    let checklistData = []
    const { maintainItems, selectedTemplate} = this.state
    if (selectedTemplate === null) {
      return
    }
    let cloneItems = [...maintainItems]
    const {maintainInfo} = selectedTemplate
    for (let i = 0; i < maintainInfo.length; i++ ) {
      const idxItem = cloneItems.findIndex(o => o.id === maintainInfo[i].id)
      if (idxItem !== -1) {
        cloneItems.splice(idxItem,1)
      }
      let obj = {...maintainInfo[i]}
      selectedItems.push(obj)      
    }
    for (let i = 0; i <  cloneItems.length; i++) {
      let obj = cloneItems[i]
      obj.isChecked = false
      obj.value = `${obj.id}`
      checklistData.push(obj)
    }
    this.setState({
      selectedItems,
      checklistData
    })
  }

  doSave = async () => {
    let data = []
    const { selectedVehicles } = this.state
    let { history } = this.props
    for(let i = 0; i < selectedVehicles.length; i++) {
      data.push({
        vehicleId: selectedVehicles[i],
        maintainInfo: this.buildMaintainInfo(this.state.selectedItems)
      })
    }
    await Provider.dataProvider('CREATE', 'vehiclemaintains', { data })
    history.push('/vehiclemaintains')
  }

  buildMaintainInfo = items => {
    let notEmptyItem = []
    for(let i = 0; i < items.length; i++) {
      let obj = items[i]
      for (let j = 0; j < this.state.actions.length; j++ ) {
        const act = this.state.actions[j]
        if (obj[act.actionCode] && obj[act.actionCode] !== 0) {
          notEmptyItem.push(obj)
          break
        }
      }
    }
    return notEmptyItem
  }
  handleChecklistClose = selectedItems => {
    let copyData = [...this.state.selectedItems]
    let checklistData = [...this.state.checklistData]
    const {actions} =  this.state
    if (Array.isArray(selectedItems) && selectedItems.length > 0) {
      for(let i = 0; i < selectedItems.length; i++) {
        let obj = selectedItems[i]
        const idx = checklistData.findIndex(item => item.id === obj.value)
        if (idx !== -1) {
          checklistData.splice(idx, 1)
        }        
        for (let j = 0; j < actions.length; j++ ) {
          const act = actions[j]
          obj[act.actionCode] = 0
        }
        delete obj.isChecked
        delete obj.value
        copyData.push(obj)
      }
      this.setState({selectedItems: copyData, checklistData})
    }
  }
  handleSelectItem = (rowIdx, actionType, value) => {        
    const copyData = [...this.state.selectedItems]
    const currItem = copyData[rowIdx]
    currItem[actionType] = value
    copyData[rowIdx]= currItem
    this.setState({selectedItems: copyData})
  }
  handleChecklistChange =  value => {
    this.setState({ selected: value })
  }
  renderActionItem = (action, sequenceList, rowIdx) => {   
    const that = this
    const {selectedItems} = this.state    
    const currValue = selectedItems[rowIdx][action.actionCode]
    
    return (
      <Select
        value={currValue}
        onChange={ event => {
          that.handleSelectItem(rowIdx, action.actionCode, event.target.value)
        }}
        input={<Input name={`${action.name}`} id="age-label-placeholder" />}
        displayEmpty
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {
          sequenceList.map((item,idx) => {
            return <MenuItem key={idx} value={item.month}>
              {`${item.month} T/${numeral(item.km*1000).format('0,0')} Km`}
            </MenuItem>
          })
        }
      </Select>
    )
  }
  handleVehicleChanged = event => {
    this.setState({ selectedVehicles: event.target.value })
  }
  handleTemplateChanged = event => {    
    const idx = this.state.maintainTemplates.findIndex(obj => obj.id === event.target.value)
    if (idx !== -1) {
      this.setState({ selectedTemplate: this.state.maintainTemplates[idx]}, ()=> {
        this.onTemplateChanged()
      })

    } else {
      this.setState({ selectedTemplate: null })
    }
    
  }
  renderVehicleSelectedValue = selected => {
    let { vehicles } = this.state
    let plate = []
    for(let i=0; i < selected.length; i++) {
      const idx = vehicles.findIndex(v => v.id === selected[i])
      if (idx !== -1) {
        plate.push(vehicles[idx].plate)
      }
    }
    return plate.join(', ')
  }
  renderMaintainTemplate = () => {
    const { maintainTemplates, selectedTemplate } = this.state
    let { classes, translate } = this.props  
    return (
      <div className={classes.selectContainer}>
        <span className={classes.selectLabel}>{translate('resources.vehiclemaintains.fields.chooseTemplate')}</span>
        <Select
          value={selectedTemplate?selectedTemplate.name: ''}
          onChange={this.handleTemplateChanged}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={selected => selected}
          MenuProps={MenuProps}
          className={classes.select}
        >
          {maintainTemplates.map(template => (
            <MenuItem key={`${template.id}`} value={template.id}>
              <ListItemText primary={template.name} />
            </MenuItem>
          ))}
        </Select>
      </div>      
    )
  }

  renderSelectVehicle = () => {
    const { vehicles } = this.state
    let { classes, translate } = this.props
    return (
      <div className={classes.selectContainer}>
        <span className={classes.selectLabel}>{translate('resources.vehiclemaintains.fields.chooseVehicle')}</span>
        <Select
          multiple
          value={this.state.selectedVehicles}
          onChange={this.handleVehicleChanged}
          input={<Input id="select-multiple-checkbox" />}
          renderValue={this.renderVehicleSelectedValue}
          MenuProps={MenuProps}
          className={classes.select}
        >
          {vehicles.map(vehicle => (
            <MenuItem key={`${vehicle.id}`} value={vehicle.id}>
              <Checkbox checked={this.state.selectedVehicles.findIndex(it => it === vehicle.id) !== -1} />
              <ListItemText primary={vehicle.plate} />
            </MenuItem>
          ))}
        </Select>
      </div>      
    )
  }

  toogleChecklist = () => {
    this.setState({openChecklist: !this.state.openChecklist})
  }

  deleteItem = (item, idx) => {
    let copyData = [...this.state.selectedItems]
    let checklistData = [...this.state.checklistData]
    const removedItem = copyData.splice(idx, 1)
    const {actions} =  this.state
    for (let j = 0; j < actions.length; j++ ) {
      const act = actions[j]
      removedItem[0][act.actionCode] = 0
    }
    removedItem[0].isChecked = false
    removedItem[0].value = `${removedItem[0].id}`
    checklistData.push(removedItem[0])
    this.setState({selectedItems: copyData, checklistData})
  }
  render() {
    const { classes, translate } = this.props
    const { selectedItems, actions, schedules, checklistData = [] } = this.state
    if (selectedItems.length === 0) return <div className={classes.progress}><CircularProgress /> </div>
    return (
      <Paper>        
        {this.renderSelectVehicle()}
        {this.renderMaintainTemplate()}
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{translate('resources.vehiclemaintains.fields.name')}</TableCell>
              {
                actions.map((item, idx) => {
                  return <TableCell align="right" key={idx}>
                    {item.name}
                  </TableCell>
                })
              }
              <TableCell align="right">{translate('resources.vehiclemaintains.fields.action')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((row, rowIdx) => (
              <TableRow key={rowIdx}>
                <TableCell component="th" scope="row">
                  {row.name}
                </TableCell>
                {
                  actions.map((item, idx) => {
                    return (<TableCell align="right" key={idx}>
                      {this.renderActionItem(item, schedules, rowIdx)}
                    </TableCell>)
                  })
                }
                <TableCell align="right">
                  <IconButton
                    className={classes.button}
                    aria-label="Delete"
                    color="primary"
                    onClick={evt => {
                      this.deleteItem(row, rowIdx)
                    }}>
                    <DeleteIcon color="error"/>
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div style={{ display: 'flex', margin: 16 }}>
          {checklistData.length > 0 ? (
            <CheckboxMenu
              icon={<AddIcon color="primary"/>}
              options={checklistData}
              closeEvent={this.handleChecklistClose}
            />) : null
          }
          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.doSave}>
            <span className={classes.label}>
              {translate('button.save')}
            </span>
          </Button>
        </div>
      </Paper>
    )
  }
}
const enhance = compose(withStyles(styles), translate)
export default enhance(CreateMaintainForm)
