import React,  { Component} from 'react'

import {List,
  TextField,

  Datagrid,
  translate,
} from 'react-admin'
import compose from 'recompose/compose'
import { withStyles } from '@material-ui/core'

class _MaintainTemplateList extends Component {
  state = {
    nextEvent: {},
    version: 0,
  }
  render() {
    const {classes, translate, ...props} = this.props
    return(
      <List 
        {...props}
      >
        <Datagrid rowClick="edit" >          
          <TextField source="name" />
        </Datagrid>
      </List>)
  }
}
const styles = {

}
const enhance = compose( withStyles(styles), translate)
export default enhance(_MaintainTemplateList) 