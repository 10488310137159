import React, { Component, Fragment } from 'react'
import { Provider } from '../provider'
import moment from 'moment'
import { ViewTripByTimeline } from './ViewTripByTimeline'
import { DatePickerInput } from '../common/DatePicker'
import { viewByModes } from '../common/constants'
import {
  Button,
  Card,
  CardContent,
  CardActions,
  CircularProgress,
  withStyles,
  Drawer,
  ListItemText,
  Divider,
  Typography,
  ListItem,
  Avatar,
} from '@material-ui/core'
import reduce from 'lodash/reduce'
import {
  translate,
  Edit,
  Create,
  SimpleForm,
  SaveButton,
  CloneButton,
  Toolbar,
  ReferenceInput,
  Title,
  showNotification,
} from 'react-admin'
import Snackbar from '../common/Snackbar'
import { compose } from 'recompose'
import { DragSource, DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { getColor } from '../utils/color'
import { multiSelectTo as multiSelect } from '../utils/mutiselect'
import difference from 'lodash/difference'
import findIndex from 'lodash/findIndex'
import { EditTitle } from '../common/Title'
import FormSave from './FormSave'
import LinkField from './../common/LinkField'
import { SeatIcon, TripIcon, DriverIcon } from './../icons'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { updateTripsDriver, viewByMapping } from './tripFunctions'
import _ from 'lodash'
import Confirm from '../common/Confirm'
import { isCompanyManager } from '../utils/permission'
import FormBody from '../common/FormBody'
import { parseUrl } from 'query-string' 
import { change as changeForm } from 'redux-form'
import { connect } from 'react-redux'

const ItemTypes = {
  DRIVER: 'driver',
}

const styleDriverDrawer = {
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    marginTop: '3em',
    width: 240,
  },
  driverInfo: {
    display: 'flex',
  },
  driverAvatar: {
    margin: 0,
  },
  driverName: {
    margin: 'auto',
  },
}

const chipSource = {
  beginDrag(props) {
    let { id, selectedDriverIds, index, color } = props
    if (selectedDriverIds.indexOf(id) !== -1) {
      return { selectedDriverIds, index }
    }
    props.unselectAll()
    return { id, index, color }
  },
  isDragging(props, monitor) {
    return props.id === monitor.getItem().id
  },
  endDrag(props, monitor) {
    const didDrop = monitor.didDrop()
    if (didDrop) {
      props.onMove()
    }
    props.unHover()
    props.unselectAll()
  }
}


class _DriverInfo extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isSelected: false,
      assignedVehicles: []
    }
  }

  componentDidMount() {
    const img = new Image()
    img.onload = () =>
      this.props.connectDragPreview && this.props.connectDragPreview(img)
    img.src = '/images/driver.png'
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { isSelected, assignedVehicles } = nextProps
    let currentIsSelected = prevState.isSelected
    if (isSelected !== currentIsSelected) {
      currentIsSelected = isSelected
    }
    let currentAssignedVehicles = prevState.assignedVehicles
    if (currentAssignedVehicles !== assignedVehicles) {
      currentAssignedVehicles = assignedVehicles
    }
    return {
      isSelected: currentIsSelected,
      assignedVehicles: currentAssignedVehicles,
    }
  }

  render() {
    const {
      connectDragSource,
      isDragging,
      driver,
      classes,
      color,
      onClick,
      onKeyDown,
    } = this.props
    let { isSelected, assignedVehicles } = this.state
    let opacity = isDragging ? 0 : 1
    const backgroundColor = color
    return connectDragSource(
      <div
        className={classes.driverInfo}
        style={{ opacity }}
      >
        <ListItem
          divider
          selected={isSelected}
          onClick={(e) => onClick(e, driver.id)}
          onKeyDown={(e) => onKeyDown(e, {}, { isDragging })}
        >
          <Avatar style={{ backgroundColor }}><DriverIcon /></Avatar>
          <ListItemText
            className={classes.driverName}
            primary={driver.fullName}
            secondary={assignedVehicles ? assignedVehicles.join(' ').trim() : ''}
          />
        </ListItem>
      </div>
    )
  }
}

export const DriverInfoWithDragDrop = DragSource(
  ItemTypes.DRIVER,
  chipSource,
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
    connectDragPreview: connect.dragPreview(),
  })
)(_DriverInfo)

const DriversDrawer = withStyles(styleDriverDrawer)(({
  classes,
  open,
  drivers = {},
  onMove,
  onClose,
  onClick,
  onKeyDown,
  selectedDriverIds = [],
  unselectAll,
  unHover,
  driverVehicles,
  vehicles,
  translate,
}) => (
  <Drawer
    className={classes.drawer}
    variant="persistent"
    anchor="right"
    open={open}
    onClose={() => onClose()}
    classes={{ paper: classes.drawerPaper }}
  >
    <CardContent>
      <Typography component="h2" variant="h6">
        {translate('resources.drivers.name', { smart_count: 1 })}
      </Typography>
      <Typography component="h2" variant="subtitle1">
        {translate('resources.trips.drawer_driver_desc')}
      </Typography>
    </CardContent>
    <Divider />
    {Object.values(drivers).map((driver, index) => {
      let { id } = driver
      let isSelected = selectedDriverIds.indexOf(id) !== -1
      let color = getColor(id)
      let assignedVehicles = driverVehicles[id] ? driverVehicles[id] : []
      let assignedVehiclePlates = assignedVehicles.map((assignedVehicle) => {
        let assignedVehicleId = assignedVehicle.id
        let numberOfTrips = assignedVehicle.count
        if (numberOfTrips === 0) return ''
        return vehicles[assignedVehicleId] && vehicles[assignedVehicleId].plate
      })
      return (
        <DriverInfoWithDragDrop
          id={id}
          index={index}
          classes={classes}
          key={index}
          driver={driver}
          onMove={onMove}
          onClick={onClick}
          onKeyDown={onKeyDown}
          isSelected={isSelected}
          selectedDriverIds={selectedDriverIds}
          unselectAll={unselectAll}
          color={color}
          assignedVehicles={[...assignedVehiclePlates]}
          unHover={() => unHover()}
        />
      )
    })}
  </Drawer>
))

class _TripList extends Component {

  constructor(props) {
    super(props)
    this.state = {
      startDate: moment().startOf('day'),
      endDate: moment().add(7, 'days').endOf('day'),
      version: 0,
      isOpenDriverDrawer: false,
      selectedDriverIds: [],
      driverVehicles: {},
      allVehicles: {},
      viewBy: viewByModes.STATUS,
      renderSmartAssignButton: false,
      renderUnAssignDriverButton: false,
    }
    this.vehicleTimelineRef = React.createRef()
  }

  async componentDidMount() {
    let { location, changeForm, startDate, endDate, scheduleId } = this.props
    let url = parseUrl(location.search || '')
    if (!_.isEmpty(url.query)) {
      let { startDate: queryStartDate, endDate: queryEndDate, scheduleId: queryScheduleId } = url.query
      changeForm('TRIPS_FILTER_FORM', 'startDate', queryStartDate)
      changeForm('TRIPS_FILTER_FORM', 'endDate', queryEndDate)
      changeForm('TRIPS_FILTER_FORM', 'scheduleId', parseInt(queryScheduleId))
    }
    await this.loadData({ startDate, endDate, scheduleId })
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { startDate: prevStartDate, endDate: prevEndDate, scheduleId: prevScheduleId } = prevProps
    let { startDate, endDate, scheduleId } = this.props
    prevStartDate = moment(prevStartDate).toISOString()
    prevEndDate = moment(prevEndDate).toISOString()
    const updated = !(prevStartDate === startDate && prevEndDate === endDate && prevScheduleId === scheduleId)
    return { updated }
  }

  async componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { startDate, endDate, scheduleId } = this.props
      await this.loadData({ startDate, endDate, scheduleId })
    }
  }

  onKeyDown = (event, provided, snapshot) => {
    if (provided.dragHandleProps) {
      provided.dragHandleProps.onKeyDown(event)
    }

    if (event.defaultPrevented) {
      return
    }

    if (snapshot.isDragging) {
      return
    }

    if (event.keyCode !== 'enter') {
      return
    }

    // we are using the event for selection
    event.preventDefault()

    this.performAction(event)
  }

  onClick = (event, driverId) => {
    if (event.defaultPrevented) {
      return
    }

    if (event.button !== 0) {
      return
    }

    // marking the event as used
    event.preventDefault()

    this.performAction(event, driverId)
  }

  performAction = (event, driverId) => {
    if (this.wasToggleInSelectionGroupKeyUsed(event)) {
      this.toggleSelectionInGroup(driverId)
      return
    }

    if (this.wasMultiSelectKeyUsed(event)) {
      this.multiSelectTo(driverId)
      return
    }

    this.toggleSelection(driverId)
  }

  // Determines if the platform specific toggle selection in group key was used
  wasToggleInSelectionGroupKeyUsed = (event) => {
    const isUsingWindows = navigator.platform.indexOf('Win') >= 0
    return isUsingWindows ? event.ctrlKey : event.metaKey
  }

  // Determines if the multiSelect key was used
  wasMultiSelectKeyUsed = (event) => event.shiftKey

  toggleSelection = (driverId) => {
    const selectedDriverIds = this.state.selectedDriverIds || []
    const wasSelected = selectedDriverIds.includes(driverId)
    let newDriverIds = []
    if (!wasSelected) {
      newDriverIds = [driverId]
    }
    if (selectedDriverIds.length > 1) {
      newDriverIds = [driverId]
    }
    this.setState({
      selectedDriverIds: newDriverIds || [],
    })
  }

  toggleSelectionInGroup = (driverId) => {
    const selectedDriverIds = this.state.selectedDriverIds
    const index = selectedDriverIds.indexOf(driverId)

    // if not selected - add it to the selected items
    if (index === -1) {
      this.setState({
        selectedDriverIds: [...selectedDriverIds, driverId] || [],
      })
      return
    }

    // it was previously selected and now needs to be removed from the group
    const shallow = [...selectedDriverIds]
    shallow.splice(index, 1)
    this.setState({
      selectedTaskIds: shallow,
    })
  }

  // This behaviour matches the MacOSX finder selection
  multiSelectTo = (newDriverId) => {
    let { drivers, selectedDriverIds } = this.state
    let arrDrivers = drivers ? Object.values(drivers) : []
    const updated = multiSelect(arrDrivers, selectedDriverIds, newDriverId)

    if (updated == null) {
      return
    }

    this.setState({
      selectedDriverIds: updated || [],
    })
  }

  openDriverDrawer = () => {
    let { isOpenDriverDrawer } = this.state
    this.setState({
      isOpenDriverDrawer: !isOpenDriverDrawer
    })
  }

  unselectAll = () => {
    this.setState({
      selectedDriverIds: []
    })
  }

  closeDriverDrawer = () => {
    this.setState({
      isOpenDriverDrawer: false
    })
  }

  onMove = () => {
    let { vehicleTimelineRef } = this
    let current = vehicleTimelineRef.current || {}
    let driverVehicles = current.getDriverVehicles()
    this.setState({
      driverVehicles: { ...driverVehicles }
    })
  }

  updateDriverVehicles = (oldDriverVehicles, newDriverVehicles) => {
    for (let id in newDriverVehicles) {
      let newDriverVehicle = newDriverVehicles[id]
      let oldDriverVehicle = oldDriverVehicles[id]
      if (newDriverVehicle) {
        if (!oldDriverVehicle) {
          oldDriverVehicles[id] = newDriverVehicle
        } else {
          let newIds = difference(newDriverVehicle, oldDriverVehicle)
          if (newIds && newIds.length > 0) {
            oldDriverVehicles[id] = [oldDriverVehicle, newIds]
          }
        }
      }
    }
    return oldDriverVehicles
  }

  initialDriverVehicles = (trips) => {
    let rs = reduce(trips, (result = {}, trip) => {
      let { driverId, vehicleId } = trip
      if (driverId && vehicleId) {
        let vehicles = this.setDriverVehicle(result, driverId, vehicleId)
        result[driverId] = vehicles
      }
      return { ...result }
    }, {})
    return rs
  }

  setDriverVehicle = (driverVehicle, driverId, vehicleId) => {
    let vehicles = driverVehicle[driverId]
    if (vehicles) {
      let idx = findIndex(vehicles, { 'id': vehicleId })
      if (idx === -1) {
        vehicles.push({ id: vehicleId, count: 1 })
      } else {
        let vehicle = vehicles[idx]
        vehicles[idx] = { ...vehicle, count: ++vehicle.count }
      }
    } else {
      vehicles = [{ id: vehicleId, count: 1 }]
    }
    return vehicles
  }

  loadData = async (values) => {
    let { startDate, endDate, scheduleId } = values
    startDate = startDate ? moment(startDate).startOf('day') : moment().startOf('day')
    endDate = endDate ? moment(endDate).endOf('day') : moment().add(7, 'days').endOf('day')
    let singleSchedule = false
    let where = {
      status: { neq: '40ARC' },
      departureTime: {
        between: [startDate, endDate],
      }
    }
    if (scheduleId) {
      where.scheduleId = scheduleId
      singleSchedule = true
    }
    const loadTrips = async () => {
      let trips = await Provider.dataProvider('GET_LIST', 'trips', {
        filter: {
          where,
          fields: [
            'id',
            'vehicleId',
            'departureTime',
            'arrivalTime',
            'driverId',
            'driver2Id',
            'departure',
            'arrival',
            'patternId',
            'routeId',
            'status',
            'scheduleId',
            'assistantDriverId',
            'data',
          ],
          path: 'list',
        },
        sort: [
          { field: 'vehicleId', order: 'asc' },
          { field: 'departureTime', order: 'asc' }
        ],
        pagination: { page: 1, perPage: 5000 },
      })
      trips = trips && trips.data
      
      let vehicleIds = _.reduce(trips, (prev, trip) => {
        if (trip.vehicleId) {
          prev.push(trip.vehicleId)
        }
        return prev
      }, [])

      return [trips, vehicleIds]
    }

    const loadDrivers = async () => {
      let drivers = await Provider.dataProvider('GET_LIST', 'drivers', {
        filter: {
          where: {},
          path: 'list',
          fields: ['id', 'fullName'],
        },
        sort: {},
        pagination: { page: 1, perPage: 100 },
      })
      if (drivers) {
        drivers = drivers.data
        drivers = _.reduce(drivers, (prev, driver) => {
          prev[driver.id] = driver
          return prev
        }, {})
      }
      return drivers || {}
    }

    const loadAssistantDrivers = async () => {
      let assistantDrivers = await Provider.dataProvider('GET_LIST', 'assistantdrivers', {
        filter: {
          where: {},
          path: 'list',
          fields: ['id', 'fullName'],
        },
        sort: {},
        pagination: { page: 1, perPage: 100 },
      })
      if (assistantDrivers) {
        assistantDrivers = assistantDrivers.data
        assistantDrivers = _.reduce(assistantDrivers, (prev, assistantDriver) => {
          prev[assistantDriver.id] = assistantDriver
          return prev
        }, {})
      }
      return assistantDrivers || {}
    }

    const loadVehicles = async () => {
      let vs = await Provider.dataProvider('GET_LIST', 'vehicles', {
        filter: {
          where: {},
          path: 'list',
          fields: ['id', 'plate'],
        },
        sort: {},
        pagination: { page: 1, perPage: 100 },
      })
      if (vs) {
        vs = vs.data
        vs = _.reduce(vs, (prev, vehicle) => {
          prev[vehicle.id] = vehicle
          return prev
        }, {})
      }
      return vs || {}
    }

    let [[trips, vehicleIds], drivers, allVehicles, assistantDrivers] = await Promise.all([loadTrips(), loadDrivers(), loadVehicles(), loadAssistantDrivers()])
    let driverVehicles = this.initialDriverVehicles(trips)
    let vehicles = _.reduce(vehicleIds, (result, vehicleId) => {
      result[vehicleId] = allVehicles[vehicleId]
      return result
    }, {})
    let renderUnAssignDriverButton = this.unassignDriverEnabled(trips, singleSchedule)
    let renderSmartAssignButton = this.historicalAssignEnabled(trips, singleSchedule)
    this.setState({
      version: this.state.version + 1,
      trips: trips || [],
      vehicles,
      drivers,
      startDate,
      endDate,
      driverVehicles,
      allVehicles,
      singleSchedule,
      scheduleId,
      assistantDrivers,
      renderUnAssignDriverButton,
      renderSmartAssignButton,
    })
  }

  // it can reset driver for trips only if trips are in the future
  unassignDriverEnabled(trips, singleSchedule) {
    if (!trips) {
      trips = this.state.trips
    }
    if (!singleSchedule) {
      singleSchedule = this.state.singleSchedule
    }
    // only if a signle schedule
    if (!singleSchedule) return false

    // first trip in the furture
    let firstTrip = _.first(trips)

    if (!firstTrip || !firstTrip.departureTime) return false
    let isBefore = moment(firstTrip.departureTime).isBefore(moment())
    if (isBefore) return false

    // at least a trip with driver
    let idx = _.find(trips, function (v) { return v.driverId })

    if (!idx || idx < 0) return false
    return true
  }

  // unassign drivers for the current trips
  async unassignDrivers() {
    let { trips, viewBy } = this.state
    if (!this.unassignDriverEnabled()) return

    let len = trips.length
    let toupdate = []
    for (let idx = 0; idx < len; idx++) {
      let trip = trips[idx]
      trip.driverId = null
      trip.dirty = true
      toupdate.push(trip)
    }

    if (toupdate.length > 0) {
      let { vehicleTimelineRef } = this
      let current = vehicleTimelineRef.current || {}
      current.reloadTrips(toupdate, viewBy)
      //await updateTripsDriver(toupdate)
    }
  }

  // assign drivers to vehchile using the historical data only if a single schedule selected
  // assign drivers smart
  historicalAssignEnabled(trips, singleSchedule) {

    if (!trips) {
      trips = this.state.trips
    }
    if (!singleSchedule) {
      singleSchedule = this.state.singleSchedule
    }
    if (!singleSchedule) return false

    // at least there's a driver assigned for a trip
    let driverIdTest = _.get(_.first(trips), 'driverId')
    if (!driverIdTest) return false

    // at least a trip without driver
    let idx = _.find(trips, function (v) { return !v.driverId })

    if (!idx || idx < 0) return false
    return true
  }

  // assign drivers to vehicles using the historical data
  // in many cases a driver is dedicated to a vehicle
  // assign drivers smart
  async assignUsingHistoricalData() {
    let { trips, viewBy } = this.state
    let { showNotification } = this.props
    let len = trips.length
    let vehicleDriver = {}
    let toupdate = []
    for (let idx = 0; idx < len; idx++) {
      let trip = trips[idx]
      let { vehicleId, driverId } = trip
      if (!vehicleId) continue
      if (driverId) {
        vehicleDriver[vehicleId] = driverId
        continue
      }

      trip.driverId = vehicleDriver[vehicleId]
      trip.dirty = true
      toupdate.push(trip)
    }

    if (toupdate.length > 0) {
      let result = await updateTripsDriver(toupdate, showNotification)
      if (result) {
        let { vehicleTimelineRef } = this
        let current = vehicleTimelineRef.current || {}
        current.reloadTrips(trips, viewBy)
        showNotification('notification.assign_driver_success')
      }
    }
  }

  render() {
    let {
      startDate,
      endDate,
      trips,
      vehicles,
      drivers,
      version,
      isOpenDriverDrawer,
      selectedDriverIds,
      driverVehicles,
      allVehicles,
      assistantDrivers,
      viewBy,
      renderUnAssignDriverButton,
      renderSmartAssignButton,
    } = this.state
    let { translate, classes, permissions } = this.props
    let companyManager = isCompanyManager(permissions)
    let { vehicleTimelineRef } = this
    let current = vehicleTimelineRef.current || {}
    let unHover = () => {
      current.unHoverDriversInItem()
      current.unHoverDriversInVehicleCol()
    }
    return <Fragment>
      <Title title={translate('resources.trips.name', { smart_count: 2 })} />
      <Card>
        <CardContent>
          <SimpleForm toolbar={null} form="TRIPS_FILTER_FORM" >
            <FormBody>
              <div className={classes.filter}>
                <div className={classes.filterInput}>
                  <DatePickerInput
                    resource="trips"
                    initValue={startDate}
                    source="startDate"
                    keyboard
                    dateFormat="DD/MM/YYYY"
                    showLunarDate
                  />
                </div>
                <div className={classes.filterInput}>
                  <DatePickerInput
                    initValue={endDate}
                    minDate={startDate}                    
                    resource="trips"
                    source="endDate"
                    keyboard
                    dateFormat="DD/MM/YYYY"                  
                  />
                </div>

                <div className={classes.filterInput}>
                  <ReferenceInput
                    resource="trips"
                    source="scheduleId"
                    reference="schedules/list"
                    filter={{ status: { neq: '20ARCHIVED' }, '../fields': ['id', 'name'] }}
                    perPage={1000}
                  >
                    <FuzzySelectInput
                      renderItem={item => item.name}
                      {...defaultFuzzySearch()}
                    />
                  </ReferenceInput>
                </div>
              </div>
            </FormBody>
          </SimpleForm>
        </CardContent>
        {!trips && <div className={classes.progress}><CircularProgress /> </div>}
        {trips && <CardContent className={classes.calenderContainer}>
          {trips.length === 0 ? <Snackbar
            variant="warning"
            message={translate('resources.trips.messages.no_trips', {
              start: startDate.format('DD/MM/Y'),
              end: endDate.format('DD/MM/Y'),
            })}
            className={classes.fullWidth}
          /> : (<Fragment>
            <div className={classes.switchButtonContainer}>
              {Object
                .keys(viewByMapping)
                .map(viewBy =>
                {
                  let item = viewByMapping[viewBy]
                  return <Button
                    key={viewBy}
                    onClick={() => this.setState({ viewBy })}
                    className={viewBy === this.state.viewBy ? classes.switchButtonActive : ''}
                  >
                    {translate(item.label)}
                  </Button>
                })
              }
            </div>
            <ViewTripByTimeline
              key={version}
              trips={trips}
              vehicles={vehicles}
              assistantDrivers={assistantDrivers}
              drivers={drivers}
              timeStart={startDate}
              timeEnd={endDate}
              driverVehicles={{ ...driverVehicles }}
              forwardRef={this.vehicleTimelineRef}
              updateDrawer={() => this.onMove()}
              allVehicles={allVehicles}
              companyManager={companyManager}
              viewBy={viewBy}
              historicalAssignEnabled={() => {
                let renderSmartAssignButton = this.historicalAssignEnabled()
                this.setState({ renderSmartAssignButton })
              }}
              unassignDriverEnabled={() => {
                let renderUnAssignDriverButton  = this.unassignDriverEnabled()
                this.setState({ renderUnAssignDriverButton })
              }}
            />
          </Fragment>)
          }
        </CardContent>}
        <DriversDrawer
          drivers={drivers}
          open={isOpenDriverDrawer}
          onClose={this.closeDriverDrawer}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          selectedDriverIds={selectedDriverIds}
          onMove={this.onMove}
          driverVehicles={{ ...driverVehicles }}
          vehicles={vehicles}
          unselectAll={this.unselectAll}
          unHover={unHover}
          translate={translate}
        />
        <CardActions>
          {companyManager && <Button
            variant="contained"
            color="primary"
            onClick={this.openDriverDrawer}
          >
            {translate('button.assign_driver')}
          </Button>}

          {companyManager && (renderSmartAssignButton &&
              <Confirm
                title={translate('resources.common.confirm')}
                desc={translate('resources.trips.dialogs.assignDriverSmart')}
              >
                {confirm => (
                  <Button
                    style={{ marginRight: 4 }}
                    variant="contained"
                    color="primary"
                    onClick={confirm(() => this.assignUsingHistoricalData())}
                  >
                    {translate('resources.trips.buttons.assignDriverSmart')}
                  </Button>
                )}
              </Confirm>
          )}

          {companyManager && (renderUnAssignDriverButton &&
              <Confirm
                title={translate('resources.common.confirm')}
                desc={translate('resources.trips.dialogs.unassignDriver')}
              >
                {confirm => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={confirm(() => this.unassignDrivers())}
                  >
                    {translate('resources.trips.buttons.unassignDriver')}
                  </Button>
                )}
              </Confirm>
          )}

          <LinkField path={'/viewtrips'} >
            <TripIcon fontSize="small" />
            {translate('button.list')}
          </LinkField>
        </CardActions>
      </Card>
    </Fragment>
  }
}

const styles = {
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  filter: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  filterInput: {
    width: 192,
    padding: 6,
  },
  progress: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 50,
  },
  calenderContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  switchButtonContainer:{
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // alignSelf: 'flex-end'
  },
  switchButtonActive: {
    backgroundImage: 'none',
    boxShadow: 'inset 0 3px 5px rgba(0, 0, 0, 0.125)',
    backgroundColor: '#e6e6e6',
    borderColor: '#adadad',
    borderRadius: 0,
  },
}

const mapStateToProps = state => {
  let filterValues = state.form['TRIPS_FILTER_FORM'] && state.form['TRIPS_FILTER_FORM'].values ? state.form['TRIPS_FILTER_FORM'].values : {}
  let { startDate, endDate, scheduleId = 0 } = filterValues
  return { startDate, endDate, scheduleId: parseInt(scheduleId) }
}

const enhance = compose(
  translate,
  withStyles(styles),
  DragDropContext(HTML5Backend),
  connect(mapStateToProps, { changeForm, showNotification })
)

export const TripList = enhance(_TripList)

////////////////////////////////////////////////////////////////////////////////
// Create
export const TripCreate = props => (
  <Create
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm>
      <FormSave action='create' />
    </SimpleForm>
  </Create>
)

////////////////////////////////////////////////////////////////////////////////
// Edit

const TripEditToolbar = translate(({ translate, ...props }) => {
  return (
    <Toolbar {...props} >
      <SaveButton redirect="/viewtrips" />
      <CloneButton />
      <LinkField
        path={`/reservations/trip_layout/${props.record.id}`}>
        <SeatIcon fontSize="small" />
        {translate('button.reservation_extra')}
      </LinkField>
    </Toolbar>
  )
})

export const TripEdit = props => (
  <Edit
    {...props}
    undoable={false}
    title={<EditTitle resource={props.resource} />}
  >
    <SimpleForm
      toolbar={<TripEditToolbar />}
    >
      <FormSave action='update' />
    </SimpleForm>
  </Edit>
)
