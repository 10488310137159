import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
  Dialog,
  Grid,
  DialogTitle,
  Divider,
  Typography,
} from '@material-ui/core'
import { Provider } from '../provider'
import {
  translate,
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  refreshView,
  showNotification,
  TextInput,
} from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned'
import { MoneyInput } from '../common/MoneyInput'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'
import { formatCurrency } from '../utils/formatUtil'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  },
}

const AccountMoreInfo = translate(({ deposit, translate }) => {
  return deposit ? <i>
    {translate('resources.financeaccounts.helperTextAccountId')}&nbsp;
    <b>{formatCurrency(deposit)}</b>
  </i> : <i>
    {translate('resources.financeaccounts.not_topUp_yet')}
  </i>
})

const mappingType = {
  AGE:{
    title: 'resources.financeaccounts.withdrawAgencyTitle',
    fromRef: 'agencies',
    fromRefSource: 'agencyId',
    fromRefFilter: { 
      '../ignore': { agencyIsCompany: true },
      '../account': true,
    },
    fromAccountSource: 'agencyAccountId',
    fromRefType: 'AGE',
    toRef: 'companies',
    toRefSource: 'companyId',
    toAccountSource: 'companyAccountId',
    toRefType: 'COM',
    toRefExtraFilter: {},
    withdrawType: 'FOR_AGENCY',
  },
  COM: {
    title: 'resources.financeaccounts.withdrawCompanyTitle',
    fromRef: 'companies',
    fromRefSource: 'companyId',
    fromRefFilter: { 
      '../account': true,
    },
    fromAccountSource: 'companyAccountId',
    fromRefType: 'COM',
    toRef: 'agencies',
    toRefSource: 'agencyId',
    toAccountSource: 'agencyAccountId',
    toRefType: 'AGE',
    toRefExtraFilter: {},
    withdrawType: 'FOR_PLATFORM',
  }
}

class _WithdrawForm extends Component {

  state = {}

  getMoneyCanWithdraw = async (agencyAccountId, withdrawValue) => {
    let { moneyCanWithdraw } = this.state
    let { changeForm } = this.props
    let moneyCanWithdrawRes = await Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: 'getMoneyCanWithdraw',
      requestMethod: 'GET',
      data: { agencyAccountId }
    })
    if (moneyCanWithdrawRes && moneyCanWithdraw !== moneyCanWithdrawRes.data) {
      this.setState({
        moneyCanWithdraw: moneyCanWithdrawRes.data > 0 ? moneyCanWithdrawRes.data : 0,
        agencyAccountId,
      }, () => {
        if (withdrawValue) {
          changeForm('withdraw-form', 'withdrawValue', 0)
        }
      })
    }
  }

  onEnter = () => {
    let { deposit, platformDeposit } = this.props
    let moneyCanWithdraw = deposit || platformDeposit
    this.setState({ moneyCanWithdraw })
  }

  onExit = () => {
    this.setState({ moneyCanWithdraw: 0 })
  }

  render() {
    let {
      open,
      fromRefName,
      fromId,
      fromAccountDefault,
      onClose,
      withdraw,
      translate,
      type,
      deposit,
    } = this.props
    if (!type) return null
    let { 
      fromRefSource,
      fromAccountSource,
      fromRefType,
      title,
      withdrawType
    } = mappingType[type]
    let record = { withdrawType }
    let { moneyCanWithdraw } = this.state
    let extra = { resource: 'financeaccounts', fullWidth: true }
    return <Dialog
      open={open}
      onClose={() => onClose()}
      fullWidth
      maxWidth="md"
      onEnter={this.onEnter}
      onExit={this.onExit}
    >
      <DialogTitle>
        <Typography variant="h4">{translate(title)}&nbsp;<b>{fromRefName}</b></Typography>
      </DialogTitle>
      <Divider />
      <SimpleForm
        resource="financeaccounts"
        form="withdraw-form"
        record={record}
        validate={record => {
          let error = {}
          let { withdrawValue } = record
          let { moneyCanWithdraw } = this.state
          if (!withdrawValue || withdrawValue <= 0) {
            error.withdrawValue = translate('resources.financeaccounts.warning_withdraw_more_than_zero')
          }

          if (withdrawValue > moneyCanWithdraw) {
            error.withdrawValue = translate('resources.financeaccounts.warning_withdraw_exceed_to_platform_deposit', { money: formatCurrency(moneyCanWithdraw)})
          }
          return error
        }}
        save={record => withdraw(record)}
      >
        <FormDataConsumer>
          {({ formData }) => {
            return fromId && <Grid container fullWidth>
              <ReferenceInput
                record={{}}
                perPage={1000}
                reference="financeaccounts"
                filter={{ '../mappingCondition': { type: fromRefType, refId: formData[fromRefSource] }}}
                source={fromAccountSource}
                defaultValue={fromAccountDefault}
                {...extra}
              >
                <SelectInput optionValue="id" optionText="accountName" />
              </ReferenceInput>
              {formData[fromAccountSource] && <AccountMoreInfo deposit={deposit} />}
              <MoneyInput
                autoFocus
                source="withdrawValue"
                label="resources.financeaccounts.fields.withdrawValue"
                helperText={moneyCanWithdraw ? translate('resources.financeaccounts.amount_can_withdraw', { money: formatCurrency(moneyCanWithdraw)}) : translate('resources.financeaccounts.company_agency_not_deposit')}
              />
              <TextInput
                source="invoiceNumber"
                {...extra}
              />
              <TextInput {...extra} source="description" />
              <ReferenceInput
                source="withdrawMethod"
                label="resources.reservations.payment.method"
                reference="paymentmethod2s"
                filter={{ id: { lt: '20' }}}
                defaultValue="00CASH"
                {...extra}
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              {formData.withdrawMethod === '10BANK' && <Fragment>
                <TextInput
                  source="journalNumber"
                  {...extra}
                />
              </Fragment>}
            </Grid>
          }}
        </FormDataConsumer>
      </SimpleForm>
    </Dialog> 
  }
}

const enhanceWithdrawForm = compose(translate, connect(null, { changeForm }))
const WithdrawForm = enhanceWithdrawForm(_WithdrawForm)

class WithdrawButton extends Component {

  state = {
    open: false
  }

  open = () => {
    this.setState({
      open: true
    })
  }

  onClose = () => {
    let { reset } = this.props
    this.setState({
      open: false
    }, () => { reset('withdraw-form')})
  }

  withdraw = record => {
    let { refreshView, showNotification } = this.props
    Provider.dataProvider('REMOTE', 'financeaccounts', {
      method: 'withdraw',
      requestMethod: 'POST',
      data: { ...record, withdrawValue: parseFloat(record.withdrawValue) }
    }).then(
      () => {
        refreshView()
        this.onClose()
        showNotification('notification.withdraw_success')
      }
    ).catch(
      () => {
        this.onClose()
        showNotification('notification.withdraw_failure', 'warning')
      }
    )
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      isAdmin,
      toId,
      fromId,
      type,
      fromRefName,
      fromAccountDefault,
      deposit,
      platformDeposit,
    } = this.props
    let { open } = this.state
    return <Fragment>
      {button 
        ? <Button
          color={color}
          onClick={this.open}
        >
          <div className={classes.container}>
            <AssignmentReturnedIcon className={classes.icon} />
            <span>{translate('button.withdraw')}</span>
          </div>
        </Button>
        : <LinkField
          className={classes.iconButton}
          icon
          color={color}
          onClick={this.open}
        >
          <Tooltip title={translate('button.withdraw')} enterDelay={100} >
            <AssignmentReturnedIcon />
          </Tooltip>
        </LinkField>
      }
      <WithdrawForm
        open={open}
        onClose={this.onClose}
        withdraw={this.withdraw}
        toId={toId}
        fromId={fromId}
        fromRefName={fromRefName}
        fromAccountDefault={fromAccountDefault}
        type={type}
        isAdmin={isAdmin}
        deposit={deposit || platformDeposit}
      />
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { refreshView, showNotification, reset, changeForm })
)

export default enhance(WithdrawButton)
