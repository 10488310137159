import { put, race, take, takeEvery } from 'redux-saga/effects'
import { showNotification } from 'react-admin'
import { GET_ZONES, GET_ZONES_SUCCESS, GET_ZONES_FAILURE } from './actions'

// ------------------------------------------------------------------------------------------
function* getZones(action) {
  let { start, done } = action
  start()
  const { success, failure } = yield race({
    success: take(GET_ZONES_SUCCESS),
    failure: take(GET_ZONES_FAILURE),
  })
  if (success) {
    const { payload } = success
    if (payload.data) {
      done(payload.data)
    } else {
      yield put(showNotification('Get zone with no data'))
    }
  }
  if (failure) {
    yield put(showNotification('Cannot get zones'))
  }
}

export function* requestGetZones() {
  yield takeEvery(GET_ZONES, getZones)
}