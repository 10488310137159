import React, { Component, Fragment } from 'react'
import {
  Grid,
  withStyles,
  Typography,
  TextField as MTextField,
  InputAdornment,
  FormControlLabel,
  Switch,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import {
  ReferenceInput,
  SelectInput,
  SimpleShowLayout,
  addField,
  translate,
  FunctionField,
  NumberField,
  required,
  TextInput,
} from 'react-admin'
import compose from 'recompose/compose'
import { faReceipt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormBody from '../common/FormBody'
import MoneyInput from '../common/MoneyInput'
import SelectInputCustom from '../common/SelectInputCustom'

const styles = () => ({
  contentItem: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    fontStyle: 'italic'
  },
  label: {
    fontWeight: 'bold'
  },
})

class _PaymentInput extends Component {

  state = {
    val: 0
  }

  render() {
    let { input, meta, onReceivedMoneyClicked, onChangePaidAmount, translate } = this.props
    return <MTextField
      error={meta.error}
      helperText={meta.error}
      label={translate('resources.reservations.paidAmount')}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Tooltip title={translate('resources.reservations.enoughMoney')}>
              <IconButton onClick={() => onReceivedMoneyClicked()}>
                <FontAwesomeIcon icon={faReceipt} />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        ),
        inputComponent: MoneyInput,
      }}
      value={input.value}
      defaultValue={0}
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        let val = e.target.value || 0
        onChangePaidAmount(val)
        input.onChange(val)
      }}
      fullWidth
    />
  }
}

export const PaymentInput = addField(_PaymentInput)

const paymentMethodChoices = [
  {
    id: 'CASH',
    name: 'Tiền mặt',
  },
  {
    id: 'ONLINE',
    name: 'Thanh toán trực tuyến',
  }
]

export const PaymentMethod = ({ record }) => <span>{record.code} - {record.name}</span>

class PaymentStep extends Component {

  constructor(props) {
    super(props)
    this.state = {
      paidAmount: 0,
      diffAmount: 0
    }
  }

  onReceivedMoneyClicked = () => {
    let { totalAmount } = this.props
    this.onChangePaidAmount(totalAmount)
  }

  onChangePaidAmount = (value) => {
    let { record } = this.props
    let { paid: totalAmount } = record
    let paidAmount = !isNaN(value) ? parseFloat(value) : 0
    let diffAmount = parseFloat(paidAmount) - parseFloat(totalAmount)
    this.setState({ paidAmount, diffAmount })
  }

  render() {
    let { translate, record, classes } = this.props
    let { diffAmount, isPayLater } = this.state
    let { paid: totalAmount, type } = record
    let label = type === '00NORMAL' ? translate('resources.reservations.totalAmount') : translate('resources.reservations.totalRefund')
    return (
      <FormBody>
        <Grid container spacing={8}>
          <Grid item xs={11}>
            <Typography variant="h6" component="h6">
              {translate('resources.reservations.steps.payment')}
            </Typography>
          </Grid>
          <Grid item xs={6} md={6}>
            <SimpleShowLayout record={{ totalAmount }}>
              <FunctionField
                addLabel={false}
                render={record => <div className={classes.contentItem}>
                  <span className={classes.label}>{label}:</span>&nbsp;
                  <span>
                    <NumberField
                      record={record}
                      source="totalAmount"
                      locales="vi-VN"
                      options={{ style: 'currency', currency: 'VND' }}
                    />
                  </span>
                </div>}
              />
              <br />
              {!isPayLater && <PaymentInput
                source="paid"
                onChangePaidAmount={this.onChangePaidAmount}
                translate={translate}
                onReceivedMoneyClicked={this.onReceivedMoneyClicked}
              />}
              <br />
              {
                diffAmount !== 0 &&
                <div className={classes.contentItem}>
                  <span className={classes.label}>{translate(
                    diffAmount > 0
                      ? 'resources.reservations.changeAmount'
                      : 'resources.reservations.debtAmount'
                  )}:</span>&nbsp;
                  <span>
                    <NumberField
                      record={{ diffAmount: Math.abs(diffAmount) }}
                      source="diffAmount"
                      locales="vi-VN"
                      options={{ style: 'currency', currency: 'VND' }}
                    />
                  </span>
                </div>
              }
            </SimpleShowLayout>
          </Grid>

          <Grid item xs={6} md={6}>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={isPayLater}
                  onChange={(k, v) => this.onChangePayLater(v)}
                  value="isPayLater"
                />
              }
              label={translate('resources.reservations.payLater')}
            />

            {!isPayLater && <Fragment>
              <SelectInput
                label="resources.reservations.payment.method"
                source="paymentMethodCode"
                resource="transactions"
                fullWidth
                validate={required()}
                choices={paymentMethodChoices}
                optionText="name"
                defaultValue="CASH"
              />
              <ReferenceInput
                label="resources.reservations.currency"
                source="currencyId"
                reference="currencies"
                resource="transactions"
                fullWidth
                validate={required()}
              >
                <SelectInputCustom
                  defaultOption={{ field: 'code', value: 'VND' }}
                  optionText="code"
                />
              </ReferenceInput>

              <ReferenceInput
                label="resources.reservations.transaction.type"
                source="type"
                reference="transactiontypes"
                resource="transactions"
                defaultValue="00NORMAL"
                fullWidth
              >
                <SelectInput optionText="name" />
              </ReferenceInput>

              <ReferenceInput
                label="resources.reservations.transaction.status"
                source="status"
                reference="transactionstatuses"
                resource="transactions"
                defaultValue="20DONE"
                disabled
                fullWidth
              >
                <SelectInput optionText="name" />
              </ReferenceInput>
              <TextInput resource="transactions" source="desc" fullWidth />
            </Fragment>
            }
          </Grid>
        </Grid>
      </FormBody>
    )
  }
}

const enhance = compose( withStyles(styles), translate)
export default enhance(PaymentStep)
