import React, { Component } from 'react'
import { Grid, withStyles} from '@material-ui/core'
import {
  ReferenceInput,
  SelectInput,
  TextInput,
  translate,
  required,
  minLength,
} from 'react-admin'
import { DatePickerInput } from '../common/DatePicker'
import compose from 'recompose/compose'
import { MoneyInput } from '../common/MoneyInput'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 'bold',
    },
    stopList: {
      padding: 12
    },
    card: {
      padding: 12
    },
    checkbox: {
      display: 'flex'
    },
    item: {
      padding: 0
    }
  })
}

export const validateFareName = [required(), minLength(6)]

class FormSave extends Component {

  constructor(props) {
    super(props)
    this.state = {
      fareRules: {}
    }
  }

  render() {
    let { classes, translate, resource } = this.props
    let extra = { resource, fullWidth: true }

    return <Grid container spacing={24}>
      <Grid item xs={6}>
        <TextInput source="name" validate={validateFareName} {...extra} />
        <DatePickerInput
          source="applyFrom"
          label="resources.fares.fields.applyFrom"
          dateFormat="DD/MM/YYYY"
          showLunarDate={true}
          keyboard={true}
          {...extra}
        />
        <Grid container spacing={8}>
          <Grid item xs={6} className={classes.item}>
            <MoneyInput
              label="resources.fares.fields.amount"
              translate={translate}
              helperText={translate('resources.fares.prices.amountHelper')}
              source="amount"
              {...extra}
            />
          </Grid>
          <Grid item xs={6} className={classes.item}>
            <ReferenceInput
              source="status"
              reference="farestatuses"
              defaultValue="10ACT"
              {...extra}
            >
              <SelectInput />
            </ReferenceInput>
          </Grid>
          <ReferenceInput
            source="type"
            reference="faretypes"
            defaultValue="10TRIP"
            filter={{ id: { neq: '00ROUTE' } }}
            {...extra}
          >
            <SelectInput />
          </ReferenceInput>
          <ReferenceInput
            source="routeId"
            reference="routes"
            filter={{ status: { neq: '20ARCHIVED' } }}
            validate={required()}
            {...extra}
          >
            <SelectInput />
          </ReferenceInput>
          <TextInput source="desc" {...extra} />
        </Grid>
      </Grid>
    </Grid >
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(FormSave)
