import { fork, take, put,race } from 'redux-saga/effects'
import {
  SAVE_VEHICLES,
  FETCH_SAVE_VEHICLES_SUCCESS,
  FETCH_SAVE_VEHICLES_FAILURE,
  fetchVehicles as fetchVehiclesAction,
  resetForm as resetFormAction,
} from './action'
import { showNotification } from 'ra-core'
import { push } from 'react-router-redux'

function* handleSaveVehicle() {
  while (true) {
    let { vehicles, handleExistsVehicle } = yield take(SAVE_VEHICLES)
    yield put(fetchVehiclesAction(vehicles))
    while (true) {
      let { success, failure } = yield race({
        success: take(FETCH_SAVE_VEHICLES_SUCCESS),
        failure: take(FETCH_SAVE_VEHICLES_FAILURE)
      })
      if (success) {
        let result = success.payload.data.result
        let { resultCode } = result

        if (resultCode !== 200) {
          let { existsVehicle } = result 
          yield put(showNotification(
            'notification.error_vehicle_exists',
            'warning',
            { messageArgs: { count: existsVehicle.length } }
          ))
          handleExistsVehicle(existsVehicle)
        } else {
          let { vehicleSaveds } = result
          yield put(showNotification(
            'notification.create_vehicle_success',
            'info',
            { messageArgs: { count: vehicleSaveds.length } }
          ))
          yield put(push('/vehicles'))
          yield put(resetFormAction())
        }
        break
      }
      if (failure) {
        yield put(showNotification('notification.create_vehicle_failure', 'warning'))
        break
      }
    }
  }
}

export function* handleVehicle() {
  yield fork(handleSaveVehicle)
}
