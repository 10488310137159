import React, { Component, Fragment } from 'react'
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Chip, withStyles, IconButton, FormControl, Input, Avatar } from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import * as dataType from '../common/data-type'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
    },
    addIcon: {
      padding: 5,
      width: 36,
      height: 36
    },
    formControl: {
      margin: theme.spacing.unit,
    },
  })
}

class ChipArrayInput extends Component {

  constructor(props) {
    super(props)
    this.state = {
      datum: [],
      requestNew: {
        dataType: '',
        state: false,
        data: '',
        maskComponent: props.maskComponent
      }
    }
  }

  componentDidMount = () => {
    let { input } = this.props
    let { value } = input
    let datum = []
    try {
      if (typeof value === 'string') {
        datum = JSON.parse(value)
      } else {
        datum = value
      }
    } catch (err) {
      console.error('Invalue consume chip array input! Array is required!')
    }
    this.setState({ datum })
  }

  onChange = () => {
    let { input } = this.props
    let { datum } = this.state
    let newValue = JSON.stringify(datum)
    input = { ...input, value: newValue }
    input.onChange(newValue)
  }

  onAdd = () => {
    let { requestNew } = this.state
    this.setState({ requestNew: { ...requestNew, state: true } })
  }

  onSave = () => {
    let { datum, requestNew } = this.state
    datum.push(requestNew.data)
    this.setState({ datum, requestNew: { ...requestNew, data: '', state: false } })
    this.onChange()
  }

  onDelete = (k, index) => {
    let { datum } = this.state
    datum && datum.splice(index, 1)
    this.setState({ datum: datum })
    this.onChange()
  }

  render() {
    let { classes, type } = this.props
    let { datum, requestNew } = this.state

    return (
      <Fragment>
        {datum && datum.map((data, index) => {
          return (
            <Chip
              key={index}
              label={dataType.format(data, type)}
              onDelete={(k) => this.onDelete(k, index)}
              className={classes.chip}
            />
          )
        })}

        {
          requestNew.state && <Chip
            label={
              <FormControl className={classes.formControl}>
                <Input
                  value={requestNew.data.trim()}
                  onChange={(k) => {
                    this.setState({ requestNew: { ...requestNew, data: k.target.value.trim() } })
                  }}
                  id="input"
                  inputComponent={requestNew.maskComponent}
                />
              </FormControl>
            }
            avatar={
              <Avatar onClick={() => this.onSave()}>
                <FontAwesomeIcon size="xs" icon={faCheck} />
              </Avatar>}
            onDelete={() => this.setState({ requestNew: { ...requestNew, state: false } })}
            className={classes.chip}
          />
        }

        <IconButton size="small"
          onClick={() => this.onAdd()}
          className={classes.addIcon}>
          <FontAwesomeIcon size="xs" icon={faPlus} />
        </IconButton>
      </Fragment>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(ChipArrayInput)
