import React from 'react'
import { IconButton, withStyles } from '@material-ui/core'
import { faSearchPlus, faSearchMinus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = {
  bar: {
    background: 'rgba(0, 0, 0, 0.1)',
    display: 'flex',
    justifyContent: 'space-between',
  },
}

const enhance = withStyles(styles)

export const ZoomComponent = enhance(({ classes, zoom }) => (
  <div className={classes.bar}>
    <span>
      <IconButton onClick={() => zoom(1.2, 0)}>
        <FontAwesomeIcon icon={faSearchMinus} size="1x" />
      </IconButton>
      <IconButton onClick={() => zoom(1/1.2, 0)}>
        <FontAwesomeIcon icon={faSearchPlus} size="1x" />
      </IconButton>
    </span>
    <span>
      <IconButton onClick={() => zoom(1.2, 0.5)}>
        <FontAwesomeIcon icon={faSearchMinus} size="1x" />
      </IconButton>
      <IconButton onClick={() => zoom(1/1.2, 0.5)}>
        <FontAwesomeIcon icon={faSearchPlus} size="1x" />
      </IconButton>
    </span>
    <span>
      <IconButton onClick={() => zoom(1.2, 1)}>
        <FontAwesomeIcon icon={faSearchMinus} size="1x" />
      </IconButton>
      <IconButton onClick={() => zoom(1/1.2, 1)}>
        <FontAwesomeIcon icon={faSearchPlus} size="1x" />
      </IconButton>
    </span>
  </div>
))
