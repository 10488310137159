import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { withLoading } from './withLoading'
import compose from 'recompose/compose'

class ButtonWithLoading extends Component {

  render() {
    let { children, isLoading, disabled, setLoading, ...props } = this.props
    return <Button disabled={isLoading || disabled} {...props}>{children}</Button>
  }
}

const enhance = compose(withLoading)

export default enhance(ButtonWithLoading)
