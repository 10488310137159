import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const DriverIcon = (props) => (
  <SvgIcon {...props}>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path stroke="null" d="m11.90909,2.88769c-1.92172,0 -3.48634,1.73712 -3.48634,3.87371c0,2.13659 1.56461,3.87371 3.48634,3.87371c1.92323,0 3.48634,-1.73711 3.48634,-3.87371c0,-2.13659 -1.5631,-3.87371 -3.48634,-3.87371zm0,8.159c-2.00192,0 -3.47423,0.24665 -4.92687,0.81106c-0.60073,0.23303 -0.96843,0.82619 -1.39211,1.51317c-0.09684,0.15585 -0.1952,0.31776 -0.30263,0.48421c-0.16191,0.24816 -0.345,0.53112 -0.54474,0.83527c-0.82165,1.2529 -1.84001,2.80692 -2.19107,3.48634c-0.37527,0.72783 -0.39645,1.31192 -0.07263,1.93685c0.25724,0.49934 0.61586,1.15152 1.06527,1.93685l0.12105,0.20579l2.43317,0l0,-0.38737c0,-2.7812 2.60113,-5.03582 5.81056,-5.03582c3.20943,0 5.81056,2.25462 5.81056,5.03582l0,0.38737l2.43317,0l0.12105,-0.20579c0.44638,-0.77928 0.80349,-1.43146 1.06527,-1.93685c0.32382,-0.62494 0.30263,-1.20902 -0.07263,-1.93685c-0.24967,-0.4827 -0.8504,-1.39514 -1.48896,-2.37265c-0.46152,-0.70816 -0.93968,-1.44054 -1.23474,-1.93685l-0.16947,-0.27842c-0.47059,-0.80198 -0.86704,-1.50258 -1.53738,-1.74317c-1.33764,-0.48119 -2.69344,-0.79895 -4.92687,-0.79895zm0,7.33584c-2.34995,0 -4.26108,1.57218 -4.26108,3.48634l0,0.38737l8.52216,0l0,-0.38737c0,-1.91416 -1.91264,-3.48634 -4.26108,-3.48634z"/>
  </SvgIcon>
)

export default DriverIcon