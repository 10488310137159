import React, { Component } from 'react'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import { deleteReminder as deleteReminderAction } from './action'
import { translate } from 'react-admin'

const CHANGE_ONE = '1'
const CHANGE_AFTER = '2'

const deleteChoices = [
  { id: CHANGE_ONE, name: 'Lời nhắc này' },
  { id: CHANGE_AFTER, name: 'Lời nhắc này và các lời nhắc sau' },
]

const DeleteReminderConfirm = ({ open, onClose, value, onChange, onDone, translate }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">
        {translate('resources.servicereminders.title.confirm_changing')}
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {deleteChoices.map((updateChoice, idx) => (
            <FormControlLabel
              key={idx}
              value={updateChoice.id}
              control={<Radio />}
              label={updateChoice.name}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {translate('button.cancel')}
        </Button>
        <Button onClick={() => onDone()} color="primary">
          {translate('button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

class DeleteReminderButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openConfirmDialog: false,
      confirmValue: CHANGE_ONE
    }
  }

  handleClick = () => {
    let { repeat } = this.props
    if (!repeat) {
      this.delete()
      return
    }
    this.setState({
      openConfirmDialog: true
    })
  }

  handleClose = () => {
    this.setState({
      openConfirmDialog: false,
      confirmValue: CHANGE_ONE
    })
  }

  handleChangeConfirmValue = (value) => {
    this.setState({
      confirmValue: value
    })
  }

  delete = () => {
    let { id, deleteReminderAction, done, error } = this.props
    let { confirmValue } = this.state
    let onDone = () => {
      done && done()
      this.setState({
        openConfirmDialog: false,
      })
    }
    let onError = () => {
      error && error()
      this.setState({
        openConfirmDialog: false,
      })
    }
    deleteReminderAction(id, { deleteType: confirmValue }, onDone, onError)
  }

  handleOkClick = () => {
    this.delete()
  }

  render() {
    let { openConfirmDialog, confirmValue } = this.state
    let { translate } = this.props
    return (
      <>
        <IconButton
          size="small"
          className="iconButton"
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faTrashAlt}/>
        </IconButton>
        <DeleteReminderConfirm
          value={confirmValue}
          open={openConfirmDialog}
          onClose={this.handleClose}
          onDone={this.handleOkClick}
          onChange={this.handleChangeConfirmValue}
          translate={translate}
        />
      </>
    )
  }
}

const enhance = compose(
  translate,
  connect(null, { deleteReminderAction })
)

export default enhance(DeleteReminderButton)