import React, { Component } from 'react'
import {
  Chip,
  withStyles,
  FormControl,
  Input,
  IconButton,
  Tooltip,
} from '@material-ui/core'
import { Provider } from '../provider'
import compose from 'recompose/compose'
import EditIcon from '@material-ui/icons/Edit'
import DoneIcon from '@material-ui/icons/Done'
import { translate, showNotification } from 'react-admin'
import { connect } from 'react-redux'
import _ from 'lodash'

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336'
    },
  })
}

const editInputStyles = (theme) => {
  return ({
    formControl: {
      margin: theme.spacing.unit,
    },
    input: {
      width: 50,
    },
  })
}

const _EditInput = ({ value, classes, onChange }) => {
  return <FormControl className={classes.formControl}>
    <Input
      autoFocus
      classes={{ root: classes.input }}
      value={value}
      onChange={(evt) => {
        let { value } = evt.target
        onChange(value)
      }}
    />
  </FormControl>
}

const EditInput = withStyles(editInputStyles)(_EditInput)

class ProductFareDescriptionChip extends Component {

  state = {
    edit: false,
  }

  componentDidMount() {
    let { record, source } = this.props
    let value = record[source]
    this.setState({ value })
  }

  componentWillUnmount() {
    this.setState({ value: '' })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { record, source } = nextProps
    let newValue = record[source]
    let { value } = prevState 
    if (newValue !== value) {
      return { ...prevState, value: newValue }
    }
    return { ...prevState }
  }

  handleEdit = () => {
    this.setState({ edit: true })
  }

  handleUpdate = () => {
    let { value } = this.state
    let { record, showNotification, source } = this.props
    let { id, code: oldValue } = record
    if (value === oldValue) {
      this.setState({ edit: false })
    } else {
      Provider.dataProvider('REMOTE', 'fares', {
        method: id,
        requestMethod: 'PATCH',
        data: { [source]: value },
      }).then(() => {
        this.setState({ edit: false })
        showNotification('notification.change_product_fare_description_success')
      }).catch((e) => {
        this.setState({ value: oldValue, edit: false })
        showNotification('notification.change_product_fare_description_failure', 'warning')
      })
    }
  }

  onChange = value => {
    this.setState({
      value,
      messageError: null,
    })
  }

  render() {
    let { classes, isCompanyManager, translate } = this.props
    let { edit, value, messageError } = this.state
    let showValue = !_.isEmpty(value) ? value : translate('resources.products.no_product_fare_description')
    return <Tooltip 
      placement="bottom-end"
      open={edit && messageError}
      classes={{
        tooltip: classes.tooltip
      }}
      title={messageError ? translate(messageError) : ''}
      arrow="true"
    >
      <Chip
        size="small"
        variant="outlined"
        color="primary"
        label={edit ? <EditInput onChange={this.onChange} value={value} /> : showValue}
        className={classes.chip}
        onDelete={() => { 
          if (!_.isEmpty(messageError)) {
            return
          }
          if (!edit) {
            this.handleEdit()
          } else {
            this.handleUpdate()
          }
        }}
        deleteIcon={isCompanyManager && <IconButton
          color="primary"
          className={classes.actionButton}
        >
          {!edit ? <EditIcon fontSize="small" /> : <DoneIcon fontSize="small" />}
        </IconButton>}
      />
    </Tooltip>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification })
)
export default enhance(ProductFareDescriptionChip)
