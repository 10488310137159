import React from 'react'
import { blue, green, grey, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core'
import { ChipField } from 'react-admin'
import get from 'lodash/get'

export const INACTIVE = '00IAT'
export const ACTIVE = '10ACT'
export const PENDING = '20PND'
export const BANNED = '30BAN'
export const ARCHIVED = '40ARC'
export const DEPARTED = '50DEP'
export const ARRIVED = '60ARV'
export const COMPLETED = '70COM'
export const CANCELED = '80CAN'
 
export const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  [ACTIVE]: { color: 'white', backgroundColor: green[500] },
  [DEPARTED]: { color: 'white', backgroundColor: blue[500] },
  [ARRIVED]: { color: 'white', backgroundColor: grey[500] },
  [CANCELED]: { color: 'white', backgroundColor: red[500] },
  [INACTIVE]: { color: 'white', backgroundColor: 'rgb(204, 204, 204)' },
}

export const activeGroups = [ACTIVE, DEPARTED]
export const canSwapGroups = [INACTIVE, ACTIVE]

export function canSale (status) {
  return activeGroups.indexOf(status) >= 0
}

export function validSwapTrip (status) {
  return canSwapGroups.indexOf(status) >= 0
}

////////////////////////////////////////////////////////////////////////////////
// ViewTrip
export const StatusField = withStyles(statusStyles)(({ classes, ...props }) => {
  let status = get(props.record, 'id')
  let className = get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})
