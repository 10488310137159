import React, { Component, Fragment } from 'react'
import compose from 'recompose/compose'
import {
  translate,
  Toolbar,
  WithPermissions,
  showNotification,
} from 'react-admin'
import { 
  Button,
  withStyles,
} from '@material-ui/core'
import * as permission from '../utils/permission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import DialogLoading from '../common/DialogLoading'
import _ from 'lodash'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { change as changeForm, reset as resetForm } from 'redux-form'
import moment from 'moment'

const payOnlineButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#303f9f',
    color: 'white',
    '&:hover': {
      backgroundColor: '#5c6bc0'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const payCashButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#00796b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009688'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const buildRechargeRequest = (values) => {
  const { code, rechargeMonth  } = values
  const req = {
    code,
    month: moment(rechargeMonth).format('MM/YYYY'),
    source: '10WEBADMIN'
  }
  return req
}

const _PayOnlineButton = ({
  translate,
  handleSubmit,
  recharge,
  classes,
  isRechargedMonth,
}) => {
  return <Button
    className={classes.button}
    onClick={handleSubmit(values => { recharge(values, true, false) })}
    variant="contained"
    disabled={isRechargedMonth}
  >
    <FontAwesomeIcon icon={faAddressCard} className={classes.icon} />
    {translate('button.recharge_member_card.pay_online')}
  </Button>
}

const PayOnlineButton = compose(translate, withStyles(payOnlineButtonStyle))(_PayOnlineButton)

const _PayCashButton = ({
  translate,
  handleSubmit,
  recharge,
  classes,
  isRechargedMonth,
}) => {
  return <Button
    className={classes.button}
    onClick={handleSubmit(values => { recharge(values, false, true) })}
    variant="contained"
    disabled={isRechargedMonth}
  >
    <FontAwesomeIcon icon={faAddressCard} className={classes.icon} />
    {translate('button.recharge_member_card.pay_cash')}
  </Button>
}

const PayCashButton = compose(translate, withStyles(payCashButtonStyle))(_PayCashButton)

class RechargeToolbar extends Component {

  state = {
    open: false,
    loading: false,
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  requestOnlinePayment = async (reservationId, amount, source, memberCardId, rechargeMonth) => {
    let currentHost = window.location.origin
    let pathname = `/membercards/recharge?memberCardId=${memberCardId}&rechargeMonth=${rechargeMonth}`
    let returnUrl = `${currentHost}${pathname}`
    let resp = await Provider.dataProvider('REMOTE', 'payments', {
      method: 'card-request',
      requestMethod: 'POST',
      data: {
        reservationId,
        amount,
        source,
        orderType: 'bill payment',
        returnUrl,
      }
    })
    if (resp && resp.data) {
      let url = _.get(resp.data, 'data')
      if (url) {
        window.location.href = url
      }
    }
  }

  recharge = (values, isPayOnline, isPayCash) => {
    const request = buildRechargeRequest(values)
    request.isPayCash = isPayCash
    let { showNotification, resetForm, form, translate, openResultDialog } = this.props
    this.startLoading()
    Provider.dataProvider('REMOTE', 'membercards', {
      method: 'recharge',
      requestMethod: 'POST',
      data: request,
    }).then( async res => {
      if (res.data) {
        let resp = _.get(res.data, 'data', {})
        let { id, source, amount, } = resp
        if (!isPayCash) {
          this.endLoading()
          const memberCardId = _.get(resp, 'memberCardId')
          const rechargeMonth = _.get(values, 'rechargeMonth')
          await this.requestOnlinePayment(id, amount, source, memberCardId, moment(rechargeMonth).format('MM/YYYY'))
          resetForm(form)
        } else {
          this.endLoading()
          resetForm(form)
          showNotification(translate('notification.recharge_member_card_success'), 'success')
          openResultDialog()
        }
      }
    }).catch((e) => {
      this.endLoading()
      let code = _.get(e, 'code') || ''
      showNotification(translate(`error_messages.codes.member_cards.${code.toLowerCase()}`), 'warning')
    })
  }

  render() {
    let { 
      handleSubmit,
      permissions,
      isRechargedMonth,
    } = this.props
    let { loading } = this.state
    let isCompanyRole = permission.hasOneOfPermission(permissions, 'company-manager', 'company-employee', 'company-supplier') 
    return <Toolbar>
      {isCompanyRole &&<Fragment>
        <PayOnlineButton 
          handleSubmit={handleSubmit}
          recharge={this.recharge}
          disabled={isRechargedMonth}
        />
        <PayCashButton 
          handleSubmit={handleSubmit}
          recharge={this.recharge}
          disabled={isRechargedMonth}
        />
      </Fragment>}
      {loading && <DialogLoading open={loading} />}
    </Toolbar>
  }
}

const RechargeToolbarWithPermissions = props => <WithPermissions
  render={({ permissions }) => <RechargeToolbar permissions={permissions} {...props} />}
/>

const enhance = compose(
  translate,
  connect(null, {
    showNotification,
    push,
    changeForm,
    resetForm,
  })
)
export default enhance(RechargeToolbarWithPermissions)
