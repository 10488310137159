import React, { Component } from 'react'
import {
  Card,
  CardHeader,
  CardActions,
  withStyles,
  Chip,
} from '@material-ui/core'
import ReminderButton from './ReminderButton'
import UpsertServiceTaskButton from './UpsertServiceTaskButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBell } from '@fortawesome/free-solid-svg-icons'

const NotificationIcon = () => (
  <FontAwesomeIcon icon={faBell} />
)

const style = () => ({
  root: {
    padding: 16
  },
  card: {
    marginBottom: 16
  },
  chip: {
    margin: 4
  }
})

const LabelChip = ({ record = {} }) => {
  return record.name
}

const ServiceTask = ({ principal, principalType, record, classes, showList, companyManager }) => {
  return !showList ? (
    <Card className={classes.card}>
      <CardHeader
        title={record.name}
        subheader={record.desc}
      />
      {companyManager && <CardActions>
        <ReminderButton
          principal={principal}
          servicetaskRecord={record}
          icon={<NotificationIcon />}
          isCreate={true}
          principalType={principalType}
        />
        <UpsertServiceTaskButton
          record={record}
          isIconButton={true}
          isCreate={false}
        />
      </CardActions>}
    </Card>
  ) : (
    <Chip
      className={classes.chip}
      label={<LabelChip record={record} />}
    />
  )
}

class ServiceTaskList extends Component {

  render() {
    let { data, principal, principalType, classes, ids, showList, companyManager } = this.props
    return (
      <div className={classes.root}>
        {ids.map((id, idx) => <ServiceTask
          classes={classes}
          companyManager={companyManager}
          key={idx}
          record={data[id]}
          principal={principal}
          principalType={principalType}
          showList={showList}
        />)}
      </div>
    )
  }
}

ServiceTaskList.defaultProps = {
  data: {},
  ids: []
}

export default withStyles(style)(ServiceTaskList)