import {
  MAP_UPDATE,
  MAP_ACTION,
  OPEN_CONTEXT_MENU,
  NETWORK_UPDATE,
  CHANGE_ROUTE
} from './actions'

export const network = (state = {
  isChanged: false
}, action) => {
  let next
  switch (action.type) {
    case MAP_UPDATE:
      next = { ...state, map: { ...action.payload } }
      return next
    case MAP_ACTION:
      next = { ...state, action: { ...action.payload } }
      return next
    case OPEN_CONTEXT_MENU:
      next = { ...state, anchorEl: action.payload.anchorEl, 
        anchorPosition: action.payload.anchorPosition,
        anchorReference: action.payload.anchorReference,
        data: action.payload.data,
        type: action.payload.type
      }
      return next
    case NETWORK_UPDATE:
      next = { ...state, ts: action.payload.ts }
      return next
    case CHANGE_ROUTE: {
      let { isChanged } = action
      return {
        ...state,
        isChanged
      }
    }
    default:
      return state
  }
}