import React, { Component } from 'react'
import { translate } from 'react-admin'
import {
  withStyles,
  Tabs,
  Tab,
} from '@material-ui/core'
import compose from 'recompose/compose'
import VehicleInfomation from './VehicleInfomation'

const styles = theme => ({
  root: {
    display: 'flex'
  },
  tabsRoot: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabsIndicator: {
    backgroundColor: '#1890ff',
  },
  tabRoot: {
    textTransform: 'initial',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing.unit * 4,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&$tabSelected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: '#40a9ff',
    },
  },
  tabSelected: {},
  tabContainer: { padding: theme.spacing.unit * 3 }
})

const TabHeaders = ({ classes, translate, tabIndex, onChange, ...props }) => {
  return (
    <Tabs
      value={tabIndex}
      onChange={(k, v) => onChange(v)}
      classes={{ root: classes.tabsRoot, indicator: classes.tabsIndicator }}
    >
      <Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.vehicles.generalInformation')}
      />
      {<Tab
        disableRipple
        classes={{ root: classes.tabRoot, selected: classes.tabSelected }}
        label={translate('resources.vehicles.maintainHistory')}
      />
      }
    </Tabs>
  )
}

class FormSave extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0
    }
  }

  changeTab = (idx) => {
    this.setState({ tabIndex: idx })
  }

  render() {
    let { tabIndex } = this.state
    let { classes, action, translate, permissions, ...props } = this.props
    let isCompanyManager = permissions && permissions['company-employee']
    return (
      <div>
        <TabHeaders
          classes={classes}
          tabIndex={tabIndex}
          onChange={(v) => this.changeTab(v)}
          action={action}
          translate={translate}
        />
        {tabIndex === 0 && <div className={classes.tabContainer}>
          <VehicleInfomation {...props} isCompanyManager={isCompanyManager} />
        </div>}

        {tabIndex === 1 && <div className={classes.tabContainer}>
        </div>}
      </div>
    )
  }
}

const enhance = compose(withStyles(styles), translate)

export default enhance(FormSave)
