export function validate(values, props){
  let errors = {}
  let { translate } = props
  
  let plate = values && values.plate && values.plate.split(' ') 

  if (plate && plate.length > 1) {
    errors.plate = translate('error_messages.required.vehicles.plate')
  }

  return errors
}
