import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { IconButton, Menu, MenuItem, withStyles, Button } from '@material-ui/core'

const styles = theme => ({
  root: {
    display: 'flex',
  },
})

class SelectControl extends Component {
  state = {
    anchorEl: null
  }

  handleToggle = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = event => {
    this.setState({ anchorEl: null })
  }

  render() {
    const { classes, elements, children, iconButton, label, icon, ...rest } = this.props
    const { anchorEl } = this.state
    return (
      <div className={classes.root} {...rest}>
        <div>
          {iconButton 
            ? <IconButton
              aria-owns={anchorEl ? 'simple-menu' : null}
              aria-haspopup="true"
              onClick={this.handleToggle}
            >
              {label}
            </IconButton>
            : <Button
              onClick={this.handleToggle}
              color="primary"
            >
              {icon}{label}
            </Button>}
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={this.handleClose}
          >
            {elements && elements.map(element => (
              <MenuItem key={element.id}>
                {element.element}
              </MenuItem>
            ))}
            {/* {children}  */}
            {React.Children.map(children, child => {
              if (child && child.props.onClick) {
                let onClick = child.props.onClick
                let extra = {
                  onClick: (evt) => {
                    onClick(evt)
                    this.handleClose()
                  }
                }
                return React.cloneElement(child, extra)
              } else {
                return child
              }
            })}
          </Menu>
        </div>
      </div>
    )
  }
}

SelectControl.defaultProps = {
  iconButton: true
}

SelectControl.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SelectControl)
