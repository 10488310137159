import React, { Component } from 'react'
import {
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  ImageInput,
  ImageField,
  SimpleForm,
  FormDataConsumer,
  required,
  minLength,
  maxLength,
} from 'react-admin'
import {
  withStyles,
  InputAdornment,
  Grid,
} from '@material-ui/core'
import compose from 'recompose/compose'
import VehicleTable from './VehicleTable'
import isEmpty from 'lodash/isEmpty'
import Snackbar from '../common/Snackbar'
import { validate } from './validate'

const styles = () => ({
  root: {
    display: 'flex',
    width: '100%',
  },
  card: {
    padding: 16,
    width: '50%',
  },
  gridItem: {
    padding: 0
  },
  snackbar: {
    marginLeft: 16
  },
  colorText: {
    color: 'red',
  }
})

const DEFAULT_STATUS = '10ACT'
const DEFAULT_FUEL = '10DS'
const min = 9
const max = 10
export const validateVehicleName = [required(), minLength(min), maxLength(max)]

class AddInfoStep extends Component {

  constructor(props) {
    super(props)
    this.state = {
      vehicles: [],
      perPage: 10,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { vehicleTypeSelected, vehicleSelected, vehicles } = nextProps
    let currentRecord = prevState.record
    if (vehicleSelected !== currentRecord) {
      currentRecord = vehicleSelected
    }
    if (vehicleTypeSelected.id !== currentRecord.type) {
      currentRecord.type = vehicleTypeSelected.id
      currentRecord.capacity = vehicleTypeSelected.totalSeat
      currentRecord.seatAvailable = vehicleTypeSelected.totalSeat
    }
    let currentVehicles = prevState.vehicles
    if (currentVehicles !== vehicles) {
      currentVehicles = vehicles
    }
    return {
      record: currentRecord,
      vehicles: currentVehicles,
    }
  }

  updatePerPage = perPage => this.setState({ perPage })

  render() {
    const { classes, translate } = this.props
    const { record, vehicles } = this.state
    let extras = { resource: 'vehicles', fullWidth: true }
    return !isEmpty(record) ? (
      <SimpleForm record={record} toolbar={null} validate={validate}>
        <div className={classes.root}>
          <div className={classes.card}>
            <Grid container spacing={8}>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <TextInput 
                  source="plate"
                  validate={validateVehicleName}
                  {...extras}
                />
              </Grid>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <FormDataConsumer>
                  {({ formData = {} }) => {
                    let { added, plate } = formData
                    let defaultValue = plate ? plate : ''
                    return (
                      <TextInput
                        source="name"
                        {...extras}
                        defaultValue={defaultValue}
                        disabled={!added}
                      />
                    )}
                  }
                </FormDataConsumer>
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <TextInput source="brand" {...extras} />
              </Grid>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <TextInput source="model" {...extras} />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <TextInput source="color" {...extras} />
              </Grid>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <ReferenceInput
                  reference="fueltypes"
                  source="fuel"
                  resource="fueltypes"
                  {...extras}
                  defaultValue={DEFAULT_FUEL}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <TextInput
                  source="capacity"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">chỗ</InputAdornment>
                  }}
                  {...extras}
                  disabled
                />
              </Grid>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <TextInput
                  source="seatAvailable"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">chỗ</InputAdornment>
                  }}
                  {...extras}
                  disabled
                />
              </Grid>
            </Grid>
            <Grid container spacing={8}>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <ReferenceInput
                  reference="vehicletypes"
                  source="type"
                  resource="vehicletypes"
                  {...extras}
                  disabled
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Grid>
              <Grid className={classes.gridItem} item xs={6} md={6}>
                <ReferenceInput
                  reference="vehiclestatuses"
                  source="status"
                  resource="vehiclestatuses"
                  {...extras}
                  defaultValue={DEFAULT_STATUS}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Grid>
            </Grid>
            <ImageInput
              source="images"
              accept="image/*"
              multiple
              {...extras}
            >
              <ImageField source="src" />
            </ImageInput>
          </div>
          <div className={classes.card}>
            <VehicleTable rows={vehicles} updatePerPage={this.updatePerPage} perPage={this.state.perPage}/>
          </div>
        </div>
      </SimpleForm>
    ) : (
      <Snackbar
        className={classes.snackbar}
        variant="warning"
        message={translate('notification.select_vehicle_type_uncompleted')}
      />
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(AddInfoStep)
