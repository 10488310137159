import React, { Fragment, Component } from 'react'
import {
  Datagrid,
  List,
  Filter,
  ReferenceField,
  translate,
  CardActions,
  FunctionField,
  refreshView,
  NumberField,
  ReferenceInput,
} from 'react-admin'
import { DateTimePickerInput } from '../common/DateTimePicker'
import compose from 'recompose/compose'
import {
  withStyles,
  Tooltip,
  Icon,
  CircularProgress,
  IconButton,
} from '@material-ui/core'
import moment from 'moment'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import { SeatIcon } from '../icons'
import GeneralReport from './GeneralReport'
import LinkField from './../common/LinkField'
import { Edit as EditIcon } from '@material-ui/icons'
import { connect } from 'react-redux'
import { getCurrentCompany, sanitize } from '../utils/commonUtil'
import { shortDateTimeFormat } from '../common/format'
import { withRouter } from 'react-router'
import { validate as valid } from './validate'
import { withSnackbar } from '../common/notistack'
import * as permission from '../utils/permission'
import withMQTT from '../mqtt/withMQTT'
import { VIEW_TRIP } from '../common/mqttEventEmitter'
import { Provider } from '../provider'
import TripMoreInfo from './TripMoreInfo'
import { StatusField } from './StatusField'
import { TypeField } from './TypeField'
import _ from 'lodash'
import GroupAvatar from './GroupAvatar'
import QuickCreateTripButton from './QuickCreateTripButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClone } from '@fortawesome/free-solid-svg-icons'
import SelectInputCustom from '../common/SelectInputCustom'

export const MessageTypes = {
  NEW_CHARGE: 'new_charge',
  UPDATE_CHARGE: 'update_charge',
  UPDATE_TRIP: 'update_trip',
  CANCELED_CHARGE: 'canceled_charge',
  RESERVATION_CHANGED: 'RESERVATION_CHANGED',
  TRIP_CHANGED: 'TRIP_CHANGED'
}

const chargeStatus = {
  CANCELED: '20CANCEL'
}

const ViewTripActions = ({
  classes,
  translate,
  bulkActions,
  basePath,
  displayedFilters,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
}) => <CardActions>
  <QuickCreateTripButton classes={classes} />
  {bulkActions && React.cloneElement(bulkActions, {
    basePath,
    filterValues,
    resource,
    selectedIds,
    onUnselectItems,
  })}
  {filters && React.cloneElement(filters, {
    resource,
    showFilter,
    displayedFilters,
    filterValues,
    context: 'button',
  })}
  <LinkField
    classes={classes}
    path={'/trips'}>
    <Icon style={{ marginRight: 8 }}>list</Icon>
    {translate('resources.viewtrips.fields.timeline')}
  </LinkField>
  <LinkField
    classes={classes}
    path={'/viewtripsclone'}>
    <FontAwesomeIcon icon={faClone} style={{ marginRight: 8, fontSize: 14 }} />
    <span>{translate('resources.viewtripsclone.name')}</span>
  </LinkField>
</CardActions>

const styleViewTripFilters = ({
  inputSelect: {
    minWidth: 180,
  }
})

class _ViewTripFilters extends Component {

  state = {
    chargesWithStatus: {}
  }

  validate = values => {
    let { translate } = this.props
    const minDate = moment(values.departureTime_gte).endOf('days').toDate().toISOString()
    if (values.departureTime_lte < minDate) {
      values.departureTime_lte = minDate
    }

    const maxDate = moment(values.departureTime_gte).add(7, 'days').endOf('days').toDate().toISOString()
    if (values.departureTime_lte > maxDate) {
      values.departureTime_lte = maxDate
    }

    if (this.state.fromDate !== minDate) {
      this.setState({ fromDate: minDate })
    }
    valid(values, translate)
  }

  render() {
    let { translate, classes, ...props } = this.props
    let { fromDate } = this.state

    return (
      <Filter
        form="view-trip-filter-form"
        validate={this.validate}
        {...props}
      >
        <DateTimePickerInput
          source="departureTime_gte"
          dateFormat="DD/MM/YYYY HH:mm"
          ampm={false}
          showLunarDate={true}
          keyboard={true}
          allowEmpty
          pickerType='datetime'
          alwaysOn
        />

        <DateTimePickerInput
          source="departureTime_lte"
          dateFormat="DD/MM/YYYY HH:mm"
          ampm={false}
          showLunarDate={true}
          keyboard={true}
          minDate={fromDate}
          allowEmpty
          pickerType='datetime'
          alwaysOn
        />

        <ReferenceInput
          source="routeId"
          reference="routes/list"
          sort={{ field: 'id', order: 'ASC' }}
          filter={{ status: { neq: '20ARCHIVED' }, '../fields': ['id', 'name'] }}
          alwaysOn
        >
          <FuzzySelectInput
            renderItem={item => item.name}
            {...defaultFuzzySearch()}
          />
        </ReferenceInput>

        <ReferenceInput
          source="originId"
          reference="stops"
          perPage={1000}
        >
          <FuzzySelectInput
            renderItem={item => item.name}
            {...defaultFuzzySearch()}
          />
        </ReferenceInput>

        <ReferenceInput
          source="destinationId"
          reference="stops"
          perPage={1000}
        >
          <FuzzySelectInput
            renderItem={item => item.name}
            {...defaultFuzzySearch()}
          />
        </ReferenceInput>

        <ReferenceInput
          source="vehicleId"
          reference="vehicles/list"
          perPage={1000}
        >
          <FuzzySelectInput
            optionText="plate"
            renderItem={item => item.plate}
            {...defaultFuzzySearch({ name: 'plate' })}
          />
        </ReferenceInput>

        <ReferenceInput
          source="status"
          reference="tripstatuses"
          sort={{ field: 'id', order: 'ASC' }}
          label="resources.tripstatuses.fields.status"
          classExtra={classes.inputSelect}
          allowEmpty
        >
          <SelectInputCustom
            optionText="name"
            all={translate('resources.common.all')}
          />
        </ReferenceInput>
      </Filter>
    )
  }
}

const enhanceViewTripFilters = compose(translate, withStyles(styleViewTripFilters))
const ViewTripFilters = enhanceViewTripFilters(_ViewTripFilters)

const styles = {
  icon: {
    paddingRight: '0.5em',
  },
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  textCenter: {
    textAlign: 'center',
  },
  textEnd: {
    textAlign: 'end',
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
}

const buildFilter = filterAttrs => {
  let searchValue = {}
  for (let searchKey in filterAttrs) {
    if (searchKey.endsWith('_gte')) {
      let value = filterAttrs[searchKey]
      let newKey = searchKey.substring(0, searchKey.indexOf('_gte'))

      if (!searchValue[newKey]) {
        searchValue[newKey] = { gte: value }
      } else {
        searchValue['and'] = [{ [newKey]: { ...searchValue[newKey] } }, { [newKey]: { gte: value } }]
        delete searchValue[newKey]
      }
    } else if (searchKey.endsWith('_lte')) {
      let value = filterAttrs[searchKey]
      let newKey = searchKey.substring(0, searchKey.indexOf('_lte'))

      if (!searchValue[newKey]) {
        searchValue[newKey] = { lte: value }
      } else {
        searchValue['and'] = [{ [newKey]: { ...searchValue[newKey] } }, { [newKey]: { lte: value } }]
        delete searchValue[newKey]
      }
    } else {
      searchValue[searchKey] = filterAttrs[searchKey]
    }
  }
  return searchValue
}

class ViewTripList extends Component {

  state = {
    searchValue: {},
    report: {},
    loading: true,
  }

  componentDidMount = async () => {
    //this.consumeNewReservation()
    let drivers = await this.loadDrivers()
    let assistantDrivers = await this.loadAssistantDrivers()
    let vehicles = await this.loadVehicles()
    this.setState({
      drivers,
      assistantDrivers,
      vehicles,
      loading: false,
    })
  }

  loadVehicles = async () => {
    let vehicles = await Provider.dataProvider('GET_LIST', 'vehicles', {
      filter: {
        where: {},
        path: 'list',
        fields: ['id', 'plate'],
      },
      sort: {},
      pagination: { page: 1, perPage: 100 },
    })
    if (vehicles && vehicles.data) {
      vehicles = _.keyBy(vehicles.data, 'id')
    }
    return vehicles || {}
  }

  loadDrivers = async () => {
    let drivers = await Provider.dataProvider('GET_LIST', 'drivers', {
      filter: {
        where: {},
        path: 'list',
        fields: ['id', 'fullName'],
      },
      sort: {},
      pagination: { page: 1, perPage: 100 },
    })
    if (drivers && drivers.data) {
      drivers = _.keyBy(drivers.data, 'id')
    }
    return drivers || {}
  }

  loadAssistantDrivers = async () => {
    let assistantDrivers = await Provider.dataProvider('GET_LIST', 'assistantdrivers', {
      filter: {
        where: {},
        path: 'list',
        fields: ['id', 'fullName'],
      },
      sort: {},
      pagination: { page: 1, perPage: 100 },
    })
    if (assistantDrivers && assistantDrivers.data) {
      assistantDrivers = _.keyBy(assistantDrivers.data, 'id')
    }
    return assistantDrivers || {}
  }

  refreshPage = () => {
    this.props.refreshView()
  }

  getSnapshotBeforeUpdate(prevProps) {
    let { filterAttrs } = this.props
    let { filterAttrs: prevFilterAttrs } = prevProps
    const updated = !(filterAttrs === prevFilterAttrs)
    return { updated }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot.updated) {
      let { filterAttrs } = this.props
      filterAttrs && this.onSearchChange(filterAttrs)
    }
  }

  onSearchChange = (filterAttrs) => {
    let { permissions } = this.props
    let isCompanyManager = permission.isCompanyManager(permissions)
    isCompanyManager && this.fetchReport(filterAttrs)
  }

  fetchReport = async (filterAttrs) => {
    let searchValue = buildFilter(filterAttrs)
    let filter = {
      where: {}
    }
    let { departureTime_gte, departureTime_lte } = filterAttrs
    if ((departureTime_gte && departureTime_lte) && moment(departureTime_lte).diff(departureTime_gte, 'days') > 7) return
    for (let searchKey in searchValue) {
      filter.where[searchKey] = searchValue[searchKey]
    }

    let res = await Provider.dataProvider('REMOTE', 'viewtrips', {
      method: 'generalReport',
      requestMethod: 'GET',
      data: { filter }
    })

    if (res && res.data) {
      this.setState({
        report: res.data
      })
    }
  }

  getReservationById = async id => {
    let result
    let reservation = await Provider.dataProvider('GET_ONE', 'reservations', {
      id,
      filter: {
        fields: ['id'],
        include: {
          relation: 'charges',
          scope: {
            where: { cancelReference: null },
            fields: ['id', 'status', 'itemDetail', 'tripId'],
            include: {
              relation: 'trip',
              scope: {
                fields: ['name', 'departureTime', 'arrivalTime']
              }
            }
          }
        }
      }
    })
    if (reservation && reservation.data) {
      result = reservation.data
    }
    return result
  }

  updateGroupAvatar = (source, value) => {
    if (this.groupAvatar && this.groupAvatar.current) {
      let groupAvatarRef = this.groupAvatar.current
      groupAvatarRef.updateState(source, value)
    }
  }

  onMessage = async (message) => {
    // Handle notification for company
    let {
      refreshView,
      enqueueSnackbar,
      translate,
    } = this.props
    let data = JSON.parse(message)
    let { name } = data
    let { payload } = data
    let { chargesWithStatus: currentCharges = {} } = this.state
    switch (name) {
      case MessageTypes.TRIP_CHANGED:
        refreshView()
        break
      case MessageTypes.RESERVATION_CHANGED: {
        let reservation = await this.getReservationById(payload.reservationId)
        let { charges } = reservation
        if (charges && charges.length > 0) {
          for (let i = 0; i < charges.length; i++) {
            let charge = charges[i]
            let { id, status, trip, itemDetail } = charge
            if (!currentCharges[id] || currentCharges[id] !== status) {
              let { name = '', departureTime, arrivalTime } = trip
              let { itemCode } = itemDetail
              let variant = 'success'
              let description = translate('resources.charges.newCharge')
              if (status === chargeStatus.CANCELED) {
                variant = 'warning'
                description = translate('resources.charges.cancelCharge')
              }
              enqueueSnackbar(`
                  ${name} (${moment(departureTime).format(shortDateTimeFormat)} - ${moment(arrivalTime).format(shortDateTimeFormat)}) ${description} '${itemCode}'!
                `, {
                variant,
              })
              currentCharges[id] = status
            }
          }
          this.setState({
            chargesWithStatus: { ...currentCharges }
          })
        }
        refreshView()
        break
      }
      default:
        break
    }
  }

  consumeNewReservation = () => {
    let { mqttEmitter, subscribe } = this.props
    let company = getCurrentCompany() || {}
    let topic = `nexbus/company/${company.id}/trips`
    this.setState({ topic })
    subscribe(topic)
    mqttEmitter.on(VIEW_TRIP, (message) => this.onMessage(message))
  }

  //componentWillUnmount() {
  //let { unsubscribe } = this.props
  //let { topic } = this.state
  //unsubscribe(topic)
  //}

  redirectTripLayout = (tripId) => {
    let { history } = this.props
    let path = `/reservations/trip_layout/${tripId}`
    localStorage.setItem('prevUrl', '/viewtrips')
    history.push(path)
  }

  render() {
    let {
      classes,
      translate,
      doFetchAction,
      history,
      staticContext,
      filterAttrs,
      refreshView,
      enqueueSnackbar,
      closeSnackbar,
      permissions,
      ...props
    } = this.props
    let { report, loading, drivers, assistantDrivers, vehicles } = this.state
    let isCompanyManager = permission.isCompanyManager(permissions)
    let company = getCurrentCompany() || {}
    return <Fragment>
      {loading ? <div className={classes.progress}><CircularProgress /></div> : <Fragment>
        {isCompanyManager && <Fragment>
          <GeneralReport report={report} />
          <br />
        </Fragment>}
        <List
          {...sanitize(
            props, [
              'subscribe',
              'unsubscribe',
              'dispatch',
              'mqttEmitter',
              'eventListener',
              'Component'
            ])}
          actions={<ViewTripActions translate={translate} classes={classes} />}
          bulkActionButtons={false}
          filters={<ViewTripFilters />}
          sort={{ field: 'departureTime', order: 'ASC' }}
          filter={{
            companyId: company.id || 0,
            '../fields': [
              'id',
              'tripId',
              'type',
              'routeName',
              'tripName',
              'departureTime',
              'arrivalTime',
              'status',
              'saleStart',
              'saleEnd',
              'driverId',
              'driver2Id',
              'vehicleId',
              'reservedSeats',
              'totalFare',
              'capacity',
              'assistantDriverId',
              'departure',
              'arrival',
              'routeId',
            ],
            '../withTotalFare': 1,
            '../include': [
              {
                relation: 'route',
                scope: {
                  fields: ['id', 'routeGroupId'],
                  include: [
                    {
                      relation: 'routeGroup',
                      scope: {
                        fields: ['id', 'type'],
                      }
                    }
                  ]
                }
              }
            ],
            status: { neq: '40ARC' },
          }}
          filterDefaultValues={{
            departureTime_gte: moment().startOf('days').toDate().toISOString(),
            departureTime_lte: moment().add(7, 'days').endOf('days').toDate().toISOString(),
          }}
        >
          <Datagrid
            expand={<TripMoreInfo
              assistantDrivers={assistantDrivers}
              drivers={drivers}
              vehicles={vehicles}
              companyManager={isCompanyManager}
              updateGroupAvatar={this.updateGroupAvatar}
            />}
          >
            <FunctionField
              source="tripName"
              render={({ tripName: name }) => name ? (name.length > 20 ? `${name.substring(0, 17)}...` : name) : ''}
            />
            <FunctionField
              source="departureTime"
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              render={record => moment(record.departureTime).format(shortDateTimeFormat)}
            />
            <ReferenceField
              source="status"
              reference="tripstatuses"
              linkType={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
            >
              <StatusField source="name" />
            </ReferenceField>

            <ReferenceField
              source="type"
              reference="triptypes"
              linkType={false}
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
            >
              <TypeField source="name" />
            </ReferenceField>
            <FunctionField
              source="reservedSeats"
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              render={(record) => {
                let { reservedSeats, capacity } = record
                return <span><b>{reservedSeats}</b>/{capacity ? capacity : 0}</span>
              }}
            />

            <NumberField
              source="totalFare"
              className={classes.bold}
              locales="vi-VN"
              headerClassName={classes.textCenter}
              cellClassName={classes.textCenter}
              options={{ style: 'currency', currency: 'VND' }}
            />
            <FunctionField render={record => (record && record !== null) && <GroupAvatar
              tripId={record.tripId}
              vehicleId={record.vehicleId}
              driverId={record.driverId}
              driver2Id={record.driver2Id}
              assistantDriverId={record.assistantDriverId}
            />}
            />
            <FunctionField
              headerClassName={classes.textEnd}
              cellClassName={classes.textEnd}
              render={({ tripId, vehicleId, type, route = {} }) => {
                let { routeGroup = {} } = route
                let { type: routeGroupType } = routeGroup
                let canShow = routeGroupType !== '10BUSTOUR'
                let canReserved = ['90CAN'].indexOf(type) < 0
                return <Fragment>
                  {canShow && <Tooltip
                    title={translate('resources.viewtrips.fields.makeReservation')}
                    enterDelay={100}
                  >
                    <IconButton
                      disabled={!vehicleId || !canReserved}
                      onClick={() => this.redirectTripLayout(tripId)}
                      color="primary"
                    >
                      <SeatIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>}
                  {isCompanyManager && <Tooltip
                    title={translate('button.edit')}
                    enterDelay={100}
                  >
                    <LinkField
                      className={classes.iconButton}
                      path={`/trips/${tripId}`}
                      icon
                    >
                      <EditIcon fontSize="small" />
                    </LinkField>
                  </Tooltip>}
                </Fragment>
              }}
            />
          </Datagrid>
        </List></Fragment>}
    </Fragment>
  }
}

const mapStateToProps = (state) => {
  return {
    filterAttrs: state.form && state.form['view-trip-filter-form'] && state.form['view-trip-filter-form'].values
  }
}

const enhance = compose(
  translate,
  withStyles(styles),
  withRouter,
  withSnackbar,
  //withMQTT({ eventListener: VIEW_TRIP }),
  connect(mapStateToProps, { refreshView }),
)

export default enhance(ViewTripList)
