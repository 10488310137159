import React, { Component } from 'react'
import {
  Card,
  CardContent,
  CardMedia,
  withStyles,
  Button,
  Popover,
  MenuItem,
  ListItemText,
} from '@material-ui/core'
import { Provider } from '../provider'
import { ReferenceField, FunctionField, translate, ReferenceManyField } from 'react-admin'
import moment from 'moment'
import { ExpiredText } from '../licence/ObjectLicence'
import { Link } from 'react-router-dom'
import compose from 'recompose/compose'
import { push } from 'react-router-redux'
import { connect } from 'react-redux'
import Snackbar from '../common/Snackbar'

const style = () => ({
  root: {
    margin: 8
  },
  cover: {
    width: '60%',
    backgroundSize: 'cover'
  },
  contentContainer: {
    display: 'flex',
    fontSize: '0.8571428571428571rem'
  },
  label: {
    fontWeight: 'bold'
  },
  content: {
    width: '40%',
  },
  contentItem: {
    marginBottom: 4
  },
})

const getFirstEle = (array) => {
  if (!array) return ''
  let first = array[0] || {}
  return first
}

let anchorOrigin = {
  vertical: 'top',
  horizontal: 'right',
}
let transformOrigin = {
  vertical: 'top',
  horizontal: 'left',
}

const Actions = ({ data = {}, ids = [], handleLink }) => {
  return ids.map((id, idx) => (id === '10LID') && (
    <MenuItem
      key={idx}
      onClick={() => handleLink(id)}
    >
      <ListItemText>{data[id].name}</ListItemText>
    </MenuItem>
  ))
}

const ActionMenu = ({ open, onClose, anchorEl, handleLink }) => {
  return <Popover
    anchorEl={anchorEl}
    open={open}
    anchorOrigin={anchorOrigin}
    transformOrigin={transformOrigin}
    onClose={onClose}
  >
    <ReferenceManyField
      basePath="/licencetypes"
      resource="licencetypes"
      reference="licencetypes"
      record={{}}
      target=""
    >
      <Actions handleLink={handleLink} />
    </ReferenceManyField>
  </Popover>
}


class _Licence extends Component {
  render() {
    const { record, classes, translate } = this.props
    let { licence } = record
    let { id, licenceValidity: licenceValidities, extras } = licence
    let { licenceClass } = extras
    let licenceValidity = getFirstEle(licenceValidities)
    let { licenceNumber, validFrom, validTo, images } = licenceValidity
    let imageDefault = images ? images[0].src : '/images/default-image.png'
    return record && <Card className={classes.root}>
      <div className={classes.contentContainer}>
        <CardContent className={classes.content}>
          <div className={classes.contentItem}>
            <span className={classes.label}>{translate('resources.licences.fields.licenceNumber')}:</span>&nbsp;
            <span>{licenceNumber}</span>
          </div>
          <ReferenceField
            resource="licencetypes"
            basePath="/licencetypes"
            reference="licencetypes"
            record={licence}
            source="type"
            linkType={false}
          >
            <FunctionField
              render={ record =><div className={classes.contentItem}>
                <span className={classes.label}>{translate('resources.licences.fields.type')}:</span>&nbsp;
                <span>{record.name}</span>
              </div>
              }
            />
          </ReferenceField>
          {licenceClass && <div className={classes.contentItem}>
            <span className={classes.label}>{translate('resources.drivers.fields.licenceClass')}:</span>&nbsp;
            <span>{licenceClass}</span>
          </div>}
          <div className={classes.contentItem}>
            <span className={classes.label}>{translate('resources.licences.fields.validFrom')}:</span>&nbsp;
            <span>{`${moment(validFrom).format('l')}-${moment(validTo).format('l')}`}</span>
          </div>
          <ReferenceField
            resource="provinces"
            basePath="/provinces"
            reference="provinces"
            record={licenceValidity}
            source="placeOfIssue"
            linkType={false}
          >
            <FunctionField
              render={ record =><div className={classes.contentItem}>
                <span className={classes.label}>{translate('resources.licences.fields.placeOfIssue')}:</span>&nbsp;
                <span>{record.name}</span>
              </div>
              }
            />
          </ReferenceField>
          <div className={classes.contentItem}>
            <span className={classes.label}>{translate('resources.licences.fields.note')}:</span>&nbsp;
            <span>{<ExpiredText validTo={validTo} />}</span>
          </div>
          <div className={classes.contentItem}>
            <Button
              component={Link}
              to={`/licences/${id}`}
              color="primary"
            >
              {translate('button.view_detail')}
            </Button>
          </div>
        </CardContent>
        <CardMedia className={classes.cover} image={imageDefault} />
      </div>
    </Card>
  }
}

const Licence = withStyles(style)(_Licence)

const action = (onClick, labelButtonAction) => {
  return [
    <Button
      key={0}
      onClick={(event) => onClick(event)}
      style={{ color: '#ffff' }}
    >
      {labelButtonAction}
    </Button>
  ]
}

class LicenceDrivers extends Component {

  constructor(props) {
    super(props)
    this.state = {
      licences: [],
      open: false,
    }
  }

  async componentDidMount() {
    let { driver = {} } = this.props
    let { id } = driver
    let param = {
      filter: {
        where: { driverId: id },
        include: {
          relation: 'licence',
          scope: {
            include: {
              relation: 'licenceValidity',
              scope: {
                where: { status: { neq: '40ARC' } }
              }
            }
          },
        },
      },
      sort: {},
      pagination: {}
    }
    let res  = await Provider.dataProvider('GET_LIST', 'licencedrivers', param)
    let licences = res.data || []
    this.setState({ licences })
  }

  handleCloseMenu = () => {
    this.setState({
      open: false,
      anchorEl: {}
    })
  }

  openMenu = (event) => {
    this.setState({
      open: true,
      anchorEl: event.target
    })
  }

  handleLink = (type) => {
    let { push, driver } = this.props
    let extras = {
      driverId: driver.id
    }
    push(`/licences/create?type=${type}&extras=${JSON.stringify(extras)}`)
  }

  render() {
    let { licences, open, anchorEl } = this.state
    let { translate } = this.props
    return licences.length > 0 ? licences.map((licence, idx) => {
      return <Licence key={idx} record={licence} translate={translate} />
    }) : <>
      <Snackbar
        variant="warning"
        message={translate('notification.no_licence')}
        onClick={this.openMenu}
        labelButtonAction={translate('button.create_licence')}
        action={action}
      />
      <ActionMenu
        open={open}
        onClose={this.handleCloseMenu}
        anchorEl={anchorEl}
        handleLink={this.handleLink}
      />
    </>
  }
}

const enhance = compose(
  translate,
  connect(null, { push })
)

export default enhance(LicenceDrivers)