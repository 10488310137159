import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  Edit,
  SimpleForm,
  Create,
  Filter,
  TextInput,
  ReferenceInput,
  SelectInput,
} from 'react-admin'
import ImageBase64 from './ImageBase64'
import FormSave from './FormSave'
import CustomToolbar from '../common/CustomToolbarForm'
import { EditTitle } from '../common/Title'
import { isAdmin } from '../utils/permission'

let Datatype = {
  TEXT: 'TEXT',
  IMAGE: 'IMAGE',
  JSON: 'JSON',
}

let groups = {
  VNPAY: 'VNPAY',
  QR_BANK: 'QR_BANK',
}

const ConfigurationListFilter = props => {
  return <Filter {...props}>
    <TextInput source="code" alwaysOn />
    <ReferenceInput
      source="group"
      reference="configurationgroups"
      alwaysOn
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
}

export const ConfigurationList = ({ permissions, ...props }) => {
  let extras = { resource: 'settings' }
  let admin = isAdmin(permissions)
  return <List
    {...props}
    filters={<ConfigurationListFilter />}
    bulkActionButtons={false}
  >
    <Datagrid {...extras} rowClick={ admin && 'edit' }>
      <TextField source="code" {...extras} />
      <TextField source="group" />
      <TextField source="desc" />
      <FunctionField
        source="value"
        render={record => {
          let { dataType, value: component, group, bValue, code } = record
          if (dataType === Datatype.IMAGE) {
            let size = { width: 100, height: 100 }
            let value = (bValue && bValue !== null) ? bValue : {}
            if (dataType === Datatype.IMAGE) {
              if (group === groups.QR_BANK) {
                size = { ...size, width: 200 }
              }
              component = <ImageBase64
                value={value}
                alt={code}
                label="menu.payment.fields.image"
                {...size}
              />
            }
          } else {
            component = record.value && record.value.length > 50 ? `${record.value.substring(0, 50)}...${record.value[record.value.length - 1]}` : record.value
          }
          return component
        }}
      />
    </Datagrid>
  </List>
}

export const ConfigurationEdit = props => {
  return <Edit
    undoable={false}
    {...props}
    title={<EditTitle
      resource={props.resource}
      render={record => record.code}
    />}
  >
    <SimpleForm toolbar={<CustomToolbar />}>
      <FormSave />
    </SimpleForm>
  </Edit>
}

export const ConfigurationCreate = props => {
  return <Create
    title={<EditTitle
      resource={props.resource}
    />}
    {...props}
  >
    <SimpleForm toolbar={<CustomToolbar />}>
      <FormSave />
    </SimpleForm>
  </Create>
}