
const companyRoles = ['company-manager', 'company-employee', 'accounting', 'finance-viewer', 'mornitoring']

export const isCompany = permissions => {
  if (!permissions) return false
  console.log('==========', permissions)
  for (const role of companyRoles) {
    const allow = permissions[role]
    if (allow) {
      return true
    }
  }
  return false
}

export const isCompanyManager = permissions => {
  return permissions && permissions['company-manager']
}

export const isCompanyEmployee = permissions => {
  return permissions && permissions['company-employee']
}

export const isAgencyManager = permissions => {
  return permissions && permissions['agency-manager']
}

export const isAgencyEmployee = permissions => {
  return permissions && permissions['agency-employee']
}

export const isAgencyRole = permissions => {
  return permissions && (permissions['agency-manager'] || permissions['agency-employee'])
}

export const isCompanyRole = permissions => {
  return permissions && (permissions['company-manager'] || permissions['company-employee'])
}

export const isAdmin = (permissions) => {
  return permissions && permissions['admin']
}

export const hasOneOfPermission = (permissions, ...roles) => {
  if (!permissions) return false
  for (let role of roles) {
    if (permissions[role]) return true
  }
  return false
}
