export const errorMessage = {
  FORBIDDEN: 'error.forbidden',
  AUTHORIZATION_REQUIRED: 'error.authorization_required',
  BAD_REQUEST: 'error.bad_request',
  ERROR_REQUEST_TIMEOUT: 'error.error_request_timeout',
  NOT_FOUND: 'error.not_found',
  NOT_ACCEPTABLE: 'error.not_acceptable',
  LOGIN_FAILED: 'error.login_failed'
}

export const handleError = err => {
  let { body = {} } = err
  let { error = {} } = body
  let locale = localStorage.getItem('locale')
  let { message } = error
  message = error[`message_${locale}`] || message
  error.message = message
  const { status } = err
  if (status === 401 || status === 403) {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('devices')
  }
  return Promise.reject(error)
}
