import React from 'react'
import {
  Datagrid,
  Filter,
  List,
  TextField,
  FunctionField,
  Edit,
  SimpleForm,
  FormDataConsumer,
  ReferenceManyField,
  SimpleShowLayout,
} from 'react-admin'
import {
  Grid,
  ListItemText,
  Chip,
  withStyles,
} from '@material-ui/core'
import moment from 'moment'
import { dateTimeFormat } from '../common/format'
import SearchInput from '../common/SearchInput'
import { formatCurrency, formatPhoneNumber } from '../utils/formatUtil'
import { EditTitle } from '../common/Title'

const styles = {
  textCenter: {
    textAlign: 'center',
  }
}

////////////////////////////////////////////////////////////////////////////////
// List
const CustomerListFilter = (props) => {
  return <Filter {...props}>
    <SearchInput 
      helperText="resources.customers.searchHelperText"
      source="q"
      alwaysOn
    />
  </Filter>
}

const _CustomerList = ({ classes, ...props }) => <List
  {...props}
  bulkActionButtons={false}
  filters={<CustomerListFilter />}
  filter={{
    '../fields': [
      'id',
      'fullName',
      'phone',
      'email',
      'lastCompanyId',
      'lastAgencyId',
      'lastOrderDate',
      'totalPay',
      'orderCount'
    ],
    '../include': [
      {
        relation: 'lastCompany',
        scope: {
          fields: ['id', 'name']
        }
      },
      {
        relation: 'lastAgency',
        scope: {
          fields: ['id', 'name']
        }
      }
    ],
    '../moreInfo': {
      byCompany: 1,
      byAgency: 1
    }
  }}
>
  <Datagrid rowClick="show">
    <FunctionField
      source="fullName"
      render={({fullName, email}) => {
        return <ListItemText
          primary={fullName}
          secondary={email}
        />
      }}
    />
    <FunctionField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      source="phone"
      render={({phone}) => phone && formatPhoneNumber(phone)}
    />
    <TextField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      source="orderCount"
    />
    <FunctionField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      source="totalPay"
      render={({totalPay}) => {
        return formatCurrency(totalPay)
      }}
    />
    <TextField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      source="lastCompany.name"
      sortable={false}
    />
    <TextField
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      source="lastAgency.name"
      sortable={false}
    />
    <FunctionField 
      headerClassName={classes.textCenter}
      cellClassName={classes.textCenter}
      source="lastOrderDate"
      render={({ lastOrderDate }) => moment(lastOrderDate).format(dateTimeFormat) }
    />
  </Datagrid>
</List>

export const CustomerList = withStyles(styles)(_CustomerList)

export const CustomerEdit = ({ resource, id, ...props }) => {
  return <Edit 
    title={<EditTitle
      resource={resource}
      render={item => item.fullName ? item.fullName : item.phone}
    />}
    id={id}
    resource={resource}
    {...props}
  >
    <SimpleForm toolbar={null}>
      <Grid container fullWidth>
        <Grid item xs={12} md={4}>
          <FormDataConsumer>
            {({ formData }) => {
              return <SimpleShowLayout record={formData} resource={resource}>
                <TextField source="fullName" />
                <TextField source="phone" />
                <TextField source="email" />
                <FunctionField 
                  source="totalPay"
                  label="resources.customers.fields.totalPay"
                  render={({ totalPay }) => formatCurrency(totalPay)}
                />
                <FunctionField 
                  source="totalDiscount"
                  label="resources.customers.fields.totalDiscount"
                  render={({ totalDiscount }) => formatCurrency(totalDiscount)}
                />
                <TextField source="orderCount" />
                <TextField source="totalLength" />
              </SimpleShowLayout>

            }}
          </FormDataConsumer>
        </Grid>
        <Grid item xs={12} md={8}>
          <ReferenceManyField
            basePath="/customercompanies"
            reference="customercompanies"
            filter={{ 
              customerId: id,
              '../withTrips': 1,
              '../include': {
                relation: 'reservation',
                scope: {
                }
              }
            }}
            record={{}}
            addLabel={false}
            limit={10}
          >
            <Datagrid>
              <FunctionField 
                source="trips"
                render={record => {
                  return record.trips && record.trips.map((trip, idx) => <Chip
                    //className={classes.routeChip}
                    key={idx}
                    label={`[${moment(trip.departureTime).format('HH:mm DD/MM')}] ${trip.name}`}
                  />
                  )
                }}
              />
              <FunctionField 
                source="reservation.createdAt"
                render={({ reservation = {} }) => moment(reservation.createdAt).format(dateTimeFormat)}  
              />
              <FunctionField source="amount" render={({ amount }) => formatCurrency(amount)} />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
}
