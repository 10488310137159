import React, { Component, Fragment } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { sanitize } from '../utils/commonUtil'
import SwapHorizIcon from '@material-ui/icons/SwapHoriz'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  },
}

class SwapSeatButton extends Component {

  onClick = (evt) => {
    let { selected, seat, reservationId, changeSwapMode } = this.props
    if (selected) {
      evt.preventDefault()
      evt.stopPropagation()
    }
    changeSwapMode(seat, reservationId)
  }

  render() {
    let {
      translate,
      color,
      button,
      onClick,
      classes,
      isSwap,
      ...props
    } = this.props
    let rest = sanitize(props, [ 'changeSwapMode', 'reservationId' ])
    return <Fragment>
      {button 
        ? <Button
          color={color}
          onClick={onClick}
          {...rest}
        >
          <div className={classes.container}>
            <SwapHorizIcon className={classes.icon} />
            <span>{translate('button.swap_seat')}</span>
          </div>
        </Button>
        : <Tooltip
          title={translate('button.swap_seat')}
          enterDelay={100}
        >
          <LinkField
            className={classes.iconButton}
            icon
            onClick={this.onClick}
            style={{ color: isSwap && '#1a237e' }}
            {...rest}
          >
            <SwapHorizIcon />
          </LinkField>
        </Tooltip>
      }
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(SwapSeatButton)
