/* eslint-disable no-console */
import React, { Fragment } from 'react'
import { moment } from '../common/format'
import _ from 'lodash'
import { Provider } from '../provider'
import { red, blue, green, grey, yellow } from '@material-ui/core/colors'
import { viewByModes } from '../common/constants'
import { getColor } from '../utils/color'

const fieldStyles = {
  status: { color: 'purple', backgroundColor: red[500] },
  '10ACT': { color: 'white', backgroundColor: green[500] },
  '20PND': { color: 'white', backgroundColor: yellow[500]},
  '50DEP': { color: 'white', backgroundColor: blue[500] },
  '60ARV': { color: 'white', backgroundColor: grey[500] },
  '00IAT': { color: 'white', backgroundColor: 'rgb(204, 204, 204)' },
  '80CAN': { color: 'white', backgroundColor: red[500] },
  '70COM': { color: 'black', backgroundColor: grey[500] },
  '40ARC': {  }
}

export const statusMapping = {
  '10ACT': 'resources.trips.status.active', 
  '50DEP': 'resources.trips.status.deparated',
  '60ARV': 'resources.trips.status.arrived',
  '00IAT': 'resources.trips.status.inactive',
  '80CAN': 'resources.trips.status.cancelled',
  '70COM': 'resources.trips.status.completed',
  '40ARC': ''
}

export const viewByMapping = {
  [viewByModes.STATUS]: {
    label: 'resources.trips.viewBy.status',
    backgroundColor: (status = 'status') => fieldStyles[status].backgroundColor,
    tooltip: tripByStatuses => <>
      {tripByStatuses.map((tripByStatus, idx) =>
      {
        return <span key={idx}>{tripByStatus.toLowerCase()}<br /></span>
      })}
    </>
  },
  [viewByModes.DRIVER]: {
    label: 'resources.trips.viewBy.driver',
    backgroundColor: (driverId = -1) => getColor(driverId || -1),
    tooltip: tripByDrivers => <>
      {tripByDrivers.map((tripByDriver, idx) =>
      {
        return <span key={idx}>{tripByDriver.toLowerCase()}<br /></span>
      })}
    </>
  }
}

let groupWithoutVehicleIdxMapping = {}

export const assignDriverToTrip = function (trip, driverId, driverIdx) {
  if (driverIdx === 2) {
    return { ...trip, driver2Id: driverId, dirty: driverId !== trip.driver2Id, driverIdx }
  }
  return { ...trip, driverId, dirty: driverId !== trip.driverId, driverIdx }
}

export const assignDriverManyTrips = function (trips, tripIds, tripDriverMapping, driverIdx) {
  let driverField = 'driverId'
  if (driverIdx === 2) {
    driverField = 'driver2Id'
  }
  return trips.map(function (trip) {
    let { id } = trip
    let oldDriver = trip[driverField]
    if (tripIds.indexOf(id) >= 0) {
      let newDriverId = tripDriverMapping[id]
      return { ...trip, [driverField]: newDriverId, dirty: oldDriver !== newDriverId, driverIdx }
    } else {
      return trip
    }
  })
}

export const findVehicleIdFromGroupId = function (groups, dayItems) {
  let groupMapping = _.keyBy(groups, 'id')
  let dayItemIdxVehicleMapping = _.reduce(dayItems, (result, ele) => {
    let { index, group } = ele
    if (groupMapping[group]) {
      let { vehicleId } = groupMapping[group]
      result[index] = vehicleId || group
    }
    return result
  }, {})
  return dayItemIdxVehicleMapping
}

// assign driver to vehicle
export const assignDriverToVehicle = function (trips, vehicleIds, vehicleDriverMapping) {
  return trips.map(function (trip) {
    let { vehicleId, driverId } = trip
    if (vehicleIds.indexOf(vehicleId) >= 0) {
      let newDriverId = vehicleDriverMapping[vehicleId]
      return { ...trip, driverId: newDriverId, dirty: driverId !== newDriverId }
    } else {
      return trip
    }
  })
}

// update assigned driver to server
export const updateTripsDriver = async function (trips, showNotification) {
  let dirtyTrips = _.filter(trips, { dirty: true })
  let updateTrips = dirtyTrips.map(trip => {
    let { driverIdx } = trip
    let fieldDriver = 'driverId'
    if (driverIdx === 2) {
      fieldDriver = 'driver2Id'
    }
    return {
      id: trip.id,
      [fieldDriver]: trip[fieldDriver]
    }
  })
  if (updateTrips.length > 0) {
    let success = false
    try {
      let result = await Provider.dataProvider('REMOTE', 'trips', {
        method: 'assignDriver',
        data: updateTrips,
      })
      if (result && result.data && result.data.success === 1) {
      // clear dirty
        dirtyTrips.forEach(trip => delete trip.dirty)
        success = true
      }
    } catch(e) {
      showNotification('notification.assign_driver_failure', 'warning')
    }
    return success
  } else {
    showNotification('notification.driver_assigned_those_trips')
  }
}

export const tripsToTimelineState = ({ trips, vehicles, assistantDrivers, drivers, groupTitleFunc, version, translate, viewBy = viewByModes.STATUS }) => {
  let items = []
  let groupId = 0
  let groupWithoutVehicleId = 0
  let groups = []
  let groupIds = {}
  Object.values(vehicles).forEach((vehicle, idx) => {
    let { id: vehicleId, plate } = vehicle
    groupIds[vehicleId] = groupId
    groups.push({
      id: groupId++,
      title: groupTitleFunc(vehicleId, plate, idx),
      isSelected: false,
      rightTitle: vehicle.plate,
      vehicleId: vehicle.id,
      idx,
    })
  })
  let days = {}
  let index = 0
  items = trips.map(function (trip) {
    let {
      vehicleId,
      driverId = -1,
      driver2Id = -1,
      assistantDriverId,
      id,
      departureTime,
      arrivalTime,
      status,
      blockIdx,
      scheduleId,
      departure,
      arrival,
    } = trip
    let date = moment(departureTime).startOf('day').toISOString()
    let start_time = moment(departureTime)
    let end_time = moment(arrivalTime)
    let dayItem
    let hasVehicle = true
    if (!vehicleId) {
      vehicleId = `${blockIdx}-${scheduleId}`
      let groupWithoutVehicleIdx = groupWithoutVehicleIdxMapping[vehicleId]
      let groupIdx = _.findIndex(groups, group => {
        if (!group) return -1
        return group.id === vehicleId
      })
      if (groupIdx === -1) {
        let newGroup = {
          id: vehicleId,
          isSelected: false,
        }
        groupIds[vehicleId] = vehicleId
        if (groupWithoutVehicleIdx) {
          newGroup.idx = groupWithoutVehicleIdx
          newGroup.title = `${translate('resources.vehicles.name', { smart_count: 1 })} ${groupWithoutVehicleIdx}`
        } else {
          newGroup.idx = groupWithoutVehicleId
          newGroup.title = `${translate('resources.vehicles.name', { smart_count: 1 })} ${++groupWithoutVehicleId}`
          groupWithoutVehicleIdxMapping[vehicleId] = groupWithoutVehicleId
        }
        groups.push(newGroup)
        hasVehicle = false
      }
    }
    // group by vehicleId & date
    days[vehicleId] = days[vehicleId] || {}
    if (!(date in days[vehicleId])) {
      days[vehicleId][date] = dayItem = {
        id: `${date}-${vehicleId}-${version}`,
        group: groupIds[vehicleId],
        canMove: false,
        canResize: false,
        canSelect: false,
        count: 0,
        isDay: true,
        start_time,
        end_time,
        drivers: {},
        status: {},
        totalTime: 0,
        hasVehicle,
        tripId: id,
        trip,
        tripIds: [id]
      }
    } else {
      dayItem = days[vehicleId][date]
    }
    if (dayItem.start_time > start_time) {
      dayItem.start_time = start_time
    }
    if (dayItem.end_time < end_time) {
      dayItem.end_time = end_time
    }
    dayItem.count++
    if (driverId) {
      dayItem.drivers[driverId] = (dayItem.drivers[driverId] || 0) + 1
    }
    if (status) {
      dayItem.status[status] = (dayItem.status[status] || 0) + 1
    }
    if (id) {
      dayItem.tripIds = [...dayItem.tripIds, id]
    }
    dayItem.totalTime += end_time.diff(start_time, 'minutes')
    return {
      id: trip.id,
      group: groupIds[vehicleId],
      title: `${departure}-${arrival}`,
      canMove: false,
      canResize: false,
      canSelect: false,
      bgColor: viewByMapping[viewBy].backgroundColor(viewBy === viewByModes.STATUS ? status : driverId),
      start_time,
      end_time,
      hasVehicle,
      index: index++,
      tripId: id,
      trip,
      tooltip: (
        <Fragment>
          {departure}<br />
          {arrival}<br />
          {start_time.format('HH:mm')} - {end_time.format('HH:mm')}<br />
          {driverId && (
            <Fragment>
              {translate('resources.drivers.fields.driver_1')}: {drivers[driverId] ? drivers[driverId].fullName : ''}<br />
            </Fragment>
          )}
          {driver2Id && (
            <Fragment>
              {translate('resources.drivers.fields.driver_2')}: {drivers[driver2Id] ? drivers[driver2Id].fullName : ''}<br />
            </Fragment>
          )}
          {assistantDriverId && (
            <Fragment>
              {translate('resources.assistantdrivers.name', { smart_count: 1 })}: {assistantDrivers[assistantDriverId] ? assistantDrivers[assistantDriverId].fullName : ''}<br />
            </Fragment>
          )}
          {translate('resources.trips.fields.status')}: {translate(statusMapping[status])}
        </Fragment>
      )
    }
  })
  let dayItems = []
  Object.values(days).forEach(function (day) {
    dayItems = dayItems.concat(Object.values(day))
  })
  // add information for day items
  dayItems.forEach(function (dayItem, index) {
    let statuses = Object.keys(dayItem.status)
    let _drivers = Object.keys(dayItem.drivers)
    dayItem.driverCount = _drivers.length
    // dayItem.title = `${dayItem.driverCount}/${dayItem.count}`
    dayItem.index = index
    let linears = []
    let tripByStatuses = []
    let numberTripByStatuses = []
    let tripByDrivers = []
    let numberTripByDrivers = []
    if (statuses && statuses.length > 0) {
      for( let i = 0; i < statuses.length; i++) {
        let status = statuses[i]
        let numberTripByStatus = dayItem.status[status]
        numberTripByStatuses.push({
          status,
          amount: numberTripByStatus,
        })
        if (numberTripByStatus > 0) {
          tripByStatuses.push(`${numberTripByStatus} ${translate('resources.trips.name', { smart_count: 1 })} ${translate(statusMapping[status])}`)
        }
      }
    }
    if (_drivers && _drivers.length > 0) {
      for( let i = 0; i < _drivers.length; i++) {
        let driver = _drivers[i]
        let numberTripByDriver = dayItem.drivers[driver]
        numberTripByDrivers.push({
          driver,
          amount: numberTripByDriver,
        })
        if (numberTripByDriver > 0) {
          tripByDrivers.push(`${numberTripByDriver} ${translate('resources.trips.name', { smart_count: 1 })} ${translate('resources.drivers.fields.byDriver')} ${drivers[driver] ? drivers[driver].fullName : '' }`)
        }
      }
    }
    if (linears.length === 1) {
      dayItem.bgColor = linears[0]
    } else {
      // let linearStr = linears.join(',')
      // dayItem.bgColor = `linear-gradient(to right, ${linearStr})`
      // dayItem.bgColor = `linear-gradient(to right, ${linearStr})`
      let statusObj = _.maxBy(numberTripByStatuses, 'amount') || {}
      let driverObj = _.maxBy(numberTripByDrivers, 'amount') || {}
      dayItem.bgColor = viewByMapping[viewBy].backgroundColor(viewBy === viewByModes.STATUS ? statusObj.status : driverObj.driver)
    }
    dayItem.tooltip = viewByMapping[viewBy].tooltip(viewBy === viewByModes.STATUS ? tripByStatuses : tripByDrivers)
  })
  groups = _.orderBy(groups, 'vehicleId', 'asc')
  return { groups, items, dayItems }
}
