import vietnamese from 'ra-language-vietnamese'

export default {
  ...vietnamese,
  'ra.page.list': '%{name}',
  button: {
    reset: 'Khôi phục',
    remove: 'Xóa',
    refresh: 'Lấy mã mới',
    pairDevice: 'Ghép đôi thiết bị',
    create_maintenance: 'Tạo lịch bảo trì',
    create_trip: 'Tạo chuyến đi',
    template: 'Tệp mẫu',
    view_by_time: 'Xem theo thời gian',
    view_by_route: 'Xem theo tuyến',
    ok: 'OK',
    cancel: 'Hủy',
    save: 'Lưu',
    search: 'Tìm kiếm',
    validate: 'Validate',
    import: 'Nhập',
    close: 'Đóng',
    assign_vehicle: 'Gán xe',
    clear_all_stops: 'Tách tất cả điểm dừng',
    create_stop: 'Tạo điểm dừng',
    save_route: 'Lưu tuyến đường',
    smart_save_route: 'Tạo nhanh tuyến đường',
    set_as_end: 'Thiết lập điểm cuối',
    set_as_start: 'Thiết lập điểm đầu',
    attach: 'Gán điểm dừng vào lộ trình',
    detach_stop: 'Bỏ điểm dừng khỏi lộ trình',
    edit_stop: 'Cập nhật điểm dừng',
    remove_stop: 'Xóa điểm dừng',
    assign_driver: 'Gán tài xế',
    assign_assistant_driver: 'Gán phụ xe',
    back: 'Trở về',
    next: 'Tiếp tục',
    finish: 'Hoàn thành',
    add: 'Thêm',
    create_vehicle_type: 'Tạo loại xe',
    create_licence: 'Tạo giấy tờ',
    add_vehicle: 'Thêm xe',
    add_driver: 'Thêm tài xế',
    assign: 'Gán',
    unassign: 'Gỡ',
    create_service_task: 'Tạo dịch vụ',
    swap_stop: 'Đổi chiều',
    create: 'Tạo mới',
    calendar: 'Lịch',
    list: 'Danh sách',
    make_done: 'Hoàn tất',
    restore: 'Bỏ đánh dấu hoàn tất',
    renewal: 'Gia hạn',
    view_detail: 'Xem chi tiết',
    reservation_extra: 'Đặt thêm chỗ',
    reset_password: 'Đặt lại mật khẩu',
    change_password: 'Cập nhật mật khẩu',
    assign_role: 'Gán quyền',
    register_company: 'Đăng kí công ty',
    unregister_company: 'Hủy đăng ký',
    edit: 'Sửa',
    register_fare: 'Đăng kí giá',
    unregister_fare: 'Hủy đăng ký giá',
    unregister_route: 'Hủy đăng ký',
    unregister_agency: 'Hủy đăng ký đại lý',
    config_sale_time: 'Cài đặt thời gian mở bán',
    add_agency: 'Thêm đại lý',
    add_setting: 'Thêm cài đặt',
    download_file: 'Tải bản mẫu lịch trình',
    assign_fare: 'Gán giá',
    add_finance_account: 'Thêm tài khoản tài chính',
    add_reference: 'Thêm công ty / đại lý',
    add_supplier: 'Thêm nhà cung cấp',
    download_ticket: 'Tải vé',
    download_reservations: 'Tải',
    download_confirmed_reservations: 'Tải mã thành công',
    paid: 'Thanh toán',
    cancel_reservation: 'Hủy tất cả',
    cancel_reservation_2: 'Hủy đặt chỗ',
    cancel_charge: 'Hủy ghế đã chọn',
    choose: 'Chọn',
    setup_passenger_collection: 'Cài đặt điểm đón / điểm trả',
    unassign_driver: 'Gỡ tài xế',
    unassign_vehicle: 'Gỡ xe',
    unassign_assistant_driver: 'Gỡ phụ xe',
    setup_driver: 'Thiết lập tài xế',
    setup_vehicle: 'Thiết lập xe',
    setup_assistant_driver: 'Thiết lập phụ xe',
    edit_booking_information: 'Sửa thông tin đặt chỗ',
    archive: 'Lưu trữ',
    help: 'Giúp đỡ',
    notification: 'Thông báo',
    topUp: 'Nạp tiền',
    withdraw: 'Rút tiền',
    create_reconciliation: 'Tạo phiếu đối soát',
    export: 'Xuất',
    payment_reconciliation: 'Ghi nhận thanh toán',
    remote_print: 'In vé từ xa',
    swap_seat: 'Đổi chỗ',
    swap_trip: 'Đổi chuyến',
    remote_payment_qr: 'Thanh toán QR từ xa',
    paid_from_customer: 'Thanh toán trực tuyến',
    paid_from_agency: 'Đại lý thanh toán',
    pay_later: 'Trả sau',
    pay: 'Thanh toán',
    pay_cash: 'Tiền mặt',
    add_new_fare: 'Thêm giá mới',
    register_agency: 'Đăng ký đại lý',
    continue: 'Tiếp tục',
    linkToTrip: 'Tới chuyến đi',
    print_seat_map: 'In phơi',
    add_child_product: 'Thêm sản phẩm đi kèm',
    remove_child_product: 'Gỡ sản phẩm đi kèm',
    setting: 'Cài đặt',
    addEventTypes: 'Thêm sự kiện',
    addUserContacts: 'Thêm thông tin đăng ký',
    register_member_card: 'Đăng ký vé tháng',
    recharge_member_card: {
      pay_cash: 'Thanh toán tiền mặt',
      pay_online: 'Thanh toán online',
    },
    designTicket: 'Thiết kế mẫu vé',
    print: 'In thẻ',
    issue_invoice: 'Xuất hóa đơn',
    einvoice_sumary: 'Xuất báo cáo tổng hợp',
  },
  notification: {
    updated: 'Cập nhật thành công',
    vehicle_plate_invalid: 'Dữ liệu không hợp lệ. Vui lòng điền biển số xe.',
    qrCodeHasExpired: 'Mã đã hết hạn!',
    save_seatmaps_sucess: 'Lưu sơ đồ ghế thành công!',
    save_seatmaps_fail: 'Lưu sơ đồ ghế thất bại!',
    assign_role_success: 'Gán quyền thành công',
    assign_role_failure: 'Gán quyền không thành công',
    unassign_role_success: 'Gỡ quyền thành công',
    unassign_role_failure: 'Gỡ quyền không thành công',
    reset_password_success: 'Đặt lại mật khẩu thành công',
    save_seatmaps_warning: 'Sơ đồ ghế đang trống, bạn vẫn muốn lưu lại?',
    reset_password_failure: 'Đặt lại mật khẩu không thành công',
    change_password_success: 'Cập nhật mật khẩu thành công',
    change_password_failure: 'Cập nhật mật khẩu không thành công',
    create_user_success: 'Tạo người dùng thành công',
    create_user_failure: 'Tạo người dùng không thành công',
    update_user_success: 'Cập nhật người dùng thành công',
    update_user_failure: 'Cập nhật người dùng không thành công',
    create_vehicle_success: '%{count} xe đã được thêm vào hệ thống',
    create_vehicle_failure: 'Thêm xe không thành công',
    vehicle_type_not_exists:
      'Hệ thống chưa có loại xe. Hãy nhấn vào để tạo loại xe',
    error_vehicle_exists:
      'Thêm xe không thành công - Có %{count} xe đã tồn tại',
    seat_map_not_exists: 'Chưa thêm sơ đồ xe vào hệ thống',
    import_trip_success: 'Đã import %{amount} chuyến đi',
    import_trip_exists: 'Những chuyến đi này đã tồn tại trong hệ thống',
    import_trip_failure: 'Import không thành công',
    delete_trip_success: 'Chuyến đi đã được xóa thành công',
    delete_trip_failure: 'Chuyến đi đã được xóa không thành công',
    assigned_vehicle_success: 'Gán xe thành công',
    assigned_vehicle_failure: 'Gán xe không thành công',
    unassigned_vehicle_success: 'Gỡ xe thành công',
    unassigned_vehicle_failure: 'Gỡ xe không thành công',
    unassigned_driver_success: 'Gỡ tài xế thành công',
    unassigned_driver_failure: 'Gỡ tài xế không thành công',
    clear_all_stop: 'Bạn có muốn xóa tất cả điểm dừng?',
    clear_all_stop_success: 'Xóa tất cả điểm dừng thành công',
    save_route: 'Bạn có muốn lưu tuyến?',
    smart_save_route: 'Bạn có muốn áp dụng thông tin của chiều ngược lại không',
    save_route_success: 'Lưu tuyến thành công',
    set_end_point: 'Bạn có muốn đổi điểm này thành điểm kết thúc?',
    set_start_point: 'Bạn có muốn đổi điểm này thành điểm bắt đầu?',
    set_end_point_success: 'Thiết lập điểm kết thúc thành công',
    set_start_point_success: 'Thiết lập điểm bắt đầu thành công',
    create_stop_success: 'Tạo điểm dừng thành công',
    create_stop_fail: 'Tạo điểm dừng không thành công',
    update_stop_success: 'Cập nhật điểm dừng thành công',
    update_stop_fail: 'Cập nhật điểm dừng không thành công',
    remove_stop: 'Bạn có muốn xóa điểm dừng không?',
    remove_stop_success: 'Xóa điểm dừng thành công',
    remove_stop_failure: 'Xóa điểm dừng không thành công',
    no_route: 'Điểm dừng này không thể đi qua',
    delete_all_stops:
      'Tất cả điểm dừng sẽ bị xóa khỏi tuyến đường khi quá trình diễn ra thành công',
    unassigned_vehicle_to_trip: 'Chưa gán xe cho chuyến đi',
    info_vehicle_not_exsist: 'Chưa thêm thông tin xe',
    select_vehicle_type_uncompleted:
      'Loại xe chưa được chọn. Quay lại bước 1 để chọn loại xe',
    add_info_uncompleted:
      'Chưa thêm thông tin xe. Quay lại bước 2 để thêm thông tin xe',
    reservation: {
      success: 'Đặt chỗ thành công!',
      error: 'Đặt chỗ không thành công!',
      missingTrip: 'Vui lòng chọn một chuyến đi hợp lệ!',
      missingPassenger:
        'Vui lòng chọn một chỗ ngồi và điền thông tin hành khách!',
      missingCharge: 'Thông tin ghế ngồi không hợp lệ!',
      missingPayment: 'Vui lòng chọn một phương thức thanh toán hợp lệ!',
      missingContact: 'Vui lòng nhập thông tin liên hệ!',
      missingPhone: 'Vui lòng nhập thông tin số điện thoại!',
      missingName: 'Vui lòng nhập thông tin Họ và Tên!',
      missingTransactionInfo: 'Vui lòng nhập đầy đủ thông tin thanh toán',
      cancelSuccess: 'Huỷ thành công',
      cancelFail: 'Huỷ thất bại',
      updateBookingInfoSuccess: 'Sửa thông tin đặt chỗ thành công',
      updateBookingInfoFail: 'Sửa thông tin đặt chỗ không thành công',
    },
    create_trip_success: 'Tạo chuyến đi thành công',
    create_trip_failure: 'Tạo chuyến đi không thành công',
    assign_success: 'Gắn thành công',
    assign_failure: 'Gắn không thành công',
    unassign_success: 'Gỡ thành công',
    unassign_failure: 'Gỡ không thành công',
    save_service_task_success: 'Lưu dịch vụ thành công',
    save_service_task_failure: 'Lưu dịch vụ không thanh công',
    save_service_reminder_success: 'Lưu dịch vụ thành công',
    save_service_reminder_failure: 'Lưu dịch vụ không thanh công',
    licence_unassign: 'Giấy tờ chưa được gán',
    licence_expired: 'Giấy tờ này đã hết hạn',
    days_expired: 'Còn %{days} ngày nữa sẽ hết hạn',
    expired: 'Đã thực hiện %{days} ngày trước',
    due_at: 'Đang thực hiện',
    before_expired: 'Đã thực hiện %{days} trước',
    after_expired: 'Còn %{days} ngày nữa sẽ thực hiện',
    swap_dimension_route_success: 'Đổi chiều thành công',
    swap_dimension_route_failure: 'Đổi chiều không thành công',
    navigate_when_not_save_route:
      'Tuyến đường vẫn chưa lưu, Bạn có muốn chuyến đến trang khác không ?',
    reload_when_not_save_route:
      'Tuyến đường vẫn chưa lưu, Bạn có muốn làm mới lại trang không ?',
    prevent_notification: 'Chặn thông báo này',
    delete_reminder_success: 'Xóa lời nhắc thành công',
    delete_reminder_failure: 'Xóa lời nhắc không thành công',
    make_done_reminder_success: 'Lời nhắc được đánh dấu hoàn tất',
    make_done_reminder_fail: 'Đánh dấu hoàn tất cho lời nhắc không thành công',
    make_restore_success: 'Bỏ đánh dấu hoàn tất thành công',
    make_restore_fail: 'Bỏ đánh dấu hoàn tất không thành công',
    to: 'đến',
    renewal_success: 'Gia hạn thành công',
    renewal_failure: 'Gia hạn không thành công',
    no_path: 'Đường đi không tồn tại',
    no_licence: 'Chưa có giấy tờ',
    assign_driver_success: 'Gán tài xế thành công',
    assign_driver_failure: 'Gán tài xế không thành công',
    sent_notification_success: 'Gửi thông báo thành công',
    sent_notification_failure: 'Gửi thông báo không thành công',
    register_company_success: 'Đăng kí công ty thành công',
    register_company_failure: 'Đăng kí công ty không thành công',
    empty_trip: 'Chưa có chuyến đi. Hãy tạo chuyến đi cho ngày này',
    register_fare_success: 'Đăng kí giá thành công',
    register_fare_failure: 'Đăng kí giá không thành công',
    unregister_fare_success: 'Hủy đăng kí giá thành công',
    unregister_fare_failure: 'Hủy đăng kí giá không thành công',
    edit_product_route_success: 'Cập nhật đăng kí giá thành công',
    edit_product_route_failure: 'Cập nhật đăng kí giá không thành công',
    warning_unregister_fare: 'Bạn có muốn hủy đăng kí không ?',
    warning_change_numOfColRow_seatmaps: 'Thay đổi sẽ xóa sơ đồ ghế hiện tại!',
    update_route_success: 'Cập nhật tuyến thành công',
    update_route_fail: 'Cập nhật tuyến không thành công',
    create_route_success: 'Tạo tuyến thành công',
    create_route_fail: 'Tạo tuyến không thành công',
    unassign_fare_to_trip:
      'Chuyến đi chưa được gán giá, Hãy nhấn vào nút "Gán giá" để gán giá cho chuyến đi',
    no_reference: 'Chưa được gán cho Công ty / Đại lý',
    no_reference_user: 'Chưa được gán cho Công ty / Đại lý',
    add_supplier: 'Gắn người dùng với nhà cung cấp',
    add_principal_success_user: 'Gán cho Công ty / Đại lý thành công',
    remove_principal_success_user: 'Gỡ cho Công ty / Đại lý thành công',
    add_principal_fail_user: 'Gán cho Công ty / Đại lý không thành công',
    add_principal_success: 'Gán cho Công ty / Đại lý  thành công',
    remove_principal_success: 'Gỡ cho Công ty / Đại lý thành công',
    add_principal_fail: 'Gán cho Công ty / Đại lý không thành công',
    way_to_create_schedule: 'Hãy vào Tuyến để tạo lịch trình',
    title_unassign_company: 'Hủy đăng kí công ty',
    confirm_unassign_company: 'Bạn có muốn hủy đăng kí công ty này không ?',
    title_unassign_agency: 'Hủy đăng kí đại lý',
    confirm_unassign_agency: 'Bạn có muốn hủy đăng kí đại lý này không ?',
    unassign_agency_success: 'Hủy đăng kí đại lý thành công',
    unassign_agency_failure: 'Hủy đăng kí đại lý không thành công',
    unassign_company_success: 'Hủy đăng kí công ty thành công',
    unassign_company_failure: 'Hủy đăng kí công ty không thành công',
    confirm_unassign_route: 'Bạn có muốn gỡ chuyến đi này không ?',
    confirm_unassign_vehicle_driver: 'Bạn có muốn gỡ xe / tài xế không ?',
    update_company_success: 'Cập nhật công ty thành công',
    update_company_failure: 'Cập nhật công ty không thành công',
    update_agency_success: 'Cập nhật đại lý thành công',
    update_agency_failure: 'Cập nhật đại lý không thành công',
    set_up_sale_time_success: 'Cài đặt thời gian mở bán thành công',
    set_up_sale_time_failure: 'Cài đặt thời gian mở bán không thành công',
    setup_passenger_collection_success:
      'Thiết lập điểm đón / điểm trả thành công',
    setup_passenger_collection_fail:
      'Thiết lập điểm đón / điểm trả không thành công',
    pickup_point_empty:
      'Điểm đón chưa có. Hãy đến Thiết Kế Tuyến Đường để thiết lập',
    drop_off_point_empty:
      'Điểm trả chưa có. Hãy đến Thiết Kế Tuyến Đường để thiết lập',
    warning_unassign_vehicle:
      'Bạn có muốn gỡ xe ra khỏi những chuyến đi đã chọn ?',
    assign_assistant_driver_success: 'Gán phụ xe thành công',
    assign_assistant_driver_failure: 'Gán phụ xe không thành công',
    unassign_assistant_driver_success: 'Gỡ phụ xe thành công',
    unassign_assistant_driver_failure: 'Gỡ phụ xe không thành công',
    warning_unassign_assistant_driver:
      'Bạn có gỡ phụ xe cho các chuyến đi này không ?',
    archive_routegroup_success: 'Lữu trữ tuyến đường thành công',
    archive_routegroup_failure: 'Lữu trữ tuyến đường không thành công',
    import_schedule_success: 'Nhập lịch trình thành công',
    topUp_success: 'Nạp tiền thành công',
    topUp_failure: 'Nạp tiền không thành công',
    withdraw_success: 'Rút tiền thành công',
    withdraw_failure: 'Rút tiền không thành công',
    create_reconciliation_success: 'Tạo phiếu đối soát thành công',
    create_reconciliation_failure: 'Tạo phiếu đối soát không thành công',
    update_reconciliation_success: 'Cập nhật phiếu đối soát thành công',
    update_reconciliation_failure: 'Cập nhật phiếu đối soát không thành công',
    payment_reconciliation_success: 'Ghi nhận thanh toán thành công',
    payment_reconciliation_failure: 'Ghi nhận thanh toán không thành công',
    swap_seat_success: 'Đổi chỗ thành công',
    sale_ticket_success: 'Bán vé thành công',
    change_commission_success: 'Cập nhật hoa hồng thành công',
    change_commission_failure: 'Cập nhật hoa hồng không thành công',
    empty_route_tour: 'Chưa có tuyến đường phục vụ cho việc bán vé',
    empty_product: 'Chưa có danh sách vé cho chuyến đi này',
    warning_no_paired_device: 'Chưa ghép đôi thiết bị',
    warning_no_agency_paired_device:
      'Hãy quét mã QR bên dưới để tiến hành ghép đôi thiết bị.',
    remote_print_confirm: 'Xác nhận in vé từ xa',
    have_paired_device:
      'Đã ghép đôi thiết bị. Bạn có muốn tiếp tục in vé từ xa?',
    change_quantity_success: 'Cập nhật số lượng sản phẩm thành công',
    change_quantity_failure: 'Cập nhật số lượng sản phẩm không thành công',
    unlimit_quantity: 'Nếu bỏ trống giá trị sẽ là không giới hạn',
    no_new_platform_debit_transaction: 'Không có giao dịch mới để tạo đối soát',
    driver_assigned_those_trips:
      'Tài xế này đã được phân công cho những chuyến đi đó',
    name: 'Thông báo',
    warning_no_selected_seat:
      'Chưa chọn ghế muốn đổi. Hãy chọn ở bên sơ đồ ghế bên trái',
    change_product_fare_code_success: 'Cập nhật mã thành công',
    change_product_fare_description_success:
      'Cập nhật mô tả sản phẩm thành công',
    change_product_fare_code_failure: 'Cập nhật mã không thành công',
    change_product_fare_description_failure:
      'Cập nhật mô tả sản phẩm không thành công',
    update_template_success: 'Cập nhật biểu mẫu thành công',
    update_template_failure: 'Cập nhật biểu mẫu không thành công',
    create_product_success: 'Tạo sản phẩm thành công',
    update_product_success: 'Cập nhật sản phẩm thành công',
    add_child_product_success: 'Thêm sản phẩm thành công',
    remove_child_product_success: 'Xóa sản phẩm thành công',
    change_fare_success: 'Cập nhật giá thành công',
    change_fare_failure: 'Cập nhật giá không thành công',
    register_agency_success: 'Đăng ký đại lý thành công',
    register_agency_failure: 'Đăng ký đại lý không thành công',
    assign_tag_success: 'Gán thẻ thành công',
    unassign_tag_success: 'Gỡ thẻ thành công',
    set_default_layout_success: 'Cài đặt mẫu vé mặc định thành công',
    delete_ticket_layout_success: 'Xóa mẫu vé thành công',
    update_customer_info_success: 'Cập nhật thông tin khách hàng thành công',
    create_fare_companion_success: 'Tạo bảng biểu mẫu vé thành công',
    create_fare_companion_failure: 'Tạo bảng biểu mẫu vé không thành công',
    update_fare_companion_success: 'Cập nhật bảng biểu mẫu vé thành công',
    update_fare_companion_failure: 'Cập nhật bảng biểu mẫu vé không thành công',
    update_receive_place_success: 'Cập nhật địa điểm nhận thẻ thành công',
    create_receive_place_success: 'Tạo mới địa điểm nhận thẻ thành công',
    register_member_card_success: 'Đăng ký phôi vé thành công',
    recharge_member_card_success: 'Nạp tiền thành công',
    create_cart_order: 'Xác nhận tạo mới đơn hàng',
    change_segment_confirm:
      'Hệ thống chỉ hỗ trợ mua vé của 1 chặng duy nhất. Nếu chọn mua vé của một chặng khác, các vé trong đơn hàng hiện tại sẽ xóa và thêm vé của chặng mới được chọn.',
    send_issue_einvoice_request_success: 'Gửi yêu cầu xuất hóa đơn thành công',
    cancel_charge_fail:
      'Hủy vé không thành công. Vui lòng tải lại trang để cập nhật trạng thái mới nhất',
  },
  error_messages: {
    required: {
      name: 'Hãy nhập tên và xóa khoảng cách trắng không hợp lệ',
      campaigns: {
        description: 'Hãy nhập mô tả và xóa khoảng cách trắng không hợp lệ',
      },
      drivers: {
        fullname: 'Hãy nhập tên tài xế và xóa khoảng cách trắng không hợp lệ',
      },
      assistant_drivers: {
        fullname: 'Hãy nhập tên phụ xe và xóa khoảng cách trắng không hợp lệ',
      },
      stop: {
        name: 'Hãy nhập tên điểm dừng',
        code: 'Hãy nhập mã điểm dừng',
        code_unique: 'Mã điểm dừng %{code} đã tồn tại',
      },
      suppliers: {
        name: 'Hãy nhập tên nhà cung cấp',
      },
      vehicles: {
        plate: 'Hãy nhập biển số và xóa khoảng cách trắng không hợp lệ',
      },
      commission: {
        value: 'Bắt buộc',
      },
      tags: {
        name: 'Hãy nhập tên tag',
        space: 'Tên tag không hợp lệ',
      },
      userContacts: {
        messageTypeId: 'Hãy chọn phương thức nhắn tin',
        recipient: 'Hãy nhập thông tin của phương thức nhắn tin',
        userSubscriptions: 'Hãy chọn các sự kiện bạn muốn đăng ký',
      },
      fares: {
        name: 'Hãy nhập tên loại giá vé',
      },
      routes: {
        number: 'Hãy nhập mã số và xóa khoảng cách trắng không hợp lệ',
        name: 'Hãy nhập tên tuyến đường và xóa khoảng cách trắng không hợp lệ',
      },
      licences: {
        number: 'Hãy nhập số quản lý',
      },
      viewtrip: {
        min_length_name: 'Phải ít nhất %{min} ký tự',
        max_length_name: 'Phải nhiều nhất %{max} ký tự',
      },
      member_cards: {
        code: 'Hãy nhập số thẻ và xóa khoảng cách trắng không hợp lệ',
        segmentId: 'Bắt buộc',
      },
      receive_places: {
        name: 'Hãy nhập tên địa điểm và xóa khoảng cách trắng không hợp lệ',
      },
    },
    invalid: {
      drivers: {
        phone: 'Số điện thoại không hợp lệ',
        email: 'Email phải là abc@domail.com',
        validFrom: 'Ngày hiệu lực từ phải trước ngày hiệu lực đến',
        validTo: 'Ngày hiệu đên từ phải sau ngày hiệu lực từ',
      },
      assistant_drivers: {
        phone: 'Số điện thoại không hợp lệ',
        email: 'Email phải là abc@domail.com',
      },
      services: {
        startDate: 'Ngày bắt đầu phải trước ngày kết thúc',
        endDate: 'Ngày kết thúc phải sau ngày bắt đầu',
      },
      viewtrips: {
        departureTime_gte: 'Ngày đến phải sau ngày bắt đầu',
        departureTime_lte: 'Ngày bắt đầu phải trước ngày đến',
      },
      users: {
        confirm_password: 'Mật khẩu không khớp',
        phone: 'Số điện thoại không hợp lệ',
      },
      payment: {
        paid_must_be_more_than_zero: 'Số tiền thanh toán phải lớn hơn 0đ',
        paid_must_be_less_than_total:
          'Số tiền thanh toán không được vượt quá %{debt}',
      },
      campaign: {
        discount_must_be_more_than_zero:
          'Số tiền hoặc số phần trăm giảm phải lơn hơn 0',
        discount_must_be_less_than_100: 'Số phần trăm giảm phải nhỏ hơn 100%',
        discount_must_be_less_than_budget: 'Số tiền giảm phải nhỏ hơn kinh phí',
        budget_must_be_more_than_discount: 'Kinh phí phải lớn hơn số tiền giảm',
        sum_of_three_part_must_be_100_percent: 'Tổng 3 phần chi phải là 100%',
        number_percent_must_be_between_0_an_100:
          'Sô phần trăm phải nằm trong khoảng 0% - 100%',
      },
      commission: {
        invalid_percent_value:
          'Phần trăm hoa hồng phải nằm trong khoảng 0 - 100%',
        invalid_value: 'Giá trị hoa hồng không được nhỏ hơn 0',
        wrong_format_and_nagetive:
          'Hoa hồng có dạng 1000 hoặc 10%. Giá trị phải lớn hơn 0 và không vượt quá 100 đối với giá trị là phần trăm',
      },
      suppliers: {
        phone: 'Số điện thoại không hợp lệ',
        email: 'Email phải là abc@domail.com',
      },
      product_fare_code: 'Mã sản phẩm ít nhất 6 kí tự',
      amount_child_fare:
        'Giá không hợp lệ. Giá sản phẩm không vượt quá %{maxAmount} |||| Tổng giá của những sản phẩm con khác đang bằng với giá sản phẩm cha',
    },
    codes: {
      member_cards: {
        error_invalid_code_member_card: 'Không tìm thấy số thẻ!',
        error_member_card_is_existed: 'Phôi vé đã tồn tại!',
        error_invalid_month: 'Tháng nạp tiền không hợp lệ!',
        error_month_is_recharged:
          'Tháng đã được nạp tiền. Vui lòng chọn tháng khác!',
      },
      receive_places: {
        error_invalid_stop: '',
      },
    },
  },
  resources: {
    profile: 'Hồ sơ',
    company: 'Công ty',
    dashboard: {
      resource_use: 'Sử dụng',
      on_board: 'Xe đang chạy',
      used: 'Sử dụng',
      un_used: 'Chưa sử dụng',
      vehicle: 'Xe',
      driver: 'Tài xế',
      trips: 'Chuyến đi',
      used_vehicle: 'Xe được dùng',
      total_distance: 'Quãng đường (km)',
      reservation: 'Đặt chỗ',
      total_seat: 'Tổng số ghế',
      total_reservation: 'Tổng số đặt chỗ',
      total_fee: 'Tổng tiền',
      revenue: 'Doanh thu',
      view_by: 'Xem theo',
      vehicle_chart: 'Đồ thị xe',
      sell_ticket_chart: 'Đồ thị số lượng vé bán',
      number_of_vehicle: 'Số lượng xe',
      cost: 'Chi phí (triệu đồng)',
      distance: 'Quãng đường (km)',
      time: 'Thời gian (phút)',
      number_of_ticket: 'Số lượng vé',
      revenue_axsis: 'Doanh thu (triệu đồng)',
      day: 'Ngày',
      week: 'Tuần',
      month: 'Tháng',
      year: 'Năm',
      quarter: 'Quý',
      hour: 'Giờ',
      money: 'Tiền (triệu đồng)',
      date: 'Thời gian',
      fromDate: 'Từ ngày',
      toDate: 'Đến ngày',
      granularity: 'Thống kê theo',
    },
    charts: {
      extra_vehicle: 'Xe tăng cường',
      vehicle: 'Xe',
      ticket: 'Vé thường',
      student_ticket: 'Vé sinh viên',
      profit: 'Lợi nhuận',
      revenue: 'Doanh thu',
      cost_vehicle: 'Chi phí xe',
    },
    common: {
      month: 'Tháng',
      saved: 'Đã lưu!',
      position: 'Vị trí',
      sort: 'Sắp xếp',
      empty: 'Không có',
      app: 'Ứng dụng',
      action: 'Hành động',
      titleDevicesTable: 'Thiết bị đã ghép đôi',
      manipulation: 'Thao tác',
      seconds: 'giây',
      timeLeft: 'Thời gian còn lại',
      seeAll: 'Xem tất cả các thông báo',
      markAll: 'Đánh dấu tất cả đã đọc',
      noNotification: 'Chưa có thông báo',
      no: 'STT',
      id: 'ID',
      price_table: 'Bảng giá',
      departure_date: 'Ngày khởi hành',
      departure_time: 'Giờ khởi hành',
      departure: 'Điểm khởi hành',
      arrival_date: 'Ngày đến',
      arrival_time: 'Giờ đến',
      arrival: 'Điểm đến',
      name: 'Tên',
      long_name: 'Tên đầy đủ',
      full_name: 'Tên đầy đủ',
      address: 'Địa chỉ',
      desc: 'Mô tả',
      type: 'Loại',
      status: 'Trạng thái',
      edit: 'Chỉnh sửa',
      search: 'Tìm kiếm',
      title_form_info: 'Thông tin',
      title_maintain_calendar: 'Lịch bảo dưỡng',
      title_activity: 'Hoạt động',
      title_trips: 'Chuyến đi',
      title_service_task: 'Danh sách các dịch vụ',
      zone: 'Khu vực',
      city: 'Thành phố',
      province: 'Tỉnh/Thành',
      country: 'Quốc gia',
      summary: 'Thông tin chung',
      code: 'Mã',
      date: 'Ngày',
      time: 'Giờ',
      seat: 'Ghế',
      warning: 'Thông báo',
      message_warning_delete: 'Bạn có muốn số chuyến đi %{time} không ?',
      message_warning_assign_vehicle: 'Bạn có muốn gán xe tự động ?',
      block_number: '#Block',
      unassigned_vehicle: 'Chưa gán xe',
      assigned_vehicle: 'Đã gán xe',
      assigned_vehicle_label: 'Gán xe',
      start_date: 'Ngày bắt đầu',
      end_date: 'Ngày kết thúc',
      location: 'Vị trí',
      drop_file_here: 'Thả tệp để nhập dữ liệu',
      delimiter: 'delimiter',
      quoting: 'quoting',
      length: 'Quãng đường',
      stops: 'Điểm dừng',
      enter_address: 'Nhập địa chỉ',
      confirm: 'Xác nhận',
      required: 'Bạn phải nhập thông tin này',
      create: 'Thêm mới',
      select_vehicle_type: 'Chọn loại xe',
      add_info_vehicle: 'Thêm thông tin xe',
      review_info_vehicle: 'Kiểm tra thông tin',
      renewal_info: 'Thông tin gia hạn',
      periodTime: {
        byWeek: 'Theo tuần',
        byMonth: 'Theo tháng',
        byQuarter: 'Theo quý',
      },
      weekValue: 'Tuần %{value}',
      monthValue: 'Tháng %{value}',
      quarterValue: 'Quý %{value}',
      daysOfWeek: {
        monday: 'Thứ 2',
        tuesday: 'Thứ 3',
        wednesday: 'Thứ 4',
        thursday: 'Thứ 5',
        friday: 'Thứ 6',
        saturday: 'Thứ 7',
        sunday: 'Chủ nhật',
      },
      applyForOptions: {
        buy_time: 'Thời gian mua vé',
        departure_time: 'Thời gian khởi hành',
      },
      paidAt: 'vào ngày',
      no_record: 'Không tìm thấy dữ liệu',
      no_reservation:
        'Không tìm thấy thông tin đặt chỗ từ số điện thoại / mã đặt chỗ',
      search_reservation_placeholder: 'Số điện thoại / mã đặt chỗ',
      new_fare_more_than_old_fare: 'Tổng tiền vé mới cao hơn',
      new_fare_less_than_old_fare: 'Tổng tiền vé mới thấp hơn',
      want_to_continue: 'Bạn có muốn tiếp tục?',
      old_seatmap: 'Sơ đồ ghế cũ',
      new_seatmap: 'Sơ đồ ghế mới',
      cancel_reason: 'Lý do hủy đặt chỗ',
      confirm_cancel_reservation_title: 'Xác nhận hủy đặt chỗ',
      confirm_cancel_charge_title: 'Xác nhận hủy vé với định danh %{chargeId}',
      confirm_cancel_charge_content:
        'Bạn đang thực hiện hủy vé. Bạn có muốn tiếp tục thao tác ?',
      template: 'Mẫu vé',
      templatelayout: 'Mẫu vé tự thiết kế',
      child_product: 'Sản phẩm đi kèm',
      product_validate_type: {
        expired: 'Thời gian hiệu lực (ngày)',
        start_time_type: 'Kiểu tính thời gian',
        duration: 'Thời hạn (giây)',
      },
      start_time_type: {
        buy_time: 'Thời gian bán hàng',
        first_use: 'Lần đầu sử dụng',
        input: 'Tự nhập',
      },
      no_config_validation:
        'Nhấn vào để thực hiện cấu hình soát vé |||| Chưa cấu hình soát vé',
      no_config: 'Chưa cấu hình',
      no_config_commission: 'Chưa cấu hình hoa hồng',
      all: 'Tất cả',
    },
    home: {
      name: 'Trang chủ',
    },
    blocks: {
      name: 'Block',
    },
    networks: {
      name: 'Thiết kế tuyến đường',
    },
    routes: {
      name: 'Tuyến |||| Các tuyến',
      name_route: 'Tên tuyến',
      long_name: 'Tên tuyến đầy đủ',
      number: 'Mã số',
      desc: 'Mô tả',
      type: 'Loại tuyến',
      url: 'url',
      color: 'Màu',
      text_color: 'Màu chữ',
      sort_order: 'Vị trí',
      timetable: 'Thời gian biểu xe chạy',
      distance: 'Quãng đường',
      duration: 'Thời lượng (Phút)',
      numberOfPrivateSeat: 'Tổng số ghế ẩn',
      numberOfLeg: 'Số chặng đi',
      origin: 'Điểm khởi hành',
      destination: 'Điểm đến',
      create_timetable: 'Tạo thời gian biểu xe chạy',
      create_pattern: 'Tạo lộ trình',
      patterns: 'Danh sách lộ trình',
      network_plan: 'Thiết kế tuyến đường',
      count_pattern: '%{count} lộ trình',
      count_stop: '%{count} điểm dừng',
      count_trip: '%{count} chuyến',
      count_schedule: '%{count} lịch trình',
      no_stop_for_routepattern:
        'Chưa chọn điểm dừng, bạn cần chọn ít nhất 2 điểm dừng',
      no_stop: 'Chưa có điểm dừng',
      edit: 'Sửa thông tin tuyến',
      edit_timetable: 'Sửa thời gian biểu',
      create_schedule: 'Tạo lịch trình',
      total_route: '%{count} tuyến con',
      no_route: 'Chưa có tuyến con',
      total_timetable: '%{count} thời gian biểu',
      total_trip: '%{count} chuyến đi',
      no_timetable: 'Chưa có thời gian biểu',
      total_schedule: '%{count} lịch trình',
      no_schedule: 'Chưa có lịch trình',
      no_pattern: 'Chưa có lộ trình',
      fare_assigned: 'Đã gán giá',
      fare_assigned_all: 'Tất cả các tuyến con',
      fare_assigned_a_part: '%{number} tuyến con',
      fare_unassigned: 'Chưa gán giá',
      add_route: 'Thêm tuyến con',
      add_timetable: 'Thêm thời gian biểu',
      no_route_need_create:
        'Chưa có tuyến con, bạn cần tạo ít nhất 1 tuyến con',
      no_pattern_need_create:
        'Chưa có lộ trình, bạn cần tạo ít nhất 1 lộ trình',
      no_timetable_need_create:
        'Chưa có thời gian biểu, bạn cần tạo ít nhất 1 thời gian biểu',
      no_stop_need_design:
        'Bạn cần thiết kế điểm dừng đầy đủ (từ 2 điểm dừng trở lên) cho cả tuyến đường chiều đi và tuyến đường chiều về trước khi tạo lộ trình.',
      no_trip_need_create:
        'Thời gian biểu này không có chuyến đi nào. Bạn cần "Chỉnh sửa" lại thời gian biểu, chọn lộ trình và tạo thêm chuyến đi',
      no_schedule_need_create:
        'Chưa có lịch trình. Bạn cần tạo lịch trình trước',
      network_plan_not_enough:
        'Bạn cần thiết kế tuyến đường cho chiều đi và chiều về',
      should_two_way: 'Tuyến đường nên có 2 tuyến con (2 chiều)',
      warning_change_route_type: 'Cập nhật loại tuyến',
      change_route_to_trip: 'Bạn có muốn cập nhật chuyến đi sang loại',
      create_trips: 'Tạo chuyến đi',
      fields: {
        numberOfPrivateSeat: 'Tổng số ghế ẩn',
        name: 'Tên tuyến',
        routeId: 'Tuyến',
        originId: 'Điểm khởi hành',
        destinationId: 'Điểm kết thúc',
        distance: 'Quãng đường',
        duration: 'Thời gian',
        status: 'Trạng thái',
      },
      status_test: 'Thử nghiệm',
      status_private: 'Ẩn',
      status_public: 'Công khai',
    },
    schedules: {
      name: 'Lịch trình |||| Danh sách lịch trình',
      drawer_vehicle_desc:
        'Chọn xe và kéo vào cột đầu tiên trong bảng lịch trình, dùng Shift & Ctrl để chọn nhiều xe',
      no_schedule: 'Chưa có thời gian biểu cho dịch vụ trên',
      select_schedule_to_create_trip: 'Chọn lịch trình để tạo chuyến đi',
      create_trip_confirm_title: 'Tạo chuyến đi',
      create_trip_unassign_vehicle:
        'Xe chưa được gán. Bạn có muốn tiếp tục tạo chuyến đi không ?',
      empty: 'Chưa có lịch trình',
      create_schedule_reminder: 'Hãy tạo lịch trình từ tuyến/thời gian biểu',
      fields: {
        name: 'Tên lịch trình',
        desc: 'Mô tả',
        serviceId: 'Dịch vụ',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        every: 'Số lần lặp',
        recurType: 'Lặp theo',
        timetableGroupId: 'Thời gian biểu',
        createdAt: 'Ngày tạo',
      },
      buttons: {
        createTrip: 'Tạo chuyến đi',
      },
    },
    services: {
      name: 'Dịch vụ |||| Các dịch vụ',
      fields: {
        name: 'Tên dịch vụ',
        desc: 'Mô tả',
        days: 'Danh sách ngày hoạt động',
        startDate: 'Ngày bắt đầu',
        endDate: 'Ngày kết thúc',
        status: 'Trạng thái',
      },
    },
    ticketlayouts: {
      name: 'Mẫu vé',
      common: {
        default: 'Mặc định',
        setDefault: 'Cài đặt mặc định',
        warning_delete_title: 'Xác nhận xóa',
        warning_delete_content: 'Bạn có chắc chắn muốn xóa mẫu vé này?',
      },
      fields: {
        name: 'Tên mẫu vé',
        group: 'Nhóm',
        default: 'Mặc định',
        background: 'Ảnh nền',
        layout: 'Template',
        layoutType: 'Loại in',
        dynamic: 'In gộp',
        normal: 'In thường',
      },
    },
    servicereminders: {
      title: {
        create: 'Tạo lời nhắc',
        update: 'Cập nhật lời nhắc',
        confirm_changing: 'Cập nhật sửa lời nhắc định kì',
      },
      fields: {
        principalType: 'Loại đối tượng',
        principalName: 'Đối tượng',
        note: 'Ghi chú',
        serviceTaskId: 'Công việc',
        name: 'Tiêu đề',
        dueAt: 'Ngày thực hiện',
        notifyBefore: 'Báo trước',
        notifyFrequencyUnit: '',
        repeat: 'Lặp lại',
        dueInterval: 'Lặp lại mỗi',
        dueFrequencyUnit: '',
        daysOfMonth: 'Ngày trong tháng',
        finishType: 'Kết thúc theo',
        applyTo: 'Áp dụng tới',
        applyToNumber: 'Áp dụng sau',
        daysOfWeek: 'Thứ trong tuần',
        desc: 'Mô tả',
        dueAtGte: 'Từ',
        dueAtLte: 'Đến',
        serviceTask: 'Công việc',
        doneDate: 'Ngày hoàn tất',
        status: 'Trạng thái',
      },
    },
    stops: {
      name: 'Điểm dừng |||| Các điểm dừng',
      code: 'Mã số',
      name_stop: 'Tên điểm dừng',
      address: 'Địa chỉ',
      desc: 'Mô tả',
      type: 'Loại điểm dừng',
      url: 'Url',
      lat: 'Vĩ độ',
      lon: 'Kinh độ',
      fields: {
        name: 'Tên điểm dừng',
        code: 'Mã',
        address: 'Địa chỉ',
        stopIds: 'Danh sách điểm dừng',
      },
      notifications: {
        notFoundAddress: 'Không tìm thấy địa chỉ trên bản đồ!',
      },
      helperText: {
        address: 'Nhập địa chỉ để tìm trên bản đồ',
      },
      create_new_stop: 'Tạo mới điểm dừng',
    },
    einvoices: {
      name: 'Hóa đơn điện tử |||| Danh sách hóa đơn điện tử',
      fields: {
        erp: 'Mã hóa đơn',
        transactionId: 'Giao dịch',
        invoiceStatus: 'Trạng thái xuất hóa đơn',
        signStatus: 'Trạng thái ký',
        issuedDate: 'Ngày xuất hóa đơn',
        createdAt: 'Ngày tạo hóa đơn',
      },
      statuses: {
        initial: 'Chưa xuất',
        origin: 'Xuất thành công',
        deleted: 'Đã xóa',
        deleted_cancel: 'Đã xóa và hủy',
        nexbus_cancel: 'Đã hủy vé bán',
        sign_success: 'Ký thành công',
        sign_error: 'Ký lỗi',
        signing: 'Đang ký',
        sign_waiting: 'Chờ ký',
        sign_initial: 'Khởi tạo',
      },
      statistic: {
        number_of_unissued: 'Số đơn chưa xuất',
        number_of_issued: 'Số đơn đã xuất',
        total: 'Tổng số đơn',
      },
    },
    timetables: {
      name: 'Thời gian biểu xe chạy',
      data: 'Dữ liệu',
      routes: {
        name: 'Tuyến',
      },
      route: 'Tuyến',
      stop: 'Điểm dừng',
      seq: 'Thứ tự điểm dừng',
      legSeq: 'Thứ tự chặng đi',
      time: 'Thời gian',
      totalStop: 'Số điểm dừng',
      stopTimes: {
        name: 'Giờ xe',
      },
      departure: {
        time: 'Thời gian khởi hành',
        place: 'Địa điểm khởi hành',
      },
      arrival: {
        time: 'Thời gian đến',
        place: 'Địa điểm đến',
      },
      days: 'Các ngày',
      from: 'từ',
      to: 'đến',
      hour: 'giờ',
      tripPerMinute: 'phút mỗi chuyến',
      speed: 'Với vận tốc',
      pickedItem: 'Đã chọn %{count} hàng',
    },
    stopTimes: {
      time: 'Thời gian khởi hành',
      departureTime: 'Thời gian khởi hành',
      arrivalTime: 'Thời gian đến',
      totalStop: 'Số điểm dừng',
      seq: 'Thứ tự điểm dừng',
      legSeq: 'Thứ tự chặng đi',
      stop: 'Điểm dừng',
    },
    seatMaps: {
      fist_floor: 'Tầng 1',
      second_floor: 'Tầng 2',
      empty_seat_map: 'Chưa có sơ đồ ghế',
    },
    notifications: {
      name: 'Thông báo',
      fields: {
        title: 'Tiêu đề',
        subTitle: 'Phụ đề',
        body: 'Body',
        badge: 'Badge',
        icon: 'Icon',
        iconUrl: 'Icon url',
        sound: 'Âm thanh',
        priority: 'Độ ưu tiên',
        category: 'Loại',
        payload: 'payload',
        when: 'Thông báo khi',
      },
    },
    vehicles: {
      name: 'Xe |||| Danh sách xe',
      helper_text: 'Tìm kiếm theo tên, biển số xe',
      generalInformation: 'Thông tin chung',
      activity: 'Hoạt động',
      numberOfTripByStatus: 'Số chuyến %{status}',
      maintainHistory: 'Lịch sử bảo dưỡng',
      fields: {
        name: 'Tên xe',
        plate: 'Biển số',
        model: 'Dòng xe',
        capacity: 'Sức chứa (chỗ)',
        fuelType: 'Loại nhiên liệu',
        numberOfTrip: 'Số chuyến đã đi',
        numberOfRoute: 'Số tuyến đã đi',
        run: 'Đã đi (km)',
        depot: 'Bãi đậu',
        images: 'Hình ảnh',
        seatAvailable: 'Số lượng ghế trống',
        fuel: 'Loại nhiên liệu',
        color: 'Màu xe',
        brand: 'Thương hiệu',
        type: 'Loại xe',
        status: 'Trạng thái',
        vehicleId: 'Xe',
      },
      unassign_vehicle: 'Chưa có xe',
    },
    vehicletypes: {
      name: 'Loại xe |||| Các loại xe',
      empty_seat_map: 'Chưa có sơ đồ xe',
      fields: {
        name: 'Tên loại xe',
        totalSeat: 'Tổng số ghế',
        vehicleClass: 'Nhóm xe',
        seatMap: 'Sơ đồ xe',
      },
    },
    licences: {
      name: 'Giấy tờ',
      fields: {
        licenceNumber: 'Số quản lý',
        validFrom: 'Ngày cấp',
        validTo: 'Ngày hết hạn',
        type: 'Loại giấy tờ',
        numberOfRenewal: 'Số lần cấp lại',
        renewalPeriod: 'Chu kỳ cấp lại',
        renewalPeriodUnit: 'Đơn vị',
        status: 'Trạng thái',
        placeOfIssue: 'Nơi cấp',
        desc: 'Mô tả',
        images: 'Hình ảnh',
        applyFor: 'Giấy tờ cho',
        note: 'Ghi chú',
        serviceTask: 'Công việc',
      },
      auto_create_reminder: 'Bạn có muốn tự động tạo lời nhắc',
    },
    servicetasks: {
      fields: {
        name: 'Tên',
        desc: 'Mô tả',
        serviceGroupId: 'Nhóm dịch vụ',
        status: 'Trạng thái',
      },
    },
    tripstatuses: {
      name: 'Trạng thái chuyến đi',
      fields: {
        status: 'Trạng thái chuyến đi',
      },
    },
    trips: {
      name: 'Chuyến đi |||| Danh sách chuyến đi',
      drawer_driver_desc:
        'Chọn tài xế và kéo đên cột đầu tiên trong bảng chuyến đi hoặc thả trực tiếp vào các chuyến đi đã thể hiện trên bảng, dùng Shift & Ctrl để chọn nhiều tài xế',
      messages: {
        no_trips: 'Không có chuyến đi nào từ %{start} tới %{end}',
        saveFailed: 'Tạo chuyến đi thất bại!',
        saveSuccess: 'Tạo chuyến đi thành công!',
        cloneSuccess: 'Đã copy chuyến đi với thời gian đã chọn!',
        cloneFailed:
          'Nhân bản thất bại không thể tạo chuyến đi với ngày trong quá khứ',
      },
      status: {
        active: 'Đang hoạt động',
        deparated: 'Đã khởi hành',
        arrived: 'Đã đến',
        inactive: 'Chưa hoạt động',
        cancelled: 'Hủy',
        completed: 'Đã hoàn thành',
      },
      fields: {
        generalInformation: 'Thông tin chung',
        history: 'Lịch sử',
        tickets: 'Danh sách vé đã bán',
        departureTime: 'Thời gian khởi hành',
        capacity: 'Tổng số ghế',
        seatAvailable: 'Số ghế còn lại',
        route: 'Tuyến đường',
        routeName: 'Tuyến đường',
        routeNumber: 'Số tuyến',
        tripId: 'Mã chuyến đi',
        tripName: 'Tên chuyến đi',
        arrivalTime: 'Thời gian đến',
        originId: 'Điểm khởi hành',
        destinationId: 'Điểm đến',
        round: 'Khứ hồi',
        oneWay: 'Một chiều',
        outbound: 'Chiều đi',
        inbound: 'Chiều về',
        startDate: 'Từ ngày',
        endDate: 'Đến ngày',
        desc: 'Mô tả',
        name: 'Tên',
        longName: 'Tên đầy đủ',
        departure: 'Điểm khởi hành',
        arrival: 'Điểm đến',
        duration: 'Thời gian chạy (Phút)',
        distance: 'Khoảng cách (Km)',
        actualDepartureTime: 'Thời gian khởi hành thực tế',
        actualArrivalTime: 'Thời gian đến thực tế',
        date: 'Ngày',
        status: 'Trạng thái',
        type: 'Loại',
        saleStart: 'Bắt đầu mở bán',
        saleEnd: 'Kết thúc bán',
        vehicleId: 'Xe',
        driverId: 'Tài xế 1',
        routeId: 'Tuyến',
        scheduleId: 'Lịch trình',
        driver2Id: 'Tài xế 2 ',
        assistantDriverId: 'Phụ xe',
        newRouteId: 'Tuyến mới',
        newDepartureDate: 'Ngày đi mới',
        newDepartureTime: 'Giờ đi mới',
        departureDay: 'Ngày khởi hành',
        arrivalDay: 'Ngày đến',
      },
      buttons: {
        assignDriverSmart: 'Gán tài xế - Smart',
        unassignDriver: 'Gán lại tài xế',
        quickCreateTrip: 'Tạo chuyến nhanh',
      },
      dialogs: {
        assignDriverSmart:
          'Tài xế sẽ được gán vào chuyến đi dựa vào dữ liệu đã có trong quá khứ',
        unassignDriver:
          'Bạn có chắc bạn muốn gỡ tài xế ra khỏi các chuyến đi này?',
      },
      viewBy: {
        status: 'Trạng thái',
        driver: 'Tài xế',
      },
      validate: {
        routeError: 'Bạn phải chọn tuyến đường!',
        arrivedTimeError: 'Thời điểm đến phải sau thời điểm khởi hành!',
      },
    },
    drivers: {
      name: 'Tài xế |||| Danh sách tài xế',
      fields: {
        avatar: 'Ảnh đại diện',
        fullName: 'Họ và tên',
        phone: 'Số điện thoại',
        email: 'Email',
        birthday: 'Ngày sinh',
        address: 'Địa chỉ',
        desc: 'Mô tả',
        status: 'Trạng thái',
        licenceNumber: 'Số giấy phép',
        placeOfIssue: 'Nơi cấp',
        validFrom: 'Hiệu lực từ',
        validTo: 'Hiệu lực đến',
        byDriver: 'của tài xế',
        licenceClass: 'Hạng',
        driverId: 'Tài xế',
        driver_1: 'Tài xế 1',
        driver_2: 'Tài xế 2',
      },
      unassign_driver_1: 'Chưa có tài xế 1',
      unassign_driver_2: 'Chưa có tài xế 2',
    },
    farerules: {
      name: 'Quy định giá vé',
      fields: {
        name: 'Tên loại quy định',
        desc: 'Mô tả',
        ruleType: 'Loại quy định giá',
        ruleValue: 'Số tiền',
        conditionName: 'Tên điều kiện',
        conditionValue: 'Giá trị điều kiện',
      },
    },
    fares: {
      name: 'Bảng giá vé',
      type: 'Mức giá',
      fields: {
        name: 'Tên loại giá vé',
        code: 'Mã loại giá vé',
        desc: 'Mô tả',
        status: 'Trạng thái',
        amount: 'Giá vé',
        currencyId: 'Tiền tệ',
        applyFrom: 'Áp dụng từ',
        applyRules: 'Quy định giá được áp dụng',
        seats: 'Số ghế',
        price: 'Giá',
        total: 'Tổng cộng',
        type: 'Loại',
        conditions: 'Điều kiện',
        routeId: 'Tuyến',
      },
      prices: {
        name: 'Giá',
        amountHelper: 'Giá vé được tính từ quy định giá!',
      },
      rules: {
        name: 'Quy định giá vé',
        value: 'Số tiền',
        decrease: 'Giảm:',
        increase: 'Tăng:',
      },
      error: {
        getListProduct: 'Lấy danh sách sản phẩm thất bại!',
        save: 'Lưu thất bại!',
      },
    },
    maps: {
      name: 'Bản đồ',
    },
    products: {
      name: 'Sản phẩm |||| Các sản phẩm',
      no_limit: 'Không giới hạn',
      no_product_fare_code: 'Chưa gán mã',
      no_product_fare_description: 'Chưa gán mô tả',
      other_quantity: 'Số lượng khác',
      searchHelperText: 'Tìm kiếm theo tên hoặc mô tả sản phẩm',
      route_no_fare: 'Tuyến đường chưa có giá',
      product_no_route: 'Chưa có tuyến đường nào được đăng ký',
      product_no_agency: 'Chưa có đại lý nào được đăng ký',
      template_not_exists: 'Chưa có mẫu vé',
      no_child_product: 'Chưa có sản phẩm đi kèm',
      save_success: 'Lưu chi tiết sản phẩm thành công',
      save_failed: 'Thay đổi thất bại',
      empty_supplier: 'Chưa có nhà cung cấp nào',
      no_supplier: 'Không chọn nhà cung cấp',
      empty_tax: 'Chưa có thuế nào',
      no_tax: 'Không chọn thuế',
      fields: {
        name: 'Tên sản phẩm',
        desc: 'Mô tả sản phẩm',
        validFrom: 'Ngày bắt đầu hiệu lực',
        validTo: 'Ngày kết thúc hiệu lực',
        note: 'Ghi chú',
        type: 'Loại sản phẩm',
        validDate: 'Ngày hiệu lực',
        agencyCount: 'Số đại lý đăng ký',
        routeCount: 'Số tuyến đường đăng ký',
        productfares: {
          route: 'Tuyến',
          amount: 'Giá',
          type: 'Loại',
        },
        ticket: {
          duration: 'Thời gian hiệu lực (giây)',
          inclusion: 'Bao gồm',
          exclusion: 'Ngoại trừ',
          note: 'Ghi chú thêm cho loại vé',
        },
        quantityType: 'Số lượng',
        quantity: 'Số lượng',
        tax: 'Thuế',
        images: 'Hình ảnh',
        isGroup: 'In gộp',
        supplierId: 'Nhà cung cấp',
        status: 'Trạng thái',
      },
      sort: {
        sortTitle: 'Sắp xếp vé sản phẩm',
        sortFare:
          'Kéo thả vé sản phẩm để sắp xếp thứ tự các vé trong một tuyến đường.',
        sortRoute: 'Kéo thả tuyến đường để sắp xếp thứ tự nhiều vé.',
      },
      openAll: 'mở tất cả',
      collapseAll: 'đóng tất cả',
    },
    reservations: {
      name: 'Đặt chỗ',
      list: 'Danh sách',
      generalInfomation: 'Thông tin chung',
      infoDetail: 'Thông tin chi tiết',
      totalRefund: 'Tổng tiền hoàn lại',
      agencyKeep: 'Tiền đại lý nhận được',
      fields: {
        code: 'Mã đặt chỗ',
        origin: 'Điểm khởi hành',
        destination: 'Điểm đến',
        deviceId: 'Thiết bị',
        createdAt_gte: 'Từ ngày',
        createdAt_lte: 'Đến ngày',
        createdAt: 'Ngày tạo',
        updatedAt: 'Ngày sửa',
        status: 'Trạng thái',
        agencyId: 'Đại lý',
        source: 'Nguồn',
        debt: 'Nợ',
        total: 'Tổng tiền',
        paid: 'Tổng tiền thanh toán',
        code_like: 'Mã đặt chỗ',
        print: 'In vé',
        trips: 'Chuyến đi',
        fullName: 'Tên người đặt chỗ',
        phone: 'Số điện thoại đặt chỗ',
        email: 'Email đặt chỗ',
        reservationDate: 'Ngày đặt chỗ',
        departureDate: 'Ngày đi',
        productType: 'Loại sản phẩm',
        note: 'Ghi chú',
        productAmount: 'Số lượng sản phẩm',
        routeId: 'Tuyến',
        paymentMethodId: 'Phương thức thanh toán',
        createdByUser: {
          username: 'Người tạo',
        },
      },
      steps: {
        trip: 'Chuyến đi',
        bookingInformation: 'Thông tin người đặt',
        passengers: 'Thông tin hành khách',
        charges: 'Dịch vụ',
        payment: 'Thanh toán',
        review: 'Kiểm tra',
      },
      trips: 'Danh sách chuyến đi',
      charges: 'Các loại phí',
      vehiclePlate: 'Số xe',
      origin: 'Điểm khởi hành',
      destination: 'Điểm đến',
      departureTime: 'Ngày khởi hành',
      totalFoundTrips: 'Tổng số kết quả',
      fare: 'Giá/phí',
      totalFare: 'Tổng phí',
      paid: 'Đã trả',
      debt: 'Còn nợ',
      refund: 'Hoàn lại',
      selectedSeat: 'Ghế đã chọn',
      selectedTrip: 'Chuyến đi đã chọn',
      passenger: {
        label: 'Hành khách',
        firstName: 'Tên',
        lastName: 'Họ và đệm',
        phone: 'Số điện thoại',
        email: 'Địa chỉ thư điện tử',
        address: 'Địa chỉ',
        class: 'Phân loại hành khách',
      },
      payment: {
        method: 'Phương thức thanh toán',
      },
      currency: 'Tiền tệ',
      transaction: {
        type: 'Loại giao dịch',
        status: 'Trạng thái giao dịch',
      },
      payLater: 'Trả sau',
      enoughMoney: 'Đã nhận đủ tiền',
      makeBalance: 'Thanh toán dư nợ',
      note: 'Ghi chú',
      usePassengerInfo: 'Sử dụng thông tin hành khách',
      totalAmount: 'Tổng tiền thanh toán',
      paidAmount: 'Số tiền thanh toán',
      changeAmount: 'Tiền thừa',
      debtAmount: 'Cần thanh toán thêm',
      company_create: 'Đặt chỗ nhanh',
      new: 'Tạo mới',
      prepaid50: 'Trả trước 50.000đ',
      prepaid100: 'Trả trước 100.000đ',
      paidAll: 'Trả hết',
      cancelSeatWarning: 'Xác nhận yêu cầu hủy ghế',
      swapSeatWarning: 'Xác nhận yêu cầu đổi ghế',
      cancelSeatConfirm: 'Ghế đã chọn',
      cancelAll: 'Tất cả',
      deposit: 'Đã đặt cọc',
      place: 'chỗ',
      empty_place: 'chỗ trống',
      error_charge: 'Số tiền tối đa phải trả là %{amount}',
      choose: 'Chọn',
      pickUpPoint: 'Điểm đón',
      dropOffPoint: 'Điểm trả',
      productType: 'Loại sản phẩm',
      product: 'Sản phẩm',
      amount: 'Giá',
      saletickets: 'Bán vé',
      device: {
        serial: 'Số serial',
      },
      user: {
        createdBy: 'Tạo bởi',
        updatedBy: 'Sửa bởi',
      },
      unit: {
        seat: 'ghế',
        ticket: 'vé',
        product: 'sản phẩm',
      },
      report: {
        number_reservedSeat_time: 'Số vé đặt thành công',
        number_revenue_time: 'Tổng doanh thu',
        number_ticket_total: 'Tổng số vé',
        revenue_chart_title: 'Biểu đồ doanh thu',
        quantity_chart_title: 'Biểu đồ số lượng vé bán thành công',
        total_chart_title: 'Biểu đồ tổng số vé',
        empty_record: 'Chưa có dữ liệu',
      },
      status: {
        new: 'Mới',
        standby: 'Chờ xác nhận',
        confirmed: 'Đã xác nhận',
        cancelled: 'Đã hủy',
        checkedIn: 'Đã được checkin',
        finalized: 'Đã hoàn thành',
        expired: 'Đã hết hạn',
        other: 'Khác',
      },
      activity: {
        create: 'Đơn hàng đã được tạo',
        update: 'Đơn hàng đã được cập nhật từ %{oldValue} sang %{newValue}',
        cancel: 'Đơn hàng đã hủy',
        checkin: 'Đơn hàng đã checkin',
        print: 'Đơn hàng đã in',
        createdBy: 'Thử hiện bởi %{createdBy}',
        deviceCheckIn: 'Checkin trên thiết bị %{serial}',
      },
    },
    possessions: {
      name: 'Phiên bán hàng',
      searchHelperText: 'Tìm kiếm theo người tạo phiên',
      reservations: 'Danh sách đặt chỗ',
      other_expenses: 'Danh sách chi phí tiền mặt khác',
      other_income: 'Danh sách thu nhập tiền mặt khác',
      no_reservation: 'Chưa có chỗ nào được đặt',
      infoDetail: 'Thông tin chi tiết phiên',
      close_session: 'Đóng phiên bán hàng',
      close_session_detail: 'Bạn có muốn đóng phiên bán hàng này không?',
      fields: {
        name: 'Tên phiên bán hàng',
        startAt: 'Thời gian bắt đầu',
        closeAt: 'Thời gian kết thúc',
        startAt_gte: 'Từ ngày',
        startAt_lte: 'Đến ngày',
        totalPaymentAmount: 'Tổng tiền doanh thu',
        reservationCount: 'Số lượng chỗ đã đặt',
        reservationCancelledCount: 'Số lượng chỗ đã hủy',
        chargeCount: 'Số lượng vé đã đặt',
        chargeCancelledCount: 'Số lượng vé đã hủy',
        balanceStart: 'Tổng tiền nhận đầu phiên',
        balanceCloseReal: 'Tổng tiền thực cuối phiên',
        totalExpense: 'Tổng tiền chi ra',
        totalRevenue: 'Tổng tiền bán vé',
        totalIncome: 'Tổng tiền thu vào ngoài tiền vé',
        receiptsExpenses: 'Danh sách chi phí thu chi',
        balanceClose: 'Tổng tiền cuối phiên',
        balanceDiff: 'Số tiền chênh lệch',
        source: 'Nguồn phiên bán hàng',
        notes: '  Ghi chú',
        status: 'Trạng thái',
        createdBy: 'Người tạo',
        deviceId: 'Thiết bị',
        code: 'Mã đặt chỗ',
        trips: 'Chuyến đi',
        productAmount: 'Số lượng sản phẩm',
        paid: 'Tổng tiền thanh toán',
        paidCash: 'Tổng tiền thanh toán tiền mặt',
        paidOnline: 'Tổng tiền thanh toán trực tuyến',
        otherExpenses: 'Danh sách chi phí tiền mặt khác',
        otherIncome: 'Danh sách thu nhập tiền mặt khác ngoài vé',
        images: 'Hình ảnh',
        note: 'Ghi chú',
      },
    },
    membercards: {
      name: 'Thẻ thành viên |||| Danh sách thẻ thành viên',
      list: 'Danh sách thẻ thành viên',
      title: {
        recharge: 'Vui lòng nhập số thẻ để mua vé tháng',
      },
      registration: 'Đăng ký',
      recharge: 'Nạp tiền',
      status: {
        rechargedMonth: 'Vé đã nạp tiền tháng này',
        noRechargedMonth: 'Vé chưa nạp tiền tháng này',
      },
      fields: {
        code: 'Số thẻ',
        createdAt: 'Ngày đăng ký',
        createdAt_gte: 'Từ ngày',
        createdAt_lte: 'Đến ngày',
        customerName: 'Tên khách hàng',
        customerPhone: 'Số điện thoại khách hàng',
        customerEmail: 'Email khách hàng',
        segmentName: 'Chặng',
        routeName: 'Tuyến',
        status: 'Trạng thái',
        amount: 'Tổng tiền/Tháng',
        rechargeMonth: 'Tháng nạp tiền',
        rechargedAt: 'Ngày nạp tiền',
        historyRecharged: 'Lịch sử nạp tiền',
        paidAt: 'Thời gian thanh toán',
      },
    },
    payment: {
      methods: {
        cash: 'Tiền Mặt',
        atm: 'ATM',
        visa: 'VISA',
        qrCode: 'QR Code',
      },
    },
    routepatterns: {
      name: 'Lộ trình |||| Danh sách lộ trình',
      fields: {
        name: 'Tên',
        routeId: 'Tuyến',
      },
    },
    routegroups: {
      name: 'Tuyến đường |||| Danh sách tuyến đường',
      warning_archived_title: 'Bạn có muốn lưu trữ tuyến đường này không ?',
      warning_archived_content:
        'Khi lưu trữ tuyến đường thành công thì trạng thái của tuyến đường con, lộ trình, thời gian biểu, lịch trình và chuyến đi liên quan tới tuyến đường này sẽ chuyển sang LƯU TRỮ.\n Những tuyến có chuyến đi đã được đặt vé thì không thể lưu trữ',
      createSubRoute: 'Tạo tuyến con',
      fields: {
        name: 'Tên tuyến đường',
        countRoute: 'Số tuyến con',
        countTimetable: 'Số thời gian biểu',
        countSchedule: 'Số lịch trình',
        countTrip: 'Số chuyến đi',
        routeFares: 'Gán giá',
        type: 'Loại',
        subroute: 'Tuyến con',
      },
    },
    timetablegroups: {
      name: 'Thời gian biểu |||| Danh sách thời gian biểu',
      fields: {
        name: 'Tên thời gian biểu',
        desc: 'Mô tả',
        routeGroupId: 'Tuyến đường',
      },
      apply: 'Tạo lịch',
      remove: 'Xoá lịch',
      create_timetable: 'Lập lịch cho lộ trình',
      add_pattern_title: 'Thêm lịch cho lộ trình',
      add_pattern_desc:
        'Click vào lộ trình, nhập vào khung thời gian, nhấn nút [Tạo lịch]',
      apply_timetable_desc: 'Nhập vào khung thời gian, nhấn nút [Tạo lịch]',
      no_service: 'Chưa thêm vào thời gian biểu',
      no_timetable: 'Chưa có thời gian biểu, hãy chọn ít nhất 1 lộ trình',
      confirm_remove: 'Bạn có muốn xoá thời gian biểu này không?',
      hideStop: 'Ẩn %{smart_count} điểm dừng',
    },
    devicemodels: {
      name: 'Chủng loại Thiết bị |||| Danh sách chủng loại Thiết bị',
      fields: {
        name: 'Tên',
        model: 'Chủng loại',
        category: 'Danh mục',
        desc: 'Mô tả',
        os: 'Hệ điều hành',
        gps: 'GPS',
        wifiVersion: 'Chuẩn Wifi',
      },
    },
    devices: {
      name: 'Thiết bị |||| Danh sách thiết bị',
      helper_text: 'Tìm kiếm theo tên, số serial, định danh',
      fields: {
        name: 'Tên',
        serial: 'Số serial',
        imei: 'Số IMEI',
        deviceId: 'Định danh',
        userLanguage: 'Ngôn ngữ',
        lastSeen: 'Lần cuối hoạt động',
        lastLocation: 'Vị trí',
        installedApp: 'Ứng dụng',
        modelId: 'Chủng loại',
        companyId: 'Công ty sở hữu',
        operatorId: 'Công ty vận hành',
        configId: 'Cấu hình',
        apps: 'Ứng dụng',
        status: 'Trạng thái',
      },
      push_config: 'Ghi cấu hình lên máy',
    },
    apps: {
      name: 'Ứng dụng |||| Danh sách ứng dụng',
      fields: {
        name: 'Tên',
        applicationId: 'Định danh ứng dụng',
        os: 'Hệ điều hành',
        versionName: 'Tên phiên bản',
        versionCode: 'Mã phiên bản',
        desc: 'Mô tả',
        configId: 'Cấu hình',
        category: 'Chủng loại ứng dụng',
      },
    },
    appconfigs: {
      name: 'Cấu hình ứng dụng |||| Danh sách cấu hình Ứng dụng',
      fields: {
        name: 'Tên',
        value: 'Giá trị',
      },
    },
    deviceconfigs: {
      name: 'Cấu hình thiết bị',
      fields: {
        name: 'Tên',
        value: 'Giá trị',
      },
    },
    configurations: {
      name: 'Cấu hình |||| Danh sách cấu hình',
      fields: {
        code: 'Mã',
        value: 'Giá trị',
        group: 'Nhóm',
        desc: 'Mô tả',
        dataType: 'Kiểu dữ liệu',
        bValue: 'Giá trị',
      },
    },
    tags: {
      name: 'Tag |||| Danh sách tag',
      helper_text: 'Tìm kiếm theo tên tag',
      fields: {
        name: 'Tên tag',
        color: 'Màu tag',
        status: 'Trạng thái',
      },
    },
    companies: {
      name: 'Công ty |||| Danh sách công ty',
      helper_text: 'Tìm kiếm theo tên, địa chỉ, số đăng kí, mã số thuế',
      fields: {
        identifier: 'Định danh',
        name: 'Tên',
        desc: 'Mô tả',
        longName: 'Tên đầy đủ',
        address: 'Địa chỉ',
        email: 'Hộp thư',
        url: 'URL',
        tel: 'Điện thoại',
        fax: 'Fax',
        status: 'Trạng thái',
        countryId: 'Quốc gia',
        provinceId: 'Tỉnh',
        cityId: 'Thành phố',
        agencyList: 'Danh sách đại lý',
        taxNumber: 'Mã số thuế',
        registrationNumber: 'Số đăng kí',
        generalInformation: 'Thông tin chung',
        setting: 'Cài đặt',
        settingMenu: 'Cài đặt Admin Menu',
        settingMenuManager: 'Cài đặt Manager Menu',
        settingPos: 'Cài đặt Pos',
        settingMenuPos: 'Cài đặt Menu',
        settingActionPos: 'Cài đặt Action',
        settingPosAgency: 'Cài đặt Đại lý',
        settingPosDriver: 'Cài đặt Tài xế',
        agency: {
          name: 'Đại lý',
        },
        commission: 'Hoa hồng',
        numberOfAgency: 'Đại lý đăng kí',
        numberOfUser: 'Người dùng',
        platformDeposit: 'Ký quỹ nền tảng',
        platformCommission: 'Hoa hồng nền tảng',
        showOrHideMenu: 'Hiện hoặc ẩn menu',
        showOrHideMenuManager: 'Hiện hoặc ẩn menu Quản lý',
        showOrHideMenuPosAgency: 'Hiện hoặc ẩn menu Đại lý',
        showOrHideMenuPosDriver: 'Hiện hoặc ẩn menu Tài xế',
      },
    },
    financeaccounts: {
      name: 'Tài khoản |||| Danh sách tài khoản',
      searchHelperText: 'Tìm kiếm theo tên hoặc mô tả tài khoản',
      helperTextAccountId: 'Tài khoản đã ký quỹ',
      helperPlatformDeposit: 'Nền tảng đã ký quỹ',
      depositAgencyTitle: 'Nạp tiền ký quỹ cho đại lý',
      depositCompanyTitle: 'Nạp tiền ký quỹ vào công ty',
      depositPlatformTitle: 'Nạp tiền ký quỹ cho nền tảng',
      withdrawAgencyTitle: 'Rút tiền ký quỹ cho đại lý',
      withdrawCompanyTitle: 'Rút tiền ký quỹ từ công ty',
      not_topUp_yet: 'Tài khoản chưa ký quỹ',
      not_platformDeposit: 'Nền tảng chưa ký quỹ',
      warning_deposit_exceed_to_platform_deposit: 'Số tiền ký quỹ đã vượt quá',
      warning_withdraw_exceed_to_platform_deposit:
        'Số tiền rút đã vượt quá %{money}',
      warning_withdraw_more_than_zero: 'Số tiền rút phải lớn hơn 0',
      amount_can_withdraw: 'Số tiền có thể rút %{money}',
      company_agency_not_deposit: 'Công ty / Đại lý chưa ký quỹ',
      fields: {
        accountName: 'Tên tài khoản',
        accountNumber: 'Số tài khoản',
        bank: 'Ngân hàng',
        belongsTo: 'Công ty / Đại lý',
        type: {
          company: 'Công ty',
          agency: 'Đại lý',
          platform: 'Nền tảng',
        },
        agencyId: 'Đại lý',
        agencyAccountId: 'Tài khoản đại lý',
        companyId: 'Công ty',
        companyAccountId: 'Tài khoản công ty',
        topUpValue: 'Tiền ký quỹ',
        withdrawValue: 'Số tiền cần rút',
        description: 'Mô tả',
        invoiceNumber: 'Số hóa đơn',
        journalNumber: 'Số bút toán',
        deposit: 'Tiền ký quỹ',
        platformDeposit: 'Ký quỹ nền tảng',
        platformDebitStatus: 'Loại ký quỹ',
      },
    },
    platformdebits: {
      name: 'Danh sách giao dịch với nền tảng',
      fields: {
        createdAt: 'Ngày tạo',
        debit: 'Nợ',
        credit: 'Có',
        status: 'Trạng thái',
        createdAt_gte: 'Từ ngày',
        createdAt_lte: 'Đến ngày',
        accountId: 'Tài khoản',
        refTransaction: {
          id: 'Từ giao dịch',
        },
        description: 'Nội dung',
      },
    },
    reconciliations: {
      name: 'Phiếu đối soát |||| Danh sách phiếu đối soát',
      reconciledValue_helperText:
        'Nếu giá trị âm thì đó là số tiền platform phải trả, giá trị dương là số tiền platform được nhận',
      statementStartDate_helperText:
        'Ngày tạo của ghi nợ chưa đối soát củ nhất',
      statementEndDate_helperText: 'Ngày tạo của ghi nợ chưa đối soát mới nhất',
      balance_less_than_zero_note:
        '** Nếu số dư nhỏ hơn 0 thì tài khoản phải chuyển cho nền tảng 1 khoảng bằng tiền số dư.',
      balance_more_than_zero_note:
        '** Nếu số dư lơn hơn 0 thì tài khoản được nhận từ nền tảng 1 khoảng bằng tiền số dư.',
      detail: 'Chi tiết phiếu đối soát',
      suggestion_name: 'Phiếu đối soát từ %{fromDate} đến %{toDate}',
      fields: {
        createdAt: 'Ngày tạo',
        debit: 'Tiền phải thanh toán',
        credit: 'Tiền được nhận',
        status: 'Trạng thái',
        createdAt_gte: 'Từ ngày',
        createdAt_lte: 'Đến ngày',
        fromDate: 'Từ ngày',
        toDate: 'Đến ngày',
        accountId: 'Tài khoản',
        sumOfCredit: 'Tổng tiền được nhận',
        sumOfDebit: 'Tổng tiền phải trả',
        balance: 'Số dư',
        desc: 'Mô tả',
        statementStartDate: 'Ngày bắt đầu sao kê',
        statementEndDate: 'Ngày kết thúc sao kê',
        statementDate: 'Ngày sao kê',
        name: 'Tên',
        paid: 'Đã thanh toán',
        invoiceNumber: 'Số hóa đơn',
        journalNumber: 'Số bút toán',
        platformTransaction: {
          createdAt: 'Ngày ghi nhận thanh toán',
        },
        pay: 'Phải trả',
        recieve: 'Được nhận',
      },
    },
    agencies: {
      name: 'Đại lý |||| Danh sách đại lý',
      generalInfomation: 'Thông tin chung',
      fields: {
        identifier: 'Định danh',
        name: 'Tên',
        desc: 'Mô tả',
        address: 'Địa chỉ',
        email: 'Hộp thư',
        url: 'URL',
        tel: 'Điện thoại',
        fax: 'Fax',
        type: 'Phân loại',
        taxNumber: 'Mã số thuế',
        registrationNumber: 'Số đăng kí',
        longName: 'Tên đầy đủ',
        status: 'Trạng thái',
        countryId: 'Quốc gia',
        provinceId: 'Tỉnh',
        cityId: 'Thành phố',
        companyId: 'Công ty',
        company: {
          name: 'Công ty',
        },
        numberOfCompany: 'Công ty đã đăng ký',
        deposit: 'Tiền ký quỹ',
      },
    },
    faretables: {
      name: 'Bảng giá vé |||| Danh sách bảng giá vé',
      addRoute: 'Thêm tuyến',
      fields: {
        name: 'Tên',
        desc: 'Mô tả',
        conditions: 'Điều kiện',
        routes: 'Dùng cho tuyến đường',
        farePrices: 'Các mức giá',
        fareCompanionId: 'Bảng biểu mẫu vé',
      },
    },
    farecompanions: {
      name: 'Bảng biểu mẫu vé |||| Danh sách bảng biểu mẫu vé',
      no_valid_product: 'Hiện tại không có sản phẩm hợp lệ cho tuyến đường này',
      fields: {
        name: 'Tên',
        desc: 'Mô tả',
        routeId: 'Tuyến đường',
        productId: 'Sản phẩm',
        fareTableId: 'Bảng giá vé',
      },
    },
    timeline: {
      name: 'Bảng thời gian',
    },
    viewtripsclone: {
      name: 'Nhân bản chuyến đi',
      clonedTrips: 'chuyến đã nhân bản',
      countTrips: '%{count} chuyến',
      cloneComfirm: 'Bạn có muốn nhân bản các chuyến đi này ko?',
      routeFilter: 'Lọc chuyến đi',
      cloneTimesTrip: 'Danh sách thời gian chuyến đi',
      unCheckTime: 'Bỏ chọn thời gian không muốn nhân bản',
      openTripTime: 'Chọn thời gian để nhân bản',
      copyCheckedTime: 'Sao chép chuyến đi',
      cloneAll: 'Nhân bản tất cả',
      cloneByTime: 'Nhân bản theo thời gian',
      copy: 'Sao chép',
      allRoute: 'Tất cả các chuyến đi',
      customRecurrence: 'Lặp lại tùy chỉnh',
      reapeatEvery: 'Lặp lại mỗi',
      repeatOn: 'Lặp lại vào',
      ends: 'Kết thúc',
      endYear: 'Đến cuối năm',
      on: 'Vào ngày',
      after: 'Sau',
      custom: 'Tùy chỉnh...',
      daily: 'Hàng ngày',
      weeklyOn: 'Hàng tuần vào %{weekdays}',
      monthlyOn: 'Hàng tháng vào ngày %{day}',
      everyWeekdays: 'Mọi ngày trong tuần (từ thứ 2 đến thứ 6)',
      noRepeat: 'Không lặp lại',
      occurrences: 'lần xuất hiện',
      monday: 'T2',
      tuesday: 'T3',
      webnesday: 'T4',
      thursday: 'T5',
      friday: 'T6',
      saturday: 'T7',
      sunday: 'CN',
    },
    viewtrips: {
      name: 'Chuyến đi |||| Danh sách chuyến đi',
      fields: {
        tripName: 'Tên chuyến đi',
        routeName: 'Tên tuyến',
        departure: 'Điểm khởi hành',
        arrival: 'Điểm đến',
        departureTime: 'Thời gian khởi hành',
        status: 'Trạng thái',
        driverName: 'Tài xế',
        vehiclePlate: 'Số xe',
        seatAvailable: 'Tổng số ghế',
        reservedSeats: 'Số ghế đã bán',
        timeline: 'Bảng thời gian',
        date_gte: 'Từ ngày',
        date_lte: 'Đến ngày',
        totalFare: 'Tổng tiền vé',
        makeReservation: 'Bán vé',
        departureTime_gte: 'Từ ngày',
        departureTime_lte: 'Đến ngày',
        vehicleId: 'Xe',
        driverId: 'Tài xế 1',
        driver2Id: 'Tài xế 2 ',
        assignDriver1: 'Gán tài xế 1',
        assignDriver2: 'Gán tài xế 2',
        assignVehicle: 'Gán xe',
        assignAssistantDriver: 'Gán phụ xe',
        selectVehicle: 'Chọn xe',
        selectDriver: 'Chọn tài xế',
        selectAssistantDriver: 'Chọn phụ xe',
        type: 'Loại',
        saleStart: 'Bắt đầu mở bán',
        saleEnd: 'Kết thúc bán',
        saleTime: 'Thời gian mở bán',
        routeId: 'Tuyến',
        originId: 'Điểm khởi hành',
        destinationId: 'Điểm đến',
        helperText_selected_driver1:
          'Tài xế được chọn sẽ là tài xế 1 cho chuyến đi',
        helperText_selected_driver2:
          'Tài xế được chọn sẽ là tài xế 2 cho chuyến đi',
        assistantDriverId: 'Phụ xe',
      },
      selectUnassignDialog: {
        title: 'Hãy chọn tài xế bạn muốn gỡ ?',
        onlyDriver1: 'Chỉ tài xế 1',
        onlyDriver2: 'Chỉ tài xế 2',
        all: 'Cả tài xế 1 và tài xế 2',
      },
    },
    licencevalidities: {
      fields: {
        licenceNumber: 'Số quản lí mới',
        validFrom: 'Ngày cấp mới',
        validTo: 'Ngày hết hạn mới',
        placeOfIssue: 'Nơi cấp mới',
        images: 'Hình ảnh mới',
      },
    },
    actions: {
      name: 'Hành động',
      fields: {
        action: 'Hành động',
      },
    },
    triphistories: {
      name: 'Lịch sử chuyến đi',
      fields: {
        field: 'Trường thao tác',
        oldValue: 'Giá trị cũ',
        newValue: 'Giá trị mới',
        desc: 'Mô tả',
        createdAt: 'Thực thi lúc',
      },
    },
    reminders: {
      name: 'Lịch nhắc',
    },
    maintainance: {
      name: 'Bảo trì & Bảo dưỡng',
    },
    vehiclemaintains: {
      name: 'Bảo dưỡng xe',
      fields: {
        vehicleId: 'Xe',
        nextEvent: 'Mục bảo dưỡng tới',
        name: 'Tên',
        action: 'Hành động',
        chooseTemplate: 'Chọn mẫu bảo dưỡng',
        chooseVehicle: 'Chọn xe',
      },
    },
    maintaintemplates: {
      name: 'Lịch bảo dưỡng chuẩn',
      fields: {},
    },
    charges: {
      name: 'Phí',
      select_swap_seat: 'Nhấn vào để chọn ghế muốn chuyển',
      fields: {
        id: 'Định danh',
        createdAt: 'Được tạo lúc',
        reservationId: 'Mã đặt chỗ',
        productId: 'Sản phẩm',
        itemDetail: {
          itemCode: 'Mã sản phẩm',
          productId: 'Tên sản phẩm',
          fareId: 'Loại vé',
        },
        amount: 'Thành tiền',
        paid: 'Đã thanh toán',
        no_paid: 'Chưa thanh toán',
        code: 'Mã',
        price: 'Giá',
        agency: 'Đại lý',
        status: 'Trạng thái',
        empty_seat: 'Ghế trống',
        reserved_seat: 'Ghế đã đặt',
        move_to: 'Chuyển tới',
      },
      newCharge: 'Ghế mới',
      cancelCharge: 'Huỷ ghế',
      pickUpPoint: 'Điểm đón',
      dropOffPoint: 'Điểm trả',
      fareInfo: 'Thông tin giá',
      reserveInfo: 'Thông tin người đặt chỗ',
      empty_pickUpPoint: 'Chưa có điểm đón',
      empty_dropOffPoint: 'Chưa có điểm trả',
    },
    zones: {
      name: 'Khu vực |||| Danh sách khu vực',
      fields: {
        name: 'Khu vực',
      },
    },
    seatmaps: {
      name: 'Sơ đồ ghế |||| Danh sách sơ đồ ghế',
      fields: {
        name: 'Tên',
        firstFloor: 'Tầng 1',
        secondFloor: 'Tầng 2',
        privateCode: 'Mã ghế ẩn',
        numberOfFloor: '%{count} tầng',
        numOfCol: 'Số cột',
        numOfRow: 'Số hàng',
        numOfFloor: 'Số tầng',
        numOfDecks: 'Số tầng',
        numOfSeat: 'Tổng số ghế',
        column: 'Cột',
      },
    },
    users: {
      name: 'Người dùng |||| Danh sách Người dùng',
      add_role: 'Thêm quyền',
      helper_text: 'Tìm kiếm theo tên đăng nhập, tên, số điện thoại, email',
      fields: {
        name: 'Tên',
        fullName: 'Họ và tên',
        username: 'Tên đăng nhập',
        password: 'Mật khẩu',
        email: 'Email',
        phone: 'Điện thoại',
        status: 'Trạng thái',
        companyId: 'Công ty',
        agencyId: 'Đại lý',
        oldPassword: 'Mật khẩu cũ',
        newPassword: 'Mật khẩu mới',
        confirmPassword: 'Xác nhận mật khẩu mới',
        userType: 'Tạo cho',
        userContacts: 'Thông tin liên hệ người dùng',
      },
      userContacts: {
        createUserContact: 'Thêm thông tin',
        editUserContact: 'Sửa thông tin',
      },
      page: {
        create_user: 'Tạo người dùng',
        reset_password: 'Đặt lại mật khẩu',
        change_password: 'Cập nhật mật khẩu',
      },
      message: {
        save_userContact_success: 'Lưu thông tin thành công',
      },
    },
    messagetypes: {
      fields: {
        messageTypeId: 'Phương thức nhắn tin',
      },
    },
    recipient: {
      fields: {
        recipient: 'Thông tin người nhận',
      },
    },
    calllogs: {
      lastestTrip: '%{number} chuyến đi gần nhất',
      name: 'Tổng đài',
      fields: {
        from: 'Gọi từ',
        to: 'Gọi đến',
        start: 'Ngày thực hiện cuộc gọi',
        duration: 'Thời gian gọi',
        type: 'Loại cuộc gọi',
        reservations: 'Mã đặt chỗ',
        startCall: 'Bắt đầu',
        endCall: 'Kết thúc',
        startGte: 'Từ',
        startLte: 'Đến',
        lastestReservation: 'Ngày đặt gần nhất',
        noRecord: 'Không có thông tin',
        username: 'Tổng đài viên',
        status: 'Trạng thái',
      },
      report: {
        total: 'Tổng cuộc gọi',
        totalReceived: 'Cuộc gọi đã nhận',
        totalMissed: 'Cuộc gọi nhỡ',
        totalReservated: 'Cuộc gọi đặt chỗ',
      },
    },
    transactions: {
      name: 'Giao dịch |||| Danh sách Giao dịch',
      title: 'Thông tin chung',
      fields: {
        transactionNumber: 'Mã giao dịch',
        total: 'Tổng tiền',
        paid: 'Đã thanh toán',
        paidAt: 'Ngày thanh toán',
        createdAt: 'Ngày tạo',
        status: 'Trạng thái',
        reservationId: 'Mã đặt chỗ',
        paymentMethodId: 'Phương thức',
        type: 'Loại',
        createdAt_lte: 'Đến',
        createdAt_gte: 'Từ',
        companyId: 'Công ty',
        desc: 'Ghi chú',
        attempt: 'Số lần gửi yêu cầu thanh toán',
        agencyId: 'Đại lý',
      },
    },
    transactiondetails: {
      title: 'Thông tin chi tiết',
      fields: {
        product: 'Loại sản phẩm',
        productCode: 'Sản phẩm',
        paid: 'Đã thanh toán',
        total: 'Tổng tiền',
        company: 'Công ty',
        agency: 'Đại lí',
      },
    },
    paymentmethods: {
      name: 'Phương thức thanh toán |||| Danh sách Phương thức thanh toán',
    },
    companyagencies: {
      fields: {
        companyId: 'Công ty',
        type: 'Loại',
        status: 'Trạng thái',
        agencyId: 'Đại lý',
      },
    },
    routeproducts: {
      fields: {
        routeId: 'Tuyến',
        fareId: 'Giá',
        type: 'Loại',
        fares: 'Danh sách giá',
        'fares.name': 'Tên giá',
        'fares.amount': 'Số tiền',
        'fares.type': 'Áp dụng bán',
        'fares.code': 'Mã',
      },
    },
    settings: {
      fields: {
        code: 'Mã',
        name: 'Tên',
        group: 'Nhóm',
        dataType: 'Loại',
        value: 'Giá trị',
        bValue: 'Giá trị',
        desc: 'Mô tả',
        fareCode: 'Mã',
      },
    },
    reports: {
      revenue: 'Doanh thu',
      totalTrip: 'Tổng số chuyến',
      totalVehicle: 'Tổng số xe',
      totalDriver: 'Tổng số tài xế',
      runningTrip: 'Số chuyến đang chạy',
      finance: 'Tài chính',
    },
    accountcredits: {
      name: 'Thu nhập',
      fields: {
        createdAt_gte: 'Từ ngày',
        createdAt_lte: 'Tới ngày',
        createdAt: 'Thời gian`',
        credit: 'Có',
        'transaction.paid': 'Giá trị giao dịch',
        'transaction.reservation.code': 'Mã đặt chỗ',
        trip: 'Chuyến đi',
        company: 'Công ty',
        'transaction.reservation.agency.name': 'Đại lý',
        description: 'Nội dung',
      },
    },
    passengercollections: {
      title: 'Thiết lập điểm đón / điểm trả',
      fields: {
        address: 'Địa chỉ điểm đón /  điểm trả',
        type: 'Loại',
        status: 'Trạng thái',
        stopId: 'Điểm dừng liên quan',
      },
      helperTextStop:
        'Hãy chọn điểm dừng gần nhất với để làm điểm liên quan với điểm được chọn làm điểm đón hoặc điểm trả',
    },
    campaigns: {
      name: 'Chiến dịch |||| Chiến dịch',
      promotion_info: 'Thông tin khuyến mãi',
      discount_helperText:
        'Số tiền hoặc số phần trăm được giảm của chiến lược. Nếu có kí tự % ở cuối thì sẽ giảm theo phần trăm',
      promotion_company_helperText: 'Phần trăm công ty phải chi',
      promotion_agency_helperText: 'Phần trăm đại lý phải chi',
      promotion_platform_helperText: 'Phần trăm nên tảng phải chi',
      promotion_limitTime_helperText:
        'Khách hàng dùng mã giảm giá 1 lần trong khoảng thời gian trên',
      promotion_countLimit_helperText: 'Nhập 0 cho trường hợp không giới hạn',
      helper_text: 'Tìm kiếm theo tên, mô tả',
      discounted: 'Đã giảm',
      fields: {
        name: 'Tên',
        description: 'Mô tả',
        startTime: 'Thời gian bắt đầu',
        endTime: 'Thời gian kết thúc',
        rules: 'Luật',
        fullDescription: 'Mô tả chi tiết',
        agencies: 'Áp dụng cho đại lý',
        routes: 'Áp dụng cho tuyến đường',
        companies: 'Áp dụng cho công ty',
        type: 'Loại',
        status: 'Trạng thái',
        daysOfMonth: 'Ngày trong tháng',
        daysOfWeek: 'Ngày trong tuần',
        duration: 'Khoảng thời gian áp dụng',
        applyFor: 'Áp dụng cho',
        promotion: {
          discount: 'Giảm',
          discountMax: 'Giảm tối đa',
          totalLimit: 'Kinh phí',
          company: 'Công ty chi',
          agency: 'Đại lý chi',
          platform: 'Nền tảng chi',
          code: 'Mã giảm giá',
          countLimit: 'Số lần / Số khách hàng có thể sử dụng',
          limitTime: 'Thời gian áp dụng',
        },
        time_range_warning:
          'Cần nhập khoảng thời gian dưới dạng như: 06:00-18:30',
        time_range_error: 'Thời gian băt đầu phải nhỏ hơn thời gian kết thúc',
      },
    },
    assistantdrivers: {
      name: 'Phụ xe |||| Danh sách phụ xe',
      fields: {
        fullName: 'Tên',
        assistantDriverId: 'Phụ xe',
        email: 'Email',
        phone: 'Số điện thoại',
        birthday: 'Ngày sinh',
        address: 'Địa chỉ',
        desc: 'Mô tả',
        status: 'Trạng thái',
        avatar: 'Ảnh đại diện',
      },
      unassign_assistant_driver: 'Chưa có phụ xe',
    },
    customers: {
      name: 'Hành khách |||| Hành khách',
      searchHelperText: 'Tìm kiếm theo tên, số điện thoại và email',
      fields: {
        fullName: 'Họ tên',
        email: 'Email',
        phone: 'Điện thoại',
        firstCompanyId: 'Nhà xe',
        firstAgencyId: 'Đại lý',
        totalPay: 'Tổng số tiền đã trả',
        totalDiscount: 'Tổng số tiền được giảm giá',
        orderCount: 'Số lần đặt chỗ',
        totalLength: 'Tổng quãng đường đã đi',
        lastOrderDate: 'Ngày đặt chỗ gần nhất',
        reservationCount: 'Số lần đặt chỗ',
        lastCompany: {
          name: 'Công ty lần cuối đặt vé',
        },
        lastAgency: {
          name: 'Đại lý lần cuối đặt vé',
        },
      },
    },
    customercompanies: {
      fields: {
        trips: 'Chuyến đi',
        amount: 'Số tiền mua vé',
        reservation: {
          createdAt: 'Ngày đặt chỗ',
        },
      },
    },
    saletickets: {
      name: 'Bán vé',
      routeInfo: 'Thông tin tuyến',
      ticket: 'Danh sách vé',
      duration_label: 'Thời gian hiệu lực',
      inclusion_label: 'Bao gồm',
      exclusion_label: 'Ngoại trừ',
      supplier: 'Nhà cung cấp',
      company: 'Công ty',
      seconds: 'Giây',
      minutes: 'Phút',
      hours: 'Giờ',
      days: 'Ngày',
      months: 'Tháng',
      years: 'Nắm',
      quantity: 'Số lượng',
      fareType: 'Loại giá',
      unit: 'Đơn giá',
      total: 'Tổng tiền',
      customerInfo: 'Thông tin khách hàng',
      phone: 'Số điện thoại',
      cusName: 'Tên khách hàng',
      email: 'Email',
      paymentMethod: 'Phương thức thanh toán',
      note: 'Ghi chú',
      no_select_product: 'Hãy chọn 1 loại vé',
      total_ticket: 'Tổng tiền vé',
      agency_commission: 'Hoa hồng đại lý',
      agency_paid_total: 'Tổng tiền đại lý thanh toán',
      departureDate: 'Ngày đi',
      numberOfProduct: '%{quantity} sản phẩm',
      product_empty: 'Chưa có sản phẩm',
      companyId: 'Công ty',
      productName: 'Sản phẩm',
      sale_ticket_confirm: 'Xác nhận danh sách vé',
      type: 'Áp dụng bán',
      fareCode: 'Mã',
      fareName: 'Chặng',
      fareAmount: 'Thành tiền',
      code: 'Số vé',
      birthday: 'Ngày sinh',
      address: 'Địa chỉ',
      normal_customer: 'Bình thường',
      piority_customer: 'Ưu tiên',
      one_route: 'Một chặng',
      multi_route: 'Liên tuyến',
      route: 'Tuyến',
      segment: 'Chặng',
      receive_member_card_place: 'Điểm nhận thẻ',
    },
    saleticketbysegments: {
      name: 'Bán ghế theo chặng',
      orderInfo: 'Thông tin đơn hàng',
      total: 'Tổng cộng',
      ticket: 'vé',
    },
    suppliers: {
      name: 'Nhà cung cấp',
      helper_text: 'Tìm kiếm theo tên, số điện thoại, email',
      products: 'Danh sách sản phẩm',
      product_empty: 'Chưa có sản phẩm',
      fields: {
        name: 'Tên nhà cung cấp',
        address: 'Địa chỉ nhà cung cấp',
        phone: 'Số điện thoại',
        email: 'Email',
        hashPayment: 'Hỗ trợ thanh toán',
        statusCode: 'Trạng thái',
        quantity_products: 'Số lượng',
      },
    },
    agencyproducts: {
      fields: {
        agency: {
          name: 'Đại lý',
        },
        agencyId: 'Đại lý',
        agencyQuantityType: 'Số lượng',
        agencyQuantity: 'Số lượng',
        commission: 'Hoa hồng',
        platformCommission: 'Hoa hồng của nền tảng',
        platformCommissionScript: 'Hoa hồng của nền tảng',
        quantity: 'Số lượng',
      },
    },
    commissiontemplates: {
      dialog_title: 'Cập nhật hoa hồng',
      fields: {
        templateId: 'Loại hoa hồng',
        params: {
          value: 'Giá trị',
          includePaymentFee: 'Bao gồm phí thanh toán',
        },
      },
    },
    finance: {
      name: 'Tài chính',
    },
    // pos action
    autoPrint: {
      name: 'Tự động in vé sau khi mua',
    },
    usePOSSession: {
      name: 'Sử dụng phiên làm việc',
    },
    cancelTime: {
      name: 'Thời gian hủy reservation trên POS',
    },
    //pos menu item
    bar_home: {
      name: 'Trang chủ',
    },
    bar_sales: {
      name: 'Bán vé',
    },
    bar_payment: {
      name: 'Thanh toán',
    },
    menu_transaction: {
      name: 'Lịch sử giao dịch',
    },
    bar_check: {
      name: 'Soát vé',
    },
    menu_info: {
      name: 'Thông tin',
    },
    menu_commission: {
      name: 'Hoa hồng',
    },
    menu_reloadconfig: {
      name: 'Làm mới cấu hình',
    },
    bar_currenttrip: {
      name: 'Chuyến đi',
    },
    bar_menu: {
      name: 'Menu',
    },
    menu_schedule: {
      name: 'Lịch trình',
    },
    menu_tickets: {
      name: 'Vé đã bán',
    },
    menu_revenue: {
      name: 'Doanh thu',
    },
    menu_notification: {
      name: 'Thông báo',
    },
    menu_changevehicle: {
      name: 'Đổi xe',
    },
    menu_setting: {
      name: 'Cài đặt',
    },
    menu_session: {
      name: 'Phiên làm việc',
    },
    bar_reservations: {
      name: 'Danh sách đặt chỗ',
    },

    //manager menu item
    VehicleTracking: {
      name: 'Định vị xe',
    },
    Home: {
      name: 'Trang chủ',
    },
    Chart: {
      name: 'Biểu đồ',
    },
    Profile: {
      name: 'Thông tin cá nhân',
    },
    InfoApp: {
      name: 'Về chúng tôi',
    },
    VehicleList: {
      name: 'Danh sách xe',
    },
    Route: {
      name: 'Tuyến',
    },
    validationproducts: {
      configuration_title: 'Cấu hình soát vé',
      fields: {
        type: 'Loại',
        params: {
          serviceDays: 'Ngày áp dụng',
        },
      },
    },
    einvoicesettings: {
      configuration_title: 'Cấu hình hóa đơn điện tử',
      fields: {
        provider: 'Nhà cung cấp',
        allow: 'Xuất tự động',
        not_allow: 'Xuất sau',
        serialNo: 'Ký hiệu',
        templateNo: 'Mẫu số',
      },
    },
    productcharges: {
      name: 'Sản phẩm đã bán |||| Sản phẩm đã bán',
      searchHelperText: 'Tìm kiếm theo mã',
      fields: {
        price: 'Tổng tiền vé',
        net: 'Giá net',
        code: 'Mã',
        createdAt_gte: 'Từ ngày',
        createdAt_lte: 'Đến ngày',
        createdAt: 'Ngày tạo',
        status: {
          name: 'Trạng thái',
        },
        product: {
          name: 'Sản phẩm',
        },
        agency: {
          name: 'Đại lý',
        },
        user: {
          username: 'Tài khoản',
        },
        charge: {
          chargeStatus: {
            name: 'Trạng thái',
          },
          reservation: {
            device: {
              serial: 'Thiết bị',
            },
          },
        },
        fromDate: 'Ngày đi',
        firstUse: 'Ngày đi',
      },
    },
    reservations2: {
      name: 'Đặt chỗ nhanh',
    },
    chargereports: {
      name: 'Báo cáo doanh thu',
      fields: {
        fromDate: 'Từ ngày',
        toDate: 'Đến ngày',
        reportType: 'Loại báo cáo',
        company: 'Công ty',
        date: 'Ngày',
      },
      by_company: 'Báo cáo theo đơn vị',
      by_day: 'Báo cáo theo ngày',
      detail: 'Báo cáo chi tiết',
      by_product: 'Báo cáo theo sản phẩm',
    },
    fareconditions: {
      name: 'Điều kiện bảng giá',
      fields: {
        id: 'Mã điều kiện',
        name: 'Tên điều kiện',
        default: 'Phần trăm giảm trừ',
        type: 'Loại',
        status: 'Trạng thái',
      },
    },
    editor: {
      name: 'Thiết kế mẫu vé',
      common: {
        editObj: 'Chỉnh sửa đối tượng',
        createObj: 'Tạo đối tượng',
      },
      button: {
        preview: 'Xem trước',
        save: 'Lưu',
        delete: 'Xóa',
        blankBackground: 'Tạo nền trắng',
        uploadBackground: 'Tải ảnh nền',
        cancel: 'Hủy',
        apply: 'Áp dụng',
      },
      fields: {
        x: 'x',
        y: 'y',
        width: 'Chiều rộng',
        height: 'Chiều cao',
        actualWidth: 'Chiều rộng thực tế',
        fontSize: 'Cỡ chữ',
        fontFamily: 'Phông chữ',
        fontStyle: 'Định dạng',
        size: 'Kích thước',
        content: 'Nội dung',
        rotation: 'Độ quay',
        align: 'Căn lề',
        text: 'Văn bản',
        color: 'Màu sắc',
        formatType: 'Kiểu định dạng',
      },
    },
    receiveplaces: {
      name: 'Địa điểm nhận thẻ',
      fields: {
        name: 'Tên địa điểm',
        code: 'Mã địa điểm',
        address: 'Địa chỉ',
        parentId: 'Khu vực',
        zones: 'Thuộc khu vực',
      },
    },
    membercardreports: {
      name: 'Báo cáo doanh thu vé tháng',
    },
  },
  error: {
    forbidden: 'Người dùng không có quyền truy cập',
    authorization_required: 'Đăng nhập không thành công',
    bad_request: 'Yêu cầu không chính xác',
    error_request_timeout: 'Hết thời gian yêu cầu',
    not_found: 'Dữ liệu không được tìm thấy',
    not_acceptable: 'Yêu cầu không được chấp nhận',
    login_failed: 'Đăng nhập không thành công',
    undefined: 'Lỗi chưa xác định',
    change_failed: 'Thay đổi thất bại!',
  },
}
