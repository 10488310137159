import React, { Component, Fragment } from 'react'
import {
  SimpleForm,
  ReferenceInput,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  TextInput,
  translate,
  SelectInput,
} from 'react-admin'
import {
  FuzzySelectInput,
  defaultFuzzySearch,
} from '../common/react-fuzzy-picker'
import { Provider } from '../provider'
import CustomToolbar from '../common/CustomToolbarForm'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import _ from 'lodash'
import { reset, Field  } from 'redux-form'
import { IncludePaymentFeeInput, PercentInput } from '../common/CommissionChip'
import { MoneyInput } from '../common/MoneyInput'
import { getCurrentAgency } from '../utils/commonUtil'

const quantityOptions = [
  { id: 'NO_LIMIT', name: 'resources.products.no_limit' },
  { id: 'OTHER', name: 'resources.products.other_quantity' },
]

const DEFAULT_1_PERCENT = {
  templateId: 1,
  params: {
    percent: 1,
  },
}

class AgencyProductForm extends Component {

  state = {}

  componentDidMount() {
    let { commissionTemplates } = this.props
    let commissionTemplateChoices = commissionTemplates.map(({ id, name }) => ({ id, name }))
    let commissionTemplateMapping = _.keyBy(commissionTemplates, 'id')
    this.setState({
      commissionTemplateChoices,
      commissionTemplateMapping,
      template: commissionTemplates[0],
    })
  }

  onSave = record => {
    let { productId } = this.props
    let { agencyQuantity, agencyQuantityType, templateId, params } = record
    let quantity = agencyQuantityType === 'NO_LIMIT' ? -1 : agencyQuantity
    record = { ...record, productId, quantity, commissionScript: { params, templateId } }
    this.create(record)
  }

  create = record => {
    let { onDone, onError } = this.props
    Provider.dataProvider('CREATE', 'agencyproducts', {
      data: { ...record, platformCommissionScript: DEFAULT_1_PERCENT }
    }).then((res) => {
      onDone && onDone(res.data)
    }).catch(() => {
      onError && onError()
    })
  }

  renderTemplate = (parameters) => {
    let { percent, includePaymentFee, fee, range } = parameters || {}
    let { translate } = this.props
    return <div>
      {percent && <PercentInput
        range={range}
        label={translate('resources.commissiontemplates.fields.params.value')}
        source="params.percent"
        fullWidth
      />}
      {fee && <MoneyInput
        label="resources.commissiontemplates.fields.params.value"
        source="params.fee"
        fullWidth
      />}
      {includePaymentFee && <IncludePaymentFeeInput
        label={translate('resources.commissiontemplates.fields.params.includePaymentFee')}
        source="params.includePaymentFee"
      />}
    </div>
  }

  handleChangeTemplate = (evt, value) => {
    let { reset, form  } = this.props
    let { commissionTemplateMapping  } = this.state
    let template = commissionTemplateMapping[value]
    this.setState({ template })
    reset(form)
  }

  render() {
    let { form, productId, translate, record } = this.props
    let { commissionTemplateChoices } = this.state
    let { template = {} } = this.state
    let { parameters = {}, id: templateId } = template
    let currentAgency = getCurrentAgency() || {}
    let currentAgencyId = currentAgency.id
    return <SimpleForm
      record={record}
      form={form}
      basePath="/routeproducts"
      resource="agencyproducts"
      save={this.onSave}
      validate={(value) => {
        let { percent: percentTemplate } = parameters || {}
        let { params = {} } = value
        let { fee, percent } = params
        let error = { params: {} }
        if (percentTemplate) {
          if (percent && (percent < 0 || percent > 100)) {
            error.params.percent = translate('error_messages.invalid.commission.invalid_percent_value')
          }
        }
        if (fee && fee < 0) {
          error.params.fee = translate('error_messages.invalid.commission.invalid_value')
        }
        return error
      }}
      toolbar={<CustomToolbar />}
    >
      <ReferenceInput
        reference="agencies"
        source="agencyId"
        fullWidth
        perPage={1000}
        validate={required()}
        filter={{
          id: { neq: currentAgencyId },
          '../ignore': {
            agencyRegisteredProduct: { productId },
          },
          '../fields': ['id', 'name'],
        }}
      >
        <FuzzySelectInput renderItem={item => item.name} {...defaultFuzzySearch()} />
      </ReferenceInput>
      <SelectInput
        label="resources.commissiontemplates.fields.templateId"
        source="templateId"
        choices={commissionTemplateChoices}
        optionText="name"
        defaultValue={templateId}
        fullWidth
        onChange={this.handleChangeTemplate}
      />
      <Field
        name="commissionScriptValue"
        fullWidth
        component={() => this.renderTemplate(parameters)}
      />
      <RadioButtonGroupInput
        choices={quantityOptions}
        defaultValue={'NO_LIMIT'}
        source="agencyQuantityType"
        fullWidth
      />
      <FormDataConsumer>
        {({ formData = {} }) => {
          let { agencyQuantityType } = formData
          return <Fragment>
            {agencyQuantityType === 'OTHER' ? <TextInput
              autoFocus
              defaultValue={0}
              source="agencyQuantity"
              resource="agencyproducts"
              type="number"
              fullWidth
              inputProps={{
                min: 0,
                onKeyPress: (e) => {
                  if (e.which !== 8 && e.which !== 0 && (e.which < 48 || e.which > 57)) {
                    e.preventDefault()
                  }
                }
              }}
            /> : null}
          </Fragment>
        }}
      </FormDataConsumer>
    </SimpleForm>
  }
}

const enhance = compose(translate, connect(null, { reset }))

export default enhance(AgencyProductForm)
