import React, { Component, Fragment } from 'react'
import { addField, TextInput } from 'react-admin'
import { quantityOptions, OTHER, NO_LIMIT } from './constant'
import RadioGroupCustom from '../common/RadioGroupCustom'


class QuantityInput extends Component {

  state = {}

  static getDerivedStateFromProps(nextProps, prevState) {
    let { input } = nextProps
    let { value } = input
    let { currentValue } = prevState
    if (value !== currentValue) {
      let quantityType = value < 0 ? NO_LIMIT : OTHER
      return { ...prevState, quantityType, value }
    }
    return { ...prevState }
  }

  handleChangeQuantityType = (evt, value) => {
    let { input } = this.props
    if (value === NO_LIMIT) {
      input.onChange(-1)
    } else {
      input.onChange(0)
    }
    this.setState({ quantityType: value })
  }

  render() {
    let { input, extra } = this.props
    let { quantityType } = this.state
    return <Fragment>
      <RadioGroupCustom
        choices={quantityOptions}
        onChange={this.handleChangeQuantityType}
        value={quantityType}
        source="quantityType"
        {...extra}
      />
      {quantityType === OTHER ? <TextInput
        value={input.value}
        source="quantity"
        type="number"
        {...extra}
      /> : null}
    </Fragment>
  }
}

export default addField(QuantityInput)
