import React, { Component } from 'react'
import {
  translate,
  SimpleShowLayout,
  TextField,
  ReferenceField,
} from 'react-admin'
import compose from 'recompose/compose'

class FormView extends Component {

  render() {
    let { record } = this.props
    const extra = {
      resource: 'receiveplaces',
      fullWidth: true,
    }

    return <SimpleShowLayout
      record={record}
      basePath="/receiveplaces"
      {...extra}
    >
      <TextField
        label="resources.receiveplaces.fields.name"
        source="name"
        required
        fullWidth
        { ...extra }
      />
      <TextField
        label="resources.receiveplaces.fields.code"
        source="code"
        fullWidth
        { ...extra }
      />
      <TextField
        label="resources.receiveplaces.fields.address"
        source="address"
        fullWidth
        { ...extra }
      />
      <ReferenceField
        reference="stops"
        label="resources.receiveplaces.fields.zones"
        source="parentId"
        linkType={false}
        allowEmpty
        fullWidth
        {...extra}
      >
        <TextField source="name" />
      </ReferenceField>
    </SimpleShowLayout>
  }
}

const enhance = compose(translate)

export default enhance(FormView)
