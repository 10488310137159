import React, { Component } from 'react'
import get from 'lodash/get'
import {
  FormLabel,
  FormControl,
  FormGroup,
  FormControlLabel,
  FormHelperText,
  Checkbox,
  withStyles,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { addField, translate, FieldTitle } from 'ra-core'
import PropTypes from 'prop-types'

const styles = theme => ({
  root: {},
  label: {
    transform: 'translate(0, 5px) scale(0.75)',
    transformOrigin: `top ${theme.direction === 'ltr' ? 'left' : 'right'}`,
  },
})

class CheckboxGroupInput extends Component {

  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.object,
    input: PropTypes.object,
    optionText: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    optionValue: PropTypes.string,
    choices: PropTypes.array,
    direction: PropTypes.string,
    defaultValue: PropTypes.object,
  }

  componentDidMount() {
    let { defaultValue, input } = this.props
    input = {...input, value: defaultValue }
    input.onChange(defaultValue)
  }

  handleCheck = (event, isChecked, typeOfValue) => {
    const {
      input: { value, onChange },
    } = this.props
    let newValue = event.target.value
    if (typeOfValue === 'number') {
      newValue = parseInt(newValue)
    }
    if (typeof newValue === 'object') {
      newValue = JSON.parse(newValue)
    }
    if (isChecked) {
      onChange([...(value || []), ...[newValue]])
    } else {
      onChange(value.filter(v => v !== newValue))
    }
  }

  renderCheckbox = choice => {
    const {
      id,
      input: { value },
      optionText,
      optionValue,
    } = this.props
    const val = get(choice, optionValue)
    let typeOfValue = typeof val
    const choiceName = React.isValidElement(optionText)
      ? React.cloneElement(optionText, { record: choice })
      : typeof optionText === 'function'
        ? optionText(choice)
        : get(choice, optionText)
    return (
      <FormControlLabel
        htmlFor={`${id}_${val}`}
        key={val}
        checked={value && value.includes(val)}
        onChange={(event, isChecked) => this.handleCheck(event, isChecked, typeOfValue)}
        value={String(val)}
        control={
          <Checkbox
            id={`${id}_${val}`}
            color="primary"
          />
        }
        label={choiceName}
      />
    )
  }

  render() {
    const {
      choices,
      className,
      classes = {},
      isRequired,
      label,
      meta,
      resource,
      source,
      direction,
    } = this.props
    const { touched, error, helperText = false } = meta
    return (
      <FormControl
        className={className}
        component="fieldset"
        margin="normal"
      >
        <FormLabel component="legend" className={classes.label}>
          <FieldTitle
            label={label}
            source={source}
            resource={resource}
            isRequired={isRequired}
          />
        </FormLabel>
        <FormGroup row={direction==='row'}>{choices.map(this.renderCheckbox)}</FormGroup>

        {touched && error && <FormHelperText error>{error}</FormHelperText>}
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    )
  }
}

CheckboxGroupInput.defaultProps = {
  choices: [],
  options: {},
  optionText: 'name',
  optionValue: 'id',
}

const enhance = compose(
  addField,
  translate,
  withStyles(styles)
)

export default enhance(CheckboxGroupInput)
