import _ from 'lodash'

export function formatCurrency (value, locale, currencyUnit) {
  if (!value) return ''
  if (typeof value === 'string') {
    let regex = /^\d+/g
    if (regex.test(value)) {
      value = parseFloat(value)
    } else {
      value = 0
    }
  }
  if (!locale) {
    locale = 'vi-VN'
    currencyUnit = 'đ'
  }
  return `${value.toLocaleString(locale)}${currencyUnit}`
}

export const DEFAULT_PHONE_NUMBER_FORMAT = /(\d{3})(\d{3})(\d{4})/

export function formatPhoneNumber(value, format = DEFAULT_PHONE_NUMBER_FORMAT) {
  value = value.replace(/[^\d]/g, '')
  if (value.length === 10) {
    return value.replace(format, '$1 $2 $3')
  }
  return value
}

export function removeLeadingZero(value) {
  if (!value) return
  let isPercent = value && _.endsWith(value, '%')
  if (!isPercent) {
    value = parseFloat(value).toString() 
  } else {
    value = `${parseFloat(value)}%`
  }
  return value
}

export function formatNumber(value) {
  if (!value) return
  let isPercent = value && _.endsWith(value, '%')
  if (!isPercent) {
    value = formatCurrency(parseFloat(value)) 
  } else {
    value = `${parseFloat(value)}%`
  }
  return value
}

export function validateNumberOrPercentNumber(strValue) {
  let regexPercentNumber = /^\d+\.?\d*%$/
  let regexNumber = /^\d+\.?\d*$/
  if (regexPercentNumber.test(strValue)) {
    let value = parseFloat(strValue)
    if (value > 100) {
      return false
    }
    return true
  } else if (regexNumber.test(strValue)) {
    return true
  }
  return false
}

export function limitTextLength(text, maxLengthToShow) {
  return text ? (text.length > maxLengthToShow ? `${text.substring(0, maxLengthToShow - 3)}...` : text) : ''
}

