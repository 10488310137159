import React, { Component } from 'react'
import { translate } from 'react-admin'
import 'leaflet/dist/leaflet.css'
import compose from 'recompose/compose'
import { MenuList, MenuItem, ListItemIcon, Paper, Fade, withStyles } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classnames from 'classnames'

const styles = (theme) => {
  return {
    container: {
      display: 'flex',
      height: '100%'
    },
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    primary: {},
    icon: {
      marginRight: 0
    },
    menu: {},
    contentContainer: {}
  }
}

const renderFrame = (frame) => {
  let element = frame.node.element
  let typeOfElement = typeof element
  let component = null

  switch (typeOfElement) {
    case 'object':
      component = element
      break
    case 'function':
      component = element()
      break
    default:
      break
  }

  return component
}

class MapMenu extends Component {

  constructor(props) {
    super(props)
    this.state = {
      frames: [],
      activeFrame: '',
      frameIndexes: {}
    }
  }

  static getDerivedStateFromProps = (newProps, state) => {
    let newFrames = newProps.frames
    let totalNewFrame = newFrames ? newFrames.length : 0
    let indexes = {}
    for (let i = 0; i < totalNewFrame; i++) {
      let frame = newFrames[i]
      indexes[frame.identifier] = frame
    }

    return {
      frames: newFrames,
      frameIndexes: indexes
    }
  }

  render() {
    let { classes } = this.props
    let { activeFrame, frames } = this.state

    return (
      <div className={classes.container}>
        <div style={{ backgroundColor: '#ccc', display: 'flex', alignItems: 'center' }}>
          <MenuList style={{ width: '100%' }}>
            {frames && frames.map(frame => {
              return (
                <MenuItem key={frame.identifier}
                  className={classes.menuItem}
                  onClick={() => {
                    if (activeFrame !== frame.identifier) {
                      this.setState({ activeFrame: frame.identifier })
                    } else {
                      this.setState({ activeFrame: '' })
                    }
                  }}>
                  <ListItemIcon className={classes.icon}>
                    <FontAwesomeIcon icon={frame.node.icon} />
                  </ListItemIcon>
                </MenuItem>
              )
            })}
          </MenuList>
        </div>

        {frames && frames.map(frame => {
          if (frame.identifier === activeFrame) {
            return (
              <Fade key={frame.identifier} in={frame.identifier === activeFrame}>
                <Paper elevation={4} key={frame.identifier} className={classnames(classes.contentContainer)}>
                  {frame ? renderFrame(frame) : ''}
                </Paper>
              </Fade>
            )
          } else {
            return ('')
          }
        })
        }
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(MapMenu)
