import { withStyles } from '@material-ui/core'
import React, { createRef, Component } from 'react'
import { translate } from 'react-admin'
import 'leaflet/dist/leaflet.css'
import './map.css'
import Leaflet from 'leaflet'
import compose from 'recompose/compose'
import { Map as OSM, TileLayer, GeoJSON, Marker, Popup, ZoomControl, ScaleControl } from 'react-leaflet'
import { MapProps } from './../common/constants'
import MapMenu from './MapMenu'
import classnames from 'classnames'
import {
  mapMove
} from './actions'
import { connect } from 'react-redux'

import TransitiveLayer from './transitive-layer'
import * as transitiveStyles from './transitive-styles'

import RouteLayer from './route-layer'

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const styles = (theme) => {
  return {
    container: {
      position: 'relative'
    },
    layer: {
      position: 'absolute',
    },
    itemContainer: {
      padding: 0
    },
    layerControl: {
      zIndex: 401,
    },
    menuItem: {
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
        '& $primary, & $icon': {
          color: theme.palette.common.white,
        },
      },
    },
    icon: {
      marginRight: 0
    },
  }
}

export const BaseLayers = {
  OSM_LAYER: {
    identifier: 'OSM_LAYER',
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    type: 'tilelayer',
    selected: true
  },
  MAPNIK_LAYER: {
    identifier: 'MAPNIK_LAYER',
    name: 'OpenStreetMap - BW',
    url: 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
    attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    type: 'tilelayer',
    selected: false
  }
}

const renderLayerComponent = (v) => {
  const visible = v.visibility && v.visibility === MapProps.LayerStates.VISIBLE
  switch (v.type) {
    case MapProps.LayerTypes.TILE:
      return <TileLayer attribution={v.attribution}
        type={v.type}
        url={v.url}
        key={v.identifier}
        identifier={v.identifier} />
    case MapProps.LayerTypes.GEO_JSON:
      if (visible) {
        let actions = v.actions
        return (
          <GeoJSON data={v.data} type={v.type}
            identifier={v.identifier}
            key={v.identifier} color={v.color}
            onEachFeature={(feature, layer) => {
              layer.on({
                mouseover: () => {
                  actions && actions.mouseover && actions.mouseover(feature, layer)
                  layer.bringToFront()
                },
                mouseout: () => {
                  actions && actions.mouseout && actions.mouseout(feature, layer)
                  layer.bringToBack()
                }
              })
            }
            } />
        )
      }
      break
    case MapProps.LayerTypes.MARKER:
      if (visible) {
        return (
          <Marker position={v.data.coordinates} key={v.identifier} identifier={v.identifier}>
            <Popup>{v.name}</Popup>
          </Marker >
        )
      }
      break
    case MapProps.LayerTypes.TRANSITIVE:
      if (visible) {
        return (
          <TransitiveLayer data={ v.data } renderer={ 'default' }
            styles={ transitiveStyles.STYLES}
          />
        )
      }
      break
    case MapProps.LayerTypes.ROUTE:
      if (visible) {
        return (
          <RouteLayer data={v.data} type={v.type}
            identifier={v.identifier}
            key={v.identifier} color={v.color} />
        )
      }
      break
    default:
      return null
  }
}

const renderLayers = (layers) => {
  let array = []
  let totalLayer = layers && layers.length
  for (let idx = 0; idx < totalLayer; idx++) {
    let layer = layers[idx]
    let component = renderLayerComponent(layer)
    array.push(component)
  }
  return array
}

class Map extends Component {

  constructor(props) {
    super(props)
    this.map = createRef()
    let { center, zoom, baseLayer, layers } = this.props

    baseLayer = baseLayer || BaseLayers.OSM_LAYER

    this.state = {
      zoom: zoom ? zoom : 13,
      center,
      baseLayer: baseLayer,
      layers: layers,
      layerContainer: {
        baseLayer: {},
        totalLayer: 0,
        layerMap: {},
        layers: []
      }
    }
  }

  handleMoveend(event) {
    let { onMoveEnd, mapMove } = this.props

    const leafletElem = event.target
    const zoom = leafletElem.getZoom()
    const center = leafletElem.getCenter()
    const bbox = leafletElem.getBounds()

    let update = {
      zoom,
      center,
      bbox: [bbox.getEast(), bbox.getNorth(), bbox.getWest(), bbox.getSouth()]
    }
    onMoveEnd && onMoveEnd({ zoom, center })
    this.setState(update)

    mapMove(update)
  }

  handleLatChange = (event) => {
    let value = Number(event.target.value)
    value = value.toFixed(6)
    let update = { lat: value }

    this.setState(update)
  }

  handleLonChange = (event) => {
    let value = Number(event.target.value)
    value = value.toFixed(6)
    let update = { lon: value }
    this.setState(update)
  }

  static getDerivedStateFromProps = (newProps, state) => {
    let { zoom, center, geojson, layers } = newProps

    return { zoom, center, geojson, layers }
  }

  onAddLayer = (k) => {
    let { layerContainer } = this.state
    let { identifier, type } = k.layer.options
    let baseLayer = layerContainer.baseLayer
    let layerMap = layerContainer.layerMap
    let layers = layerContainer.layers

    switch (type) {
      case MapProps.LayerTypes.TILE:
        baseLayer = k.layer
        break
      case MapProps.LayerTypes.GEO_JSON:
        layerMap[identifier] = k.layer
        layers.push(k.layer)
        break
      case MapProps.LayerTypes.TRANSITIVE:
        layerMap[identifier] = k.layer
        layers.push(k.layer)
        break
      case MapProps.LayerTypes.ROUTE:
        layerMap[identifier] = k.layer
        layers.push(k.layer)
        break
      default:
        break
    }
    this.setState({
      layerContainer: {
        ...layerContainer,
        totalLayer: layerContainer.totalLayer + 1,
        layers,
        baseLayer,
        layerMap
      },
    })
  }

  onRemoveLayer = (k) => {
    let { layerContainer } = this.state
    let identifier = k.layer.options.identifier
    let layers = layerContainer.layers
    delete layers[identifier]
    this.setState({ layerContainer: { ...layerContainer, 
      totalLayer: layerContainer.totalLayer - 1, layers } })
  }

  render() {
    let { classes, style, frames, onClick, minZoom } = this.props
    let { zoom, bounds, baseLayer, layers, center } = this.state

    return (
      <div className={classes.container}>

        <div className={classnames(classes.layer, classes.layerControl)}
          style={{ height: style.height }}>
          <MapMenu
            style={{ height: style.height }}
            frames={frames} />
        </div>

        <div
          className={classes.layer}
          style={style}>

          <OSM center={center}
            ref={this.map}
            zoom={zoom}
            minZoom={minZoom}
            bounds={bounds}
            style={style}
            onClick={(k) => {
              onClick && onClick(k)
            }}
            onlayeradd={(k) => this.onAddLayer(k)}
            onlayerremove={(k) => this.onRemoveLayer(k)}
            onMoveend={(event) => this.handleMoveend(event)}
          >
            <ZoomControl position="topright" />
            <ScaleControl position="bottomright" />

            {renderLayers([baseLayer])}
            {renderLayers(Object.values(layers))}

            <RouteLayer />
          </OSM>
        </div>
      </div>
    )
  }
}

Map.defaultProps = {
  style: { width: '100%', height: 300 },
}

const enhance = compose(
  withStyles(styles),
  translate,
  connect(
    (state) => {
      let { layers, center, zoom, bbox } = state.map
      return { layers, center, zoom, bbox }
    }, {
      mapMove
    })
)

export default enhance(Map)
