import React, { Component } from 'react'
import { translate, addField } from 'react-admin'
import {
  InputLabel,
  MenuItem,
  FormControl,
  FormHelperText,
  Input,
  Select,
  withStyles,
} from '@material-ui/core'
import compose from 'recompose/compose'
import get from 'lodash/get'
import { isArray } from 'util'
import classnames from 'classnames'

const styles = () => ({
  formControl: {
    marginBottom: 8,
    marginTop: 16,
  }
})

class SelectInputCustom extends Component {

  state = {}

  componentDidMount() {
    let { defaultValue, defaultOption, choices, input, optionValue } = this.props
    let field = optionValue
    let value
    if (defaultValue) {
      value = defaultValue
    } else if (defaultOption) {
      field = defaultOption.field
      value = defaultOption.value
    }
    if (!value) return
    if (choices && choices.length > 0) {
      for (let i = 0; i < choices.length; i++) {
        let choice = choices[i]
        if (get(choice, field) === value) {
          let valueInput = get(choice, optionValue)
          input = { ...input, value: valueInput }
          input.onChange(valueInput)
        }
      }
    }
  }

  onChange = e => {
    let { input } = this.props
    let value = e.target.value
    input = { ...input, value }
    input.onChange(value)
  }

  renderComponent = (choice) => {
    let { translate, optionText, isTranslateText } = this.props
    if (React.isValidElement(optionText))
      return React.cloneElement(optionText, {
        record: choice,
      })
    const choiceName =
      typeof optionText === 'function'
        ? optionText(choice)
        : get(choice, optionText)
    return isTranslateText
      ? translate(choiceName, { _: choiceName })
      : choiceName
  }

  isRender = (choice) => {
    let { filter } = this.props
    if (filter && filter.length) {
      for (let i = 0; i < filter.length; i++) {
        let { field, value } = filter[i]
        if (isArray(value)) {
          if (value.indexOf(get(choice, field)) === -1) return false
        } else {
          if (get(choice, field) !== value) return false
        }
      }
    }
    return true
  }

  render() {
    let {
      classes,
      choices = [],
      label,
      allowEmpty,
      optionValue = 'id',
      fullWidth,
      translate,
      input,
      all,
      empty,
      meta,
      classExtra,
      required,
      disabled = false,
    } = this.props
    let { error, touched } = meta
    const defaultChoices = get(this.props, 'defaultChoices')
    choices = defaultChoices || choices
    return (
      <FormControl
        required={required}
        error={Boolean(error) && touched}
        className={classnames(classes.formControl, classExtra)}
        margin="none"
        fullWidth={fullWidth}
        disabled={disabled}
      >
        <InputLabel htmlFor="select-custom">{label ? translate(label) : label}</InputLabel>
        <Select
          value={input.value}
          onChange={this.onChange}
          input={<Input />}
          fullWidth
        >
          {allowEmpty && (
            <MenuItem value="">
              {(all || empty) ? <em>{choices && choices.length > 0 ? all : empty}</em> : <em></em>}
            </MenuItem>
          )}
          {choices.map((choice, key) => this.isRender(choice) && (
            <MenuItem key={key} value={get(choice, optionValue)}>
              {this.renderComponent(choice)}
            </MenuItem>
          ))}
        </Select>
        {touched && error && <FormHelperText>{error}</FormHelperText>}
      </FormControl>
    )
  }
}

SelectInputCustom.defaultProps = {
  optionValue: 'id',
  choices: [],
  isTranslateText: true,
  optionText: 'name'
}


const enhance = compose(
  translate,
  withStyles(styles),
  addField
)

export default enhance(SelectInputCustom)
