import React, { Component } from 'react'
import { Provider } from '../provider'
import { SelectArrayInput } from 'react-admin'
import { addField } from 'ra-core'

const getCurrentCompanyId = () => {
  let currentUser = localStorage.getItem('user')
  if (!currentUser) return {}
  let user = JSON.parse(currentUser)
  let { data = {} } = user 
  let { company = {} } = data
  return company.id || 0
}

const optionRender = choice => {
  let { app, device } = choice
  let { name: appName } = app
  let { name: deviceName } = device
  return `[${deviceName}]-${appName}`
}

const principalMapping = {
  devices: {
    resource: 'devices',
    optionText: 'name',
    filter: {},
    method: 'getDevicesByOperator',
  },
  apps: {
    resource: 'apps',
    optionText: optionRender,
    filter: { },
    method: 'getAppsByOperator'
  },
}

class Principal extends Component {

  state = {
    principals: [],
    optionText: ''
  }

  async componentDidMount() {
    let { principalType } = this.props
    let principalTypeObj = principalMapping[principalType] || {}
    let { resource, method, filter, optionText } = principalTypeObj
    let operatorId = getCurrentCompanyId()
    filter = { ...filter, operatorId }
    let res = await Provider.dataProvider('REMOTE', resource, {
      method,
      requestMethod: 'GET',
      data: { ...filter }
    })
    if (res && res.data) {
      this.setState({ principals: res.data, optionText })
    }
  }

  render() {
    let { principals, optionText } = this.state
    return <SelectArrayInput
      choices={principals}
      optionText={optionText}
      fullWidth
      {...this.props}
    />
  }
}

export default addField(Principal)