import { hourToMin } from '../../utils/time'

export class DescriptionBlock {
  constructor(stopNames, times) {
    this.stopNames = stopNames
    this.times = times
  }
}

/*
    stops: {
      "1": 0,
      "2": 8,
      "3": 5,
      "4": 8,
      "5": 8,
    }

    timetableRows: {
      "1": {
        "index": 1,
        "value": "09:00",
        "base": 0,
        "step": 0
      },
      "2": {
        "index": 2,
        "value": "09:08",
        "base": 8,
        "step": 8
      },
      "3": {
        "index": 3,
        "value": "09:13",
        "base": 5,
        "step": 5
      },
      "4": {
        "index": 4,
        "value": "09:21",
        "base": 8,
        "step": 8
      },
      "5": {
        "index": 5,
        "value": "09:29",
        "base": 8,
        "step": 8
      },
      "index": 0
    },
*/
export class Block {
  rows = []
  constructor(stops, timetableRows, patternId, descriptionBlock) {
    let rows = []
    for (var i = 1; i < 100; i++) {
      if (!(i in timetableRows)) break
      let row = timetableRows[i]
      rows.push({
        min: hourToMin(row.value),
        stop: stops[i-1],
      })
    }
    this.patternId = patternId
    this.rows = rows
    this.min = rows[0].min
    this.lastStop = rows[stops.length-1]
    this.firstStop = rows[0]
    this.descriptionBlock = descriptionBlock
  }
}