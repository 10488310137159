import { Provider } from '../provider'

export const getRouteGroupInfo = async(routeGroupId) => {
  try {
    let response = await Provider.dataProvider('GET_ONE', 'routegroups', {
      id: routeGroupId,
      filter: {
        fields: ['id', 'name'],
      },
    })
    if (response && response.data) {
      return response.data
    }
  } catch (e) {
    return null
  }
}
