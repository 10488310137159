import { GET_MANY, GET_LIST } from 'react-admin'


// -----------------------------------------------------------------------------
export const GET_LIST_SERVICE_DAYS = 'GET_LIST_SERVICE_DAYS'
export const GET_LIST_SERVICE_DAYS_FAILURE = 'GET_LIST_SERVICE_DAYS_FAILURE'
export const GET_LIST_SERVICE_DAYS_SUCCESS = 'GET_LIST_SERVICE_DAYS_SUCCESS'

export const getListServiceDays = (start, done, params) => {
  return ({
    type: GET_SERVICE_DAYS,
    payload: params,
    meta: {
      resource: 'servicedays',
      fetch: GET_LIST,
    },
    start,
    done,
  })
}

// -----------------------------------------------------------------------------
export const GET_SERVICE_DAYS = 'GET_SERVICE_DAYS'
export const GET_SERVICE_DAYS_FAILURE = 'GET_SERVICE_DAYS_FAILURE'
export const GET_SERVICE_DAYS_SUCCESS = 'GET_SERVICE_DAYS_SUCCESS'

export const getSelectedServiceDays = (start, done, params) => {
  return ({
    type: GET_SERVICE_DAYS,
    payload: {
      ids: params.ids
    },
    meta: {
      resource: 'servicedays',
      fetch: GET_MANY,
    },
    start,
    done,
  })
}

