import React, { Component } from 'react'
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import CloseForm from './CloseForm'

const dialogStyle = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
}

class CloseDialog extends Component {

  render() {
    let {
      open,
      onClose,
      onOpenConfirm,
      title,
      posSessionId,
      classes,
    } = this.props
    return <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>{title}</span>
      </DialogTitle>
      <DialogContent>
        <CloseForm
          posSessionId={posSessionId}
          onOpenConfirm={onOpenConfirm}
          onClose={onClose}
        />
      </DialogContent>
    </Dialog>
  }
}

const ehanceCloseDialog = compose(translate, withStyles(dialogStyle))
export default ehanceCloseDialog(CloseDialog)
