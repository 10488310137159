import React, { Component } from 'react'
import {
  Dialog,
  DialogTitle,
  IconButton,
} from '@material-ui/core'
import { translate } from 'react-admin'
import ServiceReminderFormSave from '../calendar/ServiceReminderFormSave'

class ReminderFormSave extends Component {

  render() {
    let {
      open,
      servicetaskRecord = {},
      handleClose,
      record,
      isCreate,
      principal,
      principalType,
      done,
      error,
      translate
    } = this.props
    return (
      <Dialog
        open={open}
        onClose={() => handleClose()}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>{translate('resources.servicereminders.title.update')}</DialogTitle>
        <ServiceReminderFormSave
          record={record}
          isCreate={isCreate}
          done={done}
          error={error}
          servicetaskRecord={servicetaskRecord}
          principal={principal}
          principalType={principalType}
        />
      </Dialog>
    )
  }
}

class ReminderButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      elements: [],
      typeObj: {},
    }
  }

  openDialog = () => {
    this.setState({
      open: true,
    })
  }

  done = () => {
    let { doneFunc } = this.props
    if (doneFunc) {
      doneFunc()
    } else {
      this.setState({
        open: false,
      })
    }
  }
  error = () => {
    let { errorFunc } = this.props
    if (errorFunc) {
      errorFunc()
    } else {
      this.setState({
        open: false,
      })
    }
  }

  handleClose = () => {
    let { isCreate } = this.props
    let { isRepeat } = this.state
    if (isCreate) {
      isRepeat = false
    }
    this.setState({
      open: false,
      isRepeat
    })
  }

  render() {
    let {
      servicetaskRecord,
      isCreate,
      reminderRecord,
      principal,
      principalType,
      icon,
      translate,
    } = this.props
    let { open } = this.state
    return (
      <>
        <IconButton onClick={this.openDialog}>
          {icon}
        </IconButton>
        <ReminderFormSave
          principal={principal}
          record={reminderRecord}
          open={open}
          handleClose={this.handleClose}
          servicetaskRecord={servicetaskRecord}
          isCreate={isCreate}
          principalType={principalType}
          done={this.done}
          error={this.error}
          translate={translate}
        />
      </>
    )
  }
}

export default translate(ReminderButton)