import {
  ExpansionPanelDetails,
  ExpansionPanel,
  ExpansionPanelSummary,
  Chip,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  SimpleForm,
  TextInput,
  translate,
  ReferenceField,
  TextField
} from 'react-admin'
import React, { Component } from 'react'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import {
  doAction, RESERVATION_UPDATE_PASSENGER_INFORMATION
} from './actions'

const styles = (theme) => {
  return ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    icon: {
      verticalAlign: 'bottom',
      height: 20,
      width: 20,
    },
    details: {
      alignItems: 'center',
    },
    column: {
      flexBasis: '20%',
    },
    helper: {
      borderLeft: `2px solid ${theme.palette.divider}`,
      padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    },
    link: {
      color: theme.palette.primary.main,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    }
  })
}

class PassengerInfo extends Component {
  render() {
    let { classes, translate, passenger, onChange } = this.props
    let { firstName, lastName, phone, email, address } = passenger
    let record = { firstName, lastName, phone, email, address }

    return (
      <SimpleForm
        form={`passenger-info-form-${passenger.index}`}
        toolbar={null}
        record={record}>
        <Grid container className={classes.root}>
          <Grid item xs={6}>
            <TextInput
              label={translate('resources.reservations.passenger.firstName')}
              source="firstName"
              onChange={(e, value) => onChange(passenger.index, { firstName: value })}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              label={translate('resources.reservations.passenger.lastName')}
              source="lastName"
              onChange={(e, value) => onChange(passenger.index, { lastName: value })}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              label={translate('resources.reservations.passenger.phone')}
              source="phone"
              onChange={(e, value) => onChange(passenger.index, { phone: value })}
              required
              fullWidth
            />
          </Grid>

          <Grid item xs={6}>
            <TextInput
              label={translate('resources.reservations.passenger.email')}
              source="email"
              type="email"
              onChange={(e, value) => onChange(passenger.index, { email: value })}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              label={translate('resources.reservations.passenger.address')}
              source="address"
              onChange={(e, value) => onChange(passenger.index, { address: value })}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    )
  }
}

class PassengerView extends Component {

  getPassengerName = (passenger) => {
    if (!passenger) {
      return ''
    }

    let { firstName, lastName, phone } = passenger
    let passengerName = ''
    if (lastName && firstName) {
      passengerName = `${lastName}, ${firstName}- ${phone}`
    } else {
      passengerName = phone
    }

    return passengerName
  }

  render() {
    let { classes, translate, passenger, charge, onChange } = this.props
    return (
      <div className={classes.root}>
        <br />

        <ExpansionPanel>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            <div className={classes.column}>
              <Typography className={classes.heading}>
                {this.getPassengerName(passenger)}
              </Typography>
            </div>

            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>
                {`${translate('resources.reservations.selectedSeat')}:`}
                <b>({charge && charge.itemDetail.itemCode})</b>
              </Typography>
            </div>

            <div className={classes.column}>
              <Typography className={classes.secondaryHeading}>
                {`${translate('resources.reservations.selectedTrip')}:`}
                <b>
                  <ReferenceField
                    label="Trip"
                    source="tripId"
                    reference="trips"
                    basePath="/trips"
                    record={charge}
                    linkType={false}
                  >
                    <TextField source="name" />
                  </ReferenceField>
                </b>
              </Typography>
            </div>
          </ExpansionPanelSummary>

          <ExpansionPanelDetails className={classes.details}>
            <div className={classes.column} />
            <div className={classes.column}>
              <Chip label={charge && charge.itemDetail.itemCode} className={classes.chip} />
            </div>

            <div className={classes.helper}>
              <PassengerInfo
                classes={classes}
                translate={translate}
                passenger={passenger}
                onChange={onChange} />
            </div>
          </ExpansionPanelDetails>
          {/* <Divider />
          <ExpansionPanelActions>
            <Button size="small" color="primary">
              {translate('button.save')}
            </Button>
          </ExpansionPanelActions> */}
        </ExpansionPanel>
      </div>
    )
  }
}

class PassengersStep extends Component {

  constructor(props) {
    super(props)
    this.state = {
    }
  }

  onPassengerInfoChange = (passengerIndex, updatedData) => {
    let { doAction } = this.props
    doAction(
      RESERVATION_UPDATE_PASSENGER_INFORMATION,
      null,
      { passengerIndex, updatedData }
    )
  }

  render() {
    let { classes, translate, passengers, charges } = this.props
    return passengers && passengers.map((passenger, idx) => {
      let charge = charges.find(e => e.passenger.index === passenger.index)
      return <PassengerView
        key={idx}
        classes={classes}
        translate={translate}
        passenger={passenger}
        charge={charge}
        onChange={(passengerIndex, updateData) =>
          this.onPassengerInfoChange(passengerIndex, updateData)} />
    })
  }
}
const enhance = compose(
  withStyles(styles),
  translate,
  connect((state) => {
    let { reservation } = state
    let { passengers, charges } = reservation
    return { passengers, charges }
  },
  {
    doAction
  }
  )
)

export default enhance(PassengersStep)
