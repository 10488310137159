import { SSF } from 'xlsx'
import { hourToMin } from '../../utils/time'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import slugify from 'voca/slugify'

const getBlockNumber = row => {
  let { number } = row
  if (!number) {
    number = row['Xe'] || row['xe']
  }
  return number
}

const getTime = row => {
  let { time } = row
  if (!time) {
    time = row['Thoi gian'] || row['thoi gian'] || row['Thời gian'] || row['thời gian']
  }
  return time
}

const getPattern = (row, patternLength) => {
  let { pattern } = row
  if (!pattern) {
    pattern = row['chieu'] || row['Chieu'] || row['chiều'] || row['Chiều'] || ''
    pattern = slugify(pattern)
    if (pattern === 'di') {
      pattern = 0
    } else if (pattern === 've') {
      pattern = 1
    }
  }
  return pattern % patternLength
}

export function normalize(rows, patterns) {
  if (!rows || rows.length === 0) return 0
  const patternIds = uniqBy(patterns, 'patternId').map(v => v.patternId)
  if (!patternIds || patternIds.length < 2) return 0
  let patternLength = patternIds.length
  let lengthRows = rows.length
  let blockObj = {}
  let idx = 0
  // sorts by vehicle number
  rows = sortBy(rows, (row) => parseInt(row.number))
  for (let i = 0; i < lengthRows; i++) {
    let row = rows[i]
    let { number, time, min, patternId, pattern } = row

    // if number, time, pattern not valiable
    // Xe, Thời gian, Chiều (đi, về)
    if (!number) {
      number = getBlockNumber(row)
    }
    if (!time) {
      time = getTime(row)
    }
    if (!pattern) {
      pattern = getPattern(row, patternLength)
    }    

    if (typeof number === 'string') {
      number = number.trim()
    }
    if (number) {
      let blockVehicle = blockObj[number]
      if (!patternId) {
        // modulo patternLength to ensure the index is in range
        patternId = patternIds[pattern % patternLength]
      }
      if (!min) {
        if (time && !SSF.is_date(time)) {
          time = SSF.format('HH:mm', time)
        }
        min = hourToMin(time) || 0
      }
      let trip = { patternId, min }
      if (blockVehicle) {
        let { trips } = blockVehicle
        trips.push(trip)
        blockVehicle.trips = trips
        blockObj[number] = blockVehicle
      } else {
        blockObj[number] = {
          idx: idx++,
          number: number,
          trips: [trip]
        }
      }
    }
  }
  return Object.values(blockObj)
}