import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import compose from 'recompose/compose'

const styles = () => ({
  preview: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
  },
})

class PreviewFile extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imgUrl: ''
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { imgUrl } = nextProps
    let currentImgUrl = prevState.imgUrl
    if (currentImgUrl !== imgUrl) {
      currentImgUrl = imgUrl
    }
    return {
      imgUrl: currentImgUrl
    }
  }

  render() {
    const { classes } = this.props
    const { imgUrl } = this.state
    return (
      <div
        className={classes.preview}
        style={{ backgroundImage: `url(${imgUrl})` }}
      />
    )
  }
}

const enhance = compose(withStyles(styles))

export default enhance(PreviewFile)
