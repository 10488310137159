import React, { Component, Fragment } from 'react'
import compose from 'recompose/compose'
import {
  translate,
  Toolbar,
  WithPermissions,
  showNotification,
} from 'react-admin'
import { 
  Button,
  withStyles,
} from '@material-ui/core'
import * as permission from '../utils/permission'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons'
import DialogLoading from '../common/DialogLoading'
import _ from 'lodash'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { change as changeForm, reset as resetForm } from 'redux-form'

const registrationButtonStyle = {
  button: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#00796b',
    color: 'white',
    '&:hover': {
      backgroundColor: '#009688'
    },
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 17,
  }
}

const buildRegisterRequest = (values) => {
  const {
    address,
    birthday,
    fullName,
    phone,
    email,
    fareId,
    productId,
    routeId,
    stopId,
    segmentId,
  } = values
  const req = {
    bookingInformation: {
      contact: {
        fullName,
        phone,
        email,
        birthday,
        address,
      },
    },
    itemDetail: {
      fareId,
      productId,
      routeId,
      stopId,
      segmentId,
    },
    source: '10WEBADMIN'
  }
  return req
}

const _RegisterButton = ({
  translate,
  handleSubmit,
  register,
  classes,
}) => {
  return <Button
    className={classes.button}
    onClick={handleSubmit(values => { register(values) })}
    variant="contained"
  >
    <FontAwesomeIcon icon={faAddressCard} className={classes.icon} />
    {translate('button.register_member_card')}
  </Button>
}

const RegisterButton = compose(translate, withStyles(registrationButtonStyle))(_RegisterButton)

class RegistrationToolbar extends Component {

  state = {
    open: false,
    loading: false,
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  requestOnlinePayment = async (id, paidTotal, source) => {
    console.log(id, paidTotal, source)
  }

  register = (values) => {
    const request = buildRegisterRequest(values)
    let { showNotification, push, resetForm, form, } = this.props
    this.startLoading()
    Provider.dataProvider('REMOTE', 'membercards', {
      method: 'register',
      requestMethod: 'POST',
      data: request,
    }).then(res => {
      if (res.data) {
        let resp = _.get(res.data, 'data', {})
        let { id } = resp
        showNotification('notification.register_member_card_success', 'success')
        let pathname = `/membercards/${id}/show`
        push(pathname)
        this.endLoading()
        resetForm(form)
      }
    }).catch((e) => {
      this.endLoading()
      let code = _.get(e, 'code','').toLowerCase() 
      let message = `error_messages.codes.member_cards.${code}`
      showNotification(message, 'warning')
    })
  }

  render() {
    let { handleSubmit, permissions, } = this.props
    let { loading } = this.state
    let isCompanyRole = permission.hasOneOfPermission(permissions, 'company-manager', 'company-employee', 'company-supplier') 
    return <Toolbar>
      {isCompanyRole &&<Fragment>
        <RegisterButton 
          handleSubmit={handleSubmit}
          register={this.register}
        />
      </Fragment>}
      {loading && <DialogLoading open={loading} />}
    </Toolbar>
  }
}

const RegistrationToolbarWithPermissions = props => <WithPermissions
  render={({ permissions }) => <RegistrationToolbar permissions={permissions} {...props} />}
/>

const enhance = compose(connect(null, { showNotification, push, changeForm, resetForm, translate, }))
export default enhance(RegistrationToolbarWithPermissions)
