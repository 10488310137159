import React, { createRef, Component } from 'react'
import { withStyles, TextField } from '@material-ui/core'
import { translate } from 'react-admin'
import 'leaflet/dist/leaflet.css'
import Leaflet from 'leaflet'
import compose from 'recompose/compose'
import { Map as OSM, TileLayer, GeoJSON, FeatureGroup } from 'react-leaflet'
import { MapProps } from './../common/constants'
import classnames from 'classnames'

delete Leaflet.Icon.Default.prototype._getIconUrl

Leaflet.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

const styles = () => {
  return {
    container: {
      position: 'relative'
    },
    layer: {
      position: 'absolute',
    },
    itemContainer: {
      padding: 0
    },
    frameContainer: {
      display: 'flex'
    },
    layerControl: {
      zIndex: 400,
    },
    top: {
      top: 0
    },
    left: {
      left: 0,
    },
    right: {
      right: 0
    },
    bottom: {
      bottom: 0
    },
    centerLeft: {
      top: '50%',
      left: 0
    },
    centerRight: {
      top: '50%',
      right: 0
    },
    centerTop: {
      left: '50%',
      top: 0
    },
    centerBottom: {
      right: '50%',
      bottom: 0
    }
  }
}

export const BaseLayers = {
  OSM_LAYER: {
    identifier: 'OSM_LAYER',
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '© <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    type: 'tilelayer',
    selected: true
  },
  MAPNIK_LAYER: {
    identifier: 'MAPNIK_LAYER',
    name: 'OpenStreetMap - BW',
    url: 'http://{s}.tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png',
    attribution: '© <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    type: 'tilelayer',
    selected: false
  }
}

const renderLayerComponent = (v) => {
  switch (v.type) {
    case MapProps.LayerTypes.TILE:
      return <TileLayer attribution={v.attribution}
        type={v.type}
        url={v.url}
        key={v.identifier}
        identifier={v.identifier} />
    case MapProps.LayerTypes.GEO_JSON:
      return (
        <FeatureGroup key={v.identifier} color={v.color}>
          <GeoJSON data={v.data} type={v.type} identifier={v.identifier} />
        </FeatureGroup>
      )
    case MapProps.LayerTypes.MARKER:
      return <GeoJSON data={v.data} key={v.identifier} identifier={v.identifier} />
    default:
      return null
  }
}

const renderLayers = (layers) => {
  let array = []
  let totalLayer = layers && layers.length

  for (let idx = 0; idx < totalLayer; idx++) {
    let layer = layers[idx]
    let component = renderLayerComponent(layer)
    array.push(component)
  }
  return array
}

const renderFrame = (frame) => {
  let element = frame.node
  let typeOfElement = typeof element
  let component = null

  switch (typeOfElement) {
    case 'object':
      component = element
      break
    case 'function':
      component = element()
      break
    default:
      break
  }

  return component
}

class Map extends Component {

  constructor(props) {
    super(props)
    this.map = createRef()

    let { centerPoint, zoom, baseLayer, layers } = this.props

    baseLayer = baseLayer || BaseLayers.OSM_LAYER
    this.state = {
      zoom: zoom ? zoom : 13,
      lat: (centerPoint && centerPoint.lat) ? centerPoint.lat : 0,
      lon: (centerPoint && centerPoint.lon) ? centerPoint.lon : 0,
      baseLayer: baseLayer,
      layers: layers,
      layerContainer: {
        baseLayer: {},
        totalLayer: 0,
        layerMap: {},
        layers: []
      }
    }
  }

  handleMoveend(event) {
    let { onMoveEnd } = this.props

    const leafletElem = event.target
    const zoom = leafletElem.getZoom()
    const center = leafletElem.getCenter()
    const bbox = leafletElem.getBounds()

    let update = {
      zoom: zoom,
      lat: center.lat.toFixed(6),
      lon: center.lng.toFixed(6),
      bbox: [
        bbox.getSouth().toFixed(6),
        bbox.getNorth().toFixed(6),
        bbox.getWest().toFixed(6),
        bbox.getEast().toFixed(6)
      ]
    }
    onMoveEnd && onMoveEnd({ zoom, center })
    this.setState(update)
  }

  handleZoomChange = event => {
    let value = Number(event.target.value)
    value = value.toFixed(0)
    value = Math.max(0, Math.min(value, 21))
    let update = { zoom: value }
    this.setState(update)
  }

  handleLatChange = (event) => {
    let value = Number(event.target.value)
    value = value.toFixed(6)
    let update = { lat: value }

    this.setState(update)
  }

  handleLonChange = (event) => {
    let value = Number(event.target.value)
    value = value.toFixed(6)
    let update = { lon: value }
    this.setState(update)
  }

  static getDerivedStateFromProps = (newProps, state) => {
    let { centerPoint } = newProps

    return {
      zoom: newProps.zoom ? newProps.zoom : 1,
      lat: (centerPoint && centerPoint.lat) ? centerPoint.lat : 0,
      lon: (centerPoint && centerPoint.lon) ? centerPoint.lon : 0,
      geojson: newProps.geojson,
      markers: newProps.markers
    }
  }

  onAddLayer = (k) => {
    let { layerContainer } = this.state
    let { identifier, type } = k.layer.options
    let baseLayer = layerContainer.baseLayer
    let layerMap = layerContainer.layerMap
    let layers = layerContainer.layers

    switch (type) {
      case MapProps.LayerTypes.TILE:
        baseLayer = k.layer
        break
      case MapProps.LayerTypes.GEO_JSON:
        layerMap[identifier] = k.layer
        layers.push(k.layer)
        break
      default:
        break
    }

    this.setState({
      layerContainer: {
        ...layerContainer,
        totalLayer: layerContainer.totalLayer + 1,
        layers,
        baseLayer,
        layerMap
      },
    })
  }

  onRemoveLayer = (k) => {
    let { layerContainer } = this.state

    let identifier = k.layer.options.identifier
    let layers = layerContainer.layers
    delete layers[identifier]

    this.setState({ layerContainer: { ...layerContainer, totalLayer: layerContainer.totalLayer - 1, layers } })
  }

  getCenter = () => {
    let { layerContainer, lat, lon } = this.state
    let layers = layerContainer.layers

    let center = { lat, lon }

    if (layers && layers.length > 0) {
      let bbox = Leaflet.featureGroup(layers).getBounds()
      let { lat, lng } = bbox.getCenter()
      center = { lat, lon: lng }
    }

    return {
      lat: center.lat,
      lon: center.lon
    }
  }

  renderFrameGroup = (classes, frame) => {
    let classNames = [classes.layer, classes.layerControl, classes.itemContainer]

    let framePosition = frame.position
    let totalPosition = framePosition.length
    for (let i = 0; i < totalPosition; i++) {
      switch (framePosition[i]) {
        case MapProps.Position.LEFT:
          classNames.push(classes.left)
          break
        case MapProps.Position.TOP:
          classNames.push(classes.top)
          break
        case MapProps.Position.RIGHT:
          classNames.push(classes.right)
          break
        case MapProps.Position.BOTTOM:
          classNames.push(classes.bottom)
          break
        case MapProps.Position.CENTER_LEFT:
          classNames.push(classes.centerLeft)
          break
        case MapProps.Position.CENTER_RIGHT:
          classNames.push(classes.centerRight)
          break
        case MapProps.Position.CENTER_TOP:
          classNames.push(classes.centerTop)
          break
        case MapProps.Position.CENTER_BOTTOM:
          classNames.push(classes.centerBottom)
          break
        default:
          break
      }
    }

    return (
      <div
        key={frame.identifier}
        className={classnames(classNames)}>
        {frame ? renderFrame(frame) : ''}
      </div>
    )
  }

  render() {
    let { classes, advanced, forwardRef, style, frames, onClick } = this.props
    let { zoom, bbox, bounds, baseLayer, layers } = this.state
    let { lat, lon } = this.getCenter()
    let techbar

    // let frameGroup = this.getFrameGroup(frames)

    if (advanced) {
      techbar = <div style={{ padding: 5 }}>
        <TextField name="zoom" value={zoom} onChange={(event) => this.handleZoomChange(event)} />
        <TextField name="lat" value={lat} onChange={(event) => this.handleLatChange(event)} />
        <TextField name="lon" value={lon} onChange={(event) => this.handleLonChange(event)} />
        <TextField name="bbox" value={bbox} />
      </div>
    }

    return (
      <div
        className={classes.container}
        style={style}>
        {techbar}

        <OSM center={[lat, lon]}
          className={classes.layer}
          ref={this.map}
          forwardRef={forwardRef}
          zoom={zoom}
          bounds={bounds}
          style={style}
          onClick={(k) => {
            this.handleClick(k)
            onClick && onClick(k)
          }}
          onlayeradd={(k) => this.onAddLayer(k)}
          onlayerremove={(k) => this.onRemoveLayer(k)}
          onMoveend={(event) => this.handleMoveend(event)}
        >
          {renderLayers([baseLayer])}
          {renderLayers(layers)}
        </OSM>

        {frames && frames.map(frame => this.renderFrameGroup(classes, frame))}
      </div>
    )
  }
}

Map.defaultProps = {
  style: { width: '100%', height: '100%' },
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(Map)
