import React, { Component } from 'react'
import {
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { addField, translate } from 'ra-core'

class CheckboxInput extends Component {

  render() {
    const { label, input, fullWidth, extrasFunction } = this.props
    let style = { width: fullWidth && '100%' }
    return <FormControlLabel
      style={style}
      control={<Checkbox
        color="primary"       
        onClick={(evt) => { 
          extrasFunction && extrasFunction(evt.target.checked)
          input.onChange(!input.value) 
        }}
        checked={input.value}
      />}
      label={label}
    />
  }
}

const enhance = compose(addField, translate)

export default enhance(CheckboxInput)
