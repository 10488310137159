import { Component } from 'react'
import L from 'leaflet'
import { withLeaflet } from 'react-leaflet'
import PropTypes from 'prop-types'
import throttle from 'lodash/throttle'
import {
  coordinatesToLatLngs,
  getMouseEvtLatLng,
  getRouting,
  length, 
  latlngsToLineString,
  calcDistance, 
  getLatLngs,
  LinkedList,
  getBounds,
  gotoKm,
} from './utils'

import { connect } from 'react-redux'
import compose from 'recompose/compose'

import { invokeAction, resourceRequest } from '../action'

require('leaflet.markercluster/dist/MarkerCluster.css')
require('leaflet.markercluster/dist/MarkerCluster.Default.css')

//helper methods
function startLatLng(model) {
  if (model.segments.head) {
    return model.segments.head.value
  }
  return null
}

function prevLatLng(model) {
  if (model.segments.tail) {
    return model.segments.tail.value
  }
  return null
}

function prevLatLngs(model) {
  if (model.lines.tail) {
    return model.lines.tail.value
  }
  return []
}

class NetworkPlanningLayer extends Component {
  constructor(props) {
    super(props)
    let { routeId, mapUpdate } = this.props
    this.state = { routeId }
    this.mapUpdate = mapUpdate

    this.onMouseMoveThrottled = throttle(this.onMouseMove, 500)
    this.model = {
      route: null,
      segments: new LinkedList(),
      lines: new LinkedList(),
      distances: new LinkedList(),
      poly: null,
      polyGuide: null
    }
  }

  componentDidMount() {
    const map  = this.props.leaflet.map
    map.setMaxZoom(18)
    this.leafletElement = new L.LayerGroup()
    // this.stopLayerGroup = new L.LayerGroup()
    this.stopLayerGroup = new L.markerClusterGroup({ disableClusteringAtZoom: 16 })
    this.leafletElement.addTo(map)
    this.stopLayerGroup.addTo(map)

    this.initializeMarkers()
    this.attachEvents()
  }
  
  initializeMarkers() {
    const map = this.props.leaflet.map
    this.mouseMarker = L.marker(map.getCenter(), {
      icon: L.divIcon({
        className: 'leaflet-mouse-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40]
      }),
      opacity: 0,
      zIndexOffset: 2000
    })
    this.mouseMarker.addTo(map)
    this.initializeStartEndMarkers()
  }

  initializeStartEndMarkers() {
    const map = this.props.leaflet.map
    this.startMarker = L.marker(map.getCenter(), {
      icon: L.divIcon({
        className: 'start-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
        html: 'Start'
      }),
      opacity: 0,
      zIndexOffset: 2000
    })

    this.endMarker = L.marker(map.getCenter(), {
      icon: L.divIcon({
        className: 'stop-marker',
        iconAnchor: [20, 20],
        iconSize: [40, 40],
        html: 'End'
      }),
      opacity: 0,
      zIndexOffset: 2000
    })

    this.startMarker.addTo(map)
    this.endMarker.addTo(map)
  }

  async fetchRoute(routeId) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'routes',
      fetch: 'GET_ONE',
      payload: {
        id: parseInt(routeId)
      }
    }
    let resp = await invokeAction(resourceRequest, params)

    return resp
  }

  // get route id of the other direction
  async fetchRouteReversed(routeId, routeGroupId) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'routes',
      fetch: 'REMOTE',
      payload: {
        method: '',
        requestMethod: 'GET',
        data: { filter: { where: {routeGroupId} } }
      }
    }
    let resp = await invokeAction(resourceRequest, params)
    if (resp && Array.isArray(resp)) {
      let array = resp.filter( v => v.id !== routeId )
      if (array && array.length > 0) {
        return array[0].id
      }
    }
    return null
  }

  // stops which are attached to the route
  async fetchStops(stopIds) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'stops',
      fetch: 'GET_MANY',
      payload: {
        ids: stopIds
      }
    }
    let resp = await invokeAction(resourceRequest, params)
    return resp
  }

  // all stops belonging to the company
  async fetchStopsByCompany(companyId) {
    let { resourceRequest } = this.props
    let params = {
      resource: 'stops',
      fetch: 'REMOTE',
      payload: {
        method: '',
        requestMethod: 'GET',
        data: {
          filter: { companyId },
          fields: {
            id: 1,
            lat: 1,
            lon: 1,
          }
        }
      }
    }
    let resp = await invokeAction(resourceRequest, params)
    return resp
  }

  async renderRoute() {
    let { action } = this.props
    await this.loadRoute()
    await this.loadStops()

    this.redraw(this.model)
    this.updateDistance()
    this.dispatchUpdate()

    if (action) {
      this.onAction(action)
    }
  }

  onAction(action) {
    let cmd = action.cmd
    if ('draw' === cmd) {
      if (action.value) {
        this.startDrawing()
      } else {
        this.finishDrawing()
      }
    } else if ('undo' === cmd) {
      this.undo()
    }
  }
  async loadRoute() {
    let { routeId } = this.props
    this.model.route = await this.fetchRoute(routeId)
    this.model.route.reversed = await this.fetchRouteReversed(routeId, this.model.route.routeGroupId)

    if (!this.model.route.path) return
    let latlngs = coordinatesToLatLngs(this.model.route.path.coordinates)

    let len = latlngs.length
    if (len === 0) { return }
    let start = latlngs[0]
    let end = latlngs[len - 1]

    this.model.segments = new LinkedList()
    this.model.lines = new LinkedList()
    this.model.distances = new LinkedList()

    this.model.segments.append({ value: start })
    this.model.segments.append({ value: end })
    this.model.lines.append({ value: latlngs })
    let d = length(latlngs)
    this.model.distances.append({ value: d })
  }

  async loadStops() {
    this.model.stops = await this.fetchStopsByCompany(this.model.route.companyId)
  }

  clearRoute() {
    if (!this.leafletElement) { return }
    this.leafletElement.clearLayers()
    this.model = {
      route: null,
      segments: new LinkedList(),
      lines: new LinkedList(),
      distances: new LinkedList(),
      poly: null,
      polyGuide: null
    }
    this.redrawStartEnd(this.model)
  }

  attachEvents() {
    const map = this.props.leaflet.map
    map.on('keypress', (evt) => this.onKeyPress(evt))
    map.on('movestart', (evt) => this.onMoveStart(evt))
    map.on('moveend', (evt) => this.onMoveEnd(evt))
    map.on('mousemove', (evt) => this.onMouseMoveThrottled(evt))
    map.on('contextmenu', (evt) => this.onMapContextMenu(evt))

    // this.mouseMarker.on('mousemove', (evt) => this.onMouseMoveThrottled(evt))
    this.mouseMarker.on('mouseup', (evt) => this.onMouseUp(evt))
    let self = this
    this.endMarker.on('mouseover', (evt) => {
      let elem = self.endMarker.getElement()
      if (!self.drawing) {
        elem.innerHTML = '+'
      }
    })
    this.endMarker.on('click', (evt) => {
      if (!self.drawing) {
        self.startDrawing()
      } else {
        self.finishDrawing()
      }
      L.DomEvent.preventDefault(evt.originalEvent)
    })
    this.endMarker.on('mouseout', (evt) => {
      let elem = self.endMarker.getElement()
      elem.innerHTML = 'End'
    })
  }

  detachEvents() {
    //const map = this.props.leaflet.map
    //this.mouseMarker.off('mouseup')
    //this.mouseMarker.off('mousemove')
  }

  onKeyPress(evt) {
    let { enabled } = this.props
    if (!enabled) {
      return
    }
    const origin = evt.originalEvent
    if (origin.defaultPrevented) {
      return
    }
    const ctrlKey = origin.ctrlKey
    const key = origin.key
    switch(key) {
      case 'i': //insert
        this.insert()
        break
      case 'q': //quit
      case 's': //save
        this.finishDrawing()
        break
      case 'a': {
        //goto start
        let start = startLatLng(this.model)
        this.panTo(start)
        break
      }
      case 'z': {
        let end = prevLatLng(this.model)
        this.panTo(end)
        break 
      }
      case 'b': // go back
        if (ctrlKey) {
          this.undo()
        }
        break
      case 'f':
        this.fitBounds()
        break
      case '0':
      case '1':
      case '2':
      case '3':
      case '4':
      case '5': {
        let point = gotoKm(this.model.route.path, +key * 1e5)
        if (point) {
          this.panTo(point)
        }
        break
      }
      case 'h':
        this.toggleStopLayer()
        break
      default:
        break
    }
  }

  onMoveStart(evt) {
    const map = this.props.leaflet.map
    map.off('mousemove', this.onMouseMoveThrottled)
  }

  onMoveEnd(evt) {
    const map = this.props.leaflet.map
    map.on('mousemove', this.onMouseMoveThrottled)
    this.redrawStartEnd(this.model)
    this.redrawStops(this.model)
  }

  async onMouseMove(evt) {
    if (!this.drawing) {
      return
    }
    const map = this.props.leaflet.map
    let latlng = getMouseEvtLatLng(map, evt)

    let latlngs = await this.getRouting(latlng)
    this.updateGuide(latlngs)
    // Update the mouse marker position
    this.mouseMarker.setLatLng(latlng)
  }

  onMouseUp(evt) {
    const map = this.props.leaflet.map
    let latlng = getMouseEvtLatLng(map, evt)
    this.insert(latlng)
  }

  //actions
  startDrawing() {
    this.drawing = true
    this.dispatchUpdate()
  }

  // quang's
  selectStartPoint(stop) {
    this.model.startPoint = stop
    this.findRoute()
  }

  selectEndPoint(stop) {
    this.model.endPoint = stop
    this.findRoute()
  }

  async findRoute() {
    let { startPoint, endPoint } = this.model
    if (startPoint && endPoint) {
      let start = [startPoint.lat, startPoint.lon]
      let end = [endPoint.lat, endPoint.lon]
      let latlngs = await getRouting(start, end)
      this.updatePoly(latlngs)
    }
  }
  // quang's end

  finishDrawing() {
    this.drawing = false
    this.post()
    this.clearGuide()
    this.detachEvents()
    this.dispatchUpdate()
  }

  fitBounds() {
    const map = this.props.leaflet.map
    let bounds = null
    if (!this.leafletElement) { return }
    this.leafletElement.eachLayer(v => {
      let layerBounds = v.getBounds()
      if (layerBounds && layerBounds.isValid()) {
        bounds = getBounds(bounds, layerBounds)
      }
    })
    if (bounds && bounds.isValid()) {
      map.fitBounds(bounds)
    }
  }

  updateGuide(latlngs) {
    if (!latlngs || latlngs.length === 0) { return }
    let { polyGuideStyle } = this.props
    let style = polyGuideStyle
    style.color = this.model.route.color ? this.model.route.color : style.color
    if (!this.model.polyGuide) {
      this.model.polyGuide = L.polyline([], style).addTo(this.leafletElement)
    }
    this.model.polyGuide.setLatLngs(latlngs)
  }

  async getRouting(latlng) {
    const prev = prevLatLng(this.model)
    if (!prev) { return }

    let from = [ prev.lat, prev.lng ]
    let to = [ latlng.lat, latlng.lng ]
    let latlngs = await getRouting({ from, to })
    return latlngs
  }

  clearGuide() {
    if (this.model.polyGuide) {
      this.model.polyGuide.setLatLngs([])
    }
  }

  insert(latlng) {
    if (!this.drawing) {
      this.startDrawing()
      return
    }
    let prev = prevLatLng(this.model)
    if (!prev) {
      this.model.segments.append({ value: latlng })
    } else {
      this.update(this.model)
    }
    this.draw(this.model)
    let curr = prevLatLng(this.model)
    this.panTo(curr)
    //this.panBy(prev, curr)
    this.post()
  }

  update(model) {
    if (!model.polyGuide) { return }
    let latlngs = model.polyGuide.getLatLngs()
    let len = latlngs.length
    if (len < 1) { return }

    //last latlng
    let latlng = latlngs[len - 1]
    //append segment
    model.segments.append({ value: latlng })
    //append list of latlngs
    model.lines.append({ value: latlngs })
    //append segment length
    let d = length(latlngs)
    d = Math.floor(d)

    model.distances.append({ value: d })
  }

  dispatchUpdate() {
    let route = this.model.route
    let numOfStops = route.stops ? route.stops.length : 0
    let drawing = this.drawing
    let canUndo = true
    this.mapUpdate({ route: { id: route.id,
      number: route.number, color: route.color,
      distance: Math.floor(route.distance / 1000), 
      name: route.name, path: route.path, numOfStops, reversed: route.reversed }, drawing, canUndo })
  }

  onMapContextMenu(evt) {
    const map = this.props.leaflet.map
    let latlng = getMouseEvtLatLng(map, evt)
    let route = { id: '', number: '', name: '', color: '' }
    if (this.model && this.model.route) {
      route = { id: this.model.route.id,
        number: this.model.route.number, color: this.model.route.color,
        name: this.model.route.name }
    }
    let data = { route,
      location: { lat: latlng.lat.toFixed(6), lon: latlng.lng.toFixed(6) }
    }
    this.dispatchContextMenu(evt, data, 'location')
  }

  dispatchContextMenu(evt, data, type) {
    let { openContextMenu } = this.props
    openContextMenu({
      anchorReference: 'anchorPosition',
      anchorPosition: { top: evt.originalEvent.clientY, left: evt.originalEvent.clientX },
      data: data,
      type: type
    })
  }

  initPoly(model) {
    let { routeStyle } = this.props
    if (!model.poly) {
      routeStyle.color = model.route.color ? model.route.color : routeStyle.color
      model.poly = L.polyline([], routeStyle).addTo(this.leafletElement)
    }
  }

  updatePoly(model) {
    let latlngs = prevLatLngs(model)
    if (latlngs) {
      let len = latlngs.length
      for (let idx = 0; idx < len; idx++) {
        let latlng = latlngs[idx]
        model.poly.addLatLng(latlng)
      }
    }
    this.redrawStartEnd(model)
  }

  redrawPoly(model) {
    let latlngs = getLatLngs(model)
    latlngs = L.LineUtil.simplify(latlngs)
    model.poly.setLatLngs(latlngs)
    this.redrawStartEnd(model)
  }

  redrawStops(model) {
    this.stopLayerGroup.clearLayers()
    // TODO background not correct
    let len = model.stops && model.stops.length
    for (let idx = 0; idx < len; idx++) {
      let stop = model.stops[idx]
      let stopMarker = this.createStopMarker(model.route, stop)
      stopMarker.addTo(this.stopLayerGroup)
    }
  }

  createStopMarker(route, stop) {
    let stopMarker
    if (route.stops && route.stops.includes(stop.id)) {
      stopMarker = L.marker([stop.lat, stop.lon], {
        // icon: L.divIcon({
        //   iconAnchor: [8, 8],
        //   iconSize: [1, 1],
        //   html: '<i class="material-icons bus-stop-sm" style="background: red">directions_bus</i>'
        // }),
        icon: L.icon({
          iconUrl: '/images/markers/marker-icon.png',
          iconRetinaUrl: '/images/markers/marker-icon-2x.png',
          shadowUrl: '/images/markers/marker-shadow.png',
          iconSize:    [25, 41],
          iconAnchor:  [12, 41],
          popupAnchor: [1, -34],
          tooltipAnchor: [16, -28],
          shadowSize:  [41, 41]
        }),
        opacity: 1,
        zIndexOffset: 5000,
        stopId: stop.id
      })

      stopMarker.on('contextmenu', (evt) => {
        let data = { route, stop, location: { lat: Number(stop.lat).toFixed(6), lon: Number(stop.lon).toFixed(6) }}
        this.dispatchContextMenu(evt, data, 'stop-assigned')
      })
    } else {
      stopMarker = L.marker([stop.lat, stop.lon], {
        // icon: L.divIcon({
        //   iconAnchor: [4, 4],
        //   iconSize: [8, 8],
        // }),
        opacity: 1,
        zIndexOffset: 4999,
        stopId: stop.id
      })
      stopMarker.on('contextmenu', (evt) => {
        let data = { planningRoute: route, stop, location: { lat: Number(stop.lat).toFixed(6), lon: Number(stop.lon).toFixed(6) }}
        this.dispatchContextMenu(evt, data, 'stop')
      })
    }
    return stopMarker
  } 

  draw(model) {
    this.initPoly(model)
    this.updatePoly(model)
  }

  redraw(model) {
    this.initPoly(model)
    this.redrawPoly(model)
    this.redrawStops(model)
  }

  redrawStartEnd(model) {
    const map = this.props.leaflet.map
    let start = startLatLng(model)
    let end = prevLatLng(model)
    if (start) {
      this.startMarker.setLatLng(start)
      this.startMarker.setOpacity(1)
    } else {
      this.startMarker.setOpacity(0)
    }
    if (end) {
      let point = map.latLngToLayerPoint(end)
      point.x = point.x + 15
      point.y = point.y + 15
      let latlng = map.layerPointToLatLng(point)
      this.endMarker.setLatLng(latlng)
      this.endMarker.setOpacity(1)
    } else {
      this.endMarker.setOpacity(0)
    }
  }

  updateDistance() {
    let route = this.model.route
    let latlngs = []
    if (this.model.poly) {
      latlngs = this.model.poly.getLatLngs()
    }
    let linestring = latlngsToLineString(latlngs)
    route.path = linestring
    let d = calcDistance(this.model)
    route.distance = d
    this.model.route.distance = Math.floor(d)
  }

  //post data to server
  async post() {
    let { resourceRequest } = this.props
    let route = this.model.route
    this.updateDistance()

    let params = {
      resource: 'routes',
      fetch: 'UPDATE',
      payload: {
        id: route.id, 
        data: route
      }
    }
    await invokeAction(resourceRequest, params)
    this.dispatchUpdate()
  }

  panTo(latlng) {
    const map = this.props.leaflet.map
    if (latlng) {
      map.panTo(latlng)
    }
  }

  panBy(prev, curr) {
    const map = this.props.leaflet.map
    if (prev && curr) {
      let p1 = map.latLngToLayerPoint(prev)
      let p2 = map.latLngToLayerPoint(curr)
      let offset = { x: p2.x - p1.x, y: p2.y - p1.y }
      map.panBy(offset)
    }
  }

  toggleStopLayer() {
    const map  = this.props.leaflet.map
    if (map.hasLayer(this.stopLayerGroup)) {
      map.removeLayer(this.stopLayerGroup)
    } else {
      map.addLayer(this.stopLayerGroup)
    }
  }

  undo() {
    if (!this.drawing) { return }
    this.model.segments.removeTail()
    this.model.lines.removeTail()
    this.model.distances.removeTail()
    this.redraw(this.model)
    this.panTo(prevLatLng(this.model))
    this.post()
  }

  render() {
    let { enabled } = this.props
    if (enabled) {
      this.renderRoute()
    } else {
      this.clearRoute()
    }
    return null
  }
}

NetworkPlanningLayer.propTypes = {
  routeStyle: PropTypes.object,
  polyGuideStyle: PropTypes.object,
  mapUpdate: PropTypes.func
}

NetworkPlanningLayer.defaultProps = {
  routeStyle: { weight: 8, opacity: 0.92, color: 'red' },
  polyGuideStyle: { weight: 8, color: 'red', opacity: 0.5, dashArray: '5 10' }
}

const enhance = compose(
  connect((state) => {
    let { ts, action } = state.network
    return { ts, action }
  }, { resourceRequest }), 
)
export default withLeaflet(enhance(NetworkPlanningLayer))
