import React, { Component } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { withStyles } from '@material-ui/core'
import classnames from 'classnames'
import compose from 'recompose/compose'
import { translate } from 'react-admin'

const styles = theme => ({
  grid: {
    width: '60%',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
    padding: 0,
  },
  hidden: {
    opacity: 0,
    pointerEvents: 'none',
  },
  current: {
    color: theme.palette.primary.main,
    fontWeight: 600,
  },
  selected: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabled: {
    pointerEvents: 'none',
    color: theme.palette.text.hint,
  },
})

class Day extends Component {

  render() {
    const { children, classes, disabled, hidden, current, selected, onClick, translate, ...rest } = this.props
    const className = classnames(classes.day, {
      [classes.hidden]: hidden,
      [classes.current]: current,
      [classes.selected]: selected,
      [classes.disabled]: disabled,
    })

    return (
      <IconButton onClick={onClick} className={className} tabIndex={hidden || disabled ? -1 : 0} {...rest}>
        {children}
      </IconButton>
    )
  }
}

Day.defaultProps = {
  disabled: false,
  hidden: false,
  current: false,
  selected: false,
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(Day)
