import React, { Component } from 'react'
import { 
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  IconButton,
  withStyles,
  Chip,
} from '@material-ui/core'
import { grey, green, yellow } from '@material-ui/core/colors'
import {
  ReferenceField,
  TextField,
  translate,
  FunctionField,
} from 'react-admin'
import compose from 'recompose/compose'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import { formatCurrency } from '../utils/formatUtil'
import { StatusField } from './Status'

const cardStyle = () => ({
  card: {
    marginBottom: 8
  },
  cardActions: {
    padding: 0
  },
  showButton: {
    marginLeft: 'auto',
  },
  unAssigned: {
    backgroundColor: grey[500],
    color: 'white'
  },
  assignedAll: {
    backgroundColor: green[500],
    color: 'white'
  },
  assignedAPart: {
    backgroundColor: yellow[500]
  },
  textContainer: {
    display: 'flex',
    fontSize: 13,
    marginBottom: 4
  },
  label: {
    fontWeight: 'bold'
  },
  textInReferenceField: {
    fontSize: 13,
    lineHeight: 'normal',
  },
  note: {
    fontSize: 11,
    paddingLeft: 16,
    fontStyle: 'italic'
  },
  chip: {
    marginRight: 4
  },
  chipContainer: {
    display: 'flex',
  },
})

class _TransactionCard extends Component {

  render() {
    let { record, translate, isAdmin, classes, history } = this.props
    let {
      reservationCode,
      reservationId,
      total,
      paidAt,
      createAt,
      paid,
      transactionId: id,
      attempt,
    } = record
    return <div className={classes.card}>
      <Card>
        <CardHeader
          title={`${translate('resources.transactions.name', { smart_count: 1 })}: #${id}`}
          subheader={moment(createAt).format('HH:mm DD/MM/YYYY')}
        />
        <Divider />
        <CardContent>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.transactions.fields.reservationId')}:</span>&nbsp;{isAdmin ? <span>{reservationCode}</span> : <a href={`/reservations/${reservationId}/show`}>{reservationCode}</a>}
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.transactions.fields.companyId')}:</span>&nbsp;
            <ReferenceField
              record={record}
              basePath="/companies"
              source="companyId"
              reference="companies"
              linkType={false}
            >
              <TextField className={classes.textInReferenceField} source="name" />
            </ReferenceField>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.transactions.fields.agencyId')}:</span>&nbsp;
            <ReferenceField
              record={record}
              basePath="/agencies"
              source="agencyId"
              reference="agencies"
              linkType={false}
            >
              <TextField className={classes.textInReferenceField} source="name" />
            </ReferenceField>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.transactions.fields.total')}:</span>&nbsp;<span>{formatCurrency(total)}</span>
          </div>
          <div className={classes.textContainer}>
            <span className={classes.label}>{translate('resources.transactions.fields.attempt')}:</span>&nbsp;<span>{attempt}</span>
          </div>
          <div className={classes.chipContainer}>
            <ReferenceField
              record={record}
              source="paymentMethodId"
              basePath="/paymentmethods"
              reference="paymentmethods"
              linkType={false}
            >
              <FunctionField render={({ name }) => <Chip className={classes.chip} label={name}/>}/>
            </ReferenceField>
            <ReferenceField
              record={record}
              source="type"
              basePath="/transactiontypes"
              reference="transactiontypes"
              linkType={false}
            >
              <FunctionField render={({ name }) => <Chip className={classes.chip} label={name}/>}/>
            </ReferenceField>
            <ReferenceField
              record={record}
              source="status"
              basePath="/transactionstatuses"
              reference="transactionstatuses"
              linkType={false}
            >
              <StatusField source="name" />
            </ReferenceField>
          </div>
        </CardContent>
        <Divider />
        <CardActions className={classes.cardActions}>
          <div className={classes.note}>
            <span>
              {translate('resources.transactions.fields.paid')}:&nbsp;
              <b>{formatCurrency(paid)}</b>&nbsp;
              {translate('resources.common.paidAt')}&nbsp;
              <b>{moment(paidAt).format('HH:mm DD/MM/YYYY')}</b>
            </span>
          </div>
          <IconButton
            onClick={() => history.push(`transactions/${id}/show`)}
            aria-label={translate('button.edit')}
            color="primary"
            className={classes.showButton}
          >
            <FontAwesomeIcon size="xs" icon={faEye} />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  }
}

const enhance = compose(translate, withStyles(cardStyle))
const TransactionCard = enhance(_TransactionCard)

const style = {
  root: {
    padding: 8
  }
}

class MobileGrid extends Component {

  render() {
    let { ids, isAdmin, data, classes, history } = this.props
    return <div className={classes.root}>
      {ids.map(id => {
        return <TransactionCard 
          key={id}
          isAdmin={isAdmin}
          record={data[id]}
          history={history}
        />
      })}
    </div>
  }
}

export default withStyles(style)(MobileGrid)
