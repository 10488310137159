import { validPhoneNumber } from '../utils/topUpUtil'

export const validate = (record, props) => {
  let { newPassword, confirmPassword } = record
  let { translate } = props
  let errors = {}
  if (newPassword !== confirmPassword) {
    errors.confirmPassword = translate('error_messages.invalid.users.confirm_password')
  }
  return errors
}

export const validateCreateUser = (record, props) => {
  let { password, confirmPassword } = record
  let { translate } = props
  let errors = {}
  if (password !== confirmPassword) {
    errors.confirmPassword = translate('error_messages.invalid.users.confirm_password')
  }
  return errors
}

export const validatePhone = (record, props) => {
  let { phone } = record
  let { translate } = props
  let errors = {}
  if (phone && !validPhoneNumber(phone)) {
    errors.phone = translate('error_messages.invalid.drivers.phone')
  }
  return errors
}
