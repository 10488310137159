import React, { Component, createRef } from 'react'
import { Provider } from '../provider'
import _ from 'lodash'
import { translate, showNotification } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import EmailEditor from 'react-email-editor'

const styles = () => ({
  saveButton: {
    color: '#fff',
    backgroundColor: '#3f51b5',
    padding: '8px 16px',
    fontSize: '0.75rem',
    minHeight: 36,
    borderRadius: 4,
    letterSpacing: '0.02857em',
    textTransform: 'uppercase',
    display: 'inline-flex',
    alignItems: 'center',
    border: 0,
    cursor: 'pointer'
  },
})

class ProductDescriptionForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      design: {},
    }
    this.emailEditorRef = createRef()
  }

  loadData = async () => {
    let { productId } = this.props
    let res = await Provider.dataProvider('GET_LIST', 'productfulldescriptions', {
      filter: {
        where: { productId: productId }
      },
      sort: {},
      pagination: {}
    })
    if (!_.isEmpty(res) && !_.isEmpty(res.data)) {
      let { design } = res.data[0]

      this.setState({
        design
      })
    }
  }

  exportHtml = () => {
    this.emailEditorRef.current.editor.exportHtml(data => {
      let { productId, showNotification, onClose } = this.props
      let { design, html } = data

      Provider.dataProvider('REMOTE', 'productfulldescriptions', {
        method: 'saveProductFullDescription',
        requestMethod: 'POST',
        data: {
          productId,
          html,
          design,
        }
      }).then(
        () => {
          onClose()
          showNotification('resources.products.save_success')
        }
      ).catch(
        () => {
          onClose()
          showNotification('resources.products.save_failed', 'warning')
        }
      )
    })
  }

  convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)

    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
  })

  processFile = async file => {
    let base64Picture = await this.convertFileToBase64(file)
    let { name, type } = file
    return {
      src: base64Picture,
      name,
      type,
    }
  }

  onLoad = async () => {
    await this.loadData()
    let { design } = this.state
    if (!_.isEmpty(design)) {
      this.emailEditorRef.current.editor.loadDesign(design)
    }
    this.emailEditorRef.current.registerCallback('image', async (file, done) => {
      let img = await this.processFile(file.attachments[0])
      let res = await Provider.dataProvider('REMOTE', 'productfulldescriptions', {
        method: 'saveImageProductFullDescription',
        requestMethod: 'POST',
        data: {
          img
        }
      })
      if (!_.isEmpty(res)) {
        let { data } = res
        done({ progress: 100, url: data.url })
      }
    })
  }

  render () {
    let { translate, classes } = this.props
    return (
      <div>
        <div>
          <button className={classes.saveButton} onClick={this.exportHtml}>{translate('button.save')}</button>
        </div>
        <EmailEditor
          onLoad={this.onLoad}
          ref={this.emailEditorRef}
          minHeight="700px"
        />
      </div>
    )
  }
}
export default compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification })
)(ProductDescriptionForm)
