import React, { Component } from 'react'
import {
  TableRow, 
  Table,
  TableHead,
  TableCell,
  TableBody,
  withStyles,
} from '@material-ui/core'
import TableRowItem from './TableRowItem'
import compose from 'recompose/compose'
import { translate } from 'react-admin'

let styles = {
  headerCell: {
    fontSize: '0.75rem',
  },
  rowHeader: {
    height: 20
  },
}

class SortFareTable extends Component {
  render() {
    let { 
      translate,
      classes,
      data,
      ids,
      reorder,
      reorderGroup,
      handleGetProduct,
      productSelected,
      onDrop,
      tableChecked,
      routeId
    } = this.props
    return (
      <Table>
        <TableHead>
          <TableRow className={classes.rowHeader}>
            <TableCell className={classes.headerCell}>{translate('resources.common.position')}</TableCell>
            <TableCell className={classes.headerCell}>{translate('resources.saletickets.productName')}</TableCell>
            <TableCell className={classes.headerCell}>{translate('resources.charges.fields.itemDetail.fareId')}</TableCell>
            <TableCell className={classes.headerCell}>{translate('resources.fares.fields.amount')}</TableCell>
            <TableCell className={classes.headerCell}></TableCell>
            <TableCell className={classes.headerCell}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {ids.map(id => (
            <TableRowItem
              key={id}
              record={data[id]}
              index={id}
              reorder={reorder}
              reorderGroup={reorderGroup}
              onDrop={onDrop}
              routeId={routeId}
              handleGetProduct={handleGetProduct}
              productSelected={productSelected}
              handleResetChecked={this.handleResetChecked}
              rowChecked={tableChecked[id]}
            />
          ))}
        </TableBody>
      </Table>
    )
  }
}

export default compose(
  withStyles(styles),
  translate
)(SortFareTable)
