import React, { Component, Fragment } from 'react'
import _ from 'lodash'
import {
  SimpleForm,
  FunctionField,
  ArrayField,
  ReferenceInput,
  TextInput,
  Datagrid,
  LongTextInput,
  translate,
  required,
  FormDataConsumer,
  showNotification,
} from 'react-admin'
import { reset, getFormSyncErrors, touch, change as changeForm } from 'redux-form'
import { Provider } from '../provider'
import {
  Dialog,
  DialogActions,
  Grid,
  DialogContent,
  ListItem,
  Avatar,
  ListItemText,
  Chip,
  Button,
  withStyles,
  TextField,
} from '@material-ui/core'
import { formatCurrency } from '../utils/formatUtil'
import { dateFormat, timeFormat } from '../common/format'
import { connect } from 'react-redux'
import PassengerCollectionChip from './PassengerCollectionChip'
import compose from 'recompose/compose'
import { MoneyInput } from '../common/MoneyInput'
import { push } from 'react-router-redux'
import * as chargeStatus from '../common/charge-status'
import { GET_LIST_PAGINATION } from '../provider/get'
import { PaymentMethodList } from './AddReservationDialog'
import DialogLoading from '../common/DialogLoading'
import EditIcon from '@material-ui/icons/Edit'

const EDIT_RESERVATION_FROM_SEAT_VIEW = 'EDIT_RESERVATION_FROM_SEAT_VIEW'

const styles = theme => ({
  chips: {
    display: 'flex',
  },
  fullWidth: {
    width: '100%'
  },
  bigAvatar: {
    width: 60,
    height: 60,
  },
  seatChip: {
    marginRight: 6,
  },
  moreInfo: {
    fontSize: 18,
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  selectPrice: {
    marginTop: 16,
  },
  container: {
    marginTop: 12,
  },
  iconEdit: {
    marginRight: '0.5em',
    fontSize: 17,
  },
  buttonEdit: {
    marginRight: 16,
    fontWeight: 'bold',
    backgroundColor: '#0277bd',
    color: 'white',
    '&:hover': {
      backgroundColor: '#039be5'
    },
  }
})

class EditReservationDialog extends Component {

  state = {
    loading: false,
  }

  onEnter = async () => {
    let { reservation, trip } = this.props
    let { bookingInformation } = reservation
    let { contact } = bookingInformation
    let { firstName, fullName } = contact
    if (!fullName) {
      contact.fullName = firstName
      bookingInformation.contact = contact
      reservation.bookingInformation = bookingInformation
    }
    const getCharges = async () => {
      let response = await Provider.dataProvider('GET_ONE', 'reservations', {
        id: reservation.id,
        filter: {
          fields: ['id'],
          include: [
            {
              relation: 'charges',
              scope: {
                where: { cancelReference: null, status: { neq: chargeStatus.CANCEL } }
              }
            },
            { relation: 'paymentInformation' },
          ]
        }
      })
      if (response && response.data) return response.data
    }

    const getTrips = async () => {
      if (reservation.trips.length === 1) {
        return [trip]
      }
      let response = await Provider.dataProvider('GET_LIST', 'trips', {
        filter: {
          where: { id: { inq: reservation.trips } },
        },
        ...GET_LIST_PAGINATION,
      })
      if (response && response.data) {
        return response.data
      }
    }

    let [extra, trips] = await Promise.all([getCharges(), getTrips()])

    if (extra && trips) {
      let { charges, paymentInformation } = extra
      let charge = paymentInformation.debt
      this.setState({ charges, paymentInformation, trips, charge })
    }
  }

  startLoading = () => {
    this.setState({ loading: true })
  }

  endLoading = () => {
    this.setState({ loading: false })
  }

  makeQuotation = async (paymentMethodId, formData, code) => {
    this.startLoading()
    this.setState({ isMakingReservation: true })
    let { syncErrors, trip: currentTrip, touch, showNotification, onClose, reloadCharges  } = this.props
    let { charge } = formData
    if (_.isEmpty(syncErrors)) {
      let { reservation } = this.props
      let { trips } = this.state
      let data = {
        trips: trips.map(trip => trip.id),
        charges: [],
      }
      if (code === 'CASH' && charge) {
        data.transactions = [{
          paid: charge,
          total: charge,
          paymentMethodId,
          currencyId: 1,
          type: '00NORMAL',
          status: '20DONE',
        }]
      }
      Provider.dataProvider('REMOTE', 'reservations', {
        method: `${reservation.id}/quotation`,
        data,
      }).then(res => {
        this.endLoading()
        let reservation = _.get(res.data, 'data', {})
        let { id, source} = reservation
        if (code && code !== 'CASH') {
          let currentTripId = currentTrip.id
          this.requestOnlinePayment(id, currentTripId, charge, source)
        } else {
          showNotification('notification.reservation.success')
          reloadCharges()
          onClose()
        }
      }).catch(e => {
        this.endLoading()
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      })
    } else {
      touch(EDIT_RESERVATION_FROM_SEAT_VIEW, ...Object.keys(syncErrors))
    }
    this.setState({ isMakingReservation: false })
  }

  requestOnlinePayment = (reservationId, tripId, amount, source) => {
    let currentHost = window.location.origin
    let pathname = `/reservations/trip_layout/${tripId}`
    let returnUrl = `${currentHost}${pathname}`
    Provider.dataProvider('REMOTE', 'payments', {
      method: 'card-request',
      requestMethod: 'POST',
      data: {
        reservationId,
        amount,
        source,
        orderType: 'bill payment',
        returnUrl,
      }
    }).then(res => {
      if (res.data) {
        let url = _.get(res.data, 'data')
        if (url) {
          window.location.href = url
        }
      }
    }).catch(error => {
      console.log('error', error)
    })
  }

  updateBookingInformation = async (formData) => {
    this.startLoading()
    let { syncErrors, touch, showNotification, reservation, onClose, reloadCharges, reset } = this.props
    if (_.isEmpty(syncErrors)) {
      let { bookingInformation = {} } = reservation
      let { id: bookingInformationId } = bookingInformation
      let { bookingInformation: newBookingInformation = {} } = formData
      Provider.dataProvider('UPDATE', 'bookinginformations', {
        id: bookingInformationId,
        data: { contact: newBookingInformation.contact, reservationId: reservation.id },
      }).then(
        () => {
          this.endLoading()
          showNotification('notification.reservation.success')
          reloadCharges()
          reset(EDIT_RESERVATION_FROM_SEAT_VIEW)
          onClose()
        }
      ).catch(
        () => {
          this.endLoading()
          showNotification('notification.reservation.fail', 'warning')
        }
      )
    } else {
      touch(EDIT_RESERVATION_FROM_SEAT_VIEW, ...Object.keys(syncErrors))
    }
  }

  onClose = () => {
    let { onClose, reset } = this.props
    onClose()
    reset(EDIT_RESERVATION_FROM_SEAT_VIEW)
  }

  render() {
    let { 
      open, 
      classes,
      reservation,
      changeForm,
      translate,
      canSale,
      passengerCollections,
      reload,
    } = this.props
    let { pickUpPoints, dropOffPoints } = passengerCollections
    let { charges = [], trips = [], paymentInformation = {}, loading } = this.state
    let firstCharge = charges[0]
    let pickUpPoint = _.get(firstCharge, 'itemDetail.pickUpPoint')
    let dropOffPoint = _.get(firstCharge, 'itemDetail.dropOffPoint')
    let passengerCollection = { pickUpPoint, dropOffPoint }
    let { total, debt, paid } = paymentInformation
    let isPaid = paid === total
    return <Fragment>
      <Dialog
        open={open}
        onEnter={this.onEnter}
        onClose={this.onClose}
        maxWidth="lg"
        disableEnforceFocus
      >
        <SimpleForm
          record={{ ...reservation, paymentInformation, charge: debt, passengerCollection }}
          form={EDIT_RESERVATION_FROM_SEAT_VIEW}
          resource="reservations"
          toolbar={false}
          validate={record => {
            let errors = {}
            if (record.charge > debt) {
              errors.charge = translate('error_messages.invalid.payment.paid_must_be_less_than_total', { debt: formatCurrency(debt)})
            }
            return errors
          }}
        >
          <DialogContent className={classes.fullWidth}>
            <Grid container>
              <Grid container item sm={12} md={7}>
                {trips.map(trip => (
                  <Fragment key={trip.id}>
                    <Grid container >
                      <Grid item xs={6}>
                        <ListItem>
                          <Avatar className={classes.bigAvatar}>
                            {trip.departureTime.format(timeFormat)}
                          </Avatar>
                          <ListItemText
                            primary={trip.departure}
                            secondary={trip.departureTime.format(dateFormat)}
                          />
                        </ListItem>
                      </Grid>
                      <Grid item xs={6}>
                        <ListItem>
                          <Avatar className={classes.bigAvatar}>
                            {trip.arrivalTime.format(timeFormat)}
                          </Avatar>
                          <ListItemText
                            primary={trip.arrival}
                            secondary={trip.arrivalTime.format(dateFormat)}
                          />
                        </ListItem>
                      </Grid>
                    </Grid>
                    <ArrayField record={{ charges: _.filter(charges, { tripId: trip.id }) }} source="charges">
                      <Datagrid resource="fares">
                        <FunctionField
                          label="resources.fares.fields.seats"
                          source="seat"
                          render={(record) =>
                            <Chip className={classes.seatChip} label={record.itemDetail.itemCode} />
                          }
                        />
                        <FunctionField
                          source="amount"
                          render={({ amount, itemDetail }) => <span>
                            <b>{amount && formatCurrency(amount)}</b> &nbsp;
                            <i>{itemDetail.discount && `(${translate('resources.campaigns.discounted')} ${formatCurrency(itemDetail.discount)})`}</i>
                          </span>
                          }
                        />
                        {pickUpPoints && <FunctionField
                          source="pickUpPoint"
                          label="resources.charges.pickUpPoint"
                          render={record => record && <PassengerCollectionChip
                            datum={pickUpPoints}
                            charge={record}
                            passengerCollectionType='00PICKUP'
                            reload={reload}
                          />}
                        />}
                        {dropOffPoints && <FunctionField
                          source="dropOffPoint"
                          label="resources.charges.dropOffPoint"
                          render={record => record && <PassengerCollectionChip 
                            datum={dropOffPoints}
                            charge={record}
                            passengerCollectionType='10DROPOFF'
                            reload={reload}
                          />}
                        />}
                      </Datagrid>
                    </ArrayField>
                  </Fragment>
                ))}
              </Grid>
              <Grid item sm={12} md={5}>
                <TextInput source="code"
                  label="resources.reservations.fields.code"
                  fullWidth disabled />
                <TextInput
                  source="bookingInformation.contact.phone"
                  label="resources.reservations.passenger.phone"
                  fullWidth
                  validate={required()}
                />
                <TextInput
                  source="bookingInformation.contact.fullName"
                  label="resources.reservations.passenger.firstName"
                  fullWidth
                />
                <TextInput
                  source="bookingInformation.contact.email"
                  label="resources.reservations.passenger.email"
                  fullWidth
                />
                <LongTextInput
                  source="bookingInformation.note"
                  label="resources.reservations.note"
                  fullWidth
                />
                {isPaid && total && <TextField
                  disabled
                  error
                  label={translate('resources.reservations.totalAmount')}
                  defaultValue={formatCurrency(total)}
                  helperText={translate('resources.reservations.paidAmount')}
                  margin="normal"
                  fullWidth
                />}
                {!isPaid && total && (
                  <Grid container spacing={8}>
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        label={translate('resources.reservations.totalAmount')}
                        defaultValue={total && formatCurrency(total)}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        disabled
                        label={translate('resources.reservations.paidAmount')}
                        defaultValue={paid && formatCurrency(paid)}
                        margin="normal"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <MoneyInput
                        label="resources.reservations.debtAmount"
                        source="charge"
                      />
                    </Grid>
                    <span>
                      {debt >= 50000 &&
                          <Button onClick={() => changeForm(EDIT_RESERVATION_FROM_SEAT_VIEW, 'charge', 50000)}>
                            {translate('resources.reservations.prepaid50')}
                          </Button>}
                      {debt >= 100000 &&
                          <Button onClick={() => changeForm(EDIT_RESERVATION_FROM_SEAT_VIEW, 'charge', 100000)}>
                            {translate('resources.reservations.prepaid100')}
                          </Button>}
                      <Button onClick={() => changeForm(EDIT_RESERVATION_FROM_SEAT_VIEW, 'charge', debt)}>
                        {translate('resources.reservations.paidAll')}
                      </Button>
                    </span>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </DialogContent>

          <FormDataConsumer>
            {({ formData }) => {
              let { isMakingReservation } = this.state
              return <DialogActions className={classes.fullWidth}>
                {!isPaid && total && <ReferenceInput
                  label="resources.reservations.payment.method"
                  source="paymentMethod"
                  reference="paymentmethods"
                  resource="reservations"
                  fullWidth
                >
                  <PaymentMethodList
                    onClick={(paymentMethodId, code) =>
                      this.makeQuotation(paymentMethodId, formData, code)}
                    disabled={(isMakingReservation || !canSale) || loading}
                  />
                </ReferenceInput>}
                <Button
                  onClick={() => this.updateBookingInformation(formData)}
                  className={classes.buttonEdit}
                  variant="contained"
                >
                  <EditIcon className={classes.iconEdit} />
                  {translate('button.edit_booking_information')}
                </Button>
              </DialogActions>
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Dialog>
      {loading && <DialogLoading open={loading} />}
    </Fragment>
  }
}

const enhanceReservationDialog = compose(
  withStyles(styles),
  translate,
  connect(state => ({
    syncErrors: getFormSyncErrors(EDIT_RESERVATION_FROM_SEAT_VIEW)(state),
  }),
  { reset, touch, changeForm, showNotification, push }
  )
)
export default enhanceReservationDialog(EditReservationDialog)
