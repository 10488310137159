import React from 'react'
import { green, red, blue } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/core'
import { ChipField } from 'react-admin'
import get from 'lodash/get'

export const NEW = '00NEW'
export const OPENED = '10OPN'
export const CLOSED = '20CLS'

export const statusStyles = {
  status: { color: 'purple', backgroundColor: red[500]},
  [NEW]: { color: 'white', backgroundColor: blue[500]},
  [OPENED]: { color: 'white', backgroundColor: green[500]},
  [CLOSED]: { color: 'white', backgroundColor: 'rgb(204,204,204)'},
}

////////////////////////////////////////////////////////////////////////////////POS Session
export const StatusField = withStyles(statusStyles)(({ classes, ...props }) => {
  let status = get(props.record, 'id')
  let className = get(classes, status)
  return (
    <ChipField className={className} {...props} />
  )
})
