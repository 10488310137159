import React, { Component, Fragment } from 'react'
import FuzzyPicker from './fuzzy-picker'
import './index.css'
import { translate, addField } from 'react-admin'
import compose from 'recompose/compose'
import {
  TextField,
  withStyles,
  InputAdornment,
  IconButton,
} from '@material-ui/core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = {
  root: {
    margin: '16px 0px 8px',
  },
}

class FuzzySelectArrayInput extends Component {
  state = {
    text: '',
    open: false,
    items: [],
  }

  constructor(props) {
    super(props)
    this.fuzzy = React.createRef()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { items: propItems, groupData } = nextProps
    if (propItems !== prevState.items) {
      if (typeof groupData === 'function') {
        let items = groupData(propItems)
        return { items }
      }
    }
    return { items: propItems }
  }

  clearData = (evt) => {
    let { input } = this.props
    input.onChange('')
    this.setState({
      itemSelected: {},
      text: '',
    })
    evt.preventDefault()
    evt.stopPropagation()
    return false
  }

  render() {
    const {
      label,
      input,
      renderItem,
      itemValue,
      disabled,
      onChange,
      meta,
      helperText,
      classes,
      autoSort,
      convertSearchText,
      options,
    } = this.props
    const { error } = meta
    const { text, open, items, } = this.state
    return (
      <Fragment>
        <TextField
          className={classes.root}
          disabled={disabled}
          label={label}
          value={text}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  className={classes.clear}
                  aria-label="Clear"
                  onClick={this.clearData}
                  tabIndex={-1}
                >
                  <FontAwesomeIcon icon={faTimes} size="xs" />
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          onClick={() => this.setState({ open: true })}
          onKeyPress={e => {
            // e.target.value = e.key
            this.fuzzy.current.onInputChanged({ target: { value: e.key } })
            var key = e.key
            setTimeout(() => {
              this.fuzzy.current.setInputValue(key)
            }, 0)
            this.setState({ open: true })
          }}
          helperText={error ? error : helperText}
          error={!!error}
        />
        <FuzzyPicker
          ref={this.fuzzy}
          isOpen={open}
          items={items}
          renderItem={renderItem}
          onClose={() => this.setState({ open: false })}
          itemValue={itemValue}
          showAllItems={true}
          onChange={item => {
            this.setState({
              text: item.name,
              open: false,
            })
            input && input.onChange(item.id)
            onChange && onChange(item.id)
          }}
          autoSort={autoSort}
          convertSearchText={convertSearchText}
          options={options}
        />
      </Fragment>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  addField
)

export default enhance(FuzzySelectArrayInput)
