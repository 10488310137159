// ------------------------------------------------------------
import * as routeSagas from './route/sagas'
import * as fareSagas from './fare/sagas'
import * as serviceSagas from './service/sagas'
import * as mapSagas from './map/sagas'
import trip from './trip/saga'
import * as  actionSagas from './action/sagas'
import * as vehicleSagas from './vehicle/sagas'
import * as vehicleTypeSagas from './vehicletype/sagas'
import * as reservationSagas from './reservation/sagas'
import * as scheduleSagas from './schedule/sagas'
import licenceSagas from './licence/sagas'
import serviceReminderSagas from './calendar/sagas'
import companyReservationSagas from './company_reservation/sagas'

export default [
  // Route sagas
  routeSagas.requestGetZones,

  // Fare sagas
  fareSagas.requestGetListFareRules,
  fareSagas.requestGetSelectedFareRules,

  // Service sagas
  serviceSagas.requestGetListServiceDays,
  serviceSagas.requestGetSelectedServiceDays,

  //Trip sagas
  trip,
  // Licence sagas
  licenceSagas,
  // Map sagas
  mapSagas.requestGetSquareGrid,
  mapSagas.requestGetJourney,

  actionSagas.takeResourceRequest,
  vehicleSagas.handleVehicle,
  vehicleTypeSagas.handleVehicleType,

  // Reservation
  reservationSagas.requestSearchTrip,
  reservationSagas.requestGetTripDetail,
  reservationSagas.requestGetSeatMap,
  reservationSagas.requestGetReservedSeat,
  reservationSagas.requestCalculateChargesFare,
  reservationSagas.requestMakeReservation,
  reservationSagas.requestMakeQuotation,
  reservationSagas.requestGetReservationDetail,

  // Schedule
  scheduleSagas.schedule,
  //ServiceReminder
  serviceReminderSagas,
  companyReservationSagas
]
