import {
  RESERVATION_INIT,
  RESERVATION_TRIP_TYPE,
  RESERVATION_SELECT_TRIP,
  RESERVATION_SELECT_SEAT_MAP,
  RESERVATION_RESERVED_SEAT,
  RESERVATION_ADD_PASSENGER_CHARGE,
  RESERVATION_UPDATE_BOOKING_INFORMATION,
  RESERVATION_UPDATE_PASSENGER_INFORMATION,
  RESERVATION_UPDATE_TOTAL_CHARGE_FARE,
  RESERVATION_UPDATE_TRANSACTION,
  RESERVATION_RESET,
  RESERVATION_CHANGE_ENDPOINT,
  RESERVATION_UPDATE_PAY_LATER,
  RESERVATION_UPDATE_PAYMENT_INFORMATION
} from './actions'

const init = () => {
  return {
    search: {
      'INBOUND': {
        vehiclePlate: null,
        originId: 0,
        destinationId: 0,
        departureTime: new Date().toISOString()
      },
      'OUTBOUND': {
        vehiclePlate: null,
        originId: 0,
        destinationId: 0,
        departureTime: new Date().toISOString()
      }
    },
    steps: {
      trip: {
        label: 'resources.reservations.steps.trip',
        error: false
      },
      bookingInformation: {
        label: 'resources.reservations.steps.bookingInformation',
        error: false
      },
      passengers: {
        label: 'resources.reservations.steps.passengers',
        error: false
      },
      review: {
        label: 'resources.reservations.steps.review',
        error: false
      },
      payment: {
        label: 'resources.reservations.steps.payment',
        error: false
      }
    },
    isRoundTrip: false,
    reservationId: 0,
    trips: {},
    seatMap: {},
    reservedSeatMap: {},
    inProgressBooking: {},
    bookingInformation: {},
    passengers: [],
    charges: [],
    // transactions: [],
    totalFare: 0,
    paid: 0,
    debt: 0,
    isPayLater: true,
    transaction: {
      paid: 0,
      paymentMethodId: null,
      type: '00NORMAL',
      status: null,
      currencyId: null
    }
  }
}

export const reservation = (state = init(), action) => {

  switch (action.type) {
    case RESERVATION_CHANGE_ENDPOINT: {
      let { payload } = action
      let { search } = state
      let {
        roundType,
        originId,
        destinationId,
        vehiclePlate,
        departureTime
      } = payload
      search[roundType] = { originId, destinationId, vehiclePlate, departureTime }
      return { ...state, search: { ...search } }
    }
    case RESERVATION_TRIP_TYPE: {
      let { payload } = action
      let { isRoundTrip } = payload
      return { ...state, isRoundTrip }
    }
    case RESERVATION_SELECT_TRIP: {
      let { payload } = action
      let { trip, roundType } = payload
      let { trips, inProgressBooking } = state
      if (!inProgressBooking[roundType]) {
        inProgressBooking[roundType] = {
          trip: {},
          seats: {}
        }
      }
      inProgressBooking[roundType].trip = trip

      trips[roundType] = trip
      return { ...state, trips: { ...trips }, inProgressBooking: { ...inProgressBooking } }
    }
    case RESERVATION_SELECT_SEAT_MAP: {
      let { payload } = action
      let { roundType, seats } = payload
      let { seatMap } = state
      seatMap[roundType] = seats
      return { ...state, seatMap: { ...seatMap } }
    }
    case RESERVATION_RESERVED_SEAT: {
      let { payload } = action
      let { roundType, reservedSeats } = payload
      let { reservedSeatMap } = state
      reservedSeatMap[roundType] = reservedSeats
      return { ...state, reservedSeatMap: { ...reservedSeatMap } }
    }
    case RESERVATION_ADD_PASSENGER_CHARGE: {
      let { payload } = action
      let { roundType, passenger, charge } = payload
      let { passengers, charges, inProgressBooking } = state
      let { trip, seats } = inProgressBooking[roundType]

      let totalPassenger = passengers.length

      let passengerIndex = totalPassenger + 1
      passenger.index = passengerIndex
      passengers.push(passenger)

      charge.passenger = { index: passengerIndex }
      charge.tripId = trip.tripId
      charges.push(charge)

      seats[charge.itemDetail.itemCode] = passenger
      inProgressBooking[roundType].seats = seats
      return {
        ...state,
        passengers: [...passengers],
        charges: [...charges],
        inProgressBooking: { ...inProgressBooking }
      }
    }
    case RESERVATION_UPDATE_BOOKING_INFORMATION: {
      let { payload } = action

      let { bookingInformation } = state
      bookingInformation.contact = payload
      return { ...state, bookingInformation: { ...bookingInformation } }
    }
    case RESERVATION_UPDATE_PASSENGER_INFORMATION: {
      let { payload } = action
      let { passengerIndex, updatedData } = payload
      let { passengers } = state

      let updatedDataKeys = Object.keys(updatedData)

      let existedIdx = passengers.findIndex((el) => el.index === passengerIndex)
      let existed = passengers[existedIdx]

      for (let i = 0; i < updatedDataKeys.length; i++) {
        existed[updatedDataKeys[i]] = updatedData[updatedDataKeys]
      }
      passengers[existedIdx] = { ...existed, updatedData }

      return {
        ...state,
        passengers: [...passengers]
      }
    }
    case RESERVATION_UPDATE_TOTAL_CHARGE_FARE: {
      let { payload } = action
      return {
        ...state,
        ...payload
      }
    }
    case RESERVATION_UPDATE_PAY_LATER: {
      let { payload } = action
      return {
        ...state,
        isPayLater: payload
      }
    }
    case RESERVATION_UPDATE_TRANSACTION: {
      let { payload } = action
      let { transaction } = state
      return {
        ...state,
        transaction: { ...transaction, ...payload }
      }
    }
    case RESERVATION_RESET: {
      return init()
    }
    case RESERVATION_INIT: {
      let { payload } = action
      let {
        reservationId,
        bookingInformation,
        trips,
        passengers,
        charges,
        paymentInformation,
        transactions,
        status,
        createdAt,
        source,
        agencyId,
      } = payload
      let { total, paid, debt } = paymentInformation
      return {
        ...state,
        reservationId,
        status,
        createdAt,
        trips: { ...trips },
        charges: [...charges],
        passengers: [...passengers],
        totalFare: total,
        paid,
        debt,
        transactions: [...transactions],
        bookingInformation,
        source,
        agencyId,
      }
    }
    case RESERVATION_UPDATE_PAYMENT_INFORMATION: {
      let { payload } = action
      let { paid, debt } = state
      return {
        ...state,
        paid: paid + payload.paid,
        debt: debt - payload.paid
      }
    }
    default:
      return state
  }
}
