import React from 'react'
import { withStyles, Dialog, CircularProgress } from '@material-ui/core'

const progressStyle = {
  container: {
    overflow: 'hidden',
    boxShadow: 'none',
    background: 'none',
  }
}

const DialogLoading = withStyles(progressStyle)(({ classes, open }) => {
  return <Dialog open={open} classes={{ paper: classes.container }}>
    <CircularProgress />
  </Dialog>
})

export default DialogLoading
