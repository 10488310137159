import React, { Component } from 'react'
import {
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  RadioButtonGroupInput,
  SelectArrayInput,
  translate,
} from 'react-admin'
import {
  Grid,
  Dialog,
  DialogTitle,
  Radio,
  RadioGroup,
  FormControlLabel,
  DialogContent,
  DialogActions,
  Button,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { DatePickerInput } from '../common/DatePicker'
import CheckboxInput from '../common/CheckboxInput'
import CheckboxGroupInput from '../common/CheckboxGroupInput'
import moment from 'moment'
import { saveReminder as saveReminderAction } from '../licence/action'
import compose from 'recompose/compose'
import { connect } from 'react-redux'
import ObjectLicence, { ExpiredText } from '../licence/ObjectLicence'
import { FuzzySelectInput, defaultFuzzySearch } from '../common/react-fuzzy-picker'
import PrincipalField from './PrincipalField'
import isEmpty from 'lodash/isEmpty'
import CustomToolbar from '../common/CustomToolbarForm'

const repeatChoice = {
  id: 1, name: 'Lặp lại'
}

const CHANGE_ONE = '1'
const CHANGE_AFTER = '2'

const updateChoices = [
  { id: CHANGE_ONE, name: 'Lời nhắc này' },
  { id: CHANGE_AFTER, name: 'Lời nhắc này và các lời nhắc sau' },
]

const UNLIMITED = '1'
const FINISH_BY_DAY = '2'
const FINISH_BY_TIMES = '3'

const finishTypes = [
  { id: UNLIMITED, name: 'Không bao giờ' },
  { id: FINISH_BY_DAY, name: 'Kết thúc vào ngày' },
  { id: FINISH_BY_TIMES, name: 'Kết thúc sau' },
]

const dayOfMonth = () => {
  let daysInMonth = moment().daysInMonth()
  let daysInMonths = []
  for (let i = 1; i < daysInMonth + 1; i++) {
    daysInMonths.push({
      id: i, name: i
    })
  }
  return daysInMonths
}

const initValue = (defaultDueAt, applyToNumber = 10) => {
  return {
    dueAt: defaultDueAt || new Date(),
    repeat: '',
    name: '',
    notifyBefore: 0,
    notifyFrequencyUnit: '',
    applyTo: new Date(),
    dueInterval: 0,
    dueFrequencyUnit: '',
    finishType: UNLIMITED,
    applyToNumber,
    desc: ''
  }
}

const PrincipalInfo = translate(({
  principal = {},
  principalType = {},
  servicetaskRecord = {},
  translate,
}) => {
  let { icon, fieldShow, extrasFieldShow, hasExtras, getOptionText } = principalType
  let pName = principal[fieldShow]
  let extrasValue = principal[extrasFieldShow]
  if (getOptionText) {
    pName = getOptionText(principal, principalType, fieldShow)
    extrasValue = getOptionText(principal, principalType, extrasFieldShow)
  }
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar>
          {icon}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <div>
            {hasExtras
              ? <span>{pName} (<ExpiredText validTo={extrasValue} />)</span>
              : <span>{pName}</span>
            }
            <br/>
            <div style={{ display: 'flex' }}>
              <span>{translate('resources.servicereminders.fields.principalName')}</span>:&nbsp;
              <ObjectLicence record={principal} showText={true} />
            </div>
          </div>
        }
        secondary={<span>{translate('resources.servicereminders.fields.serviceTaskId')}: {servicetaskRecord.name}</span>}
      />
    </ListItem>
  )
})

const _ChangeReminderConfirm = ({ open, onClose, value, onChange, onDone, translate }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
    >
      <DialogTitle id="simple-dialog-title">
        {translate('resources.servicereminders.title.confirm_changing')}
      </DialogTitle>
      <DialogContent>
        <RadioGroup
          value={value}
          onChange={(event) => onChange(event.target.value)}
        >
          {updateChoices.map((updateChoice, idx) => (
            <FormControlLabel
              key={idx}
              value={updateChoice.id}
              control={<Radio />}
              label={updateChoice.name}
            />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>
          {translate('button.cancel')}
        </Button>
        <Button onClick={() => onDone()} color="primary">
          {translate('button.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ChangeReminderConfirm = translate(_ChangeReminderConfirm)

const nomalize = (choices, principalType) => {
  let { fieldShow, getOptionText } = principalType
  let result = []
  for (let i = 0; i < choices.length; i ++) {
    let choice = choices[i]
    let value = choice[fieldShow]
    if (getOptionText) {
      value = getOptionText(choice, principalType, fieldShow)
    }
    if (value) {
      result.push({...choice, [fieldShow]: value})
    }
  }
  return result
}

class WrapperFuzzySelectInput extends Component {
  constructor(props) {
    super(props)
    this.state = {
      options: []
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { choices, principalType } = nextProps
    let { options } = prevState
    if (isEmpty(options)) {
      options = nomalize(choices, principalType)
    }
    return { options }
  }

  render () {
    let { principalType, input, label, resource } = this.props
    let { options } = this.state
    return <FuzzySelectInput
      choices={options}
      renderItem={item => item[principalType.fieldShow]}
      optionText={principalType.fieldShow}
      input={input}
      label={label}
      resource={resource}
      {...defaultFuzzySearch({ name: principalType.fieldShow })}
    />
  }
}

class ServiceReminderFormSave extends Component {
  constructor(props) {
    super(props)
    let currentDay = moment(new Date()).day().toString()
    this.state = {
      principalId: null,
      defaultDueAt: new Date(),
      isRepeat: false,
      frequencyUnit: 'days',
      finishType: UNLIMITED,
      daysOfWeek: currentDay,
      record: {},
      isChangeNormal: false,
      isChangeRepeatConfig: false,
      openConfirm: false,
      confirmValue: CHANGE_ONE,
      isFrequencyUnitDisable: false,
    }
  }

  componentDidMount() {
    let {
      record,
      isCreate,
      principal,
      principalType,
      servicetaskRecord,
      defaultDueAt,
    } = this.props
    let {
      record: currentRecord,
      isRepeat,
      frequencyUnit,
      finishType,
      daysOfWeek: currnetDaysOfWeek,
      isFrequencyUnitDisable,
    } = this.state
    if (isCreate) {
      currentRecord = initValue(defaultDueAt)
    } else {
      currentRecord = record
      let {
        lastRepeat,
        repeatNo,
        repeat,
      } = currentRecord
      if (!repeat) {
        isFrequencyUnitDisable = false
      } else if (!lastRepeat && repeatNo !== 0) {
        isFrequencyUnitDisable = true
      }
    }
    currentRecord = {
      ...currentRecord,
      principalName: (principal && principalType) ? principal[principalType.fieldShow] : '',
      serviceTaskId: servicetaskRecord ? servicetaskRecord.id : 0,
      serviceTaskName: servicetaskRecord ? servicetaskRecord.name : '',
    }
    let {
      repeat,
      dueFrequencyUnit,
      unLimited,
      applyTo,
      applyToNumber,
      daysOfWeek,
    } = currentRecord
    isRepeat = repeat
    if (isRepeat) {
      frequencyUnit = dueFrequencyUnit
      if (unLimited) {
        finishType = UNLIMITED
      }
      if (applyTo) {
        finishType = FINISH_BY_DAY
      }
      if (applyToNumber) {
        finishType = FINISH_BY_TIMES
      }
      currnetDaysOfWeek = daysOfWeek || []
    }
    this.setState({
      isRepeat,
      frequencyUnit,
      finishType,
      record: currentRecord,
      daysOfWeek: currnetDaysOfWeek,
      isFrequencyUnitDisable,
      isChangeRepeatConfig: false
    })
  }
  handleOnChangePrincipal = (event, value) => {
    this.setState({
      principalId: value
    })
  }

  isRepeatFunc = (isRepeat) => {
    this.setState({
      isRepeat,
    })
    if (!isRepeat) {
      this.setState({
        isChangeNormal: false,
      })
    }
  }

  handleChangeFrequencyUnit = (event, value) => {
    this.setState({
      frequencyUnit: value,
      isChangeNormal: false,
      confirmValue: CHANGE_AFTER
    })
  }

  handleChooseFinishType = (event, value) => {
    this.setState({
      finishType: value,
      isChangeNormal: false,
      confirmValue: CHANGE_AFTER
    })
  }

  changeRepeatConfig = (newRecord, currentRecord) => {
    let { applyTo, applyToNumber, dueInterval, dueFrequencyUnit } = newRecord
    let {
      applyTo: currentApplyTo,
      applyToNumber: currentApplyToNumber,
      dueInterval: currentDueInterval,
      dueFrequencyUnit: currentDueFrequencyUnit
    } = currentRecord
    if (
      applyTo !== currentApplyTo ||
      applyToNumber !== currentApplyToNumber ||
      dueInterval !== currentDueInterval ||
      dueFrequencyUnit !== currentDueFrequencyUnit
    ) {
      return true
    }
    return false
  }

  handleSave = record => {
    let { saveReminderAction, done, error, isCreate } = this.props
    let {
      isRepeat,
      isChangeNormal,
      confirmValue,
      isChangeRepeatConfig,
      record: currentRecord,
    } = this.state
    let {
      finishType,
      applyToNumber,
      applyTo,
      daysOfWeek,
    } = record
    let repeatObj
    if (isRepeat) {
      if (finishType === UNLIMITED) {
        repeatObj = { unLimited: true, applyToNumber: 0, applyTo: null }
      }
      if (finishType === FINISH_BY_DAY) {
        repeatObj = { unLimited: false, applyToNumber: 0, applyTo }
      }
      if (finishType === FINISH_BY_TIMES) {
        repeatObj = { unLimited: false, applyToNumber, applyTo: null }
      }
      record = {
        ...record,
        daysOfWeek,
        ...repeatObj,
      }
      if (!isCreate) {
        isChangeRepeatConfig = this.changeRepeatConfig(record, currentRecord)
        if (isChangeRepeatConfig) {
          isChangeNormal = false
          confirmValue = CHANGE_AFTER
        }
        if (isChangeNormal) {
          this.setState({
            openConfirm: true
          })
          return
        }
      }
    }
    record = {
      ...record,
      updateType: confirmValue,
      isChangeRepeatConfig,
    }
    if (!isChangeNormal) {
      saveReminderAction(record, done, error)
    }
  }

  handleOkClick = () => {
    this.setState({
      openConfirm: false,
      isChangeNormal: false
    })
  }

  handleCloseConfirm = () => {
    this.setState({
      openConfirm: false,
      confirmValue: CHANGE_ONE,
    })
  }

  onChangeName = () => {
    let { isRepeat, isChangeRepeatConfig } = this.state
    if (!isChangeRepeatConfig && isRepeat) {
      this.setState({
        isChangeNormal: true
      })
    }
  }

  handleChangeConfirmValue = (value) => {
    this.setState({
      confirmValue: value
    })
  }

  render() {
    const {
      servicetaskRecord,
      principal,
      isCreate,
      principalType = {},
      translate,
    } = this.props
    let {
      principalId,
      finishType,
      isRepeat,
      frequencyUnit,
      record,
      openConfirm,
      confirmValue,
      isFrequencyUnitDisable,
    } = this.state
    let daysInMonths = dayOfMonth()
    let extras = {
      resource: 'servicereminders',
      fullWidth: true
    }
    return (
      <>
        <SimpleForm
          resource="servicereminders"
          record={{ ...record }}
          form={isCreate ? 'reminderFormCreate' : 'reminderFormEdit'}
          save={this.handleSave}
          toolbar={<CustomToolbar />}
        >
          {principal ? <PrincipalField
            serviceReminder={{ serviceTask: servicetaskRecord }}
            principal={principal}
            renderChild={(data, pType) => (
              <PrincipalInfo
                principalType={pType}
                principal={data}
                servicetaskRecord={servicetaskRecord}
              />
            )}
          /> : <ReferenceInput
            source="principalName"
            basePath={`/${principalType.principalResource}`}
            reference={`${principalType.principalResource}`}
            onChange={this.handleOnChangePrincipal}
            perPage={1000}
            filter={{...principalType.extrasFilter}}
            {...extras}
          >
            <WrapperFuzzySelectInput principalType={principalType} />
          </ReferenceInput>
          }
          {principalId && <ReferenceInput
            source="serviceTaskId"
            basePath="/servicetasks"
            reference="servicetasks"
            filter={{ principalId: principalId, principalType: principalType.principal }}
            {...extras}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          }
          <TextInput source="name" onChange={this.onChangeName} {...extras} />
          <DatePickerInput
            keyboard={true}
            source="dueAt"
            minDate={moment()}
            {...extras}
          />
          <TextInput source="desc" fullWidth />
          <CheckboxInput
            extrasFunction={this.isRepeatFunc}
            source="repeat"
            defaultValue={isRepeat}
            label={translate('resources.servicereminders.fields.repeat')}
            content={translate('resources.servicereminders.fields.repeat')}
            choice={repeatChoice}
            {...extras}
          />
          {isRepeat && <>
            <Typography
              variant="h6"
              component="h6"
            >
              Cài đặt lặp lại
            </Typography>
            <Grid container>
              <Grid item xs={6} md={6}>
                <TextInput
                  type="number"
                  source="dueInterval"
                  {...extras}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <ReferenceInput
                  source="dueFrequencyUnit"
                  basePath="/frequencyunits"
                  reference="frequencyunits"
                  resource="frequencyunits"
                  filter={{ id: { neq: 'hours' } }}
                  sort={{ field: 'identifier', order: 'asc' }}
                  onChange={this.handleChangeFrequencyUnit}
                  disabled={isFrequencyUnitDisable}
                  {...extras}
                >
                  <SelectInput optionText="name" />
                </ReferenceInput>
              </Grid>
            </Grid>
            {frequencyUnit === 'weeks' && <ReferenceArrayInput
              source="daysOfWeek"
              reference="servicedays"
              sort={{ field: 'id', order: 'asc' }}
              {...extras}
              required
            >
              <CheckboxGroupInput
                direction="row"
              />
            </ReferenceArrayInput>}
            {frequencyUnit === 'months' && <SelectArrayInput
              source="daysOfMonth"
              choices={daysInMonths}
              {...extras}
            />}
            <Grid container>
              <Grid item xs={6} md={6}>
                <RadioButtonGroupInput
                  onChange={this.handleChooseFinishType}
                  defaultValue={finishType}
                  source="finishType"
                  choices={finishTypes}
                  {...extras}
                />
              </Grid>
              <Grid item xs={6} md={6}>
                <div></div>
                {<DatePickerInput
                  disabled={finishType !== '2'}
                  source="applyTo"
                  {...extras}
                />}
                {<TextInput
                  disabled={finishType !== '3'}
                  source="applyToNumber"
                  type="number"
                  {...extras}
                />}
              </Grid>
            </Grid>
          </>}
        </SimpleForm>
        <ChangeReminderConfirm
          open={openConfirm}
          onClose={this.handleCloseConfirm}
          value={confirmValue}
          onChange={this.handleChangeConfirmValue}
          onDone={this.handleOkClick}
        />
      </>
    )
  }
}

const enhance = compose(
  translate,
  connect(
    null,
    { saveReminderAction }
  )
)

export default enhance(ServiceReminderFormSave)
