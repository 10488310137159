import { formatPhoneNumber } from '../utils/formatUtil'

export const PHONE = 'phone'

export function format(data, type, format) {
  switch(type) {
    case PHONE:
      data = formatPhoneNumber(data, format)
      return data
    default:
      return data
  }
}