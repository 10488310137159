import React, { Component } from 'react'
import {
  translate,
  FunctionField,
  showNotification,
  refreshView,
  ReferenceField,
} from 'react-admin'
import {
  withStyles,
  List as MUIList,
  ListItem,
  Popover,
  ListItemIcon,
  ListItemText,
  Tooltip,
  IconButton,
} from '@material-ui/core'
import UnRegisterRouteButton from './UnRegisterRouteButton'
import UnRegisterFareButton from './UnRegisterFareButton'
import EditProductRouteButton from './EditProductRouteButton'
import compose from 'recompose/compose'
import { formatCurrency, limitTextLength } from '../utils/formatUtil'
import _ from 'lodash'
import classnames from 'classnames'
import ProductFareTypeChip from './ProductFareTypeChip'
import ProductFareCodeChip from './ProductFareCodeChip'
import { Provider } from '../provider'
import { connect } from 'react-redux'
import {
  ProductFareTypeIconMapping,
  DELETE,
  UPDATE,
  CREATE,
} from './constant'
import InfoIcon from '@material-ui/icons/Info'
import { withLoading } from '../common/withLoading'
import ProductFareDescriptionChip from './ProductFareDescriptionChip'

function saveNewRouteProduct(data, ids, newRouteProduct){
  let { id: routeProductId } = newRouteProduct
  if (!data[routeProductId]) {
    ids.unshift(routeProductId)
  } else {
    let routeProdcut = data[routeProductId]
    let { fares: prevFares } = routeProdcut
    let { fares: newFares } = newRouteProduct
    newRouteProduct = { ...newRouteProduct, fares: [ ...prevFares, ...newFares ]}
  }
  data = { ...data, [routeProductId]: newRouteProduct }
  return { ids, data }
}

export function getProductFareByParentFareId(routeProducts) {
  let childFares = _.reduce(routeProducts, (result, ele) => {
    let { childFares } = ele
    if (childFares) {
      for (let productId in childFares) {
        let { productFares } = childFares[productId]
        productFares = productFares.map(productFare => ({ ...productFare, productId: parseInt(productId) }))
        result = [...result, ...productFares]
      }
      return result
    }
  }, [])
  let parentFares = _.reduce(routeProducts, (result, ele) => {
    let { fares } = ele
    return [...result, ...fares]
  }, [])
  let parentFareMapping = _.keyBy(parentFares, 'productFareId')
  let productFareByParentFareId = _.groupBy(childFares,'parentProductFareId')
  return _.reduce(parentFareMapping, (result, parentFare, productFareId) => {
    result[productFareId] = parentFare
    let childFares = productFareByParentFareId[productFareId] || []
    if (childFares) {
      result[productFareId] = { ...parentFare, childFares }
    }
    return result
  }, {})
}

const getProductFareMapping = (data, ids) => {
  let fares = _.reduce(ids, (result, id) => {
    if (id) {
      let { fares } = data[id] || {}
      if (fares) {
        result = [...result, ...fares]
      }
    }
    return result
  }, [])

  let productFare = _.keyBy(fares, 'productFareId')
  return productFare
}

const fareDetailStyle = {
  text: {
    color: 'white',
  },
  emptyText: {
    color: 'white',
    padding: 8,
  }
}


const productFareTypeChoiceStyle = theme => ({
  paper: {
    padding: 2,
  },
  image: {
    [theme.breakpoints.down('sm')]:{
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]:{
      marginRight: 4,
    },
    width: 25,
    height: 13,
  },
  icon: {
    [theme.breakpoints.down('sm')]:{
      marginRight: 0,
    },
  },
})

const ProductFareTypeChoice = withStyles(productFareTypeChoiceStyle)(({
  classes,
  open,
  anchorEl,
  selected,
  handlePopoverClose,
  choices,
  onChange,
  isXSmall,
}) => {
  return <Popover
    className={classes.popover}
    classes={{ paper: classes.paper }}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    onClose={() => handlePopoverClose()}
  >
    <MUIList component="nav">
      {choices && choices.map((choice, idx) => {
        let { id, name } = choice
        let icon = ProductFareTypeIconMapping[id]
        return <ListItem
          key={idx}
          button
          onClick={() => onChange(id)}
          selected={selected === id}
        >
          <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
          {!isXSmall && <ListItemText primary={name} />}
        </ListItem>
      })}
    </MUIList>
  </Popover>
})

class _FareDetail extends Component {

  state = {}

  componentDidMount() {
    let { fares } = this.props
    this.setState({ fares })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { fares } = nextProps
    let { fares: currentFares } = prevState
    if (fares !== currentFares) {
      return { ...prevState, fares }
    }
    return { ...prevState }
  }

  render() {
    let { classes, translate } = this.props
    let { fares } = this.state
    return <Tooltip
      title={!_.isEmpty(fares) ? <MUIList component="nav">
        {fares.map((fare, idx) => {
          let { productId, amount } = fare
          return <ListItem key={idx}>
            <ListItemText primary={<ReferenceField
              basePath="/products"
              resource="products"
              reference="products"
              record={{ productId }}
              source="productId"
              allowEmpty
              linkType={false}
            >
              <FunctionField
                render={record => <span className={classes.text}>{record.name}:&nbsp;{amount ? formatCurrency(amount) : '0đ'}</span>} 
              />
            </ReferenceField>
            } 
            />
          </ListItem>
        })}
      </MUIList> : <span className={classes.emptyText}>{translate('resources.products.no_child_product')}</span>}
    >
      <IconButton>
        <InfoIcon />
      </IconButton>
    </Tooltip>
  }
}

const enhanceFareDetail = compose(withStyles(fareDetailStyle), translate)
const FareDetail = enhanceFareDetail(_FareDetail)

const fareListStyles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  textContainer: {
    width: '20%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    textAlign: 'center',
  },
  actionContainer: {
    width: '25%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
    textAlign: 'center',
  },
  item: {
    display: 'flex',
    width: '100%',
  },
  secondaryActionRoot: {
    position: 'inherit',
    alignSelf: 'center',
  },
  quantityInput: {
    height: 0,
    width: 50,
  },
  header: {
    backgroundColor: '#fff59d'
  },
  tooltip: {
    backgroundColor: '#f44336'
  },
  startCol: {
    paddingLeft: 8,
    width: '20%',
    wordWrap: 'break-word',
    fontSize: '0.8571428571428571rem',
    margin: 'auto',
  },
  endColStyle: {
    width: '10%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 0,
  },
  noPaddingHorizontal: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  fareEmpty: {
    width: '100%',
    margin: 'auto',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 16,
    fontWeight: 600,
    padding: 16,
  },
}

class _FareList extends Component {

  render() {
    let {
      fares,
      classes,
      translate,
      isCompanyManager,
      productFareTypes,
      handleOpenMenuProductFareType,
      productFare,
      updateProductFareMapping,
      routeProductId,
      routeId,
      updateChildRouteProductsAfterRemoveFare,
      parentProductId,
      productFareMapping = {},
    } = this.props

    let availableFares = _.reduce(fares, (result, ele) => {
      let { productFareId } = ele
      let pf = productFare[productFareId]
      if (pf) {
        ele = { ...ele, type: pf.type, code: pf.code }
        result.push(ele)
      }
      return result
    },[])
    return <MUIList disablePadding className={classes.root}>
      <ListItem className={classnames(classes.header, classes.noPaddingHorizontal)}>
        <div className={classes.item}>
          <div className={classes.startCol}>
            <b>{translate('resources.saletickets.fareType')}</b>
          </div>
          <div className={classes.textContainer}>
            <b>{translate('resources.saletickets.unit')}</b>
          </div>
          <div className={classes.actionContainer}>
            <b>{translate('resources.common.desc')}</b>
          </div>
          <div className={classes.actionContainer}>
            <b>{translate('resources.saletickets.type')}</b>
          </div>
          <div className={classes.actionContainer}>
            <b>{translate('resources.saletickets.fareCode')}</b>
          </div>
          {!parentProductId && <div className={classes.endColStyle}>
          </div>}
        </div>
      </ListItem>
      {!_.isEmpty(availableFares) ? availableFares.map((fare, idx) => {
        let { code, name, amount, productFareId, type } = fare
        let icon = ProductFareTypeIconMapping[type]
        let productFareTypeLabel = _.find(productFareTypes, ele => ele.id === type)
        let productFare = productFareMapping[productFareId] || {}
        let childFares = productFare.childFares
        return <ListItem
          className={classes.noPaddingHorizontal}
          key={idx}
        >
          <div className={classes.item}>
            <Tooltip title={name}>
              <div className={classes.startCol}>
                {limitTextLength(name, 10)}
              </div>
            </Tooltip>
            <div className={classes.textContainer}>
              <div>{amount ? formatCurrency(amount) : '0đ'}&nbsp;{!parentProductId && <FareDetail fares={childFares} />}</div>
            </div>
            {isCompanyManager && <div className={classes.actionContainer}>
              <ProductFareDescriptionChip
                isCompanyManager={isCompanyManager}
                record={fare}
                source="desc"
              />
            </div>}
            {isCompanyManager && <div className={classes.actionContainer}>
              <ProductFareTypeChip
                label={productFareTypeLabel && productFareTypeLabel.name}
                openMenu={handleOpenMenuProductFareType}
                icon={icon}
                type={type}
                record={{ productFareId, routeProductId, type }}
                parentProductId={parentProductId}
              />
            </div>}
            <div className={classes.actionContainer}>
              <ProductFareCodeChip
                isCompanyManager={isCompanyManager}
                record={{ id: productFareId, code }}
                source="code"
              />
            </div>
            {(isCompanyManager && !parentProductId) && <div className={classes.endColStyle}>
              <UnRegisterFareButton
                record={{ productFareId, routeProductId }}
                updateProductFareMapping={updateProductFareMapping}
                updateChildRouteProductsAfterRemoveFare={updateChildRouteProductsAfterRemoveFare}
                routeId={routeId}
              />
            </div>}
          </div>
        </ListItem>
      }) : <div className={classes.fareEmpty}>{translate('resources.products.route_no_fare')}</div>}
    </MUIList>
  }
}

const FareList = compose(withStyles(fareListStyles), translate)(_FareList)

const routeFareStyles = {
  root: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    marginBottom: 32,
    borderBottom: '1px solid #e8e8e8'
  },
  routeInfo: {
    display: 'flex',
    padding: 4,
  },
  routeText: {
    textAlign: 'start',
    margin: 'auto',
    flex: 1,
  },
  textPrimary: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  textSecondary: {
    fontSize: 10,
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
  unit: {
    textTransform: 'lowercase'
  },
  actionContainer: {
    display: 'flex',
    margin: 'auto',
  },
}

const _RouteFare = ({ 
  classes,
  isCompanyManager,
  routeIdx,
  record,
  productFareTypes,
  handleOpenMenuProductFareType,
  productFare,
  updateProductFareMapping,
  updateProductRouteMapping,
  updateChildRouteProductsAfterRemoveRoute,
  updateChildRouteProductsAfterRemoveFare,
  updateChildRouteProductsAfterAdd,
  parentProductId,
  productFareMapping,
}) => {
  let { fares = [], childFares = [], route = {}, routeId, productId, id } = record
  let childProductFares = _.reduce(childFares, (result, value) => {
    let { productFares } = value 
    result = [...productFares]
    return result
  })
  let productFareIds = _.reduce(fares, (result, fare) => {
    let { productFareId } = fare
    if (productFare[productFareId]) {
      result.push(productFareId)
    }
    return result
  }, [])

  let childProducts = _.reduce(childFares, (result, value) => {
    if (value) {
      let { routeProductId, productFares } = value
      let productFareIds = productFares.map(ele => ele.productFareId)
      result.push({ routeProductId, productFareIds })
    }
    return result
  }, [])

  return <div className={classes.root}>
    <div className={classes.routeInfo}>
      <div className={classes.routeText}>
        <div className={classes.textPrimary}>{route.name}</div>
      </div>
      <div className={classes.actionContainer}>
        {(isCompanyManager && !parentProductId) && <EditProductRouteButton
          size="small"
          productId={productId}
          record={{ routeId }}
          updateProductRouteMapping={updateProductRouteMapping}
          updateChildRouteProductsAfterAdd={updateChildRouteProductsAfterAdd}
        />}
        {(isCompanyManager && !parentProductId) && <UnRegisterRouteButton
          size="small"
          routeId={routeId}
          record={{ productFareIds, routeProductId: id, childProducts }}
          updateProductRouteMapping={updateProductRouteMapping}
          updateChildRouteProductsAfterRemoveRoute={updateChildRouteProductsAfterRemoveRoute}
        />}
      </div>
    </div>
    <FareList
      routeProductId={id}
      fares={fares}
      childProductFares={childProductFares}
      productFare={productFare}
      isCompanyManager={isCompanyManager}
      productId={productId}
      routeIdx={routeIdx}
      productFareTypes={productFareTypes}
      handleOpenMenuProductFareType={handleOpenMenuProductFareType}
      updateProductFareMapping={updateProductFareMapping}
      routeId={routeId}
      parentProductId={parentProductId}
      updateChildRouteProductsAfterRemoveFare={updateChildRouteProductsAfterRemoveFare}
      productFareMapping={productFareMapping}
    /> 
  </div>
}

const enhanceRouteFare = compose(withStyles(routeFareStyles), translate)
const RouteFare = enhanceRouteFare(_RouteFare)

const routeFaresStyle = {
  root: {
    marginTop: 18,
    width: '100%',
  },
  title: {
    marginBottom: 50,
    textDecoration: 'underline',
  },
  totalContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  totalLabel: {
    width: '70%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  totalValue: {
    width: '30%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: 16,
  },
  routeEmpty: {
    fontStyle: 'italic',
    width: '100%',
    paddingLeft: 18,
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 17,
  },
}

class RouteFares extends Component {

state = {
  openMenuProductFareType: false,
  anchorElMenuProductFareType: null,
}

async componentDidMount() {
  let { setLoading } = this.props
  this.loadProductFareType()
  let { ids, data, childFareByParentFareId } = await this.loadData()
  let productFare = getProductFareMapping(data, ids)
  this.setState({ productFare, ids, data, childFareByParentFareId }, () => { setLoading(false) })
}

loadData = async () => {
  let { productId, setChildRouteProducts } = this.props
  let resp = await Provider.dataProvider('GET_LIST', 'routeproducts', {
    filter: {
      where: {
        productId,
        routeId: { neq: null },
      },
      fields: ['id', 'productId', 'routeId', 'fares', 'childFares'],
      include: [{
        relation: 'route',
        scope: {
          fields: ['id', 'name']
        }
      }],
      withFare: true
    },
    pagination: {},
    sort: {},
  })
  if (resp && resp.data) {
    let routeProducts = resp.data
    let childRouteProduct = _.reduce(routeProducts, (result, ele) => {
      let { childFares } = ele
      for (let productId in childFares) {
        let childFare = childFares[productId]
        let fares = result[productId] || []
        fares.push(childFare)
        result[productId] = fares
      }
      return result
    }, {})
    let productFareMapping = getProductFareByParentFareId(routeProducts)
    let data = _.keyBy(routeProducts, 'id')
    let ids = routeProducts.map(ele => ele.id)
    setChildRouteProducts(childRouteProduct, productFareMapping)
    return { ids, data, productFareMapping }
  }
}

static getDerivedStateFromProps(nextProps, prevState) {
  let { newRouteProduct, productFareMapping } = nextProps
  let {
    ids: currentIds,
    data: currentData,
    newRouteProduct: currentNewRouteProduct,
    productFareMapping: currentProductFareMapping,
    productFare,
  } = prevState 
  if (newRouteProduct && newRouteProduct !== currentNewRouteProduct) {
    let { data: newData, ids: newIds } = saveNewRouteProduct(currentData, currentIds, newRouteProduct)
    productFare = getProductFareMapping(newData, newIds)
    currentIds = newIds
    currentData = newData
    currentNewRouteProduct = newRouteProduct
  }
  if (productFareMapping !== currentProductFareMapping) {
    currentProductFareMapping = productFareMapping
  }

  return {
    ...prevState,
    productFare,
    ids: currentIds,
    data: currentData,
    newRouteProduct: currentNewRouteProduct,
    productFareMapping: currentProductFareMapping,
  }
}

updateProductRouteMapping = (routeProductId, action, value) => {
  let { ids, data } = this.state
  let newIds, newData
  if (action === DELETE) {
    newIds = _.reduce(ids, (result, id) => {
      if (id !== routeProductId) {
        result.push(id)
      }
      return result
    }, [])
    newData = _.omit(data, routeProductId)
  } else if (action === UPDATE || action === CREATE) {
    let result = saveNewRouteProduct(data, ids, value)
    newIds = result.ids
    newData = result.data
  }
  let productFare = getProductFareMapping(newData, newIds)
  this.setState({ data: newData, ids: newIds, productFare })
}

updateProductFareMapping = (productFareId, routeProductId, action, data) => {
  let { productFare, data: routeProductMapping } = this.state
  if (action === DELETE) {
    productFare = _.omit(productFare, productFareId)
    let routeProduct = routeProductMapping[routeProductId] 
    if (routeProduct) {
      let { fares } = routeProduct
      fares = _.reduce(fares, (result, ele) => {
        if (ele.productFareId !== productFareId) {
          result.push(ele)
        }
        return result
      }, [])
      routeProduct = { ...routeProduct, fares }
      routeProductMapping = { ...routeProductMapping, [routeProductId]: routeProduct }
    }
  } else if (action === UPDATE || action === CREATE) {
    let routeProduct = routeProductMapping[routeProductId] 
    if (routeProduct) {
      let { fares } = routeProduct
      let fareIdx = _.findIndex(fares, (ele => ele.productFareId === productFareId))
      let fare = fares[fareIdx]
      fare = { ...fare, ...data }
      fares[fareIdx] = fare
      routeProduct = { ...routeProduct, fares }
      routeProductMapping = { ...routeProductMapping, [routeProductId]: routeProduct }
    }
    let oldProductFare = productFare[productFareId]
    productFare = { ...productFare, [productFareId]: { ...oldProductFare, ...data } }
  }
  this.setState({ productFare, data: routeProductMapping })
}

loadProductFareType = async () => {
  let productFareTypeResp = await Provider.dataProvider('GET_LIST', 'productfaretypes', {
    sort: {},
    pagination: {},
  })
  if (productFareTypeResp && productFareTypeResp.data) {
    this.setState({ productFareTypes: productFareTypeResp.data })
  }
}

handleOpenMenuProductFareType = (event, productFare) => {
  let { type } = productFare
  this.setState({
    currentProductFareTypeValue: type,
    currentProductFare: productFare,
    openMenuProductFareType: true,
    anchorElMenuProductFareType: event.target,
  })
}
  
handleCloseMenuProductFareType = () => {
  this.setState({
    currentProductFareTypeValue: '',
    currentProductFare: {},
    openMenuProductFareType: false,
    anchorElMenuProductFareType: null,
  })
}

handleChangeProductFareType = productFareType => {
  let { showNotification } = this.props
  let { currentProductFare, currentProductFareTypeValue } = this.state
  if (productFareType !== currentProductFareTypeValue) {
    let { productFareId, routeProductId } = currentProductFare
    let data = { productFareId, type: productFareType } 
    Provider.dataProvider('REMOTE', 'productfares', {
      method: '/changeType',
      requestMethod: 'POST',
      data,
    }).then(
      () => {
        this.handleCloseMenuProductFareType()
        this.updateProductFareMapping(productFareId, routeProductId, UPDATE, data)
      }
    ).catch(
      e => {
        this.handleCloseMenuProductFareType()
        showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
      }
    )
  }
}

render() {

  let {
    classes,
    translate,
    isCompanyManager,
    updateChildRouteProductsAfterRemoveRoute,
    updateChildRouteProductsAfterRemoveFare,
    updateChildRouteProductsAfterAdd,
    parentProductId,
  } = this.props
  let {
    data,
    ids,
    productFareTypes,
    openMenuProductFareType,
    anchorElMenuProductFareType,
    currentProductFareTypeValue,
    productFare,
    productFareMapping,
  } = this.state
  return ids ? <div className={classes.root}>
    {ids.length > 0 ? <div>
      {ids.map((id, idx) => {
        let routeFare = data[id] || {}
        return <RouteFare
          key={idx}
          routeIdx={idx}
          record={routeFare}
          isCompanyManager={isCompanyManager}
          productFareTypes={productFareTypes}
          handleOpenMenuProductFareType={this.handleOpenMenuProductFareType}
          productFare={productFare}
          updateProductFareMapping={this.updateProductFareMapping}
          updateProductRouteMapping={this.updateProductRouteMapping}
          updateChildRouteProductsAfterRemoveRoute={updateChildRouteProductsAfterRemoveRoute}
          updateChildRouteProductsAfterRemoveFare={updateChildRouteProductsAfterRemoveFare}
          updateChildRouteProductsAfterAdd={updateChildRouteProductsAfterAdd}
          parentProductId={parentProductId}
          productFareMapping={productFareMapping}
        />
      })}
    </div> : <div className={classes.routeEmpty}>
      {translate('resources.products.product_no_route')}
    </div>}
    <ProductFareTypeChoice
      onChange={this.handleChangeProductFareType}
      open={openMenuProductFareType}
      choices={productFareTypes}
      anchorEl={anchorElMenuProductFareType}
      handlePopoverClose={this.handleCloseMenuProductFareType}
      selected={currentProductFareTypeValue}
    />
  </div> : null}
}

const enhanceRouteFares = compose(
  withStyles(routeFaresStyle),
  translate,
  connect(null, { showNotification, refreshView }),
  withLoading,
)

export default enhanceRouteFares(RouteFares)

