import React, { Component,Fragment } from 'react'
import {
  showNotification,
  translate,
  WithPermissions,
  ReferenceField,
} from 'react-admin'
import { Provider } from '../provider'
import { compose } from 'recompose'
import {
  withStyles,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogActions,
  Tabs,
  Tab,
  IconButton,
  GridList,
  GridListTile,
  AppBar,
  Toolbar,
  Typography,
  Button,
  DialogTitle,
  DialogContentText,
} from '@material-ui/core'
import _ from 'lodash'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Close as CloseIcon } from '@material-ui/icons'
import { deepOrange, grey, yellow, red, green } from '@material-ui/core/colors'
import { connect } from 'react-redux'
import {
  timeFormat,
  dateTimeFormat,
  shortDateTimeFormat,
} from '../common/format'
import { formatCurrency } from '../utils/formatUtil'
import moment from 'moment'
import { GET_LIST_PAGINATION } from '../provider/get'
import FareTable from '../common/FareTable'
import { observeTripAction } from './action'
import { parseUrl } from 'query-string'
import { push } from 'react-router-redux'
import { getCurrentAgency } from '../utils/commonUtil'
import withMQTT from '../mqtt/withMQTT'
import { TRIP_LAYOUT } from '../common/mqttEventEmitter'
import { withSnackbar } from '../common/notistack'
import * as chargeStatus from '../common/charge-status'
import Snackbar from '../common/Snackbar'
import * as permission from '../utils/permission'
import { StatusField, canSale, validSwapTrip } from '../viewtrip/StatusField'
import SeatCard from './SeatCard'
import AddReservationDialog from './AddReservationDialog'
import EditReservationDialog from './EditReservationDialog'
import SwapSeatToTripButton from './SwapSeatToTripButton'
import { getCampaignDiscountByPrice } from '../utils/discount'
import { isNotEmpty2D } from '../utils/array'

const MessageTypes = {
  NEW_CHARGE: 'new_charge',
  UPDATE_CHARGE: 'update_charge',
  UPDATE_TRIP: 'update_trip',
  CANCELED_CHARGE: 'canceled_charge',
  RESERVATION_CHANGED: 'RESERVATION_CHANGED',
}

const styles = theme => ({
  bigAvatar: {
    width: 60,
    height: 60,
  },
  tabRoot: {
    flexGrow: 1,
  },
  seatCard: {
    margin: 3,
    height: 280 - 6,
    display: 'flex',
    flexDirection: 'column',
  },
  swapSeatCard: {
    margin: 3,
    height: 280 - 6,
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid grey'
  },
  selectedSeatCard: {
    margin: 3,
    height: 280 - 6,
    background: yellow[100],
    display: 'flex',
    flexDirection: 'column',
  },
  swapSelectedSeatCard: {
    margin: 3,
    height: 280 - 6,
    background: yellow[300],
    display: 'flex',
    flexDirection: 'column',
  },
  layoutContainer: {
    background: '#eee',
  },
  tabs: {
    background: '#fff',
    marginTop: 80,
  },
  selectedAvatar: {
    background: deepOrange[500],
  },
  avatar: {
  },
  seatChip: {
    marginRight: 6,
  },
  empty: {
    backgroundColor: grey[500],
  },
  flex: {
    width: '100%',
  },
  floorTitle: {
    display: 'none',
    padding: 2,
  },
  seatTitle: {
    fontSize: 16,
  },
  progress: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    position: 'absolute',
  },
  seatContent: {
    padding: '8px 16px 0',
    flex: 1,
  },
  cardActions: {
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
  },
  rightText: {
    float: 'right',
  },
  '@media print': {
    appBar: {
      display: 'none'
    },
    tabs: {
      display: 'none'
    },
    floorList: {
      display: 'flex !important',
      pageBreakAfter: 'always'
    },
    floorTitle: {
      display: 'flex',
      flex: '1',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    seatCard: {
      margin: 0,
      padding: 0,
      boxShadow: 'none',
      borderStyle: 'solid',
      borderWidth: 2,
      height: '150px !important',
      display: 'flex',
      flexDirection: 'column',
    },
    selectedSeatCard: {
      margin: 0,
      padding: 0,
      boxShadow: 'none',
      borderStyle: 'solid',
      borderWidth: 2,
      height: '150px !important',
      display: 'flex',
      flexDirection: 'column',
    },
    seatAvatar: {
      backgroundColor: 'white',
      color: 'black'
    },
    seatAction: {
      display: 'none',
      width: 36,
      height: 36,
      padding: 0,
      margin: 0,
    },
    seatSubTitle: {
      color: 'black'
    },
    seatContent: {
      padding: 4,
      flex: 1,
    },
    seatHeader: {
      padding: 4
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    content: {
      flexGrow: 1,
      padding: 3,
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -240,
    },
  },
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
    color: '#555',
  },
  fullWidth: {
    width: '70%',
    maxWidth: '70%',
    minWidth: 70,
  },
  fullWidth100: {
    width: '100%',
    maxWidth: '100%',
    minWidth: 70,
  },
  snackbarPosition: {
    marginTop: 70,
    marginLeft: 32,
    marginRight: 32,
  },
  divider: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  selectPrice: {
    marginTop: 16
  },
  container: {
    marginTop: 12
  },
  icon: {
    marginRight: '0.5em',
  },
  appBarContainer: {
    display: 'flex'
  },
  appBarText: {
    display: 'flex',
    width: '80%',
  },
  appBarAction: {
    display: 'flex',
    width: '20%',
    justifyContent: 'flex-end',
  },
  printSeatMapButton: {
    marginLeft: 8,
    backgroundColor: '#00838f',
    '&:hover': {
      backgroundColor: '#26a69a',
    },
  },
  swapTripButton: {
    backgroundColor: '#0277bd',
    color: 'white',
    '&:disabled': {
      backgroundColor: '#9e9e9e',
      color: 'gray',
    },
    '&:hover': {
      backgroundColor: '#039be5',
    },
  },
})

const action = (onClick, labelButtonAction) => {
  return [
    <Button
      key={0}
      onClick={(event) => onClick(event)}
      style={{ color: '#ffff' }}
    >
      {labelButtonAction}
    </Button>
  ]
}

class _SeatLayout extends Component {

  constructor(props) {
    super(props)
    this.state = {
      tabIndex: 0,
      loading: true,
      openDrawer: false,
      cusPhone: '',
      callLogId: 0,
      showReservationDialog: false,
      showEditReservationDialog: false,
      isSwapMode: false,
      confirmSwapSeatDialog: {},
      swapLoading: true,
    }
  }

  async componentDidMount() {
    await this.reload()
    //this.comsumeCharge()
    let prevUrl = localStorage.getItem('prevUrl') || 'reservations/company_reservation'
    let cusPhone
    let callLogId
    let { location = {} } = this.props
    let { search = {} } = location
    let url = parseUrl(search)
    let query = url.query
    if (query) {
      cusPhone = query['customer_phone']
      callLogId = query['call_log']
    }
    this.setState({ cusPhone, callLogId, prevUrl })
  }

  //comsumeCharge = () => {
  //let { trip = {} } = this.state
  //let { subscribe, mqttEmitter } = this.props
  //let topic = `nexbus/trip/${trip.id}`
  //this.setState({ topic })
  //subscribe(topic)
  //mqttEmitter.on(TRIP_LAYOUT, (message) => this.onMessage(message))
  //}

  componentWillUnmount = () => {
    //let { unsubscribe } = this.props
    //let { topic } = this.state
    //unsubscribe(topic)
  }

  getReservationById = async id => {
    let result
    let reservation = await Provider.dataProvider('GET_ONE', 'reservations', {
      id,
      filter: {
        fields: ['id'],
        include: {
          relation: 'charges',
          scope: {
            where: { cancelReference: null },
            fields: ['id', 'status', 'itemDetail', 'tripId'],
            include: {
              relation: 'trip',
              scope: {
                fields: ['name', 'departureTime', 'arrivalTime']
              }
            }
          }
        }
      }
    })
    if (reservation && reservation.data) {
      result = reservation.data
    }
    return result
  }

  onMessage = async (message) => {
    let data = JSON.parse(message)
    let { isSwapMode } = this.state
    let { enqueueSnackbar, translate } = this.props
    let { name } = data
    let { payload } = data
    let { chargesWithStatus: currentCharges = {} } = this.state
    switch (name) {
      case MessageTypes.RESERVATION_CHANGED: {
        if (!isSwapMode) {
          let reservation = await this.getReservationById(payload.reservationId)
          let { charges } = reservation
          if (charges && charges.length > 0) {
            for (let i = 0; i < charges.length; i++) {
              let charge = charges[i]
              let { id, status, trip, itemDetail } = charge
              if (!currentCharges[id] || currentCharges[id] !== status) {
                let { name = '', departureTime, arrivalTime } = trip
                let { itemCode } = itemDetail
                let variant = 'success'
                let description = translate('resources.charges.newCharge')
                if (status === chargeStatus.CANCEL) {
                  variant = 'warning'
                  description = translate('resources.charges.cancelCharge')
                }
                enqueueSnackbar && enqueueSnackbar(`
                  ${name} (${moment(departureTime).format(shortDateTimeFormat)} - ${moment(arrivalTime).format(shortDateTimeFormat)}) ${description} '${itemCode}'!
                `, {
                  variant,
                })
                currentCharges[id] = status
              }
            }
            this.setState({
              chargesWithStatus: { ...currentCharges }
            })
          }
        }
        this.reload(true)
        break
      }
      default:
        break
    }
  }

  reload = async (chargeOnly = false, tripId) => {
    let { match = {}, showNotification } = this.props
    let { params = {} } = match
    if (!tripId) {
      tripId = params.tripId
    }
    const getTrip = async () => {
      let response = await Provider.dataProvider('GET_ONE', 'trips', {
        id: tripId,
        filter: {
          include: {
            relation: 'vehicle',
            scope: {
              fields: ['name', 'plate', 'type'],
              include: {
                relation: 'typeObj',
                scope: {
                  fields: 'seatMap',
                  include: {
                    relation: 'seatMapObj',
                    scope: { fields: ['codesFistFloor', 'codesSecondFloor'] }
                  },
                }
              }
            }
          },
          fields: ['id', 'departureTime', 'arrivalTime', 'arrival', 'departure', 'routeId', 'type', 'vehicleId', 'status', 'companyId']
        }
      })
      if (response) return response.data
    }
    const getCharges = async () => {
      let response = await Provider.dataProvider('GET_LIST', 'charges', {
        filter: {
          where: { tripId, status: { nin: ['30FINISH', '20CANCEL'] }, cancelReference: null },
          include: {
            relation: 'reservation',
            scope: {
              include: [
                { relation: 'bookingInformation', scope: { fields: ['id', 'contact'] } },
                { relation: 'paymentInformation', scope: { fields: ['total', 'dept', 'paid'] } },
                { relation: 'agency', scope: { fields: 'name' } },
              ],
            },
          },
        },
        ...GET_LIST_PAGINATION,
      })
      if (response) return response.data
    }

    const getTripAndFares = async () => {
      let trip = await getTrip()
      let fareTable
      if (trip) {
        fareTable = await FareTable.createFromTrip(trip)
      }
      return [trip, fareTable]
    }

    const getProducts = async () => {
      let response = await Provider.dataProvider('GET_LIST', 'products', {
        filter: {
          path: 'list',
          where: {},
          fields: [ 'id', 'name', 'type', 'companyId' ],
        },
        ...GET_LIST_PAGINATION,
      })
      if (response) return response.data
    }

    const getTripCampaigns = async () => {
      let { tripId } = this.props.match.params
      let response = await Provider.dataProvider('REMOTE', 'trips', {
        method: 'findTripCampaigns',
        requestMethod: 'POST',
        data: { ids: [tripId] }
      })
      if (response) return response.data
    }

    let { 
      trip,
      charges,
      fareTable,
      products,
      passengerCollections,
      allStop,
      campaigns,
      tripCampaigns,
      isSwapMode,
      selectedSeats,
      swapSelectedSeat,
      chargeStatus,
      confirmSwapSeatDialog,
    } = this.state
    if (!chargeOnly) {
      [[trip, fareTable], charges, products, { campaigns, tripCampaigns }] = await Promise.all([
        getTripAndFares(),
        getCharges(),
        getProducts(),
        getTripCampaigns(),
      ])
      if (trip) {
        let { departureTime, arrivalTime, routeId } = trip
        trip.departureTime = departureTime = moment(departureTime)
        trip.arrivalTime = arrivalTime = moment(arrivalTime)
        passengerCollections = await this.getPassengerCollection(routeId)
        let { dropOffPoints, pickUpPoints } = passengerCollections
        allStop = _.keyBy([...pickUpPoints, ...dropOffPoints ], 'id')
      }
      if (products && products.length > 0) {
        for (let i = 0; i < products.length; i++) {
          let product = products[i] || {}
          let { companyId, type } = product
          if (companyId === trip.companyId) {
            products[type] = product
          }
        }
        // products = _.mapKeys(products, product => product.type)
      }
    } else {
      charges = await getCharges()
    }

    if (trip && charges) {
      let reservationChargeCount = charges.reduce(function (acc, charge) {
        let { reservationId } = charge
        acc[reservationId] = (acc[reservationId] || 0) + 1
        return acc
      }, {})
      charges = _.mapKeys(charges, charge => charge.itemDetail.itemCode)
      chargeStatus = _.reduce(charges, (result = {}, charge) => {
        let { id, status } = charge
        result[id] = status
        return result
      }, {})
      // checks it's a double deck vehicle
      let { typeObj: { seatMapObj: { codesFistFloor: floor1, codesSecondFloor: floor2 } } } = trip.vehicle
      let floors = [floor1]
      if (floor2 && isNotEmpty2D(floor2)) floors.push(floor2)

      floors = floors.map(
        floor => floor.map(
          row => row.map(
            seat => ({
              seat,
              charge: seat ? charges[seat] : undefined,
            })
          )
        )
      )
      // Handle result when swap seat
      if (isSwapMode) {
        isSwapMode = false
        selectedSeats = { ...swapSelectedSeat }
        swapSelectedSeat = {}
        showNotification('notification.swap_seat_success')
        let { open } = confirmSwapSeatDialog
        if (open) {
          this.onCloseConfirmSwapSeatDialog()
        }
      }
      this.setState({
        trip,
        floors,
        charges,
        fareTable,
        products,
        loading: false,
        swapLoading: false,
        reservationChargeCount,
        passengerCollections,
        allStop,
        campaigns,
        tripCampaigns,
        isSwapMode,
        swapSelectedSeat,
        selectedSeats,
        chargeStatus,
      })
    }
  }

  handleChange = (event, tabIndex) => {
    this.setState({ tabIndex })
  }

  getPassengerCollection = async (routeId) => {
    let res = await Provider.dataProvider('REMOTE', 'passengercollections', {
      method: 'getPassengerCollectionByRoute',
      requestMethod: 'GET',
      data: { routeId }
    })
    if (res && res.data){
      return res.data
    }
  }
  
  onAddSeat = () => {
    let { selectedSeats, charges } = this.state
    for(let code in selectedSeats) {
      if (charges[code]) {
        delete (selectedSeats[code])
      }
    }
    this.setState({ showReservationDialog: true, selectedSeats })
  }

  onEditSeat = (seat) => {
    let { selectedSeats, charges } = this.state
    for(let code in selectedSeats) {
      if (!charges[code]) {
        delete (selectedSeats[code])
      }
    }
    this.setState({
      showEditReservationDialog: true,
      selectedReservation: seat.charge.reservation,
      selectedSeats,
    })
  }

  getRelatedSeat = (seat, reservationId) => {
    let { charges } = this.state
    let relatedSeats = []
    for (let code in charges) {
      let { reservationId: rId } = charges[code]
      if (rId === reservationId) {
        relatedSeats.push(code)
      } 
    }
    return relatedSeats
  }

  onCancelSeat = (seat, reservationId) => {
    let { charges, selectedSeats = {}, isSwapMode, swapSelectedSeat = {} } = this.state
    let relatedSeats = []
    for (let code in charges) {
      let { reservationId: rId } = charges[code]
      if (rId === reservationId) {
        relatedSeats.push(code)
      } else if (code in selectedSeats) {
        delete (selectedSeats[code])
      }
    }
    if (isSwapMode) {
      isSwapMode = false
      swapSelectedSeat = {}
    }
    this.setState({
      showCancelConfirmationDialog: true,
      requestCancelSeat: seat,
      reservationSelected: {
        id: reservationId,
        relatedSeats,
      },
      isSwapMode,
      selectedSeats,
      swapSelectedSeat,
    })
  }

  onCloseCancelSeat = () => {
    this.setState({
      showCancelConfirmationDialog: false,
      requestCancelSeat: null,
      reservationSelected: {
        id: 0,
        relatedSeats: [],
      }
    })
  }

  handleOpenDrawerPhone = () => {
    let { openDrawer } = this.state
    this.setState({
      openDrawer: !openDrawer
    })
  }

  handleCloseDrawerPhone = () => {
    this.setState({
      openDrawer: false
    })
  }

  doCancelSeat = async (seat) => {
    let { showNotification } = this.props
    if (!seat || !seat.charge) {
      showNotification('notification.reservation.cancelFail', 'warning')
      return
    }

    let res = await Provider.dataProvider('REMOTE', 'charges', {
      method: '/cancel',
      requestMethod: 'POST',
      data: { ids: [seat.charge.id] },
    })

    if (res && res.data && res.data.result) {
      this.onCloseCancelSeat()
      showNotification('notification.reservation.cancelSuccess', 'info')
      this.reload(true)
    } else {
      this.onCloseCancelSeat()
      showNotification('notification.reservation.cancelFail', 'warning')
    }
  }

  doCancelReservation = async (reservationId) => {
    let { showNotification } = this.props
    let res = await Provider.dataProvider('REMOTE', 'reservations', {
      method: `${reservationId}/cancel`,
      requestMethod: 'POST',
    })

    if (res && res.data && res.data.data.result) {
      showNotification('notification.reservation.cancelSuccess', 'info')
      this.onCloseCancelSeat()
      this.reload(true)
    } else {
      this.onCloseCancelSeat()
      showNotification('notification.reservation.cancelFail', 'warning')
    }
  }


  goBack = () => {
    let { prevUrl } = this.state
    let { history } = this.props
    history.push(prevUrl)
  }

  multipleSelect = (code, event) => {
    event.preventDefault()
    event.stopPropagation()
    let { selectedSeats = {}, swapSelectedSeat, isSwapMode, reservationSelected = {} } = this.state
    if (!isSwapMode) {
      if (code in selectedSeats) {
        delete (selectedSeats[code])
      } else {
        selectedSeats[code] = 1
      }
      this.setState({ selectedSeats })
    } else {
      if (code in swapSelectedSeat) {
        delete (swapSelectedSeat[code])
      } else {
        let selectedSeatsLen = Object.values(selectedSeats).length
        let swapSelectedSeatLen = Object.values(swapSelectedSeat).length
        if (selectedSeatsLen === swapSelectedSeatLen) {
          let codes = Object.keys(swapSelectedSeat)
          let lastestCode = _.last(codes)
          delete (swapSelectedSeat[lastestCode])
          swapSelectedSeat[code] = 1
          let reservationId = reservationSelected.id
          this.swapSeat(selectedSeats, swapSelectedSeat, reservationId)
        } else if (swapSelectedSeatLen < selectedSeatsLen) {
          swapSelectedSeat[code] = 1
          swapSelectedSeatLen = Object.values(swapSelectedSeat).length
          if (selectedSeatsLen === swapSelectedSeatLen) {
            let reservationId = reservationSelected.id
            this.setState({ swapLoading: true }, () => {
              this.swapSeat(selectedSeats, swapSelectedSeat, reservationId) 
            })
          }
        }
        this.setState({ swapSelectedSeat })
      }
    }
  }

  singleSelect = (code) => {
    let { isSwapMode, selectedSeats: currentSelectedSeats, reservationSelected = {} } = this.state
    if (!isSwapMode) {
      let selectedSeats = { [code]: 1 }
      this.setState({ selectedSeats })
    } else {
      let swapSelectedSeat = { [code]: 1 }
      let currentSelectedSeatsLen = Object.values(currentSelectedSeats).length
      let swapSelectedSeatLen = Object.values(swapSelectedSeat).length
      this.setState({ swapSelectedSeat })
      if (currentSelectedSeatsLen === swapSelectedSeatLen) {
        let reservationId = reservationSelected.id
        this.setState({ swapLoading: true }, () => {
          this.swapSeat(currentSelectedSeats, swapSelectedSeat, reservationId) 
        })
      }
    }
  }

  getCurrentCampaign = () => {
    let { campaigns, tripCampaigns } = this.state
    let campaign
    if (!_.isEmpty(tripCampaigns)) {
      let campaignId = Object.values(tripCampaigns)[0][0]
      campaign = campaigns[campaignId]
    }

    let { voucherCampaign } = this.state
    if (voucherCampaign) campaign = voucherCampaign
    return campaign
  }
  
  buildCharges = (charges) => {
    let { fareTable } = this.state
    let currentFares = fareTable.getPriceTable()
    let currentCampaign = this.getCurrentCampaign()
    let totalDiff = 0
    for(let i = 0; i < charges.length; i++) {
      let charge = charges[i]
      if (charge) {
        let chargeFare = {}
        let { itemDetail, amount: prevAmount } = charge
        let { fare = {} } = itemDetail
        let { column: code } = fare
        let fareIdx = _.findIndex(currentFares, { key: code })
        fareIdx = fareIdx >= 0 ? fareIdx : 0
        let currentFare = currentFares[fareIdx]
        chargeFare = fareTable.getChargeFare({ index: fareIdx, code }) 
        itemDetail = { ...itemDetail, ...chargeFare }
        let discount = 0
        let campaignId = 0
        if (currentCampaign) {
          discount = getCampaignDiscountByPrice(currentCampaign.promotion, currentFare.value)
          campaignId = currentCampaign.id
        }
        itemDetail = { ...itemDetail, discount, campaignId }
        charge.itemDetail = itemDetail
        let newAmount = currentFare.value - discount
        charge.amount = newAmount
        totalDiff +=(prevAmount - newAmount)
      }
    }
    return { charges, totalDiff }
  }
  
  onSwapSeat = (oldChargeIds, newCharges, reservationId) => {
    let { showNotification } = this.props
    Provider.dataProvider('REMOTE', 'reservations', {
      method: 'swapSeat',
      requestMethod: 'POST',
      data: { oldChargeIds, newCharges, reservationId }
    }).then(() => {
      this.reload()
    }).catch((e) => {
      this.setState({
        isSwapMode: false,
        selectedSeats: {},
        swapSelectedSeat: {},
      })
      showNotification(_.get(e, 'body.error.message') || e.message, 'warning')
    })
  }
  
  onCloseConfirmSwapSeatDialog = () => {
    this.setState({
      confirmSwapSeatDialog: {
        open: false,
        totalDiff: 0,
        oldChargeIds: [],
        newCharges: [],
        reservationId: 0,
      },
      swapSelectedSeat: {},
    })
  }

  onOkConfirmSwapSeatDialog = (oldChargeIds, newCharges, reservationId) => {
    this.onSwapSeat(oldChargeIds, newCharges, reservationId)
  }

  swapSeat = (oldSeats, newSeats, reservationId) => {
    let { charges } = this.state
    let oldChargeIds = []
    let index = 0
    let swappedCharges = []
    newSeats = Object.keys(newSeats)
    for (let seat in oldSeats) {
      let charge = charges[seat]
      if (charge) {
        let newSeat = newSeats[index]
        let { id, itemDetail, amount, tripId } = charge
        itemDetail.itemCode = newSeat
        let swappedCharge = { itemDetail, amount, tripId }
        swappedCharges.push(swappedCharge)
        oldChargeIds.push(id)
      }
      index++
    }
    let { charges: newCharges, totalDiff } = this.buildCharges(swappedCharges)
    if (totalDiff !== 0) {
      this.setState({
        confirmSwapSeatDialog: {
          open: true,
          totalDiff,
          oldChargeIds,
          newCharges,
          reservationId,
        }
      })
    } else {
      this.onSwapSeat(oldChargeIds, newCharges, reservationId)
    }
  }

  selectReservation = (code) => {
    let { charges } = this.state
    let charge = charges[code]
    if (!charge) return
    let selectedSeats = {}
    let { reservationId } = charge
    for (let chargeCode in charges) {
      let { reservationId: rId } = charges[chargeCode]
      if (reservationId === rId) {
        selectedSeats[chargeCode] = 1
      }
    }
    this.setState({ selectedSeats })
  }

  changeSwapMode = (seat, reservationId) => {
    let {
      charges,
      swapSelectedSeat = {},
      selectedSeats = {},
      isSwapMode,
    } = this.state
    let relatedSeats = []
    for (let code in charges) {
      let { reservationId: rId } = charges[code]
      if (rId === reservationId) {
        relatedSeats.push(code)
      } else if (code in selectedSeats) {
        delete (selectedSeats[code])
        swapSelectedSeat = {}
      }
    }
    if (isSwapMode) {
      if (seat in selectedSeats) {
        let selectedSeatsLen = Object.values(selectedSeats).length
        let swapSelectedSeatLen = Object.values(swapSelectedSeat).length
        delete (selectedSeats[seat])
        if (selectedSeatsLen === swapSelectedSeatLen) {
          let codes = Object.keys(swapSelectedSeat)
          let lastestCode = _.last(codes)
          delete (swapSelectedSeat[lastestCode])
        }
      } else {
        selectedSeats[seat] = 1
      }
      isSwapMode = !_.isEmpty(selectedSeats)
    } else {
      isSwapMode = true
    }
    this.setState({
      isSwapMode,
      selectedSeats,
      swapSelectedSeat,
      reservationSelected: { id: reservationId }
    }, () =>{
      let { isSwapMode } = this.state
      !isSwapMode && this.setState({ swapSelectedSeat: {} })
    })
  }

  isOwner = agencyId => {
    let currentAgency = getCurrentAgency()
    let { id: currentAgencyId } = currentAgency
    return currentAgencyId === agencyId
  }

  assignFare = () => {
    let { push } = this.props
    push('/faretables')
  }

  render() {
    let {
      floors = [],
      selectedSeats = {},
      swapSelectedSeat = {},
      loading,
      trip = {},
      fareTable,
      products,
      showReservationDialog,
      showEditReservationDialog,
      showCancelConfirmationDialog,
      reservationChargeCount = {},
      selectedReservation,
      requestCancelSeat,
      cusPhone,
      callLogId,
      reservationSelected = {},
      passengerCollections,
      allStop,
      campaigns,
      tripCampaigns,
      isSwapMode,
      confirmSwapSeatDialog,
      swapLoading,
      charges,
      prevUrl,
      tabIndex,
    } = this.state
    let styleSeatLayout = { opacity: swapLoading ? 0.5 : 1 }
    let { id: reservationId, relatedSeats } = reservationSelected
    let { open: openConfirmSwapSeatDialog, oldChargeIds, newCharges, totalDiff } = confirmSwapSeatDialog
    let { classes, translate, permissions } = this.props
    let isCompanyManager = permission.isCompanyManager(permissions)
    let { vehicle, status: tripStatus } = trip || {}
    let decks = floors.filter(v => v && v.length > 0)
    let isDoubleDeck = decks && decks.length > 1
    let licensePlateNum = vehicle && `${translate('resources.vehicles.name', { smart_count: 1 })}: ${vehicle.plate}`
    let tripActive = canSale(tripStatus)
    let onlyView = trip.status === '40ARC'
    let canSwapTrip = validSwapTrip(tripStatus) && (charges && !_.isEmpty(charges))
    return <div>
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.appBarContainer}>
          <div className={classes.appBarText}>
            <IconButton color="inherit" onClick={this.goBack} aria-label="Close">
              <CloseIcon />
            </IconButton>
            {vehicle &&
              <div className={classes.flex}>
                <Typography variant="h6" color="inherit">
                  {licensePlateNum}
                  &nbsp;&nbsp;
                  ({trip.departureTime.format(timeFormat)} - {trip.arrivalTime.format(timeFormat)})
                  &nbsp;
                  {trip.departureTime.format('DD/MM/YYYY')}
                  &nbsp;
                  <ReferenceField
                    basePath="/tripstatuses"
                    reference="tripstatuses"
                    resource="tripstatuses"
                    source="status"
                    linkType={false}
                    allowEmpty
                    record={trip}
                  >
                    <StatusField source="name" />
                  </ReferenceField>
                </Typography>
                <Typography variant="subtitle2" color="inherit">
                  {trip.departure} - {trip.arrival}
                </Typography>
              </div>
            }
          </div>
          <div className={classes.appBarAction}>
            <SwapSeatToTripButton
              button
              trip={trip}
              vehicle={vehicle}
              licensePlateNum={licensePlateNum}
              fareTable={fareTable}
              isDoubleDeck={isDoubleDeck}
              floors={floors}
              isCompanyManager={isCompanyManager}
              styleSeatLayout={styleSeatLayout}
              selectedSeats={selectedSeats}
              swapSelectedSeat={swapSelectedSeat}
              reservationChargeCount={reservationChargeCount}
              reservationId={reservationId}
              relatedSeats={relatedSeats}
              allStop={allStop}
              onlyView={onlyView}
              charges={charges}
              reloadTripLayout={this.reload}
              canSwapTrip={canSwapTrip}
              size="small"
              variant="contained"
              className={classes.swapTripButton}
            />
            <Button
              className={classes.printSeatMapButton}
              onClick={() => {
                window.print()
                return
              }}
              color="inherit"
              variant="contained"
              size="small"
            >
              <FontAwesomeIcon icon={faPrint} size="xs" className={classes.icon} />
              <span>{translate('button.print_seat_map')}</span>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      {(loading || swapLoading) && <div className={classes.progress}><CircularProgress /></div>}
      {fareTable && <>
          <DeckTabs
            tabIndex={tabIndex}
            handleChange={this.handleChange}
            classes={classes}
            isDoubleDeck={isDoubleDeck}
            translate={translate}
            className={classes.tabs}
          />
          <div className={classes.layoutContainer} style={styleSeatLayout}>
            {!loading && <div className={classes.tabRoot}>
              {!_.isEmpty(tripCampaigns) &&
                <Snackbar
                  variant="info"
                  message={Object.values(tripCampaigns).map(campaignId => campaigns[campaignId].description).join('\n')}
                  className={classes.fullWidth100}
                />
              }
              {floors.map((floor, index) => {
                if (!floor || floor.length === 0) return null
                const cols = floor[0].length
                return (
                  <div key={index}>
                    <DeckTitle
                      isDoubleDeck={isDoubleDeck}
                      index={index}
                      trip={trip}
                      vehicle={vehicle}
                      translate={translate}
                      classes={classes}
                    />
                    <GridList
                      className={classes.floorList}
                      key={index}
                      cols={cols}
                      cellHeight="auto"
                      style={{ display: index !== this.state.tabIndex ? 'none' : 'flex' }}
                    >
                      {floor.map(row =>
                        row.map((seat, cindex) => {
                          let { charge, seat: code } = seat
                          let isOwner = true
                          if (!isCompanyManager) {
                            if (charge) {
                              isOwner = this.isOwner(charge.agencyId)
                            }
                          }
                          return (
                            <GridListTile key={cindex} className={classes.seat}>
                              {code && <SeatCard
                                translate={translate}
                                seat={seat}
                                selected={code in selectedSeats}
                                swapSelected={code in swapSelectedSeat}
                                changeSwapMode={this.changeSwapMode}
                                reservationChargeCount={reservationChargeCount}
                                onDoubleClick={(evt) => {
                                  //if (charge && isSwapMode) return
                                  evt.preventDefault()
                                  this.selectReservation(code, evt)
                                }}
                                onClick={(evt) => {
                                  if (charge && isSwapMode) return
                                  if (evt.ctrlKey || evt.metaKey) {
                                    this.multipleSelect(code, evt)
                                  } else {
                                    this.singleSelect(code, evt)
                                  }
                                }}
                                isSwapMode={isSwapMode}
                                multipleSelect={this.multipleSelect}
                                onAddSeat={this.onAddSeat}
                                onEditSeat={this.onEditSeat}
                                onCancelSeat={this.onCancelSeat}
                                isOwner={isOwner}
                                reservationId={reservationId}
                                relatedSeats={relatedSeats}
                                allStop={allStop}
                                onlyView={onlyView}
                                tripStatus={tripStatus}
                              />}
                            </GridListTile>
                          )
                        })
                      )}
                    </GridList>
                  </div>
                )
              })}
            </div>}
            <AddReservationDialog
              licensePlateNum={licensePlateNum}
              prevUrl={prevUrl}
              open={showReservationDialog}
              trip={trip}
              fareTable={fareTable}
              products={products}
              selectedSeats={selectedSeats}
              onClose={() => this.setState({ showReservationDialog: false })}
              reloadCharges={() => this.reload(true)}
              cusPhone={cusPhone}
              callLogId={callLogId}
              canSale={tripActive}
              passengerCollections={passengerCollections}
              campaigns={campaigns}
              tripCampaigns={tripCampaigns}
            />
            {showEditReservationDialog && <EditReservationDialog
              licensePlateNum={licensePlateNum}
              prevUrl={prevUrl}
              open={showEditReservationDialog}
              trip={trip}
              reservation={selectedReservation}
              onClose={() => this.setState({ showEditReservationDialog: false })}
              reloadCharges={() => this.reload(true)}
              canSale={tripActive}
              passengerCollections={passengerCollections}
              reload={this.reload}
            />}
            {showCancelConfirmationDialog && <CancelConfirmationDialog
              open={showCancelConfirmationDialog}
              onClose={this.onCloseCancelSeat}
              relatedSeats={relatedSeats}
              reservationId={reservationId}
              onCancelSeat={this.doCancelSeat}
              onCancelReservation={this.doCancelReservation}
              reloadCharges={() => this.reload(true)}
              seat={requestCancelSeat}
            />}
            {openConfirmSwapSeatDialog && <SwapSeatConfirmationDialog
              open={openConfirmSwapSeatDialog}
              onClose={this.onCloseConfirmSwapSeatDialog}
              reservationId={reservationId}
              onOk={this.onOkConfirmSwapSeatDialog}
              oldChargeIds={oldChargeIds}
              totalDiff={totalDiff}
              newCharges={newCharges}
            />}
          </div>
        </>}
      {!loading && !fareTable && <div className={classes.snackbarPosition}>
        <Snackbar
          variant="warning"
          message={translate('notification.unassign_fare_to_trip')}
          className={classes.fullWidth}
          onClick={this.assignFare}
          labelButtonAction={translate('button.assign_fare')}
          action={action}
        />
      </div>}
    </div>
  }
}

const EnhanceSeatLayout = compose(
  withSnackbar,
  translate,
  withStyles(styles),
  //withMQTT({ eventListener: TRIP_LAYOUT }),
  connect(null,
    { showNotification, observeTripAction, push }
  )
)(_SeatLayout)

const SeatLayoutWithPermission = ({ history, location, match }) => {
  return <WithPermissions
    render={({ permissions }) => {
      return permissions && <EnhanceSeatLayout
        history={history}
        location={location}
        match={match}
        permissions={permissions}
      />
    }}
  />
}

export default SeatLayoutWithPermission

class _SwapSeatConfirmationDialog extends Component {

  render() {
    let {
      reservationId,
      onClose,
      onOk,
      translate,
      open,
      oldChargeIds,
      newCharges,
      totalDiff,
    } = this.props
    let lessThan = totalDiff > 0
    let message = lessThan ? 'resources.common.new_fare_less_than_old_fare' : 'resources.common.new_fare_more_than_old_fare'
    let style = { color: lessThan? green[300] : red[300]}
    return <Dialog
      open={open}
      onClose={onClose}
      maxWidt="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {translate('resources.reservations.swapSeatWarning')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>{translate(message)}&nbsp;<b style={style}>{formatCurrency(Math.abs(totalDiff))}</b>.&nbsp;{translate('resources.common.want_to_continue')}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            onOk(oldChargeIds, newCharges, reservationId)
          }}
          color="primary"
        >
          {translate('button.ok')}
        </Button>
        <Button onClick={onClose}>
          {translate('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  }
}

export const SwapSeatConfirmationDialog = compose(translate)(_SwapSeatConfirmationDialog)

class _CancelConfirmationDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      open: false,
      seat: null,
    }
  }

  render() {
    let {
      onCancelSeat,
      onCancelReservation,
      reservationId,
      relatedSeats = [],
      onClose,
      translate,
      open,
      seat,
    } = this.props
    let hasRelatedSeat = relatedSeats && relatedSeats.length > 1
    return <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="cancel-seat-title"
      aria-describedby="alert-dialog-description"
      maxWidt="sm"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {translate('resources.reservations.cancelSeatWarning')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {translate('resources.reservations.cancelSeatConfirm')}: <strong>{seat.seat}</strong>
        </DialogContentText>
        {hasRelatedSeat && <DialogContentText id="alert-dialog-description">
          {translate('resources.reservations.cancelAll')}: <strong>{relatedSeats.join(',')}</strong>
        </DialogContentText>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (!hasRelatedSeat) {
              onCancelReservation(reservationId)
            } else {
              onCancelSeat(seat)
            } 
          }}
          color="primary"
        >
          {translate('button.cancel_charge')}
        </Button>
        {hasRelatedSeat && <Button
          onClick={() => onCancelReservation(reservationId)}
          color="primary"
        >
          {translate('button.cancel_reservation')}
        </Button>}
        <Button
          onClick={onClose}
          color="primary"
          autoFocus
        >
          {translate('button.close')}
        </Button>
      </DialogActions>
    </Dialog>
  }
}

// render signle- or double-decker tabs
export const DeckTabs = ({ isDoubleDeck, label, translate, tabIndex, handleChange, className }) => {
  // filter only decker which has seats
  return <Tabs
    value={tabIndex}
    onChange={handleChange}
    textColor="primary"
    centered
    className={className}
  >
    {isDoubleDeck && <Tab label={translate('resources.seatmaps.fields.firstFloor')} />}
    {isDoubleDeck && <Tab label={translate('resources.seatmaps.fields.secondFloor')} />}
    {!isDoubleDeck && <Tab label={label ? label : translate('resources.seatmaps.name', { smart_count: 1 })} />}
  </Tabs>
}

// decker title, single- or double-decker
export const DeckTitle = ({ isDoubleDeck, index, isSwapMode, vehicle, trip, translate, classes }) => {
  let deckName = index === 1 ? translate('resources.seatmaps.fields.secondFloor') : translate('resources.seatmaps.fields.firstFloor')
  let title = isDoubleDeck ? deckName : translate('resources.seatmaps.name', { smart_count: 1 })
  let licensePlateNum = `${translate('resources.vehicles.name', { smart_count: 1 })}: ${vehicle.plate}`

  return <b className={classes.floorTitle}>
    <span>{title}</span>
    {!isSwapMode && <Fragment>
      <span>{trip.departure} - {trip.arrival}</span>
      <span>
        {licensePlateNum}
          &nbsp;&nbsp;
          ({trip.departureTime.format(dateTimeFormat)} - {trip.arrivalTime.format(dateTimeFormat)})
      </span>
    </Fragment>}
  </b>
}

const enhanceCancelConfirmationDialog = compose(
  withStyles(styles),
  translate,
  connect(null, { showNotification })
)

const CancelConfirmationDialog = enhanceCancelConfirmationDialog(_CancelConfirmationDialog)
