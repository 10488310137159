import React, { Component } from 'react'
import compose from 'recompose/compose'
import {
  withStyles,
  Grid,
} from '@material-ui/core'
import { translate } from 'react-admin'
import { getColor } from '../utils/color'
import CallLogCard from './CallLogCard'
import MissedCallIcon from '../icons/missedcall'
import CallIcon from '../icons/call'
import ReservationIcon from '../icons/reservation'
import EndCallIcon from '../icons/endcall'

const styles = (theme) => {
  let { unit } = theme.spacing
  return {
    swipeContainer: {
      width: 400,
    },
    gridRoot: {
      marginLeft: -2 * unit,
      marginRight: -2 * unit,
    },
    icon: {
      float: 'right',
      width: 85,
      height: 55,
      padding: 8,
      color: '#fff',
    },
  }
}

class GeneralReport extends Component {

  render() {
    let { classes, report = {}, translate } = this.props
    let { total, receivedTotal, missedTotal, totalReservated } = report

    return (
      <div className={classes.gridRoot}>
        <Grid container spacing={0}>
          <Grid item xs>
            <CallLogCard
              title={translate('resources.calllogs.report.total')}
              bgColor={getColor(1)}
              icon={<EndCallIcon className={classes.icon} />}
              value={total ? total : 0}
            />
          </Grid>

          <Grid item xs>
            <CallLogCard
              title={translate('resources.calllogs.report.totalReceived')}
              bgColor={getColor(2)}
              icon={<CallIcon className={classes.icon} />}
              size="3x"
              value={receivedTotal ? receivedTotal : 0}
            />
          </Grid>

          <Grid item xs>
            <CallLogCard
              title={translate('resources.calllogs.report.totalMissed')}
              bgColor={getColor(3)}
              icon={<MissedCallIcon className={classes.icon} />}
              size="3x"
              value={missedTotal ? missedTotal : 0}
            />
          </Grid>
          <Grid item xs>
            <CallLogCard
              title={translate('resources.calllogs.report.totalReservated')}
              bgColor={getColor(4)}
              icon={<ReservationIcon className={classes.icon} />}
              size="3x"
              value={totalReservated ? totalReservated : 0}
            />
          </Grid>
        </Grid>
      </div>
    )
  }
}

const enhance = compose(
  withStyles(styles),
  translate
)

export default enhance(GeneralReport)
