import React, { Component, Fragment } from 'react'
import {
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Paper,
  FormLabel,
  FormControl,
} from '@material-ui/core'
import compose from 'recompose/compose'
import { 
  showNotification,
  translate,
  SelectInput,
  addField,
  SimpleForm,
  NumberInput,
  TextInput,
  FormDataConsumer,
  required,
  ReferenceArrayInput,
} from 'react-admin'
import { connect } from 'react-redux'
import { reset, change as changeForm } from 'redux-form'
import ChipArrayField from '../common/ChipArrayField'
import _ from 'lodash'
import { Provider } from '../provider'
import CheckboxGroupInput from '../common/CheckboxGroupInput'

const CONFIG_VALIDATE_FORM = 'config-validate-form'

export const EXPIRED = 'expire'
export const START_TIME_TYPE = 'startTimeType'
export const DURATION = 'duration'

export const labelMapping = {
  [EXPIRED]: 'resources.common.product_validate_type.expired',
  [START_TIME_TYPE]: 'resources.common.product_validate_type.start_time_type',
  [DURATION]: 'resources.common.product_validate_type.duration',
}

const BUY_TIME = 'buyTime'
const FIRST_USE = 'firstUse'
const INPUT = 'input'

export const labelStartTimeTypeMapping = {
  [BUY_TIME]: 'resources.common.start_time_type.buy_time',
  [FIRST_USE]: 'resources.common.start_time_type.first_use',
  [INPUT]: 'resources.common.start_time_type.input',
}

const dialogStyles = {
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  content: {
    padding: 0,
  },
  contentText: {
    paddingLeft: 16,
  }
}

const validationStyles = {
  checkbox: {
    display: 'flex'
  },
  title: {
    padding: 8,
    backgroundColor: '#303f9f',
    marginBottom: 16,
  },
  textTitle: {
    fontWeight: 'bold',
    color: 'white',
  },
  content: {
    padding: 0,
  },
  contentText: {
    paddingLeft: 16,
  }
}
class _ValidationInput extends Component {

  renderComponent = () => {
    let { source, options, classes, } = this.props
    let { type, label, values } = options
    let choices
    if (values) {
      choices = _.reduce(values, (result, name, id) => {
        result.push({ id, name })
        return result
      }, [])
    }
    switch(type) {
      case 'number':
        return <NumberInput
          label={label}
          resource="validationproducts"
          source={source}
          fullWidth
        />
      case 'choice':
        return <SelectInput
          label={label}
          resource="validationproducts"
          choices={choices}
          source={source}
          optionText="name"
          translationChoice
          fullWidth
        />
      default:
        return <TextInput
          label={label}
          resource="validationproducts"
          source={source}
          fullWidth
        />

    }
  }

  render() {
    return this.renderComponent()
  }
}

const ValidationInput = compose(
  withStyles(validationStyles),
  translate,
  connect(null, { showNotification, changeForm })
)(_ValidationInput)

class _ValidateFormDialog extends Component {

  state = {}

  componentDidMount() {
    let { ticketValidations, record = {} } = this.props
    if (!ticketValidations) {
      this.getTicketValidations()
        .then(res => {
          this.setState({ ticketValidations: res })
        })
        .catch(err => {
          console.log('===ticketValidations err... ', err)
        })
    }
    let type = record.type
    this.getServiceDays()
      .then(res => {
        const serviceDays = res
        this.setState({ serviceDays })
      })
      .catch(err => {
        console.log('===getServiceDays err... ', err)
      })
    this.setState({ type })
  }

  getTicketValidations = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'ticketvalidations', {
      filter: {
        '../fields': ['id', 'name', 'parameters']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getServiceDays = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'servicedays', {
      filter: {},
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      const data = _.get(res, 'data', [])
      const serviceDays = _.map(data, item => _.get(item, 'id'))
      return serviceDays
    }
  }

  save = data => {
    const { setValue, onClose } = this.props
    setValue(data)
    onClose()
  }

  renderParameterInput = (type = 'general') => {
    let { changeForm, record, ticketValidations, classes, } = this.props
    let { type: currentType } = this.state
    let validation = ticketValidations.find(ele => ele.id === type)
    let { parameters = {} } = validation
    let parameterKeys = Object.keys(parameters)
    if (type !== currentType) {
      this.setState({ type })
      let params = null
      if (record.type === type) {
        params = record.params
      }
      changeForm(CONFIG_VALIDATE_FORM, 'params', params)
    }

    return parameterKeys && parameterKeys.map((parameterKey, idx) => {
      let options = parameters[parameterKey]
      let source = `params.${parameterKey}`
      return <div
        key={idx}
      >
        <ValidationInput
          key={idx}
          source={source}
          options={options}
        />
        {
          source === 'params.startTimeType' && <FormDataConsumer>
            {({ formData = {} }) => {
              let { serviceDays: defaultValue } = this.state
              let serviceDays = _.get(formData, 'params.serviceDays') || defaultValue
              _.set(formData, 'params.serviceDays', serviceDays)
              let startTimeType = _.get(formData, 'params.startTimeType', '')
              return startTimeType === 'input' ? <ReferenceArrayInput
                source="params.serviceDays"
                reference="servicedays"
                resource="validationproducts"
                validate={required()}
                sort={{id: 'asc'}}
              >
                <CheckboxGroupInput
                  className={classes.checkbox}
                  direction="row"
                />
              </ReferenceArrayInput> : null
            }}
          </FormDataConsumer>
        }
      </div>
    })
  }

  render() {
    let { 
      classes,
      open,
      onClose,
      translate,
      record = {},
      ticketValidations
    } = this.props
    return <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className={classes.title}>
        <span className={classes.textTitle}>{translate('resources.validationproducts.configuration_title')}</span>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <SimpleForm
          resource="validationproducts"
          enableReinitialize={true}
          keepDirtyOnReinitialize={false}
          form={CONFIG_VALIDATE_FORM}
          record={record}
          save={this.save}
        >
          <SelectInput
            resource="validationproducts"
            choices={ticketValidations}
            source="type"
            validate={required()}
            optionText="name"
            fullWidth
          />

          <FormDataConsumer>
            {({ formData = {} }) => {
              let { type } = formData
              return type ? this.renderParameterInput(type) : null
            }}
          </FormDataConsumer>
        </SimpleForm>
      </DialogContent>
    </Dialog>
  }
}

const ValidateFormDialog = compose(
  withStyles(dialogStyles),
  translate,
  connect(null, { showNotification, changeForm })
)(_ValidateFormDialog)

const styles = (theme) => {
  return ({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    chip: {
      margin: theme.spacing.unit / 2,
      fontSize: 13,
      flex: 1,
    },
    actionButton: {
      padding: 5,
      width: 36,
      height: 36
    },
    defaultText: {
      width: '100%',
      fontSize: 13,
      textAlign: 'center',
    },
    tooltip: {
      backgroundColor: '#f44336'
    },
    fieldSet: {
      border: '1px solid #3f51b5',
      borderRadius: 4,
    },
    legend: {
      marginLeft: 16,
      color: '#3f51b5',
      padding: '0px 4px'
    },
    chipContainer: {
      display: 'flex',
      padding: '16px 4px',
      boxShadow: 'none',
      flexWrap: 'wrap',
    },
    noConfig: {
      fontSize: 13,
      fontStyle: 'italic',
      marginLeft: 16,
      color: '#424242',
    }
  })
}

class ProductValidateChip extends Component {

  state = {
    open: false,
  }

  async componentDidMount() {
    let { input, setting, ticketValidations } = this.props
    if (!ticketValidations) {
      ticketValidations = await this.getTicketValidations()
    }
    setting = !_.isEmpty(setting) ? setting : {}
    let { value: inputValue } = input
    let record = inputValue || setting
    const serviceDayValues = await this.getServiceDays()
    this.setState({ record, ticketValidations, serviceDayValues })
  }

  getTicketValidations = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'ticketvalidations', {
      filter: {
        '../fields': ['id', 'name', 'parameters']
      },
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      return res.data
    }
  }

  getServiceDays = async () => {
    let res = await Provider.dataProvider('GET_LIST', 'servicedays', {
      filter: {},
      pagination: {},
      sort: {},
    })
    if (res && res.data) {
      const data = _.get(res, 'data', [])
      const serviceDayValues = _.reduce(data, (result, value) => {
        const { id, name } = value
        result = { ...result, [id]: name }
        return result
      }, {})
      return serviceDayValues
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { input } = nextProps
    let { value: record } = input
    let { record: currentRecord } = prevState 
    if (record !== currentRecord) {
      return { ...prevState, record }
    }
    return { ...prevState }
  }

  handleEdit = () => {
    this.setState({
      open: true,
    })
  }

  handleClose = () => {
    const { reset } = this.props
    this.setState({
      open: false,
    })
    reset(CONFIG_VALIDATE_FORM)
  }

  setValue = record => {
    let { input } = this.props
    this.setState({ record })
    input.onChange(record)
  }

  getLabels = () => {
    let { translate } = this.props
    let { record, ticketValidations, serviceDayValues } = this.state
    if (!_.isEmpty(ticketValidations) && !_.isEmpty(record)) {
      let { type, params } = record
      let validation = ticketValidations.find(ele => ele.id === type)
      let { name, parameters } = validation
      let labels = _.reduce(params, (result, value, key) => {
        let suffix
        let parameter = parameters[key]
        if (parameter) {
          let { label, values } = parameter
          let prefix = label
          if (value) {
            suffix = value
            if (values) {
              suffix = values[suffix]
            }
          } else {
            suffix = translate('resources.common.no_config')
          }
          let validationValue = `${prefix}: ${suffix}`
          result.push(validationValue)
        }
        if (key === 'serviceDays' && value && _.get(params, 'startTimeType') === 'input') {
          value = value || []
          const serviceDays = _.map(value, key => serviceDayValues[key])
          let validationValue = `${translate('resources.validationproducts.fields.params.serviceDays')}: ${serviceDays.join(', ')}`
          result.push(validationValue)
        }
        return result
      }, [])
      return { validationTypeName: name, labels }
    }
  }

  render() {
    let { classes, translate, nonEdit } = this.props
    let { open, record, ticketValidations } = this.state
    let { validationTypeName, labels } = this.getLabels() || {}
    return <Fragment>
      <FormControl
        component="fieldset"
        variant="outlined"
        fullWidth
        className={classes.fieldSet}
        onClick={() => { 
          if (nonEdit) return
          this.handleEdit()
        }}
      >
        <FormLabel
          component="legend"
          color="primary"
          className={classes.legend}
        >
          {translate('resources.validationproducts.configuration_title')}{validationTypeName && `: ${validationTypeName}`}
        </FormLabel>
        <Paper className={classes.chipContainer}>
          {labels ? <ChipArrayField
            color="primary"
            datum={labels}
            variant="outlined"
            className={classes.chip}
          /> : <span className={classes.noConfig}>{translate('resources.common.no_config_validation', { smart_count: 2 })}</span>}
        </Paper>
      </FormControl>
      {open && <ValidateFormDialog
        open={open}
        onClose={this.handleClose}
        setValue={this.setValue}
        record={record}
        ticketValidations={ticketValidations}
      />}
    </Fragment>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
  addField,
  connect(null, { showNotification, reset })
)

export default enhance(ProductValidateChip)
