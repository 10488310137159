import FuzzyPicker from './fuzzy-picker'
import AsyncFuzzyPicker from './async-fuzzy-picker'
import FuzzyWrapper from './fuzzy-wrapper'
import FuzzySelectInput from './fuzzy-select-input'
import FuzzySelectArray from './fuzzy-select-array'
import FuzzySelectArrayInput from './fuzzy-select-array-input'
import FuzzyAsyncPickerInput from './fuzzy-async-picker-input'
import voca from 'voca'

export const defaultFuzzySearch = ({ options, name = 'name' } = {}) => ({
  options: {
    shouldSort: true,
    includeMatches: true,
    threshold: 0.6,
    location: 0,
    distance: 100,
    maxPatternLength: 20,
    minMatchCharLength: 1,
    tokenize: true,
    keys: ['key', 'shortkey'],
    ...options,
  },
  itemValue: function(item) {
    if (!item.key) {
      item.key = voca.latinise(item[name])
      let firstCharOfWord = item.key.split(/\s+/).map(word => word[0]).join('')
      item.shortkey = firstCharOfWord
    }
    return item
  },
})

export {
  FuzzyPicker,
  AsyncFuzzyPicker,
  FuzzyWrapper,
  FuzzySelectInput,
  FuzzySelectArray,
  FuzzySelectArrayInput,
  FuzzyAsyncPickerInput,
}
export default FuzzyPicker
