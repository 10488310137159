import { take, fork } from 'redux-saga/effects'
import { OBSERVE_TRIP } from './action'

function* handleObserveTrip() {
  while (true) {
    let { data } = yield take(OBSERVE_TRIP)
    let { trip, mqttService } = data
    if (mqttService) {
      if (!mqttService.isConnected()) {
        mqttService.connect()
      }
      let { id } = trip
      let topics = [`nexbus/trip/${id}`]
      mqttService.subscribe(topics)
    }
  }
}

export default function* handleTripLayout() {
  yield fork(handleObserveTrip)
}