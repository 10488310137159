import React, { Component } from 'react'
import {
  Button,
  Tooltip,
  withStyles,
} from '@material-ui/core'
import { translate } from 'react-admin'
import compose from 'recompose/compose'
import LinkField from '../common/LinkField'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { sanitize } from '../utils/commonUtil'

const styles = {
  iconButton: {
    width: 36,
    height: 36,
    padding: 0,
    margin: 0,
  },
  container: {
    display: 'flex',
  },
  icon: {
    marginRight: '0.5em',
    fontSize: 20
  },
}

class CancelReservationButton extends Component {

  onClick = (evt) => {
    let { seat, charge, reservationId, onCancelSeat, selected } = this.props
    if (selected) {
      evt.preventDefault()
      evt.stopPropagation()
    }
    onCancelSeat({ seat, charge }, reservationId)
  }

  render() {
    let {
      translate,
      color,
      button,
      classes,
      disabled,
      ...props
    } = this.props
    let style={ color: !disabled && '#fb404b'}
    let rest = sanitize(props, [ 'onCancelSeat', 'reservationId' ])
    return button ? <Button
      color={color}
      onClick={this.open}
      style={style}
      disabled={disabled}
      {...rest}
    >
      <div className={classes.container}>
        <FontAwesomeIcon icon={faTrash} className={classes.icon} />
        <span>{translate('button.cancel')}</span>
      </div>
    </Button> : <Tooltip
      title={translate('button.cancel')}
      enterDelay={100}
      style={style}
    >
      <LinkField
        className={classes.iconButton}
        icon
        onClick={this.onClick}
        disabled={disabled}
        {...rest}
      >
        <FontAwesomeIcon icon={faTrash} size="xs" />
      </LinkField>
    </Tooltip>
  }
}

const enhance = compose(
  withStyles(styles),
  translate,
)

export default enhance(CancelReservationButton)
