import React, { Component } from 'react'
import {
  List,
  TextField,
  DateField,
  Datagrid,
  Create,
  SimpleForm,
  Edit,
  FunctionField,
  ReferenceField,
  Filter,
  ReferenceInput,
  SelectInput,
  translate,
  CreateButton,
  ExportButton,
  CardActions,
  Responsive,
} from 'react-admin'
import FormSave from './FormSave'
import CardAvatar from '../common/CardAvatar'
import { withStyles, Button } from '@material-ui/core'
import compose from 'recompose/compose'
import { EditTitle } from '../common/Title'
import CustomToolbar from '../common/CustomToolbarForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileUpload } from '@fortawesome/free-solid-svg-icons'
import { isCompanyManager } from '../utils/permission'
import SearchInput from '../common/SearchInput'
import  AssistantdriversMobileGrid from './AssistantdriversMobileGrid'
import {validate} from './validate'

const styleList = () => ({
  avatarCol: {
    display: 'flex'
  },
  fullName: {
    marginLeft: 8,
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  textCenter: {
    textAlign: 'center',
  }
})

const styleListAction = () => ({
  rigthLabel: {
    paddingLeft: '0.5em'
  }
})

const styleListFilter = () => ({
  otherSearchInput: {
    marginBottom: 17,
  }
})

///////////////////////////////////////////
// List
const DriverFilter = withStyles(styleListFilter)(({ translate, classes, ...props }) => (
  <Filter {...props}>
    <SearchInput 
      helperText="resources.customers.searchHelperText"
      source="q"
      alwaysOn
    />
    <ReferenceInput
      basePath="/driverstatuses"
      reference="driverstatuses"
      source="status"
      resource="driverstatuses"
      fullWidth
      alwaysOn
      className={classes.otherSearchInput}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
))

const ActionList = withStyles(styleListAction)(({
  basePath,
  resource,
  currentSort,
  filterValues,
  exporter,
  classes,
  total,
  history,
  translate,
  companyManager,
}) => {
  return (
    <CardActions>
      {companyManager && <>
        <CreateButton basePath={basePath} />
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filter={filterValues}
          exporter={exporter}
        />
        <Button
          color="primary"
          onClick={() => {
            history.push(`/${resource}/import`)
          }}
        >
          <FontAwesomeIcon icon={faFileUpload} />
          <span className={classes.rigthLabel}>
            {translate('button.import')}
          </span>
        </Button>
      </>}
    </CardActions>
  )
})

class _AssistantDriverList extends Component {
  render() {
    let { classes, permissions, history, ...props } = this.props
    let companyManager = isCompanyManager(permissions)
    return (
      <List
        {...props}
        filters={<DriverFilter />} 
        actions={<ActionList history={history} companyManager={companyManager} />}
        bulkActionButtons={false}
        history={history}
        filterDefaultValues={{
          '../fields': [
            'id',
            'fullName',
            'birthday',
            'phone',
            'status',
            'avatar',
          ]
        }}
      >
        <Responsive
          xsmall={ <AssistantdriversMobileGrid companyManager={companyManager} history={history}/>}
          medium={
            <Datagrid rowClick={ companyManager && 'edit' }>
              <FunctionField
                source="fullName"
                render={record => (
                  <div className={classes.avatarCol}>
                    <CardAvatar record={record} size='small' />
                    <span className={classes.fullName}>{record.fullName}</span>
                  </div>
                )}
              />
              <DateField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                source="birthday"
                locales="vi-VN"
              />
              <TextField
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                source="phone"
              />
              <ReferenceField
                source="status"
                reference="driverstatuses"
                linkType={false}
                headerClassName={classes.textCenter}
                cellClassName={classes.textCenter}
                allowEmpty
              >
                <TextField source="name" />
              </ReferenceField>
            </Datagrid>}
        />
      </List>
    )
  }
}

const enhanceList = compose(withStyles(styleList), translate)
export const AssistantDriverList = enhanceList(_AssistantDriverList)

/////////////////////////////////////////
// Create
export class AssistantDriverCreate extends Component {

  render() {
    let { props } = this
    return (
      <Create
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} />}
      >
        <SimpleForm validate={validate} >
          <FormSave />
        </SimpleForm>
      </Create>
    )
  }
}

/////////////////////////////////////////
// Edit
export class AssistantDriverEdit extends Component {

  render() {
    let { id, ...props } = this.props
    return (
      <Edit
        id={id}
        {...props}
        undoable={false}
        title={<EditTitle resource={props.resource} render={record => record.fullName} />}
      >
        <SimpleForm toolbar={<CustomToolbar />} validate={validate} >
          <FormSave id={id} />
        </SimpleForm>
      </Edit>
    )
  }
}
